import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_EmailLink } from '@mirage/analytics/events/types/open_email_link';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useLogEmailLinkClickTracking = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    const campaignId = parseInt(searchParams.get('_camp') || '');

    if (campaignId) {
      reportPapEvent(
        PAP_Open_EmailLink({
          campaignId,
        }),
      );
      searchParams.delete('_camp');
      setSearchParams(searchParams);
    }
  }, [reportPapEvent]);
};

export default useLogEmailLinkClickTracking;
