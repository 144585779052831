import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { SummaryQna } from './SummaryQna';

import type { CloseDashChatType } from '@mirage/analytics/events/enums/close_dash_chat_type';
import type {
  FlyoutPanelData,
  FlyoutPanelView,
} from '@mirage/mosaics/FlyoutPanel/atoms';

type SummaryPanelProps = {
  onClose: (closeChatType: CloseDashChatType) => void;
  panelData: FlyoutPanelData['summary'];
  refreshPanel: (view: FlyoutPanelView) => void;
};

export const SummaryPanel = ({ onClose, panelData }: SummaryPanelProps) => {
  return (
    <SummaryQna
      onClose={onClose}
      papData={panelData?.pap || undefined}
      attachment={panelData?.attachment}
      isSummarize={panelData?.isSummarize}
      onOpenFeedbackSnackbar={() => {
        showSnackbar({
          title: i18n.t('feedback_received'),
        });
      }}
      onOpenCopyTextSnackbar={() => {
        showSnackbar({ title: i18n.t('copied_answer') });
      }}
      initialQuery={panelData?.initialQuery}
    />
  );
};
