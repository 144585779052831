import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { Text } from '@dropbox/dig-components/typography';
import { Box, ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronUpLine,
  CloseLine,
  RocketLine,
} from '@dropbox/dig-icons/assets';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import {
  LottieAnimation,
  LottieAnimationType,
} from '@mirage/shared/lottie-animations/LottieAnimation';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Checklist } from './Checklist';
import styles from './ChecklistButton.module.css';
import { ChecklistProgressBar } from './ChecklistProgressBar';

import type { OnboardingChecklistStep } from './types';

const SHOW_FINISHED_DELAY = 1500;

type ChecklistButtonProps = {
  checklistItems: OnboardingChecklistStep[] | undefined;
  progress: number;
  displayed: boolean;
  open: boolean;
  openId: string;
  className?: string;
  setDismissed: () => void;
  setOpen: (open: boolean) => void;
  setOpenId: (moduleId: string) => void;
  openLearnMore: (moduleId: string, uri: string) => void;
  handleShown: () => void;
};

type ChecklistSuccessProps = {
  setDismissed: () => void;
};

const FinishedPrompt = ({ setDismissed }: ChecklistSuccessProps) => {
  const handleDismissClick = () => {
    setDismissed();
  };
  return (
    <Box
      className={classnames(styles.successContainer)}
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="row"
        className={styles.dismissContainer}
      >
        <IconButton
          aria-label={i18n.t('aria_dismiss_icon')}
          variant="transparent"
          onClick={handleDismissClick}
        >
          <UIIcon src={CloseLine} />
        </IconButton>
      </Box>
      <Box
        className={styles.animationContainer}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LottieAnimation
          animationType={LottieAnimationType.ONBOARDING_CHECK}
          loop={false}
          autoplay={true}
          className={styles.animation}
        />
      </Box>
      <Text variant="label" size="large" isBold>
        {i18n.t('get_started_complete_title')}
      </Text>
      <Text className={styles.successText} variant="label" size="medium">
        {i18n.t('get_started_complete_message')}
      </Text>
    </Box>
  );
};

export const ChecklistButton = ({
  checklistItems,
  className,
  progress,
  displayed,
  open,
  openId,
  setDismissed,
  setOpen,
  setOpenId,
  openLearnMore,
  handleShown,
}: ChecklistButtonProps) => {
  const isDarkMode = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const hasSentShownEvent = useRef<boolean>(false);
  const hasDisplayedFullProgress = useRef<boolean>(false);
  const [showFinished, setShowFinished] = useState(false);
  const theme = isDarkMode ? 'bright' : 'dark'; // Invert the theme

  useEffect(() => {
    if (hasSentShownEvent.current) {
      return;
    }
    if (displayed) {
      handleShown();
      hasSentShownEvent.current = true;
    }
  }, [displayed, handleShown]);

  useEffect(() => {
    if (progress === 100 && open) {
      if (hasDisplayedFullProgress.current) {
        setShowFinished(true);
        return;
      }

      setTimeout(() => {
        hasDisplayedFullProgress.current = true;
        setShowFinished(true);
      }, SHOW_FINISHED_DELAY);
    }
  }, [progress, open]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  const handleDismiss = () => {
    setDismissed();
  };

  if (!displayed || !checklistItems?.length) return <></>;

  checklistItems.sort((a, b) => {
    if (a.id > b.id) return 1;
    if (a.id < b.id) return -1;
    return 0;
  });

  return (
    <ThemeProvider mode={theme}>
      <ThemeContainer>
        <ClickOutside
          isActive={open}
          onClickOutside={handleClickOutside}
          isClickThroughPortaled={false}
          shouldPropagateMouseEvents={true}
        >
          <div
            ref={ref}
            className={classnames(styles.container, className, {
              [styles.openContainer]: open,
              [styles.finishedContainer]: open && showFinished,
            })}
          >
            {open && showFinished ? (
              <FinishedPrompt setDismissed={setDismissed} />
            ) : (
              <>
                <button
                  type="button"
                  className={classnames(
                    styles.buttonContainer,
                    open ? styles.openButtonContainer : '',
                    progress > 0 ? styles.buttonContainerWithProgress : '',
                  )}
                  onClick={toggleOpen}
                >
                  <Box className={styles.titleContainer}>
                    <UIIcon src={RocketLine} className={styles.rocketIcon} />
                    <Text
                      className={styles.buttonText}
                      variant="label"
                      size="large"
                      isBold
                    >
                      {i18n.t('get_started_with_dash')}
                    </Text>
                    <UIIcon className={styles.chevron} src={ChevronUpLine} />
                  </Box>
                  <ChecklistProgressBar
                    className={classnames(
                      styles.progressBar,
                      open || progress > 0 ? styles.progressBarOpen : '',
                    )}
                    value={progress}
                  />
                </button>
                {open && (
                  <>
                    <Box
                      className={styles.listContainer}
                      display="flex"
                      flexDirection="column"
                    >
                      <Checklist
                        checklistItems={checklistItems}
                        openId={openId}
                        openLearnMore={openLearnMore}
                        setOpenId={setOpenId}
                      />
                    </Box>
                    <Box className={styles.dismissButtonContainer}>
                      <Button
                        variant="transparent"
                        onClick={handleDismiss}
                        size="small"
                      >
                        {i18n.t('get_started_checklist_dismiss')}
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
          </div>
        </ClickOutside>
      </ThemeContainer>
    </ThemeProvider>
  );
};
