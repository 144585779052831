import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import {
  CelebrateLine,
  ClockLine,
  EmojiSunglassesLine,
  LocationLine,
  SendLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import { Link } from '@mirage/link/Link';
import { UserProfileCircleIcon } from '@mirage/mosaics/UserProfilePage/UserProfileCircleIcon';
import {
  calculateTenurePercentile,
  formatHireDate,
  formatLeaveDate,
  formatLocation,
  getLocalTime,
  getTimezoneOffset,
  isMoreThanToday,
  toMetro,
} from '@mirage/mosaics/UserProfilePage/utils';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { Fragment } from 'react';
import styles from './UserProfileDetails.module.css';

import type { UserInfo } from '@mirage/mosaics/UserProfilePage/types';
import type { FunctionComponent } from 'react';

export const UserProfileDetails = ({ user }: { user: UserInfo }) => {
  const currentAccount = useDropboxAccount();

  const formatBirthday = (dateUnformatted: string): string => {
    const [month, day] = dateUnformatted.split('/');

    return Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'long',
      timeZone: 'UTC',
    }).format(new Date(`2020-${month}-${day}`)); // 2020 is a leap year
  };

  const startDate = user.upcoming_pto
    ? formatLeaveDate(user.upcoming_pto.split(' ')[0])
    : null;
  const endDate = user.upcoming_pto
    ? formatLeaveDate(user.upcoming_pto.split(' ')[1])
    : null;

  const calculateTimeDifference = (timezone: string) => {
    try {
      const diff = getTimezoneOffset(timezone);

      if (diff === 0) {
        return i18n.t('same_timezone_as_yours');
      } else {
        return i18n.t('current_time_info', {
          countHours: Math.abs(diff),
          hours: Math.abs(diff) === 1 ? i18n.t('hour') : i18n.t('hours'),
          aheadOrBehind:
            diff > 0 ? i18n.t('timezone_ahead') : i18n.t('timezone_behind'),
        });
      }
    } catch (e) {
      return '';
    }
  };

  const calculateTenure = (tenure: number): string => {
    if (tenure < 1) {
      return i18n.t('less_month');
    }

    const years = Math.floor(tenure / 12);
    const months = tenure % 12;

    let result = '';

    if (years > 0) {
      result += i18n.t('year', { count: years });
      if (months > 0) {
        result += ' ';
      }
    }

    if (months > 0) {
      result += i18n.t('month', { count: months });
    }

    return result;
  };

  const isBoomerang = (tenure: number, hireDate: string): boolean => {
    const current = new Date();
    const [month, year] = hireDate.split('/').map(Number);

    const hired = new Date(year, month - 1, 1);

    let years = current.getFullYear() - hired.getFullYear();
    let months = current.getMonth() - hired.getMonth();
    if (months < 0) {
      years--;
      months += 12;
    }

    const monthsSinceHireDate = years * 12 + months;

    return Math.abs(tenure - monthsSinceHireDate) > 1;
  };

  const isValidEmail = (email: string): boolean => {
    // contractor email is not assigned and hide it from UI.
    return email !== 'donotuse@dropbox.com';
  };

  const details = [
    <Item
      key="contact"
      icon={SendLine}
      primary={i18n.t('profile_contact')}
      secondary={
        <>
          {user.email && isValidEmail(user.email) && (
            <Link
              variant="monochromatic"
              hasNoUnderline
              href="mailto:${user.email}"
              key={user.email}
            >
              {'@' + user.ldap}
            </Link>
          )}
        </>
      }
      supporting={
        user.email && isValidEmail(user.email) ? user.email : undefined
      }
    />,
  ];
  if (user.time_zone && getLocalTime(user.time_zone)) {
    details.push(
      <Item
        icon={ClockLine}
        primary={i18n.t('profile_current_time')}
        secondary={getLocalTime(user.time_zone)}
        supporting={
          user.email !== currentAccount?.email
            ? calculateTimeDifference(user.time_zone)
            : ''
        }
      />,
    );
  }
  if (user.upcoming_pto && isMoreThanToday(user.upcoming_pto)) {
    details.push(
      <Item
        icon={EmojiSunglassesLine}
        primary={i18n.t('profile_pto')}
        secondary={
          startDate === endDate ? startDate : `${startDate} - ${endDate}`
        }
      />,
    );
  }

  if (user.location) {
    details.push(
      <Item
        icon={LocationLine}
        primary={i18n.t('location')}
        secondary={formatLocation(user.city) || toMetro(user.location)}
      />,
    );
  }

  if (user?.hire_date && user?.tenure != null) {
    details.push(
      <Item
        icon={TeamLine}
        primary={i18n.t('at_company')}
        secondary={calculateTenure(user.tenure)}
        supporting={
          (isBoomerang(user.tenure, user.hire_date)
            ? i18n.t('first_started')
            : i18n.t('started')) +
          ' ' +
          formatHireDate(user.hire_date)
        }
        withTooltip={
          user.tenure_percentile
            ? i18n.t('tenure_percentile', {
                percentile: calculateTenurePercentile(user.tenure_percentile),
              })
            : undefined
        }
      />,
    );
  }

  if (user.birthday) {
    details.push(
      <Item
        icon={CelebrateLine}
        primary={i18n.t('birthday')}
        secondary={formatBirthday(user.birthday)}
      />,
    );
  }

  return (
    <div className={styles.container}>
      {details.map((detail, index) => (
        <Fragment key={index}>{detail}</Fragment>
      ))}
    </div>
  );
};

interface ItemProps {
  icon: React.ComponentType<React.SVGAttributes<SVGElement>>;
  primary: string;
  secondary?: string | React.ReactNode;
  supporting?: string | React.ReactNode;
  withTooltip?: string;
}

const Item: FunctionComponent<ItemProps> = ({
  icon,
  primary,
  secondary,
  supporting,
  withTooltip,
}) => {
  return (
    <Box
      className={styles.item}
      padding="12"
      style={{ width: '252px', height: '72px' }}
    >
      <Box as={'div'}>
        <UserProfileCircleIcon src={icon} />
      </Box>

      <div className={styles.details}>
        <Box as={Text} isBold variant="label">
          {primary}
        </Box>
        {secondary && withTooltip && (
          <DigTooltip title={withTooltip} placement="bottom">
            <Box
              as={Text}
              variant="label"
              size="small"
              display={'block'}
              color="Text Subtle"
            >
              {secondary}
            </Box>
          </DigTooltip>
        )}
        {secondary && !withTooltip && (
          <Box
            as={Text}
            variant="label"
            size="small"
            display={'block'}
            color="Text Subtle"
          >
            {secondary}
          </Box>
        )}
        {supporting && (
          <Box
            as={Text}
            variant="label"
            size="xsmall"
            display={'block'}
            color="Text Subtle"
            marginTop="4"
          >
            {supporting}
          </Box>
        )}
      </div>
    </Box>
  );
};
