import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { omit } from 'lodash';

import type {
  DraftStackComment,
  StackComment,
  StackItemCommentRecord,
  StackItemIdentifier,
} from '../types';
import type { WritableAtom } from 'jotai';

// Store separate atom state for persisted and draft comments
export const stackItemCommentAtom =
  atom<StackItemCommentRecord<StackComment>>();
export const stackItemDraftCommentAtom =
  atom<StackItemCommentRecord<DraftStackComment>>();

// Atom family for persisted comments associated with Stack items
export const stackItemCommentAtomFamily = atomFamily(
  (stackItemId: string) =>
    atom(
      (get) => get(stackItemCommentAtom)?.[stackItemId],
      (get, set, args: StackComment | undefined) => {
        const prevComments = get(stackItemCommentAtom);

        if (args === undefined) {
          set(stackItemCommentAtom, {
            ...omit(prevComments, stackItemId),
          });
        } else {
          set(stackItemCommentAtom, {
            ...prevComments,
            [stackItemId]: args,
          });
        }
      },
    ),
  (a, b) => a === b,
);

// Atom family for draft comments associated with Stack items
export const stackItemDraftCommentAtomFamily = atomFamily(
  (stackItemId: string) =>
    atom(
      (get) => get(stackItemDraftCommentAtom)?.[stackItemId],
      (get, set, args: DraftStackComment | undefined) => {
        const prevComments = get(stackItemDraftCommentAtom);

        if (args === undefined) {
          set(stackItemDraftCommentAtom, {
            ...omit(prevComments, stackItemId),
          });
        } else {
          set(stackItemDraftCommentAtom, {
            ...prevComments,
            [stackItemId]: args,
          });
        }
      },
    ),
  (a, b) => a === b,
);

// Flag value based on user metadata to determine if the user should see the one-time comment prompt.
export const _shouldShowStackCommentPromptAtom = atom<boolean | null>(null);
// These are broken out for performance benefits in situations where only the setter is needed
export const shouldShowStackCommentPromptAtom = atom((get) =>
  get(_shouldShowStackCommentPromptAtom),
);
export const setShouldShowStackCommentPromptAtom: WritableAtom<
  false,
  [boolean],
  void
> = atom(false, (_, set, flagVal) => {
  set(_shouldShowStackCommentPromptAtom, flagVal);
  // Intentional safety check to clear the active comment prompt if the flag is set to false
  set(_activeStackItemCommentPromptAtom, null);
});

// Atom for storing the Stack Item ID that the comment prompt should be displayed for
export const _activeStackItemCommentPromptAtom =
  atom<StackItemIdentifier | null>(null);
export const activeStackItemCommentPromptAtom = atom((get) =>
  get(_activeStackItemCommentPromptAtom),
);
export const setActiveStackItemCommentPromptAtom: WritableAtom<
  null,
  [string],
  void
> = atom(null, (get, set, stackItemId) => {
  const shouldShowPrompt = get(shouldShowStackCommentPromptAtom);

  // Let the setter handler whether or not we can should show the prompt
  if (shouldShowPrompt) {
    set(_activeStackItemCommentPromptAtom, stackItemId);
  }
});
