import { Button } from '@dropbox/dig-components/buttons';
import styles from './DashButton.module.css';

import type { ButtonProps } from '@dropbox/dig-components/buttons';

export const DashButton = ({
  children,
  ...rest
}: Omit<ButtonProps, 'variant'>) => (
  <Button className={styles.button} variant="primary" {...rest}>
    {children}
  </Button>
);
export const OpacityDashButton = ({
  children,
  ...rest
}: Omit<ButtonProps, 'variant'>) => (
  <Button className={styles.opacityButton} variant="opacity" {...rest}>
    {children}
  </Button>
);
