import { createContext, useContext, useEffect, useRef } from 'react';
import { noopFLIPOrchestrator } from './internal/NoopFLIPOrchestrator';
import { ResizeFLIPOrchestrator } from './internal/ResizeFLIPOrchestrator';

import type { FLIPOrchestrator } from './internal/FLIPOrchestrator';

const FLIP_TRANSITION_DURATION_MS = 300;

const FLIPContext = createContext<FLIPOrchestrator | null>(null);

/**
 * An optional multiplier for the animation's duration for debugging that changes the speed of
 * animations.
 *
 * Prefer this to increasing the duration since increasing the duration will create more frames
 * and is computationally expensive. Has no effect unless in a dev environment.
 */
export const FLIPDebugPlaybackRateContext = createContext(1);

export const useFLIPOrchestrator = () => {
  const maybeOrchestrator = useContext(FLIPContext);

  if (!maybeOrchestrator) {
    throw new Error(
      'Must set a FLIP orchestrator above components that use any `useFLIP` hook',
    );
  }

  return maybeOrchestrator;
};

/**
 * Provides an orchestrator for FLIP animations of its descendents.
 *
 * Try to wrap orchestrators narrowly around only sets of components that will actively affect one
 * another's layout.
 */
export const FLIPOrchestratorProvider: React.FC = ({ children }) => {
  const debugPlaybackRate = useContext(FLIPDebugPlaybackRateContext);
  const orchestrator = useRef<FLIPOrchestrator>(noopFLIPOrchestrator);

  useEffect(() => {
    let canceled = false;

    // Init the orchestrator after a small delay to avoid moving the page
    // during initial load.
    setTimeout(() => {
      if (!canceled) {
        orchestrator.current = new ResizeFLIPOrchestrator(
          FLIP_TRANSITION_DURATION_MS,
          'ease-in-out',
          debugPlaybackRate,
        );
      }
    }, FLIP_TRANSITION_DURATION_MS);

    return () => {
      canceled = true;
      orchestrator.current?.dispose();
      orchestrator.current = noopFLIPOrchestrator;
    };
  }, [debugPlaybackRate]);

  return (
    <FLIPContext.Provider value={orchestrator.current}>
      {children}
    </FLIPContext.Provider>
  );
};
