import { Avatar } from '@dropbox/dig-components/avatar';
import { Badge } from '@dropbox/dig-components/badge';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AdminConsoleLine,
  AnalyticsLine,
  CollectionLine,
  ComputerLine,
  DownloadLine,
  FlagLine,
  HelpLine,
  MobileLine,
  SettingsLine,
  SignOutLine,
  WarningLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashAvatarOption } from '@mirage/analytics/events/types/click_dash_avatar_option';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { AvatarDropdown } from '@mirage/search/General/Avatar';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { openURL } from '@mirage/service-platform-actions';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import { getAbbreviatedFullName, getInitials } from '@mirage/shared/account';
import { openManageCookiesPanel } from '@mirage/shared/privacy';
import {
  DESKTOP_DOWNLOAD_URL,
  HELP_CENTER,
  PRIVACY_AND_LEGAL,
} from '@mirage/shared/urls';
import { useModal } from '@mirage/shared/util/showModal';
import {
  logoForCurrentBrowser,
  openExtensionLinkForCurrentBrowser,
} from '@mirage/shared/util/webExtensionsHelpers';
import i18n, {
  i18nUnlocalizableStringClass,
  meticulousRedactedStringClass,
} from '@mirage/translations';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { MobileDownloadModal } from '../MobileDownloadModal/MobileDownloadModal';
import { isMobileAppEnabledForCountry } from '../MobileDownloadModal/util';
import { navigationAvatarAtom } from './atoms';
import styles from './NavigationAvatar.module.css';

import type { OverlayPlacement } from '@dropbox/dig-components/overlay';
import type { DropdownItem } from '@mirage/search/General/Dropdown';
import type { ReactElement } from 'react';

type Props = {
  anchor: ReactElement;
  menuPlacement?: OverlayPlacement;
  hasAdminConsoleWarning: boolean;
  ariaRole?: string;
  onAdminConsole?: () => void;
  onSettings?: () => void;
  onFeatures?: () => void;
  onDeveloper?: () => void;
  onSignOut?: () => void;
  onPublishedContent?: () => void;
};

type FooterProps = {
  showCookieBanner: boolean;
};

const ConfigFooter = ({ showCookieBanner }: FooterProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handlePrivacyPolicyClick = () => {
    reportPapEvent(
      PAP_Click_DashAvatarOption({
        dashAvatarOptionType: 'privacy_policy',
        featureLine: 'global_nav',
      }),
    );
    openURL(PRIVACY_AND_LEGAL);
  };

  const handleCookieClick = () => {
    reportPapEvent(
      PAP_Click_DashAvatarOption({
        dashAvatarOptionType: 'cookies',
        featureLine: 'global_nav',
      }),
    );
    openManageCookiesPanel();
  };
  return (
    <div className={styles.navigationAvatarFooter}>
      <Text
        className={styles.footerLink}
        size="xsmall"
        onClick={handlePrivacyPolicyClick}
        data-uxa-log={createUxaElementId('privacy_policy_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        })}
      >
        {i18n.t('settings_privacy_policy')}
      </Text>
      {showCookieBanner && (
        <>
          <Text size="xsmall" className={i18nUnlocalizableStringClass}>
            •
          </Text>
          <Text
            className={styles.footerLink}
            size="xsmall"
            onClick={handleCookieClick}
            data-uxa-log={createUxaElementId('manage_cookies_button', {
              actionSurfaceComponent: 'avatar_dropdown',
            })}
          >
            {i18n.t('manage_cookies')}
          </Text>
        </>
      )}
    </div>
  );
};

export default function NavigationAvatar({
  anchor,
  menuPlacement = 'bottom-end',
  hasAdminConsoleWarning,
  onAdminConsole,
  onSettings,
  onFeatures,
  onDeveloper,
  onSignOut,
  onPublishedContent,
  ariaRole,
}: Props) {
  const account = useDropboxAccount();
  const {
    showSettings,
    showLogout,
    showCookieBanner,
    showDownloadDesktop,
    showDownloadExtension,
    showDownloadMobile,
  } = useAtomValue(navigationAvatarAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { extensionAvailableForBrowser } = useAvailableExtensions();
  const { showModal, hideModal } = useModal();
  const menuSections: DropdownItem[][] = [];
  const isTeamAdmin =
    onAdminConsole && account?.team?.team_user_permissions?.is_admin;
  const displayName =
    account?.name.display_name ||
    `${account?.name.given_name ?? ''} ${account?.name.surname ?? ''}`.trim();

  if (account) {
    menuSections.push([
      {
        key: 'avatar-profile',
        title: (
          <div
            className={classNames(
              styles.userNameRow,
              meticulousRedactedStringClass,
            )}
          >
            {getAbbreviatedFullName(displayName)}
            {isTeamAdmin && <Badge>{i18n.t('admin')}</Badge>}
          </div>
        ),
        subtitle: (
          <span className={meticulousRedactedStringClass}>
            {account?.email ?? ''}
          </span>
        ),
        leftAccessory: (
          <Avatar src={account?.profile_photo_url} hasNoOutline={true}>
            {getInitials(displayName)}
          </Avatar>
        ),
      },
    ]);
  }

  const mainSection: DropdownItem[] = [];

  if (isTeamAdmin) {
    mainSection.push({
      key: 'admin-console',
      title: i18n.t('admin_console'),
      leftAccessory: <UIIcon src={AdminConsoleLine} />,
      rightAccessory: hasAdminConsoleWarning ? (
        <UIIcon src={WarningLine} color="var(--dig-color__alert__base)" />
      ) : null,
      onClick: () => onAdminConsole(),
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('admin_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (onSettings && showSettings) {
    mainSection.push({
      key: 'settings',
      title: i18n.t('open_settings'),
      leftAccessory: <UIIcon src={SettingsLine} />,
      onClick: () => onSettings(),
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('settings_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (onFeatures) {
    mainSection.push({
      key: 'features',
      title: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          {i18n.t('features')}
        </span>
      ),
      leftAccessory: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          <UIIcon src={FlagLine} />
        </span>
      ),
      onClick: () => onFeatures(),
    });
  }

  if (onDeveloper) {
    mainSection.push({
      key: 'developer',
      title: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          {i18n.t('developer')}
        </span>
      ),
      leftAccessory: (
        <span style={{ color: 'var(--dig-color__primary__base)' }}>
          <UIIcon src={AnalyticsLine} />
        </span>
      ),
      onClick: () => onDeveloper(),
    });
  }

  if (onPublishedContent) {
    mainSection.push({
      key: 'published-content',
      title: i18n.t('published_content_title'),
      leftAccessory: <UIIcon src={CollectionLine} />,
      onClick: () => onPublishedContent(),
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('published_content_admin_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  menuSections.push(mainSection);

  const secondaryActions: Array<DropdownItem> = [];

  const downloadItems = [];

  if (showDownloadDesktop) {
    downloadItems.push({
      key: 'download_desktop',
      title: i18n.t('navatar_desktop_app'),
      leftAccessory: <UIIcon src={ComputerLine} />,
      onClick: () => {
        reportPapEvent(
          PAP_Click_DashAvatarOption({
            dashAvatarOptionType: 'download_desktop',
            featureLine: 'global_nav',
          }),
        );
        openURL(DESKTOP_DOWNLOAD_URL);
      },
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('download_desktop_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (showDownloadExtension && extensionAvailableForBrowser) {
    const icon = logoForCurrentBrowser(false);
    downloadItems.push({
      key: 'browser_extension',
      title: i18n.t('tab_manager_browser_extension'),
      leftAccessory: <UIIcon src={icon} />,
      onClick: () => {
        reportPapEvent(
          PAP_Click_DashAvatarOption({
            dashAvatarOptionType: 'download_extension',
            featureLine: 'global_nav',
          }),
        );
        openExtensionLinkForCurrentBrowser(openURL);
      },
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('download_extension_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (showDownloadMobile && isMobileAppEnabledForCountry(account?.country)) {
    downloadItems.push({
      key: 'download_mobile',
      title: i18n.t('navatar_mobile_app'),
      leftAccessory: <UIIcon src={MobileLine} />,
      onClick: () => {
        reportPapEvent(
          PAP_Click_DashAvatarOption({
            dashAvatarOptionType: 'download_mobile',
            featureLine: 'global_nav',
          }),
        );
        showModal(<MobileDownloadModal onClose={hideModal} />);
      },
      analyticsAttr: {
        'data-uxa-log': createUxaElementId('download_mobile_button', {
          actionSurfaceComponent: 'avatar_dropdown',
        }),
      },
    });
  }

  if (downloadItems.length) {
    // TODO: Make an option to hide parts of this
    secondaryActions.push({
      key: 'download',
      title: i18n.t('download'),
      leftAccessory: <UIIcon src={DownloadLine} />,
      subItems: downloadItems,
    });
  }

  secondaryActions.push({
    key: 'help-center',
    title: i18n.t('settings_visit_help_center'),
    leftAccessory: <UIIcon src={HelpLine} />,
    onClick: () => {
      reportPapEvent(
        PAP_Click_DashAvatarOption({
          dashAvatarOptionType: 'help_center',
          featureLine: 'global_nav',
        }),
      );
      openURL(HELP_CENTER);
    },
    analyticsAttr: {
      'data-uxa-log': createUxaElementId('help_center_button', {
        actionSurfaceComponent: 'avatar_dropdown',
      }),
    },
  });

  menuSections.push(secondaryActions);

  let menuActions: Array<DropdownItem> = [];

  if (onSignOut && showLogout) {
    menuActions = [
      ...menuActions,
      {
        key: 'logout',
        title: i18n.t('logout'),
        leftAccessory: <UIIcon src={SignOutLine} />,
        onClick: () => {
          reportPapEvent(
            PAP_Click_DashAvatarOption({
              dashAvatarOptionType: 'log_out',
              featureLine: 'global_nav',
            }),
          );
          onSignOut();
        },
        analyticsAttr: {
          'data-uxa-log': createUxaElementId('logout_button', {
            actionSurfaceComponent: 'avatar_dropdown',
          }),
        },
      },
    ];
  }

  menuSections.push(menuActions);

  return (
    <AvatarDropdown
      anchor={anchor}
      config={{
        sections: menuSections,
        footer: <ConfigFooter showCookieBanner={showCookieBanner} />,
      }}
      placement={menuPlacement}
      ariaRole={ariaRole}
    />
  );
}
