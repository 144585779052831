import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_StacksName } from '@mirage/analytics/events/types/cancel_stacks_name';
import { PAP_Click_StacksName } from '@mirage/analytics/events/types/click_stacks_name';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { dispatchElementClicked } from '@mirage/analytics/uxa/dispatchElementClicked';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { useStackChat } from '@mirage/service-experimentation/useStackChat';
import { MAX_STACK_NAME_LENGTH } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useRef } from 'react';
import { StackDetailsTourTooltip } from '../../StackDetailsTourTooltip';
import styles from '../EditStackDetails.module.css';
import { useUpdateStackNameAndDescription } from '../hooks/useUpdateStackNameAndDescription';
import { FieldEditor } from './FieldEditor';

import type { StackDerivedPAPPropsWithFeature } from '../../types';
import type { FieldEditorRef } from './FieldEditor';
import type { RefObject } from 'react';

interface StackNameEditorProps {
  stackName: string;
  isNewStack: boolean;
  updateStackNameAndDescription: (name: string, description: string) => void;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  fieldEditorRef?: RefObject<FieldEditorRef>;
  enableEditMode?: boolean;
  hasWritePermissionsOverride?: boolean;
  onEditStatusUpdate?: (status: boolean) => void;
}

export const StackNameEditor: React.FC<StackNameEditorProps> = ({
  stackName,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  fieldEditorRef,
  enableEditMode,
  hasWritePermissionsOverride,
  onEditStatusUpdate,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isAugustRev = useStackPageAugustRevisionEnabled();
  const isStackChatEnabled = useStackChat();
  const fieldInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = useUpdateStackNameAndDescription(
    isNewStack,
    updateStackNameAndDescription,
  );

  return (
    <>
      <FieldEditor
        fieldName={stackName}
        fieldEditorRef={fieldEditorRef}
        fieldInputRef={fieldInputRef}
        mode={'name'}
        rows={isAugustRev ? 1 : undefined}
        focused={isAugustRev && (enableEditMode || isNewStack)}
        enableEditMode={enableEditMode || isNewStack}
        hasWritePermissionsOverride={hasWritePermissionsOverride}
        submitFieldChange={handleSubmit}
        placeholder={i18n.t('stack_title_placeholder')}
        resizable="auto"
        maxLength={MAX_STACK_NAME_LENGTH}
        onEditStatusUpdate={onEditStatusUpdate}
        onFocused={() => {
          reportPapEvent(PAP_Click_StacksName(stackDerivedPAPPropsWithFeature));
          dispatchElementClicked(
            createUxaElementId('stack_title', {
              featureLine: 'stacks',
            }),
          );
        }}
        onCancel={() => {
          reportPapEvent(
            PAP_Cancel_StacksName({
              featureLine: 'stacks',
              ...stackDerivedPAPPropsWithFeature,
            }),
          );
        }}
        additionalStyles={classnames(styles.stackNameInput, {
          [styles.stackNameInputAugRev]: isAugustRev,
          [styles.stackNameInputChat]: isStackChatEnabled,
        })}
        dataTestId={'textFill'}
      />
      {isAugustRev && (enableEditMode || isNewStack) && (
        <StackDetailsTourTooltip
          stepKey="name-stack"
          triggerRef={fieldInputRef}
        />
      )}
    </>
  );
};
