import { useMultimediaPreview } from '@mirage/mosaics/SearchPage/Multimedia/hooks/useMultimediaPreview';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { SearchResultIcon } from '@mirage/shared/icons';
import EventIcon from '@mirage/shared/icons/EventIcon';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { RESULT_BADGE_ICON_SIZE } from '@mirage/shared/icons/util';
import { utcToZonedTime } from 'date-fns-tz';
import { PersonIcon } from './PersonIcon';

import type { ContentIconProps } from '@dropbox/dash-component-library';
import type { SearchResult } from '@mirage/service-dbx-api';

type Props = {
  result: SearchResult;
  size?: ContentIconProps['size'];
};

export default function ResultIcon({ result, size = 'medium' }: Props) {
  const shouldFetchMultimediaResults = useFeatureFlagValue(
    'dash_2025_03_13_multimedia_search',
  );
  const { base64Image, base64Loading, hasError } = useMultimediaPreview(
    shouldFetchMultimediaResults ? result.previewUrl || undefined : undefined,
    512,
  );

  const isEvent = result?.recordType?.['.tag'] === 'event' && result?.startTime;

  if (isEvent) {
    let eventDate = new Date(result.startTime as number); // Cannot be null if isEvent is true
    if (result?.isAllDay) {
      // All-day events are represented as UTC, so we need to convert to local time
      // TODO: Temporary fix until https://jira.dropboxer.net/browse/OTCIP-46 is
      // resolved and the API provides just the date for these all-day events.
      eventDate = utcToZonedTime(eventDate, 'UTC');
    }
    // checking if the date is valid here prevents EventIcon from throwing on invalid dates
    if (eventDate instanceof Date && !isNaN(eventDate.getTime())) {
      return (
        <EventIcon
          size={size}
          eventDate={eventDate}
          sourceIcon={
            <SearchResultIcon result={result} size={RESULT_BADGE_ICON_SIZE} />
          }
        />
      );
    }
  }

  const sender = result?.sender || result?.messages?.[0]?.sender;
  if (
    (result.fileTypeInfo?.id === 'email' ||
      result.fileTypeInfo?.id === 'chat') &&
    sender
  ) {
    return <PersonIcon result={result} person={sender} size={size} />;
  }

  const thumbnailUrl =
    shouldFetchMultimediaResults === 'V1' &&
    base64Image &&
    !base64Loading &&
    !hasError
      ? base64Image
      : undefined;

  return (
    <FileContentIcon
      thumbnailUrl={
        shouldFetchMultimediaResults === 'V1' ? thumbnailUrl : undefined
      }
      content={result}
      size={size}
    />
  );
}
