import * as React from 'react';
import BaseListItem from './BaseListItem';
import styles from './ButtonListItem.module.css';

import type { TextColorType } from '@dropbox/dig-components/typography';

export type ButtonProps = Readonly<{
  /** left hand side icon */
  icon?: React.ReactNode;
  textColor?: TextColorType | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  /** default title */
  title: React.ReactNode;
  /** optional a11y parameters */
  role?: string;
  ariaLabel?: string;
  subtitle?: React.ReactNode;
  tooltipTitle?: string;
}>;

export default function ButtonListItem(props: ButtonProps) {
  const {
    icon,
    textColor = 'standard',
    onClick,
    title = '',
    role,
    ariaLabel,
    subtitle,
    tooltipTitle,
  } = props;

  return (
    <BaseListItem
      className={styles.buttonListItem}
      icon={icon}
      textColor={textColor}
      onClick={onClick}
      title={title}
      subtitle={subtitle}
      role={role || 'button'}
      ariaLabel={ariaLabel}
      tooltipTitle={tooltipTitle}
    />
  );
}
