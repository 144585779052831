import {
  useAllStacksAugustRevisionEnabled,
  useIsStartPageAugustRevisionEnabled,
  useStackPageAugustRevisionEnabled,
} from '@mirage/august-revision-hook';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { usePageScrollListener } from '@mirage/shared/hooks/usePageScrollListener';
import { activeStackAtom } from '@mirage/stacks/ActiveStack/atoms';
import { useCurrentBackgroundTheme } from '@mirage/stacks/themes';
import { useAtomValue } from 'jotai';

import type { OmniboxProps } from '../Omnibox';

export function useSharedOmniboxProps(): Pick<
  OmniboxProps,
  | 'pageScrolled'
  | 'augustRevisionEnabled'
  | 'fullWidth'
  | 'showBackground'
  | 'shouldFocusOnRender'
> {
  const desktopExperience = EnvCtx.surface === 'desktop';

  const { pageScrolled } = usePageScrollListener();
  const stackFromFullStackPage = useAtomValue(activeStackAtom);
  const backgroundTheme = useCurrentBackgroundTheme(stackFromFullStackPage);
  const isAugustStackPageEnabled = useStackPageAugustRevisionEnabled();
  const isAugustStartPageEnabled = useIsStartPageAugustRevisionEnabled();
  const isAugustAllStacksPageEnabled = useAllStacksAugustRevisionEnabled();

  const augustRevisionEnabled =
    isAugustStackPageEnabled ||
    isAugustStartPageEnabled ||
    isAugustAllStacksPageEnabled;

  return {
    pageScrolled,
    augustRevisionEnabled,
    fullWidth: desktopExperience,
    showBackground: isAugustStackPageEnabled && backgroundTheme !== undefined,
    shouldFocusOnRender: true,
  };
}
