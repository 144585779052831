import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { activeStackAtom, activeStackItemsAtom } from './atoms';

import type { stacks } from '@dropbox/api-v2-client';
import type { StackItem } from '@mirage/service-stacks/service/types';

/**
 * Initialized and sync the active stack and stack items atoms based
 * on the provided stack and stack items. The atoms themselves handle
 * the memoization. Must be called ONCE somewhere in your component tree
 * in order to use active stack atoms, preferably as high up in the tree
 * as possible.
 * @param stack
 * @param stackItems
 */
export const useSetActiveStack = (
  stack: stacks.Stack | null,
  stackItems: StackItem[] | null,
) => {
  const setActiveStack = useSetAtom(activeStackAtom);
  const setActiveStackItems = useSetAtom(activeStackItemsAtom);

  useEffect(() => {
    setActiveStack(stack);

    return () => {
      setActiveStack(null);
    };
  }, [stack, setActiveStack]);

  useEffect(() => {
    setActiveStackItems(stackItems);

    return () => {
      setActiveStackItems(null);
    };
  }, [stackItems, setActiveStackItems]);
};
