import { SEARCH_QUERY_PARAM } from '@mirage/search/hooks/useQueryParams';
import { useCallback } from 'react';

export function useSearchUrlManagement() {
  const updateSearchUrlSilently = useCallback((newQuery: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(SEARCH_QUERY_PARAM, newQuery);

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }, []);

  return { updateSearchUrlSilently };
}
