import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { syncQueryWithFilters } from '@mirage/shared/search/search-filters';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchUrlManagement } from './hooks/useSearchURLManagement';
import { useSpellCorrectionAnalytics } from './hooks/useSpellCorrectionAnalytics';
import SearchFilters from './SearchFilters';
import styles from './SearchPageHeader.module.css';
import { SearchSpellingSuggestion } from './SearchSpellingSuggestion';

import type { Connectors } from '@mirage/service-connectors/service';
import type { Connector as BrowserHistoryConnector } from '@mirage/service-dbx-api';
import type { SearchOptions } from '@mirage/service-search/service';
import type { SearchFilter } from '@mirage/shared/search/search-filters';
import type { SearchResultAnnotations } from '@mirage/shared/search/search-result';
import type { Dispatch, SetStateAction } from 'react';

export type Props = {
  isDesktop?: boolean;
  typeaheadQuery: string;
  setTypeaheadQuery: Dispatch<SetStateAction<string>>;
  handleSearch: (
    query: string,
    filters: SearchFilter[],
    options?: Partial<SearchOptions>,
  ) => Promise<void>;
  annotations?: SearchResultAnnotations;
  connectors: Connectors;
  browserHistoryConnectors: BrowserHistoryConnector[];
  filters: SearchFilter[];
  handleFilterChange: (filters: SearchFilter[]) => void;
};

export const syncTypeaheadQuery = (
  annotations: SearchResultAnnotations,
  filters: SearchFilter[],
  isUsingTypeaheadFiltersFeatureFlag: boolean,
) => {
  return isUsingTypeaheadFiltersFeatureFlag
    ? syncQueryWithFilters(annotations!.originalQuery!, filters)
    : annotations!.originalQuery!;
};

export function SearchPageHeader({
  isDesktop,
  typeaheadQuery,
  setTypeaheadQuery,
  handleSearch,
  annotations,
  connectors,
  browserHistoryConnectors,
  filters,
  handleFilterChange,
}: Props) {
  const isUsingTypeaheadFiltersFeatureFlag =
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters') === 'ON';
  const isUsingSpellCorrectFeatureFlag =
    useFeatureFlagValue('dash_2025_01_31_spell_correct') === 'ON';
  const { reportSelectEvent, reportShownEvent } =
    useSpellCorrectionAnalytics(filters);
  const { updateSearchUrlSilently } = useSearchUrlManagement();

  const [isSuggestionResolved, setIsSuggestionResolved] = useState(false);

  const shouldRenderSuggestion =
    isUsingSpellCorrectFeatureFlag &&
    !isSuggestionResolved &&
    !!annotations?.correctedQuery &&
    !!annotations?.originalQuery &&
    !!annotations.tokens;

  const handleCorrectOriginalQuery = useCallback(() => {
    setTypeaheadQuery(annotations!.correctedQuery!);
    updateSearchUrlSilently(annotations!.correctedQuery!);
    reportSelectEvent(annotations!.originalQuery!, 'corrected');
    setIsSuggestionResolved(true);
  }, [annotations, setTypeaheadQuery, reportSelectEvent]);

  const handleSearchOriginalQuery = useCallback(() => {
    const syncedTypeaheadQuery = syncTypeaheadQuery(
      annotations!,
      filters,
      isUsingTypeaheadFiltersFeatureFlag,
    );
    reportSelectEvent(annotations!.originalQuery!, 'ignored');
    handleSearch(syncedTypeaheadQuery, filters, {
      disableSpellCorrection: true,
    });
    setIsSuggestionResolved(true);
  }, [
    annotations,
    filters,
    isUsingTypeaheadFiltersFeatureFlag,
    handleSearch,
    reportSelectEvent,
  ]);

  // Utilizing ref to support React 18 compiler migration
  // We use this to track whether or not suggestions are resolved
  const lastCorrectedQuery = useRef<string | undefined>(undefined);
  const lastSearchQuery = useRef<string | undefined>(undefined);
  useEffect(() => {
    const requiresCorrectedQueryUpdate =
      annotations?.correctedQuery &&
      isUsingSpellCorrectFeatureFlag &&
      lastCorrectedQuery.current !== annotations?.correctedQuery;

    const requiresSearchQueryUpdate =
      isUsingSpellCorrectFeatureFlag &&
      lastSearchQuery.current !== typeaheadQuery;

    if (requiresCorrectedQueryUpdate || requiresSearchQueryUpdate) {
      setIsSuggestionResolved(false);
    }

    if (requiresCorrectedQueryUpdate) {
      lastCorrectedQuery.current = annotations!.correctedQuery;
    }

    if (requiresSearchQueryUpdate) {
      lastSearchQuery.current = typeaheadQuery;
    }
  }, [
    annotations,
    reportShownEvent,
    typeaheadQuery,
    isUsingSpellCorrectFeatureFlag,
  ]);

  useEffect(() => {
    const handlePopState = () => {
      setIsSuggestionResolved(false);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [annotations]);

  // Utilizing ref to support React 18 compiler migration
  // We want to ensure that the shown event is only reported when the suggestion is visible
  // and not reported multiple times across multiple renders
  const lastIsVisibleRef = useRef<boolean | undefined>(undefined);
  useEffect(() => {
    if (shouldRenderSuggestion && lastIsVisibleRef.current !== true) {
      reportShownEvent(annotations!.originalQuery!);
      lastIsVisibleRef.current = true;
    } else if (!shouldRenderSuggestion) {
      lastIsVisibleRef.current = false;
    }
  }, [reportShownEvent, shouldRenderSuggestion, annotations]);

  return (
    <div
      className={classNames({
        [styles.filtersContainerFullWidth]: isDesktop,
      })}
    >
      <SearchFilters
        connectors={connectors}
        browserHistoryConnectors={browserHistoryConnectors}
        activeFilters={filters}
        onFilterChange={handleFilterChange}
      />

      {shouldRenderSuggestion && (
        <SearchSpellingSuggestion
          tokens={annotations.tokens!}
          correctedQuery={annotations.correctedQuery!}
          originalQuery={annotations.originalQuery!}
          onClickOriginalQuery={handleSearchOriginalQuery}
          onClickCorrectedQuery={handleCorrectOriginalQuery}
        />
      )}
    </div>
  );
}
