import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';

// As per Dropbox's security policy, do NOT add emails as an attribute
export type SurveyCoreAttributes = {
  dashSurface?: DashSurface;
  isInternal?: boolean;
  publicUserId?: string;
};

// Additional attributes that can be sent to Sprig
// Populated from getSprigAttributesList Apiv2 endpoint
export type SurveyAttributes = SurveyCoreAttributes & {
  [key: string]: string | number | boolean;
};

export const enum SurveyEvent {
  // General
  AppOpened = 'dash_open_app',
  DebugTriggered = 'dash_debug_trigger',
  CoreUserJourneyAction = 'dash_core_user_journey_action',

  // Start Page
  OpenStartPage = 'dash_open_start_page',
  LaunchStartPageRecentItem = 'dash_start_page_recent_item_launch',
  LaunchStartPageCalendarItem = 'dash_start_page_calendar_item_launch',
  LaunchStartPageActivityItem = 'dash_start_page_activity_item_launch',

  // Search
  CompleteSearchQuery = 'dash_search_complete',
  ApplySearchFilter = 'dash_search_filter_click',
  SummarizeSearchResult = 'dash_search_summarize_ask_click',

  // Stacks
  OpenStackPage = 'dash_stacks_page_open',
  AddStackItem = 'dash_stack_item_add',
  DeleteStackItem = 'dash_stack_item_delete',
  LaunchStackItem = 'dash_stack_item_launch',
  ShareStack = 'dash_stack_share',
  CopyStackLink = 'dash_stack_copy_link',

  // Assist
  SendAssistMessage = 'dash_assist_message_send',
}

export type SprigPublishEvent = {
  event: SurveyEvent;
  properties: SurveyAttributes;
};
