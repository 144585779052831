import {
  type ConnectorFilter,
  type ContentTypeFilter,
  type LastUpdatedFilter,
  type PersonFilter,
  type SearchFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import { formatSalesforceResult } from './formatSalesforceResult';
import { formatSlackResult } from './formatSlackResult';

import type { dash, dcs } from '@dropbox/api-v2-client';
import type {
  AuthorInfo,
  BrandedSiteInfo,
  ConnectorInfo,
  DataSource,
  FileTypeInfo,
  IconResource,
  ItemLocation,
  SearchResult,
} from '@mirage/service-dbx-api/service/search';
import type { PossibleContentTypes } from '@mirage/shared/content-type/content-types';
import type {
  Channel,
  CuratorUser,
  DashMedia,
  Message,
  Modifier,
} from '@mirage/shared/search/search-result';

export const buildCuratorUser = (
  entity: dash.CuratorUser | undefined,
): CuratorUser | null => {
  if (!entity) return null;

  const {
    display_name: displayName,
    profile_image_url: profilePhotoUrl,
    email,
  } = entity;
  return {
    email,
    displayName,
    profilePhotoUrl,
  };
};

export const buildIconResource = (
  entity: dash.IconResource | undefined,
): IconResource | null => {
  if (!entity) return null;

  const { light_url: lightUrl, dark_url: darkUrl } = entity;

  return { lightUrl, darkUrl };
};

export const buildItemLocation = (
  item: dash.ItemLocation | undefined,
): ItemLocation | null => {
  if (!item) return null;

  const { display_name: displayName, url } = item;
  return { displayName, url };
};

export const buildAuthorInfo = (
  author: dash.Author | undefined,
): AuthorInfo | null => {
  if (!author) return null;

  const {
    display_name: displayName,
    email,
    profile_image_url: profilePhotoUrl,
    alias_handle: aliasHandle,
    profile_link: profileLink,
  } = author;
  return { displayName, email, profilePhotoUrl, aliasHandle, profileLink };
};

export const buildFileTypeInfo = (
  entity: dash.FileTypeInfo | undefined,
): FileTypeInfo | undefined => {
  if (!entity) return undefined;

  const { id, display_name: displayName } = entity;
  const icon = buildIconResource(entity.icon);

  return { id: id as PossibleContentTypes, displayName, icon };
};

export const buildBrandedSiteInfo = (
  entity: dash.BrandedSiteInfo | undefined,
): BrandedSiteInfo | null => {
  if (!entity) return null;

  const { name } = entity;
  const icon = buildIconResource(entity.icon);

  return { name, icon };
};

export const buildConnectorInfo = (
  connectorInfo?: dash.ConnectorInfo,
  useUndefinedCheck: boolean = false,
): ConnectorInfo | null => {
  // TODO: Might have to loosen these up and provide fallbacks
  if (!connectorInfo) return null;
  if (useUndefinedCheck) {
    if (connectorInfo.connector_id === undefined) return null;
    if (connectorInfo.connector_name === undefined) return null;
    if (connectorInfo.connector_type === undefined) return null;
  } else {
    if (!connectorInfo.connector_id) return null;
    if (!connectorInfo.connector_name) return null;
    if (!connectorInfo.connector_type) return null;
  }

  const {
    connector_id: connectorId,
    connector_type: connectorType,
    connector_name: connectorName,
    display_name: displayName = '',
    connector_icon_url: connectorIconUrl = '',
    platform = null,
  } = connectorInfo;
  const icon = buildIconResource(connectorInfo.icon);

  return {
    connectorId,
    connectorType,
    connectorName,
    displayName,
    connectorIconUrl,
    platform,
    icon,
  };
};

function buildDataSource(
  ds: dash.DataSource | undefined,
): DataSource | undefined {
  if (!ds || !ds.connection_id) return undefined;
  return {
    connectionId: ds.connection_id,
  };
}

// TODO OSE-4166 move this to a more obvious location
const summarizableTags = [
  'yes_summarizable',
  'not_summarizable',
  'not_summarizable_branded_type',
  'not_summarizable_mime_type',
  'not_summarizable_body_missing_empty_file',
  'not_summarizable_body_missing_file_size',
  'not_summarizable_body_missing_unsupported',
  'not_summarizable_body_missing_processing_failure',
  'not_summarizable_body_missing_skipped',
  'not_summarizable_body_missing_other',
  'unknown_summarizable_type',
];

export function buildSummarizable(
  dsummarizable: dash.SummarizableType | undefined,
): dash.SummarizableType['.tag'] | null {
  if (!dsummarizable?.['.tag']) {
    return null;
  }
  if (!summarizableTags.includes(dsummarizable['.tag'])) {
    return null;
  }
  return dsummarizable['.tag'];
}

export const buildChannel = (channel?: dash.Channel): Channel | null => {
  if (!channel?.name) return null;
  const { id, name, url, created_at_ms, members, type } = channel;
  return {
    id,
    name,
    url,
    createdAtMs: created_at_ms,
    members,
    type: type || {
      '.tag': 'channel_type_unset',
    },
  };
};

export const buildMessage = (message?: dash.Message): Message | null => {
  if (!message || !message.body || !message?.sender) return null;
  const { body, sender, created_at_ms, reactions, attachments } = message;
  const senderInfo = buildAuthorInfo(sender);
  if (!senderInfo) return null;
  return {
    body,
    sender: senderInfo,
    createdAtMs: created_at_ms,
    reactions,
    attachments,
  };
};

// single, non-threaded messages treated as a thread with length 1
export const buildMessageThread = (
  messages?: dash.Message[],
): Message[] | null => {
  if (!messages || messages.length === 0) return null;
  return messages
    .map((message: dash.Message) => buildMessage(message))
    .filter((message) => message !== null)
    .sort((a, b) => {
      if (a.createdAtMs && b.createdAtMs) {
        return a.createdAtMs - b.createdAtMs;
      }
      return 0;
    }) as Message[];
};

export const buildMedia = (media?: dash.Media): DashMedia | null => {
  if (!media) return null;
  const {
    preview_url: previewUrl,
    size,
    x_pixels: xPixels,
    y_pixels: yPixels,
    datetime_original_ms: datetimeOriginalMs,
    match_type: matchType,
  } = media;
  return {
    matchType,
    previewUrl,
    xPixels,
    yPixels,
    datetimeOriginalMs,
    size,
  };
};

/**
 * Takes the dash.SearchResult and transforms it into a MirageSearchResult. Nulls out any fields that are missing.
 * and provides reasonable defaults for others. Will likely need to loosen this up as the api is rough rn.
 * @param dashResult
 * @returns MirageSearchResult | null
 */
export const transformDashResultToMirage = (
  dashResult: dash.SearchResult,
  searchRequestId?: string,
  analyticsTraceId?: string,
  isAllModifiersEnabled?: boolean,
): SearchResult | null => {
  // map names, and provide defaults
  const {
    uuid,
    display_icon_override: displayIconOverride = null,
    record_type: recordType,
    upstream_id: upstreamId = null,
    url = null,
    description = null,
    additional_links: additionalLinks = [],
    conference_links: conferenceLinks = [],
    email = null,
    profile_image_url: profileImageUrl = null,
    is_all_day: isAllDay = null,
    location = null,
    relevance_score: relevanceScore = 0,
    branded_type: brandedType = null,
    id_3p: id3p = null,
    source_index_type: sourceIndexType,
    attendees = [],
    curations = [],
    virtual_path: virtualPath = [],
    mime_type: mimeType = null,
    status = null,
    normalized_status: normalizedStatus = null,
    priority = null,
    category = null,
    tag: tags = [],
    reference_id: referenceId = null,
    logical_type: logicalType = null,
    attachments = [],
    preview_url: previewUrl = null,
  } = dashResult;

  const connectorInfo = buildConnectorInfo(dashResult?.connector_info);
  const fileTypeInfo = buildFileTypeInfo(dashResult?.file_type_info);
  const brandedSiteInfo = buildBrandedSiteInfo(dashResult?.branded_site_info);
  const startTime = dashResult.start_time || null;
  const endTime = dashResult.end_time || null;
  const updatedAtMs = dashResult.updated_at_ms || null;
  const providerUpdateAtMs = dashResult.provider_updated_at_ms || null;
  const providerCreatedAtMs = dashResult.provider_created_at_ms || null;
  const summarizable = buildSummarizable(dashResult.summarizable) || null;
  const highlights = dashResult.highlights || null;
  const recurringEventId = dashResult.recurring_event_id || null;
  const dataSource = buildDataSource(dashResult.data_source);
  const parent = buildItemLocation(dashResult.parent);
  const sender = buildAuthorInfo(dashResult.sender);
  const creator = buildAuthorInfo(dashResult.creator);
  const lastModifier = buildAuthorInfo(dashResult.last_modifier);
  const assignedTo = buildAuthorInfo(dashResult.assigned_to);
  const filteredModifier = buildFilteredModifier(
    dashResult.filtered_modifier,
    isAllModifiersEnabled,
  );
  const channel = buildChannel(dashResult.channel);
  const messages = buildMessageThread(dashResult.messages);

  const media = buildMedia(dashResult.media);
  const title = overwriteMessagesTitle(
    dashResult.title,
    sender,
    messages as Message[],
    logicalType || '',
  );

  // check required fields, verbose but readable
  if (!uuid) return null;
  if (!connectorInfo) return null;
  if (!recordType) return null;
  if (!title) return null;

  let searchResult: SearchResult = {
    uuid,
    attachments,
    connectorInfo,
    displayIconOverride,
    recordType,
    upstreamId,
    title,
    url,
    description,
    additionalLinks,
    conferenceLinks,
    email,
    profileImageUrl,
    startTime,
    endTime,
    isAllDay,
    location,
    providerUpdateAtMs,
    providerCreatedAtMs,
    updatedAtMs,
    summarizable,
    highlights,
    recurringEventId,
    searchRequestId,
    analyticsTraceId,
    relevanceScore,
    brandedType,
    id3p,
    fileTypeInfo,
    brandedSiteInfo,
    dataSource,
    parent,
    sender,
    creator,
    lastModifier,
    attendees,
    sourceIndexType,
    curations,
    virtualPath,
    mimeType,
    status: status ?? normalizedStatus,
    priority,
    category,
    assignedTo,
    tags,
    referenceId,
    logicalType,
    filteredModifier,
    channel,
    messages,
    media,
    previewUrl,
  };

  searchResult = formatSalesforceResult(searchResult);
  searchResult = formatSlackResult(searchResult);
  return searchResult;
};

export function mapConnectorFilterToQueryFilter({
  parameters,
}: ConnectorFilter): dcs.QueryFilter {
  return {
    filter: {
      '.tag': 'connector_filter',
      connector_id: parameters.connectorId,
    },
  };
}

export function overwriteMessagesTitle(
  title: string | undefined,
  sender: AuthorInfo | null,
  messages: Message[],
  logicalType: string,
): string {
  if (title) return title;
  if (logicalType !== 'message' || !messages?.length) return '';
  const {
    displayName: topLevelDisplayName,
    aliasHandle: topLevelAliasHandle,
    email: topLevelEmail,
  } = sender || {};
  const {
    displayName: firstSenderDisplayName,
    aliasHandle,
    email,
  } = messages[0].sender;
  return (
    topLevelDisplayName ||
    topLevelAliasHandle ||
    topLevelEmail ||
    firstSenderDisplayName ||
    aliasHandle ||
    email ||
    ''
  );
}

export function mapContentTypeFilterToQueryFilter({
  parameters,
}: ContentTypeFilter): dcs.QueryFilter {
  return {
    filter: {
      '.tag': 'file_type_filter',
      file_type: parameters.key,
    },
  };
}

export function mapLastUpdatedFilterToQueryFilter({
  parameters,
}: LastUpdatedFilter): dcs.QueryFilter {
  return {
    filter: {
      '.tag': 'time_range_filter',
      start_datetime: parameters.start
        ? new Date(parameters.start).toISOString()
        : undefined,
      end_datetime: parameters.end
        ? new Date(parameters.end).toISOString()
        : undefined,
    },
  };
}

export function mapPeopleFilterToQueryFilter({
  parameters,
}: PersonFilter): dcs.QueryFilter {
  return {
    filter: {
      '.tag': 'person_filter',
      email: parameters?.email || '',
      // We want our people filter to match on any of the creator fields
      match_creator: true,
      match_last_modifier: true,
      match_sender: true,
      match_all_modifiers: true,
    },
  };
}

export function buildFilteredModifier(
  filtered_modifier?: dash.Modifier | null,
  isAllModifiersEnabled?: boolean,
): Modifier | null {
  return isAllModifiersEnabled && filtered_modifier
    ? {
        modifiedAtMs: filtered_modifier.modified_at_ms,
        author: buildAuthorInfo(filtered_modifier.author),
      }
    : null;
}

export function getSearchVerticalFromFilters(
  filters: SearchFilter[],
  messagesVerticalEnabled: boolean,
  multimediaVerticalEnabled: boolean,
): dcs.SearchVertical | undefined {
  if (
    messagesVerticalEnabled &&
    filters.some(
      (filter) =>
        filter.type === SearchFilterType.ContentType &&
        filter.parameters?.key === 'chat',
    )
  ) {
    return {
      ['.tag']: 'message',
    };
  }

  if (
    multimediaVerticalEnabled &&
    filters.some(
      (filter) =>
        filter.type === SearchFilterType.ContentType &&
        filter.parameters?.key === 'image',
    )
  ) {
    return {
      ['.tag']: 'multimedia',
    };
  }
  return undefined;
}
