// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/answers.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, protoInt64 } from '@bufbuild/protobuf';
import {
  AnswerInfo,
  ClientContext,
  DocumentInfo,
  PromptMessage,
  UserInteractionRequest,
} from './models_pb';
import {
  DSLDocument,
  ExecutionStep,
  ExecutionStepOutput,
  ExecutionTimingInfo,
} from './agents_pb';
import {
  ConversationsChatMessage,
  ConversationsUserMessage,
} from './conversations_pb';
import {
  DcsMetadataApiInteractionArg,
  DcsMetadataApiInteractionResponse,
  DcsSearchApiInteractionArg,
  DcsSearchApiInteractionResponse,
} from './dcs_proto_message_str_clones_pb';

/**
 * @generated from enum context_engine.AnswersWorkflowAnswerRejectionReason
 */
export enum AnswersWorkflowAnswerRejectionReason {
  /**
   * @generated from enum value: ANSWER_NOT_REJECTED = 0;
   */
  ANSWER_NOT_REJECTED = 0,

  /**
   * @generated from enum value: ANSWER_REJECTED_BY_ANSWER_REJECTER = 1;
   */
  ANSWER_REJECTED_BY_ANSWER_REJECTER = 1,

  /**
   * @generated from enum value: ANSWER_SCORE_LOW = 2;
   */
  ANSWER_SCORE_LOW = 2,

  /**
   * We will add more reasons here as needed.
   *
   * @generated from enum value: NO_VALID_ANSWERS = 3;
   */
  NO_VALID_ANSWERS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AnswersWorkflowAnswerRejectionReason)
proto3.util.setEnumType(
  AnswersWorkflowAnswerRejectionReason,
  'context_engine.AnswersWorkflowAnswerRejectionReason',
  [
    { no: 0, name: 'ANSWER_NOT_REJECTED' },
    { no: 1, name: 'ANSWER_REJECTED_BY_ANSWER_REJECTER' },
    { no: 2, name: 'ANSWER_SCORE_LOW' },
    { no: 3, name: 'NO_VALID_ANSWERS' },
  ],
);

/**
 * @generated from enum context_engine.AnswersWorkflowAnswerSelectionReason
 */
export enum AnswersWorkflowAnswerSelectionReason {
  /**
   * @generated from enum value: UNKNOWN_SELECTION_REASON = 0;
   */
  UNKNOWN_SELECTION_REASON = 0,

  /**
   * @generated from enum value: HIGH_SIMILARITY_SCORE = 1;
   */
  HIGH_SIMILARITY_SCORE = 1,

  /**
   * We will add more reasons here as needed.
   *
   * @generated from enum value: ONLY_ANSWER_AVAILABLE = 2;
   */
  ONLY_ANSWER_AVAILABLE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AnswersWorkflowAnswerSelectionReason)
proto3.util.setEnumType(
  AnswersWorkflowAnswerSelectionReason,
  'context_engine.AnswersWorkflowAnswerSelectionReason',
  [
    { no: 0, name: 'UNKNOWN_SELECTION_REASON' },
    { no: 1, name: 'HIGH_SIMILARITY_SCORE' },
    { no: 2, name: 'ONLY_ANSWER_AVAILABLE' },
  ],
);

/**
 * @generated from enum context_engine.AnswersWorkflowEmptyAnswerReason
 */
export enum AnswersWorkflowEmptyAnswerReason {
  /**
   * @generated from enum value: ANSWER_NOT_EMPTY = 0;
   */
  ANSWER_NOT_EMPTY = 0,

  /**
   * @generated from enum value: EMPTY_SEARCH_RESULTS = 1;
   */
  EMPTY_SEARCH_RESULTS = 1,

  /**
   * @generated from enum value: DOCUMENT_CONTENT_EMPTY = 2;
   */
  DOCUMENT_CONTENT_EMPTY = 2,

  /**
   * @generated from enum value: SELECTED_CONTEXT_EMPTY = 3;
   */
  SELECTED_CONTEXT_EMPTY = 3,

  /**
   * @generated from enum value: NO_KEYWORDS = 4;
   */
  NO_KEYWORDS = 4,

  /**
   * We will add more reasons here as needed.
   *
   * @generated from enum value: NO_ANSWER_GENERATED = 5;
   */
  NO_ANSWER_GENERATED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(AnswersWorkflowEmptyAnswerReason)
proto3.util.setEnumType(
  AnswersWorkflowEmptyAnswerReason,
  'context_engine.AnswersWorkflowEmptyAnswerReason',
  [
    { no: 0, name: 'ANSWER_NOT_EMPTY' },
    { no: 1, name: 'EMPTY_SEARCH_RESULTS' },
    { no: 2, name: 'DOCUMENT_CONTENT_EMPTY' },
    { no: 3, name: 'SELECTED_CONTEXT_EMPTY' },
    { no: 4, name: 'NO_KEYWORDS' },
    { no: 5, name: 'NO_ANSWER_GENERATED' },
  ],
);

/**
 * @generated from enum context_engine.AnswersWorkflowErrorCode
 */
export enum AnswersWorkflowErrorCode {
  /**
   * @generated from enum value: UNKNOWN_ERROR = 0;
   */
  UNKNOWN_ERROR = 0,

  /**
   * @generated from enum value: API_ERROR = 1;
   */
  API_ERROR = 1,

  /**
   * @generated from enum value: TIMEOUT_ERROR = 2;
   */
  TIMEOUT_ERROR = 2,

  /**
   * @generated from enum value: EXCEPTION = 3;
   */
  EXCEPTION = 3,

  /**
   * @generated from enum value: INVALID_REQUEST = 4;
   */
  INVALID_REQUEST = 4,

  /**
   * @generated from enum value: INVALID_RESPONSE = 5;
   */
  INVALID_RESPONSE = 5,

  /**
   * Hive or DataBricks etc.
   *
   * @generated from enum value: ERROR_LOGGING_TO_DB = 6;
   */
  ERROR_LOGGING_TO_DB = 6,

  /**
   * @generated from enum value: INVALID_SOURCE = 7;
   */
  INVALID_SOURCE = 7,

  /**
   * @generated from enum value: ERROR_GENERATING_ANSWERS = 8;
   */
  ERROR_GENERATING_ANSWERS = 8,

  /**
   * @generated from enum value: CONTEXT_EMPTY = 9;
   */
  CONTEXT_EMPTY = 9,

  /**
   * Search API errors
   *
   * @generated from enum value: DCS_SEARCH_API_ERROR = 10;
   */
  DCS_SEARCH_API_ERROR = 10,

  /**
   * @generated from enum value: DCS_SEARCH_API_TIMEOUT_ERROR = 11;
   */
  DCS_SEARCH_API_TIMEOUT_ERROR = 11,

  /**
   * Content download API errors
   *
   * @generated from enum value: DCS_GET_BODY_FROM_ES_API_ERROR = 20;
   */
  DCS_GET_BODY_FROM_ES_API_ERROR = 20,

  /**
   * @generated from enum value: DCS_GET_BODY_FROM_ES_API_TIMEOUT_ERROR = 21;
   */
  DCS_GET_BODY_FROM_ES_API_TIMEOUT_ERROR = 21,

  /**
   * @generated from enum value: DCS_GET_BODY_METADATA_API_ERROR = 22;
   */
  DCS_GET_BODY_METADATA_API_ERROR = 22,

  /**
   * @generated from enum value: DCS_GET_BODY_METADATA_API_TIMEOUT_ERROR = 23;
   */
  DCS_GET_BODY_METADATA_API_TIMEOUT_ERROR = 23,

  /**
   * @generated from enum value: ERROR_FETCHING_SEARCH_RESULTS = 24;
   */
  ERROR_FETCHING_SEARCH_RESULTS = 24,

  /**
   * @generated from enum value: DCS_GET_BODY_METADATA_DOCUMENT_ERROR = 25;
   */
  DCS_GET_BODY_METADATA_DOCUMENT_ERROR = 25,

  /**
   * @generated from enum value: DCS_GET_BODY_METADATA_DOCUMENT_LINK_DID_NOT_MATCH = 26;
   */
  DCS_GET_BODY_METADATA_DOCUMENT_LINK_DID_NOT_MATCH = 26,

  /**
   * @generated from enum value: DCS_NO_DOCUMENTS_FOUND = 27;
   */
  DCS_NO_DOCUMENTS_FOUND = 27,

  /**
   * LLM errors
   *
   * @generated from enum value: LLM_ERROR = 30;
   */
  LLM_ERROR = 30,

  /**
   * @generated from enum value: LLM_INVALID_RESPONSE = 31;
   */
  LLM_INVALID_RESPONSE = 31,

  /**
   * @generated from enum value: LLM_INVALID_JSON_RESPONSE = 32;
   */
  LLM_INVALID_JSON_RESPONSE = 32,

  /**
   * @generated from enum value: LLM_TIMEOUT = 33;
   */
  LLM_TIMEOUT = 33,

  /**
   * @generated from enum value: LLM_HALLUCINATION = 34;
   */
  LLM_HALLUCINATION = 34,

  /**
   * Query Errors
   *
   * @generated from enum value: EMPTY_QUERY = 40;
   */
  EMPTY_QUERY = 40,

  /**
   * @generated from enum value: SUSPICIOUS_QUERY_LAKERA_ERROR = 41;
   */
  SUSPICIOUS_QUERY_LAKERA_ERROR = 41,

  /**
   * @generated from enum value: ERROR_CLASSIFYING_QUERY = 42;
   */
  ERROR_CLASSIFYING_QUERY = 42,

  /**
   * RAG & Question Generation Errors
   *
   * @generated from enum value: ERROR_EXTRACTING_KEYWORDS = 43;
   */
  ERROR_EXTRACTING_KEYWORDS = 43,

  /**
   * @generated from enum value: ERROR_GENERATING_QUESTIONS = 44;
   */
  ERROR_GENERATING_QUESTIONS = 44,

  /**
   * @generated from enum value: TOO_LONG_QUERY = 45;
   */
  TOO_LONG_QUERY = 45,

  /**
   * @generated from enum value: ERROR_GENERATING_QUESTIONS_FROM_DOCUMENT = 46;
   */
  ERROR_GENERATING_QUESTIONS_FROM_DOCUMENT = 46,

  /**
   * @generated from enum value: NO_QUESTIONS = 47;
   */
  NO_QUESTIONS = 47,

  /**
   * @generated from enum value: ERROR_GENERATING_ANSWER_FROM_DOCUMENT = 48;
   */
  ERROR_GENERATING_ANSWER_FROM_DOCUMENT = 48,

  /**
   * @generated from enum value: NO_ANSWERS_FOUND = 49;
   */
  NO_ANSWERS_FOUND = 49,

  /**
   * Predict BE availability errors
   *
   * @generated from enum value: PREDICT_BE_ERROR = 50;
   */
  PREDICT_BE_ERROR = 50,

  /**
   * @generated from enum value: PREDICT_BE_TIMEOUT_ERROR = 51;
   */
  PREDICT_BE_TIMEOUT_ERROR = 51,

  /**
   * DSL Errors
   *
   * @generated from enum value: DSL_GENERATION_ERROR = 60;
   */
  DSL_GENERATION_ERROR = 60,

  /**
   * @generated from enum value: DSL_EXECUTION_ERROR = 61;
   */
  DSL_EXECUTION_ERROR = 61,

  /**
   * @generated from enum value: ANSWER_REJECTED = 70;
   */
  ANSWER_REJECTED = 70,

  /**
   * @generated from enum value: ERROR_RANKING_ANSWERS = 71;
   */
  ERROR_RANKING_ANSWERS = 71,

  /**
   * @generated from enum value: FAILED_FETCHING_RECENT_DOCUMENT_TITLES = 72;
   */
  FAILED_FETCHING_RECENT_DOCUMENT_TITLES = 72,

  /**
   * @generated from enum value: RETRIEVER_GATED_FOR_USER = 73;
   */
  RETRIEVER_GATED_FOR_USER = 73,

  /**
   * We will add more errors here as needed.
   *
   * @generated from enum value: ERROR_GENERATING_CONVERSATION_RESPONSE = 74;
   */
  ERROR_GENERATING_CONVERSATION_RESPONSE = 74,
}
// Retrieve enum metadata with: proto3.getEnumType(AnswersWorkflowErrorCode)
proto3.util.setEnumType(
  AnswersWorkflowErrorCode,
  'context_engine.AnswersWorkflowErrorCode',
  [
    { no: 0, name: 'UNKNOWN_ERROR' },
    { no: 1, name: 'API_ERROR' },
    { no: 2, name: 'TIMEOUT_ERROR' },
    { no: 3, name: 'EXCEPTION' },
    { no: 4, name: 'INVALID_REQUEST' },
    { no: 5, name: 'INVALID_RESPONSE' },
    { no: 6, name: 'ERROR_LOGGING_TO_DB' },
    { no: 7, name: 'INVALID_SOURCE' },
    { no: 8, name: 'ERROR_GENERATING_ANSWERS' },
    { no: 9, name: 'CONTEXT_EMPTY' },
    { no: 10, name: 'DCS_SEARCH_API_ERROR' },
    { no: 11, name: 'DCS_SEARCH_API_TIMEOUT_ERROR' },
    { no: 20, name: 'DCS_GET_BODY_FROM_ES_API_ERROR' },
    { no: 21, name: 'DCS_GET_BODY_FROM_ES_API_TIMEOUT_ERROR' },
    { no: 22, name: 'DCS_GET_BODY_METADATA_API_ERROR' },
    { no: 23, name: 'DCS_GET_BODY_METADATA_API_TIMEOUT_ERROR' },
    { no: 24, name: 'ERROR_FETCHING_SEARCH_RESULTS' },
    { no: 25, name: 'DCS_GET_BODY_METADATA_DOCUMENT_ERROR' },
    { no: 26, name: 'DCS_GET_BODY_METADATA_DOCUMENT_LINK_DID_NOT_MATCH' },
    { no: 27, name: 'DCS_NO_DOCUMENTS_FOUND' },
    { no: 30, name: 'LLM_ERROR' },
    { no: 31, name: 'LLM_INVALID_RESPONSE' },
    { no: 32, name: 'LLM_INVALID_JSON_RESPONSE' },
    { no: 33, name: 'LLM_TIMEOUT' },
    { no: 34, name: 'LLM_HALLUCINATION' },
    { no: 40, name: 'EMPTY_QUERY' },
    { no: 41, name: 'SUSPICIOUS_QUERY_LAKERA_ERROR' },
    { no: 42, name: 'ERROR_CLASSIFYING_QUERY' },
    { no: 43, name: 'ERROR_EXTRACTING_KEYWORDS' },
    { no: 44, name: 'ERROR_GENERATING_QUESTIONS' },
    { no: 45, name: 'TOO_LONG_QUERY' },
    { no: 46, name: 'ERROR_GENERATING_QUESTIONS_FROM_DOCUMENT' },
    { no: 47, name: 'NO_QUESTIONS' },
    { no: 48, name: 'ERROR_GENERATING_ANSWER_FROM_DOCUMENT' },
    { no: 49, name: 'NO_ANSWERS_FOUND' },
    { no: 50, name: 'PREDICT_BE_ERROR' },
    { no: 51, name: 'PREDICT_BE_TIMEOUT_ERROR' },
    { no: 60, name: 'DSL_GENERATION_ERROR' },
    { no: 61, name: 'DSL_EXECUTION_ERROR' },
    { no: 70, name: 'ANSWER_REJECTED' },
    { no: 71, name: 'ERROR_RANKING_ANSWERS' },
    { no: 72, name: 'FAILED_FETCHING_RECENT_DOCUMENT_TITLES' },
    { no: 73, name: 'RETRIEVER_GATED_FOR_USER' },
    { no: 74, name: 'ERROR_GENERATING_CONVERSATION_RESPONSE' },
  ],
);

/**
 * @generated from enum context_engine.AnswersWorkflowProcessName
 */
export enum AnswersWorkflowProcessName {
  /**
   * @generated from enum value: UNKNOWN_PROCESS = 0;
   */
  UNKNOWN_PROCESS = 0,

  /**
   * @generated from enum value: QUERY_CLASSIFICATION = 1;
   */
  QUERY_CLASSIFICATION = 1,

  /**
   * @generated from enum value: SEARCH_DOCUMENTS = 2;
   */
  SEARCH_DOCUMENTS = 2,

  /**
   * @generated from enum value: DOWNLOAD_DOCUMENTS_CONTENT = 3;
   */
  DOWNLOAD_DOCUMENTS_CONTENT = 3,

  /**
   * @generated from enum value: DOCUMENTS_SELECTION = 4;
   */
  DOCUMENTS_SELECTION = 4,

  /**
   * @generated from enum value: CONTEXT_SELECTION = 5;
   */
  CONTEXT_SELECTION = 5,

  /**
   * @generated from enum value: GENERATE_QUESTIONS = 6;
   */
  GENERATE_QUESTIONS = 6,

  /**
   * @generated from enum value: QUESTION_SELECTION = 7;
   */
  QUESTION_SELECTION = 7,

  /**
   * @generated from enum value: GENERATE_ANSWERS = 8;
   */
  GENERATE_ANSWERS = 8,

  /**
   * @generated from enum value: GENERATE_SCORES = 9;
   */
  GENERATE_SCORES = 9,

  /**
   * @generated from enum value: EXTRACT_KEYWORDS = 10;
   */
  EXTRACT_KEYWORDS = 10,

  /**
   * @generated from enum value: LLM_CHAT_COMPLETION = 11;
   */
  LLM_CHAT_COMPLETION = 11,

  /**
   * @generated from enum value: LLM_TEXT_GENERATION = 12;
   */
  LLM_TEXT_GENERATION = 12,

  /**
   * @generated from enum value: EMBEDDING_GENERATION = 13;
   */
  EMBEDDING_GENERATION = 13,

  /**
   * @generated from enum value: EMBEDDING_COMPARISON = 14;
   */
  EMBEDDING_COMPARISON = 14,

  /**
   * @generated from enum value: TFIDF_COMPARISON = 15;
   */
  TFIDF_COMPARISON = 15,

  /**
   * @generated from enum value: ANSWER_RANKING = 16;
   */
  ANSWER_RANKING = 16,

  /**
   * @generated from enum value: ANSWER_REJECTION = 17;
   */
  ANSWER_REJECTION = 17,

  /**
   * @generated from enum value: HALLUCINATION_CHECK = 18;
   */
  HALLUCINATION_CHECK = 18,

  /**
   * @generated from enum value: IDK_ANSWER_CHECK = 19;
   */
  IDK_ANSWER_CHECK = 19,

  /**
   * @generated from enum value: SCORES_GENERATION = 20;
   */
  SCORES_GENERATION = 20,

  /**
   * @generated from enum value: SIMILARITY_CHECK = 21;
   */
  SIMILARITY_CHECK = 21,

  /**
   * @generated from enum value: RAG_ANSWER_GENERATION = 22;
   */
  RAG_ANSWER_GENERATION = 22,

  /**
   * @generated from enum value: KEYWORD_TO_QA_ANSWER_GENERATION = 23;
   */
  KEYWORD_TO_QA_ANSWER_GENERATION = 23,

  /**
   * @generated from enum value: PROCESS_SEARCH_RESULTS = 24;
   */
  PROCESS_SEARCH_RESULTS = 24,

  /**
   * @generated from enum value: REQUEST_VALIDATION = 25;
   */
  REQUEST_VALIDATION = 25,

  /**
   * @generated from enum value: ANSWERS_WORKFLOW = 26;
   */
  ANSWERS_WORKFLOW = 26,

  /**
   * Hive or DataBricks etc.
   *
   * @generated from enum value: LOG_TO_DB = 27;
   */
  LOG_TO_DB = 27,

  /**
   * @generated from enum value: PRESCRIBED_ANSWER_GENERATION = 28;
   */
  PRESCRIBED_ANSWER_GENERATION = 28,

  /**
   * @generated from enum value: FS_AGENT_ANSWER_GENERATION = 29;
   */
  FS_AGENT_ANSWER_GENERATION = 29,

  /**
   * @generated from enum value: AGENTS_ANSWER_GENERATION = 30;
   */
  AGENTS_ANSWER_GENERATION = 30,

  /**
   * @generated from enum value: PROCESS_LLM_RESPONSE = 31;
   */
  PROCESS_LLM_RESPONSE = 31,

  /**
   * @generated from enum value: LLM_INTERACTION = 32;
   */
  LLM_INTERACTION = 32,

  /**
   * @generated from enum value: FILTER_DOCUMENTS = 33;
   */
  FILTER_DOCUMENTS = 33,

  /**
   * @generated from enum value: FETCH_SEARCH_RESULTS = 34;
   */
  FETCH_SEARCH_RESULTS = 34,

  /**
   * @generated from enum value: GENERATE_QUESTIONS_FROM_DOCUMENT = 35;
   */
  GENERATE_QUESTIONS_FROM_DOCUMENT = 35,

  /**
   * @generated from enum value: RAG_ANSWER_GENERATION_FROM_DOCUMENT = 36;
   */
  RAG_ANSWER_GENERATION_FROM_DOCUMENT = 36,

  /**
   * @generated from enum value: CONVERSATIONS_WORKFLOW = 37;
   */
  CONVERSATIONS_WORKFLOW = 37,

  /**
   * @generated from enum value: REPHRASE_QUERY = 38;
   */
  REPHRASE_QUERY = 38,

  /**
   * We will add more processes here as needed.
   *
   * @generated from enum value: CONVERSATIONS_SETUP = 39;
   */
  CONVERSATIONS_SETUP = 39,
}
// Retrieve enum metadata with: proto3.getEnumType(AnswersWorkflowProcessName)
proto3.util.setEnumType(
  AnswersWorkflowProcessName,
  'context_engine.AnswersWorkflowProcessName',
  [
    { no: 0, name: 'UNKNOWN_PROCESS' },
    { no: 1, name: 'QUERY_CLASSIFICATION' },
    { no: 2, name: 'SEARCH_DOCUMENTS' },
    { no: 3, name: 'DOWNLOAD_DOCUMENTS_CONTENT' },
    { no: 4, name: 'DOCUMENTS_SELECTION' },
    { no: 5, name: 'CONTEXT_SELECTION' },
    { no: 6, name: 'GENERATE_QUESTIONS' },
    { no: 7, name: 'QUESTION_SELECTION' },
    { no: 8, name: 'GENERATE_ANSWERS' },
    { no: 9, name: 'GENERATE_SCORES' },
    { no: 10, name: 'EXTRACT_KEYWORDS' },
    { no: 11, name: 'LLM_CHAT_COMPLETION' },
    { no: 12, name: 'LLM_TEXT_GENERATION' },
    { no: 13, name: 'EMBEDDING_GENERATION' },
    { no: 14, name: 'EMBEDDING_COMPARISON' },
    { no: 15, name: 'TFIDF_COMPARISON' },
    { no: 16, name: 'ANSWER_RANKING' },
    { no: 17, name: 'ANSWER_REJECTION' },
    { no: 18, name: 'HALLUCINATION_CHECK' },
    { no: 19, name: 'IDK_ANSWER_CHECK' },
    { no: 20, name: 'SCORES_GENERATION' },
    { no: 21, name: 'SIMILARITY_CHECK' },
    { no: 22, name: 'RAG_ANSWER_GENERATION' },
    { no: 23, name: 'KEYWORD_TO_QA_ANSWER_GENERATION' },
    { no: 24, name: 'PROCESS_SEARCH_RESULTS' },
    { no: 25, name: 'REQUEST_VALIDATION' },
    { no: 26, name: 'ANSWERS_WORKFLOW' },
    { no: 27, name: 'LOG_TO_DB' },
    { no: 28, name: 'PRESCRIBED_ANSWER_GENERATION' },
    { no: 29, name: 'FS_AGENT_ANSWER_GENERATION' },
    { no: 30, name: 'AGENTS_ANSWER_GENERATION' },
    { no: 31, name: 'PROCESS_LLM_RESPONSE' },
    { no: 32, name: 'LLM_INTERACTION' },
    { no: 33, name: 'FILTER_DOCUMENTS' },
    { no: 34, name: 'FETCH_SEARCH_RESULTS' },
    { no: 35, name: 'GENERATE_QUESTIONS_FROM_DOCUMENT' },
    { no: 36, name: 'RAG_ANSWER_GENERATION_FROM_DOCUMENT' },
    { no: 37, name: 'CONVERSATIONS_WORKFLOW' },
    { no: 38, name: 'REPHRASE_QUERY' },
    { no: 39, name: 'CONVERSATIONS_SETUP' },
  ],
);

/**
 * @generated from enum context_engine.QuestionSource
 */
export enum QuestionSource {
  /**
   * @generated from enum value: UNKNOWN_SOURCE = 0;
   */
  UNKNOWN_SOURCE = 0,

  /**
   * @generated from enum value: GENERATED_FROM_USER_KEYWORD = 1;
   */
  GENERATED_FROM_USER_KEYWORD = 1,

  /**
   * @generated from enum value: USER_QUERY = 2;
   */
  USER_QUERY = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionSource)
proto3.util.setEnumType(QuestionSource, 'context_engine.QuestionSource', [
  { no: 0, name: 'UNKNOWN_SOURCE' },
  { no: 1, name: 'GENERATED_FROM_USER_KEYWORD' },
  { no: 2, name: 'USER_QUERY' },
]);

/**
 * @generated from enum context_engine.QuestionRejecterReason
 */
export enum QuestionRejecterReason {
  /**
   * @generated from enum value: QUESTION_NOT_REJECTED = 0;
   */
  QUESTION_NOT_REJECTED = 0,

  /**
   * @generated from enum value: QUESTION_LENGTH_DID_NOT_MEET_THRESHOLD = 1;
   */
  QUESTION_LENGTH_DID_NOT_MEET_THRESHOLD = 1,

  /**
   * @generated from enum value: DUPLICATE_QUESTION = 2;
   */
  DUPLICATE_QUESTION = 2,

  /**
   * @generated from enum value: DOES_NOT_MATCH_SEARCH_QUERY = 3;
   */
  DOES_NOT_MATCH_SEARCH_QUERY = 3,

  /**
   * @generated from enum value: RELATIVE_RANKING_SCORE_LOW = 4;
   */
  RELATIVE_RANKING_SCORE_LOW = 4,

  /**
   * @generated from enum value: INVALID_QUESTION = 5;
   */
  INVALID_QUESTION = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(QuestionRejecterReason)
proto3.util.setEnumType(
  QuestionRejecterReason,
  'context_engine.QuestionRejecterReason',
  [
    { no: 0, name: 'QUESTION_NOT_REJECTED' },
    { no: 1, name: 'QUESTION_LENGTH_DID_NOT_MEET_THRESHOLD' },
    { no: 2, name: 'DUPLICATE_QUESTION' },
    { no: 3, name: 'DOES_NOT_MATCH_SEARCH_QUERY' },
    { no: 4, name: 'RELATIVE_RANKING_SCORE_LOW' },
    { no: 5, name: 'INVALID_QUESTION' },
  ],
);

/**
 * @generated from message context_engine.Answer
 */
export class Answer extends Message<Answer> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = '';

  /**
   * @generated from field: float score = 2;
   */
  score = 0;

  /**
   * @generated from field: string created_at_timestamp = 3;
   */
  createdAtTimestamp = '';

  /**
   * @generated from field: string session_id = 4;
   */
  sessionId = '';

  /**
   * @generated from field: string conversation_id = 5;
   */
  conversationId = '';

  /**
   * @generated from field: context_engine.UserInteractionRequest ui_request = 6;
   */
  uiRequest?: UserInteractionRequest;

  /**
   * Please use result diagnostics instead. Will be removed in the future.
   *
   * @generated from field: context_engine.AnswerDebugInfo debug_info = 7 [deprecated = true];
   * @deprecated
   */
  debugInfo?: AnswerDebugInfo;

  /**
   * @generated from field: string query = 8;
   */
  query = '';

  /**
   * @generated from field: context_engine.AnswerDetail answer = 9;
   */
  answer?: AnswerDetail;

  /**
   * @generated from field: string answer_id = 10;
   */
  answerId = '';

  /**
   * @generated from field: context_engine.Question question = 11;
   */
  question?: Question;

  /**
   * @generated from field: bool is_cached_answer = 13;
   */
  isCachedAnswer = false;

  /**
   * @generated from field: context_engine.Scores answer_scores = 14;
   */
  answerScores?: Scores;

  /**
   * @generated from field: context_engine.AnswersWorkflowAnswerRejectionReason ignore_reason = 100;
   */
  ignoreReason = AnswersWorkflowAnswerRejectionReason.ANSWER_NOT_REJECTED;

  /**
   * @generated from field: context_engine.AnswersWorkflowAnswerSelectionReason selection_reason = 101;
   */
  selectionReason =
    AnswersWorkflowAnswerSelectionReason.UNKNOWN_SELECTION_REASON;

  /**
   * @generated from field: context_engine.AnswersWorkflowEmptyAnswerReason empty_answer_reason = 102;
   */
  emptyAnswerReason = AnswersWorkflowEmptyAnswerReason.ANSWER_NOT_EMPTY;

  constructor(data?: PartialMessage<Answer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.Answer';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'score', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
    {
      no: 3,
      name: 'created_at_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 4, name: 'session_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 5,
      name: 'conversation_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 6, name: 'ui_request', kind: 'message', T: UserInteractionRequest },
    { no: 7, name: 'debug_info', kind: 'message', T: AnswerDebugInfo },
    { no: 8, name: 'query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 9, name: 'answer', kind: 'message', T: AnswerDetail },
    { no: 10, name: 'answer_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 11, name: 'question', kind: 'message', T: Question },
    {
      no: 13,
      name: 'is_cached_answer',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
    { no: 14, name: 'answer_scores', kind: 'message', T: Scores },
    {
      no: 100,
      name: 'ignore_reason',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowAnswerRejectionReason),
    },
    {
      no: 101,
      name: 'selection_reason',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowAnswerSelectionReason),
    },
    {
      no: 102,
      name: 'empty_answer_reason',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowEmptyAnswerReason),
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): Answer {
    return new Answer().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): Answer {
    return new Answer().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): Answer {
    return new Answer().fromJsonString(jsonString, options);
  }

  static equals(
    a: Answer | PlainMessage<Answer> | undefined,
    b: Answer | PlainMessage<Answer> | undefined,
  ): boolean {
    return proto3.util.equals(Answer, a, b);
  }
}

/**
 * @generated from message context_engine.Scores
 */
export class Scores extends Message<Scores> {
  /**
   * @generated from field: float keyword_relevance = 1;
   */
  keywordRelevance = 0;

  /**
   * @generated from field: float rag_relevance = 2;
   */
  ragRelevance = 0;

  /**
   * @generated from field: float kw_question_confidence = 3;
   */
  kwQuestionConfidence = 0;

  /**
   * @generated from field: float rag_answer_confidence = 4;
   */
  ragAnswerConfidence = 0;

  /**
   * @generated from field: float dash_canned_response_confidence = 5;
   */
  dashCannedResponseConfidence = 0;

  /**
   * @generated from field: float dash_agent_confidence = 6;
   */
  dashAgentConfidence = 0;

  /**
   * @generated from field: float calendar_agent_confidence = 7;
   */
  calendarAgentConfidence = 0;

  /**
   * @generated from field: float total_score = 8;
   */
  totalScore = 0;

  constructor(data?: PartialMessage<Scores>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.Scores';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'keyword_relevance',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    {
      no: 2,
      name: 'rag_relevance',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    {
      no: 3,
      name: 'kw_question_confidence',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    {
      no: 4,
      name: 'rag_answer_confidence',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    {
      no: 5,
      name: 'dash_canned_response_confidence',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    {
      no: 6,
      name: 'dash_agent_confidence',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    {
      no: 7,
      name: 'calendar_agent_confidence',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    { no: 8, name: 'total_score', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): Scores {
    return new Scores().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): Scores {
    return new Scores().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): Scores {
    return new Scores().fromJsonString(jsonString, options);
  }

  static equals(
    a: Scores | PlainMessage<Scores> | undefined,
    b: Scores | PlainMessage<Scores> | undefined,
  ): boolean {
    return proto3.util.equals(Scores, a, b);
  }
}

/**
 * @generated from message context_engine.QuerySuggestion
 */
export class QuerySuggestion extends Message<QuerySuggestion> {
  /**
   * @generated from field: string suggested_query = 1;
   */
  suggestedQuery = '';

  /**
   * @generated from field: float score = 2;
   */
  score = 0;

  constructor(data?: PartialMessage<QuerySuggestion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.QuerySuggestion';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'suggested_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'score', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): QuerySuggestion {
    return new QuerySuggestion().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestion {
    return new QuerySuggestion().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestion {
    return new QuerySuggestion().fromJsonString(jsonString, options);
  }

  static equals(
    a: QuerySuggestion | PlainMessage<QuerySuggestion> | undefined,
    b: QuerySuggestion | PlainMessage<QuerySuggestion> | undefined,
  ): boolean {
    return proto3.util.equals(QuerySuggestion, a, b);
  }
}

/**
 * @generated from message context_engine.QuerySuggestionsList
 */
export class QuerySuggestionsList extends Message<QuerySuggestionsList> {
  /**
   * @generated from field: repeated context_engine.QuerySuggestion query_suggestions = 1;
   */
  querySuggestions: QuerySuggestion[] = [];

  constructor(data?: PartialMessage<QuerySuggestionsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.QuerySuggestionsList';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'query_suggestions',
      kind: 'message',
      T: QuerySuggestion,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): QuerySuggestionsList {
    return new QuerySuggestionsList().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsList {
    return new QuerySuggestionsList().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsList {
    return new QuerySuggestionsList().fromJsonString(jsonString, options);
  }

  static equals(
    a: QuerySuggestionsList | PlainMessage<QuerySuggestionsList> | undefined,
    b: QuerySuggestionsList | PlainMessage<QuerySuggestionsList> | undefined,
  ): boolean {
    return proto3.util.equals(QuerySuggestionsList, a, b);
  }
}

/**
 * @generated from message context_engine.AnswerDebugInfo
 * @deprecated
 */
export class AnswerDebugInfo extends Message<AnswerDebugInfo> {
  /**
   * @generated from field: context_engine.DSLDocument dsl_document = 1;
   */
  dslDocument?: DSLDocument;

  /**
   * @generated from field: string gend_code = 2;
   */
  gendCode = '';

  /**
   * @generated from field: int32 execution_time_ms = 3;
   */
  executionTimeMs = 0;

  /**
   * @generated from field: int32 questions_from_keywords_ms = 4;
   */
  questionsFromKeywordsMs = 0;

  /**
   * @generated from field: repeated context_engine.ExecutionStep execution_steps = 5;
   */
  executionSteps: ExecutionStep[] = [];

  /**
   * @generated from field: context_engine.ExecutionTimingInfo timing_info = 6;
   */
  timingInfo?: ExecutionTimingInfo;

  /**
   * @generated from field: int32 keyword_gen_time_ms = 7;
   */
  keywordGenTimeMs = 0;

  /**
   * @generated from field: int32 es_query_exec_time_ms = 8;
   */
  esQueryExecTimeMs = 0;

  /**
   * @generated from field: int32 rag_gen_answer_from_docs_time_ms = 9;
   */
  ragGenAnswerFromDocsTimeMs = 0;

  /**
   * @generated from field: string es_query_json = 10;
   */
  esQueryJson = '';

  /**
   * @generated from field: int32 fetch_body_exec_time_ms = 11;
   */
  fetchBodyExecTimeMs = 0;

  constructor(data?: PartialMessage<AnswerDebugInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AnswerDebugInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dsl_document', kind: 'message', T: DSLDocument },
    { no: 2, name: 'gend_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'execution_time_ms',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    {
      no: 4,
      name: 'questions_from_keywords_ms',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    {
      no: 5,
      name: 'execution_steps',
      kind: 'message',
      T: ExecutionStep,
      repeated: true,
    },
    { no: 6, name: 'timing_info', kind: 'message', T: ExecutionTimingInfo },
    {
      no: 7,
      name: 'keyword_gen_time_ms',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    {
      no: 8,
      name: 'es_query_exec_time_ms',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    {
      no: 9,
      name: 'rag_gen_answer_from_docs_time_ms',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    {
      no: 10,
      name: 'es_query_json',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 11,
      name: 'fetch_body_exec_time_ms',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AnswerDebugInfo {
    return new AnswerDebugInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AnswerDebugInfo {
    return new AnswerDebugInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AnswerDebugInfo {
    return new AnswerDebugInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: AnswerDebugInfo | PlainMessage<AnswerDebugInfo> | undefined,
    b: AnswerDebugInfo | PlainMessage<AnswerDebugInfo> | undefined,
  ): boolean {
    return proto3.util.equals(AnswerDebugInfo, a, b);
  }
}

/**
 * @generated from message context_engine.AnswerDetail
 */
export class AnswerDetail extends Message<AnswerDetail> {
  /**
   * @generated from field: context_engine.ExecutionStepOutput output = 1;
   */
  output?: ExecutionStepOutput;

  constructor(data?: PartialMessage<AnswerDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AnswerDetail';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'output', kind: 'message', T: ExecutionStepOutput },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AnswerDetail {
    return new AnswerDetail().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AnswerDetail {
    return new AnswerDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AnswerDetail {
    return new AnswerDetail().fromJsonString(jsonString, options);
  }

  static equals(
    a: AnswerDetail | PlainMessage<AnswerDetail> | undefined,
    b: AnswerDetail | PlainMessage<AnswerDetail> | undefined,
  ): boolean {
    return proto3.util.equals(AnswerDetail, a, b);
  }
}

/**
 * @generated from message context_engine.Question
 */
export class Question extends Message<Question> {
  /**
   * @generated from field: string question_id = 1;
   */
  questionId = '';

  /**
   * @generated from field: string question = 2;
   */
  question = '';

  /**
   * @generated from field: context_engine.QuestionSource question_source = 3;
   */
  questionSource = QuestionSource.UNKNOWN_SOURCE;

  /**
   * @generated from field: string source_user_query = 4;
   */
  sourceUserQuery = '';

  /**
   * @generated from field: context_engine.Scores scores = 5;
   */
  scores?: Scores;

  /**
   * @generated from field: repeated string keywords = 6;
   */
  keywords: string[] = [];

  /**
   * @generated from field: context_engine.QuestionRejecterReason rejecter_reason = 7;
   */
  rejecterReason = QuestionRejecterReason.QUESTION_NOT_REJECTED;

  constructor(data?: PartialMessage<Question>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.Question';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'question_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'question', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'question_source',
      kind: 'enum',
      T: proto3.getEnumType(QuestionSource),
    },
    {
      no: 4,
      name: 'source_user_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 5, name: 'scores', kind: 'message', T: Scores },
    {
      no: 6,
      name: 'keywords',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 7,
      name: 'rejecter_reason',
      kind: 'enum',
      T: proto3.getEnumType(QuestionRejecterReason),
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): Question {
    return new Question().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): Question {
    return new Question().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): Question {
    return new Question().fromJsonString(jsonString, options);
  }

  static equals(
    a: Question | PlainMessage<Question> | undefined,
    b: Question | PlainMessage<Question> | undefined,
  ): boolean {
    return proto3.util.equals(Question, a, b);
  }
}

/**
 * @generated from message context_engine.GetAnswersForQueryInputArg
 */
export class GetAnswersForQueryInputArg extends Message<GetAnswersForQueryInputArg> {
  /**
   * Represents GetAnswersForQueryRequest. GetAnswersForQueryRequest gets renamed to GetAnswersForQueryArg in Stone spec.
   *
   * @generated from field: string user_query = 1;
   */
  userQuery = '';

  /**
   * @generated from field: bool debug = 2;
   */
  debug = false;

  /**
   * @generated from field: string source = 3;
   */
  source = '';

  /**
   * @generated from field: context_engine.ClientContext client_context = 4;
   */
  clientContext?: ClientContext;

  /**
   * @generated from field: bool disable_cache = 5;
   */
  disableCache = false;

  /**
   * @generated from field: string experiment_setting = 6;
   */
  experimentSetting = '';

  /**
   * @generated from field: bool use_usp = 7;
   */
  useUsp = false;

  constructor(data?: PartialMessage<GetAnswersForQueryInputArg>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetAnswersForQueryInputArg';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'client_context', kind: 'message', T: ClientContext },
    {
      no: 5,
      name: 'disable_cache',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
    {
      no: 6,
      name: 'experiment_setting',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 7, name: 'use_usp', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAnswersForQueryInputArg {
    return new GetAnswersForQueryInputArg().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryInputArg {
    return new GetAnswersForQueryInputArg().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryInputArg {
    return new GetAnswersForQueryInputArg().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAnswersForQueryInputArg
      | PlainMessage<GetAnswersForQueryInputArg>
      | undefined,
    b:
      | GetAnswersForQueryInputArg
      | PlainMessage<GetAnswersForQueryInputArg>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAnswersForQueryInputArg, a, b);
  }
}

/**
 * @generated from message context_engine.GetResponseInputArg
 */
export class GetResponseInputArg extends Message<GetResponseInputArg> {
  /**
   * Represents ConversationsUserRequest.
   *
   * @generated from field: string conversation_id = 1;
   */
  conversationId = '';

  /**
   * @generated from field: repeated context_engine.ConversationsChatMessage history = 2;
   */
  history: ConversationsChatMessage[] = [];

  /**
   * @generated from field: context_engine.ConversationsUserMessage user_message = 3;
   */
  userMessage?: ConversationsUserMessage;

  /**
   * @generated from field: repeated string document_ids = 4;
   */
  documentIds: string[] = [];

  /**
   * @generated from field: bool debug_enabled = 5;
   */
  debugEnabled = false;

  /**
   * @generated from field: context_engine.ClientContext client_context = 6;
   */
  clientContext?: ClientContext;

  /**
   * @generated from field: repeated string agent_ids = 7;
   */
  agentIds: string[] = [];

  /**
   * @generated from field: string experiment_setting = 8;
   */
  experimentSetting = '';

  /**
   * @generated from field: string source = 9;
   */
  source = '';

  constructor(data?: PartialMessage<GetResponseInputArg>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetResponseInputArg';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'conversation_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'history',
      kind: 'message',
      T: ConversationsChatMessage,
      repeated: true,
    },
    {
      no: 3,
      name: 'user_message',
      kind: 'message',
      T: ConversationsUserMessage,
    },
    {
      no: 4,
      name: 'document_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 5,
      name: 'debug_enabled',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
    { no: 6, name: 'client_context', kind: 'message', T: ClientContext },
    {
      no: 7,
      name: 'agent_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 8,
      name: 'experiment_setting',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 9, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetResponseInputArg {
    return new GetResponseInputArg().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetResponseInputArg {
    return new GetResponseInputArg().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetResponseInputArg {
    return new GetResponseInputArg().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetResponseInputArg | PlainMessage<GetResponseInputArg> | undefined,
    b: GetResponseInputArg | PlainMessage<GetResponseInputArg> | undefined,
  ): boolean {
    return proto3.util.equals(GetResponseInputArg, a, b);
  }
}

/**
 * @generated from message context_engine.ChatCompletionInputSignal
 */
export class ChatCompletionInputSignal extends Message<ChatCompletionInputSignal> {
  /**
   * @generated from field: string model_name = 1;
   */
  modelName = '';

  /**
   * @generated from field: repeated context_engine.PromptMessage messages = 2;
   */
  messages: PromptMessage[] = [];

  /**
   * @generated from field: int64 max_tokens = 3;
   */
  maxTokens = protoInt64.zero;

  /**
   * @generated from field: float temperature = 4;
   */
  temperature = 0;

  /**
   * list of stop sequences
   *
   * @generated from field: repeated string stop = 5;
   */
  stop: string[] = [];

  /**
   * @generated from field: string model_source = 6;
   */
  modelSource = '';

  /**
   * @generated from field: bool is_shadow = 7;
   */
  isShadow = false;

  constructor(data?: PartialMessage<ChatCompletionInputSignal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ChatCompletionInputSignal';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'messages',
      kind: 'message',
      T: PromptMessage,
      repeated: true,
    },
    { no: 3, name: 'max_tokens', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: 'temperature', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
    {
      no: 5,
      name: 'stop',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 6,
      name: 'model_source',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 7, name: 'is_shadow', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ChatCompletionInputSignal {
    return new ChatCompletionInputSignal().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ChatCompletionInputSignal {
    return new ChatCompletionInputSignal().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ChatCompletionInputSignal {
    return new ChatCompletionInputSignal().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ChatCompletionInputSignal
      | PlainMessage<ChatCompletionInputSignal>
      | undefined,
    b:
      | ChatCompletionInputSignal
      | PlainMessage<ChatCompletionInputSignal>
      | undefined,
  ): boolean {
    return proto3.util.equals(ChatCompletionInputSignal, a, b);
  }
}

/**
 * @generated from message context_engine.TextGenerationInputSignal
 */
export class TextGenerationInputSignal extends Message<TextGenerationInputSignal> {
  /**
   * @generated from field: string model_name = 1;
   */
  modelName = '';

  /**
   * @generated from field: string prompt = 2;
   */
  prompt = '';

  /**
   * @generated from field: int64 max_tokens = 3;
   */
  maxTokens = protoInt64.zero;

  /**
   * @generated from field: float temperature = 4;
   */
  temperature = 0;

  /**
   * list of stop sequences
   *
   * @generated from field: repeated string stop = 5;
   */
  stop: string[] = [];

  /**
   * trusted prompt template to be filtered out during security checks
   *
   * @generated from field: string template = 6;
   */
  template = '';

  constructor(data?: PartialMessage<TextGenerationInputSignal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.TextGenerationInputSignal';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'model_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'prompt', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'max_tokens', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: 'temperature', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
    {
      no: 5,
      name: 'stop',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    { no: 6, name: 'template', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): TextGenerationInputSignal {
    return new TextGenerationInputSignal().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): TextGenerationInputSignal {
    return new TextGenerationInputSignal().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): TextGenerationInputSignal {
    return new TextGenerationInputSignal().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | TextGenerationInputSignal
      | PlainMessage<TextGenerationInputSignal>
      | undefined,
    b:
      | TextGenerationInputSignal
      | PlainMessage<TextGenerationInputSignal>
      | undefined,
  ): boolean {
    return proto3.util.equals(TextGenerationInputSignal, a, b);
  }
}

/**
 * @generated from message context_engine.AnswersWorkflowProcessValue
 */
export class AnswersWorkflowProcessValue extends Message<AnswersWorkflowProcessValue> {
  /**
   * We can make this an enum if we want to use a fixed set of values.
   *
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: string description = 2;
   */
  description = '';

  /**
   * @generated from field: string created_at_timestamp = 3;
   */
  createdAtTimestamp = '';

  /**
   * @generated from oneof context_engine.AnswersWorkflowProcessValue.value
   */
  value:
    | {
        /**
         * @generated from field: string value_str = 100;
         */
        value: string;
        case: 'valueStr';
      }
    | {
        /**
         * @generated from field: int32 value_int = 101;
         */
        value: number;
        case: 'valueInt';
      }
    | {
        /**
         * @generated from field: float value_float = 102;
         */
        value: number;
        case: 'valueFloat';
      }
    | {
        /**
         * @generated from field: bool value_bool = 103;
         */
        value: boolean;
        case: 'valueBool';
      }
    | {
        /**
         * map fields like map<string, string> are not allowed in oneof.
         * We can use map<string, Any> outside as a separate message but we have restrictions on using Any.
         *
         * @generated from field: string value_json_str = 104;
         */
        value: string;
        case: 'valueJsonStr';
      }
    | {
        /**
         * @generated from field: string value_error_message = 105;
         */
        value: string;
        case: 'valueErrorMessage';
      }
    | {
        /**
         * @generated from field: context_engine.DocumentInfo value_document_info = 106;
         */
        value: DocumentInfo;
        case: 'valueDocumentInfo';
      }
    | {
        /**
         * @generated from field: context_engine.DcsSearchApiInteractionArg value_dcs_search_api_interaction_arg = 107;
         */
        value: DcsSearchApiInteractionArg;
        case: 'valueDcsSearchApiInteractionArg';
      }
    | {
        /**
         * @generated from field: context_engine.DcsSearchApiInteractionResponse value_dcs_search_api_interaction_response = 108;
         */
        value: DcsSearchApiInteractionResponse;
        case: 'valueDcsSearchApiInteractionResponse';
      }
    | {
        /**
         * @generated from field: context_engine.PromptMessage value_prompt_message = 109;
         */
        value: PromptMessage;
        case: 'valuePromptMessage';
      }
    | {
        /**
         * @generated from field: context_engine.ChatCompletionInputSignal value_chat_completion_input_signal = 110;
         */
        value: ChatCompletionInputSignal;
        case: 'valueChatCompletionInputSignal';
      }
    | {
        /**
         * @generated from field: context_engine.TextGenerationInputSignal value_text_generation_input_signal = 111;
         */
        value: TextGenerationInputSignal;
        case: 'valueTextGenerationInputSignal';
      }
    | {
        /**
         * @generated from field: context_engine.AnswersWorkflowAnswerRejectionReason value_answer_rejected_reason = 112;
         */
        value: AnswersWorkflowAnswerRejectionReason;
        case: 'valueAnswerRejectedReason';
      }
    | {
        /**
         * @generated from field: context_engine.DcsMetadataApiInteractionArg value_dcs_metadata_api_interaction_arg = 113;
         */
        value: DcsMetadataApiInteractionArg;
        case: 'valueDcsMetadataApiInteractionArg';
      }
    | {
        /**
         * @generated from field: context_engine.DcsMetadataApiInteractionResponse value_dcs_metadata_api_interaction_response = 114;
         */
        value: DcsMetadataApiInteractionResponse;
        case: 'valueDcsMetadataApiInteractionResponse';
      }
    | {
        /**
         * @generated from field: string value_agent_id = 115;
         */
        value: string;
        case: 'valueAgentId';
      }
    | {
        /**
         * @generated from field: context_engine.Question value_question = 116;
         */
        value: Question;
        case: 'valueQuestion';
      }
    | {
        /**
         * @generated from field: context_engine.AnswersWorkflowError value_exception = 117;
         */
        value: AnswersWorkflowError;
        case: 'valueException';
      }
    | {
        /**
         * @generated from field: context_engine.Scores value_answer_scores = 118;
         */
        value: Scores;
        case: 'valueAnswerScores';
      }
    | {
        /**
         * @generated from field: context_engine.AnswersWorkflowAnswerSelectionReason value_answer_selected_reason = 119;
         */
        value: AnswersWorkflowAnswerSelectionReason;
        case: 'valueAnswerSelectedReason';
      }
    | {
        /**
         * @generated from field: context_engine.GetAnswersForQueryInputArg value_get_answers_for_query_input_arg = 120;
         */
        value: GetAnswersForQueryInputArg;
        case: 'valueGetAnswersForQueryInputArg';
      }
    | {
        /**
         * @generated from field: string value_dcs_search_result_json_str = 121;
         */
        value: string;
        case: 'valueDcsSearchResultJsonStr';
      }
    | {
        /**
         * @generated from field: string value_user_id = 122;
         */
        value: string;
        case: 'valueUserId';
      }
    | {
        /**
         * @generated from field: string value_query = 123;
         */
        value: string;
        case: 'valueQuery';
      }
    | {
        /**
         * @generated from field: string value_keywords_str = 124;
         */
        value: string;
        case: 'valueKeywordsStr';
      }
    | {
        /**
         * @generated from field: string value_dcs_search_results_json_str = 125;
         */
        value: string;
        case: 'valueDcsSearchResultsJsonStr';
      }
    | {
        /**
         * @generated from field: string value_growthbook_feature_variant = 126;
         */
        value: string;
        case: 'valueGrowthbookFeatureVariant';
      }
    | {
        /**
         * @generated from field: context_engine.AnswersWorkflowEmptyAnswerReason value_empty_answer_reason = 127;
         */
        value: AnswersWorkflowEmptyAnswerReason;
        case: 'valueEmptyAnswerReason';
      }
    | {
        /**
         * @generated from field: context_engine.Answer value_answer = 128;
         */
        value: Answer;
        case: 'valueAnswer';
      }
    | {
        /**
         * @generated from field: context_engine.AnswerInfo value_answer_info = 129;
         */
        value: AnswerInfo;
        case: 'valueAnswerInfo';
      }
    | {
        /**
         * @generated from field: context_engine.GetResponseInputArg value_get_response_input_arg = 130;
         */
        value: GetResponseInputArg;
        case: 'valueGetResponseInputArg';
      }
    | {
        /**
         * @generated from field: context_engine.ConversationsUserMessage value_user_message = 131;
         */
        value: ConversationsUserMessage;
        case: 'valueUserMessage';
      }
    | {
        /**
         * @generated from field: context_engine.ConversationsChatMessage value_message = 132;
         */
        value: ConversationsChatMessage;
        case: 'valueMessage';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AnswersWorkflowProcessValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AnswersWorkflowProcessValue';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'description',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 3,
      name: 'created_at_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 100,
      name: 'value_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 101,
      name: 'value_int',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
      oneof: 'value',
    },
    {
      no: 102,
      name: 'value_float',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
      oneof: 'value',
    },
    {
      no: 103,
      name: 'value_bool',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
      oneof: 'value',
    },
    {
      no: 104,
      name: 'value_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 105,
      name: 'value_error_message',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 106,
      name: 'value_document_info',
      kind: 'message',
      T: DocumentInfo,
      oneof: 'value',
    },
    {
      no: 107,
      name: 'value_dcs_search_api_interaction_arg',
      kind: 'message',
      T: DcsSearchApiInteractionArg,
      oneof: 'value',
    },
    {
      no: 108,
      name: 'value_dcs_search_api_interaction_response',
      kind: 'message',
      T: DcsSearchApiInteractionResponse,
      oneof: 'value',
    },
    {
      no: 109,
      name: 'value_prompt_message',
      kind: 'message',
      T: PromptMessage,
      oneof: 'value',
    },
    {
      no: 110,
      name: 'value_chat_completion_input_signal',
      kind: 'message',
      T: ChatCompletionInputSignal,
      oneof: 'value',
    },
    {
      no: 111,
      name: 'value_text_generation_input_signal',
      kind: 'message',
      T: TextGenerationInputSignal,
      oneof: 'value',
    },
    {
      no: 112,
      name: 'value_answer_rejected_reason',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowAnswerRejectionReason),
      oneof: 'value',
    },
    {
      no: 113,
      name: 'value_dcs_metadata_api_interaction_arg',
      kind: 'message',
      T: DcsMetadataApiInteractionArg,
      oneof: 'value',
    },
    {
      no: 114,
      name: 'value_dcs_metadata_api_interaction_response',
      kind: 'message',
      T: DcsMetadataApiInteractionResponse,
      oneof: 'value',
    },
    {
      no: 115,
      name: 'value_agent_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 116,
      name: 'value_question',
      kind: 'message',
      T: Question,
      oneof: 'value',
    },
    {
      no: 117,
      name: 'value_exception',
      kind: 'message',
      T: AnswersWorkflowError,
      oneof: 'value',
    },
    {
      no: 118,
      name: 'value_answer_scores',
      kind: 'message',
      T: Scores,
      oneof: 'value',
    },
    {
      no: 119,
      name: 'value_answer_selected_reason',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowAnswerSelectionReason),
      oneof: 'value',
    },
    {
      no: 120,
      name: 'value_get_answers_for_query_input_arg',
      kind: 'message',
      T: GetAnswersForQueryInputArg,
      oneof: 'value',
    },
    {
      no: 121,
      name: 'value_dcs_search_result_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 122,
      name: 'value_user_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 123,
      name: 'value_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 124,
      name: 'value_keywords_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 125,
      name: 'value_dcs_search_results_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 126,
      name: 'value_growthbook_feature_variant',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'value',
    },
    {
      no: 127,
      name: 'value_empty_answer_reason',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowEmptyAnswerReason),
      oneof: 'value',
    },
    {
      no: 128,
      name: 'value_answer',
      kind: 'message',
      T: Answer,
      oneof: 'value',
    },
    {
      no: 129,
      name: 'value_answer_info',
      kind: 'message',
      T: AnswerInfo,
      oneof: 'value',
    },
    {
      no: 130,
      name: 'value_get_response_input_arg',
      kind: 'message',
      T: GetResponseInputArg,
      oneof: 'value',
    },
    {
      no: 131,
      name: 'value_user_message',
      kind: 'message',
      T: ConversationsUserMessage,
      oneof: 'value',
    },
    {
      no: 132,
      name: 'value_message',
      kind: 'message',
      T: ConversationsChatMessage,
      oneof: 'value',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AnswersWorkflowProcessValue {
    return new AnswersWorkflowProcessValue().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AnswersWorkflowProcessValue {
    return new AnswersWorkflowProcessValue().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AnswersWorkflowProcessValue {
    return new AnswersWorkflowProcessValue().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | AnswersWorkflowProcessValue
      | PlainMessage<AnswersWorkflowProcessValue>
      | undefined,
    b:
      | AnswersWorkflowProcessValue
      | PlainMessage<AnswersWorkflowProcessValue>
      | undefined,
  ): boolean {
    return proto3.util.equals(AnswersWorkflowProcessValue, a, b);
  }
}

/**
 * @generated from message context_engine.AnswersWorkflowError
 */
export class AnswersWorkflowError extends Message<AnswersWorkflowError> {
  /**
   * @generated from field: context_engine.AnswersWorkflowErrorCode error_code = 1;
   */
  errorCode = AnswersWorkflowErrorCode.UNKNOWN_ERROR;

  /**
   * @generated from field: string message = 2;
   */
  message = '';

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = '';

  /**
   * @generated from field: int32 status_code = 4;
   */
  statusCode = 0;

  /**
   * map fields like map<string, string> are not allowed in oneof.
   * We can use map<string, Any> outside as a separate message but we have restrictions on using Any.
   *
   * @generated from field: string additional_info_json_str = 5;
   */
  additionalInfoJsonStr = '';

  /**
   * @generated from field: string created_at_timestamp = 6;
   */
  createdAtTimestamp = '';

  constructor(data?: PartialMessage<AnswersWorkflowError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AnswersWorkflowError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'error_code',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowErrorCode),
    },
    { no: 2, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'display_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 4, name: 'status_code', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    {
      no: 5,
      name: 'additional_info_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 6,
      name: 'created_at_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AnswersWorkflowError {
    return new AnswersWorkflowError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AnswersWorkflowError {
    return new AnswersWorkflowError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AnswersWorkflowError {
    return new AnswersWorkflowError().fromJsonString(jsonString, options);
  }

  static equals(
    a: AnswersWorkflowError | PlainMessage<AnswersWorkflowError> | undefined,
    b: AnswersWorkflowError | PlainMessage<AnswersWorkflowError> | undefined,
  ): boolean {
    return proto3.util.equals(AnswersWorkflowError, a, b);
  }
}

/**
 * @generated from message context_engine.AnswersWorkflowProcess
 */
export class AnswersWorkflowProcess extends Message<AnswersWorkflowProcess> {
  /**
   * @generated from field: string process_id = 1;
   */
  processId = '';

  /**
   * @generated from field: context_engine.AnswersWorkflowProcessName name = 2;
   */
  name = AnswersWorkflowProcessName.UNKNOWN_PROCESS;

  /**
   * @generated from field: repeated context_engine.AnswersWorkflowProcessValue inputs = 3;
   */
  inputs: AnswersWorkflowProcessValue[] = [];

  /**
   * @generated from field: repeated context_engine.AnswersWorkflowProcessValue outputs = 4;
   */
  outputs: AnswersWorkflowProcessValue[] = [];

  /**
   * @generated from field: repeated context_engine.AnswersWorkflowError errors = 5;
   */
  errors: AnswersWorkflowError[] = [];

  /**
   * @generated from field: repeated context_engine.AnswersWorkflowProcess processes = 6;
   */
  processes: AnswersWorkflowProcess[] = [];

  /**
   * @generated from field: string execution_start_timestamp = 7;
   */
  executionStartTimestamp = '';

  /**
   * We will add more fields here as needed.
   *
   * @generated from field: string execution_end_timestamp = 8;
   */
  executionEndTimestamp = '';

  constructor(data?: PartialMessage<AnswersWorkflowProcess>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AnswersWorkflowProcess';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'process_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'name',
      kind: 'enum',
      T: proto3.getEnumType(AnswersWorkflowProcessName),
    },
    {
      no: 3,
      name: 'inputs',
      kind: 'message',
      T: AnswersWorkflowProcessValue,
      repeated: true,
    },
    {
      no: 4,
      name: 'outputs',
      kind: 'message',
      T: AnswersWorkflowProcessValue,
      repeated: true,
    },
    {
      no: 5,
      name: 'errors',
      kind: 'message',
      T: AnswersWorkflowError,
      repeated: true,
    },
    {
      no: 6,
      name: 'processes',
      kind: 'message',
      T: AnswersWorkflowProcess,
      repeated: true,
    },
    {
      no: 7,
      name: 'execution_start_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 8,
      name: 'execution_end_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AnswersWorkflowProcess {
    return new AnswersWorkflowProcess().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AnswersWorkflowProcess {
    return new AnswersWorkflowProcess().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AnswersWorkflowProcess {
    return new AnswersWorkflowProcess().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AnswersWorkflowProcess
      | PlainMessage<AnswersWorkflowProcess>
      | undefined,
    b:
      | AnswersWorkflowProcess
      | PlainMessage<AnswersWorkflowProcess>
      | undefined,
  ): boolean {
    return proto3.util.equals(AnswersWorkflowProcess, a, b);
  }
}

/**
 * @generated from message context_engine.Diagnostics
 */
export class Diagnostics extends Message<Diagnostics> {
  /**
   * @generated from field: repeated context_engine.AnswersWorkflowProcess processes = 1;
   */
  processes: AnswersWorkflowProcess[] = [];

  /**
   * @generated from field: string execution_start_timestamp = 2;
   */
  executionStartTimestamp = '';

  /**
   * @generated from field: string execution_end_timestamp = 3;
   */
  executionEndTimestamp = '';

  /**
   * @generated from field: string request_id = 4;
   */
  requestId = '';

  /**
   * @generated from field: string user_id = 5;
   */
  userId = '';

  /**
   * Unused
   *
   * @generated from field: context_engine.GetAnswersForQueryInputArg get_answers_for_query_input_arg = 6;
   */
  getAnswersForQueryInputArg?: GetAnswersForQueryInputArg;

  /**
   * Add Agent Execution Diagnostics here when needed.
   *
   * @generated from field: string search_query = 7;
   */
  searchQuery = '';

  constructor(data?: PartialMessage<Diagnostics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.Diagnostics';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'processes',
      kind: 'message',
      T: AnswersWorkflowProcess,
      repeated: true,
    },
    {
      no: 2,
      name: 'execution_start_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 3,
      name: 'execution_end_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 4, name: 'request_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'user_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 6,
      name: 'get_answers_for_query_input_arg',
      kind: 'message',
      T: GetAnswersForQueryInputArg,
    },
    {
      no: 7,
      name: 'search_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): Diagnostics {
    return new Diagnostics().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): Diagnostics {
    return new Diagnostics().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): Diagnostics {
    return new Diagnostics().fromJsonString(jsonString, options);
  }

  static equals(
    a: Diagnostics | PlainMessage<Diagnostics> | undefined,
    b: Diagnostics | PlainMessage<Diagnostics> | undefined,
  ): boolean {
    return proto3.util.equals(Diagnostics, a, b);
  }
}
