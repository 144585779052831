import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { getDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import { getRoutePathname } from '@mirage/shared/util/route-pathname';

import type { ActionSurfaceComponent } from '../events/enums/action_surface_component';
import type { DashActionSurface } from '../events/enums/dash_action_surface';
import type { DashSurface } from '../events/enums/dash_surface';
import type { FeatureLine } from '../events/enums/feature_line';
/**
 * The Product Analytics hierarch is how DS has standardized element and feature identification
 * These values allow DS to roll-up events in useful ways for analysis
 * Our uxa naming convention uses these values to create a unique identifier for each element
 * https://www.dropbox.com/scl/fi/n3ls0bz6r8968txf2nlwq/Event-Logging-Design-Proposal-for-Hierarchy-Values.paper?rlkey=ly7n9mk43fdz2hlkoxrdnld81&dl=0
 *
 **/

// used as a fallback for optional fields so that the element id can be parsed easily in post-processing
export type NotApplicable = 'n_a';

type AnalyticsHierarchy = {
  dashSurface: DashSurface;
  actionSurface: DashActionSurface;
  actionSurfaceComponent: ActionSurfaceComponent | NotApplicable;
  featureLine: FeatureLine | NotApplicable;
};

function generateUxaElementId(
  elementName: string,
  hierarchy: AnalyticsHierarchy,
) {
  const { dashSurface, actionSurface, actionSurfaceComponent, featureLine } =
    hierarchy;
  return `${dashSurface}.${actionSurface}.${actionSurfaceComponent}.${featureLine}.${elementName}`;
}

export function createUxaElementId(
  elementName: string,
  hierarchyOverrides: Partial<AnalyticsHierarchy>,
) {
  const pathname = getRoutePathname();
  const dashActionSurface = getDashActionSurface(pathname) || 'start_page';

  return generateUxaElementId(elementName, {
    dashSurface: EnvCtx.surface,
    actionSurface: dashActionSurface,
    actionSurfaceComponent: 'n_a',
    featureLine: 'n_a',
    ...hierarchyOverrides,
  });
}
