import { listConnections } from '@mirage/service-connectors';
import { ProfilingQuestionKindOfWorkAnswer } from '@mirage/service-onboarding-surveys/service/onboarding-surveys/self-serve-profiling';
import { filterBrowserExtensionConnections } from '@mirage/settings/hooks/connectors/useConnectConnector';
import { fetchConnectorsWithVisibilityFilters } from '@mirage/settings/utils/connectorMetadataService';
import { useSetConnections } from '@mirage/shared/atoms/connections';
import { useSetConnectors } from '@mirage/shared/atoms/connectors';
import { useEffect } from 'react';
import { useOnboardingConnectors } from './useOnboardingConnectors';

import type { dash_connectors } from '@dropbox/api-v2-client';
import type { UIConnection, UIConnector } from '@mirage/shared/types';

// Connections that are available regardless of work type
const ALL_CONNECTORS = [
  'Outlook',
  'Google Calendar',
  'Gmail',
  'OneNote',
  'DBX Paper',
  'Zoom',
  'Box',
  'Microsoft OneNote',
];

const WORKTYPE_TO_CONNECTION_MAP: Map<
  ProfilingQuestionKindOfWorkAnswer,
  string[]
> = new Map([
  [
    ProfilingQuestionKindOfWorkAnswer.EngineeringProductDesign,
    [
      'Notion',
      'Smartsheet',
      'Github',
      'Miro',
      'Lucid',
      'Zendesk',
      'GitLab',
      'ClickUp',
      'Wordpress',
      'Quip',
      'Monday',
      'Basecamp',
      'Linear',
      'Aha!',
    ],
  ],
  [
    ProfilingQuestionKindOfWorkAnswer.Marketing,
    [
      'Notion',
      'Smartsheet',
      'Asana',
      'Miro',
      'Lucid',
      'Figma',
      'HubSpot',
      'Zendesk',
      'Canva',
      'Freshworks',
      'ClickUp',
      'Wordpress',
      'Quip',
      'Monday',
      'Basecamp',
    ],
  ],
  [
    ProfilingQuestionKindOfWorkAnswer.Operations,
    [
      'Smartsheet',
      'Asana',
      'Lucid',
      'HubSpot',
      'Zendesk',
      'Freshworks',
      'ClickUp',
      'Monday',
      'Basecamp',
      'Outreach',
    ],
  ],
  [
    ProfilingQuestionKindOfWorkAnswer.Sales,
    [
      'Notion',
      'HubSpot',
      'Zendesk',
      'Canva',
      'Outreach',
      'Freshworks',
      'ClickUp',
      'Quip',
      'Monday',
      'Basecamp',
    ],
  ],
  [
    ProfilingQuestionKindOfWorkAnswer.InformationTechnology,
    [
      'Notion',
      'Asana',
      'Github',
      'Miro',
      'Lucid',
      'Figma',
      'Zendesk',
      'Freshworks',
      'GitLab',
      'ClickUp',
      'Wordpress',
      'Monday',
      'Basecamp',
    ],
  ],
  [
    ProfilingQuestionKindOfWorkAnswer.FinancialOrStrategicAdvisor,
    ['Smartsheet'],
  ],
  [
    ProfilingQuestionKindOfWorkAnswer.Human_resources,
    ['ClickUp', 'Monday', 'Basecamp'],
  ],
]);

const filterForConnectedConnectors = (
  connectors: (UIConnector | UIConnection)[],
) => {
  return connectors.filter((connectionOrConnector) => {
    const connector = connectionOrConnector as dash_connectors.Connection;
    return connector.connection_status?.['.tag'] === 'connected';
  });
};

const filterSuggestedConnectors = (
  allConnectors: (UIConnector | UIConnection)[],
  workType?: ProfilingQuestionKindOfWorkAnswer,
) => {
  const connectorMap = workType ? WORKTYPE_TO_CONNECTION_MAP.get(workType) : [];

  return allConnectors.filter((connector) => {
    const displayName = connector.branding?.display_name;
    if (!displayName) return false;
    if (ALL_CONNECTORS.includes(displayName)) return true;
    return connectorMap?.includes(displayName) || false;
  });
};

const removeExistingConnectors = (
  suggestedConnectors: (UIConnector | UIConnection)[],
  existingConnectors: (UIConnector | UIConnection)[],
) => {
  return suggestedConnectors.filter((suggestedConnector) => {
    const displayName = suggestedConnector.branding?.display_name;
    return !existingConnectors.some(
      (existingConnector) =>
        existingConnector.branding?.display_name === displayName,
    );
  });
};

export const useSuggestedConnectors = (
  workType?: ProfilingQuestionKindOfWorkAnswer,
) => {
  const setConnections = useSetConnections();
  const setConnectors = useSetConnectors();
  const onboardingConnectors = useOnboardingConnectors();

  useEffect(() => {
    async function doFetchConnectors() {
      setConnectors((atom) => ({ ...atom, loading: true }));
      try {
        const connectors = await fetchConnectorsWithVisibilityFilters();
        setConnectors({
          data: connectors.map((c) => ({ ...c, loading: false })),
          loading: false,
          loaded: true,
        });
      } catch {
        setConnectors({
          data: [],
          loading: false,
          loaded: true,
        });
      }
    }
    doFetchConnectors();

    async function doFetchConnections() {
      setConnections((atom) => ({ ...atom, loading: true }));
      try {
        const connections = await listConnections();
        setConnections({
          data: connections
            .map((c) => ({
              ...c,
              loading: false,
              syncing: false,
            }))
            .filter(filterBrowserExtensionConnections),
          loading: false,
          loaded: true,
        });
      } catch {
        setConnections({
          data: [],
          loading: false,
          loaded: true,
        });
      }
    }
    doFetchConnections();
  }, [setConnectors, setConnections]);

  const allConnectors: (UIConnector | UIConnection)[] = onboardingConnectors
    .map((connector) => ({
      ...connector,
      loading: false,
    }))
    .sort((a, b) => {
      if (a.branding?.display_name && b.branding?.display_name) {
        return a.branding.display_name.localeCompare(b.branding.display_name);
      } else {
        return 0;
      }
    });

  const connectors = filterForConnectedConnectors(allConnectors);
  const suggestedConnectors = removeExistingConnectors(
    filterSuggestedConnectors(allConnectors, workType),
    connectors,
  );

  return { connectors, allConnectors, suggestedConnectors };
};
