import {
  DemoUrlParams,
  getDemoFileInfo,
  getDemoPrompt,
  isDemoFileName,
  isDemoPromptType,
} from '@mirage/growth/onboarding/assistant-demo/demo-contents';
import { processFile } from '@mirage/mosaics/Chat/utils/fileProcessing';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { getPdfFileFromUrl } from '@mirage/shared/hooks/usePdfFileFromUrl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { PostUserMessageParams } from '../data/current-session/ComposeCurrentSessionPostMessage';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type {
  DemoFileInfo,
  DemoFileName,
} from '@mirage/growth/onboarding/assistant-demo/demo-contents';
import type { ChatSource } from '@mirage/mosaics/Chat/types';

export const useOnboardingMode = ({
  addSource,
  logComposeEvent,
  postUserMessage,
  setCustomMode,
  setDidJustSendFirstWriteCommand,
  setIsAddSourceModalOpen,
  sources,
  currentSessionDataId,
}: {
  addSource: (source: ChatSource) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  postUserMessage: (messageParams: PostUserMessageParams) => void;
  setCustomMode: (customMode: 'onboarding' | 'other' | undefined) => void;
  setDidJustSendFirstWriteCommand?: (
    didJustSendFirstWriteCommand: boolean,
  ) => void;
  setIsAddSourceModalOpen: (isOpen: boolean) => void;
  sources: ChatSource[];
  currentSessionDataId: string;
}) => {
  const featureEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2025_03_03_assistant_onboarding_demo'),
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [onboardingMode, setOnboardingMode] = useState(false);
  const [demoPrompt, setDemoPrompt] = useState<string>('');
  const onboardingModePromptSentRef = useRef(false);

  const cleanUpUrlParams = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(DemoUrlParams.ONBOARDING);
    newSearchParams.delete(DemoUrlParams.DEMO);
    newSearchParams.delete(DemoUrlParams.ACTION);
    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams]);

  /**
   * if onboarding=true exist in url params, then we start onboarding mode
   * prompt & demo files are controlled by `demo` & `action` url params
   * if demo=upload exist in url params, then we show add source modal to upload file instead of automatically adding demo file
   */
  useEffect(() => {
    const onboarding = searchParams.get(DemoUrlParams.ONBOARDING) === 'true';
    const demo = searchParams.get(DemoUrlParams.DEMO);
    const action = searchParams.get(DemoUrlParams.ACTION);
    if (onboarding && featureEnabled) {
      // It is important to set onboardingMode first/early because this value is used from parent component
      // to suppress some default UI and setup of Dash Assistant
      setOnboardingMode(true);
      const { fileInfo, prompt } = getOnboardingDemoFileAndPrompt({
        demo,
        action,
      });
      if (prompt === undefined) {
        // As url params are invalid, we clean them up and fail silently
        cleanUpUrlParams();
        setOnboardingMode(false);
      } else {
        // set custom mode for Dash Assistant chat session
        setCustomMode('onboarding');
        if (fileInfo === undefined) {
          // Show add source modal so that user can upload file or search a file
          setDemoPrompt(prompt);
          setIsAddSourceModalOpen(true);
        } else {
          // TODO: handle error from `getPdfFileFromUrl` by returning it to the caller & show it to user
          getPdfFileFromUrl(fileInfo.url, fileInfo.title).then(({ file }) => {
            if (file) {
              // TODO: look for error returned from `processFile` and handle it
              processFile(file, addSource, logComposeEvent).then(() => {
                setDemoPrompt(prompt);
              });
            }
          });
        }
      }
    }
  }, [featureEnabled, setIsAddSourceModalOpen]);

  // Wait for demo file to be added to session correctly before we post demo prompt
  useEffect(() => {
    // Non-empty `currentSessionDataId` indicates that chat session is successfully created on server-side
    //  as demo file is added to the session successfully.
    // Checking for demoPrompt & sources.length just to make sure that demo files are added to the session
    if (
      demoPrompt !== '' &&
      sources.length > 0 &&
      currentSessionDataId !== '' &&
      onboardingModePromptSentRef.current === false
    ) {
      onboardingModePromptSentRef.current = true;
      setIsAddSourceModalOpen(false);
      postUserMessage({
        text: demoPrompt,
      });
      setDidJustSendFirstWriteCommand?.(true);

      // remove url params for onboarding so that reloading of page wouldn't start brand new onboarding chat session
      cleanUpUrlParams();
    }
  }, [
    sources.length,
    postUserMessage,
    onboardingModePromptSentRef,
    setDidJustSendFirstWriteCommand,
    setIsAddSourceModalOpen,
    demoPrompt,
    cleanUpUrlParams,
    currentSessionDataId,
  ]);

  return {
    onboardingMode,
  };
};

type DemoFileAndPrompt = {
  fileInfo?: DemoFileInfo;
  prompt?: string;
};

export type DemoAction = 'summarize' | 'analyze' | 'brainstorm';

/**
 * This function returns the demo file and prompt pair for the onboarding mode
 * If invalid action is provided, it returns empty object
 * If demo=upload or demo file doesn't exist, it returns prompt only
 * @param demo - The demo file name
 * @param action - The action to be performed
 * @returns The demo file and prompt pair
 */
export const getOnboardingDemoFileAndPrompt = ({
  demo = 'upload',
  action,
}: {
  demo: string | null;
  action: string | null;
}): DemoFileAndPrompt => {
  if (action !== null && isDemoPromptType(action)) {
    const prompt = getDemoPrompt(action);
    if (demo === 'upload') {
      return { prompt };
    }
    if (demo !== null && isDemoFileName(demo)) {
      const fileInfo = getDemoFileInfo(demo as DemoFileName);
      return { fileInfo, prompt };
    } else {
      // If file info doesn't exist, behavior would be the same as demo=upload
      return { prompt };
    }
  }
  return {};
};
