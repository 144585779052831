import { Title } from '@dropbox/dig-components/typography';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { useStackChat } from '@mirage/service-experimentation/useStackChat';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import styles from '../EditStackDetails.module.css';
import { Expand } from './Expand';

interface ReadOnlyModeNameProps {
  onClick: () => void;
  stackName: string;
}

export const ReadOnlyModeName: React.FC<ReadOnlyModeNameProps> = ({
  onClick,
  stackName,
}) => {
  const [isNameExpanded, setIsNameExpanded] = useState(false);
  const [isNameExpandable, setIsNameExpandable] = useState(false);
  const isAugRev = useStackPageAugustRevisionEnabled();
  const isStackChatEnabled = useStackChat();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    [onClick],
  );

  if (isAugRev) {
    return (
      <Title
        className={classnames(styles.stackNameAugRev, styles.alignSeeMore)}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        size={isStackChatEnabled ? 'large' : 'medium'}
        role="button"
        tabIndex={0}
        aria-label={i18n.t('edit_stack_title')}
        weightVariant="emphasized"
      >
        {stackName}
      </Title>
    );
  }

  return (
    <>
      <Title
        className={classnames(styles.stackName, styles.alignSeeMore)}
        onClick={onClick}
        size="large"
        weightVariant="emphasized"
      >
        {isNameExpanded ? (
          stackName
        ) : (
          <DashTruncateWithTooltip
            lines={3}
            onBeforeTruncate={setIsNameExpandable}
          >
            {stackName}
          </DashTruncateWithTooltip>
        )}
      </Title>
      {(isNameExpanded || isNameExpandable) && (
        <Expand isExpanded={isNameExpanded} setIsExpanded={setIsNameExpanded} />
      )}
    </>
  );
};
