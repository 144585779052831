import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashGettingStartedChecklist } from '@mirage/analytics/events/types/dismiss_dash_getting_started_checklist';
import { PAP_Open_DashGettingStartedChecklistItem } from '@mirage/analytics/events/types/open_dash_getting_started_checklist_item';
import { PAP_Shown_DashGettingStartedChecklist } from '@mirage/analytics/events/types/shown_dash_getting_started_checklist';
import { useShouldShowWelcomeModal } from '@mirage/growth/onboarding/hooks/useShouldShowWelcomeModal';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import {
  DismissAfterAccountAge,
  useDismissableModule,
} from '@mirage/service-onboarding/useDismissableModule';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import { useSortedStacks } from '@mirage/stacks/hooks';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as modules from '../modules';
import { OnboardingChecklistAtom } from './checklist-atoms';
import { useGetOnboardingChecklistItems } from './useGetOnboardingChecklistItems';

import type {
  OnboardingChecklistStep,
  OnboardingChecklistStepPartial,
} from '../types';
import type { stacks } from '@dropbox/api-v2-client';
import type { DashGettingStartedChecklistItem } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item';

const DISMISSED_GETTING_STARTED_CHECKLIST = 'dismissedGettingStartedChecklist';
const SEEN_GETTING_STARTED_CHECKLIST = 'seenGettingStartedChecklist';
const OPEN_DELAY_ON_LOAD = 1500; // 1.5 seconds
const MARK_SEEN_DELAY = 5000;
const WELCOME_MODAL_VERSION = 1;

const getOnboardingChecklistSteps = (
  extensionAvailableForBrowser: boolean,
  hasGettingStartedStack: boolean,
): OnboardingChecklistStepPartial[] => {
  const checklistItems: OnboardingChecklistStepPartial[] = [];

  checklistItems.push(
    modules.search,
    ...(hasGettingStartedStack ? [modules.getStartedStack] : []),
    modules.createStack,
    extensionAvailableForBrowser ? modules.installApps : modules.installDesktop,
  );

  return checklistItems.map((item, idx) => ({ ...item, id: idx }));
};

function useFetchChecklistItems(
  displayed: boolean,
  setOpen: (open: boolean) => void,
) {
  const { checklistItems, checklistSteps, refreshChecklistItems } =
    useOnboardingChecklistItems();
  const { getOnboardingValue } = useOnboardingValues();

  useEffect(() => {
    const fetchShownOnLoad = async () => {
      if (!displayed || !checklistItems?.length) return;

      const seen = await getOnboardingValue(SEEN_GETTING_STARTED_CHECKLIST);
      if (!seen) {
        setTimeout(() => setOpen(true), OPEN_DELAY_ON_LOAD);
      }
    };

    fetchShownOnLoad();
  }, [
    displayed,
    checklistItems?.length,
    refreshChecklistItems,
    getOnboardingValue,
    setOpen,
    checklistSteps?.length,
  ]);

  return { checklistItems, checklistSteps, refreshChecklistItems };
}

function useDismissChecklist(setDismissed: (val: boolean) => void) {
  const { reportPapEvent } = useMirageAnalyticsContext();

  return useCallback(
    (log = true) => {
      setDismissed(true);
      if (log) {
        reportPapEvent(
          PAP_Dismiss_DashGettingStartedChecklist({
            actionSurfaceComponent: 'getting_started_checklist',
            featureLine: 'getting_started_checklist',
          }),
        );
      }
    },
    [reportPapEvent, setDismissed],
  );
}

function useChecklistState(
  checklistItems: OnboardingChecklistStep[] | undefined,
  setOpen: (open: boolean) => void,
) {
  const previousIncompleteItem = useRef('');
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [openId, setOpenId] = useState('');

  const handleOpenId = useCallback(
    (moduleId: string) => {
      setOpenId(moduleId);

      reportPapEvent(
        PAP_Open_DashGettingStartedChecklistItem({
          dashGettingStartedChecklistItem:
            moduleId as DashGettingStartedChecklistItem,
          actionSurfaceComponent: 'getting_started_checklist',
          featureLine: 'getting_started_checklist',
        }),
      );
    },
    [reportPapEvent],
  );

  useEffect(() => {
    if (!checklistItems?.length) return;

    const firstIncompleteItem = checklistItems.find((m) => !m.isComplete);
    if (
      firstIncompleteItem &&
      firstIncompleteItem?.moduleId !== previousIncompleteItem.current
    ) {
      handleOpenId(firstIncompleteItem.moduleId);
      previousIncompleteItem.current = firstIncompleteItem.moduleId;
      setOpen(true);
    }
  }, [checklistItems, handleOpenId, setOpen]);

  return { openId, setOpenId };
}

function useChecklistDisplayState(
  isWelcomeModalShowing: boolean | undefined,
  dismissed: boolean,
) {
  const displayed =
    !isWelcomeModalShowing && isWelcomeModalShowing !== undefined && !dismissed;

  return displayed;
}

export default function useOnboardingChecklist() {
  const dismissBehavior = useMemo(() => new DismissAfterAccountAge('3d'), []);
  const { dismissed, setDismissed } = useDismissableModule(
    DISMISSED_GETTING_STARTED_CHECKLIST,
    dismissBehavior,
  );
  const { setOnboardingValue } = useOnboardingValues();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isWelcomeModalShowing = useShouldShowWelcomeModal(
    WELCOME_MODAL_VERSION,
  );
  const displayed = useChecklistDisplayState(isWelcomeModalShowing, dismissed);
  const [open, setOpen] = useState(false);
  const { checklistItems, refreshChecklistItems, checklistSteps } =
    useFetchChecklistItems(displayed, setOpen);
  const { progress } = useChecklistProgress(checklistItems);
  const handleDismissed = useDismissChecklist(setDismissed);
  const { openId, setOpenId } = useChecklistState(checklistItems, setOpen);

  useEffect(() => {
    if (!isWelcomeModalShowing && isWelcomeModalShowing !== undefined) {
      setOpen(true);
    }
  }, [isWelcomeModalShowing]);

  const handleShown = () => {
    setTimeout(
      () => setOnboardingValue(SEEN_GETTING_STARTED_CHECKLIST, true),
      MARK_SEEN_DELAY,
    );
    reportPapEvent(
      PAP_Shown_DashGettingStartedChecklist({
        actionSurfaceComponent: 'getting_started_checklist',
        featureLine: 'getting_started_checklist',
      }),
    );
  };

  return {
    checklistItems,
    checklistSteps,
    refreshChecklistItems,
    displayed,
    progress,
    open,
    openId,
    setDismissed: handleDismissed,
    setOpenId,
    setOpen,
    handleShown,
  };
}

export function useOnboardingChecklistItems() {
  const stacks = useSortedStacks();
  const checklistItems = useAtomValue(OnboardingChecklistAtom);
  const [gettingStartedStackExists, setGettingStartedStackExists] =
    useState(false);
  const [gettingStartedStack, setGettingStartedStack] = useState<
    stacks.Stack | undefined
  >(undefined);
  const [checklistSteps, setChecklistSteps] = useState<
    OnboardingChecklistStepPartial[]
  >([]);
  const { extensionAvailableForBrowser } = useAvailableExtensions();

  useEffect(() => {
    const stack = stacks?.find(
      (s) => s.stack_data?.creation_type?.['.tag'] === 'welcome_stack',
    );
    setGettingStartedStack(stack);
    if (stack) {
      setGettingStartedStackExists(true);
    }
  }, [stacks]);

  useEffect(() => {
    const steps = getOnboardingChecklistSteps(
      extensionAvailableForBrowser,
      gettingStartedStackExists,
    );
    setChecklistSteps(steps);
  }, [extensionAvailableForBrowser, gettingStartedStackExists]);

  const { refreshChecklistItems } = useGetOnboardingChecklistItems(
    checklistSteps,
    gettingStartedStack,
  );

  return {
    checklistSteps,
    checklistItems,
    refreshChecklistItems,
  };
}

function useChecklistProgress(checklistItems: OnboardingChecklistStep[]) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!checklistItems || checklistItems.length === 0) {
      setProgress(0);
      return;
    }

    const completeItemCount = checklistItems.filter(
      (item) => item.isComplete,
    ).length;
    const newProgress = (completeItemCount / checklistItems.length) * 100;

    setProgress((prev) => (prev !== newProgress ? newProgress : prev));
  }, [checklistItems]);

  return { progress };
}
