import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashSuggestedLink } from '@mirage/analytics/events/types/open_dash_suggested_link';
import { PAP_Shown_DashSuggestedLink } from '@mirage/analytics/events/types/shown_dash_suggested_link';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { ListItemSize } from '@mirage/link-list/types';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import * as stacksSuggestionsService from '@mirage/service-stack-suggestions';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import {
  activeStackAtom,
  activeStackItemShortcutsAtom,
  activeStackSessionIdAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { atom, useAtom, useAtomValue } from 'jotai';
import { uniqBy } from 'lodash';
import { createRef, useEffect, useRef } from 'react';
import { contentSuggestionToLinkWithMLScore } from '../../Helpers/Utils';

import type { Link } from '@mirage/link-list/types';
import type { TabSuggestion } from '@mirage/stacks/AddStackItemMenuContent/types';
import type { RefObject } from 'react';

export const useOpenSuggestedLink = () => {
  const stack = useAtomValue(activeStackAtom);
  const sessionId = useAtomValue(activeStackSessionIdAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (link: TabSuggestion | Link) => {
    if (stack) {
      reportPapEvent(
        PAP_Open_DashSuggestedLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'content_suggestions',
          predictionIdHash: link.predictionIdHash,
          itemIdHash: link.itemIdHash,
          dashRequestId: link.dashRequestId,
          createStackSessionId: sessionId ?? undefined,
        }),
      );
    }
  };
};

export const useShownContentSuggestions = () => {
  const shownContentSuggestionsRef = useRef('');
  const stack = useAtomValue(activeStackAtom);
  const sessionId = useAtomValue(activeStackSessionIdAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();

  return (links: TabSuggestion[] | Link[]) => {
    const hashId = links.map((link) => link.itemIdHash).join('-');
    if (shownContentSuggestionsRef.current === hashId) {
      return;
    }
    shownContentSuggestionsRef.current = hashId;
    if (!stack || !stack.namespace_id) {
      return;
    }

    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      reportPapEvent(
        PAP_Shown_DashSuggestedLink({
          itemIdHash: link.itemIdHash,
          predictionIdHash: link.predictionIdHash,
          dashRequestId: link.dashRequestId,
          predictionPosition: i,
          dashSuggestedLinkType: 'link',
          dashSuggestionSource: 'content_suggestion',
          featureLine: 'content_suggestions',
          createStackSessionId: sessionId ?? undefined,
          ...stackDerivePAPProps(stack),
        }),
      );
    }
  };
};

export const fetchContentSuggestionsAtom = atom<{
  isFetchingContentSuggestions: boolean;
  contentSuggestions: TabSuggestion[];
}>({
  isFetchingContentSuggestions: false,
  contentSuggestions: [],
});

// Lift this variable outside the atom to make it more like a useRef and not a
// useState thing.
let fetchContentSuggestionNameAndNsId = '';

export const useFetchContentSuggestions = () => {
  const stack = useAtomValue(activeStackAtom);
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const [state, setState] = useAtom(fetchContentSuggestionsAtom);
  const isDarkMode = useTheme();

  useEffect(() => {
    const fetchContentSuggestions = async () => {
      if (!stack?.stack_data?.name || !items) {
        return;
      }

      const nameAndNsId =
        stack.stack_data.name + '\0' + (stack.namespace_id ?? '');

      if (fetchContentSuggestionNameAndNsId === nameAndNsId) {
        return;
      }

      fetchContentSuggestionNameAndNsId = nameAndNsId;
      setState({
        isFetchingContentSuggestions: true,
        contentSuggestions: [],
      });

      const resultApi = await stacksSuggestionsService.getContentSuggestions(
        stack.stack_data?.name,
        items,
      );

      const contentSuggestions = uniqBy(
        resultApi.content_suggestions || [],
        (suggestion) => suggestion?.content?.url ?? '',
      );

      const withMlScore = (contentSuggestions || [])
        .map((suggestion) =>
          contentSuggestionToLinkWithMLScore(suggestion, resultApi.request_id),
        )
        .map((link) => ({
          ...link,
          icon: (
            <FavIcon
              src={faviconSrcForSrcUrl(link.url, 32, isDarkMode)}
              size={ListItemSize.Small}
            />
          ),
        }));

      fetchContentSuggestionNameAndNsId = '';
      setState({
        isFetchingContentSuggestions: false,
        contentSuggestions: withMlScore,
      });
    };

    fetchContentSuggestions();
  }, [setState, stack?.namespace_id, stack?.stack_data?.name, isDarkMode]);

  return state;
};

export const useContentSuggestions = () => {
  return useAtomValue(fetchContentSuggestionsAtom);
};

// Storing this globally allows the hook to be called from any component
// and retrieve the relevant section ref without needing to contain it all
// in one component and prop drill the resulting refs.
let sectionRefsById: { [key: string]: RefObject<HTMLDivElement> } = {};
export const useSectionsNavigation = () => {
  const currentStackId = useRef<string | null>(null);

  const getSectionRef = (sectionId: string) => {
    if (!sectionRefsById[sectionId]) {
      sectionRefsById[sectionId] = createRef<HTMLDivElement>();
    }
    return sectionRefsById[sectionId];
  };

  const scrollToSection = (sectionId: string) => {
    const sectionRef = sectionRefsById[sectionId];
    sectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const resetSectionRefs = (stackId: string) => {
    if (currentStackId.current !== stackId) {
      sectionRefsById = {};
    }
    currentStackId.current = stackId;
  };

  const triggerEditSection = (sectionId: string) => {
    const sectionRef = sectionRefsById[sectionId];
    if (sectionRef.current) {
      sectionRef.current.dispatchEvent(new CustomEvent('edit'));
    }
  };

  return {
    getSectionRef,
    scrollToSection,
    resetSectionRefs,
    triggerEditSection,
  };
};
