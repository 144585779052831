import { PAP_Click_ContentTypeButton } from '@mirage/analytics/events/types/click_content_type_button';
import { type AssistCustomTemplate } from '@mirage/shared/compose/assist-template';
import { memo } from 'react';
import {
  ContextInputContentTypeMessage,
  ContextInputSourcesMessage,
} from './ContextInputMessage';
import { ConversationMessageRow } from './ConversationMessages';

import type { SourcesContentCache } from '../../data/ComposeSourcesCache';
import type { ChatSource } from '../../types';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { ComposeAssistantConversationMessage } from '@mirage/shared/compose/assist-message';
import type {
  ComposeAssistantDraftConfig,
  ContentTypeOption,
} from '@mirage/shared/compose/compose-session';

interface ContextInputMessagesProps {
  sources: ChatSource[];
  sourcesContentCache: SourcesContentCache;
  removeSource: (source: ChatSource) => void;
  draftConfig: ComposeAssistantDraftConfig;
  setDraftConfig: (draftConfig: ComposeAssistantDraftConfig) => void;
  onCompleteInputs?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  handleClickAdd: () => void;
  templates: AssistCustomTemplate[];
  addRawMessage: (message: ComposeAssistantConversationMessage) => void;
  templateSelectedMessage?: ComposeAssistantConversationMessage;
}
export const ContextInputMessages = memo(
  ({
    sources,
    sourcesContentCache,
    removeSource,
    handleClickAdd,
    draftConfig,
    setDraftConfig,
    onCompleteInputs,
    logComposeEvent,
    templates,
    addRawMessage,
    templateSelectedMessage,
  }: ContextInputMessagesProps) => {
    const didSelectContentType =
      draftConfig.contentType || draftConfig.templateID !== undefined;
    return (
      <>
        {!didSelectContentType && (
          <ContextInputContentTypeMessage
            currentContentType={draftConfig.contentType}
            currentTemplate={draftConfig.templateID}
            onContentTypeChange={(contentType?: ContentTypeOption | null) => {
              setDraftConfig({
                ...draftConfig,
                contentType,
              });
              logComposeEvent(
                PAP_Click_ContentTypeButton({
                  composeContentType: contentType || undefined,
                }),
              );
            }}
            onTemplateChange={(
              templateID: string | null,
              templateName: string,
            ) => {
              setDraftConfig({
                ...draftConfig,
                templateID,
              });
              addRawMessage({
                type: 'message',
                role: 'user',
                text: templateName,
                ts: Date.now(),
                ignoreMessageForPrompt: true,
                actionContext: { type: 'template_selected' },
              });
            }}
            didSelectContentType={didSelectContentType}
            templates={templates}
          />
        )}
        {didSelectContentType && (
          <>
            {/* When the user selects a template from step one, render it separately above the context inputs */}
            {templateSelectedMessage && (
              <ConversationMessageRow
                variant="default"
                isLastMessage={false}
                message={templateSelectedMessage}
                onRemoveSource={() => {}}
                onOpenArtifact={() => {}}
                logComposeEvent={logComposeEvent}
              />
            )}
            <ContextInputSourcesMessage
              logComposeEvent={logComposeEvent}
              sources={sources}
              sourcesContentCache={sourcesContentCache}
              handleClickAdd={handleClickAdd}
              removeSource={removeSource}
              onDone={onCompleteInputs}
            />
          </>
        )}
      </>
    );
  },
);
ContextInputMessages.displayName = 'ContextInputMessages';
