import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Add_DashConnectedAccount } from '@mirage/analytics/events/types/add_dash_connected_account';
import { listConnections } from '@mirage/service-connectors';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { useSetRecentConnectionIds } from '@mirage/settings/atoms/recentConnections';
import {
  useConnections,
  useSetConnections,
} from '@mirage/shared/atoms/connections';
import {
  useSetConnector,
  useSetConnectors,
} from '@mirage/shared/atoms/connectors';
import {
  completeJanusOauthConnectionError,
  dashConnectedAccountError,
} from '@mirage/shared/types';
import i18n from '@mirage/translations';
import {
  connectionEnabled,
  fetchConnectorsWithVisibilityFilters,
} from '../../utils/connectorMetadataService';
import {
  monitorOAuthPopupBroadcastMessage,
  OAUTH_POPUP_CLOSED,
  openPopup,
} from '../../utils/oauth';

import type { DropboxResponseError, janus_oauth } from '@dropbox/api-v2-client';
import type { dash_connectors } from '@dropbox/api-v2-client/types/dropbox_types';
import type { Add_DashConnectedAccount } from '@mirage/analytics/events/types/add_dash_connected_account';
import type { Connection } from '@mirage/service-dbx-api/service';
import type {
  DashConnectedAccountError,
  UIConnection,
  UIConnector,
} from '@mirage/shared/types';

const logger = tagged('useConnectConnector');

type Options = {
  eventProps?: Add_DashConnectedAccount['properties'];
};

type ConnectConnector = (
  connector: UIConnector,
  promptValues?: Record<string, string>,
  reauth_connection_id?: string,
) => Promise<{
  connectionId: string | null;
  success: boolean;
  message: string;
}>;

// Browser extension connections are now returned from list_connections. These are essentially
// unbranded and not for display and are returned for use elsewhere, so we want to hide
// them in this list
export const filterBrowserExtensionConnections = (
  connection: dash_connectors.Connection,
) => {
  return connection.id_attributes?.connector?.type !== 'browser_extension';
};

export const useConnectConnector = (
  options: Options = {},
): ConnectConnector => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const setConnections = useSetConnections();
  const setRecentConnectionIds = useSetRecentConnectionIds();
  const setConnectors = useSetConnectors();
  const setConnector = useSetConnector();
  const connections = useConnections();
  const { refreshConnectionsList } = useConnectors();

  return async function connectConnector(
    connector: UIConnector,
    promptValues?: Record<string, string>,
    reauth_connection_id?: string,
  ) {
    const connectorType = connector.id_attrs?.type;
    const platform = connector.id_attrs?.platform?.['.tag'] ?? '';
    const connectorName = connector.branding?.display_name;

    reportPapEvent(
      PAP_Add_DashConnectedAccount({
        dashConnectorId: connectorType,
        eventState: 'start',
        ...options.eventProps,
      }),
    );

    let upsertedConnectionId: string | undefined;
    try {
      if (
        !connectorType ||
        !connectorName ||
        !['janus', 'paragon'].includes(platform)
      ) {
        throw dashConnectedAccountError(
          'invalid_connector_properties',
          'Invalid connector properties',
        );
      }

      setConnector(connectorType, { ...connector, loading: true });
      upsertedConnectionId = await upsertJanusConnection(
        connectorType,
        connectorName,
        connector,
        promptValues,
        reauth_connection_id,
      );

      // Fetch updated connections and connectors, and update atoms.
      const [connectionsAfter, connectorsAfter] = await Promise.all([
        listConnections(),
        fetchConnectorsWithVisibilityFilters(),
      ]);

      if (!upsertedConnectionId) {
        // User closed window but connection made have been made
        // check connectionsAfter list to see if the connection exists
        // before throwing an error
        if (
          !hasNewConnection(connections.data, connectionsAfter, connectorType)
        ) {
          throw dashConnectedAccountError(
            'janus_account_auth',
            i18n.t('error_detecting_new_connection', {
              appName: connectorName,
            }),
          );
        }
      } else {
        // Call connectionEnabled to trigger syncing of user data.
        // If the call fails the user will not immediately see data. It will backfill eventually.
        // TODO: (only possible in Janus) use EventBus in the backend to handle this based on server side events and remove the need for this call.
        connectionEnabled(
          upsertedConnectionId,
          connector.id_attrs?.platform,
          connectorType,
        );
        setRecentConnectionIds((atom) => [...atom, upsertedConnectionId!]);
      }
      setConnections({
        data: connectionsAfter
          .map((c) => ({
            ...c,
            loading: false,
            syncing: false,
          }))
          .filter(filterBrowserExtensionConnections),
        loaded: true,
        loading: false,
      });
      setConnectors({
        data: connectorsAfter.map((c) => ({
          ...c,
          loading: false,
          syncing: false,
        })),
        loaded: true,
        loading: false,
      });
    } catch (e) {
      let reason;
      if ((e as DashConnectedAccountError).reason) {
        reason = (e as DashConnectedAccountError).reason;
      }

      if (reason !== 'janus_popup_closed') {
        logger.error('connectConnector', { connectorType, platform, error: e });
      }

      reportPapEvent(
        PAP_Add_DashConnectedAccount({
          dashConnectorId: connectorType,
          eventState: 'failed',
          dashConnectAccountFailureReason: reason,
          ...options.eventProps,
        }),
      );
      return {
        connectionId: null,
        message: (e as Error).message,
        success: false,
      };
    } finally {
      setConnections((atom) => ({ ...atom, loading: false }));
      setConnectors((atom) => ({ ...atom, loading: false }));
      if (connectorType) {
        setConnector(connectorType, { ...connector, loading: false });
      }
      refreshConnectionsList();
    }

    reportPapEvent(
      PAP_Add_DashConnectedAccount({
        dashConnectorId: connectorType,
        eventState: 'success',
        ...options.eventProps,
      }),
    );
    return {
      connectionId: upsertedConnectionId ?? null,
      success: true,
      message: i18n.t('connectors_settings_connected_to_service', {
        appName: connectorName,
      }),
    };
  };
};

export async function upsertJanusConnection(
  connectorType: string,
  connectorName: string,
  connector: UIConnector,
  promptValues?: Record<string, string>,
  reauth_connection_id?: string,
) {
  if (connector.auth_details?.connection_method === 'API_KEY') {
    return upsertJanusApiKeyConnection(connector, promptValues);
  }

  // Open popup, fetch oauth URL, and load the URL in the popup.
  const popup = openPopup(`popup-${connectorType}`);
  if (!popup) {
    throw dashConnectedAccountError(
      'janus_popup_closed',
      i18n.t('could_not_open_popup'),
    );
  }

  const origin = window.location.origin;
  const redirect_uri = `${origin}/authconfirm`;
  const janusUrl = await getOAuthUrlForConnector(
    connector,
    redirect_uri,
    promptValues,
    reauth_connection_id,
  );
  popup.location.href = janusUrl;

  const oAuthPopupResult = await monitorOAuthPopupBroadcastMessage(
    popup,
    origin,
  );
  const { error, connectionId } = oAuthPopupResult;
  if (error || !connectionId) {
    if (error === OAUTH_POPUP_CLOSED) {
      // If popup is closed, connection still may have been made
      // do not throw error and let upstream find connection
      return undefined;
    }
    logger.log('oAuthPopupResult', oAuthPopupResult);

    const reason =
      error === OAUTH_POPUP_CLOSED
        ? 'janus_popup_closed'
        : 'janus_account_auth';
    throw dashConnectedAccountError(
      reason,
      i18n.t('error_detecting_new_connection', {
        appName: connectorName,
      }),
    );
  }
  return connectionId;
}

async function upsertJanusApiKeyConnection(
  connector: UIConnector,
  promptValues?: Record<string, string>,
): Promise<string> {
  if (!connector.id_attrs?.id) throw new Error('Missing connector ID');
  if (!promptValues?.api_key) throw new Error('Missing connector API key');

  const response = await callApiV2('janusOauthCreateApiKeyConnection', {
    connector_id: connector.id_attrs.id,
    configuration: promptValues,
  });
  if (!response.connection_id) throw new Error("Couldn't find connection id");

  return response.connection_id;
}

type CompleteConnectionResult = {
  connection_id?: string;
  connector_id?: string;
};

export async function completeJanusOauthConnection(
  request_id: string,
): Promise<CompleteConnectionResult> {
  try {
    const response = await callApiV2('janusOauthCompleteOauthConnection', {
      request_id: request_id,
    });
    if (!response.connection_id)
      throw completeJanusOauthConnectionError(
        'missing_connection_id',
        'missing connection id',
      );
    return response;
  } catch (err) {
    const e =
      err as DropboxResponseError<janus_oauth.CompleteOauthConnectionError>;

    logger.error('completeJanusOauthConnection failed with error', {
      error: JSON.stringify(e),
    });

    if (
      'error' in e &&
      typeof e.error === 'object' &&
      e.error &&
      'error' in e.error &&
      typeof e.error.error === 'object' &&
      e.error.error &&
      '.tag' in e.error.error &&
      e.error.error['.tag'] == 'email_domain_not_in_allowlist'
    ) {
      throw completeJanusOauthConnectionError(
        'invalid_email_domain',
        'invalid email domain',
      );
    }
    throw completeJanusOauthConnectionError('other', 'other');
  }
}

const getOAuthUrlForConnector = async (
  connector: UIConnector,
  manualCompletionUrl?: string,
  promptValues?: Record<string, string>,
  reauth_connection_id?: string,
) => {
  const type = connector.id_attrs?.type as string;
  const id = connector.id_attrs?.id as string;

  if (!type || !id) {
    throw new Error(`Invalid connector properties: ${type}, ${id}`);
  }

  if (manualCompletionUrl?.startsWith('http://localhost')) {
    manualCompletionUrl = 'https://dash.ai/authconfirm';
  }

  const response = await callApiV2('janusOauthInitiateOauthConnection', {
    connector_id: id,
    reauth_connection: reauth_connection_id,
    configuration: promptValues,
    manual_completion_url: manualCompletionUrl,
  });

  if (!response.oauth_url) throw new Error(i18n.t('error_getting_oauth_url'));
  return response.oauth_url;
};

export const hasNewConnection = (
  originalConnections: UIConnection[],
  newConnections: Connection[],
  targetType: string,
) => {
  const connectionsByType = originalConnections.reduce(
    (accumulator, connection) => {
      const type = connection.id_attributes?.connector?.type ?? 'unknown';
      if (!accumulator[type]) {
        accumulator[type] = 0;
      }
      accumulator[type]++;
      return accumulator;
    },
    {} as Record<string, number>,
  );
  const connectionsAfterByType = newConnections.reduce(
    (accumulator, connection) => {
      const type = connection.id_attributes?.connector?.type ?? 'unknown';
      if (!accumulator[type]) {
        accumulator[type] = 0;
      }
      accumulator[type]++;
      return accumulator;
    },
    {} as Record<string, number>,
  );
  return (
    (connectionsAfterByType[targetType] ?? 0) >
    (connectionsByType[targetType] ?? 0)
  );
};
