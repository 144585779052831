import { Chip } from '@dropbox/dig-components/chip';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ArrowDownLine,
  ArrowUpLine,
  CheckmarkLine,
  ClockLine,
  FilterLine,
  TextStyleLine,
  UnorderedListLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_SortOption } from '@mirage/analytics/events/types/click_sort_option';
import { PAP_Sort_SortOption } from '@mirage/analytics/events/types/sort_sort_option';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook/hook';
import {
  StackSortDirection,
  StackSortOption,
} from '@mirage/service-settings/service/types';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import isEqual from 'lodash/isEqual';
import { memo, useCallback, useMemo } from 'react';
import { StackSortOptionMetadata } from './FullScreenStack/utils';
import styles from './SortIconMenu.module.css';

import type { SortOptionType } from '@mirage/analytics/events/enums/sort_option_type';
import type { SelectableStackSortPreference } from '@mirage/service-settings/service/types';

export const AllStacksPreset: SelectableStackSortPreference[] = [
  {
    option: StackSortOption.VIEWED,
    direction: StackSortDirection.DESC,
  },
  {
    option: StackSortOption.RECENT,
    direction: StackSortDirection.DESC,
  },
  {
    option: StackSortOption.ALPHA,
    direction: StackSortDirection.ASC,
  },
];

export const FullScreenStackPreset: SelectableStackSortPreference[] = [
  {
    option: StackSortOption.SECTION,
  },
  {
    option: StackSortOption.RECENT,
    direction: StackSortDirection.DESC,
  },
  {
    option: StackSortOption.ALPHA,
    direction: StackSortDirection.ASC,
  },
];

const StackSortOptionPapValue: {
  [keys in Exclude<
    StackSortOption,
    StackSortOption.COMPANY_AND_VIEWED
  >]: SortOptionType;
} = {
  [StackSortOption.RECENT]: 'last_modified_desc',
  [StackSortOption.ALPHA]: 'alphabetical_asc',
  [StackSortOption.SECTION]: 'section',
  [StackSortOption.VIEWED]: 'last_viewed_desc',
};

const getSortIcon = (sortOption: StackSortOption) => {
  switch (sortOption) {
    case StackSortOption.ALPHA:
      return TextStyleLine;
    case StackSortOption.SECTION:
      return UnorderedListLine;
    case StackSortOption.RECENT:
      return ClockLine;
    default:
      return FilterLine;
  }
};

export const SortIconMenu = memo(
  ({
    augustRevision,
    sortPreference,
    onSortSelect,
    optionsPreset,
    defaultOption,
    showDropdownIcon = true,
  }: {
    augustRevision: boolean;
    sortPreference?: SelectableStackSortPreference;
    onSortSelect: (sortPreference: SelectableStackSortPreference) => void;
    optionsPreset: SelectableStackSortPreference[];
    defaultOption: SelectableStackSortPreference;
    showDropdownIcon?: boolean;
  }) => {
    const { reportPapEvent } = useMirageAnalyticsContext();
    const { option: sortOption, direction: sortDirection } =
      sortPreference ?? defaultOption;
    const isAugRev = useStackPageAugustRevisionEnabled();

    const sortIcon = useMemo(() => {
      return !isAugRev ? FilterLine : getSortIcon(sortOption);
    }, [isAugRev, sortOption]);

    const handleSelection = useCallback(
      (value: SelectableStackSortPreference) => {
        onSortSelect(value);
        const papSortValue: SortOptionType =
          StackSortOptionPapValue[value.option];

        reportPapEvent(
          PAP_Sort_SortOption({
            actionSurfaceComponent: 'stacks',
            featureLine: 'stacks',
            sortOptionType: papSortValue,
          }),
        );
      },
      [onSortSelect, reportPapEvent],
    );

    const handleToggle = useCallback(
      (event: { isOpen: boolean }) => {
        if (event.isOpen) {
          reportPapEvent(
            PAP_Click_SortOption({
              actionSurfaceComponent: 'stacks',
              featureLine: 'stacks',
            }),
          );
        }
      },
      [reportPapEvent],
    );

    return (
      <Menu.Wrapper onSelection={handleSelection} onToggle={handleToggle}>
        {({ getContentProps, getTriggerProps }) => (
          <>
            {augustRevision ? (
              <Chip withDropdownIcon={showDropdownIcon} {...getTriggerProps()}>
                <Chip.Content>
                  {StackSortOptionMetadata[sortOption].label()}
                </Chip.Content>
              </Chip>
            ) : (
              <IconButtonWithTooltip
                tooltipProps={{
                  title: i18n.t('sort_option_label'),
                }}
                {...getTriggerProps()}
                variant="borderless"
                withDropdownIcon={showDropdownIcon}
              >
                <UIIcon src={sortIcon} />
              </IconButtonWithTooltip>
            )}
            <Menu.Content {...getContentProps()} minWidth={236}>
              <Menu.Segment withLabel={i18n.t('sort_option_label')}>
                {optionsPreset.map((preference) => {
                  const isSelected =
                    (sortOption === undefined &&
                      isEqual(defaultOption, preference)) ||
                    sortOption === preference.option;

                  const value = { ...preference };
                  let arrowDirection =
                    preference.direction === StackSortDirection.ASC
                      ? ArrowUpLine
                      : ArrowDownLine;

                  if (isSelected && value.direction !== undefined) {
                    // swap direction to other direction
                    value.direction =
                      sortDirection === StackSortDirection.ASC
                        ? StackSortDirection.DESC
                        : StackSortDirection.ASC;

                    // keep the proper direction based on selected direction
                    arrowDirection =
                      sortDirection === StackSortDirection.ASC
                        ? ArrowUpLine
                        : ArrowDownLine;
                  }

                  return (
                    <Menu.ActionItem
                      key={preference.option}
                      withLeftAccessory={
                        <UIIcon
                          src={StackSortOptionMetadata[preference.option].icon}
                        />
                      }
                      withRightAccessory={
                        isSelected && <UIIcon src={CheckmarkLine} />
                      }
                      value={value}
                      style={{ alignItems: 'center' }}
                    >
                      <div className={styles.optionLabel}>
                        {StackSortOptionMetadata[preference.option].label()}
                        {preference.direction !== undefined && (
                          <UIIcon
                            color="var(--dig-color__border__base)"
                            size="small"
                            src={arrowDirection}
                          />
                        )}
                      </div>
                    </Menu.ActionItem>
                  );
                })}
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    );
  },
);

SortIconMenu.displayName = 'SortIconMenu';
