import { Draggable as RbDraggable } from 'react-beautiful-dnd';

import type { CSSProperties, HTMLProps, ReactElement } from 'react';
import type { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

export type DraggableGetStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => CSSProperties | undefined;

export type DraggableProps<T extends HTMLProps<E>, E extends HTMLElement> = {
  // Off switch to render everything without enabling dragging.
  dragDisabled?: boolean;

  // Unique id for this draggable element.
  draggableId: string;

  // Index of this element in an array of draggables.
  index: number;

  // Extra properties to set on the element.
  props?: T | null;

  // Extra properties to set on the element, as a callback.
  getProps?: (isDragging: boolean) => T | undefined;

  // Due to the way the react-beautiful-dnd api is implemented, we need to
  // update the style when dragging, so make it easy to override this.
  getStyle?: DraggableGetStyle;

  // Function to render the child node.
  renderChild: (props: T, isDragging: boolean) => ReactElement<E>;
};

/**
 * Generic wrapper for <Draggable> in react-beautiful-dnd to make it easier
 * to use.
 */
export function Draggable<T extends HTMLProps<E>, E extends HTMLElement>({
  dragDisabled,
  draggableId,
  index,
  props,
  getProps,
  getStyle,
  renderChild,
}: DraggableProps<T, E>): ReactElement {
  if (dragDisabled) {
    // @ts-expect-error: Prevent the "'T' could be instantiated with an
    // arbitrary type which could be unrelated" error which is expected.
    const newProps: T = {
      ...props,
      ...getProps?.(false),
      style: getStyle?.(false, undefined),
    };

    return renderChild(newProps, false);
  }

  return (
    <RbDraggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => {
        // @ts-expect-error: Prevent the "'T' could be instantiated with an
        // arbitrary type which could be unrelated" error which is expected.
        const newProps: T = {
          ref: provided.innerRef,
          ...provided.draggableProps,
          ...provided.dragHandleProps,
          ...props,
          ...getProps?.(snapshot.isDragging),
          style: getStyle
            ? getStyle(snapshot.isDragging, provided.draggableProps.style)
            : provided.draggableProps.style,
        };

        return renderChild(newProps, snapshot.isDragging);
      }}
    </RbDraggable>
  );
}
