import type { InviteFormContact } from '../types';

/**
 * TODO: For now we consider a contact with an unvalidated email as invalid
 *  when calculating license usage and validating forms. The assumption is that
 *   all emails should be validated soon after being added. Revisit this once
 *   we have clearer requirements
 */
export const isValidInvitee = <T extends InviteFormContact>(
  contact: T,
): contact is T & { email: string; validationError: null } =>
  !!contact.email && contact.validationError === null;
