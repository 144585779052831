import {
  GlobeLine,
  LockLine,
  PersonMultipleLine,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { StackAccessLevel, StackPermission } from './Types';

import type {
  SharingMember,
  SharingMemberKey,
  SharingUserContactKey,
  StackMember,
} from './Types';
import type { stacks, users } from '@dropbox/api-v2-client';
import type { SVGProps } from 'react';

export const textForPermission = (permission: StackPermission): string => {
  switch (permission) {
    case StackPermission.OWNER:
      return i18n.t('owner');
    case StackPermission.WRITE:
      return i18n.t('can_edit');
    case StackPermission.READ:
      return i18n.t('can_view');
  }
};

// Taken from https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
const VALID_EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@|"]+(\.[^<>()[\]\\.,;:\s@|"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i;
const SINGLE_VALID_EMAIL_REGEX = new RegExp(
  `^${VALID_EMAIL_REGEX.source}$`,
  VALID_EMAIL_REGEX.flags,
);
const GLOBAL_VALID_EMAIL_REGEX = new RegExp(
  VALID_EMAIL_REGEX.source,
  VALID_EMAIL_REGEX.flags + 'g',
);
export const DELIMITED_CONTACT_REGEX = /[,|;>\s]/;

export const validateEmail = (email: string): boolean => {
  return SINGLE_VALID_EMAIL_REGEX.test(email);
};

export const tokenizeValidRawInput = (
  input: string,
): SharingUserContactKey[] => {
  const emails = input.match(GLOBAL_VALID_EMAIL_REGEX) || [];
  return emails.map((email) => ({ '.tag': 'user', email }));
};

export const tokenizeDelimitedInput = (
  input: string,
): SharingUserContactKey[] => {
  return DELIMITED_CONTACT_REGEX.test(input)
    ? tokenizeValidRawInput(input)
    : [];
};

export const safeLocaleLowerCase = (s: string): string =>
  // s.toLocaleLowerCase(getBestMatchingBrowserLocale());
  s.toLocaleLowerCase();

export const textMatchesMember = (text: string, member: SharingMember) => {
  const transform = (toTransform: string) =>
    safeLocaleLowerCase(toTransform.replace(' ', ''));
  return (
    transform(member.displayName) === transform(text) ||
    (member['.tag'] === 'user' &&
      transform(member.email || '') === transform(text))
  );
};

export const hasStackOwnerPermissions = (permission?: string) => {
  return permission === StackPermission.OWNER;
};

export const hasStackWritePermissions = (permission?: string) => {
  return (
    permission === StackPermission.OWNER || permission === StackPermission.WRITE
  );
};

export const serverStackPermissionFromStackPermission = (
  stackPermission: StackPermission,
): stacks.StackPermission => {
  switch (stackPermission) {
    case StackPermission.OWNER:
      return { '.tag': 'owner' };
    case StackPermission.READ:
      return { '.tag': 'read' };
    case StackPermission.WRITE:
      return { '.tag': 'write' };
  }
};

export const serverAccessLevelFromAccessLevel = (
  accessLevel: StackAccessLevel,
): stacks.StackSharedLinkAccessLevel => {
  switch (accessLevel) {
    case StackAccessLevel.INVITED:
      return { '.tag': 'invited' };
    case StackAccessLevel.PUBLIC:
      return { '.tag': 'public' };
    case StackAccessLevel.TEAM:
      return { '.tag': 'team' };
  }
};

export const coerceStackPermission = (permission?: stacks.StackPermission) => {
  const tag = permission?.['.tag'];
  if (tag === 'owner') {
    return StackPermission.OWNER;
  }
  if (tag === 'write') {
    return StackPermission.WRITE;
  }
  return StackPermission.READ;
};

export const coerceStackLinkPermission = (
  permission?: stacks.StackPermission,
) => {
  const tag = permission?.['.tag'];
  return tag === 'write' ? StackPermission.WRITE : StackPermission.READ;
};

export const coerceStackAccessLevel = (
  publishData?: stacks.PublishData,
  accessLevel?: stacks.StackSharedLinkAccessLevel,
) => {
  const tag = accessLevel?.['.tag'];
  if (tag === 'public') {
    return StackAccessLevel.PUBLIC;
  }
  if (tag === 'team' || !!publishData?.is_published) {
    return StackAccessLevel.TEAM;
  }
  return StackAccessLevel.INVITED;
};

export const stackMemberFromUser = (user: users.FullAccount): StackMember => {
  return {
    '.tag': 'user',
    displayName: user.name.display_name,
    email: user.email,
    profilePhotoUrl: user.profile_photo_url,
    // If we have no sharing data, assume we are the owner
    permission: StackPermission.OWNER,
    isSameTeamAsOwner: true,
  };
};

export const stackMemberFromSharingMember = (
  member: stacks.StackSharingMember,
): StackMember => {
  if (member?.subject?.['.tag'] === 'user') {
    const user = member?.subject as stacks.StackSharingSubjectUser;
    return {
      '.tag': 'user',
      displayName: user.display_name ?? '',
      email: user.email ?? '',
      profilePhotoUrl: user.profile_photo_url,
      permission: coerceStackPermission(member?.permission),
      isSameTeamAsOwner: Boolean(user.same_team_as_owner),
    };
  } else if (member?.subject?.['.tag'] === 'unclaimed_email') {
    const email = member?.subject as stacks.StackSharingSubjectUnclaimedEmail;
    return {
      '.tag': 'user',
      displayName: '',
      email: email.unclaimed_email ?? '',
      profilePhotoUrl: '',
      permission: coerceStackPermission(member?.permission),
      isSameTeamAsOwner: false,
    };
  }
  const group = member?.subject as stacks.StackSharingSubjectGroup;
  return {
    '.tag': 'group',
    groupId: group.group_id ?? '',
    displayName: group.display_name ?? '',
    memberCount: group.member_count ?? 0,
    permission: coerceStackPermission(member?.permission),
    isSameTeamAsOwner: true,
  };
};

export const sharingMemberKeyFromSharingMember = (
  member: stacks.StackSharingMember,
): SharingMemberKey => {
  if (member?.subject?.['.tag'] === 'user') {
    const user = member?.subject as stacks.StackSharingSubjectUser;
    return {
      '.tag': 'user',
      email: user.email ?? '',
    };
  } else if (member?.subject?.['.tag'] === 'unclaimed_email') {
    return {
      '.tag': 'user',
      email: member.subject.unclaimed_email,
    };
  }
  const group = member?.subject as stacks.StackSharingSubjectGroup;
  return {
    '.tag': 'group',
    groupId: group.group_id ?? '',
  };
};

export const sharingMemberKeysMatch = (
  a: SharingMemberKey,
  b: SharingMemberKey,
): boolean => {
  return (
    a['.tag'] === b['.tag'] &&
    getSharingMemberIdentifier(a) === getSharingMemberIdentifier(b)
  );
};

export const getSharingMemberIdentifier = (
  member: SharingMemberKey,
): string => {
  return member['.tag'] === 'user' ? member.email : member.groupId;
};

export const serverSubjectRefFromSharingMemberKey = (
  key: SharingMemberKey,
):
  | stacks.StackSharingSubjectRefEmail
  | stacks.StackSharingSubjectRefGroupId => {
  if (key['.tag'] === 'user') {
    return { '.tag': 'email', email: key.email };
  }
  return { '.tag': 'group_id', group_id: key.groupId };
};

export const accessLevelTitleForMemberCount = (numMembers: number): string => {
  return numMembers > 1
    ? i18n.t('private_accesslevel_multiple_users')
    : i18n.t('private_accesslevel');
};

export const accessLevelTitleForAccessLevel = (
  publishData?: stacks.PublishData,
  accessLevel?: stacks.StackSharedLinkAccessLevel,
  numMembers?: number,
  isPublicSharingAllowed?: boolean,
) => {
  if (publishData?.is_published) {
    return i18n.t('company_accesslevel');
  }
  if (accessLevel !== undefined) {
    let accessLevelToCheck = accessLevel['.tag'];
    if (accessLevelToCheck === 'public' && !isPublicSharingAllowed) {
      accessLevelToCheck = 'team';
    }
    switch (accessLevelToCheck) {
      case 'public':
        return i18n.t('public_accesslevel');
      case 'team':
        return i18n.t('private_accesslevel_multiple_users');
      case 'invited':
        return accessLevelTitleForMemberCount(numMembers ?? 1);
      default:
        return i18n.t('private_accesslevel');
    }
  } else {
    return i18n.t('private_accesslevel');
  }
};

export const titleForAccessLevel = (
  accessLevel: StackAccessLevel,
  stackTeamName: string | undefined,
  numMembers: number | undefined,
  isPublicSharingAllowed?: boolean,
): string => {
  let accessLevelToCheck = accessLevel;
  if (
    accessLevelToCheck === StackAccessLevel.PUBLIC &&
    !isPublicSharingAllowed
  ) {
    accessLevelToCheck = StackAccessLevel.TEAM;
  }

  switch (accessLevelToCheck) {
    case StackAccessLevel.PUBLIC:
      return i18n.t('public_accesslevel');
    case StackAccessLevel.TEAM:
      return stackTeamName ?? '';
    case StackAccessLevel.INVITED:
      return accessLevelTitleForMemberCount(numMembers ?? 1);
  }
};

export const newTitleForAccessLevelAndPermission = (
  accessLevel: StackAccessLevel,
  permission: StackPermission,
  stackTeamName?: string,
  isPublicSharingAllowed?: boolean,
) => {
  let accessLevelToCheck = accessLevel;
  if (
    accessLevelToCheck === StackAccessLevel.PUBLIC &&
    !isPublicSharingAllowed
  ) {
    accessLevelToCheck = StackAccessLevel.TEAM;
  }
  const combinedState = `${accessLevelToCheck}_${permission}`;

  switch (combinedState) {
    case `${StackAccessLevel.PUBLIC}_${StackPermission.READ}`:
      return i18n.t('stack_share_access_public_description_can_view');
    case `${StackAccessLevel.PUBLIC}_${StackPermission.WRITE}`:
      return i18n.t('stack_share_access_public_description_can_edit');
    case `${StackAccessLevel.TEAM}_${StackPermission.READ}`: {
      if (stackTeamName) {
        return i18n.t('stack_share_access_team_description_can_view', {
          team: stackTeamName,
        });
      } else {
        return i18n.t('stack_share_access_team_description_fallback_can_view');
      }
    }
    case `${StackAccessLevel.TEAM}_${StackPermission.WRITE}`: {
      if (stackTeamName) {
        return i18n.t('stack_share_access_team_description_can_edit', {
          team: stackTeamName,
        });
      } else {
        return i18n.t('stack_share_access_team_description_fallback_can_edit');
      }
    }
    case `${StackAccessLevel.INVITED}_${StackPermission.READ}`:
      return i18n.t('stack_share_access_private_description_can_view');
    case `${StackAccessLevel.INVITED}_${StackPermission.WRITE}`:
      return i18n.t('stack_share_access_private_description_can_edit');
  }
};

export const newTitleForAccessLevel = (
  accessLevel: StackAccessLevel,
  stackTeamName?: string,
  isPublicSharingAllowed?: boolean,
): string => {
  let accessLevelToCheck = accessLevel;
  if (
    accessLevelToCheck === StackAccessLevel.PUBLIC &&
    !isPublicSharingAllowed
  ) {
    accessLevelToCheck = StackAccessLevel.TEAM;
  }

  switch (accessLevelToCheck) {
    case StackAccessLevel.PUBLIC:
      return i18n.t('stack_share_access_public_description');
    case StackAccessLevel.TEAM: {
      if (stackTeamName) {
        return i18n.t('stack_share_access_team_description', {
          team: stackTeamName,
        });
      } else {
        return i18n.t('stack_share_access_team_description_fallback');
      }
    }
    case StackAccessLevel.INVITED:
      return i18n.t('stack_share_access_private_description');
  }
};

export const descriptionForStackAccessLevel = (
  accessLevel?: stacks.StackSharedLinkAccessLevel,
  teamName?: string,
  isPublicSharingAllowed?: boolean,
): string | undefined => {
  let accessLevelToCheck = accessLevel?.['.tag'];
  if (accessLevelToCheck === 'public' && !isPublicSharingAllowed) {
    accessLevelToCheck = 'team';
  }

  switch (accessLevelToCheck) {
    case 'public':
      return i18n.t('public_accesslevel_description');
    case 'invited':
      return i18n.t('private_accesslevel_with_shares_description');
    case 'team':
      return teamName
        ? i18n.t('company_accesslevel_description', { teamName })
        : i18n.t('company_accesslevel_description_no_team_name');
    default:
      return undefined;
  }
};

export const accessLevelIconSrcForMemberCount = (
  numMembers: number,
  accessLevel?: stacks.StackSharedLinkAccessLevel,
  isCompanyStack?: boolean,
  isPublicSharingAllowed?: boolean,
): ((props: SVGProps<SVGSVGElement>) => JSX.Element) => {
  if (isCompanyStack) {
    return TeamLine;
  }
  if (isPublicSharingAllowed && accessLevel?.['.tag'] === 'public') {
    return GlobeLine;
  }
  return numMembers > 1 ? PersonMultipleLine : LockLine;
};

export function getSubjectEmail(
  subject: stacks.StackSharingSubject | undefined,
): string | undefined {
  if (subject === undefined) return undefined;

  switch (subject['.tag']) {
    case 'user':
      return subject.email;
    case 'unclaimed_email':
      return subject.unclaimed_email;
    case 'group':
      break;
    case 'other':
      break;
    default:
      subject satisfies never;
  }
  return undefined;
}

export function getSubjectIdentifier(
  subject: stacks.StackSharingSubject | undefined,
): string | undefined {
  if (subject === undefined) return undefined;

  switch (subject['.tag']) {
    case 'user':
      return subject.email;
    case 'unclaimed_email':
      return subject.unclaimed_email;
    case 'group':
      return subject.group_id;
    case 'other':
      break;
    default:
      subject satisfies never;
  }
  return undefined;
}

export const memberTextForStack = (stack: stacks.Stack, localEmail: string) => {
  const members = stack.sharing_data?.members;
  if (members === undefined || members.length <= 1) {
    return i18n.t('only_you');
  }

  const memberNamesAndEmails = members
    .map((member) => {
      if (member.subject?.['.tag'] === 'user') {
        return {
          email: member.subject.email,
          name: member.subject.display_name,
        };
      }

      if (member.subject?.['.tag'] === 'unclaimed_email') {
        return {
          email: member.subject.unclaimed_email,
          name: member.subject.unclaimed_email,
        };
      }

      if (member.subject?.['.tag'] === 'group') {
        return {
          email: undefined,
          name: member.subject.display_name,
        };
      }
      return undefined;
    })
    .filter(isDefined);

  const otherMemberName = memberNamesAndEmails.find(
    (member) => member.email !== localEmail,
  )?.name;

  if (members.length === 2) {
    return i18n.t('shared_with_you_two_people', {
      otherMemberName,
    });
  }

  const numOthers = memberNamesAndEmails.length - 2;
  if (numOthers === 1) {
    return i18n.t('shared_with_you_one_person', { otherMemberName });
  } else {
    return i18n.t('shared_with_you_n_people', {
      otherMemberName,
      numOthers,
    });
  }
};

export const sortStackMembers = (members: StackMember[]): StackMember[] => {
  const sortedMembers = [...members];
  sortedMembers.sort((member1, member2) => {
    // ensures that owners always appear first
    if (
      member1.permission === StackPermission.OWNER &&
      member2.permission !== StackPermission.OWNER
    ) {
      return -1;
    }
    if (
      member1.permission !== StackPermission.OWNER &&
      member2.permission === StackPermission.OWNER
    ) {
      return 1;
    }
    return member1.displayName.localeCompare(member2.displayName);
  });
  return sortedMembers;
};
