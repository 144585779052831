import { isUserIneligibleAtom } from '@mirage/eligibility/useEligibilityCheck';
import { useAtomValue } from 'jotai';
import { ChecklistButton } from './ChecklistButton';
import { useOnboardingChecklistContext } from './hooks/ChecklistProvider';
import styles from './OnboardingChecklistButton.module.css';

export const OnboardingChecklistButton = () => {
  const props = useOnboardingChecklistContext();
  const isUserIneligible = useAtomValue(isUserIneligibleAtom);

  if (!props || isUserIneligible) return null;
  return <ChecklistButton className={styles.onboardingChecklist} {...props} />;
};
