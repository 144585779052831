import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, EditLine } from '@dropbox/dig-icons/assets';
import {
  actionMatchesURL,
  type AssistAction,
} from '@mirage/shared/compose/assist-actions';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { memo, useMemo } from 'react';
import styles from './MessageAssistActions.module.css';

import type { ChatSourceTransient } from '@mirage/mosaics/Chat/types';

interface MessageAssistActionsProps {
  source: ChatSourceTransient;
  enabledActions: AssistAction[];
  onTriggerAction: (action: AssistAction) => void;
  onOpenListActionsModal: () => void;
}
export const MessageAssistActions = memo(
  ({
    source,
    enabledActions,
    onTriggerAction,
    onOpenListActionsModal,
  }: MessageAssistActionsProps) => {
    const actionsToShow = useActionsToShow(enabledActions, source);
    if (!source || actionsToShow.length === 0) {
      return null;
    }
    return (
      <div className={styles.AssistActions}>
        {actionsToShow.map((action) => (
          <ActionChip
            key={action.id}
            action={action}
            onTrigger={onTriggerAction}
          />
        ))}
        <Chip
          key="new-action"
          variant="transparent"
          size="medium"
          className={styles.ActionChip}
          onClick={() => {
            onOpenListActionsModal();
          }}
        >
          <Chip.IconAccessory>
            <UIIcon src={AddCircleLine} />
          </Chip.IconAccessory>
          <Chip.Content>{i18n.t('assist_add_ai_button')}</Chip.Content>
        </Chip>
      </div>
    );
  },
);
MessageAssistActions.displayName = 'MessageAssistActions';

interface ActionChipProps {
  action: AssistAction;
  onTrigger: (action: AssistAction) => void;
  onEdit?: () => void;
}
export const ActionChip = memo(
  ({ action, onTrigger, onEdit }: ActionChipProps) => {
    return (
      <div className={styles.ActionChipContainer}>
        {onEdit && (
          <IconButtonWithTooltip
            tooltipProps={{ title: 'Edit' }}
            variant="borderless"
            size="small"
          >
            <UIIcon src={EditLine} onClick={onEdit} />
          </IconButtonWithTooltip>
        )}
        <Chip
          key={action.id}
          variant="transparent"
          size="medium"
          className={styles.ActionChip}
          onClick={() => {
            onTrigger(action);
          }}
        >
          {action.icon && (
            <Chip.IconAccessory>{action.icon}</Chip.IconAccessory>
          )}
          <Chip.Content>{action.title}</Chip.Content>
        </Chip>
      </div>
    );
  },
);
ActionChip.displayName = 'ActionChip';

function filterActions(actions: AssistAction[], source: ChatSourceTransient) {
  return actions.filter((action) => actionMatchesURL(action, source.url));
}

function useActionsToShow(
  enabledActions: AssistAction[],
  transientSource: ChatSourceTransient | undefined,
): AssistAction[] {
  return useMemo(() => {
    if (!transientSource) {
      return [];
    }
    return filterActions(enabledActions, transientSource);
  }, [enabledActions, transientSource]);
}
