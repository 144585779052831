import { ServiceId } from '@mirage/discovery/id';
import * as service from '@mirage/discovery/services';

import type { Service } from './service';
import type {
  AssistActionSettings,
  AssistActionSettingsMap,
} from '@mirage/service-compose/service/assistActionsSettings';
import type {
  AssistAction,
  CustomAssistAction,
} from '@mirage/shared/compose/assist-actions';
import type { AssistCustomTemplate } from '@mirage/shared/compose/assist-template';
import type { ComposeSession } from '@mirage/shared/compose/compose-session';
import type { ComposeVoice } from '@mirage/shared/compose/compose-voice';

const composeService = service.getp<Service>(ServiceId.COMPOSE);
const composeService$ = service.get<Service>(ServiceId.COMPOSE);

export default composeService;

export function loadComposeSessions(): Promise<ComposeSession[]> {
  return composeService.loadComposeSessions();
}

export function loadComposeApiSessions(): Promise<ComposeSession[]> {
  return composeService.loadComposeApiSessions();
}

export function saveComposeSessions(sessions: ComposeSession[]) {
  return composeService.saveComposeSessions(sessions);
}

export function saveComposeApiSession(
  session: ComposeSession,
): Promise<ComposeSession> {
  return composeService.saveComposeApiSession(session);
}

export function syncComposeSessions(
  localSessions: ComposeSession[],
  apiSessions: ComposeSession[],
): Promise<{ updated: ComposeSession[]; deletedIds: string[] }> {
  return composeService.syncComposeSessions(localSessions, apiSessions);
}

export function deleteComposeApiSession(session: ComposeSession) {
  return composeService.deleteComposeApiSession(session);
}

export function loadComposeVoices(): Promise<ComposeVoice[]> {
  return composeService.loadComposeVoices();
}

export function loadComposeApiVoices(): Promise<ComposeVoice[]> {
  return composeService.loadComposeApiVoices();
}

export function saveComposeVoices(voices: ComposeVoice[]) {
  return composeService.saveComposeVoices(voices);
}

export function saveComposeApiVoice(
  voice: ComposeVoice,
): Promise<ComposeVoice> {
  return composeService.saveComposeApiVoice(voice);
}

export function deleteComposeApiVoice(voice: ComposeVoice): Promise<void> {
  return composeService.deleteComposeApiVoice(voice);
}

export function loadAssistTemplates(): Promise<AssistCustomTemplate[]> {
  return composeService.loadAssistTemplates();
}

export function loadAssistApiTemplates(): Promise<AssistCustomTemplate[]> {
  return composeService.loadAssistApiTemplates();
}

export function saveAssistTemplates(templates: AssistCustomTemplate[]) {
  return composeService.saveAssistTemplates(templates);
}

export function saveAssistApiTemplate(
  template: AssistCustomTemplate,
): Promise<AssistCustomTemplate> {
  return composeService.saveAssistApiTemplate(template);
}

export function deleteAssistApiTemplate(
  template: AssistCustomTemplate,
): Promise<void> {
  return composeService.deleteAssistApiTemplate(template);
}

export function saveAssistAction(action: CustomAssistAction): Promise<void> {
  return composeService.saveAssistAction(action);
}

export function deleteAssistAction(action: CustomAssistAction): Promise<void> {
  return composeService.deleteAssistAction(action);
}

export function loadAssistActions(): Promise<AssistAction[]> {
  return composeService.loadAssistActions();
}

export function subscribeToAssistActionsUpdates(
  callback: (actions: AssistAction[]) => void,
) {
  return composeService$.assistActionsObservable().subscribe(callback);
}

export function getAllAssistActionSettings(): Promise<AssistActionSettingsMap> {
  return composeService.getAllAssistActionSettings();
}

export function saveAssistActionSetting(
  actionId: string,
  setting: AssistActionSettings,
) {
  return composeService.saveAssistActionSetting(actionId, setting);
}

export function subscribeToAssistActionSettingsUpdates(
  callback: (settings: AssistActionSettingsMap) => void,
) {
  return composeService$.assistActionSettingsObservable().subscribe(callback);
}

export function tearDown(): Promise<void> {
  return composeService.tearDown();
}
