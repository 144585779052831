import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import ConnectorIssuesNotificationBanner from '@mirage/mosaics/GlobalBanners/ConnectorIssuesNotificationBanner';
import { activeStackAtom } from '@mirage/stacks/ActiveStack/atoms';
import { useCurrentBackgroundTheme } from '@mirage/stacks/themes';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';
import ColdStartNotificationBanner from './ColdStartNotificationBanner';
import styles from './GlobalBanners.module.css';
import { globalBannersHeightAtom } from './globalBannersAtom';
import { MeticulousRecordingBanner } from './MeticulousRecordingBanner';

export default function GlobalBanners() {
  const activeStack = useAtomValue(activeStackAtom);
  const backgroundTheme = useCurrentBackgroundTheme(activeStack);
  const isAugustStackPageEnabled = useStackPageAugustRevisionEnabled();
  const [, setGlobalBannersHeight] = useAtom(globalBannersHeightAtom);
  const bannersRef = useRef<HTMLDivElement>(null);

  // Update the height atom whenever the banners div changes size
  useEffect(() => {
    if (!bannersRef.current) return;

    // Capture the current ref value to use in cleanup
    const currentRef = bannersRef.current;

    const updateHeight = () => {
      const height = currentRef?.offsetHeight || 0;
      setGlobalBannersHeight(height);
    };

    // Initial measurement
    updateHeight();

    // Set up a resize observer to detect changes in the banners' height
    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(currentRef);

    return () => {
      resizeObserver.unobserve(currentRef);
      resizeObserver.disconnect();
    };
  }, [setGlobalBannersHeight]);

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        <div
          ref={bannersRef}
          className={classNames(styles.banners, {
            [styles.showBackground]:
              isAugustStackPageEnabled && backgroundTheme !== undefined,
          })}
        >
          <ConnectorIssuesNotificationBanner bannerClass={styles.banner} />
          <ColdStartNotificationBanner bannerClass={styles.banner} />
          <MeticulousRecordingBanner bannerClass={styles.banner} />
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
}
