/* eslint-disable @typescript-eslint/no-namespace */
import { typeahead } from '@mirage/service-typeahead-search/service/types';

import type { HitTracking } from '../types/common';
import type { stacks } from '@dropbox/api-v2-client';
import type {
  MathCalculation,
  SuggestedQuery,
} from '@mirage/service-typeahead-search/service/types';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { SearchFilter } from '@mirage/shared/search/search-filters';
import type { SearchResult } from '@mirage/shared/search/search-result';
import type { StackItem } from '@mirage/shared/search/stack-item';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';

export function searchResult(
  uuid: string,
  result: SearchResult,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.SearchResult, uuid, result };
}

export function previousQuery(
  uuid: string,
  result: PreviousQuery,
): typeahead.TaggedResult {
  return {
    type: typeahead.ResultType.PreviousQuery,
    uuid,
    result,
  };
}

export function suggestedQuery(
  uuid: string,
  result: SuggestedQuery,
): typeahead.TaggedResult {
  return {
    type: typeahead.ResultType.SuggestedQuery,
    uuid,
    result,
  };
}

export function stack(
  uuid: string,
  result: stacks.Stack,
  hits?: HitTracking,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.Stack, uuid, result, hits };
}

export function stackItem(
  uuid: string,
  result: StackItem,
  hits?: HitTracking,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.StackItem, uuid, result, hits };
}

export function urlShortcut(
  uuid: string,
  result: URLShortcut,
): typeahead.TaggedResult {
  return {
    type: typeahead.ResultType.URLShortcut,
    uuid,
    result,
  };
}

export function recommendation(
  uuid: string,
  result: Recommendation,
  hits?: HitTracking,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.Recommendation, uuid, result, hits };
}

export function mathCalculation(
  uuid: string,
  result: MathCalculation,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.MathCalculation, uuid, result };
}

export function searchFilter(
  uuid: string,
  result: SearchFilter,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.SearchFilter, uuid, result };
}

//
// native primitives
// ----------------------------------------------------------------------------
export function desktopFile(
  uuid: string,
  result: SearchResult,
  hits?: HitTracking,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.DesktopFile, uuid, result, hits };
}

export function desktopApplication(
  uuid: string,
  result: SearchResult,
  hits?: HitTracking,
): typeahead.TaggedResult {
  return { type: typeahead.ResultType.DesktopApplication, uuid, result, hits };
}
