import { fetchMultimediaPreview } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useEffect, useState } from 'react';

export const useMultimediaPreview = (previewUrl?: string, size?: number) => {
  const [base64Image, setBase64Image] = useState<string | undefined>(undefined);
  const [base64Loading, setBase64Loading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchBase64 = async () => {
      try {
        const base64Preview = await fetchMultimediaPreview(
          `${previewUrl}`,
          EnvCtx?.surface === 'desktop',
          size,
        );

        // The preview service was unable to extract the image
        if (base64Preview?.startsWith('data:text')) {
          setHasError(true);
          setBase64Loading(false);
          return;
        }
        setBase64Image(base64Preview);
      } catch (e) {
        setHasError(true);
      } finally {
        setBase64Loading(false);
      }
    };
    if (previewUrl) {
      setBase64Image(undefined);
      setBase64Loading(true);
      fetchBase64();
    } else {
      setBase64Loading(false);
    }
  }, [previewUrl]);

  return {
    base64Image,
    base64Loading,
    hasError,
  };
};
