// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3694
import type { PAPEventGeneric } from "../base/event";
import type { MiltimediaActionType } from "../enums/miltimedia_action_type";

// Event_name: 'click.dash_miltimedia_drawer_action_button'
// Description: UI event send from Dash when Action Button is clicked from Multimedia drawer
// Owner: dash-exp-find-and-discover
export type Click_DashMiltimediaDrawerActionButton = PAPEventGeneric<
  'dash',
  'click',
  'dash_miltimedia_drawer_action_button',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
    // Type of actions which could be performed on multimedia objects from dash
    miltimediaActionType?: MiltimediaActionType;
  }
>;

export function PAP_Click_DashMiltimediaDrawerActionButton(properties?: Click_DashMiltimediaDrawerActionButton['properties']): Click_DashMiltimediaDrawerActionButton {
  return <Click_DashMiltimediaDrawerActionButton>{
    class: 'dash',
    action: 'click',
    object: 'dash_miltimedia_drawer_action_button',
    properties,
  };
}
