import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { InfiniteScroll } from '@mirage/feed/components/InfiniteScroll/InfiniteScroll';
import { NoResults } from '@mirage/search/SearchResults/NoResults';
import { useMultimediaSearch } from '@mirage/service-search/hooks/useMultimediaSearch';
import { useResultPAPLogger } from '@mirage/shared/hooks/useResultPAPLogger';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { MultimediaResultItem } from './MultimediaResultItem';
import styles from './MultimediaSearchResults.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';
import type { ScoredResult } from '@mirage/service-search/service';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

type MultimediaSearchResultsProps = {
  query: string;
  filters: SearchFilter[];
  resetAllFilters: () => void;
  results?: ScoredResult[];
  loading: boolean;
};
export const MultimediaSearchResults: React.FC<
  MultimediaSearchResultsProps
> = ({ query, filters, resetAllFilters, results, loading }) => {
  const {
    loadMore,
    data: fssResults,
    loading: fssLoading,
  } = useMultimediaSearch(query, filters);
  const { logSearchResultOpen } = useResultPAPLogger();
  const containerRef = useRef<HTMLDivElement>(null);
  const mediaResults = useMemo(() => {
    if (results) {
      return results;
    } else {
      return fssResults;
    }
  }, [results, fssResults]);

  const handleResultClick = useCallback(
    (result: SearchResult) => {
      logSearchResultOpen({
        defaultProps: {
          searchResult: result,
          query,
          filters,
          results: fssResults,
          featureLine: 'search',
        },
        rightRailOpened: false,
        isTypeahead: false,
        launchMethod: 'click',
        actionSurfaceComponent: 'result_row',
      });
    },
    [filters, fssResults, logSearchResultOpen, query],
  );

  const imagesLoading = useMemo(() => {
    return results ? (results.length > 0 ? false : loading) : fssLoading;
  }, [loading, fssLoading, results]);

  if (!imagesLoading && isEmpty(mediaResults)) {
    return (
      <NoResults activeFilters={filters} resetAllFilters={resetAllFilters} />
    );
  }
  return (
    <div className={styles.root}>
      <div className={styles.multimediaHeader}>
        <Text variant="label" color="standard" size="large">
          Images
        </Text>
        {!loading && (
          <Text variant="label" color="subtle" size="small">
            {mediaResults.length}
          </Text>
        )}
      </div>
      <div
        ref={containerRef}
        className={classNames(styles.container, styles.expanded)}
      >
        {imagesLoading &&
          mediaResults.length === 0 &&
          new Array(9).fill(0).map((_, i) => (
            <div role="button" className={styles.item} key={i}>
              <MultimediaResultItem />
            </div>
          ))}
        {mediaResults.map((res, i) => (
          <MultimediaResultItem
            key={i}
            result={res}
            onClick={handleResultClick}
          />
        ))}
        {!imagesLoading && !results && <InfiniteScroll onTrigger={loadMore} />}
      </div>
      {imagesLoading && mediaResults.length > 0 && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
