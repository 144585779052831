import type {
  AssistActionCondition,
  AssistActionConditionWebURL,
  AssistActionInstruction,
  PresetAssistAction,
} from '@mirage/shared/compose/assist-actions';

const CONDITION_ANY_URL: AssistActionConditionWebURL = {
  type: 'web_url',
  url_pattern: 'https://*',
};

export type PresetActionKey =
  | 'summarize-highlights'
  | 'next-steps'
  | 'quick-draft'
  | 'sentiment-analysis'
  | 'ask-me-anything'
  | 'translate-summarize'
  | 'critical-review'
  | 'explain-code'
  | 'extract-data'
  | 'quotes'
  | 'argument-map';

interface AssistActionPresetDefinition {
  key: PresetActionKey; // unique key used as id + for analytics
  title: string;
  conditions: AssistActionCondition[]; // matches if any of the conditions are met
  instruction: AssistActionInstruction;
  icon: string;
}

export const PRESET_ACTION_DEFINITIONS: AssistActionPresetDefinition[] = [
  // GENERAL PRESETS:
  {
    key: 'summarize-highlights',
    title: 'Summarize & Highlights',
    conditions: [CONDITION_ANY_URL],
    icon: '✂️',
    instruction: {
      type: 'single_message',
      message:
        'Please provide a concise summary of the following content and list the key points or major takeaways in bullet form.',
    },
  },
  {
    key: 'next-steps',
    title: 'Next Steps',
    conditions: [CONDITION_ANY_URL],
    icon: '✅',
    instruction: {
      type: 'single_message',
      message:
        'Identify all tasks or to-dos in the text, along with any roles or deadlines mentioned.',
    },
  },
  {
    key: 'quick-draft',
    title: 'Quick Draft',
    conditions: [CONDITION_ANY_URL],
    icon: '✉️',
    instruction: {
      type: 'single_message',
      message:
        'Summarize the following content into a concise format suitable for an internal email announcement (subject + body) and a short message (e.g., 280 characters) for chat or social.',
    },
  },
  // Per product feedback, showing only first 3 for now. May enable additional ones for testing later.
  // {
  //   title: 'Content & Clarity Optimizer',
  //   conditions: [CONDITION_ANY_URL],
  //   icon: '🔍',
  //   instruction: {
  //     type: 'single_message',
  //     message:
  //       'Analyze this text for clarity, structure, and discoverability. Suggest improvements to headings, keywords, overall organization, and reading level.',
  //   },
  // },
  // {
  //   title: 'Rewrite for Audience',
  //   conditions: [CONDITION_ANY_URL],
  //   icon: '✍️',
  //   instruction: {
  //     type: 'single_message',
  //     message: 'Rewrite this text to make it clearer and more approachable while keeping its original meaning.'
  //   },
  // },

  // DOMAIN SPECIFIC PRESETS:
  {
    key: 'sentiment-analysis',
    title: 'Sentiment Analysis',
    conditions: [
      { type: 'web_url', url_pattern: 'https://*.slack.com' },
      { type: 'web_url', url_pattern: 'https://teams.microsoft.com' },
      { type: 'web_url', url_pattern: 'https://*.gong.io/call' },
      { type: 'web_url', url_pattern: 'https://*.chorus.ai' },
      { type: 'web_url', url_pattern: 'https://*.my.salesforce.com' },
      { type: 'web_url', url_pattern: 'https://*.glassdoor.com' },
      { type: 'web_url', url_pattern: 'https://*.indeed.com' },
      { type: 'web_url', url_pattern: 'https://*.g2.com' },
      { type: 'web_url', url_pattern: 'https://*.capterra.com' },
      { type: 'web_url', url_pattern: 'https://*.zendesk.com' },
      { type: 'web_url', url_pattern: 'https://*.intercom.com' },
      { type: 'web_url', url_pattern: 'https://*.usertesting.com' },
    ],
    icon: '❤️',
    instruction: {
      type: 'single_message',
      message:
        'Analyze the overall sentiment of the following text (positive, negative, neutral). Provide evidence or key quotes supporting your analysis and highlight any strongly charged opinions.',
    },
  },
  {
    key: 'ask-me-anything',
    title: 'Ask Me Anything',
    conditions: [
      { type: 'web_url', url_pattern: 'https://*.slack.com' },
      { type: 'web_url', url_pattern: 'https://teams.microsoft.com' },
      { type: 'web_url', url_pattern: 'https://*.docs.google.com' },
      { type: 'web_url', url_pattern: 'https://*.atlassian.net' },
      { type: 'web_url', url_pattern: 'https://*.gong.io' },
      { type: 'web_url', url_pattern: 'https://*.my.salesforce.com' },
      { type: 'web_url', url_pattern: 'https://*.zendesk.com' },
      { type: 'web_url', url_pattern: 'https://*.intercom.com' },
    ],
    icon: '❓',
    instruction: {
      type: 'single_message',
      message:
        'From the text below, create a set of relevant questions and their concise answers. Focus on the kind of questions a reader might have after reading this content.',
    },
  },
  {
    key: 'translate-summarize',
    title: 'Translate & Summarize',
    conditions: [
      { type: 'web_url', url_pattern: 'https://*.gong.io' },
      { type: 'web_url', url_pattern: 'https://mail.google.com' },
      { type: 'web_url', url_pattern: 'https://*.gmail.com' },
      { type: 'web_url', url_pattern: 'https://outlook.office.com' },
      { type: 'web_url', url_pattern: 'https://*.outlook.com' },
      { type: 'web_url', url_pattern: 'https://*.slack.com' },
      { type: 'web_url', url_pattern: 'https://*.teams.microsoft.com' },
      { type: 'web_url', url_pattern: 'https://*.hubspot.com' },
      { type: 'web_url', url_pattern: 'https://*.marketo.com' },
      { type: 'web_url', url_pattern: 'https://docs.google.com' },
      { type: 'web_url', url_pattern: 'https://*.g2.com' },
      { type: 'web_url', url_pattern: 'https://*.capterra.com' },
      { type: 'web_url', url_pattern: 'https://*.intercom.com' },
      { type: 'web_url', url_pattern: 'https://*.zendesk.com' },
    ],
    icon: '🌐',
    instruction: {
      type: 'single_message',
      message:
        'Translate the following text into English and provide a short summary of the main points.',
    },
  },
  {
    key: 'critical-review',
    title: 'Critical Review',
    conditions: [
      { type: 'web_url', url_pattern: 'https://*.hubspot.com' },
      { type: 'web_url', url_pattern: 'https://docs.google.com' },
      { type: 'web_url', url_pattern: 'https://*.atlassian.net' },
      { type: 'web_url', url_pattern: 'https://*.zendesk.com' },
      { type: 'web_url', url_pattern: 'https://*.glassdoor.com' },
      { type: 'web_url', url_pattern: 'https://*.indeed.com' },
    ],
    icon: '⚖️',
    instruction: {
      type: 'single_message',
      message:
        'Summarize the main arguments, list pros and cons, and provide a brief contrarian viewpoint or weaknesses in the text.',
    },
  },
  {
    key: 'explain-code',
    title: 'Explain Code',
    conditions: [
      { type: 'web_url', url_pattern: 'https://github.com' },
      { type: 'web_url', url_pattern: 'https://github.*.com' },
      { type: 'web_url', url_pattern: 'https://stackoverflow.com' },
      { type: 'web_url', url_pattern: 'https://*.atlassian.net' },
    ],
    icon: '💻',
    instruction: {
      type: 'single_message',
      message:
        'Read the following code and provide an explanation of how it works, what it does, and any potential pitfalls. Include simpler pseudocode if relevant.',
    },
  },
  {
    key: 'extract-data',
    title: 'Extract Data',
    conditions: [
      { type: 'web_url', url_pattern: 'https://*.bamboohr.com' },
      { type: 'web_url', url_pattern: 'https://*.workday.com' },
      { type: 'web_url', url_pattern: 'https://*.amplitude.com' },
      { type: 'web_url', url_pattern: 'https://*.mixpanel.com' },
      { type: 'web_url', url_pattern: 'https://*.my.salesforce.com' },
      { type: 'web_url', url_pattern: 'https://*.hubspot.com' },
      { type: 'web_url', url_pattern: 'https://*.analytics.google.com' },
      { type: 'web_url', url_pattern: 'https://*.xero.com' },
      { type: 'web_url', url_pattern: 'https://*.intuit.com' },
      { type: 'web_url', url_pattern: 'https://*.atlassian.net' },
      // disabling for now as we don't do well ingesting Google sheets data
      // { type: 'web_url', url_pattern: 'https://*.sheets.google.com' },
      { type: 'web_url', url_pattern: 'https://*.docs.google.com' },
      { type: 'web_url', url_pattern: 'https://*.atlassian.net' },
    ],
    icon: '📊',
    instruction: {
      type: 'single_message',
      message:
        'Scan the text for numeric data, key statistics, or tabular information. Summarize them in a structured list or simple table.',
    },
  },
  {
    key: 'quotes',
    title: 'Quotes',
    conditions: [
      { type: 'web_url', url_pattern: 'https://*.zendesk.com' },
      { type: 'web_url', url_pattern: 'https://*.intercom.com' },
      { type: 'web_url', url_pattern: 'https://*.gong.io' },
      { type: 'web_url', url_pattern: 'https://*.chorus.ai' },
      { type: 'web_url', url_pattern: 'https://*.hubspot.com' },
      { type: 'web_url', url_pattern: 'https://*.glassdoor.com' },
      { type: 'web_url', url_pattern: 'https://*.indeed.com' },
      { type: 'web_url', url_pattern: 'https://*.usertesting.com' },
      { type: 'web_url', url_pattern: 'https://*.slack.com' },
      { type: 'web_url', url_pattern: 'https://teams.microsoft.com' },
      { type: 'web_url', url_pattern: 'https://mail.google.com' },
      { type: 'web_url', url_pattern: 'https://*.gmail.com' },
      { type: 'web_url', url_pattern: 'https://*.outlook.com' },
      { type: 'web_url', url_pattern: 'https://docs.google.com' },
    ],
    icon: '💬',
    instruction: {
      type: 'single_message',
      message:
        'Identify the most impactful or relevant quotes in the text. List them separately, and include a brief context for each.',
    },
  },
  // Disabling for now -- training guides likely not great use case unless we crawl multiple pages
  // {
  //   title: 'Training Guide',
  //   conditions: [
  //     { type: 'web_url', url_pattern: 'https://*.bamboohr.com' },
  //     { type: 'web_url', url_pattern: 'https://*.workday.com' },
  //     { type: 'web_url', url_pattern: 'https://github.com' },
  //     { type: 'web_url', url_pattern: 'https://github.*.com' },
  //     { type: 'web_url', url_pattern: 'https://*.notion.site' },
  //     { type: 'web_url', url_pattern: 'https://*.notion.so' },
  //     { type: 'web_url', url_pattern: 'https://*.atlassian.net' },
  //     { type: 'web_url', url_pattern: 'https://docs.google.com' },
  //     { type: 'web_url', url_pattern: 'https://*.zendesk.com' },
  //     { type: 'web_url', url_pattern: 'https://*.intercom.com' },
  //     { type: 'web_url', url_pattern: 'https://*.gong.io' },
  //     { type: 'web_url', url_pattern: 'https://*.chorus.ai' },
  //   ],
  //   icon: '📚',
  //   instruction: {
  //     type: 'single_message',
  //     message:
  //       'Transform the following onboarding or training material into a concise guide that highlights main topics, key definitions, and includes a few open-ended questions for knowledge checks.',
  //   },
  // },
  {
    key: 'argument-map',
    title: 'Argument Map',
    conditions: [
      { type: 'web_url', url_pattern: 'https://*.notion.site' },
      { type: 'web_url', url_pattern: 'https://*.notion.so' },
      { type: 'web_url', url_pattern: 'https://*.atlassian.net' },
      { type: 'web_url', url_pattern: 'https://*.gong.io' },
      { type: 'web_url', url_pattern: 'https://*.chorus.ai' },
      { type: 'web_url', url_pattern: 'https://*.linkedin.com' },
      { type: 'web_url', url_pattern: 'https://docs.google.com' },
      { type: 'web_url', url_pattern: 'https://github.com' },
      { type: 'web_url', url_pattern: 'https://github.*.com' },
      { type: 'web_url', url_pattern: 'https://*.zendesk.com' },
    ],
    icon: '🔗',
    instruction: {
      type: 'single_message',
      message:
        'Break down the arguments in the text into premises, supporting evidence, and conclusions. Show the logical flow of ideas.',
    },
  },
];

export const PRESET_ACTIONS: PresetAssistAction[] =
  PRESET_ACTION_DEFINITIONS.map((action) => ({
    type: 'preset',
    key: action.key,
    id: `preset-action-${action.key}`,
    condition: {
      type: 'any',
      conditions: action.conditions,
    },
    title: action.title,
    instruction: action.instruction,
    icon: action.icon,
  }));
