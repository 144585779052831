import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { PdfLibInitializationError } from '../errors/pdf-errors';
import { getPdfLibrary, getPdfWorkerUrl } from '../utils/pdf-worker-loader';

import type { SupportedMimeTypes } from './file-parser.worker';
import type { TextContent, TextItem } from 'pdfjs-dist/types/src/display/api';

const logger = tagged('file-parser-client');

// Initializes the PDF.js library if it hasn't been loaded yet.
// This includes setting up the worker URL which is required for PDF.js to function.
// Note: initPdfLib must run in this file in order to set the pdfjs variable

type ErrorContext = {
  fileType?: string;
  fileSize?: number;
  errorType?: string;
  pageCount?: number;
  workerState?: string;
  pdfjsVersion?: string;
};

const captureError = (error: Error, context: ErrorContext = {}) => {
  Sentry.withScope((scope) => {
    scope.setExtras(context);
    Sentry.captureException(error, {}, scope);
  });
  logger.error('File parsing error:', { error, ...context });
};

let pdfjs: typeof import('pdfjs-dist') | null = null;
const initPdfLib = async () => {
  if (!pdfjs) {
    try {
      logger.info('Initializing PDF.js library');
      pdfjs = await getPdfLibrary();
      if (!pdfjs) {
        const error = new PdfLibInitializationError();
        captureError(error, {
          errorType: 'PdfLibInitializationError',
          pdfjsVersion: 'not_loaded',
        });
        throw error;
      }
      pdfjs.GlobalWorkerOptions.workerSrc = getPdfWorkerUrl();
      logger.info('PDF.js library initialized', { version: pdfjs.version });
    } catch (error) {
      const errorToThrow =
        error instanceof Error
          ? error
          : new Error('PDF.js initialization failed');
      captureError(errorToThrow, { errorType: 'PdfLibInitializationError' });
      throw errorToThrow;
    }
  }
  return pdfjs;
};

/**
 * Single worker instance used for processing non-PDF files.
 * PDFs are handled separately because they require the worker from pdfjs-dist.
 */
let worker: Worker | null = null;

const PARSE_TIMEOUT = 30000;

const withTimeout = async <T>(
  promise: Promise<T>,
  timeoutMs: number,
): Promise<T> => {
  let timeoutHandle: NodeJS.Timeout;
  const timeoutPromise = new Promise<never>((_, reject) => {
    timeoutHandle = setTimeout(() => {
      reject(new Error('Operation timed out'));
    }, timeoutMs);
  });
  try {
    return await Promise.race([promise, timeoutPromise]);
  } finally {
    clearTimeout(timeoutHandle!);
  }
};

// Allow injection of worker for testing
export const createWorker = (mockWorkerUrl?: string) => {
  if (mockWorkerUrl === 'mock-worker-url') {
    return new Worker(mockWorkerUrl);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return new Worker(new URL('./file-parser.worker.ts', import.meta.url), {
    type: 'module',
  });
};

// Handle all supported files except pdf which is handled in its own worker
async function parseInWorker(
  file: File,
  mockWorkerUrl?: string,
): Promise<string> {
  if (!worker) {
    worker = createWorker(mockWorkerUrl);
  }

  return new Promise((resolve, reject) => {
    if (!worker) {
      const error = new Error('Worker failed to initialize');
      captureError(error, {
        errorType: 'WorkerInitializationError',
        workerState: 'null',
      });
      return reject(error);
    }

    const context = {
      fileType: file.type,
      fileSize: file.size,
    };
    logger.info('Starting worker file parse', context);

    const cleanup = () => {
      worker?.removeEventListener('message', handleMessage);
      worker?.removeEventListener('error', handleError);
    };

    const handleMessage = (event: MessageEvent) => {
      if (event.data.error) {
        cleanup();
        const error = new Error(event.data.error);
        captureError(error, {
          ...context,
          errorType: 'WorkerProcessingError',
        });
        reject(error);
      } else {
        cleanup();
        logger.info('Worker processing complete', context);
        resolve(event.data.result);
      }
    };

    const handleError = (error: ErrorEvent) => {
      cleanup();
      const workerError = new Error(`Worker failed: ${error}`);
      captureError(workerError, {
        ...context,
        errorType: 'WorkerError',
      });
      reject(workerError);
    };

    worker.addEventListener('message', handleMessage);
    worker.addEventListener('error', handleError);

    worker.postMessage({ file }, []);
  });
}

export async function handlePdf(file: File): Promise<string> {
  const context = {
    fileType: 'application/pdf',
    fileSize: file.size,
  };

  try {
    logger.info('Starting PDF processing', context);

    const pdfjsLib = await initPdfLib();
    if (pdfjsLib === null) {
      throw new PdfLibInitializationError();
    }

    const arrayBuffer = await withTimeout(file.arrayBuffer(), PARSE_TIMEOUT);
    const pdf = await withTimeout(
      pdfjsLib.getDocument({
        data: arrayBuffer,
        disableAutoFetch: true,
        disableStream: true,
      }).promise,
      PARSE_TIMEOUT,
    );

    logger.info('PDF document loaded', {
      ...context,
      pageCount: pdf.numPages,
    });
    if (pdf.numPages > 5000) {
      const error = new Error('PDF has too many pages');
      captureError(error, {
        ...context,
        pageCount: pdf.numPages,
        errorType: 'TooManyPagesError',
      });
      throw error;
    }

    const pagePromises = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      pagePromises.push(
        withTimeout(
          pdf.getPage(i).then((page) =>
            page.getTextContent({
              includeMarkedContent: false,
            }),
          ),
          PARSE_TIMEOUT,
        ),
      );
    }

    const pages = await Promise.all(pagePromises);
    logger.info('PDF text extraction complete', {
      ...context,
      pageCount: pdf.numPages,
    });

    return pages
      .map((page: TextContent) =>
        page.items
          .filter(
            (item): item is TextItem => 'str' in item && !('type' in item),
          )
          .map((item) => item.str)
          .join(' '),
      )
      .join('\n\n');
  } catch (error) {
    const errorToThrow =
      error instanceof Error ? error : new Error('PDF processing failed');
    const errorType =
      error instanceof PdfLibInitializationError
        ? 'PdfLibInitializationError'
        : 'PdfProcessingError';

    captureError(errorToThrow, {
      ...context,
      errorType,
    });

    throw new Error(`PDF processing failed: ${errorToThrow.message}`);
  }
}

/**
 * Main entry point for file parsing.
 * Routes PDFs to pdf handler which has its own worker
 * while other supported files are routed to the worker.
 */
export const parseFileInWorker = async (
  file: File,
  workerUrl?: string,
): Promise<string> => {
  const context = {
    fileType: file.type,
    fileSize: file.size,
  };

  try {
    logger.info('Starting file parsing', context);

    if (!file || !(file instanceof File)) {
      const error = new Error('Invalid file object');
      captureError(error, { ...context, errorType: 'InvalidFileError' });
      throw error;
    }

    if (file.type === 'application/pdf') {
      return handlePdf(file);
    }

    return parseInWorker(file, workerUrl);
  } catch (error) {
    const errorToThrow =
      error instanceof Error ? error : new Error('File parsing failed');
    captureError(errorToThrow, {
      ...context,
      errorType: 'ParseFileError',
    });
    throw errorToThrow;
  }
};

export function terminateWorker() {
  worker?.terminate();
  worker = null;
}

export function isMimeTypeSupported(type: string): type is SupportedMimeTypes {
  return [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain',
    'text/csv',
  ].includes(type);
}
