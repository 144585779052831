import { useStackChat } from '@mirage/service-experimentation/useStackChat';
import i18n from '@mirage/translations';
import styles from './Actions.module.css';
import { ManageStackMenu } from './ManageStackMenu';
import { PinStackButton } from './PinStackButton';
import { ShareStackButton, ShareStackIconButton } from './ShareStackButton';

import type { ActionsMenuProps, SetMenuOpenForStackId } from '../ActionsMenu';
import type { stacks, users } from '@dropbox/api-v2-client';
import type { MenuPlacement } from '@dropbox/dig-components/menu';
import type { CoreTooltipProps } from '@mirage/shared/buttons/ButtonWithTooltip';

export type ActionsProps = Omit<ActionsMenuProps, 'onShare' | 'stack'> & {
  stack: stacks.Stack | null;
  currentAccount?: users.FullAccount;
  triggerEditStackRef?: React.RefObject<HTMLInputElement>;
  showShareLabeledIcon?: boolean;
  shareButtonTooltipProps?: CoreTooltipProps;
  mutationRequestId?: string;
  sessionId?: string;
};

export default function Actions({
  stack,
  currentAccount,
  setMenuOpenForStackId,
  iconColor,
  menuPlacement,
  showPinnedIcon = true,
  triggerEditStackRef,
  showShareLabeledIcon,
  shareButtonTooltipProps = { title: '' },
  mutationRequestId,
  sessionId,
}: ActionsProps) {
  const isNewStack = !stack?.namespace_id;

  const onEditStack = () => {
    triggerEditStackRef?.current?.click();
  };
  const showShareIconOnly = !showShareLabeledIcon;

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        {showShareIconOnly && !isNewStack && (
          <ShareStackIconButton stack={stack} iconColor={iconColor} />
        )}
        {showPinnedIcon && (
          <PinStackButton
            stack={stack}
            mutationRequestId={mutationRequestId}
            sessionId={sessionId}
            currentAccount={currentAccount}
            iconColor={iconColor}
          />
        )}
        <ManageStackMenu
          stack={stack}
          iconType="more"
          currentAccount={currentAccount}
          setMenuOpenForStackId={setMenuOpenForStackId}
          iconColor={iconColor}
          menuPlacement={menuPlacement}
          onEdit={triggerEditStackRef ? onEditStack : undefined}
        />
        {showShareLabeledIcon && !isNewStack && (
          <ShareStackButton
            stack={stack}
            iconType="arrow"
            variant="outline"
            shareButtonTooltipProps={shareButtonTooltipProps}
            disabled={!currentAccount}
          >
            {i18n.t('share')}
          </ShareStackButton>
        )}
      </div>
    </div>
  );
}

interface HeaderActionsProps {
  stack: stacks.Stack | null;
  menuPlacement?: MenuPlacement;
  mutationRequestId?: string;
  sessionId?: string;
  currentAccount?: users.FullAccount;
  iconColor?: string;
  setMenuOpenForStackId?: SetMenuOpenForStackId;
}

export const HeaderActions: React.FC<HeaderActionsProps> = ({
  stack,
  menuPlacement,
  mutationRequestId,
  sessionId,
  currentAccount,
  iconColor,
  setMenuOpenForStackId,
}) => {
  const isStackChatEnabled = useStackChat();

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        {!isStackChatEnabled && (
          <PinStackButton
            stack={stack}
            mutationRequestId={mutationRequestId}
            sessionId={sessionId}
            currentAccount={currentAccount}
            iconColor={iconColor}
          />
        )}
        <ManageStackMenu
          stack={stack}
          iconType="gear"
          currentAccount={currentAccount}
          setMenuOpenForStackId={setMenuOpenForStackId}
          iconColor={iconColor}
          menuPlacement={menuPlacement}
        />
      </div>
    </div>
  );
};
