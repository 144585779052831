import {
  HighFiveMini,
  LockMini,
  MailboxMini,
} from '@dropbox/dig-illustrations';
import { RedirectModal } from '@mirage/growth/components/RedirectModal/RedirectModal';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { tagged } from '@mirage/service-logging';
import { openURL } from '@mirage/service-platform-actions';
import {
  DROPBOX_ONBOARDING_START_URL,
  DROPBOX_TEAM_JOIN_URL,
} from '@mirage/shared/urls';
import { WEB_HOST_LOCAL } from '@mirage/shared/util/build-vars';
import i18n from '@mirage/translations';
import { useRef, useState } from 'react';
import {
  isOnWebOnboarding,
  useWebOnboardingEligibility,
} from './hooks/useWebOnboardingEligibility';
import { RedirectVariant } from './types';

import type { RedirectModalProps } from '@mirage/growth/components/RedirectModal/RedirectModal';

const logger = tagged('FullPageRedirect');

interface Props {
  variant: RedirectVariant;
  isDfbUser?: boolean;
  isEligible?: boolean;
  isDevboxBackend?: boolean;
}

export const FullPageRedirect = ({
  variant,
  isDfbUser = false,
  isEligible = false,
  isDevboxBackend = false,
}: Props) => {
  const { isUserEligibleOnWeb } = useWebOnboardingEligibility();
  const [dismissed, setDismissed] = useState(false);
  const isOnboardingPageRef = useRef<boolean | undefined>(undefined);
  const currentAccount = useDropboxAccount();

  const teamName = currentAccount?.team?.name ?? i18n.t('fallback_team_name');

  const modalConfig = {
    [RedirectVariant.CountryBlock]: {
      asset: <LockMini altText={i18n.t('lock')} />,
      description: i18n.t('blocked_country_description'),
      title: i18n.t('blocked_country_title'),
    },
    [RedirectVariant.GenericBlock]: {
      asset: <LockMini altText={i18n.t('lock')} />,
      description: i18n.t('blocked_generic_description'),
      title: i18n.t('blocked_generic_title'),
    },
    [RedirectVariant.PendingInviteBlock]: {
      asset: <MailboxMini altText={i18n.t('mailbox')} />,
      description: i18n.t('blocked_pending_invite_description', {
        teamName,
      }),
      title: i18n.t('blocked_pending_invite_title'),
      primaryCta: {
        label: i18n.t('join_team_cta'),
        onClick: () => {
          openURL(DROPBOX_TEAM_JOIN_URL);
        },
      },
    },
    [RedirectVariant.DfbOnboarding]: {
      asset: <HighFiveMini altText={i18n.t('high_five')} />,
      description: i18n.t('blocked_onboarding_description'),
      title: i18n.t('blocked_onboarding_title'),
      primaryCta: {
        label: i18n.t('blocked_onboarding_cta'),
        onClick: () => {
          setDismissed(true);
          const onboardingUrl = isDevboxBackend
            ? // bypassing devbox url that redirects user to correct onboarding page and go to DfB onboarding start url
              WEB_HOST_LOCAL + 'setup/team'
            : DROPBOX_ONBOARDING_START_URL;
          openURL(onboardingUrl);
        },
      },
    },
  };

  const getConfigForVariant = (
    variant: RedirectVariant,
  ): (RedirectModalProps & { variant: RedirectVariant }) | undefined => {
    const config = modalConfig[variant];

    if (!config) {
      logger.error(
        `User navigated to invalid redirect page variant: ${variant}`,
      );
      return;
    }

    return { ...config, variant };
  };

  const props = getConfigForVariant(variant);
  // `useUpdateWithCheckResult` will call showModal once at page load based on eligibility check result,
  // and then that state won't be updated even if eligibility state updates on backend due to user
  // finishing onboarding flow. This means that if something triggers a re-render of FullPageRedirect on url/page change,
  // user will be shown the modal once user is no longer on onboarding page.
  // To avoid this case, we use ref to avoid re-running the onboarding check on every render, and just run it once on page load.
  if (isOnboardingPageRef.current === undefined) {
    isOnboardingPageRef.current = isOnWebOnboarding(
      window.location.pathname,
      window.location.protocol,
    );
  }

  // Don't show onboarding redirect modal if on onboarding already
  if (
    isOnboardingPageRef.current &&
    isUserEligibleOnWeb(variant, isDfbUser, isEligible)
  ) {
    return null;
  }

  if (dismissed || !props) {
    return null;
  }

  return <RedirectModal {...props} />;
};
