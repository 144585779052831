import { IconButton } from '@dropbox/dig-components/buttons';
import { IconButtonGroup } from '@dropbox/dig-components/combinations';
import { Table } from '@dropbox/dig-components/table';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { DeleteLine, EditLine } from '@dropbox/dig-icons/assets';
import { DeleteVerificationModal } from '@mirage/mosaics/Verification/DeleteVerificationModal';
import { VerifyContentModal } from '@mirage/mosaics/Verification/VerifyContentModal';
import { ResultIcon } from '@mirage/search/SearchResults';
import { PersonMetadata } from '@mirage/search/SearchResults/Metadata/PersonMetadata';
import { useDeleteVerification } from '@mirage/service-curations/useDeleteVerification';
import { buildCuratorUser } from '@mirage/service-dbx-api/service/utils';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';
import styles from './VerifiedResultsTable.module.css';

import type { dash } from '@dropbox/api-v2-client';
import type { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';
import type { HydratedCuration } from '@mirage/service-curations';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { Row } from 'react-table';

export const VerifiedResultsTable = ({
  data,
  updateSavedVerification,
  dashActionSurface,
}: {
  data: HydratedCuration[];
  updateSavedVerification: (
    uuid: string,
    verification: dash.Curation | null,
  ) => void;
  dashActionSurface: DashActionSurface;
}) => {
  const handleSaveDeletion = (uuid: string, curation: dash.Curation | null) => {
    updateSavedVerification(uuid, curation);
  };

  const {
    hasDeleteFailed,
    isDeleting,
    isDeleteConfirmOpen,
    confirmDeletion,
    initiateDeletion,
    cancelDeletion,
    resultTitle,
  } = useDeleteVerification(handleSaveDeletion, dashActionSurface);

  const columns = useMemo(
    () => [
      {
        id: 'title',
        Header: i18n.t('verifications_table_header_title'),
        Cell: TitleCell,
        width: '300',
      },
      {
        id: 'verification_created_by',
        Header: i18n.t('verifications_table_header_created_by'),
        Cell: VerifiedByCell,
        width: '200',
      },
      {
        id: 'last_verified_by',
        Header: i18n.t('verifications_table_header_updated_by'),
        Cell: UpdatedByCell,
        width: '200',
      },
    ],
    [],
  );
  const tableInstance = useTable<HydratedCuration>(
    {
      columns,
      data,
    },
    useRowSelect,
    useFlexLayout,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const [verificationToEdit, setVerificationToEdit] =
    useState<SearchResult | null>(null);

  const handleEdit = (e: React.MouseEvent, curation: HydratedCuration) => {
    e.stopPropagation();
    setVerificationToEdit(curation.result);
  };

  const handleDelete = (e: React.MouseEvent, curation: HydratedCuration) => {
    e.stopPropagation();
    initiateDeletion(curation.result);
  };

  return (
    <div className={styles.tableContainer}>
      <Table
        {...getTableProps()}
        spacing="xlarge"
        hasDividers
        verticalAlign="center"
        className={styles.table}
      >
        <Table.Header>
          {headerGroups.map((headerGroup) => {
            const { key, ...rest } = headerGroup.getHeaderGroupProps();
            return (
              <Table.Row key={key} className={styles.headerRow} {...rest}>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {headerGroup.headers.map((column: any) => {
                  const { key, ...rest } = column.getHeaderProps();
                  return (
                    <Table.HeaderCell
                      {...rest}
                      {...column.getHeaderProps({})}
                      key={key}
                    >
                      <Text variant="label" isBold color="subtle">
                        {column.render('Header')}
                      </Text>
                    </Table.HeaderCell>
                  );
                })}
                <Table.HeaderCell className={styles.stickyHeader} />
              </Table.Row>
            );
          })}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...rest } = row.getRowProps({});
            const onClick = () => {
              if (row.original?.result) {
                setVerificationToEdit(row.original.result);
              }
            };
            return (
              <Table.Row
                tabIndex={0}
                key={key}
                isSelectable
                onClick={onClick}
                onKeyDown={onKeyDownCommitFn(onClick)}
                className={styles.contentRow}
                {...rest}
              >
                {row.cells.map((cell) => {
                  const { key, ...rest } = cell.getCellProps();
                  return (
                    <Table.Cell {...rest} {...cell.getCellProps({})} key={key}>
                      {cell.render('Cell')}
                    </Table.Cell>
                  );
                })}
                <Table.Cell className={styles.stickyCell}>
                  <IconButtonGroup>
                    {() => (
                      <>
                        <DigTooltip title={i18n.t('verification_edit_label')}>
                          <IconButton
                            aria-label={i18n.t('verification_edit_label')}
                            variant="borderless"
                            onClick={(e: React.MouseEvent) =>
                              handleEdit(e, row.original)
                            }
                          >
                            <UIIcon src={EditLine} />
                          </IconButton>
                        </DigTooltip>
                        <DigTooltip title={i18n.t('verification_delete_label')}>
                          <IconButton
                            aria-label={i18n.t('verification_delete_label')}
                            variant="borderless"
                            onClick={(e: React.MouseEvent) => {
                              handleDelete(e, row.original);
                            }}
                          >
                            <UIIcon src={DeleteLine} />
                          </IconButton>
                        </DigTooltip>
                      </>
                    )}
                  </IconButtonGroup>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {verificationToEdit && (
        <VerifyContentModal
          open={!!verificationToEdit}
          result={verificationToEdit}
          onClose={() => setVerificationToEdit(null)}
          dashActionSurface="published_content_verifications"
          onSave={(curation: dash.Curation | null) => {
            updateSavedVerification(verificationToEdit.uuid, curation);
            setVerificationToEdit(null);
          }}
        />
      )}

      <DeleteVerificationModal
        open={isDeleteConfirmOpen}
        processing={isDeleting}
        hasDeleteFailed={hasDeleteFailed}
        onConfirmDeletion={confirmDeletion}
        onDismiss={cancelDeletion}
        onCancelDeletion={cancelDeletion}
        resultTitle={resultTitle}
      />
    </div>
  );
};

const UpdatedByCell = ({ row }: { row: Row<HydratedCuration> }) => {
  const updatedDate = row.original.curation?.updated_at_ms
    ? new Date(row.original.curation?.updated_at_ms)
    : undefined;

  return (
    <div className={styles.verifiedByColumn}>
      <PersonMetadata
        i18nKey="by"
        person={buildCuratorUser(row.original.curation?.updated_by)}
      />
      {updatedDate && (
        <Text color="subtle" size="small">
          {format(updatedDate, 'MMM d, yyyy')}
        </Text>
      )}
    </div>
  );
};

const VerifiedByCell = ({ row }: { row: Row<HydratedCuration> }) => {
  const updatedDate = row.original.curation?.created_at_ms
    ? new Date(row.original.curation?.created_at_ms)
    : undefined;

  return (
    <div className={styles.verifiedByColumn}>
      <PersonMetadata
        i18nKey="by"
        person={buildCuratorUser(row.original.curation?.created_by)}
      />
      {updatedDate && (
        <Text color="subtle" size="small">
          {format(updatedDate, 'MMM d, yyyy')}
        </Text>
      )}
    </div>
  );
};

const TitleCell = ({ row }: { row: Row<HydratedCuration> }) => {
  return (
    <div className={styles.titleContainer}>
      <ResultIcon result={row.original.result} size="large" />
      <Truncate lines={2}>
        <Text>{row.original.result.title}</Text>
      </Truncate>
    </div>
  );
};
