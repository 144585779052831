import { Card } from '@dropbox/dash-component-library';
import { IconButton } from '@dropbox/dig-components/buttons';
import { IconButtonGroup } from '@dropbox/dig-components/combinations';
import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkLine,
  ChevronDownLine,
  ChevronUpLine,
} from '@dropbox/dig-icons/assets';
// import { useVerticalExpandableRef } from '@mirage/shared/FLIP/useVerticalExpandableRef';
import { VerticalExpandable } from '@mirage/shared/FLIP/VerticalExpandable';
import classnames from 'classnames';
import React from 'react';
import styles from './ExpandableCard.module.css';

export const ExpandableCard = ({
  step,
  title,
  children,
  active = false,
  expanded = false,
  completed = false,
  toggleExpanded,
}: {
  step: number;
  children: React.ReactNode;
  title: React.ReactNode;
  active?: boolean;
  expanded?: boolean;
  completed?: boolean;
  toggleExpanded?: () => void;
}) => {
  // const ref = useVerticalExpandableRef();

  return (
    <Card.Module
      className={styles.card}
      breakout={expanded ? 'xsmall' : undefined}
      explicitHeight={!expanded ? 66 : undefined}
      withHeader={
        <Card.Header
          breakout="xsmall"
          title={
            <Split alignY="center">
              <div
                className={classnames(styles.step, {
                  [styles.active]: active,
                  [styles.completed]: completed,
                })}
              >
                {!completed ? (
                  <Text size="medium" className={styles.stepText}>
                    {step}
                  </Text>
                ) : (
                  <UIIcon src={CheckmarkLine} />
                )}
              </div>
              <div>
                <Text size="large" isBold>
                  {title}
                </Text>
              </div>
            </Split>
          }
          withMargin={false}
          actions={
            <IconButtonGroup size="medium">
              {({ getButtonProps }) => (
                <IconButton
                  {...getButtonProps()}
                  variant="borderless"
                  onClick={toggleExpanded}
                >
                  <UIIcon src={expanded ? ChevronUpLine : ChevronDownLine} />
                </IconButton>
              )}
            </IconButtonGroup>
          }
        />
      }
    >
      <VerticalExpandable>{expanded && children}</VerticalExpandable>
    </Card.Module>
  );
};
