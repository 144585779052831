import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { Link } from '@mirage/link/Link';
import { getAbbreviatedFullName } from '@mirage/shared/account';
import { Trans } from '@mirage/translations';
import { useEffect, useMemo, useState } from 'react';
import { TeamMembersList } from './TeamMemberList';

import type { TeamMember } from './types';

export type TeamMembersCaptionDropdownProps = {
  teamMembers: Readonly<TeamMember[]>;
  maxAvatars: number;
  remainderCount: number;
};

export function TeamMembersCaptionDropdown({
  teamMembers,
  maxAvatars,
  remainderCount,
}: TeamMembersCaptionDropdownProps) {
  const [menuOffset, setMenuOffset] = useState(0);

  const getCaption = useMemo(() => {
    // Removes period at end of abbreviated full name
    const memberAbbreviatedName = teamMembers
      .slice(0, maxAvatars)
      .map((member) => getAbbreviatedFullName(member.name).slice(0, -1));

    const renderCaption = (dropdownLink: JSX.Element) => {
      switch (teamMembers.length) {
        case 1:
          return (
            <Trans
              i18nKey="members_one_only"
              values={{ member: memberAbbreviatedName[0] }}
              components={{ Dropdown: dropdownLink }}
            />
          );
        case 2:
          return (
            <Trans
              i18nKey="members_two_only"
              values={{
                member1: memberAbbreviatedName[0],
                member2: memberAbbreviatedName[1],
              }}
              components={{ Dropdown: dropdownLink }}
            />
          );
        default:
          return (
            <Trans
              i18nKey="members_with_remainder"
              values={{
                member1: memberAbbreviatedName[0],
                member2: memberAbbreviatedName[1],
                count: remainderCount,
              }}
              components={{ Dropdown: dropdownLink }}
            />
          );
      }
    };

    return renderCaption;
  }, [teamMembers, maxAvatars, remainderCount]);

  // Offset used to shift pop up menu to left to align with start of caption text
  // Offset is necessary because DIG Menu aligns pop up to trigger element
  useEffect(() => {
    const textElm = document.getElementById('invite-entire-team-caption');
    const linkElm = document.getElementById('invite-entire-team-link');
    if (textElm && linkElm) {
      const textStart = textElm.getBoundingClientRect().left;
      const linkStart = linkElm.getBoundingClientRect().left;
      setMenuOffset(textStart - linkStart);
    }
  }, [teamMembers]);

  return (
    <Menu.Wrapper>
      {({ getContentProps, getTriggerProps }) => {
        const dropdownLink = (
          <Link
            hasNoUnderline
            id="invite-entire-team-link"
            href="#"
            {...getTriggerProps({
              onClick: (e) => {
                e.preventDefault();
              },
            })}
          />
        );

        return (
          <>
            <Text
              variant="paragraph"
              size="small"
              color="subtle"
              id="invite-entire-team-caption"
            >
              {getCaption(dropdownLink)}
            </Text>
            <Menu.Content
              {...getContentProps()}
              placement="bottom-start"
              triggerOffset={{
                mainAxis: 4,
                crossAxis: menuOffset,
              }}
            >
              <TeamMembersList teamMembers={teamMembers} />
            </Menu.Content>
          </>
        );
      }}
    </Menu.Wrapper>
  );
}
