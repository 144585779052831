import { tagged } from '@mirage/service-logging';

import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { SearchResult } from '@mirage/service-dbx-api';
import type {
  PlatformActionMode,
  PlatformActionsAdapter,
} from '@mirage/service-platform-actions/service';
import type { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';

const logger = tagged('web-adapter');

// TODO: Centralize this!
// TODO: Figure out if we want ALLOW_LIST_PREFIXES too
const DENY_LIST_PROTOCOLS = ['javascript:'];

export class WebPlatformActionsAdapter implements PlatformActionsAdapter {
  getActionMode(): PlatformActionMode {
    return 'direct';
  }

  /**
   * Returns an error message if the URL is not safe to open.
   */
  urlCanBeOpened(urlString: string): string | null {
    try {
      const url = new URL(urlString);
      if (
        DENY_LIST_PROTOCOLS.some((disallowed) => url.protocol === disallowed)
      ) {
        return 'URL has disallowed protocol';
      }
    } catch (e) {
      return `URL could not be parsed: ${e}`;
    }

    return null;
  }

  async openURL(urlString: string | null) {
    try {
      if (!urlString) throw new Error('URL missing on Search Result');

      const urlValidationError = this.urlCanBeOpened(urlString);
      if (urlValidationError) throw new Error(urlValidationError);

      // Ok to use inside openURL().
      // eslint-disable-next-line no-restricted-syntax
      window.open(urlString, '_blank', 'noopener,noreferrer');
    } catch (error) {
      logger.error(error);
    }
  }

  async openResult(
    result: SearchResult | QuestionAndAnswerSource | SummarizableResult,
  ) {
    // just always open the URL on web for now
    return this.openURL(result.url);
  }

  async openLocalPath() {
    logger.error(`openLocalPath not implemented in web adapter, doing nothing`);
  }

  async runLocalLaunchCommandForShortcut() {
    return false;
  }

  async copyToClipboard(text: string | null) {
    if (!text) return Promise.reject('No text to copy to clipboard');
    try {
      navigator.clipboard.writeText(text);
    } catch (error) {
      logger.error(error);
    }
  }

  async openPersistentApp() {
    logger.error('Call made to openPersistentApp on web. No-op.');
    return;
  }
}
