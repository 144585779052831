import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Select_DashSearchResultSeeMore } from '@mirage/analytics/events/types/select_dash_search_results_see_more';
import { MOTION_SETTINGS } from '@mirage/mosaics/Layout/constants';
import { useResultPAPLogger } from '@mirage/shared/hooks/useResultPAPLogger';
import {
  concatActiveFilterBindings,
  contentTypeToSearchFilter,
} from '@mirage/shared/search/search-filters';
import i18n from '@mirage/translations';
import { reportEvent } from '@mirage/webapp/analytics/logging';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useState } from 'react';
import { CategoryImageGrid } from './CategoryImageGrid';
import { CategoryResultList } from './CategoryResultList';
import styles from './CategorySearchAccordion.module.css';
import { useCategorySearch } from './useCategorySearch';

import type { CategoryConfig } from './config';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

type Props = {
  query: string;
  seeAllForCategory: (filter: SearchFilter[]) => void;
  categories: CategoryConfig[];
};

export function CategorySearchAccordion({
  query,
  seeAllForCategory,
  categories,
}: Props) {
  const [expandedPanels, setExpandedPanels] = useState<string[]>(() =>
    categories
      .filter((category) => category.defaultOpen)
      .map((category) => category.id),
  );

  const handleToggleCategory = (itemId: string) => {
    setExpandedPanels((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId],
    );
  };

  return (
    <Box marginY="24">
      {categories.map((category, i) => {
        const isOpen = expandedPanels.includes(category.id);

        return (
          <Category
            key={category.id}
            query={query}
            category={category}
            isOpen={isOpen}
            handleToggleCategory={handleToggleCategory}
            seeAllForCategory={seeAllForCategory}
            isFirstCategory={i === 0}
          />
        );
      })}
    </Box>
  );
}

type CategoryProps = {
  query: string;
  category: CategoryConfig;
  isOpen: boolean;
  handleToggleCategory: (id: string) => void;
  seeAllForCategory: (filter: SearchFilter[]) => void;
  isFirstCategory: boolean;
};

function Category({
  query,
  category,
  isOpen,
  handleToggleCategory,
  seeAllForCategory,
  isFirstCategory,
}: CategoryProps) {
  const { loading, results } = useCategorySearch(category, query);
  const { logSearchResultOpen, logSearchResultShown } = useResultPAPLogger();

  const handleResultClick = useCallback(
    (result: SearchResult) => {
      const filter = contentTypeToSearchFilter(category.contentType);
      logSearchResultOpen({
        defaultProps: {
          query,
          searchResult: result,
          filters: filter ? [filter] : [],
          results,
          featureLine: 'search',
        },
        rightRailOpened: false,
        isTypeahead: false,
        launchMethod: 'click',
        actionSurfaceComponent: 'result_row',
        categorizedResult: true,
      });
    },
    [logSearchResultOpen, query, category.contentType, results],
  );

  const handleResultShown = useCallback(
    (result: SearchResult) => {
      const filter = contentTypeToSearchFilter(category.contentType);
      logSearchResultShown(
        {
          query,
          searchResult: result,
          filters: filter ? [filter] : [],
          results,
          featureLine: 'search',
        },
        false,
        'result_row',
      );
    },
    [logSearchResultShown, query, category.contentType, results],
  );

  const handleSeeAll = (e: React.MouseEvent) => {
    e.stopPropagation();
    const filter = contentTypeToSearchFilter(category.contentType);
    if (!filter) return;
    seeAllForCategory([filter]);
    reportEvent(
      PAP_Select_DashSearchResultSeeMore({
        featureLine: 'search',
        categorizedResult: true,
        activeFilters: concatActiveFilterBindings([filter]),
      }),
    );
  };

  return (
    <Box display="flex" flexDirection="column" key={category.id}>
      <button
        className={styles.accordionHeader}
        onClick={() => handleToggleCategory(category.id)}
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          borderWidth="1"
          justifyContent="space-between"
          borderTop={!isFirstCategory ? 'Solid' : undefined}
          borderColor="Border Subtle"
          paddingY="24"
        >
          <Text variant="label" size="medium" color="subtle">
            {category.label}
          </Text>
          <Box display="flex" alignItems="center" className={styles.gapSpacing}>
            {isOpen && (
              <Button size="small" variant="transparent" onClick={handleSeeAll}>
                {i18n.t('see_all')}
              </Button>
            )}
            <UIIcon src={isOpen ? ChevronUpLine : ChevronDownLine} />
          </Box>
        </Box>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div {...MOTION_SETTINGS}>
            {category.layout === 'list' ? (
              <CategoryResultList
                results={results}
                loading={loading}
                onResultClick={handleResultClick}
                onResultShown={handleResultShown}
              />
            ) : (
              <CategoryImageGrid
                results={results}
                loading={loading}
                onResultClick={handleResultClick}
                onResultShown={handleResultShown}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}
