import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { getFeatureRolloutRingFromString } from '@mirage/analytics/events/enums/feature_rollout_ring-utils';
import { PAP_Exposure_DashGrowthbook } from '@mirage/analytics/events/types/exposure_dash_growthbook';
import { PAP_Exposure_Stormcrow } from '@mirage/analytics/events/types/exposure_stormcrow';
import { useFeatureRingSettings } from '@mirage/service-feature-ring-settings/hooks/useFeatureRingSettings';
import { tagged } from '@mirage/service-logging';
import { useCallback, useEffect } from 'react';
import { useFeatureFlags } from './useInitFeatureFlags';
import { convertFeatureValueToBool } from './util';

import type { FeatureName, FeatureValue } from './features';

const logger = tagged('useFeatureFlagValue');

const useLogExposureCallback = (feature: FeatureName) => {
  const featureFlags = useFeatureFlags();
  const {
    source,
    value: serverValue,
    featureName,
    overrideValue,
  } = featureFlags[feature];
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { getFeatureRingFromCacheForLogging } = useFeatureRingSettings();
  const resolvedValue = overrideValue ?? serverValue;

  return useCallback(() => {
    const experimentVariant = resolvedValue?.toString();

    // Automatically log exposure to PAP if the feature flag is coming from Growthbook
    if (source === 'growthbook') {
      getFeatureRingFromCacheForLogging()
        .then((featureRing) =>
          reportPapEvent(
            PAP_Exposure_DashGrowthbook({
              feature: featureName,
              experimentVariant,
              featureRolloutRing: featureRing
                ? getFeatureRolloutRingFromString(featureRing)
                : undefined,
            }),
          ),
        )
        .catch((error) => {
          logger.error(
            `Failed to get feature ring from cache for PAP exposure logging: ${error.message}`,
          );
        });
    }

    // Log exposure to PAP if the feature flag is coming from Stormcrow
    if (source === 'stormcrow') {
      reportPapEvent(
        PAP_Exposure_Stormcrow({
          feature: featureName,
          experimentVariant,
        }),
      );
    }
  }, [
    featureName,
    getFeatureRingFromCacheForLogging,
    reportPapEvent,
    resolvedValue,
    source,
  ]);
};
export const useFeatureFlagValue = (
  feature: FeatureName,
  logExposureImmediately = true,
): FeatureValue => {
  const featureFlags = useFeatureFlags();
  const { value: serverValue, overrideValue } = featureFlags[feature];

  const resolvedValue = overrideValue ?? serverValue;

  const exposureLoggingCallback = useLogExposureCallback(feature);
  useEffect(() => {
    if (logExposureImmediately) {
      exposureLoggingCallback();
    }
  }, [exposureLoggingCallback, logExposureImmediately]);

  return resolvedValue;
};

/**
 * Return the value of an experiment and a callback to log exposure to PAP. Useful when your experiment has
 * conditions other than just the feature value. Since you can't call hooks conditionally, you can instead
 * conditionally call the exposure logging callback when the user has actually seen your feature.
 *
 * @returns [FeatureValue, () => void] A tuple with the feature value and a callback to log exposure to PAP.
 */
export const useFeatureFlagValueWithLogExposureCallback = (
  feature: FeatureName,
): [FeatureValue, () => void] => {
  const featureValue = useFeatureFlagValue(feature, false);
  const logExposureCallback = useLogExposureCallback(feature);

  return [featureValue, logExposureCallback];
};

export const useFeatureFlagValueBool = (
  feature: FeatureName,
  includeExposureLogging = true,
): boolean => {
  return convertFeatureValueToBool(
    useFeatureFlagValue(feature, includeExposureLogging),
  );
};
