import { Avatar } from '@dropbox/dig-components/avatar';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { getInitials } from '@mirage/shared/account';
import { getSharingUserContacts } from '@mirage/stacks/ShareModal/Api';
import i18n from '@mirage/translations';
import cx from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import styles from './UserProfileAvatar.module.css';

import type { AvatarSizes } from '@dropbox/dig-components/avatar';
import type { UserInfo } from '@mirage/mosaics/UserProfilePage/types';

type ProfileAvatarProps = {
  className?: string;
  style?: React.CSSProperties;
  size?: AvatarSizes | 'xlarge' | 'profile' | 'edit';
  onLeave?: boolean;
  isInactive?: boolean;
  user: Pick<UserInfo, 'name' | 'email'>;
};

/**
 * Avatar component for displaying user a profile photo or initials
 *
 * @param size - size of the avatar
 * @param user - Employee | GoalUser | PulseUser | ldap
 * @param onLeave - whether the user is on leave
 */
export const UserProfileAvatar = forwardRef<HTMLElement, ProfileAvatarProps>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ size, user, onLeave, isInactive, className, style }, ref: any) => {
    const [photoUrl, setPhotoUrl] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false);
    const [transform, setTransform] = useState(0);

    const isCustomSize = ['xlarge', 'profile', 'edit'].includes(size ?? '');

    const employeeInitials = getInitials(user.name);

    useEffect(() => {
      const fetchUserContacts = async () => {
        try {
          const result = await getSharingUserContacts(user.email);
          if (result?.[0]?.profilePhotoUrl) {
            setPhotoUrl(result[0].profilePhotoUrl);
          } else {
            setImageLoaded(true);
          }
        } catch (error) {
          setImageLoaded(true);
        }
      };

      fetchUserContacts();
    }, [user.email]);

    // Hacky workaround until we allow people to adjust their crop
    useEffect(() => {
      const image = new Image();
      image.src = photoUrl;

      const onLoad = () => {
        setImageLoaded(true);

        const width = image.naturalWidth;
        const height = image.naturalHeight;

        if (height === width) {
          // Square thumbnail from editor
          setTransform(0);
        } else if (height === 300 && width === 200) {
          // This seems to be a fairly common dimension
          setTransform(1);
        } else if (height > width) {
          // otherwise they're ~195 x ~315 — ish.
          setTransform(2);
        }
      };
      image.addEventListener('load', onLoad);
      return () => {
        image.removeEventListener('load', onLoad);
      };
    }, [photoUrl]);

    if (onLeave && size !== 'profile') {
      throw new Error(
        'DSYS/Avatar.tsx: `onLeave` prop is only supported for `profile` size avatars',
      );
    }

    return (
      <Box
        position="relative"
        ref={ref}
        className={cx(className, {
          [styles.avatar64]: size === 'xlarge',
          [styles.avatar104]: size === 'profile',
          [styles.avatar211]: size === 'edit',
        })}
        style={style}
      >
        {onLeave && imageLoaded && (
          <div className={styles.wrapper}>
            <div className={styles.ticker}>
              <div className={styles.tickerText}>
                <Text size="small">{i18n.t('currently_on_leave')}</Text>
              </div>
            </div>
          </div>
        )}
        <Box
          as={Avatar}
          colorPalette={
            imageLoaded
              ? undefined
              : {
                  background: 'var(--dig-color__opacity__surface)',
                  foreground: '',
                }
          }
          src={photoUrl}
          hasNoOutline
          size={isCustomSize ? 'large' : (size as AvatarSizes)}
          alt={user.name}
          isInactive={isInactive}
          className={cx(styles.avatar, {
            [styles.avatarLoaded]: imageLoaded,
            [styles.deactivatedAvatarLoaded]: isInactive,
            [styles.moveUpXSmall]: transform === 1 && !isCustomSize,
            [styles.moveUpSmall]: transform === 2 && !isCustomSize,
            [styles.moveUpMedium]: transform === 2 && size === 'xlarge',
            [styles.moveUpBig]: transform === 2 && size === 'profile',
          })}
        >
          {imageLoaded ? employeeInitials : undefined}
        </Box>
      </Box>
    );
  },
);

export const UserProfileAvatarImage: React.FC<{
  displayName: string;
  profilePhotoUrl: string;
  size?: AvatarSizes;
}> = ({ displayName, profilePhotoUrl, size = 'standard' }) => {
  const initials = getInitials(displayName);
  return (
    <Avatar
      alt={displayName}
      src={profilePhotoUrl}
      size={size}
      hasImageBackgroundColor={false}
      hasNoOutline
    >
      {initials}
    </Avatar>
  );
};

UserProfileAvatar.displayName = 'UserProfileAvatar';
