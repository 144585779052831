import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine, SearchLine } from '@dropbox/dig-icons/assets';
import { LookMagnifyingGlassSpot } from '@dropbox/dig-illustrations';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { OnboardingConnectorsV2 } from '../flows/OnboardingConnectorsV2';
import styles from './ConnectorsModal.module.css';

import type { UIConnection, UIConnector } from '@mirage/shared/types';

type Props = {
  open: boolean;
  onClose: () => void;
  connectors?: (UIConnector | UIConnection)[];
};

const ConnectorsModalEmptyState = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <div className={styles.iconContainer}>
        <LookMagnifyingGlassSpot />
      </div>
      <Text className={styles.noResultsTitle}>
        {i18n.t('no_connector_search_results_text')}
      </Text>
    </Box>
  );
};

export const ConnectorsModal = ({ onClose, open, connectors }: Props) => {
  const isMobile = useIsMobileSize();
  const [filterText, setFilterText] = useState('');

  const filteredConnectors = useMemo(
    () =>
      connectors?.filter((connector) =>
        connector.branding?.display_name
          ?.toLowerCase()
          .includes(filterText.toLowerCase()),
      ),
    [connectors, filterText],
  );

  return (
    <Modal open={open} width={950} className={styles.modal}>
      <Modal.Header className={styles.modalHeader}>
        <Box
          className={styles.header}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box className={styles.filterInput}>
            <TextInput
              size="medium"
              withLeftAccessory={<UIIcon src={SearchLine} />}
              placeholder={i18n.t('onboarding_search_apps')}
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </Box>
          {!isMobile && (
            <IconButton
              className={styles.closeButton}
              aria-label={i18n.t('close')}
              variant="borderless"
              onClick={onClose}
            >
              <UIIcon src={CloseLine} />
            </IconButton>
          )}
        </Box>
      </Modal.Header>
      <Modal.Body
        className={classnames(styles.modalBody, isMobile && styles.mobile)}
      >
        {filteredConnectors?.length === 0 ? (
          <ConnectorsModalEmptyState />
        ) : (
          <OnboardingConnectorsV2
            numColumns={3}
            connectors={filteredConnectors}
            showSnackbar={showSnackbar}
            browserExtensionData={[]}
            showConnectorDescription
          />
        )}
      </Modal.Body>
      {isMobile && (
        <Modal.Footer>
          <Button size="xlarge" variant="primary" onClick={onClose} fullWidth>
            <span>{i18n.t('close')}</span>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
