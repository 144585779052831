import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashAddConnectionsModal } from '@mirage/analytics/events/types/dismiss_dash_add_connections_modal';
import { PAP_Shown_DashAddConnectionsModal } from '@mirage/analytics/events/types/shown_dash_add_connections_modal';
import { useDoOnceOnMount } from '@mirage/shared/hooks/useDoOnceOnMount';
import i18n from '@mirage/translations';

type Props = {
  open: boolean;
  onClose: (skip: boolean) => void;
};

export const AddConnectionsModal = ({ onClose, open }: Props) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  useDoOnceOnMount(() => {
    reportPapEvent(
      PAP_Shown_DashAddConnectionsModal({
        featureLine: 'onboarding',
      }),
    );
  });

  const handleClose = (skip: boolean) => {
    onClose(skip);
    reportPapEvent(
      PAP_Dismiss_DashAddConnectionsModal({
        isChooserModalSkipped: skip,
        featureLine: 'onboarding',
      }),
    );
  };

  return (
    <Modal open={open} width={600} isCentered>
      <Modal.Header>
        <Modal.Title>{i18n.t('add_apps_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{i18n.t('add_apps_modal_description')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="medium"
          variant="opacity"
          onClick={() => handleClose(true)}
        >
          {i18n.t('add_apps_modal_skip')}
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={() => handleClose(false)}
        >
          {i18n.t('add_apps')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
