// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/conversations.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { DocumentInfo } from './models_pb';

/**
 * @generated from enum context_engine.ResponseFormat
 */
export enum ResponseFormat {
  /**
   * @generated from enum value: MARKDOWN = 0;
   */
  MARKDOWN = 0,

  /**
   * @generated from enum value: JSON = 1;
   */
  JSON = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ResponseFormat)
proto3.util.setEnumType(ResponseFormat, 'context_engine.ResponseFormat', [
  { no: 0, name: 'MARKDOWN' },
  { no: 1, name: 'JSON' },
]);

/**
 * @generated from enum context_engine.ProgressUpdateStep
 */
export enum ProgressUpdateStep {
  /**
   * @generated from enum value: STEP_SEARCH = 0;
   */
  STEP_SEARCH = 0,

  /**
   * @generated from enum value: STEP_CONTEXT_SELECTION = 1;
   */
  STEP_CONTEXT_SELECTION = 1,

  /**
   * @generated from enum value: STEP_RESPONSE_GENERATION_FROM_LLM = 2;
   */
  STEP_RESPONSE_GENERATION_FROM_LLM = 2,

  /**
   * @generated from enum value: STEP_RESPONSE_GENERATION_FROM_AGENT = 3;
   */
  STEP_RESPONSE_GENERATION_FROM_AGENT = 3,

  /**
   * @generated from enum value: STEP_SUGGESTED_QUERIES_GENERATION = 4;
   */
  STEP_SUGGESTED_QUERIES_GENERATION = 4,

  /**
   * @generated from enum value: STEP_INTENT_DETECTION = 5;
   */
  STEP_INTENT_DETECTION = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ProgressUpdateStep)
proto3.util.setEnumType(
  ProgressUpdateStep,
  'context_engine.ProgressUpdateStep',
  [
    { no: 0, name: 'STEP_SEARCH' },
    { no: 1, name: 'STEP_CONTEXT_SELECTION' },
    { no: 2, name: 'STEP_RESPONSE_GENERATION_FROM_LLM' },
    { no: 3, name: 'STEP_RESPONSE_GENERATION_FROM_AGENT' },
    { no: 4, name: 'STEP_SUGGESTED_QUERIES_GENERATION' },
    { no: 5, name: 'STEP_INTENT_DETECTION' },
  ],
);

/**
 * @generated from enum context_engine.ConversationsAgent
 */
export enum ConversationsAgent {
  /**
   * @generated from enum value: UNKNOWN_AGENT = 0;
   */
  UNKNOWN_AGENT = 0,

  /**
   * @generated from enum value: STACK_INSIGHTS = 1;
   */
  STACK_INSIGHTS = 1,

  /**
   * @generated from enum value: WRITE = 2;
   */
  WRITE = 2,

  /**
   * @generated from enum value: CONVERSATION = 3;
   */
  CONVERSATION = 3,

  /**
   * @generated from enum value: SUMMARIZE = 4;
   */
  SUMMARIZE = 4,

  /**
   * @generated from enum value: FIND = 5;
   */
  FIND = 5,

  /**
   * @generated from enum value: SEARCH = 6;
   */
  SEARCH = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ConversationsAgent)
proto3.util.setEnumType(
  ConversationsAgent,
  'context_engine.ConversationsAgent',
  [
    { no: 0, name: 'UNKNOWN_AGENT' },
    { no: 1, name: 'STACK_INSIGHTS' },
    { no: 2, name: 'WRITE' },
    { no: 3, name: 'CONVERSATION' },
    { no: 4, name: 'SUMMARIZE' },
    { no: 5, name: 'FIND' },
    { no: 6, name: 'SEARCH' },
  ],
);

/**
 * @generated from message context_engine.ProgressUpdate
 */
export class ProgressUpdate extends Message<ProgressUpdate> {
  /**
   * @generated from field: context_engine.ProgressUpdateStep step = 1;
   */
  step = ProgressUpdateStep.STEP_SEARCH;

  /**
   * @generated from field: repeated context_engine.DocumentInfo context = 2;
   */
  context: DocumentInfo[] = [];

  /**
   * @generated from field: string agent_id = 3;
   */
  agentId = '';

  constructor(data?: PartialMessage<ProgressUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ProgressUpdate';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'step',
      kind: 'enum',
      T: proto3.getEnumType(ProgressUpdateStep),
    },
    {
      no: 2,
      name: 'context',
      kind: 'message',
      T: DocumentInfo,
      repeated: true,
    },
    { no: 3, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ProgressUpdate {
    return new ProgressUpdate().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ProgressUpdate {
    return new ProgressUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ProgressUpdate {
    return new ProgressUpdate().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProgressUpdate | PlainMessage<ProgressUpdate> | undefined,
    b: ProgressUpdate | PlainMessage<ProgressUpdate> | undefined,
  ): boolean {
    return proto3.util.equals(ProgressUpdate, a, b);
  }
}

/**
 * @generated from message context_engine.ConversationsSystemMessage
 */
export class ConversationsSystemMessage extends Message<ConversationsSystemMessage> {
  /**
   * @generated from field: string text = 1;
   */
  text = '';

  constructor(data?: PartialMessage<ConversationsSystemMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConversationsSystemMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConversationsSystemMessage {
    return new ConversationsSystemMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConversationsSystemMessage {
    return new ConversationsSystemMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConversationsSystemMessage {
    return new ConversationsSystemMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ConversationsSystemMessage
      | PlainMessage<ConversationsSystemMessage>
      | undefined,
    b:
      | ConversationsSystemMessage
      | PlainMessage<ConversationsSystemMessage>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConversationsSystemMessage, a, b);
  }
}

/**
 * @generated from message context_engine.ConversationsUserMessage
 */
export class ConversationsUserMessage extends Message<ConversationsUserMessage> {
  /**
   * @generated from field: string text = 1;
   */
  text = '';

  /**
   * @generated from field: string rephrased = 2;
   */
  rephrased = '';

  /**
   * @generated from field: repeated context_engine.DocumentInfo context = 3;
   */
  context: DocumentInfo[] = [];

  constructor(data?: PartialMessage<ConversationsUserMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConversationsUserMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'rephrased', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'context',
      kind: 'message',
      T: DocumentInfo,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConversationsUserMessage {
    return new ConversationsUserMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConversationsUserMessage {
    return new ConversationsUserMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConversationsUserMessage {
    return new ConversationsUserMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ConversationsUserMessage
      | PlainMessage<ConversationsUserMessage>
      | undefined,
    b:
      | ConversationsUserMessage
      | PlainMessage<ConversationsUserMessage>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConversationsUserMessage, a, b);
  }
}

/**
 * @generated from message context_engine.ConversationsAssistantMessage
 */
export class ConversationsAssistantMessage extends Message<ConversationsAssistantMessage> {
  /**
   * @generated from field: string text = 1;
   */
  text = '';

  /**
   * @generated from field: repeated context_engine.DocumentInfo cited = 2;
   */
  cited: DocumentInfo[] = [];

  /**
   * @generated from field: bool is_cached = 3;
   */
  isCached = false;

  /**
   * @generated from field: repeated context_engine.DocumentInfo context = 4;
   */
  context: DocumentInfo[] = [];

  /**
   * @generated from field: string agent_id = 5;
   */
  agentId = '';

  /**
   * @generated from field: context_engine.ResponseFormat format = 6;
   */
  format = ResponseFormat.MARKDOWN;

  constructor(data?: PartialMessage<ConversationsAssistantMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConversationsAssistantMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'cited', kind: 'message', T: DocumentInfo, repeated: true },
    { no: 3, name: 'is_cached', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    {
      no: 4,
      name: 'context',
      kind: 'message',
      T: DocumentInfo,
      repeated: true,
    },
    { no: 5, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 6,
      name: 'format',
      kind: 'enum',
      T: proto3.getEnumType(ResponseFormat),
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConversationsAssistantMessage {
    return new ConversationsAssistantMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConversationsAssistantMessage {
    return new ConversationsAssistantMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConversationsAssistantMessage {
    return new ConversationsAssistantMessage().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ConversationsAssistantMessage
      | PlainMessage<ConversationsAssistantMessage>
      | undefined,
    b:
      | ConversationsAssistantMessage
      | PlainMessage<ConversationsAssistantMessage>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConversationsAssistantMessage, a, b);
  }
}

/**
 * @generated from message context_engine.ConversationsChatMessage
 */
export class ConversationsChatMessage extends Message<ConversationsChatMessage> {
  /**
   * @generated from field: string id = 1;
   */
  id = '';

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * @generated from oneof context_engine.ConversationsChatMessage.message_type
   */
  messageType:
    | {
        /**
         * @generated from field: context_engine.ConversationsSystemMessage system_message = 3;
         */
        value: ConversationsSystemMessage;
        case: 'systemMessage';
      }
    | {
        /**
         * @generated from field: context_engine.ConversationsUserMessage user_message = 4;
         */
        value: ConversationsUserMessage;
        case: 'userMessage';
      }
    | {
        /**
         * @generated from field: context_engine.ConversationsAssistantMessage assistant_message = 5;
         */
        value: ConversationsAssistantMessage;
        case: 'assistantMessage';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ConversationsChatMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConversationsChatMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'timestamp', kind: 'message', T: Timestamp },
    {
      no: 3,
      name: 'system_message',
      kind: 'message',
      T: ConversationsSystemMessage,
      oneof: 'message_type',
    },
    {
      no: 4,
      name: 'user_message',
      kind: 'message',
      T: ConversationsUserMessage,
      oneof: 'message_type',
    },
    {
      no: 5,
      name: 'assistant_message',
      kind: 'message',
      T: ConversationsAssistantMessage,
      oneof: 'message_type',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConversationsChatMessage {
    return new ConversationsChatMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConversationsChatMessage {
    return new ConversationsChatMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConversationsChatMessage {
    return new ConversationsChatMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ConversationsChatMessage
      | PlainMessage<ConversationsChatMessage>
      | undefined,
    b:
      | ConversationsChatMessage
      | PlainMessage<ConversationsChatMessage>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConversationsChatMessage, a, b);
  }
}

/**
 * @generated from message context_engine.SuggestedQuery
 */
export class SuggestedQuery extends Message<SuggestedQuery> {
  /**
   * @generated from field: string text = 1;
   */
  text = '';

  /**
   * @generated from field: repeated string doc_ids = 2;
   */
  docIds: string[] = [];

  constructor(data?: PartialMessage<SuggestedQuery>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.SuggestedQuery';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'doc_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): SuggestedQuery {
    return new SuggestedQuery().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): SuggestedQuery {
    return new SuggestedQuery().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): SuggestedQuery {
    return new SuggestedQuery().fromJsonString(jsonString, options);
  }

  static equals(
    a: SuggestedQuery | PlainMessage<SuggestedQuery> | undefined,
    b: SuggestedQuery | PlainMessage<SuggestedQuery> | undefined,
  ): boolean {
    return proto3.util.equals(SuggestedQuery, a, b);
  }
}

/**
 * @generated from message context_engine.QuerySuggestionItem
 */
export class QuerySuggestionItem extends Message<QuerySuggestionItem> {
  /**
   * @generated from field: string text = 1;
   */
  text = '';

  /**
   * @generated from field: repeated string doc_ids = 2;
   */
  docIds: string[] = [];

  /**
   * @generated from field: context_engine.ConversationsAgent agent = 3;
   */
  agent = ConversationsAgent.UNKNOWN_AGENT;

  /**
   * @generated from field: bool is_static_suggestion = 4;
   */
  isStaticSuggestion = false;

  constructor(data?: PartialMessage<QuerySuggestionItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.QuerySuggestionItem';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'doc_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 3,
      name: 'agent',
      kind: 'enum',
      T: proto3.getEnumType(ConversationsAgent),
    },
    {
      no: 4,
      name: 'is_static_suggestion',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): QuerySuggestionItem {
    return new QuerySuggestionItem().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionItem {
    return new QuerySuggestionItem().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionItem {
    return new QuerySuggestionItem().fromJsonString(jsonString, options);
  }

  static equals(
    a: QuerySuggestionItem | PlainMessage<QuerySuggestionItem> | undefined,
    b: QuerySuggestionItem | PlainMessage<QuerySuggestionItem> | undefined,
  ): boolean {
    return proto3.util.equals(QuerySuggestionItem, a, b);
  }
}
