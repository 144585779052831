import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type {
  OnboardingSurveyAnswer,
  OnboardingSurveyQuestion,
} from './service/types';
import type {
  OnboardingSurveyId,
  Service,
} from '@mirage/service-onboarding-surveys/service';

export const service = services.get<Service>(ServiceId.ONBOARDING_SURVEYS);

export function submitOnboardingSurvey(
  surveyId: OnboardingSurveyId,
  surveyAnswers: OnboardingSurveyAnswer[],
  isTeamSurvey: boolean,
): Promise<boolean> {
  return rx.firstValueFrom(
    service.submitOnboardingSurvey(surveyId, surveyAnswers, isTeamSurvey),
  );
}

export function getOnboardingSurveyResponse(
  surveyId: OnboardingSurveyId,
  surveyQuestions: string[],
  isTeamSurvey: boolean,
): Promise<OnboardingSurveyAnswer[] | undefined> {
  return rx.firstValueFrom(
    service.getOnboardingSurveyResponse(
      surveyId,
      surveyQuestions,
      isTeamSurvey,
    ),
  );
}

export function getOnboardingSurveyQuestions(
  surveyId: OnboardingSurveyId,
): Promise<OnboardingSurveyQuestion[] | undefined> {
  return rx.firstValueFrom(service.getOnboardingSurveyQuestions(surveyId));
}
