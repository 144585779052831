import { UIIcon } from '@dropbox/dig-icons';
import { VerifiedLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Delete_DashCuration } from '@mirage/analytics/events/types/delete_dash_curation';
import { PAP_Dismiss_DashRemoveCuration } from '@mirage/analytics/events/types/dismiss_dash_remove_curation';
import { PAP_Initiate_DashRemoveCuration } from '@mirage/analytics/events/types/initiate_dash_remove_curation';
import { deleteCuration } from '@mirage/service-curations';
import { VERIFIED_CURATION_TAG } from '@mirage/shared/curations';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useState } from 'react';

import type { dash } from '@dropbox/api-v2-client';
import type { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';
import type { SearchResult } from '@mirage/service-dbx-api';

export const useDeleteVerification = (
  onSave: (uid: string, curation: dash.Curation | null) => void,
  dashActionSurface?: DashActionSurface,
) => {
  const { reportPapEvent, searchSessionManager } = useMirageAnalyticsContext();

  const [isDeleting, setIsDeleting] = useState(false);
  const [hasDeleteFailed, setHasDeleteFailed] = useState(false);
  const [verifiedResultToDelete, setVerifiedResultToDelete] =
    useState<SearchResult | null>(null);

  const confirmDeletion = async (pinnedQueries?: string[]) => {
    if (!verifiedResultToDelete) return;
    const { uuid, title, connectorInfo } = verifiedResultToDelete;

    setIsDeleting(true);
    const response = await deleteCuration(uuid, VERIFIED_CURATION_TAG);
    setIsDeleting(false);
    setHasDeleteFailed(false);
    if (response.success) {
      onSave?.(uuid, null);
      showSnackbar({
        title: i18n.t('verify_content_remove_success'),
        accessory: <UIIcon src={VerifiedLine} />,
      });
      reportPapEvent(
        PAP_Delete_DashCuration({
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashCurationType: 'verification',
          resultUuid: uuid,
          connector: connectorInfo?.connectorName,
          title: title,
          curatedExactQueries: pinnedQueries?.join?.(','),
          dashActionSurface,
        }),
      );
      setVerifiedResultToDelete(null);
    } else {
      setHasDeleteFailed(true);
    }
  };

  const initiateDeletion = (result: SearchResult) => {
    setVerifiedResultToDelete(result);
    reportPapEvent(
      PAP_Initiate_DashRemoveCuration({
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashCurationType: 'verification',
        resultUuid: result.uuid,
        connector: result.connectorInfo?.connectorName,
        dashActionSurface,
      }),
    );
  };

  const cancelDeletion = () => {
    reportPapEvent(
      PAP_Dismiss_DashRemoveCuration({
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashCurationType: 'verification',
        resultUuid: verifiedResultToDelete?.uuid,
        connector: verifiedResultToDelete?.connectorInfo?.connectorName,
        dashActionSurface,
      }),
    );

    setVerifiedResultToDelete(null);
  };

  return {
    hasDeleteFailed,
    isDeleting,
    isDeleteConfirmOpen: !!verifiedResultToDelete,
    confirmDeletion,
    initiateDeletion,
    cancelDeletion,
    resultTitle: verifiedResultToDelete?.title,
  };
};
