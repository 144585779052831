import { getAuthenticationData } from "@mirage/service-auth";
import type { AuthenticationData } from "@mirage/service-auth/types";
import { tagged } from "@mirage/service-logging";
import Sentry from "@mirage/shared/sentry";
import { DASH_WEBAPP, DASH_WEBAPP_STAGE } from "@mirage/shared/urls";
import {
  isCanaryBuildChannel,
  isDevBuildChannel,
  isIframe,
  isProdBuildChannel,
} from "@mirage/shared/util/tiny-utils";
import Cookies from "js-cookie";

import { Config } from "./config";

const AUTH_COOKIE_NAME = "dbx_auth_cookie";
const ENV_COOKIE_NAME = "dash_web_env";
const WEB_APP_DOMAIN = ".dash.ai";

const logger = tagged("WebCookies");

export const enum EnvCookieValue {
  STAGING = "staging",
  PROD = "prod",
}

export function getWebAuthCookie(): AuthenticationData | undefined {
  const cookie = Cookies.get(AUTH_COOKIE_NAME);
  if (cookie) {
    return JSON.parse(cookie) as AuthenticationData;
  }

  return undefined;
}

export function setWebAuthCookie(value: AuthenticationData) {
  Cookies.set(AUTH_COOKIE_NAME, JSON.stringify(value), {
    expires: 3, // 3 days
    domain: WEB_APP_DOMAIN,
  });
}

export function removeWebAuthCookie(): void {
  Cookies.remove(AUTH_COOKIE_NAME, { domain: WEB_APP_DOMAIN });
}

export function getWebEnvCookie(): EnvCookieValue | undefined {
  return Cookies.get(ENV_COOKIE_NAME) as EnvCookieValue | undefined;
}

export function setWebEnvCookie(value: EnvCookieValue) {
  Cookies.set(ENV_COOKIE_NAME, value, {
    // This is a tradeoff between how soon the feature flag configs will take
    // effect vs how fast the redirect is for users. If we only check the
    // feature flag once every 3 days, that means it will take about 4 days to
    // turn off redirects using the feature flag without a code change.
    expires: 3, // 3 days
    domain: WEB_APP_DOMAIN,
  });
}

export function removeWebEnvCookie(): void {
  Cookies.remove(ENV_COOKIE_NAME, { domain: WEB_APP_DOMAIN });
}

export function checkAuthCookieAndRedirect() {
  const isProd = isProdBuildChannel(Config.BUILD_CHANNEL);
  const isDev = isDevBuildChannel(Config.BUILD_CHANNEL);
  const isCanary = isCanaryBuildChannel(Config.BUILD_CHANNEL);

  // Do nothing if it's dev or canary or in an iframe
  if (isDev || isCanary || isIframe()) {
    return;
  }

  const envCookie = getWebEnvCookie();
  if (!envCookie) {
    return;
  }

  const shouldAccessProd = envCookie === EnvCookieValue.PROD;

  if (!shouldAccessProd) {
    // Redirect to staging if they are on dash.ai
    if (isProd) {
      logger.debug("WebCookies: Redirect from prod to staging.");
      setAuthCookieAndRedirect(DASH_WEBAPP_STAGE);
    }
  } else {
    // Cookie env is prod
    if (!isProd) {
      logger.debug("WebCookies: Redirect from staging to prod.");
      // Redirect to dash.ai if they are on staging but the cookie is set to be prod
      setAuthCookieAndRedirect(DASH_WEBAPP);
    }
  }
}

async function setAuthCookieAndRedirect(baseUrl: string) {
  try {
    const authData = await getAuthenticationData();
    if (!authData) {
      Sentry.captureMessage(
        "No auth data found when trying to set auth cookie and redirect.",
      );
      return;
    }
    setWebAuthCookie(authData);
    location.href =
      baseUrl + location.pathname + location.hash + location.search;
  } catch (e) {
    Sentry.captureException(e);
  }
}
