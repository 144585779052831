// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/dev_settings.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message context_engine.DevConfigOverride
 */
export class DevConfigOverride extends Message<DevConfigOverride> {
  /**
   * @generated from field: string type = 1;
   */
  type = '';

  /**
   * @generated from field: string key = 2;
   */
  key = '';

  /**
   * @generated from field: string value = 3;
   */
  value = '';

  constructor(data?: PartialMessage<DevConfigOverride>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DevConfigOverride';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'key', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DevConfigOverride {
    return new DevConfigOverride().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DevConfigOverride {
    return new DevConfigOverride().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DevConfigOverride {
    return new DevConfigOverride().fromJsonString(jsonString, options);
  }

  static equals(
    a: DevConfigOverride | PlainMessage<DevConfigOverride> | undefined,
    b: DevConfigOverride | PlainMessage<DevConfigOverride> | undefined,
  ): boolean {
    return proto3.util.equals(DevConfigOverride, a, b);
  }
}

/**
 * @generated from message context_engine.DevRequestOverrides
 */
export class DevRequestOverrides extends Message<DevRequestOverrides> {
  /**
   * @generated from field: repeated context_engine.DevConfigOverride devbox_overrides = 1;
   */
  devboxOverrides: DevConfigOverride[] = [];

  constructor(data?: PartialMessage<DevRequestOverrides>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DevRequestOverrides';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'devbox_overrides',
      kind: 'message',
      T: DevConfigOverride,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DevRequestOverrides {
    return new DevRequestOverrides().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DevRequestOverrides {
    return new DevRequestOverrides().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DevRequestOverrides {
    return new DevRequestOverrides().fromJsonString(jsonString, options);
  }

  static equals(
    a: DevRequestOverrides | PlainMessage<DevRequestOverrides> | undefined,
    b: DevRequestOverrides | PlainMessage<DevRequestOverrides> | undefined,
  ): boolean {
    return proto3.util.equals(DevRequestOverrides, a, b);
  }
}
