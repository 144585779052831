import { Button } from '@dropbox/dig-components/buttons';
import { ButtonGroup } from '@dropbox/dig-components/combinations';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Cluster, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CalendarLine,
  SlackExternalLogo,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { Link } from '@mirage/mosaics/BaseProfilePage/Link';
import { getTeamProfilePageHref } from '@mirage/mosaics/TeamProfilePage/utils';
import i18n from '@mirage/translations';
import { Fragment } from 'react';
import { UserProfileAvatar } from './UserProfileAvatar';
import { UserProfileDetails } from './UserProfileDetails';

import type { UserInfo } from '@mirage/mosaics/UserProfilePage/types';

interface ProfileBodyProps {
  user: UserInfo;
}

const BACKGROUND_IMAGE_URL =
  'https://assets.dropbox.com/images/dashweb/dash_user_profile_bg.png';

export const UserProfileBody = ({ user }: ProfileBodyProps) => {
  return (
    <Stack width="100%">
      <UserProfileBackgroundImage />
      <UserProfileBasicInfoSection user={user} />
    </Stack>
  );
};

const UserProfileBackgroundImage = () => {
  return <img src={BACKGROUND_IMAGE_URL} alt="background" />;
};

const UserProfileBasicInfoSection = ({ user }: { user: UserInfo }) => {
  return (
    <Stack
      gap="12"
      style={{ marginTop: '-88px' }}
      paddingLeft="32"
      paddingRight="32"
      paddingBottom="8"
    >
      <UserProfileAvatar
        user={user}
        isInactive={Boolean(user.is_deactivated)}
        onLeave={user.on_leave}
        size="profile"
        key={user.ldap}
      />
      <Cluster alignX="between" alignY="top">
        <Cluster.Item>
          <Stack gap="8" paddingBottom="Micro XLarge" width="100%">
            <Stack paddingBottom={'4'} style={{ maxWidth: '540px' }}>
              <Box
                as={Title}
                size="large"
                weightVariant="emphasized"
                tagName="span"
              >
                {user.name}
              </Box>
              <Box>
                <Text color="faint" variant="paragraph">
                  {user.role}
                  {user.type == 'Regular' && user.level && ` (${user.level})`}
                </Text>
                {user.type !== 'Regular' && (
                  <Text color="faint">({user.type})</Text>
                )}
                {user.teams.length > 0 && (
                  <Text
                    color="faint"
                    style={{ marginLeft: '4px', marginRight: '4px' }}
                  >
                    {i18n.t('user_profile_subtitle_static_on')}
                  </Text>
                )}
                {user.teams.length > 0 &&
                  user.teams.map(
                    (team, index) =>
                      team.slug && (
                        <Fragment key={team.slug}>
                          <Text color="faint" variant="paragraph">
                            {index > 0 && <strong>{', '}</strong>}
                            <Link
                              to={getTeamProfilePageHref(team.slug)}
                              monochromatic
                              hasNoUnderline
                              showUnderlineOnHover
                              isBold
                            >
                              {team.name}
                            </Link>
                          </Text>
                        </Fragment>
                      ),
                  )}
              </Box>
            </Stack>
            <UserProfileButtonGroup />
          </Stack>
        </Cluster.Item>
        <Cluster.Item>
          {!user.is_deactivated && <UserProfileDetails user={user} />}
        </Cluster.Item>
      </Cluster>
    </Stack>
  );
};

const UserProfileButtonGroup = () => {
  return (
    <ButtonGroup size="small">
      {({ getButtonProps }) => (
        <>
          <Button
            {...getButtonProps({
              variant: 'primary',
            })}
            withIconStart={<UIIcon src={SlackExternalLogo} />}
          >
            {i18n.t('user_profile_button_message_string')}
          </Button>
          <Button
            {...getButtonProps({
              variant: 'opacity',
            })}
            withIconStart={<UIIcon src={CalendarLine} />}
          >
            {i18n.t('user_profile_button_schedule_string')}
          </Button>
        </>
      )}
    </ButtonGroup>
  );
};
