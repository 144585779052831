import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import { managedShortcuts } from '@mirage/service-typeahead-search/service/utils/managed-shortcuts';
import {
  extractParameterMatches,
  getUrl,
  isHotwordActive,
  requiredParameterCount,
  tokenizeTitle,
  toQueryParts,
} from '@mirage/service-typeahead-search/service/utils/url-shortcut-utils';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import * as rx from 'rxjs';

import type { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type {
  CachedURLShortcut,
  URLShortcut,
} from '@mirage/shared/search/url-shortcut';
import type { Observable } from 'rxjs';

export const search = wrappers.wrapped(SourceId.URLShortcuts, raw);

export function raw(
  query: string,
  config: typeahead.Config,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    const connectorTypes = config.connections.map(
      (c) => c.connector?.id_attrs?.type,
    );
    const connectorTypeSet = new Set(connectorTypes);

    const queryParts = toQueryParts(query);
    const [hotword, ...queryPartsExcludingHotword] = queryParts;

    // skip url shortcuts search on empty query
    if (query.length === 0) return rx.from([]);

    const wrapped = orderByRecencyCache(
      managedShortcuts,
      cache.getURLShortcuts(),
    )
      // Filter out shortcuts that don't belong to any of the active connectors
      .filter((shortcut) => {
        if (!shortcut.supportedConnectors) return true;

        return shortcut.supportedConnectors?.some((connector) =>
          connectorTypeSet.has(connector),
        );
      })
      // Filter URL shortcuts where query matches hotword
      .filter((shortcut) =>
        shortcut.hotwords.some((hotword) => isHotwordActive(query, hotword)),
      )
      .map((shortcut) => {
        const required = requiredParameterCount(shortcut.urlTemplate);
        const activeHotword =
          shortcut.hotwords.find((hotword) =>
            isHotwordActive(query, hotword),
          ) || ''; // will always match because of filter above
        const defaultQuery = shortcut.getDefaultQuery?.();
        // We need to remove getDefaultQuery since it is non serializable
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { getDefaultQuery, ...minShortcut } = shortcut;
        return {
          ...minShortcut,
          parameters: {
            activeHotword,
            count: required,
            url:
              getUrl(shortcut.urlTemplate, queryPartsExcludingHotword) ||
              shortcut.urlTemplate,
            chunks: queryPartsExcludingHotword.length,
            matches:
              hotword === activeHotword
                ? extractParameterMatches(shortcut.urlTemplate, query)
                : [],
            highlightWords: queryPartsExcludingHotword,
            complete: queryPartsExcludingHotword.length >= required,
            matched: hotword === activeHotword,
            title: tokenizeTitle(
              shortcut.titleTemplate,
              activeHotword,
              queryParts,
              defaultQuery,
            ),
          },
          timestamp: Date.now(),
        };
      });
    return rx.from(
      wrapped.map((result) => primitives.urlShortcut(result.uuid, result)),
    );
  });
}

/**
 * Orders managed shortcuts based on their recent usage in the cache.
 *
 * @param managedShortcuts - The static list of all available shortcuts
 * @param cachedShortcuts - Recently used shortcuts with timestamps
 * @returns A new array of managed shortcuts ordered by:
 *   1. Recently used shortcuts first (most recent to least recent)
 *   2. Unused shortcuts last (maintaining their original order)
 */
export function orderByRecencyCache(
  managedShortcuts: CachedURLShortcut[],
  cachedShortcuts: readonly URLShortcut[],
): CachedURLShortcut[] {
  const timestampMap = new Map(
    cachedShortcuts.map((shortcut) => [shortcut.uuid, shortcut.timestamp]),
  );

  return [...managedShortcuts].sort((a, b) => {
    const aTimestamp = timestampMap.get(a.uuid) || 0;
    const bTimestamp = timestampMap.get(b.uuid) || 0;

    if (aTimestamp && bTimestamp) {
      return bTimestamp - aTimestamp;
    }

    if (aTimestamp) return -1;
    if (bTimestamp) return 1;

    return 0;
  });
}
