import { atom, useAtomValue, useSetAtom } from 'jotai';
import { sortConnectorsByDisplayName } from '../connectors/util';

import type { FetchedAtom } from './types';
import type { dash_connectors } from '@dropbox/api-v2-client';
import type { UIConnector } from '@mirage/shared/types';

export const connectorsAtom = atom<FetchedAtom<UIConnector[]>>({
  data: [],
  loaded: false,
  loading: false,
});

export const useConnectors = () => useAtomValue(connectorsAtom);

export const useSetConnectors = () => useSetAtom(connectorsAtom);

export const connectorsSortedByDisplayNameAtom = atom((get) => {
  const { data: connectors } = get(connectorsAtom);

  if (!connectors) return [];

  return sortConnectorsByDisplayName(connectors);
});

export const connectorsByTypeAtom = atom((get) => {
  const { data: connectors } = get(connectorsAtom);

  return connectors.reduce((acc: Record<string, UIConnector>, connector) => {
    if (!connector.id_attrs) return acc;
    return {
      ...acc,
      [connector.id_attrs.type as string]: connector,
    };
  }, {});
});

export const janusConnectorsByTypeAtom = atom((get) => {
  const { data: connectors } = get(connectorsAtom);
  const janusConnectors = connectors.filter(
    (c) => c.id_attrs?.platform?.['.tag'] === 'janus',
  );

  return janusConnectors.reduce((acc: Record<string, UIConnector>, c) => {
    if (!c.id_attrs) return acc;
    return { ...acc, [c.id_attrs.type as string]: c };
  }, {});
});

export const useSetConnector = () => {
  const setConnectors = useSetConnectors();

  return (type: string, updatedConnector: UIConnector) => {
    setConnectors((atom) => {
      const updatedConnectors = atom.data.map((c) =>
        c.id_attrs?.type === type ? updatedConnector : c,
      );

      return {
        ...atom,
        data: updatedConnectors,
      };
    });
  };
};

export const visibleConnectorsAtom = atom<dash_connectors.Connector[]>([]);
export const visibleConnectorsLastFetchMsAtom = atom(0);
