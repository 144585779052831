import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { TextInput, TextInputAffix } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { PhotoUploadLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import { useDoOnceOnMount } from '@mirage/shared/hooks/useDoOnceOnMount';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useOnboardingResponses } from '../hooks/OnboardingResponsesProvider';
import { useDashTeamInfo } from '../hooks/useDashTeamInfo';
import styles from './OnboardingCompanyInfoStep.module.css';

type Props = {
  isAdmin: boolean;
  onValidationChange: (valid: boolean) => void;
};

const MAX_COMPANY_NAME_LENGTH = 20;
const MAX_FILE_SIZE = 5; // Max file size in MB

export const OnboardingCompanyInfoStep = forwardRef(
  ({ isAdmin, onValidationChange }: Props, ref) => {
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [teamLogo, setTeamLogo] = useState<File>();
    const [isDragging, setIsDragging] = useState(false);
    const { getTeamLogo } = useDashTeamInfo();
    const { reportPapEvent } = useMirageAnalyticsContext();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { companyLogo, companyName, setCompanyName, setCompanyLogo } =
      useOnboardingResponses();

    useDoOnceOnMount(() => {
      reportPapEvent(
        PAP_View_DashSelfServeOnboarding({
          selfServeIsTeamAdmin: isAdmin,
          onboardingStep: 'name_and_logo',
          featureLine: 'onboarding',
        }),
      );
    });

    useEffect(() => {
      const isValid =
        companyName.length > 0 && companyName.length <= MAX_COMPANY_NAME_LENGTH;
      onValidationChange(isValid);
    }, [companyName, onValidationChange]);

    useEffect(() => {
      if (!companyLogo) return;
      setImagePreview(companyLogo);
    }, [companyLogo]);

    const handleLogoClick = () => {
      fileInputRef.current?.click();
    };

    useImperativeHandle(ref, () => ({
      submitSurvey: () => handleSaveTeamInfo(),
    }));

    const handleSaveTeamInfo = useCallback(async () => {
      setCompanyName(companyName);
      if (teamLogo) {
        const teamLogoSrc = await getTeamLogo(teamLogo);
        if (!teamLogoSrc) return;
        setCompanyLogo(teamLogoSrc);
      }
    }, [setCompanyName, companyName, teamLogo, getTeamLogo, setCompanyLogo]);

    const handleFileChange = (file: File | null) => {
      if (!file) return;

      // Validate file size and type
      if (!file.type.startsWith('image/')) {
        return;
      }

      if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
        return;
      }

      // Generate a preview URL for the image
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setTeamLogo(file);

      // Clean up the object URL when the component is unmounted
      return () => URL.revokeObjectURL(imageUrl);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = () => {
      setIsDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);

      const file = event.dataTransfer.files[0];
      handleFileChange(file);
    };

    return (
      <Box display="flex" flexDirection="column">
        <Box
          className={classnames(
            styles.logoDragDrop,
            isDragging ? styles.logoDragDropActive : '',
          )}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          role="button"
          onClick={handleLogoClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          {imagePreview ? (
            <img
              className={styles.companyLogo}
              src={imagePreview}
              alt={i18n.t('self_serve_image_alt_text')}
              onError={() => setImagePreview(null)}
            />
          ) : (
            <Box
              className={styles.placeholderImage}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              data-testid="placeholder-image"
            >
              <UIIcon width={42} src={PhotoUploadLine} />
            </Box>
          )}

          <Text
            variant="paragraph"
            size="large"
            className={styles.logoDragDropLabel}
          >
            {imagePreview ? (
              <span>&nbsp;</span>
            ) : (
              i18n.t('self_serve_add_logo_or_image')
            )}
          </Text>
        </Box>

        <input
          ref={fileInputRef}
          className={styles.fileInput}
          accept="image/*"
          data-testid="file-input"
          onChange={(e) => handleFileChange(e.target.files?.[0] ?? null)}
          type="file"
        />

        <FormRow>
          <FormLabel htmlFor="companyNameInput">
            {i18n.t('self_serve_organization')}
          </FormLabel>
          <TextInput
            id="companyNameInput"
            size="large"
            value={companyName}
            placeholder={i18n.t('self_serve_enter_organization_name')}
            onChange={(e) => setCompanyName(e.target.value)}
            maxLength={MAX_COMPANY_NAME_LENGTH}
            withRightAccessory={
              <TextInputAffix>
                {Math.max(
                  0,
                  MAX_COMPANY_NAME_LENGTH - (companyName.length ?? 0),
                )}
              </TextInputAffix>
            }
          ></TextInput>
        </FormRow>
      </Box>
    );
  },
);

OnboardingCompanyInfoStep.displayName = 'OnboardingCompanyInfoStep';
