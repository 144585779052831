import { atom, useAtomValue, useSetAtom } from 'jotai';

import type { UIConnection } from '@mirage/shared/types';

/**
 * note: Ensures a connector is shown as "sync in progress" immediately after the connection is added,
 *       before reloading the list of connections
 */

const RecentConnectionIds: string[] = [];

export const recentConnectionIds = atom(RecentConnectionIds);

export const useSetRecentConnectionIds = () => useSetAtom(recentConnectionIds);
export const useConnectionRecentlyEstablished = () => {
  const connectionIds = useAtomValue(recentConnectionIds);

  return (connection: UIConnection) => {
    return connectionIds.some((id: string) => {
      return id === connection.id_attributes?.id;
    });
  };
};
