import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { StackSortOption } from '@mirage/service-settings/service/types';
import { useStackByIdSortSettings } from '@mirage/service-settings/useStackByIdSortSettings';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import {
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackItemShortcutsAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import {
  FullScreenStackPreset,
  SortIconMenu,
} from '@mirage/stacks/SortIconMenu';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { AddItemButtonAugRev } from '../Sections/AddItemButton';
import { useFetchContentSuggestions } from '../Sections/hooks';
import { SearchInStackMenu } from '../Sections/SearchInStack';

import type { SelectableStackSortPreference } from '@mirage/service-settings/service/types';

export const HeaderWithChatActions = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const stack = useAtomValue(activeStackAtom);
  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);
  const { defaultSection } = useAtomValue(activeStackSectionsAtom);

  const { sortPreference, setStackByIdSortPreference } =
    useStackByIdSortSettings(stack?.namespace_id ?? '');

  const { contentSuggestions, isFetchingContentSuggestions } =
    useFetchContentSuggestions();

  return (
    <>
      {hasWritePermissions && defaultSection && (
        <AddItemButtonAugRev
          align="right"
          variant="primary"
          contentSuggestions={contentSuggestions}
          loadingSuggestions={isFetchingContentSuggestions}
          section={defaultSection}
          // Emphasize add button when there are no stack items yet.
          emphasize={!items || items.length === 0}
        >
          {i18n.t('add')}
        </AddItemButtonAugRev>
      )}
      <SortIconMenu
        augustRevision={false}
        sortPreference={sortPreference as SelectableStackSortPreference}
        onSortSelect={setStackByIdSortPreference}
        optionsPreset={FullScreenStackPreset}
        defaultOption={{
          option: StackSortOption.SECTION,
        }}
      />
      <Box display="flex" flexGrow={1}>
        <SearchInStackMenu
          // Force re-render when stack changes
          key={stack?.namespace_id}
          items={items}
          onOpenLink={() => {
            if (stack) {
              reportPapEvent(
                PAP_Open_DashLink({
                  ...stackDerivePAPProps(stack),
                  featureLine: 'stacks',
                }),
              );
            }
          }}
        />
      </Box>
    </>
  );
};
