import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { useChartKeyboardShortcuts } from '@mirage/mosaics/BaseProfilePage/OrgChart/hooks';
import { OrgLine } from '@mirage/mosaics/BaseProfilePage/OrgChart/OrgLine';
import { Title } from '@mirage/mosaics/BaseProfilePage/Title';
import i18n from '@mirage/translations';
import cx from 'classnames';
import * as d3 from 'd3';
import { useCallback, useRef } from 'react';
import styles from './Chart.module.css';

import type {
  FocusData,
  HierarchyData,
} from '@mirage/mosaics/BaseProfilePage/OrgChart/helpers';

export type OrgChartProps = {
  overrideTree?: HierarchyData;
  focus: FocusData;
  toggle: { employee?: FocusData; team?: FocusData };
  setFocus: (focus: FocusData) => void;
  expanded: boolean;
  setExpanded?: (expanded: boolean) => void;
};

const maxZoom = 1.4;
const minZoom = 0.7;

export const OrgChart = ({ expanded }: OrgChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const zoomRef = useRef<d3.ZoomBehavior<SVGSVGElement, unknown> | null>(null);
  const isLightMode = true; // TODO: Fix color mode

  // Function to adjust zoom scale
  const adjustZoom = (zoomFactor: number) => {
    if (!zoomRef.current || !svgRef.current) {
      return;
    }

    const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const point = [
      svgRef.current.clientWidth / 2,
      svgRef.current.clientHeight / 2,
    ];

    const currentTransform = d3.zoomTransform(svgZoom.node() as SVGSVGElement);
    const newScale = Math.max(
      minZoom,
      Math.min(maxZoom, currentTransform.k * zoomFactor),
    );

    // Calculate the new x and y to keep the point centered
    const zoomTransform = d3.zoomIdentity
      .translate(
        point[0] -
          (point[0] - currentTransform.x) * (newScale / currentTransform.k),
        point[1] -
          (point[1] - currentTransform.y) * (newScale / currentTransform.k),
      )
      .scale(newScale);

    svgZoom.call(zoomRef.current.transform, zoomTransform);
  };

  // Reset zoom function
  const resetZoom = useCallback(() => {
    if (!zoomRef.current || !svgRef.current) return;

    const svgZoom = d3.select<SVGSVGElement, unknown>(svgRef.current);

    const initialTransform = d3.zoomIdentity;
    svgZoom.call(zoomRef.current.transform, initialTransform);
  }, [expanded]);

  useChartKeyboardShortcuts({ expanded, resetZoom, adjustZoom });

  return (
    <Box
      ref={wrapperRef}
      borderRadius="Large"
      borderStyle="Solid"
      borderColor="Border Subtle"
      borderWidth="1"
      className={cx(styles.container, {
        [styles.adjustable]: expanded,
      })}
    >
      {expanded && (
        <Box
          pointerEvents="none"
          position="absolute"
          style={{
            height: 68,
            background: `linear-gradient(180deg, rgba(${isLightMode ? '247,245,242' : '36,35,33'}, .9) 68%, transparent 100%`,
          }}
          width="100%"
        />
      )}
      <Box
        position="absolute"
        paddingX={expanded ? '24' : '20'}
        paddingY={expanded ? '24' : '16'}
      >
        <Title
          weightVariant="emphasized"
          size="small"
          withAccessoryStart={<UIIcon src={OrgLine} />}
        >
          {i18n.t('organization_title')}
        </Title>
      </Box>
    </Box>
  );
};
