// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/agents.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, protoInt64 } from '@bufbuild/protobuf';
import { ContextEngineModel } from './models_pb';

/**
 * @generated from message context_engine.ExecutionStep
 */
export class ExecutionStep extends Message<ExecutionStep> {
  /**
   * @generated from field: context_engine.ExecutionStepStatement statement = 1;
   */
  statement?: ExecutionStepStatement;

  /**
   * @generated from field: context_engine.ExecutionStepOutput output = 2;
   */
  output?: ExecutionStepOutput;

  constructor(data?: PartialMessage<ExecutionStep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionStep';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'statement', kind: 'message', T: ExecutionStepStatement },
    { no: 2, name: 'output', kind: 'message', T: ExecutionStepOutput },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionStep {
    return new ExecutionStep().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStep {
    return new ExecutionStep().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStep {
    return new ExecutionStep().fromJsonString(jsonString, options);
  }

  static equals(
    a: ExecutionStep | PlainMessage<ExecutionStep> | undefined,
    b: ExecutionStep | PlainMessage<ExecutionStep> | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionStep, a, b);
  }
}

/**
 * @generated from message context_engine.ExecutionStepStatement
 */
export class ExecutionStepStatement extends Message<ExecutionStepStatement> {
  /**
   * @generated from field: string raw_statement = 1;
   */
  rawStatement = '';

  constructor(data?: PartialMessage<ExecutionStepStatement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionStepStatement';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'raw_statement',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionStepStatement {
    return new ExecutionStepStatement().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStepStatement {
    return new ExecutionStepStatement().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStepStatement {
    return new ExecutionStepStatement().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ExecutionStepStatement
      | PlainMessage<ExecutionStepStatement>
      | undefined,
    b:
      | ExecutionStepStatement
      | PlainMessage<ExecutionStepStatement>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionStepStatement, a, b);
  }
}

/**
 * @generated from message context_engine.ExecutionStepOutput
 */
export class ExecutionStepOutput extends Message<ExecutionStepOutput> {
  /**
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: context_engine.ExecutionStepOutputType type = 3;
   */
  type?: ExecutionStepOutputType;

  /**
   * @generated from field: context_engine.ContextEngineModel model = 4;
   */
  model?: ContextEngineModel;

  constructor(data?: PartialMessage<ExecutionStepOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionStepOutput';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'type', kind: 'message', T: ExecutionStepOutputType },
    { no: 4, name: 'model', kind: 'message', T: ContextEngineModel },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionStepOutput {
    return new ExecutionStepOutput().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStepOutput {
    return new ExecutionStepOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStepOutput {
    return new ExecutionStepOutput().fromJsonString(jsonString, options);
  }

  static equals(
    a: ExecutionStepOutput | PlainMessage<ExecutionStepOutput> | undefined,
    b: ExecutionStepOutput | PlainMessage<ExecutionStepOutput> | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionStepOutput, a, b);
  }
}

/**
 * @generated from message context_engine.ExecutionStepOutputType
 */
export class ExecutionStepOutputType extends Message<ExecutionStepOutputType> {
  /**
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: bool is_list = 2;
   */
  isList = false;

  constructor(data?: PartialMessage<ExecutionStepOutputType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionStepOutputType';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'is_list', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionStepOutputType {
    return new ExecutionStepOutputType().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStepOutputType {
    return new ExecutionStepOutputType().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionStepOutputType {
    return new ExecutionStepOutputType().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ExecutionStepOutputType
      | PlainMessage<ExecutionStepOutputType>
      | undefined,
    b:
      | ExecutionStepOutputType
      | PlainMessage<ExecutionStepOutputType>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionStepOutputType, a, b);
  }
}

/**
 * @generated from message context_engine.ExecutionEngineException
 */
export class ExecutionEngineException extends Message<ExecutionEngineException> {
  /**
   * @generated from field: string message = 1;
   */
  message = '';

  /**
   * @generated from field: string name = 2;
   */
  name = '';

  /**
   * Code that was being executed when the exception occurred.
   * Only provided if debug=True
   *
   * @generated from field: string gend_code = 3;
   */
  gendCode = '';

  constructor(data?: PartialMessage<ExecutionEngineException>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionEngineException';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'gend_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionEngineException {
    return new ExecutionEngineException().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionEngineException {
    return new ExecutionEngineException().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionEngineException {
    return new ExecutionEngineException().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ExecutionEngineException
      | PlainMessage<ExecutionEngineException>
      | undefined,
    b:
      | ExecutionEngineException
      | PlainMessage<ExecutionEngineException>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionEngineException, a, b);
  }
}

/**
 * @generated from message context_engine.ExecutionLineTimingInfo
 */
export class ExecutionLineTimingInfo extends Message<ExecutionLineTimingInfo> {
  /**
   * @generated from field: int64 line_no = 1;
   */
  lineNo = protoInt64.zero;

  /**
   * @generated from field: int64 duration_ns = 2;
   */
  durationNs = protoInt64.zero;

  /**
   * @generated from field: int64 num_ticks = 3;
   */
  numTicks = protoInt64.zero;

  constructor(data?: PartialMessage<ExecutionLineTimingInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionLineTimingInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'line_no', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: 'duration_ns', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: 'num_ticks', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionLineTimingInfo {
    return new ExecutionLineTimingInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionLineTimingInfo {
    return new ExecutionLineTimingInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionLineTimingInfo {
    return new ExecutionLineTimingInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ExecutionLineTimingInfo
      | PlainMessage<ExecutionLineTimingInfo>
      | undefined,
    b:
      | ExecutionLineTimingInfo
      | PlainMessage<ExecutionLineTimingInfo>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionLineTimingInfo, a, b);
  }
}

/**
 * @generated from message context_engine.ExecutionFunctionCallTimingInfo
 */
export class ExecutionFunctionCallTimingInfo extends Message<ExecutionFunctionCallTimingInfo> {
  /**
   * @generated from field: string function_name = 1;
   */
  functionName = '';

  /**
   * @generated from field: int64 duration_ns = 2;
   */
  durationNs = protoInt64.zero;

  /**
   * @generated from field: int64 num_ticks = 3;
   */
  numTicks = protoInt64.zero;

  /**
   * @generated from field: int64 start_line_no = 4;
   */
  startLineNo = protoInt64.zero;

  /**
   * @generated from field: int64 end_line_no = 5;
   */
  endLineNo = protoInt64.zero;

  /**
   * @generated from field: int64 start_col_no = 6;
   */
  startColNo = protoInt64.zero;

  /**
   * @generated from field: int64 end_col_no = 7;
   */
  endColNo = protoInt64.zero;

  constructor(data?: PartialMessage<ExecutionFunctionCallTimingInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionFunctionCallTimingInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'function_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'duration_ns', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: 'num_ticks', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    {
      no: 4,
      name: 'start_line_no',
      kind: 'scalar',
      T: 3 /* ScalarType.INT64 */,
    },
    { no: 5, name: 'end_line_no', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    {
      no: 6,
      name: 'start_col_no',
      kind: 'scalar',
      T: 3 /* ScalarType.INT64 */,
    },
    { no: 7, name: 'end_col_no', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionFunctionCallTimingInfo {
    return new ExecutionFunctionCallTimingInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionFunctionCallTimingInfo {
    return new ExecutionFunctionCallTimingInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionFunctionCallTimingInfo {
    return new ExecutionFunctionCallTimingInfo().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ExecutionFunctionCallTimingInfo
      | PlainMessage<ExecutionFunctionCallTimingInfo>
      | undefined,
    b:
      | ExecutionFunctionCallTimingInfo
      | PlainMessage<ExecutionFunctionCallTimingInfo>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionFunctionCallTimingInfo, a, b);
  }
}

/**
 * @generated from message context_engine.ExecutionTimingInfo
 */
export class ExecutionTimingInfo extends Message<ExecutionTimingInfo> {
  /**
   * aggregated timing info per line
   *
   * @generated from field: map<string, context_engine.ExecutionLineTimingInfo> lines = 1;
   */
  lines: { [key: string]: ExecutionLineTimingInfo } = {};

  /**
   * a list of timing info for function calls in the order they occurred
   *
   * @generated from field: repeated context_engine.ExecutionFunctionCallTimingInfo function_calls = 2;
   */
  functionCalls: ExecutionFunctionCallTimingInfo[] = [];

  constructor(data?: PartialMessage<ExecutionTimingInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecutionTimingInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'lines',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: ExecutionLineTimingInfo },
    },
    {
      no: 2,
      name: 'function_calls',
      kind: 'message',
      T: ExecutionFunctionCallTimingInfo,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecutionTimingInfo {
    return new ExecutionTimingInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecutionTimingInfo {
    return new ExecutionTimingInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecutionTimingInfo {
    return new ExecutionTimingInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: ExecutionTimingInfo | PlainMessage<ExecutionTimingInfo> | undefined,
    b: ExecutionTimingInfo | PlainMessage<ExecutionTimingInfo> | undefined,
  ): boolean {
    return proto3.util.equals(ExecutionTimingInfo, a, b);
  }
}

/**
 * @generated from message context_engine.ExecuteDSLInstructionsDebugInfo
 */
export class ExecuteDSLInstructionsDebugInfo extends Message<ExecuteDSLInstructionsDebugInfo> {
  /**
   * @generated from field: context_engine.DSLDocument dsl_document = 1;
   */
  dslDocument?: DSLDocument;

  /**
   * @generated from field: context_engine.ExecutionTimingInfo timing_info = 2;
   */
  timingInfo?: ExecutionTimingInfo;

  constructor(data?: PartialMessage<ExecuteDSLInstructionsDebugInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecuteDSLInstructionsDebugInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dsl_document', kind: 'message', T: DSLDocument },
    { no: 2, name: 'timing_info', kind: 'message', T: ExecutionTimingInfo },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecuteDSLInstructionsDebugInfo {
    return new ExecuteDSLInstructionsDebugInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsDebugInfo {
    return new ExecuteDSLInstructionsDebugInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsDebugInfo {
    return new ExecuteDSLInstructionsDebugInfo().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ExecuteDSLInstructionsDebugInfo
      | PlainMessage<ExecuteDSLInstructionsDebugInfo>
      | undefined,
    b:
      | ExecuteDSLInstructionsDebugInfo
      | PlainMessage<ExecuteDSLInstructionsDebugInfo>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecuteDSLInstructionsDebugInfo, a, b);
  }
}

/**
 * @generated from message context_engine.DSLType
 */
export class DSLType extends Message<DSLType> {
  /**
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: bool is_list = 2;
   */
  isList = false;

  constructor(data?: PartialMessage<DSLType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLType';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'is_list', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLType {
    return new DSLType().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLType {
    return new DSLType().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLType {
    return new DSLType().fromJsonString(jsonString, options);
  }

  static equals(
    a: DSLType | PlainMessage<DSLType> | undefined,
    b: DSLType | PlainMessage<DSLType> | undefined,
  ): boolean {
    return proto3.util.equals(DSLType, a, b);
  }
}

/**
 * @generated from message context_engine.DSLArgument
 */
export class DSLArgument extends Message<DSLArgument> {
  /**
   * defined if it's a named argument (kwargs)
   *
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: context_engine.DSLType type = 2;
   */
  type?: DSLType;

  /**
   * @generated from field: string value = 3;
   */
  value = '';

  /**
   * e.g. str, int, float, bool
   *
   * @generated from field: bool is_constant = 4;
   */
  isConstant = false;

  constructor(data?: PartialMessage<DSLArgument>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLArgument';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'type', kind: 'message', T: DSLType },
    { no: 3, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'is_constant', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLArgument {
    return new DSLArgument().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLArgument {
    return new DSLArgument().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLArgument {
    return new DSLArgument().fromJsonString(jsonString, options);
  }

  static equals(
    a: DSLArgument | PlainMessage<DSLArgument> | undefined,
    b: DSLArgument | PlainMessage<DSLArgument> | undefined,
  ): boolean {
    return proto3.util.equals(DSLArgument, a, b);
  }
}

/**
 * @generated from message context_engine.DSLFunction
 */
export class DSLFunction extends Message<DSLFunction> {
  /**
   * @generated from field: string object = 1;
   */
  object = '';

  /**
   * @generated from field: string method_name = 2;
   */
  methodName = '';

  /**
   * @generated from field: repeated context_engine.DSLArgument arguments = 3;
   */
  arguments: DSLArgument[] = [];

  constructor(data?: PartialMessage<DSLFunction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLFunction';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'object', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'method_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 3,
      name: 'arguments',
      kind: 'message',
      T: DSLArgument,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLFunction {
    return new DSLFunction().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLFunction {
    return new DSLFunction().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLFunction {
    return new DSLFunction().fromJsonString(jsonString, options);
  }

  static equals(
    a: DSLFunction | PlainMessage<DSLFunction> | undefined,
    b: DSLFunction | PlainMessage<DSLFunction> | undefined,
  ): boolean {
    return proto3.util.equals(DSLFunction, a, b);
  }
}

/**
 * @generated from message context_engine.DSLResult
 */
export class DSLResult extends Message<DSLResult> {
  /**
   * @generated from field: string name = 1;
   */
  name = '';

  /**
   * @generated from field: context_engine.DSLType type = 2;
   */
  type?: DSLType;

  constructor(data?: PartialMessage<DSLResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'type', kind: 'message', T: DSLType },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLResult {
    return new DSLResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLResult {
    return new DSLResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLResult {
    return new DSLResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: DSLResult | PlainMessage<DSLResult> | undefined,
    b: DSLResult | PlainMessage<DSLResult> | undefined,
  ): boolean {
    return proto3.util.equals(DSLResult, a, b);
  }
}

/**
 * @generated from message context_engine.DSLStatement
 */
export class DSLStatement extends Message<DSLStatement> {
  /**
   * @generated from field: context_engine.DSLResult result = 1;
   */
  result?: DSLResult;

  /**
   * @generated from field: context_engine.DSLFunction function = 2;
   */
  function?: DSLFunction;

  /**
   * @generated from field: string raw_statement = 3;
   */
  rawStatement = '';

  constructor(data?: PartialMessage<DSLStatement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLStatement';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'result', kind: 'message', T: DSLResult },
    { no: 2, name: 'function', kind: 'message', T: DSLFunction },
    {
      no: 3,
      name: 'raw_statement',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLStatement {
    return new DSLStatement().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLStatement {
    return new DSLStatement().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLStatement {
    return new DSLStatement().fromJsonString(jsonString, options);
  }

  static equals(
    a: DSLStatement | PlainMessage<DSLStatement> | undefined,
    b: DSLStatement | PlainMessage<DSLStatement> | undefined,
  ): boolean {
    return proto3.util.equals(DSLStatement, a, b);
  }
}

/**
 * @generated from message context_engine.DSLDocument
 */
export class DSLDocument extends Message<DSLDocument> {
  /**
   * @generated from field: repeated context_engine.DSLStatement statements = 1;
   */
  statements: DSLStatement[] = [];

  /**
   * @generated from field: context_engine.DSLDocumentDebugInfo debug_info = 4;
   */
  debugInfo?: DSLDocumentDebugInfo;

  constructor(data?: PartialMessage<DSLDocument>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLDocument';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'statements',
      kind: 'message',
      T: DSLStatement,
      repeated: true,
    },
    { no: 4, name: 'debug_info', kind: 'message', T: DSLDocumentDebugInfo },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLDocument {
    return new DSLDocument().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLDocument {
    return new DSLDocument().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLDocument {
    return new DSLDocument().fromJsonString(jsonString, options);
  }

  static equals(
    a: DSLDocument | PlainMessage<DSLDocument> | undefined,
    b: DSLDocument | PlainMessage<DSLDocument> | undefined,
  ): boolean {
    return proto3.util.equals(DSLDocument, a, b);
  }
}

/**
 * @generated from message context_engine.DSLDocumentDebugInfo
 */
export class DSLDocumentDebugInfo extends Message<DSLDocumentDebugInfo> {
  /**
   * @generated from field: string system_prompt = 1;
   */
  systemPrompt = '';

  /**
   * @generated from field: string user_prompt = 2;
   */
  userPrompt = '';

  /**
   * @generated from field: string predict_model_variant = 3;
   */
  predictModelVariant = '';

  /**
   * @generated from field: repeated context_engine.RankedAgentExample ranked_agent_examples = 4;
   */
  rankedAgentExamples: RankedAgentExample[] = [];

  constructor(data?: PartialMessage<DSLDocumentDebugInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLDocumentDebugInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'system_prompt',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'user_prompt',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 3,
      name: 'predict_model_variant',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 4,
      name: 'ranked_agent_examples',
      kind: 'message',
      T: RankedAgentExample,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLDocumentDebugInfo {
    return new DSLDocumentDebugInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLDocumentDebugInfo {
    return new DSLDocumentDebugInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLDocumentDebugInfo {
    return new DSLDocumentDebugInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: DSLDocumentDebugInfo | PlainMessage<DSLDocumentDebugInfo> | undefined,
    b: DSLDocumentDebugInfo | PlainMessage<DSLDocumentDebugInfo> | undefined,
  ): boolean {
    return proto3.util.equals(DSLDocumentDebugInfo, a, b);
  }
}

/**
 * @generated from message context_engine.RankedAgentExample
 */
export class RankedAgentExample extends Message<RankedAgentExample> {
  /**
   * @generated from field: context_engine.ContextEngineAgentExample agent_example = 1;
   */
  agentExample?: ContextEngineAgentExample;

  /**
   * @generated from field: float distance = 2;
   */
  distance = 0;

  constructor(data?: PartialMessage<RankedAgentExample>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.RankedAgentExample';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'agent_example',
      kind: 'message',
      T: ContextEngineAgentExample,
    },
    { no: 2, name: 'distance', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): RankedAgentExample {
    return new RankedAgentExample().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): RankedAgentExample {
    return new RankedAgentExample().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): RankedAgentExample {
    return new RankedAgentExample().fromJsonString(jsonString, options);
  }

  static equals(
    a: RankedAgentExample | PlainMessage<RankedAgentExample> | undefined,
    b: RankedAgentExample | PlainMessage<RankedAgentExample> | undefined,
  ): boolean {
    return proto3.util.equals(RankedAgentExample, a, b);
  }
}

/**
 * @generated from message context_engine.CreateAgentRequestAgentExample
 */
export class CreateAgentRequestAgentExample extends Message<CreateAgentRequestAgentExample> {
  /**
   * @generated from field: string input = 1;
   */
  input = '';

  /**
   * @generated from field: string output = 2;
   */
  output = '';

  /**
   * @generated from field: repeated string suggested_follow_ups = 3;
   */
  suggestedFollowUps: string[] = [];

  constructor(data?: PartialMessage<CreateAgentRequestAgentExample>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CreateAgentRequestAgentExample';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'input', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'output', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'suggested_follow_ups',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateAgentRequestAgentExample {
    return new CreateAgentRequestAgentExample().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentRequestAgentExample {
    return new CreateAgentRequestAgentExample().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentRequestAgentExample {
    return new CreateAgentRequestAgentExample().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | CreateAgentRequestAgentExample
      | PlainMessage<CreateAgentRequestAgentExample>
      | undefined,
    b:
      | CreateAgentRequestAgentExample
      | PlainMessage<CreateAgentRequestAgentExample>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateAgentRequestAgentExample, a, b);
  }
}

/**
 * @generated from message context_engine.DSLExampleInstructions
 */
export class DSLExampleInstructions extends Message<DSLExampleInstructions> {
  /**
   * @generated from field: string example_user_query = 1;
   */
  exampleUserQuery = '';

  /**
   * @generated from field: repeated string example_instructions = 2;
   */
  exampleInstructions: string[] = [];

  constructor(data?: PartialMessage<DSLExampleInstructions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLExampleInstructions';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'example_user_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'example_instructions',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLExampleInstructions {
    return new DSLExampleInstructions().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLExampleInstructions {
    return new DSLExampleInstructions().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLExampleInstructions {
    return new DSLExampleInstructions().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DSLExampleInstructions
      | PlainMessage<DSLExampleInstructions>
      | undefined,
    b:
      | DSLExampleInstructions
      | PlainMessage<DSLExampleInstructions>
      | undefined,
  ): boolean {
    return proto3.util.equals(DSLExampleInstructions, a, b);
  }
}

/**
 * @generated from message context_engine.ContextEngineAgentExample
 */
export class ContextEngineAgentExample extends Message<ContextEngineAgentExample> {
  /**
   * @generated from field: string agent_example_id = 1;
   */
  agentExampleId = '';

  /**
   * @generated from field: string agent_id = 2;
   */
  agentId = '';

  /**
   * @generated from field: string input = 3;
   */
  input = '';

  /**
   * @generated from field: string output = 4;
   */
  output = '';

  /**
   * @generated from field: context_engine.AgentExampleEmbedding embedding = 5;
   */
  embedding?: AgentExampleEmbedding;

  /**
   * @generated from field: repeated string suggested_follow_ups = 6;
   */
  suggestedFollowUps: string[] = [];

  constructor(data?: PartialMessage<ContextEngineAgentExample>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ContextEngineAgentExample';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'agent_example_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'input', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'output', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'embedding', kind: 'message', T: AgentExampleEmbedding },
    {
      no: 6,
      name: 'suggested_follow_ups',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ContextEngineAgentExample {
    return new ContextEngineAgentExample().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineAgentExample {
    return new ContextEngineAgentExample().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineAgentExample {
    return new ContextEngineAgentExample().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ContextEngineAgentExample
      | PlainMessage<ContextEngineAgentExample>
      | undefined,
    b:
      | ContextEngineAgentExample
      | PlainMessage<ContextEngineAgentExample>
      | undefined,
  ): boolean {
    return proto3.util.equals(ContextEngineAgentExample, a, b);
  }
}

/**
 * @generated from message context_engine.AgentExampleEmbedding
 */
export class AgentExampleEmbedding extends Message<AgentExampleEmbedding> {
  /**
   * @generated from field: repeated float embedding = 1;
   */
  embedding: number[] = [];

  constructor(data?: PartialMessage<AgentExampleEmbedding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AgentExampleEmbedding';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'embedding',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AgentExampleEmbedding {
    return new AgentExampleEmbedding().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AgentExampleEmbedding {
    return new AgentExampleEmbedding().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AgentExampleEmbedding {
    return new AgentExampleEmbedding().fromJsonString(jsonString, options);
  }

  static equals(
    a: AgentExampleEmbedding | PlainMessage<AgentExampleEmbedding> | undefined,
    b: AgentExampleEmbedding | PlainMessage<AgentExampleEmbedding> | undefined,
  ): boolean {
    return proto3.util.equals(AgentExampleEmbedding, a, b);
  }
}

/**
 * @generated from message context_engine.ContextAgentInfo
 */
export class ContextAgentInfo extends Message<ContextAgentInfo> {
  /**
   * @generated from field: string agent_name = 1;
   */
  agentName = '';

  /**
   * @generated from field: string agent_description = 2;
   */
  agentDescription = '';

  /**
   * @generated from field: bool is_readonly = 11;
   */
  isReadonly = false;

  constructor(data?: PartialMessage<ContextAgentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ContextAgentInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'agent_description',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 11, name: 'is_readonly', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ContextAgentInfo {
    return new ContextAgentInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ContextAgentInfo {
    return new ContextAgentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ContextAgentInfo {
    return new ContextAgentInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: ContextAgentInfo | PlainMessage<ContextAgentInfo> | undefined,
    b: ContextAgentInfo | PlainMessage<ContextAgentInfo> | undefined,
  ): boolean {
    return proto3.util.equals(ContextAgentInfo, a, b);
  }
}

/**
 * @generated from message context_engine.ContextEngineAgent
 */
export class ContextEngineAgent extends Message<ContextEngineAgent> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = '';

  /**
   * @generated from field: context_engine.ContextAgentInfo agent_info = 2;
   */
  agentInfo?: ContextAgentInfo;

  constructor(data?: PartialMessage<ContextEngineAgent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ContextEngineAgent';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'agent_info', kind: 'message', T: ContextAgentInfo },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ContextEngineAgent {
    return new ContextEngineAgent().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineAgent {
    return new ContextEngineAgent().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineAgent {
    return new ContextEngineAgent().fromJsonString(jsonString, options);
  }

  static equals(
    a: ContextEngineAgent | PlainMessage<ContextEngineAgent> | undefined,
    b: ContextEngineAgent | PlainMessage<ContextEngineAgent> | undefined,
  ): boolean {
    return proto3.util.equals(ContextEngineAgent, a, b);
  }
}

/**
 * Predict Model communication signals.
 *
 * @generated from message context_engine.DSLGenerationInputSignal
 */
export class DSLGenerationInputSignal extends Message<DSLGenerationInputSignal> {
  /**
   * this proto is only used as an input signal to predict
   * for generating DSL instructions for incoming user query
   *
   * @generated from field: string user_query = 1;
   */
  userQuery = '';

  /**
   * @generated from field: repeated context_engine.DSLExampleInstructions dsl_example_instructions = 2;
   */
  dslExampleInstructions: DSLExampleInstructions[] = [];

  constructor(data?: PartialMessage<DSLGenerationInputSignal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DSLGenerationInputSignal';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'dsl_example_instructions',
      kind: 'message',
      T: DSLExampleInstructions,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DSLGenerationInputSignal {
    return new DSLGenerationInputSignal().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DSLGenerationInputSignal {
    return new DSLGenerationInputSignal().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DSLGenerationInputSignal {
    return new DSLGenerationInputSignal().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DSLGenerationInputSignal
      | PlainMessage<DSLGenerationInputSignal>
      | undefined,
    b:
      | DSLGenerationInputSignal
      | PlainMessage<DSLGenerationInputSignal>
      | undefined,
  ): boolean {
    return proto3.util.equals(DSLGenerationInputSignal, a, b);
  }
}

/**
 * @generated from message context_engine.CodeGenerationInputSignal
 */
export class CodeGenerationInputSignal extends Message<CodeGenerationInputSignal> {
  /**
   * @generated from field: string code_gen_query = 1;
   */
  codeGenQuery = '';

  /**
   * @generated from field: string context_name = 2;
   */
  contextName = '';

  /**
   * @generated from field: repeated string data_models = 3;
   */
  dataModels: string[] = [];

  /**
   * @generated from field: repeated string tools = 4;
   */
  tools: string[] = [];

  /**
   * @generated from field: repeated string examples = 5;
   */
  examples: string[] = [];

  constructor(data?: PartialMessage<CodeGenerationInputSignal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CodeGenerationInputSignal';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'code_gen_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'context_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 3,
      name: 'data_models',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 4,
      name: 'tools',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 5,
      name: 'examples',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CodeGenerationInputSignal {
    return new CodeGenerationInputSignal().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CodeGenerationInputSignal {
    return new CodeGenerationInputSignal().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CodeGenerationInputSignal {
    return new CodeGenerationInputSignal().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CodeGenerationInputSignal
      | PlainMessage<CodeGenerationInputSignal>
      | undefined,
    b:
      | CodeGenerationInputSignal
      | PlainMessage<CodeGenerationInputSignal>
      | undefined,
  ): boolean {
    return proto3.util.equals(CodeGenerationInputSignal, a, b);
  }
}
