import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkCircleFill } from '@dropbox/dig-icons/assets';
import styles from './DownloadDashItem.module.css';

import type { SVGProps } from 'react';

type DownloadDashEntryProps = {
  title: string;
  content: string;
  callToAction: string;
  completeCallToAction: string;
  complete: boolean;
  onAction: () => void;
  icon?: (props: SVGProps<SVGSVGElement>) => React.ReactElement;
};

export const DownloadDashItem = ({
  title,
  content,
  callToAction,
  completeCallToAction,
  complete,
  onAction,
  icon,
}: DownloadDashEntryProps) => {
  let downloadIcon = icon;
  if (!icon || complete) {
    downloadIcon = CheckmarkCircleFill;
  }

  return (
    <Box className={styles.container} display="flex" flexDirection="row">
      <Box className={styles.browserIconContainer}>
        {downloadIcon && <UIIcon src={downloadIcon} />}
      </Box>
      <Box display="flex" flexDirection="column">
        <Text className={styles.title} size="small" isBold>
          {title}
        </Text>
        <Text size="small" className={styles.description}>
          {content}
        </Text>
        <Box>
          <Button
            variant={complete ? 'transparent' : 'outline'}
            onClick={onAction}
            size="small"
          >
            {complete ? completeCallToAction : callToAction}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
