import { Avatar } from '@dropbox/dig-components/avatar';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { getInitials } from '@mirage/shared/account';
import i18n from '@mirage/translations';
import styles from './SuggestedTeamInvites.module.css';

import type { TeamMember } from './types';

type Props = {
  teamMembers: TeamMember[];
  onTeamMemberSelect: (teamMember: TeamMember) => void;
};

export const SuggestedTeamInvites = ({
  teamMembers,
  onTeamMemberSelect,
}: Props) => {
  if (!teamMembers?.length) return null;

  return (
    <Box className={styles.container}>
      <Text size="small" isBold={true} className={styles.suggestedPeopleLabel}>
        {i18n.t('team_invite_suggested_people')}
      </Text>
      <Box className={styles.teamMembers} display="flex" flexDirection="column">
        {teamMembers.map((user, i) => {
          return (
            <Box
              className={styles.contactRow}
              display="flex"
              key={`team-member-${i}`}
              flexDirection="row"
              alignItems="center"
              role="button"
              onClick={() => onTeamMemberSelect(user)}
            >
              <Box className={styles.avatar}>
                <Avatar src={user.photo} alt={user.name}>
                  {getInitials(user.name || user.email || '')}
                </Avatar>
              </Box>
              <Box
                overflowX="hidden"
                display="flex"
                flexDirection="column"
                flexGrow={1}
              >
                <Text size="medium" className={styles.contactName}>
                  {user.name}
                </Text>
                <Text size="small" className={styles.contactEmail}>
                  {user.email}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
