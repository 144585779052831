import { ContentRow, Metadata } from '@dropbox/dash-component-library';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { activityDerivePAPProps } from '@mirage/service-feed/util/analytics';
import { getFirstActivity } from '@mirage/service-feed/util/helpers';
import { publishEvent, SurveyEvent } from '@mirage/service-feedback';
import { openURL } from '@mirage/service-platform-actions';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { getTimeAgoString } from '@mirage/shared/util/time';
import { useCallback } from 'react';
import { ActivityActionBar } from '../ActivityActionBar';
import { type ActivityObjectProps } from '../ActivityObject';

import type { dash_feed, stacks } from '@dropbox/api-v2-client';

type StackActivityObjectProps = {
  stack: stacks.Stack;
  stackData: stacks.StackData;
} & ActivityObjectProps;

export const StackActivityObject = ({
  activity,
  stack,
  stackData,
  index,
}: StackActivityObjectProps) => {
  const isMobileSize = useIsMobileSize();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const onClickRow = useCallback(() => {
    reportPapEvent(
      PAP_Open_DashLink({
        ...activityDerivePAPProps(activity, index),
        actionSurfaceComponent: 'activity_feed',
        featureLine: 'activity_feed',
      }),
    );
    publishEvent(SurveyEvent.LaunchStartPageActivityItem, {}, true);
    openURL(activity.target?.url);

    if (stack) {
      reportPapEvent(
        PAP_View_DashStack({
          ...stackDerivePAPProps(stack),
          itemPosition: index,
          actionSurfaceComponent: 'activity_feed',
          featureLine: 'stacks',
        }),
      );
    }
  }, [activity, stack, reportPapEvent, index]);

  return (
    <ContentRow
      isMobile={isMobileSize}
      paddingSize="medium"
      onClick={onClickRow}
      withStartAccessory={
        <StackActivityObjectIcon
          activity={activity}
          stack={stack}
          stackData={stackData}
        />
      }
      withLabel={stackData.name}
      withMeta={
        <StackActivityObjectMetadata
          activity={activity}
          stack={stack}
          stackData={stackData}
        />
      }
      withActions={<ActivityActionBar activity={activity} index={index} />}
    />
  );
};

const StackActivityObjectIcon = ({ stackData }: StackActivityObjectProps) => {
  return (
    <DashStackIcon
      size="large"
      colorIndex={stackData.color_index}
      emoji={stackData.emoji}
      shapeVariant="square"
      customColor={stackData.custom_color}
    />
  );
};

const StackActivityObjectMetadata = ({
  stack,
  activity,
}: StackActivityObjectProps) => {
  const timestampMs = getStackActivityTimestampMs(stack, activity);
  if (!timestampMs) {
    return null;
  }

  return (
    <Metadata size="small">
      <Metadata.Item>
        <Metadata.Label>{getTimeAgoString(timestampMs)}</Metadata.Label>
      </Metadata.Item>
    </Metadata>
  );
};

const getStackActivityTimestampMs = (
  stack: stacks.Stack,
  activity: dash_feed.FeedItem,
): number => {
  const activityTs = getFirstActivity(activity)?.ts;
  if (activityTs) {
    return activityTs * 1000;
  }
  return stack.last_modified_time_utc_sec! * 1000;
};
