// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/context_engine_agents_service.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Empty, Message, proto3 } from '@bufbuild/protobuf';
import {
  ContextEngineAgent,
  ContextEngineAgentExample,
  CreateAgentRequestAgentExample,
  DSLDocument,
  ExecuteDSLInstructionsDebugInfo,
  ExecutionEngineException,
  ExecutionStep,
} from './agents_pb';
import {
  ClientContext,
  UserInteractionRequest,
  UserInteractionResponse,
} from './models_pb';

/**
 * @generated from message context_engine.CreateAgentRequest
 */
export class CreateAgentRequest extends Message<CreateAgentRequest> {
  /**
   * @generated from field: string agent_name = 1;
   */
  agentName = '';

  /**
   * @generated from field: string agent_description = 2;
   */
  agentDescription = '';

  /**
   * @generated from field: repeated context_engine.CreateAgentRequestAgentExample agent_examples = 8;
   */
  agentExamples: CreateAgentRequestAgentExample[] = [];

  /**
   * @generated from field: bool debug = 9;
   */
  debug = false;

  /**
   * @generated from field: string source = 10;
   */
  source = '';

  constructor(data?: PartialMessage<CreateAgentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CreateAgentRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'agent_description',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 8,
      name: 'agent_examples',
      kind: 'message',
      T: CreateAgentRequestAgentExample,
      repeated: true,
    },
    { no: 9, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateAgentRequest {
    return new CreateAgentRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentRequest {
    return new CreateAgentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentRequest {
    return new CreateAgentRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateAgentRequest | PlainMessage<CreateAgentRequest> | undefined,
    b: CreateAgentRequest | PlainMessage<CreateAgentRequest> | undefined,
  ): boolean {
    return proto3.util.equals(CreateAgentRequest, a, b);
  }
}

/**
 * @generated from message context_engine.CreateAgentResult
 */
export class CreateAgentResult extends Message<CreateAgentResult> {
  /**
   * @generated from field: context_engine.ContextEngineAgent context_engine_agent = 1;
   */
  contextEngineAgent?: ContextEngineAgent;

  constructor(data?: PartialMessage<CreateAgentResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CreateAgentResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'context_engine_agent',
      kind: 'message',
      T: ContextEngineAgent,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateAgentResult {
    return new CreateAgentResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentResult {
    return new CreateAgentResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentResult {
    return new CreateAgentResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateAgentResult | PlainMessage<CreateAgentResult> | undefined,
    b: CreateAgentResult | PlainMessage<CreateAgentResult> | undefined,
  ): boolean {
    return proto3.util.equals(CreateAgentResult, a, b);
  }
}

/**
 * @generated from message context_engine.CreateAgentError
 */
export class CreateAgentError extends Message<CreateAgentError> {
  /**
   * @generated from oneof context_engine.CreateAgentError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_name = 1;
         */
        value: Empty;
        case: 'missingName';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 2;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 3;
         */
        value: Empty;
        case: 'missingSource';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateAgentError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CreateAgentError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'missing_name', kind: 'message', T: Empty, oneof: 'err' },
    { no: 2, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateAgentError {
    return new CreateAgentError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentError {
    return new CreateAgentError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentError {
    return new CreateAgentError().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateAgentError | PlainMessage<CreateAgentError> | undefined,
    b: CreateAgentError | PlainMessage<CreateAgentError> | undefined,
  ): boolean {
    return proto3.util.equals(CreateAgentError, a, b);
  }
}

/**
 * @generated from message context_engine.GenerateAndExecuteDSLInstructionsError
 */
export class GenerateAndExecuteDSLInstructionsError extends Message<GenerateAndExecuteDSLInstructionsError> {
  /**
   * @generated from oneof context_engine.GenerateAndExecuteDSLInstructionsError.err
   */
  err:
    | {
        /**
         * @generated from field: context_engine.GenerateDSLInstructionsError generation_error = 1;
         */
        value: GenerateDSLInstructionsError;
        case: 'generationError';
      }
    | {
        /**
         * @generated from field: context_engine.ExecuteDSLInstructionsError execution_error = 2;
         */
        value: ExecuteDSLInstructionsError;
        case: 'executionError';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 3;
         */
        value: Empty;
        case: 'missingSource';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty invalid_variant = 4;
         */
        value: Empty;
        case: 'invalidVariant';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GenerateAndExecuteDSLInstructionsError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName =
    'context_engine.GenerateAndExecuteDSLInstructionsError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'generation_error',
      kind: 'message',
      T: GenerateDSLInstructionsError,
      oneof: 'err',
    },
    {
      no: 2,
      name: 'execution_error',
      kind: 'message',
      T: ExecuteDSLInstructionsError,
      oneof: 'err',
    },
    { no: 3, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
    { no: 4, name: 'invalid_variant', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GenerateAndExecuteDSLInstructionsError {
    return new GenerateAndExecuteDSLInstructionsError().fromBinary(
      bytes,
      options,
    );
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GenerateAndExecuteDSLInstructionsError {
    return new GenerateAndExecuteDSLInstructionsError().fromJson(
      jsonValue,
      options,
    );
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GenerateAndExecuteDSLInstructionsError {
    return new GenerateAndExecuteDSLInstructionsError().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GenerateAndExecuteDSLInstructionsError
      | PlainMessage<GenerateAndExecuteDSLInstructionsError>
      | undefined,
    b:
      | GenerateAndExecuteDSLInstructionsError
      | PlainMessage<GenerateAndExecuteDSLInstructionsError>
      | undefined,
  ): boolean {
    return proto3.util.equals(GenerateAndExecuteDSLInstructionsError, a, b);
  }
}

/**
 * @generated from message context_engine.GenerateDSLInstructionsRequest
 */
export class GenerateDSLInstructionsRequest extends Message<GenerateDSLInstructionsRequest> {
  /**
   * query submitted by the user, that the instructions generated by context engine will answer i.e. "when is my next meeting?"
   *
   * @generated from field: string user_query = 2;
   */
  userQuery = '';

  /**
   * id of the agent to generate instructions for
   *
   * @generated from field: string agent_id = 4;
   */
  agentId = '';

  /**
   * optional. whether or not to log additional debugging information which may contain PII.
   *
   * @generated from field: bool debug = 5;
   */
  debug = false;

  /**
   * required. the source (i.e. client) of this api call. i.e. "dash"
   *
   * @generated from field: string source = 6;
   */
  source = '';

  /**
   * When used with GenerateAndExecuteDSLInstructions, specifying session_id will
   * continue execution of a saved session and not generate new dsl instructions.
   * This is exclusively used for user-interaction requests in single-turn. If
   * using multi-turn, only use conversation_id.
   *
   * @generated from field: string session_id = 7;
   */
  sessionId = '';

  /**
   * When used with GenerateAndExecuteDSLInstructions to continue execution of
   * a saved session, ui_response will contain the value that should be passed
   * back into the paused execution for use when executing the remaining
   * instructions.
   *
   * @generated from field: context_engine.UserInteractionResponse ui_response = 8;
   */
  uiResponse?: UserInteractionResponse;

  /**
   * optional. Information from the client environment that can be used in execution,
   * for example: local timezone information use to do date/time arithmetic
   *
   * @generated from field: context_engine.ClientContext client_context = 9;
   */
  clientContext?: ClientContext;

  /**
   * optional. When present is used to refer to the current conversation. Should not
   * be used with session_id. If session_id and conversation_id are present, session_id
   * will be ignored.
   *
   * @generated from field: string conversation_id = 10;
   */
  conversationId = '';

  /**
   * optional. Disable the use of cached instructions
   *
   * @generated from field: bool disable_cache = 11;
   */
  disableCache = false;

  constructor(data?: PartialMessage<GenerateDSLInstructionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GenerateDSLInstructionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: 'user_query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'session_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'ui_response', kind: 'message', T: UserInteractionResponse },
    { no: 9, name: 'client_context', kind: 'message', T: ClientContext },
    {
      no: 10,
      name: 'conversation_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 11,
      name: 'disable_cache',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GenerateDSLInstructionsRequest {
    return new GenerateDSLInstructionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GenerateDSLInstructionsRequest {
    return new GenerateDSLInstructionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GenerateDSLInstructionsRequest {
    return new GenerateDSLInstructionsRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GenerateDSLInstructionsRequest
      | PlainMessage<GenerateDSLInstructionsRequest>
      | undefined,
    b:
      | GenerateDSLInstructionsRequest
      | PlainMessage<GenerateDSLInstructionsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GenerateDSLInstructionsRequest, a, b);
  }
}

/**
 * @generated from message context_engine.GenerateDSLInstructionsResult
 */
export class GenerateDSLInstructionsResult extends Message<GenerateDSLInstructionsResult> {
  /**
   * @generated from field: string user_query = 1;
   */
  userQuery = '';

  /**
   * @generated from field: string agent_id = 2;
   */
  agentId = '';

  /**
   * @generated from field: string dsl_id = 3;
   */
  dslId = '';

  /**
   * @generated from field: context_engine.DSLDocument dsl_document = 4;
   */
  dslDocument?: DSLDocument;

  /**
   * @generated from field: string created_at_timestamp = 5;
   */
  createdAtTimestamp = '';

  /**
   * @generated from field: string gend_code = 6;
   */
  gendCode = '';

  /**
   * @generated from field: string gend_inlined_code = 7;
   */
  gendInlinedCode = '';

  constructor(data?: PartialMessage<GenerateDSLInstructionsResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GenerateDSLInstructionsResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'dsl_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'dsl_document', kind: 'message', T: DSLDocument },
    {
      no: 5,
      name: 'created_at_timestamp',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 6, name: 'gend_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 7,
      name: 'gend_inlined_code',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GenerateDSLInstructionsResult {
    return new GenerateDSLInstructionsResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GenerateDSLInstructionsResult {
    return new GenerateDSLInstructionsResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GenerateDSLInstructionsResult {
    return new GenerateDSLInstructionsResult().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GenerateDSLInstructionsResult
      | PlainMessage<GenerateDSLInstructionsResult>
      | undefined,
    b:
      | GenerateDSLInstructionsResult
      | PlainMessage<GenerateDSLInstructionsResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(GenerateDSLInstructionsResult, a, b);
  }
}

/**
 * @generated from message context_engine.DeleteAgentRequest
 */
export class DeleteAgentRequest extends Message<DeleteAgentRequest> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = '';

  /**
   * @generated from field: bool debug = 2;
   */
  debug = false;

  /**
   * @generated from field: string source = 3;
   */
  source = '';

  constructor(data?: PartialMessage<DeleteAgentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DeleteAgentRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteAgentRequest {
    return new DeleteAgentRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentRequest {
    return new DeleteAgentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentRequest {
    return new DeleteAgentRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteAgentRequest | PlainMessage<DeleteAgentRequest> | undefined,
    b: DeleteAgentRequest | PlainMessage<DeleteAgentRequest> | undefined,
  ): boolean {
    return proto3.util.equals(DeleteAgentRequest, a, b);
  }
}

/**
 * @generated from message context_engine.DeleteAgentResult
 */
export class DeleteAgentResult extends Message<DeleteAgentResult> {
  constructor(data?: PartialMessage<DeleteAgentResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DeleteAgentResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteAgentResult {
    return new DeleteAgentResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentResult {
    return new DeleteAgentResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentResult {
    return new DeleteAgentResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteAgentResult | PlainMessage<DeleteAgentResult> | undefined,
    b: DeleteAgentResult | PlainMessage<DeleteAgentResult> | undefined,
  ): boolean {
    return proto3.util.equals(DeleteAgentResult, a, b);
  }
}

/**
 * @generated from message context_engine.DeleteAgentError
 */
export class DeleteAgentError extends Message<DeleteAgentError> {
  /**
   * @generated from oneof context_engine.DeleteAgentError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_agent_id = 1;
         */
        value: Empty;
        case: 'missingAgentId';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 2;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 3;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_is_readonly = 4;
         */
        value: Empty;
        case: 'agentIsReadonly';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 5;
         */
        value: Empty;
        case: 'missingSource';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DeleteAgentError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DeleteAgentError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_agent_id',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 2, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 4,
      name: 'agent_is_readonly',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 5, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteAgentError {
    return new DeleteAgentError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentError {
    return new DeleteAgentError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentError {
    return new DeleteAgentError().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteAgentError | PlainMessage<DeleteAgentError> | undefined,
    b: DeleteAgentError | PlainMessage<DeleteAgentError> | undefined,
  ): boolean {
    return proto3.util.equals(DeleteAgentError, a, b);
  }
}

/**
 * @generated from message context_engine.GetAgentRequest
 */
export class GetAgentRequest extends Message<GetAgentRequest> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = '';

  constructor(data?: PartialMessage<GetAgentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetAgentRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAgentRequest {
    return new GetAgentRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAgentRequest {
    return new GetAgentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAgentRequest {
    return new GetAgentRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetAgentRequest | PlainMessage<GetAgentRequest> | undefined,
    b: GetAgentRequest | PlainMessage<GetAgentRequest> | undefined,
  ): boolean {
    return proto3.util.equals(GetAgentRequest, a, b);
  }
}

/**
 * @generated from message context_engine.GetAgentResult
 */
export class GetAgentResult extends Message<GetAgentResult> {
  /**
   * @generated from field: context_engine.ContextEngineAgent context_engine_agent = 1;
   */
  contextEngineAgent?: ContextEngineAgent;

  constructor(data?: PartialMessage<GetAgentResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetAgentResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'context_engine_agent',
      kind: 'message',
      T: ContextEngineAgent,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAgentResult {
    return new GetAgentResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAgentResult {
    return new GetAgentResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAgentResult {
    return new GetAgentResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetAgentResult | PlainMessage<GetAgentResult> | undefined,
    b: GetAgentResult | PlainMessage<GetAgentResult> | undefined,
  ): boolean {
    return proto3.util.equals(GetAgentResult, a, b);
  }
}

/**
 * @generated from message context_engine.GetAgentError
 */
export class GetAgentError extends Message<GetAgentError> {
  /**
   * @generated from oneof context_engine.GetAgentError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_agent_id = 1;
         */
        value: Empty;
        case: 'missingAgentId';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 2;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 3;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetAgentError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetAgentError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_agent_id',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 2, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAgentError {
    return new GetAgentError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAgentError {
    return new GetAgentError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAgentError {
    return new GetAgentError().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetAgentError | PlainMessage<GetAgentError> | undefined,
    b: GetAgentError | PlainMessage<GetAgentError> | undefined,
  ): boolean {
    return proto3.util.equals(GetAgentError, a, b);
  }
}

/**
 * @generated from message context_engine.UpdateAgentRequest
 */
export class UpdateAgentRequest extends Message<UpdateAgentRequest> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = '';

  /**
   * @generated from field: string agent_name = 2;
   */
  agentName = '';

  /**
   * @generated from field: string agent_description = 3;
   */
  agentDescription = '';

  /**
   * @generated from field: string url = 4;
   */
  url = '';

  /**
   * @generated from field: string logo_url = 5;
   */
  logoUrl = '';

  /**
   * @generated from field: repeated string coowners = 6;
   */
  coowners: string[] = [];

  /**
   * @generated from field: repeated string context_ids = 7;
   */
  contextIds: string[] = [];

  /**
   * @generated from field: bool public = 8;
   */
  public = false;

  /**
   * @generated from field: bool debug = 9;
   */
  debug = false;

  /**
   * @generated from field: string source = 10;
   */
  source = '';

  constructor(data?: PartialMessage<UpdateAgentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UpdateAgentRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'agent_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'agent_description',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 4, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'logo_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 6,
      name: 'coowners',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 7,
      name: 'context_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    { no: 8, name: 'public', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UpdateAgentRequest {
    return new UpdateAgentRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UpdateAgentRequest {
    return new UpdateAgentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UpdateAgentRequest {
    return new UpdateAgentRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateAgentRequest | PlainMessage<UpdateAgentRequest> | undefined,
    b: UpdateAgentRequest | PlainMessage<UpdateAgentRequest> | undefined,
  ): boolean {
    return proto3.util.equals(UpdateAgentRequest, a, b);
  }
}

/**
 * @generated from message context_engine.UpdateAgentResult
 */
export class UpdateAgentResult extends Message<UpdateAgentResult> {
  /**
   * @generated from field: context_engine.ContextEngineAgent context_engine_agent = 1;
   */
  contextEngineAgent?: ContextEngineAgent;

  constructor(data?: PartialMessage<UpdateAgentResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UpdateAgentResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'context_engine_agent',
      kind: 'message',
      T: ContextEngineAgent,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UpdateAgentResult {
    return new UpdateAgentResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UpdateAgentResult {
    return new UpdateAgentResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UpdateAgentResult {
    return new UpdateAgentResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateAgentResult | PlainMessage<UpdateAgentResult> | undefined,
    b: UpdateAgentResult | PlainMessage<UpdateAgentResult> | undefined,
  ): boolean {
    return proto3.util.equals(UpdateAgentResult, a, b);
  }
}

/**
 * @generated from message context_engine.UpdateAgentError
 */
export class UpdateAgentError extends Message<UpdateAgentError> {
  /**
   * @generated from oneof context_engine.UpdateAgentError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_agent_id = 1;
         */
        value: Empty;
        case: 'missingAgentId';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 2;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 3;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_is_readonly = 4;
         */
        value: Empty;
        case: 'agentIsReadonly';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 5;
         */
        value: Empty;
        case: 'missingSource';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UpdateAgentError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UpdateAgentError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_agent_id',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 2, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 4,
      name: 'agent_is_readonly',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 5, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UpdateAgentError {
    return new UpdateAgentError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UpdateAgentError {
    return new UpdateAgentError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UpdateAgentError {
    return new UpdateAgentError().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateAgentError | PlainMessage<UpdateAgentError> | undefined,
    b: UpdateAgentError | PlainMessage<UpdateAgentError> | undefined,
  ): boolean {
    return proto3.util.equals(UpdateAgentError, a, b);
  }
}

/**
 * @generated from message context_engine.ListAgentsRequest
 */
export class ListAgentsRequest extends Message<ListAgentsRequest> {
  constructor(data?: PartialMessage<ListAgentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ListAgentsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListAgentsRequest {
    return new ListAgentsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListAgentsRequest {
    return new ListAgentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListAgentsRequest {
    return new ListAgentsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAgentsRequest | PlainMessage<ListAgentsRequest> | undefined,
    b: ListAgentsRequest | PlainMessage<ListAgentsRequest> | undefined,
  ): boolean {
    return proto3.util.equals(ListAgentsRequest, a, b);
  }
}

/**
 * @generated from message context_engine.ListAgentsResult
 */
export class ListAgentsResult extends Message<ListAgentsResult> {
  /**
   * @generated from field: repeated context_engine.ContextEngineAgent context_engine_agents = 1;
   */
  contextEngineAgents: ContextEngineAgent[] = [];

  constructor(data?: PartialMessage<ListAgentsResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ListAgentsResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'context_engine_agents',
      kind: 'message',
      T: ContextEngineAgent,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListAgentsResult {
    return new ListAgentsResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListAgentsResult {
    return new ListAgentsResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListAgentsResult {
    return new ListAgentsResult().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAgentsResult | PlainMessage<ListAgentsResult> | undefined,
    b: ListAgentsResult | PlainMessage<ListAgentsResult> | undefined,
  ): boolean {
    return proto3.util.equals(ListAgentsResult, a, b);
  }
}

/**
 * @generated from message context_engine.ListAgentsError
 */
export class ListAgentsError extends Message<ListAgentsError> {
  /**
   * @generated from oneof context_engine.ListAgentsError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 1;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListAgentsError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ListAgentsError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListAgentsError {
    return new ListAgentsError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListAgentsError {
    return new ListAgentsError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListAgentsError {
    return new ListAgentsError().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListAgentsError | PlainMessage<ListAgentsError> | undefined,
    b: ListAgentsError | PlainMessage<ListAgentsError> | undefined,
  ): boolean {
    return proto3.util.equals(ListAgentsError, a, b);
  }
}

/**
 * @generated from message context_engine.CreateAgentExampleRequest
 */
export class CreateAgentExampleRequest extends Message<CreateAgentExampleRequest> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = '';

  /**
   * @generated from field: string input = 2;
   */
  input = '';

  /**
   * @generated from field: string output = 3;
   */
  output = '';

  /**
   * @generated from field: bool debug = 4;
   */
  debug = false;

  /**
   * @generated from field: string source = 5;
   */
  source = '';

  /**
   * @generated from field: repeated string suggested_follow_ups = 6;
   */
  suggestedFollowUps: string[] = [];

  constructor(data?: PartialMessage<CreateAgentExampleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CreateAgentExampleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'input', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'output', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 6,
      name: 'suggested_follow_ups',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateAgentExampleRequest {
    return new CreateAgentExampleRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentExampleRequest {
    return new CreateAgentExampleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentExampleRequest {
    return new CreateAgentExampleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateAgentExampleRequest
      | PlainMessage<CreateAgentExampleRequest>
      | undefined,
    b:
      | CreateAgentExampleRequest
      | PlainMessage<CreateAgentExampleRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateAgentExampleRequest, a, b);
  }
}

/**
 * @generated from message context_engine.CreateAgentExampleResult
 */
export class CreateAgentExampleResult extends Message<CreateAgentExampleResult> {
  /**
   * @generated from field: context_engine.ContextEngineAgentExample agent_example = 1;
   */
  agentExample?: ContextEngineAgentExample;

  constructor(data?: PartialMessage<CreateAgentExampleResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CreateAgentExampleResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'agent_example',
      kind: 'message',
      T: ContextEngineAgentExample,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateAgentExampleResult {
    return new CreateAgentExampleResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentExampleResult {
    return new CreateAgentExampleResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentExampleResult {
    return new CreateAgentExampleResult().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateAgentExampleResult
      | PlainMessage<CreateAgentExampleResult>
      | undefined,
    b:
      | CreateAgentExampleResult
      | PlainMessage<CreateAgentExampleResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateAgentExampleResult, a, b);
  }
}

/**
 * @generated from message context_engine.CreateAgentExampleError
 */
export class CreateAgentExampleError extends Message<CreateAgentExampleError> {
  /**
   * @generated from oneof context_engine.CreateAgentExampleError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 1;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 2;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_is_readonly = 3;
         */
        value: Empty;
        case: 'agentIsReadonly';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 4;
         */
        value: Empty;
        case: 'missingSource';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateAgentExampleError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CreateAgentExampleError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
    { no: 2, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 3,
      name: 'agent_is_readonly',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 4, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CreateAgentExampleError {
    return new CreateAgentExampleError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentExampleError {
    return new CreateAgentExampleError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CreateAgentExampleError {
    return new CreateAgentExampleError().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | CreateAgentExampleError
      | PlainMessage<CreateAgentExampleError>
      | undefined,
    b:
      | CreateAgentExampleError
      | PlainMessage<CreateAgentExampleError>
      | undefined,
  ): boolean {
    return proto3.util.equals(CreateAgentExampleError, a, b);
  }
}

/**
 * @generated from message context_engine.DeleteAgentExampleRequest
 */
export class DeleteAgentExampleRequest extends Message<DeleteAgentExampleRequest> {
  /**
   * @generated from field: string agent_example_id = 1;
   */
  agentExampleId = '';

  /**
   * @generated from field: bool debug = 2;
   */
  debug = false;

  /**
   * @generated from field: string source = 3;
   */
  source = '';

  constructor(data?: PartialMessage<DeleteAgentExampleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DeleteAgentExampleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'agent_example_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteAgentExampleRequest {
    return new DeleteAgentExampleRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentExampleRequest {
    return new DeleteAgentExampleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentExampleRequest {
    return new DeleteAgentExampleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DeleteAgentExampleRequest
      | PlainMessage<DeleteAgentExampleRequest>
      | undefined,
    b:
      | DeleteAgentExampleRequest
      | PlainMessage<DeleteAgentExampleRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(DeleteAgentExampleRequest, a, b);
  }
}

/**
 * @generated from message context_engine.DeleteAgentExampleResult
 */
export class DeleteAgentExampleResult extends Message<DeleteAgentExampleResult> {
  constructor(data?: PartialMessage<DeleteAgentExampleResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DeleteAgentExampleResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteAgentExampleResult {
    return new DeleteAgentExampleResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentExampleResult {
    return new DeleteAgentExampleResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentExampleResult {
    return new DeleteAgentExampleResult().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DeleteAgentExampleResult
      | PlainMessage<DeleteAgentExampleResult>
      | undefined,
    b:
      | DeleteAgentExampleResult
      | PlainMessage<DeleteAgentExampleResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(DeleteAgentExampleResult, a, b);
  }
}

/**
 * @generated from message context_engine.DeleteAgentExampleError
 */
export class DeleteAgentExampleError extends Message<DeleteAgentExampleError> {
  /**
   * @generated from oneof context_engine.DeleteAgentExampleError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 1;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_example_not_found = 2;
         */
        value: Empty;
        case: 'agentExampleNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_is_readonly = 3;
         */
        value: Empty;
        case: 'agentIsReadonly';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 4;
         */
        value: Empty;
        case: 'missingSource';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DeleteAgentExampleError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DeleteAgentExampleError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 2,
      name: 'agent_example_not_found',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    {
      no: 3,
      name: 'agent_is_readonly',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 4, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DeleteAgentExampleError {
    return new DeleteAgentExampleError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentExampleError {
    return new DeleteAgentExampleError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DeleteAgentExampleError {
    return new DeleteAgentExampleError().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DeleteAgentExampleError
      | PlainMessage<DeleteAgentExampleError>
      | undefined,
    b:
      | DeleteAgentExampleError
      | PlainMessage<DeleteAgentExampleError>
      | undefined,
  ): boolean {
    return proto3.util.equals(DeleteAgentExampleError, a, b);
  }
}

/**
 * @generated from message context_engine.ListAgentExamplesRequest
 */
export class ListAgentExamplesRequest extends Message<ListAgentExamplesRequest> {
  /**
   * @generated from field: string agent_id = 1;
   */
  agentId = '';

  constructor(data?: PartialMessage<ListAgentExamplesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ListAgentExamplesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListAgentExamplesRequest {
    return new ListAgentExamplesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListAgentExamplesRequest {
    return new ListAgentExamplesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListAgentExamplesRequest {
    return new ListAgentExamplesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListAgentExamplesRequest
      | PlainMessage<ListAgentExamplesRequest>
      | undefined,
    b:
      | ListAgentExamplesRequest
      | PlainMessage<ListAgentExamplesRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(ListAgentExamplesRequest, a, b);
  }
}

/**
 * @generated from message context_engine.ListAgentExamplesResult
 */
export class ListAgentExamplesResult extends Message<ListAgentExamplesResult> {
  /**
   * @generated from field: repeated context_engine.ContextEngineAgentExample agent_examples = 1;
   */
  agentExamples: ContextEngineAgentExample[] = [];

  constructor(data?: PartialMessage<ListAgentExamplesResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ListAgentExamplesResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'agent_examples',
      kind: 'message',
      T: ContextEngineAgentExample,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListAgentExamplesResult {
    return new ListAgentExamplesResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListAgentExamplesResult {
    return new ListAgentExamplesResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListAgentExamplesResult {
    return new ListAgentExamplesResult().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListAgentExamplesResult
      | PlainMessage<ListAgentExamplesResult>
      | undefined,
    b:
      | ListAgentExamplesResult
      | PlainMessage<ListAgentExamplesResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(ListAgentExamplesResult, a, b);
  }
}

/**
 * @generated from message context_engine.ListAgentExamplesError
 */
export class ListAgentExamplesError extends Message<ListAgentExamplesError> {
  /**
   * @generated from oneof context_engine.ListAgentExamplesError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 1;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 2;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListAgentExamplesError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ListAgentExamplesError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    { no: 2, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ListAgentExamplesError {
    return new ListAgentExamplesError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ListAgentExamplesError {
    return new ListAgentExamplesError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ListAgentExamplesError {
    return new ListAgentExamplesError().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListAgentExamplesError
      | PlainMessage<ListAgentExamplesError>
      | undefined,
    b:
      | ListAgentExamplesError
      | PlainMessage<ListAgentExamplesError>
      | undefined,
  ): boolean {
    return proto3.util.equals(ListAgentExamplesError, a, b);
  }
}

/**
 * @generated from message context_engine.ExecuteDSLInstructionsRequest
 */
export class ExecuteDSLInstructionsRequest extends Message<ExecuteDSLInstructionsRequest> {
  /**
   * @generated from field: string instructions_id = 1;
   */
  instructionsId = '';

  /**
   * @generated from field: string instructions = 2;
   */
  instructions = '';

  /**
   * optional. whether or not to log additional debugging information which may contain PII.
   *
   * @generated from field: bool debug = 3;
   */
  debug = false;

  /**
   * required. the source (i.e. client) of this api call. i.e. "dash"
   *
   * @generated from field: string source = 4;
   */
  source = '';

  /**
   * required. id of the agent the instructions are for. Needed to guarantee that only code
   * the agent has access to is executed.
   *
   * @generated from field: string agent_id = 5;
   */
  agentId = '';

  /**
   * When used with GenerateAndExecuteDSLInstructions, specifying session_id will
   * continue execution of a saved session and not generate new dsl instructions.
   * This is exclusively used for user-interaction requests in single-turn. If
   * using multi-turn, only use conversation_id.
   *
   * @generated from field: string session_id = 7;
   */
  sessionId = '';

  /**
   * When used with session_id,
   * ui_response should contain the value that should be passed
   * back into the paused execution for use when executing the remaining
   * instructions.
   *
   * @generated from field: context_engine.UserInteractionResponse ui_response = 8;
   */
  uiResponse?: UserInteractionResponse;

  /**
   * optional. Information from the client environment that can be used in execution,
   * for example: local timezone information use to do date/time arithmetic
   *
   * @generated from field: context_engine.ClientContext client_context = 9;
   */
  clientContext?: ClientContext;

  /**
   * optional. When present is used to refer to the current conversation. Should not
   * be used with session_id. If session_id and conversation_id are present, session_id
   * will be ignored.
   *
   * @generated from field: string conversation_id = 10;
   */
  conversationId = '';

  /**
   * optional. Used to provide extra information about the instructions that are being
   * executed. This is intended to make everything work on the Context Engine test page.
   * without this, we won't be able to save the original user query with the interaction.
   *
   * @generated from field: string user_query = 11;
   */
  userQuery = '';

  /**
   * optional. Disable use of the cached instructions during codegen
   *
   * @generated from field: bool disable_cache = 12;
   */
  disableCache = false;

  constructor(data?: PartialMessage<ExecuteDSLInstructionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecuteDSLInstructionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'instructions_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'instructions',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 3, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'session_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'ui_response', kind: 'message', T: UserInteractionResponse },
    { no: 9, name: 'client_context', kind: 'message', T: ClientContext },
    {
      no: 10,
      name: 'conversation_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 11,
      name: 'user_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 12,
      name: 'disable_cache',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecuteDSLInstructionsRequest {
    return new ExecuteDSLInstructionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsRequest {
    return new ExecuteDSLInstructionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsRequest {
    return new ExecuteDSLInstructionsRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ExecuteDSLInstructionsRequest
      | PlainMessage<ExecuteDSLInstructionsRequest>
      | undefined,
    b:
      | ExecuteDSLInstructionsRequest
      | PlainMessage<ExecuteDSLInstructionsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecuteDSLInstructionsRequest, a, b);
  }
}

/**
 * @generated from message context_engine.ExecuteDSLInstructionsResult
 */
export class ExecuteDSLInstructionsResult extends Message<ExecuteDSLInstructionsResult> {
  /**
   * @generated from field: repeated context_engine.ExecutionStep execution_steps = 1;
   */
  executionSteps: ExecutionStep[] = [];

  /**
   * @generated from field: string session_id = 2;
   */
  sessionId = '';

  /**
   * @generated from field: context_engine.UserInteractionRequest ui_request = 3;
   */
  uiRequest?: UserInteractionRequest;

  /**
   * @generated from field: string gend_code = 4;
   */
  gendCode = '';

  /**
   * @generated from field: string gend_inlined_code = 5;
   */
  gendInlinedCode = '';

  /**
   * @generated from field: string conversation_id = 6;
   */
  conversationId = '';

  /**
   * @generated from field: context_engine.ExecuteDSLInstructionsDebugInfo debug_info = 7;
   */
  debugInfo?: ExecuteDSLInstructionsDebugInfo;

  constructor(data?: PartialMessage<ExecuteDSLInstructionsResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecuteDSLInstructionsResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'execution_steps',
      kind: 'message',
      T: ExecutionStep,
      repeated: true,
    },
    { no: 2, name: 'session_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'ui_request', kind: 'message', T: UserInteractionRequest },
    { no: 4, name: 'gend_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 5,
      name: 'gend_inlined_code',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 6,
      name: 'conversation_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 7,
      name: 'debug_info',
      kind: 'message',
      T: ExecuteDSLInstructionsDebugInfo,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecuteDSLInstructionsResult {
    return new ExecuteDSLInstructionsResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsResult {
    return new ExecuteDSLInstructionsResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsResult {
    return new ExecuteDSLInstructionsResult().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ExecuteDSLInstructionsResult
      | PlainMessage<ExecuteDSLInstructionsResult>
      | undefined,
    b:
      | ExecuteDSLInstructionsResult
      | PlainMessage<ExecuteDSLInstructionsResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecuteDSLInstructionsResult, a, b);
  }
}

/**
 * @generated from message context_engine.GetScoreForQueryAgentCompatibilityRequest
 */
export class GetScoreForQueryAgentCompatibilityRequest extends Message<GetScoreForQueryAgentCompatibilityRequest> {
  /**
   * the user query to be scored
   *
   * @generated from field: string query = 1;
   */
  query = '';

  /**
   * name of the agent
   *
   * @generated from field: string agent_id = 2;
   */
  agentId = '';

  /**
   * a string that identifies the source of the request (e.g. "dash", "test")
   *
   * @generated from field: string source = 3;
   */
  source = '';

  /**
   * set to true to log detailed debug info in exclog
   *
   * @generated from field: bool debug = 4;
   */
  debug = false;

  constructor(
    data?: PartialMessage<GetScoreForQueryAgentCompatibilityRequest>,
  ) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName =
    'context_engine.GetScoreForQueryAgentCompatibilityRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetScoreForQueryAgentCompatibilityRequest {
    return new GetScoreForQueryAgentCompatibilityRequest().fromBinary(
      bytes,
      options,
    );
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetScoreForQueryAgentCompatibilityRequest {
    return new GetScoreForQueryAgentCompatibilityRequest().fromJson(
      jsonValue,
      options,
    );
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetScoreForQueryAgentCompatibilityRequest {
    return new GetScoreForQueryAgentCompatibilityRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GetScoreForQueryAgentCompatibilityRequest
      | PlainMessage<GetScoreForQueryAgentCompatibilityRequest>
      | undefined,
    b:
      | GetScoreForQueryAgentCompatibilityRequest
      | PlainMessage<GetScoreForQueryAgentCompatibilityRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetScoreForQueryAgentCompatibilityRequest, a, b);
  }
}

/**
 * @generated from message context_engine.GetScoreForQueryAgentCompatibilityResult
 */
export class GetScoreForQueryAgentCompatibilityResult extends Message<GetScoreForQueryAgentCompatibilityResult> {
  /**
   * the lof decision function score for the query-agent pair: higher score means higher compatibility ranges from -inf to +inf, recommended threshold 0.01
   *
   * @generated from field: float score = 1;
   */
  score = 0;

  constructor(data?: PartialMessage<GetScoreForQueryAgentCompatibilityResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName =
    'context_engine.GetScoreForQueryAgentCompatibilityResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'score', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetScoreForQueryAgentCompatibilityResult {
    return new GetScoreForQueryAgentCompatibilityResult().fromBinary(
      bytes,
      options,
    );
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetScoreForQueryAgentCompatibilityResult {
    return new GetScoreForQueryAgentCompatibilityResult().fromJson(
      jsonValue,
      options,
    );
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetScoreForQueryAgentCompatibilityResult {
    return new GetScoreForQueryAgentCompatibilityResult().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GetScoreForQueryAgentCompatibilityResult
      | PlainMessage<GetScoreForQueryAgentCompatibilityResult>
      | undefined,
    b:
      | GetScoreForQueryAgentCompatibilityResult
      | PlainMessage<GetScoreForQueryAgentCompatibilityResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetScoreForQueryAgentCompatibilityResult, a, b);
  }
}

/**
 * @generated from message context_engine.GetScoreForQueryAgentCompatibilityError
 */
export class GetScoreForQueryAgentCompatibilityError extends Message<GetScoreForQueryAgentCompatibilityError> {
  /**
   * @generated from oneof context_engine.GetScoreForQueryAgentCompatibilityError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_query = 1;
         */
        value: Empty;
        case: 'missingQuery';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 2;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 3;
         */
        value: Empty;
        case: 'missingSource';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetScoreForQueryAgentCompatibilityError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName =
    'context_engine.GetScoreForQueryAgentCompatibilityError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'missing_query', kind: 'message', T: Empty, oneof: 'err' },
    { no: 2, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetScoreForQueryAgentCompatibilityError {
    return new GetScoreForQueryAgentCompatibilityError().fromBinary(
      bytes,
      options,
    );
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetScoreForQueryAgentCompatibilityError {
    return new GetScoreForQueryAgentCompatibilityError().fromJson(
      jsonValue,
      options,
    );
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetScoreForQueryAgentCompatibilityError {
    return new GetScoreForQueryAgentCompatibilityError().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GetScoreForQueryAgentCompatibilityError
      | PlainMessage<GetScoreForQueryAgentCompatibilityError>
      | undefined,
    b:
      | GetScoreForQueryAgentCompatibilityError
      | PlainMessage<GetScoreForQueryAgentCompatibilityError>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetScoreForQueryAgentCompatibilityError, a, b);
  }
}

/**
 * @generated from message context_engine.ExecuteDSLInstructionsError
 */
export class ExecuteDSLInstructionsError extends Message<ExecuteDSLInstructionsError> {
  /**
   * @generated from oneof context_engine.ExecuteDSLInstructionsError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_instructions_id = 1;
         */
        value: Empty;
        case: 'missingInstructionsId';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty instructions_not_found = 2;
         */
        value: Empty;
        case: 'instructionsNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 3;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 4;
         */
        value: Empty;
        case: 'missingSource';
      }
    | {
        /**
         * @generated from field: context_engine.ExecutionEngineException execution_engine_exception = 5;
         */
        value: ExecutionEngineException;
        case: 'executionEngineException';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 6;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ExecuteDSLInstructionsError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExecuteDSLInstructionsError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_instructions_id',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    {
      no: 2,
      name: 'instructions_not_found',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 3, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
    { no: 4, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 5,
      name: 'execution_engine_exception',
      kind: 'message',
      T: ExecutionEngineException,
      oneof: 'err',
    },
    { no: 6, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExecuteDSLInstructionsError {
    return new ExecuteDSLInstructionsError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsError {
    return new ExecuteDSLInstructionsError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExecuteDSLInstructionsError {
    return new ExecuteDSLInstructionsError().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ExecuteDSLInstructionsError
      | PlainMessage<ExecuteDSLInstructionsError>
      | undefined,
    b:
      | ExecuteDSLInstructionsError
      | PlainMessage<ExecuteDSLInstructionsError>
      | undefined,
  ): boolean {
    return proto3.util.equals(ExecuteDSLInstructionsError, a, b);
  }
}

/**
 * @generated from message context_engine.GenerateDSLInstructionsError
 */
export class GenerateDSLInstructionsError extends Message<GenerateDSLInstructionsError> {
  /**
   * @generated from oneof context_engine.GenerateDSLInstructionsError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_user_query = 1;
         */
        value: Empty;
        case: 'missingUserQuery';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 2;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty not_admin = 3;
         */
        value: Empty;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 4;
         */
        value: Empty;
        case: 'missingSource';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty invalid_variant = 5;
         */
        value: Empty;
        case: 'invalidVariant';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty invalid_interp_session = 6;
         */
        value: Empty;
        case: 'invalidInterpSession';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GenerateDSLInstructionsError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GenerateDSLInstructionsError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_user_query',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 2, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'not_admin', kind: 'message', T: Empty, oneof: 'err' },
    { no: 4, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
    { no: 5, name: 'invalid_variant', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 6,
      name: 'invalid_interp_session',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GenerateDSLInstructionsError {
    return new GenerateDSLInstructionsError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GenerateDSLInstructionsError {
    return new GenerateDSLInstructionsError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GenerateDSLInstructionsError {
    return new GenerateDSLInstructionsError().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GenerateDSLInstructionsError
      | PlainMessage<GenerateDSLInstructionsError>
      | undefined,
    b:
      | GenerateDSLInstructionsError
      | PlainMessage<GenerateDSLInstructionsError>
      | undefined,
  ): boolean {
    return proto3.util.equals(GenerateDSLInstructionsError, a, b);
  }
}
