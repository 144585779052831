import { Text } from '@dropbox/dig-components/typography';
import styles from './ConversationMessageLoadingText.module.css';

export interface ConversationMessageLoadingTextProps {
  text: string;
  size?: 'medium' | 'large';
}
export const ConversationMessageLoadingText = ({
  text,
  size = 'large',
}: ConversationMessageLoadingTextProps) => {
  return (
    <div className={styles.ConversationMessageLoadingText}>
      <Text size={size} tagName="div">
        {text}
      </Text>
      <div className={styles.loader}></div>
      <div className={styles.loader}></div>
      <div className={styles.loader}></div>
    </div>
  );
};
