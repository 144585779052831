import {
  GlobeLine,
  LockLine,
  StacksFill,
  TeamLine,
} from '@dropbox/dig-icons/assets';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { ListItemSize } from '@mirage/link-list/types';
import { fetchSearchResults } from '@mirage/service-dbx-api';
import {
  isStackItemShortcut,
  stackItemComputeNameFromFields,
} from '@mirage/service-stacks/service/utils';
import { CancelationError } from '@mirage/shared/util/cancellable';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { isDefined, nonNil } from '@mirage/shared/util/tiny-utils';
import * as React from 'react';
import { StackAccessLevel } from '../ShareModal/Types';

import type { TabSuggestion } from '../AddStackItemMenuContent/types';
import type { LinkWithMLScore } from '../types';
import type { dash_ml, stacks } from '@dropbox/api-v2-client';
import type { DashSuggestionSource } from '@mirage/analytics/events/enums/dash_suggestion_source';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { SVGProps } from 'react';

export const EMOJI_CLEARED_SENTINEL_VALUE = 'emoji_cleared';
export const DEFAULT_STACK_NAME = 'DROPBOX_DEFAULT_STACK';

export const ALL_STACKS_GRID_TWO_COLUMN_BREAKPOINT = 648;
export const ALL_STACKS_GRID_THREE_COLUMN_BREAKPOINT = 951;
export const ALL_STACKS_GRID_TWO_COLUMN_WIDTH = 600;

export const stackIsPinned = (stack: stacks.Stack): boolean => {
  return stack.user_data?.is_pinned || false;
};

export const stackGetName = (stack: stacks.Stack): string => {
  return nonNil(stack.stack_data?.name, 'stack_data.name');
};

export const stackGetNamespaceId = (stack: stacks.Stack): string => {
  return nonNil(stack.namespace_id, 'namespace_id');
};

export const stackGetEmoji = (
  stackOrEmoji: stacks.Stack | string | undefined,
) => {
  if (!stackOrEmoji) return undefined;
  const emoji =
    typeof stackOrEmoji === 'string'
      ? stackOrEmoji
      : stackOrEmoji.stack_data?.emoji;
  return emoji && emoji !== EMOJI_CLEARED_SENTINEL_VALUE ? emoji : '';
};

export const stackGetFavicon = (
  stackOrEmoji: stacks.Stack | string | undefined,
) => {
  const emoji = stackGetEmoji(stackOrEmoji);
  return emoji ? () => <span data-icontype="emoji">{emoji}</span> : StacksFill;
};

export const stackHasWritePermissions = (stack: stacks.Stack): boolean => {
  const tag = stack?.permission?.['.tag'];
  return tag === 'owner' || tag === 'write';
};

export const isStackOwner = (stack: stacks.Stack): boolean => {
  const tag = stack?.permission?.['.tag'];
  return tag === 'owner';
};

export const isStackShared = (stack: stacks.Stack): boolean => {
  return (
    (stack.sharing_data?.members?.length || 0) > 0 ||
    stack.sharing_data?.shared_link?.url !== undefined
  );
};

export const stackIsDefault = (stack: stacks.Stack): boolean => {
  return stack?.stack_data?.name === DEFAULT_STACK_NAME;
};

export const isStackItemWithUrl = (
  item: stacks.StackItemShortcut,
): item is stacks.StackItemShortcut & { url: string } => {
  return !!item.url;
};

export const suggestedStackToLinkWithMLScore = (
  suggestedStack: stacks.AutoStackSuggestion,
): LinkWithMLScore[] => {
  // create a mapping from item name to item hash id
  const titleToItemHashId: Record<string, number> =
    suggestedStack.content_items?.reduce<Record<string, number>>(
      (acc, item) => {
        if (item.title && item.item_id_hash) {
          acc[item.title] = item.item_id_hash;
        }
        return acc;
      },
      {},
    ) || {};

  return (
    suggestedStack.items
      ?.filter(isStackItemShortcut)
      .map((item) => {
        const computedName = stackItemComputeNameFromFields(item);
        if (computedName && item.url) {
          // Autostacks does not have item level scores
          return {
            url: item.url,
            title: computedName,
            score: 0,
            itemIdHash: titleToItemHashId?.[computedName],
            predictionIdHash: suggestedStack.prediction_id_hash,
            dashSuggestionSource: 'content_suggestion' as DashSuggestionSource,
            // stacks.getAutoStackSuggestions does not return requestId
            dashRequestId: '',
          };
        }
        return undefined;
      })
      .filter(isDefined) || []
  );
};

export const contentSuggestionToLinkWithMLScore = (
  suggestion: dash_ml.SuggestedContent,
  dashRequestId?: string,
): LinkWithMLScore & { title: string } => {
  const url = suggestion.content?.url || '';
  return {
    url,
    title: suggestion.content?.title || '',
    itemIdHash: suggestion.content?.item_id_hash,
    predictionIdHash: suggestion.prediction_id_hash,
    predictionId: suggestion.prediction_id,
    rank: suggestion.rank,
    score: suggestion.score ?? 0,
    dashSuggestionSource: 'content_suggestion',
    dashRequestId,
  };
};

export const membersFromStack = (stack: stacks.Stack) => {
  const members = stack.sharing_data?.members?.map(
    (member: stacks.StackSharingMember) => {
      const subject: stacks.StackSharingSubject | undefined = member.subject;
      if (
        subject?.['.tag'] === 'user' &&
        subject.email &&
        subject.display_name
      ) {
        return {
          email: subject.email,
          display_name: subject.display_name,
          profile_photo_url: subject.profile_photo_url,
        } as stacks.StackSharingUser;
      }

      if (subject?.['.tag'] === 'unclaimed_email' && subject.unclaimed_email) {
        return {
          '.tag': 'unclaimed_email',
          unclaimed_email: subject.unclaimed_email,
        } as stacks.StackSharingSubjectUnclaimedEmail;
      }

      if (subject?.['.tag'] === 'group' && subject.display_name) {
        return {
          display_name: subject.display_name,
        } as stacks.StackSharingGroup;
      }
    },
  );
  return members?.filter(isDefined) || [];
};

export const iconSrcForAccessLevel = (
  accessLevel: StackAccessLevel,
  isPublicSharingAllowed?: boolean,
): ((props: SVGProps<SVGSVGElement>) => JSX.Element) => {
  let accessLevelToCheck = accessLevel;
  if (
    accessLevelToCheck === StackAccessLevel.PUBLIC &&
    !isPublicSharingAllowed
  ) {
    accessLevelToCheck = StackAccessLevel.TEAM;
  }

  switch (accessLevel) {
    case StackAccessLevel.PUBLIC:
      return GlobeLine;
    case StackAccessLevel.INVITED:
      return LockLine;
    case StackAccessLevel.TEAM:
      return TeamLine;
  }
};

export const stackIsArchived = (stack: stacks.Stack | null): boolean => {
  return stack?.stack_data?.archive_data?.is_archived || false;
};

export const stackIsNotArchived = (stack: stacks.Stack | null): boolean => {
  return !stackIsArchived(stack);
};

export const stackIsMatch = (
  stack: stacks.Stack | null,
  inputString: string,
) => {
  if (!inputString || !stack) {
    return false;
  }
  return (
    stack.stack_data?.name?.toLowerCase().includes(inputString.toLowerCase()) ||
    false
  );
};

export const stackIsPublished = (
  stack: stacks.Stack,
  contents: stacks.PublishedContent[],
) => {
  const isPublished = contents.find(
    (c) => c['.tag'] === 'stack' && c.namespace_id === stack.namespace_id,
  );
  return !!isPublished;
};

export const fetchSearchResultsFromAPI = async (
  inputString: string,
  isDarkMode: boolean = false,
): Promise<TabSuggestion[]> => {
  const trimmedInput = inputString.trim().toLocaleLowerCase();

  if (!trimmedInput?.length) return [];

  let searchResults: SearchResult[] | undefined;

  try {
    ({ results: searchResults } = await fetchSearchResults(
      trimmedInput,
      [],
      false,
    ));
  } catch (e) {
    if (e instanceof CancelationError) {
      return [];
    }
    throw e;
  }

  return (searchResults || []).map((item) => ({
    title: item.title,
    url: item.url ?? '',
    score: item.score ?? 0,
    icon: (
      <FavIcon
        src={faviconSrcForSrcUrl(item.url ?? '', 32, isDarkMode)}
        size={ListItemSize.Small}
      />
    ),
    connectorDisplayName: item.connectorInfo?.displayName,
    id3p: item.id3p ?? undefined,
    brandedType: item.brandedType ?? undefined,
  }));
};

export const urlExistsInList = (url: string, urlList: string[]): boolean => {
  // Ignores query parameters (?param=1 vs ?param=2 → still equal).
  // Ignores ports (https://example.com:8080 vs https://example.com → still equal).
  // Ignores trailing slashes (/path/ vs /path → still equal).
  // Ignores case sensitivity in hostname (EXAMPLE.COM vs example.com → still equal).
  // Maintains strict matching on protocol (http:// vs https://).

  try {
    const normalizeUrl = (url: string) => {
      const parsed = new URL(url);
      return {
        protocol: parsed.protocol,
        hostname: parsed.hostname.toLowerCase(), // Ignore case sensitivity
        pathname: parsed.pathname.replace(/\/+$/, ''), // Remove trailing slashes
      };
    };

    const targetNormalized = normalizeUrl(url);

    return urlList.some((url) => {
      try {
        const normalized = normalizeUrl(url);
        return (
          targetNormalized.protocol === normalized.protocol &&
          targetNormalized.hostname === normalized.hostname &&
          targetNormalized.pathname === normalized.pathname
        );
      } catch {
        return false; // Skip invalid URLs in the list
      }
    });
  } catch (error) {
    return false;
  }
};
