import { IconButton } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { DashWordmark, WordmarkLogo } from '@dropbox/dig-logos';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashOmniboxSearchPrompt } from '@mirage/analytics/events/types/dismiss_dash_omnibox_search_prompt';
import { PAP_Shown_DashOmniboxSearchPrompt } from '@mirage/analytics/events/types/shown_dash_omnibox_search_prompt';
import { useShouldShowWelcomeModal } from '@mirage/growth/onboarding/hooks/useShouldShowWelcomeModal';
import { useDismissableModule } from '@mirage/service-onboarding/useDismissableModule';
import { isInsideExtension } from '@mirage/shared/util/webExtensionsHelpers';
import i18n from '@mirage/translations';
import { useEffect } from 'react';
import RawOmniboxSearchGif from './omniboxSearch.gif';
import styles from './OmniboxSearchPrompt.module.css';

const DISMISSED_OMNIBOX_SEARCH_PROMPT = 'dismissedOmniboxSearchPrompt';

export const OmniboxSearchPrompt = () => {
  const isWelcomeModalShowing = useShouldShowWelcomeModal(1);
  const { dismissed, setDismissed } = useDismissableModule(
    DISMISSED_OMNIBOX_SEARCH_PROMPT,
  );
  const isExtension = isInsideExtension();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const shouldShowPrompt =
    // Prevent collision with welcome modal.
    // TODO: Growth team (maybe ryankara) will create reusable system to prevent collisions
    !isWelcomeModalShowing &&
    // If this has already previously been dismissed
    !dismissed &&
    // We only want to show this when user is inside extension (ideally new tab page)
    isExtension;

  useEffect(() => {
    if (shouldShowPrompt) {
      reportPapEvent(PAP_Shown_DashOmniboxSearchPrompt({}));
    }
  }, [shouldShowPrompt, reportPapEvent]);

  if (!shouldShowPrompt) return null;

  const handleClose = async () => {
    setDismissed(true);
    reportPapEvent(PAP_Dismiss_DashOmniboxSearchPrompt({}));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <WordmarkLogo size={32} src={DashWordmark} />
        <IconButton variant="transparent" onClick={handleClose}>
          <UIIcon src={CloseLine} />
        </IconButton>
      </div>
      <img
        src={RawOmniboxSearchGif}
        alt={i18n.t('omnibox_search_prompt_gif_alt')}
        className={styles.image}
      />

      <Title size="small">{i18n.t('omnibox_search_prompt_title')}</Title>
      <div>
        <Text color="faint">{i18n.t('omnibox_search_prompt_subtitle')}</Text>
      </div>
    </div>
  );
};
