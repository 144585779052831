import { Metadata } from '@dropbox/dash-component-library';
import { LabelGroup } from '@dropbox/dig-components/label_group';
import { Text } from '@dropbox/dig-components/typography';
import { Highlighter } from '@mirage/search/SearchResults/Highlighter';
import { isEmpty } from 'lodash';

import type { ReactNode } from 'react';

export type ResultRowProps = {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  currentQuery: string;
};
export const ResultRow = ({
  icon,
  title,
  subtitle,
  currentQuery,
}: ResultRowProps) => {
  const withLabelNode = !isEmpty(subtitle) ? (
    <Metadata>
      <Metadata.Item>
        <Text color={'standard'} size={'medium'}>
          <Highlighter
            title={title}
            highlightWords={currentQuery ? [currentQuery] : []}
          />
        </Text>
      </Metadata.Item>
      <Metadata.Item>
        <Text size={'medium'} color={'subtle'}>
          {subtitle}
        </Text>
      </Metadata.Item>
    </Metadata>
  ) : (
    <Text size={'medium'}>
      <Highlighter
        title={title}
        highlightWords={currentQuery ? [currentQuery] : []}
      />
    </Text>
  );

  return (
    <LabelGroup
      withStartAccessory={icon}
      withLabel={withLabelNode}
      verticalAlignment={'center'}
      accessoryGap={'Micro Small'}
    />
  );
};
ResultRow.displayName = 'ResultsRow';
