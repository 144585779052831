import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import * as jobs from '@mirage/shared/util/jobs';
import { Subject } from 'rxjs';
import { SurveyEvent } from '../types';

import type { SprigPublishEvent, SurveyAttributes } from '../types';
import type { cmde, users } from '@dropbox/api-v2-client';
import type { DashSurface } from '@mirage/analytics/events/enums/dash_surface';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { Observable } from 'rxjs';

export type Service = ReturnType<typeof provideFeedbackService>;

interface AuthServiceContract {
  getCurrentAccount: () => Promise<users.FullAccount | undefined>;
}

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

const JOB_NAME = 'sprig/report-sprig-attributes';
const JOB_INTERVAL = 10 * ONE_MINUTE_IN_MILLIS;

// The Sprig SDK has made their WindowSprig type unassignable to SprigAPI

export default function provideFeedbackService(
  authService: AuthServiceContract,
  dbxApiService: DbxApiServiceContract,
  surface: DashSurface,
) {
  let anonymizedUserId: string | undefined;

  // Proxy subjects that are given to the sprig client, which will
  // emit events whenever a survey is presented or closed. This are exposed
  // via this service, so that consumers do not have to interact with the
  // sprig client directly.
  const surveyPresented$ = new Subject<void>();
  const surveyClosed$ = new Subject<void>();

  // This service will publish events to this subject, which the Sprig client will subscribe to and will actually send the events.
  const sprigPublishEvent$ = new Subject<SprigPublishEvent>();
  // This service will publish attributes to this subject, which the Sprig client will subscribe to and will actually send the attributes.
  const sprigAttributes$ = new Subject<SurveyAttributes>();

  async function getIsInternal(): Promise<boolean> {
    const account = await authService.getCurrentAccount();
    return account?.email?.endsWith('@dropbox.com') ?? false;
  }

  /**
   * Set attributes for the Sprig survey. Calling this function will
   * immediately send the given attributes to Sprig.
   * @param newAttributes - The attributes to set.
   */
  async function updateAttributes(
    newAttributes: SurveyAttributes,
  ): Promise<void> {
    // https://jira.dropboxer.net/browse/ASKSEC-11542
    // security approves to use public id for Sprig.
    // We send this userId in shown.dash_survey PAP events for correlation.
    const isInternal = await getIsInternal();
    sprigAttributes$.next({
      isInternal,
      dashSurface: surface,
      ...newAttributes,
    });
  }

  async function publishEvent(
    event: SurveyEvent,
    eventProperties?: SurveyAttributes,
    isCoreAction?: boolean,
  ): Promise<void> {
    sprigPublishEvent$.next({
      event,
      properties: {
        ...eventProperties,
      },
    });

    if (isCoreAction) {
      sprigPublishEvent$.next({
        event: SurveyEvent.CoreUserJourneyAction,
        properties: {
          ...eventProperties,
          action: event,
        },
      });
    }
  }

  function listenForSurveyPresented(): Observable<void> {
    return surveyPresented$.asObservable();
  }

  function listenForSurveyClosed(): Observable<void> {
    return surveyClosed$.asObservable();
  }

  function _listenForSprigPublishEvent(): Observable<SprigPublishEvent> {
    return sprigPublishEvent$.asObservable();
  }

  function _listenForSprigAttributes(): Observable<SurveyAttributes> {
    return sprigAttributes$.asObservable();
  }

  function _publishSurveyPresented(): void {
    surveyPresented$.next();
  }

  function _publishSurveyClosed(): void {
    surveyClosed$.next();
  }

  function convertSprigAttributeListToSurveyAttributes(
    attributeList: cmde.SprigAttributeList,
  ): SurveyAttributes {
    return (
      attributeList.attributes?.reduce((acc, attr) => {
        const nestedValue = attr.value?.value;
        if (
          nestedValue &&
          typeof nestedValue === 'object' &&
          '.tag' in nestedValue
        ) {
          if (
            nestedValue['.tag'] === 'bool_value' &&
            'bool_value' in nestedValue
          ) {
            acc[attr.name ?? ''] = nestedValue.bool_value;
          } else if (
            nestedValue['.tag'] === 'string_value' &&
            'string_value' in nestedValue
          ) {
            acc[attr.name ?? ''] = nestedValue.string_value;
          } else if (
            nestedValue['.tag'] === 'int_value' &&
            'int_value' in nestedValue
          ) {
            acc[attr.name ?? ''] = nestedValue.int_value;
          }
        }
        return acc;
      }, {} as SurveyAttributes) || {}
    );
  }

  async function reportSprigAttributes() {
    // Get attributes from survey attributes endpoint
    let attributes: SurveyAttributes = {};
    if (!anonymizedUserId) {
      // Survey ID should not change for user, so only fetch once
      const surveyUserAttributes = await dbxApiService.callApiV2(
        'userSurveyGetUserAttributes',
        undefined,
      );
      anonymizedUserId = surveyUserAttributes.user_survey_id;
    }
    attributes = { publicUserId: anonymizedUserId ?? '' };

    // Get segment based usage attributes
    const serverSideProperties = await dbxApiService.callApiV2(
      'cmdeGetSprigAttributeList',
      {},
    );
    const attributeList = serverSideProperties.sprig_attribute_list;
    if (attributeList) {
      attributes = {
        ...attributes,
        ...convertSprigAttributeListToSurveyAttributes(attributeList),
      };
    }
    // Call update regardless of return from APIs
    await updateAttributes(attributes);
  }

  function startReportSprigAttributesJob() {
    jobs.register(
      JOB_NAME,
      JOB_INTERVAL,
      /* runOnStart= */ true,
      reportSprigAttributes,
    );
  }

  function cancelReportSprigAttributesJob() {
    jobs.unregister(JOB_NAME);
  }

  return services.provide(
    ServiceId.FEEDBACK,
    {
      updateAttributes,
      publishEvent,
      listenForSurveyPresented,
      listenForSurveyClosed,
      startReportSprigAttributesJob,
      cancelReportSprigAttributesJob,
      _listenForSprigPublishEvent,
      _listenForSprigAttributes,
      _publishSurveyPresented,
      _publishSurveyClosed,
    },
    [ServiceId.DASH_AUTH, ServiceId.DBX_API],
  );
}
