import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PAP_Interact_TextSelectionMenu } from '@mirage/analytics/events/types/interact_text_selection_menu';
import {
  $getSelection,
  $isRangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import debounce from 'lodash/debounce';
import { memo, useEffect, useRef } from 'react';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';

/**
 * This plugin captures cursor text selection events in the compose editor pane
 */
interface SelectionLoggerPluginProps {
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ComposeSelectionLoggerPlugin = memo(
  ({ logComposeEvent }: SelectionLoggerPluginProps) => {
    const [editor] = useLexicalComposerContext();
    const latestSelectedText = useRef('');
    const debouncedLogEvent = debounce((logComposeEvent, selectedText) => {
      logComposeEvent(
        PAP_Interact_TextSelectionMenu({
          queryString: selectedText,
        }),
      );
    }, 1000);
    useEffect(() => {
      const unregister = editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const selectedText = selection.getTextContent();
            if (
              selectedText.length >= 25 &&
              selectedText !== latestSelectedText.current
            ) {
              latestSelectedText.current = selectedText;
              debouncedLogEvent(logComposeEvent, selectedText);
            }
          }
          return false;
        },
        1,
      );
      return () => {
        unregister();
        debouncedLogEvent.cancel();
      };
    }, [editor, logComposeEvent, debouncedLogEvent]);
    return null;
  },
);
ComposeSelectionLoggerPlugin.displayName = 'ComposeSelectionLoggerPlugin';
