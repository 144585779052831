import { tagged } from '@mirage/service-logging';

type ApiOp<T> = {
  fn: (arg: T) => Promise<T>;
  resolve: (value: T) => void;
  reject: (reason: unknown) => void;
};

const logger = tagged('ApiOpQueue');

/**
 * Op queue designed to handle concurrent save requests against the Assist KV API.
 *
 * This is a simple queue that ensures that we wait for any ongoing save requests to complete before
 * processing a new one. This is important to prevent race conditions where multiple save requests
 * are sent concurrently, creating duplicate items in the API.
 */
export class ApiOpQueue<T> {
  private queuedOp: ApiOp<T> | undefined;
  private ongoingOp: ApiOp<T> | undefined;

  constructor(private currentValue: T) {}

  /**
   * Add an operation to the queue.
   * If there is an ongoing operation, it will be queued up. If there is already a queued operation,
   * it will be replaced.
   *
   * @param op - The operation to add to the queue.
   * @returns A promise that resolves to the result of the operation. Note that the promise may never
   * resolve if the operation is replaced by a new op.
   */
  public addOp(fn: (arg: T) => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const op = { fn, resolve, reject };

      if (this.ongoingOp) {
        // queue this as the next op to run (may replace an existing queued op)
        this.queuedOp = op;
      } else {
        // start the op immediately
        this.ongoingOp = op;
        this.runOp();
      }
    });
  }

  // run the currently ongoing op + handle firing the next op in the queue when done
  private async runOp() {
    if (!this.ongoingOp) {
      throw new Error('no ongoing op');
    }

    const ongoingOp = this.ongoingOp;
    try {
      const result = await ongoingOp.fn(this.currentValue);
      // Only update currentValue and resolve if this op is still the ongoing op
      if (this.ongoingOp === ongoingOp) {
        this.currentValue = result;
        ongoingOp.resolve(result);
      } else {
        logger.log('queue has been stopped, skipping result');
      }
    } catch (error) {
      logger.error('error handling API op', error);
      ongoingOp.reject(error);
    } finally {
      // Only continue processing queue if this op is still the ongoing op
      if (this.ongoingOp === ongoingOp) {
        this.ongoingOp = undefined;
        if (this.queuedOp) {
          const nextOp = this.queuedOp;
          this.queuedOp = undefined;
          this.ongoingOp = nextOp;
          this.runOp();
        }
      } else {
        logger.log('queue has been stopped, skipping cleanup');
      }
    }
  }

  /**
   * Stop the ongoing op and clear the queue.
   */
  public stop() {
    this.ongoingOp = undefined;
    this.queuedOp = undefined;
  }
}
