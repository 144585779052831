import classnames from 'classnames';
import React, { useState } from 'react';
import { StackDescriptionEditor } from '../EditStackDetails';
import styles from './Header.module.css';

import type { StackDerivedPAPPropsWithFeature } from '../types';
import type { UpdateStackNameAndDescription } from './HeaderCommon';

interface StackDescriptionRowProps {
  stackDescription: string;
  isNewStack: boolean;
  updateStackNameAndDescription: UpdateStackNameAndDescription;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  canEdit?: boolean;
}

const StackDescriptionRow: React.FC<StackDescriptionRowProps> = ({
  stackDescription,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  canEdit,
}) => {
  const [isEditingStackName, setIsEditingStackName] = useState(false);

  return (
    // Hide description on view only stacks with no description
    ((canEdit || stackDescription) && (
      <div
        className={classnames({
          [styles.stackDescriptionContainerEdit]: isEditingStackName,
        })}
      >
        <StackDescriptionEditor
          stackDescription={stackDescription}
          isNewStack={isNewStack}
          updateStackNameAndDescription={updateStackNameAndDescription}
          stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
          hasWritePermissionsOverride={canEdit}
          onEditStatusUpdate={setIsEditingStackName}
        />
      </div>
    )) ||
    null
  );
};

export default StackDescriptionRow;
