import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { StackItemCreationFields } from './types';
import type { stacks, version_tree } from '@dropbox/api-v2-client/types';
import type {
  Service,
  StackMutationEvent,
  StacksCompleteEvent,
  StackUpsertId,
} from '@mirage/service-stacks/service';

export { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';

export const service = services.get<Service>(ServiceId.STACKS);

export function getStacks() {
  return rx.firstValueFrom(service.getStacks());
}

export function areStacksReady(): Promise<boolean> {
  return rx.firstValueFrom(service.areStacksReady());
}

export function areStacksComplete(): Promise<boolean> {
  return rx.firstValueFrom(service.areStacksComplete());
}

export function subscribeToStacksCompleteUpdates(
  callback: (event: StacksCompleteEvent) => void | Promise<void>,
) {
  return service.stacksCompleteObservable().subscribe(callback);
}

export function stacksCompleteObservable() {
  return service.stacksCompleteObservable();
}

export function listStacksIncrementally(
  refresh = false,
): Promise<stacks.Stack[]> {
  return rx.firstValueFrom(service.listStacksIncrementally(refresh));
}

export function queryFullStacksById(args: {
  namespaceIds: string[];
  hlc?: version_tree.Hlc;
}): Promise<stacks.QueryStacksResponse> {
  return rx.firstValueFrom(service.queryFullStacksById(args));
}

export function listStackItems(
  namespaceId: string,
  refresh = false,
): Promise<{ items: stacks.StackItem[]; cached: boolean }> {
  return rx.firstValueFrom(service.listStackItems(namespaceId, refresh));
}

export function listStackItemsFromCache(
  namespaceId: string,
): Promise<stacks.StackItem[] | undefined> {
  return rx.firstValueFrom(service.listStackItemsFromCache(namespaceId));
}

export function listAllStackItemsFromCache(): Promise<
  { [nsid: string]: stacks.StackItem[] } | undefined
> {
  return rx.firstValueFrom(service.listAllStackItemsFromCache());
}

export function listAllStackItemsOneByOne(): Promise<void> {
  return rx.firstValueFrom(service.listAllStackItemsOneByOne());
}

export function previewPublicStack(
  shareId: string,
  options?: {
    onError?: (e: Error) => void;
    hlcMicros?: number;
  },
): Promise<{ stack: stacks.Stack; items: stacks.StackItem[] }> {
  return rx.firstValueFrom(service.previewPublicStack(shareId, options));
}

export function previewStack(
  shareId: string,
  options?: {
    refresh?: boolean;
    fetchInBackground?: boolean;
    onError?: (e: Error) => void;
    hlcMicros?: number;
  },
): Promise<{ stack: stacks.Stack; items: stacks.StackItem[] }> {
  return rx.firstValueFrom(service.previewStack(shareId, options));
}

export function createStack(
  name: string,
  description: string,
  emoji?: string,
  colorIndex?: number,
  isPinned?: boolean,
  creationTypeTag?: stacks.StackCreationType['.tag'],
): Promise<stacks.Stack> {
  return rx.firstValueFrom(
    service.createStack(
      name,
      description,
      emoji,
      colorIndex,
      isPinned,
      creationTypeTag,
    ),
  );
}

export function updateStack(
  namespaceId: string,
  updates: stacks.StackDataUpdate[],
): Promise<stacks.UpdateStackResponse> {
  return rx.firstValueFrom(service.updateStack(namespaceId, updates));
}

export function upsertStack(
  upsertData: StackUpsertId,
  updates: stacks.StackDataUpdate[],
): Promise<stacks.UpdateStackResponse> {
  return rx.firstValueFrom(service.upsertStack(upsertData, updates));
}

export function toggleStackIsPinned(
  namespaceId: string,
): Promise<stacks.PinStackResponse> {
  return rx.firstValueFrom(service.toggleStackIsPinned(namespaceId));
}

export function sortPinnedStack(
  namespaceId: string,
  sortKey: string,
): Promise<stacks.PinStackResponse> {
  return rx.firstValueFrom(service.sortPinnedStack(namespaceId, sortKey));
}

export function upsertToggleStackIsPinned(
  upsertData: StackUpsertId,
): Promise<stacks.PinStackResponse & { nsId?: string }> {
  return rx.firstValueFrom(service.upsertToggleStackIsPinned(upsertData));
}

export function deleteStack(
  namespaceId: string,
): Promise<stacks.DeleteStacksResponse> {
  return rx.firstValueFrom(service.deleteStack(namespaceId));
}

export function leaveStack(
  namespaceId: string,
  email: string,
): Promise<stacks.RemoveStackSharingMembersResponse> {
  return rx.firstValueFrom(service.leaveStack(namespaceId, email));
}

export function createStackItem(
  namespaceId: string,
  url: string,
  metadataTitle?: string,
  sectionId?: string,
  sortKey?: string,
  brandedType?: string,
  id3p?: string,
): Promise<stacks.StackItem> {
  return rx.firstValueFrom(
    service.createStackItem(
      namespaceId,
      url,
      metadataTitle,
      sectionId,
      sortKey,
      brandedType,
      id3p,
    ),
  );
}

export function ensureStackAndCreateItem(
  upsertData: StackUpsertId,
  url: string,
  metadataTitle?: string,
  sectionId?: string,
  sortKey?: string,
  id3p?: string,
  brandedType?: string,
): Promise<stacks.StackItem & { nsId?: string }> {
  return rx.firstValueFrom(
    service.ensureStackAndCreateItem(
      upsertData,
      url,
      metadataTitle,
      sectionId,
      sortKey,
      id3p,
      brandedType,
    ),
  );
}

export function createStackItemsBatch(
  namespaceId: string,
  items: StackItemCreationFields[],
): Promise<void> {
  return rx.firstValueFrom(service.createStackItemsBatch(namespaceId, items));
}

export function updateStackItem<T extends stacks.StackItem>(
  namespaceId: string,
  stackItem: T,
  updateLastModifiedTime?: boolean,
): Promise<stacks.UpdateStackResponse | undefined> {
  return rx.firstValueFrom(
    service.updateStackItem(namespaceId, stackItem, updateLastModifiedTime),
  );
}

export function deleteStackItem(
  namespaceId: string,
  fileId: string,
): Promise<stacks.DeleteStackItemResponse> {
  return rx.firstValueFrom(service.deleteStackItem(namespaceId, fileId));
}

export function createStackSection(
  namespaceId: string,
  sectionTitle: string,
): Promise<stacks.UpdateStackResponse> {
  return rx.firstValueFrom(
    service.createStackSection(namespaceId, sectionTitle),
  );
}

export function publishStack(
  sharingId: string,
): Promise<stacks.PublishStackResponse> {
  return rx.firstValueFrom(service.publishStack(sharingId));
}

export function unpublishStack(
  sharingId: string,
): Promise<stacks.UnpublishStackResponse> {
  return rx.firstValueFrom(service.unpublishStack(sharingId));
}

export function listPublishedContent(
  refresh = false,
  hlc?: version_tree.Hlc,
): Promise<stacks.PublishedContent[] | undefined> {
  return rx.firstValueFrom(service.listPublishedContent(hlc, refresh));
}

/**
 * Allows any code to listen to updates to the stack data. Note that this
 * function does not fetch any data. It will only detect changes to data
 * caused from other places in the code, and invoke the given callback
 * function for each change. Please remember to unsubscribe to avoid memory
 * and channel leaks.
 *
 * Example:
 *
 * useEffect(() => {
 *   const stacksSubscription = subscribeToStacksUpdates((stacks) => {
 *     // do something
 *   });
 *   return () => stacksSubscription.unsubscribe();
 * }, []);
 */
export function subscribeToStacksUpdates(
  callback: (stacks: stacks.Stack[]) => void | Promise<void>,
) {
  return service.stacksObservable().subscribe(callback);
}

/**
 * Subscribe to any stack items data updates. Each update is for all the stack
 * items for one stack.
 */
export function subscribeToStackItemsUpdates(
  callback: (data: {
    namespaceId: string;
    items: stacks.StackItem[];
  }) => void | Promise<void>,
) {
  return service.stackItemsObservable().subscribe(callback);
}

export function subscribeToPublishedContentsUpdates(
  callback: (contents: stacks.PublishedContent[]) => void | Promise<void>,
) {
  return service.stackPublishedContentsObservable().subscribe(callback);
}

/**
 * Allows any code to listen to updates on stack edit progress.
 * This will include both temporary edits and final stack creation updates.
 * Remember to unsubscribe to avoid memory and channel leaks.
 *
 * Example usage:
 *
 * useEffect(() => {
 *   const mutationSubscription = subscribeToStackMutation((event) => {
 *     // Handle the event, e.g., update UI based on event.type
 *   });
 *   return () => mutationSubscription.unsubscribe();
 * }, []);
 */
export function subscribeToStackMutation(
  callback: (event: StackMutationEvent) => void,
) {
  return service.stackMutationObservable().subscribe(callback);
}

export function tearDown() {
  return rx.firstValueFrom(service.tearDown());
}

export function clear() {
  return rx.firstValueFrom(service.clear());
}
