import { useConnectedCalendars } from '@mirage/mosaics/WorkingSet/Calendar/useConnectedCalendars';
import {
  calendarEventsUpdates,
  getCachedCalendarEvents,
  refreshCalendarEvents,
  startRefreshCalendarEvents,
  stopRefreshCalendarEvents,
} from '@mirage/service-calendar-events';
import { useConsolidatedSubscription } from '@mirage/shared/hooks/useConsolidatedSubscription';
import { ONE_SECOND_IN_MILLIS } from '@mirage/shared/util/constants';
import { isPast } from 'date-fns';
import { atom, useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

import type { dash } from '@dropbox/api-v2-client';

export type ConnectionSyncState =
  | 'syncing'
  | 'synced'
  | 'disconnected'
  | undefined;

const eventsAtom = atom<dash.SearchResult[] | undefined>(undefined);

function useCalendarEvents() {
  const { connections } = useConnectedCalendars();
  const [syncState, setSyncState] = useState<ConnectionSyncState | undefined>(
    undefined,
  );
  const [events, setEvents] = useAtom(eventsAtom);
  const subscribe = useMemo(() => {
    const observer = calendarEventsUpdates();
    return observer.subscribe.bind(observer);
  }, []);

  useEffect(() => {
    const hasUnsyncedConnections = connections.some(
      (connection) =>
        !connection?.sync_status?.sync_progress?.last_successful_sync_time,
    );

    if (connections.length === 0) {
      setSyncState('disconnected');
    } else if (hasUnsyncedConnections) {
      setSyncState('syncing');
    } else {
      setSyncState((prev) => {
        if (prev == 'syncing') {
          // Perform a one-off refresh API call to get the newly sync'ed data
          void refreshCalendarEvents();
        }
        return 'synced';
      });
    }
  }, [connections, setSyncState]);

  useConsolidatedSubscription({
    hookName: 'useCalendarEvents',
    shouldSubscribe: syncState === 'synced' || syncState === 'syncing',
    setState: setEvents,
    getInitialData: getCachedCalendarEvents,
    subscribe,
    onSubscribe: startRefreshCalendarEvents,
    onUnsubscribe: stopRefreshCalendarEvents,
  });

  return { syncState, events };
}

export const useUpcomingCalendarEvents = () => {
  const { syncState, events } = useCalendarEvents();
  const [upcomingCalendarEvents, setUpcomingCalendarEvents] = useState<
    dash.SearchResult[] | undefined
  >();

  // Filter out old events every 30 seconds without making an additional api call (just use timestamps)
  useEffect(() => {
    function dropOldEvents() {
      setUpcomingCalendarEvents((existingEvents) => {
        const hasEventThatHasEnded = existingEvents?.some(
          (event) => event.end_time && isPast(event.end_time),
        );

        // This should have no change on the events
        if (!hasEventThatHasEnded) {
          return existingEvents;
        }

        // Keep only the events that have not ended yet
        return existingEvents?.filter(
          (event) => !(event.end_time && isPast(event.end_time)),
        );
      });
    }

    setUpcomingCalendarEvents(events);
    dropOldEvents();
    const intervalId = setInterval(dropOldEvents, ONE_SECOND_IN_MILLIS * 30);

    return () => {
      clearInterval(intervalId);
    };
  }, [events, setUpcomingCalendarEvents]);

  return { syncState, upcomingCalendarEvents };
};
