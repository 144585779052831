import { Card } from '@dropbox/dash-component-library';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Cluster, useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { Twinkle2Line } from '@dropbox/dig-icons/dist/mjs/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { Link as ExternalLink } from '@mirage/link/Link';
import { AnswerCitationCardsGrid } from '@mirage/mosaics/MultiAnswersCard/layout/AnswerCitationCardsGrid';
import { MultiAnswerCardContainerWideLoading } from '@mirage/mosaics/MultiAnswersCard/layout/MultiAnswerCardContainerWideLoading';
import { useOneColumnLayout } from '@mirage/mosaics/MultiAnswersCard/layout/useOneColumnLayout';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { BetaBadge } from '@mirage/shared/badges/BetaBadge';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { AI_LEARN_MORE_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { memo, useRef, useState } from 'react';
import styles from './MultiAnswerCardContainer.module.css';

import type { Connectors } from '@mirage/service-connectors/service';
import type { MutableRefObject, ReactNode } from 'react';

interface MultiAnswerCardContainerBaseProps {
  loading: boolean;
  followupButtons?: ReactNode;
  contentRef: MutableRefObject<HTMLDivElement | null>; // ref set on the main content container (excluding header)
  connectors: Connectors;
  answer: ReactNode;
  citations?: ReactNode;
}
interface MultiAnswerCardContainerProps
  extends MultiAnswerCardContainerBaseProps {
  variant: 'narrow' | 'wide';
}
export const MultiAnswerCardContainer = memo(
  ({ variant, ...props }: MultiAnswerCardContainerProps) => {
    switch (variant) {
      case 'narrow':
        return <MultiAnswerCardContainerNarrow {...props} />;
      case 'wide':
        return <MultiAnswerCardContainerWide {...props} />;
      default:
        variant satisfies never;
        throw new Error(`Invalid variant: ${variant}`);
    }
  },
);
MultiAnswerCardContainer.displayName = 'MultiAnswerCardContainer';

const MultiAnswerCardContainerNarrow = memo(
  ({
    loading,
    contentRef,
    followupButtons,
    answer,
  }: MultiAnswerCardContainerBaseProps) => {
    const isMobile = useIsMobileSize();
    const isDesktop = EnvCtx.surface === 'desktop';
    const cardHeight = useNarrowCardHeight(contentRef, loading);

    return (
      <Card
        variant="outline"
        loading={loading}
        className={classNames(styles.container, styles.isNarrow, {
          [styles.isMobile]: isMobile,
          [styles.isDesktop]: isDesktop,
        })}
        style={{
          maxHeight: `${cardHeight}px`,
          transition: 'max-height 750ms cubic-bezier(0.17, 0, 0.09, 1)',
        }}
      >
        <Card.Header
          withMargin={!loading}
          title={
            <Cluster>
              <Cluster.Item>
                <Text tagName="h2" variant="label" isBold>
                  {i18n.t('answers_header_title')}
                </Text>
              </Cluster.Item>
              <Cluster.Item>
                <BetaBadge className={styles.badge} />
              </Cluster.Item>
            </Cluster>
          }
        />
        <div
          ref={contentRef}
          className={classNames({ [styles.cardContent]: !loading })}
        >
          {answer}
          {followupButtons && (
            <div className={styles.followUpContainer}>{followupButtons}</div>
          )}
          {/* "Experimental feature" disclaimer whenever we're not loading */}
          {!loading && (
            <>
              <Text
                size="small"
                className={styles.experimentalNoticeText}
                color="faint"
              >
                {i18n.t('answers_disclaimer_text')}
                &nbsp;
                <ExternalLink
                  variant="monochromatic"
                  href={AI_LEARN_MORE_URL}
                  data-uxa-log={createUxaElementId('learn_more_link', {
                    actionSurfaceComponent: 'inline_answer',
                    featureLine: 'multi_answers',
                  })}
                >
                  {i18n.t('learn_more')}
                </ExternalLink>
              </Text>
            </>
          )}
        </div>
      </Card>
    );
  },
);
MultiAnswerCardContainerNarrow.displayName = 'MultiAnswerCardContainerNarrow';

const ESTIMATED_NARROW_CARD_HEIGHT = 80;

function useNarrowCardHeight(
  contentRef: MutableRefObject<HTMLDivElement | null>,
  loading: boolean,
) {
  const [cardHeight, setCardHeight] = useState(0);
  const handleResize = () => {
    if (contentRef?.current) {
      setCardHeight(
        contentRef.current.clientHeight + ESTIMATED_NARROW_CARD_HEIGHT,
      );
    }
  };
  useResizeObserver(contentRef, handleResize);
  return loading ? ESTIMATED_NARROW_CARD_HEIGHT : cardHeight;
}

const MultiAnswerCardContainerWide = memo(
  ({
    loading,
    contentRef,
    followupButtons,
    connectors,
    answer,
    citations,
  }: MultiAnswerCardContainerBaseProps) => {
    const isMobile = useIsMobileSize();
    const isDesktop = EnvCtx.surface === 'desktop';
    const isDarkMode = useTheme().mode === 'dark';
    const [isTruncated, setIsTruncated] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const oneColumnLayout = useOneColumnLayout(containerRef);
    return (
      <div
        className={classNames(styles.container, styles.isWide, {
          [styles.isMobile]: isMobile,
          [styles.isDesktop]: isDesktop,
          [styles.isDarkMode]: isDarkMode,
        })}
        ref={containerRef}
      >
        {loading ? (
          <MultiAnswerCardContainerWideLoading connectors={connectors} />
        ) : (
          <>
            <div className={styles.mainContent}>
              <Cluster
                gap="8px"
                alignY="center"
                className={styles.titleContainer}
              >
                <Cluster.Item className={styles.titleIcon}>
                  <UIIcon src={Twinkle2Line} />
                </Cluster.Item>
                <Cluster.Item>
                  <Text size="medium" color="subtle">
                    {i18n.t('answers_header_title')}
                  </Text>
                </Cluster.Item>
                <Cluster.Item>
                  <BetaBadge className={styles.badge} />
                </Cluster.Item>
              </Cluster>
              <div
                ref={contentRef}
                className={classNames(styles.content, {
                  [styles.isTruncated]: isTruncated,
                })}
              >
                {answer}
                {oneColumnLayout && citations && (
                  <AnswerCitationCardsGrid singleRow={true} cards={citations} />
                )}
                {followupButtons && (
                  <div className={styles.followUpContainer}>
                    {followupButtons}
                  </div>
                )}
              </div>
              {isTruncated && !loading && (
                <div>
                  <Button
                    className={styles.seeMoreButton}
                    variant="filled"
                    size="large"
                    fullWidth
                    onClick={() => setIsTruncated(false)}
                  >
                    {i18n.t('answers_see_more')}
                  </Button>
                </div>
              )}
              {/* "Experimental feature" disclaimer whenever we're not loading */}
              {!loading && (
                <Text
                  size="small"
                  className={styles.experimentalNoticeText}
                  color="faint"
                >
                  {i18n.t('assist_ai_prompt_disclaimer')}
                  &nbsp;
                  <ExternalLink
                    variant="monochromatic"
                    href={AI_LEARN_MORE_URL}
                    data-uxa-log={createUxaElementId('learn_more_link', {
                      actionSurfaceComponent: 'inline_answer',
                      featureLine: 'multi_answers',
                    })}
                  >
                    {i18n.t('learn_more')}
                  </ExternalLink>
                </Text>
              )}
            </div>
            {!oneColumnLayout && (
              <div className={styles.sidebarContent}>
                {citations && (
                  <AnswerCitationCardsGrid
                    singleRow={false}
                    cards={citations}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  },
);
MultiAnswerCardContainerWide.displayName = 'MultiAnswerCardContainerWide';

export const MultiAnswerCardContainerDivider = () => {
  return <div className={styles.divider} />;
};
