// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3712
import type { PAPEventGeneric } from "../base/event";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";
import type { Environment } from "../enums/environment";

// Event_name: 'delete.quick_link'
// Description: Fires when a quick link is deleted
// Owner: dash-exp-find-and-discover
export type Delete_QuickLink = PAPEventGeneric<
  'dash',
  'delete',
  'quick_link',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
  }
>;

export function PAP_Delete_QuickLink(properties?: Delete_QuickLink['properties']): Delete_QuickLink {
  return <Delete_QuickLink>{
    class: 'dash',
    action: 'delete',
    object: 'quick_link',
    properties,
  };
}
