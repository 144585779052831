import { useEffect, useMemo, useState } from 'react';
import { getDisplayStats, namespace, subscribeToDisplayStatsUpdates } from '.';

import type { Namespace } from '.';
import type { DisplayStatsByName } from './service';
import type { Subscription } from 'rxjs';

export function useOperationalMetrics(ns: string): Namespace {
  return useMemo(() => namespace(ns), [ns]);
}

export function useDisplayStatsByName() {
  const [statsByName, setStatsByName] = useState<DisplayStatsByName>();

  useEffect(() => {
    getDisplayStats().then((s) => {
      setStatsByName(s);
    });

    let subscription: Subscription | null = null;

    subscribeToDisplayStatsUpdates((s) => {
      setStatsByName(s);
    }).then((s) => (subscription = s));

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  return statsByName;
}
