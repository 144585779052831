import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_Shown_DashCard } from '@mirage/analytics/events/types/shown_dash_card';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { ListItemSize } from '@mirage/link-list/types';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { logPageLoadMilestoneOnce } from '@mirage/service-operational-metrics/page-load';
import { useCollapsedCardSettings } from '@mirage/service-settings/useCollapsedCardSettings';
import { useStackItemCollapseSummarySettings } from '@mirage/service-settings/useStackItemByIdCollapseSummarySettings';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useResolveWidthProp } from '@mirage/shared/responsive/breakpoints';
import {
  StackItemSummary,
  StackItemSummaryButton,
} from '@mirage/shared/stack-item-summary/StackItemSummary';
import { isSummarySupported } from '@mirage/shared/stack-item-summary/types';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { getStackItemMetadata } from '@mirage/shared/util/stack-item-metadata';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useImpressionLogging } from '../WorkingSetCard/useImpressionLogging';
import styles from './StackCard.module.css';

import type { WorkingSetCardData } from '../types';
import type { CollapsedCardKey } from '@mirage/service-settings/service/types';

type StackCardDataProps = {
  stackData: WorkingSetCardData;
};

/* Originally thought we'd have more states here, but
 * this keeps them clean, as we want to hide more and
 * more components.
 */
enum ContainerState {
  SHOW_ALL,
  HIDE_FACEPILE,
}

export const StackCard: React.FC<StackCardDataProps> = ({ stackData }) => {
  logPageLoadMilestoneOnce(`StackCard ${stackData.title} start`);

  const contentRowEnabled = !!useFeatureFlagValue(
    'dash_use_dash_components',
    false,
  );
  const dashStackItemSummaryFlag =
    useFeatureFlagValue('dash_2024_06_10_stack_item_summary') === 'ON';

  const settingId: CollapsedCardKey = `stack:${stackData.id}`;
  const { isCollapsed, setCollapsed } = useCollapsedCardSettings(settingId);
  const { isSummaryCollapsed, setCollapseSummary } =
    useStackItemCollapseSummarySettings();

  const account = useDropboxAccount();
  const userProfilePhotoUrl = account?.profile_photo_url;
  const userGivenName = account?.name?.given_name ?? '';

  const { reportPapEvent } = useMirageAnalyticsContext();
  const actionSurfaceComponent = 'stacks';
  const featureLine = 'stacks';

  useImpressionLogging(
    true,
    stackData.namespaceId,
    PAP_Shown_DashCard({
      actionSurfaceComponent,
      featureLine,
      dashCardType: 'stack',
      stackId: stackData.namespaceId,
    }),
  );

  const onExpandCollapseClick = () => {
    if (isCollapsed) {
      reportPapEvent(
        PAP_Expand_DashCard({ actionSurfaceComponent, featureLine }),
      );
    } else {
      reportPapEvent(
        PAP_Collapse_DashCard({ actionSurfaceComponent, featureLine }),
      );
    }
    setCollapsed(!isCollapsed);
  };
  const isAlwaysCollapsed = stackData.links.length > 0;

  const containerState: ContainerState =
    useResolveWidthProp({
      // We are hiding the facepile approx when the card itself drops below 424px
      812: ContainerState.HIDE_FACEPILE,
      // When the sidebar hides, and we move to one colum layout, we reset with
      // a new set of breakpoints
      703: ContainerState.SHOW_ALL,
      455: ContainerState.HIDE_FACEPILE,
    }) ?? ContainerState.SHOW_ALL;

  const customHeader = (
    <div
      className={styles.headerContainer}
      onClick={() => stackData.onClickCard()}
      role="button"
      tabIndex={0}
      onKeyDown={onKeyDownCommitFn(() => stackData.onClickCard())}
      data-uxa-log={createUxaElementId('stack_card', {
        actionSurfaceComponent,
        featureLine,
      })}
      data-uxa-interactions="show click"
      data-uxa-entity-id={stackData.namespaceId}
    >
      <div className={styles.stackHeader}>
        <div className={styles.leftHeader}>
          <DashStackIcon
            size="large"
            colorIndex={stackData.colorIndex}
            emoji={stackData.emoji}
            customColor={stackData.customColor}
          />
          <div className={styles.shareState}>
            <UIIcon
              src={stackData.accessLevelIcon}
              size="small"
              color="var(--dig-color__text__subtle)"
            />
            <Text size="small" color="faint">
              {stackData.accessLevel}
            </Text>
          </div>
        </div>
        <div className={styles.rightHeader}>
          <span
            className={classnames(
              containerState >= ContainerState.HIDE_FACEPILE &&
                styles.hiddenHeaderContent,
            )}
          >
            {stackData.getMembersComponent('small')}
          </span>
          {
            // eslint-disable-next-line
            <div
              className={styles.buttonContainer}
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                e.stopPropagation()
              }
            >
              {stackData.actionMenuComponent}
              {isAlwaysCollapsed && (
                <IconButtonWithTooltip
                  tooltipProps={{
                    title: isCollapsed ? i18n.t('expand') : i18n.t('collapse'),
                  }}
                  variant="borderless"
                  onClick={onExpandCollapseClick}
                  data-uxa-log={createUxaElementId(
                    isCollapsed ? 'expand_button' : 'collapse_button',
                    { actionSurfaceComponent, featureLine },
                  )}
                  data-uxa-entity-id={stackData.namespaceId}
                >
                  <UIIcon
                    src={isCollapsed ? ChevronDownLine : ChevronUpLine}
                    color="var(--dig-color__text__subtle)"
                  />
                </IconButtonWithTooltip>
              )}
            </div>
          }
        </div>
      </div>
      <DigTooltip title={stackData.title} placement="top-start">
        <div className={styles.titleContainer}>
          <Title weightVariant="emphasized" className={styles.title}>
            {stackData.title}
          </Title>
        </div>
      </DigTooltip>
    </div>
  );

  return (
    <div data-testid="ForYou-StackCard">
      <TwoColumnGridCard
        settingId={`stack:${stackData.id}`}
        isAlwaysCollapsed={isAlwaysCollapsed}
        isCollapsedOverride={isCollapsed}
        showDividerLine={isAlwaysCollapsed}
        cardTypeProps={{
          cardType: CardHeaderType.CUSTOM,
          customHeader,
        }}
      >
        {isAlwaysCollapsed &&
          (contentRowEnabled ? (
            <LinkList listItemSize={ListItemSize.XLarge}>
              {stackData.links.slice(0, 3).map((link) => (
                <LinkComponent
                  key={link.url}
                  link={{
                    title: link.name,
                    url: link.url,
                    id: link.id,
                  }}
                  listItemSize={ListItemSize.XLarge}
                  accessoryComponent={
                    <>
                      {dashStackItemSummaryFlag &&
                        isSummarySupported(link.url) && (
                          <StackItemSummaryButton
                            apiFileId={link.id}
                            isSummaryCollapsed={isSummaryCollapsed(link.id)}
                            setCollapseSummary={setCollapseSummary}
                          />
                        )}
                      {stackData.actionComponentForStackItemId &&
                        stackData.actionComponentForStackItemId(link.id)}
                    </>
                  }
                  shouldShowHoverState={link.shouldShowHoverState}
                  metadata={getStackItemMetadata(link, {
                    showUrl: true,
                    showUpdatedTime: true,
                    showCreatorName: false,
                  })}
                  openPapEvents={[
                    PAP_Open_DashLink({
                      ...stackData.stackDerivedPAPProps,
                      actionSurfaceComponent,
                      featureLine,
                    }),
                  ]}
                >
                  {dashStackItemSummaryFlag && !isSummaryCollapsed(link.id) ? (
                    <StackItemSummary
                      url={link.url}
                      userProfilePhotoUrl={userProfilePhotoUrl}
                      userGivenName={userGivenName}
                    />
                  ) : (
                    <></>
                  )}
                </LinkComponent>
              ))}
            </LinkList>
          ) : (
            <LinkList listItemSize={ListItemSize.Large}>
              {stackData.links.slice(0, 3).map((link, index) => (
                <LinkComponent
                  key={index}
                  link={{
                    title: link.name,
                    url: link.url,
                    id: link.id,
                  }}
                  listItemSize={ListItemSize.Large}
                  accessoryComponent={
                    stackData.actionComponentForStackItemId &&
                    stackData.actionComponentForStackItemId(link.id)
                  }
                  shouldShowHoverState={link.shouldShowHoverState}
                  openPapEvents={[
                    PAP_Open_DashLink({
                      ...stackData.stackDerivedPAPProps,
                      actionSurfaceComponent,
                      featureLine,
                    }),
                  ]}
                ></LinkComponent>
              ))}
            </LinkList>
          ))}
      </TwoColumnGridCard>
    </div>
  );
};
