import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { OpenLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Shown_DashCard } from '@mirage/analytics/events/types/shown_dash_card';
import {
  HomePageModule,
  MetricPageName,
} from '@mirage/service-operational-metrics/module/constants';
import { useRecordModuleLatency } from '@mirage/service-operational-metrics/module/module';
import {
  getFreshLoginTags,
  useFreshLoginTags,
} from '@mirage/service-operational-metrics/module/tags';
import {
  shouldIgnoreRecentContent,
  useRecentBrowserHistory,
} from '@mirage/service-recent-content/hooks';
import { DefaultCustomizableModuleId } from '@mirage/service-settings/service/customize';
import { useCustomizableModuleIsHidden } from '@mirage/service-settings/useCustomizableModuleSettings';
import i18n from '@mirage/translations';
import { useEffect } from 'react';
import { AsyncLinkListCard } from './AsyncCard';
import styles from './RecentsCard.module.css';
import { useImpressionLogging } from './useImpressionLogging';

import type { LinkWithRenderData } from './AsyncCard';
import type { RecentContent } from '@mirage/service-recent-content/types';

const createRecentContentLink = ({
  url,
  title,
}: RecentContent): LinkWithRenderData => {
  return {
    url,
    title,
    accessoryComponent: (
      <IconButton variant="transparent">
        <UIIcon src={OpenLine} className={styles.rightIcon} />
      </IconButton>
    ),
  };
};

export const RecentsCard: React.FC = () => {
  const isHidden = useCustomizableModuleIsHidden(
    DefaultCustomizableModuleId.RECENT_CONTENT,
  );

  return isHidden ? null : <RecentsCardActual />;
};

const RecentsCardActual = () => {
  const { content, isLoading } = useRecentBrowserHistory();
  const recentContentForDisplay = isLoading
    ? []
    : content
        .filter((c) => !shouldIgnoreRecentContent(c.url))
        .slice(0, 4)
        .map(createRecentContentLink);

  const freshLoginTags = useFreshLoginTags();

  const { markModuleLoadComplete } = useRecordModuleLatency(
    MetricPageName.HOME_V2,
    HomePageModule.RECENTS_V2,
    freshLoginTags || getFreshLoginTags,
  );

  useEffect(() => {
    if (!isLoading) {
      markModuleLoadComplete();
    }
  }, [isLoading, markModuleLoadComplete]);

  useImpressionLogging(
    !isLoading && content.length > 0,
    'recents',
    PAP_Shown_DashCard({
      actionSurfaceComponent: 'recents',
      featureLine: 'recents',
      dashCardType: 'recents',
    }),
  );

  return isLoading || content.length > 0 ? (
    <AsyncLinkListCard
      settingId="recents"
      title={i18n.t('recent_content')}
      subtitle={
        isLoading
          ? undefined
          : i18n.t('num_items', { count: recentContentForDisplay.length })
      }
      links={recentContentForDisplay}
      loadingRowCount={4}
      showSubtitleWhenExpanded={true}
      actionSurfaceComponent="recents"
      featureLine="recents"
    />
  ) : null;
};
