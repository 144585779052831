import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashStackDetailsTour } from '@mirage/analytics/events/types/dismiss_dash_stack_details_tour';
import { PAP_Next_DashStackDetailsTour } from '@mirage/analytics/events/types/next_dash_stack_details_tour';
import { PAP_Previous_DashStackDetailsTour } from '@mirage/analytics/events/types/previous_dash_stack_details_tour';
import { PAP_Shown_DashStackDetailsTour } from '@mirage/analytics/events/types/shown_dash_stack_details_tour';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import {
  useIsUsersDay0,
  useOnboardingValues,
} from '@mirage/service-onboarding/hooks';
import {
  DismissAfterAccountAge,
  useDismissableModule,
} from '@mirage/service-onboarding/useDismissableModule';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { StackDetailsTourDismissButton } from '@mirage/analytics/events/enums/stack_details_tour_dismiss_button';
import type { TourStep } from '@mirage/shared/tooltips/TourTooltip';

const DISMISS_STORAGE_KEY = 'dismissedStackDetailsTour';
const STAGE_STORAGE_KEY = 'stackDetailTourStepIndex';

const stackTourStepAtom = atom(-1);

export function useStackDetailsTour() {
  const TOUR_STEPS: TourStep[] = [
    {
      key: 'name-stack',
      stepNumber: 1,
      title: i18n.t('create_stack_tour_name_stack_title'),
      message: i18n.t('create_stack_tour_name_stack_message'),
    },
    {
      key: 'add-more-files',
      stepNumber: 2,
      title: i18n.t('create_stack_tour_more_files_title'),
      message: i18n.t('create_stack_tour_more_files_message'),
    },
  ];

  const dismissBehavior = useMemo(() => new DismissAfterAccountAge('3d'), []);
  const { dismissed, setDismissed } = useDismissableModule(
    DISMISS_STORAGE_KEY,
    dismissBehavior,
  );

  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();
  const [stepIndex, setStepIndex] = useAtom(stackTourStepAtom);
  const isMobileSize = useIsMobileSize();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [isExposureLogged, setIsExposureLogged] = useState(false);
  const isAugustRev = useStackPageAugustRevisionEnabled();
  const isDay0 = useIsUsersDay0();

  const getCurrentStep = useCallback(() => {
    if (dismissed || isMobileSize) {
      return undefined;
    }
    return TOUR_STEPS[stepIndex];
  }, [stepIndex, dismissed, isMobileSize]);

  const isTourStepEnabled = useCallback(
    (stepKey: string) => {
      const currentStep = getCurrentStep();
      return (
        isDay0 &&
        isAugustRev &&
        stepIndex !== -1 &&
        currentStep &&
        stepKey === currentStep.key
      );
    },
    [getCurrentStep, isAugustRev, isDay0, stepIndex],
  );

  useEffect(() => {
    (async () => {
      if (stepIndex === -1) {
        const onboardingValue = await getOnboardingValue(STAGE_STORAGE_KEY);
        setStepIndex(onboardingValue);
      }
    })();
  }, [getOnboardingValue, setStepIndex, stepIndex]);

  const handleStepChange = (isIncrementing: boolean) => {
    const newStepIndex = isIncrementing ? stepIndex + 1 : stepIndex - 1;
    setStepIndex(newStepIndex);
    setOnboardingValue(STAGE_STORAGE_KEY, newStepIndex);
  };

  const nextStep = useCallback(() => {
    if (stepIndex >= TOUR_STEPS.length - 1) {
      setDismissed(true);
      reportPapEvent(
        PAP_Dismiss_DashStackDetailsTour({
          stackDetailsTourStep: stepIndex,
          stackDetailsTourDismissButton: 'complete',
          featureLine: 'stacks',
        }),
      );
      return;
    }
    handleStepChange(true);
    reportPapEvent(
      PAP_Next_DashStackDetailsTour({
        stackDetailsTourStep: stepIndex,
        featureLine: 'stacks',
      }),
    );
  }, [setDismissed, reportPapEvent, handleStepChange, stepIndex]);

  const previousStep = useCallback(() => {
    if (stepIndex === 0) return;
    handleStepChange(false);
    reportPapEvent(
      PAP_Previous_DashStackDetailsTour({
        stackDetailsTourStep: stepIndex,
        featureLine: 'stacks',
      }),
    );
  }, [stepIndex, handleStepChange, reportPapEvent]);

  const dismissTour = useCallback(
    (dismissBtnName: StackDetailsTourDismissButton | undefined) => {
      setDismissed(true);
      reportPapEvent(
        PAP_Dismiss_DashStackDetailsTour({
          stackDetailsTourStep: stepIndex,
          stackDetailsTourDismissButton: dismissBtnName,
          featureLine: 'stacks',
        }),
      );
    },
    [stepIndex, reportPapEvent, setDismissed],
  );

  const logTourExposure = useCallback(() => {
    if (isExposureLogged) return;
    setIsExposureLogged(true);
    reportPapEvent(
      PAP_Shown_DashStackDetailsTour({
        stackDetailsTourStep: stepIndex,
        featureLine: 'stacks',
      }),
    );
  }, [isExposureLogged, setIsExposureLogged, reportPapEvent, stepIndex]);

  return {
    getCurrentStep,
    dismissTour,
    nextStep,
    previousStep,
    totalSteps: TOUR_STEPS.length,
    logTourExposure,
    isTourStepEnabled,
    tests: {
      reportPapEvent,
    },
  };
}
