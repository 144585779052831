import { ProgressBar } from '@dropbox/dig-components/progress_indicators';
import { StacksLoadingContainer } from '@mirage/mosaics/StacksLoading/StacksLoadingContainer';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { dismissStackSuggestion } from '@mirage/service-stack-suggestions';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { FullScreenStackV2 } from '@mirage/stacks';
import { useSetActiveStack } from '@mirage/stacks/ActiveStack/atomHooks';
import { useCreateStackAndNav } from '@mirage/stacks/FullScreenStack/hooks';
import {
  useStackByNsId,
  useStackMutationForRequestId,
} from '@mirage/stacks/hooks';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { resetNewStackPageAtom } from './atoms';

import type { stacks } from '@dropbox/api-v2-client';
import type { FullScreenStackV2IncomingState } from '@mirage/stacks/FullScreenStack/types';

export const NewStackPage = () => {
  const currentAccount = useDropboxAccount();
  useDashTitle('New Stack');

  const [stack, setStack] = useState<stacks.Stack | undefined>();
  const [items, setItems] = useState<stacks.StackItem[]>([]);
  const [creatingStack, setIsCreatingStack] = useState(false);
  const [hasNavToNewStack, setHasNavToNewStack] = useState(false);
  const [resetNewStackPage, setResetNewStackPage] = useAtom(
    resetNewStackPageAtom,
  );
  const sessionId = useMemo(() => uuidv4(), [resetNewStackPage]);
  const mutationId = useMemo(() => uuidv4(), [resetNewStackPage]);
  const createStackAndNav = useCreateStackAndNav(sessionId);
  const { state, pathname } = useLocation();
  const { tempStack, tempStackItems, createdNamespaceId, resetMutationState } =
    useStackMutationForRequestId(mutationId);
  const { stack: createdStack, items: createdItems } = useStackByNsId(
    createdNamespaceId || undefined,
  );
  // Initialize the active stack atoms
  useSetActiveStack(stack ?? null, items);
  const incomingState = state as FullScreenStackV2IncomingState;

  useEffect(() => {
    if (incomingState && !creatingStack) {
      const {
        name: stackName,
        items,
        colorIndex,
        predictionIdHash,
        autostackSuggestion,
      } = incomingState;
      if (items.length > 0 && stackName) {
        setIsCreatingStack(true);
        createStackAndNav(
          { name: stackName, color_index: colorIndex },
          { items, predictionIdHash },
          'suggested_stack',
          () => {
            autostackSuggestion && dismissStackSuggestion(autostackSuggestion);
          },
        );
      }
    }
  }, [incomingState, creatingStack, createStackAndNav]);

  useEffect(() => {
    if (createdNamespaceId && createdStack) {
      setStack(createdStack);
    } else {
      setStack(tempStack || undefined);
    }
  }, [tempStack, createdNamespaceId, createdStack]);

  useEffect(() => {
    // If we have item data from the real stack (with NSId), use that
    if (createdNamespaceId && createdItems) {
      setItems(createdItems);
    } else {
      // Otherwise, use the item data from the optimistic updates
      setItems(tempStackItems);
    }
  }, [createdItems, tempStackItems, createdNamespaceId]);

  // Handle the update of the URL when a stack is created
  useEffect(() => {
    // Replace url with the created stack shareId. Can't use react-router's
    // navigate here as it will cause a full page reload.
    if (createdNamespaceId && createdStack && !hasNavToNewStack) {
      const shareId = stackGetShareId(createdStack);
      if (shareId) {
        const currentPath = pathname;
        // remove /new from path and replace with shareId
        const newPath = currentPath.replace(/\/new$/, `/${shareId}`);
        // Cant use navigate here as it will cause a full page reload
        window.history.replaceState(null, '', newPath);
        setHasNavToNewStack(true);
      }
    }
  }, [
    createdNamespaceId,
    createdStack,
    hasNavToNewStack,
    resetMutationState,
    pathname,
  ]);

  // Reset the new stack page state whenever the resetNewStackPageAtom is set.
  // Use the `useForceNavToNewStack` hook to navigate to this page and reset its state.
  // Only needed when the button to nav to /stacks/new is visible on the new stack page
  // itself (i.e. from the sidebar)
  useEffect(() => {
    if (resetNewStackPage) {
      resetMutationState();
      setHasNavToNewStack(false);
      setResetNewStackPage(false);
    }
  }, [resetNewStackPage, resetMutationState, setResetNewStackPage]);

  if (!currentAccount || creatingStack) {
    return <ProgressBar in isIndeterminate />;
  }

  return (
    <StacksLoadingContainer>
      <FullScreenStackV2
        currentAccount={currentAccount}
        mutationRequestId={mutationId}
        sessionId={sessionId}
        // Latency measurement is not applicable to the new stack page.
        setShowSharingModule={() => void 0}
        setShowSuggestions={() => void 0}
      />
    </StacksLoadingContainer>
  );
};
