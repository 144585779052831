import { IconButton } from '@dropbox/dig-components/buttons';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine, FailFill, UndoLine } from '@dropbox/dig-icons/assets';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import { showSnackbar } from '@mirage/shared/snackbar';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import { useKeyboardShortcut } from './hooks';
import styles from './KeyboardShortcutListItem.module.css';

import type { GlobalShortcutActions } from './hooks';
import type { TextInputInputRefObject } from '@dropbox/dig-components/text_fields';
import type { AvatarSettings } from '@mirage/service-settings/service/types';
import type { KeyboardEvent, SyntheticEvent } from 'react';

type Props = Readonly<{
  setEditing: (editing: boolean) => void;
  handleToggle: () => void;
  label: string;
  platform: string;
  settingId: GlobalShortcutActions;
  settings: AvatarSettings;
  setSetting: (
    settingName: keyof AvatarSettings,
    value?: number | string,
  ) => void;
}>;

export default function KeyboardInput({
  setEditing,
  handleToggle,
  label,
  platform,
  settingId,
  settings,
  setSetting,
}: Props) {
  const inputRef = useRef<TextInputInputRefObject>(null);
  const handleSetSetting = (
    settingName: keyof AvatarSettings,
    value?: number | string,
  ) => {
    setSetting(settingName, value);
  };
  const {
    recordingBuffer = '',
    errorMessage,
    validKeyboardShortcut,
    editKBShortcut,
    resetKBShortcut,
    cancelKBShortcut,
    setKBShortcut,
    keyDownHandler,
  } = useKeyboardShortcut(
    platform,
    settingId,
    settings,
    handleSetSetting,
    setEditing,
  );

  const handleFocus = () => {
    editKBShortcut();
    setEditing(true);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    if (e) e.preventDefault();
    setKBShortcut();
  };

  const handleReset = () => {
    resetKBShortcut();
  };

  const keydown = (e: KeyboardEvent) => {
    e.stopPropagation();
    keyDownHandler(e);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showSnackbar({ title: errorMessage });
    }
  }, [errorMessage]);

  return (
    <div className={styles.keyboardShortcutFormContainer}>
      <form className={styles.keyboardShortcutForm} onSubmit={handleSubmit}>
        <TextInput.Container
          isInvalid={errorMessage !== ''}
          className={styles.keyboardShortcutTextInput}
        >
          <TextInput.ChipsContainer
            aria-label={i18n.t('settings_keyboard_shortcuts_editing', {
              label,
            })}
          >
            {recordingBuffer?.length !== 0 && (
              <PrettyShortcuts
                shortcut={recordingBuffer}
                isDarwin={platform === 'darwin'}
                handleToggle={handleToggle}
                editable
                separateKeys
              />
            )}
            <TextInput.Input
              ref={inputRef}
              aria-label={i18n.t(
                'settings_keyboard_shortcuts_aria_for_action',
                {
                  label,
                },
              )}
              placeholder={
                recordingBuffer?.length
                  ? ''
                  : i18n.t(
                      'settings_keyboard_shortcuts_placeholder_type_shortcut',
                    )
              }
              onFocus={handleFocus}
              onKeyDown={keydown}
              readOnly
              style={{ caretColor: 'transparent', width: '0' }}
              value=""
            />
          </TextInput.ChipsContainer>
        </TextInput.Container>
        <DigTooltip
          title={i18n.t('settings_keyboard_shortcuts_save')}
          placement="bottom"
        >
          <IconButton
            aria-label={i18n.t('settings_keyboard_shortcuts_aria_save')}
            className={styles.keyboardShortcutButton}
            disabled={!validKeyboardShortcut || !!errorMessage}
            onClick={handleSubmit}
            onKeyDown={keydown}
            size="medium"
            variant="transparent"
          >
            <UIIcon src={CheckmarkLine} />
          </IconButton>
        </DigTooltip>
      </form>
      <DigTooltip
        title={i18n.t('settings_keyboard_shortcuts_reset')}
        placement="bottom"
      >
        <IconButton
          aria-label={i18n.t('settings_keyboard_shortcuts_aria_reset')}
          className={styles.keyboardShortcutButton}
          onClick={handleReset}
          onKeyDown={keydown}
          size="medium"
          variant="transparent"
        >
          <UIIcon src={UndoLine} />
        </IconButton>
      </DigTooltip>
      <DigTooltip
        title={i18n.t('settings_keyboard_shortcuts_cancel')}
        placement="bottom"
      >
        <IconButton
          aria-label={i18n.t('settings_keyboard_shortcuts_aria_cancel', {
            label,
          })}
          className={styles.keyboardShortcutButton}
          onClick={cancelKBShortcut}
          onKeyDown={keydown}
          size="medium"
          variant="transparent"
        >
          <UIIcon src={FailFill} />
        </IconButton>
      </DigTooltip>
    </div>
  );
}
