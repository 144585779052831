import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { AccessType } from '@mirage/shared/compose/assist-api';

import type { context_engine } from '@dropbox/api-v2-client/types/dropbox_types';

const logger = tagged('compose-service/apiDataLoader');

export interface ItemDataParams {
  key: string;
  data: string;
  rev: number | undefined;
  is_owned: boolean | undefined;
}

/**
 * Load all items from the KV API for a given user data type, handles pagination.
 *
 * @param userDataType - The key to look up via the KV API
 * @param parseItemData - A function that takes the raw item data and returns a parsed item. If
 *   the item is invalid, return undefined and it will be ignored.
 * @param accessType - The access type to use for the API call.
 * @returns A list of all parsed items.
 */
export async function loadAssistKVDataAPI<ItemType>(
  userDataType: string,
  parseItemData: (itemData: ItemDataParams) => ItemType | undefined,
  accessType: AccessType = AccessType.INDIVIDUAL,
): Promise<ItemType[]> {
  const loadedItems: ItemType[] = [];
  let cursor: string = '';

  do {
    const args: context_engine.AssistListUserDataArg = {
      user_data_type: userDataType,
      cursor,
      access_type: { '.tag': accessType ?? AccessType.INDIVIDUAL },
    };
    logger.log('Fetching next page...');
    const response = await callApiV2(
      'contextEngineAssistApiListUserData',
      args,
    );

    if (response.items) {
      Object.entries(response.items).forEach(([key, item]) => {
        if (!item.user_data) {
          return;
        }
        const itemData = parseItemData({
          key,
          data: item.user_data,
          rev: item.rev,
          is_owned: item.is_owned,
        });
        if (itemData) {
          loadedItems.push(itemData);
        }
      });
    }

    cursor = response.cursor || '';
  } while (cursor);

  logger.log('Done fetching all pages', userDataType, loadedItems.length);
  return loadedItems;
}
