import { Box } from '@dropbox/dig-foundations';
import { Breadcrumb } from '@mirage/mosaics/BaseProfilePage/Breadcrumb';
import { callCompanyOSTeamApi } from '@mirage/mosaics/BaseProfilePage/companyos-apis';
import { OrgChart } from '@mirage/mosaics/BaseProfilePage/OrgChart/Chart';
import { useCompanyOSIntegrationEnabled } from '@mirage/mosaics/BaseProfilePage/utils';
import { TeamProfileBody } from '@mirage/mosaics/TeamProfilePage/TeamProfileBody';
import { getTeamProfilePageHref } from '@mirage/mosaics/TeamProfilePage/utils';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import baseStyles from '../BaseProfilePage/BaseProfilePage.module.css';

import type { FocusData } from '@mirage/mosaics/BaseProfilePage/OrgChart/helpers';
import type { TeamInfo } from '@mirage/mosaics/TeamProfilePage/types';

export const TeamProfilePage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const teamSlug = pathname.split('/').pop();

  const [teamInfo, setTeamInfo] = useState<TeamInfo | null>(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (teamSlug) {
      callCompanyOSTeamApi({ teamSlug }).then((response) => {
        setTeamInfo(response);
      });
    }
  }, [teamSlug]);

  if (!useCompanyOSIntegrationEnabled() || !teamSlug) {
    navigate('/');
    return null;
  }

  return (
    teamInfo && (
      <div className={baseStyles.pageContainer}>
        <div className={baseStyles.contentContainer}>
          {teamInfo.hierarchy_info.length && (
            <Box marginTop="8" marginBottom="16">
              <Breadcrumb
                path={[
                  {
                    children: i18n.t('teams_title'),
                    to: getTeamProfilePageHref(teamInfo.slug),
                  },
                  ...teamInfo.hierarchy_info.map(({ name, slug }) => ({
                    key: `team-breadcrumb__${slug}`,
                    children: name,
                    to: getTeamProfilePageHref(slug),
                  })),
                ]}
              />
            </Box>
          )}
          <TeamProfileBody team={teamInfo} />
          <Box marginTop="20" marginBottom="20">
            <TeamOrgChart
              team={teamInfo}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </Box>
        </div>
      </div>
    )
  );
};

const TeamOrgChart = ({
  team,
  expanded,
  setExpanded,
}: {
  team: FocusData;
  expanded: boolean;
  setExpanded: (expan: boolean) => void;
}) => {
  const [focus, setFocus] = useState<FocusData>(team);

  useEffect(() => {
    setFocus(team);
    setExpanded(false);
  }, [setExpanded, team]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setExpanded]);

  return (
    <OrgChart
      toggle={{ team }}
      focus={focus}
      setFocus={setFocus}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  );
};
