import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import {
  DefaultCategoryState,
  privacyBannerHeightAtom,
  privacyCategoryStateAtom,
} from '@mirage/shared/privacy';
import {
  DASH_WEBAPP_AMPLIFY_HOSTNAME,
  DASH_WEBAPP_DOMAIN,
  DASH_WEBAPP_MASTER_HOSTNAME,
} from '@mirage/shared/urls';
import { isDevBuildChannel } from '@mirage/shared/util/tiny-utils';
import { useSetAtom } from 'jotai';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect } from 'react';

import type { Categories, EmbeddableConfig } from '@mirage/shared/privacy';

declare global {
  interface Window {
    dropbox: {
      privacyConsent: {
        autoInit: EmbeddableConfig;
        init: (config: EmbeddableConfig) => void;
        getConsentCategories: () => Categories | null;
      };
    };
  }
}

export const isConsentAccepted = (categories: Partial<Categories>) =>
  isDevBuildChannel(EnvCtx.buildChannel) ||
  categories.all ||
  categories.analytics ||
  false;

export const usePrivacyModule = (devbox?: string) => {
  const useDevboxVersion = useDevboxPrivacyModule(devbox);
  const setPrivacyCategoryState = useSetAtom(privacyCategoryStateAtom);
  const setPrivacyBannerHeight = useSetAtom(privacyBannerHeightAtom);

  const tryPrivacyConsentInit = useCallback(() => {
    if (
      typeof window !== 'undefined' &&
      window.dropbox &&
      window.dropbox.privacyConsent
    ) {
      // Initialize privacy module
      window.dropbox.privacyConsent.init({
        injectToFooter: true,
        injectFloatingButton: false,
        disableBanner: false,
        thirdPartyConfig: {
          // Setup different domains that are registered in the allowlist of privacy team
          cookieDomain: window.location.hostname.includes(
            DASH_WEBAPP_AMPLIFY_HOSTNAME,
          )
            ? DASH_WEBAPP_AMPLIFY_HOSTNAME
            : window.location.hostname.includes(DASH_WEBAPP_MASTER_HOSTNAME)
              ? DASH_WEBAPP_MASTER_HOSTNAME
              : /*
                 * Browser extension protocols:
                 *  - Firefox: "moz-extension:"
                 *  - Chrome: "chrome-extension:"
                 *  - Edge: "edge-extension:"
                 *  - Safari: "safari-web-extension:"
                 */
                window.location.protocol.includes('-extension:')
                ? window.location.host
                : DASH_WEBAPP_DOMAIN,
        },
        consentChangedCallback: (categories: Partial<Categories>) => {
          setPrivacyCategoryState((prevState) => {
            return { ...prevState, ...categories, userInteracted: true };
          });
        },
        priorConsentCallback: (
          categories: Partial<Categories>,
          preferencesChanged: boolean,
          userInteracted: boolean,
        ) => {
          // This callback may be hit during initialization or with empty categories
          // Only set when the state in our storage is default state.
          setPrivacyCategoryState((prevState) => {
            if (isEqual(prevState, DefaultCategoryState)) {
              return { ...prevState, ...categories, userInteracted };
            } else {
              return prevState;
            }
          });
        },
        onBannerVisible: setPrivacyBannerHeight,
        onBannerNotVisible() {
          setPrivacyBannerHeight(0);
        },
      });
    } else {
      setTimeout(() => {
        // Need to retry if the script is not loaded yet.
        tryPrivacyConsentInit();
      }, 50);
    }
  }, [setPrivacyBannerHeight, setPrivacyCategoryState]);

  useEffect(() => {
    if (isDevBuildChannel(EnvCtx.buildChannel) || useDevboxVersion) {
      // The privacy module cannot be loaded in localhost unless you follow the instructions in the comment for `useDevboxPrivacyModule`
      return;
    }

    // Load privacy module
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/pithos/privacy_consent';
    script.async = true;
    document.head.appendChild(script);

    // Wait for all scripts to load on page
    tryPrivacyConsentInit();
  }, [tryPrivacyConsentInit, useDevboxVersion]);
};

/*
 * Here is an instruction to load privacy banner from devbox server when running `yarn dev` of web app.
 * 1. Run `//services/metaserver/pithos` target with following changes - https://github.com/dropbox-internal/server/pull/140468
 * 2. Toggle `devboxPrivacyModuleRunning` to `true`
 * 3. Make sure that `DEVBOX` is set correctly on `apps/web/src/shared/config.ts`
 *
 * Additionally, this should work for Amplify prototype branch build (drl/dash-web-prototypes)
 *  if you remove `devbox === undefined` check and hardcode src url for the script to your devbox
 */
const devboxPrivacyModuleRunning = false;
const useDevboxPrivacyModule = (devbox?: string) => {
  const setPrivacyCategoryState = useSetAtom(privacyCategoryStateAtom);
  const setPrivacyBannerHeight = useSetAtom(privacyBannerHeightAtom);
  // Try to load privacy module from my devbox server to make it work against non-whitelisted domains
  useEffect(() => {
    if (!devboxPrivacyModuleRunning || devbox === undefined) {
      return;
    }
    (() => {
      window.dropbox = {
        ...window.dropbox,
        privacyConsent: {
          ...window.dropbox?.privacyConsent,
          autoInit: {
            ...window.dropbox?.privacyConsent?.autoInit,
            thirdPartyConfig: {
              ...window.dropbox?.privacyConsent?.autoInit?.thirdPartyConfig,
              cookieDomain: window.location.host,
            },
            // Update codes here to match the version in `usePrivacyModule` or to test code changes without deploying code to prod
            injectToFooter: true,
            injectFloatingButton: true,
            disableBanner: false,
            consentChangedCallback: (categories: Partial<Categories>) => {
              setPrivacyCategoryState((prevState) => {
                return { ...prevState, ...categories, userInteracted: true };
              });
            },
            priorConsentCallback: (
              categories: Partial<Categories>,
              preferencesChanged: boolean,
              userInteracted: boolean,
            ) => {
              // This callback may be hit during initialization or with empty categories
              // Only set when the state in our storage is default state.
              setPrivacyCategoryState((prevState) => {
                if (isEqual(prevState, DefaultCategoryState)) {
                  return { ...prevState, ...categories, userInteracted };
                } else {
                  return prevState;
                }
              });
            },
            onBannerVisible: setPrivacyBannerHeight,
            onBannerNotVisible: () => {
              setPrivacyBannerHeight(0);
            },
          },
        },
      };
      document.head.appendChild(
        Object.assign(document.createElement('script'), {
          src: `https://meta-${devbox}.dev.corp.dropbox.com/pithos/privacy_consent`,
          ['async']: true,
          defer: true,
          onerror: ({ target, type }: { target: HTMLElement; type: string }) =>
            alert(`${type}: ${target.outerHTML} failed to load`),
        }),
      );
    })();
  }, [devbox, setPrivacyBannerHeight, setPrivacyCategoryState]);

  return devboxPrivacyModuleRunning;
};
