import i18n from '@mirage/translations';
import Handlebars from 'handlebars';

import type {
  ManagedShortcutTitleTemplate,
  TitleToken,
} from '@mirage/shared/search/url-shortcut';

export function requiredParameterCount(template: string): number {
  // XXX: we're assuming that these are correctly constructed shortcut configs,
  // in which case either they are using {{q}} in its entirety or they are using
  // the split query pieces in order to generate a URL.
  // - if we fail to match qParts in any capacity, there is only a single
  //   parameter required in order to generate a URL
  // - otherwise, we look for the highest index we can find from our matches of
  //   the query parts array indexes used in the template. we don't validate
  //   that all pieces of the query are used when creating URL Shortcuts so it
  //   is possible that the URL omits items from the split query. this is a
  //   very slim edge case... but check for that here :)
  const indices = queryPartIndices(template);
  if (!indices.length) return 1;

  // XXX: since these are array indices we have to make sure we increment by one
  return Math.max(...indices) + 1;
}

// 'gh ben standefer' -> ['gh', 'ben', 'standefer']
// WARN: In JS, .split() on a blank string leaves a blank string in the resulting array
export function toQueryParts(query: string): Array<string> {
  return query
    .trim()
    .split(' ')
    .filter((val) => val);
}

export function extractParameterMatches(
  template: string,
  query: string,
): Array<string> {
  const queryParts = toQueryParts(query);
  // XXX: we have to omit the shortcut hotword here
  const [, ...queryPartsExcludingHotword] = queryParts;
  const indices = queryPartIndices(template);

  // handle cases where we use the entire input and have no input
  if (!indices.length && !queryPartsExcludingHotword.length) return [];

  // handle cases where we use the entire input
  if (!indices.length) return [queryPartsExcludingHotword.join(' ')];

  // handle extracting individual index values, removing undefined lookups
  // XXX: this does not handle the case where we use both index lookups and the
  // entire user input for generating a URL... but should suffice for UI render
  return indices.map((idx) => queryPartsExcludingHotword[idx]).filter(Boolean);
}

export function queryPartIndices(template: string): Array<number> {
  let match = qPartsRegex.exec(template);
  if (!match) return [];

  const matches = [];
  do {
    matches.push(parseInt(match[1], 10));
  } while ((match = qPartsRegex.exec(template))); // eslint-disable-line no-cond-assign
  return matches;
}

export const qPartsRegex = new RegExp(/{{qParts\.\[(\d)\]}}/g);

export function tokenizeTitle(
  template: ManagedShortcutTitleTemplate,
  activation: string,
  [hotword, ...queryPartsExcludingHotword]: string[],
  defaultQuery?: string,
): TitleToken {
  const subquery = queryPartsExcludingHotword.join(' ');
  const shouldMatch = subquery.length && activation === hotword;
  const query = shouldMatch ? subquery : defaultQuery || i18n.t('param');
  return {
    type: 'shortcut',
    template: template,
    query: query,
  };
}

export const getUrl = (
  urlTemplate: string,
  queryPartsWithoutHotword: string[],
): string => {
  const pieces = queryPartsWithoutHotword;
  const q = pieces.join(' ');

  const target = new URL(urlTemplate);
  try {
    const hostRenderer = Handlebars.compile(decodeURI(target.host), {
      strict: true,
    });
    target.host = hostRenderer({ q: q, qParts: pieces });

    const pathRenderer = Handlebars.compile(decodeURI(target.pathname), {
      strict: true,
    });
    target.pathname = pathRenderer({ q: q, qParts: pieces });

    const hashRenderer = Handlebars.compile(decodeURI(target.hash), {
      strict: true,
    });
    target.hash = hashRenderer({ q: q, qParts: pieces });

    for (const [k, v] of target.searchParams.entries()) {
      const paramRenderer = Handlebars.compile(v, { strict: true });
      target.searchParams.set(k, paramRenderer({ q: q, qParts: pieces }));
    }
  } catch (error) {
    // we're running handlebars in strict mode, and couldn't construct url
    return '';
  }

  return target.toString();
};

export function isHotwordActive(query: string, hotword: string) {
  return (
    // returns all matching
    hotword.startsWith(query.toLowerCase()) ||
    // returns exact matching plus param
    query?.toLowerCase().startsWith(hotword)
  );
}
