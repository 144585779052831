import { Chip } from '@dropbox/dig-components/chip';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { StacksLine } from '@dropbox/dig-icons/dist/mjs/assets';
import i18n from '@mirage/translations';
import { PersonMetadata } from './Metadata/PersonMetadata';
import styles from './StackItemTitle.module.css';
import { getTimeAgoStringFromTimestamp } from './util/resultUtil';

import type { StackItem } from '@mirage/shared/search/stack-item';

export function StackItemSubtitle({ stackItem }: { stackItem: StackItem }) {
  const timeAgoString = stackItem.lastModifiedTime
    ? getTimeAgoStringFromTimestamp(stackItem.lastModifiedTime * 1000, true)
    : undefined;

  return (
    <div className={styles.subtitleContainer}>
      {stackItem.creator && (
        <Text size="small" color="subtle" className={styles.metadata}>
          <PersonMetadata
            i18nKey="result_updated_by"
            person={stackItem.creator}
          />
        </Text>
      )}
      {!!timeAgoString && (
        <Text size="small" color="subtle">
          {timeAgoString}
        </Text>
      )}
      {stackItem.stackIds.length > 0 ? (
        <Chip variant="standard" size="small">
          <Chip.IconAccessory>
            <UIIcon src={StacksLine} />
          </Chip.IconAccessory>
          <Chip.Content>
            {stackItem.stackIds.length === 1 && stackItem.stackIds[0].name
              ? stackItem.stackIds[0].name
              : `${stackItem.stackIds.length} ${i18n.t(
                  'stack_item_typeahead_subtitle_badge',
                )}`}
          </Chip.Content>
        </Chip>
      ) : undefined}
    </div>
  );
}
