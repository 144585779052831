import { Box } from '@dropbox/dig-foundations';
import { Breadcrumb } from '@mirage/mosaics/BaseProfilePage/Breadcrumb';
import { callCompanyOSEmployeeApi } from '@mirage/mosaics/BaseProfilePage/companyos-apis';
import { useCompanyOSIntegrationEnabled } from '@mirage/mosaics/BaseProfilePage/utils';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import baseStyles from '../BaseProfilePage/BaseProfilePage.module.css';
import { UserProfileBody } from './UserProfileBody';

import type { UserInfo } from '@mirage/mosaics/UserProfilePage/types';

const mockedBreadcrumbs = [
  { children: 'People', to: '/people/baochenh' },
  { key: 'drew', children: 'Drew', to: '/people/drew' },
  { key: 'youcef', children: 'Youcef', to: '/people/youcef' },
  { key: 'baochenh', children: 'Baochen', to: '/people/baochenh' },
];

export const UserProfilePage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const username = pathname.split('/').pop();

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useDashTitle(userInfo?.name ?? '');

  useEffect(() => {
    if (username) {
      callCompanyOSEmployeeApi({ username }).then((response) => {
        setUserInfo(response);
      });
    }
  }, [username]);

  if (!useCompanyOSIntegrationEnabled() || !username) {
    navigate('/');
    return null;
  }

  return (
    userInfo && (
      <Box as="div" className={baseStyles.pageContainer}>
        <Box as="div" className={baseStyles.contentContainer}>
          <Breadcrumb path={mockedBreadcrumbs} />
          <UserProfileBody user={userInfo} />
        </Box>
      </Box>
    )
  );
};
