// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/context_engine_answers_service.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Empty, Message, proto3, protoInt64 } from '@bufbuild/protobuf';
import { ClientContext, ContextEngineModel } from './models_pb';
import { DevRequestOverrides } from './dev_settings_pb';
import {
  Answer,
  AnswerDebugInfo,
  AnswersWorkflowError,
  Diagnostics,
  QuerySuggestion,
} from './answers_pb';
import {
  ExecuteDSLInstructionsError,
  GenerateDSLInstructionsError,
} from './context_engine_agents_service_pb';

/**
 * @generated from enum context_engine.Retriever
 */
export enum Retriever {
  /**
   * @generated from enum value: DCS = 0;
   */
  DCS = 0,

  /**
   * @generated from enum value: FSS = 1;
   */
  FSS = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(Retriever)
proto3.util.setEnumType(Retriever, 'context_engine.Retriever', [
  { no: 0, name: 'DCS' },
  { no: 1, name: 'FSS' },
]);

/**
 * @generated from message context_engine.ExperimentSettings
 */
export class ExperimentSettings extends Message<ExperimentSettings> {
  /**
   * @generated from field: string context_engine_2025_30_01_exp_use_highlights_as_body = 1;
   */
  contextEngine20253001ExpUseHighlightsAsBody = '';

  constructor(data?: PartialMessage<ExperimentSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ExperimentSettings';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'context_engine_2025_30_01_exp_use_highlights_as_body',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ExperimentSettings {
    return new ExperimentSettings().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ExperimentSettings {
    return new ExperimentSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ExperimentSettings {
    return new ExperimentSettings().fromJsonString(jsonString, options);
  }

  static equals(
    a: ExperimentSettings | PlainMessage<ExperimentSettings> | undefined,
    b: ExperimentSettings | PlainMessage<ExperimentSettings> | undefined,
  ): boolean {
    return proto3.util.equals(ExperimentSettings, a, b);
  }
}

/**
 * @generated from message context_engine.GetAnswersForQueryRequest
 */
export class GetAnswersForQueryRequest extends Message<GetAnswersForQueryRequest> {
  /**
   * Note: Please update GetAnswersForQueryInputArg when this message is updated.
   * Query submitted by the user.
   *
   * @generated from field: string user_query = 1;
   */
  userQuery = '';

  /**
   * Whether or not to log additional debugging information which may contain PII.
   *
   * @generated from field: bool debug = 3;
   */
  debug = false;

  /**
   * The source (i.e. client) of this api call. i.e. "dash"
   *
   * @generated from field: string source = 4;
   */
  source = '';

  /**
   * Information from the client environment that can be used in execution, for example: local timezone information use to do date/time arithmetic.
   *
   * @generated from field: context_engine.ClientContext client_context = 5;
   */
  clientContext?: ClientContext;

  /**
   * optional. Disable the use of caches while generating answers
   *
   * @generated from field: bool disable_cache = 6;
   */
  disableCache = false;

  /**
   * optional. Experiment setting override so FE can pass this in
   *
   * @generated from field: string experiment_setting = 7;
   */
  experimentSetting = '';

  /**
   * optional. Whether to use USP for the search index instead of ES
   *
   * @generated from field: bool use_usp = 8;
   */
  useUsp = false;

  /**
   * @generated from field: context_engine.DevRequestOverrides dev_overrides = 9;
   */
  devOverrides?: DevRequestOverrides;

  /**
   * Which source to use when searching for files to answer the question
   *
   * @generated from field: context_engine.Retriever retriever = 10;
   */
  retriever = Retriever.DCS;

  /**
   * @generated from field: context_engine.ExperimentSettings experiment_settings = 11;
   */
  experimentSettings?: ExperimentSettings;

  /**
   * Scope the answer to a given fss path
   *
   * @generated from field: string fss_answer_gen_scope_path = 12;
   */
  fssAnswerGenScopePath = '';

  /**
   * optional. List of filter connectors to apply to the query
   *
   * @generated from field: repeated string filter_connectors = 13;
   */
  filterConnectors: string[] = [];

  constructor(data?: PartialMessage<GetAnswersForQueryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetAnswersForQueryRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'client_context', kind: 'message', T: ClientContext },
    {
      no: 6,
      name: 'disable_cache',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
    {
      no: 7,
      name: 'experiment_setting',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 8, name: 'use_usp', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: 'dev_overrides', kind: 'message', T: DevRequestOverrides },
    {
      no: 10,
      name: 'retriever',
      kind: 'enum',
      T: proto3.getEnumType(Retriever),
    },
    {
      no: 11,
      name: 'experiment_settings',
      kind: 'message',
      T: ExperimentSettings,
    },
    {
      no: 12,
      name: 'fss_answer_gen_scope_path',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 13,
      name: 'filter_connectors',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAnswersForQueryRequest {
    return new GetAnswersForQueryRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryRequest {
    return new GetAnswersForQueryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryRequest {
    return new GetAnswersForQueryRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAnswersForQueryRequest
      | PlainMessage<GetAnswersForQueryRequest>
      | undefined,
    b:
      | GetAnswersForQueryRequest
      | PlainMessage<GetAnswersForQueryRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAnswersForQueryRequest, a, b);
  }
}

/**
 * @generated from message context_engine.GetAnswersForQueryResult
 */
export class GetAnswersForQueryResult extends Message<GetAnswersForQueryResult> {
  /**
   * @generated from field: string user_query = 1;
   */
  userQuery = '';

  /**
   * @generated from field: repeated context_engine.Answer answers = 2;
   */
  answers: Answer[] = [];

  /**
   * @generated from field: repeated string matched_agents = 3;
   */
  matchedAgents: string[] = [];

  /**
   * Please use diagnostics instead. query_classification_time_ms will be removed in the future.
   *
   * @generated from field: int32 query_classification_time_ms = 4 [deprecated = true];
   * @deprecated
   */
  queryClassificationTimeMs = 0;

  /**
   * Please use diagnostics instead. Will be removed in the future.
   *
   * @generated from field: context_engine.AnswerDebugInfo debug_info = 5 [deprecated = true];
   * @deprecated
   */
  debugInfo?: AnswerDebugInfo;

  /**
   * @generated from field: string request_id = 9;
   */
  requestId = '';

  /**
   * @generated from field: context_engine.Diagnostics diagnostics = 100;
   */
  diagnostics?: Diagnostics;

  constructor(data?: PartialMessage<GetAnswersForQueryResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetAnswersForQueryResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'answers', kind: 'message', T: Answer, repeated: true },
    {
      no: 3,
      name: 'matched_agents',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 4,
      name: 'query_classification_time_ms',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    { no: 5, name: 'debug_info', kind: 'message', T: AnswerDebugInfo },
    { no: 9, name: 'request_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 100, name: 'diagnostics', kind: 'message', T: Diagnostics },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAnswersForQueryResult {
    return new GetAnswersForQueryResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryResult {
    return new GetAnswersForQueryResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryResult {
    return new GetAnswersForQueryResult().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAnswersForQueryResult
      | PlainMessage<GetAnswersForQueryResult>
      | undefined,
    b:
      | GetAnswersForQueryResult
      | PlainMessage<GetAnswersForQueryResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAnswersForQueryResult, a, b);
  }
}

/**
 * @generated from message context_engine.GetAnswersForQueryError
 */
export class GetAnswersForQueryError extends Message<GetAnswersForQueryError> {
  /**
   * @generated from oneof context_engine.GetAnswersForQueryError.err
   */
  err:
    | {
        /**
         * Please use answers_err instead. Other errors will be removed in the future.
         *
         * @generated from field: string missing_user_query = 1 [deprecated = true];
         * @deprecated
         */
        value: string;
        case: 'missingUserQuery';
      }
    | {
        /**
         * @generated from field: string missing_source = 2 [deprecated = true];
         * @deprecated
         */
        value: string;
        case: 'missingSource';
      }
    | {
        /**
         * @generated from field: string not_admin = 3 [deprecated = true];
         * @deprecated
         */
        value: string;
        case: 'notAdmin';
      }
    | {
        /**
         * @generated from field: context_engine.GenerateDSLInstructionsError generation_error = 4 [deprecated = true];
         * @deprecated
         */
        value: GenerateDSLInstructionsError;
        case: 'generationError';
      }
    | {
        /**
         * @generated from field: context_engine.ExecuteDSLInstructionsError execution_error = 5 [deprecated = true];
         * @deprecated
         */
        value: ExecuteDSLInstructionsError;
        case: 'executionError';
      }
    | {
        /**
         * @generated from field: context_engine.AnswersWorkflowError answers_err = 6;
         */
        value: AnswersWorkflowError;
        case: 'answersErr';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetAnswersForQueryError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetAnswersForQueryError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_user_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'err',
    },
    {
      no: 2,
      name: 'missing_source',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'err',
    },
    {
      no: 3,
      name: 'not_admin',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'err',
    },
    {
      no: 4,
      name: 'generation_error',
      kind: 'message',
      T: GenerateDSLInstructionsError,
      oneof: 'err',
    },
    {
      no: 5,
      name: 'execution_error',
      kind: 'message',
      T: ExecuteDSLInstructionsError,
      oneof: 'err',
    },
    {
      no: 6,
      name: 'answers_err',
      kind: 'message',
      T: AnswersWorkflowError,
      oneof: 'err',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetAnswersForQueryError {
    return new GetAnswersForQueryError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryError {
    return new GetAnswersForQueryError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetAnswersForQueryError {
    return new GetAnswersForQueryError().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetAnswersForQueryError
      | PlainMessage<GetAnswersForQueryError>
      | undefined,
    b:
      | GetAnswersForQueryError
      | PlainMessage<GetAnswersForQueryError>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetAnswersForQueryError, a, b);
  }
}

/**
 * @generated from message context_engine.GetQuerySuggestionsRequest
 */
export class GetQuerySuggestionsRequest extends Message<GetQuerySuggestionsRequest> {
  /**
   * @generated from field: string source = 1;
   */
  source = '';

  /**
   * @generated from field: bool debug = 2;
   */
  debug = false;

  /**
   * @generated from field: int64 num_suggestions = 3;
   */
  numSuggestions = protoInt64.zero;

  /**
   * @generated from field: bool disable_cache = 4;
   */
  disableCache = false;

  constructor(data?: PartialMessage<GetQuerySuggestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetQuerySuggestionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    {
      no: 3,
      name: 'num_suggestions',
      kind: 'scalar',
      T: 3 /* ScalarType.INT64 */,
    },
    {
      no: 4,
      name: 'disable_cache',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetQuerySuggestionsRequest {
    return new GetQuerySuggestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetQuerySuggestionsRequest {
    return new GetQuerySuggestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetQuerySuggestionsRequest {
    return new GetQuerySuggestionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetQuerySuggestionsRequest
      | PlainMessage<GetQuerySuggestionsRequest>
      | undefined,
    b:
      | GetQuerySuggestionsRequest
      | PlainMessage<GetQuerySuggestionsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetQuerySuggestionsRequest, a, b);
  }
}

/**
 * @generated from message context_engine.GetRelatedQuerySuggestionsRequest
 */
export class GetRelatedQuerySuggestionsRequest extends Message<GetRelatedQuerySuggestionsRequest> {
  /**
   * @generated from field: string query = 2;
   */
  query = '';

  /**
   * @generated from field: string agent_id = 3;
   */
  agentId = '';

  /**
   * @generated from field: string source = 4;
   */
  source = '';

  /**
   * @generated from field: bool debug = 5;
   */
  debug = false;

  /**
   * @generated from field: int64 num_suggestions = 6;
   */
  numSuggestions = protoInt64.zero;

  /**
   * @generated from field: context_engine.ContextEngineModel previous_output = 7;
   */
  previousOutput?: ContextEngineModel;

  constructor(data?: PartialMessage<GetRelatedQuerySuggestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetRelatedQuerySuggestionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: 'query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'agent_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    {
      no: 6,
      name: 'num_suggestions',
      kind: 'scalar',
      T: 3 /* ScalarType.INT64 */,
    },
    { no: 7, name: 'previous_output', kind: 'message', T: ContextEngineModel },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetRelatedQuerySuggestionsRequest {
    return new GetRelatedQuerySuggestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetRelatedQuerySuggestionsRequest {
    return new GetRelatedQuerySuggestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetRelatedQuerySuggestionsRequest {
    return new GetRelatedQuerySuggestionsRequest().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GetRelatedQuerySuggestionsRequest
      | PlainMessage<GetRelatedQuerySuggestionsRequest>
      | undefined,
    b:
      | GetRelatedQuerySuggestionsRequest
      | PlainMessage<GetRelatedQuerySuggestionsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetRelatedQuerySuggestionsRequest, a, b);
  }
}

/**
 * @generated from message context_engine.GetQuerySuggestionsResult
 */
export class GetQuerySuggestionsResult extends Message<GetQuerySuggestionsResult> {
  /**
   * @generated from field: repeated context_engine.QuerySuggestion query_suggestions = 1;
   */
  querySuggestions: QuerySuggestion[] = [];

  constructor(data?: PartialMessage<GetQuerySuggestionsResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetQuerySuggestionsResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'query_suggestions',
      kind: 'message',
      T: QuerySuggestion,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetQuerySuggestionsResult {
    return new GetQuerySuggestionsResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetQuerySuggestionsResult {
    return new GetQuerySuggestionsResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetQuerySuggestionsResult {
    return new GetQuerySuggestionsResult().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetQuerySuggestionsResult
      | PlainMessage<GetQuerySuggestionsResult>
      | undefined,
    b:
      | GetQuerySuggestionsResult
      | PlainMessage<GetQuerySuggestionsResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetQuerySuggestionsResult, a, b);
  }
}

/**
 * @generated from message context_engine.GetRelatedQuerySuggestionsResult
 */
export class GetRelatedQuerySuggestionsResult extends Message<GetRelatedQuerySuggestionsResult> {
  /**
   * @generated from field: repeated context_engine.QuerySuggestion query_suggestions = 1;
   */
  querySuggestions: QuerySuggestion[] = [];

  constructor(data?: PartialMessage<GetRelatedQuerySuggestionsResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetRelatedQuerySuggestionsResult';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'query_suggestions',
      kind: 'message',
      T: QuerySuggestion,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetRelatedQuerySuggestionsResult {
    return new GetRelatedQuerySuggestionsResult().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetRelatedQuerySuggestionsResult {
    return new GetRelatedQuerySuggestionsResult().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetRelatedQuerySuggestionsResult {
    return new GetRelatedQuerySuggestionsResult().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GetRelatedQuerySuggestionsResult
      | PlainMessage<GetRelatedQuerySuggestionsResult>
      | undefined,
    b:
      | GetRelatedQuerySuggestionsResult
      | PlainMessage<GetRelatedQuerySuggestionsResult>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetRelatedQuerySuggestionsResult, a, b);
  }
}

/**
 * @generated from message context_engine.GetQuerySuggestionsError
 */
export class GetQuerySuggestionsError extends Message<GetQuerySuggestionsError> {
  /**
   * @generated from oneof context_engine.GetQuerySuggestionsError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_query = 1;
         */
        value: Empty;
        case: 'missingQuery';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetQuerySuggestionsError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetQuerySuggestionsError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'missing_query', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetQuerySuggestionsError {
    return new GetQuerySuggestionsError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetQuerySuggestionsError {
    return new GetQuerySuggestionsError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetQuerySuggestionsError {
    return new GetQuerySuggestionsError().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetQuerySuggestionsError
      | PlainMessage<GetQuerySuggestionsError>
      | undefined,
    b:
      | GetQuerySuggestionsError
      | PlainMessage<GetQuerySuggestionsError>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetQuerySuggestionsError, a, b);
  }
}

/**
 * @generated from message context_engine.GetRelatedQuerySuggestionsError
 */
export class GetRelatedQuerySuggestionsError extends Message<GetRelatedQuerySuggestionsError> {
  /**
   * @generated from oneof context_engine.GetRelatedQuerySuggestionsError.err
   */
  err:
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_query = 1;
         */
        value: Empty;
        case: 'missingQuery';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty agent_not_found = 2;
         */
        value: Empty;
        case: 'agentNotFound';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty missing_source = 3;
         */
        value: Empty;
        case: 'missingSource';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetRelatedQuerySuggestionsError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.GetRelatedQuerySuggestionsError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'missing_query', kind: 'message', T: Empty, oneof: 'err' },
    { no: 2, name: 'agent_not_found', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'missing_source', kind: 'message', T: Empty, oneof: 'err' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): GetRelatedQuerySuggestionsError {
    return new GetRelatedQuerySuggestionsError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): GetRelatedQuerySuggestionsError {
    return new GetRelatedQuerySuggestionsError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): GetRelatedQuerySuggestionsError {
    return new GetRelatedQuerySuggestionsError().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | GetRelatedQuerySuggestionsError
      | PlainMessage<GetRelatedQuerySuggestionsError>
      | undefined,
    b:
      | GetRelatedQuerySuggestionsError
      | PlainMessage<GetRelatedQuerySuggestionsError>
      | undefined,
  ): boolean {
    return proto3.util.equals(GetRelatedQuerySuggestionsError, a, b);
  }
}
