import { Title as DIGTitle } from '@dropbox/dig-components/typography';
import { Box, Split } from '@dropbox/dig-foundations';
import { forwardRef } from 'react';

import type { TitlePropsWeightVariant } from '@dropbox/dig-components/typography';
import type { SplitProps } from '@dropbox/dig-foundations';
import type { ReactNode } from 'react';

const sizes = {
  // DIG defaults
  large: {
    fontSize: '28px',
    lineHeight: '36px',
  },
  standard: {
    fontSize: '22px',
    lineHeight: '28px',
  },
  small: {
    fontSize: '16px',
    lineHeight: '26px',
  },

  28: {
    // large
    fontSize: '28px',
    lineHeight: '36px',
  },
  24: {
    // custom
    fontSize: '24px',
    lineHeight: '28px',
  },
  22: {
    // standard
    fontSize: '22px',
    lineHeight: '28px',
  },
  20: {
    // custom
    fontSize: '20px',
    lineHeight: '28px',
  },
  18: {
    // custom
    fontSize: '18px',
    lineHeight: '26px',
  },
  16: {
    // small
    fontSize: '16px',
    lineHeight: '24px',
  },
};

export const Title = forwardRef<
  HTMLElement,
  Omit<TitlePropsWeightVariant, 'size'> & {
    gap?: SplitProps['gap'];
    size?: keyof typeof sizes;
    withAccessoryStart?: ReactNode;
    withAccessoryEnd?: ReactNode;
    alignY?: SplitProps['alignY'];
  }
>(
  (
    {
      size,
      gap = '8',
      alignY = 'center',
      withAccessoryStart,
      withAccessoryEnd,
      children,
      ...titleProps
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref,
  ) => (
    <DIGTitle
      {...titleProps}
      style={sizes[size ?? 'standard']}
      weightVariant="emphasized"
    >
      <Split gap={gap} alignY={alignY}>
        {withAccessoryStart && (
          <Box as={Split.Item} display="flex">
            {withAccessoryStart}
          </Box>
        )}
        <Split.Item width="fill" style={{ width: '100%' }}>
          <>{children}</>
        </Split.Item>
        {withAccessoryEnd && (
          <Split.Item style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {withAccessoryEnd}
          </Split.Item>
        )}
      </Split>
    </DIGTitle>
  ),
);

Title.displayName = 'Title';
