import noop from 'lodash/noop';

import type { FLIPOrchestrator } from './FLIPOrchestrator';

/** An orchestrator that doesn't run any animations. */
class NoopFLIPOrchestrator implements FLIPOrchestrator {
  register() {
    return noop;
  }

  subscribe() {
    return noop;
  }

  dispose() {
    // Noop
  }
}

// No need to ever create more than one instance.
export const noopFLIPOrchestrator = new NoopFLIPOrchestrator();
