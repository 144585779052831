import { StackIcon } from '@dropbox/dash-component-library';
import { Banner } from '@dropbox/dig-components/banner';
import { Button } from '@dropbox/dig-components/buttons';
import { RadioButton, Toggle } from '@dropbox/dig-components/controls';
import {
  Select,
  TextArea,
  TextInput,
} from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Split, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { EmojiSmileLine } from '@dropbox/dig-icons/assets';
import { tagged } from '@mirage/service-logging';
import { ColorPicker } from '@mirage/stacks/ColorPicker';
import { EmojiPicker } from '@mirage/stacks/FullScreenStack/EmojiPicker';
import { getTheme } from '@mirage/stacks/themes';
import { meticulousRedactedStringClass } from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { SourceSelect } from './SourceSelect';
import styles from './WorkflowStep.module.css';

import type {
  WorkflowStep as WorkflowStepType,
  WorkflowStepInputType,
} from './types';
import type { WorkflowStepValuesType } from '@mirage/service-conversation/types';
import type { ChangeEvent } from 'react';

const logger = tagged('WorkflowStep');

export const WorkflowStep = ({
  step,
  currentValues,
  onCancel,
  onSubmit,
}: {
  step: WorkflowStepType;
  currentValues?: WorkflowStepValuesType;
  onCancel: () => void;
  onSubmit: (values: WorkflowStepValuesType) => void;
}) => {
  const [selectedInputValues, setSelectedInputValues] =
    useState<WorkflowStepValuesType>(currentValues ?? {});
  const [inputsValid, setInputsValid] = useState(false);

  const handleSubmit = () => {
    onSubmit(selectedInputValues);
  };

  /**
   * Validate that all required inputs have been filled
   * @returns true if all required inputs have been filled, false otherwise
   */
  const validateRequiredInputs = useCallback(
    (inputs: WorkflowStepInputType[], inputValues: WorkflowStepValuesType) => {
      const isValid = inputs.every((input: WorkflowStepInputType): boolean => {
        const value = inputValues[input.id];
        if (input.required && !value) {
          return false;
        }
        if (typeof value === 'string' && value.trim() === '') {
          return false;
        }
        if (input.type === 'combo-step') {
          return validateRequiredInputs(
            input.comboInputs as WorkflowStepInputType[],
            value as WorkflowStepValuesType,
          );
        }
        return true;
      });
      logger.info('are inputes valid?', isValid);
      logger.info('selectedInputValues', selectedInputValues);
      return isValid;
    },
    [selectedInputValues],
  );

  useEffect(() => {
    setInputsValid(validateRequiredInputs(step.inputs, selectedInputValues));
  }, [selectedInputValues, step.inputs, validateRequiredInputs]);

  // Group inputs by their group property
  const groupedInputs = step.inputs.reduce<{
    [key: string]: WorkflowStepInputType[];
  }>((acc, input) => {
    const group = input.group || `uniq-${input.id}`;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(input);
    return acc;
  }, {});

  const renderInputGroup = (inputs: WorkflowStepInputType[]) => {
    if (inputs.length === 1) {
      const input = inputs[0];
      return (
        <Stack key={input.id} gap="Micro Small">
          {input.type === 'combo-step' && input.comboInputs && (
            <WorkflowStepComboStep
              input={input}
              selectedInputValues={selectedInputValues}
              setSelectedInputValues={setSelectedInputValues}
            />
          )}
          {input.type === 'boolean' && (
            <Split alignX="between" alignY="center">
              <Split.Item>
                <WorkflowStepLabel input={input} />
              </Split.Item>
              <Split.Item>
                <WorkflowStepInput
                  input={input}
                  selectedInputValues={selectedInputValues}
                  setSelectedInputValues={setSelectedInputValues}
                />
              </Split.Item>
            </Split>
          )}
          {input.type !== 'boolean' && input.type !== 'combo-step' && (
            <>
              {input.type !== 'source-select' && (
                <WorkflowStepLabel input={input} />
              )}
              <WorkflowStepInput
                input={input}
                selectedInputValues={selectedInputValues}
                setSelectedInputValues={setSelectedInputValues}
              />
            </>
          )}
        </Stack>
      );
    }

    return (
      <Split key={inputs[0].group} gap="Macro Medium" alignX="left">
        {inputs.map((input) => (
          <Split.Item key={input.id}>
            <Stack gap="Micro Small">
              <WorkflowStepLabel input={input} />
              <WorkflowStepInput
                input={input}
                selectedInputValues={selectedInputValues}
                setSelectedInputValues={setSelectedInputValues}
              />
            </Stack>
          </Split.Item>
        ))}
      </Split>
    );
  };

  return (
    <Stack gap="Macro Small">
      {Object.values(groupedInputs).map(renderInputGroup)}

      {/* Footer */}
      <Split alignX="right" gap="Micro Small">
        <Split.Item>
          <Button variant="opacity" onClick={onCancel}>
            Back
          </Button>
        </Split.Item>
        <Split.Item>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!inputsValid}
          >
            {step.step === step.inputs.length ? 'Publish' : 'Next'}
          </Button>
        </Split.Item>
      </Split>
    </Stack>
  );
};

const WorkflowStepLabel = ({ input }: { input: WorkflowStepInputType }) => {
  return (
    <Stack gap="Micro XSmall">
      <Text tagName="div" isBold>
        {input.name}
      </Text>
      {input.description && (
        <Text tagName="div" color="subtle">
          {input.description}
        </Text>
      )}
    </Stack>
  );
};

const WorkflowStepInput = ({
  input,
  className,
  selectedInputValues,
  setSelectedInputValues,
}: {
  input: WorkflowStepInputType;
  className?: string;
  selectedInputValues: WorkflowStepValuesType;
  setSelectedInputValues: (values: WorkflowStepValuesType) => void;
}) => {
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);

  switch (input.type) {
    case 'boolean':
      return (
        <Toggle
          id={input.id}
          checked={selectedInputValues[input.id] as boolean}
          onChange={(e) => {
            const value = e.target.checked;
            setSelectedInputValues({
              ...selectedInputValues,
              [input.id]: value,
            });
          }}
        />
      );
    case 'select':
      return (
        <Select
          id={input.id}
          placeholder={input.placeholder}
          value={selectedInputValues[input.id] as string}
          className={classnames(meticulousRedactedStringClass, className)}
          onChange={(value) => {
            setSelectedInputValues({
              ...selectedInputValues,
              [input.id]: value,
            });
          }}
        >
          {input.availableOptions?.map((option) => (
            <Select.Option
              key={option.id}
              value={option.id}
              disabled={option.disabled}
            >
              {option.label}
            </Select.Option>
          ))}
        </Select>
      );
    case 'paragraph':
      return (
        <TextArea
          id={input.id}
          placeholder={input.placeholder}
          className={classnames(meticulousRedactedStringClass, className)}
          value={selectedInputValues[input.id] as string}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setSelectedInputValues({
              ...selectedInputValues,
              [input.id]: e.target.value,
            });
          }}
        />
      );
    case 'text':
      return (
        <TextInput
          id={input.id}
          placeholder={input.placeholder}
          value={selectedInputValues[input.id] as string}
          className={classnames(meticulousRedactedStringClass, className)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedInputValues({
              ...selectedInputValues,
              [input.id]: e.target.value,
            });
          }}
        />
      );
    case 'radio-select':
      return (
        <Stack gap="Micro Small">
          {input.availableOptions?.map((option) => (
            <Split key={option.id} alignY="center" gap="Micro Medium">
              <Split.Item>
                <RadioButton
                  id={option.id}
                  checked={selectedInputValues[input.id] === option.id}
                  onChange={() => {
                    setSelectedInputValues({
                      ...selectedInputValues,
                      [input.id]: option.id,
                    });
                  }}
                />
              </Split.Item>
              <Split.Item>
                <label htmlFor={option.id}>
                  <Text tagName="div" htmlFor={option.id}>
                    {option.label}
                  </Text>
                  {option.description && (
                    <Text tagName="div" color="subtle" htmlFor={option.id}>
                      {option.description}
                    </Text>
                  )}
                </label>
              </Split.Item>
            </Split>
          ))}
        </Stack>
      );
    case 'icon-select': {
      const isEmoji = !!selectedInputValues[input.id];
      return (
        <>
          <StackIcon
            size="xlarge"
            theme={
              getTheme(parseInt(selectedInputValues.theme as string, 10))
                .primary
            }
            variant={isEmoji ? 'emoji' : 'icon'}
            icon={
              selectedInputValues[input.id] || <UIIcon src={EmojiSmileLine} />
            }
            onClick={() => {
              setEmojiPickerVisible(true);
            }}
          />
          {emojiPickerVisible && (
            <EmojiPicker
              setEmojiPickerVisible={setEmojiPickerVisible}
              onEmojiSelect={(newEmoji) => {
                setSelectedInputValues({
                  ...selectedInputValues,
                  [input.id]: newEmoji.native,
                });
                setEmojiPickerVisible(false);
              }}
              papProps={{}}
            />
          )}
        </>
      );
    }
    case 'color-select':
      return (
        <Split gap="10">
          <Split.Item
            style={{
              ['--circle-container-gap' as string]: '10px',
            }}
          >
            <ColorPicker
              colorIndex={
                parseInt(selectedInputValues[input.id] as string, 10) ?? 0
              }
              menuKey={input.id}
              className={styles.colorPicker}
              onSelect={(index) => {
                setSelectedInputValues({
                  ...selectedInputValues,
                  [input.id]: index.toString(),
                });
              }}
            />
          </Split.Item>
        </Split>
      );
    case 'source-select':
      return (
        <>
          <Banner type="warning" style={{ marginBottom: 20 }}>
            <Banner.Message>
              Previously added sources will only be visible within the current
              editing session due to technical limitations.
            </Banner.Message>
          </Banner>
          <SourceSelect
            value={selectedInputValues[input.id] as string[]}
            onChange={(sourceIds) => {
              setSelectedInputValues({
                ...selectedInputValues,
                [input.id]: sourceIds,
              });
            }}
          />
        </>
      );
  }

  return null;
};

const WorkflowStepComboStep = ({
  input,
  selectedInputValues,
  setSelectedInputValues,
}: {
  input: WorkflowStepInputType;
  selectedInputValues: WorkflowStepValuesType;
  setSelectedInputValues: (values: WorkflowStepValuesType) => void;
}) => {
  return (
    <>
      <Split key={input.id} alignX="between">
        <Split.Item>
          <Text
            tagName="div"
            size="large"
            isBold
            style={{ fontFamily: 'var(--dig-type__titlefontstack)' }}
          >
            {input.name}
          </Text>
        </Split.Item>
        <Split.Item>
          <Split alignY="center" gap="Micro Small">
            <Split.Item>
              <Text
                tagName="label"
                color="disabled"
                htmlFor={`${input.id}-required`}
              >
                Required
              </Text>
            </Split.Item>
            <Split.Item>
              <Toggle id={`${input.id}-required`} isToggled disabled />
            </Split.Item>
          </Split>
        </Split.Item>
      </Split>
      <Split
        key={`${input.id}-combo-inputs`}
        gap="Micro Small"
        style={{
          justifyContent: 'space-between',
        }}
      >
        {input.comboInputs?.map((comboInput) => {
          // Get the nested values for this comboInput step
          const comboInputValues: WorkflowStepValuesType = selectedInputValues[
            input.id
          ] as WorkflowStepValuesType;

          return (
            <Split.Item
              key={comboInput.id}
              marginRight="auto"
              className={styles.comboInput}
            >
              <WorkflowStepInput
                input={comboInput}
                selectedInputValues={comboInputValues}
                setSelectedInputValues={(values) => {
                  setSelectedInputValues({
                    ...selectedInputValues,
                    [input.id]: {
                      ...comboInputValues,
                      ...values,
                    },
                  });
                }}
              />
            </Split.Item>
          );
        })}
      </Split>
      {input.description && (
        <Text tagName="div" color="subtle" size="small">
          {input.description}
        </Text>
      )}
    </>
  );
};
