import { Box, Stack } from '@dropbox/dig-foundations';
import { ConnectPrompt } from '@mirage/settings/components/connectors/ConnectPrompt';
import { useConnectConnector } from '@mirage/settings/hooks/connectors/useConnectConnector';
import {
  getConnectionMetadata,
  isUIConnection,
} from '@mirage/shared/connectors/util';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useState } from 'react';
import { useOnboardingConnectors } from '../hooks/useOnboardingConnectors';
import { Connector } from './Connector';
import styles from './TeamOnboardingConnectorsList.module.css';

import type {
  UIConnectionOrUIConnector,
  UIConnector,
} from '@mirage/shared/types';

interface Props {
  snackbarMarginBottom?: number;
  isMobile?: boolean;
}
export const TeamOnboardingConnectorList: React.FC<Props> = ({
  snackbarMarginBottom = 0,
  isMobile = false,
}) => {
  const onboardingConnectors = useOnboardingConnectors();

  return (
    <Stack
      gap="Macro Large"
      paddingTop="Macro Small"
      style={{ marginBottom: snackbarMarginBottom }}
    >
      <Box>
        <TwoColumnConnectorList
          connectionOrConnectors={onboardingConnectors}
          snackbarMarginBottom={snackbarMarginBottom}
          isMobile={isMobile}
        />
      </Box>
    </Stack>
  );
};

interface TwoColumnConnectorListProps {
  connectionOrConnectors?: UIConnectionOrUIConnector[];
  snackbarMarginBottom: number;
  isMobile?: boolean;
}

// Most of logic (including `ConnectPrompt`) comes from `OnboardingConnectors.tsx`
const TwoColumnConnectorList: React.FC<TwoColumnConnectorListProps> = ({
  connectionOrConnectors,
  snackbarMarginBottom,
  isMobile = false,
}) => {
  const connectConnector = useConnectConnector({
    eventProps: { featureLine: 'onboarding' },
  });

  const [useConnectPrompt, setUseConnectPrompt] = useState<UIConnector | null>(
    null,
  );

  const onConnectInitiate = async (connector: UIConnector): Promise<void> => {
    if (connector?.auth_details?.prompt_configuration) {
      setUseConnectPrompt(connector);
    } else {
      const result = await connectConnector(connector);
      showSnackbar({
        title: result.message,
        marginBottom: snackbarMarginBottom,
      });
    }
  };

  const onConnectPromptSubmit = async (
    connector: UIConnector | undefined,
    values: Record<string, string>,
  ): Promise<void> => {
    if (connector) {
      setUseConnectPrompt(null);
      const result = await connectConnector(connector, values);
      showSnackbar({
        title: result.message,
        marginBottom: snackbarMarginBottom,
      });
    }
  };

  return (
    <Box>
      <div
        className={classnames(styles.connectorsContainer, {
          [styles.isMobile]: isMobile,
        })}
      >
        {connectionOrConnectors?.map((connectionOrConnector, index) => {
          const displayName =
            connectionOrConnector.branding?.display_name ?? '';
          const owner = isUIConnection(connectionOrConnector)
            ? getConnectionMetadata(connectionOrConnector).connectionOwner
            : '';
          return (
            <ConnectionOrConnector
              // There could be multiple connectors with the same display name when let user connect multiple accounts from the same connector
              // In that case, owner info should be unqiue. To be extra safe, we add index to the key since we are preserving the order on the UI
              key={displayName + owner + index}
              connectionOrConnector={connectionOrConnector}
              onConnectInitiate={onConnectInitiate}
            />
          );
        })}
      </div>
      {useConnectPrompt && (
        <ConnectPrompt
          connector={useConnectPrompt}
          onSubmit={(values) => onConnectPromptSubmit(useConnectPrompt, values)}
          onCancel={() => setUseConnectPrompt(null)}
        />
      )}
    </Box>
  );
};

interface ConnectionOrConnectorProps {
  connectionOrConnector: UIConnectionOrUIConnector;
  onConnectInitiate: (connector: UIConnector) => void;
}
const ConnectionOrConnector: React.FC<ConnectionOrConnectorProps> = ({
  connectionOrConnector,
  onConnectInitiate,
}) => {
  const { branding, loading } = connectionOrConnector;
  const displayName = branding?.display_name ?? '';
  const isConnection = isUIConnection(connectionOrConnector);
  let description;
  let type = '';
  if (isConnection) {
    const connection = connectionOrConnector;
    const { teamConnector, type: connectionType } =
      getConnectionMetadata(connection);
    description = teamConnector
      ? i18n.t('connected_by_admin_description')
      : i18n.t('added_by_user_description');
    type = connectionType!;
  } else {
    const connector = connectionOrConnector;
    type = connector.id_attrs?.type || '';
  }

  const onClick = () => {
    if (!isConnection) {
      onConnectInitiate(connectionOrConnector);
    }
  };

  return (
    <div className={styles.connector} data-testid="connectionOrConnector">
      <Connector
        connected={isConnection}
        description={description}
        label={displayName}
        icon_src={branding?.icon_src}
        loading={loading}
        onClick={onClick}
        type={type}
      />
    </div>
  );
};
