import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { getOperatingSystem } from '@mirage/shared/util/os';
import { runWithRetries } from '@mirage/shared/util/retries';
import debounce from 'lodash/debounce';

import type { client_metrics } from '@dropbox/api-v2-client';
import type {
  Metric,
  MetricSink,
  Tags,
} from '@mirage/service-operational-metrics/service';
import type { Environment } from '@mirage/shared/util/types';

const logger = tagged('metricSink');

// Aggregate time interval for flushing the metrics to server.
// Server-side time granularity for metrics is per minute.
const DEBOUNCE_INTERVAL_MS = 5000;
const DEBOUNCE_MAX_WAIT_MS = 15000;

export type ClientMetricBase = {
  namespace: string;
  name: string;
  timestampSec: number;
  value: number;
  tags?: Tags;
};

export type CounterMetric = { '.tag': 'counter' } & ClientMetricBase;

export type HistogramMetric = { '.tag': 'histogram' } & ClientMetricBase;

export type ClientMetric = CounterMetric | HistogramMetric;

export type ApiV2MetricsSinkProps = {
  artifactName: string;
  rootNamespace: string;
  environment: Environment;
  globalTags?: Tags;
  notifyMetricQueued?: (metric: ClientMetric) => void;
  notifyMetricsSent?: (metrics: ClientMetric[]) => void;
};

export default function apiV2MetricsSink({
  artifactName,
  // Note that this implementation intentionally only supports a single root
  // namespace. We could support multiple roots, but there is no use case for
  // it right now, and doing that actually makes the metrics a bit less well-
  // organized. So for now, we will support only a single root namespace.
  rootNamespace,
  environment,
  globalTags,
  notifyMetricQueued,
  notifyMetricsSent,
}: ApiV2MetricsSinkProps): MetricSink {
  const queue: ClientMetric[] = [];

  // These hardcoded values are known to work, and updating them over time is
  // likely unnecessary.
  const constantRequestFields = {
    environment: environment === 'production' ? 'prod' : 'developer',
    artifact_name: artifactName,
    artifact_version: '0000000000000000000000000000000000000000',
    client_metadata: {
      client_version: 26,
      implementation: {
        '.tag': 'typescript',
      },
    },
    trigger: {
      '.tag': 'trigger_publish',
    },
    os: {
      name: {
        '.tag': getOperatingSystem() ?? 'other',
      },
      version: 'unknown',
    },
    origin_identifier: '',
  } as const;

  // Ref: typescript/libraries/shared/apex-metrics/src/sink/apiv2_sink.ts in rSERVER,
  // `makeRecordRequest` function of the same name.
  function makeRecordRequest(
    metrics: ClientMetric[],
  ): client_metrics.RecordRequest {
    return {
      ...constantRequestFields,
      known_namespaces: [rootNamespace],
      scopes: convertMetricsToScopes(metrics),
    };
  }

  /**
   * Most complex operation in this file. The basic idea is that the root scope
   * only contains the root namespace and the global tags, and all metrics with
   * their own tags will go into the descendents.
   *
   * Ref: typescript/libraries/shared/apex-metrics/src/sink/filter.ts in rSERVER,
   * `partitionAndSerializeSpans` function which uses indexed db.
   */
  function convertMetricsToScopes(
    metrics: ClientMetric[],
  ): client_metrics.RecordRequestScope[] {
    const rootScopes: client_metrics.RecordRequestScope[] = [];

    // Merge root scopes across spans and sets stores by tracking namespaces.
    // Also track whether each structure has already been added to the request
    // so duplicates are not added by different event handlers.
    const namespaceRootScopes = new Map<
      string,
      Required<client_metrics.RecordRequestScope>
    >();
    const scopesAdded = new Set<string>();

    for (const metric of metrics) {
      // Since we only support a single root namespace now, this value will
      // always be equal to rootNamespace.
      const namespace = metric.namespace;

      // If there is an existing root scope already, add descendents to it.
      // This might happen if a histogram and set metric use the same namespace.
      // Otherwise, just create a new root scope.
      const rootScope: Required<client_metrics.RecordRequestScope> =
        namespaceRootScopes.get(namespace) ?? {
          metric_namespace: namespace,
          timestamp_sec: metric.timestampSec,
          // Global tags only appear in the root scope.
          tags: globalTags ? convertTags(globalTags) : [],
          descendants: [],
        };
      namespaceRootScopes.set(namespace, rootScope);

      // Add the metric tags.
      const descendantScope: Required<client_metrics.FlattenedScopeV2> = {
        tags: metric.tags ? convertTags(metric.tags) : [],
        metrics: [],
        user_id_association: { '.tag': 'fs_v2_unknown' },
      };

      // Add metric to descendantScope.
      // Note that while this can be aggregated across multiple entries to
      // send samples with more than one item in the list, in practice, there
      // is no use case for it. One complexity with aggregation is also that
      // all the tags should be matching exactly before we aggregate (check
      // for diff to ensure aggregation is correct). This is just additional
      // work both during dev time and runtime that is not going to be used at
      // all, so it is better for us not to do it.
      const samples = [metric.value];

      // Add numerical data to descendantScope.
      // ref: serializeSamplesAsNumerical -> implemented here & simplified
      // ref: serializeSamplesAsSet -> not implemented here, no use case so far
      descendantScope.metrics.push({
        name: {
          value: {
            '.tag': 'string_value' as const,
            string_value: metric.name,
          },
        },
        data: { '.tag': 'numerical_data' as const, samples },
      });

      // Add to rootScope.
      rootScope.descendants.push(descendantScope);

      // Only add to return result if not already added. This assumes support
      // for multiple root namespaces, which is fine, but not needed now.
      if (!scopesAdded.has(namespace)) {
        rootScopes.push(rootScope);
        scopesAdded.add(namespace);
      }
    }

    return rootScopes;
  }

  async function reportClientMetrics(metrics: ClientMetric[]) {
    const request = makeRecordRequest(metrics);

    // No need user auth here since metrics are never tied to users since
    // doing that will make the cardinality too high.
    return await runWithRetries(() =>
      callApiV2('clientMetricsRecord', request, 'app'),
    );
  }

  async function flush() {
    if (!queue.length) return;

    const metrics = queue.splice(0, queue.length);

    try {
      await reportClientMetrics(metrics);
    } catch (e) {
      logger.warn('Failed to report client metrics', e);
    }

    // Notify even if the send failed.
    notifyMetricsSent?.(metrics);
  }

  // Flush metrics when the page is hidden to avoid metrics being lost.
  addEventListener('visibilitychange', flush);

  // Flush metrics when there is no more metric for a few seconds, but since
  // the metrics are a bit time-sensitive, don't wait for too long to flush.
  const debouncedFlush = debounce(flush, DEBOUNCE_INTERVAL_MS, {
    maxWait: DEBOUNCE_MAX_WAIT_MS,
  });

  function logMetric(metric: ClientMetric) {
    queue.push(metric);
    notifyMetricQueued?.(metric);

    debouncedFlush();
  }

  function createMetric(
    metric: Metric,
    value: number,
    tags?: Tags,
  ): ClientMetricBase {
    return {
      namespace: rootNamespace,
      name: `${metric.ns}/${metric.name}`,
      // Note: Including decimals will cause all api calls to fail.
      timestampSec: Math.floor(Date.now() / 1000),
      value,
      tags,
    };
  }

  async function counter(metric: Metric, value: number, tags?: Tags) {
    logMetric({
      '.tag': 'counter',
      ...createMetric(metric, value, tags),
    });
  }

  async function stats(metric: Metric, value: number, tags?: Tags) {
    logMetric({
      '.tag': 'histogram',
      ...createMetric(metric, value, tags),
    });
  }

  return { counter, stats, flush };
}

function convertTags(tags: Tags): client_metrics.TagEntry[] {
  return Object.entries(tags).map(([tagName, tagValue]) => ({
    name: {
      value: {
        '.tag': 'string_value' as const,
        string_value: tagName,
      },
    },
    value: {
      value: {
        '.tag': 'string_value' as const,
        string_value: String(tagValue),
      },
    },
  }));
}
