import { Box } from '@dropbox/dig-foundations';
import { DEFAULT_SECTION_ID } from '@mirage/service-stacks/service/utils';
import { SectionWithItems } from '@mirage/stacks/StackCardPopover/Popover/SectionWithItems';

import type { stacks } from '@dropbox/api-v2-client';
import type { WorkingSetCardData } from '@mirage/working-set';

/** Popover content for displaying stack items grouped by sections
 *
 * @param stackItemsBySection
 * @param sections
 * @param stackData
 * @constructor
 */
export const PopoverContent = ({
  stackItemsBySection,
  sections,
  stackData,
}: {
  stackData: WorkingSetCardData;
  stackItemsBySection: { [sectionName: string]: stacks.StackItemShortcut[] };
  sections: stacks.Section[];
}) => {
  return (
    <Box>
      {/* If we have any stack items NOT in a section, let's display those first */}
      {stackItemsBySection[DEFAULT_SECTION_ID]?.length && (
        <SectionWithItems
          sectionItems={stackItemsBySection[DEFAULT_SECTION_ID]}
          stackData={stackData}
        />
      )}

      {/* Display all other stack items in a section */}
      {Object.entries(stackItemsBySection).map(([sectionId, sectionItems]) => {
        if (sectionId === DEFAULT_SECTION_ID) {
          return null;
        }

        const sectionName =
          sections.find((s) => s.id === sectionId)?.title || '';

        return (
          <SectionWithItems
            key={sectionId}
            sectionName={sectionName}
            sectionItems={sectionItems}
            stackData={stackData}
          />
        );
      })}
    </Box>
  );
};
