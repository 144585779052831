import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { meticulousRedactedStringClass } from '@mirage/translations';
import classnames from 'classnames';
import React, { useState } from 'react';
import { StackNameEditor } from '../EditStackDetails';
import styles from './Header.module.css';
import StackHeaderActions from './StackHeaderActions';

import type { StackDerivedPAPPropsWithFeature } from '../types';
import type { UpdateStackNameAndDescription } from './HeaderCommon';

interface StackTitleAreaProps {
  stackTitle: string;
  isNewStack: boolean;
  updateStackNameAndDescription: UpdateStackNameAndDescription;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  withImmediateAction?: React.ReactNode;
  withRightAction?: React.ReactNode;
  withInfo?: React.ReactNode;
  canEdit?: boolean;
}

const StackTitleArea: React.FC<StackTitleAreaProps> = ({
  stackTitle,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  withImmediateAction,
  withRightAction,
  withInfo,
  canEdit,
}) => {
  const [isEditingStackName, setIsEditingStackName] = useState(false);
  const isMobile = useIsMobileSize();

  return (
    <div className={styles.stackTitleArea}>
      <div className={styles.stackTitleRow}>
        <div
          className={classnames(
            styles.stackTitleContainer,
            meticulousRedactedStringClass,
            { [styles.stackTitleContainerEdit]: isEditingStackName },
            { [styles.stackTitleContainerEditVerticalFix]: isEditingStackName },
          )}
        >
          <StackNameEditor
            stackName={stackTitle}
            isNewStack={isNewStack}
            updateStackNameAndDescription={updateStackNameAndDescription}
            stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
            hasWritePermissionsOverride={canEdit}
            onEditStatusUpdate={setIsEditingStackName}
          />
          {!isEditingStackName && (
            <div className={styles.immediateActions}>{withImmediateAction}</div>
          )}
        </div>
        {!isMobile && <StackHeaderActions action={withRightAction} />}
      </div>
      {withInfo}
    </div>
  );
};

export default StackTitleArea;
