import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { MAX_INPUT_LENGTH } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { useTimeoutEffect } from '@react-hookz/web';
import { useCallback, useRef, useState } from 'react';
import styles from './EditStackItemModal.module.css';

import type { TextInputRefObject } from '@dropbox/dig-components/text_fields';

interface EditStackItemModalProps {
  originalStackItemName: string;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (data: { name?: string }) => void;
}

export const EditStackItemModal: React.FC<EditStackItemModalProps> = ({
  originalStackItemName,
  isOpen,
  onCancel,
  onSubmit,
}) => {
  const [stackItemName, setStackItemName] = useState(originalStackItemName);
  const isValid = stackItemName?.trim()?.length;

  const handleSubmit = useCallback(() => {
    const data: { name?: string } = {};
    const [newName, oldName] = [stackItemName, originalStackItemName].map((x) =>
      x.trim().replace(/\n+/gi, '\n'),
    );
    if (newName !== oldName) {
      data['name'] = newName;
    }
    onSubmit(data);
  }, [stackItemName, originalStackItemName, onSubmit]);

  const ref = useRef<TextInputRefObject>(null);
  useTimeoutEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.focus();
    ref.current.setSelectionRange(0, ref.current.value.length);
  }, 100); // Delay to ensure the input is rendered

  return (
    <Modal
      open={isOpen}
      width="small"
      withCloseButton="Close"
      onRequestClose={onCancel}
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title size="small">{i18n.t('rename_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextInput
          ref={ref}
          value={stackItemName}
          maxLength={MAX_INPUT_LENGTH}
          onChange={(e) => setStackItemName(e.currentTarget.value)}
          placeholder={i18n.t('rename_title_placeholder')}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && isValid) {
              handleSubmit();
            }
          }}
          wrapperProps={{ className: styles.modalTextInput }}
          className={styles.modalTextInput}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel}>
          {i18n.t('create_stack_modal_cancel_button')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!isValid}>
          {i18n.t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
