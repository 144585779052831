import { isEmail } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';

import type {
  UIConnection,
  UIConnectionOrUIConnector,
  UIConnector,
  UIWebExtension,
  WebExtensionProps,
} from '@mirage/shared/types';

export const getConnectionMetadata = (item: UIConnection | UIWebExtension) => {
  const isWebExtension = isBrowserExtension(item);
  const id = isWebExtension ? item.id : item.id_attributes?.id;
  const platform = isWebExtension
    ? item.id
    : item.id_attributes?.connector?.platform;
  const type = isWebExtension ? undefined : item.id_attributes?.connector?.type;
  const label = isWebExtension ? item.label : item.branding?.display_name;
  const authenticated = isWebExtension
    ? true
    : item.connection_status?.['.tag'] === 'connected';
  const isReauth = isWebExtension
    ? false
    : item.connection_status?.['.tag'] === 'reauth';
  const isExpired = isWebExtension
    ? false
    : item.connection_status?.['.tag'] === 'expired';
  const isSyncInProgress =
    isWebExtension || isExpired || isReauth
      ? false
      : item.syncing ||
        (item.sync_status?.sync_progress?.start_time &&
          !item.sync_status.sync_progress.end_time);

  const teamConnector =
    !isWebExtension && item.metadata?.owner?.owner?.['.tag'] === 'team';

  const connectedEmail =
    !isWebExtension && isEmail(item.metadata?.display_identifier)
      ? item.metadata?.display_identifier
      : null;
  const connectionOwner = teamConnector
    ? i18n.t('managed_by_admin')
    : connectedEmail;

  return {
    id,
    isWebExtension,
    platform,
    type,
    label,
    authenticated,
    isReauth,
    isExpired,
    isSyncInProgress,
    teamConnector,
    connectedEmail,
    connectionOwner,
  };
};

export const sortConnectorsByDisplayName: (
  connectors: UIConnector[],
) => UIConnector[] = (connectors) => {
  return connectors.sort((a, b) => {
    const aName = a.branding?.display_name?.toLowerCase();
    const bName = b.branding?.display_name?.toLowerCase();
    if (aName && bName) {
      return aName.localeCompare(bName);
    }
    return 0;
  });
};

export const sortConnectionsByDisplayName: (
  connections: UIConnection[],
) => UIConnection[] = (connections) => {
  return connections.sort((a, b) => {
    const aName = a.branding?.display_name?.toLowerCase();
    const bName = b.branding?.display_name?.toLowerCase();
    if (aName && bName) {
      return aName.localeCompare(bName);
    }
    return 0;
  });
};

export const sortConnectionsByAuthTypeAndDisplayName: (
  connections: UIConnection[],
) => UIConnection[] = (connections) => {
  const companyConnections = connections.filter((connection) => {
    const { teamConnector } = getConnectionMetadata(connection);
    return teamConnector;
  });
  const individualConnections = connections.filter((connection) => {
    const { teamConnector } = getConnectionMetadata(connection);
    return !teamConnector;
  });
  return sortConnectionsByDisplayName(companyConnections).concat(
    sortConnectionsByDisplayName(individualConnections),
  );
};

export function isUIConnection(
  connectionOrConnector: UIConnectionOrUIConnector,
): connectionOrConnector is UIConnection {
  return Object.prototype.hasOwnProperty.call(
    connectionOrConnector,
    'connection_status',
  );
}

export const isBrowserExtension = (
  props: unknown,
): props is WebExtensionProps =>
  Object.prototype.hasOwnProperty.call(props, 'installUrl');
