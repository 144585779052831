export type OnboardingSurveyAnswerOption = {
  label: string;
  value: string;
};

export enum OnboardingSurveyQuestionFieldType {
  ChipList,
  Select,
}

export type OnboardingSurveyQuestionId =
  | 'COMPANY_SIZE_V1'
  | 'INDUSTRY_V1'
  | 'IS_IT_ADMIN_V1'
  | 'TITLE_V1'
  | 'JOBS_TO_BE_DONE_V1'
  | 'KIND_OF_WORK_V1';

export type OnboardingSurveyQuestion = {
  questionId: OnboardingSurveyQuestionId;
  questionType: OnboardingSurveyQuestionFieldType;
  label: string;
  questionValues?: OnboardingSurveyAnswerOption[];
  isTeamBased: boolean;
};

export type OnboardingSurveyAnswer = {
  surveyQuestionId?: string;
  surveyAnswer?: string;
};
