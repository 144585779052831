import { PAP_Click_AppType } from '@mirage/analytics/events/types/click_app_type';
import { PAP_Click_SaveSources } from '@mirage/analytics/events/types/click_save_sources';
import { contentTypeOptionToString } from '@mirage/mosaics/Chat/components/chat/ContextInputMessage';
import { ContextInputMessages } from '@mirage/mosaics/Chat/components/chat/ContextInputMessages';
import { ConversationFollowUpSuggestions } from '@mirage/mosaics/Chat/components/chat/ConversationFollowUpSuggestions';
import { ConversationInput } from '@mirage/mosaics/Chat/components/chat/ConversationInput';
import { ConversationMessages } from '@mirage/mosaics/Chat/components/chat/ConversationMessages';
import { ConversationMessageSourceChips } from '@mirage/mosaics/Chat/components/chat/ConversationMessageSourceChips';
import { EmptyChatInstructions } from '@mirage/mosaics/Chat/components/chat/EmptyChatInstructions';
import { AddSourcesModal } from '@mirage/mosaics/Chat/components/compose-sources/AddSourcesModal';
import { getAllReferencedSources } from '@mirage/mosaics/Chat/utils/messageSources';
import { useAssistActionsRenderer } from '@mirage/mosaics/ComposeAssistant/containers/ComposeConversationAssistActions';
import { useAssistTemplatesContext } from '@mirage/mosaics/ComposeAssistant/data/AssistTemplatesContext';
import { useComposeAnalyticsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeAnalyticsContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { getSelectionPromptString } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-prompts';
import { createTransientSourceMessage } from '@mirage/mosaics/ComposeAssistant/data/TransientSources';
import { useOnboardingMode } from '@mirage/mosaics/ComposeAssistant/onboarding/useOnboardingMode';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import {
  getFirstMarkdownArtifact,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import type { ClickableEmptyChatInstructionsOption } from '@mirage/mosaics/Chat/components/chat/EmptyChatInstructions';
import type { ChatConversationMessageActionContext } from '@mirage/mosaics/Chat/types';
import type { TransientSource } from '@mirage/mosaics/ComposeAssistant/data/TransientSources';
import type {
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageMessage,
} from '@mirage/shared/compose/assist-message';

interface ComposeConversationProps {
  variant?: 'default' | 'condensed';
  rightPaneExpanded: boolean;
  setDidJustSendFirstWriteCommand?: (
    didJustSendFirstWriteCommand: boolean,
  ) => void;
  onOpenArtifact: () => void;
  handleClickAdd: () => void;
  setIsAddSourceModalOpen: (isOpen: boolean) => void;
  isAddSourceModalOpen: boolean;
  inputContainerRef?: React.RefObject<HTMLDivElement>;

  /** callback for showing list of all AI Actions. Action chips are only rendered if this is passed in. */
  onOpenListActionsModal?: () => void;
}
export const ComposeConversation = memo(
  ({
    variant = 'default',
    rightPaneExpanded,
    setDidJustSendFirstWriteCommand,
    onOpenArtifact,
    handleClickAdd,
    setIsAddSourceModalOpen,
    isAddSourceModalOpen,
    inputContainerRef,
    onOpenListActionsModal,
  }: ComposeConversationProps) => {
    const chatWithDashEnabled =
      useFeatureFlagValue('dash_assist_2024_10_12_chat_v1') === 'ON';
    const {
      messagesHistory,
      sources,
      addSource,
      removeSource,
      artifacts,
      setDraftConfig,
      sourcesContentCache,
      addRawMessage,
      postUserMessage,
      inputContext,
      setInputContext,
      currentSessionID,
      currentSessionDataId,
      isWaitingForResponse,
      progressString,
      setCustomMode,
      newSession,
      transientSources,
    } = useComposeCurrentSessionContext();
    const { templates } = useAssistTemplatesContext();
    const { logComposeEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_chat_pane',
      currentSessionID,
    });
    const markdownArtifact = useMemo(
      () => getFirstMarkdownArtifact(artifacts),
      [artifacts],
    );
    const transientSourcesMessage = useMemo(() => {
      return getTransientSourcesMessage(transientSources, messagesHistory);
    }, [messagesHistory, transientSources]);

    // If the user started by selecting a template, pluck that message from the messages to display so that
    // we can render it separately within ContextInputMessages
    const { messagesToDisplay, templateSelectedMessage } = useMemo(() => {
      const messages = transientSourcesMessage
        ? [...messagesHistory, transientSourcesMessage]
        : messagesHistory;
      let templateSelectedMessage:
        | ComposeAssistantConversationMessage
        | undefined;
      const filteredMessages = messages.filter((m) => {
        if (
          m.type === 'message' &&
          m.actionContext?.type === 'template_selected'
        ) {
          templateSelectedMessage = m;
          return false;
        }
        return true;
      });
      return { messagesToDisplay: filteredMessages, templateSelectedMessage };
    }, [messagesHistory, transientSourcesMessage]);

    // auto trigger draft generation with empty msg if user has just selected a template and sources
    const hasUserJustSelectedTemplate =
      templateSelectedMessage !== undefined && messagesHistory.length === 1;
    const hasSources = sources.length > 0;
    const hasTemplate = markdownArtifact?.draftConfig.templateID !== undefined;
    const autoTriggerDraft =
      hasUserJustSelectedTemplate && hasSources && hasTemplate;

    const handleCompleteContextInputs = useCallback(() => {
      // otherwise trigger normal content_type message when templates are disabled
      const text = markdownArtifact?.draftConfig.contentType
        ? i18n.t('compose_assistant_message_with_content_type', {
            contentType: contentTypeOptionToString(
              markdownArtifact.draftConfig.contentType,
            ).toLowerCase(),
          })
        : i18n.t('compose_assistant_message_generic');
      addRawMessage({
        type: 'message',
        role: 'assistant',
        text,
        ts: Date.now(),
      });
    }, [addRawMessage, markdownArtifact?.draftConfig.contentType]);
    const handleCloseAddSourcesModal = useCallback(() => {
      if (isAddSourceModalOpen && sources.length > 0) {
        logComposeEvent(
          PAP_Click_SaveSources({
            numberOfSelectedItems: sources.length,
          }),
          { actionSurfaceComponent: 'compose_source_modal' },
        );
      }
      setIsAddSourceModalOpen(false);

      // Allow the closing of the modal to post a user message in the case
      // where the user has selected a template and has selected sources
      if (autoTriggerDraft) {
        postUserMessage({
          mustGenerateDraft: true,
          mustIncludeSourceContents: true,
          rawPromptText:
            'Use the template to generate a draft using content from the provided source documents',
        });
      }
    }, [
      logComposeEvent,
      isAddSourceModalOpen,
      setIsAddSourceModalOpen,
      autoTriggerDraft,
      sources.length,
      postUserMessage,
    ]);

    const { onboardingMode } = useOnboardingMode({
      addSource,
      logComposeEvent,
      postUserMessage,
      setCustomMode,
      setDidJustSendFirstWriteCommand,
      setIsAddSourceModalOpen,
      sources,
      currentSessionDataId,
    });

    const showEmptyChatInstructions =
      messagesToDisplay.length === 0 &&
      markdownArtifact === undefined &&
      sources.length === 0 &&
      !onboardingMode;
    const handleSubmitInput = useCallback(
      (text: string) => {
        const rawPromptText = inputContext
          ? getSelectionPromptString(text, inputContext.selectedText)
          : undefined;
        const actionContext: ChatConversationMessageActionContext | undefined =
          inputContext
            ? {
                type: 'compose_selection_edit',
                selectedText: inputContext.selectedText,
              }
            : undefined;
        const hasNonEmptyMarkdownContent = Boolean(
          markdownArtifact && markdownArtifact.markdownContent.length > 0,
        );

        // if there was a message about transient sources that the user is responding to, add that
        // message to the message history first
        const appendRawMessages = transientSourcesMessage
          ? [transientSourcesMessage]
          : undefined;

        if (
          getUserMessages(messagesHistory).length === 0 &&
          !hasNonEmptyMarkdownContent
        ) {
          // first user message with empty draft must trigger new draft generation
          postUserMessage({
            text,
            rawPromptText,
            mustGenerateDraft: true,
            mustIncludeSourceContents: true,
            actionContext,
            appendRawMessages,
          });
          setDidJustSendFirstWriteCommand?.(true);
        } else {
          postUserMessage({
            text,
            rawPromptText,
            actionContext,
            appendRawMessages,
          });
        }
        if (showEmptyChatInstructions) {
          logComposeEvent(PAP_Click_AppType({ actionType: 'open_chat' }));
        }
      },
      [
        inputContext,
        markdownArtifact,
        messagesHistory,
        postUserMessage,
        setDidJustSendFirstWriteCommand,
        transientSourcesMessage,
        logComposeEvent,
        showEmptyChatInstructions,
      ],
    );

    const hasCustomInstruction = messagesHistory.some(
      (message) => message.type === 'instruction',
    );
    // Update the URL based on the current state: for empty sessions clear search params,
    // if user clicked on the sidebar 'Assistant' button then go back to new session page,
    // and for non-empty sessions set the 'app' param to 'chat' or 'write'
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
      if (location.state?.reset) {
        newSession([]);
        navigate(location.pathname, { replace: true }); // go to new session page and remove the state.reset property
        return;
      }
      if (showEmptyChatInstructions) {
        if ([...searchParams].length > 0) {
          setSearchParams({});
        }
      } else {
        const appName = markdownArtifact ? 'write' : 'chat';
        if (searchParams.get('app') !== appName) {
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set('app', appName);
          setSearchParams(newSearchParams);
        }
      }
    }, [
      showEmptyChatInstructions,
      markdownArtifact,
      setSearchParams,
      searchParams,
      newSession,
      location.state,
      navigate,
      location.pathname,
    ]);
    const isWriteApp = !hasCustomInstruction && !!markdownArtifact;
    // If a user selects a template and sources we start drafting
    const didUserSelectTemplate =
      isWriteApp && markdownArtifact?.draftConfig.templateID !== undefined;
    const hasSentPrompt = messagesToDisplay.some(
      (m): m is ComposeAssistantConversationMessageMessage =>
        m.role === 'assistant' &&
        m.type === 'message' &&
        m.actionContext?.type === 'done',
    );

    const startingMessageNode = showEmptyChatInstructions ? (
      <ComposeEmptyChatInstructions chatWithDashEnabled={chatWithDashEnabled} />
    ) : (
      isWriteApp &&
      !hasSentPrompt && (
        <ContextInputMessages
          key={currentSessionID} // reset inputs when session changes
          handleClickAdd={handleClickAdd}
          sources={sources}
          sourcesContentCache={sourcesContentCache}
          removeSource={removeSource}
          draftConfig={markdownArtifact?.draftConfig || {}}
          setDraftConfig={setDraftConfig}
          onCompleteInputs={handleCompleteContextInputs}
          logComposeEvent={logComposeEvent}
          addRawMessage={addRawMessage}
          templates={templates || []}
          templateSelectedMessage={templateSelectedMessage}
        />
      )
    );
    const lastMessage =
      messagesHistory.length > 0 && messagesHistory[messagesHistory.length - 1];
    const isLastMessageFollowUpSuggestions = !!(
      lastMessage as ComposeAssistantConversationMessageMessage
    ).followUpSuggestions?.length;
    const renderNewSources = useCallback(() => {
      const referencedSources = getAllReferencedSources(messagesToDisplay);
      const newSources = sources.filter(
        (source) => !referencedSources.has(getSourceUUID(source)!),
      );
      if (newSources.length > 0) {
        return (
          <ConversationMessageSourceChips
            sources={newSources}
            removeSource={removeSource}
            logComposeEvent={logComposeEvent}
          />
        );
      }
      return null;
    }, [messagesToDisplay, sources, removeSource, logComposeEvent]);
    const renderMessageActions = useAssistActionsRenderer(
      transientSourcesMessage,
      onOpenListActionsModal,
    );
    return (
      <>
        <ConversationMessages
          variant={variant}
          renderNewSources={renderNewSources}
          isLastMessageFollowUpSuggestions={isLastMessageFollowUpSuggestions}
          startingMessageNode={startingMessageNode}
          showStartingNodeAfterContextInputs={didUserSelectTemplate}
          messages={messagesToDisplay}
          isWaitingForResponse={isWaitingForResponse}
          progressString={progressString}
          onRemoveSource={removeSource}
          artifacts={artifacts}
          logComposeEvent={logComposeEvent}
          onOpenArtifact={onOpenArtifact}
          renderMessageActions={renderMessageActions}
        />
        {isLastMessageFollowUpSuggestions && (
          <ConversationFollowUpSuggestions
            rightPaneExpanded={rightPaneExpanded}
            message={messagesHistory[messagesHistory.length - 1]}
            onClickFollowUpSuggestion={(suggestion: string) => {
              postUserMessage({
                text: suggestion,
                isFollowUpSuggestion: true,
              });
            }}
          />
        )}
        <ConversationInput
          variant={variant}
          onSubmit={handleSubmitInput}
          inputContext={inputContext}
          setInputContext={setInputContext}
          logComposeEvent={logComposeEvent}
          handleClickAdd={handleClickAdd}
          sources={sources}
          messages={messagesHistory}
          containerRef={inputContainerRef}
        />
        {isAddSourceModalOpen && (
          <AddSourcesModal
            sources={sources}
            sourcesContentCache={sourcesContentCache}
            addSource={addSource}
            removeSource={removeSource}
            onRequestClose={handleCloseAddSourcesModal}
            logComposeEvent={logComposeEvent}
          />
        )}
      </>
    );
  },
);
ComposeConversation.displayName = 'ComposeConversation';

interface ComposeEmptyChatInstructionsProps {
  chatWithDashEnabled: boolean;
}
export const ComposeEmptyChatInstructions = memo(
  ({ chatWithDashEnabled }: ComposeEmptyChatInstructionsProps) => {
    const { addArtifact, addRawMessage, currentSessionID } =
      useComposeCurrentSessionContext();
    const { logComposeEvent } = useComposeAnalyticsContext({
      actionSurfaceComponent: 'compose_chat_pane',
      currentSessionID,
    });
    const navigate = useNavigate();
    const handleClickStartingOption = useCallback(
      (option: ClickableEmptyChatInstructionsOption) => {
        logComposeEvent(PAP_Click_AppType({ actionType: option }));
        switch (option) {
          case 'write':
            addArtifact({
              type: 'markdown_draft',
              id: uuidv4(),
              markdownContent: '',
              draftConfig: {},
            });
            return;
          case 'chat_with_dash':
            addRawMessage({
              type: 'instruction',
              role: 'assistant',
              title: i18n.t('assistant_initial_generic_prompt_message'),
            });
            return;
          case 'create-ai-tool':
            navigate(RoutePath.NEW_WORKFLOW_AGENT);
            return;
          case 'workflow-agent':
            // This should never actually be called here,
            // but it's here to satisfy the type checker
            return;
          default:
            option satisfies never;
            throw new Error(`Unknown option: ${option}`);
        }
      },
      [addArtifact, addRawMessage, logComposeEvent, navigate],
    );
    return (
      <EmptyChatInstructions
        chatWithDashEnabled={chatWithDashEnabled}
        onClickOption={handleClickStartingOption}
      />
    );
  },
);
ComposeEmptyChatInstructions.displayName = 'ComposeEmptyChatInstructions';

function getUserMessages(
  messages: ComposeAssistantConversationMessage[],
): ComposeAssistantConversationMessage[] {
  return messages.filter((message) => message.role === 'user');
}

function getTransientSourcesMessage(
  transientSources: TransientSource[] | undefined,
  messagesHistory: ComposeAssistantConversationMessage[],
): ComposeAssistantConversationMessage | undefined {
  // look for transient sources that were never referenced by a message
  const referencedSourcesUUIDs = new Set<string>();
  for (const message of messagesHistory) {
    if (message.type === 'message') {
      for (const source of message.referencingSources || []) {
        const uuid = getSourceUUID(source);
        if (uuid) {
          referencedSourcesUUIDs.add(uuid);
        }
      }
    }
  }
  const unreferencedSources = (transientSources || []).filter(
    (transientSource) => {
      const uuid = getSourceUUID(transientSource.source);
      return uuid && !referencedSourcesUUIDs.has(uuid);
    },
  );
  if (unreferencedSources.length === 0) {
    return undefined;
  }

  // if unreferenced sources exist, return a message to ask user if they want to ask about it
  return createTransientSourceMessage(unreferencedSources[0]);
}
