import { tagged } from '@mirage/service-logging';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { createConversationResponseObservable } from './context_observables';

import type { ConversationsAssistantResponse } from './gen/context_engine_conversations_service_pb';
import type { ConversationsChatMessage } from './gen/conversations_pb';
import type { ConversationsResult } from './types';
import type { Observable } from 'rxjs';

const logger = tagged('conversations_grpc');

export function getConversationResponse(
  userMessage: string,
  conversationId: string,
  history?: ConversationsChatMessage[],
  documentIds?: string[],
  experimentSetting?: string,
  source?: string,
): Observable<ConversationsResult> {
  const conversation$ = createConversationResponseObservable(
    userMessage,
    conversationId,
    history,
    documentIds,
    experimentSetting,
    source,
  );

  return conversation$.pipe(
    map((response: ConversationsAssistantResponse): ConversationsResult => {
      return {
        conversationId,
        messages: response.messages ?? [],
        timestamp: new Date().toISOString(),
      };
    }),
    catchError((error) => {
      logger.error('getConversationResponse error', error);
      const errorSummary: ConversationsResult = {
        conversationId,
        error: 'Failed to fetch conversation response. ' + error,
        messages: [],
        timestamp: new Date().toISOString(),
      };
      return of(errorSummary);
    }),
  );
}
