import { IconButton } from '@dropbox/dig-components/buttons';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine, CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { preventBubble } from '@mirage/shared/hotkeys';
import { MAX_STACK_SECTION_TITLE_LENGTH } from '@mirage/shared/util/constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import i18n from '../../../translations';
import styles from './FancyInlineEdit.module.css';

import type { TextInputRefObject } from '@dropbox/dig-components/text_fields';

export const FancyInlineEdit = ({
  title: oldTitle,
  onSubmit,
  onCancel,
  'data-testid': dataTestId,
}: {
  title: string | undefined;
  onSubmit: (title: string) => void;
  onCancel: () => void;
  'data-testid'?: string;
}) => {
  const ref = useRef<TextInputRefObject>(null);
  const [title, setTitle] = useState(oldTitle || '');
  const trimmedTitle = title.trim();
  const isInvalid =
    trimmedTitle.length === 0 ||
    trimmedTitle.length > MAX_STACK_SECTION_TITLE_LENGTH;

  const handleSubmit = useCallback(() => {
    if (isInvalid) {
      return;
    }
    onSubmit(trimmedTitle);
  }, [trimmedTitle, isInvalid, onSubmit]);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <div className={styles.fancyInlineEdit}>
      <div className={styles.fancyInlineEditInput}>
        <TextInput
          maxLength={MAX_STACK_SECTION_TITLE_LENGTH}
          ref={ref}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          isInvalid={isInvalid}
          onFocus={(e) => {
            e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
          }}
          onKeyUp={(event) => {
            if (event.key === 'Escape') {
              preventBubble(event);
              onCancel();
            } else if (event.key === 'Enter') {
              handleSubmit();
            }
          }}
          data-testid={dataTestId}
        />
      </div>
      <IconButton
        variant="borderless"
        size="standard"
        onClick={onCancel}
        aria-label={i18n.t('cancel')}
        data-testid="SectionRenameCancelBtn"
      >
        <UIIcon src={CloseLine} />
      </IconButton>
      <IconButton
        variant="borderless"
        size="standard"
        onClick={handleSubmit}
        disabled={isInvalid}
        aria-label={i18n.t('create_section')}
        data-testid="SectionRenameSubmitBtn"
      >
        <UIIcon src={CheckmarkLine} />
      </IconButton>
    </div>
  );
};
