import type { AccessType } from './assist-api';
import type { ComposeAssistantConversationMessage } from './assist-message';
import type { ChatSource } from '@mirage/mosaics/Chat/types';

export type PreConfiguredTemplateID =
  (typeof PRE_CONFIGURED_TEMPLATE_IDS)[number];

export interface BaseTemplate {
  id: string;
  name: string;
  description: string;
  templateContent: string;
  type: 'custom' | 'default';
}

export interface DefaultTemplate extends BaseTemplate {
  type: 'default';
  contentType: AssistDefaultTemplateName;
}

export interface AssistCustomTemplate extends BaseTemplate {
  type: 'custom';
  dataId: string;
  sources: ChatSource[];
  rev: number;
  accessType: AccessType;
  messagesHistory: ComposeAssistantConversationMessage[];
  isOwned: boolean;
}

export type AssistTemplate = AssistCustomTemplate | DefaultTemplate;

export enum AssistDefaultTemplateName {
  FAQ_DOCUMENT = 'FAQ Document',
  TEAM_MEETING_AGENDA = 'Team Meeting Agenda',
  PROJECT_KICKOFF = 'Project Kickoff',
  MEETING_SUMMARY = 'Meeting Summary',
  TASK_TRACKING = 'Task Tracking',
  STATUS_REPORT = 'Status Report',
  CHANGE_MANAGEMENT_PLAN = 'Change Management Plan',
  TEAM_RECOGNITION_EMAIL = 'Team Recognition Email',
  SOP = 'Standard Operating Procedure (SOP)',
  FOLLOW_UP_EMAIL = 'Follow-Up Email',
  MEETING_SCHEDULING_EMAIL = 'Meeting Scheduling Email',
  CUSTOMER_ONBOARDING_EMAIL = 'Customer Onboarding Email',
  SALES_OUTREACH_EMAIL = 'Sales Outreach Email',
  MARKETING_EMAIL_CAMPAIGN = 'Marketing Email Campaign',
  MARKETING_BLOG_POST = 'Marketing Blog Post',
  SALES_CUSTOMER_ONBOARDING = 'Sales Customer Onboarding Email',
  CUSTOMER_SUPPORT_EMAIL = 'Customer Support Email',
  PRODUCT_RELEASE_NOTES = 'Product Release Notes',
  ANALYSIS_REPORT = 'Analysis Report',
  BUSINESS_MEMO = 'Business Memo',
}

/**
 * This list of IDs must match whatever is on the server since this
 * ID is what is used to fetch the right prompt
 */
export const PRE_CONFIGURED_TEMPLATE_IDS = [
  'faq_document',
  'team_meeting_agenda',
  'project_kickoff',
  'meeting_summary',
  'task_tracking',
  'status_report',
  'change_management_plan',
  'team_recognition_email',
  'standard_operating_procedure_sop',
  'follow_up_email',
  'meeting_scheduling_email',
  'customer_onboarding_email',
  'sales_outreach_email',
  'marketing_email_campaign',
  'marketing_blog_post',
  'sales_customer_onboarding_email',
  'customer_support_email',
  'product_release_notes',
  'analysis_report',
  'business_memo',
] as const;

export const DEFAULT_PRECONFIGURED_TEMPLATE_ID: PreConfiguredTemplateID =
  PRE_CONFIGURED_TEMPLATE_IDS[0];

export function isPreConfiguredTemplateID(
  templateID: string,
): templateID is PreConfiguredTemplateID {
  return PRE_CONFIGURED_TEMPLATE_IDS.includes(
    templateID as PreConfiguredTemplateID,
  );
}

interface TemplateLabels {
  title: string;
  description: string;
}

export const TEMPLATE_CONTENT: Record<AssistDefaultTemplateName, string> = {
  [AssistDefaultTemplateName.FAQ_DOCUMENT]: `# FAQ for Product X

### Password Reset
**Q**: How do I reset my password?  
**A**: Go to the login page and click "Forgot Password." Enter your email, and you'll receive a reset link within minutes.

### Free Plan Features
**Q**: What features are included in the free plan?  
**A**: The free plan includes basic task management, collaboration tools for up to 5 users, and 2 GB of storage.

### Support Contact
**Q**: How do I contact support?  
**A**: You can reach our support team 24/7 via live chat or email at support@productx.com.`,

  [AssistDefaultTemplateName.TEAM_MEETING_AGENDA]: `# Team Meeting Agenda

**Date**: November 20, 2024  
**Time**: 10:00 AM – 11:00 AM  
**Location**: Zoom (Link: [Insert Link])

## Agenda

### 1. Welcome and Objectives (10 mins)
- Review meeting purpose and key outcomes

### 2. Project Updates (15 mins)
- Team Leads provide status updates

### 3. Challenges and Blockers (15 mins)
- Discuss current obstacles and potential solutions

### 4. Upcoming Deliverables (10 mins)
- Review deadlines for Q4

### 5. Q&A and Open Discussion (10 mins)

### 6. Action Items Recap and Closing (5 mins)`,

  [AssistDefaultTemplateName.PROJECT_KICKOFF]: `# Project Kickoff – AI Integration

**Date**: November 22, 2024  
**Time**: 2:00 PM – 3:30 PM  
**Attendees**: [Project Sponsor, PM, Team Leads]

## Agenda

### 1. Welcome and Introductions (10 mins)
- Overview of roles and responsibilities

### 2. Project Objectives (15 mins)
- Define goals and success metrics

### 3. Scope and Deliverables (20 mins)
- Review project charter and timelines

### 4. Communication Plan (15 mins)
- Set meeting cadence and tools for updates

### 5. Next Steps and Q&A (30 mins)`,

  [AssistDefaultTemplateName.MEETING_SUMMARY]: `# Meeting Summary

**Team/Project**: [Team Name/Project Name]  
**Date**: November 20, 2024  
**Attendees**: [Names/Roles]

## Summary
- Discussed Q4 project milestones
- Identified blockers in marketing campaign execution
- Reviewed new deadlines for deliverables

## Next Steps
1. [Team Member 1] to finalize marketing materials by November 25th
2. [Team Member 2] to resolve software issue with IT by November 23rd
3. Schedule follow-up meeting for November 27th at 3:00 PM`,

  [AssistDefaultTemplateName.TASK_TRACKING]: `## Purpose: Stay organized and ensure your team is aligned on priorities

## How to Use:
- Task Name: Clearly describe the task to avoid confusion.
- Owner: Assign responsibility to a specific person.
- Due Date: Set realistic deadlines to keep the project moving forward.
- Priority: Label tasks (e.g., High, Medium, Low) to focus on what matters most.
- Status: Track progress with statuses like Not Started, In Progress, or Completed.
- Notes: Capture any additional details or updates.

This template ensures that tasks are visible, ownership is clear, and nothing falls through the cracks. Perfect for team collaboration or personal productivity!`,

  [AssistDefaultTemplateName.STATUS_REPORT]: `## Purpose: Provide a quick, clear update on progress, challenges, and next steps

## Structure:
1. Project Name/Overview: A brief one-liner summarizing the project or initiative.
2. Highlights: Share major wins or milestones achieved this period.
3. Challenges: Mention blockers or risks impacting progress and any steps being taken to address them.
4. Metrics/Deliverables: Include key numbers or deliverables to show measurable progress.
5. Next Steps: Outline what’s coming up and who is responsible.

This template helps stakeholders stay informed and aligns everyone on what’s next. Pro tip: Keep it short and focused—people appreciate concise updates!`,

  [AssistDefaultTemplateName.CHANGE_MANAGEMENT_PLAN]: `# Change Management Plan

## 1. Objective
Transition from the current project management software to a more robust, cloud-based solution to improve team collaboration and reduce task duplication.

## 2. Stakeholders
- Executive Sponsors (CEO, CTO)
- Project Management Team (PMO Lead, Project Managers)
- End Users (All employees managing tasks and projects)

## 3. Communication Plan
- Weekly email updates to all staff
- Bi-weekly town hall for Q&A and feedback
- Dedicated intranet page with FAQs and training videos

## 4. Implementation Steps
### Phase 1 (Feb 15 – Feb 28)
- Pilot testing with the PMO team, led by PMO Lead

### Phase 2 (Mar 1 – Mar 15)
- Roll-out to project managers, including short training sessions, led by Training Manager

### Phase 3 (Mar 16 – Mar 31)
- Full organization-wide launch, monitored by IT and Customer Success teams for support

## 5. Training/Support
- Virtual workshops and recorded sessions available via company LMS
- On-demand email and help desk support for troubleshooting
- "Office hours" twice a week for live assistance

## 6. Success Metrics
- 90% of projects fully active on new software within one month of launch
- 25% reduction in duplicate task creation after three months
- Positive user feedback score of 8/10 or higher on post-implementation survey`,

  [AssistDefaultTemplateName.TEAM_RECOGNITION_EMAIL]: `# Team Recognition Email

**Subject**: Big Kudos to the Marketing Team 🎉

Hi Team,

I want to take a moment to spotlight our incredible Marketing Team for their outstanding work on the Spring Product Launch campaign. They orchestrated a seamless rollout that not only captured our customers' attention but also drove a significant boost in our online engagement.

Their creativity and hard work truly shone through, and I'm thrilled to see how this has positively impacted our brand visibility and overall sales performance. I especially appreciate the late hours they put in leading up to launch day—your dedication did not go unnoticed!

Let's all give them a big round of applause 👏 and celebrate this amazing accomplishment together!

Keep up the fantastic work, everyone—it's inspiring to see the dedication and talent on this team!

Best,  
[Your Name]`,

  [AssistDefaultTemplateName.SOP]: `# SOP: Submitting a Marketing Campaign Proposal

Purpose: 
To outline the process for submitting and approving marketing campaign proposals.

Scope: 
Applies to all marketing team members submitting proposals for review.

Procedure:

1. Draft Proposal:
    - Use the Marketing Proposal Template.
    - Include campaign goals, target audience, budget, and timeline.
2. Submit for Review:
    - Upload the draft to the shared drive under the "Proposals" folder.
    - Notify the Marketing Manager via Slack with the link to the proposal.
3. Approval Process:
    - The Marketing Manager reviews and provides feedback within 2 business days.
    - Update the document based on feedback and resubmit for final approval.
4. Archival:
    - Once approved, move the final proposal to the "Approved Campaigns" folder.

Tools and Resources:
- Marketing Proposal Template: [Link]
- Shared Drive: [Link]`,

  [AssistDefaultTemplateName.FOLLOW_UP_EMAIL]: `# Subject Line: "Great connecting today, Ashley!"

Hi Ashley,

It was great speaking with you earlier! I wanted to recap our conversation and highlight the next steps we discussed:

- You're interested in automating your reporting process to save time.
- I'll send over a demo link by Friday for you to review with your team.

In the meantime, here's a case study I mentioned: [Link]. Let me know if you have any questions!

Looking forward to reconnecting next week.

Best regards, 
[Your Name]`,

  [AssistDefaultTemplateName.MEETING_SCHEDULING_EMAIL]: `# Subject Line: "Let's schedule our follow-up meeting"

Hi Ashley,

I hope you're doing well! I'd like to schedule a follow-up meeting to discuss the next steps on the project. Here are some times that work for me:

- Tuesday, November 21, 10:00 AM – 11:00 AM
- Wednesday, November 22, 2:00 PM – 3:00 PM

Let me know what works best for you, or feel free to suggest another time.

Looking forward to connecting!

Best regards, 
[Your Name]`,

  [AssistDefaultTemplateName.CUSTOMER_ONBOARDING_EMAIL]: `# Subject Line: "Welcome to [Your Company]!"

Hi Ashley,

Welcome to [Company Name]! We're excited to have you on board. Here's everything you need to get started:

1. Log in to your account: [Insert Link].
2. Review our Getting Started Guide: [Insert Link].
3. Join our onboarding webinar: [Insert Date/Time and Link].

If you have any questions, don't hesitate to reach out to our support team at [email].

Looking forward to helping you succeed!

Best regards, 
[Your Name]`,

  [AssistDefaultTemplateName.SALES_OUTREACH_EMAIL]: `# Subject Line: "Ashley, let's simplify your customer onboarding process"

Hi Ashley,

I recently came across your team's work in streamlining customer experiences for SMBs—impressive!

I'm reaching out because I believe we can help you take that impact even further. Our AI-powered platform reduces customer onboarding time by 40%, helping teams like yours focus on building relationships instead of managing tedious tasks.

For example, a flooring installation company we worked with cut manual data entry by 50%, saving 15 hours a week.

Would you be open to a quick call to explore if this is a good fit for your goals? Feel free to grab a time on my calendar here: [insert link].

Looking forward to connecting!

Warm regards, 
[Your Name] 
[Your Title] 
[Your Company]`,

  [AssistDefaultTemplateName.MARKETING_EMAIL_CAMPAIGN]: `# Subject Line: "Unlock 20% Off Our AI-Powered Tools—This Week Only!"

Imagine saving hours every week by automating your repetitive tasks. With our AI-powered tools, it's now easier than ever to focus on what matters most.

For a limited time, enjoy 20% off all plans and access features designed to simplify your workflows:

- Automate reporting, task management, and client communications.
- Gain insights faster with AI-driven analytics.
- Get exclusive access to our new assistant feature tailored for small businesses.

Join 10,000+ professionals already transforming their productivity. "This tool has completely changed the way my team works!" – Jessica B.

Don't miss out—this offer ends November 25th!
[Save Now]

Follow us on social media. Unsubscribe anytime.`,

  [AssistDefaultTemplateName.MARKETING_BLOG_POST]: `# Title: "5 Must-Know Marketing Trends to Dominate 2024"

## Introduction: 
Are your marketing strategies stuck in the past? With 2024 right around the corner, staying ahead of the curve is critical. This post reveals five cutting-edge trends that top marketers are using to outpace their competition. Let's dive in and future-proof your marketing game!

1. Personalization at Scale 
Today's customers demand a tailored experience. According to a recent report, 80% of consumers are more likely to buy from brands that offer personalized experiences. Use tools like HubSpot or Mailchimp to segment your audience and deliver relevant messages.

2. Short-Form Video Dominance 
Platforms like TikTok and Instagram Reels are reshaping consumer preferences. Videos under 60 seconds drive 74% higher engagement rates. Create behind-the-scenes clips, tutorials, or customer testimonials to maximize your reach.

3. Sustainability Matters 
Consumers are prioritizing eco-friendly brands. Highlight your sustainability initiatives in marketing campaigns to resonate with this growing demographic. For example, Patagonia effectively communicates its environmental impact in every campaign.

## Conclusion: 
The future of marketing is fast, personal, and socially conscious. By embracing these trends, your brand will be positioned for success. Don't wait—subscribe to our newsletter for weekly insights to keep your strategies sharp!`,

  [AssistDefaultTemplateName.SALES_CUSTOMER_ONBOARDING]: `# Subject Line: "Welcome to StreamlinePro—Let's Get Started!"

Hi [Customer Name],

We're thrilled to have you on board at StreamlinePro! Your journey to simplified project management starts now.

Here's how to get started:

Step 1: Set Up Your Account 
Click here to log in and follow our quick setup wizard. It only takes 2 minutes!

Step 2: Explore Key Features
- Task Automation: Save hours by automating repetitive tasks.
- Team Collaboration: Assign roles and share updates with a single click.
- Progress Tracking: Monitor your projects in real-time on our sleek dashboard.

Step 3: Connect with Us 
Got questions? Our team is here 24/7 via live chat or email at support@streamlinepro.com.

Let's get started! Click below to set up your account and begin your journey. 
Complete Setup Now

We're excited to see you succeed!

Warm regards, 
The StreamlinePro Team`,

  [AssistDefaultTemplateName.CUSTOMER_SUPPORT_EMAIL]: `# Subject Line: "We're here to help with your recent issue"

Hi Ashley,

Thank you for reaching out about the login issue you experienced. I understand how frustrating that can be.

Here's what we've done to resolve the issue:
- Reset your account password. You can create a new one using this link: [Insert Link].
- Confirmed that your account is now active and working correctly.

If you continue to experience issues, please don't hesitate to reply to this email or contact us at [Insert Support Contact Info].

We're here to help!

Best regards, 
[Your Name] 
Customer Support Specialist`,

  [AssistDefaultTemplateName.PRODUCT_RELEASE_NOTES]: `# Subject Line: "New Features and Updates: November 2024"

Hi Ashley,

We're excited to share the latest updates to [Product Name]!

What's New:
- Enhanced Analytics Dashboard: Visualize your data with improved charts and customizable reports.
- Team Collaboration Tools: Assign tasks and track progress directly in the app.
- Bug Fixes: Resolved login issues and improved app speed by 25%.

Check out the full release notes here: [Insert Link].
We hope you enjoy these updates! Let us know what you think.

Best regards, 
[Your Team]`,

  [AssistDefaultTemplateName.ANALYSIS_REPORT]: `# Subject: "Quarterly Sales Analysis – Q3 2024"

Overview: 
Q3 2024 saw a 15% increase in revenue compared to Q2, driven primarily by higher SMB conversions. Key takeaways include:

- Top-performing region: West Coast, with 35% of total sales.
- Best-selling product: AI Assistant Pro, contributing 50% of sales growth.
- Customer churn: Reduced by 8% after implementing proactive outreach campaigns.

Recommendations:
1. Increase marketing spend in the West Coast region.
2. Develop a bundled pricing model for AI Assistant Pro.
3. Expand outreach campaigns to target mid-market segments.`,

  [AssistDefaultTemplateName.BUSINESS_MEMO]: `# Subject: "Policy Change: Remote Work Flexibility"

To: All Employees 
From: HR Department 
Date: November 18, 2024

Overview: 
Starting December 1, 2024, employees may work remotely up to three days a week. This policy aims to enhance work-life balance while maintaining productivity.

Details:
- Eligible Employees: All full-time staff.
- Remote Work Days: Monday through Thursday (Friday reserved for in-office collaboration).
- Process: Submit weekly schedules via the HR portal.

For more information, visit our HR page or contact hr@company.com.

Best regards, 
[Your Name] 
[Your Title]`,
};

export function getTemplateContent(
  templateName: AssistDefaultTemplateName,
): string {
  return TEMPLATE_CONTENT[templateName];
}

export function getTemplateLabels(
  templateName: AssistDefaultTemplateName,
): TemplateLabels {
  const labels: Record<AssistDefaultTemplateName, TemplateLabels> = {
    [AssistDefaultTemplateName.FAQ_DOCUMENT]: {
      title: 'FAQ Document',
      description:
        'Create a comprehensive FAQ document with organized Q&A sections',
    },

    [AssistDefaultTemplateName.TEAM_MEETING_AGENDA]: {
      title: 'Team Meeting Agenda',
      description: 'Structure a clear and effective team meeting agenda',
    },

    [AssistDefaultTemplateName.PROJECT_KICKOFF]: {
      title: 'Project Kickoff',
      description:
        'Create a detailed project kickoff meeting agenda and structure',
    },

    [AssistDefaultTemplateName.MEETING_SUMMARY]: {
      title: 'Meeting Summary',
      description:
        'Summarize key points, decisions, and action items from meetings',
    },

    [AssistDefaultTemplateName.TASK_TRACKING]: {
      title: 'Task Tracking',
      description: 'Organize and track tasks effectively',
    },

    [AssistDefaultTemplateName.STATUS_REPORT]: {
      title: 'Status Report',
      description: 'Provide a concise update on project status and progress',
    },

    [AssistDefaultTemplateName.CHANGE_MANAGEMENT_PLAN]: {
      title: 'Change Management Plan',
      description:
        'Develop a structured plan for implementing organizational changes',
    },

    [AssistDefaultTemplateName.TEAM_RECOGNITION_EMAIL]: {
      title: 'Team Recognition Email',
      description: 'Write an email recognizing team accomplishments',
    },

    [AssistDefaultTemplateName.SOP]: {
      title: 'Standard Operating Procedure',
      description: 'Document step-by-step procedures for common tasks',
    },

    [AssistDefaultTemplateName.FOLLOW_UP_EMAIL]: {
      title: 'Follow-Up Email',
      description:
        'Send a professional follow-up after meetings or discussions',
    },

    [AssistDefaultTemplateName.MEETING_SCHEDULING_EMAIL]: {
      title: 'Meeting Scheduling Email',
      description: 'Coordinate meeting times with participants',
    },

    [AssistDefaultTemplateName.CUSTOMER_ONBOARDING_EMAIL]: {
      title: 'Customer Onboarding Email',
      description: 'Welcome and guide new customers through initial steps',
    },

    [AssistDefaultTemplateName.SALES_OUTREACH_EMAIL]: {
      title: 'Sales Outreach Email',
      description:
        'Connect with potential customers and highlight value propositions',
    },

    [AssistDefaultTemplateName.MARKETING_EMAIL_CAMPAIGN]: {
      title: 'Marketing Email Campaign',
      description: 'Create engaging marketing email content',
    },

    [AssistDefaultTemplateName.MARKETING_BLOG_POST]: {
      title: 'Marketing Blog Post',
      description: 'Write informative and engaging blog content',
    },

    [AssistDefaultTemplateName.SALES_CUSTOMER_ONBOARDING]: {
      title: 'Sales Customer Onboarding',
      description: 'Guide new customers through your product or service',
    },

    [AssistDefaultTemplateName.CUSTOMER_SUPPORT_EMAIL]: {
      title: 'Customer Support Email',
      description: 'Respond professionally to customer inquiries',
    },

    [AssistDefaultTemplateName.PRODUCT_RELEASE_NOTES]: {
      title: 'Product Release Notes',
      description: 'Document new features and improvements',
    },

    [AssistDefaultTemplateName.ANALYSIS_REPORT]: {
      title: 'Analysis Report',
      description: 'Present data findings and recommendations',
    },

    [AssistDefaultTemplateName.BUSINESS_MEMO]: {
      title: 'Business Memo',
      description: 'Communicate important information within an organization',
    },
  };

  return labels[templateName];
}

export function createDefaultTemplates(): DefaultTemplate[] {
  return Object.values(AssistDefaultTemplateName).map((name) => {
    const labels = getTemplateLabels(name);
    return {
      // TODO (will): do this with a single map instead of converting the name
      // to match the id format and maintaining a separate map
      id: name
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[()]/g, '')
        .replace(/-/g, '_'), // Added hyphen replacement
      type: 'default',
      name: labels.title,
      description: labels.description,
      templateContent: TEMPLATE_CONTENT[name],
      contentType: name,
    };
  });
}

// TODO i18n these names
export const templateIdToTemplateName: Record<
  PreConfiguredTemplateID,
  AssistDefaultTemplateName
> = {
  faq_document: AssistDefaultTemplateName.FAQ_DOCUMENT,
  team_meeting_agenda: AssistDefaultTemplateName.TEAM_MEETING_AGENDA,
  project_kickoff: AssistDefaultTemplateName.PROJECT_KICKOFF,
  meeting_summary: AssistDefaultTemplateName.MEETING_SUMMARY,
  task_tracking: AssistDefaultTemplateName.TASK_TRACKING,
  status_report: AssistDefaultTemplateName.STATUS_REPORT,
  change_management_plan: AssistDefaultTemplateName.CHANGE_MANAGEMENT_PLAN,
  team_recognition_email: AssistDefaultTemplateName.TEAM_RECOGNITION_EMAIL,
  standard_operating_procedure_sop: AssistDefaultTemplateName.SOP,
  follow_up_email: AssistDefaultTemplateName.FOLLOW_UP_EMAIL,
  meeting_scheduling_email: AssistDefaultTemplateName.MEETING_SCHEDULING_EMAIL,
  customer_onboarding_email:
    AssistDefaultTemplateName.CUSTOMER_ONBOARDING_EMAIL,
  sales_outreach_email: AssistDefaultTemplateName.SALES_OUTREACH_EMAIL,
  marketing_email_campaign: AssistDefaultTemplateName.MARKETING_EMAIL_CAMPAIGN,
  marketing_blog_post: AssistDefaultTemplateName.MARKETING_BLOG_POST,
  sales_customer_onboarding_email:
    AssistDefaultTemplateName.SALES_CUSTOMER_ONBOARDING,
  customer_support_email: AssistDefaultTemplateName.CUSTOMER_SUPPORT_EMAIL,
  product_release_notes: AssistDefaultTemplateName.PRODUCT_RELEASE_NOTES,
  analysis_report: AssistDefaultTemplateName.ANALYSIS_REPORT,
  business_memo: AssistDefaultTemplateName.BUSINESS_MEMO,
};
