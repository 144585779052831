import { IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { WarningLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { useAtom } from 'jotai';
import { magicFlagSettings } from './magicFlagSettings';
import styles from './MagicFlagsIndicator.module.css';
import { useMagicFlags } from './useMagicFlags';

import type { OverlayPlacement } from '@dropbox/dig-components/overlay';

export default function MagicFlagsIndicator(props: {
  placement?: OverlayPlacement | undefined;
}) {
  const [, setMagicFlags] = useAtom(magicFlagSettings);
  const magicFlagsList = useMagicFlags().list;

  const clearFlags = () => {
    setMagicFlags({});
  };
  if (magicFlagsList.length === 0) {
    return <></>;
  }
  const tooltipContent = (
    <>
      <Text isBold>Magic Flags Enabled</Text>
      <div className={styles.magicFlagTooltip}>
        {magicFlagsList.map((flag) => (
          <>
            <Text>{flag.key}</Text>
            <Text>{flag.value}</Text>
          </>
        ))}
      </div>
      <Text variant="label">
        <Text isBold>Double click</Text> to clear
      </Text>
    </>
  );
  return (
    <DigTooltip title={tooltipContent} placement={props.placement}>
      <IconButton
        variant="borderless"
        size="small"
        shape="standard"
        onDoubleClick={clearFlags}
      >
        <WarningLine className={styles.magicFlagIcon} />
      </IconButton>
    </DigTooltip>
  );
}
