import { Text } from '@dropbox/dig-components/typography';
import MagicFlagsIndicator from '@mirage/mosaics/MagicFlags/MagicFlagsIndicator';
import { useDualMode } from '@mirage/service-experimentation/useDualMode';
import useSettings from '@mirage/service-settings/useSettings';
import useIsMobileDevice from '@mirage/settings/utils/useIsMobileDevice';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import i18n from '@mirage/translations';
import { useWindowSize } from '@react-hookz/web';
import styles from './TypeaheadShortcutBanner.module.css';

type TypeaheadKeyboardShortcutBannerProps = {
  isDarwin: boolean;
  showAddToStackShortcut?: boolean;
  showSummarizeShortcut?: boolean;
};

export const TypeaheadKeyboardShortcutBanner = ({
  isDarwin,
  showAddToStackShortcut = false,
  showSummarizeShortcut = false,
}: TypeaheadKeyboardShortcutBannerProps) => {
  const isMobile = useIsMobileDevice(); // On mobile devices we only show the last shortcut (`/new`)
  const windowSize = useWindowSize().width;
  const shortcutsToShow = Math.floor(windowSize / 200); // Dedicate 200px per shortcut to avoid overflow

  const { isDualModeLauncher, isDualModePersistent } = useDualMode();
  const { settings } = useSettings(['appShortcut', 'persistentAppShortcut']);

  const shortcuts = [
    isDualModeLauncher && settings?.persistentAppShortcut && (
      <div className={styles.shortcut} key="dualModePersistent">
        <PrettyShortcuts
          shortcut={settings.persistentAppShortcut}
          isDarwin={isDarwin}
        />
        <Text size="small" monospace className={styles.text}>
          {i18n.t('app_shortcut_persistent')}
        </Text>
      </div>
    ),
    isDualModePersistent && settings?.appShortcut && (
      <div className={styles.shortcut} key="dualModeLauncher">
        <PrettyShortcuts shortcut={settings.appShortcut} isDarwin={isDarwin} />
        <Text size="small" monospace className={styles.text}>
          {i18n.t('app_shortcut_launcher')}
        </Text>
      </div>
    ),
    showSummarizeShortcut && (
      <div className={styles.shortcut} key="summarize">
        <PrettyShortcuts
          shortcut={['commandOrControl', 'S']}
          isDarwin={isDarwin}
        />
        <Text size="small" monospace className={styles.text}>
          {i18n.t('summarizable_reason_yes_summarizable')}
        </Text>
      </div>
    ),
    showAddToStackShortcut && (
      <div className={styles.shortcut} key="addToStack">
        <PrettyShortcuts
          shortcut={['commandOrControl', 'G']}
          isDarwin={isDarwin}
        />
        <Text size="small" monospace className={styles.text}>
          {i18n.t('add_to_stack')}
        </Text>
      </div>
    ),
    <div className={styles.shortcut} key="copyLink">
      <PrettyShortcuts
        shortcut={['commandOrControl', 'C']}
        isDarwin={isDarwin}
      />
      <Text size="small" monospace className={styles.text}>
        {i18n.t('copy_link')}
      </Text>
    </div>,
    <div className={styles.shortcut} key="typeaheadCreate">
      <PrettyShortcuts
        shortcut={['/new']}
        isDarwin={isDarwin}
        casingOverride="lowercase"
      />
      <Text size="small" monospace className={styles.text}>
        {i18n.t('typeahead_shortcut_create')}
      </Text>
    </div>,
  ]
    .filter((s): s is JSX.Element => !!s)
    .slice(isMobile ? -1 : -shortcutsToShow);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <MagicFlagsIndicator placement="right" />
      </div>
      {shortcuts}
    </div>
  );
};
