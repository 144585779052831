import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { Service } from '@mirage/service-stack-admin-settings/service';

const service = services.get<Service>(ServiceId.STACK_ADMIN_SETTINGS);

export const isPublicSharingAllowed = service.isPublicSharingAllowed;

export function refreshPublicSharingStatus() {
  return rx.firstValueFrom(service.refreshPublicSharingStatus());
}

export function startUpdateStackAdminSettings() {
  return rx.firstValueFrom(service.startUpdateStackAdminSettings());
}

export function cancelUpdateStackAdminSettings() {
  return rx.firstValueFrom(service.cancelUpdateStackAdminSettings());
}
