import { i18nUnlocalizableStringClass } from '@mirage/translations';
import classnames from 'classnames';
import * as React from 'react';
import styles from './KeyCap.module.css';

export type KeyCapCasing = 'uppercase' | 'lowercase' | 'capitalize';

type Props = Readonly<{
  casing: KeyCapCasing;
  children: React.ReactNode;
  /** used to set font-size for symbols to get proper size */
  symbol?: boolean;
  onClick?: () => void;
  separateKeys?: boolean;
}>;

export default function KeyCap({
  children,
  symbol = false,
  casing,
  onClick,
  separateKeys = false,
}: Props) {
  const classes = classnames(
    'keyCap',
    styles.keyCapContainer,
    styles[casing],
    i18nUnlocalizableStringClass,
    {
      [styles.symbol]: symbol,
      [styles.separateKeys]: separateKeys,
    },
  );

  return (
    <div
      className={classes}
      role={onClick ? 'button' : undefined}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onClick?.();
        }
      }}
    >
      {children}
    </div>
  );
}
