import { useEffect, useRef } from 'react';

export const useDoOnceOnMount = (action: () => void) => {
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (hasRunRef.current) return;
    hasRunRef.current = true;
    action();
  }, [action]);
};
