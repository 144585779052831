import type { HarResponse, HarRequest } from "@alwaysmeticulous/api";
import { dropRequestHeader } from "@alwaysmeticulous/redaction";
import type {
  NetworkResponseMetadata,
  NetworkRequestMetadata,
  RecorderMiddleware,
} from "@alwaysmeticulous/sdk-bundles-api";

import { dashFeedListActivityFeedRedacted } from "./redactions/dashFeedListActivityFeed";
import { dashFeedListFeedItemsRedacted } from "./redactions/dashFeedListFeedItems";
import { dcsGetCalendarEventsRedacted } from "./redactions/dcsGetCalendarEvents";
import { janusProxyProxyRedacted } from "./redactions/janusProxyProxy";
import { stacksGetRecentActivityRedacted } from "./redactions/stacksGetRecentActivity";
import { stacksQueryStacksRedacted } from "./redactions/stacksQueryStacks";
import {
  DEFAULT_REDACTOR,
  redactDefaultIndexedDbEntry,
  redactIndexedDBEntry,
} from "./utils";

const ENDPOINT_REDACTORS = [
  dashFeedListActivityFeedRedacted,
  dashFeedListFeedItemsRedacted,
  dcsGetCalendarEventsRedacted,
  stacksGetRecentActivityRedacted,
  stacksQueryStacksRedacted,
  janusProxyProxyRedacted,
];

const handledUrlRegexes = [
  ...ENDPOINT_REDACTORS.map(({ urlRegExp }) => urlRegExp),
];

export const DEFAULT_REDACTION_FOR_UNHANDLED_REQUESTS: RecorderMiddleware = {
  transformNetworkRequest: (
    request: Omit<HarRequest, "queryString">,
    metadata: NetworkRequestMetadata,
  ) => {
    let parsed: any;
    try {
      parsed = JSON.parse(request.postData?.text ?? "");
    } catch {
      // If it's not JSON and no special redaction rule set then don't redact
      return request;
    }

    return {
      ...request,
      postData: {
        ...request.postData,
        mimeType: request.postData?.mimeType ?? "",
        text: JSON.stringify(
          DEFAULT_REDACTOR(parsed, {
            ...metadata,
            request,
          }),
        ),
      },
    };
  },
};

export const DEFAULT_REDACTION_FOR_UNHANDLED_RESPONSES: RecorderMiddleware = {
  transformNetworkResponse: (
    response: HarResponse,
    metadata: NetworkResponseMetadata,
  ) => {
    if (handledUrlRegexes.some((regex) => regex.test(metadata.request.url))) {
      return response;
    }

    let parsed: any;
    try {
      parsed = JSON.parse(response.content.text ?? "");
    } catch {
      // If it's not JSON and no special redaction rule set then don't redact
      return response;
    }

    return {
      ...response,
      content: {
        ...response.content,
        text: JSON.stringify(
          DEFAULT_REDACTOR(parsed, {
            ...metadata,
            response,
          }),
        ),
      },
    };
  },
};

const INDEXED_DB_REDACTIONS = ENDPOINT_REDACTORS.map(
  ({ indexedDbKey, indexedDbRedactor }) => ({
    key: indexedDbKey,
    redactor: indexedDbRedactor,
  }),
);

export const INDEXED_DB_REDACTOR: RecorderMiddleware = {
  transformIndexedDBEntries: (data) => {
    if (
      data.databaseName !== "DropboxKeyValueStore" ||
      data.objectStoreName !== "KeyValue"
    ) {
      return data;
    }

    const redactedEntries = data.entries.map((entry) => {
      const redaction = INDEXED_DB_REDACTIONS.find(
        (indexedDbRedaction) => indexedDbRedaction.key === entry.key,
      );
      if (redaction === undefined) {
        return redactDefaultIndexedDbEntry(entry);
      }
      return redactIndexedDBEntry(
        entry,
        redaction.key,
        // This cast is safe because we know each entry in INDEXED_DB_REDACTIONS
        // has a redactor that takes an type T and returns that same type T.
        redaction.redactor as (parsed: unknown) => unknown,
      );
    });

    return {
      ...data,
      entries: redactedEntries,
    };
  },
};

export const METICULOUS_REDACTION_MIDDLEWARE: RecorderMiddleware[] = [
  INDEXED_DB_REDACTOR,
  DEFAULT_REDACTION_FOR_UNHANDLED_REQUESTS,
  DEFAULT_REDACTION_FOR_UNHANDLED_RESPONSES,
  ...ENDPOINT_REDACTORS.map(({ createMiddleware }) => createMiddleware()),
];

export const METICULOUS_TOKEN_REDACTION_MIDDLEWARE: RecorderMiddleware[] = [
  dropRequestHeader("Authorization"),
  {
    transformLocalStorageEntry: (entry) => {
      if (entry.key === "authentication") {
        // Note: dropping the authentication entry altogether
        // will cause replay to fail because the frontend JS
        // redirects to login if the entry doesn't exist.
        // So we redact instead.
        return {
          ...entry,
          value: JSON.stringify({
            ...JSON.parse(entry.value),
            accessToken: "REDACTED",
            codeVerifier: "REDACTED",
            refreshToken: "REDACTED",
          }),
        };
      }
      return entry;
    },
  },
];
