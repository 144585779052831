import { Droppable as RbDroppable } from 'react-beautiful-dnd';

import type { HTMLProps, ReactElement } from 'react';

export type DroppableProps<T extends HTMLProps<E>, E extends HTMLElement> = {
  // Off switch to render everything without enabling dropping.
  dropDisabled?: boolean;

  // Unique id for this draggable element.
  droppableId: string;

  // Extra properties to set on the element.
  props?: T | null;

  // Extra properties to set on the element, as a callback.
  getProps?: (isDragging: boolean) => T | undefined;

  // Function to render the child node.
  renderChild: (props: T, isDraggingOver: boolean) => ReactElement<E>;
};

export function Droppable<T extends HTMLProps<E>, E extends HTMLElement>({
  dropDisabled,
  droppableId,
  props,
  getProps,
  renderChild,
}: DroppableProps<T, E>) {
  if (dropDisabled) {
    // @ts-expect-error: Prevent the "'T' could be instantiated with an
    // arbitrary type which could be unrelated" error which is expected.
    const newProps: T = {
      ...props,
      ...getProps?.(false),
    };

    return renderChild(newProps, false);
  }

  return (
    <RbDroppable droppableId={droppableId}>
      {(provided, snapshot) => {
        // @ts-expect-error: Prevent the "'T' could be instantiated with an
        // arbitrary type which could be unrelated" error which is expected.
        const newProps: T = {
          ref: provided.innerRef,
          ...provided.droppableProps,
          ...props,
          ...getProps?.(snapshot.isDraggingOver),
        };

        return (
          <>
            {renderChild(newProps, snapshot.isDraggingOver)}
            {provided.placeholder}
          </>
        );
      }}
    </RbDroppable>
  );
}
