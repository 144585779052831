import { Badge } from '@dropbox/dig-components/badge';
import { Button } from '@dropbox/dig-components/buttons';
import { RadioButton } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { Split, Stack } from '@dropbox/dig-foundations';
import classnames from 'classnames';
import { useState } from 'react';
import styles from './ToolTypeSelector.module.css';

import type { WorkflowStep } from './types';

export const ToolTypeSelector = ({
  step,
  currentValue,
  onCancel,
  onSubmit,
}: {
  step: WorkflowStep;
  currentValue?: string;
  onCancel: () => void;
  onSubmit: (step: number, selectedOption: string) => void;
}) => {
  const input = step.inputs[0];
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    currentValue,
  );

  const handleOptionSelect = (optionId: string) => {
    setSelectedOption(optionId);
  };

  const handleSubmit = () => {
    onSubmit(step.step, selectedOption!);
  };

  return (
    <Stack gap="Micro Medium">
      <Stack gap="4">
        <div>
          <Text variant="label" isBold>
            {input.name}
          </Text>
        </div>
        <div>
          <Text color="subtle">{input.description}</Text>
        </div>
      </Stack>

      <div className={styles.optionCardContainer}>
        {input.availableOptions?.map((option) => (
          <Stack
            key={option.id}
            className={classnames(styles.optionCard, {
              [styles.selectedCard]: selectedOption === option.id,
              [styles.disabledCard]: option.disabled,
            })}
            onClick={() => !option.disabled && handleOptionSelect(option.id)}
            gap="Micro Small"
          >
            <Split alignX="between" alignY="center">
              <Text isBold color={option.disabled ? 'disabled' : undefined}>
                {option.label}
              </Text>
              <RadioButton
                checked={selectedOption === option.id}
                disabled={option.disabled}
                onChange={() => {}}
              />
            </Split>
            <div>
              <Text
                size="small"
                color={option.disabled ? 'disabled' : 'subtle'}
              >
                {option.description}
              </Text>
            </div>
            {option.disabled && (
              <div className={styles.badge}>
                <Badge>Coming soon</Badge>
              </div>
            )}
          </Stack>
        ))}
      </div>

      <Split alignX="right" gap="Micro Small">
        <Split.Item>
          <Button variant="opacity" onClick={onCancel}>
            Cancel
          </Button>
        </Split.Item>
        <Split.Item>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!selectedOption}
          >
            Next
          </Button>
        </Split.Item>
      </Split>
    </Stack>
  );
};
