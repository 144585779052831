import type {
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageMessage,
} from './assist-message';
import type { ChatSource } from '@mirage/mosaics/Chat/types';

export interface ComposeSession {
  dataId: string; // Server public id
  id: string; // Client private id
  messagesHistory: ComposeAssistantConversationMessage[];
  sources: ChatSource[];
  artifacts: ComposeArtifact[];
  lastUpdated?: number;
  rev?: number;
  customMode?: 'onboarding' | 'other';
}

export function isComposeAssistantConversationMessageMessage(
  message: ComposeAssistantConversationMessage,
): message is ComposeAssistantConversationMessageMessage {
  return message.type === 'message';
}

export function getSourceUUID(source: ChatSource): string | undefined {
  switch (source.type) {
    case 'dash_search_result':
      return source.searchResult.uuid;
    case 'recommendation':
      return source.recommendation.uuid;
    case 'transient':
      return source.uuid;
    case 'local_file_content':
      return source.uuid;
    default:
      source satisfies never;
      return undefined;
  }
}

export function getSourceTitle(source: ChatSource): string {
  switch (source.type) {
    case 'dash_search_result':
      return source.searchResult.title || '';
    case 'recommendation':
      return source.recommendation.title;
    case 'transient':
      return source.title;
    case 'local_file_content':
      return source.fileName;
    default:
      source satisfies never;
      return '';
  }
}

export function getSourceUrl(source: ChatSource): string | undefined | null {
  switch (source.type) {
    case 'dash_search_result':
      return source.searchResult.url;
    case 'recommendation':
      return source.recommendation.url;
    case 'transient':
      return source.url;
    case 'local_file_content':
      return undefined;
    default:
      source satisfies never;
      return undefined;
  }
}

export type ComposeArtifact = ComposeArtifactMarkdownDraft;

export interface ComposeArtifactMarkdownDraft {
  id: string;
  type: 'markdown_draft';
  markdownContent: string;
  draftConfig: ComposeAssistantDraftConfig;
}

export function getFirstMarkdownArtifact(
  artifacts: ComposeArtifact[],
): ComposeArtifactMarkdownDraft | undefined {
  return artifacts.find((a) => a.type === 'markdown_draft');
}

export interface ComposeAssistantDraftConfig {
  contentType?: ContentTypeOption | null;
  contentDescription?: string;
  voiceID?: string;
  templateID?: string | null;
}

export const CONTENT_TYPES = [
  'email',
  'message',
  'report',
  'proposal',
  'social_media_post',
  'meeting_agenda',
] as const;
export type ContentTypeOption = (typeof CONTENT_TYPES)[number];

export const PRE_CONFIGURED_VOICE_IDS = [
  'preset_neutral',
  'preset_persuasive',
  'preset_instructional',
  'preset_narrative',
  'preset_informal',
  'preset_analytical',
] as const;
export type PreConfiguredVoiceID = (typeof PRE_CONFIGURED_VOICE_IDS)[number];

export const DEFAULT_PRECONFIGURED_VOICE_ID: PreConfiguredVoiceID =
  PRE_CONFIGURED_VOICE_IDS[0];

export function isPreConfiguredVoiceID(
  voiceID: string,
): voiceID is PreConfiguredVoiceID {
  return PRE_CONFIGURED_VOICE_IDS.includes(voiceID as PreConfiguredVoiceID);
}

export type InputContext = {
  type: 'selection';
  selectedText: string;
};

export const getTemplateModificationHistoryFromMessages = (
  messages: ComposeAssistantConversationMessage[],
) => {
  return messages
    .filter((m) => m.role === 'user')
    .filter((m) => m.type === 'message')
    .map((m) => m.text || '');
};

export const getVoiceModificationHistoryFromMessages = (
  messages: ComposeAssistantConversationMessage[],
) => {
  return messages
    .filter((m) => m.role === 'user')
    .filter((m) => m.type === 'message')
    .map((m) => m.text || '');
};
