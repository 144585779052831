import { tagged } from '@mirage/service-logging';
import { provisionFreeDashTeam } from '@mirage/service-provisioning';
import { useState } from 'react';

const logger = tagged('useProvisioning');

export const useProvisioning = () => {
  const [loading, setLoading] = useState(false);
  const startSubscription = async (teamName: string) => {
    // This is a long running promise, so we are also setting loading state
    setLoading(true);
    try {
      const success = await provisionFreeDashTeam(teamName);
      return success;
    } catch (error) {
      logger.error('Failed to start subscription:', error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { provisionFreeDashTeam: startSubscription, loading };
};
