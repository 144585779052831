import * as rx from 'rxjs';
import { createAPIv2GRPCWebPromiseClient } from '../api_v2';
import { MLCapabilitiesStreamingApiV2 } from './gen/api_v2_service_streaming_connectweb';

import type { CreateChatCompletionRequest } from './gen/api_v2_pb';

export function createChatCompletionObservable(
  request: CreateChatCompletionRequest,
): rx.Observable<string> {
  return new rx.Observable<string>((subscriber) => {
    const mlcClient = createAPIv2GRPCWebPromiseClient(
      MLCapabilitiesStreamingApiV2,
    );

    (async () => {
      try {
        for await (const res of mlcClient.createChatCompletionStreaming(
          request,
        )) {
          if (
            res.results[0]?.generations[0].chatMessage?.messageType.value
              ?.content?.content?.value
          ) {
            subscriber.next(
              res.results[0]?.generations[0].chatMessage?.messageType.value
                ?.content?.content?.value,
            );
          }
        }
        subscriber.complete();
      } catch (error) {
        subscriber.error(error);
      }
    })();
  });
}
