import {
  buildConnectorInfo,
  buildFileTypeInfo,
  getSearchVerticalFromFilters,
  mapConnectorFilterToQueryFilter,
  mapContentTypeFilterToQueryFilter,
  mapLastUpdatedFilterToQueryFilter,
  mapPeopleFilterToQueryFilter,
  transformDashResultToMirage,
} from '@mirage/service-dbx-api/service/utils';
import {
  cacheLastSearchRequestArguments,
  cacheLastSearchRequestId,
} from '@mirage/service-relevance-snapshot/index';
import { DESKTOP_LOCAL_FILE_CONNECTOR_ID } from '@mirage/shared/connectors';
import {
  isConnectorFilter,
  isContentTypeFilter,
  isLastUpdatedFilter,
  isPersonFilter,
} from '@mirage/shared/search/search-filters';
import {
  type AuthorInfo,
  type BrandedSiteInfo,
  type ConnectorInfo,
  DASH_DESKTOP_APPLICATION_TAG,
  type DataSource,
  type FileTypeInfo,
  type IconResource,
  type ItemLocation,
  type SearchResult,
  type SearchResultAnnotations,
} from '@mirage/shared/search/search-result';

import type { dcs } from '@dropbox/api-v2-client';
import type {
  ConnectorFilter,
  ContentTypeFilter,
  LastUpdatedFilter,
  PersonFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

// TODO: remove this and update all imports to use shared if we like the strategy
// having it here was causing circular dependencies, and boundary import issues
export {
  SearchResult,
  ConnectorInfo,
  IconResource,
  FileTypeInfo,
  BrandedSiteInfo,
  DataSource,
  ItemLocation,
  AuthorInfo,
};

import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';

import type {
  APIv2Callable,
  APIv2CallableWithHeaders,
  AuthServiceContract,
} from '..';

function isSearchResult(
  queryResult: dcs.query_result_union | undefined,
): queryResult is dcs.query_result_unionSearchResult {
  return queryResult !== undefined && queryResult['.tag'] === 'search_result';
}

export const getAnnotations = (
  result: dcs.SearchApiv2Response,
): SearchResultAnnotations | undefined => {
  if (!result.query_annotation) return;

  return {
    originalQuery: result.query_annotation?.original_query?.length
      ? result.query_annotation.original_query
      : undefined,
    correctedQuery: result.query_annotation?.executed_query?.length
      ? result.query_annotation.executed_query
      : undefined,
    tokens: (result.query_annotation?.token_annotations ?? []).map((item) => ({
      token: item.token!,
      originalToken: item.original_token!,
    })),
  };
};

export async function getSearchResults(
  api: APIv2CallableWithHeaders,
  query: string,
  filters: SearchFilter[] = [],
  disableSpellCorrection = false,
  messagesVerticalEnabled = false,
  multimediaVerticalEnabled = false,
): Promise<{
  results: SearchResult[];
  annotations?: SearchResultAnnotations;
}> {
  const isAllModifiersEnabled =
    (await getCachedOrFetchFeatureValue('dash_2025_01_31_all_modifiers')) ===
    'ON';

  const connectorFilters: Array<dcs.QueryFilter> = filters
    .filter(
      (filter): filter is ConnectorFilter =>
        isConnectorFilter(filter) &&
        filter.id !== DESKTOP_LOCAL_FILE_CONNECTOR_ID,
    )
    .map(mapConnectorFilterToQueryFilter);

  const lastUpdatedFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is LastUpdatedFilter =>
      isLastUpdatedFilter(filter),
    )
    .map(mapLastUpdatedFilterToQueryFilter);

  const peopleFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is PersonFilter => isPersonFilter(filter))
    .map(mapPeopleFilterToQueryFilter);

  const contentTypeFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is ContentTypeFilter =>
      isContentTypeFilter(filter),
    )
    .map(mapContentTypeFilterToQueryFilter);

  // TODO: put into a function
  const searchVertical = getSearchVerticalFromFilters(
    filters,
    messagesVerticalEnabled,
    multimediaVerticalEnabled,
  );

  const appliedFilters = [
    ...connectorFilters,
    ...lastUpdatedFilters,
    ...peopleFilters,
    ...contentTypeFilters,
  ];

  // check if messages experiment is enabled, if so add the search vertical param

  cacheLastSearchRequestArguments({
    query_text: query,
    filters: appliedFilters,
  });

  // TODO: API error handling. Should probably define this at the boundary and return predictable errors
  // currently just bubbling up to the caller

  // TODO - remove after #sev-speedy-carnelian-sandpiper
  const response = await api('dcsSearch', {
    query_text: query,
    filters: appliedFilters,
    query_options: {
      disable_spell_correction: disableSpellCorrection,
    },
    search_vertical: searchVertical,
  });

  const { headers, result } = response;

  const annotations = getAnnotations(result);

  if (result.results === undefined) {
    return {
      results: [],
      annotations,
    };
  }

  const searchRequestId = result?.search_request_id || '';
  const analyticsTraceId = headers.get('x-dropbox-request-id') || '';

  // save the last search request payload and id in case of relevance snapshot
  cacheLastSearchRequestId(searchRequestId);

  const processedResults = (result.results || [])
    .map((result) => {
      const queryResult = result.query_result;
      if (!isSearchResult(queryResult)) {
        return null;
      }
      return transformDashResultToMirage(
        { ...queryResult, relevance_score: result.relevance_score },
        searchRequestId,
        analyticsTraceId,
        isAllModifiersEnabled,
      );
    })
    .filter((msr): msr is SearchResult => Boolean(msr));

  return {
    results: processedResults,
    annotations,
  };
}

export async function getMultimediaSearchResults(
  api: APIv2Callable,
  query?: string | undefined,
  cursor?: string,
): Promise<{ results: SearchResult[]; cursor: string | undefined }> {
  const fileResults = await api('dcsMediaSearchPrototype', {
    query_text: query,
    cursor,
  });
  if (fileResults === undefined) {
    return { results: [], cursor: undefined };
  }

  const results: SearchResult[] = [];

  (fileResults.response?.results || []).map((match) => {
    const relevance = match.relevance_score;
    const searchResult =
      match.query_result as dcs.query_result_unionSearchResult;
    if (!searchResult || !searchResult.media) {
      return;
    }
    const { media } = searchResult;

    if (!media.preview_url) {
      return;
    }

    const connectorInfo = buildConnectorInfo(searchResult.connector_info, true);
    const fileTypeInfo = buildFileTypeInfo(searchResult.file_type_info);

    if (!connectorInfo || !fileTypeInfo) {
      return;
    }

    results.push({
      media: {
        previewUrl: media.preview_url,
        size: media.size,
        datetimeOriginalMs: media.datetime_original_ms,
        xPixels: media.x_pixels || 0,
        yPixels: media.y_pixels || 0,
        matchType: media.match_type,
      },
      uuid: searchResult.uuid || '',
      brandedType: searchResult.branded_type || '',
      id3p: searchResult.id_3p || '',
      title: searchResult.title || '',
      /* videoPlayerUrl, */
      url: searchResult.url || '',
      mimeType: searchResult.mime_type || '',
      /* size: media.preview?.dimensions?.x_pixels || 0, */
      /* sizeDisplay: match.size_display, */
      updatedAtMs: searchResult.updated_at_ms || 0,
      virtualPath: searchResult.virtual_path,
      score: relevance || 0,
      connectorInfo: connectorInfo,
      upstreamId: searchResult.upstream_id || '',
      displayIconOverride: searchResult.display_icon_override || null,
      recordType: searchResult.record_type || DASH_DESKTOP_APPLICATION_TAG,
      description: searchResult.description || '',
      additionalLinks: searchResult.additional_links || [],
      attachments: searchResult.attachments || [],
      conferenceLinks: searchResult.conference_links || [],
      email: searchResult.email || '',
      profileImageUrl: searchResult.profile_image_url || '',
      startTime: searchResult.start_time || 0,
      endTime: searchResult.end_time || 0,
      isAllDay: searchResult.is_all_day || false,
      location: searchResult.location || '',
      recurringEventId: searchResult.recurring_event_id || '',
      providerUpdateAtMs: searchResult.provider_updated_at_ms || 0,
      summarizable: null,
      highlights: searchResult.highlights || {},
      relevanceScore: searchResult.relevance_score || 0,
    });
  });

  return { results, cursor: fileResults.cursor };
}

export const getMultimediaPreview = async (
  authService: AuthServiceContract,
  url: string,
  isDesktop: boolean = false,
  size?: number,
) => {
  const authParams = await authService.getDropboxInitializationParameters();

  const previewBase64 = await imageUrlToBase64(
    `${url}${size ? `?size=${size}x${size}` : ''}`,
    authParams.accessToken!,
    isDesktop,
  );
  return previewBase64;
};

const imageUrlToBase64 = async (
  url: string,
  accessToken: string,
  isDesktop: boolean = false,
) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: null,
    method: 'GET',
  });
  const blob = await response.blob();
  if (isDesktop) {
    const base64String = await blobToBase64(blob);
    return base64String;
  }
  return new Promise<string | undefined>((onSuccess, onError) => {
    try {
      const reader = new FileReader();
      reader.onload = function () {
        if (!this.result) {
          onSuccess(undefined);
        } else if (typeof this.result === 'string') {
          onSuccess(this.result);
        } else {
          onSuccess(arrayBufferToBase64(this.result));
        }
      };
      reader.readAsDataURL(blob);
    } catch (e) {
      onError(e);
    }
  });
};

const blobToBase64 = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = blob.stream().getReader();
    const chunks: Uint8Array[] = [];

    function read() {
      reader
        .read()
        .then(({ done, value }) => {
          if (done) {
            const buffer = Buffer.concat(chunks);
            resolve(`data:${blob.type};base64,${buffer.toString('base64')}`);
            return;
          }
          chunks.push(Buffer.from(value));
          read();
        })
        .catch(reject);
    }

    read();
  });
};
export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};
