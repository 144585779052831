import { createContext, useContext } from 'react';

import type { OnboardingSurveyId } from '@mirage/service-onboarding-surveys/service';
import type { OnboardingSurveyAnswer } from '@mirage/service-onboarding-surveys/service/types';

export type OnboardingResponsesContextType = {
  companyName: string;
  companyLogo: string;
  surveyResponses: Map<OnboardingSurveyId, OnboardingSurveyAnswer[]>;
  setCompanyName: (name: string) => void;
  setCompanyLogo: (logo: string) => void;
  setSurveyResponses: (
    surveyName: OnboardingSurveyId,
    surveyResponses: OnboardingSurveyAnswer[],
  ) => Promise<void>;
};

const OnboardingResponsesContext = createContext<
  OnboardingResponsesContextType | undefined
>(undefined);

export const useOnboardingResponses = (): OnboardingResponsesContextType => {
  const context = useContext(OnboardingResponsesContext);
  if (!context) {
    throw new Error('Missing OnboardingResponsesProvider wrapper in JSX tree');
  }
  return context;
};

export const OnboardingResponsesProvider: React.FC<
  OnboardingResponsesContextType
> = ({ children, ...props }) => {
  return (
    <OnboardingResponsesContext.Provider value={props}>
      {children}
    </OnboardingResponsesContext.Provider>
  );
};
