import { fetchMultimediaSearchResults } from '@mirage/service-dbx-api';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import { searchForContentType } from '@mirage/service-search';
import { useCheckIsMounted } from '@mirage/shared/hooks/useIsMounted';
import { contentTypeToSearchFilter } from '@mirage/shared/search/search-filters';
import { MultimediaView } from '@mirage/shared/search/search-result';
import { useEffect, useState } from 'react';
import { useMultimediaView } from '../Multimedia/hooks/useMultimediaView';
import { imagesCategoryConfig } from './config';

import type { CategoryConfig } from './config';
import type { SearchResult } from '@mirage/service-dbx-api';

const imageFilter = contentTypeToSearchFilter(imagesCategoryConfig.contentType);

const logger = tagged('category-search');

export function useCategorySearch(category: CategoryConfig, query: string) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<SearchResult[]>([]);
  const checkIsMounted = useCheckIsMounted();
  const isMessagesEnabled =
    useFeatureFlagValue('dash_2025_03_03_search_message_results') === 'ON';

  const multimediaView = useMultimediaView(imageFilter ? [imageFilter] : []);

  useEffect(() => {
    if (!query) return;

    setLoading(true);

    const fetchResults = async () => {
      try {
        let res;
        if (category.id === 'images' && multimediaView === MultimediaView.ON) {
          res = await fetchMultimediaSearchResults(query, undefined);
          if (!checkIsMounted()) return;
          setResults(res.results.slice(0, category.limit));
        } else {
          res = await searchForContentType(
            query,
            category.contentType,
            category.limit,
            isMessagesEnabled,
            multimediaView === MultimediaView.V1,
          );
          if (!checkIsMounted()) return;
          setResults(res);
        }
      } catch (error) {
        logger.error(
          `Error fetching category search results for ${category.label}`,
          error,
        );
      } finally {
        if (checkIsMounted()) setLoading(false);
      }
    };

    fetchResults();
  }, [
    query,
    category.contentType,
    checkIsMounted,
    category.label,
    category.id,
    isMessagesEnabled,
    category.limit,
    multimediaView,
  ]);

  return {
    loading,
    results,
  };
}
