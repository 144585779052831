import { stackItemComputeNameFromFields } from '@mirage/service-stacks/service/utils';

import type { stacks } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

const MAX_SUGGESTED_STACKS = 10;
const MAX_ITEMS_PER_SUGGESTED_STACK = 10;

export const getSuggestedStacksForHistory = (
  dismissedSuggestions: stacks.AutoStackSuggestion[],
  callApiV2: APIv2Callable,
): Promise<stacks.AutoStackSuggestion[] | undefined> => {
  return callApiV2('stacksGetAutoStackSuggestions', {
    dismissed_suggestions: dismissedSuggestions,
    max_num_stacks: MAX_SUGGESTED_STACKS,
    max_num_items_per_stack: MAX_ITEMS_PER_SUGGESTED_STACK,
  })
    .then((r) => r.stack_suggestions)
    .catch(() => undefined);
};

export type ContentSuggestionItem =
  | stacks.StackItemShortcut
  | {
      name: string;
      url: string;
      custom_name?: string;
      metadata_title?: string;
    };

export const getContentSuggestions = async (
  stack: stacks.Stack | string,
  items: ContentSuggestionItem[],
  scoreThreshold: number = 0.01,
  maxItems: number = 20,
  callApiV2: APIv2Callable,
) => {
  let name;
  if (typeof stack === 'string') {
    name = stack;
  } else {
    name = stack.stack_data?.name;
  }

  return await callApiV2('stacksGetContentSuggestions', {
    score_threshold: scoreThreshold,
    current_stack: {
      name,
      contents: items.slice(0, maxItems).map((i) => ({
        title: stackItemComputeNameFromFields(i),
        url: i.url,
      })),
    },
    max_num_contents: 50,
  });
};
