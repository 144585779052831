import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { ResultIcon } from '@mirage/search/SearchResults';
import { SnippetText } from '@mirage/search/SearchResults/ResultRow/SnippetText';
import { getTimeAgoStringFromTimestamp } from '@mirage/search/SearchResults/util/resultUtil';
import { overwriteMessagesTitle } from '@mirage/service-dbx-api/service/utils';
import { openResult } from '@mirage/service-result-actions';
import cn from 'classnames';
import { useCallback } from 'react';
import styles from './CategorySearchAccordion.module.css';
import { useFirstIntersection } from './useFirstIntersection';

import type { SearchResult } from '@mirage/service-dbx-api';

function ListItem({
  result,
  onResultClick,
  onResultShown,
}: {
  result: SearchResult;
  onResultClick: (result: SearchResult) => void;
  onResultShown: (result: SearchResult) => void;
}) {
  const { title, body, timeAgoString } = getListResultContent(result);
  const handleClick = () => {
    openResult(result);
    onResultClick(result);
  };

  const onShow = useCallback(() => {
    onResultShown(result);
  }, [onResultShown, result]);

  const ref = useFirstIntersection(onShow);

  return (
    <Box
      className={cn(styles.listItem, styles.clickableListItem)}
      paddingY="8"
      onClick={handleClick}
      cursor="pointer"
      role="button"
      ref={ref}
    >
      <div style={{ minWidth: '40px' }}>
        <ResultIcon result={result} size="medium" />
      </div>
      <Box display="flex" flexDirection="column" overflow="hidden">
        <div className={styles.titleRow}>
          <Text
            className={styles.title}
            variant="label"
            size="large"
            color="standard"
          >
            {title}
          </Text>
          {timeAgoString && (
            <Text
              variant="label"
              size="medium"
              color="subtle"
              className={styles.modifiedDate}
            >
              {timeAgoString}
            </Text>
          )}
        </div>
        {body && (
          <Text variant="label" size="small">
            <SnippetText
              textToHighlight={body || ''}
              numOfLines={1}
              textSize="small"
            />
          </Text>
        )}
      </Box>
    </Box>
  );
}

function LoadingListItem() {
  return (
    <Box className={styles.listItem} paddingY="8">
      <Skeleton.Avatar />
      <Box display="flex" flexDirection="column" minWidth="100%">
        <Skeleton.Text
          size="medium"
          width={330}
          className={styles.loadingText}
        />
        <Skeleton.Text
          size="small"
          width={200}
          className={styles.loadingText}
        />
      </Box>
    </Box>
  );
}

export function CategoryResultList({
  results,
  loading,
  onResultClick,
  onResultShown,
}: {
  results: SearchResult[];
  loading: boolean;
  onResultClick: (result: SearchResult) => void;
  onResultShown: (result: SearchResult) => void;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      marginBottom="8"
      className={styles.listContainer}
    >
      {loading ? (
        Array.from({ length: 3 }).map((_, index) => (
          <LoadingListItem key={index} />
        ))
      ) : results.length ? (
        results.map((result) => (
          <ListItem
            key={result.uuid}
            result={result}
            onResultClick={onResultClick}
            onResultShown={onResultShown}
          />
        ))
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Text variant="label" size="medium" color="subtle">
            No results found
          </Text>
        </Box>
      )}
    </Box>
  );
}

type ListResultContent = {
  title: string;
  body: string;
  timeAgoString?: string;
};

function getListResultContent(result: SearchResult): ListResultContent {
  const {
    filteredModifier,
    providerUpdateAtMs,
    logicalType,
    sender = null,
    messages,
    providerCreatedAtMs,
  } = result;

  if (logicalType === 'message' || messages?.length) {
    const snippets = result.highlights?.body?.text || [];
    const body =
      snippets.length > 0 ? snippets[0] : result.messages?.[0]?.body || '';
    const title = overwriteMessagesTitle(
      result.title,
      sender,
      messages || [],
      logicalType || '',
    );
    const timeAgoString = getTimeAgoStringFromTimestamp(
      providerCreatedAtMs || messages?.[0]?.createdAtMs,
      true,
    );
    return {
      title,
      body,
      timeAgoString,
    };
  }

  return {
    title: result.title,
    body: result?.highlights?.body?.text?.[0] || '',
    timeAgoString: getTimeAgoStringFromTimestamp(
      filteredModifier?.modifiedAtMs ?? providerUpdateAtMs,
      true,
    ),
  };
}
