import { Button } from '@dropbox/dig-components/buttons';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { FailLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { ComposeSources } from '@mirage/mosaics/Chat/components/compose-sources/ComposeSources';
import { ComposeUploadFileButton } from '@mirage/mosaics/Chat/components/compose-sources/ComposeUploadFileButton';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useState } from 'react';
import styles from './SourcesFromSettingsOne.module.css';

import type { RenderFooterSettings } from './SourcesFromSettingsModalStepper';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { SourcesContentCache } from '@mirage/mosaics/Chat/data/ComposeSourcesCache';
import type { ChatSource } from '@mirage/mosaics/Chat/types';

interface SourcesFromSettingsOneProps {
  title: string;
  description: string;
  sources: ChatSource[];
  handleAddSource: (source: ChatSource) => void;
  handleRemoveSource: (source: ChatSource) => void;
  sourcesContents: SourcesContentCache;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  renderFooter?: (footerSettings: RenderFooterSettings) => JSX.Element; // renderFooter is derived from SourcesFromSettingsModalStepper
}
export const SourcesFromSettingsOne = memo(
  ({
    title,
    description,
    sources,
    handleAddSource,
    handleRemoveSource,
    sourcesContents,
    logComposeEvent,
    renderFooter,
  }: SourcesFromSettingsOneProps) => {
    const isMobile = useIsMobileSize();
    const [uploadSnackbarOpen, setUploadSnackbarOpen] = useState(false);
    return (
      <>
        <div
          className={classnames(
            styles.SourcesFromSettingsOneContent,
            isMobile && styles.SourcesFromSettingsOneMobile,
          )}
        >
          <Title
            size="medium"
            className={styles.SourcesFromSettingsOneTitle}
            weightVariant="emphasized"
          >
            {title}
          </Title>
          <Text
            size="medium"
            tagName="div"
            color="subtle"
            className={styles.SourcesFromSettingsOneDescription}
          >
            {description}
          </Text>
          <div className={styles.SourcesFromSettingsOneControls}>
            <ComposeSources
              logComposeEvent={logComposeEvent}
              sources={sources || []}
              sourcesContentCache={sourcesContents}
              onAddSource={handleAddSource}
              onRemoveSource={handleRemoveSource}
              hasBorder
              hideSearchResultsLabel
              uploadButton={
                <ComposeUploadFileButton
                  onAddSource={(s: ChatSource) => {
                    handleAddSource(s);
                    setUploadSnackbarOpen(true);
                  }}
                  logComposeEvent={logComposeEvent}
                />
              }
              sourcesTitleLabel={
                <Text
                  size="small"
                  tagName="div"
                  color="standard"
                  isBold
                  className={styles.SourcesFromSettingsOneSourcesLabel}
                >
                  {i18n.t('compose_settings_reference_label')}
                </Text>
              }
              searchTitleLabel={
                <Text
                  size="small"
                  tagName="div"
                  color="standard"
                  isBold
                  className={styles.SourcesFromSettingsOneSourcesInputLabel}
                >
                  {i18n.t('compose_settings_reference_description')}
                </Text>
              }
            />
            {uploadSnackbarOpen && (
              <Snackbar.Position>
                <Snackbar
                  open
                  preferComposition
                  timeout={5000}
                  onRequestClose={() => {
                    setUploadSnackbarOpen(false);
                  }}
                >
                  <Snackbar.Content>
                    <Snackbar.Accessory>
                      <UIIcon src={FailLine} />
                    </Snackbar.Accessory>
                    <Snackbar.Message>
                      {i18n.t(
                        'compose_assistant_file_uploader_snackbar_message_templates',
                      )}
                    </Snackbar.Message>
                    <Snackbar.Actions>
                      <Button
                        variant="transparent"
                        size="medium"
                        tone="neutral"
                        onClick={() => setUploadSnackbarOpen(false)}
                      >
                        {i18n.t(
                          'compose_assistant_file_uploader_snackbar_dismiss',
                        )}
                      </Button>
                    </Snackbar.Actions>
                  </Snackbar.Content>
                </Snackbar>
              </Snackbar.Position>
            )}
          </div>
        </div>
        {renderFooter?.({ sourceCount: sources.length })}
      </>
    );
  },
);
SourcesFromSettingsOne.displayName = 'SourcesFromSettingsOne';
