import { callApiV2 } from '@mirage/service-dbx-api';
import {
  serverAccessLevelFromAccessLevel,
  serverStackPermissionFromStackPermission,
  serverSubjectRefFromSharingMemberKey,
} from './Utils';

import type {
  SharingGroup,
  SharingMember,
  SharingMemberKey,
  SharingMemberKeyWithPermission,
  SharingUserContact,
  StackAccessLevel,
  StackPermission,
} from './Types';
import type { sharing, stacks } from '@dropbox/api-v2-client';

type SortableSharingUserContact = SharingUserContact & { sortKey: string };
type SortableSharingGroup = SharingGroup & { sortKey: string };
type SortableSharingMember = SortableSharingUserContact | SortableSharingGroup;

export async function getSharingUserContacts(
  searchQuery: string,
): Promise<SharingUserContact[]> {
  const responseItemCount = 6;
  // For empty search queries, use the get_people_suggestion api
  const sortableSuggestions =
    searchQuery === ''
      ? await getPeopleSuggestions(responseItemCount)
      : ((await getSharingTargets(
          searchQuery,
          responseItemCount,
          false, // Only retrieve users, not groups
        )) as SortableSharingUserContact[]);
  return sortableSuggestions.sort((a, b) => {
    if (a.sortKey === b.sortKey) return 0;
    return a.sortKey < b.sortKey ? 1 : -1;
  });
}

export async function getSharingMembers(
  searchQuery: string,
  includeGroups: boolean = false,
): Promise<SharingMember[]> {
  const responseItemCount = 6;
  // For empty search queries, use the get_people_suggestion api
  const sortableSuggestions =
    searchQuery === ''
      ? await getPeopleSuggestions(responseItemCount)
      : await getSharingTargets(searchQuery, responseItemCount, includeGroups);
  return sortableSuggestions.sort((a, b) => {
    if (a.sortKey === b.sortKey) return 0;
    return a.sortKey < b.sortKey ? 1 : -1;
  });
}

async function getPeopleSuggestions(
  numItems: number,
): Promise<SortableSharingUserContact[]> {
  const response = await callApiV2('searchSuggestionGetPeopleSuggestion', {
    num_items: numItems,
  });
  const contacts = (response.people_suggestions ?? []).map(
    (entry): SortableSharingUserContact => ({
      '.tag': 'user',
      displayName: entry.display_name ?? '',
      email: entry.email ?? '',
      profilePhotoUrl: entry.photo_url,
      sortKey: String(entry.score ?? 0),
    }),
  );
  return contacts.sort((entry1, entry2) => {
    if (entry1.sortKey === entry2.sortKey) return 0;
    return entry1.sortKey < entry2.sortKey ? 1 : -1;
  });
}

async function getSharingTargets(
  query: string,
  numItems: number,
  includeGroups: boolean,
): Promise<SortableSharingMember[] | SortableSharingUserContact[]> {
  const response = await callApiV2('sharingTargetsSearch', {
    limit: numItems,
    query: query,
  });
  return response.entries.reduce(
    (
      filtered: SortableSharingMember[],
      entry:
        | sharing.GroupTargetReference
        | sharing.TargetReference
        | sharing.UserTargetReference,
    ) => {
      if (!('name' in entry)) return filtered;
      if (entry['.tag'] === 'user') {
        filtered.push({
          '.tag': entry['.tag'],
          email: entry.email,
          displayName: entry.name,
          profilePhotoUrl: entry.photo_url,
          sortKey: entry.sort_key,
        });
      } else if (includeGroups && entry['.tag'] === 'group') {
        filtered.push({
          '.tag': entry['.tag'],
          groupId: entry.group_id,
          displayName: entry.name,
          memberCount: entry.group_size,
          sortKey: entry.sort_key,
        });
      }
      return filtered;
    },
    [],
  );
}

export const addOrUpdateSharingMembers = async (
  namespaceId: string,
  members: SharingMemberKeyWithPermission[],
  sendNotifications: boolean,
  notificationMessage: string,
) => {
  return callApiV2('stacksAddOrUpdateStackSharingMembers', {
    namespace_id: namespaceId,
    members:
      members?.map(({ memberKey, permission }) => {
        return {
          subject_ref: serverSubjectRefFromSharingMemberKey(memberKey),
          permission: serverStackPermissionFromStackPermission(permission),
        };
      }) ?? [],
    send_notifications: sendNotifications,
    notification_message: notificationMessage,
  });
};

export const removeSharingMember = async (
  namespaceId: string,
  memberKey: SharingMemberKey,
): Promise<stacks.RemoveStackSharingMembersResponse | undefined> => {
  try {
    return await callApiV2('stacksRemoveStackSharingMembers', {
      namespace_id: namespaceId,
      members: [serverSubjectRefFromSharingMemberKey(memberKey)],
    });
  } catch {
    return undefined;
  }
};

export const createOrUpdateStackSharedLink = async (
  namespaceId: string,
  permission: StackPermission,
  accessLevel: StackAccessLevel,
): Promise<stacks.CreateOrUpdateStackSharedLinkResponse | undefined> => {
  try {
    const response = await callApiV2('stacksCreateOrUpdateStackSharedLink', {
      namespace_id: namespaceId,
      permission: serverStackPermissionFromStackPermission(permission),
      access_level: serverAccessLevelFromAccessLevel(accessLevel),
    });
    return response;
  } catch {
    return undefined;
  }
};
