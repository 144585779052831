// @generated by bzl gen
// @generated by protoc-gen-connect-web v0.3.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/doc_summarization.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import {
  DocDiffSummaryRequest,
  DocDiffSummaryResponse,
  DocQnaRequest,
  DocQnaResponse,
  DocSummarizationRequest,
  DocSummarizationResponse,
} from './context_engine_summarization_service_pb';
import { MethodKind } from '@bufbuild/protobuf';
import {
  ConversationsAssistantResponse,
  ConversationsUserRequest,
  QuerySuggestionsRequest,
  QuerySuggestionsResponse,
} from './context_engine_conversations_service_pb';
import {
  GetAnswersForQueryRequest,
  GetAnswersForQueryResult,
} from './context_engine_answers_service_pb';

/**
 * @generated from service context_engine.DocSummarizationApiV2
 */
export const DocSummarizationApiV2 = {
  typeName: 'context_engine.DocSummarizationApiV2',
  methods: {
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.GetDocSummary
     */
    getDocSummary: {
      name: 'GetDocSummary',
      I: DocSummarizationRequest,
      O: DocSummarizationResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.GetDocAnswer
     */
    getDocAnswer: {
      name: 'GetDocAnswer',
      I: DocQnaRequest,
      O: DocQnaResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.GetDocDiffSummary
     */
    getDocDiffSummary: {
      name: 'GetDocDiffSummary',
      I: DocDiffSummaryRequest,
      O: DocDiffSummaryResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.ConversationsGetResponse
     */
    conversationsGetResponse: {
      name: 'ConversationsGetResponse',
      I: ConversationsUserRequest,
      O: ConversationsAssistantResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.GenAnswersForQueryProxy
     */
    genAnswersForQueryProxy: {
      name: 'GenAnswersForQueryProxy',
      I: GetAnswersForQueryRequest,
      O: GetAnswersForQueryResult,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.FssGenAnswersForQueryProxy
     */
    fssGenAnswersForQueryProxy: {
      name: 'FssGenAnswersForQueryProxy',
      I: GetAnswersForQueryRequest,
      O: GetAnswersForQueryResult,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc context_engine.DocSummarizationApiV2.GenQuerySuggestionsProxy
     */
    genQuerySuggestionsProxy: {
      name: 'GenQuerySuggestionsProxy',
      I: QuerySuggestionsRequest,
      O: QuerySuggestionsResponse,
      kind: MethodKind.ServerStreaming,
    },
  },
} as const;
