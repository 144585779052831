import { doNotRedact } from "@alwaysmeticulous/redaction";
import type { stacks } from "@dropbox/api-v2-client";
import type { StoredRecentContent } from "@mirage/service-recent-content/service";

import { createRedactedApiV2Endpoint } from "../utils";

export const stacksGetRecentActivityRedacted = createRedactedApiV2Endpoint<
  StoredRecentContent,
  stacks.GetRecentActivityResponse
>({
  name: "stacksGetRecentActivity",
  api: "stacks",
  endpoint: "get_recent_activity",
  indexedDbKey: "recent-content",
  stringRedactors: {
    connectorName: doNotRedact,
  },
});
