import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { logPageLoadMilestoneOnce } from '@mirage/service-operational-metrics/page-load';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import { BrowserExtensionModule } from '../BrowserExtensionModule/BrowserExtensionModule';
import { ConnectAppsModule } from '../ConnectAppsModule/ConnectAppsModule';
import { ForYouModule } from '../ForYouModule/ForYouModule';
import { StackCard } from '../StackCard/StackCard';

import type { WorkingSetCardData } from '../types';

type WorkingSetGridV2Props = {
  workingSetCardData: WorkingSetCardData[];
  showConnectApps: boolean;
  homeDisplayModules: { id: string; jsx: JSX.Element }[];
};

export const WorkingSetGridV2: React.FC<WorkingSetGridV2Props> = ({
  workingSetCardData,
  showConnectApps,
  homeDisplayModules,
}) => {
  logPageLoadMilestoneOnce('WorkingSetGridV2 start');

  return (
    <TwoColumnGrid
      headerChildren={
        <ForYouModule hasNoStacks={workingSetCardData.length < 1} />
      }
      sidebarChildren={
        <>
          {homeDisplayModules.map((module) => {
            return (
              <SidebarModule key={module.id} id={module.id} jsx={module.jsx} />
            );
          })}
          {showConnectApps && <ConnectAppsModule />}
          {EnvCtx.surface !== 'extension' && <BrowserExtensionModule />}
        </>
      }
      mainChildren={
        <>
          {workingSetCardData &&
            workingSetCardData.length > 0 &&
            workingSetCardData.map((stackData) => (
              <StackCard stackData={stackData} key={stackData.id} />
            ))}
        </>
      }
    />
  );
};

const SidebarModule: React.FC<{ id: string; jsx: JSX.Element }> = ({
  id,
  jsx,
}) => {
  logPageLoadMilestoneOnce(`SidebarModule ${id} start`);

  return jsx;
};
