import { ContentIcon } from '@dropbox/dash-component-library';
import { FileIcon, FolderBaseDefaultSmall } from '@dropbox/dig-content-icons';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ActivityLine,
  ComputerLine,
  StacksLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import styled from '@emotion/styled';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { getConnectorDisplayConfig } from '@mirage/shared/connectors';
import { getEntityTypeFromResult } from '@mirage/shared/connectors/entityTypes';
import * as ConnectorIcons from '@mirage/shared/icons/connector-icons';
import { getEntityIcon } from '@mirage/shared/icons/entityIcons';
import { CREATE_STACK_ACTION_UUID } from '@mirage/shared/search/url-shortcut';
import { useMemo } from 'react';

import type { ActivityResult } from '../feed/activity-result';
import type { IconResource } from '../search/search-result';
import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { RecentConnectorContent } from '@mirage/service-recent-content/types';
import type { ConnectorName } from '@mirage/shared/connectors';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';

function getDarkModeIcon(connectorName: ConnectorName) {
  switch (connectorName) {
    case 'github':
      return ConnectorIcons.GithubDark;
  }
  return null;
}

// composite of possible result types that we can use to determine the icon
type Result =
  | SearchResult
  | Recommendation
  | RecentConnectorContent
  | SummarizableResult
  | ActivityResult;

function getLocalIconFromResult(result: Result, darkMode: boolean = false) {
  const connectorName = result?.connectorInfo?.connectorName as ConnectorName;
  // This was a temporary solution for open beta.
  // entity type should be provided from upstream
  // TODO: Update icon logic when entities are available
  const entityType = getEntityTypeFromResult(result);
  if (entityType) {
    return getEntityIcon(entityType);
  }

  const config = getConnectorDisplayConfig(connectorName);
  return (darkMode && getDarkModeIcon(connectorName)) || config.icon;
}

export function getIconFromIconResource(
  resource: IconResource,
  source: string,
  darkMode: boolean = false,
) {
  const iconUrl = darkMode ? resource.darkUrl : resource.lightUrl;
  if (iconUrl) {
    return ResultSvgFactory(iconUrl, source);
  }
  return;
}

export function getFileTypeIconFromResult(
  result: Result,
  darkMode: boolean = false,
) {
  const connectorName = result.connectorInfo?.connectorName as ConnectorName;
  const resultName = result.fileTypeInfo?.displayName || connectorName;
  if (result.fileTypeInfo?.icon) {
    const fileIcon = getIconFromIconResource(
      result.fileTypeInfo.icon,
      resultName,
      darkMode,
    );
    if (fileIcon) {
      return fileIcon;
    }
  }
  return getLocalIconFromResult(result, darkMode);
}

export function getBrandedSiteIconFromResult(
  result: Result,
  darkMode: boolean = false,
) {
  const connectorName = result.connectorInfo?.connectorName as ConnectorName;
  if (!isSummarizableResult(result) && result.brandedSiteInfo?.icon) {
    const siteIcon = getIconFromIconResource(
      result.brandedSiteInfo.icon,
      connectorName,
      darkMode,
    );
    if (siteIcon) {
      return siteIcon;
    }
  }
  return getLocalIconFromResult(result, darkMode);
}

export function getConnectorIconFromResult(
  result: Result,
  darkMode: boolean = false,
) {
  const connectorName = result.connectorInfo?.connectorName as ConnectorName;

  if (result.connectorInfo?.icon) {
    const fileIcon = darkMode
      ? result.connectorInfo.icon.darkUrl
      : result.connectorInfo.icon.lightUrl;
    if (fileIcon) {
      return ResultSvgFactory(fileIcon, connectorName);
    }
  }

  if (result.connectorInfo?.connectorIconUrl) {
    // connectorIconUrl is being deprecated
    return ResultSvgFactory(
      result.connectorInfo.connectorIconUrl,
      connectorName,
    );
  }

  return getLocalIconFromResult(result, darkMode);
}

function getConnectorIcon(
  connectorName: ConnectorName,
  darkMode: boolean = false,
  iconUrl?: string,
) {
  const config = getConnectorDisplayConfig(connectorName);
  // Respect dark mode icons if they exist, as API-provided icons don't support a dark version, yet.
  if (darkMode && getDarkModeIcon(connectorName)) {
    return getDarkModeIcon(connectorName)!;
  }

  // If an icon URL is provided, use it before the default config.
  if (iconUrl) {
    return ResultSvgFactory(iconUrl, connectorName);
  }

  return config.icon;
}

export const BaseIcon = ({
  Icon,
  size = 36,
}: {
  Icon: React.JSXElementConstructor<ConnectorIcons.IconProps>;
  size?: number | string;
}) => {
  return (
    <IconContainer size={size}>
      <Icon size={size} />
    </IconContainer>
  );
};

export function UrlSrcIcon({
  name,
  iconUrl,
  size = 36,
}: {
  name: string;
  iconUrl: string;
  size?: number | string;
}) {
  const Icon = ResultSvgFactory(iconUrl, name);
  return <BaseIcon size={size} Icon={Icon} />;
}

export function ConnectorIcon({
  connectorName,
  size = 36,
  iconUrl,
}: {
  connectorName: string;
  size?: number | string;
  iconUrl?: string;
}) {
  const isDarkMode = useTheme();
  const Icon = useMemo(
    () => getConnectorIcon(connectorName as ConnectorName, isDarkMode, iconUrl),
    [isDarkMode, iconUrl, connectorName],
  );
  return <BaseIcon size={size} Icon={Icon} />;
}

export const FileTypeIcon = ({
  result,
  size = 36,
}: {
  result: Result;
  size?: number | string;
}) => {
  const isDarkMode = useTheme();
  const Icon = useMemo(
    () => getFileTypeIconFromResult(result, isDarkMode),
    [result, isDarkMode],
  );
  return <BaseIcon size={size} Icon={Icon} />;
};

export const BrandedSiteIcon = ({
  result,
  size = 36,
}: {
  result: Result;
  size?: number | string;
}) => {
  const isDarkMode = useTheme();
  const Icon = useMemo(
    () => getBrandedSiteIconFromResult(result, isDarkMode),
    [result, isDarkMode],
  );
  return <BaseIcon size={size} Icon={Icon} />;
};

export const SearchResultIcon = ({
  result,
  size = 36,
}: {
  result: Result;
  size?: number | string;
}) => {
  const isDarkMode = useTheme();
  const Icon = useMemo(
    () => getConnectorIconFromResult(result, isDarkMode),
    [result, isDarkMode],
  );
  return <BaseIcon size={size} Icon={Icon} />;
};

export const DesktopFileIcon = ({
  result,
  size = 36,
}: {
  result: SearchResult;
  size?: number | string;
}) => {
  const extension = getExtension(result) || ''; // forces fallback to unknown icon within fileIcon
  if (extension === 'folder') {
    return <FolderBaseDefaultSmall />;
  }
  return (
    <IconContainer size={size}>
      <FileIcon
        extension={extension}
        size="small"
        width={size}
        height={size}
        hasWhitespace={false}
        hasBackground={false}
      />
    </IconContainer>
  );
};

export const DesktopFilterIcon = ({ size = 16 }: { size?: number }) => {
  return (
    <IconContainer size={size}>
      <UIIcon src={ComputerLine} />
    </IconContainer>
  );
};

export const URLShortcutIcon = ({
  result,
}: {
  result: URLShortcut;
  size?: number | string;
}) => {
  const isDarkMode = useTheme();

  const url = isDarkMode
    ? (result.icon?.darkUrl ?? result.icon?.lightUrl)
    : result.icon?.lightUrl;

  const name = result.parameters.activeHotword;
  if (result.uuid === CREATE_STACK_ACTION_UUID) {
    return <UIIcon src={StacksLine} />;
  }
  if (url) {
    const Icon = ResultSvgFactory(url, name);
    return <ContentIcon size="medium" icon={<Icon />} hasBackground={true} />;
  } else {
    return <UIIcon src={ActivityLine} />;
  }
};

const IconContainer = styled.div<{ size: number | string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
  height: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
`;

function ResultSvgFactory(url: string, name: string) {
  return function ResultSvg({ size = 24 }: ConnectorIcons.IconProps) {
    return <img width={size} height={size} src={url} alt={`${name} logo`} />;
  };
}

function getExtension(result: SearchResult): string | null {
  // Guard for null url
  const url = result.url || null;
  if (!url) return null;

  // no extension means we have a folder
  const lastDotIndex = url.lastIndexOf('.');
  if (lastDotIndex <= 1) {
    return 'folder';
  }

  const extension = url.substring(lastDotIndex + 1);
  return extension;
}

const isSummarizableResult = (result: Result): result is SummarizableResult => {
  return (result as SummarizableResult).type !== undefined;
};
