import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { urlToService } from '@mirage/shared/util/urlRulesUtil';

import type { AppShortcutPreferencesItem } from '@mirage/service-settings/service/types';
import type { ContentInfo } from '@mirage/service-stack-suggestions/service/types';

interface RootUrlItem {
  count: number;
  titles: string[];
  favicon?: string;
  fallbackFavicon?: string;
  hostname: string;
  favicon_url: string;
  service_name?: string;
}

export const resolveBrowserHistory = (
  arr: ContentInfo[],
  maxSize: number = 5,
  darkMode: boolean = false,
): AppShortcutPreferencesItem[] => {
  const rootUrls: Record<string, RootUrlItem> = {};
  arr.forEach((i) => {
    const a = document.createElement('a');
    a.href = i.url;
    const hostname = a.protocol + '//' + a.host + '/';
    if (ignoreList.some((ignore) => hostname.includes(ignore))) {
      return;
    }
    if (!rootUrls[hostname]) {
      rootUrls[hostname] = {
        count: 0,
        titles: [],
        favicon: i.fav_icon_url,
        service_name: urlToService(i.url),
        favicon_url: i.url,
        fallbackFavicon: i.fav_icon_encoded_string,
        hostname: hostname,
      };
    }
    rootUrls[hostname].count += i.visit_count ?? 1;
    if (i.title) {
      rootUrls[hostname].titles.push(i.title);
    }
    if (!rootUrls[hostname].favicon) {
      rootUrls[hostname].favicon = i.fav_icon_url;
    }
    if (!rootUrls[hostname].fallbackFavicon) {
      rootUrls[hostname].fallbackFavicon = i.fav_icon_encoded_string;
    }
  });
  const rootUrlsList = Object.values(rootUrls);
  rootUrlsList.sort((a, b) => b.count - a.count);
  return rootUrlsList.slice(0, maxSize).map((rootUrl) => {
    return {
      title: rootUrl.service_name
        ? rootUrl.service_name
        : getTitle(rootUrl.titles, rootUrl.hostname),
      url: rootUrl.hostname,
      favicon: faviconSrcForSrcUrl(rootUrl.favicon_url, 64, darkMode),
      fallbackFavicon: rootUrl.fallbackFavicon ?? rootUrl.favicon,
    };
  });
};

const getTitle = (titles: string[], hostname: string) => {
  if (replacementTitles[hostname]) {
    return replacementTitles[hostname];
  }
  const titleMap: Record<string, number> = {};
  titles.forEach((title) => {
    const parts = title.split(/[/\-·|]/);
    const trim = parts[parts.length - 1].trim();
    if (!titleMap[trim]) {
      titleMap[trim] = 0;
    }
    titleMap[trim]++;
  });
  const res = Object.entries(titleMap).map(([key, value]) => {
    return {
      key,
      value,
    };
  });
  res.sort((a, b) => b.value - a.value);
  return res?.[0]?.key ?? '';
};

const replacementTitles: Record<string, string> = {
  'https://calendar.google.com/': 'Google Calendar',
  'https://docs.google.com/': 'Google Docs',
  'https://www.dropbox.com/': 'Dropbox',
  'https://dropbox.com/': 'Dropbox',
  'https://github.com/': 'GitHub',
  'https://www.servicenow.com': 'ServiceNow',
};

const ignoreList: string[] = [
  'https://staging.dash.ai/',
  'https://dash.ai/',
  'okta',
  'localhost',
];
