import { LabelGroup } from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { ResultRow } from '@mirage/mosaics/Omnibox/components/ResultRow/ResultRow';
import cx from 'classnames';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './OmniboxResultRowAdapter.module.css';

import type { Props as TypeaheadResultRowProps } from '@mirage/search/SearchResults/TypeaheadResultRow';
import type { MouseEvent } from 'react';

type MaybeLiEl = HTMLLIElement | null;

export const OmniboxResultRowAdapter = forwardRef<
  HTMLLIElement,
  TypeaheadResultRowProps
>(
  (
    {
      result,
      query,
      isSelected = false,
      // disabled = false, // NOTE: not supporting disable results (subtle text)
      isFocused = false,
      setIsHovered,
      setIsUnhovered,
      onClick,
      observer,
      resultRowIndex,
      isMouseActive = true,
    },
    ref,
  ) => {
    const [wrapperEl, setWrapperEl] = useState<MaybeLiEl>(null);
    useImperativeHandle<MaybeLiEl, MaybeLiEl>(ref, () => wrapperEl);
    const { title, subtitle, icon } = result;

    // Observe and unobserve this paragraph
    useEffect(() => {
      if (observer) {
        if (wrapperEl) {
          observer.observe(wrapperEl);
        }
        return () => {
          if (wrapperEl) {
            observer.unobserve(wrapperEl);
          }
        };
      }
    }, [observer, wrapperEl]);

    const handleClick = (e: MouseEvent<HTMLLIElement>) => {
      onClick?.(e);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onClick?.(e as unknown as MouseEvent<HTMLLIElement>);
      }
    };

    return (
      <li
        className={cx(styles.resultRowWrapper, {
          [styles.isSelected]: isSelected || isFocused,
          [styles.mouseActive]: isMouseActive,
        })}
        onClick={handleClick}
        ref={(el) => {
          setWrapperEl(el);
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref) {
            ref.current = el;
          }
        }}
        onKeyDown={handleKeyDown}
        onMouseEnter={() => setIsHovered?.()}
        onMouseLeave={() => setIsUnhovered?.()}
        role="option"
        id={`typeahead-option-${resultRowIndex}`}
        aria-selected={isSelected}
        tabIndex={0}
      >
        {typeof title === 'string' && typeof subtitle === 'string' ? (
          <ResultRow
            icon={icon}
            title={title}
            subtitle={subtitle}
            currentQuery={query}
          />
        ) : (
          // TEMP (AYC): we're using a quick escape hatch here for now to display Shortcut URLS
          // which pass in a text as a react node instead of a string
          // omitting the subtitle for now
          <LabelGroup
            withStartAccessory={icon}
            withLabel={
              <Text size={'small'} isBold={false}>
                {title}
              </Text>
            }
            verticalAlignment={'center'}
            accessoryGap={'Micro Small'}
          />
        )}
      </li>
    );
  },
);
OmniboxResultRowAdapter.displayName = 'OmniboxResultRowAdapter';
