// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3730
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";

// Event_name: 'open.email_link'
// Description: Open event for links residing in Iterable-sent emails
// Owner: otc-growth
export type Open_EmailLink = PAPEventGeneric<
  'dash',
  'open',
  'email_link',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The identifier of the campaign relevant to this event. This is an integer that is generated for each campaign created in Megaphone, or hardcoded in yaml files for custom campaigns.
    campaignId?: number;
  }
>;

export function PAP_Open_EmailLink(properties?: Open_EmailLink['properties']): Open_EmailLink {
  return <Open_EmailLink>{
    class: 'dash',
    action: 'open',
    object: 'email_link',
    properties,
  };
}
