import { useTheme } from '@dropbox/dig-foundations';
import { ConversationInput } from '@mirage/mosaics/Chat/components/chat/ConversationInput';
import { ConversationMessageLoadingText } from '@mirage/mosaics/Chat/components/chat/ConversationMessageLoadingText';
import { SourcesFromSettingsConversationMessages } from '@mirage/mosaics/Chat/components/chat/SourcesFromSettingsConversationMessages';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import classnames from 'classnames';
import { memo } from 'react';
import styles from './SourcesFromSettingsConversationPane.module.css';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { ComposeAssistantConversationMessage } from '@mirage/shared/compose/assist-message';

interface SourcesFromSettingsConversationPaneProps {
  messages: ComposeAssistantConversationMessage[] | undefined;
  onSubmitMessage: (text: string) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  sources: ChatSource[];
  loading: boolean;
  loadingText: string;
}

export const SourcesFromSettingsConversationPane = memo(
  ({
    messages,
    onSubmitMessage,
    logComposeEvent,
    sources,
    loading,
    loadingText,
  }: SourcesFromSettingsConversationPaneProps) => {
    const isMobile = useIsMobileSize();
    const { mode } = useTheme();

    const isDarkMode = mode === 'dark';
    return (
      <div
        className={classnames(styles.SourcesFromSettingsConversationPane, {
          [styles.SourcesFromSettingsConversationPaneMobile]: isMobile,
          [styles.SourcesFromSettingsConversationPaneDarkMode]: isDarkMode,
        })}
      >
        <SourcesFromSettingsConversationMessages
          messages={messages || []}
          sources={sources}
          onRemoveSource={() => {}}
          logComposeEvent={logComposeEvent}
          loadingMessage={
            loading ? (
              <div
                className={classnames(
                  styles.LoadingMessageRow,
                  styles.ConversationMessageRowMessageAnimation,
                )}
              >
                <ConversationMessageLoadingText
                  size="medium"
                  text={loadingText}
                />
              </div>
            ) : null
          }
        />

        <ConversationInput
          variant="minimal"
          onSubmit={onSubmitMessage}
          showAddSourcesButton={false}
          sources={sources}
          logComposeEvent={logComposeEvent}
          messages={messages || []}
          disabled={loading}
        />
      </div>
    );
  },
);

SourcesFromSettingsConversationPane.displayName =
  'SourcesFromSettingsConversationPane';
