import type { Space } from '@dropbox/dig-foundations';

/**
 * Converts a Dig space value to its CSS variable equivalent.
 *
 * @param space - The space value to convert.
 * @returns The CSS variable corresponding to the space value.
 */
export const convertSpaceToCSSVariable = (space?: Space) => {
  if (!space) return undefined;
  if (space.startsWith('Macro') || space.startsWith('Micro')) {
    const key = space.toLowerCase().split(' ').join('__');
    return `var(--dig-spacing__${key})`;
  }
  return `var(--dig-spacing__dimension__${space})`;
};
