import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import i18n from '@mirage/translations';
import { useCallback, useState } from 'react';

type DeleteCommentConfirmationModalProps = {
  open: boolean;
  onCancel: () => void;
  onDelete: () => Promise<void>;
};

export const DeleteCommentConfirmationModal = ({
  open,
  onCancel,
  onDelete,
}: DeleteCommentConfirmationModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const onDeleteHandler = useCallback(async () => {
    setIsDeleting(true);
    try {
      await onDelete();
    } finally {
      setIsDeleting(false);
    }
  }, [onDelete]);

  return (
    <Modal data-testid="StackComments-delete-confirmation-modal" open={open}>
      <Modal.Header>
        <Modal.Title>{i18n.t('confirm_delete_comment_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{i18n.t('confirm_delete_comment_body')}</Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel} disabled={isDeleting}>
          {i18n.t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={onDeleteHandler}
          isLoading={isDeleting}
        >
          {i18n.t('delete')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
DeleteCommentConfirmationModal.displayName = 'DeleteCommentConfirmationModal';
