import type { Browser } from 'webextension-polyfill';

declare global {
  export const browser: Browser | undefined;
}

/**
 * PDF.js requires two main components to work:
 * 1. The main library (pdf.js)
 * 2. A Web Worker (pdf.worker.js)
 *
 * The worker needs to be loaded separately because it runs in a different thread.
 * This prevents blocking the main thread during PDF processing.
 */

/**
 * Detects if the code is running in browser extension environment —
 * importing brwoser polyfill directly instead of using the global type throws an error on web
 */
const isScoutExtension = (): boolean => {
  return !!(
    (typeof browser !== 'undefined' && browser?.runtime?.getURL) ||
    (typeof chrome !== 'undefined' && chrome?.runtime?.getURL)
  );
};

const getExtensionRuntime = () => {
  return (typeof browser !== 'undefined' ? browser : chrome).runtime;
};

/**
 * Gets the URL for the PDF.js worker script.
 */
export const getPdfWorkerUrl = () => {
  if (isScoutExtension()) {
    return getExtensionRuntime().getURL('public/pdf.worker.mjs');
  }
  return new URL(
    'pdfjs-dist/build/pdf.worker.mjs',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import.meta.url,
  ).href;
};

/**
 * Dynamically imports the PDF.js library.
 *
 * We use dynamic imports because:
 * 1. PDF.js is a large library (~2MB) and we only want to load it when needed
 * 2. Browser extensions need special handling for content scripts:
 *    - Can't directly import from node_modules in content scripts
 *    - Need to load from extension's public directory
 *    - Use webpackInclude to ensure the file is included in the build
 * 3. Web app loads directly from node_modules
 *
 * @returns Promise that resolves to the PDF.js library
 */
export const getPdfLibrary = async () => {
  if (isScoutExtension()) {
    const pdfUrl =
      /* webpackInclude: /pdf\.mjs$/ */ getExtensionRuntime().getURL(
        'public/pdf.mjs',
      );
    // webpackIgnore prevents webpack from trying to analyze the dynamic import
    // which is necessary because the URL is determined at runtime
    return import(/* webpackIgnore: true */ pdfUrl);
  }
  // Standard web environment - load from node_modules
  return import('pdfjs-dist');
};
