import UAParser from 'ua-parser-js';

import type { BuildChannel } from './types';
import type { Runtype } from 'runtypes';

export const asType =
  <T>(t: Runtype<T>) =>
  (o: unknown): T | undefined => {
    const result = t.validate(o);
    return result.success ? result.value : undefined;
  };

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export const isDefined = <T>(x: T | undefined): x is T => x !== undefined;

export const isNotNull = <T>(x: T | null): x is T => x !== null;

export function nonNil<T>(value: T | null | undefined, varName: string): T {
  if (value === undefined || value === null) {
    throw new Error(`Missing ${varName}`);
  }
  return value;
}

export function nonEmpty<T>(value: T | null | undefined, varName: string): T {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  ) {
    throw new Error(`Empty ${varName}`);
  }
  return value;
}

export const getFilenameWithoutExtension = (
  filenameWithExtension: string,
): string => {
  const lastDot = filenameWithExtension.lastIndexOf('.');
  return lastDot > 0
    ? filenameWithExtension.slice(0, lastDot)
    : filenameWithExtension;
};

export const getFileExtension = (filenameWithExtension: string): string => {
  const lastDot = filenameWithExtension.lastIndexOf('.');
  return lastDot > 0 ? filenameWithExtension.slice(lastDot + 1) : '';
};

export const getFilenameAndExtension = (
  filenameWithExtension: string,
): { filename: string; extension: string } => {
  const lastDot = filenameWithExtension.lastIndexOf('.');
  return lastDot > 0
    ? {
        filename: filenameWithExtension.slice(0, lastDot),
        extension: filenameWithExtension.slice(lastDot + 1),
      }
    : {
        filename: filenameWithExtension,
        extension: '',
      };
};

export const sortKeyCompare = (a: string, b: string) => {
  return a < b ? -1 : a > b ? 1 : 0;
};

export const coerceSortKey = (value?: string | null) => {
  return value?.trim() ? value?.trim() : null;
};

export function nowMicros() {
  return Date.now() * 1_000;
}

export const isProdBuildChannel = (buildChannel: BuildChannel) =>
  buildChannel === 'production';

export const isStagingBuildChannel = (buildChannel: BuildChannel) =>
  buildChannel === 'staging';

export const isDevBuildChannel = (buildChannel: BuildChannel) =>
  buildChannel === 'development';

export const isCanaryBuildChannel = (buildChannel: BuildChannel) =>
  buildChannel === 'canary';

export const isTestBuildChannel = (buildChannel: BuildChannel) =>
  buildChannel === 'test';

export function sleepMs(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), ms));
}

export const sleepForever = new Promise<never>(() => {});

// TODO: Remove this code after the pap-client is updated with proper fix.
// Polyfill for window.navigator used in pap-client.
if (typeof navigator === 'undefined' && typeof global !== 'undefined') {
  global.navigator = { userAgent: 'chrome' } as Navigator;
}

export const USER_AGENT_INFO = new UAParser(navigator.userAgent).getResult();

// https://sourcegraph.pp.dropbox.com/github.com/dropbox-internal/dropbox-product-components/-/blob/packages/product-components/src/utils/isEmail.ts?L11
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const isEmail = (text = '') => EMAIL_REGEX.test(text);

// https://sourcegraph.pp.dropbox.com/github.com/dropbox-internal/dropbox-product-components/-/blob/packages/product-components/src/utils/obfuscate.ts?L28:7-28:16
const URL_SCHEMA_REGEX = /^[^:]*:\/\//i;
const URL_REGEX =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const isUrl = (value: string) => {
  try {
    let url = value;
    if (!URL_SCHEMA_REGEX.test(value) && URL_REGEX.test(url)) {
      url = `https://${value}`;
    }
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const dropboxUrlFromPath = (path: string) => {
  if (path !== '' && !isUrl(path)) {
    if (!path.startsWith('/')) {
      path = `/${path}`;
    }
    return `https://dropbox.com${path}`;
  }
  return path;
};

export const isIframe = () => window.self !== window.top;

const twoDigits = (n: number): string => {
  return (n < 10 ? '0' : '') + n;
};

const threeDigits = (n: number): string => {
  return n < 10 ? '00' + n : n < 100 ? '0' + n : n + '';
};

export const getReadableTime = (): string => {
  const d = new Date();

  // https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
  const tzo = -d.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';

  const YYYY = d.getFullYear();
  const MM = twoDigits(d.getMonth() + 1);
  const DD = twoDigits(d.getDate());
  const hh = twoDigits(d.getHours());
  const mm = twoDigits(d.getMinutes());
  const ss = twoDigits(d.getSeconds());
  const ms = threeDigits(d.getMilliseconds());

  const tzHH = twoDigits(Math.floor(Math.abs(tzo) / 60));
  const tzMM = twoDigits(Math.abs(tzo) % 60);

  return `${YYYY}-${MM}-${DD}T${hh}:${mm}:${ss}.${ms}${dif}${tzHH}:${tzMM}`;
};

export const indexFromSeedString = (inputString: string, length: number) => {
  const HASH_MULTIPLIER = 5381;
  let hash = HASH_MULTIPLIER * inputString.length;
  for (let i = 0; i < inputString.length; i++) {
    hash = inputString.charCodeAt(i) + ((hash << 6) + (hash << 16) - hash);
  }

  return Math.floor(Math.abs(hash) % length);
};

export async function runWithTimeLimit<T>(
  promise: Promise<T>,
  timeoutMs: number,
): Promise<T> {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  const timeoutPromise = new Promise<never>((_, reject) => {
    timeout = setTimeout(() => {
      reject(new Error(`Time limit exceeded: timeoutMs=${timeoutMs}`));
    }, timeoutMs);
  });

  const response = await Promise.race([promise, timeoutPromise]);
  if (timeout) {
    clearTimeout(timeout);
  }

  return response;
}

export function getLastWord(str: string) {
  if (str.endsWith(' ')) {
    return '';
  }
  return str.split(' ').pop() || '';
}
