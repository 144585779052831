import i18n from '@mirage/translations';

import type {
  ContentCacheError,
  ContentCacheLoaded,
} from '@mirage/mosaics/Chat/data/ComposeSourcesCache';
import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { ComposeAssistantConversationMessage } from '@mirage/shared/compose/assist-message';

/**
 * Transient sources represent sources that are not persisted + may be constantly changing. e.g.
 * this may be a browser tab that the Assistant is interacting with.
 */
export interface TransientSource {
  source: ChatSource;
  getContent: () => Promise<ContentCacheLoaded | ContentCacheError>;
}

export function createTransientSourceMessage(
  transientSource: TransientSource,
): ComposeAssistantConversationMessage {
  const source = transientSource.source;
  const title =
    getSourceTitle(source) ||
    i18n.t('compose_assistant_message_transient_source_generic_title');
  return {
    type: 'message',
    role: 'assistant',
    text: i18n.t('compose_assistant_message_transient_sources', {
      title,
    }),
    ts: Date.now(),
    referencingSources: [source],
    actionContext: {
      type: 'read_current_browser_tab',
      sourceTitle: title,
    },
  };
}

export function getSourceTitle(source: ChatSource): string | undefined {
  switch (source.type) {
    case 'dash_search_result':
      return source.searchResult.title;
    case 'recommendation':
      return source.recommendation.title;
    case 'transient':
      return source.title;
    case 'local_file_content':
      return source.fileName;
    default:
      source satisfies never;
      return undefined;
  }
}
