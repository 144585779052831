import uniqueId from 'lodash/uniqueId';
import { Disposer } from './utils';

import type {
  AnimationConfig,
  FLIPOrchestrator,
  OnFLIP,
} from './FLIPOrchestrator';

/**
 * An orchestrator that tracks size changes to DOM elements that could trigger layout changes requiring
 * animation. Whenever a tracked element changes size, a series of callbacks are run that output
 * animations to be executed.
 */
export class ResizeFLIPOrchestrator implements FLIPOrchestrator {
  /** The max number of keyframes that can be played for an animation of the default duration. */
  private readonly maxKeyframesCount: number;
  constructor(
    /** The default duration for animations run by this orchestrator. */
    private readonly duration: number,
    /** The default easing for animations run by this orchestrator. */
    private readonly easing: string,
    /**
     * An optional multiplier for the animation's duration for debugging that changes the speed of
     * animations.
     *
     * Prefer this to increasing the duration since increasing the duration will create more frames
     * and is computationally expensive. Has no effect unless in a dev environment.
     */
    private readonly debugPlaybackRate?: number,
  ) {
    // In theory we could divide this by 16.66 to have 1 step per frame but in practice it's better to
    // have _more_ than one step per frame an let the browser do the smoothing. Dividing this further
    // down can cause some slight bumpiness in animations and at the numbers we're talking about there
    // aren't serious performance implications to inflating this by 16.66x.
    this.maxKeyframesCount = Math.round(duration / 10);
  }

  /**
   * A mapping from an ID of a subscriber to an onFLIP callback for the subscriber and a disposer
   * associated with its animations.
   */
  private readonly subscribers: Map<
    string,
    { onFLIP: OnFLIP; disposer: Disposer }
  > = new Map();

  /**
   * An observer tracking changes to registered elements.
   *
   * The orchestrator only tracks size changes, not position changes. Resize changes are more
   * straightforward to track, batch easily so we're only invoked once for a layout change affecting
   * more than one registree, and run synchronously before paint so we have time to start our
   * animations.
   */
  private readonly observer = new ResizeObserver(() => {
    const idToConfigs: Map<string, AnimationConfig[]> = new Map();

    // Cancel any currently running animations.
    for (const { disposer } of this.subscribers.values()) {
      disposer.dispose();
    }

    // Collect animations from the subscribers but _don't_ run them yet.
    //
    // Subscribers may need to measure the DOM and running animations can synchronously affect those
    // measurements. Instead, collect all of the animations, then run them all at once.
    for (const [id, { onFLIP }] of this.subscribers.entries()) {
      const configs: AnimationConfig[] = [];
      idToConfigs.set(id, configs);
      onFLIP({
        schedule: (config) => {
          configs.push(config);
        },
        duration: this.duration,
        maxKeyframesCount: this.maxKeyframesCount,
      });
    }

    // Run the animations and add them to their subscriber's associated disposer in case we need to
    // cancel the animation early. Once the animation finishes, remove it from the disposer.
    for (const [id, { disposer }] of this.subscribers.entries()) {
      const configs = idToConfigs.get(id);
      if (!configs) return;

      for (const { target, keyframes, overrides } of configs) {
        const animation = target.animate(keyframes, {
          duration: this.duration,
          easing: this.easing,
          ...overrides,
        });

        if (this.debugPlaybackRate !== undefined) {
          animation.updatePlaybackRate(this.debugPlaybackRate);
        }

        const cancel = () => animation.cancel();
        disposer.add(cancel);
        animation.addEventListener('finish', () => {
          disposer.remove(cancel);
        });
      }
    }
  });

  /**
   * Observes the element. Changes to its size will cause the orchestrator to calculate animations.
   *
   * Returns a function to deregister the element.
   */
  register(el: HTMLElement) {
    this.observer.observe(el);

    return () => {
      this.observer.unobserve(el);
    };
  }

  /**
   * Records a callback that can create animations when the orchestrator detects a layout change.
   *
   * The callback will be passed a function to create animations along with some default animation
   * values for this orchestrator.
   *
   * Returns a function to remove the callback.
   */
  subscribe(onFLIP: OnFLIP) {
    const id = uniqueId();
    this.subscribers.set(id, { onFLIP, disposer: new Disposer() });

    return () => {
      this.subscribers.get(id)?.disposer?.dispose();
      this.subscribers.delete(id);
    };
  }

  /** Cancels any animations the orchestrator is running and stops watching for new changes. */
  dispose() {
    this.observer.disconnect();
    for (const { disposer } of this.subscribers.values()) {
      disposer.dispose();
    }
  }
}
