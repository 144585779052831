import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Initiate_UpdateStackEmoji } from '@mirage/analytics/events/types/initiate_update_stack_emoji';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import {
  getDefaultColorIndex,
  useCurrentBackgroundTheme,
} from '@mirage/stacks/themes';
import i18n, { meticulousRedactedStringClass } from '@mirage/translations';
import classnames from 'classnames';
import React, { useState } from 'react';
import { EMOJI_CLEARED_SENTINEL_VALUE } from '../../Helpers/Utils';
import { StackNameEditor } from '../EditStackDetails/components/StackNameEditor';
import { EmojiPicker } from '../EmojiPicker';
import styles from './Header.module.css';
import { HeaderWithChatActions } from './HeaderWithChatActions';
import StackDescriptionRow from './StackDescriptionRow';

import type { HeaderCoreProps } from './HeaderCommon';

const HeaderWithChat: React.FC<HeaderCoreProps> = ({
  stack,
  canEdit,
  emojiPickerVisible,
  setEmojiPickerVisible,
  updateEmoji,
  stackDerivedPAPPropsWithFeature,
  updateStackNameAndDescription,
  additionalContainerStyles,
}) => {
  const backgroundTheme = useCurrentBackgroundTheme(stack);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isNewStack = stack === null;
  const [isEditingStackName, setIsEditingStackName] = useState(false);
  const newStackColorIndex = getDefaultColorIndex();
  const newStackEmoji = EMOJI_CLEARED_SENTINEL_VALUE;

  const stackTitle = stack?.stack_data?.name ?? '';
  const stackDescription = stack?.stack_data?.description ?? '';

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        <div
          className={classnames(
            styles.headerContainerAugWithChat,
            additionalContainerStyles,
          )}
        >
          <div className={styles.headerMainRow}>
            <div className={styles.headerIconContainer} data-testid="stackIcon">
              <DashStackIcon
                className={styles.stackIcon}
                colorIndex={
                  isNewStack
                    ? newStackColorIndex
                    : stack.stack_data?.color_index || getDefaultColorIndex()
                }
                aria-label={i18n.t('aria_emoji_button')}
                emoji={
                  isNewStack
                    ? newStackEmoji
                    : stack.stack_data?.emoji || EMOJI_CLEARED_SENTINEL_VALUE
                }
                size={'xlarge'}
                onClick={
                  canEdit
                    ? () => {
                        if (!emojiPickerVisible) {
                          setEmojiPickerVisible(!emojiPickerVisible);
                          reportPapEvent(
                            PAP_Initiate_UpdateStackEmoji(
                              stackDerivedPAPPropsWithFeature,
                            ),
                          );
                        }
                      }
                    : undefined
                }
                customColor={
                  isNewStack ? undefined : stack.stack_data?.custom_color
                }
              />
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={updateEmoji}
                  papProps={stackDerivedPAPPropsWithFeature}
                />
              )}
            </div>
            <div className={styles.stackTitleArea}>
              <div className={styles.stackTitleRow}>
                <div
                  className={classnames(
                    styles.stackTitleContainer,
                    meticulousRedactedStringClass,
                    { [styles.stackTitleContainerEdit]: isEditingStackName },
                  )}
                >
                  <StackNameEditor
                    stackName={stackTitle}
                    isNewStack={isNewStack}
                    updateStackNameAndDescription={
                      updateStackNameAndDescription
                    }
                    stackDerivedPAPPropsWithFeature={
                      stackDerivedPAPPropsWithFeature
                    }
                    hasWritePermissionsOverride={canEdit}
                    onEditStatusUpdate={setIsEditingStackName}
                  />
                </div>
              </div>
              <StackDescriptionRow
                stackDescription={stackDescription}
                isNewStack={isNewStack}
                updateStackNameAndDescription={updateStackNameAndDescription}
                stackDerivedPAPPropsWithFeature={
                  stackDerivedPAPPropsWithFeature
                }
                canEdit={canEdit}
              />
            </div>
            <div className={styles.headerActionsContainer}>
              <div className={styles.headerActionsItemContainer}>
                <HeaderWithChatActions />
              </div>
            </div>
          </div>
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
};

export default HeaderWithChat;
