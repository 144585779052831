import { Avatar } from '@dropbox/dig-components/avatar';
import { Button } from '@dropbox/dig-components/buttons';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  ChevronDownLine,
  ChevronUpLine,
  CommentLine,
  DeleteLine,
  EditLine,
  SendLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Create_DashLinkDescription } from '@mirage/analytics/events/types/create_dash_link_description';
import { PAP_Delete_DashLinkDescription } from '@mirage/analytics/events/types/delete_dash_link_description';
import { PAP_Edit_DashLinkDescription } from '@mirage/analytics/events/types/edit_dash_link_description';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { stackDerivePAPProps, updateStackItem } from '@mirage/service-stacks';
import { getInitials } from '@mirage/shared/account';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { MAX_STACK_ITEM_DESCRIPTION_LENGTH } from '@mirage/shared/util/constants';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './StackItemDescription.module.css';

import type { stacks } from '@dropbox/api-v2-client';
import type { TextInputRefObject } from '@dropbox/dig-components/text_fields';

interface StackItemDescriptionButtonProps {
  item: stacks.StackItemShortcut;
  toggleDescriptionEditStates: (index: number) => void;
  index: number;
  collapseDescription: boolean;
  setCollapseDescription: (apiFileId: string, state?: boolean) => void;
  hasWritePermissions: boolean;
}

export const StackItemDescriptionButton: React.FC<
  StackItemDescriptionButtonProps
> = ({
  item,
  toggleDescriptionEditStates,
  index,
  collapseDescription,
  setCollapseDescription,
  hasWritePermissions,
}) => {
  const hasDescription = Boolean(item.description?.content);

  if (hasDescription) {
    return (
      <IconButtonWithTooltip
        variant="borderless"
        size="standard"
        onClick={() => {
          if (item.api_file_id) {
            setCollapseDescription(item.api_file_id, !collapseDescription);
          }
          // buttonRef.current?.blur();
        }}
        className={classnames(styles.pointerEventReset, styles.buttonContainer)}
        tooltipProps={{
          title: collapseDescription
            ? i18n.t('expand_link_description')
            : i18n.t('collapse_link_description'),
        }}
      >
        <UIIcon src={CommentLine} />
        <UIIcon
          src={collapseDescription ? ChevronDownLine : ChevronUpLine}
          className={styles.sideIcon}
        />
      </IconButtonWithTooltip>
    );
  }

  if (!hasWritePermissions) {
    return null;
  }

  return (
    <IconButtonWithTooltip
      variant="borderless"
      size="standard"
      onClick={() => toggleDescriptionEditStates(index)}
      className={classnames(styles.pointerEventReset, styles.buttonContainer)}
      tooltipProps={{
        title: i18n.t('add_link_description'),
        isPortaled: true,
      }}
    >
      <UIIcon src={CommentLine} />
      <UIIcon src={AddLine} className={styles.sideIcon} />
    </IconButtonWithTooltip>
  );
};

interface StackItemDescriptionProps {
  toggleDescriptionEditStates: (index: number) => void;
  descriptionEditStates: boolean[];
  userProfilePhotoUrl: string | undefined;
  index?: number;
  namespace_id?: string;
  stackItem: Readonly<stacks.StackItemShortcut>;
  stack: Readonly<stacks.Stack> | null;
}

export const StackItemDescription: React.FC<StackItemDescriptionProps> = ({
  toggleDescriptionEditStates,
  descriptionEditStates,
  userProfilePhotoUrl,
  index,
  namespace_id,
  stackItem,
  stack,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const account = useDropboxAccount();
  const inputRef = useRef<TextInputRefObject>(null);

  const content = stackItem.description?.content ?? '';
  const [textContent, setTextContent] = useState(content);
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [isTextExpandable, setIsTextExpandable] = useState(false);

  const hasDescription = content !== '';
  const inEdit = index !== undefined && descriptionEditStates[index];
  const descriptionCreator = stackItem.description_creator;
  const timeAgoText = stackItem.description?.last_modified_time_utc_sec
    ? ` · ${getTimeAgoString(
        stackItem.description?.last_modified_time_utc_sec * 1000,
        {
          capitalize: false,
          abbreviateTime: true,
          abbreviateDate: true,
        },
      )}`
    : '';
  const showCreatorName = content.length > timeAgoText.length + 20; // 20 is the buffer for avatar and edit/remove buttons on hover

  const creatorAvatar = useMemo(() => {
    const avatarUrl = descriptionCreator?.profile_photo_url;
    const initials = getInitials(descriptionCreator?.display_name || '');

    return (
      <Avatar size="small" hasNoOutline src={avatarUrl}>
        {initials}
      </Avatar>
    );
  }, [descriptionCreator?.display_name, descriptionCreator?.profile_photo_url]);

  useEffect(() => {
    if (inEdit) {
      inputRef.current?.select();
    }
  }, [inEdit]);

  const updateDescription = useCallback(async () => {
    const updatedItem: stacks.StackItemShortcut = {
      ...stackItem,
      description: {
        content: textContent,
        last_modified_time_utc_sec: Date.now(),
        creator_email: account?.email,
        type: { '.tag': 'stack_item_description_type_manually_created' },
      },
    };

    await updateStackItem(namespace_id ?? '', updatedItem);

    if (stack) {
      if (hasDescription) {
        reportPapEvent(
          PAP_Edit_DashLinkDescription({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      } else {
        reportPapEvent(
          PAP_Create_DashLinkDescription({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
    }

    if (index != null) {
      toggleDescriptionEditStates(index);
    }
  }, [
    stackItem,
    textContent,
    account?.email,
    namespace_id,
    stack,
    index,
    hasDescription,
    reportPapEvent,
    toggleDescriptionEditStates,
  ]);

  const deleteDescription = useCallback(async () => {
    const updatedItem: stacks.StackItemShortcut = {
      ...stackItem,
      description: {
        content: '',
        last_modified_time_utc_sec: Date.now(),
        creator_email: '',
        type: { '.tag': 'stack_item_description_type_unspecified' },
      },
    };

    await updateStackItem(namespace_id ?? '', updatedItem);

    if (stack) {
      reportPapEvent(
        PAP_Delete_DashLinkDescription({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
    }

    setTextContent('');
  }, [stackItem, namespace_id, stack, reportPapEvent]);

  const userAvatar = useMemo(() => {
    return userProfilePhotoUrl ? (
      <Avatar size="small" hasNoOutline src={userProfilePhotoUrl} />
    ) : null;
  }, [userProfilePhotoUrl]);

  if (inEdit) {
    return (
      <div className={styles.outerWrapper}>
        <TextInput
          ref={inputRef}
          value={textContent}
          onChange={(e) => setTextContent(e.target.value)}
          onBlur={() => {
            if (index !== undefined) {
              toggleDescriptionEditStates(index);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              updateDescription();
            } else if (e.key === 'Escape') {
              // setTextContent(content);
              inputRef.current?.blur();
            }
          }}
          isTransparent
          placeholder={i18n.t('add_description')}
          size="standard"
          withLeftAccessory={userAvatar}
          withRightAccessory={
            <IconButtonWithTooltip
              variant="borderless"
              size="standard"
              onMouseDown={(e: React.MouseEvent) => {
                // This is to prevent the input from losing focus after clicking
                e.preventDefault();
              }}
              onClick={() => {
                updateDescription();
              }}
              className={styles.pointerEventReset}
              tooltipProps={{
                title: i18n.t('save_link_description'),
              }}
            >
              <UIIcon src={SendLine} />
            </IconButtonWithTooltip>
          }
          wrapperProps={{ className: styles.textInputWrapper }}
          maxLength={MAX_STACK_ITEM_DESCRIPTION_LENGTH}
        />
      </div>
    );
  }

  if (hasDescription) {
    return (
      <div className={styles.outerWrapper}>
        <div className={styles.descriptionContainer}>
          <Text className={styles.contentText}>
            {isTextExpanded ? (
              content
            ) : (
              <DashTruncateWithTooltip
                lines={3}
                onBeforeTruncate={setIsTextExpandable}
              >
                {content}
              </DashTruncateWithTooltip>
            )}
          </Text>
          {(isTextExpandable || isTextExpanded) && (
            <div>
              <Button
                size="small"
                variant="transparent"
                onClick={() => setIsTextExpanded((prev) => !prev)}
              >
                {isTextExpanded ? i18n.t('see_less') : i18n.t('see_more')}
              </Button>
            </div>
          )}
          <div className={styles.lowerContainer}>
            <div className={styles.footnoteContainer}>
              {creatorAvatar}
              {showCreatorName && descriptionCreator?.display_name && (
                <Text color="subtle">{descriptionCreator.display_name}</Text>
              )}
              {stackItem.description?.last_modified_time_utc_sec && (
                <Text color="subtle">{timeAgoText}</Text>
              )}
            </div>
            {(!stackItem.description_creator ||
              stackItem.description_creator?.email === account?.email) && (
              <div className={styles.actionButtonsContainer}>
                <IconButtonWithTooltip
                  variant="borderless"
                  size="standard"
                  onClick={() => {
                    if (index != null) {
                      toggleDescriptionEditStates(index);
                    }
                  }}
                  className={styles.pointerEventReset}
                  tooltipProps={{
                    title: i18n.t('edit_link_description'),
                  }}
                >
                  <UIIcon src={EditLine} />
                </IconButtonWithTooltip>
                <IconButtonWithTooltip
                  variant="borderless"
                  size="standard"
                  onClick={deleteDescription}
                  className={styles.pointerEventReset}
                  tooltipProps={{
                    title: i18n.t('delete_link_description'),
                  }}
                >
                  <UIIcon src={DeleteLine} />
                </IconButtonWithTooltip>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};
