import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashSpellCorrect } from '@mirage/analytics/events/types/select_dash_spell_correct';
import { PAP_Shown_DashSpellCorrect } from '@mirage/analytics/events/types/shown_dash_spell_correct';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { concatActiveFilterBindings } from '@mirage/shared/search/search-filters';
import { useCallback } from 'react';

import type { SearchFilter } from '@mirage/shared/search/search-filters';

export function useSpellCorrectionAnalytics(filters: SearchFilter[]) {
  const { reportPapEvent, searchSessionManager } = useMirageAnalyticsContext();
  const { searchQueryUuid } = useSearchQueryId();

  const reportSelectEvent = useCallback(
    (originalQuery: string, spellCorrectAction: 'corrected' | 'ignored') => {
      reportPapEvent(
        PAP_Select_DashSpellCorrect({
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          searchQueryUuid,
          activeFilters: concatActiveFilterBindings(filters),
          query: originalQuery,
          spellCorrectAction,
        }),
      );
    },
    [reportPapEvent, searchSessionManager, searchQueryUuid, filters],
  );

  const reportShownEvent = useCallback(
    (originalQuery: string) => {
      reportPapEvent(
        PAP_Shown_DashSpellCorrect({
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          searchQueryUuid,
          activeFilters: concatActiveFilterBindings(filters),
          query: originalQuery,
        }),
      );
    },
    [reportPapEvent, searchSessionManager, searchQueryUuid, filters],
  );

  return { reportSelectEvent, reportShownEvent };
}
