import classnames from 'classnames';
import styles from './BlurWithOverlay.module.css';

import type { ReactNode } from 'react';

export type BlurCondition = 'never' | 'always' | 'hover';
export type OverlayCondition = 'never' | 'always' | 'hover';

export type BlurWithOverlayProps = {
  overlay?: ReactNode;
  blurWhen?: BlurCondition;
  overlayWhen?: OverlayCondition;
};

export const BlurWithOverlay: React.FC<BlurWithOverlayProps> = ({
  children,
  overlay,
  blurWhen = 'hover',
  overlayWhen = 'hover',
}) => {
  return (
    <div className={styles.overlayContainer}>
      <div
        className={classnames(
          blurWhen === 'hover' && styles.hoverBlur,
          blurWhen === 'always' && styles.blur,
        )}
      >
        {children}
      </div>
      {/* Prevent mouse events on children */}
      <div className={styles.barrier} />
      <div
        className={classnames(
          styles.overlay,
          overlayWhen === 'hover' && styles.hoverShowOverlay,
          overlayWhen === 'always' && styles.showOverlay,
        )}
      >
        {overlay}
      </div>
    </div>
  );
};
