import { Button } from '@dropbox/dig-components/buttons';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine, FailLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { DuplicateCommentError } from '@mirage/service-comments/utils/stack';
import { showSnackbar } from '@mirage/shared/snackbar';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { useCallback, useState } from 'react';
import { CommentReadonlyBody } from './CommentReadonlyBody';
import { DeleteCommentConfirmationModal } from './DeleteCommentConfirmationModal';
import styles from './StackItemComment.module.css';

import type { CommentAuthor } from '@mirage/service-comments/types';

type StackItemCommentProps = {
  // Comment author for existing comments, otherwise should be the current user for new comments
  author: CommentAuthor;
  content?: string;
  currentUserHasWritePermissions?: boolean;
  currentUserIsStackOwner?: boolean;
  // An undefined or empty id indicates a new comment
  id?: string;
  onCancel: () => void;
  onPost: (onPostArgs: { id?: string; content: string }) => Promise<void>;
  onDelete: (id: string) => Promise<void>; // opt. only renders if author.isCurrentUser
  timestamp?: number;
};

export const StackItemComment = ({
  author,
  id,
  content,
  currentUserHasWritePermissions,
  currentUserIsStackOwner,
  onCancel,
  onPost,
  onDelete,
  timestamp,
}: StackItemCommentProps) => {
  const [commentContent, setCommentContent] = useState(content || '');
  const [isEditing, setIsEditing] = useState(!id);
  const [isPosting, setIsPosting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = useCallback(async () => {
    // Here for type safety, the button won't render if id is empty or undefined
    if (!id) return;

    try {
      await onDelete(id);

      // On successful deletion, show a success snackbar and close the modal
      showSnackbar({
        accessory: <UIIcon src={CheckmarkLine} />,
        title: i18n.t('stack_comments_delete_comment_success'),
      });

      setShowDeleteModal(false);
    } catch (e) {
      // We intentionally keep the modal open to make it clear that the delete failed and allow the user to retry
      showSnackbar({
        accessory: <UIIcon src={FailLine} />,
        title: i18n.t('stack_comments_failed_to_delete'),
      });
    }
  }, [id, onDelete]);

  const handleUpdateOrPost = useCallback(async () => {
    setIsPosting(true);

    const onPostArgs = id
      ? { id, content: commentContent }
      : { content: commentContent };

    try {
      await onPost(onPostArgs);
      setIsEditing(false);

      showSnackbar({
        accessory: <UIIcon src={CheckmarkLine} />,
        title: id
          ? i18n.t('stack_comments_edit_comment_success')
          : i18n.t('stack_comments_add_comment_success'),
      });
    } catch (e) {
      // Show a targeted error message for duplicate comments
      if (e instanceof DuplicateCommentError) {
        showSnackbar({
          accessory: <UIIcon src={FailLine} />,
          title: i18n.t('stack_comments_failed_duplicate_comment'),
        });
      } else {
        showSnackbar({
          accessory: <UIIcon src={FailLine} />,
          title: id
            ? i18n.t('stack_comments_failed_to_update')
            : i18n.t('stack_comments_failed_to_add'),
        });
      }
    } finally {
      setIsPosting(false);
    }
  }, [onPost, id, commentContent, setIsEditing, setIsPosting]);

  const handleCancel = useCallback(async () => {
    onCancel();

    // Reset local state (technically only matters for edits, but is a safe operation regardless)
    setCommentContent(content || '');
    setIsEditing(false);
  }, [content, commentContent, setIsEditing, onCancel, author.isCurrentUser]);

  return (
    <>
      <Box className={styles.commentContainer}>
        <Box className={styles.commentBodyContainer}>
          {isEditing ? (
            <TextArea
              // This element is conditional rendered and SHOULD take focus when it first appears
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              className={styles.commentInput}
              // Used to identify comment text during drag and drop
              data-stack-comment-content
              maxLength={2000}
              onChange={(e) => setCommentContent(e.currentTarget.value)}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length,
                )
              }
              onKeyDown={(e) => {
                if (e.key === KeyCodes.escape) {
                  // Treat the escape key as a cancel action when the input is focused
                  handleCancel();
                } else if (
                  e.key === KeyCodes.enter &&
                  (e.ctrlKey || e.metaKey)
                ) {
                  // Treat ctrl+enter or cmd+enter as a submit action when the input is focused
                  handleUpdateOrPost();
                }
              }}
              placeholder={i18n.t('stack_item_comment_placeholder')}
              resizable="auto"
              rows={1}
              value={commentContent}
              disabled={isPosting}
            />
          ) : (
            <CommentReadonlyBody
              author={author}
              content={commentContent}
              currentUserHasWritePermissions={currentUserHasWritePermissions}
              currentUserIsStackOwner={currentUserIsStackOwner}
              onDeleteClick={() => setShowDeleteModal(true)}
              onEditClick={() => setIsEditing(true)}
              timestamp={timestamp}
            />
          )}
          {isEditing && (
            <Box className={styles.commentButtonContainer}>
              <Button
                disabled={!commentContent}
                isLoading={isPosting}
                onClick={handleUpdateOrPost}
                size="small"
                tone="neutral"
                variant="primary"
              >
                {id ? i18n.t('save') : i18n.t('stack_item_comment_post')}
              </Button>
              <Button
                disabled={isPosting}
                onClick={handleCancel}
                size="small"
                tone="neutral"
                variant="opacity"
              >
                {i18n.t('cancel')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {showDeleteModal && (
        // Possible improvement: See if we can move this modal to a higher level in the component tree
        <DeleteCommentConfirmationModal
          open={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};
