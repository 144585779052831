import { tagged } from '@mirage/service-logging';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import { fetchUserEligibility } from '..';

export type DashUserAttributes = {
  isDfbUser: boolean;
  isEligible: boolean;
  canSeeDfbInviteMemberForm: boolean;
};

export const userAttributesAtom = atomWithStorage<DashUserAttributes | null>(
  'dashUserAttributes',
  null,
);

const logger = tagged('useDashUserAttributes');

export const useDashUserAttributes = () => {
  const [userAttributes, setUserAttributes] = useAtom(userAttributesAtom);

  const fetchAttributes = useCallback(async () => {
    try {
      const response = await fetchUserEligibility();
      const { dash_user_attributes } = response || {};

      if (dash_user_attributes) {
        setUserAttributes({
          isDfbUser: dash_user_attributes.is_dfb_user || false,
          isEligible: dash_user_attributes.is_eligible || false,
          canSeeDfbInviteMemberForm:
            dash_user_attributes.can_see_dfb_invite_member_form || false,
        });
      }
    } catch (err) {
      logger.error('Failed to fetch user attributes', err);
    }
  }, [setUserAttributes]);

  useEffect(() => {
    fetchAttributes();
  }, [fetchAttributes]);

  return { userAttributes, refetch: fetchAttributes };
};
