import { Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { DropboxLine } from '@dropbox/dig-icons/assets';
import { getTimeOfDay } from '@mirage/mosaics/StartPage/Calendar/utils';
import { useName } from '@mirage/service-auth/useName';
import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import i18n from '@mirage/translations';
import { PersistedOmnibox } from '../Omnibox/withPersistedOmniboxSurface';
import styles from './GreeterHeader.module.css';

export const GreeterHeader = () => {
  // This is redundant but adding for safety in case the greeter header gets used before April 24th
  const isOmniboxEnabled = useFeatureFlagValueBool('dash_2025_03_18_omnibox');

  const { firstName } = useName();

  return (
    <Box className={styles.greeterHeaderWrapper}>
      <Box className={styles.greeterHeaderContainer}>
        <Box className={styles.iconContainer}>
          <UIIcon src={DropboxLine} height={40} width={40} />
        </Box>
        <Title
          weightVariant="emphasized"
          size="large"
          className={styles.titleWrapper}
        >
          {i18n.t('assistant_empty_chat_title', {
            time: getTimeOfDay(),
            name: firstName,
          })}
        </Title>
      </Box>
      {isOmniboxEnabled && <PersistedOmnibox />}
    </Box>
  );
};

GreeterHeader.displayName = 'GreeterHeader';
