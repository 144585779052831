import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import i18n from '@mirage/translations';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useSelectedMultimediaSearchResult } from '../Multimedia/hooks/useSelectedMultimediaSearchResult';
import styles from './CategoryImageGrid.module.css';
import { useFetchMultimediaPreview } from './useFetchMultimediaPreview';
import { useFirstIntersection } from './useFirstIntersection';

import type { SearchResult } from '@mirage/service-dbx-api';

export function CategoryImageGrid({
  results,
  loading,
  onResultClick,
  onResultShown,
}: {
  results: SearchResult[];
  loading: boolean;
  onResultClick: (result: SearchResult) => void;
  onResultShown: (result: SearchResult) => void;
}) {
  return (
    <div className={styles.gridContainer}>
      {loading
        ? Array.from({ length: 4 }).map((_, index) => (
            <SkeletonGridItem key={index} />
          ))
        : results.map((result) => (
            <ImageResult
              key={result.uuid}
              result={result}
              onResultClick={onResultClick}
              onResultShown={onResultShown}
            />
          ))}
    </div>
  );
}

function SkeletonGridItem() {
  return <Skeleton.Box className={styles.skeletonItem} />;
}

function ImageResult({
  result,
  onResultClick,
  onResultShown,
}: {
  result: SearchResult;
  onResultClick: (result: SearchResult) => void;
  onResultShown: (result: SearchResult) => void;
}) {
  const previewUrl = result.media?.previewUrl || result.previewUrl;

  const { base64, base64Loading, imageLoading, onImageLoad, hasError } =
    useFetchMultimediaPreview(previewUrl || '', 256);
  const { openMultimediaSearchResult } = useSelectedMultimediaSearchResult();

  // prevent flash of alt tag while rendering the base64
  const [imgAlt, setImgAlt] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (imageLoading) {
      setImgAlt(undefined);
    } else {
      setTimeout(() => {
        setImgAlt(result.title);
      }, 100);
    }
  }, [imageLoading, result.title]);

  const onShown = useCallback(() => {
    onResultShown(result);
  }, [result, onResultShown]);

  const ref = useFirstIntersection(onShown);

  if (!previewUrl) {
    return null;
  }

  if (base64Loading && !hasError && !base64) {
    return <SkeletonGridItem />;
  }

  const handleClick = () => {
    openMultimediaSearchResult(result);
    onResultClick(result);
  };

  return (
    <>
      {imageLoading && <SkeletonGridItem />}
      <Box
        padding="0"
        borderRadius="Small"
        maxWidth="100%"
        alignItems="center"
        justifyContent="center"
        backgroundColor="Background Subtle"
        className={cn(styles.imageContainer, {
          [styles.imageLoading]: imageLoading,
          [styles.renderDelay]: !imgAlt,
        })}
        onClick={handleClick}
      >
        {hasError ? (
          <Text variant="label" size="small" color="subtle">
            {i18n.t('image_preview_error')}
          </Text>
        ) : (
          <img
            alt={imgAlt}
            src={base64}
            className={styles.image}
            onLoad={onImageLoad}
            ref={ref}
          />
        )}
      </Box>
    </>
  );
}
