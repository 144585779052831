import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { dashThemeOverrides } from '@mirage/service-settings/theming/DashThemeProvider';

import type { ReactNode } from 'react';

export const UnthemedWrapper = ({ children }: { children: ReactNode }) => (
  <ThemeProvider overrides={dashThemeOverrides}>
    <ThemeContainer>{children}</ThemeContainer>
  </ThemeProvider>
);
