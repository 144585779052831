import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Confirm_StacksWarnDuplicateLinkModal } from '@mirage/analytics/events/types/confirm_stacks_warn_duplicate_link_modal';
import { PAP_Dismiss_StacksWarnDuplicateLinkModal } from '@mirage/analytics/events/types/dismiss_stacks_warn_duplicate_link_modal';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import i18n, { Trans } from '@mirage/translations';
import { useCallback } from 'react';

import type { stacks } from '@dropbox/api-v2-client';

export const AddLinkToStackWithConfirmation: React.FC<{
  hideModal: () => void;
  url: string;
  addLinkToStackFn: () => void;
  stack: stacks.Stack | null;
}> = ({ hideModal, url, addLinkToStackFn, stack }) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleCancel = useCallback(() => {
    hideModal();
    if (stack) {
      reportPapEvent(
        PAP_Dismiss_StacksWarnDuplicateLinkModal(stackDerivePAPProps(stack)),
      );
    }
  }, [hideModal, reportPapEvent, stack]);

  const handleConfirm = useCallback(() => {
    addLinkToStackFn();
    hideModal();
    if (stack) {
      reportPapEvent(
        PAP_Confirm_StacksWarnDuplicateLinkModal(stackDerivePAPProps(stack)),
      );
    }
  }, [addLinkToStackFn, hideModal, reportPapEvent, stack]);

  return (
    <Modal
      open
      width="small"
      withCloseButton="Close"
      onRequestClose={handleCancel}
    >
      <Modal.Header hasBottomSpacing="title-small">
        <Modal.Title size="small">
          {i18n.t('stack_item_already_exists')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="stack_item_already_exists_warning_modal_body"
          values={{ url }}
          components={{
            strong: <Text isBold style={{ wordBreak: 'break-all' }} />,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={handleCancel}>
          {i18n.t('cancel')}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {i18n.t('add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
