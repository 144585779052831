import { Chip } from '@dropbox/dig-components/chip';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, LockLine, TeamLine } from '@dropbox/dig-icons/assets';
import { PAP_Click_AddLaterButton } from '@mirage/analytics/events/types/click_add_later_button';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { AccessType } from '@mirage/shared/compose/assist-api';
import {
  PRE_CONFIGURED_TEMPLATE_IDS,
  templateIdToTemplateName,
} from '@mirage/shared/compose/assist-template';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import styles from './ContextInputMessage.module.css';

import type { SourcesContentCache } from '../../data/ComposeSourcesCache';
import type { ChatSource } from '../../types';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { AssistCustomTemplate } from '@mirage/shared/compose/assist-template';
import type { ContentTypeOption } from '@mirage/shared/compose/compose-session';

interface ContextInputMessageWrapperProps {
  instruction: string;
  children: React.ReactNode;
  didSelectContentType?: boolean;
}
export const ContextInputMessageWrapper = memo(
  ({
    instruction,
    children,
    didSelectContentType,
  }: ContextInputMessageWrapperProps) => {
    // Use ref to recapture focus on text options for screen reader
    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      wrapperRef.current?.focus();
    }, []);
    return (
      <div
        className={styles.ContextInputMessageWrapper}
        ref={wrapperRef}
        tabIndex={-1}
      >
        <div className={styles.ContextInputMessageWrapperContent}>
          <Text
            size="medium"
            tagName="div"
            className={styles.ContextInputMessageInstruction}
          >
            {instruction}
          </Text>
          <div
            className={
              (didSelectContentType &&
                styles.ContextInputMessageWrapperDisabled) ||
              ''
            }
          >
            {children}
          </div>
        </div>
      </div>
    );
  },
);
ContextInputMessageWrapper.displayName = 'ContextInputMessageWrapper';

interface ContextInputContentTypeMessageProps {
  currentContentType: ContentTypeOption | undefined | null;
  currentTemplate: string | undefined | null;
  onTemplateChange: (templateID: string | null, templateName: string) => void;
  onContentTypeChange: (contentType: ContentTypeOption | null) => void;
  didSelectContentType: boolean;
  templates: AssistCustomTemplate[];
}
export const ContextInputContentTypeMessage = memo(
  ({
    currentContentType,
    currentTemplate,
    onTemplateChange,
    onContentTypeChange,
    didSelectContentType,
    templates,
  }: ContextInputContentTypeMessageProps) => {
    const templatesEnabled =
      useFeatureFlagValue('dash_2025_02_20_assist_templates') === 'ON';
    const teamTemplates = useMemo(
      () =>
        templates.filter((template) => template.accessType === AccessType.TEAM),
      [templates],
    );
    const individualTemplates = useMemo(
      () =>
        templates.filter(
          (template) => template.accessType === AccessType.INDIVIDUAL,
        ),
      [templates],
    );
    return (
      <ContextInputMessageWrapper
        instruction={i18n.t('compose_content_type_question')}
        didSelectContentType={didSelectContentType}
      >
        {templatesEnabled && (
          <>
            <Box display="flex" marginBottom="Micro Large">
              <Chip
                className={classnames(
                  styles.ContextInputContentTypeMessageChip,
                  {
                    [styles.ContextInputContentTypeMessageChipSelected]:
                      didSelectContentType && currentTemplate === undefined,
                  },
                )}
                key="other"
                variant="transparent"
                onClick={() => {
                  onTemplateChange(
                    null,
                    i18n.t('assist_templates_type_scratch'),
                  );
                }}
                isSelected={
                  didSelectContentType && currentTemplate === undefined
                }
              >
                <Chip.Content>
                  {i18n.t('assist_templates_type_scratch')}
                </Chip.Content>
              </Chip>
            </Box>
            {individualTemplates.length > 0 && (
              <Box marginBottom="Micro Large">
                <Text
                  size="small"
                  color="subtle"
                  tagName="div"
                  className={styles.ContextInputMessageInstruction}
                >
                  {i18n.t('assist_templates_home_subtitle_your_templates')}
                </Text>
                {individualTemplates.map((option) => (
                  <Chip
                    className={classnames(
                      styles.ContextInputContentTypeMessageChip,
                      {
                        [styles.ContextInputContentTypeMessageChipSelected]:
                          option.id === currentTemplate,
                      },
                    )}
                    key={option.id}
                    variant="transparent"
                    onClick={() => onTemplateChange(option.id, option.name)}
                    isSelected={option.id === currentTemplate}
                  >
                    <Chip.IconAccessory>
                      <UIIcon src={LockLine} />
                    </Chip.IconAccessory>
                    <Chip.Content>{option.name}</Chip.Content>
                  </Chip>
                ))}
              </Box>
            )}
          </>
        )}
        {templatesEnabled ? (
          <>
            <Text
              size="small"
              color="subtle"
              tagName="div"
              className={styles.ContextInputMessageInstruction}
            >
              {i18n.t('assist_templates_home_subtitle_starter_templates')}
            </Text>
            <Box marginBottom="Micro Large">
              {PRE_CONFIGURED_TEMPLATE_IDS.map((option) => {
                const name = templateIdToTemplateName[option];
                return (
                  <Chip
                    className={classnames(
                      styles.ContextInputContentTypeMessageChip,
                      {
                        [styles.ContextInputContentTypeMessageChipSelected]:
                          option === currentTemplate,
                      },
                    )}
                    key={option}
                    variant="transparent"
                    onClick={() => onTemplateChange(option, name)}
                    isSelected={option === currentTemplate}
                  >
                    <Chip.Content>{name}</Chip.Content>
                  </Chip>
                );
              })}
            </Box>
            {teamTemplates.length > 0 && (
              <Box marginBottom="Micro Medium">
                <Text
                  size="small"
                  color="subtle"
                  tagName="div"
                  className={styles.ContextInputMessageInstruction}
                >
                  {i18n.t('assist_templates_home_subtitle_company_templates')}
                </Text>
                {teamTemplates.map((option) => (
                  <Chip
                    className={classnames(
                      styles.ContextInputContentTypeMessageChip,
                      {
                        [styles.ContextInputContentTypeMessageChipSelected]:
                          option.id === currentTemplate,
                      },
                    )}
                    key={option.id}
                    variant="transparent"
                    onClick={() => onTemplateChange(option.id, option.name)}
                    isSelected={option.id === currentTemplate}
                  >
                    <Chip.IconAccessory>
                      <UIIcon src={TeamLine} />
                    </Chip.IconAccessory>
                    <Chip.Content>{option.name}</Chip.Content>
                  </Chip>
                ))}
              </Box>
            )}
          </>
        ) : (
          CONTENT_TYPES_CHOICES.map((option) => (
            <Chip
              className={classnames(styles.ContextInputContentTypeMessageChip, {
                [styles.ContextInputContentTypeMessageChipSelected]:
                  option === currentContentType,
              })}
              key={option}
              variant="transparent"
              onClick={() => onContentTypeChange(option)}
              isSelected={option === currentContentType}
            >
              <Chip.Content>{contentTypeOptionToString(option)}</Chip.Content>
            </Chip>
          ))
        )}
        {!templatesEnabled && (
          <Chip
            className={classnames(styles.ContextInputContentTypeMessageChip, {
              [styles.ContextInputContentTypeMessageChipSelected]:
                didSelectContentType && currentContentType === undefined,
            })}
            key="other"
            variant="transparent"
            onClick={() => onContentTypeChange(null)}
            isSelected={
              didSelectContentType && currentContentType === undefined
            }
          >
            <Chip.Content>
              {i18n.t('compose_context_form_content_type_none')}
            </Chip.Content>
          </Chip>
        )}
      </ContextInputMessageWrapper>
    );
  },
);
ContextInputContentTypeMessage.displayName = 'ContextInputContentTypeMessage';

const CONTENT_TYPES_CHOICES: ContentTypeOption[] = [
  'email',
  'report',
  'proposal',
];

interface ContextInputSourcesMessageProps {
  sources: ChatSource[];
  sourcesContentCache: SourcesContentCache;
  handleClickAdd: () => void;
  removeSource: (source: ChatSource) => void;
  onDone?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const ContextInputSourcesMessage = memo(
  ({
    sources,
    handleClickAdd,
    onDone,
    logComposeEvent,
  }: ContextInputSourcesMessageProps) => {
    const [showAddLater, setShowAddLater] = useState(true);
    return (
      <ContextInputMessageWrapper
        instruction={i18n.t('compose_sources_question')}
      >
        <div className={styles.ContextInputSourcesActions}>
          <Chip
            variant="transparent"
            size="medium"
            onClick={handleClickAdd}
            className={styles.ContextInputSourceActionChip}
          >
            <Chip.IconAccessory>
              <UIIcon src={AddCircleLine} />
            </Chip.IconAccessory>
            <Chip.Content>{i18n.t('compose_add_source_action')}</Chip.Content>
          </Chip>
          {showAddLater && onDone && sources.length === 0 && (
            <Chip
              className={styles.ContextInputSourceActionChip}
              variant="transparent"
              onClick={() => {
                logComposeEvent(PAP_Click_AddLaterButton());
                setShowAddLater(false);
                onDone();
              }}
            >
              <Chip.Content>{i18n.t('skip_button')}</Chip.Content>
            </Chip>
          )}
        </div>
      </ContextInputMessageWrapper>
    );
  },
);
ContextInputSourcesMessage.displayName = 'ContextInputSourcesMessage';

export function contentTypeOptionToString(option: ContentTypeOption): string {
  switch (option) {
    case 'email':
      return i18n.t('compose_context_form_content_type_email');
    case 'message':
      return i18n.t('compose_context_form_content_type_message');
    case 'report':
      return i18n.t('compose_context_form_content_type_report');
    case 'proposal':
      return i18n.t('compose_context_form_content_type_proposal');
    case 'social_media_post':
      return i18n.t('compose_context_form_content_type_social_media_post');
    case 'meeting_agenda':
      return i18n.t('compose_context_form_content_type_meeting_agenda');
    default:
      option satisfies never;
      throw new Error(`Unknown content type option: ${option}`);
  }
}
