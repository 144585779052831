import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useCallback } from 'react';
import { RedirectVariant } from '../types';

export const isOnWebOnboarding = (pathname: string, protocol: string) =>
  // allow /authconfirm so users can create connections during onboarding
  pathname.toLowerCase().includes('/authconfirm') ||
  // Always show for desktop
  (pathname.toLowerCase().includes('/setup') &&
    protocol.toLowerCase().startsWith('http'));

export const useWebOnboardingEligibility = () => {
  const isCanCreateDashFreemiumSubEnabled =
    useFeatureFlagValue(
      'otc_growth_2025_03_04_eligible_to_create_dash_freemium_sub',
    ) === 'ON';

  const isUserEligibleOnWeb = useCallback(
    (variant: RedirectVariant, isDfbUser: boolean, isEligible: boolean) => {
      if (variant === RedirectVariant.DfbOnboarding) {
        return true;
      }

      if (isDfbUser && isEligible) {
        return true;
      }

      // TODO: Replace with actual API call to check eligibility
      if (!isDfbUser && isCanCreateDashFreemiumSubEnabled && !isEligible) {
        return true;
      }

      return false;
    },
    [isCanCreateDashFreemiumSubEnabled],
  );

  return { isUserEligibleOnWeb };
};
