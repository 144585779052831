import { fetchPeopleRecommendations } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import { CacheKey } from '@mirage/service-typeahead-search/service/typeahead-cache';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import { shouldSyncRecommendations } from './utils/should-sync-recommendations';

import type { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';

const logger = tagged('typeahead/sync-people-recommendations');

const SYNC_RECOMMENDATIONS_JOB_NAME = 'people-recommendations-sync';
export const SYNC_INTERVAL_MS = ONE_MINUTE_IN_MILLIS * 10;

export function start(cache: TypeaheadCache) {
  register(SYNC_RECOMMENDATIONS_JOB_NAME, SYNC_INTERVAL_MS, true, () =>
    sync(cache),
  );
}

export function cancel() {
  unregister(SYNC_RECOMMENDATIONS_JOB_NAME);
}

// sync, clear, and insert logic
const metrics = namespace('typeahead');
export async function sync(cache: TypeaheadCache) {
  try {
    const lastPeopleRecommendationsSyncMs =
      await cache.getLastPeopleRecommendationsSyncMs();

    if (
      shouldSyncRecommendations(
        lastPeopleRecommendationsSyncMs,
        'people-recommendations',
        SYNC_INTERVAL_MS,
      )
    ) {
      const peopleRecommendations = await fetchPeopleRecommendations({});

      metrics.stats('sync/count', peopleRecommendations.people?.length || 0);
      metrics.counter('sync/status', 1, {
        status: 'success',
      });

      await cache.clear(CacheKey.PeopleRecommendations);
      await cache.cachePeopleRecommendations(peopleRecommendations.people);
      await cache.savePeopleRecommendationsSyncedMs();
    }
  } catch (e) {
    logger.error('sync failed', e);
    metrics.counter('sync/status', 1, {
      status: 'error',
    });
    throw e;
  }
}
