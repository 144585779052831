import { tagged } from '@mirage/service-logging';
import { ONE_SECOND_IN_MILLIS } from '@mirage/shared/util/constants';

const logger = tagged('typeahead/sync-recommendations');

export function shouldSyncRecommendations(
  lastRecommendationsSyncMs: number | undefined,
  type:
    | 'recommendations'
    | 'people-recommendations'
    | 'channel-recommendations',
  durationMs: number,
): boolean {
  if (lastRecommendationsSyncMs == null) {
    logger.debug(
      `No last sync timestamp found, syncing ${type} recommendations`,
    );
    return true;
  }

  const cacheTTL = durationMs - ONE_SECOND_IN_MILLIS;
  const ageMs = Date.now() - lastRecommendationsSyncMs;
  const syncedRecently = ageMs < cacheTTL;

  if (ageMs < 0) {
    logger.warn(
      `Last sync timestamp is in the future, syncing ${type} recommendations`,
    );
    return true;
  } else if (syncedRecently) {
    logger.debug(`Skipping ${type} sync, last sync was only ${ageMs}ms ago`);
    return false;
  }

  return true;
}
