import { Modal } from '@dropbox/dig-components/modal';
import classnames from 'classnames';
import * as React from 'react';
import styles from './InfoModal.module.css';

import type { ModalWidth } from '@dropbox/dig-components/modal';
import type { TitlePropsBase } from '@dropbox/dig-components/typography';

type Props = {
  // Modal props
  open: boolean;
  onClose: () => void;
  withClose?: boolean;
  closeOnOverlayClick?: boolean;

  // Content props
  spotlight?: React.ReactNode;
  title?: React.ReactNode;
  body?: React.ReactNode;
  actions?: Array<React.ReactNode>;
  width?: ModalWidth;
  titleSize?: TitlePropsBase['size'];
  bodyClassName?: string;
};

export function InfoModal({
  open,
  spotlight,
  title,
  body,
  actions,
  onClose,
  bodyClassName,
  withClose = false,
  closeOnOverlayClick = true,
  width = 'small',
  titleSize = 'small',
}: Props) {
  return (
    <Modal
      open={open}
      onRequestClose={onClose}
      withCloseButton={withClose ? 'close' : undefined}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      isCentered={true}
      shouldFocusAfterRender={true}
      className={styles.modalWindow}
      width={width}
    >
      {spotlight && (
        <Modal.Header className={styles.modalSpotlight}>
          {spotlight}
        </Modal.Header>
      )}
      {title && (
        <Modal.Header className={styles.modalTitle}>
          <Modal.Title size={titleSize} weightVariant="emphasized">
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      {body && (
        <Modal.Body
          className={classnames(styles.modalBody, bodyClassName)}
          style={width === 'small' ? { maxHeight: '240px' } : {}}
        >
          {body}
        </Modal.Body>
      )}
      {actions && <Modal.Footer>{actions}</Modal.Footer>}
    </Modal>
  );
}
