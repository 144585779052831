import { useEffect, useState } from 'react';
import { useSelectedMultimediaSearchResult } from './hooks/useSelectedMultimediaSearchResult';
import { MultimediaResultModal } from './MultimediaResultModal';

export const MultimediaResultModalContainer = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { result, closeMultimediaSearchResult } =
    useSelectedMultimediaSearchResult();

  useEffect(() => {
    if (result) {
      setIsOpen(true);
    }
  }, [result]);
  if (result && isOpen) {
    return (
      <MultimediaResultModal
        onRequestClose={closeMultimediaSearchResult}
        key={result.previewUrl}
        result={result}
      />
    );
  } else {
    return null;
  }
};
