import { Text } from '@dropbox/dig-components/typography';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { useStackChat } from '@mirage/service-experimentation/useStackChat';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import i18n, { meticulousRedactedStringClass } from '@mirage/translations';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import styles from '../EditStackDetails.module.css';
import { Expand } from './Expand';
interface ReadOnlyModeDescriptionProps {
  onClick: () => void;
  stackDescription: string;
}

export const ReadOnlyModeDescription: React.FC<
  ReadOnlyModeDescriptionProps
> = ({ onClick, stackDescription }) => {
  const isDescriptionEmpty = stackDescription.trim() === '';
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isDescriptionExpandable, setIsDescriptionExpandable] = useState(false);
  const isAugRev = useStackPageAugustRevisionEnabled();
  const isStackChatEnabled = useStackChat();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <>
      {isDescriptionEmpty ? (
        <div>
          <Text
            className={classNames({
              [styles.stackDescriptionAugRev]: isAugRev,
              [styles.stackDescriptionChat]: isStackChatEnabled,
            })}
            color="faint"
            size={isStackChatEnabled ? 'large' : 'medium'}
            onClick={onClick}
            aria-label={i18n.t('edit_stack_description')}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            {i18n.t('stack_description_placeholder')}
          </Text>
        </div>
      ) : (
        <div>
          <Text
            className={classNames(meticulousRedactedStringClass, {
              [styles.stackDescription]: !isAugRev,
              [styles.stackDescriptionAugRev]: isAugRev,
              [styles.stackDescriptionChat]: isStackChatEnabled,
            })}
            onClick={onClick}
            size={isStackChatEnabled ? 'large' : 'medium'}
            aria-label={i18n.t('edit_stack_description')}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            {isDescriptionExpanded ? (
              stackDescription
            ) : (
              <DashTruncateWithTooltip
                lines={4}
                onBeforeTruncate={setIsDescriptionExpandable}
              >
                {stackDescription}
              </DashTruncateWithTooltip>
            )}
          </Text>
          {(isDescriptionExpanded || isDescriptionExpandable) && (
            <Expand
              isExpanded={isDescriptionExpanded}
              setIsExpanded={setIsDescriptionExpanded}
            />
          )}
        </div>
      )}
    </>
  );
};
