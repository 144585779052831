import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';

export const UnsavedChangesModal = ({
  open,
  onCancel,
  onContinue,
}: {
  open: boolean;
  onCancel: () => void; // user wants to stay on the main modal
  onContinue: () => void; // user discards changes and closes main modal
}) => {
  return (
    <Modal
      withCloseButton={i18n.t('close_modal_aria')}
      open={open}
      onRequestClose={onCancel}
      isCentered
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('unsaved_changes_modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{i18n.t('unsaved_changes_modal_body')}</Text>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button variant="outline" onClick={onCancel}>
            {i18n.t('unsaved_changes_modal_keep_editing')}
          </Button>
          <Button variant="primary" onClick={onContinue}>
            {i18n.t('unsaved_changes_modal_discard_changes')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
