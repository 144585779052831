import { redactString } from "@alwaysmeticulous/redaction";
import type { stacks } from "@dropbox/api-v2-client";
import type { CachedUserStacks } from "@mirage/service-stacks/service/store";

import { createRedactedApiV2Endpoint } from "../utils";

export const stacksQueryStacksRedacted = createRedactedApiV2Endpoint<
  CachedUserStacks,
  stacks.QueryStacksResponse
>({
  name: "stacksQueryStacks",
  api: "stacks",
  endpoint: "query_stacks",
  indexedDbKey: "DashUserStacks",
  stringRedactors: {
    insights: redactString,
    custom_color: redactString,
  },
});
