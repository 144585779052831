import { useOnInViewTrigger } from '../../hooks/useOnInViewTrigger';
import { ExpandedActivityListItem } from '../ActivityListItem/ExpandedActivityListItem/ExpandedActivityListItem';

import type { OnInViewHandler } from '../../hooks/useOnInViewTrigger';
import type { dash_feed, users } from '@dropbox/api-v2-client';

type ActivityListItemProps = {
  activity: dash_feed.FeedItem;
  currentAccount?: users.FullAccount;
  index: number;
  onInView?: OnInViewHandler;
  onScrollIntoView?: OnInViewHandler;
  enableSummary?: boolean;
  className?: string;
};

export const ActivityListItem = ({
  activity,
  currentAccount,
  index,
  onInView,
  onScrollIntoView,
  className,
}: ActivityListItemProps) => {
  const onViewRef = useOnInViewTrigger(
    { onInView, onScrollIntoView },
    { activity, index },
  );

  return (
    <div ref={onViewRef} className={className}>
      <ExpandedActivityListItem
        activity={activity}
        currentAccount={currentAccount}
        index={index}
      />
    </div>
  );
};

export const ActivityListItemSkeleton = () => {
  return <ExpandedActivityListItem.Skeleton />;
};
