import { getHTML } from '@atlaskit/pragmatic-drag-and-drop/external/html';
import { getURLs } from '@atlaskit/pragmatic-drag-and-drop/external/url';
import { getTitleOfUrl } from '@mirage/service-stacks/service/url-title';

import type { ExternalDragPayload } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import type { Link } from '@mirage/link-list/types';

// Unique type key to distinguish this draggable data from other kinds of draggable data
export const typeKey = Symbol('Card');

export type DraggableData = {
  [typeKey]: true;
  id: string;
} & (
  | {
      type: 'stackitem';
      index: number;
      sectionId: string;
    }
  | {
      type: 'suggestion';
      payload: Link;
    }
);

export const isDraggableData = (
  data: Record<string | symbol, unknown>,
): data is DraggableData => {
  return (data as DraggableData)[typeKey] === true;
};

function extractTextFromHTMLString(html: string) {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
}

export function tryExtractLinkFromHTMLDragElement(source: ExternalDragPayload) {
  const urls: string[] = getURLs({ source });
  if (urls.length > 0) {
    const url = urls[0];
    const html = getHTML({ source });
    let title = getTitleOfUrl(url);
    if (html) {
      title = extractTextFromHTMLString(html);
    }
    return { url, title };
  }
  return null;
}
