import { IconButton } from '@dropbox/dig-components/buttons';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronLeftLine, CloseLine } from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { useTypeaheadAutocomplete } from '@mirage/search/hooks/useTypeaheadAutocomplete';
import { useDualMode } from '@mirage/service-experimentation/useDualMode';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import styles from './AdapterInput.module.css';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { SearchFilter } from '@mirage/shared/search/search-filters';
import type { ChangeEvent, KeyboardEvent, MouseEvent, RefObject } from 'react';

const AUTOCOMPLETE_WIDTH_RATIO = 0.65;

type AdapterInputProps = {
  query: string;
  onQueryUpdate: (query: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCloseTypeahead: () => void;
  onShouldBoostSearchToTop: (shouldBeBoosted: boolean) => void;
  shouldFocusOnRender?: boolean;
  disabled?: boolean;
  focused?: boolean;
  typeaheadIsOpen?: boolean;
  fullWidth?: boolean;
  inputRef: RefObject<HTMLInputElement>;
  selectedIdx: number;
  searchResults: TypeaheadResult[] | null;
  shouldSearchBeBoosted: boolean;
  isDarwin?: boolean;
  isStartPage?: boolean;
  filters: SearchFilter[];
  showBackButton: boolean;
  onBackButtonClicked: () => void;
  omniboxPlaceholder: string;
};

export const AdapterInput = forwardRef<HTMLDivElement, AdapterInputProps>(
  (
    {
      query,
      onQueryUpdate,
      onClick,
      onFocus,
      onCloseTypeahead,
      onShouldBoostSearchToTop,
      shouldFocusOnRender = false,
      disabled = false,
      focused = false,
      typeaheadIsOpen = false,
      fullWidth = false,
      inputRef,
      selectedIdx,
      searchResults,
      shouldSearchBeBoosted,
      isStartPage = false,
      filters = [],
      showBackButton,
      onBackButtonClicked,
      omniboxPlaceholder,
    }: AdapterInputProps,
    ref,
  ) => {
    const [active, setActive] = useState(shouldFocusOnRender && !disabled);
    // Temporary solution. Not all modals are handled via useModal() yet
    const isModalOpen = document.getElementsByClassName('dig-Modal').length > 0;
    const [placeholderWidth, setPlaceholderWidth] = useState<number>();
    const isMobileSize = useIsMobileSize();
    const { isDualModeLauncher } = useDualMode();

    // OSE-4908 - Proxy for service-usage of flag value to track Growthbook experiment
    // This hook reports the Growthbook PAP exposure event
    useFeatureFlagValue(
      'dash_typeahead_2024_06_05_suggested_query_score',
      true,
    );

    const {
      inputValue,
      onSearchInputKeyDown,
      inlineCTAText,
      suffixPosition,
      shouldAutocomplete,
      fakeAutocompleteText,
      coverCTA,
    } = useTypeaheadAutocomplete(
      query,
      searchResults,
      selectedIdx,
      inputRef,
      typeaheadIsOpen,
      shouldSearchBeBoosted,
      onShouldBoostSearchToTop,
      filters,
    );

    const handleResize = () => {
      if (!inputRef.current) return;
      setPlaceholderWidth(
        inputRef.current.clientWidth * AUTOCOMPLETE_WIDTH_RATIO,
      );
    };
    useResizeObserver(inputRef, handleResize);

    useEffect(() => {
      function handleVisibilityChange() {
        if (!inputRef.current) return;
        if (document.visibilityState !== 'visible') {
          inputRef.current?.blur();
          return;
        }
        inputRef.current.select();
      }

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () =>
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
    }, [inputRef]);

    useEffect(() => {
      shouldFocusOnRender && inputRef.current?.focus();
    }, [inputRef, shouldFocusOnRender]);

    /**
     * Form Handlers
     */

    const handleFocus = () => {
      onFocus?.();
    };

    const handleKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
      onSearchInputKeyDown(event);
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
      onQueryUpdate(event.target.value);
    };

    const handleClearSearch = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onQueryUpdate('');
      inputRef.current?.focus();

      if (isMobileSize) {
        onCloseTypeahead();
      }
    };

    const handleCloseTypeahead = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      inputRef.current?.blur();
      onCloseTypeahead();
    };

    /**
     * Render
     */
    return (
      <div
        className={classNames(styles.searchHeaderContainer, {
          [styles.active]: active || typeaheadIsOpen,
          [styles.extendBottom]: typeaheadIsOpen,
          [styles.fullWidthSearchbar]: fullWidth,
          [styles.typeaheadIsOpen]: typeaheadIsOpen,
          [styles.isDualModeLauncher]: isDualModeLauncher,
          [styles.isOmniboxEnabled]: true,
        })}
        ref={ref}
        onMouseEnter={() => !disabled && setActive(true)}
        onMouseLeave={() => !disabled && setActive(false)}
        onMouseOver={() => !disabled && setActive(true)}
        onFocus={() => void 0}
      >
        <label
          className={classNames(styles.searchBarContainer, {
            [styles.disabled]: disabled,
            [styles.extendBottom]: typeaheadIsOpen,
            [styles.focused]: focused,
            [styles.fullWidthSearchbar]: fullWidth,
            [styles.isOmniboxEnabled]: true,
          })}
          htmlFor="search-bar"
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        >
          {showBackButton && (
            <IconButton
              variant="opacity"
              shape="circular"
              size="medium"
              tone="neutral"
              onClick={onBackButtonClicked}
            >
              <UIIcon src={ChevronLeftLine} role="presentation" />
            </IconButton>
          )}
          <div
            className={classNames(styles.searchInputContainer, {
              [styles.fullWidthSearchbar]: fullWidth,
            })}
          >
            <input
              className={classNames(styles.searchInputEl, {
                [styles.disabled]: disabled,
                [styles.fullWidthSearchbar]: fullWidth,
                [styles.searchInputElHiddenText]: coverCTA,
                // [styles.showDefaultPlaceholder]: filtersActive,
              })}
              ref={inputRef}
              value={inputValue}
              type="text"
              id="search-bar"
              onInput={handleSearchChange}
              onKeyDown={handleKeydown}
              onFocus={handleFocus}
              onClick={onClick}
              placeholder={omniboxPlaceholder}
              disabled={isModalOpen || disabled}
              autoComplete="off"
              tabIndex={isModalOpen || !isStartPage ? undefined : 1}
              data-uxa-log={createUxaElementId('typeahead_input', {
                actionSurface: 'search_typeahead',
                actionSurfaceComponent: 'search_bar',
                featureLine: 'search',
              })}
              data-uxa-interactions="click change"
              aria-live="polite"
              aria-controls="search-results"
              aria-expanded={typeaheadIsOpen}
              aria-autocomplete="list"
              role="combobox"
              aria-haspopup="listbox"
              aria-activedescendant={
                typeaheadIsOpen && selectedIdx !== null
                  ? `typeahead-option-${selectedIdx}`
                  : undefined
              }
            />
            {inlineCTAText && suffixPosition != null ? (
              <span
                className={classNames(styles.suffixElement, {
                  [styles.fullWidthSearchbar]: fullWidth,
                  [styles.autocompleteHighlight]: shouldAutocomplete,
                })}
                style={{ left: `${suffixPosition}px` }}
              >
                <span
                  className={classNames(
                    styles.fakeAutocomplete,
                    styles.searchInputEl,
                    {
                      [styles.disabled]: disabled,
                      [styles.fullWidthSearchbar]: fullWidth,
                      [styles.autocompleteHighlight]: shouldAutocomplete,
                    },
                  )}
                  style={{
                    maxWidth: placeholderWidth
                      ? `${placeholderWidth}px`
                      : undefined,
                  }}
                >
                  {coverCTA && fakeAutocompleteText}
                </span>
                {inlineCTAText}
              </span>
            ) : null}
            <Box
              as="span"
              pointerEvents="none"
              whiteSpace="nowrap"
              overflow="hidden"
              position="absolute"
              height="100%"
              width="100%"
              color="Text Subtle"
              fontSize={fullWidth ? 'Title Medium' : 'Text Large'}
              display="flex"
              fontFamily="Text"
              aria-hidden={true}
              className={styles.fakePlaceholder}
            >
              {omniboxPlaceholder}
            </Box>
          </div>

          <div className={classNames(styles.searchActionsContainer, {})}>
            {query.length === 0 && typeaheadIsOpen && !isDualModeLauncher && (
              <DigTooltip title={i18n.t('close')} placement="bottom-end">
                <IconButton
                  aria-label={i18n.t('close')}
                  variant="borderless"
                  onClick={handleCloseTypeahead}
                  type="reset"
                  style={{ borderRadius: '4px' }}
                >
                  <UIIcon src={CloseLine} />
                </IconButton>
              </DigTooltip>
            )}

            {query && !disabled && (
              <DigTooltip title={i18n.t('clear_search')} placement="bottom-end">
                <IconButton
                  aria-label={i18n.t('clear_search')}
                  variant="borderless"
                  onClick={handleClearSearch}
                  type="reset"
                  style={{ borderRadius: '4px' }}
                  data-uxa-log={createUxaElementId('clear_button', {
                    actionSurface: 'search_typeahead',
                    actionSurfaceComponent: 'search_bar',
                    featureLine: 'search',
                  })}
                >
                  <UIIcon src={CloseLine} />
                </IconButton>
              </DigTooltip>
            )}
          </div>
        </label>
      </div>
    );
  },
);
AdapterInput.displayName = 'AdapterInput';
