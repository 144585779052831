import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { getTemplateIdFromString } from '@mirage/analytics/events/enums/template_id-utils';
import { PAP_Click_TemplateListItem } from '@mirage/analytics/events/types/click_template_list_item';
import { PAP_Click_UseTemplateButton } from '@mirage/analytics/events/types/click_use_template_button';
import { tagged } from '@mirage/service-logging';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import {
  createDefaultTemplates,
  isPreConfiguredTemplateID,
} from '@mirage/shared/compose/assist-template';
import { getFirstMarkdownArtifact } from '@mirage/shared/compose/compose-session';
import { LexicalContentEditable } from '@mirage/shared/compose/editor/LexicalContentEditable';
import { LexicalEditor } from '@mirage/shared/compose/editor/LexicalEditor';
import i18n from '@mirage/translations';
import isEqual from 'lodash/isEqual';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useComposeCurrentSessionContext } from '../../data/current-session/ComposeCurrentSessionContext';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { SourcesFromSettingsConversationPane } from './SourcesFromSettingsConversationPane';
import { SourcesFromSettingsModalStepper } from './SourcesFromSettingsModalStepper';
import { SourcesFromSettingsOne } from './SourcesFromSettingsOne';
import { SourcesFromSettingsSharingToggle } from './SourcesFromSettingsSharingToggle';
import { SourcesFromSettingsTemplatePreview } from './SourcesFromSettingsTemplatePreview';
import { SourcesFromSettingsThree } from './SourcesFromSettingsThree';
import { SourcesFromSettingsTwo } from './SourcesFromSettingsTwo';
import styles from './TemplateSettingsModal.module.css';
import { TemplatesList } from './TemplatesList';
import { useTemplateSettings } from './useTemplateSettings';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type {
  AssistCustomTemplate,
  AssistTemplate,
} from '@mirage/shared/compose/assist-template';

const logger = tagged('TemplateSettingsModal');

const getBackgroundForTemplate = (template?: AssistTemplate): string => {
  const colors = [
    'color__highlight__blue',
    'color__highlight__green',
    'color__highlight__purple',
    'color__highlight__red',
    'color__highlight__yellow',
  ];
  if (!template || !template.name) return `var(--dig-${colors[0]})`;
  const firstChar = template.name.charAt(0);
  const charCode = firstChar.charCodeAt(0);
  const index = charCode % colors.length;
  return `var(--dig-${colors[index]})`;
};

interface TemplateSettingsModalProps {
  onRequestClose: () => void;
  isOpen: boolean;
  templates: AssistCustomTemplate[];
  onUseTemplate: (templateID: string) => void;
  currentTemplateID?: string | null;
  saveTemplate: (template: AssistCustomTemplate) => Promise<void | string>;
  onDeleteTemplate: (templateId: string) => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  setCreatedTemplateSnackbarMsg: (msg: string | undefined) => void;
}

export const TemplateSettingsModal = memo(
  ({
    onRequestClose,
    isOpen,
    templates,
    onUseTemplate,
    currentTemplateID,
    saveTemplate,
    onDeleteTemplate,
    logComposeEvent,
    setCreatedTemplateSnackbarMsg,
  }: TemplateSettingsModalProps) => {
    const defaultTemplates = useMemo(() => createDefaultTemplates(), []);
    const [selectedTemplate, setSelectedTemplate] = useState<AssistTemplate>();
    const [customTemplateModalState, setCustomTemplateModalState] = useState<{
      isOpen: boolean;
      templateID?: string;
    }>({ isOpen: false });
    const handleSelectTemplate = useCallback(
      (template: AssistTemplate) => {
        logComposeEvent(PAP_Click_TemplateListItem());
        setSelectedTemplate(template);
      },
      [logComposeEvent],
    );
    const onSaveCustomTemplate = useCallback(
      (savedTemplateID: string) => {
        setCustomTemplateModalState({ isOpen: false });
        const found = templates.find((t) => t.id === savedTemplateID);
        if (found) handleSelectTemplate(found);
      },
      [setCustomTemplateModalState, templates, handleSelectTemplate],
    );
    const { artifacts } = useComposeCurrentSessionContext();
    const markdownArtifact = useMemo(
      () => getFirstMarkdownArtifact(artifacts),
      [artifacts],
    );
    const {
      loading,
      currentTemplateState,
      setCurrentTemplateState,
      sourcesContents,
      isDeleteModalOpen,
      setIsDeleteModalOpen,
      handleAddSource,
      handleRemoveSource,
      handleChangeTemplateNameInput,
      handleChangeTemplateDescriptionInput,
      handleModifyTemplatePostUserMessage,
      handleClickSave,
      handleClickDelete,
      handleAccessTypeChange,
      resetTemplateState,
    } = useTemplateSettings({
      templates,
      saveTemplate,
      logComposeEvent,
      onDone: onSaveCustomTemplate,
      editingTemplateID: customTemplateModalState.templateID,
      setCreatedTemplateSnackbarMsg,
    });

    useEffect(() => {
      if (!selectedTemplate && defaultTemplates.length) {
        setSelectedTemplate(defaultTemplates[0]);
      }
    }, [defaultTemplates, selectedTemplate]);

    const handleUseTemplate = useCallback(() => {
      if (selectedTemplate && onUseTemplate) {
        const loggedTemplateIdValue = isPreConfiguredTemplateID(
          selectedTemplate.id,
        )
          ? selectedTemplate.id
          : getTemplateIdFromString('user_generated');
        logComposeEvent(
          PAP_Click_UseTemplateButton({
            templateId: loggedTemplateIdValue,
          }),
        );
        onUseTemplate(selectedTemplate.id);
        onRequestClose();
      }
    }, [onUseTemplate, selectedTemplate, onRequestClose, logComposeEvent]);

    useEffect(() => {
      if (markdownArtifact?.draftConfig?.templateID) {
        setSelectedTemplate(
          [...templates, ...defaultTemplates].find(
            (template) =>
              template.id === markdownArtifact.draftConfig.templateID,
          ),
        );
      }
    }, [templates, markdownArtifact, defaultTemplates]);

    useEffect(() => {
      if (customTemplateModalState.templateID) {
        const templateToEdit = templates.find(
          (template) => template.id === customTemplateModalState.templateID,
        );
        if (
          templateToEdit &&
          'type' in templateToEdit &&
          templateToEdit.type === 'custom'
        ) {
          setCurrentTemplateState(templateToEdit);
        }
      }
    }, [
      customTemplateModalState.templateID,
      templates,
      setCurrentTemplateState,
    ]);

    const [previousSources, setPreviousSources] = useState<
      typeof currentTemplateState.sources
    >([]);

    // Auto-generate a template for first time creation,
    // or when the user has hit back and changed sources,
    // but not when the user is editing an existing template.
    // In the case where the user edits and existing template and
    // changes sources, we want to clear the template content so that
    // the api doesn't make edits to the existing template.
    const onMount = useCallback(() => {
      const sourcesChanged = !isEqual(
        previousSources,
        currentTemplateState.sources,
      );
      const isNewTemplate = currentTemplateState.messagesHistory?.length === 0;

      if ((sourcesChanged && !!previousSources.length) || isNewTemplate) {
        handleModifyTemplatePostUserMessage('', { clearTemplate: true });
      }

      setPreviousSources(currentTemplateState.sources);
    }, [
      previousSources,
      currentTemplateState.sources,
      currentTemplateState.messagesHistory,
      handleModifyTemplatePostUserMessage,
    ]);

    const handleOnChangeTemplateContent = useCallback(
      (content: string) => {
        setCurrentTemplateState((prev) => ({
          ...prev,
          templateContent: content,
        }));
      },
      [setCurrentTemplateState],
    );

    const steps = [
      <SourcesFromSettingsOne
        key={0}
        title={i18n.t('compose_tones_pane_title')}
        description={i18n.t('assist_template_sources_description')}
        sources={currentTemplateState?.sources}
        handleAddSource={handleAddSource}
        handleRemoveSource={handleRemoveSource}
        sourcesContents={sourcesContents}
        logComposeEvent={logComposeEvent}
      />,
      <SourcesFromSettingsTwo
        key={1}
        loading={loading}
        onMount={onMount}
        isTemplatePreview
        leftPaneContent={
          <SourcesFromSettingsConversationPane
            messages={currentTemplateState.messagesHistory}
            logComposeEvent={logComposeEvent}
            sources={currentTemplateState.sources}
            onSubmitMessage={handleModifyTemplatePostUserMessage}
            loading={loading}
            loadingText={i18n.t('assist_template_loading_message')}
          />
        }
        rightPaneContent={
          <SourcesFromSettingsTemplatePreview
            backgroundColor={getBackgroundForTemplate(currentTemplateState)}
            templateContent={currentTemplateState.templateContent}
            onChangeTemplateContent={handleOnChangeTemplateContent}
            loading={loading}
          />
        }
      />,
      <SourcesFromSettingsThree
        key={2}
        title={i18n.t('assist_template_modal_stepper_save_title')}
        namePlaceholder={i18n.t(
          'assist_templates_modal_stepper_name_placeholder',
        )}
        descriptionPlaceholder={i18n.t(
          'assist_templates_modal_stepper_description_placeholder',
        )}
        onNameInputChange={handleChangeTemplateNameInput}
        onDescriptionInputChange={handleChangeTemplateDescriptionInput}
        nameValue={currentTemplateState.name}
        descriptionValue={currentTemplateState.description}
        deleteModal={
          <DeleteConfirmationModal
            title={i18n.t('assist_delete_templates_confirm_title')}
            description={i18n.t('assist_delete_templates_confirm_description')}
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={() => {
              onDeleteTemplate(currentTemplateState.id);
            }}
            isOpen={isDeleteModalOpen}
          />
        }
        sharingSettings={
          <SourcesFromSettingsSharingToggle
            sublabelText={i18n.t(
              'assist_templates_settings_from_sources_sublabel',
            )}
            accessType={currentTemplateState.accessType}
            onAccessTypeChange={handleAccessTypeChange}
          />
        }
      />,
    ];

    return (
      <>
        <Modal
          open={isOpen && !customTemplateModalState.isOpen}
          isCentered
          className={styles.TemplateSettingsModalWindow}
          onRequestClose={onRequestClose}
          width={1083}
          withCloseButton={'close'}
        >
          <DashThemeProvider>
            <Modal.Body className={styles.TemplateSettingsModalBody}>
              <div className={styles.TemplateSettingsModalHeader}>
                <Text
                  size="xlarge"
                  isBold
                  className={styles.TemplateSettingsModalHeaderTitle}
                >
                  Templates
                </Text>
              </div>
              <div className={styles.TemplateSettingsModalContent}>
                <div className={styles.TemplateSettingsModalLeftPanel}>
                  <TemplatesList
                    currentTemplateID={currentTemplateID}
                    defaultTemplates={defaultTemplates}
                    templates={templates}
                    selectedTemplateId={selectedTemplate?.id}
                    onSelectTemplate={handleSelectTemplate}
                    onOpenTemplateModal={(isOpen, templateID) =>
                      setCustomTemplateModalState({ isOpen, templateID })
                    }
                    resetTemplateState={resetTemplateState}
                    logComposeEvent={logComposeEvent}
                  />
                </div>
                <div className={styles.TemplateSettingsModalRightPanel}>
                  <div
                    className={styles.TemplateSettingsModalPreview}
                    style={{
                      background: getBackgroundForTemplate(selectedTemplate),
                    }}
                  >
                    {selectedTemplate && (
                      <div
                        className={styles.TemplateSettingsModalPreviewContent}
                      >
                        <LexicalEditor
                          namespace="TemplatePreview"
                          editable={false}
                          onError={logger.error}
                          contentEditable={<LexicalContentEditable />}
                          placeholder={null}
                          markdownContent={
                            selectedTemplate.templateContent || ''
                          }
                          onChangeContent={() => {}}
                          isClickableLinks={false}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.TemplateSettingsModalFooter}>
                    <Button
                      variant="primary"
                      className={styles.TemplateSettingsModalButton}
                      onClick={handleUseTemplate}
                      disabled={!selectedTemplate}
                    >
                      {i18n.t(
                        'assist_templates_settings_from_sources_use_template',
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </DashThemeProvider>
        </Modal>
        <SourcesFromSettingsModalStepper
          steps={steps}
          widths={[693, 1248, 693]}
          isOpen={customTemplateModalState.isOpen}
          onRequestClose={onRequestClose}
          onDone={handleClickSave}
          onDelete={handleClickDelete}
          initialStepIndex={customTemplateModalState.templateID ? 2 : 0}
        />
      </>
    );
  },
);
TemplateSettingsModal.displayName = 'TemplateSettingsModal';
