import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashGettingStartedChecklistItemInformation } from '@mirage/analytics/events/types/select_dash_getting_started_checklist_item_information';
import { getFirstLoginTime } from '@mirage/service-onboarding';
import { openURL } from '@mirage/service-platform-actions';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import { useIsExtensionInstalled } from '@mirage/shared/util/webExtensionsHelpers';
import { useSortedStacks } from '@mirage/stacks/hooks';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { OnboardingChecklistDirtyAtom } from './checklist-atoms';

import type { OnboardingChecklistStep } from '../types';
import type { DashGettingStartedChecklistItem } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item';

export function useChecklistActions(
  checklistItems: OnboardingChecklistStep[],
  refreshChecklistItems: () => Promise<void>,
  setOpen: (open: boolean) => void,
  markChecklistItemComplete: (moduleId: string) => Promise<boolean | undefined>,
) {
  const setIsDirty = useSetAtom(OnboardingChecklistDirtyAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const hasBrowserExtensionInstalled = useIsExtensionInstalled();
  const stacks = useSortedStacks();
  const hasMarkedStack = useRef(false);
  const hasMarkedAppsInstalled = useRef(false);
  const { extensionAvailableForBrowser } = useAvailableExtensions();

  const markComplete = useCallback(
    (moduleId: string) => {
      const asyncMarkComplete = async () => {
        const success = await markChecklistItemComplete(moduleId);
        if (!success) return;
        setIsDirty(true);
        setOpen(true);
        refreshChecklistItems();
      };
      asyncMarkComplete();
    },
    [refreshChecklistItems, markChecklistItemComplete, setIsDirty, setOpen],
  );

  // If a stack is added, mark create_stack_module module as complete
  useEffect(() => {
    const addAppModule = checklistItems.find(
      (m) => m.moduleId === 'create_stack_module' && !m.isComplete,
    );
    if (addAppModule && stacks?.length && !hasMarkedStack.current) {
      const ownedStacks = stacks.filter(
        (s) =>
          s.permission?.['.tag'] === 'owner' &&
          s.stack_data?.creation_type?.['.tag'] !== 'welcome_stack',
      );
      if (ownedStacks.length) {
        hasMarkedStack.current = true;
        markComplete('create_stack_module');
      }
    }
  }, [stacks, checklistItems, markComplete]);

  // If the browser extension and desktop application have been installed,
  // mark install_dash_desktop_and_extension_module module as complete
  useEffect(() => {
    const asyncMarkComplete = async () => {
      const hasInstalledDesktop = !!(await getFirstLoginTime('desktop'));

      // If extension is available and installed or not available
      const browserExtensionInstalled =
        (hasBrowserExtensionInstalled && extensionAvailableForBrowser) ||
        !extensionAvailableForBrowser;

      if (
        !hasMarkedAppsInstalled.current &&
        hasInstalledDesktop &&
        browserExtensionInstalled
      ) {
        hasMarkedAppsInstalled.current = true;
        markComplete('install_dash_desktop_and_extension_module');
      }
    };
    asyncMarkComplete();
  }, [
    extensionAvailableForBrowser,
    hasBrowserExtensionInstalled,
    markComplete,
  ]);

  const openLearnMore = (moduleId: string, uri: string) => {
    reportPapEvent(
      PAP_Select_DashGettingStartedChecklistItemInformation({
        dashGettingStartedChecklistItem:
          moduleId as DashGettingStartedChecklistItem,
        actionSurfaceComponent: 'getting_started_checklist',
        featureLine: 'getting_started_checklist',
      }),
    );
    return openURL(uri);
  };

  return { markComplete, openLearnMore };
}
