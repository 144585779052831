import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  LinkLine,
  PaperDocLine,
  SearchLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { createDoc } from '@mirage/service-cloud-docs';
import { FEATURE_ON_STRING_VALUES } from '@mirage/service-experimentation/features';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { openURL } from '@mirage/service-platform-actions';
import { useMetadataForUrls } from '@mirage/service-url-metadata/hooks';
import { DashSpinner } from '@mirage/shared/dash-spinner';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { isUrl } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './AddStackItemMenuContent.module.css';
import { LinkSuggestion } from './LinkSuggestion';
import { LoadingSuggestions } from './LoadingSuggestions';

import type { TabSuggestion } from './types';
import type { WrapperContentPropsFn } from '@dropbox/dig-components/menu';
import type {
  OverlayPlacement,
  OverlayVirtualElement,
} from '@dropbox/dig-components/overlay';
import type { TextInputRefObject } from '@dropbox/dig-components/text_fields';
import type { DashNewLinkType } from '@mirage/analytics/events/enums/dash_new_link_type';
import type { UrlMetadata } from '@mirage/service-url-metadata/types';
import type { KeyboardEvent } from 'react';

interface AddStackItemMenuWrapperProps {
  placement?: OverlayPlacement;
  getContentProps: WrapperContentPropsFn;
  children: React.ReactNode;
}

export const AddStackItemMenuWrapper: React.FC<
  AddStackItemMenuWrapperProps
> = ({ placement, getContentProps, children }) => {
  const isHoverFriendly = useIsHoverFriendly();
  const isAugRev = useStackPageAugustRevisionEnabled();

  return (
    <Menu.Content
      {...getContentProps()}
      placement={placement}
      className={classNames(
        isAugRev ? styles.wrapperAugRev : styles.wrapper,
        isHoverFriendly || styles.noHover,
      )}
      onKeyDown={(e) => {
        // Stop tab keys from closing the popup.
        if (e.key === KeyCodes.tab) {
          e.stopPropagation();
        }
      }}
    >
      {children}
    </Menu.Content>
  );
};

interface AddStackItemMenuContentProps {
  pasteInputRef: React.MutableRefObject<TextInputRefObject | null>;
  inputString: string;
  setInputString: React.Dispatch<React.SetStateAction<string>>;
  pasteInput: string;
  setPasteInput: React.Dispatch<React.SetStateAction<string>>;
  filenameInput: string;
  setFilenameInput: React.Dispatch<React.SetStateAction<string>>;
  addLink: (
    dashNewLinkType: DashNewLinkType,
    link: string | TabSuggestion,
    title?: string,
  ) => void;
  tabSuggestions: TabSuggestion[];
  suggestionsClassName?: string;
  onOpenLink?: (tabSuggestion: TabSuggestion) => void;
  onShowLinks?: (links: TabSuggestion[]) => void;
  isLoading: boolean;
  triggerElement: HTMLElement | Element | OverlayVirtualElement | null;
}

export const AddStackItemMenuContent: React.FC<
  AddStackItemMenuContentProps
> = ({
  pasteInputRef,
  inputString,
  setInputString,
  pasteInput,
  setPasteInput,
  filenameInput,
  setFilenameInput,
  addLink,
  tabSuggestions,
  suggestionsClassName,
  onOpenLink,
  onShowLinks,
  isLoading,
  triggerElement,
}) => {
  const linkRefs = useRef<(HTMLElement | null)[]>([]);

  // Convenience function to get the linkRef at index.
  const linkRefAt = useCallback((index: number) => {
    const a = linkRefs.current;
    if (index < 0) index = a.length + index;
    return index >= 0 ? a[index] : undefined;
  }, []);

  const textInputRef = useRef<TextInputRefObject | null>(null);
  const cloudDocCreateRef = useRef<TextInputRefObject | null>(null);

  const cloudDocGate = useFeatureFlagValue(
    'dash_2024_06_18_cloud_docs',
    false /* includeExposureLogging */,
  );

  const cloudDocsEnabled = FEATURE_ON_STRING_VALUES.includes(cloudDocGate);

  const afterPasteRef = cloudDocsEnabled ? cloudDocCreateRef : textInputRef;
  const beforeSearchRef = cloudDocsEnabled ? cloudDocCreateRef : pasteInputRef;

  const [docCreationInProgress, setDocCreationInProgress] = useState(false);

  const isMobileSize = useIsMobileSize();

  useEffect(() => {
    onShowLinks?.(tabSuggestions);
  }, [tabSuggestions, onShowLinks]);

  useEffect(() => {
    linkRefs.current = Array(tabSuggestions.length).fill(null);
  }, [tabSuggestions]);

  const inputStringIsUrl = Boolean(inputString && isUrl(inputString));
  const urlMetadataForInputString = useMetadataForUrls(
    inputStringIsUrl ? [inputString] : [],
  );

  const pasteInputIsUrl = Boolean(pasteInput && isUrl(pasteInput));
  const urlMetadataForPasteInput = useMetadataForUrls(
    pasteInputIsUrl ? [pasteInput] : [],
  );

  const searchResultsDivRef = useRef<HTMLDivElement | null>(null);

  const updateSearchResultsHeight = useCallback(() => {
    const ref = searchResultsDivRef.current;
    if (!ref) return;

    const rect = ref.getBoundingClientRect();

    // 24px - Need to leave a gap between bottom of screen to the bottom of
    // the popup menu, otherwise the menu will get cut off.
    let maxY = window.scrollY + window.innerHeight - 24;

    // Prevent popup menu from overlapping with trigger element.
    if (triggerElement) {
      const triggerRect = triggerElement.getBoundingClientRect();
      const isAboveTrigger = rect.top < triggerRect.top;
      // Note: Cannot check for overlap as DIG will adjust the height to
      // counter it, and the popup menu will not stop shaking.
      // const isOverlapping = rect.bottom >= triggerRect.top;

      if (isAboveTrigger) {
        // 4px - gap between bottom of popup menu and trigger element.
        maxY = triggerRect.top - 4;
      }
    }

    // Max 262px: This will show 5.5 search results, as we want to make sure users know
    // they can scroll for more results.
    // Min 69px: This will show 1.5 search results, and is the minimum possible height.
    ref.style.maxHeight = Math.max(Math.min(maxY - rect.top, 262), 69) + 'px';
  }, [triggerElement]);

  useResizeObserver(searchResultsDivRef, updateSearchResultsHeight);

  // This is a bit counter-intuitive. Make the font bigger for
  // mobile size because in iOS Safari, the browser will auto-zoom
  // the entire page when the input font is too small.
  const inputSize = isMobileSize ? 'large' : 'medium';

  const doAdd = (
    dashNewLinkType: DashNewLinkType,
    url: string,
    urlMetadata: { [key: string]: UrlMetadata } | undefined,
  ) => {
    const title =
      urlMetadata && url in urlMetadata ? urlMetadata[url].title : undefined;

    addLink(dashNewLinkType, url, title);
  };

  const doCreateDoc = async (filename: string) => {
    if (filename.length === 0 || docCreationInProgress) {
      return;
    }
    setDocCreationInProgress(true);
    showSnackbar({
      title: i18n.t('creating_paper_doc'),
    });
    try {
      const response = await createDoc('paper', filename);
      addLink('cloud_doc_created', response?.url, response?.filename);
      openURL(response?.url);
    } catch (e) {
      showSnackbar({
        title: i18n.t('failed_paper_doc_creation'),
      });
    }
    setDocCreationInProgress(false);
  };

  const handleKeyPress: (
    onEnter: () => void,
    onArrowDown: (e: KeyboardEvent<HTMLInputElement>) => void,
    onArrowUp: (e: KeyboardEvent<HTMLInputElement>) => void,
  ) => (e: KeyboardEvent<HTMLInputElement>) => void = (
    onEnter,
    onArrowDown,
    onArrowUp,
  ) => {
    return (e) => {
      if (e.key === KeyCodes.enter) {
        onEnter();
      } else if (
        e.key === KeyCodes.arrowDown ||
        (e.key === KeyCodes.tab && !e.shiftKey)
      ) {
        onArrowDown(e);
      } else if (e.key === KeyCodes.arrowUp) {
        onArrowUp(e);
      }
    };
  };

  return (
    <>
      <div className={styles.box}>
        <TextInput
          data-testid="Stacks-addLinkInput"
          ref={pasteInputRef}
          wrapperProps={{ className: styles.inputWrapper }}
          withLeftAccessory={
            <UIIcon src={LinkLine} className={styles.pasteIcon} />
          }
          withRightAccessory={
            pasteInputIsUrl && (
              <DigTooltip title={i18n.t('add_to_stack')}>
                <IconButton
                  className={styles.addButton}
                  variant="opacity"
                  onClick={() => {
                    doAdd('pasted', pasteInput, urlMetadataForPasteInput);
                  }}
                >
                  <UIIcon src={AddLine} size="large" />
                </IconButton>
              </DigTooltip>
            )
          }
          isTransparent
          size={inputSize}
          placeholder={i18n.t('paste_a_link')}
          value={pasteInput}
          onChange={(e) => setPasteInput(e.target.value)}
          onKeyDown={handleKeyPress(
            () => doAdd('pasted', pasteInput, urlMetadataForPasteInput),
            (e) => {
              afterPasteRef.current?.focus();
              e.preventDefault();
            },
            () => {
              (linkRefAt(-1) || textInputRef.current)?.focus();
            },
          )}
        />
      </div>
      {cloudDocsEnabled && (
        <div className={styles.box}>
          <TextInput
            ref={cloudDocCreateRef}
            wrapperProps={{ className: styles.inputWrapper }}
            withLeftAccessory={
              <UIIcon src={PaperDocLine} className={styles.paperdocIcon} />
            }
            withRightAccessory={
              filenameInput.length > 0 &&
              (docCreationInProgress ? (
                <DashSpinner size={24} displayLoadingMessage={false} />
              ) : (
                <DigTooltip title={i18n.t('create')}>
                  <IconButton
                    className={styles.addButton}
                    variant="opacity"
                    onClick={() => {
                      doCreateDoc(filenameInput);
                    }}
                  >
                    <UIIcon src={AddLine} size="large" />
                  </IconButton>
                </DigTooltip>
              ))
            }
            isTransparent
            size={inputSize}
            placeholder={i18n.t('paper_doc_creation_placeholder')}
            value={filenameInput}
            onChange={(e) => setFilenameInput(e.target.value)}
            onKeyDown={handleKeyPress(
              () => doCreateDoc(filenameInput),
              (e) => {
                textInputRef.current?.focus();
                e.preventDefault();
              },
              (e) => {
                pasteInputRef.current?.focus();
                e.preventDefault();
              },
            )}
          />
        </div>
      )}
      <div className={styles.box}>
        <TextInput
          wrapperProps={{ className: styles.searchTextInputWrapper }}
          withLeftAccessory={
            <UIIcon src={SearchLine} className={styles.searchIcon} />
          }
          withRightAccessory={
            inputStringIsUrl && (
              <DigTooltip title={i18n.t('add_to_stack')}>
                <IconButton
                  variant="opacity"
                  onClick={() => {
                    doAdd(
                      'manually_entered',
                      inputString,
                      urlMetadataForInputString,
                    );
                  }}
                >
                  <UIIcon src={AddLine} size="large" />
                </IconButton>
              </DigTooltip>
            )
          }
          isTransparent
          size={inputSize}
          value={inputString}
          placeholder={i18n.t('search_link_placeholder')}
          ref={textInputRef}
          onChange={(e) => setInputString(e.target.value)}
          onKeyDown={handleKeyPress(
            () => {
              if (inputStringIsUrl) {
                doAdd(
                  'manually_entered',
                  inputString,
                  urlMetadataForInputString,
                );
              }
            },
            () => {
              (linkRefAt(0) || pasteInputRef.current)?.focus();
            },
            (e) => {
              beforeSearchRef.current?.focus();
              e.preventDefault();
            },
          )}
        />
        <div
          className={styles.searchResults}
          ref={(ref) => {
            searchResultsDivRef.current = ref;
            updateSearchResultsHeight();
          }}
        >
          {isLoading ? (
            <LoadingSuggestions />
          ) : (
            tabSuggestions.map((tabSuggestion, index) => (
              <LinkSuggestion
                key={tabSuggestion.url}
                className={suggestionsClassName}
                setRef={(ref) => (linkRefs.current[index] = ref)}
                url={tabSuggestion.url}
                addLink={addLink}
                tabSuggestion={tabSuggestion}
                onOpenLink={onOpenLink}
                onKeyDown={(e) => {
                  if (e.key === KeyCodes.arrowUp) {
                    if (index === 0) {
                      textInputRef.current?.focus();
                    } else {
                      linkRefAt(index - 1)?.focus();
                    }
                  } else if (e.key === KeyCodes.arrowDown) {
                    if (index >= tabSuggestions.length - 1) {
                      pasteInputRef.current?.focus();
                    } else {
                      linkRefAt(index + 1)?.focus();
                    }
                  }
                }}
              />
            ))
          )}
          {!isLoading &&
            inputString.length > 0 &&
            tabSuggestions.length === 0 && (
              <Text color="faint" className={styles.noSearchResults}>
                {i18n.t('no_search_results')}
              </Text>
            )}
        </div>
      </div>
    </>
  );
};
