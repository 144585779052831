import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { addDisplayStat } from '@mirage/service-operational-metrics';
import { logPerfMetric } from './vortexMetrics';

import type { LoginType } from './vortexMetrics';

const LOGIN_START_MS_KEY = 'loginStartMs';
const LOGIN_TYPE_KEY = 'loginType';

export function startLoginMetricTiming(loginType: LoginType) {
  // Record login start time.
  localStorage.setItem(LOGIN_START_MS_KEY, String(Date.now()));
  localStorage.setItem(LOGIN_TYPE_KEY, loginType);
}

export function endLoginMetricTiming(isPaired: boolean) {
  // If start time is missing, then can't record anything.
  const startMsString = localStorage.getItem(LOGIN_START_MS_KEY);
  if (!startMsString) {
    return;
  }

  // If start time is invalid, then can't record anything.
  const startMs = Number(startMsString);
  if (!startMs) {
    return;
  }

  const elapsedMs = Date.now() - startMs;
  const loginType = localStorage.getItem(LOGIN_TYPE_KEY) as LoginType;

  logPerfMetric({
    '.tag': 'generic_perf_histogram',
    metric_name: 'login',
    surface: EnvCtx.surface,
    nav_type: loginType,
    path: isPaired ? 'paired' : 'nonpaired',
    value: elapsedMs,
  });

  addDisplayStat('login', elapsedMs);
}
