import { janusConnectorsByTypeAtom } from '@mirage/shared/atoms/connectors';
import { GroupConnectorType } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { atom } from 'jotai';
import { GoogleSuite, MicrosoftSuite } from '../icons/connector-icons';
import { connectionsByTypeAtom } from './connections';

import type { GroupConnector, UIConnection } from '@mirage/shared/types';

export const groupConnectorsAtom = atom<GroupConnector[]>((get) => {
  const janusConnectorsByType = get(janusConnectorsByTypeAtom);

  // Attach connectors to ecosystem groups and only return groups with available connectors.
  return groupConnectors
    .map((group) => ({
      ...group,
      connectors: group.connectorTypes
        // Connectors not available to the user won't be in janusConnectorsByType so they will be removed from the group.
        .map((id) => janusConnectorsByType[id])
        .filter(Boolean),
    }))
    .filter((groupConnector) => {
      return groupConnector.connectors && groupConnector.connectors.length > 0;
    });
});

// A map of { groupId: whether all connectors in the group are connected }.
export const groupConnectedByGroupIdAtom = atom<Record<string, boolean>>(
  (get) => {
    const groupConnectors = get(groupConnectorsAtom);
    const connectionsByType = get(connectionsByTypeAtom);

    return reduceGroupConnectorsToConnectedByGroupIdMap(
      groupConnectors,
      connectionsByType,
    );
  },
);

export const reduceGroupConnectorsToConnectedByGroupIdMap = (
  groupConnectors: GroupConnector[],
  connectionsByType: Record<string, UIConnection>,
) => {
  return groupConnectors.reduce((acc: Record<string, boolean>, group) => {
    const groupConnected =
      group.connectors &&
      group.connectors.length > 0 &&
      group.connectors.every(
        (connector) =>
          connector && connectionsByType[connector.id_attrs?.type ?? ''],
      );
    return {
      ...acc,
      [group.groupId]: groupConnected ?? false,
    };
  }, {});
};

const groupConnectors: GroupConnector[] = [
  {
    connectorTypes: ['googledrive', 'googleCalendar', 'gmail'],
    description: i18n.t('ecosystem_connector_google_suite_description'),
    groupId: GroupConnectorType.GOOGLE_SUITE,
    icon: <GoogleSuite size={36} />,
    platform: 'janus',
    title: i18n.t('ecosystem_connector_google_suite_title'),
  },
  {
    connectorTypes: ['outlook', 'onedrive'],
    description: i18n.t('ecosystem_connector_microsoft_suite_description'),
    groupId: GroupConnectorType.MICROSOFT_SUITE,
    icon: <MicrosoftSuite size={36} />,
    platform: 'janus',
    title: i18n.t('ecosystem_connector_microsoft_suite_title'),
  },
];
