import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { useCallback, useEffect, useState } from 'react';
import { getFirstLoginTime } from '.';

import type { OnboardingStorage } from '@mirage/service-onboarding/service';

export type { OnboardingStorage };

type OnboardingStorageValue = OnboardingStorage[keyof OnboardingStorage];
type OnboardingStorageBackoffValue = {
  latestDismissedMs: number;
  dismissedCount: number;
};

export interface DismissBehavior {
  isDismissed(value: OnboardingStorageValue): Promise<boolean>;
  dismiss(dismissed: boolean): OnboardingStorageValue;
}

export class DismissManually implements DismissBehavior {
  async isDismissed(value: boolean): Promise<boolean> {
    return value;
  }

  dismiss(dismissed: boolean): boolean {
    return dismissed;
  }
}

export class DismissAfterAccountAge extends DismissManually {
  accountAge: string;

  constructor(accountAge: string) {
    super();
    this.accountAge = accountAge;
  }

  /**
   * Converts a duration string to milliseconds.
   * @param duration string in the format of "30m", "12h", or "7d"
   */
  private durationStringToMs(duration: string) {
    const number = parseInt(duration.slice(0, -1));
    const unit = duration.slice(-1);

    switch (unit) {
      case 'm':
        return number * 60 * 1000;
      case 'h':
        return number * 60 * 60 * 1000;
      case 'd':
        return number * 24 * 60 * 60 * 1000;
      default:
        throw new Error('invalid unit');
    }
  }

  async isDismissed(value: boolean): Promise<boolean> {
    if (value) {
      return true;
    }
    const firstLoginTime = (await getFirstLoginTime()) || 0;
    if (firstLoginTime === 0) {
      return false;
    }
    const durationSinceLogin = this.durationStringToMs(this.accountAge);
    return firstLoginTime + durationSinceLogin < Date.now();
  }
}

export class DismissWithBackoff implements DismissBehavior {
  backoffFunction: (n: number) => number;
  backoffState: OnboardingStorageBackoffValue = {
    latestDismissedMs: 0,
    dismissedCount: 0,
  };

  constructor(backoffFunction: (n: number) => number) {
    this.backoffFunction = backoffFunction;
  }

  async isDismissed(value: OnboardingStorageBackoffValue): Promise<boolean> {
    // The default value from the onboarding storage can be undefined.
    // We check for that here and fall back to our own internal default.
    if (value) {
      // Store the current value for later usage in dismiss()
      this.backoffState = value;
    }
    const { latestDismissedMs, dismissedCount } = this.backoffState;
    const backoffTime = this.backoffFunction(dismissedCount);
    const dismissedUntil = latestDismissedMs + backoffTime;
    return dismissedUntil > Date.now();
  }

  dismiss(_dismissed: boolean): OnboardingStorageBackoffValue {
    return {
      latestDismissedMs: Date.now(),
      dismissedCount: this.backoffState.dismissedCount + 1,
    };
  }
}

const defaultDismissManually = new DismissManually();

export function useDismissableModule(
  moduleName: keyof OnboardingStorage,
  dismissBehavior: DismissBehavior = defaultDismissManually,
) {
  // Optimistically assume the module has been dismissed to avoid content flashing
  const [dismissed, setDismissedValue] = useState<boolean>(true);
  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();

  useEffect(() => {
    const initializeDismissed = async () => {
      const value = await getOnboardingValue(moduleName);
      const dismissed = await dismissBehavior.isDismissed(value);
      if (!dismissed) {
        setDismissedValue(false);
      }
    };

    void initializeDismissed();
  }, [moduleName, dismissBehavior, getOnboardingValue, setDismissedValue]);

  const setDismissedAndOnboardingValue = useCallback(
    (val: boolean) => {
      const onboardingValue = dismissBehavior.dismiss(val);
      void setOnboardingValue(moduleName, onboardingValue);
      setDismissedValue(val);
    },
    [dismissBehavior, moduleName, setOnboardingValue, setDismissedValue],
  );

  return {
    dismissed,
    setDismissed: setDismissedAndOnboardingValue,
  };
}
