import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { useFeatureFlagValue } from './useFeatureFlagValue';
import { convertFeatureValueToBool } from './util';

export async function getIsDualModeEnabled() {
  return convertFeatureValueToBool(
    await getCachedOrFetchFeatureValue('dash_2024_12_11_dual_mode'),
  );
}
export function getIsLauncher() {
  return !!window.IS_LAUNCHER;
}

export const useDualMode = () => {
  const isLauncher = getIsLauncher();
  const isDesktop = EnvCtx.surface === 'desktop';

  const isDualModeEnabled =
    convertFeatureValueToBool(
      useFeatureFlagValue('dash_2024_12_11_dual_mode'),
    ) && isDesktop;

  return {
    isDualModeEnabled,
    isDualModeLauncher: isDualModeEnabled && isLauncher,
    isDualModePersistent: isDualModeEnabled && !isLauncher,
  };
};
