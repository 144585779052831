import { fetchMultimediaSearchResults } from '@mirage/service-dbx-api';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';
import { useEffect } from 'react';

import type { SearchFilter } from '@mirage/shared/search/search-filters';
import type { SearchResult } from '@mirage/shared/search/search-result';

type MultimediaSearchState = {
  query: string | undefined;
  cursor: string | undefined;
  filters: SearchFilter[] | undefined;
  data: SearchResult[];
  loading: boolean;
};

export const multimediaAtom = atom<MultimediaSearchState>({
  query: undefined,
  cursor: undefined,
  filters: undefined,
  data: [],
  loading: false,
});

export const useMultimediaSearch = (
  query: string | undefined,
  filters: SearchFilter[],
) => {
  const [state, setState] = useAtom(multimediaAtom);

  useEffect(() => {
    if (state.loading) return;
    if (_.isEmpty(query)) return;
    if (query === state.query && _.isEqual(filters, state.filters)) {
      return;
    }

    const fetchMultimedia = async () => {
      setState((prev) => ({
        ...prev,
        query,
        filters,
        loading: true,
        data: [],
      }));

      const multimediaSearchResults = await fetchMultimediaSearchResults(
        query!,
        undefined,
      );
      setState((prev) => ({
        ...prev,
        loading: false,
        data: multimediaSearchResults.results,
        cursor: multimediaSearchResults.cursor,
      }));
    };
    fetchMultimedia();
  }, [
    query,
    state.query,
    filters,
    state.filters,
    state.loading,
    state.cursor,
    setState,
  ]);

  const loadMore = async () => {
    if (state.loading) return;
    if (!state.query) return;
    if (!state.cursor) return;
    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    const multimediaSearchResults = await fetchMultimediaSearchResults(
      state.query,
      state.cursor,
    );
    setState((prev) => ({
      ...prev,
      loading: false,
      data: [...prev.data, ...multimediaSearchResults.results],
      cursor: multimediaSearchResults.cursor,
    }));
  };

  return { ...state, loadMore };
};
