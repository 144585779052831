// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3686
import type { PAPEventGeneric } from "../base/event";
import type { StackAccessLevel } from "../enums/stack_access_level";
import type { FeatureLine } from "../enums/feature_line";

// Event_name: 'delete.dash_link_comment'
// Description: Fires whenever a user deletes a comment on a Stack Item.
// Owner: dash-web
export type Delete_DashLinkComment = PAPEventGeneric<
  'stacks',
  'delete',
  'dash_link_comment',
  {
    // The stack id that represents the stack
    stackId?: string;
    // The number of links in the stack
    numberOfLinks?: number;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // marks if the content is shared content
    isShared?: boolean;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // Used to identify if a stack is a published stack
    isPublished?: boolean;
  }
>;

export function PAP_Delete_DashLinkComment(properties?: Delete_DashLinkComment['properties']): Delete_DashLinkComment {
  return <Delete_DashLinkComment>{
    class: 'stacks',
    action: 'delete',
    object: 'dash_link_comment',
    properties,
  };
}
