import './dig_overrides.module.css';

import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import React from 'react';
import { useTheme } from './useTheme';

import type { AvailableThemeOverridesStrict } from '@dropbox/dig-foundations';

/**
 * OS theme provider for DIG. To check the current theme in children components,
 * use `useTheme()` from "@dropbox/dig-foundations".
 */
export const DashThemeProvider: React.FC<{
  rootElement?: HTMLElement;
  children: React.ReactNode;
}> = ({ children, rootElement }) => {
  const isDarkMode = useTheme();

  return (
    <ThemeProvider
      mode={isDarkMode ? 'dark' : 'bright'}
      theme="vis2023"
      overrides={dashThemeOverrides}
    >
      <ThemeContainer rootElement={rootElement || document.documentElement}>
        {children}
      </ThemeContainer>
    </ThemeProvider>
  );
};

export const dashThemeOverrides: AvailableThemeOverridesStrict = {
  colors: {
    'Background Subtle': {
      bright: '#F7F6F5',
      dark: '#242321',
    },
    'Opacity Surface': {
      bright: 'rgba(138, 119, 92, .1)',
      dark: 'rgba(255,255,255,.1)',
    },
    'Opacity Surface - State 1': {
      bright: 'rgba(138, 119, 92, .17)',
      dark: 'rgba(255,255,255,.15)',
    },
    'Opacity Surface - State 2': {
      bright: 'rgba(138, 119, 92, .2)',
      dark: 'rgba(255,255,255,.20)',
    },
  },
};
