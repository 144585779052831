import { ServiceId } from '@mirage/discovery/id';
import * as service from '@mirage/discovery/services';
import { openURL } from '@mirage/service-platform-actions';
import { syncService } from './service';

import type { ExitType, Service } from './service';

const settingActions = service.getp<Service>(ServiceId.SETTING_ACTIONS);

export default settingActions;

export async function getConnectorsUrl(): Promise<string> {
  return syncService
    ? syncService.getConnectorsUrl()
    : settingActions.getDashConnectorsUrl();
}

export async function openConnectors(): Promise<void> {
  const url = await getConnectorsUrl();
  await openURL(url);
}

export function manualCheckForUpdates(): Promise<void> {
  return settingActions.manualCheckForUpdates();
}

export function exit(exitType: ExitType): Promise<void> {
  return settingActions.exit(exitType);
}
