import { IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Text } from '@dropbox/dig-components/typography';
import { Box, Split, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { EmailLine, PersonMultipleLine } from '@dropbox/dig-icons/assets';
import { Link } from '@mirage/mosaics/BaseProfilePage/Link';
import { Title } from '@mirage/mosaics/BaseProfilePage/Title';
import UserProfileCard from '@mirage/mosaics/UserProfilePage/UserProfileCard';
import i18n from '@mirage/translations';
import styles from './TeamProfileBody.module.css';

import type {
  TeamInfo,
  TeamMemberInfo,
} from '@mirage/mosaics/TeamProfilePage/types';

interface ProfileBodyProps {
  team: TeamInfo;
}

export const TeamPrimaryContact = ({
  primaryContact,
  totalEmployees,
}: {
  primaryContact: TeamMemberInfo;
  totalEmployees: number;
}) => (
  <Box
    paddingBottom="8"
    marginBottom="8"
    display="flex"
    justifyContent="space-between"
    borderBottom={totalEmployees === 1 ? undefined : 'Solid'}
    borderColor="Border Subtle"
    borderWidth="1"
    alignItems="center"
  >
    <UserProfileCard
      profile_username={primaryContact.ldap}
      email={primaryContact.email}
      source="teams"
      display_name={primaryContact.name}
      profile_info={primaryContact.role?.split(',')[0]}
    />
    <Split alignY="center" gap="10">
      <Split.Item paddingRight="6">
        <Chip size="small">{i18n.t('primary_contact')}</Chip>
      </Split.Item>
      <Split.Item>
        <Link to={`mailto:${primaryContact.email}`}>
          <IconButton variant="outline" size="medium" shape="circular">
            <Box
              as={UIIcon}
              color="Text Subtle"
              src={EmailLine}
              size="medium"
            />
          </IconButton>
        </Link>
      </Split.Item>
    </Split>
  </Box>
);

export const TeamProfileBody = ({ team }: ProfileBodyProps) => {
  const primaryContact = team.members_info.find(
    (member) => member.is_primary_contact,
  );
  return (
    <div className={styles.profileBodySection}>
      <div className={styles.profileNameAvatar}>
        <div className={styles.profileNameCard}>
          <div className={styles.profileNameHeader}>
            <Text isBold style={{ fontSize: 24, lineHeight: '28px' }}>
              {team.name}
            </Text>
          </div>
          <Box>
            <Text size="large" color="faint">
              {team.description}
            </Text>
          </Box>
        </div>
      </div>
      <Box
        as={Stack}
        gap="8"
        marginTop="16"
        paddingX="20"
        paddingY="16"
        borderColor="Border Subtle"
        borderWidth="1"
        borderStyle="Solid"
        borderRadius="Medium"
        width="100%"
      >
        <Title
          size="small"
          withAccessoryStart={<UIIcon src={PersonMultipleLine} />}
        >
          {i18n.t('members_title')}
        </Title>
        {team.members_info.length > 0 ? (
          <Box marginTop="24">
            {primaryContact?.email && (
              <TeamPrimaryContact
                primaryContact={primaryContact}
                totalEmployees={team.employees.length}
              />
            )}
            <div className={styles.teamMembersContainer}>
              {team.members_info
                .filter((member) => !member.is_primary_contact)
                .map((member, index) => {
                  return (
                    <UserProfileCard
                      key={`${member.ldap}_${index}`}
                      source="team_profile"
                      profile_username={member.ldap}
                      email={member.email}
                      display_name={member.name}
                      profile_info={member.role?.split(',')[0]}
                    />
                  );
                })}
            </div>
          </Box>
        ) : (
          <Text variant="paragraph" size="large" tagName="p" color="faint">
            {i18n.t('no_members')}
          </Text>
        )}
      </Box>
    </div>
  );
};
