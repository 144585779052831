// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3714
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";

// Event_name: 'click.quick_link'
// Description: Fires when a user clicks on a quick link
// Owner: dash-exp-find-and-discover
export type Click_QuickLink = PAPEventGeneric<
  'dash',
  'click',
  'quick_link',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
  }
>;

export function PAP_Click_QuickLink(properties?: Click_QuickLink['properties']): Click_QuickLink {
  return <Click_QuickLink>{
    class: 'dash',
    action: 'click',
    object: 'quick_link',
    properties,
  };
}
