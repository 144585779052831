import {
  getCanonUrlMapRule,
  urlToContentID,
} from '@mirage/shared/util/urlRulesUtil';
import globToRegExp from 'glob-to-regexp';

import type { PresetActionKey } from '@mirage/shared/compose/assist-actions-preset';

/**
 * Base interface for all assist actions.
 */
interface BaseAssistAction {
  id: string;
  title: string;
  condition: AssistActionCondition;
  instruction: AssistActionInstruction;
  icon: string;
}

/**
 * User-created actions. Contains data for managing server sync.
 */
export interface CustomAssistAction extends BaseAssistAction {
  type: 'custom';
  // data for managing server sync
  accessConfig: AccessConfig;
  rev: number; // server rev version
  dataId?: string; // id of the action in the server
  isDirty?: boolean; // true if the action is modified locally and not synced
}

/**
 * Preset actions. These are predefined actions that are not modifiable by the user.
 */
export interface PresetAssistAction extends BaseAssistAction {
  type: 'preset';
  key: PresetActionKey;
}

export type AssistAction = CustomAssistAction | PresetAssistAction;

export type AssistActionCondition =
  | AssistActionConditionWebURL
  | AssistActionConditionDocType
  | AssistActionConditionAny;

export interface AssistActionConditionWebURL {
  type: 'web_url';
  url_pattern: string;
}
export interface AssistActionConditionDocType {
  type: 'doc_type';
  doc_type_prefix: string;
}
export interface AssistActionConditionAny {
  type: 'any';
  conditions: AssistActionCondition[];
}

export type AssistActionInstruction = AssistActionInstructionSingleMessage;

interface AssistActionInstructionSingleMessage {
  type: 'single_message';
  message: string;
}

type AccessConfig =
  | {
      type: 'user'; // user created action, not shared
    }
  | {
      type: 'team'; // team shared action
      isOwned: boolean; // true if the user is the owner of the action and can modify
    };

function urlMatchesCondition(
  condition: AssistActionCondition,
  url: string,
): boolean {
  switch (condition.type) {
    case 'web_url':
      {
        let urlPattern = condition.url_pattern;
        if (!urlPattern.endsWith('*')) {
          urlPattern = `${urlPattern}*`;
        }
        const re = globToRegExp(urlPattern);
        return re.test(url);
      }
      break;
    case 'doc_type':
      {
        const urlRule = getCanonUrlMapRule(urlToContentID(url));
        return !!urlRule && urlRule.canonPrefix === condition.doc_type_prefix;
      }
      break;
    case 'any':
      {
        return condition.conditions.some(
          (childCondition: AssistActionCondition) =>
            urlMatchesCondition(childCondition, url),
        );
      }
      break;
    default:
      condition satisfies never;
      return false;
  }
}

export function actionMatchesURL(action: AssistAction, url: string) {
  return urlMatchesCondition(action.condition, url);
}

export function canEditAction(
  action: AssistAction,
): action is CustomAssistAction {
  switch (action.type) {
    case 'preset':
      return false;
    case 'custom': {
      switch (action.accessConfig.type) {
        case 'user':
          return true;
        case 'team':
          return action.accessConfig.isOwned;
        default:
          action.accessConfig satisfies never;
          throw new Error('Invalid access config');
      }
    }
    default:
      action satisfies never;
      throw new Error('Invalid action type');
  }
}
