import { atom } from 'jotai';

import type { ChatEntryPoint } from '@mirage/conversations/types';
import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { AnswerResponse } from '@mirage/service-dbx-api/service/answers';

export type FlyoutPanelPapProperties = {
  entryPoint: ChatEntryPoint;

  // Search result related properties used for ML purposes
  resultPosition?: number;
  resultPositionNoCta?: number;
  resultUuid?: string;

  // ID from search API, used for ML logging purposes
  searchRequestId?: string;
};

export type FlyoutPanelData = {
  summary?: {
    pap?: FlyoutPanelPapProperties | null;
    // TODO OSE-3825: revisit "attachment" name, was done to keep the shape similar to the previous summary
    attachment: SummarizableResult;

    initialQuery?: string;
    initialAnswer?: AnswerResponse;

    isSummarize?: boolean;
  };
};

export type FlyoutPanelContext = string;

export const DEFAULT_PANEL_DATA: FlyoutPanelData = {};

export type FlyoutPanelView = keyof FlyoutPanelData | 'closed';

export const DEFAULT_PANEL_VIEW: FlyoutPanelView = 'closed';

// Do not export, use `flyoutPanelDataAtom` instead
const flyoutPanelDataStateAtom = atom<FlyoutPanelData>(DEFAULT_PANEL_DATA);

export const flyoutPanelViewAtom = atom<FlyoutPanelView>(DEFAULT_PANEL_VIEW);

export const flyoutPanelContextAtom = atom<FlyoutPanelContext>('');

export const flyoutPanelDataAtom = atom(
  (get) => get(flyoutPanelDataStateAtom),
  (
    get,
    set,
    update: {
      key: keyof FlyoutPanelData;
      value: FlyoutPanelData[keyof FlyoutPanelData];
    },
  ) => {
    const currentPanelData = get(flyoutPanelDataStateAtom);
    set(flyoutPanelDataStateAtom, {
      ...currentPanelData,
      [update.key]: update.value,
    });
  },
);
