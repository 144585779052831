import classnames from 'classnames';
import { useState } from 'react';
import { useSelectedMultimediaSearchResult } from './hooks/useSelectedMultimediaSearchResult';
import { MultimediaPreview } from './MultimediaPreview';
import { MultimediaResultItemSkeleton } from './MultimediaResultItemSkeleton';
import styles from './MultimediaSearchResults.module.css';

import type { SearchResult } from '@mirage/shared/search/search-result';
import type { SyntheticEvent } from 'react';

type Size = { width: number; height: number };

export const MultimediaResultItem = ({
  result,
  className,
  onSizeSet,
  onClick,
}: {
  result?: SearchResult;
  className?: string | undefined;
  onSizeSet?: (size: Size) => void;
  onClick?: (res: SearchResult) => void;
}) => {
  const [size, setSize] = useState<Size | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { openMultimediaSearchResult } = useSelectedMultimediaSearchResult();
  const onImgLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const size = {
      width: e.currentTarget.naturalWidth,
      height: e.currentTarget.naturalHeight,
    };

    const normalizedSize = {
      width: Math.min(340, (220 * size.width) / size.height),
      height: 220,
    };
    setSize(normalizedSize);
    onSizeSet?.(normalizedSize);
  };
  const onClickHandler = () => {
    if (!result) {
      return;
    }
    openMultimediaSearchResult(result);
    onClick?.(result);
  };
  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        onClickHandler();
        break;
    }
  };
  return (
    <div
      tabIndex={0}
      role="button"
      className={classnames(styles.item, className)}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
      style={{ flex: size ? `1 1 ${size.width}px` : undefined }}
    >
      {result ? (
        <MultimediaPreview
          result={result}
          onLoad={onImgLoad}
          className={styles.previewImg}
        />
      ) : (
        <MultimediaResultItemSkeleton />
      )}
      {/*  {result.videoPlayerUrl && (
        <div className={styles.videoOverlay}>
          <UIIcon src={PlayCircleFill} size="large" />
        </div>
      )} */}
    </div>
  );
};
