import { StackFilterOption } from '@mirage/service-settings/service/types';
import useSettings from '@mirage/service-settings/useSettings';
import { useIsCompanyStacksEnabled } from '@mirage/stacks/useIsCompanyStacksEnabled';
import { atom, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

import type { SelectableStackSortPreference } from '@mirage/service-settings/service/types';

const sortPreferenceAtom = atom<SelectableStackSortPreference | undefined>(
  undefined,
);
const filterPreferenceAtom = atom<StackFilterOption | undefined>(undefined);

export const useStackPagePreferences = () => {
  const isCompanyStacksEnabled = useIsCompanyStacksEnabled();
  const { settings, set: setSettings } = useSettings([
    'stackPageSortPreference',
    'stackPageFilterPreference',
  ]);
  const {
    stackPageSortPreference: sortPreference,
    stackPageFilterPreference: filterPreference,
  } = settings ?? {};

  useHydrateAtoms([
    [sortPreferenceAtom, sortPreference],
    [filterPreferenceAtom, filterPreference],
  ]);

  const [stackPageSortPreference, _setStackPageSortPreference] =
    useAtom(sortPreferenceAtom);
  const [stackPageFilterPreference, _setStackPageFilterPreference] =
    useAtom(filterPreferenceAtom);

  const setStackPageSortPreference = (
    sortPreference: SelectableStackSortPreference,
  ) => {
    _setStackPageSortPreference(sortPreference);
    setSettings('stackPageSortPreference', sortPreference);
  };

  const setStackPageFilterPreference = (
    filterPreference: StackFilterOption,
  ) => {
    _setStackPageFilterPreference(filterPreference);
    setSettings('stackPageFilterPreference', filterPreference);
  };

  // Handles edge case where user may have company filter selected and then we turn this feature off
  // leading to us still filtering for company stack without a visible UI indicator
  // Safe to remove, when we remove the useIsCompanyStackEnabled
  if (
    !isCompanyStacksEnabled &&
    filterPreference === StackFilterOption.COMPANY
  ) {
    setStackPageFilterPreference(StackFilterOption.ALL);
  }

  return {
    stackPageSortPreference,
    setStackPageSortPreference,
    stackPageFilterPreference,
    setStackPageFilterPreference,
  };
};
