import type { BaseKeys } from './baseKeys';

const common = {
  select: ['ctrl+a'],
  cut: ['ctrl+x'],
  copy: ['ctrl+c'],
  paste: ['ctrl+v'],
  quit: ['alt+f4'],
  escape: ['escape'],
  checkForUpdates: ['ctrl+u'],
  settings: ['ctrl+,'],
  focusOmnibox: ['/'],
};

const search = {
  copySelected: ['ctrl+c'],
  launchResult: ['enter'],
};

const typeahead = {
  copySelected: ['ctrl+c'],
  moveUp: ['arrowup', 'ctrl+p'],
  moveDown: ['arrowdown', 'ctrl+n'],
  launchResult: ['enter'],
  summarize: ['ctrl+s'],
  clearInput: ['escape'],
  searchQuery: ['ctrl+enter'],
  addToStack: ['ctrl+g'],
};

const resultItem = {
  launch: ['enter'],
  copy: ['ctrl+c'],
  moveDown: ['arrowdown', 'ctrl+n'],
  moveUp: ['arrowup', 'ctrl+p'],
  addToStack: ['ctrl+g'],
  summarize: ['ctrl+s'],
};

export const win32Symbols = {
  ctrl: 'Ctrl',
  control: 'Ctrl', // for invite page
  alt: 'Alt',
  super: 'Win',
  up: 'Up',
  down: 'Down',
  right: 'Right',
  left: 'Left',
  tab: 'Tab',
  shift: 'Shift',
} as const;

export const modifierKeys = {
  metaKey: 'super',
  ctrlKey: 'ctrl',
} as const;

export const mousetrapMap = {
  Control: 'ctrl',
  Meta: 'super',
  Alt: 'alt',
} as const;

export const win32Keys = (isDesktop: boolean): BaseKeys => {
  // On web, meta+p and meta+n map to existing browser shortcuts
  if (!isDesktop) {
    typeahead.moveDown = ['arrowdown'];
    typeahead.moveUp = ['arrowup'];
    resultItem.moveDown = ['arrowdown'];
    resultItem.moveUp = ['arrowup'];
  }
  return {
    common,
    search,
    launchResult: ['Enter'],
    typeahead,
    resultItem,
  };
};

export default win32Keys;
