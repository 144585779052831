import { AddSourcesContent } from '@mirage/mosaics/Chat/components/compose-sources/AddSourcesContent';
import { getSourceUUID } from '@mirage/shared/compose/compose-session';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { useMountEffect } from '@react-hookz/web';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSourcesCache } from './SourcesCacheContext';
import styles from './SourceSelect.module.css';

import type { ChatSource } from '@mirage/mosaics/Chat/types';

export const SourceSelect = ({
  value,
  onChange,
}: {
  value: string[];
  onChange: (sourceIds: string[]) => void;
}) => {
  const { sourcesContentCache, updateSources, getSourceById } =
    useSourcesCache();
  const [manuallyAddedSourceUUIDs, setManuallyAddedSourceUUIDs] =
    useState<string[]>();
  const [manuallyAddedSources, setManuallyAddedSources] = useState<
    ChatSource[]
  >([]);

  // Hydrate sources from UUIDs when component mounts or value changes
  useMountEffect(() => {
    // Skip if the UUIDs are the same
    if (isEqual(manuallyAddedSourceUUIDs, value)) {
      return;
    }

    // Update our local state with the new UUIDs
    setManuallyAddedSourceUUIDs(value);

    // Use getSourceById to hydrate sources from UUIDs
    const hydratedSources = value
      .map((uuid) => getSourceById(uuid))
      .filter(isDefined);

    if (hydratedSources.length > 0) {
      setManuallyAddedSources(hydratedSources);
    }
  });

  // Update the context when sources change
  useEffect(() => {
    if (manuallyAddedSources.length > 0) {
      updateSources(manuallyAddedSources);
    }
  }, [manuallyAddedSources, updateSources]);

  const removeSource = useCallback(
    (sourceToRemove: ChatSource) => {
      const sourceUUID = getSourceUUID(sourceToRemove);
      if (!sourceUUID) {
        return;
      }

      const newSources = manuallyAddedSources.filter((s) => {
        const currentUUID = getSourceUUID(s);
        return currentUUID !== sourceUUID;
      });
      const newSourceUUIDs = newSources.map(getSourceUUID).filter(isDefined);

      setManuallyAddedSources(newSources);
      setManuallyAddedSourceUUIDs(newSourceUUIDs);
    },
    [manuallyAddedSources],
  );

  const addSource = useCallback(
    (sourceToAdd: ChatSource) => {
      const uuid = getSourceUUID(sourceToAdd);
      if (!uuid) {
        return; // Skip if no UUID
      }

      const sourceUUIDs = new Set(manuallyAddedSources.map(getSourceUUID));
      if (sourceUUIDs.has(uuid)) {
        return; // Skip if source already exists
      }

      const newSources = [...manuallyAddedSources, sourceToAdd];
      const newSourceUUIDs = newSources.map(getSourceUUID).filter(isDefined);

      setManuallyAddedSources(newSources);
      setManuallyAddedSourceUUIDs(newSourceUUIDs);
    },
    [manuallyAddedSources],
  );

  useEffect(() => {
    if (isEqual(manuallyAddedSourceUUIDs, value)) {
      return;
    }
    onChange(manuallyAddedSourceUUIDs ?? []);
  }, [manuallyAddedSourceUUIDs, onChange, value]);

  return (
    <AddSourcesContent
      className={styles.sourceSelect}
      showTitle={false}
      enableUploads={false}
      customAddedSourcesSubtitle="Your tool will draw on these sources to during interactions with users."
      sources={manuallyAddedSources}
      sourcesContentCache={sourcesContentCache}
      addSource={addSource}
      removeSource={removeSource}
      onRequestClose={() => {}}
      logComposeEvent={() => {}}
    />
  );
};
