import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';

import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { SearchResult } from '@mirage/service-dbx-api/service/search';
import type { PlatformActionsAdapter } from '@mirage/service-platform-actions/service';
import type { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';
import type { Recommendation } from '@mirage/shared/search/recommendation';

export type Service = {
  openResult(
    result:
      | SearchResult
      | QuestionAndAnswerSource
      | SummarizableResult
      | Recommendation,
  ): Promise<void>;
  joinMeeting(result: SearchResult): Promise<void>;
};

let syncService: Service | undefined;

export function getSyncService() {
  return syncService;
}

export default function resultActions(adapter: PlatformActionsAdapter) {
  async function openResult(
    result:
      | SearchResult
      | QuestionAndAnswerSource
      | SummarizableResult
      | Recommendation,
  ) {
    return adapter.openResult(result);
  }

  async function joinMeeting(result: SearchResult) {
    if (result.recordType?.['.tag'] !== 'event') {
      return;
    }

    const conferenceLink = getMostLikelyConferenceLink(result) || result.url;

    await adapter.openURL(conferenceLink ?? '');
  }

  const service: Service = { openResult, joinMeeting };

  if (adapter.getActionMode() === 'direct') {
    syncService = service;
  }

  services.provide<Service>(ServiceId.RESULT_ACTIONS, service, []);
}

function getMostLikelyConferenceLink(
  searchResult: SearchResult,
): string | null {
  const preferedLinks = searchResult.conferenceLinks.filter(
    ({ label }) =>
      label !== 'Unknown' && label !== 'Web' && label !== 'Google Meet',
  );
  const googleMeetLinks = searchResult.conferenceLinks.filter(
    ({ label }) => label === 'Google Meet',
  );
  const links = [...preferedLinks, ...googleMeetLinks];
  if (links.length) return links[0].link || null;
  return null;
}
