import { IconButton } from '@dropbox/dig-components/buttons';
import { LabelGroup } from '@dropbox/dig-components/combinations';
import { List } from '@dropbox/dig-components/list';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { Box, useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine } from '@dropbox/dig-icons/assets';
import { useConnectQueryParam } from '@mirage/settings/hooks/useConnectQueryParam';
import { getConnectorNameForAttr } from '@mirage/shared/connectors';
import { isBrowserExtension } from '@mirage/shared/connectors/util';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n, { i18nUnlocalizableStringClass } from '@mirage/translations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './AvailableConnector.module.css';

import type { UIConnector, UIWebExtension } from '@mirage/shared/types';
import type { I18nKey } from '@mirage/translations';

interface Props {
  item: UIConnector | UIWebExtension;
  onConnect: () => void;
}

const getConnectorDescription = (item: UIConnector | UIWebExtension) => {
  const getTranslation = (id?: string, defaultString?: string) => {
    const i18nKey = `connector_description_${id}`.toLowerCase();
    return i18n.exists(i18nKey) ? i18n.t(i18nKey as I18nKey) : defaultString;
  };
  return isBrowserExtension(item)
    ? getTranslation(item.id, item.description)
    : getTranslation(
        item.id_attrs?.type?.replace('custom.', ''),
        item.branding?.brief_description,
      );
};

export const AvailableConnector: React.FC<Props> = ({ item, onConnect }) => {
  const { connect, removeConnect } = useConnectQueryParam();
  const isWebExtension = isBrowserExtension(item);
  const label = isWebExtension ? item.label : item.branding?.display_name;
  const attrFriendlyName = useMemo(
    () => getConnectorNameForAttr(label || ''),
    [label],
  );
  const description = getConnectorDescription(item);
  const integration = isWebExtension ? undefined : item.id_attrs?.type;
  const [autoConnectOpen, setAutoConnectOpen] = useState(false);
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';

  const onConnectClick = useCallback(async () => {
    onConnect();
  }, [onConnect]);

  const launchAutoConnect =
    !autoConnectOpen && !isWebExtension && connect === item.id_attrs?.type;

  useEffect(() => {
    if (launchAutoConnect) {
      onConnectClick();
      setAutoConnectOpen(true);
      removeConnect();
    }
  }, [launchAutoConnect, setAutoConnectOpen, onConnectClick, removeConnect]);

  return (
    <List.Item
      data-testid={`AvailableConnector-listItem-${attrFriendlyName}`}
      className={styles.listItem}
      padding="var(--spacing__unit--1_5)"
      verticalAlign="center"
      isSelectable={true}
      onClick={(e: React.MouseEvent): void => {
        e.stopPropagation();
        onConnectClick();
      }}
    >
      <List.Content>
        <LabelGroup
          withLeftAccessory={
            isWebExtension ? (
              <span className={styles.iconExtraMargin}>{item.icon}</span>
            ) : (
              <img
                key={`${integration}-icon`}
                src={
                  isDarkMode && item.branding?.icon_dark_src
                    ? item.branding.icon_dark_src
                    : item.branding?.icon_src
                }
                width="24px"
                height="24px"
                alt={label}
                className={styles.iconExtraMargin}
                aria-hidden
              />
            )
          }
          withText={
            <Text isBold className={i18nUnlocalizableStringClass}>
              {label}
            </Text>
          }
          withSubtext={<Box marginTop="4">{description}</Box>}
        />
      </List.Content>
      <List.Accessory className={styles.flexCenter}>
        {!isWebExtension && item.loading ? (
          <Spinner size="small" />
        ) : (
          <DigTooltip title={i18n.t('connect')} placement="left">
            <IconButton
              variant="opacity"
              size="standard"
              shape="circular"
              onClick={(e: React.MouseEvent): void => {
                e.stopPropagation();
                onConnectClick();
              }}
            >
              <UIIcon src={AddLine} aria-label={i18n.t('connect')} />
            </IconButton>
          </DigTooltip>
        )}
      </List.Accessory>
    </List.Item>
  );
};
