import { getActors, getFirstAction } from '@mirage/service-feed/util/helpers';
import { Trans } from '@mirage/translations';
import { ActivityActorsLabel } from '../ActivityLabel/ActivityLabelSubject';

import type { dash_feed, users } from '@dropbox/api-v2-client';
import type { SearchResult } from '@mirage/shared/search/search-result';

type DocumentActivityLabelProps = {
  activity: dash_feed.FeedItem;
  document: SearchResult;
  currentAccount?: users.FullAccount;
};

type EditDocumentActivityLabelProps = {} & DocumentActivityLabelProps;
type ShareDocumentActivityLabelProps = {} & DocumentActivityLabelProps;

export const DocumentActivityLabel = ({
  activity,
  document,
  currentAccount,
}: DocumentActivityLabelProps) => {
  const action = getFirstAction(activity);
  switch (action?.['.tag']) {
    case 'edit':
      return (
        <EditDocumentActivityLabel
          currentAccount={currentAccount}
          activity={activity}
          document={document}
        />
      );
    case 'share':
      return (
        <ShareDocumentActivityLabel
          currentAccount={currentAccount}
          activity={activity}
          document={document}
        />
      );
    default:
      return null;
  }
};

export const EditDocumentActivityLabel = ({
  activity,
}: EditDocumentActivityLabelProps) => {
  const actors = getActors(activity);
  return (
    <Trans
      i18nKey={'activity_feed_expanded_label_edit_document'}
      components={{
        subject: <ActivityActorsLabel actors={actors} />,
      }}
    />
  );
};

export const ShareDocumentActivityLabel = ({
  activity,
}: ShareDocumentActivityLabelProps) => {
  const actors = getActors(activity);
  return (
    <Trans
      i18nKey={'activity_feed_expanded_label_share_document'}
      components={{
        subject: <ActivityActorsLabel actors={actors} />,
      }}
    />
  );
};
