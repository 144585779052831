import type { dash, stacks } from '@dropbox/api-v2-client';

export type StackItem = stacks.StackItem | StackItemShortcutWithMetadata;

export type StackItemShortcutWithMetadata = stacks.StackItemShortcut & {
  faviconUrl?: string;
  thumbnailUrl?: string;
  previewUrl?: string;

  uuid?: string;
  summarizableTag?: dash.SummarizableType['.tag'];

  // If you wish to add items here, let's consider reworking these types
  // to maintain a separation between stacks.StackItem and the metadata.
  // IOW: we think moving to a has-a relationship would be better than is-a
  //      relationship in the long term.
};

// Interface and helper function to better integrate with the SummaryQnaPanel.
// This could probably be moved to a more general location so more than stacks
// and stack items can take advantage of it. Perhaps can be replaced by
// SummaryQnaPanel.utils.SummarizableResult. But for now it represents the delta
// between a stacks.StackItem and the values that the SummaryQnaPanel needs.
export interface Summarizable {
  uuid?: string;
  summarizableTag?: dash.SummarizableType['.tag'];
}

export function isSummarySupported(item: Summarizable): boolean {
  return !!(item.uuid && item.summarizableTag === 'yes_summarizable');
}
