// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/connector_info.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * Yes, duplicated from dash/primitives. All of the dash
 * protobufs are generated with go-v2, ContextEngine uses
 * go. This is the quick work around
 *
 * @generated from message context_engine.CE_IconResource
 */
export class CE_IconResource extends Message<CE_IconResource> {
  /**
   * @generated from field: string light_url = 1;
   */
  lightUrl = '';

  /**
   * @generated from field: string dark_url = 2;
   */
  darkUrl = '';

  /**
   * @generated from field: string light_png_url = 3;
   */
  lightPngUrl = '';

  /**
   * @generated from field: string dark_png_url = 4;
   */
  darkPngUrl = '';

  constructor(data?: PartialMessage<CE_IconResource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CE_IconResource';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'light_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'dark_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'light_png_url',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 4,
      name: 'dark_png_url',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CE_IconResource {
    return new CE_IconResource().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CE_IconResource {
    return new CE_IconResource().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CE_IconResource {
    return new CE_IconResource().fromJsonString(jsonString, options);
  }

  static equals(
    a: CE_IconResource | PlainMessage<CE_IconResource> | undefined,
    b: CE_IconResource | PlainMessage<CE_IconResource> | undefined,
  ): boolean {
    return proto3.util.equals(CE_IconResource, a, b);
  }
}

/**
 * @generated from message context_engine.CE_ConnectorInfo
 */
export class CE_ConnectorInfo extends Message<CE_ConnectorInfo> {
  /**
   * this won't be set and will be ignored as it's per-user
   *
   * @generated from field: string connector_id = 1;
   */
  connectorId = '';

  /**
   * @generated from field: int32 connector_type = 2;
   */
  connectorType = 0;

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = '';

  /**
   * @generated from field: int32 platform = 4;
   */
  platform = 0;

  /**
   * @generated from field: string connector_name = 5;
   */
  connectorName = '';

  /**
   * @generated from field: string connector_icon_url = 6;
   */
  connectorIconUrl = '';

  /**
   * @generated from field: context_engine.CE_IconResource icon = 7;
   */
  icon?: CE_IconResource;

  constructor(data?: PartialMessage<CE_ConnectorInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CE_ConnectorInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'connector_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'connector_type',
      kind: 'scalar',
      T: 5 /* ScalarType.INT32 */,
    },
    {
      no: 3,
      name: 'display_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 4, name: 'platform', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    {
      no: 5,
      name: 'connector_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 6,
      name: 'connector_icon_url',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 7, name: 'icon', kind: 'message', T: CE_IconResource },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CE_ConnectorInfo {
    return new CE_ConnectorInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CE_ConnectorInfo {
    return new CE_ConnectorInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CE_ConnectorInfo {
    return new CE_ConnectorInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: CE_ConnectorInfo | PlainMessage<CE_ConnectorInfo> | undefined,
    b: CE_ConnectorInfo | PlainMessage<CE_ConnectorInfo> | undefined,
  ): boolean {
    return proto3.util.equals(CE_ConnectorInfo, a, b);
  }
}

/**
 * @generated from message context_engine.CE_AllConnectorInfos
 */
export class CE_AllConnectorInfos extends Message<CE_AllConnectorInfos> {
  /**
   * @generated from field: map<string, context_engine.CE_ConnectorInfo> infos = 1;
   */
  infos: { [key: string]: CE_ConnectorInfo } = {};

  constructor(data?: PartialMessage<CE_AllConnectorInfos>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CE_AllConnectorInfos';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'infos',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: CE_ConnectorInfo },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CE_AllConnectorInfos {
    return new CE_AllConnectorInfos().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CE_AllConnectorInfos {
    return new CE_AllConnectorInfos().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CE_AllConnectorInfos {
    return new CE_AllConnectorInfos().fromJsonString(jsonString, options);
  }

  static equals(
    a: CE_AllConnectorInfos | PlainMessage<CE_AllConnectorInfos> | undefined,
    b: CE_AllConnectorInfos | PlainMessage<CE_AllConnectorInfos> | undefined,
  ): boolean {
    return proto3.util.equals(CE_AllConnectorInfos, a, b);
  }
}

/**
 * @generated from message context_engine.CE_FileTypeInfo
 */
export class CE_FileTypeInfo extends Message<CE_FileTypeInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = '';

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = '';

  /**
   * @generated from field: context_engine.CE_IconResource icon = 3;
   */
  icon?: CE_IconResource;

  constructor(data?: PartialMessage<CE_FileTypeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.CE_FileTypeInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 2,
      name: 'display_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 3, name: 'icon', kind: 'message', T: CE_IconResource },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): CE_FileTypeInfo {
    return new CE_FileTypeInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): CE_FileTypeInfo {
    return new CE_FileTypeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): CE_FileTypeInfo {
    return new CE_FileTypeInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: CE_FileTypeInfo | PlainMessage<CE_FileTypeInfo> | undefined,
    b: CE_FileTypeInfo | PlainMessage<CE_FileTypeInfo> | undefined,
  ): boolean {
    return proto3.util.equals(CE_FileTypeInfo, a, b);
  }
}
