import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Create_StacksDndSection } from '@mirage/analytics/events/types/create_stacks_dnd_section';
import { PAP_Move_DashLink } from '@mirage/analytics/events/types/move_dash_link';
import { PAP_Move_StacksDndStackSuggestion } from '@mirage/analytics/events/types/move_stacks_dnd_stack_suggestion';
import { updateStackItem } from '@mirage/service-stacks';
import {
  asShortcut,
  stackDerivePAPProps,
} from '@mirage/service-stacks/service/utils';
import { showSnackbar } from '@mirage/shared/snackbar';
import { coerceSortKey } from '@mirage/shared/util/tiny-utils';
import {
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackItemsAtom,
  activeStackLinkSectionsMapAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import i18n from '@mirage/translations';
import { generateKeyBetween, generateNKeysBetween } from 'fractional-indexing';
import { useAtomValue } from 'jotai';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useAddLinkToStack, useCreateStackSection } from '../hooks';
import { useSectionsNavigation } from '../Sections/hooks';

import type { stacks } from '@dropbox/api-v2-client';
import type { MoveDashLinkType } from '@mirage/analytics/events/enums/move_dash_link_type';
import type { Link } from '@mirage/link-list/types';

export const DnDContext = createContext<{
  moveSuggestionIntoSection: (
    sectionId: string,
    item: Link,
    dstIndex: number,
  ) => void;
  moveStackItemIntoSection: (
    sectionId: string,
    itemId: string,
    dstIndex: number,
  ) => void;
  createSectionAndMoveStackItem: (
    afterSectionId: string,
    itemId: string,
  ) => void;
  createSectionAndAddSuggestion: (afterSectionId: string, item: Link) => void;
  isResettingSortKeys: boolean;
  isDisabled: boolean;
  isItemDragging: boolean;
  setIsItemDragging: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export const useDnDContext = () => {
  const context = useContext(DnDContext);
  if (!context) {
    throw new Error('useDnDContext must be used within a DnDProvider');
  }
  return context;
};

export const DndProvider: React.FC<{
  children: React.ReactNode;
  disabled?: boolean;
}> = ({ children, disabled = false }) => {
  const stack = useAtomValue(activeStackAtom);
  const items = useAtomValue(activeStackItemsAtom);
  const { allSections: sections } = useAtomValue(activeStackSectionsAtom);
  const linkSectionMap = useAtomValue(activeStackLinkSectionsMapAtom);
  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);
  const addLinkToStack = useAddLinkToStack();
  const namespaceId = stack?.namespace_id ?? '';
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { triggerEditSection } = useSectionsNavigation();
  const [isResettingSortKeys, setIsResettingSortKeys] = useState(false);
  const [isItemDragging, setIsItemDragging] = useState(false);
  const sectionsDndEnabled = !disabled;

  const tryResetSectionSortKeys = useCallback(
    async (sectionLinks: stacks.StackItemShortcut[]) => {
      if (sectionLinks.length === 0) {
        return [];
      }
      setIsResettingSortKeys(true);
      const oldSortKeys = sectionLinks.map((link) => link.sort_key);
      const uniqueSortKeys = new Set(
        oldSortKeys.filter((key) => key && key !== ''),
      );
      if (uniqueSortKeys.size !== oldSortKeys.length) {
        // then we need to reassign sort keys
        const newSortKeys = generateNKeysBetween(
          null,
          null,
          sectionLinks.length,
        );
        try {
          const updateStackItemPromises = sectionLinks.map((link, index) => {
            link.sort_key = newSortKeys[index];
            return updateStackItem(namespaceId, link);
          });
          await Promise.all(updateStackItemPromises);
          setIsResettingSortKeys(false);
          return newSortKeys;
        } catch (e) {
          showSnackbar({ title: i18n.t('error_updating_stack_item') });
          setIsResettingSortKeys(false);
          throw e;
        }
      }
      setIsResettingSortKeys(false);
      return oldSortKeys;
    },
    [namespaceId, setIsResettingSortKeys],
  );

  const moveSuggestionIntoSection = useCallback(
    async (sectionId, item, dstIndex) => {
      const sectionLinks = linkSectionMap?.get(sectionId) || [];
      const sortKeys = await tryResetSectionSortKeys(sectionLinks);
      item.sortKey = generateKeyBetween(
        coerceSortKey(sortKeys[dstIndex - 1]),
        coerceSortKey(sortKeys[dstIndex]),
      );
      await addLinkToStack(sectionId, item);
      if (stack) {
        reportPapEvent(
          PAP_Move_StacksDndStackSuggestion({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
    },
    [
      linkSectionMap,
      tryResetSectionSortKeys,
      addLinkToStack,
      stack,
      reportPapEvent,
    ],
  );

  const moveStackItemIntoSection = useCallback(
    async (dstSectionId, itemId, dstIndex, isNewSection = false) => {
      const dstSectionLinks = linkSectionMap?.get(dstSectionId) || [];
      if (!stack || !sections || !items) {
        return;
      }

      const sortKeys = await tryResetSectionSortKeys(dstSectionLinks);
      const itemLinkIndex = (items || []).findIndex(
        (item) => asShortcut(item).api_file_id === itemId,
      );
      if (itemLinkIndex === -1) {
        return;
      }
      const item = asShortcut(items[itemLinkIndex]);
      const isAcrossSections = item.parent_section_id !== dstSectionId;
      item.sort_key = generateKeyBetween(
        coerceSortKey(sortKeys[dstIndex - 1]),
        coerceSortKey(sortKeys[dstIndex]),
      );
      item.parent_section_id = dstSectionId;
      items[itemLinkIndex] = item;
      const moveDashLinkType: MoveDashLinkType = isNewSection
        ? 'new_section'
        : isAcrossSections
          ? 'different_section'
          : 'within_section';

      reportPapEvent(
        PAP_Move_DashLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
          moveDashLinkType,
          moveDashObjectMethod: 'drag_and_drop',
        }),
      );
      await updateStackItem(namespaceId, item);
    },
    [
      linkSectionMap,
      items,
      sections,
      tryResetSectionSortKeys,
      stack,
      namespaceId,
      reportPapEvent,
    ],
  );

  const createStackSection = useCreateStackSection();
  const createSectionAndAddItem = useCallback(
    async (afterSectionId: string, cb: (newSectionId: string) => void) => {
      const newSectionId = await createStackSection(
        i18n.t('new_section'),
        afterSectionId,
      );
      if (!newSectionId) {
        return;
      }
      if (stack) {
        reportPapEvent(
          PAP_Create_StacksDndSection({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          }),
        );
      }
      cb(newSectionId);
      triggerEditSection(newSectionId);
    },
    [createStackSection, reportPapEvent, stack, triggerEditSection],
  );

  const createSectionAndMoveStackItem = useCallback(
    async (afterSectionId: string, itemId: string) => {
      createSectionAndAddItem(afterSectionId, (newSectionId) => {
        moveStackItemIntoSection(newSectionId, itemId, 0);
      });
    },
    [createSectionAndAddItem, moveStackItemIntoSection],
  );

  const createSectionAndAddSuggestion = useCallback(
    async (afterSectionId: string, item: Link) => {
      createSectionAndAddItem(afterSectionId, (newSectionId) => {
        moveSuggestionIntoSection(newSectionId, item, 0);
      });
    },
    [createSectionAndAddItem, moveSuggestionIntoSection],
  );

  const value = useMemo(
    () => ({
      moveStackItemIntoSection,
      moveSuggestionIntoSection,
      createSectionAndMoveStackItem,
      createSectionAndAddSuggestion,
      isResettingSortKeys,
      isItemDragging,
      setIsItemDragging,
      isDisabled:
        !sectionsDndEnabled ||
        !hasWritePermissions ||
        isResettingSortKeys ||
        !stack?.namespace_id,
    }),
    [
      moveStackItemIntoSection,
      moveSuggestionIntoSection,
      createSectionAndMoveStackItem,
      createSectionAndAddSuggestion,
      isResettingSortKeys,
      hasWritePermissions,
      stack?.namespace_id,
      sectionsDndEnabled,
      isItemDragging,
    ],
  );

  return <DnDContext.Provider value={value}>{children}</DnDContext.Provider>;
};
