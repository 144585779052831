import { setIsFreshLogin } from '@mirage/service-auth';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { addDisplayStat } from '..';
import { useOperationalMetrics } from '../hooks';
import { AGGREGATED_PAGE_MODULE } from './constants';

import type { Tags } from '../service';
import type { MetricPageName } from './constants';

export type TagsArg = Tags | (() => Promise<Tags>);

const recordedModuleLatencies = atom<{
  [moduleName: string]: number;
}>({});

export const perfTimeSinceLastNavigateAtom = atom(-1);

// Ensure each navigation can only be logged once.
let currentNavigationId = uuidv4();

const lastLoggedNavigationIdByPageAndModule: {
  [pageAndModuleName: string]: string;
} = {};

function checkAndUpdateForDuplicateLogging(
  pageName: MetricPageName,
  moduleName: string,
): 'duplicateFound' | 'noDuplicatesFound' {
  // Ensure that we don't publish duplicates for one navigation.
  const pageAndModuleName = pageName + ':' + moduleName;

  const lastLoggedNavigationId =
    lastLoggedNavigationIdByPageAndModule[pageAndModuleName];

  if (lastLoggedNavigationId === currentNavigationId) {
    // Already logged, don't log again.
    return 'duplicateFound';
  }

  // Eager mark completion to prevent double logging.
  lastLoggedNavigationIdByPageAndModule[pageAndModuleName] =
    currentNavigationId;

  return 'noDuplicatesFound';
}

/**
 * Due to the fact that `useAutoRecordAggregatedPageLatency` could be lazy
 * loaded, we need to eager init the perf time basis separately in the app.
 */
export function useInitPageLatencyWithinRouter() {
  const isFirst = useRef(true);
  const setPerfTimeSinceLastNavigate = useSetAtom(
    perfTimeSinceLastNavigateAtom,
  );

  // useLocation can only be used inside a react router.
  const { pathname, hash } = useLocation();

  useLayoutEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;

      // performance.now() starts counting from performance.timeOrigin,
      // so there is no time offset (0) since page load.
      setPerfTimeSinceLastNavigate(0);
    } else {
      currentNavigationId = uuidv4();
      setPerfTimeSinceLastNavigate(performance.now());
    }
  }, [pathname, hash, setPerfTimeSinceLastNavigate]);
}

function usePageLatencyMetrics<T extends string>(
  pageName: MetricPageName,
  moduleName: T,
  tags: TagsArg,
) {
  const { stats } = useOperationalMetrics('page_latency/' + pageName);

  const logStats = useCallback(
    (latencyMs: number) => {
      if (typeof tags === 'function') {
        tags().then((tags) => stats(moduleName, latencyMs, tags));
      } else {
        stats(moduleName, latencyMs, tags);
      }
    },
    [moduleName, stats, tags],
  );

  return logStats;
}

/**
 * Allow the page to pick and choose the slowest module to report latency
 * after all the dependent modules have completed loading.
 *
 * T is basically the module (large sub-component) name, but
 * strongly-typed to a limited set of possible values.
 */
export function useAutoRecordAggregatedPageLatency<T extends string>(
  pageName: MetricPageName,
  dependentModuleNames: T[], // callers to make sure that this is a stable object
  tags: TagsArg, // callers to make sure that this is a stable object
) {
  const [latencies, setLatencies] = useAtom(recordedModuleLatencies);
  const logStats = usePageLatencyMetrics(
    pageName,
    AGGREGATED_PAGE_MODULE,
    tags,
  );

  // Reset all latencies quickly.
  useLayoutEffect(() => {
    setLatencies({});
  }, [setLatencies]);

  // Capture the aggregated latency when all dependent modules have loaded.
  useEffect(() => {
    let maxLatencyMs = 0;

    for (const moduleName of dependentModuleNames) {
      const latency = latencies[moduleName];

      // All dependent latencies must be present to record the aggregated
      // page latency.
      if (!latency) {
        return;
      }

      maxLatencyMs = Math.max(maxLatencyMs, latency);
    }

    if (
      checkAndUpdateForDuplicateLogging(pageName, AGGREGATED_PAGE_MODULE) ===
      'duplicateFound'
    ) {
      // Already logged, don't log again.
      return;
    }

    // Publish metric to server.
    logStats(maxLatencyMs);

    // Make it easy to see the stat in the UI.
    addDisplayStat(`TTPC (${pageName})`, maxLatencyMs);

    // Reset all latencies.
    setLatencies({});

    // After first logging, set fresh login to false. Otherwise we will
    // include cached pages as fresh login too, which is off.
    setIsFreshLogin(false);
  }, [dependentModuleNames, latencies, logStats, pageName, setLatencies, tags]);
}

export function useRecordModuleLatency<T extends string>(
  pageName: MetricPageName,
  moduleName: T, // for dynamic module names, it is ok to use a custom string
  tags: TagsArg, // callers to make sure that this is a stable object,
  excludeFromE2E = false, // don't block E2E metric
) {
  const setLatencies = useSetAtom(recordedModuleLatencies);
  const logStats = usePageLatencyMetrics(pageName, moduleName, tags);
  const perfTimeSinceLastNavigate = useAtomValue(perfTimeSinceLastNavigateAtom);

  if (perfTimeSinceLastNavigate < 0) {
    throw new Error(`Must call useInitPageLatencyWithinRouter() first`);
  }

  const markModuleLoadComplete = useCallback(() => {
    // No need to mark completion more than once. Make it safe for callers to
    // call this function more than once without needing to track their own
    // state. Note that using `latencies` will not work because that value will
    // get reset when page aggregation is done.
    if (
      checkAndUpdateForDuplicateLogging(pageName, moduleName) ===
      'duplicateFound'
    ) {
      // Already logged, don't log again.
      return;
    }

    const latencyMs = performance.now() - perfTimeSinceLastNavigate;

    // Register this latency for aggregating the page latency.
    if (!excludeFromE2E) {
      setLatencies((latencies) => ({ ...latencies, [moduleName]: latencyMs }));
    }

    // Publish metric to server.
    logStats(latencyMs);
  }, [
    excludeFromE2E,
    logStats,
    moduleName,
    pageName,
    perfTimeSinceLastNavigate,
    setLatencies,
  ]);

  return { markModuleLoadComplete };
}
