// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/user_preferences.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message context_engine.WorkingHoursPerDay
 */
export class WorkingHoursPerDay extends Message<WorkingHoursPerDay> {
  /**
   * Formatted as HH:MM in 24-hour time.
   *
   * @generated from field: string start_time = 1;
   */
  startTime = '';

  /**
   * @generated from field: string end_time = 2;
   */
  endTime = '';

  constructor(data?: PartialMessage<WorkingHoursPerDay>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.WorkingHoursPerDay';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'start_time', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'end_time', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): WorkingHoursPerDay {
    return new WorkingHoursPerDay().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): WorkingHoursPerDay {
    return new WorkingHoursPerDay().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): WorkingHoursPerDay {
    return new WorkingHoursPerDay().fromJsonString(jsonString, options);
  }

  static equals(
    a: WorkingHoursPerDay | PlainMessage<WorkingHoursPerDay> | undefined,
    b: WorkingHoursPerDay | PlainMessage<WorkingHoursPerDay> | undefined,
  ): boolean {
    return proto3.util.equals(WorkingHoursPerDay, a, b);
  }
}

/**
 * @generated from message context_engine.WorkingHours
 */
export class WorkingHours extends Message<WorkingHours> {
  /**
   * @generated from field: context_engine.WorkingHoursPerDay monday = 1;
   */
  monday?: WorkingHoursPerDay;

  /**
   * @generated from field: context_engine.WorkingHoursPerDay tuesday = 2;
   */
  tuesday?: WorkingHoursPerDay;

  /**
   * @generated from field: context_engine.WorkingHoursPerDay wednesday = 3;
   */
  wednesday?: WorkingHoursPerDay;

  /**
   * @generated from field: context_engine.WorkingHoursPerDay thursday = 4;
   */
  thursday?: WorkingHoursPerDay;

  /**
   * @generated from field: context_engine.WorkingHoursPerDay friday = 5;
   */
  friday?: WorkingHoursPerDay;

  /**
   * @generated from field: context_engine.WorkingHoursPerDay saturday = 6;
   */
  saturday?: WorkingHoursPerDay;

  /**
   * @generated from field: context_engine.WorkingHoursPerDay sunday = 7;
   */
  sunday?: WorkingHoursPerDay;

  constructor(data?: PartialMessage<WorkingHours>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.WorkingHours';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'monday', kind: 'message', T: WorkingHoursPerDay },
    { no: 2, name: 'tuesday', kind: 'message', T: WorkingHoursPerDay },
    { no: 3, name: 'wednesday', kind: 'message', T: WorkingHoursPerDay },
    { no: 4, name: 'thursday', kind: 'message', T: WorkingHoursPerDay },
    { no: 5, name: 'friday', kind: 'message', T: WorkingHoursPerDay },
    { no: 6, name: 'saturday', kind: 'message', T: WorkingHoursPerDay },
    { no: 7, name: 'sunday', kind: 'message', T: WorkingHoursPerDay },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): WorkingHours {
    return new WorkingHours().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): WorkingHours {
    return new WorkingHours().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): WorkingHours {
    return new WorkingHours().fromJsonString(jsonString, options);
  }

  static equals(
    a: WorkingHours | PlainMessage<WorkingHours> | undefined,
    b: WorkingHours | PlainMessage<WorkingHours> | undefined,
  ): boolean {
    return proto3.util.equals(WorkingHours, a, b);
  }
}

/**
 * @generated from message context_engine.UserPreferences
 */
export class UserPreferences extends Message<UserPreferences> {
  /**
   * @generated from field: context_engine.WorkingHours working_hours = 1;
   */
  workingHours?: WorkingHours;

  constructor(data?: PartialMessage<UserPreferences>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UserPreferences';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'working_hours', kind: 'message', T: WorkingHours },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UserPreferences {
    return new UserPreferences().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UserPreferences {
    return new UserPreferences().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UserPreferences {
    return new UserPreferences().fromJsonString(jsonString, options);
  }

  static equals(
    a: UserPreferences | PlainMessage<UserPreferences> | undefined,
    b: UserPreferences | PlainMessage<UserPreferences> | undefined,
  ): boolean {
    return proto3.util.equals(UserPreferences, a, b);
  }
}
