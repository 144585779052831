import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import _ from 'lodash';
import { useMemo } from 'react';
import { mockRecentData } from './recents-mock-data';
import {
  useRecentActivities,
  useRecentBrowserHistory,
  useRecentEntities,
  useRecentExtensionUpsell,
} from './useRecentSubscriptions';

import type { RecentData } from '../service';
import type {
  RecentActivitiesContent,
  RecentBrowserContent,
  RecentConnectorContent,
  RecentContent,
} from '../types';
import type { dash_connectors } from '@dropbox/api-v2-client';
import type { Connector } from '@mirage/service-dbx-api/service';
import type { FeatureValue } from '@mirage/service-experimentation/features';

export const useCombinedRecents = (): RecentData<RecentContent> => {
  const extensionUpsell = useRecentExtensionUpsell();
  const browserHistory = useRecentBrowserHistory();
  const recentEntities = useRecentEntities();
  const recentActivities = useRecentActivities();
  const dataSource = useFeatureFlagValue('dash_2024_08_06_recents_data_source');

  // If true, we'll render "mock" data to be used during roadshow demos by Morgan/Umesh
  const shouldUseMockRecentsData = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_10_04_mock_recents_data'),
  );

  const recentData = useMemo(() => {
    const recentData = getRecentDataForDataSource(
      dataSource,
      recentEntities,
      browserHistory,
      recentActivities,
    );

    // show extension upsell first if it exists
    const content = _.chain(extensionUpsell.content as RecentContent[])
      .concat(recentData.content)
      .value();
    const isLoading = recentData.isLoading;
    return {
      isLoading,
      content,
    };
  }, [
    browserHistory,
    recentEntities,
    recentActivities,
    dataSource,
    extensionUpsell.content,
  ]);

  if (shouldUseMockRecentsData) {
    return {
      isLoading: false,
      content: mockRecentData,
    };
  }

  return recentData;
};

function getRecentDataForDataSource(
  dataSource: FeatureValue,
  recentEntities: RecentData<RecentConnectorContent>,
  browserHistory: RecentData<RecentBrowserContent>,
  recentActivities: RecentData<RecentActivitiesContent>,
): RecentData<RecentContent> {
  switch (dataSource) {
    // V1/V2: Browser history OR recent entities
    case 'V1':
    case 'V2':
      return getBrowserHistoryOrConnectorRecents(
        recentEntities,
        browserHistory,
      );
    // V3: Recent activities
    case 'V3':
      return getRecentActivitiesRecents(recentActivities);
    // not present or CONTROL = Browser history + recent entities
    case undefined:
    case 'CONTROL':
      return getCombinedBrowserHistoryAndConnectorRecents(
        recentEntities,
        browserHistory,
      );
    // default = nothing
    default:
      return { isLoading: false, content: [] };
  }
}

function getCombinedBrowserHistoryAndConnectorRecents(
  recentEntities: RecentData<RecentConnectorContent>,
  browserHistory: RecentData<RecentBrowserContent>,
): RecentData<RecentContent> {
  return {
    isLoading: recentEntities.isLoading || browserHistory.isLoading,
    content: _.chain(recentEntities.content as RecentContent[])
      .concat(browserHistory.content)
      .uniqBy('url')
      .orderBy('visit_ms', 'desc')
      .value(),
  };
}

function getBrowserHistoryOrConnectorRecents(
  recentEntities: RecentData<RecentConnectorContent>,
  browserHistory: RecentData<RecentBrowserContent>,
): RecentData<RecentContent> {
  let content: RecentContent[] = [];
  if (browserHistory.content.length > 0) {
    content = browserHistory.content;
  } else if (recentEntities.content.length > 0) {
    content = recentEntities.content;
  }

  return {
    isLoading: recentEntities.isLoading || browserHistory.isLoading,
    content: content,
  };
}

function getRecentActivitiesRecents(
  recentActivities: RecentData<RecentActivitiesContent>,
): RecentData<RecentContent> {
  return recentActivities;
}

export function isValidConnectorForRecentContents(connector: Connector) {
  // We already have a dedicated calendar widget for calendar events.
  return !connector.id_attrs?.type?.toLowerCase()?.includes('calendar');
}

export function filterRecentContents<T extends RecentContent>(
  recentContents: T[],
  connectors: Connector[],
): T[] {
  // Calendar updates are frequent and low value.
  const validConnectors = connectors.filter(isValidConnectorForRecentContents);

  const validConnectorNames = new Set(
    validConnectors.map((connector) => connector.id_attrs?.type ?? ''),
  );

  return (
    recentContents
      // Filter out local files
      .filter((content) => !shouldIgnoreRecentContent(content.url))
      // Only show data from connectors the user has connected or data
      // from browsing history.
      .filter(
        (content) =>
          ('connectorName' in content.connectorInfo &&
            validConnectorNames.has(content.connectorInfo.connectorName)) ||
          content.connectorInfo.type === 'browser_history' ||
          content.connectorInfo.type === 'upsell',
      )
  );
}

export function shouldIgnoreRecentContent(url: string) {
  const ignoredProtocols = [
    'file:',
    'chrome:',
    'edge:',
    'about:',
    'chrome-extension:',
  ];
  return ignoredProtocols.some((protocol) => url.startsWith(protocol));
}

export function useTopContentByConnector(
  connectors: dash_connectors.Connector[],
) {
  const { content, isLoading } = useRecentEntities();

  const topContentByConnector = useMemo(() => {
    const recentConnectorContent = filterRecentContents(content, connectors);
    const MAX_CONNECTORS = 2;

    const topContentByConnector = _.chain(recentConnectorContent)
      .uniqBy('url')
      .uniqBy('title')
      .filter((c) => 'connectorName' in c.connectorInfo)
      .groupBy((c) => (c as RecentConnectorContent).connectorInfo.connectorName)
      .entries()
      .orderBy(([, content]) => content.length, 'desc')
      .take(MAX_CONNECTORS)
      .keyBy(([connectorName]) => connectorName)
      .value();

    return connectors
      .filter(
        (connector) =>
          isValidConnectorForRecentContents(connector) &&
          connector.id_attrs !== undefined &&
          connector.id_attrs.type !== undefined &&
          topContentByConnector[connector.id_attrs.type],
      )
      .map((connector) => {
        const key = connector.id_attrs?.type ?? '';
        return {
          key,
          // Potentially add account name here if multi-account?
          name: connector.branding?.display_name ?? '',
          content: topContentByConnector[key]?.[1] ?? [],
        };
      });
  }, [content, connectors]);

  return { connectors, content, isLoading, topContentByConnector };
}
