import KeyboardShortcutListItem from './components/list-items/keyboard-shortcuts/KeyboardShortcutListItem';
import ListContainer from './components/list-items/ListContainer';

import type { AvatarSettings } from '@mirage/service-settings/service/types';

type Props = Readonly<{
  shortcutItems: string[][];
  // shortcuts are of any type because the shape varies between OSes.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shortcuts: any;
  platform: string;
  settings: AvatarSettings;
  setSetting: (
    settingName: keyof AvatarSettings,
    value?: number | string,
  ) => void;
}>;

export default function KeyboardSettings({
  platform,
  setSetting,
  settings,
  shortcutItems,
  shortcuts,
}: Props) {
  return (
    <ListContainer items={shortcutItems} height="auto" flex="1 1 auto">
      {(item) => (
        <KeyboardShortcutListItem
          key={`${item[0]}-${item[1]}`}
          shortcutItem={item}
          shortcuts={shortcuts}
          platform={platform}
          settings={settings}
          setSetting={setSetting}
        />
      )}
    </ListContainer>
  );
}
