import { callApiV2 } from '@mirage/service-dbx-api';

import type { dropbox_os } from '@dropbox/api-v2-client';
import type { TeamInfo } from '@mirage/mosaics/TeamProfilePage/types';
import type { UserInfo } from '@mirage/mosaics/UserProfilePage/types';

export async function callCompanyOSApi<T>(
  params: dropbox_os.ProxyCompanyOSApiArg,
): Promise<T | null> {
  const response = await callApiV2('dropboxOsProxyCompanyosApi', params);
  const responseBody = response.body;
  if (!responseBody) {
    return null;
  }
  const decodedBody = atob(responseBody);
  return JSON.parse(decodedBody);
}

export async function callCompanyOSTeamApi({ teamSlug }: { teamSlug: string }) {
  const params = {
    method: 'GET',
    endpoint_path: `/api/v2/team/${teamSlug}`,
  };
  return await callCompanyOSApi<TeamInfo>(params);
}

export async function callCompanyOSEmployeeApi({
  username,
}: {
  username: string;
}) {
  const params = {
    method: 'GET',
    endpoint_path: `/api/v2/employee/${username}`,
  };
  return await callCompanyOSApi<UserInfo>(params);
}
