import {
  deleteAssistApiTemplate,
  loadAssistApiTemplates,
  loadAssistTemplates as loadLocalAssistTemplates,
  saveAssistApiTemplate,
  saveAssistTemplates,
} from '@mirage/service-compose';
import { tagged } from '@mirage/service-logging';
import { createDefaultTemplates } from '@mirage/shared/compose/assist-template';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type {
  AssistCustomTemplate,
  DefaultTemplate,
} from '@mirage/shared/compose/assist-template';

const logger = tagged('AssistTemplatesContext');

export interface AssistTemplatesContextInterface {
  templates: AssistCustomTemplate[] | undefined;
  saveTemplate: (template: AssistCustomTemplate) => Promise<void | string>;
  deleteTemplate: (templateId: string) => Promise<void>;
  defaultTemplates: DefaultTemplate[];
}

export const AssistTemplatesContext =
  createContext<AssistTemplatesContextInterface | null>(null);

export const useAssistTemplatesContext = () => {
  const context = useContext(AssistTemplatesContext);
  if (!context) {
    throw new Error(
      'useAssistTemplatesContext must be used within an AssistTemplatesContextProvider',
    );
  }
  return context;
};

interface AssistTemplatesContextProviderProps {
  children: React.ReactNode;
}

export const AssistTemplatesContextProvider = ({
  children,
}: AssistTemplatesContextProviderProps) => {
  const defaultTemplates = useMemo(() => createDefaultTemplates(), []);
  const [templates, setTemplates] = useState<
    AssistCustomTemplate[] | undefined
  >(undefined);
  useEffect(() => {
    async function loadTemplates() {
      const localTemplates = await loadLocalAssistTemplates();
      if (localTemplates.length) {
        await Promise.all(localTemplates.map(saveAssistApiTemplate))
          .then(() => {
            saveAssistTemplates([]);
            return;
          })
          .catch();
      }
      const apiTemplates = await loadAssistApiTemplates();
      setTemplates(apiTemplates);
    }
    loadTemplates();
  }, []);

  const saveTemplate = useCallback((template: AssistCustomTemplate) => {
    return new Promise<void | string>((resolve) => {
      setTemplates((prevTemplates) => {
        if (prevTemplates === undefined) {
          logger.warn('attempted to save template before loading completes');
          resolve();
          return prevTemplates;
        }

        // update template if it already exists, otherwise add it to the array
        let found = false;
        const updatedTemplates = prevTemplates.map((t) => {
          if (t.id === template.id) {
            found = true;
            return template;
          }
          return t;
        });
        if (!found) {
          updatedTemplates.push(template);
        }
        saveAssistApiTemplate(template)
          .then((t) => {
            resolve(t?.id);
          })
          .catch(() => {
            resolve(undefined);
          });
        return updatedTemplates;
      });
    });
  }, []);

  const deleteTemplate = useCallback((templateId: string) => {
    return new Promise<void>((resolve) => {
      setTemplates((prevTemplates) => {
        if (prevTemplates === undefined) {
          logger.warn('attempted to delete template before loading completes');
          resolve();
          return prevTemplates;
        }

        const template = prevTemplates.find((t) => t.id === templateId);
        if (template) {
          deleteAssistApiTemplate(template)
            .then(() => {
              return resolve();
            })
            .catch();
        } else {
          resolve();
        }

        const updatedTemplates = prevTemplates.filter(
          (t) => t.id !== templateId,
        );
        return updatedTemplates;
      });
    });
  }, []);

  return (
    <AssistTemplatesContext.Provider
      value={{ templates, saveTemplate, deleteTemplate, defaultTemplates }}
    >
      {children}
    </AssistTemplatesContext.Provider>
  );
};
