import { callApiV2 } from '@mirage/service-dbx-api';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { useCallback, useEffect, useState } from 'react';

import type {
  dash_connector_metadata,
  dash_connectors,
} from '@dropbox/api-v2-client';

export const DATA_EXCLUSION_CONNECTOR_IDS: Record<string, boolean> = {
  googledrive: true,
  onedrive: true,
};

type Exclusions = Record<string, dash_connectors.ConnectionAccessControl>;

const fetchAccessControlLists = getCombineAsyncRequestsFunc(
  async (
    api: typeof callApiV2,
    request: dash_connector_metadata.ListAccessControlsAPIv2Arg,
  ): Promise<dash_connector_metadata.ListAccessControlsAPIv2Response> => {
    return await api('dashConnectorMetadataListAccessControls', request);
  },
);

export const toExclusions = (
  results: dash_connector_metadata.ListAccessControlsAPIv2Response,
): Exclusions => {
  const exclusions: Exclusions = {};

  if (!results.access_controls) {
    return exclusions;
  }

  results.access_controls.forEach((accessControl) => {
    if (!accessControl.connection_id) {
      return;
    }
    exclusions[accessControl.connection_id] = accessControl;
  });

  return exclusions;
};

export const filterDataExclusionSetupIncomplete = (
  hasFetchedExclusions: boolean,
  isDataExclusionEnabled: boolean,
  exclusions: Exclusions,
) => {
  return (connection: dash_connectors.Connection) => {
    if (!hasFetchedExclusions) {
      return false;
    }

    if (!isDataExclusionEnabled) {
      return true;
    }

    if (typeof connection.id_attributes?.id === 'undefined') {
      return true;
    }

    if (typeof connection.id_attributes?.connector?.type === 'undefined') {
      return false;
    }

    if (
      !DATA_EXCLUSION_CONNECTOR_IDS[connection.id_attributes.connector.type]
    ) {
      return true;
    }

    return connection.id_attributes.id in exclusions;
  };
};

export const useDataExclusion = (): {
  isDataExclusionEnabled: boolean;
  hasFetchedExclusions: boolean;
  filterDataExclusionSetupIncomplete: (
    connection: dash_connectors.Connection,
  ) => boolean;
} => {
  const isDataExclusionEnabled = convertFeatureValueToBool(
    useFeatureFlagValue(
      'dash_admin_experience_2025_02_19_content_exclusion_feature',
    ),
  );
  const [hasFetchedExclusions, setHasFetchedExclusions] = useState(false);
  const [exclusions, setExclusions] = useState<Exclusions>({});

  const getAccessControlLists = async () => {
    return fetchAccessControlLists(callApiV2, {});
  };

  const filterFn = useCallback(
    (connection: dash_connectors.Connection) => {
      return filterDataExclusionSetupIncomplete(
        hasFetchedExclusions,
        isDataExclusionEnabled,
        exclusions,
      )(connection);
    },
    [hasFetchedExclusions, isDataExclusionEnabled, exclusions],
  );

  useEffect(() => {
    if (!isDataExclusionEnabled) {
      setExclusions({});
      setHasFetchedExclusions(true);
    }
    if (isDataExclusionEnabled && !hasFetchedExclusions) {
      getAccessControlLists()
        .then((data) => {
          setExclusions(toExclusions(data));
        })
        .catch((_err) => {
          setExclusions({});
        })
        .finally(() => setHasFetchedExclusions(true));
      return;
    }
  }, [isDataExclusionEnabled, hasFetchedExclusions]);

  return {
    isDataExclusionEnabled,
    hasFetchedExclusions,
    filterDataExclusionSetupIncomplete: filterFn,
  };
};
