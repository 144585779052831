import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type {
  FeatureFlag,
  FeatureName,
  FeatureValue,
} from '@mirage/service-experimentation/features';
import type {
  ExperimentationAttributes,
  ExperimentationService,
} from '@mirage/service-experimentation/service';

export type { ExperimentationAttributes };

export const service = services.get<ExperimentationService>(
  ServiceId.EXPERIMENTATION,
);

export function setExperimentationAttributes(
  attributes: ExperimentationAttributes,
): Promise<void> {
  return rx.firstValueFrom(service.setExperimentationAttributes(attributes));
}

export function getCachedFlags(): Promise<FeatureFlag[]> {
  return rx.firstValueFrom(service.getCachedFlags());
}

export function refreshAllFeatureValues(): Promise<FeatureFlag[]> {
  return rx.firstValueFrom(service.refreshAllFeatureValues());
}

export function clearCachedFeatureValues(): Promise<void> {
  return rx.firstValueFrom(service.clearCachedFeatureValues());
}

export function getAllCachedOrFetchFeatureValues(): Promise<FeatureFlag[]> {
  return rx.firstValueFrom(service.getAllCachedOrFetchFeatureValues());
}

export async function getCachedOrFetchFeatureValue(
  featureName: FeatureName,
): Promise<FeatureValue> {
  return rx.firstValueFrom(service.getCachedOrFetchFeatureValue(featureName));
}

export async function setFeatureOverride(
  featureName: FeatureName,
  overrideValue: FeatureValue,
): Promise<void> {
  return rx.firstValueFrom(
    service.setFeatureOverride(featureName, overrideValue),
  );
}

export async function resetAllFeatureOverrides(): Promise<void> {
  return rx.firstValueFrom(service.resetAllFeatureOverrides());
}

export function startSyncFeatureFlags() {
  return rx.firstValueFrom(service.startSyncFeatureFlags());
}

export function cancelSyncFeatureFlags() {
  return rx.firstValueFrom(service.cancelSyncFeatureFlags());
}

export function startSyncFeatureMemCache(featureNames: FeatureName[]) {
  return rx.firstValueFrom(service.startSyncFeatureMemCache(featureNames));
}

export function cancelSyncFeatureMemCache() {
  return rx.firstValueFrom(service.cancelSyncFeatureMemCache());
}

export const listenForFeatures = service.listenForFeatures;
