import { FolderLine } from '@dropbox/dig-icons/assets';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  getAppDisplayNameFromResult,
  getPersonForMetadata,
  getTimeAgoStringFromTimestamp,
} from '../util/resultUtil';
import { MetadataItem } from './MetadataItem';
import { PersonMetadata } from './PersonMetadata';

import type { SearchResult } from '@mirage/service-dbx-api';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

type DefaultMetadataProps = {
  result: SearchResult | Recommendation;
  onClickPerson?: (person: PersonObject) => void;
  activeFilters?: SearchFilter[];
};

export const DefaultMetadata = ({
  result,
  onClickPerson,
  activeFilters,
}: DefaultMetadataProps) => {
  const {
    providerUpdateAtMs,
    parent,
    sender,
    creator,
    lastModifier,
    filteredModifier,
  } = result;
  const isMobileSize = useIsMobileSize();
  const appName = getAppDisplayNameFromResult(result);
  const timeAgoString = getTimeAgoStringFromTimestamp(
    filteredModifier?.modifiedAtMs ?? providerUpdateAtMs,
    true,
  );
  const parentDisplayName = parent?.displayName;

  const renderPersonMetadata = () => {
    const { person, i18nKey } = getPersonForMetadata({
      sender,
      creator,
      lastModifier,
      activeFilters,
      filteredModifier,
    });

    if (!i18nKey || !person) {
      return null;
    }

    return (
      <MetadataItem
        title={
          <PersonMetadata
            i18nKey={i18nKey}
            person={person}
            onClickPerson={onClickPerson}
          />
        }
      />
    );
  };

  return (
    <>
      {appName && <MetadataItem title={appName} />}
      {parentDisplayName && (
        <MetadataItem title={parentDisplayName} iconSrc={FolderLine} />
      )}
      {!isMobileSize && renderPersonMetadata()}
      {timeAgoString && <MetadataItem title={timeAgoString} />}
    </>
  );
};
