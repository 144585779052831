// This hook is used to navigate to the Conversation Assistant page

import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// TEMP hook to control what page we navigate to
export const useChatNavigation = () => {
  const navigate = useNavigate();
  const isConversationAssistantEnabled = useFeatureFlagValueBool(
    'dash_2025_02_11_conversation_assistant',
  );

  const navigateToChatOrConversationAssistant = useCallback(() => {
    if (isConversationAssistantEnabled) {
      navigate(RoutePath.CONVERSATION);
    } else {
      navigate(RoutePath.CHAT);
    }
  }, [isConversationAssistantEnabled, navigate]);

  return {
    navigateToChatOrConversationAssistant,
  };
};
