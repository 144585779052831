import type { GlobalNavTab } from './types';
import type { FeatureLine } from '@mirage/analytics/events/enums/feature_line';

export function getFeatureLine(navTarget: GlobalNavTab): FeatureLine | 'n_a' {
  switch (navTarget) {
    case 'chat':
      return 'answers';
    case 'stacks':
      return 'stacks';
  }
  return 'n_a';
}
