import { LabelGroup } from '@dropbox/dash-component-library';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { IconButtonGroup } from '@dropbox/dig-components/combinations';
import { Drawer } from '@dropbox/dig-components/drawer';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CloseLine,
  CreateStackLine,
  InfoLine,
  LinkLine,
} from '@dropbox/dig-icons/assets';
import { PAP_Click_DashMiltimediaDrawerActionButton } from '@mirage/analytics/events/types/click_dash_miltimedia_drawer_action_button';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { reportPapEvent } from '@mirage/service-product-logging';
import {
  isDesktopLocalFile,
  type SearchResult,
} from '@mirage/shared/search/search-result';
import { showSnackbar } from '@mirage/shared/snackbar';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { useShowStackChooserModal } from '@mirage/stacks/StackChooser/StackChooser';
import i18n from '@mirage/translations';
import { searchSessionManager } from '@mirage/webapp/analytics/sessionManagers/sessionManagers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMultimediaPreview } from './hooks/useMultimediaPreview';
import { MediaResultExtendedMetadata } from './MediaResultExtendedMetadata';
import styles from './MultimediaResultModal.module.css';

export const MultimediaResultModal = ({
  result,
  onRequestClose,
}: {
  result: SearchResult;
  onRequestClose: () => void;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [imageUrl, setImageUrl] = useState<string>();

  const modalRef = useRef(null);

  const { base64Image, base64Loading, hasError } = useMultimediaPreview(
    result.previewUrl || '',
  );

  const handleCopyResult = () => {
    copyToClipboard(result?.url || '');
    showSnackbar({
      title: i18n.t('copied_link_to_clipboard'),
      parentElement: modalRef.current ?? undefined,
    });

    reportPapEvent(
      PAP_Click_DashMiltimediaDrawerActionButton({
        resultUuid: result?.uuid,
        miltimediaActionType: 'copy_link',
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  };

  const navigate = useNavigate();
  const showStackChooserModal = useShowStackChooserModal(navigate);

  const handleAddToStack = () => {
    if (!result.url || isDesktopLocalFile(result)) {
      return;
    }

    showStackChooserModal({
      metadataTitle: result.title,
      url: result.url,
      brandedType: result.brandedType || undefined,
      id3p: result.id3p || undefined,
      callback: () => {
        showSnackbar({
          title: i18n.t('new_stack_item_added'),
          parentElement: modalRef.current ?? undefined,
        });
      },
    });
  };

  const resultTitle = useMemo(() => {
    const title = result.title;
    const lastDotIndex = title.lastIndexOf('.');

    if (lastDotIndex === -1 || lastDotIndex === 0) {
      return { name: title, extension: '' };
    }

    const name = title.slice(0, lastDotIndex);
    const extension = title.slice(lastDotIndex + 1);

    return { name, extension };
  }, [result.title]);

  useEffect(() => {
    setImageUrl(base64Image);
  }, [base64Image]);

  const escapeKeyPressListener = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onRequestClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', escapeKeyPressListener, false);
    return () => {
      document.removeEventListener('keyup', escapeKeyPressListener, false);
    };
  }, [escapeKeyPressListener]);

  return (
    <Modal open fullScreen>
      <Modal.Header
        hasBottomSpacing="title-standard"
        className={styles.modalHeader}
      >
        <Modal.Title className={styles.modalTitle}>
          <div className={styles.modalHeaderContainer}>
            <IconButton
              variant="borderless"
              size="medium"
              onClick={onRequestClose}
            >
              <UIIcon src={CloseLine} />
            </IconButton>
            <div className={styles.dividerVertical} />
            <div className={styles.titleContainer}>
              <Title size="small" isBold>
                {resultTitle.name}
              </Title>
              <Text
                size="small"
                color="subtle"
                className={styles.filenameExtension}
              >
                {resultTitle.extension}
              </Text>
            </div>
            <div className={styles.actionsContainer}>
              <IconButtonGroup size="medium">
                {() => (
                  <>
                    <DigTooltip title={i18n.t('copy_link')} openDelay={250}>
                      <IconButton
                        variant="borderless"
                        shape="standard"
                        size="medium"
                        tone="neutral"
                        onClick={handleCopyResult}
                      >
                        <UIIcon src={LinkLine} />
                      </IconButton>
                    </DigTooltip>
                    <DigTooltip title={i18n.t('add_to_stack')} openDelay={250}>
                      <IconButton
                        variant="borderless"
                        shape="standard"
                        size="medium"
                        tone="neutral"
                        onClick={handleAddToStack}
                      >
                        <UIIcon src={CreateStackLine} />
                      </IconButton>
                    </DigTooltip>
                    {/* <DigTooltip title={i18n.t('download')} openDelay={250}>
                    <IconButton
                      variant="borderless"
                      shape="standard"
                      size="medium"
                      tone="neutral"
                    >
                      <UIIcon src={DownloadLine} />
                    </IconButton>
                    </DigTooltip> */}
                    <DigTooltip title={i18n.t('info')} openDelay={250}>
                      <IconButton
                        variant="borderless"
                        shape="standard"
                        size="medium"
                        tone="neutral"
                        onClick={() => setDrawerOpen((prev) => !prev)}
                      >
                        <UIIcon src={InfoLine} />
                      </IconButton>
                    </DigTooltip>
                  </>
                )}
              </IconButtonGroup>
              <Button
                variant="primary"
                size="medium"
                tone="neutral"
                href={result.url ?? ''}
                target="_blank"
              >
                {i18n.t('open_file')}
              </Button>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>
        <div ref={modalRef}></div>
        <Drawer.InlineFrame
          withDrawer={
            <Drawer.InlineDrawer
              isOpen={drawerOpen}
              aria-label="Multimedia Result"
              alignment="right"
              width="400px"
            >
              <Drawer.Container>
                <Drawer.Header isBorderHidden>
                  <Drawer.Layout>
                    <Drawer.LayoutItem width="fill">
                      <LabelGroup
                        withStartAccessory={<UIIcon src={InfoLine} />}
                        direction="vertical"
                        withLabel={
                          <div className={styles.infoButton}>
                            {i18n.t('info')}
                          </div>
                        }
                      />
                    </Drawer.LayoutItem>
                    <Drawer.LayoutItem shift="right">
                      <IconButtonGroup>
                        {({ getButtonProps }) => (
                          <>
                            <IconButton
                              {...getButtonProps()}
                              variant="borderless"
                              onClick={() => setDrawerOpen(false)}
                            >
                              <UIIcon src={CloseLine} />
                            </IconButton>
                          </>
                        )}
                      </IconButtonGroup>
                    </Drawer.LayoutItem>
                  </Drawer.Layout>
                </Drawer.Header>
                <Drawer.Body>
                  <div className={styles.drawerBodyContent}>
                    <MediaResultExtendedMetadata result={result} hideTitle />
                  </div>
                </Drawer.Body>
              </Drawer.Container>
            </Drawer.InlineDrawer>
          }
        >
          <div className={styles.previewContainer}>
            {hasError && !base64Loading && (
              <Text variant="label" size="large" color="subtle">
                {i18n.t('image_preview_error')}
              </Text>
            )}
            {!hasError && !base64Loading && result.previewUrl && (
              <img
                className={styles.previewImage}
                src={imageUrl}
                alt={result.title}
              />
            )}
          </div>
        </Drawer.InlineFrame>
      </Modal.Body>
    </Modal>
  );
};
