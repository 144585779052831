import { Chip } from '@dropbox/dig-components/chip';
import { Select } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { OnboardingSurveyQuestionFieldType } from '@mirage/service-onboarding-surveys/service/types';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import styles from './ProfilingQuestions.module.css';

import type {
  OnboardingSurveyAnswer,
  OnboardingSurveyQuestion,
} from '@mirage/service-onboarding-surveys/service/types';

type ProfilingQuestionProps = {
  question: OnboardingSurveyQuestion;
  value: OnboardingSurveyAnswer | undefined;
  onChange: (value: OnboardingSurveyAnswer) => void;
};

const ProfilingQuestionContainer = ({
  question,
  value,
  onChange,
}: ProfilingQuestionProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.profilingQuestion}
    >
      <Text variant="label" size="large" isBold>
        {question.label}
      </Text>
      <ProfilingQuestion
        question={question}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
};

const ProfilingQuestion = ({
  question,
  value,
  onChange,
}: ProfilingQuestionProps) => {
  const handleValueChange = useCallback(
    (val: string) => {
      onChange({
        surveyQuestionId: question.questionId,
        surveyAnswer: val,
      });
    },
    [onChange, question.questionId],
  );

  switch (question.questionType) {
    case OnboardingSurveyQuestionFieldType.ChipList:
      return (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          className={styles.questionsContainer}
        >
          {question.questionValues?.map((q) => {
            return (
              <Chip
                onClick={() => handleValueChange(q.value)}
                key={q.label}
                isSelected={value?.surveyAnswer === q.value}
              >
                <Chip.Content>{q.label}</Chip.Content>
              </Chip>
            );
          })}
        </Box>
      );
    case OnboardingSurveyQuestionFieldType.Select:
      return (
        <Select
          id={question.questionId}
          size="large"
          placeholder={i18n.t('jtbd_type_of_work_placeholder')}
          value={value?.surveyAnswer}
          onChange={(val: string) => handleValueChange(val)}
        >
          {question.questionValues?.map((q) => {
            return (
              <Select.Option key={q.label} value={q.value}>
                {q.label}
              </Select.Option>
            );
          })}
        </Select>
      );
    default:
      return null;
  }
};
type Props = {
  questions: OnboardingSurveyQuestion[];
  values: OnboardingSurveyAnswer[];
  onChange: (values: OnboardingSurveyAnswer[]) => void;
  skipRemaining?: boolean;
};

export const ProfilingQuestions = ({
  questions,
  values,
  onChange,
  skipRemaining = false,
}: Props) => {
  const valuesMap = useMemo(
    () =>
      new Map(
        values
          .filter((v) => v.surveyQuestionId !== undefined)
          .map((v) => [v.surveyQuestionId as string, v]),
      ),
    [values],
  );

  const answeredQuestions = values.filter(
    (item) => item.surveyAnswer !== undefined && item.surveyAnswer !== '',
  ).length;

  const handleOnboardingSurveyAnswerChange = useCallback(
    (value: OnboardingSurveyAnswer) => {
      const newValues = [
        ...values.filter((v) => v.surveyQuestionId !== value.surveyQuestionId),
        value,
      ];
      onChange(newValues);
    },
    [onChange, values],
  );

  return (
    <>
      {questions
        .slice(0, skipRemaining ? answeredQuestions : questions.length) // Skip remaining if enabled
        .map((q, index) => (
          <div
            key={q.questionId}
            data-testid="profiling-question"
            className={classnames(styles.profilingQuestionContainer, {
              [styles.visible]: answeredQuestions >= index,
            })}
          >
            <ProfilingQuestionContainer
              question={q}
              value={valuesMap.get(q.questionId)}
              onChange={(value) => handleOnboardingSurveyAnswerChange(value)}
            />
          </div>
        ))}
    </>
  );
};
