import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_AddSourcesButton } from '@mirage/analytics/events/types/click_add_sources_button';
import {
  ChatGrid,
  ChatPageWrapper,
} from '@mirage/mosaics/Chat/components/layouts/ChatPageLayout';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { memo, useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { ConversationChat } from './ConversationChat';

import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { WorkflowAgentRouteState } from '@mirage/service-conversation/types';
export interface ConversationAssistantLocationState {
  providedSources: ChatSource[];
}

export const ConversationAssistantPage = memo(() => {
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { dashActionSurface } = useDashActionSurface();
  const [searchParams] = useSearchParams();
  const { state }: { state?: WorkflowAgentRouteState } = useLocation();
  const queryParamId = searchParams.get('conversationId');
  const { documentIds, model, agentId } = state || {};

  const [conversationId, setConversationId] = useState<string | undefined>(
    queryParamId || undefined,
  );

  useEffect(() => {
    if (!conversationId) {
      setConversationId(v4());
    }
  }, [conversationId]);

  const handleClickAdd = useCallback(() => {
    setIsAddSourceModalOpen(true);
    reportPapEvent(
      PAP_Click_AddSourcesButton({
        actionType: 'chat_pane',
        actionSurface: dashActionSurface,
        dashActionSurface,
        actionSurfaceComponent: 'compose_chat_pane',
        featureLine: 'assist',
      }),
    );
  }, [dashActionSurface, reportPapEvent, setIsAddSourceModalOpen]);

  const isConversationAssistantEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2025_02_11_conversation_assistant'),
  );

  if (!isConversationAssistantEnabled) {
    return <Navigate to={RoutePath.CHAT} replace />;
  }

  if (!conversationId) {
    return null;
  }

  return (
    <ChatPageWrapper>
      <ChatGrid
        disableRightPane
        logChatEvent={() => {
          // TODO logging
        }}
        sidePane={
          <ConversationChat
            variant="default"
            conversationId={conversationId}
            handleClickAdd={handleClickAdd}
            setIsAddSourceModalOpen={setIsAddSourceModalOpen}
            isAddSourceModalOpen={isAddSourceModalOpen}
            documentIds={documentIds}
            model={model}
            agentId={agentId}
          />
        }
      />
    </ChatPageWrapper>
  );
});

ConversationAssistantPage.displayName = 'ConversationAssistantPage';
