import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import styles from './SearchSpellingSuggestion.module.css';

import type { AnnotationToken } from '@mirage/shared/search/search-result';
import type { KeyboardEvent, KeyboardEventHandler } from 'react';

type SearchSpellingSuggestionProps = {
  tokens: AnnotationToken[];
  correctedQuery: string;
  originalQuery: string;
  onClickOriginalQuery: () => void;
  onClickCorrectedQuery: () => void;
};

export function SearchSpellingSuggestion({
  tokens,
  correctedQuery,
  originalQuery,
  onClickOriginalQuery,
  onClickCorrectedQuery,
}: SearchSpellingSuggestionProps) {
  const handleKeyDown = useCallback(
    (callback: () => void): KeyboardEventHandler<HTMLSpanElement> => {
      return (event: KeyboardEvent) => {
        if (event.key === KeyCodes.enter) {
          event.preventDefault();
          callback();
        }
      };
    },
    [],
  );

  const renderHighlightedQuery = (query: string, isCorrected: boolean) => {
    const parts = query.split(' ');
    const result = [];

    for (let i = 0; i < parts.length; i++) {
      if (tokens[i].token !== tokens[i].originalToken) {
        result.push(
          <span key={i} style={{ fontStyle: 'italic' }}>
            {tokens[i][isCorrected ? 'token' : 'originalToken']}
          </span>,
        );
      } else {
        result.push(<span key={i}>{tokens[i].token}</span>);
      }

      if (i < parts.length - 1) {
        result.push(' ');
      }
    }

    // TODO: Could re-add truncating logic here if needed

    return <>{result}</>;
  };

  return (
    <Box className={styles.spellingSuggestionContainer}>
      <Text
        variant="paragraph"
        color="standard"
        size="small"
        className={styles.spellingSuggestionText}
      >
        {i18n.t('spelling_suggestion_showing_results_for')}
        <span
          role="button"
          aria-label={i18n.t('spelling_suggestion_showing_results_for_label')}
          onClick={onClickCorrectedQuery}
          onKeyDown={handleKeyDown(onClickCorrectedQuery)}
          tabIndex={0}
          className={styles.spellingSuggestionButton}
        >
          {renderHighlightedQuery(correctedQuery, true)}
        </span>
      </Text>
      <Text
        variant="paragraph"
        color="standard"
        size="small"
        className={styles.spellingSuggestionText}
      >
        {i18n.t('spelling_suggestion_search_instead_for')}
        <span
          role="button"
          aria-label={i18n.t('spelling_suggestion_search_instead_for_label')}
          onClick={onClickOriginalQuery}
          onKeyDown={handleKeyDown(onClickOriginalQuery)}
          tabIndex={0}
          className={styles.spellingSuggestionButton}
        >
          {renderHighlightedQuery(originalQuery, false)}
        </span>
      </Text>
    </Box>
  );
}
