/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */
import type { ReactText } from "react";
import { useState } from "react";

import { SegmentedControl } from "@dropbox/dig-components/controls";
import { Title } from "@dropbox/dig-components/typography";
import { EnvCtx } from "@mirage/service-environment-context/global-env-ctx";
import { useFeatureFlagValue } from "@mirage/service-experimentation/useFeatureFlagValue";
import {
  isProdBuildChannel,
  isStagingBuildChannel,
} from "@mirage/shared/util/tiny-utils";
import { isInsideExtension } from "@mirage/shared/util/webExtensionsHelpers";

import {
  checkAuthCookieAndRedirect,
  EnvCookieValue,
  getWebEnvCookie,
  setWebEnvCookie,
} from "../shared/webCookies";

import styles from "./EnvironmentSwitcher.module.css";

export const EnvironmentSwitcher: React.FC = () => {
  const [environment, setEnvironment] = useState<EnvCookieValue | undefined>(
    getWebEnvCookie,
  );
  const isProd = isProdBuildChannel(EnvCtx.buildChannel);
  const isStaging = isStagingBuildChannel(EnvCtx.buildChannel);
  const enableRedirectDropboxersStaging = useFeatureFlagValue(
    "dash_web_2024_05_09_redirect_dropboxers_staging",
  );

  const handleSelect = (value?: ReactText) => {
    const cookieValue = String(value) as EnvCookieValue;
    setEnvironment(cookieValue);
    setWebEnvCookie(cookieValue);
    checkAuthCookieAndRedirect();
  };

  const showEnvironmentSwitcher =
    (isProd || isStaging) && enableRedirectDropboxersStaging === "ON";

  if (!showEnvironmentSwitcher || isInsideExtension()) {
    return null;
  }

  return (
    <div>
      <Title size="small">Environment Switch</Title>
      <hr className={styles.horizontalSeparator} />
      <SegmentedControl selectedValue={environment} onSelection={handleSelect}>
        <SegmentedControl.Option variant="text" value={EnvCookieValue.STAGING}>
          Staging
        </SegmentedControl.Option>
        <SegmentedControl.Option variant="text" value={EnvCookieValue.PROD}>
          Prod
        </SegmentedControl.Option>
      </SegmentedControl>
      <hr className={styles.horizontalSeparator} />
    </div>
  );
};
