import { metadataForUrls } from '@mirage/service-url-metadata';
import { useEffect, useMemo, useState } from 'react';

import type { UrlMetadata } from '../types';

export const useMetadataForUrls = (urls: string[]) => {
  // Stringify the urls to ensure that we don't make any excess api
  // calls if the caller doesn't pass in an array that's already memoized
  const stringifiedUrls = JSON.stringify(urls);
  const strippedUrls = useMemo(() => {
    const unwrappedUrls: string[] = JSON.parse(stringifiedUrls);
    return unwrappedUrls.map((url) => ({ url: url.split('#')[0] }));
  }, [stringifiedUrls]);

  const [urlMetadata, setUrlMetadata] = useState<
    { [key: string]: UrlMetadata } | undefined
  >(undefined);

  useEffect(() => {
    const sub = metadataForUrls(strippedUrls).subscribe(setUrlMetadata);

    return () => {
      sub.unsubscribe();
    };
  }, [strippedUrls]);

  return urlMetadata;
};
