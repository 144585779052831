import { useComposeSourcesCache } from '@mirage/mosaics/Chat/data/ComposeSourcesCache';
import { getSourceUUID } from '@mirage/shared/compose/compose-session';
import { createContext, useCallback, useContext, useState } from 'react';

import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { ReactNode } from 'react';

// Type for the sources cache
export interface SourcesCache {
  [uuid: string]: ChatSource;
}

interface SourcesCacheContextType {
  sourcesContentCache: ReturnType<typeof useComposeSourcesCache>;
  allSources: ChatSource[];
  updateSources: (sources: ChatSource[]) => void;
  getSourceById: (id: string) => ChatSource | undefined;
}

const SourcesCacheContext = createContext<SourcesCacheContextType | undefined>(
  undefined,
);

export const SourcesCacheProvider = ({ children }: { children: ReactNode }) => {
  // Keep track of all sources that have been added
  const [allSources, setAllSources] = useState<ChatSource[]>([]);

  // Use the existing hook to manage the content cache
  const sourcesContentCache = useComposeSourcesCache(allSources);

  // Update the sources collection
  const updateSources = useCallback((newSources: ChatSource[]) => {
    setAllSources((prevSources) => {
      // Create a map of existing sources by UUID for quick lookup
      const existingSourcesMap = new Map<string, ChatSource>();
      prevSources.forEach((source) => {
        const uuid = getSourceUUID(source);
        if (uuid) {
          existingSourcesMap.set(uuid, source);
        }
      });

      // Add new sources that don't already exist
      const updatedSources = [...prevSources];
      newSources.forEach((source) => {
        const uuid = getSourceUUID(source);
        if (uuid && !existingSourcesMap.has(uuid)) {
          updatedSources.push(source);
        }
      });

      return updatedSources;
    });
  }, []);

  // Get a source by its ID
  const getSourceById = useCallback(
    (id: string) => {
      return allSources.find((source) => {
        const uuid = getSourceUUID(source);
        return uuid === id;
      });
    },
    [allSources],
  );

  const value = {
    sourcesContentCache,
    allSources,
    updateSources,
    getSourceById,
  };

  return (
    <SourcesCacheContext.Provider value={value}>
      {children}
    </SourcesCacheContext.Provider>
  );
};

export const useSourcesCache = (): SourcesCacheContextType => {
  const context = useContext(SourcesCacheContext);
  if (context === undefined) {
    throw new Error(
      'useSourcesCache must be used within a SourcesCacheProvider',
    );
  }
  return context;
};
