import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Finish_DashGettingStartedChecklistItem } from '@mirage/analytics/events/types/finish_dash_getting_started_checklist_item';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { markChecklistItemComplete } from '@mirage/service-onboarding-checklist';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { OnboardingChecklistDirtyAtom } from './checklist-atoms';

import type {
  OnboardingChecklistStep,
  OnboardingChecklistStepPartial,
} from '../types';
import type { DashGettingStartedChecklistItem } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item';

export function useMarkChecklistItemComplete(
  checklistItems: OnboardingChecklistStep[] | undefined,
  checklistSteps: OnboardingChecklistStepPartial[],
) {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const setIsDirty = useSetAtom(OnboardingChecklistDirtyAtom);

  const markComplete = useCallback(
    async (moduleId: string) => {
      // Desktop does not currently support the getting started checklist
      if (EnvCtx?.surface === 'desktop') return false;

      const moduleIndex = checklistSteps.findIndex(
        (m) => m.moduleId === moduleId,
      );
      const module = checklistSteps[moduleIndex];
      if (!module) return false;

      const checklistItem = checklistItems?.find(
        (c) => c.moduleId === moduleId,
      );
      if (checklistItem?.isComplete) {
        return;
      }

      const success = await markChecklistItemComplete(module.milestoneKeys);
      if (!success) return;

      setIsDirty(true);

      reportPapEvent(
        PAP_Finish_DashGettingStartedChecklistItem({
          dashGettingStartedChecklistItem:
            moduleId as DashGettingStartedChecklistItem,
          actionSurfaceComponent: 'getting_started_checklist',
          featureLine: 'getting_started_checklist',
        }),
      );
      return true;
    },
    [checklistItems, checklistSteps, reportPapEvent, setIsDirty],
  );
  return markComplete;
}
