import {
  addStackComment,
  deleteStackComment,
  editStackComment,
} from '@mirage/service-comments';
import {
  stackItemCommentAtomFamily,
  stackItemDraftCommentAtomFamily,
} from '@mirage/service-comments/atoms/stack';
import { DuplicateCommentError } from '@mirage/service-comments/utils/stack';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useStackCommentLogging } from './useStackCommentLogging';

import type { StackComment } from '@mirage/service-comments/types';

const logger = tagged('use-stack-item-comment');

export const useStackItemComment = (
  stackSharingId: string,
  stackItemId: string,
) => {
  const [comment, setComment] = useAtom(
    stackItemCommentAtomFamily(stackItemId),
  );
  const [draft, setDraft] = useAtom(
    stackItemDraftCommentAtomFamily(stackItemId),
  );
  const {
    logCommentAddSuccess,
    logCommentDeleteSuccess,
    logCommentEditSuccess,
  } = useStackCommentLogging();

  const canToggleCommentIcon = !comment && !draft;

  // Sanity check, this should not happen
  if (comment && comment?.linkId !== stackItemId) {
    const message = `[StackItemComments] found a mismatch from stackItemId [${stackItemId}] and linkId [${comment?.linkId}]`;

    // If, for whatever reason, it does, log appropriately
    logger.error(message);
    Sentry.withScope((scope) => {
      scope.setTags({
        errorMessage: message,
        stackItemId,
        linkId: comment?.linkId,
      });
      Sentry.captureMessage(message, 'error', {}, scope);
    });

    throw new Error(message);
  }

  const onCancelComment = useCallback(() => {
    setDraft(undefined);
  }, [setDraft]);

  // This does not catch errors thrown by post/editStackComment as the expectation is that the UI will handle them
  const onPostComment = useCallback(
    async (
      content: string,
      author: StackComment['author'],
      existingId?: string,
    ) => {
      // If the user bypassed the UI and tried to post an empty comment, do nothing
      if (!content) {
        return;
      }

      // If the user attempted to save an edit with no changes, do nothing
      if (existingId && comment && comment.content === content) {
        return;
      }

      // Handles the edgecase where a user attempts to save a draft when a comment already exists on the item
      if (comment && (!existingId || comment.id !== existingId)) {
        const message =
          'Attempted to save draft comment when a comment already exists on the item';

        // Log to give us an idea of how often this is happening and whether it's a potential source of user frustration
        logger.info(message);
        Sentry.captureMessage(message, 'info');

        // We throw an error here only so it can be caught and handled by the comment form
        throw new DuplicateCommentError();
      }

      let commentId: string;

      // Determine if we are editing an existing comment or adding a new one, then save it and store the resultant comment id
      if (existingId) {
        commentId = await editStackComment(
          stackSharingId,
          stackItemId,
          existingId,
          content,
        );

        logCommentEditSuccess();
      } else {
        commentId = await addStackComment(stackSharingId, stackItemId, content);

        logCommentAddSuccess();
      }

      // Set the new comment and clear the draft on success
      setComment({
        author,
        content,
        id: commentId,
        linkId: stackItemId,
        permissions: {
          canDelete: true,
          canEdit: true,
        },
        // Timestamp reflects post date and should not change on edits
        timestamp: comment ? comment.timestamp : Date.now(),
      });
      setDraft(undefined);
    },
    [
      comment,
      logCommentAddSuccess,
      logCommentEditSuccess,
      setComment,
      setDraft,
      stackItemId,
    ],
  );

  // This does not catch errors thrown by deleteStackComment as the expectation is that the UI will handle them
  const onDeleteComment = useCallback(
    async (commentId: string) => {
      // Attempt to delete the comment and clear the local state on success
      await deleteStackComment(stackSharingId, commentId);

      setComment(undefined);

      logCommentDeleteSuccess();
    },
    [logCommentDeleteSuccess, stackItemId],
  );

  const onToggleComment = useCallback(
    (currentAuthor: StackComment['author']) => {
      // If the user bypassed the UI and tried to post an additional comment, do nothing
      if (comment || draft) return;

      setDraft({
        author: currentAuthor,
        content: '',
        linkId: stackItemId,
        permissions: {
          canDelete: true,
          canEdit: true,
        },
      });
    },
    [comment, draft, setDraft],
  );

  return {
    canToggleCommentIcon,
    comment,
    draft,
    onCancelComment,
    onDeleteComment,
    onPostComment,
    onToggleComment,
  };
};
