import { Button } from '@dropbox/dig-components/buttons';
import { Split } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { TileCardGrid } from '@mirage/mosaics/TileCard';
import { ModuleHeader } from '@mirage/shared/two-column-grid/ModuleHeader';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useMemo } from 'react';
import { AllStacksList } from '../AllStacksList/AllStacksList';
import { useDynamicExpandableContent } from '../FullScreenStack/useDynamicExpandableContent';
import styles from './AllStacksV2.module.css';
import { ControlBar } from './ControlBar';
import { DraggablePinnedStackCard } from './DraggablePinnedStacks/DraggablePinnedStackCard';
import { useDraggablePinnedStacks } from './DraggablePinnedStacks/useDraggablePinnedStacks';

import type { StackFilterOption } from '@mirage/service-settings/service/types';
import type { WorkingSetCardData } from '@mirage/working-set/types';

const MAX_VISIBLE_COUNT = 6;

export const AllStacksV2 = ({
  workingSetCardData,
  pinnedSetCardData,
  filterPreference,
}: {
  workingSetCardData: WorkingSetCardData[];
  pinnedSetCardData: WorkingSetCardData[];
  filterPreference?: StackFilterOption;
}) => {
  return (
    <TwoColumnGrid
      containerRowGap="Macro Large"
      mainRowGap="Macro Small"
      headerChildren={<PinnedStacks workingSetCardData={pinnedSetCardData} />}
      mainChildren={
        <>
          <ControlBar hasNoStacks={false} />
          <AllStacksList
            workingSetCardData={workingSetCardData}
            filterPreference={filterPreference}
          />
        </>
      }
    />
  );
};

export const PinnedStacks = ({
  workingSetCardData: allPinnedStacks,
}: {
  workingSetCardData: WorkingSetCardData[];
}) => {
  const queryForCard = `div.${styles.pinnedCard}`;
  const { expanded, setExpanded, showMore, numVisible, wrapperRef } =
    useDynamicExpandableContent(queryForCard);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const {
    sortedPinnedStacks,
    movePinnedStack,
    isEnabled: isDragAndDropEnabled,
    isResettingSortKeys,
  } = useDraggablePinnedStacks(allPinnedStacks);

  const pinnedStacks = useMemo(() => {
    return sortedPinnedStacks.slice(0, 2 * MAX_VISIBLE_COUNT);
  }, [sortedPinnedStacks]);

  if (pinnedStacks.length === 0) {
    return null;
  }

  const handleToggle = () => {
    if (expanded) {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'stacks',
          dashCardType: 'pinned_stacks',
        }),
      );
    } else {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'stacks',
          dashCardType: 'pinned_stacks',
        }),
      );
    }
    setExpanded(!expanded);
  };

  return (
    <Split direction="vertical" gap="8">
      <Split.Item>
        <ModuleHeader
          title={i18n.t('pinned_stacks')}
          actions={
            (showMore || expanded) && (
              <Button variant="transparent" size="small" onClick={handleToggle}>
                {i18n.t(expanded ? 'show_less_answer' : 'show_more_answer')}
              </Button>
            )
          }
        />
      </Split.Item>
      <Split.Item>
        <TileCardGrid
          expanded={expanded}
          className={classNames(
            styles.pinnedWrapper,
            isDragAndDropEnabled && styles.tileGridWithDraggablePinnedCards,
          )}
          tileCardClassName={styles.pinnedCard}
          isLoading={false}
          ref={wrapperRef}
        >
          {sortedPinnedStacks.map((stackData, i) => (
            <DraggablePinnedStackCard
              isResettingSortKeys={isResettingSortKeys}
              key={stackData.namespaceId}
              index={i}
              movePinnedStack={movePinnedStack}
              stackData={stackData}
              isEnabled={isDragAndDropEnabled}
              // this classname needs to be applied on the outer boundary of our pinned card
              // since it is used to compute the number of visible cards by useExpandableDynamicaContent
              titleCardClassName={styles.pinnedCard}
              isDragAndDropEnabled={isDragAndDropEnabled}
              isHidden={i >= numVisible}
            />
          ))}
        </TileCardGrid>
      </Split.Item>
    </Split>
  );
};
