import remove from 'lodash/remove';

export interface BoxShadow {
  width: number;
  unit: string;
  color: string;
  isInset: boolean;
}

export interface BorderRadius {
  width: number;
  unit: string;
}

/** Returns a keyframe to scale vertically by the ratio. */
export function verticalRatioToKeyframe(ratio: number): Keyframe {
  return { scale: `1 ${ratio}` };
}

/** Returns a keyframe to scale top and bottom border radii by the ratio. */
export function ratioToVerticalBorderRadiusKeyframe(
  ratio: number,
  { width, unit }: BorderRadius,
): Keyframe {
  return {
    borderRadius: `${width}${unit} / ${width * ratio}${unit}`,
  };
}

/** Returns a keyframe to scale the width of the top and bottom edges of a shadow by the ratio. */
export function ratioToVerticalBoxShadowWidthKeyframe(
  ratio: number,
  { width, unit, color, isInset }: BoxShadow,
): Keyframe {
  return {
    boxShadow: `
      0 ${width * ratio}${unit} 0 0 ${color}${isInset ? ' inset' : ''},
      0 -${width * ratio}${unit} 0 0 ${color}${isInset ? ' inset' : ''},
      ${width}${unit} 0 0 0 ${color}${isInset ? ' inset' : ''},
      -${width}${unit} 0 0 0 ${color}${isInset ? ' inset' : ''}`,
  };
}

/** Returns a keyframe to translate vertically by the distance in pixels. */
export function translateYToKeyframe(distance: number): Keyframe {
  return { translate: `0 ${distance}px` };
}

/**
 * Returns a list of numbers to move from the start to the end in a specified number of steps. The
 * start and end are included as steps.
 *
 * ex.
 * distribute({start: 2, end: 5, stepCount: 7 }) => [2, 2.5, 3, 3.5, 4, 4.5, 5]
 */
export function distribute({
  start,
  end,
  stepCount,
}: {
  start: number;
  end: number;
  stepCount: number;
}): number[] {
  const difference = end - start;
  const stepDistance = difference / (stepCount - 1);
  const result = new Array<number>(stepCount)
    .fill(start)
    .map((val, i) => val + stepDistance * i);

  // Eliminate JS division shenanigans on the last value.
  result.pop();
  result.push(end);
  return result;
}

/** A registry of cancellation functions. */
export class Disposer {
  private readonly disposables: (() => void)[] = [];

  /** Adds a function that will be invoked when this disposer is disposed. */
  add(disposable: () => void) {
    this.disposables.push(disposable);
  }

  /** Removes a previously added disposable. */
  remove(disposable: () => void) {
    remove(this.disposables, disposable);
  }

  /** Runs all disposables and clears them from the disposer. */
  dispose() {
    for (const disposable of this.disposables) {
      disposable();
    }
    this.disposables.length = 0;
  }
}

export const cloneHTMLCollection = (input: HTMLCollection) => {
  const array: Element[] = [];
  for (let i = 0; i < input.length; i++) {
    array.push(input[i]);
  }
  return array;
};

export const cloneNodeList = (input: NodeList) => {
  const array: Node[] = [];
  for (let i = 0; i < input.length; i++) {
    array.push(input[i]);
  }
  return array;
};
