import {
  useFeatureFlagValue,
  useFeatureFlagValueBool,
} from '@mirage/service-experimentation/useFeatureFlagValue';
import { MultimediaView } from '@mirage/shared/search/search-result';

import type { SearchFilter } from '@mirage/shared/search/search-filters';

export const useMultimediaView = (filters: SearchFilter[]): MultimediaView => {
  //app.dropboxexperiment.com/features/dash_2025_03_13_multimedia_search
  // OFF = Regular filter search view
  // ON = Media flow view through FSS
  // V1 = Media flow view through Search API
  const shouldFetchMultimediaResults = useFeatureFlagValue(
    'dash_2025_03_13_multimedia_search',
  );
  //app.dropboxexperiment.com/features/dash_2025_02_12_multimedia_search
  // ON = Media flow view through FSS
  // OFF = Use shouldFetchMultimediaResults value
  const shouldFetchMultimediaResults_deprecated = useFeatureFlagValueBool(
    'dash_2025_02_12_multimedia_search',
  );
  if (
    shouldFetchMultimediaResults === 'OFF' &&
    !shouldFetchMultimediaResults_deprecated
  ) {
    return MultimediaView.OFF;
  }
  if (filters.find((filter) => filter.id === 'image')) {
    if (shouldFetchMultimediaResults === 'V1') {
      return MultimediaView.V1;
    }
    if (
      shouldFetchMultimediaResults === 'ON' ||
      shouldFetchMultimediaResults_deprecated
    ) {
      return MultimediaView.ON;
    }
  }
  return MultimediaView.OFF;
};
