import { JTBD_QUESTIONS } from './self-serve-jtbd';
import {
  ADMIN_PROFILING_QUESTIONS,
  TEAM_MEMBER_PROFILING_QUESTIONS,
} from './self-serve-profiling';

export const ONBOARDING_SURVEY_QUESTIONS = {
  ADMIN_PROFILING_V1: ADMIN_PROFILING_QUESTIONS,
  TEAM_MEMBER_PROFILING_V1: TEAM_MEMBER_PROFILING_QUESTIONS,
  JOBS_TO_BE_DONE_V1: JTBD_QUESTIONS,
};
