import { namespace } from '@mirage/service-operational-metrics';
import { useCallback } from 'react';

import type { SearchResult } from '../search/search-result';
import type { users } from '@dropbox/api-v2-client';
import type { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';

const searchResultNS = namespace('search/result');

/*
  This exists to get more real-time tracking of product usage outside the
  context of batch databricks jobs. We are looking for the following annotations
  alongside the individual events:
  
  - Team ID
  - Internal/External
  - Branded Type
  - Surface (including Browser)
  - Build Number (client, does server have a build number?)
  - Source of result (type ahead, upstream, serp)
*/
export default function useAMISSearchResultLogging(
  envctx: typeof EnvCtx,
  account: users.FullAccount | undefined,
  isDropboxer: boolean,
) {
  const logResultShown = useCallback(
    (result: SearchResult) => {
      if (!account) return; // should not happen, but guard

      return searchResultNS.counter('shown', 1, {
        surface: envctx.surface,
        source: result.searchResultSource || 'unknown',
        teamID: account.team?.id || 'no-team',
        brandedType: result.connectorInfo.connectorName,
        version: envctx.version,
        isInternal: isDropboxer,
      });
    },
    [account, envctx.surface, envctx.version, isDropboxer],
  );

  const logResultLaunched = useCallback(
    (result: SearchResult) => {
      if (!account) return; // should not happen, but guard

      return searchResultNS.counter('launched', 1, {
        surface: envctx.surface,
        source: result.searchResultSource || 'unknown',
        teamID: account.team?.id || 'no-team',
        brandedType: result.connectorInfo.connectorName,
        version: envctx.version,
        isInternal: isDropboxer,
      });
    },
    [account, envctx.surface, envctx.version, isDropboxer],
  );

  const logTypeaheadResultShown = useCallback(
    (result: SearchResult) => {
      if (!account) return; // should not happen, but guard

      return searchResultNS.counter('shown', 1, {
        surface: envctx.surface,
        source: 'local',
        teamID: account.team?.id || 'no-team',
        brandedType: result.connectorInfo.connectorName,
        version: envctx.version,
        isInternal: isDropboxer,
      });
    },
    [account, envctx.surface, envctx.version, isDropboxer],
  );

  // XXX: this is functionally the same to SERP launches, but may be extended
  // in the future to tag suggested query launches, etc. for AMIS reporting
  const logTypeaheadResultLaunched = useCallback(
    (result: SearchResult) => {
      if (!account) return; // should not happen, but guard

      return searchResultNS.counter('launched', 1, {
        surface: envctx.surface,
        source: 'local',
        teamID: account.team?.id || 'no-team',
        brandedType: result.connectorInfo.connectorName,
        version: envctx.version,
        isInternal: isDropboxer,
      });
    },
    [account, envctx.surface, envctx.version, isDropboxer],
  );

  return {
    logResultShown,
    logResultLaunched,
    logTypeaheadResultLaunched,
    logTypeaheadResultShown,
  };
}
