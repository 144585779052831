import type { SearchResult } from '@mirage/service-dbx-api';
import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';

class ChannelRecommendationsCache implements MemoryCache<SearchResult> {
  private cache: SearchResult[] = [];

  all() {
    return this.cache;
  }

  addItems(items: SearchResult[] = []): Promise<SearchResult[]> {
    this.cache = [...items, ...this.cache];
    return Promise.resolve(this.cache);
  }

  removeItem(item: SearchResult): Promise<SearchResult[]> {
    this.cache = this.cache.filter((cacheItem) => cacheItem.uuid != item.uuid);
    return Promise.resolve(this.cache);
  }

  clear() {
    this.cache = [];
  }

  count() {
    return this.cache.length;
  }
}

export default ChannelRecommendationsCache;
