import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, PersonMultipleLine } from '@dropbox/dig-icons/assets';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { ConnectPrompt } from '@mirage/settings/components/connectors/ConnectPrompt';
import { Skeletons } from '@mirage/settings/components/connectors/Skeleton';
import { useDisconnectConnection } from '@mirage/settings/hooks/connections/useDisconnectConnection';
import { useConnectConnector } from '@mirage/settings/hooks/connectors/useConnectConnector';
import { connectionsByTypeAtom } from '@mirage/shared/atoms/connections';
import {
  getConnectionMetadata,
  isUIConnection,
} from '@mirage/shared/connectors/util';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { Connector } from './Connector';
import styles from './OnboardingConnectorsV2.module.css';

import type { ShowSnackbar } from '@mirage/shared/snackbar/types';
import type {
  UIConnection,
  UIConnector,
  UIWebExtension,
} from '@mirage/shared/types';

type Props = Readonly<{
  title?: string;
  subheader?: string;
  connectors?: (UIConnector | UIConnection)[];
  browserExtensionData?: UIWebExtension[];
  showShowAllApps?: boolean;
  showConnectorDescription?: boolean;
  onShowAllAppsClick?: () => void;
  showSnackbar: ShowSnackbar;
  numColumns?: number;
}>;

type OnboardingConnectorDescription = {
  teamConnector: boolean;
};

const OnboardingConnectorDescription = ({
  teamConnector,
}: OnboardingConnectorDescription) => {
  if (teamConnector) {
    return (
      <Text color="subtle" className={styles.connectorDescription}>
        <UIIcon src={PersonMultipleLine} /> {i18n.t('team_content_description')}
      </Text>
    );
  }
  return <Text color="subtle">{i18n.t('user_content_description')}</Text>;
};

export function OnboardingConnectorsV2({
  title,
  subheader,
  connectors,
  showConnectorDescription = false,
  browserExtensionData,
  showShowAllApps = false,
  onShowAllAppsClick = () => {},
  showSnackbar,
  numColumns = 2,
}: Props) {
  const disconnectConnection = useDisconnectConnection();
  const { refreshConnectionsList } = useConnectors();
  const connectConnector = useConnectConnector({
    eventProps: { featureLine: 'onboarding' },
  });
  const isMobile = useIsMobileSize();
  // Making sure that the snackbar doesn't overlap with the main CTA button container for mobile layout of single step onboarding variants
  const snackbarMarginBottom = isMobile ? 60 : 0;

  const [useConnectPrompt, setUseConnectPrompt] = useState<UIConnector | null>(
    null,
  );

  const onConnectDisconnect = async (connector: UIConnector | UIConnection) => {
    await disconnectConnection(connector as UIConnection);
    refreshConnectionsList();
  };

  const onConnectInitiate = async (connector: UIConnector): Promise<void> => {
    if (connector?.auth_details?.prompt_configuration) {
      setUseConnectPrompt(connector);
    } else {
      const result = await connectConnector(connector);
      showSnackbar({
        title: result.message,
        marginBottom: snackbarMarginBottom,
      });
    }
  };

  const onConnectPromptSubmit = async (
    connector: UIConnector | undefined,
    values: Record<string, string>,
  ): Promise<void> => {
    if (connector) {
      setUseConnectPrompt(null);
      const result = await connectConnector(connector, values);
      showSnackbar({
        title: result.message,
        marginBottom: snackbarMarginBottom,
      });
    }
  };

  const connectionsByType = useAtomValue(connectionsByTypeAtom);

  const header = useMemo(() => {
    if (!title) return null;
    return (
      <Box display="block" className={styles.headerContainer}>
        <Text size="small" className={styles.header}>
          {title}
        </Text>
        {subheader && (
          <Text size="small" className={styles.subheader}>
            {subheader}
          </Text>
        )}
      </Box>
    );
  }, [subheader, title]);

  if (!connectors || !browserExtensionData) {
    return (
      <>
        {header}
        <Skeletons length={3} />
      </>
    );
  }

  return (
    <Box>
      {header}
      <Box
        className={styles.connectorsContainer}
        style={{
          gridTemplateColumns: `repeat(${isMobile ? 1 : numColumns}, 1fr)`, // Dynamic column count
        }}
      >
        {connectors.map((connector) => {
          const { branding, loading } = connector;
          let type = '';
          let description;
          const isConnection = isUIConnection(connector);
          if (isConnection) {
            const { teamConnector, type: connectionType } =
              getConnectionMetadata(connector);
            type = connectionType ?? '';
            if (showConnectorDescription) {
              description = (
                <OnboardingConnectorDescription teamConnector={teamConnector} />
              );
            }
          } else {
            type = connector.id_attrs?.type ?? '';
          }
          const connected = !!connectionsByType[type];

          return (
            <Connector
              connected={connected}
              allowDisconnect
              key={connector.branding?.display_name}
              label={connector.branding?.display_name ?? ''}
              description={description}
              icon_src={branding?.icon_src}
              loading={loading}
              onDisconnectClick={() => onConnectDisconnect(connector)}
              onClick={() => onConnectInitiate(connector)}
              type={type}
            />
          );
        })}

        {showShowAllApps && (
          <Connector
            connected={false}
            showAddLine={false}
            key={'show-all-apps'}
            label={i18n.t('show_all_apps')}
            leftAccessory={<UIIcon src={AddCircleLine} />}
            loading={false}
            onClick={() => onShowAllAppsClick()}
            type={'show-all-apps'}
          />
        )}
      </Box>
      {useConnectPrompt && (
        <ConnectPrompt
          connector={useConnectPrompt}
          onSubmit={(values) => onConnectPromptSubmit(useConnectPrompt, values)}
          onCancel={() => setUseConnectPrompt(null)}
        />
      )}
    </Box>
  );
}
