import { ThemeContainer, ThemeProvider } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Initiate_UpdateStackEmoji } from '@mirage/analytics/events/types/initiate_update_stack_emoji';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  getDefaultColorIndex,
  useCurrentBackgroundTheme,
} from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import React from 'react';
import { EMOJI_CLEARED_SENTINEL_VALUE } from '../../Helpers/Utils';
import { EmojiPicker } from '../EmojiPicker';
import styles from './Header.module.css';
import StackDescriptionRow from './StackDescriptionRow';
import StackHeaderActions from './StackHeaderActions';
import StackInfoAndMembers from './StackInfoAndMembers';
import StackTitleArea from './StackTitleArea';

import type { HeaderCoreProps } from './HeaderCommon';

interface HeaderAugustRevProps {
  buttonActions?: React.ReactNode;
}

const HeaderAugustRev: React.FC<HeaderCoreProps & HeaderAugustRevProps> = ({
  stack,
  canEdit,
  emojiPickerVisible,
  setEmojiPickerVisible,
  updateEmoji,
  stackDerivedPAPPropsWithFeature,
  withImmediateAction: withAction,
  updateStackNameAndDescription,
  buttonActions,
  defaultUserInfo,
  additionalContainerStyles,
}) => {
  const backgroundTheme = useCurrentBackgroundTheme(stack);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isMobile = useIsMobileSize();
  const isNewStack = stack === null;

  const newStackColorIndex = getDefaultColorIndex();
  const newStackEmoji = EMOJI_CLEARED_SENTINEL_VALUE;

  const stackTitle = stack?.stack_data?.name ?? '';
  const stackDescription = stack?.stack_data?.description ?? '';

  return (
    <ThemeProvider overrides={backgroundTheme}>
      <ThemeContainer>
        <div
          className={classnames(
            styles.headerContainerAug,
            additionalContainerStyles,
          )}
        >
          <div className={styles.headerMainRow}>
            <div className={styles.headerIconContainer} data-testid="stackIcon">
              <DashStackIcon
                className={styles.stackIcon}
                colorIndex={
                  isNewStack
                    ? newStackColorIndex
                    : stack.stack_data?.color_index || getDefaultColorIndex()
                }
                aria-label={i18n.t('aria_emoji_button')}
                emoji={
                  isNewStack
                    ? newStackEmoji
                    : stack.stack_data?.emoji || EMOJI_CLEARED_SENTINEL_VALUE
                }
                size={'xlarge'}
                onClick={
                  canEdit
                    ? () => {
                        setEmojiPickerVisible(!emojiPickerVisible);
                        reportPapEvent(
                          PAP_Initiate_UpdateStackEmoji(
                            stackDerivedPAPPropsWithFeature,
                          ),
                        );
                      }
                    : undefined
                }
                customColor={
                  isNewStack ? undefined : stack.stack_data?.custom_color
                }
              />
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={updateEmoji}
                  papProps={stackDerivedPAPPropsWithFeature}
                />
              )}
            </div>
            <StackTitleArea
              stackTitle={stackTitle}
              isNewStack={isNewStack}
              updateStackNameAndDescription={updateStackNameAndDescription}
              stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
              withImmediateAction={withAction}
              withRightAction={buttonActions}
              canEdit={canEdit}
              withInfo={
                stack && (
                  <StackInfoAndMembers
                    stack={stack}
                    defaultUserInfo={defaultUserInfo}
                    hidePinned
                  />
                )
              }
            />
          </div>
          <StackDescriptionRow
            stackDescription={stackDescription}
            isNewStack={isNewStack}
            updateStackNameAndDescription={updateStackNameAndDescription}
            stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
            canEdit={canEdit}
          />
          {isMobile && <StackHeaderActions action={buttonActions} />}
        </div>
      </ThemeContainer>
    </ThemeProvider>
  );
};

export default HeaderAugustRev;
