// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3728
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurfaceEntryPoint } from "../enums/dash_surface_entry_point";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { DashSurfaceBuild } from "../enums/dash_surface_build";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";

// Event_name: 'shown.dash_omnibox_search_prompt'
// Description: Shown the dash_omnibox_search_prompt education prompt
// Owner: dash-exp-find-and-discover
export type Shown_DashOmniboxSearchPrompt = PAPEventGeneric<
  'dash',
  'shown',
  'dash_omnibox_search_prompt',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // what dash product the cloud connector is launched from [dash_desktop, dash_webapp, etc]
    dashSurfaceEntryPoint?: DashSurfaceEntryPoint;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
  }
>;

export function PAP_Shown_DashOmniboxSearchPrompt(properties?: Shown_DashOmniboxSearchPrompt['properties']): Shown_DashOmniboxSearchPrompt {
  return <Shown_DashOmniboxSearchPrompt>{
    class: 'dash',
    action: 'shown',
    object: 'dash_omnibox_search_prompt',
    properties,
  };
}
