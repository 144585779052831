import {
  setShouldShowStackCommentPromptAtom,
  shouldShowStackCommentPromptAtom,
} from '@mirage/service-comments/atoms/stack';
import { getShouldShowCommentPrompt } from '@mirage/service-comments/utils/stack';
import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

// Simple hook to handle initialization of metadata related to Stack commenting
export const useInitCommentMetadata = () => {
  const isStackCommentsEnabled = useFeatureFlagValueBool(
    'dash_web_2025_01_31_stacks_contextual_information',
  );
  const shouldShowCommentPrompt = useAtomValue(
    shouldShowStackCommentPromptAtom,
  );
  const setShouldShowCommentPrompt = useSetAtom(
    setShouldShowStackCommentPromptAtom,
  );

  useEffect(() => {
    async function fetchPromptMetadata() {
      // getShouldShowCommentPrompt will never throw an exception
      setShouldShowCommentPrompt(await getShouldShowCommentPrompt());
    }

    // Only fetch the prompt metadata if comments are enabled and we haven't already fetched it
    if (isStackCommentsEnabled && shouldShowCommentPrompt === null) {
      void fetchPromptMetadata();
    }
  }, [isStackCommentsEnabled, shouldShowCommentPrompt]);
};
