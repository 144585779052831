import { callApiV2 } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { tagged } from '@mirage/service-logging';
import apiV2MetricsSink from '@mirage/service-operational-metrics/service/apiv2-sink';
import { getBrowserType } from '@mirage/shared/util/browser';
import { isDefined } from '@mirage/shared/util/tiny-utils';

import type { stacks } from '@dropbox/api-v2-client';
import type {
  MetricSink,
  Tags,
} from '@mirage/service-operational-metrics/service';
import type {
  ApiV2MetricsSinkProps,
  ClientMetric,
} from '@mirage/service-operational-metrics/service/apiv2-sink';

const logger = tagged('metricSink');

type StacksMetric = stacks.Metric['metric'];

function convertClientMetricToStacksMetric(
  metric: ClientMetric,
): StacksMetric | undefined {
  switch (metric['.tag']) {
    case 'counter':
      return {
        '.tag': 'generic_counter',
        metric_name: `${metric.namespace}/${metric.name}`,
        tag: takeFirstTagIfExists(metric.tags),
        count: metric.value,
      };
    case 'histogram':
      return {
        '.tag': 'generic_histogram',
        metric_name: `${metric.namespace}/${metric.name}`,
        tag: takeFirstTagIfExists(metric.tags),
        value: metric.value,
      };
    default:
      metric satisfies never;
      return undefined;
  }
}

// Deprecated: Double-logging for now until new metrics have settled down.
// ETA safe to remove completely around Oct 2024 (>30 days data overlap).
export default function legacyApiv2MetricSink(
  props: Exclude<ApiV2MetricsSinkProps, 'notifyMetricsSent'>,
): MetricSink {
  async function notifyMetricsSent(metrics: ClientMetric[]) {
    const stacksMetrics = metrics
      .map(convertClientMetricToStacksMetric)
      .filter(isDefined);

    if (!stacksMetrics.length) return;

    try {
      await callApiV2('stacksReportClientMetrics', {
        tags: {
          channel: EnvCtx.buildChannel,
          platform: { '.tag': EnvCtx.surface },
          environment: { '.tag': getBrowserType() ?? 'other_browser' },
        },
        metrics: stacksMetrics.map((metric) => ({ metric })),
      });
    } catch (e) {
      logger.warn('Failed to report stacks metrics', e);
    }
  }

  return apiV2MetricsSink({ ...props, notifyMetricsSent });
}

// Due to current impl take the first tag from any reported results or undef
function takeFirstTagIfExists(tags: Tags | undefined): string | undefined {
  if (!tags) return undefined;

  const keys = Object.keys(tags);
  if (!keys.length) return undefined;

  // The tag is always logged with name `tag`, so we need to put the actual
  // tag name into the value itself to make it readable in grafana.
  const key = keys[0];
  return `${key}=${tags[key]}`;
}
