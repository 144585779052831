import { callApiV2 } from '@mirage/service-dbx-api';

import type {
  context_engine,
  ml_capabilities,
} from '@dropbox/api-v2-client/types/dropbox_types';

export interface LLMFunctionDefinition {
  name: string;
  description: string;
  parameters: {
    type: string;
    properties: {
      [key: string]: LLMFunctionProperty;
    };
    required: string[];
  };
}

export interface LLMFunctionProperty {
  type: string;
  description?: string;
  items?: LLMFunctionProperty;
  properties?: {
    [key: string]: LLMFunctionProperty;
  };
}

export interface LLMResponse {
  responseMessage: ml_capabilities.ChatMessageAssistantMessage;
  responseText: string;
  toolCalls: ml_capabilities.ToolCall[];
}

export async function callAssistChatApi({
  params,
}: {
  params: context_engine.AssistChatParams;
}) {
  const response = await callApiV2('contextEngineAssistApiChatCompletion', {
    params,
  });
  const responseMessage = response.results![0].generations![0].chat_message;
  switch (responseMessage?.['.tag']) {
    case 'assistant_message': {
      const responseText = responseMessage.content?.text || '';
      const toolCalls = responseMessage.tool_calls || [];
      return { responseMessage, responseText, toolCalls };
    }
    default:
      throw new Error(
        `Unexpected response message type: ${responseMessage?.['.tag']}`,
      );
  }
}
