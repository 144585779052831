import {
  isAdminUser,
  useAccountIsDropboxer,
} from '@mirage/service-auth/useDropboxAccount';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ONBOARDING_VERSION } from '../flows/constants';
import { useSelfServeOnboardingSteps } from '../hooks/useSelfServeOnboardingSteps';
import { SelfServeOnboarding } from './SelfServeOnboarding';

type Props = {
  routeParams?: Record<string, string | undefined>;
};

export const AdminSelfServeOnboarding = ({ routeParams }: Props) => {
  const { account } = useAccountIsDropboxer();
  const { onboardingSteps, showPrivacyModal, setShowPrivacyModal } =
    useSelfServeOnboardingSteps(true);
  const isAdmin = isAdminUser(account);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      // Route non-admins to team member onboarding
      navigate(RoutePath.SETUP_SELF_SERVE.replace(':step', ''));
    }
  }, [isAdmin, navigate]);

  return (
    <SelfServeOnboarding
      isAdmin={isAdmin}
      steps={onboardingSteps}
      routeParams={routeParams}
      onboardingVariant={ONBOARDING_VERSION.ADMIN_SELF_SERVE_V1}
      showPrivacyModal={showPrivacyModal}
      setShowPrivacyModal={setShowPrivacyModal}
    />
  );
};
