import sum from 'lodash/sum';
import uniqueId from 'lodash/uniqueId';
import zip from 'lodash/zip';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useFLIPOrchestrator } from '../useFLIPOrchestrator';
import { useNonScalableProperties } from './useNonScalableProperties';
import { useRegisterChildren } from './useRegisterChildren';
import {
  cloneHTMLCollection,
  distribute,
  ratioToVerticalBorderRadiusKeyframe,
  ratioToVerticalBoxShadowWidthKeyframe,
  translateYToKeyframe,
  verticalRatioToKeyframe,
} from './utils';

import type { OnFLIP } from './FLIPOrchestrator';
import type { MutableRefObject } from 'react';

/** An attr to keep track of the parent el's children. */
const CHILD_ID_KEY = 'data-use-flip-child-Id';

function updateChildrenToParentOffset(
  el: HTMLDivElement,
  childIdToParentOffset: MutableRefObject<Map<string, number>>,
): void {
  const elTop = el.getBoundingClientRect().top;

  const children = cloneHTMLCollection(el.children);
  for (const child of children) {
    if (!(child instanceof HTMLElement)) continue;

    const id = uniqueId();
    child.setAttribute(CHILD_ID_KEY, id);
    const childTop = child.getBoundingClientRect().top;

    childIdToParentOffset.current.set(id, childTop - elTop);
  }
}

/**
 * A hook that smoothly animates an element changing its height.
 *
 * There are two pieces to this:
 *   - Making the element appear to grow
 *   - Making the contents of the element appear unchanged and move smoothly to their new positions.
 *
 * The second item is the more interesting of the two and encompasses:
 *   - Correcting skew of the elements box shadows and border radius.
 *   - Correcting the skew of child elements.
 *   - Correcting position changes of child elements caused by siblings being added or removed.
 *   - Animating in new child elements so they don't overlap siblings that are moving.
 */
export function useFLIPHeight(el: HTMLDivElement | null) {
  const orchestrator = useFLIPOrchestrator();
  useRegisterChildren(el);

  const height = useRef<number>();
  // A mapping of child ID to its distance from the top of the el.
  const childIdToParentOffset = useRef<Map<string, number>>(new Map());

  // useLayoutEffect runs synchronously before paint so we can get accurate measurements.

  // NOTE (jkarabinos): We need to include the orchestrator in the deps for the useLayoutEffect
  // as there is a grace period in useFLIPOrchestrator before the orchestrator is created to
  // prevent animations early on in the lifecycle. If we add a child to the VerticalExpandable
  // before the orchestrator is created, the child won't get a flip id and will later fade
  // in unexpectedly. Fading is intended for children added later on in the life cycle, but
  // not for children added before the orchestrator has been created.
  useLayoutEffect(() => {
    if (!el) return;

    const update = () =>
      updateChildrenToParentOffset(el, childIdToParentOffset);

    update();

    el.addEventListener('scroll', update);

    return () => el.removeEventListener('scroll', update);
  }, [el, orchestrator]);

  const { boxShadow, borderRadius } = useNonScalableProperties(el);

  const scale: OnFLIP = useCallback(
    ({ schedule, duration, maxKeyframesCount }) => {
      if (!el) return;

      const prev = height.current;
      const curr = el.getBoundingClientRect().height;
      height.current = curr;
      const elTop = el.getBoundingClientRect().top;

      if (prev === undefined || curr === 0) return;

      // Make the transform origin "top" for the duration of the animation.
      schedule({
        target: el,
        keyframes: [{ transformOrigin: 'top' }, { transformOrigin: 'top' }],
      });

      // We need to show the parent scaling up but also need to make it appear as though other
      // things (child elements, border radius, and box shadow) have stayed the same. We invert the
      // scale for those. See the README for details, especially "Lots of keyframes vs interpolating
      // values"
      const scaleSteps = distribute({
        start: prev / curr,
        end: 1,
        stepCount: maxKeyframesCount,
      });
      const invertedScaleSteps = scaleSteps.map((r) => 1 / r);

      if (prev !== curr) {
        schedule({
          target: el,
          keyframes: scaleSteps.map(verticalRatioToKeyframe),
          overrides: {
            composite: 'accumulate',
          },
        });

        if (boxShadow) {
          schedule({
            target: el,
            keyframes: invertedScaleSteps.map((r) =>
              ratioToVerticalBoxShadowWidthKeyframe(r, boxShadow),
            ),
          });
        }

        if (borderRadius) {
          schedule({
            target: el,
            keyframes: invertedScaleSteps.map((r) =>
              ratioToVerticalBorderRadiusKeyframe(r, borderRadius),
            ),
          });
        }
      }

      const children = cloneHTMLCollection(el.children);
      for (const child of children) {
        if (!(child instanceof HTMLElement)) continue;

        let id = child.getAttribute(CHILD_ID_KEY);

        if (!id) {
          id = uniqueId();
          child.setAttribute(CHILD_ID_KEY, id);
          // If a new child has been added, make it transparent and then fade it in after the rest
          // of the orchestration has completed.
          schedule({
            target: child,
            keyframes: [{ opacity: 0 }, { opacity: 0 }, { opacity: 1 }],
            overrides: {
              duration: duration * 2,
            },
          });
        }

        const childTop = child.getBoundingClientRect().top;
        const currentParentOffset = childTop - elTop;
        const prevParentOffset = childIdToParentOffset.current.get(id);
        childIdToParentOffset.current.set(id, currentParentOffset);

        if (prevParentOffset === undefined) continue;

        // Make the transform origin "top" for the duration of the animation.
        schedule({
          target: child,
          keyframes: [{ transformOrigin: 'top' }, { transformOrigin: 'top' }],
        });

        // Reverse skew from the parent scaling for each child.
        schedule({
          target: child,
          keyframes: invertedScaleSteps.map(verticalRatioToKeyframe),
          overrides: {
            composite: 'accumulate',
          },
        });

        // Adjust the position of the child.
        //
        // Reversing the scale of the child ensures that it stays the proper size but it will still
        // be positioned in the wrong place as the parent scales. The child may have also moved
        // within the parent if other elements were added or had their size change.
        //
        // example: If the parent grew, the distance between the top of the parent and child would
        // also appear to grow which would make it look like the child is moving down.

        // First, anchor the child to the top of the parent el by reversing its current distance for
        // the duration of the animation. This lets us calculate from a stable spot.
        const anchors = new Array(maxKeyframesCount).fill(-currentParentOffset);
        // Add back its distance from the top of the parent el, this time adjusted by inverting the
        // scale of the parent as we go.
        const scaleCorrectedParentOffsets = invertedScaleSteps.map(
          (ratio) => currentParentOffset * ratio,
        );
        // If the child actually _did_ move within the parent, account for that now. Translate the
        // distance the child has traveled via its actual position changing, spread out over time
        // and adjusted by the scale of the parent.
        const positionChangeMoves = distribute({
          start: prevParentOffset - currentParentOffset,
          end: 0,
          stepCount: maxKeyframesCount,
        });
        const newPositionDifferences = zip(
          positionChangeMoves,
          invertedScaleSteps,
        ).map(([move, scale]) => {
          return (move ?? 0) * (scale ?? 0);
        });

        // Add all of those adjustments together to create the ultimate translation of the child.
        const composites = zip(
          anchors,
          scaleCorrectedParentOffsets,
          newPositionDifferences,
        )
          .map(sum)
          .map(translateYToKeyframe);

        schedule({
          target: child,
          keyframes: composites,
          overrides: {
            composite: 'accumulate',
          },
        });
      }
    },
    [borderRadius, boxShadow, el],
  );

  useEffect(() => {
    return orchestrator.subscribe(scale);
  }, [scale, orchestrator]);
}
