import { getSourceUUID } from '@mirage/shared/compose/compose-session';

import type {
  ChatConversationMessage,
  ChatSource,
} from '@mirage/mosaics/Chat/types';

/**
 * @returns a map of source UUID to source for all sources referenced in the messages.
 */
export function getAllReferencedSources(messages: ChatConversationMessage[]) {
  const sources = new Map<string, ChatSource>();
  for (const message of messages) {
    if (message.type !== 'message') {
      continue;
    }
    for (const source of message.referencingSources || []) {
      const sourceUUID = getSourceUUID(source);
      if (sourceUUID) {
        sources.set(sourceUUID, source);
      }
    }
  }
  return sources;
}

export function getUnreferencedSources(
  messages: ChatConversationMessage[],
  sources: ChatSource[],
) {
  const referencedSources = getAllReferencedSources(messages);
  return sources.filter((source) => {
    const sourceUUID = getSourceUUID(source);
    return sourceUUID && !referencedSources.has(sourceUUID);
  });
}
