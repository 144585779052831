import { tagged } from '@mirage/service-logging';
import {
  getPeopleSuggestions,
  performPeopleSearch,
} from '@mirage/service-people';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

import type { PersonObject } from '@mirage/shared/search/search-filters';

const logger = tagged('usePeopleSearch');

interface PeopleSearchHook {
  handleSearchPeople: (query: string) => void;
  peopleSuggestions: PersonObject[];
  peopleResults: PersonObject[];
  peopleSearchStatus: PeopleSearchStatus;
}

const SEARCH_DEBOUNCE_MS = 100;

export const enum PeopleSearchStatus {
  INITIAL,
  LOADING,
  ERROR,
  SUCCESS,
}

export const usePeopleSearch = (): PeopleSearchHook => {
  const [suggestions, setSuggestions] = useState<PersonObject[]>([]);
  const [results, setResults] = useState<PersonObject[]>([]);
  const [searchStatus, setSearchStatus] = useState<PeopleSearchStatus>(
    PeopleSearchStatus.INITIAL,
  );

  useEffect(() => {
    const getSuggestions = async () => {
      logger.debug('[suggestions] - fetching suggestions');
      const peopleSuggestions = await getPeopleSuggestions();
      setSuggestions(peopleSuggestions);
    };
    getSuggestions();
  }, []);

  const handleSearchDebounced = useCallback(
    debounce(async (query) => {
      logger.debug('[search] - searching for people');
      try {
        const people = await performPeopleSearch(query);
        setResults(people);
        setSearchStatus(PeopleSearchStatus.SUCCESS);
      } catch (err) {
        logger.debug('[search] - caught error with people search', err);
        setSearchStatus(PeopleSearchStatus.ERROR);
      }
    }, SEARCH_DEBOUNCE_MS),
    [],
  );

  const handleSearch = (query: string) => {
    if (query.trim() === '') {
      setResults([]);
      setSearchStatus(PeopleSearchStatus.INITIAL);
      return;
    } else {
      setSearchStatus(PeopleSearchStatus.LOADING);
    }

    handleSearchDebounced(query);
  };

  useEffect(() => {
    return () => {
      handleSearchDebounced.cancel();
    };
  }, [handleSearchDebounced]);

  return {
    handleSearchPeople: handleSearch,
    peopleSuggestions: suggestions,
    peopleResults: results,
    peopleSearchStatus: searchStatus,
  };
};
