import { tagged } from '@mirage/service-logging';
import { of, timer } from 'rxjs';
import { catchError, map, scan, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { createGenAnswersForQueryObservable } from './context_observables';

import type { Answer } from './gen/answers_pb';
import type { CE_FileTypeInfo, CE_IconResource } from './gen/connector_info_pb';
import type { GetAnswersForQueryResult } from './gen/context_engine_answers_service_pb';
import type { AnswersForQueryResult } from './types';
import type { context_engine } from '@dropbox/api-v2-client';
import type {
  FileTypeInfo,
  IconResource,
} from '@mirage/service-dbx-api/service/search';
import type {
  MultiAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import type { PossibleContentTypes } from '@mirage/shared/content-type/content-types';
import type { Observable } from 'rxjs';

const logger = tagged('answers_grpc');

export function processGenAnswersForQueryChunk(
  acc: AnswersForQueryResult,
  chunk: GetAnswersForQueryResult,
): AnswersForQueryResult {
  const answers = chunk?.answers ?? [];
  const existing = acc;

  // Check if any contextUserError is present.
  const noAnswers = answers.some((contextEngineAnswer: Answer) => {
    const model = contextEngineAnswer.answer?.output?.model?.items?.[0];
    return model?.data?.case === 'contextUserError';
  });
  if (noAnswers) {
    return {
      ...existing,
      error: 'No answers found.',
      response: {
        answers: [],
        requestId: chunk.requestId || 'unknown',
      },
    };
  }

  existing.response.answers =
    answers.map((contextEngineAnswer: Answer, index: number) => {
      const model = contextEngineAnswer.answer?.output?.model?.items?.[0];
      let answerText: string | undefined;
      let sources: QuestionAndAnswerSource[] = [];

      switch (model?.data?.case) {
        case 'answerInfo':
          answerText = model?.data?.value?.answer;
          sources =
            model?.data?.value?.sources?.map((documentInfo) => {
              return {
                id3p: documentInfo.thirdPartyId ?? '',
                brandedType: documentInfo.brandedType ?? '',
                title: documentInfo.title ?? '',
                url: documentInfo.url ?? '',
                connectorName: documentInfo.connectorInfo?.connectorName ?? '',
                iconUrl: documentInfo.connectorInfo?.connectorIconUrl,
                connectorInfo: (documentInfo.connectorInfo?.toJson({
                  useProtoFieldName: true,
                }) ?? {}) as unknown as context_engine.CE_ConnectorInfo,
                fileTypeInfo: buildFileTypeInfo(documentInfo?.fileTypeInfo),
                uuid: documentInfo.uuid,
                lastUpdatedMs: Number(documentInfo.lastUpdatedMs),
              };
            }) ?? [];
          break;
        case 'stringValue':
          answerText = model?.data?.value;
          // TODO: waiting for context engine API to return sources in this case
          break;
      }

      const answerId = contextEngineAnswer?.answerId;
      const conversationId = contextEngineAnswer.conversationId || uuid();
      const question =
        contextEngineAnswer.query ||
        contextEngineAnswer.question?.question ||
        '';
      return {
        position: index,
        question,
        answer: answerText ?? '',
        sources: sources.slice(0, 3),
        conversationId,
        answerId,
      };
    }) || [];

  existing.response.requestId = chunk.requestId || 'unknown';

  return existing;
}

function genAnswersForQueryImpl(
  userQuery: string,
  source?: string,
  experimentSetting?: string,
): Observable<AnswersForQueryResult> {
  const answers$ = createGenAnswersForQueryObservable(
    userQuery,
    experimentSetting,
    source,
  );

  const initialAcc: AnswersForQueryResult = {
    initialQuery: userQuery,
    created: Date.now(),
    response: {
      answers: [],
      requestId: uuid(),
    } as unknown as MultiAnswerResponse,
  };

  let firstChunk = true;

  return answers$.pipe(
    // switchMap will cancel any in-flight chunk processing when a new chunk arrives
    switchMap((chunk: GetAnswersForQueryResult) => {
      if (firstChunk) {
        firstChunk = false;
        return of(chunk);
      } else {
        // Delay a little bit to yield to the event loop if needed
        return timer(0).pipe(
          // Now process the single "latest" chunk
          map(() => chunk),
        );
      }
    }),
    scan(
      (acc: AnswersForQueryResult, latestChunk: GetAnswersForQueryResult) => {
        return processGenAnswersForQueryChunk(acc, latestChunk);
      },
      initialAcc,
    ),

    catchError((error) => {
      logger.error('genAnswersForQuery error', error);
      const errorSummary: AnswersForQueryResult = {
        initialQuery: userQuery,
        created: Date.now(),
        error: 'Failed to fetch answers. ' + error,
        response: { answers: [] } as unknown as MultiAnswerResponse,
      };
      return of(errorSummary);
    }),
  );
}

export function genAnswersForQuery(
  userQuery: string,
  source?: string,
  experimentSetting?: string,
): Observable<AnswersForQueryResult> {
  return genAnswersForQueryImpl(userQuery, source, experimentSetting);
}

export const buildFileTypeInfo = (
  entity: CE_FileTypeInfo | undefined,
): FileTypeInfo | undefined => {
  if (!entity) return undefined;
  const { id, displayName } = entity;
  const icon = buildIconResource(entity.icon);
  return { id: id as PossibleContentTypes, displayName, icon };
};

export const buildIconResource = (
  entity: CE_IconResource | undefined,
): IconResource | null => {
  if (!entity) return null;
  const { lightUrl, darkUrl } = entity;
  return { lightUrl, darkUrl };
};
