import { Accordion } from '@dropbox/dig-components/accordion';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  DropboxLine,
  LockLine,
  ShieldLine,
  Twinkle2Line,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashPrivacyModal } from '@mirage/analytics/events/types/view_dash_privacy_modal';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { Link } from '@mirage/link/Link';
import i18n, { Trans } from '@mirage/translations';
import { useEffect, useState } from 'react';
import * as React from 'react';
import styles from './PrivacyModal.module.css';

import type { DashPrivacyModalContents } from '@mirage/analytics/events/enums/dash_privacy_modal_contents';

interface Props {
  onClose: () => void;
}
export const PrivacyModal: React.FC<Props> = ({ onClose }) => {
  const [expandedPanels, setExpandedPanels] = useState<string[]>([
    'privacy_dash_ai',
  ]);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const handleClick = (itemId: string) => {
    setExpandedPanels((prev) => {
      if (prev.includes(itemId)) {
        return [];
      } else {
        return [itemId];
      }
    });
  };

  useEffect(() => {
    const panelId = expandedPanels[0];
    if (panelId) {
      const contentEnum = panelId.substring(8) as DashPrivacyModalContents; // remove `privacy_`
      reportPapEvent(
        PAP_View_DashPrivacyModal({ dashPrivacyModalContents: contentEnum }),
      );
    }
  }, [expandedPanels, reportPapEvent]);

  return (
    <Modal
      open
      onRequestClose={onClose}
      aria-labelledby="privacy-modal-title"
      withCloseButton="Close"
      width={700}
    >
      <Modal.Content>
        <Modal.Header className={styles.privacyModalHeader}>
          <Title size="small" weightVariant="emphasized">
            {i18n.t('privacy_modal_title')}
          </Title>
        </Modal.Header>
        <Modal.Body className={styles.privacyModalBody}>
          <Accordion
            variant="standard"
            expandedPanels={expandedPanels}
            className={styles.accordion}
          >
            <PrivacyAccordionItem
              title={i18n.t('privacy_dash_ai_title')}
              icon={
                <UIIcon
                  src={Twinkle2Line}
                  color="var(--dig-color__primary__base)"
                />
              }
              id="privacy_dash_ai"
              onClick={handleClick}
            >
              <AccordionContentBlock
                uxaElementId={createUxaElementId(
                  'privacy_modal_accordion_dash_ai',
                  {},
                )}
              >
                <Text>{i18n.t('privacy_dash_ai_description')}</Text>
              </AccordionContentBlock>
              <AccordionContentBlock>
                <Text>{i18n.t('privacy_need_more_info_title')}</Text>
                <Link href="https://dash.dropbox.com/security" target="_blank">
                  {i18n.t('privacy_dash_ai_link_title')}
                </Link>
              </AccordionContentBlock>
            </PrivacyAccordionItem>
            <PrivacyAccordionItem
              title={i18n.t('privacy_privacy_first_title')}
              icon={
                <UIIcon
                  src={LockLine}
                  color="var(--dig-color__primary__base)"
                />
              }
              id="privacy_privacy_first"
              onClick={handleClick}
            >
              <AccordionContentBlock
                uxaElementId={createUxaElementId(
                  'privacy_modal_accordion_privacy_first',
                  {},
                )}
              >
                <Text isBold>{i18n.t('privacy_privacy_first_subtitle')}</Text>
              </AccordionContentBlock>
              <AccordionContentBlock>
                <Text>{i18n.t('privacy_privacy_first_description1')}</Text>
              </AccordionContentBlock>
              <AccordionContentBlock>
                <Text>
                  <Trans
                    i18nKey={'privacy_privacy_first_description2'}
                    components={{
                      ppLink: (
                        <Link
                          href="mailto:privacy@dropbox.com"
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </Text>
              </AccordionContentBlock>
              <AccordionContentBlock>
                <Text>{i18n.t('privacy_need_more_info_title')}</Text>
                <Link href="https://www.dropbox.com/privacy" target="_blank">
                  {i18n.t('privacy_privacy_first_data_link_title')}
                </Link>
              </AccordionContentBlock>
            </PrivacyAccordionItem>
            <PrivacyAccordionItem
              title={i18n.t('privacy_soc2_title')}
              icon={
                <UIIcon
                  src={ShieldLine}
                  color="var(--dig-color__primary__base)"
                />
              }
              id="privacy_soc2"
              onClick={handleClick}
            >
              <AccordionContentBlock
                uxaElementId={createUxaElementId(
                  'privacy_modal_accordion_soc2',
                  {},
                )}
              >
                <Text isBold>{i18n.t('privacy_soc2_subtitle')}</Text>
              </AccordionContentBlock>
              <AccordionContentBlock>
                <Text>{i18n.t('privacy_soc2_description')}</Text>
              </AccordionContentBlock>
              <AccordionContentBlock>
                <Text>{i18n.t('privacy_need_more_info_title')}</Text>
                <Link
                  href="https://www.dropbox.com/business/trust/compliance/security-practices"
                  target="_blank"
                >
                  {i18n.t('privacy_soc2_security_practice_link_title')}
                </Link>
                <Link
                  href="https://www.dropbox.com/business/trust/compliance/SOC"
                  target="_blank"
                >
                  {i18n.t('privacy_soc2_soc_link_title')}
                </Link>
              </AccordionContentBlock>
            </PrivacyAccordionItem>
            <PrivacyAccordionItem
              title={i18n.t('privacy_dash_product_title')}
              icon={
                <UIIcon
                  src={DropboxLine}
                  color="var(--dig-color__primary__base)"
                />
              }
              id="privacy_dash_product"
              onClick={handleClick}
            >
              <AccordionContentBlock
                uxaElementId={createUxaElementId(
                  'privacy_modal_accordion_dash_product',
                  {},
                )}
              >
                <Text>{i18n.t('privacy_dash_product_subtitle')}</Text>
              </AccordionContentBlock>
              <AccordionContentBlock>
                <Text>{i18n.t('privacy_dash_product_promise_title')}</Text>
                <ul className={styles.unorderedList}>
                  <li>
                    <Text>{i18n.t('privacy_dash_product_promise_item1')}</Text>
                  </li>
                  <li>
                    <Text>{i18n.t('privacy_dash_product_promise_item2')}</Text>
                  </li>
                  <li>
                    <Text>{i18n.t('privacy_dash_product_promise_item3')}</Text>
                  </li>
                  <li>
                    <Text>{i18n.t('privacy_dash_product_promise_item4')}</Text>
                  </li>
                </ul>
              </AccordionContentBlock>
            </PrivacyAccordionItem>
          </Accordion>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

interface PrivacyAccordionItemProps {
  title: string;
  id: string;
  icon: React.ReactNode;
  onClick: (id: string) => void;
}
const PrivacyAccordionItem: React.FC<PrivacyAccordionItemProps> = ({
  title,
  icon,
  id,
  onClick,
  children,
}) => {
  return (
    <Accordion.Item itemId={id} className={styles.accordionItemContainer}>
      <Accordion.Header
        onClick={() => onClick(id)}
        className={styles.accordionHeader}
      >
        <Accordion.HeaderContent
          withTitle={<Title size="small">{title}</Title>}
          withLeftAccessory={icon}
        />
      </Accordion.Header>
      <Accordion.Panel spacing="large">{children}</Accordion.Panel>
    </Accordion.Item>
  );
};

interface AccordionContentBlockProps {
  uxaElementId?: string;
}
const AccordionContentBlock: React.FC<AccordionContentBlockProps> = ({
  children,
  uxaElementId,
}) => {
  return (
    <div
      className={styles.accordionTextContent}
      // log shown event for UXA
      data-uxa-log={uxaElementId}
      data-uxa-interactions={uxaElementId ? 'shown' : undefined}
    >
      {children}
    </div>
  );
};
