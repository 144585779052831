import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { loadAssistKVDataAPI } from '@mirage/service-compose/service/apiDataLoader';
import {
  parseSessionData,
  parseTemplateData,
  parseVoiceData,
} from '@mirage/service-compose/service/apiDataParsers';
import { createAssistActionsSettings } from '@mirage/service-compose/service/assistActionsSettings';
import { createAssistActionsSync } from '@mirage/service-compose/service/assistActionsSyncAdapters';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { AccessType } from '@mirage/shared/compose/assist-api';

import type { context_engine } from '@dropbox/api-v2-client/types/dropbox_types';
import type { KVStorageShape as AssistActionsSettingsKVStorageShape } from '@mirage/service-compose/service/assistActionsSettings';
import type { KVStorageShape as AssistActionsKVStorageShape } from '@mirage/service-compose/service/assistActionsSyncAdapters';
import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type { AssistCustomTemplate } from '@mirage/shared/compose/assist-template';
import type { ComposeSession } from '@mirage/shared/compose/compose-session';
import type { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import type { KVStorage } from '@mirage/storage';

export type Service = ReturnType<typeof composeService>;

export interface ComposeSessionsStorage {
  sessions: {
    rows: ComposeSession[];
    version: 1;
  };
}

export interface ComposeVoicesStorage {
  voices: ComposeVoice[];
}

export interface AssistCustomTemplatesStorage {
  templates: AssistCustomTemplate[];
}

export interface AssistActionsStorage
  extends AssistActionsKVStorageShape,
    AssistActionsSettingsKVStorageShape {}

export enum StorageKey {
  Voices = 'voices',
  Sessions = 'sessions',
  AssistActions = 'actions',
  Templates = 'templates',
}

export default function composeService(
  sessionsStore: KVStorage<ComposeSessionsStorage>,
  voicesStore: KVStorage<ComposeVoicesStorage>,
  actionsStore: KVStorage<
    AssistActionsKVStorageShape & AssistActionsSettingsKVStorageShape
  >,
  templatesStore: KVStorage<AssistCustomTemplatesStorage>,
  logoutService: LogoutServiceConsumerContract,
) {
  const logger = tagged('compose-service');

  async function loadComposeSessions(): Promise<ComposeSession[]> {
    const sessions = await sessionsStore.get(StorageKey.Sessions);
    if (sessions?.version === 1) {
      logger.log('loaded ComposeSessions', sessions.rows.length);
      return sessions.rows;
    } else {
      // Ignore old data if the version is not >=1
      return [];
    }
  }

  async function loadComposeApiSessions(): Promise<ComposeSession[]> {
    return loadAssistKVDataAPI<ComposeSession>(
      StorageKey.Sessions,
      parseSessionData,
    );
  }

  async function saveComposeSessions(sessions: ComposeSession[]) {
    await sessionsStore.set(StorageKey.Sessions, {
      rows: sessions,
      version: 1,
    });
    logger.log('saved ComposeSessions', sessions.length);
  }

  async function saveComposeApiSession(session: ComposeSession) {
    const args: context_engine.AssistSaveUserDataArg = {
      data_item: {
        user_data_type: StorageKey.Sessions,
        user_data_key: session.id,
        user_data: JSON.stringify(session),
      },
      data_id: session.dataId,
    };
    logger.log('Saving session to API...', session.id);
    const response = await callApiV2(
      'contextEngineAssistApiSaveUserData',
      args,
    );
    if (response.data_id) {
      session.dataId = response.data_id;
    }
    logger.log('saved ComposeSession', session.id);
    return session;
  }

  /*
   * Syncs local session with API sessions
   * Updates sessions with newer versions
   * Uploads legacy sessions
   * Marks deleted sessions for local deletion
   * Adds new sessions from API to local
   */
  async function syncComposeSessions(
    localSessions: ComposeSession[],
    apiSessions: ComposeSession[],
  ): Promise<{ updated: ComposeSession[]; deletedIds: string[] }> {
    const updatedSessions: ComposeSession[] = [];
    const deletedIds: string[] = [];
    const apiSessionsMap = new Map(apiSessions.map((s) => [s.id, s]));
    const localSessionsMap = new Map(localSessions.map((s) => [s.id, s]));

    // Go through local sessions and if any session is newer in the API, update it locally.
    for (const localSession of localSessions) {
      const apiSession = apiSessionsMap.get(localSession.id);
      if (apiSession) {
        if (
          apiSession.rev &&
          (!localSession.rev || apiSession.rev > localSession.rev)
        ) {
          updatedSessions.push(apiSession);
          logger.log(
            'Updated session with newer version from API:',
            localSession.id,
          );
        }
        // If a local session has no dataId, it was never uploaded to the API
        // so create a new API session, and update the local session with the dataId
      } else if (!localSession.dataId) {
        const savedSession = await saveComposeApiSession(localSession);
        updatedSessions.push(savedSession);
        logger.log('Saved local session to API:', localSession.id);
      } else {
        // If a session has a dataId but it didn't come back from the API, it was deleted
        // so mark it for local deletion
        // TODO (will): figure out a plan for safe deletions when sessions aren't coming back
        // deletedIds.push(localSession.id);
        // logger.log('Session deleted from API:', localSession.id);
      }
    }

    // Go through API sessions and add any new sessions that don't exist locally
    for (const apiSession of apiSessions) {
      if (!localSessionsMap.has(apiSession.id)) {
        updatedSessions.push(apiSession);
        logger.log('Added new session from API:', apiSession.id);
      }
    }

    return { updated: updatedSessions, deletedIds };
  }

  async function deleteComposeApiSession(session: ComposeSession) {
    if (!session.dataId) {
      return;
    }
    const args: context_engine.AssistDeleteUserDataArg = {
      data_id: session.dataId,
    };
    await callApiV2('contextEngineAssistApiDeleteUserData', args);
    logger.log('deleted ComposeSession', session.id);
    return;
  }

  async function loadComposeVoices(): Promise<ComposeVoice[]> {
    const voices = (await voicesStore.get(StorageKey.Voices)) || [];
    logger.log('loaded ComposeVoices', voices?.length);
    return voices || [];
  }

  async function loadComposeApiVoices(): Promise<ComposeVoice[]> {
    const [voices, teamVoices] = await Promise.all([
      loadAssistKVDataAPI<ComposeVoice>(StorageKey.Voices, parseVoiceData),
      loadAssistKVDataAPI<ComposeVoice>(
        StorageKey.Voices,
        parseVoiceData,
        AccessType.TEAM,
      ),
    ]);
    return [...teamVoices, ...voices];
  }

  async function saveComposeVoices(voices: ComposeVoice[]) {
    await voicesStore.set(StorageKey.Voices, voices);
    logger.log('saved ComposeVoices', voices.length);
  }

  async function saveComposeApiVoice(voice: ComposeVoice) {
    const args: context_engine.AssistSaveUserDataArg = {
      data_item: {
        user_data_type: StorageKey.Voices,
        user_data_key: voice.id,
        user_data: JSON.stringify(voice),
      },
      data_id: voice.dataId,
      access_type: {
        '.tag': voice.accessType || AccessType.INDIVIDUAL,
      },
    };
    const response = await callApiV2(
      'contextEngineAssistApiSaveUserData',
      args,
    );
    if (response.data_id) {
      voice.dataId = response.data_id;
    }
    logger.log('saved ComposeVoice', voice.id);
    return voice;
  }

  async function deleteComposeApiVoice(voice: ComposeVoice) {
    if (!voice.dataId) {
      return;
    }
    const args: context_engine.AssistDeleteUserDataArg = {
      data_id: voice.dataId,
    };
    await callApiV2('contextEngineAssistApiDeleteUserData', args);
    logger.log('deleted ComposeVoice', voice.id);
    return;
  }

  async function loadAssistTemplates(): Promise<AssistCustomTemplate[]> {
    const templates = (await templatesStore.get(StorageKey.Templates)) || [];
    logger.log('loaded AssistCustomTemplates', templates?.length);
    return templates || [];
  }

  async function loadAssistApiTemplates(): Promise<AssistCustomTemplate[]> {
    const [templates, teamTemplates] = await Promise.all([
      loadAssistKVDataAPI<AssistCustomTemplate>(
        StorageKey.Templates,
        parseTemplateData,
      ),
      loadAssistKVDataAPI<AssistCustomTemplate>(
        StorageKey.Templates,
        parseTemplateData,
        AccessType.TEAM,
      ),
    ]);
    return [...teamTemplates, ...templates];
  }

  async function saveAssistTemplates(templates: AssistCustomTemplate[]) {
    await templatesStore.set(StorageKey.Templates, templates);
    logger.log('saved AssistCustomTemplates', templates.length);
  }

  async function saveAssistApiTemplate(template: AssistCustomTemplate) {
    const args: context_engine.AssistSaveUserDataArg = {
      data_item: {
        user_data_type: StorageKey.Templates,
        user_data_key: template.id,
        user_data: JSON.stringify(template),
      },
      data_id: template.dataId,
      access_type: {
        '.tag': template.accessType || AccessType.INDIVIDUAL,
      },
    };
    const response = await callApiV2(
      'contextEngineAssistApiSaveUserData',
      args,
    );
    if (response.data_id) {
      template.dataId = response.data_id;
    }
    logger.log('saved AssistCustomTemplate', template.id);
    return template;
  }

  async function deleteAssistApiTemplate(template: AssistCustomTemplate) {
    if (!template.dataId) {
      return;
    }
    const args: context_engine.AssistDeleteUserDataArg = {
      data_id: template.dataId,
    };
    await callApiV2('contextEngineAssistApiDeleteUserData', args);
    logger.log('deleted AssistCustomTemplate', template.id);
    return;
  }

  const {
    loadAssistActions,
    saveAssistAction,
    deleteAssistAction,
    syncAssistActions,
    tearDownAssistActions,
    assistActionsObservable,
  } = createAssistActionsSync(actionsStore);
  const {
    getAllAssistActionSettings,
    saveAssistActionSetting,
    assistActionSettingsObservable,
  } = createAssistActionsSettings(actionsStore);

  async function tearDown() {
    await sessionsStore.clear();
    await voicesStore.clear();
    await templatesStore.clear();

    await tearDownAssistActions();
    await actionsStore.clear();
  }

  logoutService.registerLogoutCallback(ServiceId.COMPOSE, async () => {
    logger.debug('Handling logout in compose service');
    await tearDown();
    logger.debug('Done handling logout in compose service');
  });

  return services.provide(
    ServiceId.COMPOSE,
    {
      loadComposeSessions,
      loadComposeApiSessions,
      saveComposeSessions,
      saveComposeApiSession,
      deleteComposeApiSession,
      loadComposeVoices,
      loadComposeApiVoices,
      saveComposeVoices,
      saveComposeApiVoice,
      deleteComposeApiVoice,
      loadAssistTemplates,
      loadAssistApiTemplates,
      saveAssistTemplates,
      saveAssistApiTemplate,
      deleteAssistApiTemplate,
      syncComposeSessions,
      loadAssistActions,
      saveAssistAction,
      deleteAssistAction,
      assistActionsObservable,
      syncAssistActions,
      getAllAssistActionSettings,
      saveAssistActionSetting,
      assistActionSettingsObservable,
      tearDown,
    },
    [ServiceId.DBX_API],
  );
}
