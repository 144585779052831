// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/dcs_proto_message_str_clones.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, protoInt64 } from '@bufbuild/protobuf';

/**
 * *
 * Represents configs/proto/dropbox/proto/dash/dcs/search.proto > SearchInternalRequest
 * with only necessary fields. Update if we need new params.
 *
 * @generated from message context_engine.DcsSearchApiInteractionArg
 */
export class DcsSearchApiInteractionArg extends Message<DcsSearchApiInteractionArg> {
  /**
   * Using user_id instead of entire user object to include only necessary fields.
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: string query_text = 2;
   */
  queryText = '';

  /**
   * @generated from field: string query_filters_json_str = 3;
   */
  queryFiltersJsonStr = '';

  /**
   * @generated from field: string result_type_str = 4;
   */
  resultTypeStr = '';

  /**
   * @generated from field: string client_context_json_str = 5;
   */
  clientContextJsonStr = '';

  /**
   * @generated from field: string dev_json_str = 6;
   */
  devJsonStr = '';

  constructor(data?: PartialMessage<DcsSearchApiInteractionArg>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DcsSearchApiInteractionArg';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_id', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: 'query_text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'query_filters_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 4,
      name: 'result_type_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 5,
      name: 'client_context_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 6,
      name: 'dev_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DcsSearchApiInteractionArg {
    return new DcsSearchApiInteractionArg().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DcsSearchApiInteractionArg {
    return new DcsSearchApiInteractionArg().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DcsSearchApiInteractionArg {
    return new DcsSearchApiInteractionArg().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DcsSearchApiInteractionArg
      | PlainMessage<DcsSearchApiInteractionArg>
      | undefined,
    b:
      | DcsSearchApiInteractionArg
      | PlainMessage<DcsSearchApiInteractionArg>
      | undefined,
  ): boolean {
    return proto3.util.equals(DcsSearchApiInteractionArg, a, b);
  }
}

/**
 * @generated from message context_engine.DcsSearchApiInteractionResponse
 */
export class DcsSearchApiInteractionResponse extends Message<DcsSearchApiInteractionResponse> {
  /**
   * *
   * Represents configs/proto/dropbox/proto/dash/dcs/search.proto > SearchInternalResponse
   * with only necessary fields. Update if we need new params.
   *
   * @generated from field: string search_request_id = 1;
   */
  searchRequestId = '';

  /**
   * @generated from field: string query_results_json_str = 2;
   */
  queryResultsJsonStr = '';

  /**
   * @generated from field: string search_debug_output_json_str = 3;
   */
  searchDebugOutputJsonStr = '';

  constructor(data?: PartialMessage<DcsSearchApiInteractionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DcsSearchApiInteractionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'search_request_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'query_results_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 3,
      name: 'search_debug_output_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DcsSearchApiInteractionResponse {
    return new DcsSearchApiInteractionResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DcsSearchApiInteractionResponse {
    return new DcsSearchApiInteractionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DcsSearchApiInteractionResponse {
    return new DcsSearchApiInteractionResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | DcsSearchApiInteractionResponse
      | PlainMessage<DcsSearchApiInteractionResponse>
      | undefined,
    b:
      | DcsSearchApiInteractionResponse
      | PlainMessage<DcsSearchApiInteractionResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(DcsSearchApiInteractionResponse, a, b);
  }
}

/**
 * @generated from message context_engine.DcsMetadataApiInteractionArg
 */
export class DcsMetadataApiInteractionArg extends Message<DcsMetadataApiInteractionArg> {
  /**
   * *
   * Represents configs/proto/dropbox/proto/dash/dcs/get_link_metadata.proto > GetMetadataByIdRequest & GetLinkMetadataRequest
   * with only necessary fields. Update if we need new params.
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: repeated string ids = 2;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<DcsMetadataApiInteractionArg>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DcsMetadataApiInteractionArg';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_id', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    {
      no: 2,
      name: 'ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DcsMetadataApiInteractionArg {
    return new DcsMetadataApiInteractionArg().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DcsMetadataApiInteractionArg {
    return new DcsMetadataApiInteractionArg().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DcsMetadataApiInteractionArg {
    return new DcsMetadataApiInteractionArg().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | DcsMetadataApiInteractionArg
      | PlainMessage<DcsMetadataApiInteractionArg>
      | undefined,
    b:
      | DcsMetadataApiInteractionArg
      | PlainMessage<DcsMetadataApiInteractionArg>
      | undefined,
  ): boolean {
    return proto3.util.equals(DcsMetadataApiInteractionArg, a, b);
  }
}

/**
 * @generated from message context_engine.DcsMetadataApiInteractionResponse
 */
export class DcsMetadataApiInteractionResponse extends Message<DcsMetadataApiInteractionResponse> {
  /**
   * *
   * Represents configs/proto/dropbox/proto/dash/dcs/get_link_metadata.proto > GetMetadataByIdResponse & GetLinkMetadataResponse
   * with only necessary fields. Update if we need new params.
   *
   * @generated from field: string link_metadata_results_json_str = 1;
   */
  linkMetadataResultsJsonStr = '';

  constructor(data?: PartialMessage<DcsMetadataApiInteractionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DcsMetadataApiInteractionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'link_metadata_results_json_str',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DcsMetadataApiInteractionResponse {
    return new DcsMetadataApiInteractionResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DcsMetadataApiInteractionResponse {
    return new DcsMetadataApiInteractionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DcsMetadataApiInteractionResponse {
    return new DcsMetadataApiInteractionResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | DcsMetadataApiInteractionResponse
      | PlainMessage<DcsMetadataApiInteractionResponse>
      | undefined,
    b:
      | DcsMetadataApiInteractionResponse
      | PlainMessage<DcsMetadataApiInteractionResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(DcsMetadataApiInteractionResponse, a, b);
  }
}
