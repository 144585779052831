import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import {
  deleteWorkflowAgent,
  getWorkflowAgent,
  loadWorkflowAgents,
  saveWorkflowAgent,
} from '..';

import type { WorkflowAgent } from '../types';

const workflowAgentsAtom = atom<WorkflowAgent[]>([]);

export const useWorkflowAgents = () => {
  const [agents, setAgents] = useAtom(workflowAgentsAtom);

  useEffect(() => {
    const fetchAgents = async () => {
      const fetchedAgents = await loadWorkflowAgents();
      setAgents(fetchedAgents);
    };
    fetchAgents();
  }, [setAgents]);

  const deleteAgent = useCallback(
    async (agent: WorkflowAgent) => {
      await deleteWorkflowAgent(agent);
      setAgents((currentAgents) =>
        currentAgents.filter((a) => a.id !== agent.id),
      );
    },
    [setAgents],
  );

  const createAgent = useCallback(
    async (agent: WorkflowAgent) => {
      const isEdit = agent.dataId !== undefined;
      const newAgent = await saveWorkflowAgent(agent);
      if (isEdit) {
        setAgents((currentAgents) =>
          currentAgents.map((a) => (a.id === agent.id ? newAgent : a)),
        );
      } else {
        setAgents((currentAgents) => [...currentAgents, newAgent]);
      }
    },
    [setAgents],
  );

  return { agents, deleteAgent, createAgent };
};

export const useWorkflowAgent = (agentId?: string) => {
  const [agent, setAgent] = useState<WorkflowAgent | undefined>(undefined);

  useEffect(() => {
    if (agentId) {
      const fetchAgent = async () => {
        const agentData = await getWorkflowAgent(agentId);
        setAgent(agentData);
      };
      fetchAgent();
    }
  }, [agentId]);

  return agent;
};

export const useWorkflowAgentsEnabled = () => {
  const isWorkflowAgentsEnabled = useFeatureFlagValueBool(
    'dash_assist_2025_03_11_workflow_agents_tool',
  );
  return isWorkflowAgentsEnabled;
};
