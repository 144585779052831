import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Add_DashConnectedAccount } from '@mirage/analytics/events/types/add_dash_connected_account';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { tagged } from '@mirage/service-logging';
import { useConnectConnector } from '@mirage/settings/hooks/connectors/useConnectConnector';
import { getConnectorDisplayConfig } from '@mirage/shared/connectors';
import { ConnectorIcon } from '@mirage/shared/icons';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import styles from './AppChip.module.css';

import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';
import type { ConnectorName } from '@mirage/shared/connectors';
import type { UIConnector } from '@mirage/shared/types';

const MAX_SPINNER_MS = 10000;
const appChipLogger = tagged('AppChip');

export const AppChip = ({
  connector,
  grey,
  actionSurface = 'start_page',
  actionSurfaceComponent = 'connector_upsell_module',
  onConnected,
  className,
  showSpinner = false,
}: {
  connector: UIConnector;
  grey?: boolean;
  actionSurface: DashActionSurface;
  actionSurfaceComponent: ActionSurfaceComponent;
  className?: string;
  showSpinner?: boolean;
  onConnected?: (id: string) => void;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const connectConnector = useConnectConnector({
    eventProps: { featureLine: 'connector' },
  });
  const connectorName = connector.id_attrs?.type as ConnectorName;
  const connectorConfig = getConnectorDisplayConfig(connectorName);
  const [connecting, setConnecting] = useState(false);

  const handleConnectClick = useCallback(async () => {
    setConnecting(true);
    // Display the loading spinner for 10 seconds max
    const timer = setTimeout(() => {
      setConnecting(false);
    }, MAX_SPINNER_MS);

    try {
      const response = await connectConnector(connector);
      if (response.success) {
        if (onConnected) onConnected(connector.id_attrs?.type ?? '');
        reportPapEvent(
          PAP_Add_DashConnectedAccount({
            dashActionSurface: actionSurface,
            actionSurfaceComponent: actionSurfaceComponent,
          }),
        );
      }

      showSnackbar({ title: response.message });
    } catch (e) {
      appChipLogger.error(e);
    } finally {
      setConnecting(false);
    }

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <button
      className={classnames(styles.appChip, grey && styles.grey, className)}
      key={connectorName}
      onClick={handleConnectClick}
      aria-label={`Connect app ${
        connector.branding?.display_name || connectorConfig.displayName
      }`}
    >
      <div className={styles.appInfo}>
        <ConnectorIcon
          size={24}
          connectorName={
            connector.id_attrs?.type || connectorConfig.connectorName
          }
          iconUrl={connector.branding?.icon_src}
        />
        <Text isBold size="small" variant="label">
          {connector.branding?.display_name || connectorConfig.displayName}
        </Text>
      </div>
      {connecting && showSpinner ? (
        <Spinner size="small" />
      ) : (
        <IconButtonWithTooltip
          tooltipProps={{
            title: i18n.t('connect_app'),
          }}
          shape="circular"
          variant="opacity"
          aria-label={i18n.t('aria_add_icon', { connectorName })}
          onClick={handleConnectClick}
          style={{
            background: 'var(--dig-color__text__base)',
            color: 'var(--dig-color__background__base)',
            minWidth: 24,
          }}
          size="small"
          data-uxa-log={createUxaElementId('add_connector_button', {
            actionSurfaceComponent,
            featureLine: 'answers',
          })}
        >
          <UIIcon src={AddLine} />
        </IconButtonWithTooltip>
      )}
    </button>
  );
};
