import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import {
  ONE_DAY_IN_MILLIS,
  ONE_HOUR_IN_MILLIS,
} from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import { getFeatureRingAPI } from './api';

import type { FeatureRing, FeatureRingSettings } from '../types';
import type { dash_async } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { KVStorage } from '@mirage/storage';

export type Service = ReturnType<typeof featureRingSettingsService>;
interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

const SYNC_JOB_NAME = 'feature-ring-sync';
const SYNC_INTERVAL_MS = ONE_HOUR_IN_MILLIS;

// This value may directly impact page load speed, so use the cached copy
// first, then the sync job will update it quickly.
const RING_EXPIRE_MS = ONE_DAY_IN_MILLIS;

const logger = tagged(ServiceId.FEATURE_RING_SETTINGS);

function isCacheValid(
  featureRingSettings: Partial<FeatureRingSettings> | undefined,
) {
  return (
    featureRingSettings?.rings &&
    featureRingSettings?.lastFetchedMs &&
    featureRingSettings?.lastFetchedMs > Date.now() - RING_EXPIRE_MS
  );
}

export function featureRingSettingsService(
  rawStorage: KVStorage<FeatureRingSettings>,
  dbxApiService: DbxApiServiceContract,
) {
  const api = getFeatureRingAPI(dbxApiService);

  const getFeatureRingSettings = async (
    refresh: boolean,
  ): Promise<FeatureRingSettings> => {
    const fetchAndCache = async () => {
      const featureRingSettings = await api.getFeatureRingSettings();
      rawStorage.set('rings', featureRingSettings.rings);
      rawStorage.set('default', featureRingSettings.default);
      rawStorage.set('override', featureRingSettings.override);
      rawStorage.set('lastFetchedMs', featureRingSettings.lastFetchedMs);
      logger.debug('Fetched and saved ring settings:', featureRingSettings);
      return featureRingSettings;
    };

    if (!refresh) {
      const cachedSettings = await rawStorage.getAll();
      if (isCacheValid(cachedSettings)) {
        logger.info('Using cached ring settings', cachedSettings);
        return cachedSettings as FeatureRingSettings;
      }
    }

    logger.info('Fetching ring settings from network...');
    return fetchAndCache();
  };

  const setOverride = async (ring: FeatureRing) => {
    await api.setFeatureRingOverride({ '.tag': ring.id } as dash_async.Ring);
    await rawStorage.set('override', ring);
    const result = await getFeatureRingSettings(false);
    return {
      ...result,
      override: ring,
    };
  };

  const clearOverride = async (): Promise<FeatureRingSettings> => {
    await api.setFeatureRingOverride(null);
    await rawStorage.set('override', undefined);
    const result = await getFeatureRingSettings(false);
    return {
      ...result,
      override: undefined,
    };
  };

  async function getCurrentFeatureRing(): Promise<string | undefined> {
    const settings: FeatureRingSettings = await getFeatureRingSettings(false);
    return settings?.override?.id ?? settings?.default?.id;
  }

  const startSyncFeatureRingSettings = async () => {
    register(
      SYNC_JOB_NAME,
      SYNC_INTERVAL_MS,
      // Update the ring settings quickly after page load.
      /* runOnStart= */ true,
      async () => {
        await getFeatureRingSettings(true);
      },
    );
  };

  const cancelSyncFeatureRingSettings = async () => {
    unregister(SYNC_JOB_NAME);
  };

  const getFeatureRingFromCacheForLogging = async (): Promise<
    string | undefined
  > => {
    const cachedSettings = await rawStorage.getAll();
    if (isCacheValid(cachedSettings)) {
      return cachedSettings?.override?.id ?? cachedSettings?.default?.id;
    }
    return undefined;
  };

  const tearDown = async (): Promise<void> => {
    await rawStorage.clear();
    cancelSyncFeatureRingSettings();
  };

  return {
    getFeatureRingSettings,
    setOverride,
    clearOverride,
    getCurrentFeatureRing,
    startSyncFeatureRingSettings,
    cancelSyncFeatureRingSettings,
    getFeatureRingFromCacheForLogging,
    tearDown,
  };
}

export default function provideFeatureRingSettingsService(
  rawStorage: KVStorage<FeatureRingSettings>,
  dbxApiService: DbxApiServiceContract,
) {
  return services.provide<Service>(
    ServiceId.FEATURE_RING_SETTINGS,
    featureRingSettingsService(rawStorage, dbxApiService),
    [ServiceId.DBX_API],
  );
}
