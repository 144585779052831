import { fetchChannelRecommendations } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import { CacheKey } from '@mirage/service-typeahead-search/service/typeahead-cache';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';
import { shouldSyncRecommendations } from './utils/should-sync-recommendations';

import type { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';

const logger = tagged('typeahead/sync-channel-recommendations');

const SYNC_CHANNEL_RECOMMENDATIONS_JOB_NAME = 'channel-recommendations-sync';
export const SYNC_INTERVAL_MS = ONE_MINUTE_IN_MILLIS * 60 * 6;

// TODO this follows the pattern of sync-people-recommendations and sync-recommendations
// maybe worth making this pattern shared
export function start(cache: TypeaheadCache) {
  register(SYNC_CHANNEL_RECOMMENDATIONS_JOB_NAME, SYNC_INTERVAL_MS, true, () =>
    sync(cache),
  );
}
export function cancel() {
  unregister(SYNC_CHANNEL_RECOMMENDATIONS_JOB_NAME);
}
// sync, clear, and insert logic
const metrics = namespace('typeahead');
export async function sync(cache: TypeaheadCache) {
  try {
    const lastChannelRecommendationsSyncMs =
      await cache.getLastChannelRecommendationsSyncMs();

    if (
      shouldSyncRecommendations(
        lastChannelRecommendationsSyncMs,
        'channel-recommendations',
        SYNC_INTERVAL_MS,
      )
    ) {
      const channelRecommendations = await fetchChannelRecommendations();
      logger.debug('channelRecommendations', channelRecommendations);

      metrics.stats('sync/count', channelRecommendations.length);
      metrics.counter('sync/status', 1, {
        status: 'success',
      });

      logger.debug(
        `fetchChannelRecommendations`,
        channelRecommendations.slice(0, 3),
      );

      await cache.clear(CacheKey.ChannelRecommendations);
      await cache.cacheChannelRecommendations(channelRecommendations);
      await cache.saveChannelRecommendationsSyncedMs();
    }
  } catch (error) {
    logger.error(`Error syncing channel recommendations`, error);
    metrics.counter('sync/status', 1, {
      status: 'error',
    });
    throw error;
  }
}
