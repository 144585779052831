import { Button } from '@dropbox/dig-components/buttons';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import {
  isQuestionAndAnswerSource,
  Source,
} from '@mirage/conversations/Source';
import {
  FeedbackChip,
  feedbackConfig,
} from '@mirage/search/SearchResults/ResultRow/ResultAnnotations';
import { useState } from 'react';
import styles from '../AnswersAnnotationModal.module.css';

import type { AnnotationScore } from '@mirage/search/SearchResults/ResultRow/ResultAnnotations';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';

type Scores = Record<string, AnnotationScore | undefined>;

type Props = {
  sources: Array<SearchResult | QuestionAndAnswerSource>;
  scores: Scores;
  handleSelect: (resultUuid: string, score: AnnotationScore) => void;
  handleSourceClick?: (source: SearchResult | QuestionAndAnswerSource) => void;
};

const NUM_SOURCES_TO_SHOW = 5;

export function SourceAnnotations({
  sources,
  scores,
  handleSelect,
  handleSourceClick,
}: Props) {
  const [sourceLimit, setSourceLimit] = useState(NUM_SOURCES_TO_SHOW);

  if (!sources.length) {
    return null;
  }

  return (
    <FormRow>
      <FormLabel subtext="(Optional)">
        How relevant is each source to the question?
      </FormLabel>
      {sources.slice(0, sourceLimit).map((source) => {
        const id = isQuestionAndAnswerSource(source)
          ? source.id3p
          : source.uuid;
        return (
          <SourceAnnotationRow
            key={id}
            source={source}
            selected={scores?.[id]}
            handleSelect={handleSelect}
            onSourceClick={handleSourceClick}
          />
        );
      })}
      {sources.length > sourceLimit ? (
        <Button
          onClick={() => setSourceLimit(sources.length)}
          withDropdownIcon
          type="button"
          variant="transparent"
        >
          Show all
        </Button>
      ) : sourceLimit > NUM_SOURCES_TO_SHOW ? (
        <Button
          onClick={() => setSourceLimit(NUM_SOURCES_TO_SHOW)}
          type="button"
          variant="transparent"
        >
          Show less
        </Button>
      ) : null}
    </FormRow>
  );
}

type SourceRowProps = {
  source: SearchResult | QuestionAndAnswerSource;
  selected: AnnotationScore | undefined;
  handleSelect: (resultUuid: string, score: AnnotationScore) => void;
  onSourceClick?: (source: SearchResult | QuestionAndAnswerSource) => void;
};

function SourceAnnotationRow({
  source,
  selected,
  handleSelect,
  onSourceClick,
}: SourceRowProps) {
  const id = isQuestionAndAnswerSource(source) ? source.id3p : source.uuid;
  return (
    <div className={styles.sourceRow} data-testid="annotation-source-row">
      <Source
        source={source}
        sourceMaxWidth="160px"
        sourceWidth="160px"
        onClick={onSourceClick}
        onQASourceClick={onSourceClick}
      />
      <div className={styles.feedback}>
        {feedbackConfig.map((item) => (
          <FeedbackChip
            key={item.type}
            item={item}
            selectedScore={selected}
            handleSelect={() => handleSelect(id, item.type)}
            variant="standard"
          />
        ))}
      </div>
    </div>
  );
}
