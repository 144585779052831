import { Text } from '@dropbox/dig-components/typography';
import { getTimeAgoStringFromTimestamp } from '@mirage/search/SearchResults/util/resultUtil';
import { last, upperCase } from 'lodash';

import type { SearchResult } from '@mirage/shared/search/search-result';

export const MultimediaResultMetaInfo = ({
  result,
}: {
  result: SearchResult;
}) => {
  return (
    <Text color="subtle" size="small">
      {upperCase(last(result.title.split('.')))} •{' '}
      {getTimeAgoStringFromTimestamp(result.updatedAtMs, true)}
    </Text>
  );
};
