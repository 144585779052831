import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useRef } from 'react';
import { AddSourcesContent } from './AddSourcesContent';
import styles from './AddSourcesModal.module.css';

import type { AddSourcesContentProps } from './AddSourcesContent';

type AddSourcesModalProps = AddSourcesContentProps;

export const AddSourcesModal = memo(
  ({
    sources,
    sourcesContentCache,
    addSource,
    removeSource,
    onRequestClose,
    logComposeEvent,
  }: AddSourcesModalProps) => {
    const isMobile = useIsMobileSize();
    const modalContentRef = useRef<HTMLDivElement>(null);

    return (
      <Modal
        open
        width={694}
        isCentered
        onRequestClose={onRequestClose}
        withCloseButton="close"
        className={styles.AddSourcesModalWindow}
      >
        <DashThemeProvider>
          <Modal.Body
            ref={modalContentRef}
            className={classnames(
              styles.AddSourcesModalBody,
              isMobile && styles.AddSourcesModalBodyMobile,
            )}
          >
            <AddSourcesContent
              sources={sources}
              sourcesContentCache={sourcesContentCache}
              addSource={addSource}
              removeSource={removeSource}
              onRequestClose={onRequestClose}
              logComposeEvent={logComposeEvent}
            />
            <div className={styles.AddSourcesModalFooter}>
              <div className={styles.AddSourcesModalFooterLeft}>
                <Text
                  monospace
                  color="subtle"
                  size="small"
                  className={styles.AddSourcesModalEsc}
                >
                  {i18n.t('esc')}
                </Text>
                <Text monospace color="subtle" size="small">
                  {i18n.t('close')}
                </Text>
              </div>
              <div className={styles.AddSourcesModalFooterRight}>
                <Text size="small" color="subtle">
                  {i18n.t('compose_add_sources_count', {
                    count: sources.length,
                    plural: sources.length === 1 ? '' : 's',
                  })}
                </Text>
                <Button
                  size="medium"
                  variant="primary"
                  disabled={sources.length === 0}
                  onClick={onRequestClose}
                >
                  {i18n.t('done')}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </DashThemeProvider>
      </Modal>
    );
  },
);

AddSourcesModal.displayName = 'AddSourcesModal';
