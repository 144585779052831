import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import graphic from './GmailConnect.gif';
import styles from './GmailConnectPrompt.module.css';

type Props = Readonly<{
  onContinue: () => Promise<void>;
  onCancel: () => void;
}>;

export function GmailConnectPrompt({ onContinue, onCancel }: Props) {
  return (
    <Modal
      open
      onRequestClose={onCancel}
      aria-labelledby="gmail-connector-prompt-modal-title"
      withCloseButton="Close"
    >
      <Modal.Content className={styles.modal}>
        <img
          src={graphic}
          width="500px"
          className={styles.image}
          alt={i18n.t('gmail_connect_subtitle')}
        />
        <Title weightVariant="emphasized">
          {i18n.t('gmail_connect_title')}
        </Title>
        <div className={styles.subtitleContainer}>
          <Text>{i18n.t('gmail_connect_subtitle')}</Text>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            variant="primary"
            size="xlarge"
            fullWidth
            onClick={onContinue}
          >
            {i18n.t('continue_button')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
