import { LabelGroup } from '@dropbox/dig-components/combinations';
import { Text } from '@dropbox/dig-components/typography';
import { Stack } from '@dropbox/dig-foundations';
import { fetchMultimediaPreview } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { SearchResultIcon } from '@mirage/shared/icons';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { MultimediaResultItemImageSkeleton } from './MultimediaResultItemSkeleton';
import { MultimediaResultMetaInfo } from './MultimediaResultMetaInfo';
import styles from './MultimediaSearchResults.module.css';

import type { SearchResult } from '@mirage/shared/search/search-result';
import type { ReactEventHandler, SyntheticEvent } from 'react';

export const MultimediaPreview = ({
  result,
  className,
  size = 256,
  onLoad,
  large = false,
  hideTitle = false,
}: {
  result: SearchResult;
  className?: string | undefined;
  size?: number;
  onLoad?: ReactEventHandler<HTMLImageElement> | undefined;
  large?: boolean;
  hideTitle?: boolean;
}) => {
  const [state, setState] = useState<{
    base64: string | undefined;
    loading: boolean;
    hasError: boolean;
  }>({ base64: undefined, loading: true, hasError: false });
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const previewUrl = result.media?.previewUrl || result.previewUrl;

  useEffect(() => {
    if (!previewUrl) {
      setState((prev) => ({
        ...prev,
        hasError: true,
        loading: false,
      }));
      setImageLoaded(true);
    }
  }, [previewUrl]);

  useEffect(() => {
    if (state.hasError) return;
    if (state.base64) return;
    if (!previewUrl) return;

    const fetchPreview = async () => {
      try {
        const base64Preview = await fetchMultimediaPreview(
          `${previewUrl}`,
          EnvCtx?.surface === 'desktop',
          512,
        );

        // The preview service was unable to extract the image
        if (base64Preview?.startsWith('data:text/html')) {
          setState((prev) => ({
            ...prev,
            loading: false,
            hasError: true,
          }));
          setImageLoaded(true);
          return;
        }

        setState((prev) => ({
          ...prev,
          loading: false,
          base64: base64Preview,
        }));
      } catch (e) {
        setState((prev) => ({
          ...prev,
          loading: false,
          hasError: true,
        }));
      }
    };
    fetchPreview();
  }, [setState, state, previewUrl, size]);

  const imageOnLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    setTimeout(() => {
      // Allow time for the image to render before removing the skeleton
      setImageLoaded(true);
    }, 50);
    onLoad?.(e);
  };

  const image = (
    <img
      alt={result.title}
      src={state.base64}
      onLoad={imageOnLoad}
      className={classNames(
        className,
        !imageLoaded ? styles.multimediaImageLoading : '',
      )}
    />
  );

  const images = (
    <>
      {!imageLoaded && <MultimediaResultItemImageSkeleton large={large} />}
      {state.hasError && !state.loading ? (
        <div className={styles.multimediaImageError}>
          <Text variant="label" size="small" color="subtle">
            {i18n.t('image_preview_error')}
          </Text>
        </div>
      ) : !hideTitle ? (
        <div
          className={classNames(
            styles.imageBorder,
            !imageLoaded ? styles.multimediaImageLoading : '',
          )}
        >
          {image}
        </div>
      ) : (
        image
      )}
    </>
  );

  if (hideTitle) {
    return images;
  }

  return (
    <Stack gap={hideTitle ? '0' : '8px'}>
      {images}
      {!hideTitle && (
        <LabelGroup
          withText={result.title}
          align="top"
          withLeftAccessory={
            <span className={styles.resultIcon}>
              <SearchResultIcon size={16} result={result} />
            </span>
          }
          withSubtext={<MultimediaResultMetaInfo result={result} />}
        ></LabelGroup>
      )}
    </Stack>
  );
};
