import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Category } from './types';

import type { Categories } from './types';

export const DefaultCategoryState: Categories = {
  [Category.AllCookies]: false,
  [Category.Analytics]: false,
  [Category.MarketingAdvertising]: false,
  [Category.PerformanceFunctionality]: false,
  [Category.SocialMediaAdvertising]: false,
  [Category.StrictlyNecessary]: false,
};

const privacyCategoryDefaultState = {
  ...DefaultCategoryState,
  userInteracted: false,
};

export const privacyCategoryStateAtom = atomWithStorage(
  'privacyCategoryState',
  privacyCategoryDefaultState,
);

// height in "px" units. 0 means the banner is not visible
export const privacyBannerHeightAtom = atom(0);

export const isCookieConsentCheckedAtom = atomWithStorage(
  'isCookieConsentChecked',
  false,
);
