// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3767
import type { PAPEventGeneric } from "../base/event";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";
import type { BrowserName } from "../enums/browser_name";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { Environment } from "../enums/environment";
import type { FeatureLine } from "../enums/feature_line";
import type { OperatingSystem } from "../enums/operating_system";

// Event_name: 'click.template_list_item'
// Description: Clicking to view one of the items in the assist templates menu
// Owner: dash-web
export type Click_TemplateListItem = PAPEventGeneric<
  'dash_assist',
  'click',
  'template_list_item',
  {
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // fresh id that gets generated for every new session or instance of the chat window
    chatSessionId?: string;
  }
>;

export function PAP_Click_TemplateListItem(properties?: Click_TemplateListItem['properties']): Click_TemplateListItem {
  return <Click_TemplateListItem>{
    class: 'dash_assist',
    action: 'click',
    object: 'template_list_item',
    properties,
  };
}
