import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';

import type { ForegroundReason } from '@mirage/analytics/events/enums/foreground_reason';
import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { WellKnownShortcutUUID } from '@mirage/shared/search/url-shortcut';

export interface Service {
  openURL(url: string): Promise<void>;
  openResult(
    result:
      | SearchResult
      | QuestionAndAnswerSource
      | SummarizableResult
      | Recommendation,
  ): Promise<void>;

  /**
   * WARN: Only use for local apps and local files. Be weary of untrusted user
   * input! Electron `openExternal` does no sanitization!
   */
  openLocalPath(path: string): Promise<void>;

  /**
   * Attempts to run the local application associated with the shortcut UUID if possible
   * @returns true if the shortcut was successfully opened locally, false if it wasn't able to launch for any reason
   */
  runLocalLaunchCommandForShortcut(
    shortcutUuid: WellKnownShortcutUUID,
  ): Promise<boolean>;

  copyToClipboard(text: string): Promise<void>;

  /**
   * Only use for desktop application.
   */
  openPersistentApp(reason: ForegroundReason): Promise<void>;
}

export type PlatformActionMode = 'direct' | 'background';

export interface PlatformActionsAdapter extends Service {
  /**
   * Sets the action mode for each app.
   * - Electron: background
   * - Webapp: direct
   */
  getActionMode(): PlatformActionMode;
}

let syncService: PlatformActionsAdapter | undefined;

export function getSyncService() {
  return syncService;
}

export default function platformActions(adapter: PlatformActionsAdapter) {
  if (adapter.getActionMode() === 'direct') {
    syncService = adapter;
  }

  services.provide<Service>(ServiceId.PLATFORM_ACTIONS, adapter, []);
}
