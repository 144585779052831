import { Avatar } from '@dropbox/dig-components/avatar';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import styles from './CommentReadonlyBody.module.css';

import type { CommentAuthor } from '@mirage/service-comments/types';

type CommentReadonlyBodyProps = {
  author: CommentAuthor;
  content: string;
  currentUserHasWritePermissions?: boolean;
  currentUserIsStackOwner?: boolean;
  onDeleteClick: () => void;
  onEditClick: () => void;
  timestamp?: number;
};

export const CommentReadonlyBody = ({
  author,
  content,
  currentUserHasWritePermissions,
  currentUserIsStackOwner,
  onDeleteClick,
  onEditClick,
  timestamp,
}: CommentReadonlyBodyProps) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const readOnlyCommentRef = useCallback((node) => {
    if (!node) return;

    // This is garbage collected when the component unmounts
    const resizeObserver = new ResizeObserver(() => {
      setIsTextTruncated(node.scrollHeight > node.clientHeight);
    });

    resizeObserver.observe(node);
  }, []);

  return (
    <>
      <Text
        className={classnames(
          styles.truncatedCommentContent,
          isTextExpanded && styles.expanded,
          isTextTruncated && styles.truncated,
        )}
        // Used to identify comment text during drag and drop
        data-stack-comment-content
        ref={readOnlyCommentRef}
      >
        {isTextTruncated && (
          <Button
            className={styles.showMoreButton}
            onClick={() => setIsTextExpanded(true)}
            size="medium"
            tone="neutral"
            variant="transparent"
          >
            {i18n.t('stack_item_comment_show_more')}
          </Button>
        )}
        {content}
      </Text>
      <Box className={styles.commentMetadata}>
        <Box display="inline-flex">
          <Box marginRight="4">
            <Avatar src={author?.photoUrl} size="xsmall">
              {author?.initials}
            </Avatar>
          </Box>
          <Text color="subtle" size="small">
            {author.isCurrentUser
              ? i18n.t('stack_item_author_placeholder')
              : author.displayName}
          </Text>
        </Box>
        <Text color="subtle" size="small">
          {timestamp &&
            getTimeAgoString(timestamp, {
              abbreviateTime: true,
              abbreviateDate: true,
            })}
        </Text>
        {author.isCurrentUser && currentUserHasWritePermissions && (
          <Button
            className={styles.commentInlineButton}
            hasNoUnderline
            onClick={onEditClick}
            size="small"
            tone="neutral"
            variant="transparent"
          >
            {i18n.t('edit')}
          </Button>
        )}
        {(currentUserIsStackOwner || author.isCurrentUser) &&
          currentUserHasWritePermissions && (
            <Button
              className={styles.commentInlineButton}
              hasNoUnderline
              onClick={onDeleteClick}
              size="small"
              tone="destructive"
              variant="transparent"
            >
              {i18n.t('delete')}
            </Button>
          )}
        {isTextTruncated && !isTextExpanded && (
          <Button
            className={styles.safariShowMoreButton}
            onClick={() => setIsTextExpanded(true)}
            size="medium"
            tone="neutral"
            variant="transparent"
          >
            {i18n.t('stack_item_comment_show_more')}
          </Button>
        )}
        {isTextExpanded && (
          <Button
            className={styles.showLessButton}
            onClick={() => setIsTextExpanded(false)}
            size="medium"
            tone="neutral"
            variant="transparent"
          >
            {i18n.t('stack_item_comment_show_less')}
          </Button>
        )}
      </Box>
    </>
  );
};
