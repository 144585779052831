import {
  BOLT_ORIGIN_DEVBOX_TMPL,
  BOLT_ORIGIN_PROD,
  getDevboxUrl,
  GROWTHBOOK_API_KEY_DEVBOX,
  GROWTHBOOK_API_KEY_PROD,
  SPRIG_ENVIRONMENT_ID_DEV,
  SPRIG_ENVIRONMENT_ID_PROD,
} from "@mirage/shared/util/build-vars";
import type { BuildChannel } from "@mirage/shared/util/types";

export type Environment =
  | "devbox"
  | "devprod"
  | "canary"
  | "prototype"
  | "staging"
  | "prod";

// All our custom application environment variables must be prefixed with "REACT_APP_"

const ENV = process.env.REACT_APP_ENV as Environment;

if (process.env.NODE_ENV !== "test" && !ENV) {
  throw new Error(`REACT_APP_ENV must be specified during runtime`);
}

// Use REACT_APP_DEVBOX to specify a custom devbox hostname.
// E.g. export REACT_APP_DEVBOX=joshwilson-coder-dash-grande.dev.corp.dropbox.com
//   or export REACT_APP_DEVBOX=joshwilson-coder-dash-grande
// Otherwise use REACT_APP_USER to use the default devbox hostname
// of "$USER-coder-dbx.dev.corp.dropbox.com".
// E.g. export REACT_APP_USER=${USER}
const USER = process.env.REACT_APP_USER;
const DEVBOX =
  process.env.REACT_APP_DEVBOX?.replace(/(\.dev\.corp\.dropbox\.com$)/, "") ||
  (USER && `${USER}-coder-dbx`);
if (ENV === "devbox" && !DEVBOX) {
  throw new Error(
    "REACT_APP_USER or REACT_APP_DEVBOX must be specified for devbox runtime",
  );
}

// This must be a non-empty string to support Amplify environment variables
const REACT_APP_OAUTH_REDIRECT_URL =
  process.env.REACT_APP_OAUTH_REDIRECT_URL === "null"
    ? ""
    : process.env.REACT_APP_OAUTH_REDIRECT_URL;

if (ENV in ["staging", "prod"] && REACT_APP_OAUTH_REDIRECT_URL) {
  throw new Error(
    `REACT_APP_OAUTH_REDIRECT_URL cannot be used in non-development environments`,
  );
}

/** Get with default value that can be overridden by environment. */
function getValue<T>(
  overrides: { DEFAULT: T } & Partial<Record<Environment, T>>,
): T {
  return overrides[ENV] || overrides.DEFAULT;
}

/** Get value in given map where all environments must be specified. */
function valueForEnv<T extends string>(map: Record<Environment, T>): T {
  return map[ENV];
}

const buildChannel = valueForEnv<BuildChannel>({
  devbox: "development",
  devprod: "development",
  canary: "canary",
  staging: "staging",
  prod: "production",
  prototype: "canary",
});

function getBuildVersion(): string {
  if (process.env.REACT_APP_VERSION) {
    return process.env.REACT_APP_VERSION;
  }

  if (process.env.NODE_ENV === "test") {
    return "test-version";
  }

  throw new Error(`REACT_APP_VERSION must be specified during build`);
}

export const Config = {
  ENV,
  BUILD_VERSION: getBuildVersion(),

  BUILD_CHANNEL: buildChannel,

  DEVBOX,

  API_APP_ID: getValue({
    DEFAULT: 12426560,
    devbox: 12426560,
    prod: 2658945,
  }),

  OAUTH_CLIENT_KEY: getValue({
    DEFAULT: "72vmju6skshq5bn",
    devbox: "devboxdashdevkey",
    prod: "uhmfsztolr8jrh6",
  }),

  OAUTH_CLIENT_SECRET: getValue({
    DEFAULT: "8oe806y5d2a2tih",
    devbox: "devboxdashdevsecret",
    prod: "731yighrg3dzyuk",
  }),

  /** sprig api token */
  SPRIG_ENVIRONMENT_ID: getValue({
    DEFAULT: SPRIG_ENVIRONMENT_ID_DEV,
    prod: SPRIG_ENVIRONMENT_ID_PROD,
  }),

  /** datadog api information */
  DATADOG_CLIENT_TOKEN: "pubabd59852e68e81b5936317b239942ba9",
  DATADOG_SERVICE_ID: "dash-web",

  GROWTHBOOK_CLIENT_KEY: getValue({
    DEFAULT: GROWTHBOOK_API_KEY_DEVBOX, // dev
    staging: GROWTHBOOK_API_KEY_PROD,
    prod: GROWTHBOOK_API_KEY_PROD,
  }),

  API_SERVER: getValue({
    DEFAULT: "",
    devbox: `${DEVBOX}.dev.corp.dropbox.com`,
  }),

  // For webapp, the oauth redirect url is always the current host.
  STAGE_HOST: location.host,

  BOLT_URL: getValue({
    DEFAULT: BOLT_ORIGIN_PROD,
    devbox: getDevboxUrl(BOLT_ORIGIN_DEVBOX_TMPL, DEVBOX ?? ""),
  }),

  // Should only be set for prototype apps.
  // Lets one piggy-back off an already deployed front-end app's oauth flow.
  // Makes use of the redirectToPathEncoded OAuth "state" parameter.
  // This should be the whole literal url of the prototype AWS Lambda
  // that we've provided for the Dash Development Dropbox API app's Oauth2
  // redirect list.
  // Its current value is https://5mzqqh39h4.execute-api.us-east-2.amazonaws.com/prototype/AmplifyDashWebPrototypesOAuthRedirect
  OAUTH_REDIRECT_URL: getValue({
    DEFAULT: "",
    devbox: REACT_APP_OAUTH_REDIRECT_URL,
    devprod: REACT_APP_OAUTH_REDIRECT_URL,
    canary: REACT_APP_OAUTH_REDIRECT_URL,
    prototype: REACT_APP_OAUTH_REDIRECT_URL,
  }),
};
