import { OnboardingSurveyQuestionFieldType } from '../types';

import type { OnboardingSurveyQuestion } from '../types';

const JTBD_QUESTION: OnboardingSurveyQuestion = {
  questionId: 'JOBS_TO_BE_DONE_V1',
  questionType: OnboardingSurveyQuestionFieldType.Select,
  label: '',
  isTeamBased: false,
  questionValues: [],
};

export const JTBD_QUESTIONS = [JTBD_QUESTION];
