import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import {
  activeStackMutationRequestIdAtom,
  activeStackSessionIdAtom,
} from '../ActiveStack/atoms';

export const useMutationRequestId = (mutationRequestId?: string) => {
  const setMutationRequestId = useSetAtom(activeStackMutationRequestIdAtom);

  useEffect(() => {
    setMutationRequestId(mutationRequestId || null);
  }, [mutationRequestId, setMutationRequestId]);

  return [mutationRequestId, setMutationRequestId];
};

export const useSessionId = (sessionId?: string) => {
  const setSessionId = useSetAtom(activeStackSessionIdAtom);

  useEffect(() => {
    setSessionId(sessionId ?? null);
  }, [sessionId, setSessionId]);

  return [sessionId, setSessionId];
};
