import {
  deleteAssistAction,
  getAllAssistActionSettings,
  loadAssistActions,
  saveAssistAction,
  saveAssistActionSetting,
  subscribeToAssistActionSettingsUpdates,
  subscribeToAssistActionsUpdates,
} from '@mirage/service-compose';
import { tagged } from '@mirage/service-logging';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import type {
  AssistActionSettings,
  AssistActionSettingsMap,
} from '@mirage/service-compose/service/assistActionsSettings';
import type {
  AssistAction,
  CustomAssistAction,
} from '@mirage/shared/compose/assist-actions';

const logger = tagged('AssistActionsContext');

export interface AssistActionsContextInterface {
  // states may be undefined if loading is in progress
  enabledActions: AssistAction[] | undefined;
  allActions: AssistAction[] | undefined;
  actionSettings: AssistActionSettingsMap | undefined;
  saveAction: (action: CustomAssistAction) => void;
  deleteAction: (actionId: string) => void;
  saveActionSetting: (actionId: string, setting: AssistActionSettings) => void;
}
export const AssistActionsContext =
  createContext<AssistActionsContextInterface | null>(null);
export const useAssistActionsContext = () => {
  const context = useContext(AssistActionsContext);
  if (!context) {
    throw new Error(
      'useAssistActionsContext must be used within a AssistActionsContextProvider',
    );
  }
  return context;
};

interface AssistActionsContextProviderProps {
  children: React.ReactNode;
}
export const AssistActionsContextProvider = ({
  children,
}: AssistActionsContextProviderProps) => {
  const [allActions, setAllActions] = useState<AssistAction[] | undefined>(
    undefined,
  );
  const [actionSettings, setActionSettings] = useState<
    AssistActionSettingsMap | undefined
  >(undefined);

  const enabledActions = useMemo(() => {
    if (allActions === undefined || actionSettings === undefined) {
      return undefined;
    }
    return getEnabledActions(allActions, actionSettings);
  }, [allActions, actionSettings]);

  // first load
  useEffect(() => {
    loadAssistActions()
      .then((actions) => {
        setAllActions(actions);
        logger.log('Loaded assist actions', actions.length);
        return;
      })
      .catch((error) => {
        logger.error('Assist actions load error', error);
      });

    getAllAssistActionSettings()
      .then((settings) => {
        setActionSettings(settings);
        logger.log(
          'Loaded assist action settings',
          Object.keys(settings).length,
        );
        return;
      })
      .catch((error) => {
        logger.error('Assist action settings load error', error);
      });
  }, []);

  // subscribe to updates
  useEffect(() => {
    const subscription = subscribeToAssistActionsUpdates((actions) => {
      setAllActions(actions);
      logger.log('Assist actions update received', actions.length);
    });
    const settingsSubscription = subscribeToAssistActionSettingsUpdates(
      (settings) => {
        setActionSettings(settings);
        logger.log('Assist action settings update received', settings);
      },
    );
    return () => {
      subscription.unsubscribe();
      settingsSubscription.unsubscribe();
    };
  }, []);

  const saveAction = useCallback(
    (action: CustomAssistAction) => {
      if (allActions === undefined) {
        logger.error('Attempting to save action while loading in progress');
        return;
      }
      saveAssistAction(action);
    },
    [allActions],
  );
  const deleteAction = useCallback(
    (actionId: string) => {
      if (allActions === undefined) {
        logger.error('Attempting to delete action while loading in progress');
        return;
      }
      const deletedAction = allActions.find((a) => a.id === actionId);
      if (!deletedAction) {
        logger.error('Attempting to delete action that does not exist');
        return;
      }
      if (deletedAction.type === 'preset') {
        logger.error('Attempting to delete preset action');
        return;
      }
      deleteAssistAction(deletedAction);
    },
    [allActions],
  );
  const saveActionSetting = useCallback(
    (actionId: string, setting: AssistActionSettings) => {
      if (actionSettings === undefined) {
        logger.error(
          'Attempting to save action setting while loading in progress',
        );
        return;
      }
      saveAssistActionSetting(actionId, setting);
    },
    [actionSettings],
  );
  return (
    <AssistActionsContext.Provider
      value={{
        enabledActions,
        allActions,
        saveAction,
        deleteAction,
        actionSettings,
        saveActionSetting,
      }}
    >
      {children}
    </AssistActionsContext.Provider>
  );
};

function getEnabledActions(
  allActions: AssistAction[],
  actionSettings: AssistActionSettingsMap,
): AssistAction[] {
  return allActions.filter((action) => {
    const setting = actionSettings[action.id];
    // check if action is explicitly toggled ON or OFF
    if (setting?.isEnabled !== undefined) {
      return setting.isEnabled;
    }
    // otherwise, use the default
    return getIsActionDefaultEnabled(action);
  });
}

function getIsActionDefaultEnabled(action: AssistAction): boolean {
  switch (action.type) {
    case 'preset':
      return true;
    case 'custom': {
      switch (action.accessConfig.type) {
        case 'user':
          // default to true if user created the action
          return true;
        case 'team':
          // default to true if shared action is created by the user
          return action.accessConfig.isOwned;
        default:
          action.accessConfig satisfies never;
          throw new Error('Invalid action access config type');
      }
    }
    default:
      action satisfies never;
      throw new Error('Invalid action type');
  }
}
