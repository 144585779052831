import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Create_DashStacksClone } from '@mirage/analytics/events/types/create_dash_stacks_clone';
import { PAP_Open_DashStacksClone } from '@mirage/analytics/events/types/open_dash_stacks_clone';
import { callApiV2 } from '@mirage/service-dbx-api';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import {
  queryFullStacksById,
  stackDerivePAPProps,
} from '@mirage/service-stacks';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import { showSnackbar } from '@mirage/shared/snackbar';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { stacks } from '@dropbox/api-v2-client';

export enum CloneStatus {
  ONGOING,
  SUCCESS,
  ERROR,
}

export const useIsCloneStacksEnabled = () => {
  return convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_07_03_clone_stacks', false),
  );
};

export const useCloneStack = (stack: stacks.Stack | null) => {
  const namespaceId = stack?.namespace_id;
  const stackName = stack?.stack_data?.name;

  const [ongoingUuidClone, setOngoingUuidClone] = useState<string | null>(null);
  const [isCloneStackModalOpen, setIsCloneStackModalOpen] = useState(false);
  const isCloneStacksEnabled = Boolean(
    useIsCloneStacksEnabled() && namespaceId,
  );
  const sentUuidToken = useRef<string | null>(null);
  const userDismissedSnackbar = useRef<boolean>(false);
  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const showSnackbarToNewStack = useCallback(
    async (newNamespaceId: string) => {
      if (!stack) {
        return;
      }
      const queryStacks = await queryFullStacksById({
        namespaceIds: [newNamespaceId],
      });
      const newStackSharingId = stackGetShareId(queryStacks?.stacks?.[0]);
      if (!newStackSharingId) {
        return;
      }
      showSnackbar({
        title: i18n.t('clone_stack_snackbar_success', {
          stackName: stack?.stack_data?.name,
        }),
        buttons: [
          {
            label: i18n.t('view_button'),
            onClick: () => {
              reportPapEvent(
                PAP_Open_DashStacksClone(stackDerivePAPProps(stack)),
              );
              navigate(`/stacks/${newStackSharingId}`);
            },
          },
        ],
      });
    },
    [navigate, stack, reportPapEvent],
  );

  const onStart = useCallback(
    (uuidToken: string) => {
      setOngoingUuidClone(uuidToken);
      userDismissedSnackbar.current = false;
      sentUuidToken.current = uuidToken;
      showSnackbar({
        title: i18n.t('clone_stack_snackbar_pending', {
          stackName,
        }),
        timeoutMs: 0,
        showSpinner: true,
        onDismiss: (userClickedDismissButton: boolean) => {
          userDismissedSnackbar.current = userClickedDismissButton;
        },
      });
    },
    [stackName],
  );

  const onContinue = useCallback(
    (uuidToken: string) => {
      setOngoingUuidClone(uuidToken);
      if (!userDismissedSnackbar.current) {
        showSnackbar({
          title: i18n.t('clone_stack_snackbar_pending', {
            stackName,
          }),
          timeoutMs: 0,
          showSpinner: true,
          onDismiss: (userClickedDismissButton: boolean) => {
            userDismissedSnackbar.current = userClickedDismissButton;
          },
        });
      }
    },
    [stackName, userDismissedSnackbar],
  );

  const onError = useCallback(() => {
    setOngoingUuidClone(null);
    sentUuidToken.current = null;
    userDismissedSnackbar.current = false;
    showSnackbar({
      title: i18n.t('clone_stack_snackbar_error', {
        stackName,
      }),
    });
  }, [stackName]);

  const onSuccess = useCallback(
    (newNamespaceId: string) => {
      setOngoingUuidClone(null);
      sentUuidToken.current = null;
      userDismissedSnackbar.current = false;
      showSnackbarToNewStack(newNamespaceId);
    },
    [showSnackbarToNewStack],
  );

  const handleCloneStack = useCallback(
    async (uuidToken) => {
      if (!namespaceId) {
        return;
      }
      if (sentUuidToken.current) {
        showSnackbar({
          title: i18n.t('clone_stack_snackbar_error_already_ongoing', {
            stackName,
          }),
        });
        return;
      }
      try {
        reportPapEvent(
          PAP_Create_DashStacksClone({
            ...stackDerivePAPProps(stack),
            actionSurfaceComponent: 'stacks_dropdown',
          }),
        );
        await callApiV2('stacksCloneStack', {
          namespace_id: namespaceId,
          uuid_token: uuidToken,
          hlc: {
            unix_micros: stack?.max_hlc_micros,
          },
        });
        onStart(uuidToken);
      } catch (e) {
        onError();
      }
    },
    [namespaceId, onError, onStart, reportPapEvent, stack, stackName],
  );

  const checkCloneStatus = useCallback(async (uuidToken: string) => {
    try {
      const response = await callApiV2('stacksCloneStatus', {
        uuid_token: uuidToken,
      });
      if (response.is_errored) {
        return { status: CloneStatus.ERROR };
      } else if (response.is_done && response.new_namespace_id) {
        return {
          status: CloneStatus.SUCCESS,
          newNamespaceId: response.new_namespace_id,
        };
      }
    } catch (e) {
      return { status: CloneStatus.ERROR };
    }
    return { status: CloneStatus.ONGOING };
  }, []);

  const checkIsCloneOngoing = useCallback(
    async (uuidToken) => {
      const status = await checkCloneStatus(uuidToken);
      if (status.status === CloneStatus.SUCCESS) {
        onSuccess(nonNil(status.newNamespaceId, 'new namespace id'));
      } else if (status.status === CloneStatus.ERROR) {
        onError();
      } else {
        onContinue(uuidToken);
      }
      return status.status === CloneStatus.ONGOING;
    },
    [checkCloneStatus, onError, onSuccess, onContinue],
  );

  const isPollingRef = useRef(false);
  const pollCloneStatus = useCallback(
    (uuidToken) => {
      setTimeout(async () => {
        const isOngoing = await checkIsCloneOngoing(uuidToken);
        isPollingRef.current = isOngoing;
        if (isOngoing) {
          pollCloneStatus(uuidToken);
        }
      }, 3000);
    },
    [checkIsCloneOngoing],
  );

  useEffect(() => {
    if (ongoingUuidClone && !isPollingRef.current) {
      isPollingRef.current = true;
      pollCloneStatus(ongoingUuidClone);
    }
  }, [ongoingUuidClone, pollCloneStatus]);

  return {
    isCloning: ongoingUuidClone !== null,
    isCloneStacksEnabled,
    isCloneStackModalOpen,
    setIsCloneStackModalOpen,
    handleCloneStack,
    checkCloneStatus,
    checkIsCloneOngoing,
  };
};
