import { TextArea } from '@dropbox/dig-components/text_fields';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';

import type { TextAreaProps } from '@dropbox/dig-components/text_fields';

interface EditingModeProps extends TextAreaProps {
  field: string;
  setField: (name: string) => void;
  onFocusEvent: () => void;
  stackFieldInputRef?: React.RefObject<HTMLTextAreaElement>;
  handleStackFieldBlur: (relatedTarget: HTMLElement | null) => void;
  classnames?: string;
}

export const EditingMode: React.FC<EditingModeProps> = ({
  field,
  setField,
  onFocusEvent,
  stackFieldInputRef,
  handleStackFieldBlur,
  classnames,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  ...rest
}) => {
  const hasSentFieldIsFocused = useRef(false);
  const isAugRev = useStackPageAugustRevisionEnabled();

  const handleFieldIsFocused = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      if (isAugRev) {
        const value = e.currentTarget.value;
        e.target.setSelectionRange(value.length, value.length);
      }

      if (hasSentFieldIsFocused.current) {
        return;
      }
      hasSentFieldIsFocused.current = true;
      onFocusEvent();
    },
    [onFocusEvent, isAugRev],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement> | null) => {
      const relatedTarget = event?.relatedTarget as HTMLElement | null;
      handleStackFieldBlur(relatedTarget);
    },
    [handleStackFieldBlur],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && event.shiftKey === false) {
        event.preventDefault();
        handleBlur(null);
      }
    },
    [handleBlur],
  );

  return (
    <TextArea
      {...rest}
      ref={stackFieldInputRef}
      value={field}
      onBlur={(e) => {
        handleBlur(e);
        onBlur?.(e);
      }}
      onChange={(e) => {
        setField(e.currentTarget.value);
        onChange?.(e);
      }}
      onFocus={(e) => {
        handleFieldIsFocused(e);
        onFocus?.(e);
      }}
      onKeyDown={(e) => {
        handleKeyDown(e);
        onKeyDown?.(e);
      }}
      className={classNames(classnames)}
    />
  );
};
