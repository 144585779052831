import { useAugustRevisionEnabled } from '@mirage/august-revision-hook';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { SmoothShowClassName } from '../smooth-show/SmoothShow';
import styles from './TwoColumnGrid.module.css';
import { convertSpaceToCSSVariable } from './utils';

import type { Space } from '@dropbox/dig-foundations';

type TwoColumnGridVersion =
  /* @default Use whichever version is globally used */
  | 'inherit'

  /* The standard layout if no flags are applied */
  | 'standard'

  /** Temporarily define the different layout versions possible within this Grid.
   * Post rollout, these should be removed in favor of Standard.
   */
  | 'august';

type TwoColumndGridProps = {
  mainChildren: React.ReactNode;
  sidebarChildren?: React.ReactNode;
  headerChildren?: React.ReactNode;
  containerRowGap?: Space;
  mainRowGap?: Space;
  sidebarRowGap?: Space;
  version?: TwoColumnGridVersion;
  className?: string;
  columnGroupClassName?: string;
  columnClassName?: string;
  // If true: anything in the sidebar will be sticky to the top of the page
  sidebarColumnSticky?: boolean;
};

const ONE_COLUMN_MAX_WIDTH = 656;
const AUGUST_ONE_COLUMN_MAX_WIDTH = 752;

export function useOneColumnMaxWidth() {
  const masterAugustRevision = useAugustRevisionEnabled();
  return masterAugustRevision
    ? AUGUST_ONE_COLUMN_MAX_WIDTH
    : ONE_COLUMN_MAX_WIDTH;
}

export const TwoColumnGrid: React.FC<TwoColumndGridProps> = ({
  mainChildren,
  sidebarChildren,
  headerChildren,
  containerRowGap,
  mainRowGap,
  sidebarRowGap,
  version = 'inherit',
  className,
  columnGroupClassName,
  columnClassName,
  sidebarColumnSticky,
}) => {
  const alwaysOneColumn = sidebarChildren === undefined;
  const [useOneColumnLayout, setUseOneColumnLayout] = useState(alwaysOneColumn);
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const masterAugustRevision = useAugustRevisionEnabled();
  const augustRevision =
    version === 'inherit' ? masterAugustRevision : version === 'august';
  const maxColumnWidth = useOneColumnMaxWidth();

  useEffect(() => {
    if (!alwaysOneColumn) {
      const gridComponent = gridContainerRef.current;

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { width } = entry.contentRect;
          setUseOneColumnLayout(width < maxColumnWidth);
        }
      });

      if (gridComponent) {
        resizeObserver.observe(gridComponent);
      }

      return () => {
        if (gridComponent) {
          resizeObserver.unobserve(gridComponent);
        }
      };
    }
  }, [alwaysOneColumn, maxColumnWidth]);

  return (
    <div
      className={classnames(styles.gridContainer, className, {
        [styles.augustLayout]: augustRevision,
      })}
      style={{
        rowGap: convertSpaceToCSSVariable(containerRowGap),
      }}
      ref={gridContainerRef}
    >
      {headerChildren && (
        <div className={classnames(styles.headerRow)}>{headerChildren}</div>
      )}
      <div
        className={classnames(styles.columnGroup, columnGroupClassName, {
          [styles.singleColumnGroup]: useOneColumnLayout,
        })}
      >
        {sidebarChildren && (
          <div
            className={classnames(
              styles.column,
              styles.columnGap,
              styles.sidebarColumn,
              SmoothShowClassName,
              columnClassName,
              {
                [styles.singleColumn]: useOneColumnLayout,
              },
            )}
            style={{
              rowGap: convertSpaceToCSSVariable(sidebarRowGap),
            }}
          >
            {sidebarColumnSticky ? (
              <div
                className={classnames({
                  [styles.sidebarColumnSticky]: sidebarColumnSticky,
                })}
              >
                {sidebarChildren}
              </div>
            ) : (
              sidebarChildren
            )}
          </div>
        )}
        <div
          className={classnames(
            styles.column,
            styles.columnGap,
            styles.mainColumn,
            SmoothShowClassName,
            columnClassName,
            {
              [styles.singleColumn]: useOneColumnLayout,
            },
          )}
          style={{
            rowGap: convertSpaceToCSSVariable(mainRowGap),
          }}
        >
          {mainChildren}
        </div>
      </div>
    </div>
  );
};
