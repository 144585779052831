import { tagged } from '@mirage/service-logging';
import { transformDashResultToMirage } from '../utils';

import type { APIv2Callable, SearchResult } from '..';
import type { dash } from '@dropbox/api-v2-client';

const logger = tagged('channel-recommendations');

export async function getChannelRecommendations(
  api: APIv2Callable,
): Promise<SearchResult[]> {
  const channelFilter: dash.QueryFilter = {
    filter: {
      '.tag': 'file_type_filter',
      file_type: 'channel',
    },
  };
  try {
    const response = await api('dcsBrowse', {
      filters: [channelFilter],
    });
    if (!response.results) {
      return [];
    }
    const channels = response.results
      ?.map((result) => {
        if (result.logical_type !== 'channel') {
          return null;
        }
        return transformDashResultToMirage(result);
      })
      .filter((channelResult): channelResult is SearchResult =>
        Boolean(channelResult),
      );
    return channels;
  } catch (error) {
    logger.error(`Error getting channel recommendations`, error);
    return [];
  }
}
