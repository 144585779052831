import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import {
  all,
  create,
  format,
  typeOf as mathTypeOf,
  // @ts-expect-error -- no types for number import
} from 'mathjs/lib/esm/number';
import * as rx from 'rxjs';

import type { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import type {
  MathCalculation,
  typeahead,
} from '@mirage/service-typeahead-search/service/types';
import type {
  ConfigOptions,
  FormatOptions,
  MathJsStatic,
  Parser,
} from 'mathjs';
import type { Observable } from 'rxjs';

export const MATH_RESULT_UUID: string = 'e2d4d645-401b-4051-9c09-3d2820f80707';

// https://mathjs.org/docs/reference/functions/format.html
const config: ConfigOptions = { predictable: true };
const mathjs: MathJsStatic = create(all, config);

// https://mathjs.org/docs/reference/functions/format.html
const formatOptions: FormatOptions = { notation: 'fixed' };

// Defining the parser here will keep context i.e. if you evaluate x=7, in later
// evaluations you can use x and the parser will remember its value
// https://mathjs.org/docs/expressions/parsing.html#parser
const parser: Parser = mathjs.parser();

/**
 * Add commas to numbers
 * https://stackoverflow.com/questions/2901102
 * Using the 3rd option, with the @t.j.crowder comment
 *
 * Note - We are using this function to format numbers within strings
 * as well.  eg  'xxx 10000000 xxx' would format as 'xxx 100,000,000 xxx'
 */
function numberWithCommas(x: string | number): string {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Round to certain number of decimal places, if necessary
 * https://stackoverflow.com/a/40800717
 */
function limitedDecimalPlacesIfNecessary(
  num: number,
  decimalPlaces: number = 2,
) {
  // Original
  // return +(Math.round(num + "e+"+decimalPlaces)  + "e-"+decimalPlaces);
  // Make Flow happy:
  const n1 = Math.round(Number(`${num}e+${decimalPlaces}`));
  const n2 = Number(`${n1}e-${decimalPlaces}`);
  return +n2;
}

// Official mathjs typing uses any
/* eslint-disable @typescript-eslint/no-explicit-any */
function formatCallback(value: any): string {
  let formatted = value;
  // From docs: "you could also use math.format inside the callback (see example)"
  formatted = format(formatted, formatOptions);
  formatted = limitedDecimalPlacesIfNecessary(formatted, 5);
  formatted = numberWithCommas(formatted);
  return formatted;
}

function customQueryParser(query: string) {
  try {
    return parser.evaluate(query);
  } catch {
    const cleanedExpr: string = query.replace(/,/g, '');
    const result: number | undefined = mathjs.evaluate(cleanedExpr);
    return result;
  }
}

export const search = wrappers.wrapped(SourceId.MathCalculations, raw);

export function raw(
  query: string,
  _config: typeahead.Config,
  _cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    let answer: string;
    const lcQuery = query.toLocaleLowerCase();

    try {
      const rawAnswer = customQueryParser(lcQuery);

      /**
       * Don't show math results if rawAnswer type isn't right
       * Example queries: 2i, var, mean
       */
      const invalidRawAnswerTypes = [
        'Complex', // 2i
        'function', // var/variance, mean, median, max, min, prod, std, sum
      ];
      const rawAnswerType = mathTypeOf(rawAnswer);
      if (invalidRawAnswerTypes.includes(rawAnswerType)) {
        return rx.EMPTY;
      }
      /**
       * Don't show answer if it's same as query (i.e just a number)
       * Example query: 24 -> parser.evaluate(24) = 24
       */
      if (
        mathTypeOf(rawAnswer) === 'number' &&
        rawAnswer.toString() === query
      ) {
        return rx.EMPTY;
      }

      /**
       * Don't show Unit answers if there is no value
       * Example query: cup (answer is just "cup")
       *
       * `rawAnswer.value === null` makes non-empty units work
       */
      if (mathTypeOf(rawAnswer) === 'Unit' && rawAnswer.value === null) {
        return rx.EMPTY;
      }

      answer = format(rawAnswer, formatCallback);

      // if something causes a NaN, return empty
      if (Number.isNaN(Number(answer.replace(/,/g, '')))) {
        return rx.EMPTY;
      }
    } catch (error) {
      return rx.EMPTY;
    }

    const results: MathCalculation[] = [
      { uuid: MATH_RESULT_UUID, query, answer },
    ];

    return rx.from(
      results.map((result) => primitives.mathCalculation(result.uuid, result)),
    );
  });
}
