import { useCallback, useEffect, useRef } from 'react';

export function useCheckIsMounted() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const checkIsMounted = useCallback(() => {
    return isMountedRef.current;
  }, [isMountedRef]);

  return checkIsMounted;
}
