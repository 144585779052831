import { Button } from '@dropbox/dig-components/buttons';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashAnswersFeedback } from '@mirage/analytics/events/types/click_dash_answers_feedback';
import { PAP_Click_DashAnswersHide } from '@mirage/analytics/events/types/click_dash_answers_hide';
import { PAP_Click_DashAnswersShow } from '@mirage/analytics/events/types/click_dash_answers_show';
import { PAP_Click_DashAnswersSource } from '@mirage/analytics/events/types/click_dash_answers_source';
import { PAP_Click_DashAnswersTaggedFeedback } from '@mirage/analytics/events/types/click_dash_answers_tagged_feedback';
import { PAP_Copy_DashAnswers } from '@mirage/analytics/events/types/copy_dash_answers';
import { PAP_Expand_DashAnswers } from '@mirage/analytics/events/types/expand_dash_answers';
import { PAP_Shown_DashAnswersSource } from '@mirage/analytics/events/types/shown_dash_answers_source';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { ANSWER_DWELL_END_REASONS } from '@mirage/analytics/session/session-utils';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { ConversationMessages } from '@mirage/mosaics/Chat/components/chat/ConversationMessages';
import { AnswerCitationCards } from '@mirage/mosaics/MultiAnswersCard/AnswerCitationCard';
import {
  MultiAnswerCardContainer,
  MultiAnswerCardContainerDivider,
} from '@mirage/mosaics/MultiAnswersCard/layout/MultiAnswerCardContainer';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { measurePerformance } from '@mirage/service-operational-metrics/measure-performance';
import { PERFORMANCE_MARKS } from '@mirage/service-operational-metrics/performance-constants';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { openResult } from '@mirage/service-result-actions';
import { usePageVisibility } from '@mirage/shared/hooks/usePageVisibility';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { FeedbackOptions } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Answer } from './Answer';
import styles from './MultiAnswerCard.module.css';
import { NoAnswer } from './NoAnswer';

import type { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import type { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import type { ChatConversationMessage } from '@mirage/mosaics/Chat/types';
import type { Connectors } from '@mirage/service-connectors/service';
import type {
  MultiAnswerResponse,
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';

const featureLine: FeatureLine = 'multi_answers';

export type MultiAnswerCardProps = {
  multiAnswerResponse: MultiAnswerResponse | undefined;
  messages?: ChatConversationMessage[];
  connectors: Connectors;
  conversationId?: string;
  loading?: boolean;
  query: string;
  onShowFeedbackSnackbar: () => void;
  onShowCopyMessageSnackbar: () => void;
  annotationMode?: boolean;
  onOpenAnnotationModal: (
    feedback: FeedbackOptions,
    answer: QuestionAndAnswerResponse,
  ) => void;
  isStreamingEnabled?: boolean;
  variant: 'narrow' | 'wide';
};

export const MultiAnswerCard: React.FC<MultiAnswerCardProps> = ({
  multiAnswerResponse,
  messages = [],
  connectors,
  conversationId,
  loading = false,
  query,
  onShowFeedbackSnackbar,
  onShowCopyMessageSnackbar,
  annotationMode = false,
  onOpenAnnotationModal,
  isStreamingEnabled,
  variant,
}) => {
  const isPageVisible = usePageVisibility();
  const isCardVisible = useRef(false);
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const { searchQueryUuid } = useSearchQueryId();
  const { reportPapEvent, implicitAnswerDwellManager, searchSessionManager } =
    useMirageAnalyticsContext();

  const isConversationAssistantEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2025_02_11_conversation_assistant'),
  );

  const isEmpty = isConversationAssistantEnabled
    ? !messages.length
    : !multiAnswerResponse?.answers || multiAnswerResponse.answers.length === 0;

  // Ensure we don't log end session events when component re-renders due to loading or change in answers
  const canToggleSession = !isEmpty && !loading;

  // Create answer ID for logging purposes
  const answerLoggingId = useRef(uuid());

  useEffect(() => {
    if (canToggleSession) {
      // Create dwell session
      implicitAnswerDwellManager.createSession();
      implicitAnswerDwellManager.updateProperties({
        queryString: query,
        entryPoint: ChatEntryPoint.search_bar_inline,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchRequestId: multiAnswerResponse?.requestId,
        featureLine,
      });

      // Log shown event for each answer
      multiAnswerResponse?.answers?.map((answer) => {
        reportPapEvent(
          PAP_Shown_DashSearchResult({
            queryString: query,
            answerString: answer.answer,
            generatedQueryString: answer.question,
            answerId: answerLoggingId.current,
            numAnswerSources: answer.sources.length,
            entryPoint: ChatEntryPoint.search_bar_inline,
            searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
            searchRequestId: multiAnswerResponse?.requestId,
            searchQueryUuid,
            featureLine,
          }),
        );
      });
    }

    return () => {
      if (canToggleSession) {
        implicitAnswerDwellManager.endSession(ANSWER_DWELL_END_REASONS.EXITED);
      }
    };
    // Disabling because we do not want to log whenever `query` or `searchQueryId` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    multiAnswerResponse,
    reportPapEvent,
    canToggleSession,
    searchSessionManager,
    implicitAnswerDwellManager,
  ]);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Answer card is visible in viewport
          isCardVisible.current = true;
          implicitAnswerDwellManager.extendOrCreateSession();
          implicitAnswerDwellManager.updateProperties({
            queryString: query,
            entryPoint: ChatEntryPoint.search_bar_inline,
            searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
            searchQueryUuid: searchQueryUuid,
            searchRequestId: multiAnswerResponse?.requestId,
            featureLine,
          });
        } else {
          // User has scrolled the answer card out of view. We should end the session
          isCardVisible.current = false;
          implicitAnswerDwellManager.endSession(
            ANSWER_DWELL_END_REASONS.SCROLLED,
          );
        }
      });
    });

    observer.observe(ref.current);
    const refCurrent = ref.current;
    return () => {
      if (refCurrent) {
        observer.unobserve(refCurrent);
      }
    };
  }, [
    ref,
    multiAnswerResponse?.requestId,
    searchQueryUuid,
    query,
    implicitAnswerDwellManager,
    searchSessionManager,
  ]);

  useEffect(() => {
    if (isPageVisible && isCardVisible.current) {
      // User switched back to the page and answer card is in view
      implicitAnswerDwellManager.extendOrCreateSession();
      implicitAnswerDwellManager.updateProperties({
        queryString: query,
        entryPoint: ChatEntryPoint.search_bar_inline,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchQueryUuid: searchQueryUuid,
        searchRequestId: multiAnswerResponse?.requestId,
        featureLine,
      });
    } else if (!isPageVisible) {
      // User has switched tabs / minimized window / minimized app, we should end the sessions
      implicitAnswerDwellManager.endSession(
        ANSWER_DWELL_END_REASONS.LOST_FOCUS,
      );
    }
  }, [
    implicitAnswerDwellManager,
    isPageVisible,
    multiAnswerResponse?.requestId,
    searchQueryUuid,
    query,
    searchSessionManager,
  ]);

  useEffect(() => {
    const numAnswers = multiAnswerResponse?.answers?.length || 0;

    if (!loading && numAnswers > 0) {
      performance.mark(PERFORMANCE_MARKS.MULTI_ANSWERS.ANSWERS_RENDERED); // Mark when answers are rendered

      // Measure the perf / latency of fetching and rendering answers
      measurePerformance({
        metricNamespace: 'multi-answers',
        measureName: 'tta', // "time to answer"
        startMark: PERFORMANCE_MARKS.MULTI_ANSWERS.FETCH_START,
        endMark: PERFORMANCE_MARKS.MULTI_ANSWERS.ANSWERS_RENDERED,
      });
    }
  }, [multiAnswerResponse, loading]);

  const getCommonPapFields = useCallback(() => {
    return {
      queryString: query,
      answerId: answerLoggingId.current,
      entryPoint: ChatEntryPoint.search_bar_inline,
      featureLine: 'multi_answers' as FeatureLine,
      searchQueryUuid,
      searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      searchRequestId: multiAnswerResponse?.requestId,
    };
  }, [
    multiAnswerResponse?.requestId,
    query,
    searchSessionManager,
    searchQueryUuid,
  ]);

  const handleCopyMessage = (item: QuestionAndAnswerResponse) => {
    onShowCopyMessageSnackbar();
    copyToClipboard(item.answer || '');

    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Copy_DashAnswers({
        answerPositionIndex: item.position,
        answerString: item.answer,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleSourceClick = async (
    item: QuestionAndAnswerResponse,
    source: QuestionAndAnswerSource,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersSource({
        answerPositionIndex: item.position,
        answerString: item.answer,
        dashObjectId: source.uuid,
        ...getCommonPapFields(),
      }),
    );

    await openResult(source);
  };

  const handleClickFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: FeedbackOptions,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersFeedback({
        answerPositionIndex: item.position,
        answerString: item.answer,
        dashAnswerFeedback: feedback,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
    if (annotationMode && feedback === FeedbackOptions.Negative) {
      onOpenAnnotationModal(feedback, item);
    }
  };

  const handleClickSecondaryFeedback = (
    item: QuestionAndAnswerResponse,
    feedback: DashAnswersFeedbackTag,
  ) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersTaggedFeedback({
        answerPositionIndex: item.position,
        answerString: item.answer,
        dashAnswersFeedbackTag: feedback,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleExpand = (item: QuestionAndAnswerResponse) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersShow({
        answerPositionIndex: item.position,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleCollapse = (item: QuestionAndAnswerResponse) => {
    searchSessionManager.extendOrCreateSession('multi_answer_action');
    reportPapEvent(
      PAP_Click_DashAnswersHide({
        answerPositionIndex: item.position,
        generatedQueryString: item.question,
        ...getCommonPapFields(),
      }),
    );
  };

  const handleShownSource = useCallback(
    (item: QuestionAndAnswerResponse, source: QuestionAndAnswerSource) => {
      reportPapEvent(
        PAP_Shown_DashAnswersSource({
          answerPositionIndex: item.position,
          generatedQueryString: item.question,
          resultUuid: source.uuid,
          resultUpdatedAtMs: source.lastUpdatedMs,
          connector: source.connectorName,
          ...getCommonPapFields(),
        }),
      );
    },
    [reportPapEvent, getCommonPapFields],
  );

  const followupButtons = !loading && messages.length > 0 && (
    <Link
      to={`/conversation?conversationId=${conversationId}`}
      className={styles.followUpLink}
    >
      <Button variant="primary" size="medium">
        {i18n.t('ask_follow_up')}
      </Button>
    </Link>
  );
  const answerContent = (
    <>
      {/* Loading */}
      {loading && <Fragment></Fragment>}

      {/* Loaded, no answer available */}
      {!loading && isEmpty && (
        <Fragment>
          <NoAnswer />
          <MultiAnswerCardContainerDivider />
        </Fragment>
      )}

      {/* Conversation-based answer */}
      {isConversationAssistantEnabled && messages.length > 0 && (
        <ConversationMessages
          variant="condensed"
          noPadding
          messages={messages}
          isWaitingForResponse={loading}
          progressString={undefined}
          onRemoveSource={() => {}}
          isLastMessageFollowUpSuggestions={false}
          artifacts={[]}
          logComposeEvent={() => {}}
          onOpenArtifact={() => {}}
          hideFirstUserMessage={true}
          hideSources
        />
      )}

      {/* Traditional answers */}
      {!isConversationAssistantEnabled &&
        !loading &&
        !isEmpty &&
        multiAnswerResponse?.answers?.map((answer, index) => {
          return (
            <Fragment key={answer.answerId || answer.conversationId || ''}>
              <Answer
                answer={answer}
                answerId={answer.answerId || answer.conversationId || ''}
                expandedByDefault={index == 0 || Boolean(isStreamingEnabled)}
                onShowFeedbackSnackbar={onShowFeedbackSnackbar}
                onCopyMessage={handleCopyMessage}
                onSourceClick={handleSourceClick}
                onClickFeedback={handleClickFeedback}
                onClickSecondaryFeedback={handleClickSecondaryFeedback}
                onShownSource={handleShownSource}
                onCollapse={() => {
                  handleCollapse(answer);
                }}
                onExpand={() => {
                  handleExpand(answer);
                }}
                annotationMode={annotationMode}
                onClickMore={() => {
                  reportPapEvent(
                    PAP_Expand_DashAnswers({
                      answerString: answer.answer,
                      answerPositionIndex: answer.position,
                      ...getCommonPapFields(),
                    }),
                  );
                }}
                isStreamingEnabled={isStreamingEnabled}
              />
              <MultiAnswerCardContainerDivider />
            </Fragment>
          );
        })}
    </>
  );

  return (
    <MultiAnswerCardContainer
      variant={variant}
      loading={loading}
      followupButtons={followupButtons}
      contentRef={ref}
      connectors={connectors}
      answer={answerContent}
      citations={<AnswerCitationCards messages={messages} />}
    />
  );
};
