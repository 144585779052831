import { UIIcon } from '@dropbox/dig-icons';
import { FailLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { showSnackbar } from '@mirage/shared/snackbar';
import { Backoff } from '@mirage/shared/util/backoff';
import { runWithRetries } from '@mirage/shared/util/retries';
import i18n from '@mirage/translations';
import { useAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { listStackComments, subscribeToComments } from '..';
import { stackItemCommentAtom } from '../atoms/stack';

import type { StackComment, StackItemCommentRecord } from '../types';

const logger = tagged('use-init-stack-comments');

// Helper to map comments from the server to the atom state.
export const mapCommentsToRecords = (comments: StackComment[]) =>
  comments.reduce<StackItemCommentRecord<StackComment>>((acc, comment) => {
    acc[comment.linkId] = comment;
    return acc;
  }, {});

// Simple hook that handles initializing and subscribing to comments for a stack.
export const useInitStackComments = (stackSharingId?: string) => {
  const [comments, setComments] = useAtom(stackItemCommentAtom);
  const [isFetchingStackComments, setIsFetchingStackComments] = useState(false);
  const isStackCommentsEnabled = useFeatureFlagValueBool(
    'dash_web_2025_01_31_stacks_contextual_information',
  );

  // Init data fetching
  useEffect(() => {
    async function initComments(identifier: string) {
      setIsFetchingStackComments(true);

      try {
        // Make up to 2 attempts to fetch comments on a relatively short backoff before giving up.
        const comments = await runWithRetries(
          () => listStackComments(identifier),
          {
            backoff: new Backoff(500, 500),
            numAttempts: 2,
          },
        );
        setComments(mapCommentsToRecords(comments));
      } catch (e) {
        // If retries are exhausted, write logs so we can evaluate retry/backoff behavior
        const message = 'Retries exhausted while fetching stack comments';

        // We treat these as informational because we already have upstream exception logs on listStackComments
        logger.info(message);
        Sentry.captureMessage(message, 'info');

        // Show a Snackbar alerting the user and give them the option to manually retrigger
        showSnackbar({
          accessory: <UIIcon src={FailLine} />,
          buttons: [
            {
              label: i18n.t('try_again'),
              onClick: () => {
                void initComments(identifier);
              },
            },
          ],
          title: i18n.t('stack_comments_failed_to_load'),
        });
      } finally {
        setIsFetchingStackComments(false);
      }
    }

    if (isStackCommentsEnabled && stackSharingId) {
      void initComments(stackSharingId);
    }
  }, [isStackCommentsEnabled, stackSharingId]);

  useEffect(() => {
    // If comments are disabled or we're missing a stack id, don't attempt a subscription.
    if (!isStackCommentsEnabled || !stackSharingId) {
      return;
    }

    const subscription = subscribeToComments((subData) => {
      // Safety check to ensure the subscription data belongs to the current stack.
      if (subData.stackSharingId === stackSharingId) {
        setComments(mapCommentsToRecords(subData.comments));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [isStackCommentsEnabled, stackSharingId]);

  return {
    isFetchingStackComments,
    stackHasAtLeastOneComment: !isEmpty(comments),
  };
};
