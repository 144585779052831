import {
  asShortcuts,
  stackForDiffingForOnlyRenderedData,
} from '@mirage/service-stacks/service/utils';
import { currentLocationAtom } from '@mirage/shared/location/atoms';
import { atom } from 'jotai';
import isEqual from 'lodash/isEqual';
import { canEditStack } from '../utils';
import {
  initLinkSectionMap,
  initSections,
  isStackPath,
  sortSectionsForCompare,
  sortStackItemsForCompare,
} from './utils';

import type { SectionsActionType } from '../FullScreenStack/types';
import type { stacks } from '@dropbox/api-v2-client';
import type {
  StackItem,
  StackItemShortcutWithMetadata,
} from '@mirage/service-stacks/service/types';

// Active stack atoms. These atoms represent the current active stack,
// usually via the /stack/:stack_id path. The active stack atoms mirror the
// true stack data from the cache, and are used to quickly update the UI for
// features like DND and seamless create. Note that updates to these atoms
// will never effect the true stack data in the cache, you must issue API
// calls for that. The activeStackAtom is synced to the stack cache via
// `packages/stacks/ActiveStack/hooks.ts: useInitActiveStack`, (ensure this
// hooks is called somewhere above where you are using these atoms) so as
// server-side changes come in, the activeStackAtom will also be updated.

// All active stack atoms will only return values when the current route
// is a stack path. Otherwise, they will return null. This is a safeguard
// to prevent using stale data.

// Replaced fullPageShadowAtoms. This is a useful comment if you are searching
// for shadow atoms [shadowStackAtom] :)

export const activeStackStateAtom = atom<stacks.Stack | null>(null);
/**
 * The current active stack.
 * @returns The current active stack, or null if the current route is not a stack path.
 */
export const activeStackAtom = atom<
  stacks.Stack | null,
  [stacks.Stack | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackStateAtom);
  },
  // Only set the stack if the stack data has meaningfully changed
  (get, set, update) => {
    const previousStack = get(activeStackStateAtom);
    const prevDiffStack = previousStack
      ? stackForDiffingForOnlyRenderedData(previousStack)
      : null;
    const currDiffStack = update
      ? stackForDiffingForOnlyRenderedData(update)
      : null;
    if (!isEqual(prevDiffStack, currDiffStack)) {
      set(activeStackStateAtom, currDiffStack);
    }
  },
);

/**
 * The current active stack atom without checking for a valid stack path.
 * This is dangerous to use because it can cause unexpected values if not used
 * on a valid stack path. There are no use cases for this atom today, but
 * highlighting this pattern if needed.
 * @returns The current active stack, may be stale.
 */
export const dangerousActiveStackAtom = atom<
  stacks.Stack | null,
  [stacks.Stack | null],
  void
>(
  (get) => get(activeStackStateAtom),
  (get, set, update) => {
    const previousStack = get(activeStackStateAtom);
    const prevDiffStack = previousStack
      ? stackForDiffingForOnlyRenderedData(previousStack)
      : null;
    const currDiffStack = update
      ? stackForDiffingForOnlyRenderedData(update)
      : null;
    if (!isEqual(prevDiffStack, currDiffStack)) {
      set(activeStackStateAtom, currDiffStack);
    }
  },
);

/**
 * Whether the current active stack is new (yet to be created).
 * @returns True if the current active stack is new, false if not, or null if the current route is not a stack path.
 */
export const activeStackIsNewAtom = atom<boolean | null>((get) => {
  const location = get(currentLocationAtom);
  if (!isStackPath(location)) {
    return null;
  }
  return get(activeStackAtom) === null;
});

const activeStackItemsStateAtom = atom<StackItem[] | null>(null);
/**
 * Stack items in the current active stack.
 * @returns The items in the current active stack, or null if the current route is not a stack path.
 */
export const activeStackItemsAtom = atom<
  StackItem[] | null,
  [StackItem[] | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackItemsStateAtom);
  },
  // Only set the stack items if the stack items have meaningfully changed
  (get, set, update) => {
    const previousItems = get(activeStackItemsStateAtom);

    const sortedPreviousItems = sortStackItemsForCompare(previousItems);
    const sortedUpdateItems = sortStackItemsForCompare(update);

    if (!isEqual(sortedPreviousItems, sortedUpdateItems)) {
      set(activeStackItemsStateAtom, update);
    }
  },
);

/**
 * Item shortcuts in the current active stack.
 * @returns The item shortcuts, or null if the current route is not a stack path.
 */
export const activeStackItemShortcutsAtom = atom<
  StackItemShortcutWithMetadata[] | null
>((get) => {
  const location = get(currentLocationAtom);
  if (!isStackPath(location)) {
    return null;
  }

  const items = get(activeStackItemsAtom);
  if (items) {
    return asShortcuts(items);
  }
  return null;
});

const activeStackSearchItemsStateAtom = atom<stacks.StackItemShortcut[] | null>(
  null,
);
/**
 * Stack items that match the search filter in the current active stack.
 * @returns The items, or null if the current route is not a stack path.
 */
export const activeStackSearchItemsAtom = atom<
  stacks.StackItemShortcut[] | null,
  [stacks.StackItemShortcut[] | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackSearchItemsStateAtom);
  },
  (get, set, update) => {
    const location = get(currentLocationAtom);
    if (isStackPath(location)) {
      set(activeStackSearchItemsStateAtom, update);
    }
  },
);

const activeStackSearchItemsNoResultsQueryStateAtom = atom<string | null>(null);
/**
 * Query that resulted in no results for the in-stack search.
 * This is so AddStackItemBox can search for it.
 * @returns The query with no results, or null if the current route is not a stack path.
 */
export const activeStackSearchItemsNoResultsQueryAtom = atom<
  string | null,
  [string | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackSearchItemsNoResultsQueryStateAtom);
  },
  (get, set, update) => {
    const location = get(currentLocationAtom);
    if (isStackPath(location)) {
      set(activeStackSearchItemsNoResultsQueryStateAtom, update);
    }
  },
);

/**
 * Namespace ID of the current active stack.
 * @returns The namespace ID, or null if the current route is not a stack path.
 */
export const activeStackNamespaceIdAtom = atom<string | null>((get) => {
  const location = get(currentLocationAtom);
  if (!isStackPath(location)) {
    return null;
  }
  return get(activeStackAtom)?.namespace_id ?? null;
});

/**
 * Write permissions for the current active stack.
 * @returns True if the user has write permissions, false if not, or null if the current route is not a stack path.
 */
export const activeStackHasWritePermissionsAtom = atom<boolean | null>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    // Can always edit new stack
    if (get(activeStackIsNewAtom)) {
      return true;
    }
    const stack = get(activeStackAtom);
    return canEditStack(stack);
  },
);

type SectionData = {
  allSections: stacks.Section[] | null;
  defaultSection: stacks.Section | null;
  otherSections: stacks.Section[] | null;
};

/**
 * Sections data of the current active stack.
 * @returns An object containing all sections, default section, and other sections, or null if the current route is not a stack path.
 */
export const activeStackSectionsAtom = atom<
  SectionData,
  [stacks.Section[]],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return {
        allSections: null,
        defaultSection: null,
        otherSections: null,
      };
    }

    const stack = get(activeStackAtom);
    const allSections = initSections(
      stack?.stack_data?.section_data?.sections ?? null,
    );
    const [defaultSection, ...otherSections] = allSections;
    return {
      allSections,
      defaultSection,
      otherSections,
    };
  },
  // Only set the sections if the section data has meaningfully changed
  (get, set, update) => {
    const stack = get(activeStackAtom);
    const currentSectionsData = stack?.stack_data?.section_data?.sections;
    const sortedCurrentSectionData = sortSectionsForCompare(
      currentSectionsData ?? [],
    );
    const sortedUpdateSections = sortSectionsForCompare(update ?? []);

    if (!isEqual(sortedCurrentSectionData, sortedUpdateSections)) {
      // Store the section data directly in the activeStack, as the activeStack
      // already represents "mirrored" state from the real stack. Note that like
      // the rest of these activeStack atoms, updates here will never effect
      // the real stack in the cache.
      const updatedStack = {
        ...stack,
        stack_data: {
          ...stack?.stack_data,
          section_data: {
            ...stack?.stack_data?.section_data,
            sections: update,
          },
        },
      };

      set(activeStackAtom, updatedStack);
    }
  },
);

/**
 * Map of sectionId to stackItems contained in each section in the current active stack.
 * @returns A map of link sections, or null if the current route is not a stack path.
 */
export const activeStackLinkSectionsMapAtom = atom<Map<
  string,
  StackItemShortcutWithMetadata[]
> | null>((get) => {
  const location = get(currentLocationAtom);
  if (!isStackPath(location)) {
    return null;
  }

  const sections = get(activeStackSectionsAtom)?.allSections;
  const items = get(activeStackItemShortcutsAtom);

  if (sections && items) {
    return initLinkSectionMap(sections, items);
  }

  return null;
});

const activeStackSectionIdStateAtom = atom<string | null>(null);
/**
 * Currently selected section ID for the full stack page.
 * @returns The section ID, or null if the current route is not a stack path.
 */
export const activeStackSectionIdAtom = atom<
  string | null,
  [string | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackSectionIdStateAtom);
  },
  (_, set, update) => {
    set(activeStackSectionIdStateAtom, update);
  },
);

const activeStackActionStateAtom = atom<SectionsActionType | null>(null);
/**
 * Current action being performed on the stack sections.
 * @returns The current action, or null if the current route is not a stack path.
 */
export const activeStackActionAtom = atom<
  SectionsActionType | null,
  [SectionsActionType | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackActionStateAtom);
  },
  (_, set, update) => {
    set(activeStackActionStateAtom, update);
  },
);

const activeStackMutationRequestIdStateAtom = atom<string | null>(null);
/**
 * ID of the mutation request for the Full Stack Page. Used for tracking
 * seamless stack creation requests.
 * @returns The mutation request ID, or null if the current route is not a stack path.
 */
export const activeStackMutationRequestIdAtom = atom<
  string | null,
  [string | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackMutationRequestIdStateAtom);
  },
  (_, set, update) => {
    set(activeStackMutationRequestIdStateAtom, update);
  },
);

const activeStackSessionIdStateAtom = atom<string | null>(null);
/**
 * Session ID for the current active stack.
 * @returns The session ID, or null if the current route is not a stack path.
 */
export const activeStackSessionIdAtom = atom<
  string | null,
  [string | null],
  void
>(
  (get) => {
    const location = get(currentLocationAtom);
    if (!isStackPath(location)) {
      return null;
    }
    return get(activeStackSessionIdStateAtom);
  },
  (_, set, update) => {
    set(activeStackSessionIdStateAtom, update);
  },
);
