import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import * as syncChannelRecommendationsJob from '@mirage/service-typeahead-search/service/jobs/sync-channel-recommendations';
import * as syncPeopleRecommendationsJob from '@mirage/service-typeahead-search/service/jobs/sync-people-recommendations';
import * as syncRecommendationsJob from '@mirage/service-typeahead-search/service/jobs/sync-recommendations';
import {
  cancelSyncSuggestedQueryScore,
  cancelSyncWeights,
  startSyncSuggestedQueryScore,
  startSyncWeights,
} from '@mirage/service-typeahead-search/service/scoring';
import search from '@mirage/service-typeahead-search/service/search';
import {
  CacheKey,
  TypeaheadCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache';

import type { LogoutServiceConsumerContract } from '@mirage/service-logout';
import type { PersistentCacheShape } from '@mirage/service-typeahead-search/service/typeahead-cache';
import type { typeahead as typeahead_ } from '@mirage/service-typeahead-search/service/types';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const logger = tagged('service-typeahead-search');

export type Service = {
  cacheRecentQueries(queries: string[]): Promise<void>;

  getRecentQueries(): Promise<PreviousQuery[]>;
  getRecommendations(): Promise<Recommendation[]>;

  clear(key: CacheKey): Promise<void>;
  tearDown(): Promise<void>;
  remove(previousQuery: PreviousQuery): Promise<void>;

  search(
    query: string,
    config: typeahead_.Config,
  ): Observable<typeahead_.ScoredResult[]>;

  startSyncRecommendations(): void;
  cancelSyncRecommendations(): void;

  startSyncPeopleRecommendations(): void;
  cancelSyncPeopleRecommendations(): void;

  startSyncChannelRecommendations(): void;
  cancelSyncChannelRecommendations(): void;

  startSyncWeights(): void;
  cancelSyncWeights(): void;

  startSyncSuggestedQueryScore(): void;
  cancelSyncSuggestedQueryScore(): void;

  registerHit(uuid: string): Promise<void>;

  addURLShortcutToCache(shortcut: URLShortcut): Promise<void>;
};

export default function typeahead(
  adapter: KVStorage<PersistentCacheShape>,
  auxiliarySources: typeahead_.Source[] = [],
  logoutService: LogoutServiceConsumerContract,
) {
  const cache = new TypeaheadCache(adapter);

  async function cacheRecentQueries(queries: string[]): Promise<void> {
    await cache.cacheRecentQueries(queries);
  }

  async function getRecentQueries(): Promise<PreviousQuery[]> {
    return cache.all(CacheKey.RecentQueries) as PreviousQuery[];
  }

  async function getRecommendations(): Promise<Recommendation[]> {
    return cache.all(CacheKey.Recommendations) as Recommendation[];
  }

  async function clear(key: CacheKey) {
    cache.clear(key);
  }

  // used to reset the cache when the user logs out
  async function tearDown() {
    await cache.tearDown();
  }

  async function remove(previousQuery: PreviousQuery): Promise<void> {
    return cache.remove(previousQuery);
  }

  async function registerHit(uuid: string): Promise<void> {
    return cache.registerHit(uuid, Date.now());
  }

  async function addURLShortcutToCache(shortcut: URLShortcut): Promise<void> {
    return cache.addURLShortcutToCache(shortcut);
  }

  logoutService.registerLogoutCallback(ServiceId.TYPEAHEAD_SEARCH, async () => {
    logger.debug('Handling logout in typehaead search service');
    await tearDown();
    logger.debug('Done handling logout in typeahead search service');
  });

  return services.provide<Service>(
    ServiceId.TYPEAHEAD_SEARCH,
    {
      cacheRecentQueries,
      getRecentQueries,
      getRecommendations,
      clear,
      tearDown,
      remove,
      search: (query, config) => search(query, cache, auxiliarySources, config),
      startSyncRecommendations: () => syncRecommendationsJob.start(cache),
      cancelSyncRecommendations: () => syncRecommendationsJob.cancel(),
      startSyncPeopleRecommendations: () =>
        syncPeopleRecommendationsJob.start(cache),
      cancelSyncPeopleRecommendations: () =>
        syncPeopleRecommendationsJob.cancel(),
      startSyncChannelRecommendations: () =>
        syncChannelRecommendationsJob.start(cache),
      cancelSyncChannelRecommendations: () =>
        syncChannelRecommendationsJob.cancel(),
      startSyncWeights,
      cancelSyncWeights,
      startSyncSuggestedQueryScore,
      cancelSyncSuggestedQueryScore,
      registerHit,
      addURLShortcutToCache,
    },
    [ServiceId.DBX_API, ServiceId.EXPERIMENTATION],
  );
}
