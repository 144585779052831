import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';

import type { dash } from '@dropbox/api-v2-client';
import type { CalendarEventState } from '@mirage/shared/util/calendar';

const TIME_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};
const DATE_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
};

export const convertTimeToHumanReadable = (
  startEpoch: number | undefined,
  endEpoch: number | undefined,
): string => {
  if (startEpoch === undefined || endEpoch === undefined) {
    return '';
  }

  const startTime = new Date(startEpoch).toLocaleTimeString(
    'en-US',
    TIME_LOCALE_OPTIONS,
  );
  const endTime = new Date(endEpoch).toLocaleTimeString(
    'en-US',
    TIME_LOCALE_OPTIONS,
  );

  return i18n.t('meeting_times', {
    startTime,
    endTime,
  });
};

export const convertDateToHumanReadable = (
  startEpoch: number | undefined,
  endEpoch: number | undefined,
) => {
  if (startEpoch == null || endEpoch == null) {
    return null;
  }

  const startDate = new Date(startEpoch).toLocaleDateString(
    'en-US',
    DATE_LOCALE_OPTIONS,
  );
  const endDate = new Date(endEpoch).toLocaleDateString(
    'en-US',
    DATE_LOCALE_OPTIONS,
  );

  return i18n.t('all_day_event', {
    startDate,
    endDate,
  });
};

export const eventIsInProgress = (event: dash.SearchResult) => {
  const currentTime = Date.now();
  return (
    (event.start_time ?? 0) < currentTime && (event.end_time ?? 0) > currentTime
  );
};

export const eventStartsInFutureAndLessThanMinutes = (
  event: dash.SearchResult,
  minutes: number,
) => {
  const currentTime = Date.now();
  return (
    (event.start_time ?? 0) > currentTime &&
    (event.start_time ?? 0) - currentTime < minutes * ONE_MINUTE_IN_MILLIS
  );
};

export const getHeaderEventIds = (
  calendarEvents: dash.SearchResult[],
  stripAllDayEvents: boolean = false,
): { id: string; state: CalendarEventState }[] => {
  if (calendarEvents.length === 0) {
    return [];
  }

  const headerEventIds: { id: string; state: CalendarEventState }[] = [];
  for (const event of calendarEvents) {
    if (stripAllDayEvents && event.is_all_day) {
      continue;
    } else if (eventIsInProgress(event)) {
      headerEventIds.push({ id: event.uuid ?? '', state: 'in_progress' });
    } else if (eventStartsInFutureAndLessThanMinutes(event, 10)) {
      headerEventIds.push({ id: event.uuid ?? '', state: 'upcoming' });
    }
  }

  // Fall back to just the first event
  return stripAllDayEvents || headerEventIds.length > 0
    ? headerEventIds
    : [{ id: calendarEvents[0].uuid ?? '', state: 'not_relevant' }];
};

export const useCalendarEventState = (
  event: dash.SearchResult,
  determineEventState: boolean,
) => {
  const [eventState, setEventState] =
    useState<CalendarEventState>('not_relevant');

  useEffect(() => {
    const refreshTime = () => {
      if (!determineEventState) {
        setEventState('not_relevant');
        return;
      }

      if (eventIsInProgress(event)) {
        setEventState('in_progress');
      } else if (eventStartsInFutureAndLessThanMinutes(event, 30)) {
        setEventState('upcoming');
      }
    };

    refreshTime();
    // Update the time every 30s
    const intervalId = setInterval(refreshTime, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [event, determineEventState]);

  return eventState;
};
