import {
  ALL_DAY_EVENT_THRESHOLD,
  ALL_DAY_TITLE_BLOCKLIST,
  UPCOMING_EVENT_THRESHOLD,
} from '@mirage/service-calendar-events/constants';
import { getEventTimeString } from '@mirage/shared/util/calendar';
import i18n, {
  i18nDebugLocale,
  i18nFallbackLanguage,
} from '@mirage/translations';
import {
  differenceInHours,
  differenceInMinutes,
  format,
  isFuture,
  isPast,
  isSameDay,
  isSameMonth,
} from 'date-fns';
import escapeRegExp from 'lodash.escaperegexp';

import type { dash } from '@dropbox/api-v2-client';

export type CalendarEvent = {
  uuid: string;
  startTime: number;
  endTime: number;
  allDay: boolean;
  title: string;
  description: {
    contentType: 'text/html' | 'text/plain';
    content: string;
  };
  attachments: dash.LinkAttachment[];
  attendees: dash.Author[];
  attendeesCount: number;
  creator: dash.Author;
  calendarUrl?: string;
  meetingUrl?: string;
};

export function convertToCalendarEvents(
  results: dash.SearchResult[] | undefined,
): CalendarEvent[] | undefined {
  return (
    results
      // Convert to CalendarEvent
      ?.map((event) => buildCalendarEvent(event))
      // Promote long upcoming or in-progress events to all-day status
      ?.map((event) => {
        event.allDay ||= !!(
          event.startTime &&
          event.endTime &&
          event.endTime - event.startTime >= ALL_DAY_EVENT_THRESHOLD &&
          isPast(event.startTime - UPCOMING_EVENT_THRESHOLD) &&
          isFuture(event.endTime)
        );
        return event;
      })
      // Drop any "noisy" events like Working Location or Out-of-office blocks
      ?.filter((event) => {
        return (
          !event.allDay ||
          !ALL_DAY_TITLE_BLOCKLIST.some((title) => event.title === title)
        );
      })
      // Sort the events by all-day status, start-time, then meeting length
      ?.sort(compareCalendarEvents)
  );
}

function buildCalendarEvent(event: dash.SearchResult): CalendarEvent {
  return {
    uuid: event.uuid || '',
    startTime: event.start_time || 0,
    endTime: event.end_time || 0,
    allDay: event.is_all_day || false,
    title: event.title || '',
    description: event.description
      ? {
          contentType: 'text/html',
          content: getDescription(event.title || '', event.description),
        }
      : {
          contentType: 'text/plain',
          content: event.highlights?.body?.text?.[0] || '',
        },

    attachments: event.attachments ? event.attachments : [],
    attendees: event.attendees || [],
    attendeesCount: event.attendees_count || 0,
    creator: event.creator || {},
    calendarUrl: event.url,
    meetingUrl: event.conference_links?.[0]?.link,
  };
}

function getDescription(title: string, description: string): string {
  // Connectors prefixes the event description with the title, followed by a newline
  // This is redundant, so we strip it out here
  return description.replace(new RegExp(`^${escapeRegExp(title)}\n`), '');
}

export function compareCalendarEvents(a: CalendarEvent, b: CalendarEvent) {
  return compareAllDay(a, b) || compareStartTime(a, b) || compareEndTime(a, b);
}

function compareAllDay(a: CalendarEvent, b: CalendarEvent) {
  return a.allDay === b.allDay ? 0 : a.allDay ? -1 : 1;
}

function compareStartTime(a: CalendarEvent, b: CalendarEvent) {
  return a.startTime - b.startTime;
}

function compareEndTime(a: CalendarEvent, b: CalendarEvent) {
  if (a.endTime) {
    return b.endTime ? a.endTime - b.endTime : -1;
  }
  return b.endTime ? 1 : 0;
}

export function getShowableAttendees(
  creator: dash.Author,
  attendees: dash.Author[],
  maxToShow?: number,
): dash.Author[] {
  const shownAttendees = attendees
    .filter(
      (attendee) =>
        attendee.profile_image_url || attendee.display_name || attendee.email,
    )
    .sort((a, b) => {
      return (
        (b.profile_image_url ? 1 : 0) - (a.profile_image_url ? 1 : 0) ||
        (b.display_name ? 1 : 0) - (a.display_name ? 1 : 0)
      );
    });

  // If we have no creator info, just return the first maxToShow attendees
  if (!creator.email && !creator.profile_image_url && !creator.display_name) {
    return shownAttendees.slice(0, maxToShow);
  }

  const creatorIdx = shownAttendees.findIndex((guest) => {
    // Check if the creator and guest are the same person by comparing email,
    // profile image, and display name. Assume that it's true if any of them
    // are missing, so that a missing value is not a disqualifier.
    let isSameEmail = true;
    let isSameProfileImage = true;
    let isSameDisplayName = true;
    let checkedAtLeastOneField = false;

    if (creator.email && guest.email) {
      isSameEmail = creator.email === guest.email;
      checkedAtLeastOneField = true;
    }

    if (creator.profile_image_url && guest.profile_image_url) {
      isSameProfileImage =
        creator.profile_image_url === guest.profile_image_url;
      checkedAtLeastOneField = true;
    }

    if (creator.display_name && guest.display_name) {
      isSameDisplayName = creator.display_name === guest.display_name;
      checkedAtLeastOneField = true;
    }

    return (
      checkedAtLeastOneField &&
      isSameEmail &&
      isSameProfileImage &&
      isSameDisplayName
    );
  });

  if (creatorIdx > 0) {
    shownAttendees.splice(creatorIdx, 1);
    shownAttendees.unshift(creator);
  }

  return shownAttendees.slice(0, maxToShow);
}

export function getNamesFromAttendees(
  shownAttendees: dash.Author[],
  numAttendees: number,
): string {
  const names = shownAttendees
    .filter((attendee) => attendee.display_name)
    .map((attendee) => getFirstName(attendee.display_name as string))
    .filter((name) => name); // Probably unnecessary, but just in case

  switch (names.length) {
    case 0:
      return i18n.t('num_guests', { count: numAttendees });
    case 1:
      if (numAttendees === 1) {
        return names[0];
      } else {
        return i18n.t('one_known_guest', {
          guest_one: names[0],
          count: numAttendees - 1,
        });
      }
    case 2:
      if (numAttendees === 2) {
        return i18n.t('two_known_guests', {
          guest_one: names[0],
          guest_two: names[1],
          count: 0,
        });
      }
      // Same as default case, but fall-throughs are prohibited
      return i18n.t('two_known_guests', {
        guest_one: names[0],
        guest_two: names[1],
        count: numAttendees - 2,
      });
    default:
      return i18n.t('two_known_guests', {
        guest_one: names[0],
        guest_two: names[1],
        count: numAttendees - 2,
      });
  }
}

export function getFirstName(name: string): string {
  const prefixPatterns = [/^\[.+\].*$/, /^\(.+\).*$/, /^<.+>.*$/, /^{.+}.*$/];
  const match = prefixPatterns
    .map((pattern) => name.match(pattern)?.[0])
    .find((name) => name);
  if (match) {
    return match;
  }

  return name.replace(/[^A-Za-z0-9 ]/g, '').split(/\s+/)[0];
}

export function getProximateTimeString(
  startTime: number,
  _endTime: number,
): string | undefined {
  const now = Date.now();
  const inProgress = isPast(startTime);
  const eventState = inProgress ? 'in_progress' : 'upcoming';
  const { hours, minutes } = inProgress
    ? {
        hours: differenceInHours(now, startTime),
        minutes: differenceInMinutes(now, startTime) % 60,
      }
    : {
        hours: differenceInHours(startTime, now),
        minutes: differenceInMinutes(startTime, now) % 60,
      };
  return getEventTimeString(eventState, hours, minutes);
}

export function getTimespanString(
  startTime: number,
  endTime: number,
  isAllDay: boolean,
): string {
  const now = new Date();
  const endTimeExclusive = endTime - 1;
  const singleDay = isSameDay(startTime, endTimeExclusive);
  const singleMonth = isSameMonth(startTime, endTimeExclusive);

  if (isAllDay) {
    if (singleDay) {
      return i18n.t('all_day', {
        day: isSameDay(startTime, now)
          ? i18n.t('today')
          : format(startTime, 'LLLL do'),
      });
    } else {
      const startIsToday = isSameDay(startTime, now);
      const endIsToday = isSameDay(endTimeExclusive, now);
      return i18n.t('all_day_multi_day', {
        start: startIsToday ? i18n.t('today') : format(startTime, 'LLLL do'),
        end: endIsToday
          ? i18n.t('today')
          : format(
              endTimeExclusive,
              singleMonth && !startIsToday ? 'do' : 'LLLL do',
            ),
      });
    }
  } else {
    const languages =
      i18n.language && i18n.language !== i18nDebugLocale
        ? [i18n.language]
        : [i18nFallbackLanguage];
    return i18n.t('meeting_times', {
      startTime: new Date(startTime).toLocaleTimeString(languages, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
      endTime: new Date(endTime).toLocaleTimeString(languages, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
    });
  }
}

export function getClickedAnchor(
  node: Node | null,
  root: Node,
): HTMLAnchorElement | null {
  while (node && node !== root) {
    if (node.nodeName === 'A') {
      return node as HTMLAnchorElement;
    }
    node = node.parentElement;
  }
  return null;
}

export function getTimeOfDay(date: Date = new Date()): string {
  const hour = date.getHours();
  if (hour >= 4 && hour < 12) {
    return i18n.t('morning');
  } else if (hour >= 12 && hour < 17) {
    return i18n.t('afternoon');
  } else {
    return i18n.t('evening');
  }
}
