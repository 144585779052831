import { LastUpdatedFilterKeys } from '@mirage/shared/last-updated/last-updated';
import {
  extractFilterBindingsFromString,
  SearchFilterKeyword,
  SearchFilterType,
  typeToKeyword,
} from '@mirage/shared/search/search-filters';
import i18n from '@mirage/translations';
import React, { useMemo } from 'react';

import type {
  ConnectorFilter,
  ContentTypeFilter,
  LastUpdatedFilter,
  PersonFilter,
  SearchFilter,
  SearchFilterBinding,
} from '@mirage/shared/search/search-filters';
import type { CSSProperties } from 'react';

type Props = {
  allFilters: SearchFilter[];
  query: string;
};

type FilterBindingMap = {
  [SearchFilterKeyword.Connector]: ConnectorFilter[];
  [SearchFilterKeyword.ContentType]?: ContentTypeFilter;
  [SearchFilterKeyword.Person]?: PersonFilter;
  [SearchFilterKeyword.LastUpdated]?: LastUpdatedFilter;
  [SearchFilterKeyword.LastUpdatedBefore]?: LastUpdatedFilter;
  [SearchFilterKeyword.LastUpdatedAfter]?: LastUpdatedFilter;
};

export function SuggestedQueryRowSubTitle({ query, allFilters }: Props) {
  const extractedBindings = useMemo<SearchFilterBinding[]>(
    () => extractFilterBindingsFromString(query),
    [query],
  );

  const filterBindingMap = useMemo<FilterBindingMap>(() => {
    return extractedBindings.reduce(
      (acc, [keyword, filterText]) => {
        let filter = allFilters.find(
          (filter) =>
            filter.id === filterText && typeToKeyword(filter.type) === keyword,
        );

        if (!filter && keyword !== SearchFilterKeyword.Person) {
          return acc;
        }

        if (keyword === SearchFilterKeyword.Person) {
          filter = {
            id: filterText,
            type: SearchFilterType.Person,
            parameters: {
              email: filterText,
            },
          };
        }

        return {
          ...acc,
          [keyword]: Array.isArray(acc[keyword])
            ? [...acc[keyword], filter]
            : filter,
        };
      },
      {
        [SearchFilterKeyword.Connector]: [],
        [SearchFilterKeyword.ContentType]: undefined,
        [SearchFilterKeyword.Person]: undefined,
        [SearchFilterKeyword.LastUpdated]: undefined,
        [SearchFilterKeyword.LastUpdatedBefore]: undefined,
        [SearchFilterKeyword.LastUpdatedAfter]: undefined,
      } as FilterBindingMap,
    );
  }, [extractedBindings, allFilters]);

  if (!extractedBindings.length) {
    return <SubtitleTextPart text={i18n.t('suggested_result_cta')} />;
  }

  const renderContentTypeTextPart = () => {
    if (!filterBindingMap[SearchFilterKeyword.ContentType]) {
      return <SubtitleTextPart text={i18n.t('suggested_result_cta')} />;
    }

    const contentType = filterBindingMap[SearchFilterKeyword.ContentType].id;

    return (
      <SubtitleTextPart
        text={i18n.t(
          contentType === 'audio'
            ? 'suggested_result_cta_content_type_files'
            : 'suggested_result_cta_content_type',
          {
            contentType,
          },
        )}
      />
    );
  };

  const renderConnectorsTextPart = () => {
    if (!Array.isArray(filterBindingMap[SearchFilterKeyword.Connector])) {
      return;
    }

    if (!filterBindingMap[SearchFilterKeyword.Connector].length) {
      return;
    }

    const [one, ...rest] = filterBindingMap[SearchFilterKeyword.Connector];

    const joinedConnectors = rest
      .map((item) => item.parameters.displayName)
      .join(', ');

    const singularConnector = one.parameters.displayName;

    return (
      <SubtitleTextPart
        text={
          i18n.t('suggested_result_cta_connectors', {
            joinedConnectors,
          }) +
          (rest.length
            ? i18n.t('suggested_result_cta_connectors_end', {
                singularConnector,
              })
            : singularConnector)
        }
      />
    );
  };

  const renderPersonTextPart = () => {
    if (!filterBindingMap[SearchFilterKeyword.Person]) {
      return;
    }

    const person = filterBindingMap[SearchFilterKeyword.Person].id;

    return (
      <SubtitleTextPart
        text={i18n.t('suggested_result_cta_person', {
          person,
        })}
      />
    );
  };

  const renderLastUpdatedTextPart = () => {
    if (
      !filterBindingMap[SearchFilterKeyword.LastUpdated] ||
      filterBindingMap[SearchFilterKeyword.LastUpdated].id ===
        LastUpdatedFilterKeys.Any_Date
    ) {
      return;
    }

    const lastUpdated =
      filterBindingMap[
        SearchFilterKeyword.LastUpdated
      ].parameters.title.toLowerCase();

    return (
      <SubtitleTextPart
        text={i18n.t('suggested_result_cta_last_updated', {
          lastUpdated,
        })}
      />
    );
  };

  return (
    <>
      {renderContentTypeTextPart()}
      {renderConnectorsTextPart()}
      {renderPersonTextPart()}
      {renderLastUpdatedTextPart()}
    </>
  );
}

const SubtitleTextPart = ({ text }: { text: string }) => {
  const spacing: CSSProperties = {
    marginRight: 'var(--dig-spacing__micro__xsmall)',
  };

  return <span style={spacing}>{text}</span>;
};
