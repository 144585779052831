import { EmailValidationError } from '../types';

// TODO (OTCGA-58): i18n once copy finalized
export const getErrorMessage = (
  error: Exclude<EmailValidationError, EmailValidationError.VALIDATION_PENDING>,
): string => {
  switch (error) {
    case EmailValidationError.MALFORMED:
      return 'Check email format';
    case EmailValidationError.INELIGIBLE_FOR_DASH_UNSPECIFIED:
      return 'Not eligible for Dash';
    case EmailValidationError.TEAM_MEMBER_ALREADY_WITH_DASH:
      return 'Already a Dash member';
    case EmailValidationError.TEAM_MEMBER_PENDING_INVITE:
      return 'Already invited';
    case EmailValidationError.ALREADY_ON_ANOTHER_TEAM:
      return 'Already part of another team. They can join with a different email.';
    case EmailValidationError.PAID_PERSONAL_OR_TRAIL:
      return 'Can’t be invited to a free plan';
    case EmailValidationError.NO_INVITE_PERMISSION:
      return 'No permission to invite this email to your team';
    default:
      return 'Couldn’t validate email. Try again if it’s not correct.';
  }
};
