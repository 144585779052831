import useSettings from './useSettings';

import type { StackSortPreference } from './service/types';

export function useStackByIdSortSettings(
  id: string,
  initialValue?: StackSortPreference,
) {
  const { settings, set: setSettings } = useSettings([
    'stackByIdSortPreference',
  ]);
  const stackByIdSortPreference = settings?.stackByIdSortPreference ?? {};
  const sortPreference: StackSortPreference | undefined =
    typeof stackByIdSortPreference[id] === 'object'
      ? (stackByIdSortPreference[id] ?? initialValue)
      : initialValue;

  /**
   * Set the sort option for the current stack. If no parameter is passed,
   * it will delete the preference, allowing the default to be applied.
   */
  const setStackByIdSortPreference = (state?: StackSortPreference) => {
    if (state !== undefined) {
      setSettings('stackByIdSortPreference', {
        ...stackByIdSortPreference,
        [id]: state,
      });
    } else {
      const newStackByIdSortOption = { ...stackByIdSortPreference };
      delete newStackByIdSortOption[id];
      setSettings('stackByIdSortPreference', newStackByIdSortOption);
    }
  };

  return {
    sortPreference,
    setStackByIdSortPreference,
  };
}
