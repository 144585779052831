import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useDashTeamInfo } from './useDashTeamInfo';
import { useOnboardingSurveys } from './useOnboardingSurveys';

import type { OnboardingStorageSSOnboardingState } from '@mirage/service-onboarding/service';
import type { OnboardingSurveyId } from '@mirage/service-onboarding-surveys/service';
import type { OnboardingSurveyAnswer } from '@mirage/service-onboarding-surveys/service/types';

const ONBOARDING_STATE_KEY = 'selfServeOnboardingState';

export type SelfServeOnboardingState = OnboardingStorageSSOnboardingState & {
  surveyResponses: Map<OnboardingSurveyId, OnboardingSurveyAnswer[]>;
};

export const useSelfServeOnboardingState = () => {
  const { submitSurvey } = useOnboardingSurveys();
  const { getOnboardingValue, setOnboardingValue } = useOnboardingValues();
  const { teamName, teamLogoUrl, updateTeamLogo } = useDashTeamInfo();

  const [companyName, setCompanyName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [companyLogo, setCompanyLogo] = useState('');
  const [surveyResponses, setSurveyResponses] = useState<
    Map<OnboardingSurveyId, OnboardingSurveyAnswer[]>
  >(new Map());

  const getOnboardingState = useCallback(async () => {
    const onboardingState = await getOnboardingValue(ONBOARDING_STATE_KEY);
    if (!onboardingState) return {} as SelfServeOnboardingState;
    let surveyResponsesMap = new Map();
    if (onboardingState.surveyResponsesStr) {
      surveyResponsesMap = new Map(
        JSON.parse(onboardingState.surveyResponsesStr),
      );
    }
    const state: SelfServeOnboardingState = {
      ...onboardingState,
      surveyResponses: surveyResponsesMap,
    };

    return state;
  }, [getOnboardingValue]);

  useEffect(() => {
    const loadOnboardingState = async () => {
      const onboardingState = await getOnboardingState();

      const companyNameFromOnboarding =
        onboardingState.companyName ?? teamName ?? '';
      const companyLogoFromOnboarding =
        onboardingState.companyLogo ?? teamLogoUrl ?? '';

      setCompanyName(companyNameFromOnboarding);
      setCompanyLogo(companyLogoFromOnboarding);
      setSurveyResponses(onboardingState.surveyResponses ?? new Map());
    };

    loadOnboardingState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run once

  const saveOnboardingState = async () => {
    setIsSaving(true);
    // update team logo via updateTeamLogo
    if (companyLogo) {
      await updateTeamLogo(companyLogo);
    }
    // submit team specific profiling survey responses
    await Promise.allSettled(
      Array.from(surveyResponses.entries()).map(([surveyId, answers]) =>
        submitSurvey(answers, surveyId),
      ),
    );
    setIsSaving(false);
  };

  const storeCompanyLogo = async (companyLogo: string) => {
    const onboardingState = await getOnboardingState();
    onboardingState.companyLogo = companyLogo;
    await storeOnboardingState(onboardingState);
    setCompanyLogo(companyLogo);
  };

  const storeCompanyName = async (companyName: string) => {
    const onboardingState = await getOnboardingState();
    onboardingState.companyName = companyName;
    await storeOnboardingState(onboardingState);
    setCompanyName(companyName);
  };

  const storeSurveyResponses = async (
    surveyName: OnboardingSurveyId,
    surveyResponses: OnboardingSurveyAnswer[],
  ) => {
    const onboardingState = await getOnboardingState();
    if (!onboardingState.surveyResponses) {
      onboardingState.surveyResponses = new Map<
        OnboardingSurveyId,
        OnboardingSurveyAnswer[]
      >();
    }
    onboardingState.surveyResponses.set(surveyName, surveyResponses);
    await storeOnboardingState(onboardingState);
    setSurveyResponses(onboardingState.surveyResponses);
  };

  const storeOnboardingState = async (
    onboardingState: SelfServeOnboardingState,
  ) => {
    let surveyResponsesStr = '';
    if (onboardingState.surveyResponses) {
      surveyResponsesStr = JSON.stringify(
        Array.from(onboardingState.surveyResponses.entries()),
      );
    }

    return await setOnboardingValue(ONBOARDING_STATE_KEY, {
      ...onboardingState,
      surveyResponsesStr,
    });
  };

  return {
    isSaving,
    companyLogo,
    companyName,
    surveyResponses,
    saveOnboardingState,
    setCompanyLogo: storeCompanyLogo,
    setCompanyName: storeCompanyName,
    setSurveyResponses: storeSurveyResponses,
  };
};
