import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { Subject } from 'rxjs';
import { CommentsBolt } from '../bolt';
import {
  createStackMetadataComment,
  createStackStream,
  denormalizeStackComments,
} from '../utils/stack';
import { addComment, deleteComment, editComment, listComments } from './api';

import type { CommentsSubjectData, StackComment } from '../types';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { Observable } from 'rxjs';

export type Service = ReturnType<typeof commentsService>;

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

const logger = tagged('comments-service');

export default function commentsService(
  dbxApiService: DbxApiServiceContract,
  isDev: boolean = false,
  boltOrigin?: string,
) {
  // Simple state to keep track of the most recent stack identifier for refreshing comments on bolt updates
  let activeStackIdentifier: string | null = null;
  const bolt = boltOrigin
    ? new CommentsBolt(boltOrigin, isDev, false, refreshComments)
    : undefined;
  const commentsSubject = new Subject<CommentsSubjectData>();

  async function addStackComment(
    stackSharingId: string,
    stackItemId: string,
    content: string,
  ): Promise<string> {
    const stream = createStackStream(stackSharingId);
    const comment = createStackMetadataComment(stackItemId, content);

    try {
      return await addComment(dbxApiService.callApiV2, comment, stream);
    } catch (e) {
      const message = 'Failed to add comment to stack';

      logger.error(message, e);
      Sentry.withScope((scope) => {
        scope.setTags({
          errorMessage: message,
          stackItemId,
          stackSharingId,
        });

        Sentry.captureException(
          e,
          {
            data: {
              message,
              stackSharingId,
              stackItemId,
            },
          },
          scope,
        );
      });

      throw e;
    }
  }

  async function deleteStackComment(
    stackSharingId: string,
    commentId: string,
  ): Promise<string> {
    const stream = createStackStream(stackSharingId);

    try {
      return await deleteComment(dbxApiService.callApiV2, commentId, stream);
    } catch (e) {
      const message = 'Failed to delete comment from stack';

      logger.error(message, e);
      Sentry.withScope((scope) => {
        scope.setTags({
          commentId,
          errorMessage: message,
          stackSharingId,
        });

        Sentry.captureException(
          e,
          {
            data: {
              commentId,
              message,
              stackSharingId,
            },
          },
          scope,
        );
      });

      throw e;
    }
  }

  async function editStackComment(
    stackSharingId: string,
    stackItemId: string,
    commentId: string,
    content: string,
  ): Promise<string> {
    const stream = createStackStream(stackSharingId);
    const comment = createStackMetadataComment(stackItemId, content);

    try {
      return await editComment(
        dbxApiService.callApiV2,
        comment,
        commentId,
        stream,
      );
    } catch (e) {
      const message = 'Failed to edit comment in stack';

      logger.error(message, e);
      Sentry.withScope((scope) => {
        scope.setTags({
          commentId,
          errorMessage: message,
          stackItemId,
          stackSharingId,
        });

        Sentry.captureException(
          e,
          {
            data: {
              commentId,
              message,
              stackSharingId,
              stackItemId,
            },
          },
          scope,
        );
      });

      throw e;
    }
  }

  async function listStackComments(
    stackSharingId: string,
  ): Promise<StackComment[]> {
    const stream = createStackStream(stackSharingId);

    try {
      const resp = await listComments(dbxApiService.callApiV2, stream);

      // When a request is made to load comments for a stack, we subscribe to bolt and set the active stack identifier
      bolt?.subscribe(resp.bolt_info);
      activeStackIdentifier = stackSharingId;

      return denormalizeStackComments(resp.threads, resp.users);
    } catch (e) {
      const message = 'Failed to list comments for stack';
      logger.error(message, e);
      Sentry.withScope((scope) => {
        scope.setTags({
          errorMessage: message,
          stackSharingId,
        });

        Sentry.captureException(
          e,
          {
            data: {
              message,
              stackSharingId,
            },
          },
          scope,
        );
      });

      throw e;
    }
  }

  function commentsObservable(): Observable<CommentsSubjectData> {
    return commentsSubject.asObservable();
  }

  // Use the active stack identifier to refresh comments. Not exported because it's only used by bolt.
  async function refreshComments(): Promise<void> {
    if (activeStackIdentifier) {
      const stream = createStackStream(activeStackIdentifier);

      try {
        const resp = await listComments(dbxApiService.callApiV2, stream);
        const comments = denormalizeStackComments(resp.threads, resp.users);

        // Push the refreshed comments so observers can update their state
        commentsSubject.next({
          stackSharingId: activeStackIdentifier,
          comments,
        });
      } catch (e) {
        // We log here, but don't rethrow because this is a background operation and the client need not respond to it
        const message = 'Failed to refresh comments for stack';
        logger.error(message, e);
        Sentry.withScope((scope) => {
          scope.setTags({
            errorMessage: message,
            stackSharingId: activeStackIdentifier,
          });

          Sentry.captureException(
            e,
            {
              data: {
                message,
                stackSharingId: activeStackIdentifier,
              },
            },
            scope,
          );
        });
      }
    }
  }

  return services.provide(
    ServiceId.COMMENTS,
    {
      addStackComment,
      deleteStackComment,
      editStackComment,
      listStackComments,
      commentsObservable,
      // Exposed for testing
      refreshComments,
    },
    [ServiceId.DBX_API],
  );
}
