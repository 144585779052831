import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { DropboxLine, StacksFill } from '@dropbox/dig-icons/dist/mjs/assets';
import { GlyphLogo } from '@dropbox/dig-logos';
import data from '@emoji-mart/data';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_UpdateStackEmoji } from '@mirage/analytics/events/types/cancel_update_stack_emoji';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { lazy, Suspense } from 'react';
import { renderToString } from 'react-dom/server';
import styles from './EmojiPicker.module.css';

import type { Cancel_UpdateStackEmoji } from '@mirage/analytics/events/types/cancel_update_stack_emoji';

const Picker = lazy(() => import('@emoji-mart/react'));

/**
 * This is a filler type for the emoji data that is returned from the emoji picker.
 */
export type EmojiData = {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string[];
  skin?: number;
  src?: string;
  aliases?: string[];
  emoticons?: string[];
};

export const EmojiPicker = ({
  setEmojiPickerVisible,
  onEmojiSelect,
  papProps,
}: {
  setEmojiPickerVisible: (visible: boolean) => void;
  onEmojiSelect: (emoji: EmojiData) => void;
  papProps: Cancel_UpdateStackEmoji['properties'];
}) => {
  const isAugRev = useStackPageAugustRevisionEnabled();
  const { reportPapEvent } = useMirageAnalyticsContext();
  const theme = useTheme();
  const isDarkModeEnabled = theme.mode === 'dark';
  const dropboxIcon = renderToString(
    <GlyphLogo
      size={32}
      src={DropboxLine}
      xmlns="http://www.w3.org/2000/svg"
    />,
  );
  const stackIconSvgSrc = renderToString(<UIIcon src={StacksFill} />)
    // This is needed to make the SVG render correctly in an img tag
    .replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ')
    // This is needed to define an explicit color for the SVG
    .replace('currentColor', '%23736C64');

  // custom emojis do not have a "native" representation, and so wind up clearing
  // the emoji field when selected. This is fine for the purposes of the default
  // emoji, but will need to be addressed if we want to add more.
  const customIcons = [
    {
      id: 'dropbox',
      name: 'Dropbox',
      emojis: [
        // {
        //   id: 'dash',
        //   name: 'Dash',
        //   skins: [
        //     {
        //       src: `data:image/svg+xml;charset=utf-8,${dashIcon}`,
        //     },
        //   ],
        // },
        {
          id: 'stack',
          name: 'Stack Icon',
          keywords: ['stack', 'dash', 'clear', 'delete', 'reset'],
          skins: [
            {
              src: `data:image/svg+xml;charset=utf-8,${stackIconSvgSrc}`,
            },
          ],
        },
      ],
    },
  ];

  return (
    <ClickOutside
      onClickOutside={() => {
        setEmojiPickerVisible(false);
        reportPapEvent(PAP_Cancel_UpdateStackEmoji(papProps));
      }}
      isActive={true}
      isClickThroughPortaled={false}
    >
      <div
        className={
          isAugRev
            ? styles.emojiPickerContainerAugRev
            : styles.emojiPickerContainer
        }
      >
        <Suspense fallback={<></>}>
          <Picker
            data={data}
            theme={isDarkModeEnabled ? 'dark' : 'light'}
            onEmojiSelect={onEmojiSelect}
            categories={[
              'frequent',
              'dropbox',
              'people',
              'nature',
              'foods',
              'activity',
              'places',
              'objects',
              'symbols',
              'flags',
            ]}
            categoryIcons={{
              dropbox: {
                svg: `${dropboxIcon}`,
              },
            }}
            custom={customIcons}
          />
        </Suspense>
      </div>
    </ClickOutside>
  );
};
