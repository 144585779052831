import { type dash_growth } from '@dropbox/api-v2-client';
import { callApiV2 } from '@mirage/service-dbx-api';
import { useCallback, useRef } from 'react';
import { EmailValidationError, type TeamMember } from '../types';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export type EmailValidator = (
  email: string,
  onError?: (error: EmailValidationError) => void,
) => Promise<EmailValidationError | null>;

interface UseInviteFormEmailValidationArgs {
  knownValidTeamMemberInvitees: Readonly<TeamMember[]>;
}

interface UseInviteFormEmailValidationReturnType {
  emailValidator: EmailValidator;
}

export const useInviteFormEmailValidation = ({
  knownValidTeamMemberInvitees,
}: UseInviteFormEmailValidationArgs): UseInviteFormEmailValidationReturnType => {
  const errorCacheRef = useRef<Record<string, EmailValidationError | null>>({});

  const emailValidator: EmailValidator = useCallback(
    async (email, onError) => {
      let error: EmailValidationError | null = null;
      if (!EMAIL_REGEX.test(email)) {
        // TODO: replace with existing email validation util if available
        error = EmailValidationError.MALFORMED;
      } else if (errorCacheRef.current[email] !== undefined) {
        error = errorCacheRef.current[email];
      } else if (
        knownValidTeamMemberInvitees.some((member) => member.email === email)
      ) {
        error = null;
      } else {
        try {
          const { result } = await callApiV2(
            'dashGrowthTeamInvitesValidateInviteeEmails',
            {
              emails: [email],
            },
          );
          const reason: dash_growth.InvalidEmailReason | null = result
            ? result[email]
            : null;
          switch (reason && reason['.tag']) {
            case 'invalid_email_reason_none':
              error = null;
              break;
            case 'invalid_email_reason_ineligible_account':
              error = EmailValidationError.INELIGIBLE_FOR_DASH_UNSPECIFIED;
              break;
            case 'invalid_email_reason_malformed_email':
              error = EmailValidationError.MALFORMED;
              break;
            case 'invalid_email_reason_existing_team_member_with_dash':
              error = EmailValidationError.TEAM_MEMBER_ALREADY_WITH_DASH;
              break;
            case 'invalid_email_reason_pending_team_member':
              error = EmailValidationError.TEAM_MEMBER_PENDING_INVITE;
              break;
            case 'invalid_email_reason_associated_with_different_team':
              error = EmailValidationError.ALREADY_ON_ANOTHER_TEAM;
              break;
            case 'invalid_email_reason_paid_personal_or_trial':
              error = EmailValidationError.PAID_PERSONAL_OR_TRAIL;
              break;
            case 'invalid_email_reason_no_invite_permission':
              error = EmailValidationError.NO_INVITE_PERMISSION;
              break;
            default:
              error = EmailValidationError.UNKNOWN;
          }
        } catch (e) {
          // Behavior TBD
          error = EmailValidationError.VALIDATION_ERROR;
        }
      }

      if (error !== null) {
        onError?.(error);
      }
      if (error !== EmailValidationError.VALIDATION_ERROR) {
        errorCacheRef.current[email] = error;
      }
      return error;
    },
    [knownValidTeamMemberInvitees],
  );

  return { emailValidator };
};
