import {
  ContentRowAvatar,
  ContentRowIcon,
} from '@dropbox/dash-component-library';
import { getActors } from '@mirage/service-feed/util/helpers';
import { getInitials } from '@mirage/shared/account';
import { AvatarCluster } from './AvatarCluster/AvatarCluster';

import type { dash_feed } from '@dropbox/api-v2-client';

type ActivityThumbnailProps = {
  activity: dash_feed.FeedItem;
};

const MAX_AVATARS_TO_SHOW = 3;

export const ActivityThumbnail = ({ activity }: ActivityThumbnailProps) => {
  const actors = getActors(activity).slice(0, MAX_AVATARS_TO_SHOW);
  const hasNoOutline = actors.length <= 1;

  let avatar = null;
  if (actors.length === 0) {
    avatar = <ContentRowAvatar isGuest />;
  } else {
    const avatars = actors.map((actor) => {
      const src = actor.avatar_img;
      const initials = actor.name && getInitials(actor.name);
      const isGuest = !src && !initials;

      return {
        src,
        initials,
        isGuest,
        hasNoOutline,
      };
    });
    avatar = <AvatarCluster avatars={avatars} />;
  }
  return <ContentRowIcon icon={avatar} />;
};
