import type { dash_feed } from '@dropbox/api-v2-client';

// Converts a v1 activity item to a v2 feed item
export const convertActivityItemToFeedItem = (
  item: dash_feed.ActivityItem,
): dash_feed.FeedItem => {
  return {
    is_read: item.is_read,
    target: item.object,
    activities: [toActivity(item)],
  };
};

const toActivity = (item: dash_feed.ActivityItem): dash_feed.FeedActivity => {
  return {
    ts: (item.ts ?? 0) / 1000,
    action: toAction(item),
    actor: item.actor,
  };
};

const toAction = (
  item: dash_feed.ActivityItem,
): dash_feed.ActivityAction | undefined => {
  switch (item.action?.object_action?.['.tag']) {
    case 'comment':
      return { action: { '.tag': 'comment' } };
    case 'share':
      return { action: { '.tag': 'share' } };
    case 'remove_child':
      return {
        action: {
          '.tag': 'remove_child',
          removed_object: toIndirectTarget(item),
        },
      };
    case 'add_child':
      return {
        action: {
          '.tag': 'add_child',
          added_object: toIndirectTarget(item),
        },
      };
    case 'edit':
      return {
        action: { '.tag': 'edit', updates: item.action.object_action.updates },
      };
  }
};

const toIndirectTarget = (
  item: dash_feed.ActivityItem,
): dash_feed.ActivityObject | undefined => {
  const objectAction = item.action?.object_action;
  switch (objectAction?.['.tag']) {
    case 'add_child':
    case 'remove_child':
      return objectAction;
  }
};
