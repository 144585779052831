import './variables.module.css';

import { Box } from '@dropbox/dig-foundations';
import { ScopedButtonRow } from '@mirage/mosaics/Omnibox/components/Omnibar/ScopedButtonRow/ScopedButtonRow';
import cx from 'classnames';
import React from 'react';
import styles from './Omnibar.module.css';

import type { OmniboxScopedMode } from '@mirage/mosaics/Omnibox/types';

export type OmnibarProps = {
  // event handler for when we click the (+) sign to attach sources
  onAttachButtonClicked: () => void;
  // event handler for any of the scoped action buttons being clicked (find, ask, organize, create)
  onScopedButtonClicked: (m: OmniboxScopedMode) => void;
  // whether the OmniboxResults (typeahed overlay) is open or not
  open: boolean;
  // input row content
  children: React.ReactNode;
  // whether to show the scoped button row
  showScopedButtonRow?: boolean;
};

export const Omnibar = ({
  onAttachButtonClicked,
  onScopedButtonClicked,
  open: isResultsOpen,
  children,
  showScopedButtonRow = true,
}: OmnibarProps) => {
  return (
    <Box
      className={cx(styles.omniboxInputContainer, {
        [styles.hasResultContainer]: isResultsOpen,
      })}
    >
      {/* TODO: OSE-5086 */}

      {/* Render back chevron + text input for query */}
      <Box paddingTop={'Micro Medium'} paddingBottom={'Micro Medium'}>
        {children}
      </Box>

      {/* Render buttons for adding attachments & buttons for changing omnibox scope/mode */}
      {showScopedButtonRow && (
        <ScopedButtonRow
          onAttachButtonClicked={onAttachButtonClicked}
          onScopedButtonClicked={onScopedButtonClicked}
        />
      )}
    </Box>
  );
};
Omnibar.displayName = 'Omnibar';
