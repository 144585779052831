import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Text } from '@dropbox/dig-components/typography';
import { Cluster, Stack, useTheme } from '@dropbox/dig-foundations';
import { ConnectorIcon } from '@mirage/shared/icons';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo } from 'react';
import styles from './MultiAnswerCardContainer.module.css';

import type { Connectors } from '@mirage/service-connectors/service';

interface MultiAnswerCardContainerWideLoadingProps {
  connectors: Connectors;
}
export const MultiAnswerCardContainerWideLoading = memo(
  ({ connectors }: MultiAnswerCardContainerWideLoadingProps) => {
    return (
      <div className={styles.mainContent}>
        <Cluster gap="3px" alignY="center" className={styles.titleContainer}>
          <Cluster.Item>
            <Text size="medium" color="subtle">
              {i18n.t('answers_loading_text', {
                count: connectors.length,
              })}
            </Text>
          </Cluster.Item>
          <Cluster.Item>
            <ConnectorIcons connectors={connectors} />
          </Cluster.Item>
        </Cluster>
        <div className={classNames(styles.content, styles.isLoading)}>
          <Stack>
            {/* using explicit maxWidth as Skeleton width is limited to 311px */}
            <Skeleton.Text width={208} style={{ maxWidth: '373px' }} />
            <Skeleton.Text width={330} style={{ maxWidth: '592px' }} />
            <Skeleton.Text width={311} style={{ maxWidth: '558px' }} />
          </Stack>
        </div>
      </div>
    );
  },
);
MultiAnswerCardContainerWideLoading.displayName =
  'MultiAnswerCardContainerWideLoading';

interface ConnectorIconsProps {
  connectors: Connectors;
}
export const ConnectorIcons = memo(({ connectors }: ConnectorIconsProps) => {
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';
  const connectorsToShow = connectors.filter(
    (connector) => connector.id_attrs?.type,
  );
  const iconsToShow = connectorsToShow.slice(0, 3);
  return (
    <div className={styles.connectorIcons}>
      {iconsToShow.map((connector) => (
        <div key={connector.id_attrs!.type} className={styles.connectorIcon}>
          <ConnectorIcon
            key={connector.id_attrs!.type}
            connectorName={connector.id_attrs!.type!}
            iconUrl={
              isDarkMode && connector.branding?.icon_dark_src
                ? connector.branding?.icon_dark_src
                : connector.branding?.icon_src
            }
            size={16}
          />
        </div>
      ))}
    </div>
  );
});
ConnectorIcons.displayName = 'ConnectorIcons';
