import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import {
  $computeTableMapSkipCellCheck,
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $isTableCellNode,
  getDOMCellFromTarget,
  type TableCellNode,
  type TableDOMCell,
  type TableMapValueType,
} from '@lexical/table';
import { calculateZoomLevel } from '@lexical/utils';
import { getTableElements } from '@mirage/mosaics/ComposeAssistant/components/editor/table/CustomTableNode';
import { $getNearestNodeFromDOMNode } from 'lexical';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './TableCellResizerPlugin.module.css';

import type { LexicalEditor } from 'lexical';
import type { CSSProperties, Dispatch, SetStateAction } from 'react';

export const TableCellResizerPlugin = memo(() => {
  const isEditable = useLexicalEditable();
  return isEditable ? <TableCellResizerContainer /> : null;
});
TableCellResizerPlugin.displayName = 'TableCellResizerPlugin';

type ResizeState =
  | {
      type: 'resizing';
      mouseStartPos: { x: number; y: number };
      mouseCurrentPos: { x: number; y: number };
      targetCell: TableDOMCell;
      targetTableElements: TargetTableElements;
    }
  | {
      type: 'idle';
    }
  | {
      type: 'ready';
      hoverCell: TableDOMCell;
      targetTableElements: TargetTableElements;
    };
interface TargetTableElements {
  tableElement: HTMLElement; // <table> element
  controlsContainer: HTMLElement; // portal container
  containerElement: HTMLElement; // positions should be relative to this element
}

export const TableCellResizerContainer = memo(() => {
  const [resizeState, setResizeState] = useState<ResizeState>({
    type: 'idle',
  });
  const [editor] = useLexicalComposerContext();
  const resizerRef = useRef<HTMLDivElement>(null);

  useMouseMoveTracker(resizeState, setResizeState, resizerRef);
  const handleStartReize = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (resizeState.type !== 'ready') {
        throw new Error(
          `started resize on unexpected state: ${resizeState.type}`,
        );
      }
      const targetCell = resizeState.hoverCell;
      setResizeState({
        type: 'resizing',
        mouseStartPos: { x: event.clientX, y: event.clientY },
        mouseCurrentPos: { x: event.clientX, y: event.clientY },
        targetCell,
        targetTableElements: resizeState.targetTableElements,
      });

      const handleEndResize = createEndResizeHandler(
        { x: event.clientX, y: event.clientY },
        (widthChange) => {
          resizeCell(editor, targetCell, widthChange);
          setResizeState({ type: 'idle' });
        },
      );
      document.addEventListener('pointerup', handleEndResize);
    },
    [editor, resizeState],
  );

  const showResizer =
    resizeState.type === 'ready' || resizeState.type === 'resizing';
  const resizerStyles = getResizerStyles(resizeState);
  return showResizer
    ? createPortal(
        <div
          ref={resizerRef}
          className={styles.resizer}
          onPointerDown={handleStartReize}
          role="button"
          aria-hidden
          style={resizerStyles}
        />,
        resizeState.targetTableElements.controlsContainer,
      )
    : null;
});
TableCellResizerContainer.displayName = 'TableCellResizerContainer';

function createEndResizeHandler(
  initialMousePos: { x: number; y: number },
  onCommitResize: (widthChange: number) => void,
) {
  const handler = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const zoom = calculateZoomLevel(event.target as Element);
    const widthChange = (event.clientX - initialMousePos.x) / zoom;
    onCommitResize(widthChange);

    document.removeEventListener('pointerup', handler);
  };
  return handler;
}

const MIN_COLUMN_WIDTH = 50;

function resizeCell(
  editor: LexicalEditor,
  cell: TableDOMCell,
  widthChange: number,
) {
  // updater logic here references playground example:
  // https://github.com/facebook/lexical/blob/3131ff89e746b640dacbfc9314b9d718236f6a7d/packages/lexical-playground/src/plugins/TableCellResizer/index.tsx#L258
  editor.update(
    () => {
      const tableCellNode = $getNearestNodeFromDOMNode(cell.elem);
      if (!$isTableCellNode(tableCellNode)) {
        throw new Error('TableCellResizer: Table cell node not found.');
      }

      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
      const [tableMap] = $computeTableMapSkipCellCheck(tableNode, null, null);
      const columnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);
      if (columnIndex < 0) {
        throw new Error('TableCellResizer: Table column not found.');
      }

      // resize all cells in the column
      for (let row = 0; row < tableMap.length; row++) {
        const cell: TableMapValueType = tableMap[row][columnIndex];
        if (
          cell.startRow === row &&
          (columnIndex === tableMap[row].length - 1 ||
            tableMap[row][columnIndex].cell !==
              tableMap[row][columnIndex + 1].cell)
        ) {
          const width = getCellNodeWidth(cell.cell, editor);
          if (width === undefined) {
            continue;
          }
          const newWidth = Math.max(width + widthChange, MIN_COLUMN_WIDTH);
          cell.cell.setWidth(newWidth);
        }
      }
    },
    { tag: 'skip-scroll-into-view' },
  );
}

function getCellNodeWidth(
  cell: TableCellNode,
  activeEditor: LexicalEditor,
): number | undefined {
  // references logic from playground example:
  // https://github.com/facebook/lexical/blob/3131ff89e746b640dacbfc9314b9d718236f6a7d/packages/lexical-playground/src/plugins/TableCellResizer/index.tsx#L211
  const width = cell.getWidth();
  if (width !== undefined) {
    return width;
  }

  const domCellNode = activeEditor.getElementByKey(cell.getKey());
  if (domCellNode == null) {
    return undefined;
  }
  const computedStyle = getComputedStyle(domCellNode);
  return (
    domCellNode.clientWidth -
    parseFloat(computedStyle.paddingLeft) -
    parseFloat(computedStyle.paddingRight)
  );
}

// monitor mouse move events to update cell being hovered over; or, when dragging, to update the
// current mouse position
function useMouseMoveTracker(
  resizeState: ResizeState,
  setResizeState: Dispatch<SetStateAction<ResizeState>>,
  resizerRef: React.RefObject<HTMLDivElement>,
) {
  const [editor] = useLexicalComposerContext();
  const prevTargetRef = useRef<HTMLElement | null>(null);
  const hoverCell =
    resizeState.type === 'ready' ? resizeState.hoverCell : undefined;

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setTimeout(() => {
        const target = event.target;

        // update current mouse position if resizing
        if (resizeState.type === 'resizing') {
          setResizeState((state) => {
            if (state?.type === 'resizing') {
              return {
                ...state,
                mouseCurrentPos: { x: event.clientX, y: event.clientY },
              };
            }
            return state;
          });
          return;
        }

        // don't change state cases when user is mouse overing the resizer itself
        if (resizerRef.current && resizerRef.current.contains(target as Node)) {
          return;
        }

        if (prevTargetRef.current === target) {
          return; // no change, skip lookup
        }
        prevTargetRef.current = target as HTMLElement;
        const cell = getDOMCellFromTarget(target as HTMLElement);

        if (cell && hoverCell !== cell) {
          editor.update(() => {
            const tableCellNode = $getNearestNodeFromDOMNode(cell.elem);
            if (!tableCellNode) {
              throw new Error('TableCellResizer: Table cell node not found.');
            }

            const tableNode =
              $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
            const tableContainerElement = editor.getElementByKey(
              tableNode.getKey(),
            );

            if (!tableContainerElement) {
              throw new Error('TableCellResizer: Table element not found.');
            }
            const tableElements = getTableElements(tableContainerElement);
            if (!tableElements) {
              throw new Error('TableCellResizer: Table elements not found.');
            }

            setResizeState({
              type: 'ready',
              hoverCell: cell,
              targetTableElements: tableElements,
            });
          });
        } else if (cell == null) {
          setResizeState({
            type: 'idle',
          });
        }
      }, 0);
    };

    // NOTE: we track pointer rather than mouse events to align with Lexical's event handling.
    document.addEventListener('pointermove', handleMouseMove);
    return () => document.removeEventListener('pointermove', handleMouseMove);
  }, [hoverCell, setResizeState, resizeState.type, editor, resizerRef]);
}

function getResizerStyles(resizeState: ResizeState): CSSProperties {
  if (resizeState.type === 'idle') {
    return {};
  }

  const anchorElement = resizeState.targetTableElements.containerElement;
  const cell =
    resizeState.type === 'resizing'
      ? resizeState.targetCell
      : resizeState.hoverCell;
  const cellRect = cell.elem.getBoundingClientRect();
  const zoom = calculateZoomLevel(cell.elem);
  const zoneWidth = 10; // Pixel width of the zone where you can drag the edge
  let left = cellRect.left + cellRect.width - zoneWidth / 2;
  let top = cellRect.top;
  const styles: CSSProperties = {
    backgroundColor: 'none',
    cursor: 'col-resize',
    height: `${cellRect.height}px`,
    width: `${zoneWidth}px`,
  };

  if (resizeState.type === 'resizing') {
    const tableRect =
      resizeState.targetTableElements.tableElement.getBoundingClientRect();
    left = resizeState.mouseCurrentPos.x / zoom;
    top = tableRect.top;
    styles.width = '3px';
    styles.height = `${tableRect.height}px`;
    styles.backgroundColor = 'var(--dig-color__primary__base)';
  }

  // offset coordinates to be relative to the anchor element
  const anchorRect = anchorElement.getBoundingClientRect();
  styles.left = `${left - anchorRect.left}px`;
  styles.top = `${top - anchorRect.top}px`;
  return styles;
}
