import type { WorkflowStep } from './types';

export const Step1: WorkflowStep = {
  name: 'Tool type',
  step: 1,
  inputs: [
    {
      id: 'type',
      name: 'Type',
      description: 'Select the type of tool you want to use',
      type: 'tile-select',
      required: true,
      addedOptions: [],
      availableOptions: [
        {
          id: 'chat',
          label: 'Chat',
          description: "You set the prompt to guide Dash's behavior",
          isSelected: true,
        },
        {
          id: 'write',
          label: 'Write',
          description: 'Dash generates a document based on the conversation',
        },
        {
          id: 'analysis',
          label: 'Data Analysis',
          description:
            'Dash analyzes a set of data with views, charts, and more',
          disabled: true,
        },
      ],
    },
  ],
};

export const Step2: WorkflowStep = {
  name: 'Behavior',
  step: 2,
  inputs: [
    {
      id: 'base-model',
      name: 'Base model',
      type: 'select',
      required: true,
      availableOptions: [
        {
          id: 'CONTROL',
          label: 'gpt-4o-mini',
          description: 'The latest and greatest model from OpenAI',
          isSelected: true,
        },
        {
          id: 'V1',
          label: 'pixtral-12b-128k',
          description: 'A smaller, faster model for lower latency',
        },
        {
          id: 'V2',
          label: 'pixtral-12b-32k',
          description: 'A larger, more powerful model for higher quality',
        },
      ],
    },
    {
      id: 'prompt',
      name: 'Prompt',
      type: 'paragraph',
      required: true,
      description: 'Enter a custom prompt for the tool',
      placeholder: 'Try something descriptive',
    },
    {
      id: 'web-search',
      name: 'Web search',
      type: 'boolean',
      required: false,
      description: 'Enable Dash to search the web',
    },
    {
      id: 'suggestions',
      name: 'Follow up suggestions',
      type: 'boolean',
      required: false,
      description: 'Enable follow up suggestions from the user',
    },
  ],
};

export const Step3: WorkflowStep = {
  name: 'Workflow',
  step: 3,
  inputs: [
    {
      id: 'step-1',
      name: 'Step 1',
      type: 'combo-step',
      comboInputs: [
        {
          id: 'prompt',
          name: 'Prompt',
          type: 'text',
          required: true,
          placeholder: 'What would you like to know?',
        },
        {
          id: 'prompt-type',
          name: 'Prompt type',
          type: 'select',
          required: true,
          addedOptions: [],
          availableOptions: [
            {
              id: 'open',
              label: 'Open Ended',
              isSelected: true,
            },
            {
              id: 'template',
              label: 'Template selection',
              disabled: true,
            },
            {
              id: 'tone',
              label: 'Tone selection',
              disabled: true,
            },
            {
              id: 'search',
              label: 'Dash search/upload',
              disabled: true,
            },
          ],
        },
      ],
      required: true,
      description: 'The user responds via the chat.',
    },
  ],
};

export const Step4: WorkflowStep = {
  name: 'Knowledge',
  step: 4,
  inputs: [
    {
      id: 'sources',
      name: 'Add sources',
      type: 'source-select',
      required: false,
    },
  ],
};

export const Step5: WorkflowStep = {
  name: 'Publish',
  step: 5,
  inputs: [
    {
      id: 'name',
      name: 'Name',
      type: 'text',
      description: 'The name of the tool',
      placeholder: 'Try something descriptive',
      required: true,
    },
    {
      id: 'icon',
      name: 'Icon',
      type: 'icon-select',
      required: false,
      group: 'appearance',
    },
    {
      id: 'theme',
      name: 'Theme',
      type: 'color-select',
      required: false,
      group: 'appearance',
    },
    {
      id: 'description',
      name: 'Description',
      type: 'paragraph',
      description: 'A description of the tool',
      placeholder: 'Try something descriptive',
      required: true,
    },
    {
      id: 'publish',
      name: 'Publish',
      type: 'radio-select',
      description: 'Set who can access this tool',
      required: true,
      availableOptions: [
        {
          id: 'private',
          label: 'Only me',
          isSelected: true,
        },
        // {
        //   id: 'team',
        //   label: 'Team',
        //   description: 'Your team will be able to use this tool',
        // },
        {
          id: 'company',
          label: 'Entire company',
          description: 'Everyone at your company will be able to use this tool',
        },
      ],
    },
  ],
};
