// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event Property Definition: https://pap.pp.dropbox.com/#/event_property/2886

import type { TemplateId } from "./template_id";

const TemplateIdValues: TemplateId[] = [
    "faq_document",
    "team_meeting_agenda",
    "project_kickoff",
    "meeting_summary",
    "task_tracking",
    "status_report",
    "change_management_plan",
    "team_recognition_email",
    "standard_operating_procedure_sop",
    "follow_up_email",
    "meeting_scheduling_email",
    "customer_onboarding_email",
    "sales_outreach_email",
    "marketing_email_campaign",
    "marketing_blog_post",
    "sales_customer_onboarding_email",
    "customer_support_email",
    "product_release_notes",
    "analysis_report",
    "business_memo",
    "user_generated",
];

export function getTemplateIdFromString(s: string): TemplateId | undefined {
    return TemplateIdValues.find(e => e === s?.toLowerCase());
}
