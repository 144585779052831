import { Timestamp } from '@bufbuild/protobuf';
import { ConversationsChatMessage } from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/gen/conversations_pb';

import type {
  ConversationMessage,
  MessageStatus,
} from '@mirage/service-conversation/types';

/**
 * Safely converts a timestamp from various possible formats to a Date object
 * @param timestamp The timestamp to convert
 * @returns A Date object or undefined if conversion is not possible
 */
const safelyConvertTimestamp = (timestamp: unknown): Date | undefined => {
  if (!timestamp) {
    return undefined;
  }

  if (
    typeof timestamp === 'object' &&
    'toDate' in timestamp &&
    typeof timestamp.toDate === 'function'
  ) {
    return timestamp.toDate();
  }

  if (timestamp instanceof Date) {
    return timestamp;
  }

  return undefined;
};

/**
 * Converts a ConversationsChatMessage from the gRPC API to our internal ConversationMessage type
 * @param message The ConversationsChatMessage to convert
 * @param status The status to assign to the converted message
 * @returns A ConversationMessage
 */
export const convertToConversationMessage = (
  message: ConversationsChatMessage,
  status: MessageStatus,
): ConversationMessage => {
  // Safely convert timestamp if it exists
  const timestamp = safelyConvertTimestamp(message.timestamp);

  switch (message.messageType.case) {
    case 'systemMessage':
      return {
        id: message.id,
        timestamp: timestamp,
        messageType: message.messageType,
        status: status,
      };
    case 'assistantMessage':
      return {
        id: message.id,
        timestamp: timestamp,
        messageType: {
          case: 'assistantMessage',
          value: {
            text: message.messageType.value.text,
            isCached: message.messageType.value.isCached,
            cited: message.messageType.value.cited.map((cited) => ({
              ...cited,
              lastUpdatedMs: Number(cited.lastUpdatedMs),
            })),
          },
        },
        status: status,
      };
    case 'userMessage':
      return {
        id: message.id,
        timestamp: timestamp,
        messageType: {
          case: 'userMessage',
          value: {
            ...message.messageType.value,
            context: message.messageType.value.context.map((context) => ({
              ...context,
              lastUpdatedMs: Number(context.lastUpdatedMs),
            })),
          },
        },
        status: status,
      };
    default:
      return {
        id: message.id,
        timestamp: timestamp,
        messageType: { case: undefined },
        status: status,
      };
  }
};

/**
 * Converts our internal ConversationMessage type to a ConversationsChatMessage for the gRPC API
 * @param message The ConversationMessage to convert
 * @returns A ConversationsChatMessage
 */
export const convertToConversationsChatMessage = (
  message: ConversationMessage,
): ConversationsChatMessage => {
  const grpcTimestamp: Timestamp | undefined = message.timestamp
    ? Timestamp.fromDate(message.timestamp)
    : undefined;

  switch (message.messageType.case) {
    case 'systemMessage':
      return new ConversationsChatMessage({
        id: message.id,
        timestamp: grpcTimestamp,
        messageType: {
          case: message.messageType.case,
          value: message.messageType.value,
        },
      });
    case 'assistantMessage':
      return new ConversationsChatMessage({
        id: message.id,
        timestamp: grpcTimestamp,
        messageType: {
          case: message.messageType.case,
          value: {
            text: message.messageType.value.text,
            isCached: message.messageType.value.isCached,
            cited: message.messageType.value.cited.map((cited) => ({
              ...cited,
              lastUpdatedMs: BigInt(cited.lastUpdatedMs),
            })),
            context: [],
          },
        },
      });
    case 'userMessage':
      return new ConversationsChatMessage({
        id: message.id,
        timestamp: grpcTimestamp,
        messageType: {
          case: message.messageType.case,
          value: {
            ...message.messageType.value,
            context: message.messageType.value.context.map((context) => ({
              ...context,
              lastUpdatedMs: BigInt(context.lastUpdatedMs),
            })),
          },
        },
      });
    default:
      return new ConversationsChatMessage({
        id: message.id,
        timestamp: grpcTimestamp,
      });
  }
};
