import type { SearchResult } from '@mirage/service-dbx-api/service/search';

/**
 * This file should be removed once the data model is updated to support structured data for the new fields.
 *
 * Slack stopgap for Q4 2024 connectors work. Currently the connectors team is just putting extra metadata into the
 * title content of a search result. This function will parse that data out and return it as a separate object.
 *
 * In Q1 2025 the data model will be updated to support structured data for the new fields, until then
 * we have to use this hack... :(
 */
export function formatSlackResult(searchResult: SearchResult): SearchResult {
  if (searchResult.connectorInfo?.connectorName !== 'slack') {
    return searchResult;
  }

  if (searchResult.logicalType === 'channel') {
    searchResult.title = `#${searchResult.title}`;
  }

  // logical type went missing from message results, so infer it from the messages array
  // TODO OSE-5104 remove this once message gaps are filled
  if (!searchResult.logicalType && (searchResult.messages?.length || 0) > 0) {
    searchResult.logicalType = 'message';
  }

  const channelName = searchResult.channel?.name;

  if (searchResult.channel && channelName) {
    searchResult.channel = {
      ...searchResult.channel,
      name: `# ${channelName}`,
    };
  }

  return searchResult;
}
