import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CreateStackLine,
  LinkLine,
  Twinkle2Line,
  VerifiedFill,
  VerifiedLine,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashSearchResultPage } from '@mirage/analytics/events/types/open_dash_search_result_page';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { DebugCard } from '@mirage/mosaics/DebugCard';
import { useFlyoutPanel } from '@mirage/mosaics/FlyoutPanel/useFlyoutPanel';
import { useKeyboardNavigation } from '@mirage/mosaics/GlobalNav/useKeyboardNavigation';
import { EnterKey } from '@mirage/mosaics/Results/EnterKey';
import { AnnotationsConfirmation } from '@mirage/mosaics/SearchPage/Annotations/AnnotationsConfirmation';
import { SearchResults } from '@mirage/search';
import { getActionButtonAnalytics } from '@mirage/search/SearchResults/ResultRow';
import { DisplayActionTypes } from '@mirage/search/SearchResults/ResultRow/types';
import { isSummarizable } from '@mirage/search/SearchResults/util/resultUtil';
import {
  isAdminUser,
  useAccountIsDropboxer,
} from '@mirage/service-auth/useDropboxAccount';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { publishEvent, SurveyEvent } from '@mirage/service-feedback';
import { getOnboardingValue, isUsersDay0 } from '@mirage/service-onboarding';
import { useOnboardingValues } from '@mirage/service-onboarding/hooks';
import { namespace } from '@mirage/service-operational-metrics';
import {
  copyToClipboard,
  openLocalPath,
  openURL,
} from '@mirage/service-platform-actions';
import { openResult } from '@mirage/service-result-actions';
import useSettings from '@mirage/service-settings/useSettings';
import { registerHit } from '@mirage/service-typeahead-search';
import { getVerification } from '@mirage/shared/curations';
import useAMISSearchResultLogging from '@mirage/shared/hooks/useAMISSearchResultLogging';
import {
  getDashActionSurface,
  useDashActionSurface,
} from '@mirage/shared/hooks/useDashActionSurface';
import { usePreviousPath } from '@mirage/shared/hooks/usePreviousPath';
import {
  calculatePapPositions,
  useResultPAPLogger,
} from '@mirage/shared/hooks/useResultPAPLogger';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import {
  captureSerpClicMetrics,
  captureSerpImpressionMetrics,
  getSearchResultPosition,
} from '@mirage/shared/operational-metrics/operational-metrics';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  SearchFilterType,
  syncQueryWithFilters,
} from '@mirage/shared/search/search-filters';
import { isDesktopLocalFile } from '@mirage/shared/search/search-result';
import { MultimediaView } from '@mirage/shared/search/search-result';
import { showSnackbar } from '@mirage/shared/snackbar';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import { SEARCH_INITIATOR_PARAM_NAME } from '@mirage/shared/util/types';
import { useShowStackChooserModal } from '@mirage/stacks/StackChooser/StackChooser';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MultiAnswerCard,
  type MultiAnswerCardProps,
} from '../MultiAnswersCard/MultiAnswerCard';
import { useInitialConversation } from '../MultiAnswersCard/useInitialConversation';
import useMultiAnswers from '../MultiAnswersCard/useMultiAnswers';
import {
  SummaryQnAEnum,
  useSummarizableReasons,
} from '../SummaryQnaPanel/utils';
import { VerifyContentModal } from '../Verification/VerifyContentModal';
import { AnswersAnnotationModal } from './Annotations/AnswersAnnotationModal';
import { convertAnswerRespToAnnotation } from './Annotations/AnswersAnnotationModal/util/util';
import { CategorySearchAccordion } from './CategorySearchAccordion/CategorySearchAccordion';
import { useCategoriesConfig } from './CategorySearchAccordion/useCategoriesConfig';
import { useMultimediaView } from './Multimedia/hooks/useMultimediaView';
import { MultimediaSearchResults } from './Multimedia/MultimediaSearchResults';
import styles from './SearchPage.module.css';
import { SearchPageHeader } from './SearchPageHeader';

import type { AnnotationAnswer } from './Annotations/AnswersAnnotationModal/AnswersAnnotationModal';
import type { dash, stacks } from '@dropbox/api-v2-client';
import type { ContentIconProps } from '@dropbox/dash-component-library';
import type { DashSearchInitiator } from '@mirage/analytics/events/enums/dash_search_initiator';
import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { SessionStartReason } from '@mirage/analytics/events/enums/session_start_reason';
import type { ResultActionConfig } from '@mirage/search/SearchResults/ResultRow';
import type { Connectors } from '@mirage/service-connectors/service';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { AnswerResponse } from '@mirage/service-dbx-api/service/answers';
import type { SearchContext } from '@mirage/service-search/hooks';
import type { QuestionAndAnswerResponse } from '@mirage/shared/answers/multi-answer';
import type {
  PersonFilter,
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import type { FeedbackOptions } from '@mirage/shared/types';
import type { Dispatch, SetStateAction } from 'react';

type Props = {
  searchContext: SearchContext;
  connectors: Connectors;
  isDesktop?: boolean;
  onResubmitSearch: () => void;
  setTypeaheadQuery: Dispatch<SetStateAction<string>>;
};

const metrics = namespace('search-results');

const DEBUG_SENTRY = false;
const SUMMARIZE_TOOLTIP_DISMISSED = 'dismissedSummarizeTooltip';

export default function SearchPage({
  searchContext,
  connectors,
  isDesktop,
  onResubmitSearch,
  setTypeaheadQuery,
}: Props) {
  const {
    results,
    error,
    loading,
    handleSearch,
    query,
    filters,
    browserHistoryConnectors,
    annotations,
  } = searchContext;
  const showMultimediaView = useMultimediaView(filters);
  const [peekResultActionsEnabled, setPeekResultActionsEnabled] =
    useState<boolean>(false);
  const {
    answers,
    loading: loadingAnswers,
    fetch: handleFetchAnswers,
    reset: resetAnswers,
    isStreamingEnabled,
  } = useMultiAnswers();
  const { dashActionSurface } = useDashActionSurface();
  const { previousPath } = usePreviousPath(false);
  const { setOnboardingValue } = useOnboardingValues();
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();
  const papLogger = useResultPAPLogger();
  const { account, isDropboxer } = useAccountIsDropboxer();

  const isAdmin = isAdminUser(account);
  const [cachedVerifications, setCachedVerifications] = useState<{
    [key: string]: dash.Curation | null;
  }>({});
  const [verifyingResult, setVerifyingResult] = useState<SearchResult | null>(
    null,
  );
  const isFirstSearch = useRef(true);

  const {
    logResultShown: logAMISResultShown,
    logResultLaunched: logAMISResultLaunched,
  } = useAMISSearchResultLogging(EnvCtx, account, isDropboxer || false);
  const { searchQueryUuid } = useSearchQueryId();
  const { settings } = useSettings(['annotationMode']);
  const isMobileSize = useIsMobileSize();
  const navigate = useNavigate();
  const getSummarizableReason = useSummarizableReasons();

  const { handleKeyDown, resetSelectedItem } = useKeyboardNavigation();
  const isMultimodalSummarizationEnabled =
    useFeatureFlagValue('dash_2024_09_03_search_summary_enable_multimodal') ===
    'ON';
  const isSpreadsheetSummarizationEnabled =
    useFeatureFlagValue('dash_2024_09_26_summary_enable_spreadsheet') === 'ON';
  const isUsingTypeaheadFiltersFeatureFlag =
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters') === 'ON';
  const verifiedSearchResultsEnabled =
    useFeatureFlagValue('dash_2024_10_30_verified_search_results') === 'ON';
  const isSpellCorrectEnabled =
    useFeatureFlagValue('dash_2025_01_31_spell_correct') === 'ON';

  const isCategoriesEnabled =
    useFeatureFlagValue('dash_2025_03_05_serp_categories') === 'ON';

  const annotationMode = !!settings?.annotationMode;
  const requestId = results.find((r) => r.searchRequestId)?.searchRequestId;
  const multimodalFileTypeFilter =
    (useFeatureFlagValue(
      'context_engine_2024_11_26_multimodal_file_type_filter',
    ) as { [key: string]: string[] }) || {};

  const categoriesConfig = useCategoriesConfig();
  const areFiltersEnabled = filters.length > 0;

  const onClickPerson = ({
    email,
    displayName,
    profilePhotoUrl,
  }: PersonObject) => {
    if (!email.length) return; // We need email for filtering. If this is empty, return early

    // Update/add people filter to our existing set of filters and trigger a search
    const filtersWithoutPerson = filters.filter(
      (filter) => filter.type !== SearchFilterType.Person,
    );
    const personFilter: PersonFilter = {
      id: email,
      type: SearchFilterType.Person,
      parameters: {
        email,
        displayName,
        profilePhotoUrl,
      },
    };

    handleFilterChange([...filtersWithoutPerson, personFilter]);
  };

  const resetAllFilters = () => {
    handleFilterChange([]);
  };

  const getIconSize = (): ContentIconProps['size'] => {
    if (isDesktop) {
      return 'medium';
    }

    return isMobileSize ? 'large' : 'xlarge';
  };

  useEffect(() => {
    const load = async () => {
      const value = await isUsersDay0();
      setPeekResultActionsEnabled(value);
    };
    load();
  }, [setPeekResultActionsEnabled]);

  useEffect(() => {
    if (!query) return;

    if (areFiltersEnabled) {
      // Clear answer state when filters are enabled since we re-fetch when they become disabled
      resetAnswers();
    } else {
      // Only fetch new answer if filters aren't enabled
      handleFetchAnswers(query);
    }
  }, [query, areFiltersEnabled, handleFetchAnswers, resetAnswers]);

  // Answers annotation
  const [isAnswersAnnotationOpen, setIsAnswersAnnotationOpen] = useState(false);
  const [summarizeTooltipDismissed, setSummarizeTooltipDismissed] =
    useState(false);
  const [feedback, setFeedback] = useState<FeedbackOptions | undefined>(
    undefined,
  );
  const [annotationAnswer, setAnnotationAnswer] = useState<AnnotationAnswer>();

  const handleOpenAnnotationModal = (
    feedback: FeedbackOptions,
    answer: QuestionAndAnswerResponse | AnswerResponse,
  ) => {
    setAnnotationAnswer(convertAnswerRespToAnnotation(answer));
    setFeedback(feedback);
    setIsAnswersAnnotationOpen(true);
  };

  const { summarize, closeFlyoutPanel } = useFlyoutPanel();
  const showStackChooserModal = useShowStackChooserModal(navigate);

  useEffect(() => {
    const getDismissed = async () => {
      const isDismissed = await getOnboardingValue(SUMMARIZE_TOOLTIP_DISMISSED);
      setSummarizeTooltipDismissed(!!isDismissed);
    };
    getDismissed();
  }, [getOnboardingValue]);

  useEffect(() => {
    searchSessionManager.updateProperties({
      hasSerpOpened: true,
    });
  }, []);

  useEffect(() => {
    if (isFirstSearch.current) {
      isFirstSearch.current = false;
      const searchInitiator = new URLSearchParams(window.location.search).get(
        SEARCH_INITIATOR_PARAM_NAME,
      );
      extendSearchSession('open_serp', query);

      const searchAttemptManager = searchAttemptSessionManager.getSession();

      const previousPathSurface = previousPath
        ? getDashActionSurface(previousPath)
        : undefined;

      const searchSession = searchSessionManager.getSession();
      let actionSurfaceComponent;
      const initiator = (searchInitiator ?? undefined) as
        | DashSearchInitiator
        | undefined;

      if (searchSession) {
        actionSurfaceComponent =
          searchSession.properties?.actionSurfaceComponent;

        searchSessionManager.updateProperties({
          actionSurfaceComponent: undefined,
          dashSearchInitiator: initiator,
        });
      }

      reportPapEvent(
        PAP_Open_DashSearchResultPage({
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          searchAttemptId:
            searchAttemptSessionManager.getSessionIdOrUndefined(),
          searchResultPageSource:
            searchAttemptManager?.properties.searchSurface,
          featureLine: 'search',
          actionSurface: previousPathSurface ?? dashActionSurface,
          actionSurfaceComponent,
          searchQueryUuid,
          dashSearchInitiator: initiator,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPath]);

  // Clear caches everytime search is rerunn
  useEffect(() => {
    if (loading === true) {
      setCachedVerifications({});
    }
  }, [loading]);

  // Close dynamic panel when navigating away from search page
  useEffect(() => {
    return () => {
      closeFlyoutPanel();
    };
    // We omit closeFlyoutPanel from the dependencies array to avoid
    // inadvertently closing the flyout panel when it is updated upon
    // re-rendering. We only wish to close it when this component is
    // unmounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (nextFilters: SearchFilter[]) => {
    handleSearch(
      isUsingTypeaheadFiltersFeatureFlag
        ? syncQueryWithFilters(query, nextFilters)
        : query,
      nextFilters,
    );
  };

  const logAddToStack = useCallback(
    (searchResult: SearchResult, stack?: stacks.Stack) => {
      papLogger.logSearchResultAddedToStack_SERP(
        {
          searchResult,
          query,
          filters,
          results,
          featureLine: 'search',
        },
        stack,
      );
      publishEvent(SurveyEvent.AddStackItem, {}, true);
    },
    [results],
  );

  const logSearchResultOpen = useCallback(
    (searchResult: SearchResult, launchMethod: LaunchMethod) => {
      const rightRailOpened = false; // as of 8/7/24 we don't have a right rail
      const isTypeahead = false;
      papLogger.logSearchResultOpen({
        defaultProps: {
          searchResult,
          query,
          filters,
          results,
          featureLine: 'search',
        },
        rightRailOpened,
        isTypeahead,
        launchMethod,
      });

      captureSerpClicMetrics(
        metrics,
        searchResult,
        getSearchResultPosition(results, searchResult),
      );
      logAMISResultLaunched(searchResult);
    },
    [results],
  );

  const logSearchResultShown = useCallback(
    (searchResult: SearchResult) => {
      extendSearchSession('scroll_serp', query);
      papLogger.logSearchResultShown(
        {
          searchResult,
          query,
          filters,
          results,
          featureLine: 'search',
        },
        false,
      );

      captureSerpImpressionMetrics(
        metrics,
        searchResult,
        getSearchResultPosition(results, searchResult),
      );

      logAMISResultShown(searchResult);
    },
    [results],
  );

  // use a callback to create closure around the search result
  const handleOpenResult = (
    searchResult: SearchResult,
    launchMethod: LaunchMethod,
  ) => {
    if (isDesktopLocalFile(searchResult) && searchResult.url) {
      openLocalPath(searchResult.url);
    } else {
      openResult(searchResult);
    }
    logSearchResultOpen(searchResult, launchMethod);
    registerHit(searchResult.uuid);
  };

  const handleSummarize = useCallback(
    (searchResult: SearchResult) => {
      if (
        !isSummarizable(
          searchResult,
          isMultimodalSummarizationEnabled,
          isSpreadsheetSummarizationEnabled,
          multimodalFileTypeFilter,
        )
      ) {
        return;
      }
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === searchResult.uuid,
      );
      summarize(
        searchResult,
        SummaryQnAEnum.SEARCH,
        ChatEntryPoint.search_result_summary,
        resultPosition,
        resultPositionNoCta,
      );
      extendSearchSession('result_action', query);
      // If the user summarizes a doc, dismiss the tooltip
      setOnboardingValue(SUMMARIZE_TOOLTIP_DISMISSED, true);
      publishEvent(SurveyEvent.SummarizeSearchResult, {}, true);
    },
    [
      results,
      setOnboardingValue,
      isMultimodalSummarizationEnabled,
      isSpreadsheetSummarizationEnabled,
      calculatePapPositions,
      isSummarizable,
      summarize,
    ],
  );

  const handleCopyResult = (searchResult: SearchResult) => {
    copyToClipboard(searchResult.url || '');
    extendSearchSession('result_action', query);
    papLogger.logSearchResultCopy({
      searchResult,
      query,
      filters,
      results,
      featureLine: 'search',
    });
    showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
    extendSearchSession('result_action', query);
    registerHit(searchResult.uuid);
  };

  const handleAddToStack = (searchResult: SearchResult) => {
    if (!searchResult.url || isDesktopLocalFile(searchResult)) {
      return;
    }
    extendSearchSession('result_action', query);

    showStackChooserModal({
      metadataTitle: searchResult.title,
      url: searchResult.url,
      brandedType: searchResult.brandedType || undefined,
      id3p: searchResult.id3p || undefined,
      callback: (stack?: stacks.Stack) => {
        logAddToStack(searchResult, stack);
      },
      searchTerm: query,
    });
  };

  const getResultActionsConfig = (
    searchResult: SearchResult,
    results: SearchResult[],
  ) => {
    const possibleLaunchActions = [
      {
        key: 'open',
        title: i18n.t('result_open_button_text'),
        icon: <EnterKey />,
        onClick: (launchMethod: LaunchMethod) => {
          handleOpenResult(searchResult, launchMethod);
          extendSearchSession('result_action', query);
        },
        hidden: false,
        analyticsAttr: getActionButtonAnalytics(searchResult, 'open'),
      },
    ];

    const canSummarize = isSummarizable(
      searchResult,
      isMultimodalSummarizationEnabled,
      isSpreadsheetSummarizationEnabled,
      multimodalFileTypeFilter,
    );
    const possibleDisplayActions = [
      {
        key: 'copy',
        title: i18n.t('copy_link'),
        type: DisplayActionTypes.IconButton,
        icon: <UIIcon src={LinkLine} />,
        onClick: () => handleCopyResult(searchResult),
        hidden: false,
        persistent: isMobileSize,
        analyticsAttr: getActionButtonAnalytics(searchResult, 'copy'),
      },
      {
        key: 'add_to_stack',
        title: i18n.t('add_to_stack'),
        type: DisplayActionTypes.IconButton,
        icon: <UIIcon src={CreateStackLine} />,
        onClick: () => handleAddToStack(searchResult),
        persistent: isMobileSize,
        hidden: !searchResult.url || isDesktopLocalFile(searchResult),
        analyticsAttr: getActionButtonAnalytics(
          searchResult,
          'add_to_stack',
          'stacks',
        ),
      },
      {
        key: 'summarize',
        title: canSummarize
          ? i18n.t('summarizable_reason_yes_summarizable')
          : getSummarizableReason(searchResult.summarizable),
        type: DisplayActionTypes.IconButton,
        icon: <UIIcon src={Twinkle2Line} />,
        disabled: !canSummarize,
        onClick: () => {
          handleSummarize(searchResult);
        },
        tooltip: async () => {
          const isNewUser = await isUsersDay0();
          if (!summarizeTooltipDismissed && canSummarize && isNewUser) {
            const firstSummarizeSearchResult = results.find(
              (r) => r.summarizable,
            );
            if (
              firstSummarizeSearchResult &&
              firstSummarizeSearchResult.uuid === searchResult.uuid
            ) {
              return {
                key: SUMMARIZE_TOOLTIP_DISMISSED,
                title: i18n.t('summarize_tip_title'),
                message: i18n.t('summarize_tip_message'),
              };
            }
          }
          return undefined;
        },
        persistent: isMobileSize && canSummarize,
        analyticsAttr: getActionButtonAnalytics(
          searchResult,
          'summarize',
          'answers',
        ),
      },
      {
        key: 'verify',
        title: getVerification(searchResult)
          ? i18n.t('verify_content_manage_verification')
          : i18n.t('verify_content_as_accurate'),
        type: DisplayActionTypes.IconButton,
        icon: (
          <UIIcon
            src={getVerification(searchResult) ? VerifiedFill : VerifiedLine}
          />
        ),
        onClick: () => setVerifyingResult(searchResult),
        hidden: !(
          isAdmin &&
          verifiedSearchResultsEnabled &&
          searchResult.sourceIndexType?.['.tag'] === 'source_index_type_shared'
        ),
        // TODO: add analyticsAttr
      },
    ];

    // Filter out any actions that we should hide
    const actionsConfig: ResultActionConfig = {
      displayActions: possibleDisplayActions.filter((action) => !action.hidden),
      launchAction: possibleLaunchActions.find((action) => !action.hidden),
    };

    return actionsConfig;
  };

  const extendSearchSession = (
    sessionStartReason: SessionStartReason | undefined,
    query: string,
  ) => {
    searchSessionManager.extendOrCreateSession(sessionStartReason);

    searchSessionManager.updateProperties({
      hasSerpOpened: true,
    });

    if (query !== '') {
      // If a query exists, set has_query to true
      // If a query does not exist, leave it as true for the remainder of the session
      searchSessionManager.updateProperties({
        hasQuery: true,
      });
    }
  };

  // Conversation logic
  const isConversationAssistantEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2025_02_11_conversation_assistant'),
  );

  const {
    messages,
    isWaitingForResponse,
    startNewConversation,
    conversationId,
  } = useInitialConversation();

  // If flag is on, initiate a new conversation on each new query (unless filters are enabled)
  useEffect(() => {
    if (isConversationAssistantEnabled && query && !areFiltersEnabled) {
      startNewConversation(query);
    }
  }, [
    query,
    areFiltersEnabled,
    isConversationAssistantEnabled,
    startNewConversation,
  ]);

  if (DEBUG_SENTRY) {
    throw new Error(`DEBUG_SENTRY unhandled exception in mirage`);
  }

  /* eslint-disable jsx-a11y/no-static-element-interactions */
  const renderSearchResults = () => (
    <div onKeyDown={handleKeyDown}>
      <ClickOutside
        isBlock
        isClickThroughPortaled={false}
        shouldPropagateMouseEvents
        isActive
        onClickOutside={resetSelectedItem}
      >
        <SearchResults
          query={
            isSpellCorrectEnabled && annotations?.correctedQuery
              ? annotations.correctedQuery
              : query
          }
          results={results}
          loading={loading}
          error={error}
          iconSize={getIconSize()}
          getActionConfig={getResultActionsConfig}
          onCopyResult={handleCopyResult}
          onListItemView={logSearchResultShown}
          activeFilters={filters}
          annotationsEnabled={annotationMode}
          peekResultActionsEnabled={peekResultActionsEnabled}
          onClickPerson={onClickPerson}
          resetAllFilters={resetAllFilters}
          onResubmitSearch={onResubmitSearch}
          onAddToStack={handleAddToStack}
          onSummarize={handleSummarize}
          cachedVerifications={cachedVerifications}
        />
      </ClickOutside>
    </div>
  );

  const renderMultiAnswerCard = (variant: MultiAnswerCardProps['variant']) => {
    // If filters are enabled we shouldn't render an answer
    if (areFiltersEnabled) {
      return null;
    }

    /**
     * If search errors out for some reason, hide answers
     * because it looks weird to show answers on the right without search results on the left.
     * Search results and answers are generated by two parallel calls to our API.
     * If the search API call fails, just hide answers, even if the answers API call succeeded.
     */
    if (error) {
      return null;
    }

    return (
      <>
        <MultiAnswerCard
          variant={variant}
          multiAnswerResponse={
            isConversationAssistantEnabled ? undefined : answers
          }
          loading={
            isConversationAssistantEnabled
              ? isWaitingForResponse
              : loadingAnswers
          }
          connectors={connectors}
          messages={messages}
          conversationId={conversationId}
          query={query}
          onShowFeedbackSnackbar={() => {
            showSnackbar({
              title: i18n.t('feedback_received'),
            });
          }}
          onShowCopyMessageSnackbar={() => {
            showSnackbar({ title: i18n.t('copied_answer') });
          }}
          annotationMode={Boolean(isDropboxer)}
          onOpenAnnotationModal={handleOpenAnnotationModal}
          isStreamingEnabled={isStreamingEnabled}
        />
        {isCategoriesEnabled && (
          <CategorySearchAccordion
            query={query}
            seeAllForCategory={handleFilterChange}
            categories={categoriesConfig}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={classNames(styles.contentLayout, styles.largeGrid, {
          [styles.isMobile]: isMobileSize,
          [styles.isFullWidth]: isDesktop,
        })}
      >
        <div
          className={classNames(styles.resultsContainer, {
            [styles.isFullWidth]: isDesktop,
          })}
        >
          <TwoColumnGrid
            headerChildren={
              <>
                {isConversationAssistantEnabled &&
                  renderMultiAnswerCard('wide')}
                <SearchPageHeader
                  isDesktop={isDesktop}
                  connectors={connectors}
                  browserHistoryConnectors={browserHistoryConnectors}
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                  typeaheadQuery={query}
                  setTypeaheadQuery={setTypeaheadQuery}
                  handleSearch={handleSearch}
                  annotations={annotations}
                />
              </>
            }
            mainChildren={
              showMultimediaView !== MultimediaView.OFF ? (
                <MultimediaSearchResults
                  query={query}
                  filters={filters}
                  results={
                    showMultimediaView !== MultimediaView.ON
                      ? results
                      : undefined
                  }
                  loading={loading}
                  resetAllFilters={resetAllFilters}
                />
              ) : (
                renderSearchResults()
              )
            }
            sidebarChildren={
              showMultimediaView === MultimediaView.ON ||
              isConversationAssistantEnabled
                ? undefined
                : renderMultiAnswerCard('narrow')
            }
          />
        </div>
        {annotationMode && (
          <AnnotationsConfirmation searchRequestId={requestId} query={query} />
        )}
        <DebugCard searchResults={results} />
      </div>
      {verifyingResult && (
        <VerifyContentModal
          open={!!verifyingResult}
          result={verifyingResult}
          onClose={() => setVerifyingResult(null)}
          onSave={(curation: dash.Curation | null) => {
            setCachedVerifications((prev) => ({
              ...prev,
              [verifyingResult.uuid]: curation,
            }));
            setVerifyingResult(null);
          }}
          dashActionSurface={dashActionSurface}
        />
      )}
      {Boolean(isDropboxer) && (
        <AnswersAnnotationModal
          isOpen={isAnswersAnnotationOpen}
          onClose={() => {
            setIsAnswersAnnotationOpen(false);
          }}
          reportPAPEvent={reportPapEvent}
          query={query}
          answer={annotationAnswer}
          dashAnswerFeedback={feedback}
          searchAttemptId={searchAttemptSessionManager.getSessionIdOrUndefined()}
          searchSessionId={searchSessionManager.getSessionIdOrUndefined()}
          onSourceClick={openURL}
        />
      )}
    </>
  );
}
