import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { APIv2AuthType, Service } from '@mirage/service-dbx-api/service';
import type { SearchResult } from '@mirage/service-dbx-api/service/search';
export type { SearchResult };
import type { ConversationsChatMessage } from './service/grpc/context_engine_apiv2/gen/conversations_pb';
import type { DocumentId } from './service/grpc/context_engine_apiv2/gen/doc_summarization_pb';
import type {
  AnswersForQueryResult,
  ConversationsResult,
  DocSummaryQnaResult,
  DocSummaryQnaSuccess,
} from './service/grpc/context_engine_apiv2/types';
import type { PeopleRecommendationsRequestOptions } from './service/people';
import type { APIResponse, APIRoute } from './service/types';
import type { Dropbox } from '@dropbox/api-v2-client';
import type {
  Connector,
  ConnectorConnection,
} from '@mirage/service-connectors/service';
import type { SearchFilter } from '@mirage/shared/search/search-filters';
import type {
  LinkSummarySuccess,
  Question,
  QuestionAnswer,
} from '@mirage/shared/stack-item-summary/types';

export type { Service };
export { ConnectorConnection, Connector };

const service = services.get<Service>(ServiceId.DBX_API);

export function callApiV2<Route extends APIRoute>(
  apiName: Route,
  args: Parameters<Dropbox[Route]>[0],
  // Important: default value must be specified here.
  authType: APIv2AuthType = 'user',
): Promise<APIResponse<Route>> {
  return rx.firstValueFrom(
    service.callApiV2(apiName, args, authType),
  ) as Promise<APIResponse<Route>>;
}

export function fetchTypeaheadRecommendations() {
  return rx.firstValueFrom(service.fetchTypeaheadRecommendations());
}

export function fetchPeopleRecommendations(
  options: PeopleRecommendationsRequestOptions,
) {
  return rx.firstValueFrom(service.fetchPeopleRecommendations(options));
}

export function fetchChannelRecommendations() {
  return rx.firstValueFrom(service.fetchChannelRecommendations());
}

export function fetchSearchResults(
  searchQuery: string,
  filters: SearchFilter[] = [],
  disableSpellCorrection = false,
) {
  return rx.firstValueFrom(
    service.fetchSearchResults(searchQuery, filters, disableSpellCorrection),
  );
}

export function fetchMultimediaSearchResults(
  searchQuery: string | undefined,
  cursor: string | undefined,
) {
  return rx.firstValueFrom(
    service.fetchMultimediaSearchResults(searchQuery, cursor),
  );
}

export function fetchMultimediaPreview(
  url: string,
  isDesktop: boolean = false,
  size?: number,
) {
  return rx.firstValueFrom(
    service.fetchMultimediaPreview(url, isDesktop, size),
  );
}

export function fetchUpstreamForConnection(
  searchQuery: string,
  connection: ConnectorConnection,
  filters: SearchFilter[] = [],
) {
  return rx.firstValueFrom(
    service.fetchUpstreamForConnection(searchQuery, connection, filters),
  );
}

export function fetchAnswersForQuery(searchQuery: string, source?: string) {
  return rx.firstValueFrom(service.fetchAnswersForQuery(searchQuery, source));
}

function getLinkSummary(
  url: string,
  regenerate: boolean,
): rx.Observable<LinkSummarySuccess> {
  return service.grpc.getLinkSummary(url, regenerate);
}

function getAnswerForQuestion(
  summary: LinkSummarySuccess,
  question: Question,
  id: string,
): rx.Observable<QuestionAnswer> {
  return service.grpc.getAnswerForQuestion(summary, question, id);
}

function getDocSummary(
  id: DocumentId,
  answerId: string,
  initialQuery: string,
  connectorName: string,
  recordType: string,
  docSummaryQnASetting: string,
  fileType: string = '',
): rx.Observable<DocSummaryQnaResult> {
  return service.grpc.getDocSummary(
    id,
    answerId,
    initialQuery,
    connectorName,
    recordType,
    docSummaryQnASetting,
    fileType,
  );
}

function getDocAnswer(
  id: DocumentId,
  answerId: string,
  question: string,
  docSummaryQnASetting: string,
  previousSummary?: DocSummaryQnaSuccess,
  fileType: string = '',
  connectorName: string = '',
): rx.Observable<DocSummaryQnaResult> {
  return service.grpc.getDocAnswer(
    id,
    answerId,
    question,
    docSummaryQnASetting,
    previousSummary,
    fileType,
    connectorName,
  );
}

function genAnswersForQuery(
  searchQuery: string,
  source?: string,
  experimentSetting?: string,
): rx.Observable<AnswersForQueryResult> {
  return service.grpc.genAnswersForQuery(
    searchQuery,
    source,
    experimentSetting,
  );
}

function getConversationResponse(
  userMessage: string,
  conversationId: string,
  history?: ConversationsChatMessage[],
  documentIds?: string[],
  experimentSetting?: string,
  source?: string,
): rx.Observable<ConversationsResult> {
  return service.grpc.getConversationResponse(
    userMessage,
    conversationId,
    history,
    documentIds,
    experimentSetting,
    source,
  );
}

export const grpc = {
  getLinkSummary,
  getAnswerForQuestion,
  getDocSummary,
  getDocAnswer,
  genAnswersForQuery,
  getConversationResponse,
};
