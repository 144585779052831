import { doNotRedact } from "@alwaysmeticulous/redaction";
import type { dcs } from "@dropbox/api-v2-client";
import type { StoredCalendarEvents } from "@mirage/service-calendar-events/service";

import { createRedactedApiV2Endpoint } from "../utils";

export const dcsGetCalendarEventsRedacted = createRedactedApiV2Endpoint<
  StoredCalendarEvents,
  dcs.GetCalendarEventsResponse
>({
  name: "dcsGetCalendarEvents",
  api: "dcs",
  endpoint: "get_calendar_events",
  indexedDbKey: "calendar-events",
  stringRedactors: {
    exact_queries: doNotRedact,
  },
});
