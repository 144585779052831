import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { ListItemSize } from '@mirage/link-list/types';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useMemo } from 'react';
import styles from './WorkingSetCard.module.css';
import WorkingSetCardWrapper from './WorkingSetCardWrapper';

import type { WorkingSetCardData } from '../types';

type WorkingSetCardProps = {
  workingSetCardData: WorkingSetCardData;
  numLinksToShow: number;
  onClick: () => void;
};

const MAX_VISIBLE_LINKS = 5;

export default function WorkingSetCard({
  workingSetCardData,
  numLinksToShow,
  onClick,
}: WorkingSetCardProps) {
  const linksData = useMemo(() => {
    return workingSetCardData.links
      .slice(0, MAX_VISIBLE_LINKS)
      .map(({ id, url, name }) => {
        return { id, url, title: name };
      });
  }, [workingSetCardData.links]);

  const stacksLinks = (
    <LinkList
      numLinksToShow={numLinksToShow}
      className={styles.stackLinks}
      listItemSize={ListItemSize.Small}
    >
      {linksData.map((link, index) => (
        <LinkComponent
          listItemSize={ListItemSize.Small}
          key={index}
          link={link}
          openPapEvents={[
            PAP_Open_DashLink({
              ...workingSetCardData.stackDerivedPAPProps,
              actionSurfaceComponent: 'stacks',
              featureLine: 'stacks',
            }),
          ]}
        />
      ))}
    </LinkList>
  );

  return (
    <WorkingSetCardWrapper
      onClick={onClick}
      menuIsOpen={workingSetCardData.menuIsOpen}
      accessoryButton={workingSetCardData.actionMenuComponent}
    >
      <div
        data-testid="Stacks-StackCard"
        className={classnames(styles.cardContainer, styles.cardContainerMarch)}
      >
        <div className={styles.titleContainer}>
          <DashStackIcon
            size="medium"
            colorIndex={workingSetCardData.colorIndex}
            emoji={workingSetCardData.emoji}
            customColor={workingSetCardData.customColor}
          />
          <Title
            size="medium"
            weightVariant="emphasized"
            className={styles.title}
          >
            <DashTruncateWithTooltip
              lines={3}
              tooltipProps={{
                title: workingSetCardData.title,
              }}
            >
              {workingSetCardData.title}
            </DashTruncateWithTooltip>
          </Title>
          <div className={styles.infoRow}>
            <Text size="medium" color="faint">
              <UIIcon
                className={styles.accessIcon}
                src={workingSetCardData.accessLevelIcon}
                size="small"
              />
              {workingSetCardData.accessLevel}
              {' · '}
              {i18n.t('num_items', {
                count: workingSetCardData.links.length,
              })}
            </Text>
            <div className={styles.facepile}>
              {workingSetCardData.getMembersComponent('small')}
            </div>
          </div>
        </div>
        {stacksLinks}
        {
          /**
           * Optional debug data can be passed through for development purposes.
           * Uncomment to show data in card.
           */
          // Object.entries(workingSetCardData.debugData || {}).map(
          //   ([key, value]) => (
          //     <Text key={key} size="xsmall" color="faint">
          //       {key}: {value}
          //     </Text>
          //   ),
          // )
        }
      </div>
    </WorkingSetCardWrapper>
  );
}
