import { convertDocumentInfoToChatSource } from '@mirage/service-conversation/utils/convertDocumentInfoToChatSource';

import type { ChatConversationMessage } from '@mirage/mosaics/Chat/types';
import type { ConversationMessage } from '@mirage/service-conversation/types';

export const convertToChatMessage = (
  message: ConversationMessage,
): ChatConversationMessage => {
  switch (message.messageType.case) {
    case 'userMessage':
      return {
        type: 'message',
        text: message.messageType.value.text ?? '',
        role: 'user',
      };
    case 'assistantMessage': {
      const result: ChatConversationMessage = {
        type: 'message',
        text: message.messageType.value.text ?? '',
        role: 'assistant',
      };

      // Add referencingSources if there are cited documents
      if (
        message.messageType.value.cited &&
        message.messageType.value.cited.length > 0
      ) {
        result.referencingSources = convertDocumentInfoToChatSource(
          message.messageType.value.cited,
        );
      }

      return result;
    }
    case 'systemMessage':
      return {
        type: 'message',
        text: message.messageType.value.text ?? '',
        role: 'system',
      };
    default:
      throw new Error(`Unknown message type: ${message.messageType.case}`);
  }
};
