import { Button } from '@dropbox/dig-components/buttons';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { BowlEmptySpot } from '@dropbox/dig-illustrations';
import i18n from '@mirage/translations';
import styles from './SelfServeOnboardingErrorPage.module.css';

type Props = {
  onProvisionDash: () => Promise<boolean>;
};

export const SelfServeOnboardingErrorPage = ({ onProvisionDash }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
    >
      <Stack justify="center" align="center" display="flex" gap="72px">
        <Stack align="center" display="flex">
          <Title
            size="large"
            weightVariant="emphasized"
            className={styles.header}
          >
            {i18n.t('self_serve_error_page_title')}
          </Title>
          <Text
            variant="paragraph"
            size="large"
            color="subtle"
            className={styles.subHeader}
          >
            {i18n.t('self_serve_error_page_description')}
          </Text>
        </Stack>
        <Box className={styles.spotImage}>
          <BowlEmptySpot />
        </Box>
        <Button
          className={styles.actionButton}
          data-testid="tryAgainButton"
          size="xlarge"
          variant="outline"
          fullWidth
          onClick={onProvisionDash}
        >
          <span className={styles.actionButtonLabel}>
            {i18n.t('try_again')}
          </span>
        </Button>
      </Stack>
    </Box>
  );
};
