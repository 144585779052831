import type { RedactorsFor } from "@alwaysmeticulous/redaction";
import {
  doNotRedact,
  NestedFieldsRedactor,
  PatternBasedRedactorSet,
  redactKey,
  redactKeysEndingWith,
  redactString,
  transformJsonResponse,
} from "@alwaysmeticulous/redaction";
import type { RecorderMiddleware } from "@alwaysmeticulous/sdk-bundles-api";

import type { IDBEntry, RedactedEndpoint, Redactor } from "./types";

/**
 * Default redactors
 *
 * PLEASE NOTE: This list is intended for properties that are shared across
 * multiple APIs & endpoints. If you need to redact a specific prop for a specific
 * endpoint, please add it to the endpoint's redactor instead of here.
 *
 * See: src/meticulous/redactions/
 */
const DASH_DEFAULT_STRING_REDACTORS = PatternBasedRedactorSet.create<string>()
  .with(redactKeysEndingWith("email", redactString))
  .with(redactKey("body", redactString))
  .with(redactKeysEndingWith("label", redactString))
  .with(redactKeysEndingWith("title", redactString))
  .with(redactKeysEndingWith("emoji", doNotRedact))
  .with(redactKeysEndingWith("token", redactString))
  .with(redactKeysEndingWith("_img", redactString))
  .with(redactKeysEndingWith("_icon", doNotRedact))
  .with(redactKeysEndingWith("sort_key", doNotRedact))
  .with(redactKeysEndingWith("_index", doNotRedact))
  .with(redactKeysEndingWith("virtual_path", redactString))
  .with(redactKeysEndingWith("additional_links", redactString))
  .with(redactKeysEndingWith("displayName", redactString))
  .with(redactKey("initials", (initials: string) => initials[0] + "_"))
  .with(redactKey("cursor", doNotRedact))
  .with(redactKey("location", doNotRedact))
  .with(redactKey("id_3p", doNotRedact))
  .with(redactKey("preview", doNotRedact))
  .with(redactKey("normalized_status", doNotRedact))
  .with(redactKey("normalized_priority", doNotRedact))
  .with(redactKey("status", doNotRedact))
  .with(redactKey("priority", doNotRedact))
  .with(redactKey("category", doNotRedact))
  .with(redactKey("value", doNotRedact))
  .with(redactKey("alias_handle", redactString))
  .with(redactKey("raw_bytes", redactString))
  .with(redactKey("display_name", redactString))
  .with(redactKey("type_name", doNotRedact))
  .with(redactKey("connector_name", doNotRedact))
  .with(redactKey("team_name", redactString));

export const DASH_DEFAULT_REDACTOR_BUILDER =
  NestedFieldsRedactor.builderWithDefaults().withPatternBasedStringRedactors(
    DASH_DEFAULT_STRING_REDACTORS,
  );

export const DEFAULT_REDACTOR = DASH_DEFAULT_REDACTOR_BUILDER.createRedactor({
  strings: {},
}) as any;

type GetHandledStringKeyTypes<T> =
  T extends NestedFieldsRedactor<infer HANDLED_STRING_KEY_TYPES, any>
    ? HANDLED_STRING_KEY_TYPES
    : never;

export const createRedactedApiV2Endpoint = <
  INDEXED_DB_TYPE,
  RESPONSE_TYPE,
>(opts: {
  name: string;
  api: string;
  endpoint: string;
  indexedDbKey: string;
  stringRedactors: Omit<
    RedactorsFor<INDEXED_DB_TYPE | RESPONSE_TYPE>,
    GetHandledStringKeyTypes<typeof DASH_DEFAULT_REDACTOR_BUILDER>
  > &
    Partial<RedactorsFor<INDEXED_DB_TYPE | RESPONSE_TYPE>>;
}): RedactedEndpoint<INDEXED_DB_TYPE> => {
  const finalString = opts.api + "/" + opts.endpoint;
  const urlRegExp = new RegExp(finalString);
  return {
    name: opts.name,
    api: opts.api,
    endpoint: opts.endpoint,
    returnType: {} as INDEXED_DB_TYPE,
    indexedDbKey: opts.indexedDbKey,
    indexedDbRedactor:
      NestedFieldsRedactor.builderWithDefaults().createRedactor({
        strings: opts.stringRedactors as any,
      }) as any,
    createMiddleware: (): RecorderMiddleware => {
      return transformJsonResponse<RESPONSE_TYPE>({
        urlRegExp: urlRegExp,
        transform: DASH_DEFAULT_REDACTOR_BUILDER.createRedactor({
          strings: opts.stringRedactors,
        }) as any,
      });
    },
    urlRegExp,
  };
};

export const redactIfJson = <T>(
  json: string,
  transformation: (value: T) => T,
): string => {
  let parsed: T;
  try {
    parsed = JSON.parse(json ?? "");
  } catch {
    return json;
  }

  const transformed = transformation(parsed);
  return JSON.stringify(transformed);
};

export const redactIndexedDBEntry = <T>(
  entry: IDBEntry,
  targetKey: string,
  redactor: Redactor<T>,
): IDBEntry => {
  if (entry.key === targetKey) {
    if (typeof entry.value !== "string") {
      console.warn(`Unexpected non-string value for ${targetKey} in IndexedDB`);
      return entry;
    }

    return {
      ...entry,
      value: redactIfJson<T>(entry.value, redactor),
    };
  }
  return entry;
};

export const redactDefaultIndexedDbEntry = <T>(entry: IDBEntry): IDBEntry => {
  if (typeof entry.value !== "string") {
    console.warn(`Unexpected non-string value for ${entry.value} in IndexedDB`);
    return entry;
  }

  return {
    ...entry,
    value: redactIfJson<T>(entry.value, DEFAULT_REDACTOR),
  };
};
