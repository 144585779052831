import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronLeftLine } from '@dropbox/dig-icons/assets';
import { DashFacepile } from '@mirage/mosaics/DCLWrappers/DashFacepile';
import i18n from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useAtomValue } from 'jotai';
import { NavLink } from 'react-router-dom';
import { activeStackAtom, activeStackItemsAtom } from '../ActiveStack/atoms';
import { membersFromStack } from '../Helpers/Utils';
import { ManageStackMenu } from './ManageStackMenu';
import { PinStackButton } from './PinStackButton';
import { ShareStackButton } from './ShareStackButton';
import styles from './StackNavBar.module.css';

import type { SetMenuOpenForStackId } from '../ActionsMenu';
import type { users } from '@dropbox/api-v2-client/types';
import type { MenuPlacement } from '@dropbox/dig-components/menu';

export const StackNavBar = ({
  mutationRequestId,
  sessionId,
  currentAccount,
  iconColor,
  setMenuOpenForStackId,
  menuPlacement,
}: {
  mutationRequestId?: string;
  sessionId?: string;
  currentAccount: users.FullAccount;
  iconColor?: string;
  setMenuOpenForStackId?: SetMenuOpenForStackId;
  menuPlacement?: MenuPlacement;
}) => {
  const stack = useAtomValue(activeStackAtom);
  const items = useAtomValue(activeStackItemsAtom);
  const members = membersFromStack(stack ?? {});

  // Note that this will become false right as the stack is created,
  // so this is not a reliable way to determine if you're on the new stack creation page
  const isNewStack = !stack?.namespace_id || !items;

  return (
    <div className={styles.stackNavBar}>
      <div className={styles.stackNavBarLeft}>
        <NavLink to={RoutePath.STACKS}>
          <Button
            variant="borderless"
            withIconStart={<UIIcon src={ChevronLeftLine} />}
          >
            {i18n.t('all_stacks')}
          </Button>
        </NavLink>
      </div>
      <div className={styles.stackNavBarRight}>
        <PinStackButton
          stack={stack}
          mutationRequestId={mutationRequestId}
          sessionId={sessionId}
          currentAccount={currentAccount}
          iconColor={iconColor}
        />
        <ManageStackMenu
          stack={stack}
          iconType="gear"
          currentAccount={currentAccount}
          setMenuOpenForStackId={setMenuOpenForStackId}
          iconColor={iconColor}
          menuPlacement={menuPlacement}
        />
        <div style={{ paddingLeft: 4 }}>
          <DashFacepile members={members} size="small" />
        </div>
        {!isNewStack && (
          <ShareStackButton stack={stack} disabled={!currentAccount} />
        )}
      </div>
    </div>
  );
};
