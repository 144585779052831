import type { ConnectorName } from '.';

export const mostPopularApps: Array<ConnectorName> = [
  'dropbox',
  'gmail',
  'googledrive',
  'googleCalendar',
  'onedrive',
  'outlook',
  'notion',
  'asana',
  'hubspot',
  'salesforce',
  'jira',
  'confluence_cloud',
];
