import { Box } from '@dropbox/dig-foundations';
import { PictogramIcon } from '@dropbox/dig-icons';
import { WarningPictogram } from '@dropbox/dig-icons/dist/mjs/assets';
import { COMPOSE_TRANSFORMERS } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeMarkdownTransformers';
import { tagged } from '@mirage/service-logging';
import { LexicalContentEditable } from '@mirage/shared/compose/editor/LexicalContentEditable';
import { LexicalEditor } from '@mirage/shared/compose/editor/LexicalEditor';
import { memo } from 'react';
import styles from './SourcesFromSettingsTemplatePreview.module.css';

const logger = tagged('SourcesFromSettingsTemplatePreview');

interface SourcesFromSettingsTemplatePreviewProps {
  backgroundColor: string;
  templateContent?: string;
  onChangeTemplateContent: (content: string) => void;
  loading?: boolean;
}
export const SourcesFromSettingsTemplatePreview = memo(
  ({
    backgroundColor,
    templateContent,
    onChangeTemplateContent,
    loading,
  }: SourcesFromSettingsTemplatePreviewProps) => {
    return (
      <div className={styles.TemplatePreviewContainer}>
        <div
          className={styles.TemplatePreview}
          style={{
            background: backgroundColor,
          }}
        >
          {templateContent ? (
            <LexicalEditor
              namespace="TemplatePreview"
              editable={true}
              onError={logger.error}
              contentEditable={
                <div className={styles.TemplatePreviewContent}>
                  <LexicalContentEditable />
                </div>
              }
              placeholder={null}
              markdownContent={templateContent || ''}
              onChangeContent={onChangeTemplateContent}
              isClickableLinks={false}
              transformers={COMPOSE_TRANSFORMERS}
            />
          ) : loading ? null : (
            <Box
              display="flex"
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <PictogramIcon src={WarningPictogram} />
            </Box>
          )}
        </div>
      </div>
    );
  },
);
SourcesFromSettingsTemplatePreview.displayName =
  'SourcesFromSettingsTemplatePreview';
