import { useEffect, useState } from 'react';
import { useIsMobileSizeForSidebar } from './mobile';

const getIsTouchScreenWithNoMouse = () =>
  window.matchMedia('(pointer: coarse)').matches &&
  navigator.maxTouchPoints > 0 &&
  !window.matchMedia('(pointer: fine)').matches;

/** Check if we can use UI that appears on mouse hover or not. */
export function useIsHoverFriendly() {
  const [isTouchScreenWithNoMouse, setIsTouchScreenWithNoMouse] = useState(
    getIsTouchScreenWithNoMouse,
  );
  const isMobileSize = useIsMobileSizeForSidebar();

  useEffect(() => {
    const updatePointerType = () => {
      setIsTouchScreenWithNoMouse(getIsTouchScreenWithNoMouse());
    };

    window
      .matchMedia('(pointer: coarse)')
      .addEventListener('change', updatePointerType);
    window
      .matchMedia('(pointer: fine)')
      .addEventListener('change', updatePointerType);

    return () => {
      window
        .matchMedia('(pointer: coarse)')
        .removeEventListener('change', updatePointerType);
      window
        .matchMedia('(pointer: fine)')
        .removeEventListener('change', updatePointerType);
    };
  }, []);

  if (isTouchScreenWithNoMouse) {
    return false;
  }

  // If screen is small, assume that it is not hover-friendly in case the
  // above check is unsupported by the browser.
  return !isMobileSize;
}
