import { Button } from '@dropbox/dig-components/buttons';
import { LabelGroup } from '@dropbox/dig-components/combinations';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { Modal } from '@dropbox/dig-components/modal';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import i18n from '@mirage/translations';
import { useState } from 'react';
import styles from './ConnectPrompt.module.css';

import type { UIConnector } from '@mirage/shared/types';

type Props = Readonly<{
  connector: UIConnector | undefined;
  onSubmit: (record: Record<string, string>) => void;
  onCancel: () => void;
}>;

const getExtraFieldsForConnector = (connector: UIConnector): string[] => {
  const configFields =
    connector?.auth_details?.prompt_configuration?.ui_schema ?? undefined;

  if (!configFields) {
    return [];
  }
  const jsonFields = JSON.parse(configFields);
  // "{\"type\":\"object\",\"properties\":{\"subdomain\":{\"type\":\"string\"}}}"
  return Object.keys(jsonFields.properties);
};

const knownDomains: { [key: string]: string } = {
  zendesk: '.zendesk.com',
};

// This should be populated from the connector itself but this is being hardcoded
// for now until the connector configurations are updated to include this information.
function ConnectorSpecificInstructions({
  connector,
}: {
  connector: UIConnector;
}): JSX.Element | null {
  let instructionComponent: JSX.Element | null = null;

  switch (connector?.id_attrs?.type) {
    case 'zendesk':
      instructionComponent = <span>{i18n.t('zendesk_prompt')}</span>;
      break;
  }

  if (!instructionComponent) {
    return null;
  }

  return (
    <Text variant="label" size="medium">
      {instructionComponent}
    </Text>
  );
}

export function ConnectPrompt({ connector, onSubmit, onCancel }: Props) {
  const promptFields = getExtraFieldsForConnector(connector!) as (
    | 'subdomain'
    | 'api_key'
    | 'team_name'
    | 'site_url'
  )[];
  const [inputValues, setInputValues] = useState<Record<string, string>>(
    promptFields.reduce((values, field) => {
      return {
        ...values,
        [field]: '',
      };
    }, {}),
  );

  const isFormValid = (
    promptFields: string[],
    inputValues: Record<string, string>,
  ): boolean => {
    return promptFields.every((fieldKey) => inputValues[fieldKey].length > 0);
  };

  const handleInputChange = (fieldKey: string, value: string) => {
    setInputValues({
      ...inputValues,
      [fieldKey]: value,
    });
  };

  const handleConnect = () => {
    onSubmit(inputValues);
  };

  if (!connector) {
    return null;
  }

  return (
    <Modal
      open
      onRequestClose={onCancel}
      aria-labelledby="connector-prompt-modal-title"
      withCloseButton="Close"
    >
      <Modal.Content className={styles.modal}>
        <Modal.Header
          hasBottomSpacing="title-standard"
          className={styles.header}
        >
          <LabelGroup
            size={'large'}
            withLeftAccessory={
              <img
                key={`${connector?.branding?.display_name}-icon`}
                src={connector?.branding?.icon_src}
                width="40px"
                height="40px"
                alt={connector?.branding?.display_name}
                className={styles.iconExtraMargin}
              />
            }
            withText={i18n.t('connect_label', {
              displayName: connector?.branding?.display_name,
            })}
            withSubtext={i18n.t('connect_title_subheading', {
              briefDescription: connector?.branding?.brief_description,
            })}
          />
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginBottom: '20px' }}>
            <ConnectorSpecificInstructions connector={connector} />
          </div>
          {promptFields.map((fieldKey) => (
            <FormRow key={fieldKey}>
              <FormLabel>
                {fieldKey === 'subdomain'
                  ? i18n.t('subdomain_connect_label', {
                      displayName: connector?.branding?.display_name,
                    })
                  : ''}
                {fieldKey === 'site_url'
                  ? i18n.t('site_url_connect_label', {
                      displayName: connector?.branding?.display_name,
                    })
                  : ''}
                {fieldKey === 'api_key' && i18n.t('api_key_connect_label')}
                {fieldKey === 'team_name' && i18n.t('team_name_connect_label')}
              </FormLabel>
              <div className={styles.inputRow}>
                <TextInput
                  value={inputValues[fieldKey]}
                  onChange={(e) => handleInputChange(fieldKey, e.target.value)}
                  placeholder={i18n.t(`${fieldKey}_label`)}
                ></TextInput>
                {fieldKey === 'subdomain' &&
                connector?.id_attrs?.type &&
                knownDomains[connector?.id_attrs?.type] ? (
                  <Text>{knownDomains[connector?.id_attrs?.type]}</Text>
                ) : null}
              </div>
            </FormRow>
          ))}
        </Modal.Body>
        <Modal.Footer preferComposition>
          <Split gap="Micro Small">
            <Split.Item marginRight="auto">
              <Button variant="transparent" onClick={() => onCancel()}>
                {i18n.t('cancel_button_label')}
              </Button>
            </Split.Item>
            <Split.Item>
              <Button
                variant="primary"
                disabled={!isFormValid(promptFields, inputValues)}
                onClick={() => handleConnect()}
              >
                {i18n.t('connect_button_label')}
              </Button>
            </Split.Item>
          </Split>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
