import { tagged } from '@mirage/service-logging';
import { Number, Record, String } from 'runtypes';

import type { Static } from 'runtypes';

const logger = tagged('catapult');

/**
 * Fetch an auth code from the server to help a different app auto-login.
 */
export async function fetchCatapultAuthCode(
  accessToken: string,
  codeChallenge: string,
): Promise<string | undefined> {
  // We need to provide a redirect URL, but you don't actually need to redirect
  // anywhere. It's one of the security measures that is typically included in
  // the authorization code flow, so it's still needed here.  As long as it's a
  // valid one, it shouldn't be an issue.
  let response: Response | undefined;

  try {
    response = await fetch(
      'https://www.dropbox.com/catapult/get_cross_domain_redirect_url_token',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          code_challenge: codeChallenge,
          cont: 'https://www.dropbox.com/save_to_dropbox/auth_redirect',
        }),
      },
    );

    logger.info(
      `Successfully called get_cross_domain_redirect_url_token for catapult`,
    );
  } catch (e) {
    logger.error(`get_cross_domain_redirect_url_token failed: ${e}`);
    return undefined;
  }

  try {
    const json = await response.json();
    const authCode = json ? json['auth_code'] : undefined;
    if (!authCode) {
      logger.warn(
        `Failed to fetch auth code from server: ${JSON.stringify(json)}`,
      );
      return undefined;
    }

    logger.info(`Successfully fetched auth code for catapult`);
    return '' + authCode;
  } catch (e) {
    logger.error(`Failed to parse response json: ${e}`);
    return undefined;
  }
}

export const OauthTokenResponse = Record({
  access_token: String,
  token_type: String,
  expires_in: Number,
  refresh_token: String,
  scope: String,
  uid: String,
  account_id: String,
});

export type OauthTokenResponse = Static<typeof OauthTokenResponse>;

export async function obtainOauthToken({
  authCode,
  codeVerifier,
  apiv2ClientId,
  redirectUri,
}: {
  authCode: string;
  codeVerifier: string;
  apiv2ClientId: string;
  redirectUri: string;
}): Promise<OauthTokenResponse | undefined> {
  const response = await fetch('https://api.dropboxapi.com/oauth2/token', {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body:
      `grant_type=authorization_code` +
      `&code=${authCode}` +
      `&client_id=${apiv2ClientId}` +
      `&code_verifier=${codeVerifier}` +
      // The redirect_uri is hardcoded in the server and cannot be changed.
      // It shouldn't matter which url we use since we aren't actually doing
      // the redirect.  It's just there to keep the oauth logic happy.
      `&redirect_uri=${redirectUri}`,
  });

  const json = await response.json();

  const result = OauthTokenResponse.validate(json);
  if (result.success) {
    logger.info(`Successfully obtained oauth2 token!`);
    return result.value;
  }

  logger.error(
    `Failed to get oauth token: response=${JSON.stringify(response)}`,
  );
  return undefined;
}
