import { Metadata } from '@dropbox/dash-component-library';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine, CursorLine, SearchLine } from '@dropbox/dig-icons/assets';
import { PAP_Click_MessageAction } from '@mirage/analytics/events/types/click_message_action';
import { PAP_Click_RemoveSource } from '@mirage/analytics/events/types/click_remove_source';
import { PAP_Click_SourceFile } from '@mirage/analytics/events/types/click_source_file';
import { Link } from '@mirage/link/Link';
import { MarkdownResponse } from '@mirage/mosaics/Chat/components/chat/MarkdownResponse';
import { useAsyncAssistantFileViewer } from '@mirage/mosaics/FileViewer/AsyncFileViewer';
import { generateSearchURL } from '@mirage/search/hooks/useQueryParams';
import { tagged } from '@mirage/service-logging';
import { copyToClipboard, openURL } from '@mirage/service-platform-actions';
import {
  getSourceUrl,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { useClipboardCopyListenerOnRef } from '@mirage/shared/hooks/useClipboardCopyListenerOnRef';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { usePrevious } from '@mirage/shared/util/hooks';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n, { meticulousRedactedStringClass } from '@mirage/translations';
import classNames from 'classnames';
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  composeSourceToMirage,
  formatFileSize,
} from '../compose-sources/ComposeSourceRow';
import { ConversationMessageActions } from './ConversationMessageActions';
import { ConversationMessageLoadingText } from './ConversationMessageLoadingText';
import styles from './ConversationMessages.module.css';

import type {
  ChatConversationMessage,
  ChatConversationMessageInstruction,
  ChatConversationMessageMessage,
  ChatSource,
} from '../../types';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { ComposeArtifact } from '@mirage/shared/compose/compose-session';

const logger = tagged('ConversationMessages');

type LayoutVariant = 'default' | 'condensed';

interface ConversationMessagesProps {
  variant: LayoutVariant;
  noPadding?: boolean;
  startingMessageNode?: React.ReactNode;
  messages: ChatConversationMessage[];
  isWaitingForResponse: boolean;
  progressString: string | undefined;
  onRemoveSource: (source: ChatSource) => void;
  isLastMessageFollowUpSuggestions: boolean;
  artifacts: ComposeArtifact[];
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  onOpenArtifact: () => void;
  /**
   * Render a list of new sources that have been added since the last message.
   */
  renderNewSources?: () => React.ReactNode;
  renderMessageActions?: (message: ChatConversationMessage) => React.ReactNode;
  hideFirstUserMessage?: boolean;
  hideSources?: boolean; // hide sources in the message rows, default to false
  showStartingNodeAfterContextInputs?: boolean;
}
export const ConversationMessages = memo(
  ({
    variant,
    noPadding,
    startingMessageNode,
    messages,
    isWaitingForResponse,
    onRemoveSource,
    artifacts,
    logComposeEvent,
    onOpenArtifact,
    renderNewSources,
    renderMessageActions,
    hideSources = false,
    hideFirstUserMessage = false,
  }: ConversationMessagesProps) => {
    const messagesDivRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (messagesDivRef.current) {
        messagesDivRef.current.scrollTo(0, messagesDivRef.current.scrollHeight);
      }
    }, [messages, renderNewSources]);
    const handleCopyListener = useCallback(
      (selection: string) => {
        logComposeEvent(
          PAP_Click_MessageAction({
            actionType: 'copy',
            generatedQueryString: selection,
            queryLength: selection.length,
            entryPoint: 'keyboard',
          }),
        );
      },
      [logComposeEvent],
    );
    const attachCopyListener =
      useClipboardCopyListenerOnRef(handleCopyListener);
    useEffect(() => {
      if (messagesDivRef.current) {
        return attachCopyListener(messagesDivRef.current);
      }
    }, [attachCopyListener]);
    const hasDraftArtifact = artifacts.some(
      (artifact) => artifact.type === 'markdown_draft',
    );
    let displayMessages =
      hideFirstUserMessage && messages.length > 0
        ? messages.slice(messages[0].role === 'user' ? 1 : 0)
        : messages;

    const assistantMessages = displayMessages.filter(
      (m): m is ChatConversationMessageMessage =>
        m.role === 'assistant' && m.type === 'message',
    );

    const lastAssistantMsgIsAnalysis =
      assistantMessages.length > 0 &&
      assistantMessages[assistantMessages.length - 1].actionContext &&
      (assistantMessages[assistantMessages.length - 1].actionContext?.type ===
        'dash_search' ||
        assistantMessages[assistantMessages.length - 1].actionContext?.type ===
          'dash_search_analyze');

    // if lastAsisstantMsgIsAnalysis is true, filter out all messages that are not
    // dash_search or dash_search_analyze EXCEPT the last assistant message that is
    // dash_search and the last assistant message that is dash_search_analyze
    // else if lastAssistantMsgIsAnalysis is false, filter out all messages that are
    // dash_search or dash_search_analyze
    if (lastAssistantMsgIsAnalysis) {
      displayMessages = displayMessages.reduce<ChatConversationMessage[]>(
        (acc, msg, index) => {
          if (
            (msg as ChatConversationMessageMessage).actionContext?.type ===
              'dash_search' ||
            (msg as ChatConversationMessageMessage).actionContext?.type ===
              'dash_search_analyze'
          ) {
            if (
              index === displayMessages.length - 1 ||
              index === displayMessages.length - 2
            ) {
              acc.push(msg);
            }
          } else {
            acc.push(msg);
          }
          return acc;
        },
        [],
      );
    } else {
      displayMessages = displayMessages.filter(
        (msg): msg is ChatConversationMessageMessage =>
          (msg as ChatConversationMessageMessage).actionContext?.type !==
            'dash_search' &&
          (msg as ChatConversationMessageMessage).actionContext?.type !==
            'dash_search_analyze',
      );
    }

    return (
      <div
        className={classNames(styles.ConversationMessagesScroller, {
          [styles.ConversationMessagesScrollerCondensed]:
            variant === 'condensed',
          [styles.ConversationMessagesScrollerNoPadding]: noPadding,
        })}
        ref={messagesDivRef}
      >
        <div
          className={styles.ConversationMessages}
          role="log"
          aria-live="polite"
        >
          {startingMessageNode}
          {displayMessages.map((message, i) => (
            <Fragment key={i + message.role}>
              <ConversationMessageRow
                variant={variant}
                message={message}
                onRemoveSource={onRemoveSource}
                hideSources={hideSources}
                isLastMessage={
                  i === displayMessages.length - 1 && !isWaitingForResponse
                }
                progressStepState={getProgressStepState(
                  message,
                  displayMessages,
                  isWaitingForResponse,
                )}
                logComposeEvent={logComposeEvent}
                onOpenArtifact={onOpenArtifact}
                renderActions={renderMessageActions}
              />
            </Fragment>
          ))}
          {renderNewSources && renderNewSources()}
          {isWaitingForResponse && !hideFirstUserMessage && (
            <div
              className={classNames(
                styles.LoadingMessageRow,
                styles.ConversationMessageRowMessageAnimation,
              )}
            >
              <ConversationMessageLoadingText
                size={variant === 'condensed' ? 'medium' : 'large'}
                text={
                  hasDraftArtifact
                    ? i18n.t('compose_message_waiting_for_response_drafting')
                    : i18n.t('compose_message_waiting_for_response')
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);
ConversationMessages.displayName = 'ConversationMessages';

interface ConversationMessageRowGenericProps<MessageType> {
  variant: LayoutVariant;
  message: MessageType;
  onRemoveSource: (source: ChatSource) => void;
  isLastMessage: boolean;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  renderActions?: ConversationMessagesProps['renderMessageActions'];
  onOpenArtifact: () => void;
  showReactions?: boolean;
  hideSources?: boolean; // hide sources in the message row, default to false
  progressStepState?: ProgressStepDisplayState;
}

type ConversationMessageRowProps =
  ConversationMessageRowGenericProps<ChatConversationMessage>;
export const ConversationMessageRow = memo(
  ({ message, ...props }: ConversationMessageRowProps) => {
    switch (message.type) {
      case 'message':
        return <ConversationMessageRowMessage message={message} {...props} />;
      case 'instruction':
        return (
          <ConversationMessageRowInstruction message={message} {...props} />
        );
      default:
        message satisfies never;
        throw new Error(`Unknown message type: ${message}`);
    }
  },
);
ConversationMessageRow.displayName = 'ConversationMessageRow';

type ConversationMessageRowMessageProps =
  ConversationMessageRowGenericProps<ChatConversationMessageMessage>;
export const ConversationMessageRowMessage = memo(
  ({
    variant,
    message,
    isLastMessage,
    progressStepState,
    logComposeEvent,
    renderActions,
    onOpenArtifact,
    showReactions = true,
    hideSources = false,
  }: ConversationMessageRowMessageProps) => {
    const showMessageActionButtons =
      showReactions && message.actionContext?.type === 'done';
    const isWriteDraft = message.actionContext?.type === 'draft_generated';
    const stepMessageClassName = useStepMessageTransitionClass(
      message,
      progressStepState,
    );

    const referencedSourcesElement = !hideSources &&
      message.actionContext?.type !== 'dash_search_analyze' &&
      message.referencingSources &&
      message.referencingSources.length > 0 && (
        <div
          className={classNames(styles.ConversationMessageSources, {
            [styles.ConversationMessageSourcesUser]: message.role === 'user',
          })}
        >
          {message.referencingSources.map((source, i) => (
            <ConversationMessageRowMessageSourceRow
              variant={variant}
              key={getSourceUUID(source) || i}
              source={source}
              logComposeEvent={logComposeEvent}
              padBottom={i === (message?.referencingSources?.length || 0) - 1}
            />
          ))}
        </div>
      );
    const isEmptyUserMessage = message.role === 'user' && !message.text;
    return (
      <>
        {
          // for user, show newly added sources before the message
          message.role === 'user' && referencedSourcesElement
        }
        {/* hide empty user messages, but show action context for empty assistant responses */}
        {!isEmptyUserMessage && (
          <div
            className={classNames(
              styles.ConversationMessageRow,
              meticulousRedactedStringClass,
              {
                [styles.ConversationMessageRowUser]: message.role === 'user',
                [styles.ConversationMessageRowCondensed]:
                  variant === 'condensed',
              },
              stepMessageClassName,
            )}
          >
            <ConversationMessageRowActionAndText
              variant={variant}
              isLastMessage={isLastMessage}
              isInProgress={progressStepState === 'waiting'}
              message={message}
              renderActions={renderActions}
              onOpenArtifact={onOpenArtifact}
              logComposeEvent={logComposeEvent}
            />
          </div>
        )}
        {showMessageActionButtons && (
          <ConversationMessageActions
            variant={variant}
            onClickFeedback={(feedback) => {
              logComposeEvent(
                PAP_Click_MessageAction({
                  actionType: feedback,
                  generatedQueryString: message.text,
                  queryLength: message.text.length,
                }),
              );
            }}
            onCopyMessage={
              isWriteDraft
                ? undefined
                : () => {
                    copyToClipboard(message.text);
                    logComposeEvent(
                      PAP_Click_MessageAction({
                        actionType: 'copy',
                        generatedQueryString: message.text,
                        queryLength: message.text.length,
                        entryPoint: 'assistant_ui',
                      }),
                    );
                  }
            }
          />
        )}
        {
          // for assistant, show newly added sources after the message
          message.role !== 'user' && referencedSourcesElement
        }
      </>
    );
  },
);
ConversationMessageRowMessage.displayName = 'ConversationMessageRowMessage';

interface ConversationMessageRowInstructionProps {
  message: ChatConversationMessageInstruction;
  variant: LayoutVariant;
}
export const ConversationMessageRowInstruction = memo(
  ({ message, variant }: ConversationMessageRowInstructionProps) => {
    return (
      <div
        className={classNames(styles.ConversationMessageRow, {
          [styles.ConversationMessageRowCondensed]: variant === 'condensed',
        })}
      >
        <div className={styles.ConversationMessageRowMessageActionText}>
          <div>
            <Text
              tagName="div"
              size={variant === 'condensed' ? 'medium' : 'large'}
            >
              {message.title}
            </Text>
            <Text tagName="div" color="subtle">
              {message.subtitle}
            </Text>
          </div>
        </div>
      </div>
    );
  },
);
ConversationMessageRowInstruction.displayName =
  'ConversationMessageRowInstruction';

interface ConversationMessageRowMessageSourceRowProps {
  variant?: LayoutVariant;
  source: ChatSource;
  onRemoveSource?: (source: ChatSource) => void;
  padBottom?: boolean;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ConversationMessageRowMessageSourceRow = memo(
  ({
    variant,
    source,
    onRemoveSource,
    padBottom = false,
    logComposeEvent,
  }: ConversationMessageRowMessageSourceRowProps) => {
    const mirageResult = composeSourceToMirage(source);
    const url = getSourceUrl(source);

    const handleRemoveSource: React.MouseEventHandler = (e) => {
      e.stopPropagation();
      onRemoveSource?.(source);
      logComposeEvent(PAP_Click_RemoveSource());
    };

    const fileViewer = useAsyncAssistantFileViewer({
      url,
      fileName: mirageResult?.title,
      handleRemoveSource,
      logComposeEvent,
    });

    if (!mirageResult) {
      return null;
    }

    if (fileViewer) {
      return (
        <div className={classNames(styles.ConversationMessageSourceFileViewer)}>
          {fileViewer}
        </div>
      );
    }

    return (
      <div
        className={classNames(
          styles.ConversationMessageSourceRow,
          meticulousRedactedStringClass,
          {
            [styles.ConversationMessageSourcePadBottom]: padBottom,
          },
        )}
        role="button"
        tabIndex={0}
      >
        <FileContentIcon
          content={mirageResult!}
          size={variant === 'condensed' ? 'medium' : 'large'}
          hasBackground={false}
        />
        <div className={styles.ConversationMessageSourceInfo}>
          <Text size="small" className={styles.ConversationMessageSourceTitle}>
            {source.type === 'local_file_content' ? (
              <Truncate lines={2}>{mirageResult.title}</Truncate>
            ) : (
              <Link
                className={styles.ConversationMessageTitle}
                variant="monochromatic"
                href={mirageResult.url || ''}
                target="_blank"
                rel="noreferrer"
                hasNoUnderline
                onClick={() => {
                  logComposeEvent(
                    PAP_Click_SourceFile({
                      actionType: 'user_input',
                    }),
                  );
                }}
              >
                <Truncate lines={2}>{mirageResult.title}</Truncate>
              </Link>
            )}
          </Text>
          <Text
            size="small"
            className={styles.ConversationMessageSourceMetadata}
            color="subtle"
          >
            <Metadata.Item>
              <Metadata.Label>
                {source.type === 'local_file_content'
                  ? formatFileSize(source.fileSize || 0)
                  : mirageResult.providerUpdateAtMs &&
                    i18n.t('modified_ago', {
                      timeAgo: getTimeAgoString(
                        mirageResult.providerUpdateAtMs,
                      ),
                    })}
              </Metadata.Label>
            </Metadata.Item>
          </Text>
        </div>
        {onRemoveSource && (
          <DigTooltip
            title={i18n.t('compose_source_row_remove_button_label')}
            placement="top"
          >
            <IconButton
              variant="transparent"
              className={styles.ComposeSourceActionButton}
              size="small"
              shape="standard"
              onClick={handleRemoveSource}
            >
              <UIIcon src={CloseLine} />
            </IconButton>
          </DigTooltip>
        )}
      </div>
    );
  },
);
ConversationMessageRowMessageSourceRow.displayName =
  'ConversationMessageRowMessageSourceRow';

interface ConversationMessageRowActionAndTextProps {
  variant: LayoutVariant;
  message: ChatConversationMessageMessage;
  isLastMessage?: boolean;
  isInProgress: boolean;
  renderActions: ConversationMessagesProps['renderMessageActions'];
  onOpenArtifact: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ConversationMessageRowActionAndText = memo(
  ({
    variant,
    message,
    isInProgress,
    renderActions,
    onOpenArtifact,
    logComposeEvent,
  }: ConversationMessageRowActionAndTextProps) => {
    const handleMarkdownContainerClicks = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement;
        if (target.tagName === 'A') {
          e.preventDefault();
          openURL((target as HTMLAnchorElement).href);
        }
      },
      [],
    );

    const getActionContextText = () => {
      switch (message.actionContext?.type) {
        case 'draft_generated':
          return i18n.t('compose_assistant_draft_generated');
        case 'template_generated':
          return i18n.t('assist_template_created_message');
        case 'template_updated':
          return i18n.t('assist_template_updated_message');
        case 'tone_generated':
          return i18n.t('compose_tone_generated_message');
        case 'tone_updated':
          return i18n.t('compose_tone_updated_message');
        default:
          return null;
      }
    };

    const actionContextText = getActionContextText();
    // this is a special case for messages of type draft_generated that have an exact text match
    // of draft generated in which case we should just hide the message text — this should never happen
    // going forward but ensures back compatibility to remove duplicate messages in old sessions
    const isDraftGeneratedMsgAndContext =
      message.actionContext?.type === 'draft_generated' &&
      message.text === i18n.t('compose_assistant_draft_generated');
    const body =
      message.role === 'user' ? (
        <div className={styles.ConversationMessageRowPlainText}>
          {message.text}
        </div>
      ) : (
        // disabling eslint rule because this is not actually acting as a link
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={handleMarkdownContainerClicks}
          className={classNames(styles.ConversationMessageRowMarkdown, {
            [styles.ConversationMessageRowMarkdownCondensed]:
              variant === 'condensed',
          })}
        >
          {actionContextText && (
            <Text
              size={variant === 'condensed' ? 'medium' : 'large'}
              color="subtle"
              className={styles.ConversationMessageActionContextText}
            >
              {actionContextText}
            </Text>
          )}
          {!isDraftGeneratedMsgAndContext && (
            <MarkdownResponse markdownText={message.text} />
          )}
        </div>
      );
    const contextViewProps = {
      variant,
      message,
      onOpenArtifact,
      logComposeEvent,
    };
    const actionsElement = renderActions?.(message);

    // TODO:(rich) better general representation of sub-messages
    // certain action types have specific layouts, e.g. dash seach includes a starting message + submessage regarding the queries
    if (message.actionContext) {
      switch (message.actionContext?.type) {
        case 'dash_search':
          return (
            <>
              <div className={styles.ConversationMessageRowMessageActionText}>
                <Text
                  size={variant === 'condensed' ? 'medium' : 'large'}
                  color="subtle"
                >
                  {isInProgress
                    ? i18n.t('assistant_message_searching')
                    : i18n.t('assistant_message_searched')}
                  :
                </Text>
              </div>
              <div className={styles.ConversationMessageRowMessageActionText}>
                <div>
                  <ConversationMessageActionContextView {...contextViewProps} />
                  {actionsElement}
                </div>
              </div>
            </>
          );
        case 'dash_search_analyze':
          return (
            <div className={styles.ConversationMessageRowMessageActionText}>
              <div>
                <Text
                  size={variant === 'condensed' ? 'medium' : 'large'}
                  color="subtle"
                >
                  {isInProgress
                    ? i18n.t('assistant_message_analyzing_content', {
                        totalCount: message.referencingSources?.length || 0,
                      })
                    : i18n.t('assistant_message_analyzed_content', {
                        totalCount: message.referencingSources?.length || 0,
                      })}
                  :
                </Text>
                <ConversationMessageActionContextView {...contextViewProps} />
                {actionsElement}
              </div>
            </div>
          );
        case 'read_current_browser_tab':
          return (
            <div className={styles.ConversationMessageRowMessageActionText}>
              <Text
                size={variant === 'condensed' ? 'medium' : 'large'}
                color="subtle"
              >
                <Truncate
                  lines={2}
                  tooltipControlProps={{ auto: true, placement: 'top' }}
                >
                  {i18n.t('compose_message_title_read_browser_tab', {
                    title: message.actionContext.sourceTitle,
                  })}
                </Truncate>
              </Text>
              <Text size={variant === 'condensed' ? 'medium' : 'large'}>
                {body}
              </Text>
              <ConversationMessageActionContextView
                variant={variant}
                message={message}
                onOpenArtifact={onOpenArtifact}
                logComposeEvent={logComposeEvent}
              />
              {actionsElement}
            </div>
          );
        case 'done':
        case 'error':
        case 'reading':
        case 'compose_selection_edit':
        case 'draft_generated':
        case 'template_generated':
        case 'template_updated':
        case 'template_selected':
        case 'tone_generated':
        case 'tone_updated':
          // these types don't have a specific layout, fall through to use normal message layout
          break;
        default:
          message.actionContext satisfies never;
          throw new Error(
            `Unknown action context type: ${message.actionContext}`,
          );
      }
    }

    return (
      <div
        className={classNames(styles.ConversationMessageRowMessageActionText, {
          [styles.ConversationMessageRowMessageAnimation]:
            message.role !== 'user',
        })}
      >
        <Text size={variant === 'condensed' ? 'medium' : 'large'}>{body}</Text>
        <ConversationMessageActionContextView {...contextViewProps} />
      </div>
    );
  },
);
ConversationMessageRowActionAndText.displayName =
  'ConversationMessageRowActionAndText';

function useStepMessageTransitionClass(
  message: ChatConversationMessage,
  progressStepState: ProgressStepDisplayState | undefined,
) {
  const [currentState, setCurrentState] = useState<
    'hidden' | 'hiding' | 'shown'
  >(progressStepState === 'hide' ? 'hidden' : 'shown');
  const previousProgressStepState = usePrevious(progressStepState);
  const previousMessage = usePrevious(message);

  useEffect(() => {
    if (message !== previousMessage) {
      // reset state if the message changes
      setCurrentState(progressStepState === 'hide' ? 'hidden' : 'shown');
      return;
    }

    if (previousProgressStepState === progressStepState) {
      // no-op if progress-step state did not change
      return;
    }
    if (progressStepState === 'hide') {
      if (currentState === 'shown') {
        // fade out if we are currently shown
        setCurrentState('hiding');
        setTimeout(() => {
          setCurrentState('hidden');
        }, 1000);
      } else {
        // otherwise, immediately hide
        setCurrentState('hidden');
      }
    } else {
      setCurrentState('shown');
    }
  }, [
    currentState,
    message,
    previousMessage,
    previousProgressStepState,
    progressStepState,
  ]);

  switch (currentState) {
    case 'hidden':
      return styles.ProgressStepHidden;
    case 'hiding':
      return styles.ProgressStepHiding;
    case 'shown':
      return '';
    default:
      currentState satisfies never;
  }
}

interface ConversationMessageActionContextViewProps {
  variant: LayoutVariant;
  message: ChatConversationMessageMessage;
  onOpenArtifact: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ConversationMessageActionContextView = memo(
  ({
    variant,
    message,
    onOpenArtifact,
    logComposeEvent,
  }: ConversationMessageActionContextViewProps) => {
    if (!message.actionContext) {
      return null;
    }
    switch (message.actionContext?.type) {
      case 'dash_search': {
        return (
          <div>
            {message.actionContext.queries.map((query, i) => (
              <SearchQueryChip key={i} query={query} />
            ))}
          </div>
        );
      }
      case 'dash_search_analyze':
        return (
          <div>
            {message.referencingSources?.map((source, i) => (
              <ComposeMessageSourceRowChip
                key={i}
                source={source}
                logComposeEvent={logComposeEvent}
              />
            ))}
          </div>
        );
      case 'compose_selection_edit':
        return (
          <div className={styles.ComposeSelectionEditContext}>
            <UIIcon
              src={CursorLine}
              size="small"
              className={styles.ComposeSelectionEditContextIcon}
            />
            <Text size="small" color="subtle" variant="label">
              {`"${message.actionContext.selectedText}"`}
            </Text>
          </div>
        );
      case 'draft_generated':
        // only need to show this in Condensed view -- normal layout has the artifact pane on the side
        return variant === 'condensed' ? (
          <Button variant="opacity" size="small" onClick={onOpenArtifact}>
            {i18n.t('compose_open_draft')}
          </Button>
        ) : null;
      case 'done':
      case 'error':
      case 'reading':
      case 'read_current_browser_tab':
      case 'template_generated':
      case 'template_updated':
      case 'template_selected':
      case 'tone_generated':
      case 'tone_updated':
        return null;
      default:
        message.actionContext satisfies never;
        throw new Error(
          `Unknown action context type: ${message.actionContext}`,
        );
    }
  },
);
ConversationMessageActionContextView.displayName =
  'ConversationMessageActionContextView';

interface ComposeMessageSourceRowChipProps {
  source: ChatSource;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}
export const ComposeMessageSourceRowChip = memo(
  ({ source, logComposeEvent }: ComposeMessageSourceRowChipProps) => {
    const mirageResult = composeSourceToMirage(source);
    if (!mirageResult) {
      return null;
    }
    const handleClick = () => {
      const url = mirageResult.url;
      if (url) {
        openURL(url);
        logComposeEvent(
          PAP_Click_SourceFile({
            actionType: 'search_result',
          }),
        );
      } else {
        logger.error('No URL found for source', source);
      }
    };

    return (
      <Chip
        variant="standard"
        type="button"
        size="medium"
        className={styles.SearchResultChip}
        onClick={handleClick}
      >
        <Chip.IconAccessory>
          <div className={styles.SearchResultChipIcon}>
            <FileContentIcon
              content={mirageResult!}
              size="small"
              hasBackground={false}
            />
          </div>
        </Chip.IconAccessory>
        <Chip.Content className={styles.ChipContent}>
          <Truncate lines={1}>{mirageResult.title}</Truncate>
        </Chip.Content>
      </Chip>
    );
  },
);
ComposeMessageSourceRowChip.displayName = 'ComposeMessageSourceRowChip';

interface SearchQueryChipProps {
  query: string;
}
export const SearchQueryChip = memo(({ query }: SearchQueryChipProps) => {
  const handleClick = () => {
    const urlString = generateSearchURL(query);
    const url = new URL(urlString, document.baseURI);
    openURL(url.toString());
  };
  return (
    <Chip
      variant="standard"
      type="button"
      size="medium"
      className={styles.SearchQueryChip}
      onClick={handleClick}
    >
      <Chip.IconAccessory>
        <UIIcon size="small" src={SearchLine} />
      </Chip.IconAccessory>
      <Chip.Content className={styles.ChipContent}>{query}</Chip.Content>
    </Chip>
  );
});
SearchQueryChip.displayName = 'SearchQueryChip';

/**
 * @returns whether the message marks a "completed" response, e.g. an answer message; a generated draft.
 */
export function isCompletedResponseMessage(
  message: ChatConversationMessageMessage,
) {
  return (
    message.actionContext?.type === 'done' ||
    message.actionContext?.type === 'draft_generated'
  );
}

type ProgressStepDisplayState = 'done' | 'waiting' | 'hide';

/**
 * @returns config about how this progress-step message should be displayed.
 * or undefined if this message is not a progress-step message.
 */
export function getProgressStepState(
  message: ChatConversationMessage,
  allMessages: ChatConversationMessage[],
  isWaitingForResponse: boolean,
): undefined | ProgressStepDisplayState {
  if (!isProgressStepMessage(message)) {
    return undefined;
  }

  // not waiting for a response. hide all progress-step messages.
  if (!isWaitingForResponse) {
    return 'hide';
  }

  // this is the last message we are waiting reponse on
  if (allMessages[allMessages.length - 1] === message) {
    return 'waiting';
  }

  // check if all messages after message are progress-step messages
  const indexOfMessage = allMessages.indexOf(message);
  if (allMessages.slice(indexOfMessage + 1).every(isProgressStepMessage)) {
    return 'done';
  }

  // this is a progress-step message from the past, so we don't need to display it
  return 'hide';
}

/**
 * @returns true if the message represents an update about a multi-step process.
 */
function isProgressStepMessage(message: ChatConversationMessage) {
  return (
    message.type === 'message' &&
    (message.actionContext?.type === 'dash_search' ||
      message.actionContext?.type === 'dash_search_analyze')
  );
}
