import type { dash_feed } from "@dropbox/api-v2-client";

import { createRedactedApiV2Endpoint } from "../utils";

export const dashFeedListActivityFeedRedacted = createRedactedApiV2Endpoint<
  null,
  dash_feed.ListActivityFeedResponse
>({
  name: "dashFeedListActivityFeed",
  api: "dash_feed",
  endpoint: "list_activity_feed",
  indexedDbKey: "", // dashFeedListActivityItemsRedacted handles the IndexedDB redaction
  stringRedactors: {},
});
