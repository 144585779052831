import {
  isAdminUser,
  useAccountIsDropboxer,
} from '@mirage/service-auth/useDropboxAccount';
import { ONBOARDING_VERSION } from '../flows/constants';
import { useSelfServeOnboardingSteps } from '../hooks/useSelfServeOnboardingSteps';
import { SelfServeOnboarding } from './SelfServeOnboarding';

type Props = {
  routeParams?: Record<string, string | undefined>;
};

export const TeamMemberSelfServeOnboarding = ({ routeParams }: Props) => {
  const { onboardingSteps, showPrivacyModal, setShowPrivacyModal } =
    useSelfServeOnboardingSteps(false);
  const { account } = useAccountIsDropboxer();
  const isAdmin = isAdminUser(account);

  return (
    <SelfServeOnboarding
      isAdmin={isAdmin}
      steps={onboardingSteps}
      routeParams={routeParams}
      onboardingVariant={ONBOARDING_VERSION.SELF_SERVE_V1}
      showPrivacyModal={showPrivacyModal}
      setShowPrivacyModal={setShowPrivacyModal}
    />
  );
};
