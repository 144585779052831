import { tagged } from '@mirage/service-logging';
import { v4 as uuidv4 } from 'uuid';

import type { SessionStartReason } from '../events/enums/session_start_reason';

const logger = tagged('session-manager');

export type TimeoutOptions = {
  timeout: number; // ms
  timeoutEndReason: string;
};

export type SessionOptions =
  | TimeoutOptions
  | { timeout?: never; timeoutEndReason?: never };

export type Session = {
  type: string;
  id: string;
  startTime: number;
  endTime: number | null;
  endReason?: string;
  startReason?: SessionStartReason;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: { [key: string]: any };
  timeoutId?: NodeJS.Timeout;
};

type CreateSessionCallback = (session: Session) => void;
type ExtendOrCreateSessionCallback = (session: Session) => void;
type EndSessionCallback = (session: Session) => void;

export type SessionManager = {
  // Accessors
  getSession: () => Session | null;
  getSessionIdOrUndefined: () => string | undefined;
  // Session Lifecycle
  createSession: (
    startReason?: SessionStartReason,
    startTime?: number,
  ) => Session;
  extendSession: () => void;
  extendOrCreateSession: (
    startReason?: SessionStartReason,
    startTime?: number,
  ) => Session;
  endSession: (reason: string, endTime?: number) => void;
  // Properties
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateProperties: (properties: { [key: string]: any }) => void;
  // Callbacks
  setCreateSessionCallback: (callback: CreateSessionCallback) => void;
  setExtendOrCreateSessionCallback: (
    callback: ExtendOrCreateSessionCallback,
  ) => void;
  setEndSessionCallback: (callback: EndSessionCallback) => void;
};

export function createSessionManager(
  type: string,
  options?: SessionOptions,
): SessionManager {
  if (options?.timeout && !options?.timeoutEndReason) {
    throw new Error(
      `When timeout is provided, timeoutEndReason must be provided`,
    );
  }
  if (options?.timeoutEndReason && !options?.timeout) {
    throw new Error(
      `When timeoutEndReason is provided, timeout must be provided`,
    );
  }

  let session: Session | null = null;
  let createSessionCallback: CreateSessionCallback | null = null;
  let extendOrCreateSessionCallback: ExtendOrCreateSessionCallback | null =
    null;
  let endSessionCallback: EndSessionCallback | null = null;

  /**
   * Accessors
   */

  function getSession(): Session | null {
    return session;
  }

  function getSessionIdOrUndefined(): string | undefined {
    return session ? session.id : undefined;
  }

  /**
   * Session Lifecycle
   */

  function createSession(
    startReason?: SessionStartReason,
    startTime?: number,
  ): Session {
    const newSession: Session = {
      type: type,
      id: uuidv4(),
      startTime: startTime !== undefined ? startTime : Date.now(),
      endTime: null,
      timeoutId: undefined,
      endReason: undefined,
      startReason,
      properties: {},
    };

    logger.log(
      `Creating session '${type}' with start reason '${startReason}'. ID: ${newSession.id}`,
    );

    session = newSession;

    if (options?.timeout && options?.timeoutEndReason) {
      session.timeoutId = setTimeout(() => {
        endSession(options.timeoutEndReason);
      }, options.timeout);
    }

    if (createSessionCallback) {
      createSessionCallback(newSession);
    }
    return newSession;
  }

  function extendSession(): void {
    if (
      session &&
      session.endTime === null &&
      options?.timeout &&
      options?.timeoutEndReason
    ) {
      clearTimeout(session.timeoutId);
      session.timeoutId = setTimeout(() => {
        endSession(options.timeoutEndReason);
      }, options.timeout);
    }
  }

  function extendOrCreateSession(
    startReason?: SessionStartReason,
    startTime?: number,
  ): Session {
    if (session && session.endTime === null) {
      extendSession();
      session.endReason = undefined;
    } else {
      createSession(startReason, startTime);
    }

    if (extendOrCreateSessionCallback && session) {
      extendOrCreateSessionCallback(session);
    }

    return session!;
  }

  function endSession(reason: string, endTime?: number) {
    logger.log(
      `Ending session '${type}' with reason '${reason}'. ID: ${session?.id}`,
    );

    if (!session || session.endTime !== null) {
      return; // Session does not exist or already ended
    }

    session.endTime = endTime !== undefined ? endTime : Date.now();
    session.endReason = reason;

    clearTimeout(session.timeoutId);
    session.timeoutId = undefined;

    if (endSessionCallback) {
      endSessionCallback(session);
    }

    // Clear the session
    logger.log('Nulling out session', session);
    session = null;
  }

  /**
   * Properties
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateProperties(properties: { [key: string]: any }): void {
    if (session) {
      Object.assign(session.properties, properties);
    }
  }

  /**
   * Callbacks
   */

  function setCreateSessionCallback(callback: CreateSessionCallback) {
    createSessionCallback = callback;
  }

  function setExtendOrCreateSessionCallback(
    callback: ExtendOrCreateSessionCallback,
  ) {
    extendOrCreateSessionCallback = callback;
  }

  function setEndSessionCallback(callback: EndSessionCallback) {
    endSessionCallback = callback;
  }

  return {
    // Accessors
    getSession,
    getSessionIdOrUndefined,
    // Session Lifecycle
    createSession,
    extendSession,
    extendOrCreateSession,
    endSession,
    // Properties
    updateProperties,
    // Callbacks
    setCreateSessionCallback,
    setExtendOrCreateSessionCallback,
    setEndSessionCallback,
  };
}
