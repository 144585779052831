import i18n from '@mirage/translations';
import { getTimeAgoString } from './time';
import { urlToDomainName, urlToService } from './urlRulesUtil';

import type { stacks } from '@dropbox/api-v2-client';

type GetStackItemMetaDataConfig = {
  showUrl: boolean;
  showUpdatedTime: boolean;
  showCreatorName: boolean;
};

export const getStackItemMetadata = (
  item: {
    url?: string;
    last_modified_time_utc_sec?: number;
    creator?: stacks.StackItemUser;
  },
  config: GetStackItemMetaDataConfig = {
    showUrl: true,
    showCreatorName: false,
    showUpdatedTime: true,
  },
): string[] | undefined => {
  const metadata = [];

  // Service/Domain name is being replaced by creator name behind a flag
  if (config.showUrl && item.url) {
    metadata.push(urlToService(item.url) || urlToDomainName(item.url));
  }

  if (config.showUpdatedTime && item.last_modified_time_utc_sec) {
    metadata.push(
      i18n.t('stack_item_updated_ago', {
        timeAgo: getTimeAgoString(item.last_modified_time_utc_sec * 1000, {
          capitalize: false,
          abbreviateTime: true,
          abbreviateDate: true,
        }),
      }),
    );
  }

  if (config.showCreatorName && item.creator) {
    metadata.push(
      i18n.t('stack_item_added_by', {
        name: item.creator.display_name,
      }),
    );
  }

  return metadata ?? undefined;
};
