import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Close_DashMiltimediaDrawer } from '@mirage/analytics/events/types/close_dash_miltimedia_drawer';
import { atom, useAtom } from 'jotai';

import type { SearchResult } from '@mirage/shared/search/search-result';

const selectedMultimediaSearchResult = atom<SearchResult | null>(null);
export const useSelectedMultimediaSearchResult = () => {
  const { reportPapEvent, searchSessionManager } = useMirageAnalyticsContext();
  const [result, setSelectedResult] = useAtom(selectedMultimediaSearchResult);

  const closeMultimediaSearchResult = () => {
    setSelectedResult(null);
    reportPapEvent(
      PAP_Close_DashMiltimediaDrawer({
        resultUuid: result?.uuid,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  };
  const openMultimediaSearchResult = (result: SearchResult) => {
    setSelectedResult(result);
    reportPapEvent(
      PAP_Close_DashMiltimediaDrawer({
        resultUuid: result?.uuid,
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  };
  return { result, openMultimediaSearchResult, closeMultimediaSearchResult };
};
