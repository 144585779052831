import { Button } from '@dropbox/dig-components/buttons';
import { List } from '@dropbox/dig-components/list';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  CheckmarkLine,
  EditLine,
  SearchLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Click_CreateTemplateButton } from '@mirage/analytics/events/types/click_create_template_button';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { AccessType } from '@mirage/shared/compose/assist-api';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import styles from './TemplatesList.module.css';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type {
  AssistCustomTemplate,
  AssistTemplate,
} from '@mirage/shared/compose/assist-template';

interface TemplatesListProps {
  defaultTemplates: AssistTemplate[];
  templates: AssistTemplate[] | undefined;
  selectedTemplateId: string | undefined;
  onSelectTemplate: (template: AssistTemplate) => void;
  onOpenTemplateModal: (isOpen: boolean, templateID?: string) => void;
  currentTemplateID?: string | null;
  resetTemplateState?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const TemplatesList = memo(
  ({
    defaultTemplates,
    templates,
    selectedTemplateId,
    onSelectTemplate,
    onOpenTemplateModal,
    currentTemplateID,
    resetTemplateState,
    logComposeEvent,
  }: TemplatesListProps) => {
    const [searchQuery, setSearchQuery] = useState('');
    const inputRef = useRef(null);
    const handleChangeQuery = useCallback((e) => {
      setSearchQuery(e.target.value);
    }, []);
    const handleCreateTemplate = useCallback(() => {
      onOpenTemplateModal(true);
      logComposeEvent(PAP_Click_CreateTemplateButton());
      resetTemplateState?.();
    }, [onOpenTemplateModal, resetTemplateState, logComposeEvent]);

    const handleEditTemplate = useCallback(
      (templateId: string) => {
        onOpenTemplateModal(true, templateId);
      },
      [onOpenTemplateModal],
    );

    const teamTemplates = useMemo(
      () =>
        (templates || []).filter(
          (template): template is AssistCustomTemplate =>
            'accessType' in template && template.accessType === AccessType.TEAM,
        ),
      [templates],
    );

    const individualTemplates = useMemo(
      () =>
        (templates || []).filter(
          (template): template is AssistCustomTemplate =>
            'accessType' in template &&
            template.accessType === AccessType.INDIVIDUAL,
        ),
      [templates],
    );

    const allTemplates = useMemo(
      () => [...(templates || []), ...defaultTemplates],
      [templates, defaultTemplates],
    );

    const customTemplatesEnabled =
      useFeatureFlagValue('dash_2025_02_25_assist_custom_templates') === 'ON' ||
      true;

    const filteredTemplates = useMemo(() => {
      const query = searchQuery.toLowerCase();
      return allTemplates.filter((template) =>
        template.name.toLowerCase().includes(query),
      );
    }, [allTemplates, searchQuery]);

    return (
      <div className={styles.TemplatesListContent}>
        <div className={styles.TemplatesRows}>
          <TextInput.Container className={styles.TemplatesInputContainer}>
            <UIIcon
              src={SearchLine}
              className={styles.TemplatesSearchInputIcon}
              aria-label={i18n.t('icon_for_search')}
            />
            <TextInput.Input
              placeholder={i18n.t('assist_template_search')}
              size={12}
              onChange={handleChangeQuery}
              ref={inputRef}
            />
          </TextInput.Container>
          {customTemplatesEnabled && (
            <div className={styles.TemplatesRowsHeaderButtons}>
              <Button
                size="medium"
                variant="filled"
                withIconStart={<UIIcon src={AddLine} />}
                onClick={handleCreateTemplate}
              >
                {i18n.t('assist_template_create')}
              </Button>
            </div>
          )}
          {filteredTemplates.length > 0 ? (
            <>
              {/* Individual Templates Section */}
              {individualTemplates.filter((template) =>
                template.name.toLowerCase().includes(searchQuery.toLowerCase()),
              ).length > 0 && (
                <Box marginBottom="Micro Large">
                  <Text
                    size="small"
                    color="subtle"
                    tagName="div"
                    className={styles.TemplatesRowsHeader}
                  >
                    {i18n.t('assist_templates_home_subtitle_your_templates')}
                  </Text>
                  {individualTemplates
                    .filter((template) =>
                      template.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                    )
                    .map((template) => (
                      <TemplateRow
                        key={template.id}
                        template={template}
                        isSelected={selectedTemplateId === template.id}
                        isCurrent={currentTemplateID === template.id}
                        onSelect={onSelectTemplate}
                        onEdit={handleEditTemplate}
                      />
                    ))}
                </Box>
              )}

              {/* Team Templates Section */}
              {teamTemplates.filter((template) =>
                template.name.toLowerCase().includes(searchQuery.toLowerCase()),
              ).length > 0 && (
                <Box marginBottom="Micro Large">
                  <Text
                    size="small"
                    color="subtle"
                    tagName="div"
                    className={styles.TemplatesRowsHeader}
                  >
                    {i18n.t('assist_templates_home_subtitle_company_templates')}
                  </Text>
                  {teamTemplates
                    .filter((template) =>
                      template.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                    )
                    .map((template) => (
                      <TemplateRow
                        key={template.id}
                        template={template}
                        isSelected={selectedTemplateId === template.id}
                        isCurrent={currentTemplateID === template.id}
                        onSelect={onSelectTemplate}
                        onEdit={handleEditTemplate}
                      />
                    ))}
                </Box>
              )}

              {/* Default Templates Section */}
              {defaultTemplates.filter((template) =>
                template.name.toLowerCase().includes(searchQuery.toLowerCase()),
              ).length > 0 && (
                <Box marginBottom="Micro Large">
                  <Text
                    size="small"
                    color="subtle"
                    tagName="div"
                    className={styles.TemplatesRowsHeader}
                  >
                    {i18n.t('assist_templates_home_subtitle_starter_templates')}
                  </Text>
                  {defaultTemplates
                    .filter((template) =>
                      template.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                    )
                    .map((template) => (
                      <TemplateRow
                        key={template.id}
                        template={template}
                        isSelected={selectedTemplateId === template.id}
                        isCurrent={currentTemplateID === template.id}
                        onSelect={onSelectTemplate}
                        onEdit={handleEditTemplate}
                      />
                    ))}
                </Box>
              )}
            </>
          ) : (
            <Text size="medium">{i18n.t('assist_template_no_results')}</Text>
          )}
        </div>
      </div>
    );
  },
);
TemplatesList.displayName = 'TemplatesList';

interface TemplateRowProps {
  template: AssistTemplate;
  isSelected: boolean;
  isCurrent: boolean;
  onSelect: (template: AssistTemplate) => void;
  onEdit: (templateId: string) => void;
}
const TemplateRow = memo(
  ({ template, isSelected, onSelect, isCurrent, onEdit }: TemplateRowProps) => {
    const handleClick = useCallback(() => {
      onSelect(template);
    }, [onSelect, template]);
    const isOwnedTeamTemplate = 'accessType' in template && template.isOwned;
    return (
      <List.Item
        isSelected={isSelected}
        isSelectable
        onClick={handleClick}
        onKeyDown={onKeyDownCommitFn(handleClick)}
        className={classnames(styles.TemplateListItem, {
          [styles.TemplateListItemSelected]: isSelected,
        })}
        role="button"
        tabIndex={0}
      >
        <Text
          size="medium"
          tagName="div"
          className={styles.TemplatesRowTitle}
          color="inherit"
        >
          {template.name}
        </Text>
        <List.Accessory>
          {isOwnedTeamTemplate && (
            <UIIcon
              aria-label={i18n.t('assist_template_selected_label')}
              src={EditLine}
              className={styles.TemplatesRowIcon}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(template.id);
              }}
            />
          )}
          {isCurrent && (
            <UIIcon
              aria-label={i18n.t('assist_template_selected_label')}
              src={CheckmarkLine}
            />
          )}
        </List.Accessory>
      </List.Item>
    );
  },
);
TemplateRow.displayName = 'TemplateRow';
