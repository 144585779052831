import { setExperimentationAttributes } from '@mirage/service-experimentation';
import { tagged } from '@mirage/service-logging';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { atom, useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import * as service from '..';

import type { FeatureRing, FeatureRingSettings } from '../types';

const logger = tagged('useFeatureRingSettings');

const currentFeatureRingForLoggingAtom = atom<string | undefined>(undefined);

const getFeatureRingForLoggingCombined = getCombineAsyncRequestsFunc(
  async () => {
    let ring = await service.getFeatureRingFromCacheForLogging();
    if (!ring) {
      try {
        const response = await service.getFeatureRingSettings();
        ring = response?.override?.id ?? response?.default?.id;
      } catch (e) {
        // Ignore because the user might be logged out now.
        logger.debug(
          `getFeatureRingFromCacheForLogging called in logged-out state (ok to ignore)`,
        );
      }
    }
    return ring;
  },
);

export const useFeatureRingSettings = () => {
  const [featureRingSettings, setFeatureRingSettings] = useState<
    FeatureRingSettings | undefined
  >(undefined);
  const [currentFeatureRingForLogging, setCurrentFeatureRingForLogging] =
    useAtom(currentFeatureRingForLoggingAtom);

  const getCurrentFeatureRing = useCallback(async (): Promise<
    string | undefined
  > => {
    const response: FeatureRingSettings =
      await service.getFeatureRingSettings();
    setFeatureRingSettings(response);

    const featureRolloutRingId =
      response?.override?.id ?? response?.default?.id;
    logger.info(
      'Setting feature_rollout_ring attribute to',
      featureRolloutRingId,
    );
    setExperimentationAttributes({
      feature_rollout_ring: featureRolloutRingId,
    });

    return featureRolloutRingId;
  }, []);

  const setOveride = useCallback(async (ring: FeatureRing) => {
    const updated = await service.setOverride(ring);
    setFeatureRingSettings(updated);
    setExperimentationAttributes({ feature_rollout_ring: ring.id });
  }, []);

  const clearOverride = useCallback(async () => {
    const updated = await service.clearOverride();
    setFeatureRingSettings(updated);
    setExperimentationAttributes({
      feature_rollout_ring: updated?.default?.id,
    });
  }, []);

  const isOveridden = useCallback(() => {
    if (featureRingSettings?.override == undefined) {
      return false;
    }
    return featureRingSettings?.default?.id != featureRingSettings.override?.id;
  }, [featureRingSettings]);

  const getFeatureRingFromCacheForLogging = useCallback(async () => {
    let ring = currentFeatureRingForLogging;
    if (!ring) {
      ring = await getFeatureRingForLoggingCombined();
      setCurrentFeatureRingForLogging(ring);
    }
    return ring;
  }, [currentFeatureRingForLogging, setCurrentFeatureRingForLogging]);

  return {
    featureRingSettings,
    getCurrentFeatureRing,
    setOveride,
    clearOverride,
    isOveridden,
    getFeatureRingFromCacheForLogging,
  };
};
