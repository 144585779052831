import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Split } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { EditLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import {
  getWorkflowAgent,
  setWorkflowAgent,
} from '@mirage/service-conversation';
import { useWorkflowAgents } from '@mirage/service-conversation/hooks/useWorkflowAgents';
import { tagged } from '@mirage/service-logging';
import { FLIPOrchestratorProvider } from '@mirage/shared/FLIP/useFLIPOrchestrator';
import { VerticalExpandable } from '@mirage/shared/FLIP/VerticalExpandable';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Step1, Step2, Step3, Step4, Step5 } from './constants';
import { ExpandableCard } from './ExpandableCard';
import { SourcesCacheProvider } from './SourcesCacheContext';
import { ToolTypeSelector } from './ToolTypeSelector';
import { WorkflowStep as WorkflowStepComponent } from './WorkflowStep';

import type {
  WorkflowOption,
  WorkflowStep,
  WorkflowStepInputType,
  WorkflowToolType,
} from './types';
import type {
  WorkflowAgent,
  WorkflowStepValuesType,
  WorkflowValues,
} from '@mirage/service-conversation/types';

const logger = tagged('WorkflowAgent');

const WORKFLOW_STEPS: WorkflowStep[] = [Step1, Step2, Step3, Step4, Step5];
const TOTAL_STEPS = WORKFLOW_STEPS.length;

/**
 * Converts a workflow step input into its appropriate value format
 *
 * This function extracts the default/initial value from different input types:
 * - For selection inputs: returns the selected option ID or empty string
 * - For boolean inputs: returns false as default
 * - For multi-select inputs: returns empty array
 * - For combo inputs: recursively processes nested inputs
 * - For other inputs: returns empty string
 *
 * @param input - The workflow step input configuration object
 * @returns The appropriate default value based on input type
 */
const processInputForDefaultValue = (
  input: WorkflowStepInputType,
): string | boolean | string[] | WorkflowStepValuesType => {
  if (
    input.type === 'select' ||
    input.type === 'radio-select' ||
    input.type === 'tile-select'
  ) {
    // For selection inputs, find the option marked as selected or return empty string
    return (
      input.availableOptions?.find(
        (option: WorkflowOption) => option.isSelected,
      )?.id || ''
    );
  } else if (input.type === 'boolean') {
    // Default boolean inputs to false
    return false;
  } else if (input.type === 'multi-select' || input.type === 'source-select') {
    // Multi-select inputs start with empty selection array
    return [];
  } else if (input.type === 'combo-step') {
    // For combo inputs, recursively process each nested input
    const comboValues: WorkflowStepValuesType = {};
    input.comboInputs?.forEach((comboInput: WorkflowStepInputType) => {
      comboValues[comboInput.id] = processInputForDefaultValue(comboInput);
    });
    return comboValues;
  } else {
    // Default case for text inputs and others - empty string
    return '';
  }
};

const getDefaultWorkflowValues = (): WorkflowValues => {
  const defaultValues: WorkflowValues = {};

  WORKFLOW_STEPS.forEach((step) => {
    const stepValues: WorkflowStepValuesType = {};
    step.inputs.forEach((input) => {
      stepValues[input.id] = processInputForDefaultValue(input);
    });
    defaultValues[step.step] = stepValues;
  });

  return defaultValues;
};

export const WorkflowAgentUI = memo(({ agentId }: { agentId?: string }) => {
  const { showWorkflowAgent, setShowWorkflowAgent } =
    useComposeCurrentSessionContext();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [expandedStep, setExpandedStep] = useState<number | undefined>(
    currentStep,
  );
  const [selectedTool, setSelectedTool] = useState<WorkflowToolType>(
    () => getDefaultWorkflowValues()[1].type as WorkflowToolType,
  );
  const [agent, setAgent] = useState<WorkflowAgent | undefined>(undefined);
  const [workflowValues, setWorkflowValues] = useState<WorkflowValues>(
    getDefaultWorkflowValues,
  );
  const { createAgent } = useWorkflowAgents();

  useEffect(() => {
    async function loadAgent() {
      if (agentId && agentId !== 'new') {
        const agent = await getWorkflowAgent(agentId);
        setAgent(agent);
        setWorkflowValues(agent.workflow);
        setCurrentStep(5);
        setExpandedStep(5);
      }
    }
    loadAgent();
  }, [agentId]);

  const handleStepSubmit = async (
    step: number,
    values: WorkflowStepValuesType,
  ) => {
    logger.info('Step submission', { step, values });
    setWorkflowValues((prev) => ({
      ...prev,
      [step]: values,
    }));

    if (step < TOTAL_STEPS) {
      setCurrentStep(step + 1);
      setExpandedStep(step + 1);
    }
    if (step === TOTAL_STEPS) {
      const agent = await setWorkflowAgent(
        agentId !== 'new' && agentId ? agentId : uuid(),
        {
          ...workflowValues,
          [step]: values,
        },
      );
      await createAgent(agent);
      navigate(RoutePath.CHAT);
      setShowWorkflowAgent(false);
    }
  };

  const handleStepBack = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
    setExpandedStep((prev) => (prev && prev > 1 ? prev - 1 : prev));
  };

  logger.info('Workflow values', { workflowValues });

  if (!showWorkflowAgent) {
    return null;
  }

  const renderStep = (stepDef: WorkflowStep) => {
    const isFirstStep = stepDef.step === 1;

    return (
      <ExpandableCard
        key={stepDef.step}
        step={stepDef.step}
        title={stepDef.name}
        active={currentStep === stepDef.step}
        expanded={expandedStep === stepDef.step}
        completed={currentStep > stepDef.step}
        toggleExpanded={() => {
          setExpandedStep(
            expandedStep === stepDef.step ? undefined : stepDef.step,
          );
        }}
      >
        {isFirstStep ? (
          <ToolTypeSelector
            step={stepDef}
            currentValue={selectedTool}
            onCancel={() => {}}
            onSubmit={(step, tool) => {
              setSelectedTool(tool as WorkflowToolType);
              handleStepSubmit(step, { type: tool });
            }}
          />
        ) : (
          <WorkflowStepComponent
            step={stepDef}
            currentValues={workflowValues[stepDef.step]}
            onCancel={handleStepBack}
            onSubmit={(values) => {
              handleStepSubmit(stepDef.step, values);
            }}
          />
        )}
      </ExpandableCard>
    );
  };

  return (
    <SourcesCacheProvider>
      <FLIPOrchestratorProvider>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop="Micro Medium"
          paddingBottom="Macro Medium"
          style={{
            gap: 'var(--dig-spacing__micro__medium)',
            width: 'min(528px, 80%)',
          }}
          as={VerticalExpandable}
        >
          {agentId && agentId !== 'new' && (
            <Split
              alignX="left"
              alignY="center"
              width="stretch"
              gap="Micro Medium"
            >
              <Split.Item paddingTop="6">
                <Text>
                  <UIIcon src={EditLine} />
                </Text>
              </Split.Item>
              <Split.Item>
                <Title size="small">Editing {agent?.name}</Title>
              </Split.Item>
            </Split>
          )}
          {WORKFLOW_STEPS.map(renderStep)}
        </Box>
      </FLIPOrchestratorProvider>
    </SourcesCacheProvider>
  );
});

WorkflowAgentUI.displayName = 'WorkflowAgentUI';
