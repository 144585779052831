export enum SelfServeOnboardingStep {
  COMPANY_INFO = 'company-info',
  PROFILING = 'profiling',
  LARGE_TEAM = 'large-team',
  JTBD = 'jtbd',
  CONNECTORS = 'connectors',
  TEAM_INVITE = 'team-invite',
  DOWNLOAD_UPSELL = 'download',
  ERROR = 'error',
}
