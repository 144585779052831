import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_CalendarEmptyState } from '@mirage/analytics/events/types/open_calendar_empty_state';
import { PAP_Shown_CalendarEmptyState } from '@mirage/analytics/events/types/shown_calendar_empty_state';
import { useAddCalendarModal } from '@mirage/mosaics/WorkingSet/Calendar/AddCalendar';
import { useConnectedCalendars } from '@mirage/mosaics/WorkingSet/Calendar/useConnectedCalendars';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import { CalendarEmptyStateCard } from './CalendarEmptyStateCard/CalendarEmptyStateCard';

import type { UIConnector } from '@mirage/shared/types';

type CalendarEmptyStateV2Props = {
  onDismiss: (dismissed: boolean) => void;
};

export const CalendarEmptyStateV2 = ({
  onDismiss,
}: CalendarEmptyStateV2Props) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { refreshConnectionsList } = useConnectedCalendars();
  const showAddCalendarModal = useAddCalendarModal();
  const hasShown = useRef(false);

  useEffect(() => {
    if (!hasShown.current) {
      reportPapEvent(
        PAP_Shown_CalendarEmptyState({
          featureLine: 'calendar',
          calendarEmptyStateType: 'no_events',
        }),
      );
      hasShown.current = true;
    }
  }, [reportPapEvent, hasShown]);

  const handleEmptyStateClick = () => {
    reportPapEvent(
      PAP_Open_CalendarEmptyState({
        actionSurfaceComponent: 'calendar',
      }),
    );
    showAddCalendarModal({
      callback: (connector?: UIConnector) => {
        if (connector) {
          const connectorName = connector?.branding?.display_name;
          if (connectorName) {
            const title = i18n.t('app_connected', { connectorName });
            showSnackbar({ title });
            refreshConnectionsList();
          }
        }
      },
    });
  };

  return (
    <CalendarEmptyStateCard
      onClick={handleEmptyStateClick}
      onDismiss={() => onDismiss(true)}
    />
  );
};
