import { Button } from '@dropbox/dig-components/buttons';
import {
  Overlay,
  type OverlayAnchorRef,
} from '@dropbox/dig-components/overlay';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { hexToHsva, type HsvaColor, hsvaToHex } from '@uiw/color-convert';
import Hue from '@uiw/react-color-hue';
import Saturation from '@uiw/react-color-saturation';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { UnthemedWrapper } from '../UnthemedWrapper';
import { isValidHexColor } from '../utils';
import styles from './HexColorPicker.module.css';

import type { DependencyList } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounceAnimationFrame = <F extends (...args: any[]) => any>(
  fn: F,
) => {
  let timeout: number;
  const debouncedFn = (...args: Parameters<F>) => {
    cancelAnimationFrame(timeout);
    return new Promise<ReturnType<F>>((resolve) => {
      timeout = requestAnimationFrame(() => {
        const result: ReturnType<F> = fn(...args);
        resolve(result);
      });
    });
  };
  return debouncedFn;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounceAnimationFrame = <F extends (...args: any[]) => any>(
  fn: F,
  deps: DependencyList,
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => debounceAnimationFrame(fn), deps);
};

interface PointerProps {
  prefixCls?: string;
  top?: string;
  left?: string;
  color?: string;
}

const Pointer = ({ left, top, color }: PointerProps) => (
  <div
    className={styles.pointerRootDiv}
    style={{ left, top, backgroundColor: color }}
  />
);

interface HexColorPickerProps {
  open: boolean;
  anchorRef: OverlayAnchorRef;
  onSave?: (hex: string) => void;
  onClose: () => void;
  isPortaled?: boolean;
  color?: string;
}

const DEFAULT_COLOR = '#003a97';

export const HexColorPicker: React.FC<HexColorPickerProps> = ({
  open,
  anchorRef,
  onClose,
  onSave = () => {},
  isPortaled = true,
  color = DEFAULT_COLOR,
}) => {
  const initialColor = useMemo(() => {
    try {
      return hexToHsva(color);
    } catch {
      return hexToHsva(DEFAULT_COLOR);
    }
  }, [color]);

  const [hsva, setHsva] = useState(initialColor);
  const [hex, setHex] = useState(color || DEFAULT_COLOR);

  const updateColorValue = useDebounceAnimationFrame((newHsva: HsvaColor) => {
    const newHex = hsvaToHex(newHsva);
    setHsva(newHsva);
    setHex(newHex);
  }, []);

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setHex(value);
    try {
      const hsva = hexToHsva(e.currentTarget.value);
      setHsva(hsva);
    } catch {
      // Unable to parse value as hex
    }
  };

  const handleSaveClick = () => {
    if (!isValidHexColor(hex)) {
      showSnackbar({ title: i18n.t('invalid_hex_color') });
      return;
    }
    onSave(hex);
  };

  const handleCancelClick = () => {
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Overlay
      anchorRef={anchorRef}
      isPortaled={isPortaled}
      offsetDistance={8}
      placement={'bottom'}
    >
      <UnthemedWrapper>
        <div className={styles.container}>
          <Saturation
            hsva={hsva}
            onChange={(newColor) => {
              updateColorValue({ ...hsva, ...newColor, a: hsva.a });
            }}
            pointer={Pointer}
            className={styles.saturation}
          />
          <Hue
            hue={hsva.h}
            onChange={(newHue) => {
              updateColorValue({ ...hsva, ...newHue });
            }}
            // eslint-disable-next-line react/no-unstable-nested-components
            pointer={(props) => (
              <Pointer
                {...props}
                color={`hsl(${hsva.h || 0}deg 100% 50%)`}
                top="50%"
              />
            )}
            className={styles.hueContainer}
          />
          <div className={styles.colorInput}>
            <div className={styles.colorInputLabel}>
              <Text id="hex-label" isBold>
                {i18n.t('hex')}
              </Text>
            </div>
            <TextInput
              aria-labelledby="hex-label"
              onChange={handleTextInputChange}
              value={hex}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              data-testid="color-picker-cancel"
              onClick={handleCancelClick}
              size="small"
              variant="outline"
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              data-testid="color-picker-save"
              onClick={handleSaveClick}
              size="small"
              variant="primary"
            >
              {i18n.t('save')}
            </Button>
          </div>
        </div>
      </UnthemedWrapper>
    </Overlay>
  );
};
