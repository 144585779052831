import { Skeleton } from '@dropbox/dig-components/skeleton';
import classNames from 'classnames';
import styles from './MultimediaSearchResults.module.css';

export const MultimediaResultItemSkeleton = () => {
  return (
    <div className={styles.imageSkeletonContainer}>
      <MultimediaResultItemImageSkeleton />
      <div className={styles.title}>
        <div className={styles.titleRow}>
          <Skeleton.Box height={20} width={20}></Skeleton.Box>
          <div className={styles.titleText}>
            <Skeleton.Text width={330} />
          </div>
        </div>
        <Skeleton.Text className={styles.imageSkeletonText} width={330} />
      </div>
    </div>
  );
};

export const MultimediaResultItemImageSkeleton = (props: {
  large?: boolean;
}) => {
  return (
    <div
      className={classNames(
        styles.imageSkeleton,
        props.large ? styles.imageSkeletonLarge : '',
      )}
    >
      <Skeleton.Box shouldAnimate></Skeleton.Box>
    </div>
  );
};
