import type { stacks } from '@dropbox/api-v2-client';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { stackDerivePAPProps } from '@mirage/service-stacks';

export enum SectionsActionType {
  EDIT_SECTION = 'EDIT_SECTION',
  CREATE_SECTION = 'CREATE_SECTION',
  ADD_ITEM = 'ADD_ITEM',
  ADD_ITEM_FROM_SUGGESTIONS = 'ADD_ITEM_FROM_SUGGESTIONS',
}

export interface UserInfo {
  profilePhotoUrl?: string;
  displayName: string;
}

export type StackDerivedPAPPropsWithFeature =
  | ReturnType<typeof stackDerivePAPProps>
  | { [index: string]: never };

export type FullScreenStackV2IncomingState = {
  name: string;
  colorIndex: number;
  items: stacks.StackItem[];
  // only needed for dismissal
  autostackSuggestion?: stacks.AutoStackSuggestion;
  predictionIdHash?: number;
};
