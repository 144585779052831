import { ApiOpQueue } from '@mirage/service-compose/service/apiOpQueue';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';
import { Subject } from 'rxjs';

// Base interface for syncable data
export interface SyncableData {
  id: string;
  isDirty?: boolean;
  dataId?: string;
  rev: number;
}

export interface DataStorage<T extends SyncableData> {
  save: (items: T[]) => Promise<void>;
  load: () => Promise<T[]>;
}

export interface DataAPI<T extends SyncableData> {
  save: (item: T) => Promise<T>;
  delete: (item: T) => Promise<void>;
  load: () => Promise<T[]>;
}

type DataOpQueue<T> = ApiOpQueue<T | undefined>;

const logger = tagged('apiDataSync');

async function getSyncServerDeletionsConfig(): Promise<'ON' | 'OFF' | 'TEST'> {
  const flag = await getCachedOrFetchFeatureValue(
    'dash_2025_03_19_assist_kv_sync_server_deletions',
  );
  if (flag === undefined) {
    return 'OFF';
  }
  const flagString = String(flag);
  switch (flagString) {
    case 'ON':
      return 'ON';
    case 'OFF':
      return 'OFF';
    case 'TEST':
      return 'TEST';
    default:
      return 'OFF';
  }
}

/**
 * Create a data sync object that can be used to sync data between the local storage and the API.
 * @param storage - The storage object that will be used to save and load the data.
 * @param api - The API object that will be used to save and delete the data.
 * @param transformItems - A function that will be used to transform the items before they are returned by getItems.
 * @returns A data sync object that can be used to sync the data between the local storage and the API.
 */
export function createDataSync<StoredItem extends SyncableData, ReturnItem>(
  storage: DataStorage<StoredItem>,
  api: DataAPI<StoredItem>,
  transformItems: (storedItems: StoredItem[]) => ReturnItem[],
) {
  /* subject that emits latest items, includes presets */
  const itemsSubject = new Subject<ReturnItem[]>();
  /* cached items, mapped by item id, excludes presets */
  let cachedUserItems: Promise<Map<string, StoredItem>> | undefined = undefined;
  /* ongoing save API operations, mapped by item id */
  const itemOpQueues = new Map<string, DataOpQueue<StoredItem>>();

  /**
   * Get the cached user items. If they haven't been loaded yet, this will trigger a load from
   * storage and the API.
   */
  async function getUserItems(): Promise<Map<string, StoredItem>> {
    if (!cachedUserItems) {
      cachedUserItems = firstLoad();
    }
    return await cachedUserItems;
  }

  /**
   * Initial load - load from storage and does a sync with the server API.
   */
  async function firstLoad(): Promise<Map<string, StoredItem>> {
    const userItemsArray = await storage.load();
    const userItems = new Map(userItemsArray.map((item) => [item.id, item]));

    // load from API and update local items
    const apiItems = await api.load();
    const apiItemIDs = new Set(apiItems.map((item) => item.id));
    for (const apiItem of apiItems) {
      const localItem = userItems.get(apiItem.id);
      if (localItem?.isDirty) {
        // there are local changes, only assign dataId (for aligning new items)
        userItems.set(apiItem.id, {
          ...localItem,
          dataId: apiItem.dataId,
        });
      } else {
        userItems.set(apiItem.id, apiItem);
      }
    }

    const dirtyItemIDs = new Set<string>();
    for (const item of userItems.values()) {
      if (item.isDirty || item.dataId === undefined) {
        dirtyItemIDs.add(item.id);
      }
    }

    const serverDeletionsConfig = await getSyncServerDeletionsConfig();

    // delete any non-dirty items that are not in the API
    for (const item of userItems.values()) {
      if (!dirtyItemIDs.has(item.id) && !apiItemIDs.has(item.id)) {
        if (serverDeletionsConfig === 'ON') {
          logger.log(`found server-deleted item, deleting: ${item.id}`);
          userItems.delete(item.id);
        } else if (serverDeletionsConfig === 'TEST') {
          // TEST mode, logging to Sentry for testing purposes
          Sentry.captureMessage(
            `[Assist KV] found server-deleted item when syncing: ${item.id}`,
            'error',
          );
        }
      }
    }

    // kick off and wait for API saves for dirty items
    const dirtySaveOps: Promise<StoredItem>[] = [];
    for (const item of userItems.values()) {
      if (dirtyItemIDs.has(item.id)) {
        logger.log(
          `found locally modified item, kicking off API save: ${item.id}`,
        );
        const savedItem = api.save(item);
        dirtySaveOps.push(savedItem);
      }
    }
    const savedItems = await Promise.all(dirtySaveOps);
    for (const savedItem of savedItems) {
      userItems.set(savedItem.id, savedItem);
    }

    await storage.save(Array.from(userItems.values()));
    return userItems;
  }

  /**
   * Look for new items from the API and add them to the local cache.
   */
  async function checkForNewAPIItems() {
    const userItems = await getUserItems();

    const dirtyItemIDs = new Set<string>();
    for (const item of userItems.values()) {
      if (item.isDirty || item.dataId === undefined) {
        dirtyItemIDs.add(item.id);
      }
    }

    // load from API and add newly found items
    const apiItems = await api.load();
    const apiItemIDs = new Set(apiItems.map((item) => item.id));
    let hasModifications = false;

    // check for new items or updated items
    for (const apiItem of apiItems) {
      const localItem = userItems.get(apiItem.id);
      if (!localItem || localItem.rev < apiItem.rev) {
        userItems.set(apiItem.id, apiItem);
        hasModifications = true;
      }
    }

    // delete any non-dirty items that are not in the API
    for (const item of userItems.values()) {
      if (!dirtyItemIDs.has(item.id) && !apiItemIDs.has(item.id)) {
        logger.log(`found server-deleted item, deleting: ${item.id}`);
        userItems.delete(item.id);
        hasModifications = true;
      }
    }

    if (hasModifications) {
      saveCachedItemsAndEmit(userItems);
    }
  }

  /**
   * Save an item. This will optimistically update the local cache and queue a save to
   * the API without waiting for the response.
   */
  async function saveItem(item: StoredItem) {
    const dirtyItem = {
      ...item,
      isDirty: true,
    };

    const userItems = await getUserItems();
    const existingItem = userItems.get(dirtyItem.id);
    if (dirtyItem.dataId === undefined && existingItem?.dataId !== undefined) {
      dirtyItem.dataId = existingItem.dataId;
    }

    userItems.set(dirtyItem.id, dirtyItem);
    saveCachedItemsAndEmit(userItems);

    queueAPISave(dirtyItem, userItems);
  }

  /**
   * Queue a save to the API using the op queue. This helps ensure that the save does not create
   * duplicates on the server side by waiting for any ongoing save ops to complete first and applying
   * its data id to the new save op.
   */
  async function queueAPISave(
    item: StoredItem,
    userItems: Map<string, StoredItem>,
  ) {
    let queue = itemOpQueues.get(item.id);
    if (!queue) {
      queue = new ApiOpQueue<StoredItem | undefined>(userItems.get(item.id));
      itemOpQueues.set(item.id, queue);
    }

    queue
      .addOp(async (prevItem) => {
        const itemToSave = { ...item };
        if (item.dataId === undefined && prevItem?.dataId !== undefined) {
          // item is new, check if a server response just came back and use its data id
          itemToSave.dataId = prevItem.dataId;
        }
        return await api.save(itemToSave);
      })
      .then((savedItem) => {
        if (!savedItem) {
          throw new Error('Save op completed with undefined result');
        }
        // update cache + emit new items
        userItems.set(item.id, savedItem);
        saveCachedItemsAndEmit(userItems);
        return;
      })
      .catch((err) => {
        logger.error('Failed to save item', item.id, err);
      });
  }

  /**
   * Delete an item. This will optimistically update the local cache and queue a delete to
   * the API without waiting for the response.
   */
  async function deleteItem(item: StoredItem) {
    const userItems = await getUserItems();

    // optimistically update cache + emit new items
    userItems.delete(item.id);
    saveCachedItemsAndEmit(userItems);

    queueAPIDelete(item, userItems);
  }

  /**
   * Queue a delete to the API using the op queue. This helps ensure that the delete comes after
   * any ongoing save ops have completed so it does not cause the item to be recreated after the
   * deletion.
   */
  async function queueAPIDelete(
    item: StoredItem,
    userItems: Map<string, StoredItem>,
  ) {
    let queue = itemOpQueues.get(item.id);
    if (!queue) {
      queue = new ApiOpQueue<StoredItem | undefined>(userItems.get(item.id));
      itemOpQueues.set(item.id, queue);
    }
    queue
      .addOp(async (prevItem) => {
        const itemToSave = { ...item };
        if (item.dataId === undefined && prevItem?.dataId !== undefined) {
          // item is new, check if a server response just came back and use its data id
          itemToSave.dataId = prevItem.dataId;
        }
        await api.delete(item);
        return undefined;
      })
      .catch((err) => {
        logger.error('Failed to delete item', item.id, err);
      });
  }

  /**
   * Helper for saving the cached items to storage and emitting the new items.
   */
  function saveCachedItemsAndEmit(userItems: Map<string, StoredItem>) {
    storage.save(Array.from(userItems.values()));
    itemsSubject.next(transformItems(Array.from(userItems.values())));
  }

  return {
    getItems: async () => {
      const userItems = await getUserItems();
      return transformItems(Array.from(userItems.values()));
    },
    saveItem,
    deleteItem,
    checkForNewAPIItems,
    itemsObservable: () => itemsSubject.asObservable(),
    tearDown: async () => {
      for (const queue of itemOpQueues.values()) {
        queue.stop();
      }
      itemOpQueues.clear();
    },
  };
}
