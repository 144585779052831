export type ExperimentSource = 'growthbook' | 'stormcrow';

export const StormcrowValues = [
  'OFF',
  'ON',
  'CONTROL',
  'V1',
  'V2',
  'V3',
] as const;

// This is a common list for ON values, but is by no means exhaustive.
export const FEATURE_ON_STRING_VALUES: FeatureValue[] = [
  'ON',
  'V1',
  'V2',
  'V3',
];

// This is a common list for OFF values, but is by no means exhaustive.
export const FEATURE_OFF_STRING_VALUES: FeatureValue[] = ['OFF', 'CONTROL'];

/**
 * Note that the server can put any value, so this list might not always be
 * correct. If any exceptions, we'll let the dev handle special cases.
 */
export type FeatureStringValue = (typeof StormcrowValues)[number];

export type FeatureValue =
  | FeatureStringValue
  | boolean
  | number
  | object
  | undefined;

type _FeatureFlag<PossibleFeatureNames extends string> = {
  source: ExperimentSource;

  // Value of the feature.
  value?: FeatureValue;
  featureName: PossibleFeatureNames;
  userOverrideDisabled?: boolean;
  overrideValue?: FeatureValue;
  customVariants?: string[];
};

export const featuresList = [
  {
    featureName: 'test_feature_ring_internal_team',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_internal_dash',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_internal_dbx',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_external_beta',
    source: 'growthbook',
    userOverrideDisabled: true,
  },
  {
    featureName: 'test_feature_ring_lts',
    source: 'growthbook',
    userOverrideDisabled: true,
  },

  {
    featureName: 'dash_2024_05_15_typeahead_filter_out_events',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_05_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_fullstory',
    source: 'growthbook',
  },
  {
    featureName: 'dash_server_result_pinning_threshold',
    source: 'growthbook',
  },
  {
    featureName: 'dash_typeahead_2024_06_05_suggested_query_score',
    source: 'growthbook',
  },
  {
    featureName: 'dash_typeahead_weights',
    source: 'growthbook',
  },
  {
    featureName: 'dash_link_description',
    source: 'growthbook',
  },
  {
    featureName: 'dash_use_dash_components',
    source: 'growthbook',
  },
  {
    featureName: 'dash_preview_suggested_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_extension_local_webapp',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_05_09_redirect_dropboxers_staging',
    source: 'growthbook',
  },
  {
    featureName: 'dash_for_business_enabled',
    source: 'stormcrow',
  },
  {
    featureName: 'dash_2024_05_10_preview_public_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_05_23_archive_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_10_stack_item_summary',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_13_activity_feed',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_06_18_cloud_docs',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_07_03_clone_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_04_start_page_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_04_all_stacks_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_04_stack_page_august_revision',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_08_search_content_type_filter',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_07_29_typeahead_null_state_uses_recommendations',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_01_activity_feed_filter_only_mine',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_01_activity_feed_filter_people',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_08_06_recents_data_source',
    source: 'growthbook',
  },
  {
    featureName: 'context_engine_2024_08_19_experiment_setting',
    source: 'growthbook',
  },
  {
    featureName: 'context_engine_2025_02_10_streaming_answers',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_03_search_summary_enable_multimodal',
    source: 'growthbook',
  },
  {
    featureName: 'dash_assist_2024_09_06_compose_v1',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_12_activity_feed_expanded_items',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_12_force_onboarding_from_desktop_app',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_09_17_use_stage_backend',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_19_typeahead_summary_row_button',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_09_clear_browser_history_variants',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_09_23_stacks_on_start_page',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_17_eligibility_redirect_v2',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_09_24_published_content_is_admin',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_26_summary_enable_spreadsheet',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_25_recents_fallback_to_clicks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_25_force_recents_fallback',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_09_30_typeahead_filters',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2024_10_02_show_company_pinned_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_10_04_mock_recents_data',
    source: 'growthbook',
  },
  { featureName: 'dash_2024_10_07_desktop_onboarding', source: 'growthbook' },
  {
    featureName: 'dash_2024_10_07_activity_feed_fallback_collaborator_docs',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_10_08_remove_beta_labels_for_dfb',
    source: 'growthbook',
  },
  {
    featureName: 'dash_assist_2024_10_12_chat_v1',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_10_assist_tones_edit_preview',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_17_assist_tones_sharing',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_10_24_open_files_in_desktop_apps',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_10_25_increased_desktop_application_scoring',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_10_25_summary_ask_variant',
    source: 'growthbook',
    customVariants: [
      'TEXTONLY_PIXTRAL_GROQ',
      'MULTIMODAL_GPT4O',
      'MULTIMODAL_PIXTRAL',
      'MULTIMODAL_QWEN',
    ],
  },
  {
    featureName: 'dash_2024_10_28_use_assist_chat_api',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_10_09_show_safari',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_10_30_verified_search_results',
    source: 'growthbook',
  },
  {
    featureName: 'dash_assist_2024_11_13_export_to_paper',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_11_13_search_results_virtual_path',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_11_18_cold_start_notification',
    source: 'growthbook',
  },
  { featureName: 'dash_2024_11_21_language_picker', source: 'growthbook' },
  {
    featureName: 'dash_2024_11_14_extension_upsell_onboarding',
    source: 'growthbook',
  },
  {
    featureName:
      'dash_2024_11_21_activity_feed_fallback_collaborator_docs_threshold',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_11_22_upstream_filter_by_file_type',
    source: 'growthbook',
  },
  {
    featureName: 'context_engine_2024_11_26_multimodal_file_type_filter',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_04_meaningful_updates',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_11_dual_mode',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_18_sunadance_demo_folder',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_19_stacks_warn_on_adding_duplicate_links',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2024_12_23_activity_feed_use_list_feed_items',
    source: 'growthbook',
  },
  {
    featureName: 'dash_assist_2025_01_09_assist_compose_tables',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_02_20_assist_templates',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_02_25_assist_custom_templates',
    source: 'growthbook',
  },
  {
    featureName: 'dash_organize_2025_01_10_drag_pinned_stacks',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2025_01_14_paste_emails_to_share',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_01_31_paste_email_education_tooltip',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2025_01_21_stacks_add_to_section',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2025_01_22_stack_card_popover',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_01_24_assist_direct_uploads',
    source: 'growthbook',
  },
  { featureName: 'dash_2025_01_24_desktop_home_page', source: 'growthbook' },
  {
    featureName: 'dash_web_2025_01_28_dnd_improvements',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_01_28_search_results_use_logical_type_config',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_01_29_extension_upsell_on_recents',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2025_01_31_stacks_contextual_information',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_01_31_all_modifiers',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_01_31_spell_correct',
    source: 'growthbook',
  },
  {
    featureName: 'dash_search_2025_02_04_quick_links_section',
    source: 'growthbook',
  },
  {
    featureName: 'otc_growth_2025_01_30_start_page_member_invites',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2025_02_07_stack_remove_eligibility_check',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_02_12_multimedia_search',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_03_13_multimedia_search',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_02_11_conversation_assistant',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2025_02_12_assistant_file_viewer',
    source: 'growthbook',
  },
  {
    featureName: 'otc_growth_2025_02_13_dash_onboarding_complete_rtt',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_02_18_sidepanel_assist_actions',
    source: 'growthbook',
  },
  {
    featureName: 'dash_admin_experience_2025_02_19_content_exclusion_feature',
    source: 'growthbook',
  },
  { featureName: 'dash_2025_03_05_serp_categories', source: 'growthbook' },
  {
    featureName: 'dash_2025_03_03_assistant_onboarding_demo',
    source: 'growthbook',
  },
  {
    featureName: 'dash_web_2025_03_05_publish_company_stack_as_editor',
    source: 'growthbook',
  },
  {
    featureName: 'companyos_2025_03_06_dash_companyos_integration',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_03_03_search_message_results',
    source: 'growthbook',
  },
  {
    featureName: 'otc_growth_2025_03_04_eligible_to_create_dash_freemium_sub',
    source: 'growthbook',
  },
  {
    featureName: 'dash_assist_2025_03_11_workflow_agents_tool',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_03_18_omnibox',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_03_18_activity_feed_supported_connectors',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_03_19_assist_kv_sync_server_deletions',
    source: 'growthbook',
    customVariants: ['ON', 'OFF', 'TEST'],
  },
  {
    featureName: 'dash_web_2025_03_12_custom_stack_color',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_03_21_stack_chat',
    source: 'growthbook',
  },
  {
    featureName: 'dash_2025_03_27_multimedia_stack_thumbnails',
    source: 'growthbook',
  },
] as const satisfies Readonly<_FeatureFlag<string>[]>;

/** A union of all possible feature names */
export type FeatureName = (typeof featuresList)[number]['featureName'];

/** A fully typed feature flag, only allows valid featureNames */
export type FeatureFlag = _FeatureFlag<FeatureName>;

/** A full record of all feature flags by name */
export type FeatureFlags = Record<FeatureName, FeatureFlag>;

export const features = featuresList.reduce(
  (acc, feature) => ({
    ...acc,
    [feature.featureName]: feature,
  }),
  {} as FeatureFlags,
);

// Checks if there are any new feature flags added in the `newFlags` array
// compared to the `oldFlags` array.
export const areNewFlagsAdded = (
  oldFlags: FeatureFlag[],
  newFlags: FeatureFlag[],
) => {
  const oldFlagSet = new Set(oldFlags.map((item) => item.featureName));

  for (const flag of newFlags) {
    if (!oldFlagSet.has(flag.featureName)) {
      return true;
    }
  }

  return false;
};
