import { getCurrentAccount } from '@mirage/service-auth';
import { tagged } from '@mirage/service-logging';
import { useCallback, useEffect, useState } from 'react';

import type { users } from '@dropbox/api-v2-client';

const logger = tagged('useName');

interface UseCurrentAccountReturn {
  firstName: string;
  isLoading: boolean;
  error: Error | null;
}

export function useName(): UseCurrentAccountReturn {
  const [account, setAccount] = useState<users.FullAccount | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAccount = useCallback(async () => {
    try {
      setIsLoading(true);
      const newAccount = await getCurrentAccount();
      if (newAccount) setAccount(newAccount);
      setError(null);
    } catch (e) {
      logger.error('Failed to fetch account', e);
      setError(e instanceof Error ? e : new Error('Failed to fetch account'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  return {
    firstName: account?.name?.given_name || '',
    isLoading,
    error,
  };
}
