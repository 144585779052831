// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3743
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";
import type { DashActionSurface } from "../enums/dash_action_surface";
import type { DashSurfaceBuild } from "../enums/dash_surface_build";
import type { ActionSurfaceComponent } from "../enums/action_surface_component";

// Event_name: 'dismiss.dash_add_connections_modal'
// Description: Event is fired when the Add Connections friction modal is closed
// Owner: otc-growth
export type Dismiss_DashAddConnectionsModal = PAPEventGeneric<
  'dash',
  'dismiss',
  'dash_add_connections_modal',
  {
    // This is used to indicated if the file chooser modal is skipped in the flow
    isChooserModalSkipped?: boolean;
    // the start time of the event in ms
    startTimeMs?: number;
    // Version of Dash (formerly CmdE) Tesla client
    teslaVersion?: string;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
  }
>;

export function PAP_Dismiss_DashAddConnectionsModal(properties?: Dismiss_DashAddConnectionsModal['properties']): Dismiss_DashAddConnectionsModal {
  return <Dismiss_DashAddConnectionsModal>{
    class: 'dash',
    action: 'dismiss',
    object: 'dash_add_connections_modal',
    properties,
  };
}