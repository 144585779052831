import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import Sentry from '@mirage/shared/sentry';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';

const logger = tagged('service-user-metadata');

export type Service = ReturnType<typeof userMetadataService>;

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

export default function userMetadataService(
  dbxApiService: DbxApiServiceContract,
) {
  async function getUserMetadata(
    metadataKeys: string[],
  ): Promise<{ [key: string]: string }> {
    try {
      const response = await dbxApiService.callApiV2(
        'userMetadataUserMetadataGet',
        {
          metadata: metadataKeys,
        },
      );

      return response.metadata;
    } catch (e) {
      const message = 'Failed to get user metadata';

      logger.error(message, e);
      Sentry.withScope((scope) => {
        scope.setTags({
          errorMessage: message,
          keys: metadataKeys.join(', '),
        });

        Sentry.captureException(
          e,
          {
            data: {
              message,
              keys: metadataKeys,
            },
          },
          scope,
        );
      });

      throw e;
    }
  }

  async function setUserMetadata(metadata: {
    [key: string]: string;
  }): Promise<void> {
    try {
      await dbxApiService.callApiV2('userMetadataUserMetadataSet', {
        metadata,
      });
    } catch (e) {
      const message = 'Failed to set user metadata';

      logger.error(message, e);
      Sentry.withScope((scope) => {
        scope.setTags({
          errorMessage: message,
          metadata: Object.values(metadata).join(', '),
        });

        Sentry.captureException(
          e,
          {
            data: {
              message,
              metadata,
            },
          },
          scope,
        );
      });

      throw e;
    }
  }

  return services.provide(
    ServiceId.USER_METADATA,
    {
      getUserMetadata,
      setUserMetadata,
    },
    [ServiceId.DBX_API],
  );
}
