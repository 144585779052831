import { Breadcrumb as DIGBreadcrumb } from '@dropbox/dig-components/breadcrumb';
import { Text } from '@dropbox/dig-components/typography';
import { atoms } from '@dropbox/dig-foundations';
import { Link } from '@mirage/mosaics/BaseProfilePage/Link';

// eslint-disable-next-line no-restricted-imports
import type { LinkProps as DIGBreadcrumbLinkProps } from '@dropbox/dig-components/dist/breadcrumb/types';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';

export type BreadcrumbLinkProps = Omit<DIGBreadcrumbLinkProps, 'href'> &
  RouterLinkProps;

const breadcrumbLinkBuilder = (breadcrumbs: BreadcrumbLinkProps[]) =>
  breadcrumbs.map((breadcrumb, index) => (
    <DIGBreadcrumb.Link
      key={`breadcrumb__${breadcrumb.to.toString()}__${index}`}
      isCurrentPath={!index}
      withLeftIcon={breadcrumb.withLeftIcon}
      withMenuItemIcon={breadcrumb.withMenuItemIcon}
      onClick={(e) => e.preventDefault()}
    >
      <Link {...breadcrumb} to={breadcrumb.to} hasNoUnderline monochromatic>
        {!index ? (
          <Text color="subtle" isBold size="small">
            {breadcrumb.children}
          </Text>
        ) : (
          breadcrumb.children
        )}
      </Link>
    </DIGBreadcrumb.Link>
  ));

const Breadcrumb = ({ path }: { path: BreadcrumbLinkProps[] }) => (
  <DIGBreadcrumb
    linksDisplayed={3}
    typography="text"
    size="small"
    isRootOverflow={false}
    className={atoms({
      paddingTop: '6',
    })}
  >
    {breadcrumbLinkBuilder(path)}
  </DIGBreadcrumb>
);

export { Breadcrumb, breadcrumbLinkBuilder, DIGBreadcrumb };
