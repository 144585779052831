import type { ConnectorName } from '../connectors';
import type { I18nKey } from '@mirage/translations';

/**
 * subset of Translations that are used for managed shortcuts
 * Their messages have a template that can be filled in with parameters
 */
export type ManagedShortcutTitleTemplate = Extract<
  I18nKey,
  | 'who_shortcut_title_template'
  | 'drl_shortcut_title_template'
  | 'jira_shortcut_title_template'
  | 'dxw_shortcut_title_template'
  | 'dxp_shortcut_title_template'
  | 'irond_shortcut_title_template'
  | 'ironr_shortcut_title_template'
  | 'code_shortcut_title_template'
  | 'sl_shortcut_title_template'
  | 'ns_shortcut_title_template'
  | 'hs_shortcut_title_template'
  | 'cp_shortcut_title_template'
  | 'new_any_shortcut_title_template'
  | 'google_shortcut_title_template'
  | 'bing_shortcut_title_template'
>;

export type TitleToken = {
  type: 'shortcut';
  template: ManagedShortcutTitleTemplate;
  query: string;
};

export type CachedURLShortcut = {
  uuid: string;
  hotwords: string[];
  urlTemplate: string;
  titleTemplate: ManagedShortcutTitleTemplate;
  dropboxersOnly: boolean;
  getDefaultQuery?: () => string;
  defaultQuery?: string;
  icon?: {
    lightUrl: string;
    darkUrl: string;
  };
  // Only show the shortcut if the user has one of these connectors
  supportedConnectors?: ConnectorName[];
  timestamp?: number;
};

export type URLShortcut = CachedURLShortcut & {
  parameters: {
    url: string;
    count: number;
    chunks: number;
    matches: string[];
    highlightWords: string[];
    complete: boolean;
    matched: boolean;
    title: TitleToken;
    activeHotword: string;
  };
};

export const CREATE_STACK_ACTION_UUID = '59bb3fac-59f3-445e-b0d9-2374d2860d07';
export const CREATE_WORD_ACTION_UUID = 'a09dc613-0b98-46c2-923a-a4a5ca110da9';
export const CREATE_EXCEL_ACTION_UUID = '198a4fea-5683-44dd-9840-96e29c967dea';
export const CREATE_POWERPOINT_ACTION_UUID =
  '719625b9-f5ae-42d3-ac03-ab2f382184df';

/** Shortcut UUIDs that are "well known" with their IDs used in multiple places */
export const WELL_KNOWN_SHORTCUT_UUIDS = [
  CREATE_STACK_ACTION_UUID,
  CREATE_WORD_ACTION_UUID,
  CREATE_EXCEL_ACTION_UUID,
  CREATE_POWERPOINT_ACTION_UUID,
] as const;
export type WellKnownShortcutUUID = (typeof WELL_KNOWN_SHORTCUT_UUIDS)[number];
