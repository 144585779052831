import { UIIcon } from '@dropbox/dig-icons';
import {
  GridViewLine,
  ListViewLine,
  OneClickNdaLine,
  UnorderedListLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { StackSortOption } from '@mirage/service-settings/service/types';
import { useStackByIdSortSettings } from '@mirage/service-settings/useStackByIdSortSettings';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import {
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackItemShortcutsAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { FullScreenStackPreset, SortIconMenu } from '../../SortIconMenu';
import { AddItemButton } from './AddItemButton';
import { SearchInStackMenu } from './SearchInStack';
import styles from './Section.module.css';

import type {
  SelectableStackSortPreference,
  StackSortPreference,
} from '@mirage/service-settings/service/types';

interface SectionsHeaderCardProps {
  namespaceId: string;
  publicPreview?: boolean;
  // this override flag is used to display the dfb august revision styles
  // when we want to render the new styles but can't feature gate users
  // ex. public view of the stacks page using (dfi styles, and we wanted to display dfb styles)
  alwaysUseAugustRevisionDfBStyles?: boolean;
}

export const SectionsHeaderCard = ({
  namespaceId,
  publicPreview = false,
  alwaysUseAugustRevisionDfBStyles,
}: SectionsHeaderCardProps) => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const augustRevision = Boolean(
    useStackPageAugustRevisionEnabled() || alwaysUseAugustRevisionDfBStyles,
  );
  const { sortPreference, setStackByIdSortPreference } =
    useStackByIdSortSettings(namespaceId);

  return (
    <TwoColumnGridCard
      settingId="stack_action_bar"
      cardTypeProps={{
        cardType: CardHeaderType.CUSTOM,
        customHeader: (
          <SectionsHeader
            augustRevision={augustRevision}
            publicPreview={publicPreview}
            sortPreference={sortPreference}
            setStackByIdSortPreference={setStackByIdSortPreference}
            searchBarOpen={searchBarOpen}
            setSearchBarOpen={setSearchBarOpen}
          />
        ),
      }}
      isAlwaysCollapsed={false}
      showDividerLine={false}
      className={classnames(styles.actionRow, {
        [styles.actionRowAugust]: augustRevision,
      })}
    />
  );
};

export const SectionsHeader = ({
  augustRevision,
  publicPreview,
  sortPreference,
  setStackByIdSortPreference,
  searchBarOpen,
  setSearchBarOpen,
}: {
  augustRevision: boolean;
  publicPreview?: boolean;
  sortPreference?: StackSortPreference;
  setStackByIdSortPreference: (sortPreference: StackSortPreference) => void;
  searchBarOpen?: boolean;
  setSearchBarOpen: (searchBarOpen: boolean) => void;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const stack = useAtomValue(activeStackAtom);
  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);
  const { defaultSection } = useAtomValue(activeStackSectionsAtom);

  return (
    <>
      <div className={styles.actionWrapper}>
        <div
          className={classnames(styles.actionGroup, {
            [styles.actionGroupAugust]: augustRevision,
          })}
        >
          <SearchInStackMenu
            // Force re-render when stack changes
            key={stack?.namespace_id}
            items={items}
            onSearchBarChange={setSearchBarOpen}
            onOpenLink={() => {
              if (stack) {
                reportPapEvent(
                  PAP_Open_DashLink({
                    ...stackDerivePAPProps(stack),
                    featureLine: 'stacks',
                  }),
                );
              }
            }}
          />
          {!searchBarOpen && (
            <SortIconMenu
              augustRevision={augustRevision}
              sortPreference={sortPreference as SelectableStackSortPreference}
              onSortSelect={setStackByIdSortPreference}
              optionsPreset={FullScreenStackPreset}
              defaultOption={{
                option: StackSortOption.SECTION,
              }}
            />
          )}
        </div>
        {!augustRevision && (
          <>
            <div className={styles.actionGroup}>
              <IconButtonWithTooltip
                tooltipProps={{
                  title: i18n.t('section_management_label'),
                }}
                variant="transparent"
                disabled
              >
                <UIIcon src={UnorderedListLine} />
              </IconButtonWithTooltip>
            </div>
            <div className={styles.actionGroup}>
              <IconButtonWithTooltip
                tooltipProps={{
                  title: i18n.t('density_option_list_label'),
                }}
                variant="opacity"
                disabled
              >
                <UIIcon src={ListViewLine} />
              </IconButtonWithTooltip>
              <IconButtonWithTooltip
                tooltipProps={{
                  title: i18n.t('density_option_grid_label'),
                }}
                variant="transparent"
                disabled
              >
                <UIIcon src={GridViewLine} />
              </IconButtonWithTooltip>
            </div>
            <div className={styles.actionGroup}>
              <IconButtonWithTooltip
                tooltipProps={{
                  title: i18n.t('multiselect_option_label'),
                }}
                variant="transparent"
                disabled
              >
                <UIIcon src={OneClickNdaLine} />
              </IconButtonWithTooltip>
            </div>
          </>
        )}
      </div>
      {!augustRevision &&
        (publicPreview || (hasWritePermissions && defaultSection)) && (
          <AddItemButton
            align="right"
            variant="primary"
            section={defaultSection ?? undefined}
            // Emphasize add button when there are no stack items yet.
            emphasize={!publicPreview && (!items || items.length === 0)}
            publicPreview={publicPreview}
            largeVersion
          />
        )}
    </>
  );
};
