import { Toggle } from '@dropbox/dig-components/controls';
import { FormLabel } from '@dropbox/dig-components/form_row';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AppsLine,
  CalendarLine,
  CloseLine,
  CreateStackLine,
  FileHistoryLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Move_CustomizeLayoutModal } from '@mirage/analytics/events/types/move_customize_layout_modal';
import { PAP_Select_CustomizeLayoutModal } from '@mirage/analytics/events/types/select_customize_layout_modal';
import { DragDropList } from '@mirage/drag-and-drop/DragDropList';
import { DefaultCustomizableModuleId } from '@mirage/service-settings/service/customize';
import { DashSpinner } from '@mirage/shared/dash-spinner';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import cloneShallow from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './CustomizeHomePageModal.module.css';
import { useCustomizableModules } from './useCustomizableModules';

import type { DragDropListItem } from '@mirage/drag-and-drop/DragDropList';
import type {
  CustomizableModule,
  CustomizableModuleIcon,
  CustomizeIconSvgName,
} from '@mirage/service-settings/service/customize';
import type { CustomizableModulesInternalSettings } from '@mirage/service-settings/useCustomizableModuleSettings';
import type { CSSProperties } from 'react';

function getPapModuleType(moduleId: string) {
  switch (moduleId) {
    case DefaultCustomizableModuleId.NEW_STACK_SUGGESTIONS:
      return 'new_stack_suggestions';
    case DefaultCustomizableModuleId.CALENDAR:
      return 'calendar';
    case DefaultCustomizableModuleId.RECENT_CONTENT:
      return 'recent_content';
    default:
      return moduleId;
  }
}

type Props = {
  open: boolean;
  onClose: () => void;
  internalSettings: CustomizableModulesInternalSettings;
};

export const CustomizeHomePageModal: React.FC<Props> = ({
  open,
  onClose,
  internalSettings,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  const {
    settingsLoaded,
    isLoadingConnectors,
    isConnectorsCached,
    modules,
    setModules,
    modulesById,
  } = useCustomizableModules(internalSettings);

  const onToggleModule = useCallback(
    (module: CustomizableModule) => {
      const newModules = cloneShallow(modules);

      for (let i = 0; i < newModules.length; i++) {
        let m = newModules[i];
        if (m.id === module.id && m.canHide) {
          m = newModules[i] = cloneDeep(m);
          m.isHidden = !m.isHidden;
          break;
        }
      }

      reportPapEvent(
        PAP_Select_CustomizeLayoutModal({
          featureLine: 'startpage_customization',
          customizeLayoutModalType: getPapModuleType(module.id),
          customizeLayoutModalStatus: module.isHidden ? 'on' : 'off',
        }),
      );

      setModules(newModules);
    },
    [modules, reportPapEvent, setModules],
  );

  const moduleRows = useMemo(
    () =>
      modules.map((module) => {
        return {
          id: module.id,
          key: module.id,
          render: (isDragging: boolean) => {
            return (
              <ModuleRow
                key={module.id}
                module={module}
                isEnabled={!module.isHidden}
                isDragging={isDragging}
                onToggle={() => onToggleModule(module)}
              />
            );
          },
        };
      }),
    [modules, onToggleModule],
  );

  const onDrop = useCallback(
    (newItems: DragDropListItem[], fromIndex: number) => {
      const newModules = newItems.map((item) => modulesById[item.id]);

      const from = modules[fromIndex];

      setModules(newModules);

      reportPapEvent(
        PAP_Move_CustomizeLayoutModal({
          featureLine: 'startpage_customization',
          customizeLayoutModalType: getPapModuleType(from.id),
          customizeLayoutModalStatus: from.isHidden ? 'off' : 'on',
        }),
      );
    },
    [modules, modulesById, reportPapEvent, setModules],
  );

  // Wait a bit for the data to load first, so that we don't flicker.
  // It is a bit slow (maybe ~50ms), but better than flickering the UI.
  if (!settingsLoaded) {
    return null;
  }

  return (
    <Modal
      open={open}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      isCentered={true}
      shouldFocusAfterRender={true}
      width="small"
    >
      <Modal.Body className={styles.modalBody}>
        <div className={styles.titleRow}>
          <Title className={styles.title} weightVariant="emphasized">
            {i18n.t('customize_modal_title')}
          </Title>
          <div className={styles.closeButtonWrapper}>
            <IconButtonWithTooltip
              tooltipProps={{
                title: i18n.t('close'),
              }}
              variant="outline"
              onClick={onClose}
            >
              <UIIcon src={CloseLine} />
            </IconButtonWithTooltip>
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.configRows}>
          <DragDropList
            droppableId="configModules"
            items={moduleRows}
            alwaysUpdateItems
            getItemContainerProps={(isDragging) =>
              isDragging
                ? {
                    className: styles.isDragging,
                  }
                : undefined
            }
            onDrop={onDrop}
          />
          {isLoadingConnectors && !isConnectorsCached && (
            <div className={classnames(styles.configRow, styles.loading)}>
              <DashSpinner size={30} />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ModuleRow: React.FC<{
  module: CustomizableModule;
  isEnabled: boolean;
  isDragging: boolean;
  onToggle: () => void;
}> = ({ module, isEnabled, isDragging, onToggle }) => {
  const [dragStyle, setDragStyle] = useState<CSSProperties>();

  useEffect(() => {
    if (isDragging) {
      const degrees = Math.random() * 4 - 2; // -2 to +2
      setDragStyle({
        transform: `rotate(${degrees}deg)`,
        border: '1px solid var(--dig-color__primary__base)',
      });
    } else {
      setDragStyle(undefined);
    }
  }, [isDragging]);

  return (
    <div className={styles.configRow} style={dragStyle}>
      <ModuleIcon icon={module.icon} title={module.title} />

      <div className={styles.configLabel}>
        <Text size="small">{module.title}</Text>
        <Text color="faint" size="xsmall">
          {module.description}
        </Text>
      </div>

      <div className={styles.toggleWrapper}>
        <FormLabel
          className={classnames(
            styles.toggleLabel,
            module.canHide || styles.disabled,
          )}
          htmlFor={module.id}
        >
          {isEnabled ? i18n.t('on') : i18n.t('off')}
        </FormLabel>
        <Toggle
          id={module.id}
          aria-label={module.canHide ? i18n.t('toggle_setting') : undefined}
          disabled={module.canHide ? undefined : true}
          checked={isEnabled}
          onChange={onToggle}
        />
      </div>
    </div>
  );
};

const ModuleIcon: React.FC<{
  icon: CustomizableModuleIcon | undefined;
  title: string;
}> = ({ icon, title }) => {
  if (icon === undefined) {
    return <UIIcon src={AppsLine} size="large" className={styles.icon} />;
  }

  if (icon.type === 'url') {
    return <img src={icon.url} alt={title} className={styles.icon} />;
  }

  return (
    <UIIcon
      src={getIconSvg(icon.svgName)}
      size="large"
      className={styles.icon}
    />
  );
};

function getIconSvg(svgName: CustomizeIconSvgName) {
  // Using a variable to prevent the compiler from warning us of unreachable
  // code `return AppsLine;` in `default:`.
  let svg = AppsLine;

  switch (svgName) {
    case 'CalendarLine':
      svg = CalendarLine;
      break;
    case 'CreateStackLine':
      svg = CreateStackLine;
      break;
    case 'FileHistoryLine':
      svg = FileHistoryLine;
      break;
    default:
      // Check that we have covered all values, but don't throw error.
      svgName satisfies never;
  }

  return svg;
}
