import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import { connectionsByTypeAtom } from '@mirage/shared/atoms/connections';
import {
  getConnectionMetadata,
  isUIConnection,
} from '@mirage/shared/connectors/util';
import { useDoOnceOnMount } from '@mirage/shared/hooks/useDoOnceOnMount';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { OnboardingConnectorsV2 } from '../flows/OnboardingConnectorsV2';
import { useOnboardingSurveys } from '../hooks/useOnboardingSurveys';
import { useSelfServeOnboardingState } from '../hooks/useSelfServeOnboardingState';
import { useSuggestedConnectors } from '../hooks/useSuggestedConnectors';
import { AddConnectionsModal } from './AddConnectionsModal';
import { ConnectorsModal } from './ConnectorsModal';
import styles from './OnboardingConnectorsStep.module.css';

import type { ProfilingQuestionKindOfWorkAnswer } from '@mirage/service-onboarding-surveys/service/onboarding-surveys/self-serve-profiling';

type Props = {
  isAdmin: boolean;
  onContinue: () => void;
  onValidationChange: (valid: boolean) => void;
};

const MINIMUM_CONNECTIONS = 1;

export const OnboardingConnectorsStep = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ isAdmin, onContinue, onValidationChange }: Props, ref) => {
    const { reportPapEvent } = useMirageAnalyticsContext();
    const [displayAppsModal, setDisplayAppsModal] = useState(false);
    const [displayAddAppsModal, setDisplayAddAppsModal] = useState(false);
    const connectionsByType = useAtomValue(connectionsByTypeAtom);

    const surveyName = useMemo(
      () => (isAdmin ? 'ADMIN_PROFILING_V1' : 'TEAM_MEMBER_PROFILING_V1'),
      [isAdmin],
    );

    useDoOnceOnMount(() => {
      reportPapEvent(
        PAP_View_DashSelfServeOnboarding({
          selfServeIsTeamAdmin: isAdmin,
          onboardingStep: 'create_connectors',
          featureLine: 'onboarding',
        }),
      );
    });

    const { surveyResponses } = useOnboardingSurveys(surveyName);
    const { surveyResponses: surveyResponsesState } =
      useSelfServeOnboardingState();

    const surveyAnswersForSurveyNameState =
      surveyResponsesState.get(surveyName) || [];
    const answerForKindOfWorkState = surveyAnswersForSurveyNameState.find(
      (answer) => answer.surveyQuestionId === 'KIND_OF_WORK_V1',
    );

    const kindOfWorkAnswerFromState = answerForKindOfWorkState
      ? answerForKindOfWorkState.surveyAnswer
      : null;

    // If no answer is found in surveyResponsesState, fall back to surveyResponses.
    const surveyAnswersForSurveyName = surveyResponses || [];
    const answerForKindOfWork = surveyAnswersForSurveyName.find(
      (answer) => answer.surveyQuestionId === 'KIND_OF_WORK_V1',
    );

    const kindOfWorkAnswer =
      kindOfWorkAnswerFromState || answerForKindOfWork?.surveyAnswer;

    const { suggestedConnectors, connectors, allConnectors } =
      useSuggestedConnectors(
        kindOfWorkAnswer as ProfilingQuestionKindOfWorkAnswer,
      );

    useEffect(() => {
      onValidationChange(true);
    }, [isAdmin, reportPapEvent, onValidationChange]);

    const handleShowAllApps = useCallback(() => {
      setDisplayAppsModal(true);
    }, []);

    const handleAppsModalClose = useCallback(
      (skip: boolean) => {
        setDisplayAddAppsModal(false);
        if (skip) {
          onContinue();
        }
      },
      [onContinue],
    );

    const handleSubmitSurvey = useCallback(() => {
      const connections = connectors.filter((connectorOrConnection) => {
        const isConnection = isUIConnection(connectorOrConnection);
        if (!isConnection) return false;
        const { type: connectionType } = getConnectionMetadata(
          connectorOrConnection,
        );
        if (!connectionType) return false;
        const connected = !!connectionsByType[connectionType];
        return connected;
      });

      // If there is at least one connection, we consider the survey as completed.
      if (connections.length >= MINIMUM_CONNECTIONS) {
        return true;
      }
      setDisplayAddAppsModal(true);
      return false;
    }, [connectionsByType, connectors]);

    useImperativeHandle(ref, () => ({
      submitSurvey: () => handleSubmitSurvey(),
    }));

    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          className={styles.connectorsContainer}
        >
          {connectors?.length > 0 && (
            <OnboardingConnectorsV2
              title={i18n.t('onboarding_connectors_section_added')}
              subheader={i18n.t(
                'onboarding_connectors_section_added_select_to_remove',
              )}
              connectors={connectors}
              browserExtensionData={[]}
              showSnackbar={showSnackbar}
              showConnectorDescription
            />
          )}
          <OnboardingConnectorsV2
            title={i18n.t('onboarding_connectors_section_suggested')}
            connectors={suggestedConnectors}
            browserExtensionData={[]}
            showSnackbar={showSnackbar}
            showShowAllApps
            onShowAllAppsClick={handleShowAllApps}
          />
        </Box>
        <ConnectorsModal
          connectors={allConnectors}
          open={displayAppsModal}
          onClose={() => setDisplayAppsModal(false)}
        />
        <AddConnectionsModal
          open={displayAddAppsModal}
          onClose={handleAppsModalClose}
        />
      </>
    );
  },
);

OnboardingConnectorsStep.displayName = 'OnboardingConnectorsStep';
