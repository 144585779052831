import { Button } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  EditLine,
  PersonHidePictogram,
  ShowLine,
} from '@dropbox/dig-icons/assets';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { UnthemedWrapper } from '../UnthemedWrapper';
import styles from './ShareModal.module.css';
import { StackPermission } from './Types';
import { textForPermission } from './Utils';

import type { SharingStackPermission } from './Types';
import type { ButtonProps } from '@dropbox/dig-components/buttons';

export const POSSIBLE_SHARING_PERMISSIONS: SharingStackPermission[] = [
  StackPermission.WRITE,
  StackPermission.READ,
];

export const PermissionsMenu = ({
  sharePermission: sharePermission,
  onSelectSharePermissions,
  buttonProps,
  withFaintStyle = false,
  isLocalUserMenu = false,
  onRevoke,
  dfb = false,
}: {
  sharePermission: SharingStackPermission;
  onSelectSharePermissions: (permissions: SharingStackPermission) => void;
  buttonProps: ButtonProps;
  withFaintStyle?: boolean;
  // If the menu is controlling the local user
  isLocalUserMenu?: boolean;
  onRevoke?: () => void;
  dfb?: boolean;
}) => {
  return (
    <UnthemedWrapper>
      <Menu.Wrapper
        onSelection={(permissions: SharingStackPermission | 'revoke') => {
          if (permissions === 'revoke') {
            onRevoke?.();
          } else {
            onSelectSharePermissions(permissions);
          }
        }}
      >
        {({ getContentProps, getTriggerProps }) => (
          <>
            <Button
              {...getTriggerProps()}
              size="large"
              withDropdownIcon
              {...buttonProps}
              className={classNames(
                { [styles.faintText]: withFaintStyle },
                buttonProps.className,
              )}
            >
              <Text
                size={withFaintStyle ? 'small' : undefined}
                isBold={withFaintStyle && !dfb}
                className={
                  withFaintStyle ? styles.faintText : styles.standardText
                }
              >
                {textForPermission(sharePermission)}
              </Text>
            </Button>
            <Menu.Content
              {...getContentProps()}
              placement="bottom-end"
              className={classNames({ [styles.permissionMenu]: dfb })}
            >
              {!isLocalUserMenu && (
                <Menu.Segment>
                  {POSSIBLE_SHARING_PERMISSIONS.map((permissions) => (
                    <Menu.SelectItem
                      data-testid={`${permissions}-menuPermissions`}
                      key={permissions}
                      value={permissions}
                      selected={sharePermission === permissions}
                      withRightAccessory={
                        dfb ? (
                          <NewPermissionIcon permission={permissions} />
                        ) : undefined
                      }
                    >
                      <div
                        // I don't think this affects anything but I'm leaving it in for now
                        className={classNames({ [styles.permissionRow]: !dfb })}
                      >
                        {textForPermission(permissions)}
                      </div>
                    </Menu.SelectItem>
                  ))}
                </Menu.Segment>
              )}
              {onRevoke && (
                <Menu.Segment>
                  <Menu.SelectItem
                    data-testid="revoke"
                    key="revoke"
                    value="revoke"
                    selected={false}
                    withLeftAccessory={<UIIcon src={PersonHidePictogram} />}
                  >
                    {i18n.t(isLocalUserMenu ? 'leave' : 'remove')}
                  </Menu.SelectItem>
                </Menu.Segment>
              )}
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    </UnthemedWrapper>
  );
};

export const NewPermissionIcon = ({
  permission,
  withFaintStyle = false,
}: {
  permission: StackPermission;
  withFaintStyle?: boolean;
}) => {
  switch (permission) {
    case StackPermission.OWNER:
      return (
        <Text
          size={withFaintStyle ? 'small' : undefined}
          isBold={withFaintStyle}
          className={withFaintStyle ? styles.faintText : styles.standardText}
        >
          <UIIcon src={EditLine} />
        </Text>
      );
    case StackPermission.WRITE:
      return <UIIcon src={EditLine} />;
    case StackPermission.READ:
      return <UIIcon src={ShowLine} />;
  }
};
