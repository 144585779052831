import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { ChevronDownLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useVariableLineHeight } from '@mirage/shared/hooks/useVariableLineHeight';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './Markdown.module.css';

interface MarkdownProps {
  body: string;
  truncateLine?: number;
  onToggleExpand?: () => void;
}

// don't allow images in markdown.
const disallowedElements = ['img'];

export const Markdown: React.FC<MarkdownProps> = ({
  body,
  truncateLine = 0,
  onToggleExpand,
}) => {
  const isMobileSize = useIsMobileSize();
  const [isExpanded, setIsExpanded] = useState(!truncateLine);
  const [isHovering, setIsHovering] = useState(false);
  const { ref, overflowing } = useVariableLineHeight(truncateLine, body);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    onToggleExpand?.();
  };

  const showInCollapsedState = !!truncateLine && overflowing && !isExpanded;

  const lineClamp = truncateLine + (isHovering ? ADD_LINES_ON_HOVER : 0);
  return (
    <div
      className={styles.markdownRoot}
      {...(showInCollapsedState && {
        onMouseEnter: () => setIsHovering(true),
        onMouseLeave: () => setIsHovering(false),
      })}
    >
      <div
        style={
          showInCollapsedState
            ? {
                maxHeight: `calc(1.5em * ${lineClamp})`,
                lineClamp,
              }
            : undefined
        }
        className={classnames(styles.markdownContainer, {
          [styles.markdownContainerCollapsed]: showInCollapsedState,
        })}
      >
        <Text size="medium" ref={ref}>
          <ReactMarkdown disallowedElements={disallowedElements}>
            {body}
          </ReactMarkdown>
        </Text>
      </div>
      {showInCollapsedState && (isHovering || isMobileSize) && (
        <div className={styles.showMoreContainer}>
          <Button
            variant="filled"
            onClick={toggleExpand}
            withIconStart={<UIIcon src={ChevronDownLine} />}
            size="small"
          >
            {i18n.t('show_more_answer')}
          </Button>
        </div>
      )}
    </div>
  );
};

export function MarkdownViewerWithoutTruncate({ body }: MarkdownProps) {
  return (
    <Text size="medium">
      <ReactMarkdown disallowedElements={disallowedElements}>
        {body}
      </ReactMarkdown>
    </Text>
  );
}

const ADD_LINES_ON_HOVER = 2;
