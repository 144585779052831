import { tagged } from '@mirage/service-logging';
import {
  getUserMetadata,
  setUserMetadata,
} from '@mirage/service-user-metadata';
import Sentry from '@mirage/shared/sentry';

import type { StackComment } from '../types';
import type { comments2 } from '@dropbox/api-v2-client';

const USER_SEEN_COMMENT_PROMPT_METADATA = 'DASH_SEEN_STACKS_COMMENTING_PROMPT';

export class DuplicateCommentError extends Error {
  constructor() {
    super('Cannot post more than one comment to a stack item.');
    this.name = 'DuplicateCommentError';
  }
}

export const createStackStream = (stackSharingId: string): comments2.Stream => {
  return {
    identifier: {
      '.tag': 'stack_sharing_id',
      stack_sharing_id: stackSharingId,
    },
    type: {
      '.tag': 'stack',
    },
  };
};

export const createStackMetadataComment = (
  stackItemId: string,
  content: string,
): comments2.UserSubmittedComment => {
  return {
    content,
    metadata: {
      formatting: [],
      mentions: [],
      stack: {
        stack_item_id: stackItemId,
      },
    },
  };
};

export const denormalizeStackComments = (
  threads: comments2.Thread[],
  users: comments2.User[],
): StackComment[] => {
  const userDict = users.reduce(
    (map, user) => {
      map[user.id] = user;
      return map;
    },
    {} as { [id: string]: comments2.User },
  );

  const filteredThreads = threads.filter((thread) => !thread.deleted);

  return filteredThreads.map((thread) => {
    // For now, Stacks only suppport a single comment per thread
    const comment = thread.comments[0];
    const author = userDict[comment.author_id];

    return {
      author: {
        displayName: author.display_name,
        initials: author.initials,
        isCurrentUser: !!author.is_current_user,
        photoUrl: author.photo_url,
      },
      content: comment.content,
      id: comment.id,
      // This will always exist in practice (enforced by the server), but is needed for typing
      linkId: comment.metadata.stack?.stack_item_id ?? '',
      permissions: {
        canDelete: comment.permissions.can_delete,
        canEdit: comment.permissions.can_edit,
      },
      read: thread.read,
      timestamp: new Date(comment.timestamp).getTime(),
    };
  });
};

export const getShouldShowCommentPrompt = async (): Promise<boolean> => {
  try {
    const metadata = await getUserMetadata([USER_SEEN_COMMENT_PROMPT_METADATA]);

    // Invert the property value because we track whether the user has seen the prompt
    return !JSON.parse(metadata[USER_SEEN_COMMENT_PROMPT_METADATA]);
  } catch (e) {
    // This is a non-critical operation. If we fail to fetch metadata, just return false
    return false;
  }
};

export const setCommentPromptSeen = async (): Promise<void> => {
  const logger = tagged('service-comments/setCommentPromptSeen');

  try {
    await setUserMetadata({
      [USER_SEEN_COMMENT_PROMPT_METADATA]: JSON.stringify(true),
    });
  } catch (e) {
    // This is a non-critical operation, but we log it so we can track potential issues
    const message = 'Failed to set comment prompt seen metadata';

    logger.info(message);
    Sentry.captureMessage(message, 'info');
  }
};
