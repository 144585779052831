import {
  ProfilingQuestionIndustryAnswer,
  ProfilingQuestionKindOfWorkAnswer,
} from '@mirage/service-onboarding-surveys/service/onboarding-surveys/self-serve-profiling';
import { RoutePath } from '@mirage/webapp/routeTypes';

export type DemoPromptType = 'summarize' | 'analyze' | 'brainstorm';

export const isDemoPromptType = (value: string): value is DemoPromptType => {
  return ['summarize', 'analyze', 'brainstorm'].includes(value);
};

// `DemoFileAndPrompt` represents url param value for a file and a prompt to be used in onboarding demo
type DemoFileAndPrompt = {
  fileName: DemoFileName;
  prompt: DemoPromptType;
};

export type DemoFileInfo = {
  // empty `url` indicates that we do not have pdf file in CDN yet. This is a temporary state while we iterate and finalize demo contents
  url: string;
  title: string;
  // TODO: add thumbnail info to be used on start page
};

/**
 * This function returns 3 demo file and prompt pair (DemoFileAndPrompt) for the user where
 * we will select the best demo file for each prompt type based on user's role and industry
 *
 * @param industry - The industry of the user based on their survey answers
 * @param role - The role of the user based on their survey answers
 * @returns 3 demo file and prompt pair (DemoFileAndPrompt) for the user
 */
export const getOnboardingDemoFilesAndPromptsForUser = ({
  industry,
  role,
}: {
  industry?: ProfilingQuestionIndustryAnswer;
  role?: ProfilingQuestionKindOfWorkAnswer;
}): DemoFileAndPrompt[] => {
  const allPossibleDemoFilesAndPrompts: DemoFileAndPrompt[] = [];
  // First, we compile all demo files/prompts meant for role and industry + fallback files/prompts
  if (role) {
    allPossibleDemoFilesAndPrompts.push(...demoFileAndPromptForRole({ role }));
  }
  if (industry) {
    allPossibleDemoFilesAndPrompts.push(
      ...demoFileAndPromptForIndustry({ industry }),
    );
  }
  allPossibleDemoFilesAndPrompts.push(...fallbackDemoFileAndPrompt);

  // Find best match for each prompt type
  // Prioritize the ones that are for role and then industry and then fallback
  // As allPossibleDemoFilesAndPrompts array have DemoFileAndPrompt in priority order, we just need to find first match for each prompt type
  const finalDemoFilesAndPrompts: DemoFileAndPrompt[] = [];
  for (const prompt of ['summarize', 'analyze', 'brainstorm']) {
    const bestMatch = allPossibleDemoFilesAndPrompts.find((p) => {
      return p.prompt === prompt && allDemoFiles[p.fileName].url !== '';
    });
    if (bestMatch) {
      finalDemoFilesAndPrompts.push(bestMatch);
    }
  }

  return finalDemoFilesAndPrompts;
};

export enum DemoUrlParams {
  ONBOARDING = 'onboarding', // onboarding=true indicates that user is here for a demo
  DEMO = 'demo', // demo=fileName indicates the file to be used for the demo
  ACTION = 'action', // action=prompt indicates the prompt to be used for the demo
}

/**
 * This function returns the url for the demo file and prompt pair (DemoFileAndPrompt)
 * @param fileName - The name of the demo file
 * @param prompt - The prompt type
 * @returns The url for the demo file and prompt pair
 */
export const getDemoUrl = ({ fileName, prompt }: DemoFileAndPrompt) => {
  return `${RoutePath.CHAT}?${DemoUrlParams.ONBOARDING}=true&${DemoUrlParams.DEMO}=${fileName}&${DemoUrlParams.ACTION}=${prompt}`;
};

/**
 * This function returns the file info for the demo file
 * @param fileName - The name of the demo file
 * @returns The file info for the demo file
 */
export const getDemoFileInfo = (fileName: DemoFileName) => {
  return allDemoFiles[fileName];
};

/**
 * This function returns the prompt for the demo prompt
 * @param prompt - The prompt type
 * @returns The prompt for the demo prompt
 */
export const getDemoPrompt = (prompt: DemoPromptType) => {
  switch (prompt) {
    case 'summarize':
      return 'Provide a concise summary of this document, highlighting the key points, main insights, and any actionable takeaways.';
    case 'analyze':
      return 'Examine this content for trends, patterns, or key insights. Identify strengths, weaknesses, and any potential improvements based on the data or structure presented.';
    case 'brainstorm':
      return 'Generate new ideas, improvements, or alternative approaches based on the themes or objectives of this document. Consider innovative strategies, creative directions, or optimizations.';
    default:
      prompt satisfies never;
      throw new Error(`Unknown prompt type: ${prompt}`);
  }
};

/**
 * This function returns the demo file and prompt pair for the role if we have a demo file for the role
 * @param role - The role of the user
 * @returns The demo file and prompt pair for the role
 */
const demoFileAndPromptForRole = ({
  role,
}: {
  role: ProfilingQuestionKindOfWorkAnswer;
}): DemoFileAndPrompt[] => {
  switch (role) {
    case ProfilingQuestionKindOfWorkAnswer.Creative:
      return [
        { fileName: 'creative', prompt: 'summarize' },
        { fileName: 'film-script', prompt: 'brainstorm' },
        { fileName: 'brand-style', prompt: 'analyze' },
      ];
    case ProfilingQuestionKindOfWorkAnswer.EngineeringProductDesign:
      return [
        { fileName: 'prd', prompt: 'brainstorm' },
        { fileName: 'api-doc', prompt: 'summarize' },
        { fileName: 'usability-report', prompt: 'analyze' },
      ];
    case ProfilingQuestionKindOfWorkAnswer.Sales:
      return [
        { fileName: 'sales-playbook', prompt: 'summarize' },
        { fileName: 'sales-pipeline', prompt: 'analyze' },
        { fileName: 'cold-call', prompt: 'brainstorm' },
      ];
    case ProfilingQuestionKindOfWorkAnswer.Marketing:
      return [
        { fileName: 'customer-segmentation', prompt: 'summarize' },
        { fileName: 'ab-report', prompt: 'analyze' },
        { fileName: 'content-marketing', prompt: 'brainstorm' },
      ];
    default:
      return [];
  }
};

/**
 * This function returns the demo file and prompt pair for the industry if we have a demo file for the industry
 * @param industry - The industry of the user
 * @returns The demo file and prompt pair for the industry
 */
const demoFileAndPromptForIndustry = ({
  industry,
}: {
  industry: ProfilingQuestionIndustryAnswer;
}): DemoFileAndPrompt[] => {
  switch (industry) {
    case ProfilingQuestionIndustryAnswer.Education:
      return [
        { fileName: 'language-learning', prompt: 'summarize' },
        { fileName: 'lesson-plan', prompt: 'brainstorm' },
        { fileName: 'student-analysis', prompt: 'analyze' },
      ];
    case ProfilingQuestionIndustryAnswer.ArchitectureEngineeringConstruction:
      return [
        { fileName: 'built-in-blocks', prompt: 'brainstorm' },
        { fileName: 'portfolio', prompt: 'analyze' },
        { fileName: 'design-guidelines', prompt: 'summarize' },
      ];
    case ProfilingQuestionIndustryAnswer.MediaOrSports:
      return [
        { fileName: 'podcast', prompt: 'summarize' },
        { fileName: 'press-freedom', prompt: 'analyze' },
        { fileName: 'editorial-calendar', prompt: 'brainstorm' },
      ];
    case ProfilingQuestionIndustryAnswer.Technology:
      return [
        { fileName: 'deployment-checklist', prompt: 'analyze' },
        { fileName: 'conversational-ai', prompt: 'brainstorm' },
        { fileName: 'net-neutrality', prompt: 'summarize' },
      ];
  }
  return [];
};

/**
 * This is a fallback demo file and prompt pair that would work for any user
 * @returns The fallback demo file and prompt pair
 */
const fallbackDemoFileAndPrompt: DemoFileAndPrompt[] = [
  { fileName: 'meeting-notes', prompt: 'summarize' },
  { fileName: 'nda', prompt: 'analyze' },
  { fileName: 'ai-at-work', prompt: 'brainstorm' },
];

export const isDemoFileName = (value: string): value is DemoFileName => {
  return Object.keys(allDemoFiles).includes(value);
};

export type DemoFileName =
  | 'ai-report'
  | 'meeting-notes'
  | 'nda'
  | 'ai-at-work'
  | 'prd'
  | 'api-doc'
  | 'usability-report'
  | 'customer-segmentation'
  | 'ab-report'
  | 'content-marketing'
  | 'sales-playbook'
  | 'sales-pipeline'
  | 'cold-call'
  | 'creative'
  | 'film-script'
  | 'brand-style'
  | 'language-learning'
  | 'lesson-plan'
  | 'student-analysis'
  | 'built-in-blocks'
  | 'portfolio'
  | 'design-guidelines'
  | 'podcast'
  | 'press-freedom'
  | 'editorial-calendar'
  | 'deployment-checklist'
  | 'conversational-ai'
  | 'net-neutrality';

const allDemoFiles: Record<DemoFileName, DemoFileInfo> = {
  // This is a demo file that is used to test the assistant while we have other files ready
  'ai-report': {
    url: 'https://cdn.prod.website-files.com/65dcd70b48edc3a7b446950e/67c6023920ef5e475f607355_ai-report.pdf',
    title: 'Artificial Intelligence and the Future of Teaching and Learning',
  },
  // fallback demo files
  'ai-at-work': {
    url: 'https://cdn.prod.website-files.com/65dcd70b48edc3a7b446950e/67d9ebf934374927a092511e_AI%20Usage%20in%20the%20Workplace%20Report.pdf',
    title: 'AI Usage at work Report',
  },
  'meeting-notes': {
    url: 'https://cdn.prod.website-files.com/65dcd70b48edc3a7b446950e/67db04a9729e348ac1fb3fe3_Meeting%20notes.pdf',
    title: 'Meeting Notes',
  },
  nda: {
    url: 'https://cdn.prod.website-files.com/65dcd70b48edc3a7b446950e/67db04f9443df418a3cb0208_non-disclosure-agreement-test-data.pdf',
    title: 'NDA',
  },
  /** by Role */
  // EPD
  prd: {
    url: '',
    title: 'PRD',
  },
  'api-doc': {
    url: '',
    title: 'API Documentation',
  },
  'usability-report': {
    url: '',
    title: 'Usability TestingReport',
  },
  // Marketing
  'customer-segmentation': {
    url: '',
    title: 'Customer Segmentation',
  },
  'ab-report': {
    url: '',
    title: 'A/B Report',
  },
  'content-marketing': {
    url: '',
    title: 'Content Marketing Strategy',
  },
  // Sales
  'sales-playbook': {
    url: '',
    title: 'Sales Playbook',
  },
  'sales-pipeline': {
    url: '',
    title: 'Sales Pipeline Report',
  },
  'cold-call': {
    url: '',
    title: 'Cold Call Scripts',
  },
  // Creative : TBD
  creative: {
    url: '',
    title: 'Creative Brief',
  },
  'film-script': {
    url: '',
    title: 'Film Script',
  },
  'brand-style': {
    url: '',
    title: 'Brand Style Guide',
  },
  /** by Industry */
  // Education
  'language-learning': {
    url: '',
    title: 'Blended Language Learning: Evidence-based Trends and Applications',
  },
  'lesson-plan': {
    url: '',
    title: 'Lesson Plan - Intro to AI',
  },
  'student-analysis': {
    url: '',
    title: 'Student Analysis Report',
  },
  // Architecture
  'built-in-blocks': {
    url: '',
    title: 'Built in Blocks - Project Proposal',
  },
  portfolio: {
    url: '',
    title: 'Portfolio Presentation',
  },
  'design-guidelines': {
    url: '',
    title: 'Design Guidelines',
  },
  // Media
  podcast: {
    url: '',
    title: 'Podcast Outline',
  },
  'press-freedom': {
    url: '',
    title: 'CaliforniaStudent Press Freedom Law',
  },
  'editorial-calendar': {
    url: '',
    title: 'Editorial Calendar',
  },
  // Technology
  'deployment-checklist': {
    url: '',
    title: 'Deployment Checklist',
  },
  'conversational-ai': {
    url: '',
    title: 'A Pragmatic Guide to Conversational AI',
  },
  'net-neutrality': {
    url: '',
    title: 'California Net Neutrality Law',
  },
};
