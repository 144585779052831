import { useCallback, useEffect } from 'react';

export const useChartKeyboardShortcuts = ({
  expanded,
  adjustZoom,
  resetZoom,
}: {
  expanded: boolean;
  adjustZoom: (zoom: number, source?: string) => void;
  resetZoom: (source?: string) => void;
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!expanded) {
        return;
      }

      if ((event.metaKey || event.ctrlKey) && event.key === '0') {
        event.preventDefault();
        resetZoom('keyboard');
      }

      if (
        (event.metaKey || event.ctrlKey) &&
        (event.key === '+' || event.key === '=')
      ) {
        event.preventDefault();
        adjustZoom(1.2, 'keyboard');
      }

      if ((event.metaKey || event.ctrlKey) && event.key === '-') {
        event.preventDefault();
        adjustZoom(0.8, 'keyboard');
      }
    },
    [adjustZoom, expanded, resetZoom],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
