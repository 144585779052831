import { scorePerson } from '@mirage/service-people/scoring/score-person';

import type { PeopleRecommendation } from '@mirage/service-dbx-api/service/people';
import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';

class PeopleRecommendationsCache implements MemoryCache<PeopleRecommendation> {
  private cache: PeopleRecommendation[] = [];

  all() {
    return this.cache;
  }

  addItems(
    items: PeopleRecommendation[] = [],
  ): Promise<PeopleRecommendation[]> {
    const sorted = presortPeople(items);
    this.cache = sorted;

    return Promise.resolve(this.cache);
  }

  removeItem(item: PeopleRecommendation): Promise<PeopleRecommendation[]> {
    this.cache = this.cache.filter(
      (cacheItem) => cacheItem.email != item.email,
    );
    return Promise.resolve(this.cache);
  }

  clear() {
    this.cache = [];
  }

  count() {
    return this.cache.length;
  }
}

export default PeopleRecommendationsCache;

/**
 * Used to sort the people recommendations returned from the server prior to storing them in
 * the cache so that we have a bias towards the top results when selecting the candidates
 * for search during typoMatch
 *
 * Reuses our existing scoring so it stays consistent with the search pipeline
 *
 * NOTE: Attention to scoring will exist in following ticket.
 */
function presortPeople(people: PeopleRecommendation[]): PeopleRecommendation[] {
  return people.sort((a, b) => scorePerson(b, '') - scorePerson(a, ''));
}
