import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CloseLine,
  DropboxLine,
  OpenLine,
  SearchLine,
} from '@dropbox/dig-icons/assets';
import { GlyphLogo } from '@dropbox/dig-logos';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { flyoutPanelViewAtom } from '@mirage/mosaics/FlyoutPanel/atoms';
import { useDashTeamLogoUrl } from '@mirage/mosaics/GlobalNav/hooks';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useDualMode } from '@mirage/service-experimentation/useDualMode';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { openPersistentApp } from '@mirage/service-platform-actions';
import { DragBar } from '@mirage/shared/drag-bar/DragBar/DragBar';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { forwardRef, useEffect, useState } from 'react';
import { ConnectedAppsIndicator } from './ConnectedAppsIndicator';
import { useTypeaheadAutocomplete } from './hooks/useTypeaheadAutocomplete';
import styles from './SearchHeader.module.css';
import { SearchInputPlaceholder } from './SearchInputPlaceholder';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { SearchFilter } from '@mirage/shared/search/search-filters';
import type { ChangeEvent, KeyboardEvent, MouseEvent, RefObject } from 'react';

const AUTOCOMPLETE_WIDTH_RATIO = 0.65;

type SearchHeaderProps = {
  query: string;
  onQueryUpdate: (query: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCloseTypeahead: () => void;
  onShouldBoostSearchToTop: (shouldBeBoosted: boolean) => void;
  onOpenConnectors: () => void;
  shouldFocusOnRender?: boolean;
  disabled?: boolean;
  focused?: boolean;
  typeaheadIsOpen?: boolean;
  fullWidth?: boolean;
  inputRef: RefObject<HTMLInputElement>;
  selectedIdx: number;
  searchResults: TypeaheadResult[] | null;
  shouldSearchBeBoosted: boolean;
  isDarwin?: boolean;
  isStartPage?: boolean;
  filters: SearchFilter[];
};

export const SearchHeader = forwardRef<HTMLDivElement, SearchHeaderProps>(
  (
    {
      query,
      onQueryUpdate,
      onClick,
      onFocus,
      onCloseTypeahead,
      onShouldBoostSearchToTop,
      onOpenConnectors,
      shouldFocusOnRender = false,
      disabled = false,
      focused = false,
      typeaheadIsOpen = false,
      fullWidth = false,
      inputRef,
      selectedIdx,
      searchResults,
      shouldSearchBeBoosted,
      isDarwin = true,
      isStartPage = false,
      filters = [],
    }: SearchHeaderProps,
    ref,
  ) => {
    const [active, setActive] = useState(shouldFocusOnRender && !disabled);
    // Temporary solution. Not all modals are handled via useModal() yet
    const isModalOpen = document.getElementsByClassName('dig-Modal').length > 0;
    const [placeholderWidth, setPlaceholderWidth] = useState<number>();
    const isDesktop = EnvCtx.surface === 'desktop';
    const isMobileSize = useIsMobileSize();
    const dashTeamLogoUrl = useDashTeamLogoUrl();
    const filtersActive = filters.length > 0;
    const isFlyoutPanelOpen = useAtomValue(flyoutPanelViewAtom);
    const { isDualModeLauncher } = useDualMode();
    const desktopHomePageEnabled = convertFeatureValueToBool(
      useFeatureFlagValue('dash_2025_01_24_desktop_home_page'),
    );

    // OSE-4908 - Proxy for service-usage of flag value to track Growthbook experiment
    // This hook reports the Growthbook PAP exposure event
    useFeatureFlagValue(
      'dash_typeahead_2024_06_05_suggested_query_score',
      true,
    );

    const {
      inputValue,
      onSearchInputKeyDown,
      inlineCTAText,
      suffixPosition,
      shouldAutocomplete,
      fakeAutocompleteText,
      selectedIcon,
      coverCTA,
    } = useTypeaheadAutocomplete(
      query,
      searchResults,
      selectedIdx,
      inputRef,
      typeaheadIsOpen,
      shouldSearchBeBoosted,
      onShouldBoostSearchToTop,
      filters,
    );

    const handleResize = () => {
      if (!inputRef.current) return;
      setPlaceholderWidth(
        inputRef.current.clientWidth * AUTOCOMPLETE_WIDTH_RATIO,
      );
    };
    useResizeObserver(inputRef, handleResize);

    useEffect(() => {
      function handleVisibilityChange() {
        if (!inputRef.current) return;
        if (document.visibilityState !== 'visible') {
          inputRef.current?.blur();
          return;
        }
        inputRef.current.select();
      }

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () =>
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
    }, [inputRef]);

    useEffect(() => {
      shouldFocusOnRender && inputRef.current?.focus();
    }, [inputRef, shouldFocusOnRender]);

    /**
     * Form Handlers
     */

    const handleFocus = () => {
      onFocus?.();
    };

    const handleKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
      onSearchInputKeyDown(event);
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
      onQueryUpdate(event.target.value);
    };

    const handleClearSearch = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onQueryUpdate('');
      inputRef.current?.focus();

      if (isMobileSize) {
        onCloseTypeahead();
      }
    };

    const handleCloseTypeahead = (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      inputRef.current?.blur();
      onCloseTypeahead();
    };
    /**
     * Render
     */
    return (
      <div
        className={classNames(styles.searchHeaderContainer, {
          [styles.active]: active || typeaheadIsOpen,
          [styles.extendBottom]: typeaheadIsOpen,
          [styles.fullWidthSearchbar]: fullWidth,
          [styles.typeaheadIsOpen]: typeaheadIsOpen,
          [styles.isDualModeLauncher]: isDualModeLauncher,
        })}
        ref={ref}
        onMouseEnter={() => !disabled && setActive(true)}
        onMouseLeave={() => !disabled && setActive(false)}
        onMouseOver={() => !disabled && setActive(true)}
        onFocus={() => void 0}
      >
        {EnvCtx.platform === 'darwin' && EnvCtx.surface === 'desktop' && (
          <DragBar
            height={20}
            // 78px is the gap to clear the "close" and "info" buttons to the left of the scrollbar
            roomForScroll={isFlyoutPanelOpen === 'closed' ? 0 : 78}
          />
        )}
        <label
          className={classNames(styles.searchBarContainer, {
            [styles.disabled]: disabled,
            [styles.extendBottom]: typeaheadIsOpen,
            [styles.focused]: focused,
            [styles.fullWidthSearchbar]: fullWidth,
          })}
          htmlFor="search-bar"
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        >
          <div
            className={classNames(styles.searchIconContainer, {
              [styles.fullWidthSearchbar]: fullWidth,
            })}
          >
            {selectedIcon && query.length > 0 ? (
              selectedIcon
            ) : fullWidth &&
              (!isDesktop || !desktopHomePageEnabled || isDualModeLauncher) ? (
              dashTeamLogoUrl ? (
                <div className={styles.teamDashLogoContainer}>
                  <img
                    src={dashTeamLogoUrl}
                    className={styles.teamDashLogo}
                    alt={i18n.t('company_logo_alt')}
                  />
                </div>
              ) : (
                <GlyphLogo
                  src={DropboxLine}
                  aria-label={i18n.t('icon_for_dropbox')}
                  size={56}
                  style={{ height: '49px', width: '49px' }}
                  color="var(--dig-color__text__base)"
                />
              )
            ) : (
              <UIIcon
                src={SearchLine}
                size={isDesktop && desktopHomePageEnabled ? 'large' : 'medium'}
                color={
                  disabled
                    ? 'var(--dig-color__disabled__base)'
                    : 'var(--dig-color__text__subtle)'
                }
              />
            )}
          </div>
          <div
            className={classNames(styles.searchInputContainer, {
              [styles.fullWidthSearchbar]: fullWidth,
            })}
          >
            <input
              className={classNames(styles.searchInputEl, {
                [styles.disabled]: disabled,
                [styles.fullWidthSearchbar]: fullWidth,
                [styles.searchInputElHiddenText]: coverCTA,
                [styles.showDefaultPlaceholder]: filtersActive,
              })}
              ref={inputRef}
              value={inputValue}
              type="text"
              id="search-bar"
              onInput={handleSearchChange}
              onKeyDown={handleKeydown}
              onFocus={handleFocus}
              onClick={onClick}
              placeholder={i18n.t('search_prompt')}
              disabled={isModalOpen || disabled}
              autoComplete="off"
              tabIndex={isModalOpen || !isStartPage ? undefined : 1}
              data-uxa-log={createUxaElementId('typeahead_input', {
                actionSurface: 'search_typeahead',
                actionSurfaceComponent: 'search_bar',
                featureLine: 'search',
              })}
              data-uxa-interactions="click change"
              aria-live="polite"
              aria-controls="search-results"
              aria-expanded={typeaheadIsOpen}
              aria-autocomplete="list"
              role="combobox"
              aria-haspopup="listbox"
              aria-activedescendant={
                typeaheadIsOpen && selectedIdx !== null
                  ? `typeahead-option-${selectedIdx}`
                  : undefined
              }
            />
            {inlineCTAText && suffixPosition != null ? (
              <span
                className={classNames(styles.suffixElement, {
                  [styles.fullWidthSearchbar]: fullWidth,
                  [styles.autocompleteHighlight]: shouldAutocomplete,
                })}
                style={{ left: `${suffixPosition}px` }}
              >
                <span
                  className={classNames(
                    styles.fakeAutocomplete,
                    styles.searchInputEl,
                    {
                      [styles.disabled]: disabled,
                      [styles.fullWidthSearchbar]: fullWidth,
                      [styles.autocompleteHighlight]: shouldAutocomplete,
                    },
                  )}
                  style={{
                    maxWidth: placeholderWidth
                      ? `${placeholderWidth}px`
                      : undefined,
                  }}
                >
                  {coverCTA && fakeAutocompleteText}
                </span>
                {inlineCTAText}
              </span>
            ) : null}

            <SearchInputPlaceholder
              inputRef={inputRef}
              fullWidth={fullWidth}
              isDarwin={isDarwin}
              filtersActive={filtersActive}
            />
          </div>

          <div className={styles.searchActionsContainer}>
            {query.length === 0 && !typeaheadIsOpen && (
              <ConnectedAppsIndicator onOpenConnectors={onOpenConnectors} />
            )}
            {query.length === 0 &&
              typeaheadIsOpen &&
              (isDualModeLauncher ? (
                <DigTooltip
                  title={i18n.t('open_persistent_app')}
                  placement="bottom-end"
                  isPortaled={true}
                >
                  <IconButton
                    aria-label={i18n.t('open_persistent_app')}
                    variant="borderless"
                    onClick={() => openPersistentApp('launcher_button')}
                  >
                    <UIIcon src={OpenLine} />
                  </IconButton>
                </DigTooltip>
              ) : (
                <DigTooltip
                  title={i18n.t('close')}
                  placement="bottom-end"
                  isPortaled={true}
                >
                  <IconButton
                    aria-label={i18n.t('close')}
                    variant="borderless"
                    onClick={handleCloseTypeahead}
                    type="reset"
                    style={{ borderRadius: '4px' }}
                  >
                    <UIIcon src={CloseLine} />
                  </IconButton>
                </DigTooltip>
              ))}

            {query && !disabled && (
              <DigTooltip
                title={i18n.t('clear_search')}
                placement="bottom-end"
                isPortaled={true}
              >
                <IconButton
                  aria-label={i18n.t('clear_search')}
                  variant="borderless"
                  onClick={handleClearSearch}
                  type="reset"
                  style={{ borderRadius: '4px' }}
                  data-uxa-log={createUxaElementId('clear_button', {
                    actionSurface: 'search_typeahead',
                    actionSurfaceComponent: 'search_bar',
                    featureLine: 'search',
                  })}
                >
                  <UIIcon src={CloseLine} />
                </IconButton>
              </DigTooltip>
            )}
          </div>
        </label>
      </div>
    );
  },
);

SearchHeader.displayName = 'SearchHeader';
