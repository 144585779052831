import { IconButton } from '@dropbox/dig-components/buttons';
import { forwardRef } from 'react';
import { BaseButtonWithTooltip } from '../util/BaseButtonWithTooltip';

import type {
  BaseButtonWithTooltipProps,
  CoreTooltipProps,
} from '../util/BaseButtonWithTooltip';
import type { IconButtonProps } from '@dropbox/dig-components/buttons';
import type { Ref } from 'react';

export type { CoreTooltipProps };

// Prevent "Warning: Function components cannot be given refs. Attempts to
// access this ref will fail. Did you mean to use React.forwardRef()?" by
// using `forwardRef`.
export const IconButtonWithTooltip = forwardRef(
  (
    {
      tooltipProps,
      ...props
    }: {
      tooltipProps: CoreTooltipProps;
      enableOnMobileSize?: BaseButtonWithTooltipProps['enableOnMobileSize'];
    } & IconButtonProps,
    ref?: Ref<HTMLDivElement>,
  ) => {
    return (
      <BaseButtonWithTooltip
        Component={IconButton}
        ref={ref}
        {...props}
        tooltipProps={tooltipProps}
      />
    );
  },
);
IconButtonWithTooltip.displayName = 'IconButtonWithTooltip';
