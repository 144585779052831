import { Button } from '@dropbox/dig-components/buttons';
import i18n from '@mirage/translations';
import React from 'react';

interface ExpandProps {
  isExpanded: boolean;
  setIsExpanded: (expanded: (prev: boolean) => boolean) => void;
}

export const Expand: React.FC<ExpandProps> = ({
  isExpanded,
  setIsExpanded,
}) => {
  return (
    <div>
      <Button
        size="small"
        variant="transparent"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {isExpanded ? i18n.t('see_less') : i18n.t('see_more')}
      </Button>
    </div>
  );
};
