import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';

import type { FetchedAtom } from './types';
import type { UIConnection } from '@mirage/shared/types';

// The atom is exported for use in tests.
// Prefer importing useConnections and useSetConnections instead of this atom.
export const connectionsAtom = atom<FetchedAtom<UIConnection[]>>({
  data: [] as UIConnection[],
  loaded: false,
  loading: false,
});

export const useConnections = () => useAtomValue(connectionsAtom);

export const useSetConnections = () => useSetAtom(connectionsAtom);

// Connections sorted by connector name and user account.
export const sortedConnectionsAtom = atom((get) => {
  const { data: connections } = get(connectionsAtom);

  if (!connections) return [];

  return connections.sort((a, b) => {
    const aName = a.branding?.display_name?.toLowerCase();
    const bName = b.branding?.display_name?.toLowerCase();
    if (!aName || !bName) return 0;

    const nameComparison = aName.localeCompare(bName);
    if (nameComparison !== 0) return nameComparison;

    const aAccountId = a.metadata?.display_identifier?.toLowerCase();
    const bAccountId = b.metadata?.display_identifier?.toLowerCase();
    if (!aAccountId || !bAccountId) return 0;

    return aAccountId?.localeCompare(bAccountId);
  });
});

export const connectionsByTypeAtom = atom<Record<string, UIConnection>>(
  (get) => {
    const connections = get(connectionsAtom);

    if (!connections.loaded) return {};

    return connections.data.reduce((acc: Record<string, UIConnection>, c) => {
      if (!c.id_attributes?.connector?.type) return acc;
      return {
        ...acc,
        [c.id_attributes.connector.type]: c,
      };
    }, {});
  },
);

export const useSetConnection = () => {
  const [connections, setConnections] = useAtom(connectionsAtom);

  return (id: string, updatedConnetion: UIConnection) => {
    if (!connections.loaded) throw new Error('connections not loaded');

    setConnections((atom) => {
      const updatedConnections = atom.data.map((c) =>
        c.id_attributes?.id === id ? updatedConnetion : c,
      );

      return {
        ...atom,
        data: updatedConnections,
      };
    });
  };
};

export const useRemoveConnection = () => {
  const setConnections = useSetConnections();

  return (id: string) => {
    setConnections((atom) => {
      const updatedConnections = atom.data.filter(
        (c) => c.id_attributes?.id !== id,
      );

      return { ...atom, data: updatedConnections };
    });
  };
};
