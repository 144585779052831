import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { useEffect, useState } from 'react';
import { defaultCategoryConfig } from './config';

export function useCategoriesConfig() {
  const [config, setConfig] = useState(defaultCategoryConfig);

  const isMessagesEnabled =
    useFeatureFlagValue('dash_2025_03_03_search_message_results') === 'ON';
  const multimediaFlag = useFeatureFlagValue(
    'dash_2025_03_13_multimedia_search',
  );

  const isMultimediaEnabled =
    multimediaFlag === 'ON' || multimediaFlag === 'V1';

  useEffect(() => {
    const updatedConfig = defaultCategoryConfig.filter((category) => {
      if (category.id === 'messages') {
        return isMessagesEnabled;
      }
      if (category.id === 'images') {
        return isMultimediaEnabled;
      }
      return true;
    });
    setConfig(updatedConfig);
  }, [isMessagesEnabled, isMultimediaEnabled]);

  return config;
}
