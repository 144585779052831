import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import {
  getSearchFromURLParams,
  useQueryParams,
} from '@mirage/search/hooks/useQueryParams';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { usePreviousPath } from '@mirage/shared/hooks/usePreviousPath';
import { syncQueryFromURL } from '@mirage/shared/search/search-filters';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import type { TypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';

export function useCaptureOmniboxURLParams({
  setTypeaheadQuery,
}: TypeaheadSearch) {
  const { searchAttemptSessionManager } = useMirageAnalyticsContext();
  const { previousPath } = usePreviousPath();
  const { pathname } = useLocation();
  const queryParams = useQueryParams();

  const isUsingTypeaheadFiltersFeatureFlag =
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters') === 'ON';

  const urlSearch = useMemo(
    () => getSearchFromURLParams(queryParams),
    [queryParams],
  );

  /**
   * When search is triggered from browser history or from home, we need to sync
   * the value in the typeahead with the query from browser history
   */
  const isTaggedQuery = useCallback((activeQuery: string): boolean => {
    const searchAttemptSession = searchAttemptSessionManager.getSession();
    if (!searchAttemptSession) return false;
    const oldQuery = searchAttemptSession?.properties.query;
    const oldIsTypeAhead = searchAttemptSession?.properties.isTypeahead;
    return activeQuery === oldQuery && oldIsTypeAhead;
  }, []);

  useEffect(() => {
    const hasLeftSearchPage: boolean =
      previousPath === '/search_results' && pathname !== previousPath;

    if (!hasLeftSearchPage) return;

    setTypeaheadQuery('');
  }, [pathname, previousPath, setTypeaheadQuery]);

  useEffect(() => {
    const nextQuery = syncQueryFromURL({
      query: urlSearch.query,
      filters: isUsingTypeaheadFiltersFeatureFlag ? urlSearch.filters : [],
    });

    setTypeaheadQuery(nextQuery, isTaggedQuery(urlSearch.query));

    // We only want to run this effect when URL query/filters change
  }, [
    urlSearch.query,
    urlSearch.filters,
    isUsingTypeaheadFiltersFeatureFlag,
    isTaggedQuery,
    setTypeaheadQuery,
  ]);
}
