import { showSnackbar } from '@mirage/shared/snackbar';
import {
  MAX_STACK_DESCRIPTION_LENGTH,
  MAX_STACK_NAME_LENGTH,
} from '@mirage/shared/util/constants';
import { useStackDetailsTour } from '@mirage/stacks/StackDetailsTour/useStackDetailsTour';
import i18n from '@mirage/translations';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { sanitizedStackName } from '../../utils';
import { newStackDescriptionAtom, newStackNameAtom } from '../atoms';

export const useUpdateStackNameAndDescription = (
  isNewStack: boolean,
  updateStackNameAndDescription: (name: string, description: string) => void,
): (() => boolean) => {
  const { getCurrentStep, nextStep } = useStackDetailsTour();
  const [newStackName, setNewStackName] = useAtom(newStackNameAtom);
  const [newStackDescription, setNewStackDescription] = useAtom(
    newStackDescriptionAtom,
  );

  return useCallback(() => {
    const trimmedStackName = newStackName.trim();
    const finalDescription = newStackDescription.trim();

    // Empty stack, no-op
    if (trimmedStackName === '' && finalDescription === '' && isNewStack) {
      return false;
    }

    const sanitizedName = sanitizedStackName(trimmedStackName);
    if (sanitizedName !== trimmedStackName) {
      showSnackbar({
        title: i18n.t('illegal_characters_warning'),
      });
      return false;
    }
    const finalStackName =
      sanitizedName.length === 0
        ? i18n.t('untitled_stack')
        : sanitizedName.replace(/\n+/gi, '\n');

    if (finalStackName.length > MAX_STACK_NAME_LENGTH) {
      showSnackbar({
        title: i18n.t('input_too_long'),
      });
      return false;
    }
    setNewStackName(finalStackName);

    if (finalDescription.length > MAX_STACK_DESCRIPTION_LENGTH) {
      showSnackbar({
        title: i18n.t('input_too_long'),
      });
      return false;
    }
    setNewStackDescription(finalDescription);

    updateStackNameAndDescription(finalStackName, finalDescription);
    if (getCurrentStep()?.key === 'name-stack') nextStep();
    return true;
  }, [
    newStackName,
    newStackDescription,
    isNewStack,
    setNewStackName,
    setNewStackDescription,
    updateStackNameAndDescription,
    getCurrentStep,
    nextStep,
  ]);
};
