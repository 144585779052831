import type {
  ChatSource,
  ChatSourceDashSearchResult,
} from '../../mosaics/Chat/types';
import type { dash } from '@dropbox/api-v2-client';
import type {
  DocumentInfo,
  IconResource,
} from '@mirage/service-conversation/types';

export const convertDocumentInfoToChatSource = (
  documentInfos: DocumentInfo[],
): ChatSource[] => {
  const sources = documentInfos.map((info): ChatSource => {
    if (!info.uuid) {
      throw new Error('Document info is missing required uuid field');
    }

    // Convert DocumentInfo to a dash search result format
    const searchResult: dash.query_result_unionSearchResult = {
      '.tag': 'search_result',
      // Required fields with fallbacks
      uuid: info.uuid,
      url: info.url ?? '',
      title: info.title ?? '',

      // record_type is not returned from the api. Probably safe to assume
      // its a document, but doing unknown_record_type for now.
      record_type: {
        '.tag': 'unknown_record_type',
      },

      // Timestamps
      updated_at_ms: info.lastUpdatedMs ?? 0,

      // Content fields
      body: info.body ?? '',

      // Metadata
      branded_type: info.brandedType ?? '',
      connector_info: {
        connector_id: info.connectorInfo?.connectorId ?? 'unknown',
        connector_name:
          info.connectorInfo?.connectorName ?? 'unknown_connector',

        // Due to the mismatch of CE_ConnectorInfo and dash.ConnectorInfo,
        // we don't have a way to map the connector_type. If we need
        // this field, we either need to update the backend or decode this enum
        // client side. See: https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server/-/blob/configs/proto/dropbox/proto/context_engine/connector_info.proto?L11-13
        connector_type: {
          '.tag': 'unknown_provider',
        },

        // Looks like the go code is hardcoded to return paragon, but I am pretty
        // sure nothing is on paragon anymore and Janus is not an option.
        // So I am assuming this isn't really used, setting to unknown_platform since
        // its unreliable.
        // https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server/-/blob/go/src/dropbox/context_engine/tools/gen_connector_info_data/main.go?L26
        platform: {
          '.tag': 'unknown_platform',
        },
        icon:
          info.connectorInfo?.icon &&
          convertIconResource(info.connectorInfo?.icon),
        connector_icon_url: info.connectorInfo?.connectorIconUrl,
        display_name: info.connectorInfo?.displayName,
      },
      file_type_info: convertFileTypeInfo(info.fileTypeInfo),
      relevance_score: info.relevanceScore ?? 0,
    };

    const chatSource: ChatSourceDashSearchResult = {
      type: 'dash_search_result',
      searchResult,
    };

    return chatSource;
  });

  return sources;
};

function convertIconResource(iconResource: IconResource): dash.IconResource {
  return {
    light_url: iconResource.lightUrl,
    dark_url: iconResource.darkUrl,
    light_png_url: iconResource.lightPngUrl,
    dark_png_url: iconResource.darkPngUrl,
  };
}

function convertFileTypeInfo(
  fileTypeInfo: DocumentInfo['fileTypeInfo'],
): dash.FileTypeInfo | undefined {
  if (!fileTypeInfo) {
    return undefined;
  }
  return {
    id: fileTypeInfo.id,
    display_name: fileTypeInfo.displayName,
    icon: fileTypeInfo.icon && convertIconResource(fileTypeInfo.icon),
  };
}
