import { Avatar } from '@dropbox/dig-components/avatar';
import { Chip } from '@dropbox/dig-components/chip';
import { UIIcon } from '@dropbox/dig-icons';
import { ArchiveLine, PinFill } from '@dropbox/dig-icons/assets';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook/hook';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import { getInitials } from '@mirage/shared/account';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n, {
  i18nUnlocalizableStringClass,
  Trans,
} from '@mirage/translations';
import React from 'react';
import { stackIsArchived } from '../../Helpers/Utils';
import {
  accessLevelIconSrcForMemberCount,
  accessLevelTitleForAccessLevel,
  descriptionForStackAccessLevel,
} from '../../ShareModal/Utils';
import { useIsArchiveStackEnabled } from '../hooks';
import styles from './Header.module.css';

import type { UserInfo } from '../types';
import type { stacks } from '@dropbox/api-v2-client';

interface StackInfoAndMemberProps {
  stack: stacks.Stack;
  defaultUserInfo: UserInfo;
  hidePinned?: boolean;
}

const StackInfoAndMembers: React.FC<StackInfoAndMemberProps> = ({
  stack,
  defaultUserInfo,
  hidePinned = false,
}) => {
  const isAugRev = useStackPageAugustRevisionEnabled();
  const isPublicSharingAllowed = useIsPublicSharingAllowed();
  const stackOwners =
    stack?.sharing_data?.members?.filter(
      (member) => member.permission?.['.tag'] === 'owner',
    ) ?? [];
  const owner = stackOwners.length > 0 ? stackOwners[0] : undefined;
  const ownerInitials = owner
    ? getInitials(
        (owner.subject?.['.tag'] === 'user'
          ? owner.subject.display_name
          : undefined) ?? '',
      )
    : undefined;
  const accessLevelIcon = accessLevelIconSrcForMemberCount(
    stack?.sharing_data?.members?.length ?? 0,
    stack?.sharing_data?.shared_link?.access_level,
    stack.publish_data?.is_published,
    isPublicSharingAllowed,
  );

  const archiveStackIsEnabled = useIsArchiveStackEnabled();
  const isDashWelcomeStack =
    stack.stack_data?.creation_type?.['.tag'] === 'welcome_stack';

  const isUDWelcomeStack =
    stack.stack_data?.creation_type?.['.tag'] ===
    'unified_desktop_welcome_stack';

  return (
    <div className={styles.stackInfoAndMembers}>
      <Chip variant="transparent" size="small" className={styles.shortChip}>
        {archiveStackIsEnabled && stackIsArchived(stack) && (
          <>
            <Chip.IconAccessory
              className={isAugRev ? styles.subtleText : undefined}
            >
              <UIIcon src={ArchiveLine} />
            </Chip.IconAccessory>
            <Chip.Content className={isAugRev ? styles.subtleText : undefined}>
              {i18n.t('archived')}
            </Chip.Content>
            <Chip.Content>·</Chip.Content>
          </>
        )}
        {!hidePinned && stack?.user_data?.is_pinned && (
          <>
            <Chip.IconAccessory
              className={isAugRev ? styles.subtleText : undefined}
            >
              <DigTooltip title={i18n.t('stack_pinned')}>
                <div style={{ lineHeight: 0 }}>
                  <UIIcon src={PinFill} />
                </div>
              </DigTooltip>
            </Chip.IconAccessory>
            <Chip.Content>·</Chip.Content>
          </>
        )}
        {accessLevelIcon && (
          <Chip.IconAccessory
            className={isAugRev ? styles.subtleText : undefined}
          >
            <DigTooltip
              title={descriptionForStackAccessLevel(
                stack?.sharing_data?.shared_link?.access_level,
                stack?.sharing_data?.shared_link?.team_name,
                isPublicSharingAllowed,
              )}
            >
              <div style={{ lineHeight: 0 }}>
                <UIIcon src={accessLevelIcon} size="large" />
              </div>
            </DigTooltip>
          </Chip.IconAccessory>
        )}
        <Chip.Content className={isAugRev ? styles.subtleText : undefined}>
          {accessLevelTitleForAccessLevel(
            stack.publish_data,
            stack?.sharing_data?.shared_link?.access_level,
            stack?.sharing_data?.members?.length ?? 0,
            isPublicSharingAllowed,
          )}
        </Chip.Content>
        <Chip.Content className={i18nUnlocalizableStringClass}>·</Chip.Content>
        <Chip.Content className={isAugRev ? styles.subtleText : undefined}>
          {isDashWelcomeStack ? (
            i18n.t('created_by_dropbox_dash')
          ) : isUDWelcomeStack ? (
            i18n.t('created_by_dropbox')
          ) : (
            <Trans
              i18nKey="created_by"
              values={{
                initials: ownerInitials,
                name:
                  owner?.subject?.['.tag'] === 'user'
                    ? owner.subject.display_name
                    : defaultUserInfo.displayName,
              }}
              components={{
                avatar: (
                  <Avatar
                    className={styles.createdByAvatar}
                    size="xsmall"
                    src={
                      owner?.subject?.['.tag'] === 'user'
                        ? owner.subject.profile_photo_url
                        : defaultUserInfo.profilePhotoUrl
                    }
                  />
                ),
              }}
            />
          )}
        </Chip.Content>
      </Chip>
    </div>
  );
};

export default StackInfoAndMembers;
