import { useOneColumnMaxWidth } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import { useEffect, useState } from 'react';

import type { MutableRefObject } from 'react';

export function useOneColumnLayout(
  contentRef: MutableRefObject<HTMLDivElement | null>,
) {
  const [oneColumnLayout, setOneColumnLayout] = useState(false);
  const maxColumnWidth = useOneColumnMaxWidth();

  useEffect(() => {
    const contentElement = contentRef.current;
    if (!contentElement) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setOneColumnLayout(width < maxColumnWidth);
      }
    });
    resizeObserver.observe(contentElement);
    return () => {
      resizeObserver.unobserve(contentElement);
    };
  }, [maxColumnWidth, contentRef]);

  return oneColumnLayout;
}
