import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';

import type { ConnectorConnections } from '@mirage/service-connectors/service';
import type { AppShortcutPreferencesItem } from '@mirage/service-settings/service/types';

export const getAppsFromConnections = (
  connections: ConnectorConnections,
  maxSize: number = 5,
  isDarkMode: boolean = false,
): AppShortcutPreferencesItem[] => {
  const filteredConnections = connections.filter(
    (a) => a.branding?.display_name !== 'Browser Extension',
  );
  const apps: ConnectorRankItem[] = [];
  filteredConnections.forEach((connection) => {
    const connector = connection.connector;
    if (!connector) {
      return;
    }
    if (connectorsMap[connector.id_attrs?.type ?? '']) {
      const branding = connector.branding;
      apps.push({
        ...connectorsMap[connector.id_attrs?.type ?? ''],
        icon:
          isDarkMode && branding?.icon_dark_src
            ? branding?.icon_dark_src
            : branding?.icon_src,
      });
    }
  });
  apps.sort((a, b) => a.rank - b.rank);
  return apps.slice(0, maxSize).map((item) => {
    return {
      url: item.rootUrl,
      favicon: item.icon ?? faviconSrcForSrcUrl(item.rootUrl),
      title: item.name,
    };
  });
};

interface ConnectorRankItem {
  name: string;
  rootUrl: string;
  rank: number;
  icon?: string;
}

const connectorsMap: Record<string, ConnectorRankItem> = {
  dropbox: {
    name: 'Dropbox',
    rootUrl: 'https://dropbox.com/',
    rank: 200,
  },
  onedrive: {
    name: 'Microsoft 365',
    rootUrl: 'https://m365.cloud.microsoft/',
    rank: 55,
  },
  googledrive: {
    name: 'Google Drive',
    rootUrl: 'https://drive.google.com/',
    rank: 100,
  },
  gmail: {
    name: 'Google Mail',
    rootUrl: 'https://mail.google.com/',
    rank: 50,
  },
  googleCalendar: {
    name: 'Google Calendar',
    rootUrl: 'https://calendar.google.com',
    rank: 75,
  },
  outlook: {
    name: 'Microsoft Outlook',
    rootUrl: 'https://outlook.office365.com/',
    rank: 65,
  },
  github: {
    name: 'GitHub',
    rootUrl: 'https://github.com/',
    rank: 80,
  },
};
