import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { Overlay } from '@dropbox/dig-components/overlay';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { PopoverContent } from '@mirage/stacks/StackCardPopover/Popover';
import { useStackItemsByStackIdAndSection } from '@mirage/stacks/useStackItemsByStackIdAndSection';
import React, { useEffect, useRef, useState } from 'react';
import styles from './StackCardWithPopover.module.css';

import type {
  OverlayPlacement,
  OverlayProps,
} from '@dropbox/dig-components/overlay';
import type { WorkingSetCardData } from '@mirage/working-set';
import type { CSSProperties } from 'react';

// Default placement for our popover component (expose this as a prop later when we want to extend this)
const DEFAULT_POPOVER_PLACEMENT: Pick<OverlayProps, 'offsetDistance'> & {
  placement: OverlayPlacement;
} = {
  placement: 'bottom',
  offsetDistance: { mainAxis: -15, crossAxis: 70 },
};

/**
 *  StackCardWithPopover is a HOC that wraps a card component as triggering surface
 * and renders a popover component that is offset from the card (ex. stack items for a stack card)
 * Note: currently this is only enable for hover friendly devices
 *
 * @param triggerComponent - The card component that will be the trigger for the popover
 * @param parentClassname - This is a classname passed in to target the parent element
 *    (ex. needed for selector class applied for useDynamicHook)
 */
export const StackCardWithPopover = ({
  triggerComponent,
  styleOverrides,
  parentClassname,
  stackData,
  initialPlacement = DEFAULT_POPOVER_PLACEMENT.placement,
  offsetDistance = DEFAULT_POPOVER_PLACEMENT.offsetDistance,
}: {
  triggerComponent: React.ReactNode;
  styleOverrides?: CSSProperties;
  parentClassname?: string;
  stackData: WorkingSetCardData;
  initialPlacement?: OverlayPlacement;
  offsetDistance?: OverlayProps['offsetDistance'];
}) => {
  const { stackItemsBySection, sections, isEmpty, isLoading } =
    useStackItemsByStackIdAndSection(stackData.namespaceId);
  const isHoverFriendly = useIsHoverFriendly();
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [placement, setPlacement] =
    useState<OverlayPlacement>(initialPlacement);
  // shouldHydrateData is used to immediately render the component rather than using showTooltip, since there is delay in when we
  // show the tooltip, and we don't want to wait on computing stack items AND the timeout delay
  const [shouldHydrateData, setShouldHydrateData] = useState(false);
  const mouseLeaveTimeout = useRef<number | null>(null);

  const handleMouseEnter = () => {
    setShouldHydrateData(true);
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(true);
    }, 200);
  };

  const handleMouseLeave = () => {
    // TODO Improvement: let's immediate check to see if we need to hide the popoveer
    // by checking if the mouse is not over the trigger component or the popover component
    // since setTimeout gets scheduled for a min-time and seems to get called even later on the initial page load hover
    if (mouseLeaveTimeout.current) {
      window.clearTimeout(mouseLeaveTimeout.current);
    }
    mouseLeaveTimeout.current = window.setTimeout(() => {
      setShowTooltip(false);
    }, 50);
  };

  useEffect(() => {
    return () => {
      mouseLeaveTimeout.current &&
        window.clearTimeout(mouseLeaveTimeout.current);
    };
  }, []);

  const shouldRenderPopoverComponent =
    isHoverFriendly &&
    shouldHydrateData &&
    !isEmpty &&
    !isLoading &&
    showTooltip;

  return (
    <div
      ref={triggerRef}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
      style={styleOverrides}
      className={parentClassname}
    >
      {triggerComponent}
      {shouldRenderPopoverComponent ? (
        <>
          <ClickOutside onClickOutside={handleMouseLeave}>
            <Overlay
              className={styles.popoverOverlay}
              placement={placement}
              offsetDistance={offsetDistance}
              anchorRef={triggerRef}
              onChangePlacement={setPlacement}
              onPointerEnter={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
              auto
            >
              <PopoverContent
                stackData={stackData}
                stackItemsBySection={stackItemsBySection}
                sections={sections}
              />
            </Overlay>
          </ClickOutside>
        </>
      ) : null}
    </div>
  );
};
