import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import { ONE_DAY_IN_MILLIS } from '@mirage/shared/util/constants';
import WithDefaults from '@mirage/storage/with-defaults';
import { Subject } from 'rxjs';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { Surface } from '@mirage/shared/util/types';
import type { KVStorage } from '@mirage/storage';
import type { ConsolaInstance } from 'consola';
import type { Observable } from 'rxjs';

function getFirstLoginTimeMetadataKey(surface: Surface) {
  switch (surface) {
    case 'desktop':
      return 'DASH_DESKTOP_FIRST_LOGIN_UTC_MS';
    case 'web':
      return 'DASH_WEB_FIRST_LOGIN_UTC_MS';
    case 'extension':
      return 'DASH_EXTENSION_FIRST_LOGIN_UTC_MS';
  }
}

function getFirstLoginTimeStorageKey(surface: Surface) {
  switch (surface) {
    case 'desktop':
      return 'firstLoginTimeDesktop';
    case 'web':
      return 'firstLoginTimeWeb';
    case 'extension':
      return 'firstLoginTimeExtension';
  }
}

export type OnboardingStorageSSOnboardingState = {
  companyLogo?: string;
  companyName?: string;
  // Maps are not stored properly within localStorage, so we need to convert them to a string.
  // Index is name of survey, value is array of team based responses
  surveyResponsesStr?: string;
};

export type OnboardingStorage = {
  dismissedCalendarEmptyState: boolean;
  dismissedStartPageCalendarEmptyState: {
    latestDismissedMs: number;
    dismissedCount: number;
  };
  dismissedConnectApps: boolean;
  dismissedCreateStack: boolean;
  dismissedDownloadDashUpsell: boolean;
  dismissedGetToKnow: boolean;
  dismissedGettingStartedChecklist: boolean;
  dismissedInitialSyncPage: boolean;
  dismissedQuerySuggestionBanner: boolean;
  dismissedSettingsBanner: boolean;
  dismissedSetupModuleAddApps: boolean;
  dismissedSetupModuleGetStarted: boolean;
  dismissedSetupModulePlatforms: boolean;
  dismissedStackDetailsTour: boolean;
  dismissedStacksBanner: boolean;
  dismissedSummarizeTooltip: boolean;
  dismissedRecentsExtensionUpsell: boolean;
  dismissedOmniboxSearchPrompt: boolean;
  firstLoginTimeDesktop: number;
  firstLoginTimeWeb: number;
  firstLoginTimeExtension: number;
  isExplicitAnswersFTUSeen: boolean;
  latestDismissedWelcomeModal: number;
  launchKeyboardShortcutUseCount: number;
  seenAnnotationIntroModal: boolean;
  seenDownloadDashUpsell: boolean;
  seenGettingStartedChecklist: boolean;
  seenUIMigrationModal: boolean;
  seenUIMigrationV2Modal: boolean;
  seenUIV3Modal: boolean;
  stackDetailTourStepIndex: number;
  dismissedColdStartNotification: boolean;
  selfServeOnboardingState: OnboardingStorageSSOnboardingState;
};

export type OnboardingChecklistItem = {
  isComplete: boolean;
  milestoneKeys: string[];
  moduleId: string;
};

export const defaultOnboardingStorage: OnboardingStorage = {
  dismissedCalendarEmptyState: false,
  dismissedStartPageCalendarEmptyState: {
    latestDismissedMs: 0,
    dismissedCount: 0,
  },
  dismissedConnectApps: false,
  dismissedCreateStack: false,
  dismissedDownloadDashUpsell: false,
  dismissedGetToKnow: false,
  dismissedGettingStartedChecklist: false,
  dismissedInitialSyncPage: false,
  dismissedQuerySuggestionBanner: false,
  dismissedSettingsBanner: false,
  dismissedSetupModuleAddApps: false,
  dismissedSetupModuleGetStarted: false,
  dismissedSetupModulePlatforms: false,
  dismissedStackDetailsTour: false,
  dismissedStacksBanner: false,
  dismissedSummarizeTooltip: false,
  dismissedRecentsExtensionUpsell: false,
  dismissedOmniboxSearchPrompt: false,
  firstLoginTimeDesktop: 0,
  firstLoginTimeWeb: 0,
  firstLoginTimeExtension: 0,
  isExplicitAnswersFTUSeen: false,
  latestDismissedWelcomeModal: 0,
  launchKeyboardShortcutUseCount: 0,
  seenAnnotationIntroModal: false,
  seenDownloadDashUpsell: false,
  seenGettingStartedChecklist: false,
  seenUIMigrationModal: false,
  seenUIMigrationV2Modal: false,
  seenUIV3Modal: false,
  stackDetailTourStepIndex: 0,
  dismissedColdStartNotification: false,
  selfServeOnboardingState: {},
};

export type OnboardingStoragePartial<K extends keyof OnboardingStorage> = {
  [P in K]: OnboardingStorage[P];
};

export type Service = ReturnType<typeof service>;

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

interface AuditLoggingServiceContract {
  auditFirstLoginDesktop(timestamp: number): Promise<void>;
  auditFirstLoginWeb(timestamp: number): Promise<void>;
}

export default function service(
  adapter: KVStorage<OnboardingStorage>,
  surface: 'desktop' | 'web' | 'extension',
  { callApiV2 }: DbxApiServiceContract,
  { tagged }: LoggingServiceContract,
  { auditFirstLoginDesktop, auditFirstLoginWeb }: AuditLoggingServiceContract,
) {
  const logger = tagged('service-onboarding');
  const firstLoginTimeMetadataKeyForCurrentSurface =
    getFirstLoginTimeMetadataKey(surface);
  const firstLoginTimeStorageKeyForCurrentSurface =
    getFirstLoginTimeStorageKey(surface);

  const wrapped = new WithDefaults<OnboardingStorage>(
    adapter,
    defaultOnboardingStorage,
  );

  const onboardingValues$ = new Subject<OnboardingStorage>();

  function get<K extends keyof OnboardingStorage>(
    key: K,
  ): Promise<OnboardingStorage[K]> {
    return wrapped.get(key);
  }

  function getAll(): Promise<OnboardingStorage> {
    return wrapped.getAll();
  }

  async function set<K extends keyof OnboardingStorage>(
    key: K,
    value: OnboardingStorage[K],
  ): Promise<void> {
    onboardingValues$.next({ ...(await getAll()), [key]: value });
    await adapter.set(key, value);
  }

  function clear(): Promise<void> {
    onboardingValues$.next(defaultOnboardingStorage);
    return adapter.clear();
  }

  function onboardingValuesObservable(): Observable<OnboardingStorage> {
    return onboardingValues$.asObservable();
  }

  const getUserMetadata = getCombineAsyncRequestsFunc((metadataKey: string) =>
    callApiV2('userMetadataUserMetadataGet', {
      metadata: [metadataKey],
    }),
  );

  async function getFirstLoginTime(
    surface?: Surface,
  ): Promise<number | undefined> {
    let cacheKey: keyof OnboardingStorage =
      firstLoginTimeStorageKeyForCurrentSurface;
    if (surface) {
      cacheKey = getFirstLoginTimeStorageKey(surface);
    }
    const cached = await wrapped.get(cacheKey);
    if (cached) {
      logger.debug('First login time from user metadata (cached):', cached);
      return cached;
    }

    try {
      const firstLoginTimeKey = surface
        ? getFirstLoginTimeMetadataKey(surface)
        : firstLoginTimeMetadataKeyForCurrentSurface;
      const userMetadata = await getUserMetadata(firstLoginTimeKey);

      const loginKeyJson = userMetadata?.metadata?.[firstLoginTimeKey];
      if (!loginKeyJson) {
        logger.info(
          `getFirstLoginTime - User first login metadata was not set`,
        );
        return undefined;
      }
      const msTime: number | undefined = JSON.parse(loginKeyJson);

      logger.debug('First login time from user metadata:', msTime);

      // Save in cache for quick retrieval if defined.
      if (msTime) {
        await wrapped.set(cacheKey, msTime);
      }

      return msTime;
    } catch (error) {
      logger.error(
        `getFirstLoginTime - Failed to fetch user first login metadata`,
        error,
      );
    }
    return undefined;
  }

  async function isUsersFirstLogin(): Promise<boolean> {
    const firstLoginTimeMs = await getFirstLoginTime();
    // Return true if the user's onboarding timestamp is unset, OR when the value had been reset to 0 for testing reasons.
    const firstTimeUser =
      firstLoginTimeMs === undefined ||
      firstLoginTimeMs === null ||
      firstLoginTimeMs === 0;

    logger.debug('isUsersFirstLogin:', firstTimeUser);

    return firstTimeUser;
  }

  async function isUsersDay0(): Promise<boolean> {
    const firstLogin = await getFirstLoginTime();
    if (!firstLogin) {
      return true;
    }

    return Date.now() - firstLogin < ONE_DAY_IN_MILLIS;
  }

  // Used for DfI onboarding
  async function markDashUserDfIOnboardingCompleteIfNecessary(
    onboardingVariant: string,
  ) {
    callApiV2('userMetadataUserMetadataSet', {
      metadata: {
        DASH_USER_ONBOARDING_STATE: `"${onboardingVariant}:COMPLETE"`,
      },
    });
  }

  // Used for DfB onboarding
  async function markDashUserDfbOnboardingCompleteIfNecessary(
    onboardingVariant: string,
  ) {
    const shouldTriggerOnboardingCompleteRTT = convertFeatureValueToBool(
      await getCachedOrFetchFeatureValue(
        'otc_growth_2025_02_13_dash_onboarding_complete_rtt',
      ),
    );

    // TODO (OTCGA-39): Move UserMetadata update into /2/dash_growth/mark_dfb_onboarding_complete
    await callApiV2('userMetadataUserMetadataSet', {
      metadata: {
        DASH_DFB_ONBOARDING_STATE: `"${onboardingVariant}:COMPLETE"`,
      },
    });

    if (shouldTriggerOnboardingCompleteRTT) {
      await callApiV2('dashGrowthMarkDfbOnboardingComplete', {
        onboarding_variant: onboardingVariant,
      });
    }
  }

  // Sets the user's timestamp for their first use of Dash.
  async function markDashUserFirstUseTimestamp() {
    const now = Date.now(); // Keeps this consistent with the Scout code
    if (surface === 'desktop') {
      auditFirstLoginDesktop(now);
    } else if (surface === 'web') {
      auditFirstLoginWeb(now);
    }
    return await callApiV2('userMetadataUserMetadataSet', {
      metadata: {
        [firstLoginTimeMetadataKeyForCurrentSurface]: `${now}`,
      },
    });
  }

  // Helper function to reset the user's timestamp for testing reasons.
  async function resetDashUserFirstUseTimestamp() {
    if (surface === 'desktop') {
      auditFirstLoginDesktop(0);
    } else if (surface === 'web') {
      auditFirstLoginWeb(0);
    }
    return await callApiV2('userMetadataUserMetadataSet', {
      metadata: {
        [firstLoginTimeMetadataKeyForCurrentSurface]: `0`,
      },
    });
  }

  async function markDashWelcomeModalCompleteIfNecessary(modalVersion: number) {
    callApiV2('userMetadataUserMetadataSet', {
      metadata: {
        DASH_WELCOME_MODAL_VERSION: `${modalVersion}`,
      },
    });
  }

  async function getLatestWelcomeModalComplete(): Promise<number> {
    const cached = (await wrapped.get('latestDismissedWelcomeModal')) as number;
    if (cached) {
      return cached;
    }

    try {
      const userMetadata = await callApiV2('userMetadataUserMetadataGet', {
        metadata: [`DASH_WELCOME_MODAL_VERSION`],
      });

      const latestVariantDismissed: number | undefined = JSON.parse(
        userMetadata?.metadata?.[`DASH_WELCOME_MODAL_VERSION`],
      );

      // Save in cache for quick retrieval if defined.
      if (latestVariantDismissed) {
        await wrapped.set(
          'latestDismissedWelcomeModal',
          latestVariantDismissed,
        );
      }

      return latestVariantDismissed ?? 0;
    } catch (error) {
      logger.error(
        `latestVariantDismissed - Failed to fetch user latest modal variant metadata`,
      );
      // If there's an exception use a high number in order to avoid displaying the modal
      return 100;
    }
  }

  async function markDashUserStackRecipientCompleteIfNecessary() {
    const onboardingState = await getUserOnboardingState();

    if (onboardingState === undefined || onboardingState === null) {
      callApiV2('userMetadataUserMetadataSet', {
        metadata: {
          DASH_USER_ONBOARDING_STATE: `"STACK_RECIPIENT:COMPLETE"`,
        },
      });
    }
  }

  async function getUserOnboardingState() {
    try {
      const userMetadata = await callApiV2('userMetadataUserMetadataGet', {
        metadata: ['DASH_USER_ONBOARDING_STATE'],
      });

      return JSON.parse(userMetadata?.metadata?.['DASH_USER_ONBOARDING_STATE']);
    } catch (error) {
      logger.error(
        `getOnboardingState - Failed to fetch user onboarding state metadata`,
      );
    }
    return undefined;
  }

  async function fetchTeamLogoUrl() {
    try {
      const teamLogoUrlResponse = await callApiV2('brandingGetTeamLogoUrl', {});
      return teamLogoUrlResponse.team_logo_url;
    } catch (error) {
      logger.error(
        `fetchTeamLogoUrl - Failed to fetch the user's team logo url`,
      );
    }
    return undefined;
  }

  return services.provide(
    ServiceId.ONBOARDING,
    {
      get,
      getAll,
      set,
      clear,
      onboardingValuesObservable,
      getFirstLoginTime,
      isUsersFirstLogin,
      isUsersDay0,
      markDashUserDfIOnboardingCompleteIfNecessary,
      markDashUserDfbOnboardingCompleteIfNecessary,
      markDashUserFirstUseTimestamp,
      resetDashUserFirstUseTimestamp,
      markDashUserStackRecipientCompleteIfNecessary,
      markDashWelcomeModalCompleteIfNecessary,
      getLatestWelcomeModalComplete,
      getUserOnboardingState,
      fetchTeamLogoUrl,
    },
    [ServiceId.DBX_API],
  );
}
