import { UIIcon } from '@dropbox/dig-icons';
import { PinLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { PinFill } from '@dropbox/dig-icons/dist/mjs/assets/ui-icon/fill/pin';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { useToggleStackPin } from './useToggleStackPin';

import type { stacks, users } from '@dropbox/api-v2-client';

export const PinStackButton = ({
  stack,
  mutationRequestId,
  sessionId,
  currentAccount,
  iconColor,
}: {
  stack: stacks.Stack | null;
  mutationRequestId?: string;
  sessionId?: string;
  currentAccount?: users.FullAccount;
  iconColor?: string;
}) => {
  const toggleStackPin = useToggleStackPin(stack, mutationRequestId, sessionId);

  const isStackPinned = stack?.user_data?.is_pinned;

  return (
    <IconButtonWithTooltip
      tooltipProps={{
        title: i18n.t(isStackPinned ? 'unpin_stack' : 'pin_stack'),
      }}
      variant="transparent"
      onClick={toggleStackPin}
      data-testid="PinStack"
      aria-label={i18n.t(isStackPinned ? 'unpin' : 'pin')}
      data-uxa-log={createUxaElementId('pin_button', {
        actionSurfaceComponent: 'stacks',
        featureLine: 'stacks',
      })}
      disabled={!currentAccount}
      data-uxa-entity-id={stack?.namespace_id}
    >
      <UIIcon
        src={isStackPinned ? PinFill : PinLine}
        style={iconColor ? { color: iconColor } : {}}
      />
    </IconButtonWithTooltip>
  );
};
