/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */

import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ArrowDownLine,
  ArrowUpLine,
  ChevronDownLine,
  ChevronUpLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import {
  clearIpcProfilingStats,
  getAllIpcProfilingStats,
  getIpcProfilingEnabled,
  setIpcProfilingEnabled,
} from '@mirage/service-ipc-profiling';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './IpcProfiling.module.css';

import type { Stats } from '@mirage/service-ipc-profiling/service';

const isCollapsedAtom = atomWithStorage<boolean>(
  'ipcProfilingCollapsed',
  false,
);

type RowData = {
  method: string;
  count: number;
  totalDurationMs: number;
  averageDurationMs: number;
};

const enum Column {
  METHOD,
  COUNT,
  TOTAL_DURATION,
  AVERAGE_DURATION,
}

const SortDirection: React.FC<{
  isSortColumn: boolean;
  sortAscending: boolean;
}> = ({ isSortColumn, sortAscending }) => {
  return isSortColumn ? (
    sortAscending ? (
      <UIIcon src={ArrowDownLine} />
    ) : (
      <UIIcon src={ArrowUpLine} />
    )
  ) : null;
};

const ColumnHeader: React.FC<{
  columnName: string;
  isSortColumn: boolean;
  sortAscending: boolean;
  onClick: () => void;
}> = ({ columnName, isSortColumn, sortAscending, onClick }) => {
  return (
    <Text isBold>
      <Button variant="borderless" onClick={() => onClick()}>
        {columnName}
        <SortDirection
          isSortColumn={isSortColumn}
          sortAscending={sortAscending}
        />
      </Button>
    </Text>
  );
};

export const IpcProfiling: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useAtom(isCollapsedAtom);
  const [allStats, setAllStats] = useState<Stats>();
  const [isAttached, setIsAttached] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const doRefresh = () => setRefresh((v) => !v);

  const [sortColumn, setSortColumn] = useState(Column.METHOD);
  const [sortAscending, setSortAscending] = useState(true);

  const sortDataRows = useCallback(
    (rows: RowData[]) => {
      switch (sortColumn) {
        case Column.METHOD:
          rows.sort((a, b) => a.method.localeCompare(b.method));
          break;
        case Column.COUNT:
          rows.sort((a, b) => a.count - b.count);
          break;
        case Column.TOTAL_DURATION:
          rows.sort((a, b) => a.totalDurationMs - b.totalDurationMs);
          break;
        case Column.AVERAGE_DURATION:
          rows.sort((a, b) => a.averageDurationMs - b.averageDurationMs);
          break;
        case undefined:
          break;
        default:
          sortColumn satisfies never;
      }

      // Simplify the code by always sorting by ascending order above.
      if (!sortAscending) rows.reverse();
    },
    [sortAscending, sortColumn],
  );

  useEffect(() => {
    refresh; // allow forced refreshes

    getAllIpcProfilingStats().then((s) => setAllStats(s));

    getIpcProfilingEnabled().then(setIsAttached);
  }, [refresh]);

  const displayData = useMemo(() => {
    const data: RowData[] = [];

    if (!allStats) return data;

    const methodStats = allStats.statsByMethod;
    const methods = Object.keys(methodStats);

    for (const method of methods) {
      const count = methodStats[method].count;
      const totalDurationMs = methodStats[method].durationMs;
      const averageDurationMs = totalDurationMs / count;

      data.push({
        method,
        count,
        totalDurationMs,
        averageDurationMs,
      });
    }

    sortDataRows(data);

    return data;
  }, [allStats, sortDataRows]);

  useEffect(() => {
    const timer = setInterval(doRefresh, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onColumnNameClick = useCallback(
    (column: Column) => {
      if (column === sortColumn) {
        // Clicking on current column -> reverse direction.
        setSortAscending((v) => !v);
      } else {
        // Clicking on non-current column -> set it as current column.
        setSortColumn(column);
      }
    },
    [sortColumn],
  );

  return (
    <div>
      <Box display="block" paddingBottom="16">
        <Text size="medium" isBold>
          IPC Profiling &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="opacity"
            onClick={() => {
              setIpcProfilingEnabled(!isAttached);
              doRefresh();
            }}
          >
            {isAttached ? 'Disable' : 'Enable'}
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="opacity"
            onClick={() => {
              clearIpcProfilingStats();
              doRefresh();
            }}
          >
            Clear Data
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="opacity"
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            {isCollapsed ? (
              <UIIcon src={ChevronDownLine} />
            ) : (
              <UIIcon src={ChevronUpLine} />
            )}
          </Button>
        </Text>
        {isCollapsed || <hr className={styles.horizontalSeparator} />}
      </Box>
      {isCollapsed || (
        <div className={styles.grid}>
          <div>
            <ColumnHeader
              columnName="Method"
              isSortColumn={sortColumn === Column.METHOD}
              sortAscending={sortAscending}
              onClick={() => onColumnNameClick(Column.METHOD)}
            />
          </div>
          <div>
            <ColumnHeader
              columnName="Count"
              isSortColumn={sortColumn === Column.COUNT}
              sortAscending={sortAscending}
              onClick={() => onColumnNameClick(Column.COUNT)}
            />
          </div>
          <div>
            <ColumnHeader
              columnName="Total duration"
              isSortColumn={sortColumn === Column.TOTAL_DURATION}
              sortAscending={sortAscending}
              onClick={() => onColumnNameClick(Column.TOTAL_DURATION)}
            />
          </div>
          <div>
            <ColumnHeader
              columnName="Average duration"
              isSortColumn={sortColumn === Column.AVERAGE_DURATION}
              sortAscending={sortAscending}
              onClick={() => onColumnNameClick(Column.AVERAGE_DURATION)}
            />
          </div>
          {displayData.map(
            ({ method, count, totalDurationMs, averageDurationMs }) => {
              return (
                <Fragment key={method}>
                  <div>
                    <Text>{method}</Text>
                  </div>
                  <div>
                    <Text>{count}</Text>
                  </div>
                  <div>
                    <Text>{totalDurationMs.toFixed(1)}ms</Text>
                  </div>
                  <div>
                    <Text>{averageDurationMs.toFixed(1)}ms</Text>
                  </div>
                </Fragment>
              );
            },
          )}
        </div>
      )}
    </div>
  );
};
