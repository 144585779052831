import { isDefined } from '@mirage/shared/util/tiny-utils';

import type { DashFacepileMember, Member } from './types';
import type { stacks } from '@dropbox/api-v2-client';

export const normalizeMembers = (members: Member[]): DashFacepileMember[] => {
  return members
    .map((member) => {
      const email = 'email' in member ? (member.email ?? '') : '';
      const displayName =
        'display_name' in member ? member.display_name : email;
      return {
        profilePhotoUrl:
          'profile_image_url' in member
            ? member.profile_image_url
            : (member as stacks.StackSharingUser).profile_photo_url,
        displayName: displayName || '',
        email,
      };
    })
    .filter(isDefined);
};
