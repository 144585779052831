import { PAP_Click_CustomTemplateSaveButton } from '@mirage/analytics/events/types/click_custom_template_save_button';
import { PAP_Interact_TemplateSearchInput } from '@mirage/analytics/events/types/interact_template_search_input';
import { PAP_Send_ComposePrompt } from '@mirage/analytics/events/types/send_compose_prompt';
import { useComposeSourcesCache } from '@mirage/mosaics/Chat/data/ComposeSourcesCache';
import { callModifyTemplateApi } from '@mirage/mosaics/ComposeAssistant/data/llm/modify-template';
import { tagged } from '@mirage/service-logging';
import { AccessType } from '@mirage/shared/compose/assist-api';
import {
  getSourceUUID,
  getTemplateModificationHistoryFromMessages,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import debounce from 'lodash.debounce';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { ModifyTemplateResponse } from '@mirage/mosaics/ComposeAssistant/data/llm/modify-template';
import type { ComposeAssistantConversationMessage } from '@mirage/shared/compose/assist-message';
import type { AssistCustomTemplate } from '@mirage/shared/compose/assist-template';
import type { ChangeEvent } from 'react';

const logger = tagged('useTemplateSettings');

interface TemplatePostMessageOptions {
  clearTemplate?: boolean;
}

interface TemplateSettingsParams {
  templates: AssistCustomTemplate[];
  saveTemplate: (template: AssistCustomTemplate) => Promise<void | string>;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  onDone: (templateID: string) => void;
  editingTemplateID?: string;
  setCreatedTemplateSnackbarMsg: (snackbar: string | undefined) => void;
}
export const useTemplateSettings = ({
  templates,
  saveTemplate,
  logComposeEvent,
  onDone,
  editingTemplateID,
  setCreatedTemplateSnackbarMsg,
}: TemplateSettingsParams) => {
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTemplateID, setSelectedTemplateID] = useState<
    string | undefined
  >(templates.length > 0 ? templates[0].id : undefined);
  const currentTemplate = selectedTemplateID
    ? templates.find((template) => template.id === selectedTemplateID)
    : undefined;
  const initialTemplate = editingTemplateID
    ? templates.find((t) => t.id === editingTemplateID)
    : currentTemplate;

  const initialTemplateState: AssistCustomTemplate =
    initialTemplate && initialTemplate.type === 'custom'
      ? initialTemplate
      : {
          id: initialTemplate ? initialTemplate.id : uuid4(),
          type: 'custom',
          dataId: '',
          name: initialTemplate ? initialTemplate.name : '',
          description: initialTemplate ? initialTemplate.description : '',
          templateContent: initialTemplate
            ? initialTemplate.templateContent
            : '',
          sources: [],
          messagesHistory: [],
          rev: 0,
          accessType: AccessType.INDIVIDUAL,
          isOwned: true,
        };
  const [currentTemplateState, setCurrentTemplateState] =
    useState<AssistCustomTemplate>(initialTemplateState);
  const resetTemplateState = useCallback(() => {
    setCurrentTemplateState({
      id: uuid4(),
      type: 'custom',
      dataId: '',
      name: '',
      description: '',
      templateContent: '',
      sources: [],
      messagesHistory: [],
      rev: 0,
      accessType: AccessType.INDIVIDUAL,
      isOwned: true,
    });
  }, []);
  const sourcesContents = useComposeSourcesCache(
    currentTemplateState?.sources || [],
  );
  const handleAddSource = useCallback((source: ChatSource) => {
    setCurrentTemplateState((prevTemplate) => {
      if (prevTemplate === undefined) return prevTemplate;
      if (
        prevTemplate.sources.some(
          (s) => getSourceUUID(s) === getSourceUUID(source),
        )
      ) {
        return prevTemplate;
      }
      return {
        ...prevTemplate,
        sources: [...prevTemplate.sources, source],
      };
    });
  }, []);

  const handleRemoveSource = useCallback((source: ChatSource) => {
    setCurrentTemplateState((prevTemplate) => {
      if (prevTemplate === undefined) return prevTemplate;
      return {
        ...prevTemplate,
        sources: prevTemplate.sources.filter(
          (s) => getSourceUUID(s) !== getSourceUUID(source),
        ),
      };
    });
  }, []);

  const debouncedLogEvent = useMemo(() => {
    return debounce(
      () => {
        logComposeEvent(PAP_Interact_TemplateSearchInput());
      },
      1000,
      { trailing: true },
    );
  }, [logComposeEvent]);

  const handleChangeTemplateNameInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCurrentTemplateState((prevTemplate) => {
        if (prevTemplate === undefined) return prevTemplate;
        return {
          ...prevTemplate,
          name: e.target.value,
        };
      });
      debouncedLogEvent();
    },
    [debouncedLogEvent],
  );

  const handleChangeTemplateDescriptionInput = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentTemplateState((prevTemplate) => {
        if (prevTemplate === undefined) return prevTemplate;
        return {
          ...prevTemplate,
          description: e.target.value,
        };
      });
      debouncedLogEvent();
    },
    [debouncedLogEvent],
  );

  const handleClickSave = useCallback(async () => {
    if (currentTemplateState) {
      try {
        const id = await saveTemplate({
          ...currentTemplateState,
          name: currentTemplateState.name || i18n.t('compose_new_tone'),
        });
        const isTeam = currentTemplateState.accessType === AccessType.TEAM;
        const snackbarMessage = isTeam
          ? i18n.t('compose_assistant_save_company_snackbar_message_templates')
          : i18n.t(
              'compose_assistant_save_individual_snackbar_message_templates',
            );
        setCreatedTemplateSnackbarMsg(snackbarMessage);
        onDone(id || '');
        logComposeEvent(
          PAP_Click_CustomTemplateSaveButton({
            actionType: isTeam ? AccessType.TEAM : AccessType.INDIVIDUAL,
          }),
        );
      } catch (error) {
        logger.error('Failed to save template', error);
      }
    }
  }, [
    currentTemplateState,
    onDone,
    saveTemplate,
    setCreatedTemplateSnackbarMsg,
    logComposeEvent,
  ]);

  const handleClickDelete = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  }, []);

  const handleModifyTemplateResponse = useCallback(
    (response: ModifyTemplateResponse) => {
      const {
        templateContent,
        followUpSuggestions,
        draftModifications,
        templateName,
        templateDescription,
      } = response;
      const newMessage: ComposeAssistantConversationMessage = {
        type: 'message',
        role: 'assistant',
        text: draftModifications,
        ts: Date.now(),
        actionContext: {
          type:
            currentTemplateState?.messagesHistory.length === 0
              ? 'template_generated'
              : 'template_updated',
        },
        followUpSuggestions,
      };
      setCurrentTemplateState((prevTemplate) => {
        if (prevTemplate === undefined) return prevTemplate;
        return {
          ...prevTemplate,
          name: templateName,
          description: templateDescription,
          templateContent,
          messagesHistory: [
            ...(prevTemplate.messagesHistory || []),
            newMessage,
          ],
        };
      });

      return setLoading(false);
    },
    [currentTemplateState],
  );

  const handleModifyTemplatePostUserMessage = useCallback(
    (text = '', options?: TemplatePostMessageOptions) => {
      setLoading(true);

      const newMessage: ComposeAssistantConversationMessage = {
        type: 'message',
        role: 'user',
        text,
        ts: Date.now(),
        rawPromptText: '',
        actionContext: { type: 'done' },
      };

      if (text.length) {
        setCurrentTemplateState((prevTemplate) => {
          if (prevTemplate === undefined) return prevTemplate;
          return {
            ...prevTemplate,
            messagesHistory: [
              ...(prevTemplate.messagesHistory || []),
              newMessage,
            ],
          };
        });
      }

      logComposeEvent(
        PAP_Send_ComposePrompt({
          queryString: text,
          itemCount: currentTemplateState.messagesHistory.length,
          queryLength: text.length,
        }),
      );

      const templateContent = options?.clearTemplate
        ? ''
        : currentTemplateState.templateContent;

      callModifyTemplateApi({
        templateID: currentTemplateState.id,
        templateContent,
        templateSourceContents: sourcesContents,
        templateModificationHistory: getTemplateModificationHistoryFromMessages(
          currentTemplateState.messagesHistory.concat(newMessage),
        ),
      })
        .then(handleModifyTemplateResponse)
        .catch((e) => {
          logger.error('Failed to modify template', e);
          const errorMessage: ComposeAssistantConversationMessage = {
            type: 'message',
            role: 'assistant',
            text: i18n.t('assist_templates_modification_error_msg'),
            ts: Date.now(),
            actionContext: { type: 'done' },
          };
          setCurrentTemplateState((prevTemplate) => ({
            ...prevTemplate,
            messagesHistory: [
              ...(prevTemplate.messagesHistory || []),
              errorMessage,
            ],
          }));
          setLoading(false);
        });
    },
    [
      currentTemplateState,
      logComposeEvent,
      handleModifyTemplateResponse,
      sourcesContents,
    ],
  );

  const handleAccessTypeChange = useCallback((newAccessType: AccessType) => {
    setCurrentTemplateState((prevTemplate) => ({
      ...prevTemplate,
      accessType: newAccessType,
    }));
  }, []);

  return {
    loading,
    setLoading,
    templates,
    selectedTemplateID,
    setSelectedTemplateID,
    currentTemplateState,
    setCurrentTemplateState,
    sourcesContents,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    handleAddSource,
    handleRemoveSource,
    handleChangeTemplateNameInput,
    handleChangeTemplateDescriptionInput,
    handleModifyTemplatePostUserMessage,
    handleModifyTemplateResponse,
    handleClickSave,
    handleClickDelete,
    handleAccessTypeChange,
    resetTemplateState,
  };
};
