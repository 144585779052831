import {
  useEligibilityCheck,
  useUpdateWithCheckResult,
} from '@mirage/eligibility/useEligibilityCheck';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { tagged } from '@mirage/service-logging';
import { openURL } from '@mirage/service-platform-actions';
import { useCallback, useEffect, useState } from 'react';

import type { dash_async } from '@dropbox/api-v2-client';

const logger = tagged('WebEligibilityCheck');

// Conditional hook as component.
export const WebEligibilityCheck: React.FC<{
  getEligibilityFromStore: () => Promise<
    dash_async.ShouldRedirectIneligibleUserResponse | undefined
  >;
  logout: () => Promise<void>;
  isDevboxBackend: boolean;
}> = ({ getEligibilityFromStore, logout, isDevboxBackend }) => {
  const publicAccountId = useDropboxAccount()?.public_account_id;

  const onLogout = useCallback(
    async (redirectUrl: string) => {
      /*
      If a user is ineligible, we want to always:
      - Log them out
      - Open a new tab for the redirectUrl
      */
      openURL(redirectUrl);
      logout();
    },
    [logout],
  );

  const [needCheck, setNeedCheck] = useState<boolean>();
  const updateWithCheckResult = useUpdateWithCheckResult(
    publicAccountId,
    isDevboxBackend,
  );

  useEffect(() => {
    if (needCheck !== undefined) {
      return;
    }

    let canceled = false;

    getEligibilityFromStore()
      .then((result) => {
        if (canceled) return;

        if (result) {
          setNeedCheck(false);
          updateWithCheckResult(result);
        } else {
          setNeedCheck(true);
        }

        return;
      })
      .catch((error) => {
        logger.error('Error fetching eligibility from store:', error);
        setNeedCheck(true);
      });

    return () => {
      canceled = true;
    };
  }, [getEligibilityFromStore, needCheck, updateWithCheckResult]);

  return needCheck && publicAccountId ? (
    <PerformCheck
      onLogout={onLogout}
      publicAccountId={publicAccountId}
      isDevboxBackend={isDevboxBackend}
    />
  ) : null;
};

// Conditional hook as component.
const PerformCheck: React.FC<{
  onLogout: (str: string) => void;
  publicAccountId: string | undefined;
  isDevboxBackend: boolean;
}> = ({ onLogout, publicAccountId, isDevboxBackend }) => {
  useEligibilityCheck(onLogout, publicAccountId, isDevboxBackend);

  return null;
};
