import { postUserMessage } from '@mirage/service-conversation';
import useConversation from '@mirage/service-conversation/hooks/useConversation';
import { convertToChatMessage } from '@mirage/service-conversation/utils/convertToChatMessage';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

export const useInitialConversation = () => {
  const [conversationId, setConversationId] = useState<string>();

  const messages = useConversation(conversationId);

  const composeMessages = useMemo(
    () => messages.map(convertToChatMessage),
    [messages],
  );

  const isWaitingForResponse = useMemo(() => {
    return (
      messages.length === 0 ||
      messages.some((message) => message.status === 'pending')
    );
  }, [messages]);

  const startNewConversation = useCallback((query: string) => {
    const newConversationId = uuid();
    setConversationId(newConversationId);

    postUserMessage({
      conversationId: newConversationId,
      message: query,
      documentIds: [],
    });
  }, []);

  return {
    messages: composeMessages,
    isWaitingForResponse,
    conversationId,
    startNewConversation,
  };
};
