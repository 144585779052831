import { UIIcon } from '@dropbox/dig-icons';
import styles from './MetadataItem.module.css';

import type { RefObject } from 'react';

export type MetadataItemProps = {
  title: React.ReactNode;
  iconSrc?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  triggerRef?: RefObject<HTMLDivElement>;
};

export const MetadataItem = ({
  title,
  iconSrc,
  triggerRef,
}: MetadataItemProps) => {
  return (
    <div>
      {iconSrc && (
        <div ref={triggerRef}>
          <UIIcon className={styles.icon} src={iconSrc} size="small" />
        </div>
      )}
      {title}
    </div>
  );
};
