import { useActivationForConnectors } from '@mirage/service-connectors/useActivationForConnectors';
import { useUIConnectors } from '@mirage/settings/hooks/connectors/useUIConnectors';
import { fetchConnectorsWithVisibilityFilters } from '@mirage/settings/utils/connectorMetadataService';
import { ConnectorName } from '@mirage/shared/connectors';
import { mostPopularApps } from '@mirage/shared/connectors/popularApps';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

import type { dash_connectors } from '@dropbox/api-v2-client';
import type { Connector } from '@mirage/service-dbx-api';

const useVisibleConnectors = () => {
  const [visibleConnectors, setVisibleConnectors] = useState<
    dash_connectors.Connector[]
  >([]);
  const [hasFetchedVisibleConnectors, setHasFetchedVisibleConnectors] =
    useState<boolean>(false);

  useEffect(() => {
    fetchConnectorsWithVisibilityFilters()
      .then((data) => {
        const connectorsResult: Connector[] = data;

        setVisibleConnectors(connectorsResult);
      })
      .finally(() => {
        setHasFetchedVisibleConnectors(true);
      });
  }, []);

  return { visibleConnectors, hasFetchedVisibleConnectors };
};

export const useGetSuggestedApps = (
  numApps: number,
  appList: ConnectorName[] = mostPopularApps,
) => {
  const { connectors, hasFinishedInitialFetch, refreshConnectionsList } =
    useActivationForConnectors();
  const [suggestedAppTypes, setSuggestedAppTypes] = useState<string[]>([]);
  const { connectors: suggestedApps } = useUIConnectors(suggestedAppTypes);
  const { visibleConnectors, hasFetchedVisibleConnectors } =
    useVisibleConnectors();

  useEffect(() => {
    if (!hasFinishedInitialFetch || !hasFetchedVisibleConnectors) {
      return;
    }

    const connectedApps = new Set(
      connectors.map((connector) => connector?.id_attrs?.type),
    );
    const visibleConnectorApps = new Set(
      visibleConnectors.map((connector) => connector?.id_attrs?.type),
    );
    setSuggestedAppTypes((existingAppTypes) => {
      const newAppTypes = appList
        .filter((connectorName) => visibleConnectorApps.has(connectorName))
        .filter((connectorName) => !connectedApps.has(connectorName))
        .slice(0, numApps);
      if (isEqual(newAppTypes, existingAppTypes)) {
        return existingAppTypes;
      }
      return newAppTypes;
    });
  }, [
    numApps,
    connectors,
    hasFinishedInitialFetch,
    hasFetchedVisibleConnectors,
    visibleConnectors,
  ]);

  return { hasFinishedInitialFetch, suggestedApps, refreshConnectionsList };
};
