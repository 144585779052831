import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CommentLine } from '@dropbox/dig-icons/assets';
import {
  activeStackItemCommentPromptAtom,
  setShouldShowStackCommentPromptAtom,
} from '@mirage/service-comments/atoms/stack';
import { setCommentPromptSeen } from '@mirage/service-comments/utils/stack';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useStackItemComment } from '@mirage/stacks/Comments/hooks/useStackItemComments';
import i18n from '@mirage/translations';
import { useAtomValue, useSetAtom } from 'jotai';
import { useRef } from 'react';
import { CommentPromptTooltip } from '../CommentPromptTooltip';
import styles from './WithCommentIconButtonWithTooltip.module.css';

import type { StackComment } from '@mirage/service-comments/types';

/** HOC for properly showing tooltip accessory component
 *
 * @param currentAuthor
 * @param isDisabled
 * @param stackItemId
 * @param stackSharingId
 * @constructor
 */
export const WithCommentIconButtonWithTooltip = ({
  currentAuthor,
  isDisabled,
  stackItemId,
  stackSharingId,
}: {
  currentAuthor: StackComment['author'];
  isDisabled: boolean;
  stackItemId: string;
  stackSharingId: string;
}) => {
  const buttonRef = useRef(null);
  const activeCommentPrompt = useAtomValue(activeStackItemCommentPromptAtom);
  const setShouldShowCommentPrompt = useSetAtom(
    setShouldShowStackCommentPromptAtom,
  );

  const { onToggleComment, canToggleCommentIcon } = useStackItemComment(
    stackSharingId,
    stackItemId,
  );

  const handleDismissPrompt = async () => {
    setShouldShowCommentPrompt(false);

    // This is a background operation, we don't need to wait for it to complete
    void setCommentPromptSeen();
  };

  const handleToggleComment = () => {
    // Only do this if the prompt is shown to prevent incorrect setting of user metadata
    if (activeCommentPrompt === stackItemId) {
      handleDismissPrompt();
    }

    onToggleComment(currentAuthor);
  };

  if (!stackItemId || !canToggleCommentIcon) {
    return null;
  }

  // If the prompt is shown, we don't use the IconButtonWithTooltip component
  // because it doesn't support our more advanced prompt tooltip and we don't want
  // duplicates shown on hover.
  if (activeCommentPrompt === stackItemId && !isDisabled) {
    return (
      <>
        <IconButton
          className={styles.accessoryButton}
          onClick={handleToggleComment}
          ref={buttonRef}
          size="medium"
          variant="borderless"
        >
          <UIIcon src={CommentLine} />
        </IconButton>
        <CommentPromptTooltip
          onDismiss={handleDismissPrompt}
          triggerRef={buttonRef}
        />
      </>
    );
  }

  return (
    <IconButtonWithTooltip
      variant="borderless"
      size="medium"
      onClick={handleToggleComment}
      className={styles.accessoryButton}
      tooltipProps={{
        title: isDisabled
          ? i18n.t('add_comment_disabled_tooltip')
          : i18n.t('add_comment_tooltip'),
        isPortaled: true,
      }}
      disabled={isDisabled}
      ref={buttonRef}
    >
      <UIIcon src={CommentLine} />
    </IconButtonWithTooltip>
  );
};
