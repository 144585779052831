import { usePasteStackItemAnywhere } from './usePasteStackItemAnywhere';

import type { stacks } from '@dropbox/api-v2-client';

export const PasteStackItemAnywhere = ({
  stack,
}: {
  stack: stacks.Stack | null;
}) => {
  usePasteStackItemAnywhere(stack);
  return <></>;
};
