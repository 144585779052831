import { tagged } from '@mirage/service-logging';
import { useEffect, useMemo } from 'react';

import type { BorderRadius, BoxShadow } from './utils';

const logger = tagged('Flip');

/**
 * A hook to return and validate properties of the element that don't naturally scale well.
 *
 * Some properties of an element scale fine — think background colors. Some start to get weird when
 * they scale though. A border radius will skew, for instance. Some of those properties can be
 * corrected to look nice while others can't and will always scale weirdly.
 *
 * This returns attributes for the properties we can correct and warns if it detects properties that
 * will never scale well.
 */
export function useNonScalableProperties(el: HTMLDivElement | null): {
  boxShadow?: BoxShadow;
  borderRadius?: BorderRadius;
} {
  const { rawBoxShadow, rawBorderRadius, rawBorder, rawDisplay } =
    useMemo(() => {
      if (!el) {
        return {};
      }
      const computedStyle = getComputedStyle(el);

      const rawBoxShadow = computedStyle.boxShadow || undefined; // Ignore empty string
      const rawBorderRadius = computedStyle.borderRadius || undefined; // Ignore empty string
      const rawBorder = computedStyle.border || undefined; // Ignore empty string
      const rawDisplay = computedStyle.display || undefined; // Ignore empty string

      return {
        rawBoxShadow,
        rawBorderRadius,
        rawBorder,
        rawDisplay,
      };
    }, [el]);

  useFLIPScaleConformanceHinter({
    rawBoxShadow,
    rawBorderRadius,
    rawBorder,
    rawDisplay,
  });

  const boxShadow = useMemo(
    () =>
      hasBoxShadow(rawBoxShadow) && isValidBoxShadow(rawBoxShadow)
        ? boxShadowProps(rawBoxShadow)
        : undefined,
    [rawBoxShadow],
  );

  const borderRadius = useMemo(
    () =>
      hasBorderRadius(rawBorderRadius) && isValidBorderRadius(rawBorderRadius)
        ? borderRadiusProps(rawBorderRadius)
        : undefined,
    [rawBorderRadius],
  );

  return { boxShadow, borderRadius };
}

export function useFLIPScaleConformanceHinter({
  rawBoxShadow,
  rawBorderRadius,
  rawBorder,
  rawDisplay,
}: {
  rawBoxShadow?: string;
  rawBorderRadius?: string;
  rawBorder?: string;
  rawDisplay?: string;
}) {
  useEffect(() => {
    if (hasBoxShadow(rawBoxShadow) && !isValidBoxShadow(rawBoxShadow)) {
      // If you're seeing this, know that the browser may rearrange your "box-shadow" to an
      // equivalent format or turn colors into another format like RGB.
      //
      // It's possible that we could add more support but it'll be complicated.
      logger.warn(
        `Found "box-shadow" value that will skew when animated: "${rawBoxShadow}"

          useFLIP uses scale correction on box-shadow but will only support some limited values.
          Right now it just supports a shadow used to fake a border, meaning one that has no blur or
          offset. That'll come in the form "0 0 0 <length> <color>".`,
      );
    }
  }, [rawBoxShadow]);

  useEffect(() => {
    if (
      hasBorderRadius(rawBorderRadius) &&
      !isValidBorderRadius(rawBorderRadius)
    ) {
      logger.warn(
        `Found "border-radius" value that will skew when animated: "${rawBorderRadius}"

          useFLIP uses scale correction on border-radius but will only support limited values. Right
          now it just supports a single value radius. That'll come in the form "<length>". We can
          add more support but it's not currently needed.`,
      );
    }
  }, [rawBorderRadius]);

  useEffect(() => {
    if (rawBorder) {
      // We need to use box shadows instead of borders on elements that FLIP.
      //
      // That takes the "border" out of the layout flow so that we can skew correct it without
      // causing layout thrash. If we try to adjust the width of an actual border it will affect the
      // surrounding DOM structure of the element causing jank.
      logger.warn(
        `Do not use "border" on elements affected by FLIP animations. Found border "${rawBorder}"

          useFLIP cannot correct the scale of an element's border — it will skew as the element
          changes size. Instead, fake a border with "box-shadow" by giving it zero offset and zero
          blur.

          Example
          border: 1px solid blue → box-shadow: 0 0 0 1px blue`,
      );
    }
  }, [rawBorder]);

  useEffect(() => {
    const supportedDisplays = ['block', 'flex', 'grid', 'inline-block'];
    if (rawDisplay && !supportedDisplays.includes(rawDisplay)) {
      // Others might work but no promises. "inline" definitely doesn't.
      logger.warn(
        `Do not use display "${rawDisplay}" on elements affected by FLIP animations.
         The only supported values are ${supportedDisplays}`,
      );
    }
  }, [rawDisplay]);
}

const FAKE_BORDER_BOX_SHADOW_REGEX =
  /^(?<color>.+) 0px 0px 0px (?<stringifiedWidth>\d+)(?<unit>\w+)(?<inset> inset)?$/;

function hasBoxShadow(boxShadow: string | undefined): boxShadow is string {
  return !!boxShadow && boxShadow !== 'none';
}

function isValidBoxShadow(boxShadow: string | undefined): boolean {
  if (!boxShadow) return false;

  try {
    boxShadowProps(boxShadow);
    return true;
  } catch {
    return false;
  }
}

function boxShadowProps(boxShadow: string): BoxShadow {
  const match = boxShadow.match(FAKE_BORDER_BOX_SHADOW_REGEX);

  if (!match?.groups) {
    throw new Error(
      "Didn't match the box shadow regex, please check that it matches first",
    );
  }

  const { stringifiedWidth, unit, color, inset } = match.groups;
  const isInset = !!inset;
  const width = stringifiedWidth ? parseFloat(stringifiedWidth) : undefined;

  if (!width || isNaN(width) || !unit || !color) {
    throw new Error(
      "Didn't match the box shadow regex, please check that it matches first",
    );
  }

  return { width, unit, color, isInset };
}

const BORDER_RADIUS_REGEX = /^(?<stringifiedWidth>\d+)(?<unit>\w+)$/;

function hasBorderRadius(
  borderRadius: string | undefined,
): borderRadius is string {
  return !!borderRadius && borderRadius !== '0px';
}

function isValidBorderRadius(borderRadius: string | undefined): boolean {
  if (!borderRadius) return false;

  try {
    borderRadiusProps(borderRadius);
    return true;
  } catch {
    return false;
  }
}

function borderRadiusProps(borderRadius: string): BorderRadius {
  const match = borderRadius.match(BORDER_RADIUS_REGEX);

  if (!match?.groups) {
    throw new Error(
      "Didn't match the border radius regex, please check that it matches first",
    );
  }

  const { stringifiedWidth, unit } = match.groups;
  const width = stringifiedWidth ? parseFloat(stringifiedWidth) : undefined;

  if (!width || isNaN(width) || !unit) {
    throw new Error(
      "Didn't match the border radius regex, please check that it matches first",
    );
  }

  return { width, unit };
}
