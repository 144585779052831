import { Badge } from '@dropbox/dig-components/badge';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Checkbox } from '@dropbox/dig-components/controls';
import {
  type TriggerRef,
  type UseMenuTriggerProps,
} from '@dropbox/dig-components/hooks';
import { Menu } from '@dropbox/dig-components/menu';
import { Box, Cluster } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { FilterLine, StacksFill } from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import useConnectors from '@mirage/service-connectors/useConnectors';
import {
  isSupportedActivityFeedConnector,
  useSupportedFeedConnectorNames,
} from '@mirage/service-feed/util/helpers';
import { ConnectorIcon } from '@mirage/shared/icons';
import { sortDisplayFilters } from '@mirage/shared/search/search-filters';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import styles from './ApplicationsFilter.module.css';
import { type ApplicationsMenuSelection } from './FiltersMenu';

import type { Connector } from '@mirage/service-dbx-api/service';
import type { ActivityFeedApplication } from '@mirage/service-feed/types';

type ApplicationFilterMenuSegmentProps = {
  activeApplications?: ActivityFeedApplication[];
  onSetActiveApplications: (applications?: ActivityFeedApplication[]) => void;
};

const useActiveApplicationFilters = (
  activeApplications?: ActivityFeedApplication[],
) => {
  const { connectors } = useConnectors();
  const supportedConnectorNames = useSupportedFeedConnectorNames();

  const isActiveApplication = useCallback(
    (application: ActivityFeedApplication) => {
      // if we have no application filter selected at all (even not manually unchecked), we default to all applications
      if (activeApplications === undefined) {
        return true;
      }

      return activeApplications.includes(application);
    },
    [activeApplications],
  );

  const filters = useMemo(() => {
    let results = connectors
      .filter((connector: Connector) =>
        isSupportedActivityFeedConnector(connector, supportedConnectorNames),
      )
      .map((connector: Connector) => {
        const name = connector.id_attrs!.type! as ActivityFeedApplication;
        const label = connector.branding!.display_name!;
        const icon = connector.branding!.icon_src!;

        const isSelected = isActiveApplication(name);

        const connectorFilter = {
          key: name,
          label,
          icon: (
            <div role="presentation">
              <ConnectorIcon connectorName={name} iconUrl={icon} size={16} />
            </div>
          ),
          selected: isSelected,
        };

        return connectorFilter;
      });

    results = sortDisplayFilters(results);

    results.push({
      key: 'stacks',
      label: i18n.t('stacks'),
      icon: <UIIcon src={StacksFill} width={20} role="presentation" />,
      selected: isActiveApplication('stacks'),
    });

    return results;
  }, [connectors, isActiveApplication, supportedConnectorNames]);

  return { filters };
};

export const ApplicationFilterMenuSegment = ({
  activeApplications,
  onSetActiveApplications,
}: ApplicationFilterMenuSegmentProps) => {
  const { filters } = useActiveApplicationFilters(activeApplications);

  const showSelectAll = filters.some((option) => !option.selected);

  const allApplicationOptions = useMemo(
    () => filters.map((option) => option.key),
    [filters],
  );

  const selectAll = useCallback(() => {
    onSetActiveApplications(undefined);
  }, [onSetActiveApplications]);

  return (
    <Menu.Segment
      withLabel={
        <>
          {i18n.t('filter_by_app')}
          {showSelectAll && (
            <Button
              size="small"
              variant="transparent"
              onClick={selectAll}
              data-uxa-log={createUxaElementId(
                `applications_filter_select_all`,
                {
                  actionSurfaceComponent: 'activity_feed',
                  featureLine: 'activity_feed',
                },
              )}
            >
              {i18n.t('select_all')}
            </Button>
          )}
        </>
      }
    >
      {filters.map((filter, index) => (
        <Menu.ActionItem
          key={index}
          value={
            {
              segmentKey: 'applications',
              application: filter.key,
              allApplicationOptions,
            } as ApplicationsMenuSelection
          }
          role="menuitemcheckbox"
          aria-checked={filter.selected}
          data-uxa-log={createUxaElementId(
            `applications_filter_${filter.key}`,
            {
              actionSurfaceComponent: 'activity_feed',
              featureLine: 'activity_feed',
            },
          )}
          withLeftAccessory={
            <Checkbox
              role="presentation"
              checked={filter.selected}
              readOnly
              tabIndex={-1}
            />
          }
          withRightAccessory={filter.icon}
        >
          {filter.label}
        </Menu.ActionItem>
      ))}
    </Menu.Segment>
  );
};

export const SelectedApplicationsChip = ({
  activeApplications,
  menuTriggerProps,
  menuTriggerRef,
  maxIconsToShow = 3,
}: {
  activeApplications?: ActivityFeedApplication[];
  maxIconsToShow?: number; // the maximum number of icons to display before showing overflow badge
  menuTriggerProps: UseMenuTriggerProps;
  menuTriggerRef: TriggerRef;
}) => {
  const hasActiveApplications =
    activeApplications && activeApplications.length > 0;
  const { filters } = useActiveApplicationFilters(activeApplications);
  const selectedFilters = filters.filter((f) => f.selected);
  let numIconsToShow = selectedFilters.length;
  if (selectedFilters.length > maxIconsToShow) {
    numIconsToShow = maxIconsToShow - 1; // show 1 less than the max + overflow badge
  }
  const activeFilterOverflowCount = selectedFilters.length - numIconsToShow;

  // we're passing trigger props to the parent Box to avoid re-layout issues when we switch between Chip and IconButton
  // however, the aria props need to be on the actual focusable elements (the Chip/IconButton)
  const {
    'aria-haspopup': ariaHasPopup,
    'aria-expanded': ariaExpanded,
    ...triggerProps
  } = menuTriggerProps;
  return (
    <DigTooltip
      title={i18n.t('activity_feed_filters_menu_toggle_tooltip')}
      triggerRef={menuTriggerRef}
      openDelay={200}
      isPortaled={false}
    >
      <Box {...triggerProps}>
        {hasActiveApplications ? (
          <Chip
            variant="transparent"
            selectedStyle="stroke"
            isSelected={hasActiveApplications}
            withDropdownIcon
            onClick={() => {}} // this is needed to make the chip look clickable even though it's handled by the parent box
            className={classnames(styles.chip, {
              [styles.selected]: hasActiveApplications,
            })}
            aria-label={i18n.t('activity_feed_filters_menu_toggle_tooltip')}
            aria-haspopup={ariaHasPopup}
            aria-expanded={ariaExpanded}
          >
            <Chip.Content>
              <Cluster alignY="center">
                {selectedFilters.slice(0, numIconsToShow).map((filter) => {
                  return (
                    <Cluster.Item key={filter.key}>
                      <Chip.IconAccessory>{filter.icon}</Chip.IconAccessory>
                    </Cluster.Item>
                  );
                })}
                {activeFilterOverflowCount > 0 && (
                  <Cluster.Item>
                    <Badge>+{activeFilterOverflowCount}</Badge>
                  </Cluster.Item>
                )}
              </Cluster>
            </Chip.Content>
          </Chip>
        ) : (
          <IconButton
            variant="borderless"
            withDropdownIcon
            aria-haspopup={ariaHasPopup}
            aria-expanded={ariaExpanded}
            aria-label={i18n.t('activity_feed_filters_menu_toggle_tooltip')}
          >
            <UIIcon src={FilterLine} />
          </IconButton>
        )}
      </Box>
    </DigTooltip>
  );
};
