// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1931
import type { PAPEventGeneric } from '../base/event';
import type { DashSurface } from '../enums/dash_surface';
import { StackIconUpdate } from '../enums/stack_icon_update';

// Event_name: 'update.stack_icon_color'
// Description: Update the color of the icon on a stack
// Owner: otc-startpage
export type Update_StackIconColor = PAPEventGeneric<
  'stacks',
  'update',
  'stack_icon_color',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // Property used to identify if stack color is custom or out of 16 existing themes
    stackIconUpdate?: StackIconUpdate;
  }
>;

export function PAP_Update_StackIconColor(
  properties?: Update_StackIconColor['properties'],
): Update_StackIconColor {
  return <Update_StackIconColor>{
    class: 'stacks',
    action: 'update',
    object: 'stack_icon_color',
    properties,
  };
}
