import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { Service } from '@mirage/service-provisioning/service';

export const service = services.get<Service>(ServiceId.PROVISIONING);

export function provisionFreeDashTeam(teamName: string): Promise<boolean> {
  return rx.firstValueFrom(service.provisionFreeDashTeam(teamName));
}
