import { PAP_Click_VoiceSaveButton } from '@mirage/analytics/events/types/click_voice_save_button';
import { SourcesFromSettingsModalStepper } from '@mirage/mosaics/ComposeAssistant/components/settings/SourcesFromSettingsModalStepper';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import i18n from '@mirage/translations';
import { memo, useCallback } from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { SourcesFromSettingsConversationPane } from './SourcesFromSettingsConversationPane';
import { SourcesFromSettingsOne } from './SourcesFromSettingsOne';
import { SourcesFromSettingsPreviewPane } from './SourcesFromSettingsPreviewPane';
import { SourcesFromSettingsSharingToggle } from './SourcesFromSettingsSharingToggle';
import { SourcesFromSettingsThree } from './SourcesFromSettingsThree';
import { SourcesFromSettingsTwo } from './SourcesFromSettingsTwo';
import { useVoiceSettings } from './useVoiceSettings';

import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { ComposeVoice } from '@mirage/shared/compose/compose-voice';

interface VoiceSettingsModalProps {
  voices: ComposeVoice[];
  saveVoice: (voice: ComposeVoice) => Promise<void>;
  onDeleteVoice: (voiceId: string) => void;
  initialVoiceID?: string;
  onRequestClose: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
}
export const VoiceSettingsModal = memo(
  ({
    voices,
    saveVoice,
    onDeleteVoice,
    initialVoiceID,
    onRequestClose,
    logComposeEvent,
  }: VoiceSettingsModalProps) => {
    const {
      currentVoiceState,
      isDeleteModalOpen,
      loading,
      sourcesContents,
      setIsDeleteModalOpen,
      handleAddSource,
      handleRemoveSource,
      handleClickDelete,
      handleChangeVoiceNameInput,
      handleChangeVoiceDescriptionInput,
      handleModifyVoicePostUserMessage,
      handleClickSave,
      handleAccessTypeChange,
    } = useVoiceSettings({
      voices,
      saveVoice,
      initialVoiceID,
      logComposeEvent,
      onDone: onRequestClose,
    });
    const onMount = useCallback(() => {
      if (
        currentVoiceState.messagesHistory?.length === 0 &&
        currentVoiceState.redraftedSamples?.length === 0
      ) {
        handleModifyVoicePostUserMessage();
      }
    }, [
      handleModifyVoicePostUserMessage,
      currentVoiceState.messagesHistory,
      currentVoiceState.redraftedSamples,
    ]);

    // always add step 1
    const widths = [693];
    const steps = [
      <SourcesFromSettingsOne
        key={0}
        title={i18n.t('compose_tones_pane_title')}
        description={i18n.t('compose_tones_pane_description')}
        sources={currentVoiceState?.sources}
        handleAddSource={handleAddSource}
        handleRemoveSource={handleRemoveSource}
        sourcesContents={sourcesContents}
        logComposeEvent={logComposeEvent}
      />,
    ];
    // only add step 2 when feature flag is enabled
    const tonesEditPreviewEnabled =
      useFeatureFlagValue('dash_2024_12_10_assist_tones_edit_preview') === 'ON';
    if (tonesEditPreviewEnabled) {
      widths.push(1083);
      steps.push(
        <SourcesFromSettingsTwo
          key={1}
          loading={loading}
          onMount={onMount}
          leftPaneContent={
            <SourcesFromSettingsConversationPane
              messages={currentVoiceState.messagesHistory}
              logComposeEvent={logComposeEvent}
              sources={currentVoiceState.sources}
              onSubmitMessage={handleModifyVoicePostUserMessage}
              loading={loading}
              loadingText={i18n.t('compose_settings_loading_message')}
            />
          }
          rightPaneContent={
            <SourcesFromSettingsPreviewPane
              currentVoiceState={currentVoiceState}
            />
          }
        />,
      );
    }
    // always add step 3
    const tonesSharingEnabled =
      useFeatureFlagValue('dash_2024_12_17_assist_tones_sharing') === 'ON';
    widths.push(693);
    steps.push(
      <SourcesFromSettingsThree
        key={2}
        title={i18n.t('compose_tones_modal_stepper_save_tone_title')}
        namePlaceholder={i18n.t('compose_tones_modal_stepper_name_placeholder')}
        descriptionPlaceholder={i18n.t(
          'compose_tones_modal_stepper_description_placeholder',
        )}
        onNameInputChange={handleChangeVoiceNameInput}
        onDescriptionInputChange={handleChangeVoiceDescriptionInput}
        nameValue={currentVoiceState.name}
        descriptionValue={currentVoiceState.description}
        deleteModal={
          <DeleteConfirmationModal
            title={i18n.t('compose_delete_tones_confirm_title')}
            description={i18n.t('compose_delete_tones_confirm_description')}
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={() => {
              onDeleteVoice(currentVoiceState.id);
            }}
            isOpen={isDeleteModalOpen}
          />
        }
        sharingSettings={
          tonesSharingEnabled ? (
            <SourcesFromSettingsSharingToggle
              sublabelText={i18n.t('compose_settings_from_sources_sublabel')}
              accessType={currentVoiceState.accessType}
              onAccessTypeChange={handleAccessTypeChange}
            />
          ) : null
        }
      />,
    );
    return currentVoiceState ? (
      <SourcesFromSettingsModalStepper
        steps={steps}
        widths={widths}
        isOpen
        onRequestClose={onRequestClose}
        onDone={() => {
          handleClickSave();
          logComposeEvent(
            PAP_Click_VoiceSaveButton({
              actionType: currentVoiceState.accessType,
            }),
          );
        }}
        onDelete={handleClickDelete}
      />
    ) : null;
  },
);
VoiceSettingsModal.displayName = 'VoiceSettingsModal';
