import {
  DEFAULT_SECTION,
  DEFAULT_SECTION_ID,
  isStackItemShortcut,
  stackItemComputeNameFromFields,
} from '@mirage/service-stacks/service/utils';
import { sortKeyCompare } from '@mirage/shared/util/tiny-utils';
import partition from 'lodash/partition';
import { areStackItemsOrdered } from '../utils';

import type { stacks } from '@dropbox/api-v2-client';
import type { StackItem } from '@mirage/service-stacks/service/types';

/**
 * Check if the path is a stack path. Path must start with `/stacks/` and have
 * something after it. Ensure that `/stacks` (all stacks page) is not considered
 * a valid stack path.
 * @param path - The path to check.
 * @returns True if the path is a stack path, false otherwise.
 */
export const isStackPath = (path: string | null): boolean => {
  if (!path) return false;
  const regex = /^\/stacks\/[^/]+(?:\/.*)?$/;
  return regex.test(path);
};

export const sortStackItemsForCompare = (items: StackItem[] | null) => {
  if (!items) return null;

  const [shortcuts, others] = partition(items, isStackItemShortcut);

  const sortedShortcuts = shortcuts.sort(
    (a, b) =>
      (b.last_modified_time_utc_sec || 0) - (a.last_modified_time_utc_sec || 0),
  );

  return [...sortedShortcuts, ...others];
};

export const sortSectionsForCompare = (
  sections: stacks.Section[],
): stacks.Section[] => {
  return [...sections].sort((a, b) => {
    const idA = a.id ?? '';
    const idB = b.id ?? '';
    return idA.localeCompare(idB);
  });
};

const sortStackItemShortcuts = (items: stacks.StackItemShortcut[]) => {
  if (areStackItemsOrdered(items)) {
    // confirm that the sort keys are unique (hence orderable)
    items.sort((item1, item2) => {
      const a = item1.sort_key;
      const b = item2.sort_key;
      if (a === undefined || b === undefined) {
        return 0;
      }
      return sortKeyCompare(a, b);
    });
    return;
  }
  items.sort((a, b) =>
    stackItemComputeNameFromFields(a).localeCompare(
      stackItemComputeNameFromFields(b),
      'en',
      { sensitivity: 'base' },
    ),
  );
};

export const initLinkSectionMap = (
  sections: stacks.Section[],
  items: stacks.StackItemShortcut[],
) => {
  const map = new Map<string, stacks.StackItemShortcut[]>();
  const knownSectionIds = new Set(sections.map((section) => section.id));

  for (const item of items) {
    let sectionId = item.parent_section_id ?? DEFAULT_SECTION_ID;
    if (!knownSectionIds.has(sectionId)) {
      sectionId = DEFAULT_SECTION_ID;
    }
    let links = map.get(sectionId);
    if (!links) {
      links = [];
      map.set(sectionId, links);
    }
    links.push(item);
  }

  for (const links of map.values()) {
    sortStackItemShortcuts(links);
  }

  return map;
};

export const initSections = (
  inputSections: stacks.Section[] | null,
): stacks.Section[] => {
  const sections = inputSections ?? [];
  const defaultSection =
    sections.find((section) => section.id === DEFAULT_SECTION_ID) ??
    DEFAULT_SECTION;
  const otherSections = sections.filter(
    (section) => section.id !== DEFAULT_SECTION_ID,
  );
  return [defaultSection, ...otherSections];
};
