import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type {
  SearchRequestArguments,
  Service,
} from '@mirage/service-relevance-snapshot/service';

const service = services.get<Service>(ServiceId.RELEVANCE_SNAPSHOT);

export function cacheLastSearchRequestArguments(
  args: SearchRequestArguments,
): Promise<void> {
  return rx.firstValueFrom(service.cacheLastSearchRequestArguments(args));
}

export function cacheLastSearchRequestId(id: string): Promise<void> {
  return rx.firstValueFrom(service.cacheLastSearchRequestId(id));
}

export function saveRelevanceSnapshot(trace_id: string): Promise<void> {
  return rx.firstValueFrom(service.saveRelevanceSnapshot(trace_id));
}
