import React from 'react';
import styles from './Header.module.css';

interface StackHeaderActionsProps {
  action: React.ReactNode;
}

const StackHeaderActions: React.FC<StackHeaderActionsProps> = ({ action }) => {
  return <div className={styles.headerButtons}>{action}</div>;
};

export default StackHeaderActions;
