import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { contentTypeToSearchFilter } from '@mirage/shared/search/search-filters';
import * as rx from 'rxjs';

import type { Connector } from '@mirage/service-dbx-api/service';
import type { SearchOptions, Service } from '@mirage/service-search/service';
import type { ContentType } from '@mirage/shared/content-type/content-types';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

const service = services.get<Service>(ServiceId.SEARCH);

export function performSearch(
  searchQuery: string,
  connections: Connector[],
  filters: SearchFilter[],
  options: SearchOptions,
) {
  return service.performSearch(searchQuery, connections, filters, options);
}

export async function searchForContentType(
  query: string,
  contentType: ContentType,
  limit: number = 3,
  messagesVerticalEnabled: boolean = false,
  multimediaVerticalEnabled: boolean = false,
) {
  const filter = contentTypeToSearchFilter(contentType);
  if (!filter || !query) return [];
  const resp = await rx.firstValueFrom(
    service.performSearch(query, [], [filter], {
      localMixinThreshold: 0,
      isLocalFilesEnabled: false,
      disableSpellCorrection: true,
      messagesVerticalEnabled,
      multimediaVerticalEnabled,
    }),
  );
  return resp?.results.slice(0, limit) || [];
}
