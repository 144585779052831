import { IconLayout } from '@dropbox/dash-component-library';
import { ListItemSize } from '../types';
import { FavIcon } from './Favicon';

type FaviconWithThumbnailProps = {
  src?: string;
  size?: ListItemSize;
  pureImg?: boolean;
  thumbnailUrl?: string;
};

export const FaviconWithThumbnail = ({
  src,
  size = ListItemSize.Large,
  pureImg,
  thumbnailUrl,
}: FaviconWithThumbnailProps) => {
  if (thumbnailUrl) {
    return (
      <IconLayout
        hasBackground={false}
        hasBorder={true}
        hasThumbnail={true}
        content={
          <img
            src={thumbnailUrl}
            alt="thumbnail"
            // Note: we want the thumbnail to cover the parent
            // but also respect the rounded corners of the layout icon
            style={{ objectFit: 'cover', borderRadius: 6 }}
          />
        }
        size={size}
      />
    );
  }

  return <FavIcon src={src} size={size} pureImg={pureImg} />;
};
