import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Rename_DashStack } from '@mirage/analytics/events/types/rename_dash_stack';
import { PAP_Update_StacksDescription } from '@mirage/analytics/events/types/update_stacks_description';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook/hook';
import { useStackChat } from '@mirage/service-experimentation/useStackChat';
import { tagged } from '@mirage/service-logging';
import { stackDerivePAPProps, upsertStack } from '@mirage/service-stacks';
import Sentry from '@mirage/shared/sentry';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useCallback, useMemo, useState } from 'react';
import { PAP_Update_StackEmoji } from '../../../analytics/events/types/update_stack_emoji';
import { canEditStack } from '../../utils';
import { asStackUpsertId, newStackPapEvent } from '../utils';
import HeaderAugustRev from './HeaderAugustRev';
import HeaderV1 from './HeaderV1';
import HeaderWithChat from './HeaderWithChat';

import type { EmojiData } from '../EmojiPicker';
import type { StackDerivedPAPPropsWithFeature } from '../types';
import type { HeaderProps } from './HeaderCommon';
import type { stacks } from '@dropbox/api-v2-client';
import type {
  PAPEvent,
  PAPEventDefaultProperties,
} from '@mirage/analytics/events/base/event';

const logger = tagged('FullScreenStack Header');

const Header: React.FC<HeaderProps> = ({
  stack,
  editData,
  withImmediateAction,
  triggerEditStackRef,
  defaultUserInfo,
  publicPreview,
  withRightAction,
  additionalContainerStyles,
  alwaysUseAugustRevisionDfBStyles,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const isAugustRev =
    useStackPageAugustRevisionEnabled() || alwaysUseAugustRevisionDfBStyles;
  const isStackChatEnabled = useStackChat();

  const isNewStack = stack === null;

  const canEdit = editData?.canEdit ?? canEditStack(stack);

  // Cache this so we're not redefining for every event
  const stackDerivedPAPPropsWithFeature = useMemo(
    () =>
      isNewStack || !stack.namespace_id
        ? ({ featureLine: 'stacks' } as const)
        : ({
            ...stackDerivePAPProps(stack),
            featureLine: 'stacks',
          } as const),
    [stack, isNewStack],
  );

  const updateStackProperty = useCallback(
    async (
      updates: stacks.StackDataUpdate[],
      eventCreators: Array<(props: PAPEventDefaultProperties) => PAPEvent>,
    ) => {
      if (!editData?.canEdit) {
        logger.warn('Trying to edit a stack without edit data');
        return;
      }

      try {
        const { stack: createdStack } = await upsertStack(
          asStackUpsertId(
            stack?.namespace_id,
            editData.mutationRequestId ?? null,
          ),
          updates,
        );

        if (stack) {
          for (const eventCreator of eventCreators) {
            reportPapEvent(
              eventCreator({
                ...stackDerivePAPProps(stack),
                featureLine: 'stacks',
              }),
            );
          }
        } else {
          reportPapEvent(
            newStackPapEvent(createdStack?.namespace_id, editData.sessionId),
          );
        }
      } catch (e) {
        showSnackbar({
          title: i18n.t('failed_to_update_emoji'),
        });
        logger.error('Error updating emoji inside Header.tsx', e);
        Sentry.captureException(e);
      }
    },
    [stack, reportPapEvent, editData],
  );

  const updateStackEmoji = useCallback(
    (newEmoji: string) =>
      updateStackProperty(
        [
          {
            field: { emoji_update: newEmoji, '.tag': 'emoji_update' },
          },
        ],
        [PAP_Update_StackEmoji],
      ),
    [updateStackProperty],
  );

  const updateStackNameAndDescription = useCallback(
    (newName: string, newDescription: string) => {
      const updates: stacks.StackDataUpdate[] = [
        {
          field: { name_update: newName, '.tag': 'name_update' },
        },
        {
          field: {
            description_update: newDescription,
            '.tag': 'description_update',
          },
        },
      ];

      updateStackProperty(updates, [
        PAP_Rename_DashStack,
        PAP_Update_StacksDescription,
      ]);
    },
    [updateStackProperty],
  );

  const updateEmoji = async (emoji: EmojiData): Promise<void> => {
    updateStackEmoji(emoji.native);
    setEmojiPickerVisible(false);
  };

  if (isAugustRev) {
    if (isStackChatEnabled) {
      return (
        <HeaderWithChat
          stack={stack}
          canEdit={canEdit ?? false}
          emojiPickerVisible={emojiPickerVisible}
          setEmojiPickerVisible={setEmojiPickerVisible}
          updateEmoji={updateEmoji}
          stackDerivedPAPPropsWithFeature={
            stackDerivedPAPPropsWithFeature as StackDerivedPAPPropsWithFeature
          }
          withImmediateAction={withImmediateAction}
          triggerEditStackRef={triggerEditStackRef}
          defaultUserInfo={defaultUserInfo}
          updateStackNameAndDescription={updateStackNameAndDescription}
          publicPreview={publicPreview}
          additionalContainerStyles={additionalContainerStyles}
        />
      );
    }

    return (
      <HeaderAugustRev
        stack={stack}
        canEdit={canEdit ?? false}
        emojiPickerVisible={emojiPickerVisible}
        setEmojiPickerVisible={setEmojiPickerVisible}
        updateEmoji={updateEmoji}
        stackDerivedPAPPropsWithFeature={
          stackDerivedPAPPropsWithFeature as StackDerivedPAPPropsWithFeature
        }
        withImmediateAction={withImmediateAction}
        triggerEditStackRef={triggerEditStackRef}
        defaultUserInfo={defaultUserInfo}
        updateStackNameAndDescription={updateStackNameAndDescription}
        publicPreview={publicPreview}
        buttonActions={withRightAction}
        additionalContainerStyles={additionalContainerStyles}
      />
    );
  }

  return (
    <HeaderV1
      stack={stack}
      canEdit={canEdit ?? false}
      emojiPickerVisible={emojiPickerVisible}
      setEmojiPickerVisible={setEmojiPickerVisible}
      updateEmoji={updateEmoji}
      stackDerivedPAPPropsWithFeature={
        stackDerivedPAPPropsWithFeature as StackDerivedPAPPropsWithFeature
      }
      withImmediateAction={withImmediateAction}
      triggerEditStackRef={triggerEditStackRef}
      defaultUserInfo={defaultUserInfo}
      updateStackNameAndDescription={updateStackNameAndDescription}
    />
  );
};

export default Header;
