import { Button } from '@dropbox/dig-components/buttons';
import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_Shown_TabPanel } from '@mirage/analytics/events/types/shown_tab_panel';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { NoContentPlaceholder } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/NoContentPlaceholder';
import { StacksTabFilterButton } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/StacksTabFilterButton';
import { StackTileCard } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/StackTileCard';
import { useStacksTabFilterPreference } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/useStacksTabFilterPreference';
import { SeeAllStackCard, TileCardGrid } from '@mirage/mosaics/TileCard';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { useLastViewedStackInfo } from '@mirage/service-recent-content/hooks/useLastViewedStackInfo';
import {
  StackFilterOptionToPapName,
  StackSortDirection,
  StackSortOption,
} from '@mirage/service-settings/service/types';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import {
  stackDerivePAPProps,
  stackGetShareId,
} from '@mirage/service-stacks/service/utils';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { convertStackToWorkingSetCardData } from '@mirage/stacks/AllStacksGrid/AllStacks';
import { useDynamicExpandableContent } from '@mirage/stacks/FullScreenStack/useDynamicExpandableContent';
import {
  useSortedStacks,
  useSortedStacksWithItemsBySortOption,
} from '@mirage/stacks/hooks';
import { StackCardWithPopover } from '@mirage/stacks/StackCardPopover';
import i18n from '@mirage/translations';
import { usePrevious } from '@react-hookz/web';
import { useAtomValue } from 'jotai/index';
import * as React from 'react';
import { forwardRef, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import styles from './StacksTabPanel.module.css';

import type { stacks } from '@dropbox/api-v2-client';
import type { WorkingSetCardData } from '@mirage/working-set';
import type { Ref } from 'react';

const MAX_VISIBLE_COUNT = 8;

export const StacksTabPanel = ({
  headerButtonSlot,
  isActive,
  maxCardsPerRow,
}: {
  headerButtonSlot: Element | null;
  isActive: boolean;
  maxCardsPerRow: number;
}) => {
  const currentAccount = useDropboxAccount();
  const augustRevision = useAllStacksAugustRevisionEnabled();
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isPublicSharingAllowed = useIsPublicSharingAllowed();
  const navigate = useNavigate();
  const indexOfSeeAllCard = maxCardsPerRow * 2 - 1;

  const sortedStacks = useSortedStacks();
  const { startPageStackFilterPreference } = useStacksTabFilterPreference();
  const papDashStackFilterName =
    StackFilterOptionToPapName[startPageStackFilterPreference];
  const sortPreferenceByRecentlyViewed = {
    // Note this is special sort option that buckets all company pinned stacks first then by view order
    option: StackSortOption.COMPANY_AND_VIEWED,
    direction: StackSortDirection.DESC,
  };
  const meaningfulUpdateGate = useFeatureFlagValue(
    'dash_2024_12_04_meaningful_updates',
    false /* includeExposureLogging */,
  );

  const meaningfulUpdateEnabled =
    convertFeatureValueToBool(meaningfulUpdateGate);
  const { stacks: filteredSortedStacks } = useSortedStacksWithItemsBySortOption(
    meaningfulUpdateEnabled,
    sortPreferenceByRecentlyViewed,
    startPageStackFilterPreference,
  );

  const onSelectStack = React.useCallback(
    (stack: stacks.Stack) => {
      reportPapEvent(
        PAP_Open_DashLink({
          actionSurfaceComponent: 'tab_panel',
          featureLine: 'stacks',
          dashStackFilterName: papDashStackFilterName,
          ...stackDerivePAPProps(stack),
        }),
      );

      reportPapEvent(
        PAP_View_DashStack({
          actionSurfaceComponent: 'tab_panel',
          featureLine: 'stacks',
          dashStackFilterName: papDashStackFilterName,
          ...stackDerivePAPProps(stack),
        }),
      );

      navigate(`/stacks/${stackGetShareId(stack)}`);
    },
    [navigate, reportPapEvent, papDashStackFilterName],
  );

  const convertStacksToWorkingSetCardData = useCallback(
    (stacks: stacks.Stack[]) => {
      return stacks.map((stack: stacks.Stack) => {
        return convertStackToWorkingSetCardData(
          stack,
          stacks,
          onSelectStack,
          {}, // don't need stackItemsMap yet, since we're not displaying nested items
          undefined, // not hooking in menu open logic
          currentAccount,
          augustRevision,
          isPublicSharingAllowed,
          undefined, // not hooking in menu open logic
        );
      });
    },
    [augustRevision, currentAccount, isPublicSharingAllowed, onSelectStack],
  );

  const stackList = React.useMemo(() => {
    return convertStacksToWorkingSetCardData(filteredSortedStacks ?? []);
  }, [convertStacksToWorkingSetCardData, filteredSortedStacks]);
  const isLoading = sortedStacks === undefined;
  const { expanded, showMore, setExpanded, wrapperRef, numVisible } =
    useDynamicExpandableContent(`div.${styles.selectorStacksCard}`, isActive);

  const previousIsActive = usePrevious(isActive);

  useEffect(() => {
    // Let's only log when we go from not active -> active
    if (isActive && !previousIsActive) {
      reportPapEvent(
        PAP_Shown_TabPanel({
          startPageAction: 'click_stacks_tab',
          isEmpty: stackList.length === 0,
          featureLine: 'stacks',
          actionSurfaceComponent: 'tab_panel',
          dashStackFilterName:
            StackFilterOptionToPapName[startPageStackFilterPreference],
        }),
      );
    }
  }, [
    isActive,
    reportPapEvent,
    stackList,
    isLoading,
    startPageStackFilterPreference,
  ]);

  const handleToggleButton = useCallback(() => {
    if (expanded) {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    } else {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    }

    setExpanded((prev) => !prev);
  }, [expanded, setExpanded, reportPapEvent]);

  const withAccessory: React.ReactNode[] = [
    <StacksTabFilterButton key="filter-button" />,
  ];
  if (showMore) {
    withAccessory.push(
      <Box
        marginLeft="Micro Medium"
        className={styles.buttonContainer}
        key={'box-container-btns'}
      >
        <Button
          variant="transparent"
          onClick={handleToggleButton}
          size="small"
          aria-hidden={!expanded}
        >
          {i18n.t('show_less_answer')}
        </Button>
        <Button
          variant="transparent"
          onClick={handleToggleButton}
          size="small"
          aria-hidden={expanded}
        >
          {i18n.t('show_more_answer')}
        </Button>
      </Box>,
    );
  }

  return (
    <Box
      style={{
        display: isActive ? 'block' : 'none',
        position: 'relative',
        // fixes double-scroll bug on safari, but causes issues with focus border
        // https://jira.dropboxer.net/browse/DASHWEB-4909
        overflowY: privacyModeEnabled ? 'unset' : 'hidden',
      }}
    >
      {headerButtonSlot && createPortal(withAccessory, headerButtonSlot)}
      <Blur isBlurred={privacyModeEnabled} blurAmount="high">
        <StacksTileCardGrid
          expanded={expanded}
          isLoading={isLoading}
          numVisible={numVisible}
          stackCardList={stackList}
          ref={wrapperRef}
          indexOfSeeAllCard={indexOfSeeAllCard}
        />
      </Blur>
    </Box>
  );
};

const StacksTileCardGrid = forwardRef(
  (
    {
      expanded,
      stackCardList,
      isLoading,
      numVisible,
      indexOfSeeAllCard,
    }: {
      expanded: boolean;
      stackCardList: WorkingSetCardData[];
      isLoading: boolean;
      numVisible: number;
      indexOfSeeAllCard: number;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    const lastViewedInfo = useLastViewedStackInfo();
    const navigate = useNavigate();
    const stackCardPopoverEnabled = convertFeatureValueToBool(
      useFeatureFlagValue('dash_web_2025_01_22_stack_card_popover'),
    );
    const isHoverFriendly = useIsHoverFriendly();

    const showNoStacks = !isLoading && stackCardList.length === 0;
    if (showNoStacks) {
      return <NoContentPlaceholder message={i18n.t('no_stacks_from_filter')} />;
    }

    return (
      <TileCardGrid
        expanded={expanded}
        tileCardClassName={styles.selectorStacksCard}
        isLoading={isLoading}
        loadingTileCount={MAX_VISIBLE_COUNT}
        ref={ref}
        numVisible={numVisible}
      >
        {stackCardList.map((stackData, i) => {
          return i !== indexOfSeeAllCard ? (
            stackCardPopoverEnabled && isHoverFriendly ? (
              <StackCardWithPopover
                key={stackData.id}
                // We need to apply the selector class used by useDynamicExpandable hook to the most
                // parent dom element in order to ensure we don't accidentally show "show more" and apply
                // a fixed height to ensure we don't cut off the stack card's border
                parentClassname={styles.selectorStacksCard}
                styleOverrides={{ height: 158 }}
                triggerComponent={
                  <StackTileCard
                    stackData={stackData}
                    hidden={i >= numVisible}
                    lastViewedInfo={lastViewedInfo[stackData.namespaceId]}
                  />
                }
                stackData={stackData}
              />
            ) : (
              <StackTileCard
                key={stackData.id}
                stackData={stackData}
                hidden={i >= numVisible}
                selectorClassname={styles.selectorStacksCard}
                lastViewedInfo={lastViewedInfo[stackData.namespaceId]}
              />
            )
          ) : (
            <SeeAllStackCard
              key="see-all-card"
              selectorClassname={styles.selectorStacksCard}
              numTotalStacks={stackCardList.length}
              hidden={i >= numVisible}
              handleOnClick={() => navigate('/stacks')}
            />
          );
        })}
      </TileCardGrid>
    );
  },
);
StacksTileCardGrid.displayName = 'StacksTileCardGrid';
