import { Box } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import { useDoOnceOnMount } from '@mirage/shared/hooks/useDoOnceOnMount';
import i18n from '@mirage/translations';
import styles from './OnboardingDownloadUpsellStep.module.css';

type Props = {
  isAdmin: boolean;
};

const DESKTOP_UPSELL_IMAGE =
  'https://assets.dropbox.com/images/dashweb/growth/onboarding/self-serve-download-desktop-upsell.webp';

export const OnboardingDownloadUpsellStep = ({ isAdmin }: Props) => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  useDoOnceOnMount(() => {
    reportPapEvent(
      PAP_View_DashSelfServeOnboarding({
        selfServeIsTeamAdmin: isAdmin,
        onboardingStep: 'desktop_download',
        featureLine: 'onboarding',
      }),
    );
  });

  return (
    <Box className={styles.imageContainer} flexGrow={1}>
      <img
        src={DESKTOP_UPSELL_IMAGE}
        width="100%"
        alt={i18n.t('self_serve_download_upsell_title')}
      />
    </Box>
  );
};
