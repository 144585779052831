import { useLayoutEffect } from 'react';
import { useFLIPOrchestrator } from '../useFLIPOrchestrator';
import { cloneHTMLCollection, cloneNodeList } from './utils';

/**
 * Registers all current and future children of the element in the orchestrator. This ensures that
 * the orchestrator runs if the children of the element change size even if it doesn't affect the
 * size of the element itself (think going from 3 children of height 1 to one child of height 3).
 */
export function useRegisterChildren(el: HTMLElement | null) {
  const orchestrator = useFLIPOrchestrator();

  useLayoutEffect(() => {
    if (!el) return;

    const nodeToDeregister = new Map<HTMLElement, () => void>();

    const children = cloneHTMLCollection(el.children);
    for (const child of children) {
      if (child instanceof HTMLElement) {
        nodeToDeregister.set(child, orchestrator.register(child));
      }
    }

    const observer = new MutationObserver((records) => {
      for (const child of records.flatMap((r) => cloneNodeList(r.addedNodes))) {
        if (child instanceof HTMLElement) {
          nodeToDeregister.set(child, orchestrator.register(child));
        }
      }
      for (const child of records.flatMap((r) =>
        cloneNodeList(r.removedNodes),
      )) {
        if (child instanceof HTMLElement) {
          nodeToDeregister.get(child)?.();
          nodeToDeregister.delete(child);
        }
      }
    });
    observer.observe(el, { childList: true });

    return () => {
      for (const deregister of nodeToDeregister.values()) {
        deregister();
      }
    };
  }, [el, orchestrator]);
}
