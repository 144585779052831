import { SummaryPanel } from '@mirage/mosaics/SummaryQnaPanel/SummaryQnaPanel';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { flyoutPanelDataAtom, flyoutPanelViewAtom } from './atoms';
import styles from './FlyoutPanel.module.css';

import type { FlyoutPanelView } from './atoms';

const PANEL_SWITCH_DELAY_MS = 500;

export const FlyoutPanel: React.FC = () => {
  const flyoutPanelData = useAtomValue(flyoutPanelDataAtom);
  const [newPanelView, setNewPanelView] = useAtom(flyoutPanelViewAtom);
  const isMobileSize = useIsMobileSize();
  const { pathname } = useLocation();

  const [panelView, setPanelView] = useState<FlyoutPanelView>('closed');

  const handleRefreshPanel = useCallback((view) => {
    setPanelView('closed');
    const timer = setTimeout(() => {
      setPanelView(view);
    }, PANEL_SWITCH_DELAY_MS);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (newPanelView !== panelView && panelView !== 'closed') {
      return handleRefreshPanel(newPanelView);
    } else if (panelView === 'closed') {
      setPanelView(newPanelView);
    }
  }, [newPanelView, panelView, handleRefreshPanel]);

  useEffect(() => {
    setNewPanelView('closed');
  }, [pathname, setNewPanelView]);

  const isOpen = panelView !== 'closed';

  const handleClose = () => {
    setNewPanelView('closed');
  };

  const renderFlyoutPanelContent = () => {
    switch (panelView) {
      case 'summary':
        return (
          <SummaryPanel
            onClose={handleClose}
            panelData={flyoutPanelData.summary}
            refreshPanel={handleRefreshPanel}
          />
        );
      default:
        return null;
    }
  };

  // framer is handling the presence of content for smooth entry and exit
  // flyout panel animation is handled by CSS
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 1 },
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.isOpen]: isOpen,
        [styles.isOpenMobile]: isOpen && isMobileSize,
      })}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.inner}
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.2 }}
          >
            {renderFlyoutPanelContent()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
