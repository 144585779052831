import type { PAPEvent } from "@mirage/analytics/events/base/event";
import type { ServiceConfig } from "@mirage/service-product-logging/service";
import { isIframe } from "@mirage/shared/util/tiny-utils";
import {
  getExtensionChannel,
  getExtensionVersion,
  isExtensionInstalled,
  isNewTabEnabled,
} from "@mirage/shared/util/webExtensionsHelpers";

import { Config } from "../shared/config";

const LOG_PAP_EVENTS = true;

export function copyToClipboard(event: PAPEvent) {
  try {
    const eventJson = JSON.stringify(event, null, 2);
    navigator.clipboard.writeText(eventJson);
  } catch (error) {
    console.error(error);
  }
}

export const getProductLoggingServiceConfig = (
  isDev: boolean,
): ServiceConfig => {
  const extensionVersion = getExtensionVersion();
  const extensionBuildChannel = getExtensionChannel();
  const config: ServiceConfig = {
    papClientConfig: {
      clientId: Config.OAUTH_CLIENT_KEY,
      clientSecret: Config.OAUTH_CLIENT_SECRET,
      transportType: "sdk",
    },
    dashSurface: "webapp",
    logging: LOG_PAP_EVENTS,
    copyToClipboard,
    isDev,
    papEnvironment: isDev ? "development" : "production",
    buildVersion: Config.BUILD_VERSION,
    reportValidationErrorsToSentry: true,
    getExtensionPropertiesForLog: () => ({
      isExtensionInstalled: isIframe() || isExtensionInstalled(), // Set the appropriate value
      isNewTabEnabled: isIframe() || isNewTabEnabled(), // Set the appropriate value
      ...(extensionVersion !== null && {
        extensionBuildVersion: extensionVersion,
      }),
      ...(extensionBuildChannel !== null && {
        extensionBuildChannel,
      }),
    }),
  };

  return config;
};
