import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import classNames from 'classnames';
import * as React from 'react';
import styles from './BaseListItem.module.css';

import type { TextColorType } from '@dropbox/dig-components/typography';

type Props = Readonly<{
  className?: string;
  /** left hand side icon */
  icon?: React.ReactNode;
  textColor?: TextColorType | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  /** default title */
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  swapLayout?: boolean;

  role?: React.AriaRole;
  ariaLabel?: string;
  ariaChecked?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
}>;

export default function BaseListItem(props: Props) {
  const {
    className,
    icon,
    textColor = 'standard',
    onClick,
    title = '',
    subtitle,
    swapLayout = false,
    role,
    ariaLabel,
    ariaChecked,
    children,
    disabled = false,
    tooltipTitle,
  } = props;
  const Icon = icon || null;

  const handleClick = React.useCallback(
    (_e) => onClick && !disabled && onClick(),
    [onClick, disabled],
  );

  const handleKeyDown = React.useCallback(
    (e) => {
      if ((e.code === 'Enter' || e.code === 'Space') && onClick && !disabled) {
        onClick();
      }
    },
    [onClick, disabled],
  );

  return (
    <DigTooltip title={tooltipTitle}>
      <div
        className={classNames(styles.row, className)}
        onClick={handleClick}
        tabIndex={role === 'button' || role === 'link' ? 0 : undefined}
        role={role}
        onKeyDown={handleKeyDown}
        aria-label={ariaLabel}
        aria-checked={ariaChecked}
      >
        {!swapLayout && children}
        {Icon && (
          <div
            className={classNames(styles.iconContainer, {
              [styles.iconContainerOffsetPosition]: !!subtitle,
            })}
          >
            {Icon}
          </div>
        )}
        <div className={styles.rowContentContainer}>
          <Text color={disabled ? 'disabled' : textColor}>
            {typeof title === 'string' ? (
              <Truncate>
                <div className={styles.title}>{title}</div>
              </Truncate>
            ) : (
              <div className={styles.title}>{title}</div>
            )}
          </Text>
          {subtitle && (
            <Text
              size="small"
              variant="label"
              color="subtle"
              style={{ maxWidth: 500 }}
            >
              {subtitle}
            </Text>
          )}
        </div>
        {swapLayout && children}
      </div>
    </DigTooltip>
  );
}
