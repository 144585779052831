import {
  getCachedConnectorConnections,
  listenForConnections,
  refreshConnectionsList,
} from '@mirage/service-connectors';
import {
  logPageLoadMilestone,
  logPageLoadMilestoneOnce,
} from '@mirage/service-operational-metrics/page-load';
import { useConsolidatedSubscription } from '@mirage/shared/hooks/useConsolidatedSubscription';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';

import type {
  Connector,
  ConnectorConnection,
  ConnectorConnections,
} from './service';

export const connectorsAtom = atom<Connector[]>([]);
export const connectionsAtom = atom<ConnectorConnections>([]);
const hasFinishedInitialFetchAtom = atom(false);

const subscribeToConnectorConnections = (
  callback: (value: ConnectorConnections) => void,
) => {
  return listenForConnections().subscribe(callback);
};

const connectionIsUnhealthy = (connection: ConnectorConnection) => {
  return connection.connection_status?.['.tag'] !== 'connected';
};
const isAdminConnection = (connection: ConnectorConnection) => {
  return connection.metadata?.owner?.owner?.['.tag'] === 'team';
};
function getUnhealthyAdminConnections(connections: ConnectorConnection[]) {
  return () => {
    return connections.filter(isAdminConnection).filter(connectionIsUnhealthy);
  };
}

export default function useConnectors() {
  logPageLoadMilestoneOnce(`useConnectors start`);

  const [connectors, setStateConnectors] = useAtom(connectorsAtom);
  const [connections, setStateConnections] = useAtom(connectionsAtom);

  const [hasFinishedInitialFetch, setHasFinishedInitialFetch] = useAtom(
    hasFinishedInitialFetchAtom,
  );

  const updateInternalState = useCallback(
    (connections: ConnectorConnections) => {
      setStateConnections(connections);

      // Connections may contain multiple connectors (if user has multiple accounts connected to same connector)
      const connectorsDeduped = connections
        .filter((connection) => connection?.connector?.id_attrs?.id)
        .map((connection) => connection.connector)
        .filter(isDefined);
      setStateConnectors(connectorsDeduped);
      logPageLoadMilestone(
        `useConnectors updateInternalState: ` +
          `#connections=${connections.length}, ` +
          `#connectors=${connectorsDeduped.length}`,
      );

      setHasFinishedInitialFetch(true);
    },
    [setHasFinishedInitialFetch, setStateConnections, setStateConnectors],
  );

  const reauthConnections = useMemo(() => {
    return connections.filter(
      (c) =>
        c.metadata?.owner?.owner?.['.tag'] !== 'team' &&
        !!c.connection_status &&
        c.connection_status['.tag'] == 'reauth',
    );
  }, [connections]);

  const hasReauthConnections = useMemo(() => {
    return reauthConnections.length > 0;
  }, [reauthConnections.length]);

  const expiredConnections = useMemo(() => {
    return connections.filter(
      (c) =>
        c.metadata?.owner?.owner?.['.tag'] !== 'team' &&
        !!c.connection_status &&
        c.connection_status['.tag'] == 'expired',
    );
  }, [connections]);

  const hasExpiredConnections = useMemo(() => {
    return expiredConnections.length > 0;
  }, [expiredConnections.length]);

  const unhealthyAdminConnections = useMemo(
    getUnhealthyAdminConnections(connections),
    [connections],
  );

  useConsolidatedSubscription({
    hookName: 'useConnectors',
    setState: updateInternalState,
    getInitialData: getCachedConnectorConnections,
    subscribe: subscribeToConnectorConnections,
  });

  const connectorsById = useMemo(() => {
    const byId: { [key: string]: Connector } = {};
    connectors.forEach((c) => {
      if (c.id_attrs?.type) {
        byId[c.id_attrs.type] = c;
      }
    });
    return byId;
  }, [connectors]);

  return {
    connectors,
    connectorsById,
    connections,
    expiredConnections,
    // First fetch is cached data, so true initial fetch is considered done
    // after the second fetch
    hasFinishedInitialFetch,
    getConnections: getCachedConnectorConnections,
    refreshConnectionsList,
    hasExpiredConnections,
    unhealthyAdminConnections,
    hasReauthConnections,
  };
}
