import { Box } from '@dropbox/dig-foundations';
import { getBodyConfig } from '../util/config';
import { ResultAnnotations } from './ResultAnnotations';
import { SnippetText } from './SnippetText';

import type { BodyField } from '../util/config';
import type { SearchResult } from '@mirage/service-dbx-api';

type ResultBodyProps = {
  result: SearchResult;
  query: string;
  bodyFields: BodyField[];
  resultPosition: number;
  annotationsEnabled?: boolean;
};

export const ResultBody = ({
  result,
  query,
  bodyFields,
  resultPosition,
  annotationsEnabled,
}: ResultBodyProps) => {
  return (
    <Box as="div">
      <Box as="div">
        {bodyFields.map((bodyField: BodyField) => {
          const bodyFieldProps = getBodyConfig(bodyField, result, query);
          if (bodyFieldProps && bodyFieldProps['.tag'] === 'snippet') {
            return <SnippetText key={bodyField} {...bodyFieldProps} />;
          }
          return null;
        })}
      </Box>
      {annotationsEnabled && (
        <ResultAnnotations
          result={result}
          resultPosition={resultPosition}
          query={query}
        />
      )}
    </Box>
  );
};
