// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/models.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Empty, Message, proto3, protoInt64 } from '@bufbuild/protobuf';
import { CE_ConnectorInfo, CE_FileTypeInfo } from './connector_info_pb';
import { WorkingHours, WorkingHoursPerDay } from './user_preferences_pb';

/**
 * @generated from enum context_engine.PromptMessageRole
 */
export enum PromptMessageRole {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: SYSTEM = 1;
   */
  SYSTEM = 1,

  /**
   * @generated from enum value: USER = 2;
   */
  USER = 2,

  /**
   * @generated from enum value: ASSISTANT = 3;
   */
  ASSISTANT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PromptMessageRole)
proto3.util.setEnumType(PromptMessageRole, 'context_engine.PromptMessageRole', [
  { no: 0, name: 'UNKNOWN' },
  { no: 1, name: 'SYSTEM' },
  { no: 2, name: 'USER' },
  { no: 3, name: 'ASSISTANT' },
]);

/**
 * @generated from message context_engine.AuthedUser
 */
export class AuthedUser extends Message<AuthedUser> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = '';

  /**
   * @generated from field: string display_name = 3;
   */
  displayName = '';

  constructor(data?: PartialMessage<AuthedUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AuthedUser';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'user_id', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'display_name',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AuthedUser {
    return new AuthedUser().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AuthedUser {
    return new AuthedUser().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AuthedUser {
    return new AuthedUser().fromJsonString(jsonString, options);
  }

  static equals(
    a: AuthedUser | PlainMessage<AuthedUser> | undefined,
    b: AuthedUser | PlainMessage<AuthedUser> | undefined,
  ): boolean {
    return proto3.util.equals(AuthedUser, a, b);
  }
}

/**
 * @generated from message context_engine.Contact
 */
export class Contact extends Message<Contact> {
  /**
   * @generated from field: string email = 1;
   */
  email = '';

  constructor(data?: PartialMessage<Contact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.Contact';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): Contact {
    return new Contact().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): Contact {
    return new Contact().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): Contact {
    return new Contact().fromJsonString(jsonString, options);
  }

  static equals(
    a: Contact | PlainMessage<Contact> | undefined,
    b: Contact | PlainMessage<Contact> | undefined,
  ): boolean {
    return proto3.util.equals(Contact, a, b);
  }
}

/**
 * @generated from message context_engine.TimeBlock
 */
export class TimeBlock extends Message<TimeBlock> {
  /**
   * @generated from field: int64 start_ms = 1;
   */
  startMs = protoInt64.zero;

  /**
   * @generated from field: int64 end_ms = 2;
   */
  endMs = protoInt64.zero;

  constructor(data?: PartialMessage<TimeBlock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.TimeBlock';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'start_ms', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: 'end_ms', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): TimeBlock {
    return new TimeBlock().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): TimeBlock {
    return new TimeBlock().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): TimeBlock {
    return new TimeBlock().fromJsonString(jsonString, options);
  }

  static equals(
    a: TimeBlock | PlainMessage<TimeBlock> | undefined,
    b: TimeBlock | PlainMessage<TimeBlock> | undefined,
  ): boolean {
    return proto3.util.equals(TimeBlock, a, b);
  }
}

/**
 * @generated from message context_engine.MeetingInfo
 */
export class MeetingInfo extends Message<MeetingInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = '';

  /**
   * An absolute link to this event in the Google Calendar Web UI
   *
   * @generated from field: string url = 2;
   */
  url = '';

  /**
   * short summary of the meeting, like a title, e.g. "Team Standup"
   *
   * @generated from field: string summary = 3;
   */
  summary = '';

  /**
   * email address of the person who created the meeting
   *
   * @generated from field: string creator = 4;
   */
  creator = '';

  /**
   * status of the meeting from calendar backend
   * For gcal, this is one of "confirmed", "tentative", or "cancelled"
   *
   * @generated from field: string status = 5;
   */
  status = '';

  /**
   * long form description of the meeting
   *
   * @generated from field: string description = 6;
   */
  description = '';

  /**
   * Start time of the meeting in milliseconds seince unix epoch
   *
   * @generated from field: int64 start_ms = 7;
   */
  startMs = protoInt64.zero;

  /**
   * End time of the meeting in milliseconds seince unix epoch
   *
   * @generated from field: int64 end_ms = 8;
   */
  endMs = protoInt64.zero;

  /**
   * List of attendee email addresses for the meeting
   *
   * @generated from field: repeated string attendees = 9;
   */
  attendees: string[] = [];

  /**
   * whether this meeting is marked as free or busy on your calendar
   *
   * @generated from field: bool is_free = 10;
   */
  isFree = false;

  constructor(data?: PartialMessage<MeetingInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.MeetingInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'summary', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'creator', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'status', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 6,
      name: 'description',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 7, name: 'start_ms', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: 'end_ms', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    {
      no: 9,
      name: 'attendees',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    { no: 10, name: 'is_free', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): MeetingInfo {
    return new MeetingInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): MeetingInfo {
    return new MeetingInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): MeetingInfo {
    return new MeetingInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: MeetingInfo | PlainMessage<MeetingInfo> | undefined,
    b: MeetingInfo | PlainMessage<MeetingInfo> | undefined,
  ): boolean {
    return proto3.util.equals(MeetingInfo, a, b);
  }
}

/**
 * @generated from message context_engine.ConflictingMeetingGroup
 */
export class ConflictingMeetingGroup extends Message<ConflictingMeetingGroup> {
  /**
   * @generated from field: repeated context_engine.MeetingInfo meetings = 1;
   */
  meetings: MeetingInfo[] = [];

  constructor(data?: PartialMessage<ConflictingMeetingGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConflictingMeetingGroup';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'meetings',
      kind: 'message',
      T: MeetingInfo,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConflictingMeetingGroup {
    return new ConflictingMeetingGroup().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConflictingMeetingGroup {
    return new ConflictingMeetingGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConflictingMeetingGroup {
    return new ConflictingMeetingGroup().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ConflictingMeetingGroup
      | PlainMessage<ConflictingMeetingGroup>
      | undefined,
    b:
      | ConflictingMeetingGroup
      | PlainMessage<ConflictingMeetingGroup>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConflictingMeetingGroup, a, b);
  }
}

/**
 * @generated from message context_engine.ConflictingMeetings
 */
export class ConflictingMeetings extends Message<ConflictingMeetings> {
  /**
   * @generated from field: repeated context_engine.ConflictingMeetingGroup groups = 1;
   */
  groups: ConflictingMeetingGroup[] = [];

  constructor(data?: PartialMessage<ConflictingMeetings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConflictingMeetings';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'groups',
      kind: 'message',
      T: ConflictingMeetingGroup,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConflictingMeetings {
    return new ConflictingMeetings().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConflictingMeetings {
    return new ConflictingMeetings().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConflictingMeetings {
    return new ConflictingMeetings().fromJsonString(jsonString, options);
  }

  static equals(
    a: ConflictingMeetings | PlainMessage<ConflictingMeetings> | undefined,
    b: ConflictingMeetings | PlainMessage<ConflictingMeetings> | undefined,
  ): boolean {
    return proto3.util.equals(ConflictingMeetings, a, b);
  }
}

/**
 * @generated from message context_engine.ContextUserError
 */
export class ContextUserError extends Message<ContextUserError> {
  /**
   * @generated from field: string message = 1;
   */
  message = '';

  constructor(data?: PartialMessage<ContextUserError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ContextUserError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ContextUserError {
    return new ContextUserError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ContextUserError {
    return new ContextUserError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ContextUserError {
    return new ContextUserError().fromJsonString(jsonString, options);
  }

  static equals(
    a: ContextUserError | PlainMessage<ContextUserError> | undefined,
    b: ContextUserError | PlainMessage<ContextUserError> | undefined,
  ): boolean {
    return proto3.util.equals(ContextUserError, a, b);
  }
}

/**
 * @generated from message context_engine.FssRetrieverInfo
 */
export class FssRetrieverInfo extends Message<FssRetrieverInfo> {
  /**
   * @generated from field: string search_query = 1;
   */
  searchQuery = '';

  /**
   * @generated from field: uint64 ns_id = 2;
   */
  nsId = protoInt64.zero;

  /**
   * @generated from field: string ns_path = 3;
   */
  nsPath = '';

  constructor(data?: PartialMessage<FssRetrieverInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.FssRetrieverInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'search_query',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'ns_id', kind: 'scalar', T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: 'ns_path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): FssRetrieverInfo {
    return new FssRetrieverInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): FssRetrieverInfo {
    return new FssRetrieverInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): FssRetrieverInfo {
    return new FssRetrieverInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: FssRetrieverInfo | PlainMessage<FssRetrieverInfo> | undefined,
    b: FssRetrieverInfo | PlainMessage<FssRetrieverInfo> | undefined,
  ): boolean {
    return proto3.util.equals(FssRetrieverInfo, a, b);
  }
}

/**
 * @generated from message context_engine.DocumentInfo
 */
export class DocumentInfo extends Message<DocumentInfo> {
  /**
   * web link url to the document
   *
   * @generated from field: string url = 1;
   */
  url = '';

  /**
   * title of the document
   *
   * @generated from field: string title = 2;
   */
  title = '';

  /**
   * last updated time of the document
   *
   * @generated from field: int64 last_updated_ms = 3;
   */
  lastUpdatedMs = protoInt64.zero;

  /**
   * type of the document
   *
   * @generated from field: string branded_type = 4;
   */
  brandedType = '';

  /**
   * body of the document
   *
   * @generated from field: string body = 5;
   */
  body = '';

  /**
   * owner id of the document
   *
   * @generated from field: string owner_id = 6;
   */
  ownerId = '';

  /**
   * third party id of the document (e.g. google doc id or dropbox file id)
   *
   * @generated from field: string third_party_id = 7;
   */
  thirdPartyId = '';

  /**
   * search relevance score of the document
   *
   * @generated from field: float relevance_score = 8;
   */
  relevanceScore = 0;

  /**
   * info about the connector the document came from
   *
   * @generated from field: context_engine.CE_ConnectorInfo connector_info = 9;
   */
  connectorInfo?: CE_ConnectorInfo;

  /**
   * uuid that tracks the document
   *
   * @generated from field: string uuid = 10;
   */
  uuid = '';

  /**
   * info about the file type
   *
   * @generated from field: context_engine.CE_FileTypeInfo file_type_info = 11;
   */
  fileTypeInfo?: CE_FileTypeInfo;

  /**
   * info about the index of the document in the LLM request (for citations)
   *
   * @generated from field: string index = 12;
   */
  index = '';

  /**
   * info about the document's retrieval source
   *
   * @generated from field: string retrieval_source = 13;
   */
  retrievalSource = '';

  /**
   * info about citation type
   *
   * @generated from field: string citation_type = 14;
   */
  citationType = '';

  /**
   * info specific to the FSSRetriever for the document
   *
   * @generated from field: context_engine.FssRetrieverInfo fss_retriever_info = 15;
   */
  fssRetrieverInfo?: FssRetrieverInfo;

  constructor(data?: PartialMessage<DocumentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.DocumentInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'last_updated_ms',
      kind: 'scalar',
      T: 3 /* ScalarType.INT64 */,
    },
    {
      no: 4,
      name: 'branded_type',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 5, name: 'body', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'owner_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 7,
      name: 'third_party_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 8,
      name: 'relevance_score',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
    },
    { no: 9, name: 'connector_info', kind: 'message', T: CE_ConnectorInfo },
    { no: 10, name: 'uuid', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 11, name: 'file_type_info', kind: 'message', T: CE_FileTypeInfo },
    { no: 12, name: 'index', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 13,
      name: 'retrieval_source',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 14,
      name: 'citation_type',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 15,
      name: 'fss_retriever_info',
      kind: 'message',
      T: FssRetrieverInfo,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): DocumentInfo {
    return new DocumentInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): DocumentInfo {
    return new DocumentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): DocumentInfo {
    return new DocumentInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: DocumentInfo | PlainMessage<DocumentInfo> | undefined,
    b: DocumentInfo | PlainMessage<DocumentInfo> | undefined,
  ): boolean {
    return proto3.util.equals(DocumentInfo, a, b);
  }
}

/**
 * @generated from message context_engine.AnswerInfo
 */
export class AnswerInfo extends Message<AnswerInfo> {
  /**
   * answer text
   *
   * @generated from field: string answer = 1;
   */
  answer = '';

  /**
   * confidence of the answer
   *
   * @generated from field: float confidence = 2;
   */
  confidence = 0;

  /**
   * sources of the answer
   *
   * @generated from field: repeated string source_url = 3;
   */
  sourceUrl: string[] = [];

  /**
   * @generated from field: repeated context_engine.DocumentInfo sources = 4;
   */
  sources: DocumentInfo[] = [];

  /**
   * @generated from field: string query = 5;
   */
  query = '';

  constructor(data?: PartialMessage<AnswerInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.AnswerInfo';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'answer', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'confidence', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
    {
      no: 3,
      name: 'source_url',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 4,
      name: 'sources',
      kind: 'message',
      T: DocumentInfo,
      repeated: true,
    },
    { no: 5, name: 'query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): AnswerInfo {
    return new AnswerInfo().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): AnswerInfo {
    return new AnswerInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): AnswerInfo {
    return new AnswerInfo().fromJsonString(jsonString, options);
  }

  static equals(
    a: AnswerInfo | PlainMessage<AnswerInfo> | undefined,
    b: AnswerInfo | PlainMessage<AnswerInfo> | undefined,
  ): boolean {
    return proto3.util.equals(AnswerInfo, a, b);
  }
}

/**
 * @generated from message context_engine.ContextEngineModelItem
 */
export class ContextEngineModelItem extends Message<ContextEngineModelItem> {
  /**
   * @generated from oneof context_engine.ContextEngineModelItem.data
   */
  data:
    | {
        /**
         * @generated from field: int64 int_value = 1;
         */
        value: bigint;
        case: 'intValue';
      }
    | {
        /**
         * @generated from field: float float_value = 2;
         */
        value: number;
        case: 'floatValue';
      }
    | {
        /**
         * @generated from field: string string_value = 3;
         */
        value: string;
        case: 'stringValue';
      }
    | {
        /**
         * @generated from field: bool bool_value = 4;
         */
        value: boolean;
        case: 'boolValue';
      }
    | {
        /**
         * @generated from field: google.protobuf.Empty empty_value = 5;
         */
        value: Empty;
        case: 'emptyValue';
      }
    | {
        /**
         * @generated from field: string datetime_value = 6;
         */
        value: string;
        case: 'datetimeValue';
      }
    | {
        /**
         * @generated from field: context_engine.AuthedUser authed_user = 7;
         */
        value: AuthedUser;
        case: 'authedUser';
      }
    | {
        /**
         * @generated from field: context_engine.Contact contact = 8;
         */
        value: Contact;
        case: 'contact';
      }
    | {
        /**
         * @generated from field: context_engine.TimeBlock time_block = 9;
         */
        value: TimeBlock;
        case: 'timeBlock';
      }
    | {
        /**
         * @generated from field: context_engine.WorkingHours working_hours = 10;
         */
        value: WorkingHours;
        case: 'workingHours';
      }
    | {
        /**
         * @generated from field: context_engine.MeetingInfo meeting_info = 11;
         */
        value: MeetingInfo;
        case: 'meetingInfo';
      }
    | {
        /**
         * @generated from field: context_engine.ConflictingMeetings conflicting_meetings = 12;
         */
        value: ConflictingMeetings;
        case: 'conflictingMeetings';
      }
    | {
        /**
         * @generated from field: context_engine.ContextUserError context_user_error = 13;
         */
        value: ContextUserError;
        case: 'contextUserError';
      }
    | {
        /**
         * @generated from field: context_engine.WorkingHoursPerDay working_hours_per_day = 14;
         */
        value: WorkingHoursPerDay;
        case: 'workingHoursPerDay';
      }
    | {
        /**
         * @generated from field: context_engine.DocumentInfo document_info = 15;
         */
        value: DocumentInfo;
        case: 'documentInfo';
      }
    | {
        /**
         * @generated from field: context_engine.AnswerInfo answer_info = 16;
         */
        value: AnswerInfo;
        case: 'answerInfo';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ContextEngineModelItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ContextEngineModelItem';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'int_value',
      kind: 'scalar',
      T: 3 /* ScalarType.INT64 */,
      oneof: 'data',
    },
    {
      no: 2,
      name: 'float_value',
      kind: 'scalar',
      T: 2 /* ScalarType.FLOAT */,
      oneof: 'data',
    },
    {
      no: 3,
      name: 'string_value',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'data',
    },
    {
      no: 4,
      name: 'bool_value',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
      oneof: 'data',
    },
    { no: 5, name: 'empty_value', kind: 'message', T: Empty, oneof: 'data' },
    {
      no: 6,
      name: 'datetime_value',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      oneof: 'data',
    },
    {
      no: 7,
      name: 'authed_user',
      kind: 'message',
      T: AuthedUser,
      oneof: 'data',
    },
    { no: 8, name: 'contact', kind: 'message', T: Contact, oneof: 'data' },
    { no: 9, name: 'time_block', kind: 'message', T: TimeBlock, oneof: 'data' },
    {
      no: 10,
      name: 'working_hours',
      kind: 'message',
      T: WorkingHours,
      oneof: 'data',
    },
    {
      no: 11,
      name: 'meeting_info',
      kind: 'message',
      T: MeetingInfo,
      oneof: 'data',
    },
    {
      no: 12,
      name: 'conflicting_meetings',
      kind: 'message',
      T: ConflictingMeetings,
      oneof: 'data',
    },
    {
      no: 13,
      name: 'context_user_error',
      kind: 'message',
      T: ContextUserError,
      oneof: 'data',
    },
    {
      no: 14,
      name: 'working_hours_per_day',
      kind: 'message',
      T: WorkingHoursPerDay,
      oneof: 'data',
    },
    {
      no: 15,
      name: 'document_info',
      kind: 'message',
      T: DocumentInfo,
      oneof: 'data',
    },
    {
      no: 16,
      name: 'answer_info',
      kind: 'message',
      T: AnswerInfo,
      oneof: 'data',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ContextEngineModelItem {
    return new ContextEngineModelItem().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineModelItem {
    return new ContextEngineModelItem().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineModelItem {
    return new ContextEngineModelItem().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ContextEngineModelItem
      | PlainMessage<ContextEngineModelItem>
      | undefined,
    b:
      | ContextEngineModelItem
      | PlainMessage<ContextEngineModelItem>
      | undefined,
  ): boolean {
    return proto3.util.equals(ContextEngineModelItem, a, b);
  }
}

/**
 * @generated from message context_engine.ContextEngineModel
 */
export class ContextEngineModel extends Message<ContextEngineModel> {
  /**
   * @generated from field: bool is_list = 1;
   */
  isList = false;

  /**
   * @generated from field: repeated context_engine.ContextEngineModelItem items = 2;
   */
  items: ContextEngineModelItem[] = [];

  constructor(data?: PartialMessage<ContextEngineModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ContextEngineModel';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'is_list', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    {
      no: 2,
      name: 'items',
      kind: 'message',
      T: ContextEngineModelItem,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ContextEngineModel {
    return new ContextEngineModel().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineModel {
    return new ContextEngineModel().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineModel {
    return new ContextEngineModel().fromJsonString(jsonString, options);
  }

  static equals(
    a: ContextEngineModel | PlainMessage<ContextEngineModel> | undefined,
    b: ContextEngineModel | PlainMessage<ContextEngineModel> | undefined,
  ): boolean {
    return proto3.util.equals(ContextEngineModel, a, b);
  }
}

/**
 * @generated from message context_engine.ContextEngineVars
 */
export class ContextEngineVars extends Message<ContextEngineVars> {
  /**
   * @generated from field: map<string, context_engine.ContextEngineModel> vars = 1;
   */
  vars: { [key: string]: ContextEngineModel } = {};

  constructor(data?: PartialMessage<ContextEngineVars>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ContextEngineVars';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'vars',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: ContextEngineModel },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ContextEngineVars {
    return new ContextEngineVars().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineVars {
    return new ContextEngineVars().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ContextEngineVars {
    return new ContextEngineVars().fromJsonString(jsonString, options);
  }

  static equals(
    a: ContextEngineVars | PlainMessage<ContextEngineVars> | undefined,
    b: ContextEngineVars | PlainMessage<ContextEngineVars> | undefined,
  ): boolean {
    return proto3.util.equals(ContextEngineVars, a, b);
  }
}

/**
 * @generated from message context_engine.UserChoiceRequest
 */
export class UserChoiceRequest extends Message<UserChoiceRequest> {
  /**
   * @generated from field: string interaction_id = 1;
   */
  interactionId = '';

  /**
   * @generated from field: string message = 2;
   */
  message = '';

  /**
   * @generated from field: string choice_type = 3;
   */
  choiceType = '';

  /**
   * @generated from field: repeated context_engine.ContextEngineModelItem choices = 4;
   */
  choices: ContextEngineModelItem[] = [];

  constructor(data?: PartialMessage<UserChoiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UserChoiceRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'interaction_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    {
      no: 3,
      name: 'choice_type',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 4,
      name: 'choices',
      kind: 'message',
      T: ContextEngineModelItem,
      repeated: true,
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UserChoiceRequest {
    return new UserChoiceRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UserChoiceRequest {
    return new UserChoiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UserChoiceRequest {
    return new UserChoiceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UserChoiceRequest | PlainMessage<UserChoiceRequest> | undefined,
    b: UserChoiceRequest | PlainMessage<UserChoiceRequest> | undefined,
  ): boolean {
    return proto3.util.equals(UserChoiceRequest, a, b);
  }
}

/**
 * @generated from message context_engine.UserChoiceResponse
 */
export class UserChoiceResponse extends Message<UserChoiceResponse> {
  /**
   * @generated from field: string interaction_id = 1;
   */
  interactionId = '';

  /**
   * @generated from field: int64 choice_idx = 2;
   */
  choiceIdx = protoInt64.zero;

  constructor(data?: PartialMessage<UserChoiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UserChoiceResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'interaction_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 2, name: 'choice_idx', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UserChoiceResponse {
    return new UserChoiceResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UserChoiceResponse {
    return new UserChoiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UserChoiceResponse {
    return new UserChoiceResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UserChoiceResponse | PlainMessage<UserChoiceResponse> | undefined,
    b: UserChoiceResponse | PlainMessage<UserChoiceResponse> | undefined,
  ): boolean {
    return proto3.util.equals(UserChoiceResponse, a, b);
  }
}

/**
 * @generated from message context_engine.UserInteractionRequest
 */
export class UserInteractionRequest extends Message<UserInteractionRequest> {
  /**
   * @generated from oneof context_engine.UserInteractionRequest.request
   */
  request:
    | {
        /**
         * @generated from field: context_engine.UserChoiceRequest choice = 1;
         */
        value: UserChoiceRequest;
        case: 'choice';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UserInteractionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UserInteractionRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'choice',
      kind: 'message',
      T: UserChoiceRequest,
      oneof: 'request',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UserInteractionRequest {
    return new UserInteractionRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UserInteractionRequest {
    return new UserInteractionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UserInteractionRequest {
    return new UserInteractionRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UserInteractionRequest
      | PlainMessage<UserInteractionRequest>
      | undefined,
    b:
      | UserInteractionRequest
      | PlainMessage<UserInteractionRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(UserInteractionRequest, a, b);
  }
}

/**
 * @generated from message context_engine.UserInteractionResponse
 */
export class UserInteractionResponse extends Message<UserInteractionResponse> {
  /**
   * @generated from oneof context_engine.UserInteractionResponse.response
   */
  response:
    | {
        /**
         * @generated from field: context_engine.UserChoiceResponse choice = 1;
         */
        value: UserChoiceResponse;
        case: 'choice';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UserInteractionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.UserInteractionResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'choice',
      kind: 'message',
      T: UserChoiceResponse,
      oneof: 'response',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): UserInteractionResponse {
    return new UserInteractionResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): UserInteractionResponse {
    return new UserInteractionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): UserInteractionResponse {
    return new UserInteractionResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UserInteractionResponse
      | PlainMessage<UserInteractionResponse>
      | undefined,
    b:
      | UserInteractionResponse
      | PlainMessage<UserInteractionResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(UserInteractionResponse, a, b);
  }
}

/**
 * @generated from message context_engine.InterpSerializedContext
 */
export class InterpSerializedContext extends Message<InterpSerializedContext> {
  /**
   * @generated from field: string class_name = 1;
   */
  className = '';

  constructor(data?: PartialMessage<InterpSerializedContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.InterpSerializedContext';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'class_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): InterpSerializedContext {
    return new InterpSerializedContext().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): InterpSerializedContext {
    return new InterpSerializedContext().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): InterpSerializedContext {
    return new InterpSerializedContext().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | InterpSerializedContext
      | PlainMessage<InterpSerializedContext>
      | undefined,
    b:
      | InterpSerializedContext
      | PlainMessage<InterpSerializedContext>
      | undefined,
  ): boolean {
    return proto3.util.equals(InterpSerializedContext, a, b);
  }
}

/**
 * @generated from message context_engine.InterpSerializedData
 */
export class InterpSerializedData extends Message<InterpSerializedData> {
  /**
   * @generated from oneof context_engine.InterpSerializedData.data
   */
  data:
    | {
        /**
         * @generated from field: context_engine.InterpSerializedContext context = 1;
         */
        value: InterpSerializedContext;
        case: 'context';
      }
    | {
        /**
         * @generated from field: context_engine.ContextEngineModelItem model = 2;
         */
        value: ContextEngineModelItem;
        case: 'model';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<InterpSerializedData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.InterpSerializedData';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'context',
      kind: 'message',
      T: InterpSerializedContext,
      oneof: 'data',
    },
    {
      no: 2,
      name: 'model',
      kind: 'message',
      T: ContextEngineModelItem,
      oneof: 'data',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): InterpSerializedData {
    return new InterpSerializedData().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): InterpSerializedData {
    return new InterpSerializedData().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): InterpSerializedData {
    return new InterpSerializedData().fromJsonString(jsonString, options);
  }

  static equals(
    a: InterpSerializedData | PlainMessage<InterpSerializedData> | undefined,
    b: InterpSerializedData | PlainMessage<InterpSerializedData> | undefined,
  ): boolean {
    return proto3.util.equals(InterpSerializedData, a, b);
  }
}

/**
 * @generated from message context_engine.PromptMessage
 */
export class PromptMessage extends Message<PromptMessage> {
  /**
   * @generated from field: context_engine.PromptMessageRole role = 1;
   */
  role = PromptMessageRole.UNKNOWN;

  /**
   * @generated from field: string content = 2;
   */
  content = '';

  constructor(data?: PartialMessage<PromptMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.PromptMessage';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'role',
      kind: 'enum',
      T: proto3.getEnumType(PromptMessageRole),
    },
    { no: 2, name: 'content', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): PromptMessage {
    return new PromptMessage().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): PromptMessage {
    return new PromptMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): PromptMessage {
    return new PromptMessage().fromJsonString(jsonString, options);
  }

  static equals(
    a: PromptMessage | PlainMessage<PromptMessage> | undefined,
    b: PromptMessage | PlainMessage<PromptMessage> | undefined,
  ): boolean {
    return proto3.util.equals(PromptMessage, a, b);
  }
}

/**
 * Information from user facing clients like web browsers, and mobile/desktop apps.
 *
 * @generated from message context_engine.ClientContext
 */
export class ClientContext extends Message<ClientContext> {
  /**
   * timezone string of the client device, e.g. "America/Los_Angeles"
   *
   * @generated from field: string timezone = 1;
   */
  timezone = '';

  constructor(data?: PartialMessage<ClientContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ClientContext';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ClientContext {
    return new ClientContext().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ClientContext {
    return new ClientContext().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ClientContext {
    return new ClientContext().fromJsonString(jsonString, options);
  }

  static equals(
    a: ClientContext | PlainMessage<ClientContext> | undefined,
    b: ClientContext | PlainMessage<ClientContext> | undefined,
  ): boolean {
    return proto3.util.equals(ClientContext, a, b);
  }
}
