import { Metadata } from '@dropbox/dash-component-library';
import { List } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { LinkComponent } from '@mirage/link-list/Link/Link';
import { ListItemSize } from '@mirage/link-list/types';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { stackItemComputeNameFromFields } from '@mirage/service-stacks/service/utils';
import { getStackItemMetadata } from '@mirage/shared/util/stack-item-metadata';
import i18n from '@mirage/translations';
import styles from './Popover.module.css';

import type { stacks } from '@dropbox/api-v2-client';
import type { WorkingSetCardData } from '@mirage/working-set';

const SectionHeader = ({ title, count }: { title: string; count: number }) => {
  return (
    <Box className={styles.sectionHeaderContainer}>
      <Metadata size="medium" withDividers="bullet">
        <Metadata.Item>
          <Metadata.Label>
            <Text variant="label" size={'medium'}>
              {title}
            </Text>
          </Metadata.Label>
        </Metadata.Item>
        <Metadata.Item>
          <Metadata.Label>
            {i18n.t('num_items', {
              count,
            })}
          </Metadata.Label>
        </Metadata.Item>
      </Metadata>
    </Box>
  );
};

export const SectionWithItems = ({
  sectionName,
  sectionItems,
  stackData,
}: {
  sectionName?: string;
  sectionItems: stacks.StackItemShortcut[];
  stackData: WorkingSetCardData;
}) => {
  const isDarkMode = useTheme();
  return (
    <Box>
      {sectionName && (
        <SectionHeader title={sectionName} count={sectionItems?.length || 0} />
      )}
      <Box className={styles.sectionItemContainer}>
        <List>
          {sectionItems?.map((item) => {
            if (!item.name || !item.name) return null;
            return (
              <List.Item
                key={`${sectionName}-${item.sort_key}`}
                padding={0}
                className={styles.digListItem}
              >
                <LinkComponent
                  link={{
                    title: stackItemComputeNameFromFields(item),
                    url: item.url || '',
                    ...item,
                  }}
                  listItemSize={ListItemSize.Large}
                  contentIconSize={'medium'}
                  disableBoldTitle
                  metadataTextSize={'small'}
                  openPapEvents={[
                    PAP_Open_DashLink({
                      ...stackData.stackDerivedPAPProps,
                      dashActionSurface: 'start_page',
                      actionSurfaceComponent: 'tab_panel',
                    }),
                  ]}
                  isDarkMode={isDarkMode}
                  metadata={getStackItemMetadata(item, {
                    showCreatorName: false,
                    showUrl: false,
                    showUpdatedTime: true,
                  })}
                  forceContentRowEnabled
                />
              </List.Item>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};
