import { Text } from '@dropbox/dig-components/typography';
import { withShade } from '@dropbox/dig-foundations';
import { Link } from '@mirage/mosaics/BaseProfilePage/Link';
import { getTeamProfilePageHref } from '@mirage/mosaics/TeamProfilePage/utils';
import { UserProfileAvatar } from '@mirage/mosaics/UserProfilePage/UserProfileAvatar';
import { getUserProfilePageHref } from '@mirage/mosaics/UserProfilePage/utils';
import cx from 'classnames';
import styles from './UserProfileCard.module.css';

import type { AvatarSizes } from '@dropbox/dig-components/avatar';
import type { ReactNode } from 'react';
import type { LinkProps } from 'react-router-dom';

interface UserProfileCardProps {
  profile_username: string;
  email: string | null;
  display_name: string;
  team_id?: string;
  profile_info?: ReactNode;
  additional_profile_info?: string;
  size?: Exclude<AvatarSizes, 'medium' | 'standard'>;
  viewOnly?: boolean;
  isDeactivated?: boolean | null;
  directReportsCount?: number | null;
  totalReportsCount?: number | null;
  source?: string;
  onClick?: () => void;
  city?: string | null;
  showEmployeeCity?: boolean;
  target?: LinkProps['target'];
}

const UserProfileCard = ({
  profile_username,
  email,
  display_name,
  team_id,
  profile_info,
  additional_profile_info,
  source,
  size,
  isDeactivated = false,
  viewOnly = false,
  onClick,
  city,
  showEmployeeCity,
  target,
}: UserProfileCardProps) => {
  const profileLink = profile_username
    ? getUserProfilePageHref(profile_username)
    : team_id
      ? getTeamProfilePageHref(team_id)
      : '';

  return (
    <>
      <Link
        to={
          profileLink && profile_username !== 'No email available'
            ? profileLink
            : '#'
        }
        state={{ source: source ?? 'profile-card' }}
        hasNoUnderline
        isClickable={!viewOnly}
        onClick={onClick}
        target={target}
        {...withShade({
          className: cx(styles.profileLink, styles.profileContainer, {
            [styles.deactivatedContainer]: isDeactivated,
          }),
        })}
      >
        <div className={styles.avatarContainer}>
          {email && (
            <UserProfileAvatar
              user={{
                name: display_name,
                email: email,
              }}
              isInactive={Boolean(isDeactivated)}
              size="standard"
              key={profile_username}
            />
          )}
        </div>
        <div className={styles.textContainer}>
          <Text
            isBold
            className={cx({
              [styles.deactivatedName]: isDeactivated,
            })}
          >
            {display_name !== ' ' ? display_name : profile_username}
          </Text>
          {showEmployeeCity && city && <Text size="small">{city}</Text>}
          {size === 'large' ? (
            <>
              {profile_info && (
                <Text
                  size="small"
                  color="faint"
                  className={cx({
                    [styles.deactivatedName]: isDeactivated,
                  })}
                >
                  {profile_info}
                </Text>
              )}
              {additional_profile_info && !isDeactivated && (
                <Text size="small" color="faint" isBold>
                  {additional_profile_info}
                </Text>
              )}
            </>
          ) : (
            <Text
              size="small"
              color="faint"
              className={cx({
                [styles.deactivatedName]: isDeactivated,
              })}
            >
              {profile_info}
            </Text>
          )}
        </div>
      </Link>
    </>
  );
};

export default UserProfileCard;
