import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Click_DashSearchBar } from '@mirage/analytics/events/types/click_dash_search_bar';
import { PAP_Close_DashSearchBar } from '@mirage/analytics/events/types/close_dash_search_bar';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { useIsStartPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { OpenTypeaheadAtom } from '@mirage/growth/onboarding/getting-started-checklist/hooks/checklist-atoms';
import { DebugCard } from '@mirage/mosaics/DebugCard';
import { launchSelectedMainContentItem } from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { useKeyboardNavigation } from '@mirage/mosaics/GlobalNav/useKeyboardNavigation';
import { generateSearchURL } from '@mirage/search/hooks/useQueryParams';
import { TypeaheadOverlay } from '@mirage/search/SearchResults';
import { useAccountIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useDualMode } from '@mirage/service-experimentation/useDualMode';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { publishEvent, SurveyEvent } from '@mirage/service-feedback';
import { namespace } from '@mirage/service-operational-metrics';
import { removePreviousQueryFromCache } from '@mirage/service-typeahead-search';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import useAMISSearchResultLogging from '@mirage/shared/hooks/useAMISSearchResultLogging';
import { mouseActivityAtom } from '@mirage/shared/hooks/useInitDetectMouseActivity';
import { calculatePapPositions } from '@mirage/shared/hooks/useResultPAPLogger';
import { preventBubble } from '@mirage/shared/hotkeys';
import { registerHotkeys } from '@mirage/shared/hotkeys/registerHotkeys';
import {
  captureTypeaheadClickMetrics,
  captureTypeaheadImpressionMetrics,
} from '@mirage/shared/operational-metrics/operational-metrics';
import { useIsMobileSizeForSidebar } from '@mirage/shared/responsive/mobile';
import { SEARCH_DASH_UUID } from '@mirage/shared/search/cache-result';
import { syncFiltersWithQuery } from '@mirage/shared/search/search-filters';
import { KeyCodes } from '@mirage/shared/util/constants';
import { useShowStackChooserModal } from '@mirage/stacks/StackChooser/StackChooser';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlyoutPanel } from '../FlyoutPanel/useFlyoutPanel';
import { globalNavIsCollapsedAtom } from '../GlobalNav/atoms';
import { typeaheadIsOpenAtom } from '../SearchBarWithTypeahead/atoms';
import { getAddToStackParams } from '../SearchBarWithTypeahead/util/util';
import { SummaryQnAEnum } from '../SummaryQnaPanel/utils';
import { Omnibar } from './components/Omnibar';
import { AdapterInput } from './components/Omnibar/InputRow/AdapterInput';
import { useConvertToOmniboxResults } from './components/ResultRow/adapterUtils/useConvertToOmniboxResults';
import { useMockAskResults } from './components/ResultRow/adapterUtils/useMockAskResults';
import { useChatNavigation } from './hooks/useChatNavigation';
import { useOmniboxInputMode } from './hooks/useOmniboxInputMode';
import styles from './Omnibox.module.css';
import { OmniboxScopedMode } from './types';

import type { stacks } from '@dropbox/api-v2-client';
import type { SessionStartReason } from '@mirage/analytics/events/enums/session_start_reason';
import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { TypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';
import type { Handler, Handlers } from '@mirage/shared/hotkeys';

export type OmniboxProps = TypeaheadSearch & {
  shouldFocusOnRender: boolean;
  fullWidth: boolean;
  augustRevisionEnabled: boolean;
  showBackground: boolean;
  pageScrolled: boolean;
  isOmniboxEnabled: boolean;
};

const metrics = namespace('search-results');

export function Omnibox({
  canShowTypeahead,
  setCanShowTypeahead,
  typeaheadQuery,
  setTypeaheadQuery,
  typeaheadResults,
  reloadResults,
  papLogResultOpen,
  papLogResultCopy,
  papLogResultShown,
  papLogAddToStack,
  shouldFocusOnRender = false,
  activationType,
  performPreLaunchSearch,
  fullWidth = false,
  augustRevisionEnabled = false,
  showBackground = false,
  pageScrolled = false,
  filters,
  allFilters,
  isOmniboxEnabled = true,
}: OmniboxProps) {
  // ==== BEGIN: new omnibox functionality ====
  const {
    omniboxScopedMode,
    omniboxPlaceholder,
    showBackButton,
    handleBackButtonClick,
    handleScopedButtonClick,
  } = useOmniboxInputMode();
  // TODO: remove this once we integrate chat navigation in wk4
  const { navigateToChatOrConversationAssistant } = useChatNavigation();
  // ==== END: new omnibox functionality ====

  const searchHeaderRef = useRef<HTMLDivElement | null>(null);
  const searchHeaderInputRef = useRef<HTMLInputElement>(null);
  const platform = EnvCtx.platform;
  const navigate = useNavigate();

  const desktopExperience = EnvCtx.surface === 'desktop';

  // When the mobile left nav is open, keep the typeahead dropdown closed.
  // Otherwise the typeahead dropdown (with a lower z-index) will show on top
  // of the left nav (with a higher z-index).
  const [openedTypeahead, setOpenedTypeahead] = useState(false);
  const [shouldSearchBeBoosted, setShouldSearchBeBoosted] = useState(false);
  const isCollapsed = useAtomValue(globalNavIsCollapsedAtom);
  const [isTypeaheadOpen, setIsTypeaheadOpen] = useAtom(typeaheadIsOpenAtom);
  const isMobileSize = useIsMobileSizeForSidebar(); // for sidebar state only
  const { searchSessionManager, reportPapEvent, searchAttemptSessionManager } =
    useMirageAnalyticsContext();
  const [openTypeaheadListener, setOpenTypeaheadListener] =
    useAtom(OpenTypeaheadAtom);
  const showStackChooserModal = useShowStackChooserModal(navigate);
  const { summarize, closeFlyoutPanel } = useFlyoutPanel();

  const {
    moveFocusToResults,
    resetSelectedItem: resetSelectedMainContentItem,
    userHasNotInputKey,
    setUserHasNotInputKey,
  } = useKeyboardNavigation();

  const { pathname } = useLocation();
  const isAugustStartPageEnabled = useIsStartPageAugustRevisionEnabled();
  const { account, isDropboxer } = useAccountIsDropboxer();
  const {
    logTypeaheadResultShown: logAMISResultShown,
    logTypeaheadResultLaunched: logAMISResultLaunched,
  } = useAMISSearchResultLogging(EnvCtx, account, isDropboxer || false);
  const { isDualModeEnabled, isDualModeLauncher } = useDualMode();
  const isUsingTypeaheadFiltersFeatureFlag =
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters') === 'ON';

  useEffect(() => {
    if (!canShowTypeahead) return;
    setOpenedTypeahead(true);
  }, [canShowTypeahead, setOpenedTypeahead]);

  useEffect(() => {
    if (canShowTypeahead || !openedTypeahead) return;
    reportPapEvent(
      PAP_Close_DashSearchBar({
        actionSurfaceComponent: 'search_bar',
        featureLine: 'search',
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
  }, [canShowTypeahead, openedTypeahead, reportPapEvent, searchSessionManager]);

  useEffect(() => {
    if (!canShowTypeahead || !isMobileSize || isCollapsed) return;
    setCanShowTypeahead(false);
    // TODO: Fix up the deps properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowTypeahead, isMobileSize, isCollapsed]);

  // close flyout panel when typeahead is opened
  useEffect(() => {
    if (!canShowTypeahead) return;
    closeFlyoutPanel();
  }, [canShowTypeahead, closeFlyoutPanel]);

  const extendSearchSession = useCallback(
    (sessionStartReason: SessionStartReason | undefined, query: string) => {
      searchSessionManager.extendOrCreateSession(sessionStartReason);
      if (query === '') return;
      searchSessionManager.updateProperties({
        hasQuery: true,
      });
    },
    [searchSessionManager],
  );

  const handleSubmitToSERP = useCallback((nextQuery: string) => {
    setCanShowTypeahead(false);
    searchAttemptSessionManager.updateProperties({
      searchSurface: 'top_nav',
    });
    publishEvent(SurveyEvent.CompleteSearchQuery, {}, true);
    navigate(
      generateSearchURL(
        nextQuery,
        isUsingTypeaheadFiltersFeatureFlag
          ? syncFiltersWithQuery(nextQuery, filters, allFilters)
          : [],
      ),
    );
  }, []);

  // handler to delegate to main search using prior or suggested query as input to main search
  const handleTaggedSearchSubmit = useCallback(
    (
      result: typeahead.ScoredPreviousQuery | typeahead.ScoredSuggestedQuery,
      isDefaultSearch: boolean,
    ) => {
      searchSessionManager.updateProperties({
        actionSurfaceComponent: 'search_dropdown',
      });
      const queryToSearch =
        typeaheadQuery && result.type === typeahead.ResultType.SuggestedQuery
          ? typeaheadQuery
          : result.result.query;

      setTypeaheadQuery(queryToSearch, !isDefaultSearch);
      handleSubmitToSERP(queryToSearch);
      extendSearchSession('select_typeahead_result', typeaheadQuery);
      searchHeaderInputRef.current?.focus();
    },
    [
      extendSearchSession,
      handleSubmitToSERP,
      searchSessionManager,
      setTypeaheadQuery,
      typeaheadQuery,
    ],
  );

  const handleSearchBarClick = () => {
    reportPapEvent(
      PAP_Click_DashSearchBar({
        actionSurfaceComponent: 'search_bar',
        featureLine: 'search',
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
    openTypeahead(typeaheadQuery);
  };

  const onInteractedWithResult = useCallback(
    (result: typeahead.ScoredResult) => {
      searchSessionManager.updateProperties({
        actionSurfaceComponent: 'search_dropdown',
      });
      extendSearchSession('select_typeahead_result', typeaheadQuery);
      if ('id3p' in result.result) logAMISResultLaunched(result.result);
    },
    [
      extendSearchSession,
      logAMISResultLaunched,
      searchSessionManager,
      typeaheadQuery,
    ],
  );

  /**
   * Typeahead result mgmt
   */
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [hoveredIdx, setHoveredIdx] = useState<number | null>(null);

  // Note: this largely mirrors useConvertToTypeaheadResults, but includes
  // changes for mapping to the correct icon for displays
  const convertScoredResultsToOmniboxResults = useConvertToOmniboxResults({
    typeaheadQuery,
    selectedIndex: selectedIdx,
    hoveredIndex: hoveredIdx,
    setTypeaheadQuery,
    shouldSearchBeBoosted,
    handleTaggedSearchSubmit,
    removePreviousQuery: (result) =>
      removePreviousQueryFromCache(result).then(() => reloadResults()),
    onInteractedWithResult,
    papLogResultOpen,
    papLogResultCopy,
    allFilters,
  });

  // Sort the typeaheadResults array to prioritize items with priority
  let searchResults = convertScoredResultsToOmniboxResults(typeaheadResults);

  // TEMP: override the search results with mock ask results for demo
  const { results: mockAskResults } = useMockAskResults(typeaheadQuery);
  if (omniboxScopedMode === OmniboxScopedMode.ASK) {
    searchResults = mockAskResults;
  }
  /**
   * Keyboard navigation and handlers
   */
  const isMouseActive = useAtomValue(mouseActivityAtom);

  useEffect(() => {
    setSelectedIdx(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasResultsChanged(searchResults)]);

  useEffect(() => {
    if (canShowTypeahead) return;
    setSelectedIdx(0);
  }, [canShowTypeahead]);

  const moveUp = useCallback<Handler>(
    (e) => {
      if (!canShowTypeahead) return;
      preventBubble(e);
      setSelectedIdx((prevIdx) => handlePrev(prevIdx, searchResults.length));
    },
    [canShowTypeahead, setSelectedIdx, searchResults.length],
  );

  const moveDown = useCallback<Handler>(
    (e) => {
      if (!canShowTypeahead || !typeaheadResults.length) {
        preventBubble(e);
        moveFocusToResults();
        return;
      }
      setSelectedIdx((prevIdx) => handleNext(prevIdx, searchResults.length));
    },
    [
      canShowTypeahead,
      setSelectedIdx,
      searchResults.length,
      moveFocusToResults,
      typeaheadResults.length,
    ],
  );

  /**
   * Does a fresh search with the most up to date version of the query, and
   * waits to call the callback until all sources have returned from the search
   */
  const getMostUpToDateTypeaheadResults = useCallback(
    // CAUTION: Returning (TypeaheadResult | undefined)[] because sentry is alerting that there are undefined items in the array for some reason
    // Keep these types as-is until we can figure out why this is happening
    (callback: (typeaheadResults: (TypeaheadResult | undefined)[]) => void) => {
      performPreLaunchSearch(typeaheadQuery, (finalResults) => {
        let typeaheadResults =
          convertScoredResultsToOmniboxResults(finalResults);
        if (omniboxScopedMode === OmniboxScopedMode.ASK) {
          typeaheadResults = mockAskResults;
        }
        callback(typeaheadResults);
      });
    },
    [
      convertScoredResultsToOmniboxResults,
      performPreLaunchSearch,
      typeaheadQuery,
      omniboxScopedMode,
      mockAskResults,
    ],
  );

  const launchResultKeyboardHandler = useCallback<Handler>(() => {
    if (!canShowTypeahead) return;

    getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
      const result = upToDateTypeaheadResults[selectedIdx];

      result?.launchResult?.('enter');
      // simulate uxa click for keyboard actions
      dispatchElementClicked(
        createUxaElementId(
          result?.uuid === SEARCH_DASH_UUID
            ? 'default_typeahead_result_row'
            : 'typeahead_result_row',
          {
            actionSurface: 'search_typeahead',
            actionSurfaceComponent: 'search_dropdown',
            featureLine: 'search',
          },
        ),
      );

      if (result) {
        captureTypeaheadClickMetrics(metrics, result, selectedIdx + 1);
      }
      if (result && !result.keepTypeaheadOpen) {
        setCanShowTypeahead(false);
      }

      // TEMP: remove this once we have real ask suggestion results
      // some reason mock results launch result is causing a left nav bug
      if (result?.uuid === 'ask-suggested-query') {
        // Tweak where we navigate to
        navigateToChatOrConversationAssistant();
      }
    });
  }, [
    getMostUpToDateTypeaheadResults,
    canShowTypeahead,
    selectedIdx,
    setCanShowTypeahead,
    navigateToChatOrConversationAssistant,
  ]);

  const summarizeKeyboardHandler = useCallback<Handler>(() => {
    if (!canShowTypeahead) return;

    getMostUpToDateTypeaheadResults(async (upToDateTypeaheadResults) => {
      const result = upToDateTypeaheadResults[selectedIdx];
      if (
        !result ||
        !result.summarizable ||
        result.summarizable !== 'yes_summarizable'
      )
        return;

      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        upToDateTypeaheadResults,
        (r) => r?.uuid === result.uuid,
      );

      if (isDualModeLauncher) {
        // Navigate to the Search Results page inside the Persistent Window
        handleSubmitToSERP(typeaheadQuery);
        await new Promise((r) => setTimeout(r));
      }

      summarize(
        result,
        SummaryQnAEnum.TYPEAHEAD,
        ChatEntryPoint.search_bar_inline,
        resultPosition,
        resultPositionNoCta,
      );

      // simulate uxa click for keyboard actions
      dispatchElementClicked(
        createUxaElementId(
          result?.uuid === SEARCH_DASH_UUID
            ? 'default_typeahead_result_row'
            : 'typeahead_result_row',
          {
            actionSurface: 'search_typeahead',
            actionSurfaceComponent: 'search_dropdown',
            featureLine: 'search',
          },
        ),
      );

      if (result) {
        captureTypeaheadClickMetrics(metrics, result, selectedIdx + 1);
      }

      setCanShowTypeahead(false);
    });
  }, [
    getMostUpToDateTypeaheadResults,
    canShowTypeahead,
    selectedIdx,
    setCanShowTypeahead,
  ]);

  /**
   * This callback has to be synchronous, otherwise the action will
   * get blocked by the pop-up blocker in mobile browsers and Safari.
   *
   * Popup blocker logic often blocks popup windows when the popup window
   * is not opened as a direct consequence of a user action(like a click).
   * A callback that happens asynchronously is NOT a direct consequence of a user action -
   * it's sometime later and is not directly connected to that action (as the browser sees it),
   * thus the browser may not allow it.
   */
  const launchResultItemClickHandler = useCallback(
    (result: TypeaheadResult, clickedIdx: number) => {
      if (!canShowTypeahead) return;

      result.launchResult?.('click');

      captureTypeaheadClickMetrics(metrics, result, clickedIdx + 1);

      setCanShowTypeahead(false);

      // TEMP: remove this once we have real ask suggestion results
      // some reason mock results launch result is causing a left nav bug
      if (result?.uuid === 'ask-suggested-query') {
        // Tweak where we navigate to
        navigateToChatOrConversationAssistant();
      }
    },
    [
      canShowTypeahead,
      setCanShowTypeahead,
      navigateToChatOrConversationAssistant,
    ],
  );

  const copySelected = useCallback(() => {
    getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
      const selectedResult =
        upToDateTypeaheadResults[
          isMouseActive ? (hoveredIdx ?? selectedIdx) : selectedIdx
        ];
      if (selectedResult?.copyResult) {
        selectedResult.copyResult();
      }
    });
  }, [getMostUpToDateTypeaheadResults, hoveredIdx, isMouseActive, selectedIdx]);

  const addToStack = useCallback(() => {
    getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
      const selectedResult = upToDateTypeaheadResults[selectedIdx];
      if (!selectedResult) return;
      const typeaheadResult = typeaheadResults.find(
        (result) => result.uuid === selectedResult.uuid,
      );
      if (!typeaheadResult) return;

      const params = getAddToStackParams(typeaheadResult);
      if (!params) return;

      showStackChooserModal({
        metadataTitle: params.title,
        url: params.url,
        callback: (stack: stacks.Stack | undefined) => {
          papLogAddToStack(typeaheadResult, stack);
          publishEvent(SurveyEvent.AddStackItem, {}, true);
        },
      });
    });
  }, [
    getMostUpToDateTypeaheadResults,
    selectedIdx,
    typeaheadResults,
    showStackChooserModal,
    papLogAddToStack,
  ]);

  const suggestedQueryResultIndex = searchResults.findIndex(
    (result) =>
      result.scoredResult.type === typeahead.ResultType.SuggestedQuery,
  );
  const suggestedQueryScoredResult = searchResults[suggestedQueryResultIndex]
    ?.scoredResult as typeahead.ScoredSuggestedQuery;
  const searchQuery = useCallback<Handler>(() => {
    if (
      !!canShowTypeahead &&
      typeaheadQuery.length > 0 &&
      !!suggestedQueryScoredResult
    ) {
      // set the selected index to the suggested query result so autocomplete reacts appropriately
      setSelectedIdx(suggestedQueryResultIndex);
      handleTaggedSearchSubmit(suggestedQueryScoredResult, true);
      papLogResultOpen(suggestedQueryScoredResult, 'enter');
    }
  }, [
    handleTaggedSearchSubmit,
    canShowTypeahead,
    papLogResultOpen,
    suggestedQueryResultIndex,
    suggestedQueryScoredResult,
    typeaheadQuery.length,
  ]);

  const openTypeahead = useCallback(
    (newQuery: string) => {
      setTypeaheadQuery(newQuery);
      setCanShowTypeahead(true);
      extendSearchSession('select_search_input', newQuery);
    },
    [extendSearchSession, setCanShowTypeahead, setTypeaheadQuery],
  );

  const closeTypeahead = useCallback(() => {
    setCanShowTypeahead(false);
  }, [canShowTypeahead]);

  const keyHandlers = useMemo<Handlers<'typeahead'>>(
    () => ({
      moveUp,
      moveDown,
      launchResult: launchResultKeyboardHandler,
      summarize: summarizeKeyboardHandler,
      searchQuery,
      clearInput: (e) => {
        if (typeaheadQuery) {
          preventBubble(e);
          openTypeahead('');
        } else if (canShowTypeahead && !isDualModeLauncher) {
          preventBubble(e);
          setCanShowTypeahead(false);
        }
      },
      copySelected,
      addToStack,
    }),
    [
      copySelected,
      canShowTypeahead,
      launchResultKeyboardHandler,
      summarizeKeyboardHandler,
      moveDown,
      moveUp,
      openTypeahead,
      searchQuery,
      setCanShowTypeahead,
      typeaheadQuery,
      addToStack,
      isDualModeLauncher,
    ],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // This enables users to quickly launch the first item after foregrounding app
      if (userHasNotInputKey && event.key === KeyCodes.enter) {
        launchSelectedMainContentItem();
      }
      setUserHasNotInputKey(false);

      // Reset selected item when user starts typing or tabbing
      if (event.key !== KeyCodes.arrowDown) {
        resetSelectedMainContentItem();
      }

      registerHotkeys({
        event,
        keyHandlers,
        hotkeyMapName: 'typeahead',
        platform: EnvCtx.platform,
        isDesktop: desktopExperience,
        isDualModeEnabled,
      });
    },
    [
      keyHandlers,
      resetSelectedMainContentItem,
      setUserHasNotInputKey,
      userHasNotInputKey,
      desktopExperience,
      isDualModeEnabled,
    ],
  );
  const onTypeaheadResultView = useCallback(
    (item: TypeaheadResult) => {
      const result = typeaheadResults.find((r) => r.uuid === item.uuid);
      const position =
        typeaheadResults.findIndex((r) => r.uuid === item.uuid) + 1;
      if (!result) return;

      // XXX: shown results are for _all_ typeahead results -- currently filtering
      // on matching locally cached server results and not previous queries, etc.
      if ('id3p' in result.result) logAMISResultShown(result.result);
      if (!papLogResultShown) return;
      papLogResultShown(result);
      extendSearchSession('scroll_typeahead', typeaheadQuery);
      captureTypeaheadImpressionMetrics(metrics, item, position);
    },
    [logAMISResultShown, papLogResultShown, typeaheadResults],
  );

  useLayoutEffect(() => {
    if (openTypeaheadListener) {
      openTypeahead('');
      searchHeaderInputRef.current?.focus();
      setOpenTypeaheadListener(false);
    }
  }, [
    openTypeahead,
    openTypeaheadListener,
    setOpenTypeaheadListener,
    setTypeaheadQuery,
  ]);

  useEffect(() => {
    setIsTypeaheadOpen(
      Boolean(canShowTypeahead && (searchResults.length || activationType)),
    );
  }, [canShowTypeahead, searchResults.length, activationType]);

  return (
    // This key handler is not a user-interactable element.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyDown={handleKeyDown}
      onBlur={() => {
        // close handled by outside click, this closes typeahead when tabbing
        if (isMouseActive) return;
        setCanShowTypeahead(false);
      }}
      className={classNames(styles.searchContainer, {
        [styles.fullWidthSearchbar]: desktopExperience,
        [styles.typeaheadOpen]: isTypeaheadOpen,
        [styles.augustRevision]: augustRevisionEnabled,
        [styles.showBackground]: showBackground,
        [styles.scrollingBorder]: pageScrolled && desktopExperience,
        [styles.isDualModeLauncher]: isDualModeLauncher,
      })}
      data-testid="TopNav-searchBar"
    >
      {desktopExperience && (
        <div
          className={classNames(styles.searchDesktopSpacer, {
            [styles.showBackground]: showBackground,
          })}
        />
      )}
      <ClickOutside
        isActive={isTypeaheadOpen && !isDualModeLauncher}
        onClickOutside={() => {
          setCanShowTypeahead(false);
        }}
        isBlock
        isClickThroughPortaled={false}
        shouldPropagateMouseEvents={false}
      >
        {/* NOTE: assumes omnibox is always enabled so lets show this instead of the SearchHeader */}
        <Omnibar
          onAttachButtonClicked={() => {
            // TODO
          }}
          onScopedButtonClicked={handleScopedButtonClick}
          open={isTypeaheadOpen}
        >
          <AdapterInput
            query={typeaheadQuery}
            onQueryUpdate={openTypeahead}
            ref={searchHeaderRef}
            inputRef={searchHeaderInputRef}
            typeaheadIsOpen={isTypeaheadOpen}
            onClick={handleSearchBarClick}
            shouldFocusOnRender={shouldFocusOnRender}
            onCloseTypeahead={closeTypeahead}
            onShouldBoostSearchToTop={setShouldSearchBeBoosted}
            fullWidth={fullWidth}
            isDarwin={platform === 'darwin'}
            selectedIdx={selectedIdx}
            searchResults={searchResults}
            shouldSearchBeBoosted={shouldSearchBeBoosted}
            isStartPage={pathname === '/'}
            filters={filters}
            showBackButton={showBackButton}
            onBackButtonClicked={handleBackButtonClick}
            omniboxPlaceholder={omniboxPlaceholder}
          />
        </Omnibar>
        <TypeaheadOverlay
          open={isTypeaheadOpen}
          anchor={searchHeaderRef}
          query={typeaheadQuery}
          results={searchResults}
          selectedIdx={selectedIdx}
          setHoveredIdx={setHoveredIdx}
          onResultClicked={launchResultItemClickHandler}
          onListItemView={onTypeaheadResultView}
          isMouseActive={isMouseActive}
          activationType={activationType}
          fullWidth={fullWidth}
          setTypeaheadOpen={setCanShowTypeahead}
          disableActivation={pathname === '/settings/apps'}
          isAugustStartPageEnabled={isAugustStartPageEnabled}
          isOmniboxEnabled={isOmniboxEnabled}
        />
      </ClickOutside>
      {/* Portal the debug card to body so that it shows up over the search results card if it is showing */}
      {isTypeaheadOpen &&
        createPortal(
          <DebugCard typeaheadResults={typeaheadResults} />,
          document.body,
        )}
    </div>
  );
}

// Used to generate a string of uuids for react dep arrays to see if results have changed
function hasResultsChanged(results: TypeaheadResult[]): string {
  // match uuid and title because the default result needs to update when the query changes
  return results.reduce((acc, result) => acc + result.uuid + result.title, '');
}

function handleNext(current: number, numResults: number): number {
  return (current + 1) % numResults;
}

function handlePrev(current: number, numResults: number): number {
  return (current - 1 + numResults) % numResults;
}
