import AllStacksEmptyState from '../AllStacksGrid/EmptyStates/AllStacksEmptyState';
import { useAllStacksPerformanceTracking } from '../AllStacksGrid/useAllStacksPerformanceTracking';
import styles from './AllStacksList.module.css';
import { AllStacksListItem } from './AllStacksListItem';

import type { StackFilterOption } from '@mirage/service-settings/service/types';
import type { WorkingSetCardData } from '@mirage/working-set';

export const AllStacksList = ({
  workingSetCardData,
  filterPreference,
}: {
  workingSetCardData: WorkingSetCardData[];
  filterPreference?: StackFilterOption;
}) => {
  useAllStacksPerformanceTracking();

  return (
    <div className={styles.allStacksList}>
      {!workingSetCardData.length && (
        <AllStacksEmptyState filterPreference={filterPreference} />
      )}

      {workingSetCardData.map((workingSetData) => (
        <AllStacksListItem
          key={workingSetData.id}
          workingSetCardData={workingSetData}
        />
      ))}
    </div>
  );
};
