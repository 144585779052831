import { Box } from '@dropbox/dig-foundations';
import { useStackItemComment } from '@mirage/stacks/Comments/hooks/useStackItemComments';
import { StackItemComment } from '@mirage/stacks/Comments/StackItemComment';

import type { StackComment } from '@mirage/service-comments/types';

/** HOC responsible for fetching props for StackItemComment and handling logic for displaying
 *
 * @param stackItemId - file_api_id of the stack id
 * @param stackSharingId - sharing id for stack
 * @param currentAuthor - default current user data saved as author
 * @param isEnabled - feature flag to show comment UI
 * @constructor
 */
export const WithStackItemComment = ({
  stackItemId,
  stackSharingId,
  currentAuthor,
  currentUserHasWritePermissions = false,
  currentUserIsStackOwner = false,
  isEnabled = false,
}: {
  stackItemId: string;
  stackSharingId: string;
  currentAuthor: StackComment['author'];
  currentUserHasWritePermissions?: boolean;
  currentUserIsStackOwner?: boolean;
  isEnabled?: boolean;
}) => {
  const { comment, draft, onCancelComment, onPostComment, onDeleteComment } =
    useStackItemComment(stackSharingId, stackItemId);

  if (!isEnabled || !(comment || draft)) {
    return null;
  }

  return (
    <>
      {comment && (
        <StackItemComment
          currentUserHasWritePermissions={currentUserHasWritePermissions}
          currentUserIsStackOwner={currentUserIsStackOwner}
          onCancel={onCancelComment}
          onPost={async ({ id, content }) => {
            await onPostComment(content, currentAuthor, id);
          }}
          onDelete={onDeleteComment}
          {...comment}
        />
      )}
      {draft && (
        /* This is an edge-case, but if it does occur, put some distance between the comment and the draft */
        <Box marginTop={comment ? '10' : '0'} width="100%">
          <StackItemComment
            onCancel={onCancelComment}
            onPost={async ({ id, content }) => {
              await onPostComment(content, currentAuthor, id);
            }}
            // onDelete is a no-op for drafts
            onDelete={async () => {}}
            {...draft}
          />
        </Box>
      )}
    </>
  );
};
