import { MessageAssistActions } from '@mirage/mosaics/ComposeAssistant/components/chat/actions/MessageAssistActions';
import { useAssistActionsContext } from '@mirage/mosaics/ComposeAssistant/data/AssistActionsContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { memo, useCallback } from 'react';

import type { ChatConversationMessage } from '@mirage/mosaics/Chat/types';
import type { AssistAction } from '@mirage/shared/compose/assist-actions';
import type { ComposeAssistantConversationMessage } from '@mirage/shared/compose/assist-message';

interface ComposeConversationAssistActionsProps {
  transientSourcesMessage: ComposeAssistantConversationMessage | undefined;
  onOpenListActionsModal: () => void;
}
export const ComposeConversationAssistActions = memo(
  ({
    transientSourcesMessage,
    onOpenListActionsModal,
  }: ComposeConversationAssistActionsProps) => {
    const { enabledActions } = useAssistActionsContext();
    const { postUserMessage } = useComposeCurrentSessionContext();

    const source = transientSourcesMessage
      ? getTransientSource(transientSourcesMessage)
      : undefined;
    const handleTriggerAction = useCallback(
      (action: AssistAction) => {
        if (!transientSourcesMessage) {
          throw new Error(
            'Action triggered disabled not having a transient sources message it is responding to',
          );
        }
        postUserMessage({
          text: action.instruction.message,
          mustIncludeSourceContents: true,
          appendRawMessages: [transientSourcesMessage],
        });
      },
      [postUserMessage, transientSourcesMessage],
    );
    if (!source) {
      return null;
    }
    if (!enabledActions) {
      return null; // still loading
    }
    return (
      <MessageAssistActions
        source={source}
        enabledActions={enabledActions}
        onTriggerAction={handleTriggerAction}
        onOpenListActionsModal={onOpenListActionsModal}
      />
    );
  },
);
ComposeConversationAssistActions.displayName =
  'ComposeConversationAssistActions';

function getTransientSource(message: ChatConversationMessage) {
  // TODO: UX only supports at most one transient source currently
  return message.type === 'message'
    ? message.referencingSources?.find((source) => source.type === 'transient')
    : undefined;
}

/**
 * Renders a list of AssistActions relevant to the given transient message.
 * This may be disabled by a GB flag; or if onOpenListActionsModal is not provided.
 */
export function useAssistActionsRenderer(
  transientSourcesMessage: ComposeAssistantConversationMessage | undefined,
  onOpenListActionsModal?: () => void,
) {
  const assistActionsEnabled =
    useFeatureFlagValue('dash_2025_02_18_sidepanel_assist_actions') === 'ON';
  return useCallback(
    (message: ChatConversationMessage) => {
      if (!assistActionsEnabled) {
        return null;
      }
      if (!onOpenListActionsModal) {
        return null;
      }
      if (message !== transientSourcesMessage) {
        // only show AssistActions for the transient message (i.e. actions about the page that user just opened)
        return null;
      }
      return (
        <ComposeConversationAssistActions
          transientSourcesMessage={transientSourcesMessage}
          onOpenListActionsModal={onOpenListActionsModal}
        />
      );
    },
    [transientSourcesMessage, assistActionsEnabled, onOpenListActionsModal],
  );
}
