// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3692
import type { PAPEventGeneric } from "../base/event";

// Event_name: 'close.dash_miltimedia_drawer'
// Description: UI event sent from dash when Drawer with info about multimedia object was closed
// Owner: dash-exp-find-and-discover
export type Close_DashMiltimediaDrawer = PAPEventGeneric<
  'dash',
  'close',
  'dash_miltimedia_drawer',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // Unique identifier (UUIDv5 ) of the search result
    resultUuid?: string;
  }
>;

export function PAP_Close_DashMiltimediaDrawer(properties?: Close_DashMiltimediaDrawer['properties']): Close_DashMiltimediaDrawer {
  return <Close_DashMiltimediaDrawer>{
    class: 'dash',
    action: 'close',
    object: 'dash_miltimedia_drawer',
    properties,
  };
}
