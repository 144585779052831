/*

CSS variables cannot be used for media queries, so use JS to work around this.

  --dig-breakpoint__xlarge: 1920;
  --dig-breakpoint__large: 1600;
  --dig-breakpoint__medium: 1024;
  --dig-breakpoint__small: 600;
  --dig-breakpoint__xsmall: 0;

*/

import { useWindowSize } from '@react-hookz/web';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';

import type { CreateBreakpointProp } from '@dropbox/dig-components/hooks';

export enum BreakpointSize {
  XLARGE = 1920,
  LARGE = 1600,
  MEDIUM = 1024,
  SMALL = 600,
  XSMALL = 0,
}

// Need to iterate through values, so cannot be `const enum`.
export enum BreakpointClass {
  XLARGE = 'digBreakpointXlarge',
  LARGE = 'digBreakpointLarge',
  MEDIUM = 'digBreakpointMedium',
  SMALL = 'digBreakpointSmall',
  XSMALL = 'digBreakpointXsmall',
}

const DigBreakpointProp = {
  [BreakpointClass.XSMALL]: 'xsmall',
  [BreakpointClass.SMALL]: 'small',
  [BreakpointClass.MEDIUM]: 'medium',
  [BreakpointClass.LARGE]: 'large',
  [BreakpointClass.XLARGE]: 'xlarge',
} as const;

// Using an atom so that we can use it easily within React.
const breakpointClassAtom = atom(BreakpointClass.XLARGE);

const subtractWidthAtom = atom(0);

/** Update the breakpoint classnames on the DOM body. */
function updateGlobalBreakpointClass(breakpointClass: BreakpointClass) {
  for (const cls of Object.values(BreakpointClass)) {
    if (typeof cls === 'number') continue;

    if (breakpointClass === cls) {
      document.body.classList.add(cls);
    } else {
      document.body.classList.remove(cls);
    }
  }
}

function getBreakpointClassForWidth(width: number) {
  if (width < BreakpointSize.SMALL) {
    return BreakpointClass.XSMALL;
  } else if (width < BreakpointSize.MEDIUM) {
    return BreakpointClass.SMALL;
  } else if (width < BreakpointSize.LARGE) {
    return BreakpointClass.MEDIUM;
  } else if (width < BreakpointSize.XLARGE) {
    return BreakpointClass.LARGE;
  }
  return BreakpointClass.XLARGE;
}

export function useSidebarWidth() {
  return useAtomValue(subtractWidthAtom);
}

/** Window width minus sidebar width. */
export function useAdjustedWidth() {
  const windowWidth = useWindowSize().width;
  const subtractWidth = useAtomValue(subtractWidthAtom);
  return windowWidth - subtractWidth;
}

/**
 * We only need one active hook to update the breakpoint class value on window
 * resizes. Users should use useBreakpointClass() instead.
 */
function useInitBreakpointClass() {
  const setBreakpointClass = useSetAtom(breakpointClassAtom);
  const newWidth = useAdjustedWidth();

  // Make sure that we perform updates only when something has changed.
  const lastBreakpointClass = useRef<BreakpointClass>();

  useEffect(() => {
    const newBreakpointClass = getBreakpointClassForWidth(newWidth);

    if (newBreakpointClass !== lastBreakpointClass.current) {
      lastBreakpointClass.current = newBreakpointClass;
      updateGlobalBreakpointClass(newBreakpointClass);
      setBreakpointClass(newBreakpointClass);
    }
  }, [newWidth, setBreakpointClass]);
}

const InitBreakpointClass: React.FC = () => {
  useInitBreakpointClass();
  return null;
};

// Users will never need to set this value, so export a reader only.
export function useBreakpointClass() {
  return useAtomValue(breakpointClassAtom);
}

const div = document.createElement('div');
div.id = 'dashBreakpointsHook';
div.style.display = 'none';
document.body.appendChild(div);

// Use the react context so that callers can listen for changes.
render(<InitBreakpointClass />, div);

/** Allow the sidebar to subtract the actual usable width. */
export function useAdjustBreakpointClass(subtractWidth: number) {
  const setSubtractWidth = useSetAtom(subtractWidthAtom);

  useEffect(() => {
    setSubtractWidth(subtractWidth);

    return () => {
      setSubtractWidth(0);
    };
  }, [setSubtractWidth, subtractWidth]);
}

/**
 * Drop-in replacement for DIG's useResolveBreakpointProp().
 * Note that this uses the adjusted width (subtract sidebar width).
 */
export function useResolveBreakpointProp<T>(
  responsiveProp: CreateBreakpointProp<T>,
): {
  value: T | undefined;
} {
  const breakpointClass = useBreakpointClass();

  const digProp = DigBreakpointProp[breakpointClass];
  return { value: responsiveProp?.[digProp] };
}

/**
 * Resolve by window width without following DIG's breakpoints.
 * Note that this uses the adjusted width (subtract sidebar width).
 */
export function useResolveWidthProp<T>(responsiveProp: {
  [width: number]: T;
}): T | undefined {
  const newWidth = useAdjustedWidth();
  const [t, setT] = useState<T>();

  const sortedKeys = useMemo(
    () =>
      Object.keys(responsiveProp)
        .map((k) => Number(k))
        // Need a comparator to sort by number.
        .sort((a, b) => a - b),
    [responsiveProp],
  );

  useEffect(() => {
    for (const k of sortedKeys) {
      if (newWidth <= k) {
        setT(responsiveProp[k]);
        return;
      }
    }

    setT(undefined);
  }, [responsiveProp, sortedKeys, newWidth]);

  return t;
}
