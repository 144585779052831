import i18n from '@mirage/translations';
import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  isToday,
  isYesterday,
} from 'date-fns';

/**
 * 1 - 60 sec: Updated seconds ago
 * 0 - 1 hours: Updated 15 minutes ago
 * 1 - 12 hours: Updated 10 hours ago
 * Same calendar day: Updated 14 hours ago
 * Previous calendar day: Updated yesterday
 * 2 - 10 calendar days: Updated 3 days ago
 * 10+ days: Last updated on [Abbreviated month, date, year]
 */
export type TimeAgoStringConfig = {
  capitalize?: boolean;
  // "10 hours ago" vs "10h ago", *does not abbreviate "seconds ago"
  abbreviateTime?: boolean;
  // "February 21, 2025" vs "Feb 21, 2025"
  abbreviateDate?: boolean;
  // defaults to en-US
  locale?: string;
};

const DEFAULT_CONFIG = {
  capitalize: false,
  abbreviateTime: false,
  abbreviateDate: true,
  locale: i18n.language || 'en-US',
};

export function getTimeAgoString(
  pastDate: number,
  configOverride?: TimeAgoStringConfig,
) {
  const config = {
    ...DEFAULT_CONFIG,
    ...configOverride,
  };

  try {
    const currentDate = new Date();
    const minutesDifference = differenceInMinutes(currentDate, pastDate, {
      roundingMethod: 'floor',
    });
    const rtf = new Intl.RelativeTimeFormat(config.locale, {
      numeric: 'auto',
      style: config.abbreviateTime ? 'narrow' : 'long',
    });
    const hoursDifference = differenceInHours(currentDate, pastDate);
    const daysDifference = differenceInCalendarDays(currentDate, pastDate);

    let timeAgo: string;
    if (minutesDifference < 1) {
      timeAgo = i18n.t('seconds_ago');
    } else if (minutesDifference <= 60) {
      timeAgo = rtf.format(-minutesDifference, 'minute');
    } else if (hoursDifference <= 12 || isToday(pastDate)) {
      timeAgo = rtf.format(-hoursDifference, 'hour');
    } else if (isYesterday(pastDate)) {
      timeAgo = rtf.format(-1, 'day');
    } else if (daysDifference <= 10) {
      timeAgo = rtf.format(-daysDifference, 'day');
    } else {
      timeAgo = new Intl.DateTimeFormat(config.locale, {
        dateStyle: config.abbreviateDate ? 'medium' : 'long',
      }).format(new Date(pastDate));
    }

    if (timeAgo) {
      return config.capitalize
        ? timeAgo[0].toUpperCase() + timeAgo.slice(1)
        : timeAgo;
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error parsing date for time ago string.');
  }
  return '';
}
