import { UIIcon } from '@dropbox/dig-icons';
import { ShareArrowLine, ShareLine } from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { ButtonWithTooltip } from '@mirage/shared/buttons/ButtonWithTooltip';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { useShareModal } from '../ShareModal/ShareModal';

import type { stacks } from '@dropbox/api-v2-client';
import type { ButtonProps } from '@dropbox/dig-components/buttons';
import type { CoreTooltipProps } from '@mirage/shared/util/BaseButtonWithTooltip';

interface ShareStackButtonProps {
  stack: stacks.Stack;
  iconType?: 'share' | 'arrow';
  shareButtonTooltipProps?: CoreTooltipProps;
  iconColor?: string;
  disabled?: boolean;
}

export const ShareStackButton: React.FC<
  ShareStackButtonProps & Partial<ButtonProps>
> = ({
  stack,
  iconType = 'share',
  iconColor,
  shareButtonTooltipProps = { title: '' },
  disabled,
  ...props
}) => {
  const { shareModalJsx, openShareModal } = useShareModal({ stack });
  let shareIcon = ShareLine;
  if (iconType === 'arrow') {
    shareIcon = ShareArrowLine;
  }
  return (
    <>
      <ButtonWithTooltip
        variant="opacity"
        data-testid="ShareStack"
        onClick={openShareModal}
        data-uxa-log={createUxaElementId('share_button', {
          actionSurfaceComponent: 'stacks',
          featureLine: 'stacks',
        })}
        data-uxa-entity-id={stack.namespace_id}
        aria-label={i18n.t('share')}
        withIconStart={
          <UIIcon
            src={shareIcon}
            style={iconColor ? { color: iconColor } : {}}
          />
        }
        tooltipProps={shareButtonTooltipProps}
        disabled={disabled}
        {...props}
      >
        {i18n.t('share')}
      </ButtonWithTooltip>
      {shareModalJsx}
    </>
  );
};

export const ShareStackIconButton: React.FC<ShareStackButtonProps> = ({
  stack,
  iconColor,
}) => {
  const { shareModalJsx, openShareModal } = useShareModal({ stack });
  return (
    <>
      <IconButtonWithTooltip
        tooltipProps={{
          title: i18n.t('share'),
        }}
        variant="transparent"
        onClick={openShareModal}
        data-testid="ShareStack"
        aria-label={i18n.t('share')}
        data-uxa-log={createUxaElementId('share_button', {
          actionSurfaceComponent: 'stacks',
          featureLine: 'stacks',
        })}
        data-uxa-entity-id={stack.namespace_id}
      >
        <UIIcon
          src={ShareArrowLine}
          style={iconColor ? { color: iconColor } : {}}
        />
      </IconButtonWithTooltip>
      {shareModalJsx}
    </>
  );
};
