import { Tabs } from '@dropbox/dig-components/tabs';
import { Select } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { composeVoiceSamples } from '@mirage/shared/compose/compose-voice';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { memo, useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './SourcesFromSettingsPreviewPane.module.css';

import type {
  ComposeVoice,
  ComposeVoicePreviewContentType,
  ComposeVoiceSample,
} from '@mirage/shared/compose/compose-voice';

interface SourcesFromSettingsPreviewPaneProps {
  currentVoiceState: ComposeVoice;
}
export const SourcesFromSettingsPreviewPane = memo(
  ({ currentVoiceState }: SourcesFromSettingsPreviewPaneProps) => {
    const [selectedSampleType, setSelectedSampleType] = useState(
      composeVoiceSamples[0].contentType,
    );
    const [selectedTab, setSelectedTab] = useState('original');
    const handleSampleChange = (text: string) => {
      setSelectedSampleType(text as ComposeVoicePreviewContentType);
    };
    const handleTabSelection = (tabId: string) => {
      setSelectedTab(tabId);
    };
    const selectedRedraftedSample = currentVoiceState?.redraftedSamples?.find(
      (sample) => sample.contentType === selectedSampleType,
    );
    const selectedComposeVoicePreview = composeVoiceSamples.find(
      (preview) => preview.contentType === selectedSampleType,
    );
    useEffect(() => {
      if (currentVoiceState?.redraftedSamples?.length) {
        setSelectedTab('tone');
      }
    }, [currentVoiceState?.redraftedSamples]);
    const isMobile = useIsMobileSize();
    const getSampleLabel = useCallback(
      (sample: ComposeVoiceSample, index: number) =>
        isMobile ? `Sample #${index + 1}` : `${sample.contentType} Sample`,
      [isMobile],
    );
    return (
      <div className={styles.SourcesFromSettingsPreviewPaneContainer}>
        <div className={styles.SourcesFromSettingsPreviewPaneHeader}>
          <Tabs selectedTab={selectedTab} onSelection={handleTabSelection}>
            <Tabs.Group>
              <Tabs.Tab id={'original'}>Original</Tabs.Tab>
              <Tabs.Tab id={'tone'}>With tone applied</Tabs.Tab>
            </Tabs.Group>
          </Tabs>
          <div className={styles.SourcesFromSettingsPreviewPaneSelect}>
            <Select
              id="select"
              value={selectedSampleType}
              onChange={handleSampleChange}
              placeholder="Placeholder"
              size="medium"
            >
              {composeVoiceSamples.map((sample, i) => (
                <Select.Option
                  key={sample.contentType}
                  value={sample.contentType}
                >
                  {getSampleLabel(sample, i)}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className={styles.SourcesFromSettingsPreviewPaneText}>
          <Text variant="paragraph" size="large">
            <ReactMarkdown>
              {selectedTab === 'original'
                ? selectedComposeVoicePreview?.text || ''
                : selectedRedraftedSample?.text || ''}
            </ReactMarkdown>
          </Text>
        </div>
      </div>
    );
  },
);
SourcesFromSettingsPreviewPane.displayName = 'SourcesFromSettingsPreviewPane';
