import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { Service } from './service';
import type { CommentsSubjectData } from './types';

const commentsService = services.get<Service>(ServiceId.COMMENTS);

export default commentsService;

export function addStackComment(
  stackSharingId: string,
  stackItemId: string,
  content: string,
) {
  return rx.firstValueFrom(
    commentsService.addStackComment(stackSharingId, stackItemId, content),
  );
}

export function deleteStackComment(stackSharingId: string, commentId: string) {
  return rx.firstValueFrom(
    commentsService.deleteStackComment(stackSharingId, commentId),
  );
}

export function editStackComment(
  stackSharingId: string,
  stackItemId: string,
  commentId: string,
  content: string,
) {
  return rx.firstValueFrom(
    commentsService.editStackComment(
      stackSharingId,
      stackItemId,
      commentId,
      content,
    ),
  );
}

export function listStackComments(stackSharingId: string) {
  return rx.firstValueFrom(commentsService.listStackComments(stackSharingId));
}

export function subscribeToComments(
  callback: (data: CommentsSubjectData) => void | Promise<void>,
) {
  return commentsService.commentsObservable().subscribe(callback);
}
