import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { Service } from './service';

const service = services.get<Service>(ServiceId.AUDIT_LOGGING);

export type { Service };

// TODO: Stack names are not always unique, figure out a way to differentiate
export function auditLogUserOpenedStack(
  stackName: string,
  timestampMs: number,
): Promise<void> {
  return rx.firstValueFrom(
    service.auditLogUserOpenedStack(stackName, timestampMs),
  );
}

export function auditFirstLoginDesktop(timestampMs: number): Promise<void> {
  return rx.firstValueFrom(service.auditFirstLoginDesktop(timestampMs));
}

export function auditFirstLoginWeb(timestampMs: number): Promise<void> {
  return rx.firstValueFrom(service.auditFirstLoginWeb(timestampMs));
}
