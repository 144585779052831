import { handlers } from './mockFetchData';

/**
 * Not sure which env variable we can rely on yet. For now also use a
 *   manual flag coupled with a unit test to be safe.
 */
export const MOCK_FETCH_WITH_TEST_DATA = false;

export type MockedFetch = {
  (input: RequestInfo, init?: RequestInit): Promise<Response>;
  __isMocked?: true;
};

let _originalFetch = window.fetch;

export const mockedFetch: MockedFetch = async (
  input: RequestInfo,
  init: RequestInit = {},
) => {
  if (typeof input === 'string' && typeof init.body === 'string') {
    for (const { test, handler } of handlers) {
      if (test(input)) {
        // eslint-disable-next-line no-console
        console.warn(
          `[Team Invites] Mocking API call to ${input} with test data`,
        );
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return Promise.resolve(
          new Response(JSON.stringify(handler(init.body)), {
            status: 200,
            headers: { 'Content-Type': 'application/json' },
          }),
        );
      }
    }
  }
  return _originalFetch(input, init);
};

export const mockFetchWithTestData = (shouldMock: boolean) => {
  if (!shouldMock) {
    return;
  }
  if (!(window.fetch as MockedFetch)['__isMocked']) {
    // eslint-disable-next-line no-console
    console.warn(
      '[Team Invites] Mock fetch is enabled. API calls will be intercepted and mocked with test data.',
    );
    mockedFetch.__isMocked = true;
    Object.defineProperty(window, 'fetch', {
      // eslint-disable-next-line no-restricted-syntax
      configurable: process.env.NODE_ENV === 'test',
      get() {
        return mockedFetch;
      },
      set(value) {
        // from here on we redirect overwrite attempts to original implementation
        //   mockedFetch closure should have access to updated value
        _originalFetch = value;
      },
    });
  }
};

export const maybeMockFetchWithTestData = () =>
  mockFetchWithTestData(
    MOCK_FETCH_WITH_TEST_DATA &&
      // eslint-disable-next-line no-restricted-syntax
      process.env.NODE_ENV === 'development',
  );
