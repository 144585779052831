import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { useResolveWidthProp } from '@mirage/shared/responsive/breakpoints';
import { WorkingSetCard } from '@mirage/working-set';
import classnames from 'classnames';
import {
  ALL_STACKS_GRID_THREE_COLUMN_BREAKPOINT,
  ALL_STACKS_GRID_TWO_COLUMN_BREAKPOINT,
} from '../Helpers/Utils';
import styles from './AllStacksGrid.module.css';
import { ControlBar } from './ControlBar';
import AllStacksEmptyState from './EmptyStates/AllStacksEmptyState';
import { useAllStacksPerformanceTracking } from './useAllStacksPerformanceTracking';

import type { StackFilterOption } from '@mirage/service-settings/service/types';
import type { WorkingSetCardData } from '@mirage/working-set';

type AllStacksGridProps = {
  workingSetCardData: WorkingSetCardData[];
  filterPreference?: StackFilterOption;
};

export default function AllStacksGrid({
  workingSetCardData,
  filterPreference,
}: AllStacksGridProps) {
  const augustRevision = useAllStacksAugustRevisionEnabled();
  // Note: DIG breakpoints don't work well here, so use custom breakpoints.
  const numColumns =
    useResolveWidthProp({
      [ALL_STACKS_GRID_TWO_COLUMN_BREAKPOINT]: 1,
      [ALL_STACKS_GRID_THREE_COLUMN_BREAKPOINT]: 2,
    }) ?? 3;

  useAllStacksPerformanceTracking();

  return (
    <>
      <div
        className={classnames(styles.wrapper, styles.wrapperMarch, {
          [styles.oneColumn]: numColumns === 1,
          [styles.twoColumns]: numColumns === 2,
          [styles.onlyCardGrid]: augustRevision,
        })}
      >
        <div className={styles.grid}>
          {!augustRevision && (
            <div className={styles.controlBar} key="controlBar">
              <ControlBar hasNoStacks={workingSetCardData.length < 1} />
            </div>
          )}
          {!workingSetCardData.length && (
            <AllStacksEmptyState
              className={styles.emptyState}
              filterPreference={filterPreference}
            />
          )}
          {workingSetCardData.map((workingSetData) => (
            <div key={workingSetData.id}>
              <WorkingSetCard
                workingSetCardData={workingSetData}
                numLinksToShow={10}
                onClick={workingSetData.onClickCard}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
