/**
 * This hook is used to manage the omnibox input mode
 */
import { atom, useAtom } from 'jotai';
import { OmniboxScopedMode } from '../types';

const omniboxModeAtom = atom<OmniboxScopedMode>(OmniboxScopedMode.DEFAULT);
const OMNIBOX_SCOPED_MODE_TO_PLACEHOLDER_MAP = {
  [OmniboxScopedMode.DEFAULT]: 'Find anything...',
  [OmniboxScopedMode.FIND]: 'Find...',
  [OmniboxScopedMode.ASK]: 'Ask...',
  [OmniboxScopedMode.CREATE]: 'Create...',
  [OmniboxScopedMode.ORGANIZE]: 'Organize...',
};

export const useOmniboxInputMode = () => {
  const [omniboxScopedMode, setOmniboxScopedMode] = useAtom(omniboxModeAtom);
  const omniboxPlaceholder =
    OMNIBOX_SCOPED_MODE_TO_PLACEHOLDER_MAP[omniboxScopedMode];
  const showBackButton = omniboxScopedMode !== OmniboxScopedMode.DEFAULT;

  // usecallback?
  const handleBackButtonClick = () => {
    setOmniboxScopedMode(OmniboxScopedMode.DEFAULT);
  };

  // usecallback?
  const handleScopedButtonClick = (mode: OmniboxScopedMode) => {
    setOmniboxScopedMode(mode);
  };

  return {
    omniboxScopedMode,
    omniboxPlaceholder,
    showBackButton,
    handleBackButtonClick,
    handleScopedButtonClick,
  };
};
