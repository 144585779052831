import { getCurrentAccount, listenForAccount } from '@mirage/service-auth';
import { tagged } from '@mirage/service-logging';
import { getAll as getSettings } from '@mirage/service-settings';
import windowOrEnv from '@mirage/shared/util/multiSurfacePwEnv';
import i18next, { changeLanguage, t } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next, Trans as I18NextTrans } from 'react-i18next';
import en from './langs/en-US';

import type {
  GetValidI18nKeys,
  TFunction,
  TransComponentType,
  TransObjShape,
} from '@mirage/translations/types';

const logger = tagged('translations');

export type I18nKey = GetValidI18nKeys<typeof en>;

function convertTranslations(translationObject: TransObjShape) {
  return Object.fromEntries(
    Object.entries(translationObject).map(([key, value]) => [
      key as I18nKey,
      value.message,
    ]),
  );
}

function debugMissingStrings(translationObject: TransObjShape) {
  return Object.fromEntries(
    Object.entries(translationObject).map(([key, value]) => [
      (key.endsWith('_other') ? key.slice(0, key.length - 6) : key) as I18nKey,
      `${zeroWidthSpace}${value.message}`,
    ]),
  );
}

export const i18nAutomaticLocale = 'auto';
export const i18nDebugLocale = 'debugMissingStrings';
export const i18nUnlocalizableStringClass = 'unlocalizableString';
export const i18nUnlocalizedClass = 'unlocalizedStringDebug';
export const i18nFallbackLanguage = 'en';
export const meticulousRedactedStringClass = 'meticulous-redact-recording';
export const zeroWidthSpace = '​';

let debugStringsTimer: NodeJS.Timeout | undefined;

export const updateI18nDebugMode = (enable: boolean) => {
  if (enable && !debugStringsTimer) {
    debugStringsTimer = setInterval(() => {
      const selector = `*:not(script):not(noscript):not(style):not(.${i18nUnlocalizedClass})`;
      const elements = Array.from(document.querySelectorAll(selector)).filter(
        (el) => {
          if (!(el instanceof HTMLElement)) return false;
          const text = Array.from(el.childNodes)
            .filter((c) => c.nodeType === Node.TEXT_NODE)
            .map((c) => c.textContent)
            .join('')
            .trim();
          const isEmoji = /\p{Emoji}/u.test(text);
          return !!text && !isEmoji && !text.includes(zeroWidthSpace);
        },
      ) as HTMLElement[];
      elements.forEach((el) => el.classList.add(i18nUnlocalizedClass));
    }, 1000);
  } else if (!enable && debugStringsTimer) {
    clearInterval(debugStringsTimer);
    debugStringsTimer = undefined;
    document
      .querySelectorAll(`.${i18nUnlocalizedClass}`)
      .forEach((el) => el.classList.remove(i18nUnlocalizedClass));
  }
};

const translation = convertTranslations(en);

const resources = {
  en: { translation },
  'en-GB': { translation },
  'fr-CA': { translation },
  ja: { translation },
  [i18nDebugLocale]: { translation: debugMissingStrings(en) },
};

export type SupportedLocale = keyof typeof resources;

export const isSupportedLocale = (locale: string): locale is SupportedLocale =>
  locale in resources;

export const supportedLocaleConfig: Record<SupportedLocale, string> = {
  en: 'English (United States)',
  'en-GB': 'English (United Kingdom)',
  'fr-CA': 'Français (Canada)',
  ja: '日本語',
  [i18nDebugLocale]: '(Debug) Highlight unlocalized strings',
};

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(initReactI18next).init({
  fallbackLng: i18nFallbackLanguage,
  interpolation: {
    prefix:
      windowOrEnv.PLAYWRIGHT_DISABLE_INTERPOLATION === 'true'
        ? '[[[['
        : undefined,
    suffix:
      windowOrEnv.PLAYWRIGHT_DISABLE_INTERPOLATION === 'true'
        ? ']]]]'
        : undefined,
    escapeValue: false,
  },
  returnEmptyString: false,
  returnNull: false,
  keySeparator: false,
  resources,
});

const i18n = { ...i18next, t: t as TFunction<typeof en>, unchecked: t };

// When we call `changeLanguage`, some properties of i18next (such as `language`) are changed
// So we need to reflect those changes back into the `i18n` object that is used throughout the codebase
const refreshI18n = () => {
  Object.keys(i18next).forEach(
    // @ts-expect-error i18n is a superset of i18next
    (key) => key !== 'changeLanguage' && (i18n[key] = i18next[key]),
  );
  updateI18nDebugMode(i18next.language === i18nDebugLocale);
};

i18n.changeLanguage = (locale: string) => changeLanguage(locale, refreshI18n);

export const getAccountLocale = async () =>
  (await getCurrentAccount())?.locale ?? '';

export const configureCurrentLocale = async () => {
  // If the user has manually configured a locale, use that
  const { locale } = await getSettings();
  if (isSupportedLocale(locale)) {
    return i18n.changeLanguage(locale);
  }

  // Otherwise, if the account has a supported locale, use that
  const accountLocale = await getAccountLocale();
  if (isSupportedLocale(accountLocale)) {
    return i18n.changeLanguage(accountLocale);
  }

  // Otherwise i18next will just use the fallback language (en)
};

// Configure the locale on startup
configureCurrentLocale().catch((e) =>
  logger.error('Failed to load locale from settings or account', e),
);

// Update the locale when the account changes
listenForAccount().subscribe(configureCurrentLocale);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ensureAllLocalesAreLoaded: Record<
  SupportedLocale,
  Promise<typeof i18next | void>
> = {
  // Bundled with the app
  en: Promise.resolve(),
  debugMissingStrings: Promise.resolve(),

  // Lazy-loaded
  'en-GB': import('./langs/en-GB.json')
    .then(({ default: enGB }) =>
      i18n.addResourceBundle('en-GB', 'translation', convertTranslations(enGB)),
    )
    .catch((e) => logger.error('Failed to load en-GB.json', e)),
  'fr-CA': import('./langs/fr-CA.json')
    .then(({ default: frCA }) =>
      i18n.addResourceBundle('fr-CA', 'translation', convertTranslations(frCA)),
    )
    .catch((e) => logger.error('Failed to load fr-CA.json', e)),
  ja: import('./langs/ja-JP.json')
    .then(({ default: ja }) =>
      i18n.addResourceBundle('ja', 'translation', convertTranslations(ja)),
    )
    .catch((e) => logger.error('Failed to load ja-JP.json', e)),
};

export default i18n;

export const Trans = I18NextTrans as TransComponentType<typeof en>;
