import { defineStrictThemeOverrides } from '@dropbox/dig-foundations';
import { hexToHsva } from '@uiw/color-convert';
import { isValidHexColor } from '../utils';
import {
  azaleaTheme,
  canopyTheme,
  cloudTheme,
  crimsonTheme,
  goldTheme,
  limeTheme,
  navyTheme,
  oceanTheme,
  orchidTheme,
  pinkTheme,
  plumTheme,
  rustTheme,
  sunsetTheme,
  tangerineTheme,
  vividVargasTheme,
  zenTheme,
} from './themeDefinitions';

import type { ThemeFamilyKey } from '@dropbox/dash-component-library';
import type { AvailableThemeOverridesStrict } from '@dropbox/dig-foundations';

type ThemeDefinition = {
  primary: AvailableThemeOverridesStrict;
  subtle: AvailableThemeOverridesStrict;
};

export enum StackTheme {
  Ocean,
  Plum,
  Azalea,
  Crimson,
  Rust,
  Gold,
  Canopy,
  Navy,
  Cloud,
  Zen,
  Sunset,
  Pink,
  Tangerine,
  Lime,
  Orchid,
  VividVargas,
  Custom,
}

/**
 * The list of names of stack theme colors. Define these since iterating over
 * enum values will loop over 2x the number of entries
 */

export const stackThemeColorNames = Object.values(StackTheme).filter(
  (val) => typeof val === 'string',
) as string[];
/**
 * Given a color index, returns the color name, or "unknown" if the color index
 * is invalid
 */

export const getThemeColorName = (colorIndex: number): ThemeFamilyKey => {
  return stackThemeColorNames[colorIndex] as ThemeFamilyKey;
};

export const getDefaultColorIndex = () => {
  return StackTheme.Ocean;
};

export const getTheme = (
  theme?: StackTheme,
  customColor?: string,
): ThemeDefinition => {
  switch (theme) {
    case StackTheme.Ocean:
      return oceanTheme;
    case StackTheme.Plum:
      return plumTheme;
    case StackTheme.Azalea:
      return azaleaTheme;
    case StackTheme.Crimson:
      return crimsonTheme;
    case StackTheme.Rust:
      return rustTheme;
    case StackTheme.Gold:
      return goldTheme;
    case StackTheme.Canopy:
      return canopyTheme;
    case StackTheme.Cloud:
      return cloudTheme;
    case StackTheme.Navy:
      return navyTheme;
    case StackTheme.Zen:
      return zenTheme;
    case StackTheme.Sunset:
      return sunsetTheme;
    case StackTheme.Pink:
      return pinkTheme;
    case StackTheme.Tangerine:
      return tangerineTheme;
    case StackTheme.Lime:
      return limeTheme;
    case StackTheme.Orchid:
      return orchidTheme;
    case StackTheme.VividVargas:
      return vividVargasTheme;
    case StackTheme.Custom:
      return getCustomThemeByColor(customColor);
    default:
      return oceanTheme;
  }
};

export const getCustomThemeByColor = (hexColor?: string): ThemeDefinition => {
  if (!hexColor || !isValidHexColor(hexColor)) {
    return oceanTheme;
  }
  const hex = hexColor.replace('#', '');
  const hsva = hexToHsva(hex);
  return {
    primary: defineStrictThemeOverrides({
      colors: {
        'Primary Surface': {
          bright: `#${hex}`,
          dark: `#${hex}`,
        },
      },
    }),
    subtle: defineStrictThemeOverrides({
      colors: {
        'Primary Surface': {
          bright: `hsl(${hsva.h}, ${hsva.s}%, 90%)`,
          dark: `hsl(${hsva.h}, ${hsva.s}%, 90%)`,
        },
      },
    }),
  };
};
