// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3711
import type { PAPEventGeneric } from "../base/event";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { DashSurface } from "../enums/dash_surface";

// Event_name: 'edit.quick_link'
// Description: Fires when a quick link is edited
// Owner: dash-exp-find-and-discover
export type Edit_QuickLink = PAPEventGeneric<
  'dash',
  'edit',
  'quick_link',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
  }
>;

export function PAP_Edit_QuickLink(properties?: Edit_QuickLink['properties']): Edit_QuickLink {
  return <Edit_QuickLink>{
    class: 'dash',
    action: 'edit',
    object: 'quick_link',
    properties,
  };
}
