import { lazy, Suspense } from 'react';
import styles from './AssistantFileViewerToolbar.module.css';

import type { AssistantFileViewerToolbarProps } from './types';

const LazyAssistantFileViewerToolbar = lazy(
  async (): Promise<{
    default: React.ComponentType<AssistantFileViewerToolbarProps>;
  }> => {
    const component = await import(
      '@mirage/mosaics/FileViewer/AssistantFileViewerToolbar'
    );
    return { default: component.AssistantFileViewerToolbar };
  },
);

const FallbackAssistantFileViewerToolbar = () => {
  return <div className={styles.AssistantFileViewerToolbar} />;
};

export const AsyncAssistantFileViewerToolbar: React.FC<
  AssistantFileViewerToolbarProps
> = (props) => {
  return (
    <Suspense fallback={<FallbackAssistantFileViewerToolbar />}>
      <LazyAssistantFileViewerToolbar {...props} />
    </Suspense>
  );
};
