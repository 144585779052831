import { atomWithStorage } from 'jotai/utils';

import type { OnboardingChecklistStep } from '../types';

export const OpenTypeaheadAtom = atomWithStorage<boolean>(
  'openTypeaheadAtom',
  false,
);

export const OnboardingChecklistAtom = atomWithStorage<
  OnboardingChecklistStep[]
>('onboardingChecklistItems', []);

export const OnboardingChecklistDirtyAtom = atomWithStorage<boolean>(
  'onboardingChecklistDirtyAtom',
  false,
);
