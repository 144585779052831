import { loadAssistKVDataAPI } from '@mirage/service-compose/service/apiDataLoader';
import { parseAssistActionData } from '@mirage/service-compose/service/apiDataParsers';
import { createDataSync } from '@mirage/service-compose/service/apiDataSync';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { PRESET_ACTIONS } from '@mirage/shared/compose/assist-actions-preset';
import { AccessType } from '@mirage/shared/compose/assist-api';
import omit from 'lodash/omit';

import type { context_engine } from '@dropbox/api-v2-client/types/dropbox_types';
import type {
  DataAPI,
  DataStorage,
} from '@mirage/service-compose/service/apiDataSync';
import type {
  AssistAction,
  CustomAssistAction,
} from '@mirage/shared/compose/assist-actions';
import type { KVStorage } from '@mirage/storage';

export interface KVStorageShape {
  /** List of all custom actions (including shared actions created by other users), persisted to the server */
  actions: {
    rows: Omit<CustomAssistAction, 'type'>[];
    version: 1;
  };
}

const logger = tagged('assistActionsSyncAdapters');

export function createAssistActionsStorageAdapter(
  store: KVStorage<KVStorageShape>,
): DataStorage<CustomAssistAction> {
  return {
    save: async (actions: CustomAssistAction[]) => {
      await store.set('actions', {
        rows: actions,
        version: 1,
      });
      logger.log('saved AssistActions', actions.length);
    },
    load: async () => {
      const savedActions = await store.get('actions');
      if (savedActions?.version === 1) {
        logger.log('loaded AssistActions', savedActions.rows.length);
        return savedActions.rows.map((row) => ({
          ...row,
          type: 'custom',
        }));
      } else {
        // Ignore old data if the version is not >=1
        return [];
      }
    },
  };
}

const ActionsUserDataType = 'actions';

export function createAssistActionsAPIAdapter(): DataAPI<CustomAssistAction> {
  return {
    save: async (action: CustomAssistAction) => {
      const actionData = omit(action, 'isDirty');
      const args: context_engine.AssistSaveUserDataArg = {
        data_item: {
          user_data_type: ActionsUserDataType,
          user_data_key: action.id,
          user_data: JSON.stringify(actionData),
        },
        data_id: action.dataId,
        access_type: getCEAccessType(action.accessConfig),
      };
      const response = await callApiV2(
        'contextEngineAssistApiSaveUserData',
        args,
      );
      const updatedAction = { ...action };
      if (response.data_id) {
        updatedAction.dataId = response.data_id;
        updatedAction.isDirty = false;
      }
      logger.log('saved AssistAction', updatedAction.id);
      return updatedAction;
    },
    delete: async (action: CustomAssistAction) => {
      if (!action.dataId) {
        return;
      }
      const args: context_engine.AssistDeleteUserDataArg = {
        data_id: action.dataId,
      };
      await callApiV2('contextEngineAssistApiDeleteUserData', args);
      logger.log('deleted AssistAction', action.id);
    },
    load: async () => {
      const [actions, teamActions] = await Promise.all([
        loadAssistKVDataAPI<CustomAssistAction>(
          ActionsUserDataType,
          parseAssistActionData,
          AccessType.INDIVIDUAL,
        ),
        loadAssistKVDataAPI<CustomAssistAction>(
          ActionsUserDataType,
          parseAssistActionData,
          AccessType.TEAM,
        ),
      ]);
      return [...teamActions, ...actions];
    },
  };
}

function getCEAccessType(
  accessType: CustomAssistAction['accessConfig'],
): context_engine.AssistUserDataAccessType {
  switch (accessType.type) {
    case 'user':
      return { '.tag': 'individual' };
    case 'team':
      return { '.tag': 'team' };
    default:
      accessType satisfies never;
      throw new Error('Invalid access type');
  }
}

export function createAssistActionsSync(storage: KVStorage<KVStorageShape>) {
  const storageAdapter = createAssistActionsStorageAdapter(storage);
  const apiAdapter = createAssistActionsAPIAdapter();
  const sync = createDataSync<CustomAssistAction, AssistAction>(
    storageAdapter,
    apiAdapter,
    (storedItems) => [...PRESET_ACTIONS, ...storedItems],
  );
  return {
    loadAssistActions: sync.getItems,
    saveAssistAction: sync.saveItem,
    deleteAssistAction: sync.deleteItem,
    syncAssistActions: sync.checkForNewAPIItems,
    assistActionsObservable: sync.itemsObservable,
    tearDownAssistActions: sync.tearDown,
  };
}
