import { UIIcon } from '@dropbox/dig-icons';
import { SearchLine, Twinkle2Line } from '@dropbox/dig-icons/assets';
import {
  getCalendarSubtitle,
  getTimeAgoStringFromTimestamp,
} from '@mirage/search/SearchResults/util/resultUtil';
import { ResultType } from '@mirage/service-typeahead-search/service/types';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { stripStringOfPotentialFilterBindings } from '@mirage/shared/search/search-filters';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Recommendation } from '@mirage/shared/search/recommendation';
type RowDisplayResult = Pick<TypeaheadResult, 'icon' | 'title' | 'subtitle'>;

// IMPROVEMENT (AYC): move this to a util file and replace other callers with this function
const isCalendarEvent = (result: typeahead.ScoredResult) => {
  return (
    result.type === ResultType.SearchResult &&
    result.result.recordType['.tag'] === 'event'
  );
};

const getModifiedTimeAgo = (result: SearchResult | Recommendation) => {
  const { providerUpdateAtMs } = result;
  if (providerUpdateAtMs) {
    return `Modified ${getTimeAgoStringFromTimestamp(providerUpdateAtMs, true)}`;
  }

  return '';
};

export function getDisplayResult(
  result: typeahead.ScoredResult,
): RowDisplayResult {
  switch (result.type) {
    // IMPROVEMENT : we should create a new ResultType to support
    // different types of suggesionts ("Ask Dash" -> /chat vs  "Search Dash" -> serp)
    case ResultType.SuggestedQuery:
      // TEMP: this should be a new result type, but that is still being build out
      // so let's key this off of uuid for not AskSuggestedQuery
      if (result.uuid === 'ask-suggested-query') {
        return {
          icon: (
            <UIIcon
              src={Twinkle2Line}
              size={'medium'}
              color={'var(--dig-color__text__base)'}
            />
          ),
          title: stripStringOfPotentialFilterBindings(result.result.query),
          subtitle: 'Ask Dash',
        };
      }

      return {
        icon: (
          <UIIcon
            src={SearchLine}
            size={'medium'}
            color={'var(--dig-color__text__base)'}
          />
        ),
        title: stripStringOfPotentialFilterBindings(result.result.query),
        subtitle: 'Search Dash', // NOTE: we're defaulting to Find CTA for now, may need to switch on result type to generate correct subtitle
      };
    case ResultType.SearchResult:
      return {
        icon: (
          <FileContentIcon
            content={result.result}
            size="medium"
            hasBackground={false}
          />
        ),
        title: result.result.title,
        subtitle: isCalendarEvent(result)
          ? getCalendarSubtitle(result.result)
          : getModifiedTimeAgo(result.result),
      };
    case ResultType.Recommendation:
      return {
        icon: (
          <FileContentIcon
            content={result.result}
            size="medium"
            hasBackground={false}
          />
        ),
        title: result.result.title,
        subtitle: getModifiedTimeAgo(result.result),
      };
    default:
      return {} as RowDisplayResult;
  }
}
