import { tagged } from '@mirage/service-logging';
import { camelCase } from 'change-case';
import union from 'lodash/union';
import { darwinSymbols } from './darwin';
import { modifierKeys, specialKeys } from './hotkeysKeyMap';
import { getKeyboardModifierSets } from './keyboardModifierSets';

import type { BaseKeys } from './baseKeys';
import type { KeyboardModifierSet } from './keyboardModifierSets';

export type KeyMap = { [key: string]: string[] };

const logger = tagged('shared/hotkeys');

export type HotkeyMap = {
  osModifiers: { [key: string]: string };
  mapEventToMousetrap: { [key: string]: string };
};

export type Handler = (
  event: React.KeyboardEvent | void | string,
) => Promise<void> | void;
/**
 * You can optionally pass the name of a group of hotkeys as a type parameter
 * and TS will ensure you aren't missing any handlers for the assigned keybindings
 */
export type Handlers<HotkeyMapName extends string = string> =
  HotkeyMapName extends keyof BaseKeys
    ? { [key in keyof BaseKeys[HotkeyMapName]]: Handler }
    : { [key: string]: Handler };

function invertKeyMap(map: Record<string, string[]>): Record<string, string> {
  return Object.entries(map).reduce((acc, [key, values]) => {
    return {
      ...acc,
      ...values.reduce(
        (innerAcc, value) => ({
          ...innerAcc,
          [value]: key,
        }),
        {},
      ),
    };
  }, {});
}

export function hotkeys(
  e: React.KeyboardEvent,
  keyMap: KeyMap,
  handlers: Handlers,
  modifierMap?: HotkeyMap,
) {
  const { metaKey, ctrlKey, altKey, shiftKey, key, nativeEvent } = e;
  const activeKeys = invertKeyMap(keyMap);
  const action = activeKeys[e.key];
  const isComposing = Boolean(nativeEvent && nativeEvent?.isComposing);
  // we don't want to fire our key handlers if the user in composing (ie Japanese)
  if (isComposing) {
    return;
  }

  if (!modifierMap) {
    if (action && handlers[action]) {
      handlers[action](e);
      return;
    }
  } else {
    const { osModifiers, mapEventToMousetrap } = modifierMap;
    const formattedKey = key && (mapEventToMousetrap[key] || key.toLowerCase());
    const keySet = [
      metaKey ? osModifiers.metaKey : null,
      ctrlKey ? osModifiers.ctrlKey : null,
      altKey ? 'alt' : null,
      shiftKey ? 'shift' : null,
      formattedKey,
    ]
      .filter(Boolean)
      .join('+');

    // We don't want to call an action if the keydown is _only_ a modifier key
    if (key && (!modifierKeys.includes(key) || specialKeys.includes(key))) {
      const handlerName = activeKeys[keySet];
      if (handlerName && typeof handlers[handlerName] === 'function') {
        e.preventDefault();
        try {
          handlers[handlerName](e);
        } catch (error) {
          logger.warn(`Missed handler for ${handlerName}`);
        }
      }
    }
  }
}

export function isDarwinSymbol(str: string) {
  return Object.values(darwinSymbols).includes(str);
}

export function splitShortcutString(shortcut: string | string[]) {
  if (!shortcut) return [];
  return union(
    ...shortcut
      .toString()
      .split(',')
      .map((section) => section.split('+')),
  );
}

export function fancyShortcut(isDarwin: boolean, shortcutArray: string[]) {
  if (!shortcutArray) return [];
  const keyboardModifierSets = getKeyboardModifierSets(isDarwin);
  const keys = shortcutArray.map((key: string) => {
    // using this for settings shortcut, since we stripped it out above
    if (key === '') {
      return ',';
    }
    const { symbols } = keyboardModifierSets as unknown as KeyboardModifierSet;
    const camelCaseKey = camelCase(key);
    return (symbols[camelCaseKey] as string) || key;
  });
  return keys;
}

export function interleaveKeySetWithChar(
  keySet: string[],
  char: string,
): string[] {
  return keySet.flatMap((key, index) =>
    index < keySet.length - 1 ? [key, char] : [key],
  );
}

export function settingsShortcutToFancyShortcut(
  isDarwin: boolean,
  shortcut: string,
) {
  const shortcutArray = splitShortcutString(shortcut);
  const fancyShortcutArray = fancyShortcut(isDarwin, shortcutArray);
  if (isDarwin) {
    return fancyShortcutArray;
  }
  return interleaveKeySetWithChar(fancyShortcutArray, '+');
}

export function preventBubble(e: React.KeyboardEvent | void | string) {
  if (!e || typeof e === 'string') return;
  e?.preventDefault?.();
  e?.stopPropagation?.();
}
