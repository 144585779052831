import { Menu } from '@dropbox/dig-components/menu';
import {
  Cluster,
  ThemeContainer,
  ThemeProvider,
} from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddCircleLine,
  CheckmarkLine,
  EditLine,
} from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { getTheme, StackTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import styles from './ColorPicker.module.css';
import { isValidHexColor } from './utils';

interface CustomColorPickerProps {
  customHexColor?: string;
  menuKey: string;
  stackNamespaceId?: string;
  colorIndex?: number;
}

export const CustomColorPicker = ({
  customHexColor,
  menuKey,
  stackNamespaceId,
  colorIndex,
}: CustomColorPickerProps) => {
  const isSelected = colorIndex === StackTheme.Custom;
  return (
    <div>
      <Cluster
        paddingLeft="16"
        paddingRight="16"
        className={styles.colorPicker}
      >
        {' '}
        {isValidHexColor(customHexColor) && (
          <ThemeProvider
            overrides={getTheme(StackTheme.Custom, customHexColor).primary}
          >
            <ThemeContainer>
              <Cluster.Item key={customHexColor}>
                <Menu.ActionItem
                  role="menuitemradio"
                  aria-label={i18n.t('custom_stack_color')}
                  className={classnames(styles.colorCircleContainerInput)}
                  aria-checked={isSelected}
                  id={customHexColor}
                  key={menuKey}
                  value={`${menuKey}|${customHexColor}`}
                  preferComposition
                  data-uxa-log={createUxaElementId(`custom_stack_color`, {
                    actionSurfaceComponent: 'stacks',
                    featureLine: 'stacks',
                  })}
                  data-uxa-entity-id={stackNamespaceId}
                >
                  <span className={styles.colorCircle}>
                    <UIIcon
                      className={classnames(
                        styles.colorCircleCheck,
                        isSelected && styles.colorCircleCheckSelectedState,
                      )}
                      src={CheckmarkLine}
                    />
                  </span>
                </Menu.ActionItem>
              </Cluster.Item>
            </ThemeContainer>
          </ThemeProvider>
        )}
        <Cluster.Item key={`addCustomColorButton`}>
          <Menu.ActionItem
            role="menuitemradio"
            aria-label={i18n.t('custom_stack_color')}
            className={classnames(styles.colorCircleContainerInput)}
            id={`addCustomColorButton`}
            key={menuKey}
            value={`${menuKey}|`}
            preferComposition
            data-uxa-log={createUxaElementId(`add_stack_color`, {
              actionSurfaceComponent: 'stacks',
              featureLine: 'stacks',
            })}
            data-uxa-entity-id={stackNamespaceId}
          >
            {customHexColor ? (
              <UIIcon src={EditLine} />
            ) : (
              <UIIcon src={AddCircleLine} />
            )}
          </Menu.ActionItem>
        </Cluster.Item>
      </Cluster>
    </div>
  );
};
