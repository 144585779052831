import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { UIIcon } from '@dropbox/dig-icons';
import { MoveLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import { useSortedStacks } from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import { stackItemGetName } from '../../../service-stacks/service/utils';
import styles from './DragAndDrop.module.css';
import { useDraggablePreview } from './Preview';
import { useDnDContext } from './Provider';
import { typeKey } from './utils';

import type { DraggableData } from './utils';
import type { stacks } from '@dropbox/api-v2-client';
import type { DragEvent } from 'react';

export const DraggableStackLink: React.FC<{
  sectionId: string;
  item: stacks.StackItemShortcut;
  index: number;
  children?: React.ReactNode;
}> = ({ sectionId, item, index, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const url = nonNil(item.url, 'item URL');
  const allStacks = useSortedStacks();

  const { isDisabled, setIsItemDragging } = useDnDContext();

  const { preview, handleGenerateDragPreview } = useDraggablePreview({
    url,
    title: stackItemGetName(item, allStacks),
  });

  const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('text/uri-list', url);
    event.dataTransfer.setData('text/plain', url); // Fallback for some applications
  };

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const data: DraggableData = {
      [typeKey]: true,
      index,
      sectionId,
      id: nonNil(item.api_file_id, 'item ID'),
      type: 'stackitem',
    };

    return combine(
      draggable({
        element,
        getInitialData: () => data,
        canDrag: ({ element, input }) => {
          const commentContent = element.querySelector(
            '[data-stack-comment-content]',
          );

          // If there's a comment on the Stack Item, check whether the drag event originated from inside the
          // comment content. If it did, disallow the drag so that the user can select text in the comment.
          if (commentContent) {
            const { left, right, top, bottom } =
              commentContent.getBoundingClientRect();
            const { clientX, clientY } = input;

            const isInsideCommentContent =
              clientX >= left &&
              clientX <= right &&
              clientY >= top &&
              clientY <= bottom;
            return !isInsideCommentContent && !isDisabled;
          }

          return !isDisabled;
        },
        onDragStart: () => setIsItemDragging(true),
        onDrop: () => setIsItemDragging(false),
        onGenerateDragPreview: handleGenerateDragPreview,
      }),
    );
  }, [
    index,
    isDisabled,
    handleGenerateDragPreview,
    sectionId,
    setIsItemDragging,
    item.api_file_id,
  ]);

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <div
      ref={ref}
      className={styles.dragDropSectionItem}
      onDragStart={handleDragStart}
    >
      {children}
      {preview}
    </div>
  );
};

interface DraggableAccessoryIconProps {
  subtle?: boolean;
}
export const DraggableAccessoryIcon: React.FC<DraggableAccessoryIconProps> = ({
  subtle = false,
}) => {
  const { isDisabled } = useDnDContext();

  if (isDisabled) {
    return null;
  }

  return (
    <div>
      <IconButtonWithTooltip
        tooltipProps={{ title: i18n.t('move') }}
        variant="transparent"
        className={styles.draggableButton}
        disabled={isDisabled}
      >
        <UIIcon
          src={MoveLine}
          style={(subtle && { color: 'var(--dig-color__text__subtle)' }) || {}}
        />
      </IconButtonWithTooltip>
    </div>
  );
};
