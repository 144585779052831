import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ComposeContentUpdaterPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeContentUpdaterPlugin';
import { COMPOSE_TRANSFORMERS } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeMarkdownTransformers';
import { CustomTableNode } from '@mirage/mosaics/ComposeAssistant/components/editor/table/CustomTableNode';
import { TableCellResizerPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/table/TableCellResizerPlugin';
import { TableHoverActionsPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/table/TableHoverActionsPlugin';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import { useMemo } from 'react';
import styles from './LexicalEditor.module.css';
import tableStyles from './table.module.css';

import type { InitialConfigType } from '@lexical/react/LexicalComposer';
import type { ReactElement } from 'react';

const logger = tagged('LexicalEditor');

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
    };
  },
];

interface LexicalEditorProps {
  namespace: string;
  editable?: boolean;
  onError?: (error: Error) => void;
  contentEditable: ReactElement;
  placeholder?: ReactElement | null;
  markdownContent?: string;
  onChangeContent?: (content: string) => void;
  supportsTables?: boolean;
  additionalPlugins?: ReactElement | ReactElement[];
  isClickableLinks?: boolean;
  initialConfig?: Partial<InitialConfigType>;
  supportsTablesByFlag?: boolean;
  transformers?: typeof TRANSFORMERS;
  hasHorizontalScroll?: boolean;
  hasCellMerge?: boolean;
  hasCellBackgroundColor?: boolean;
  onSelectionChange?: () => void;
}

const getLexicalConfig = (
  namespace: string,
  options: {
    onError?: (error: Error) => void;
    editable?: boolean;
  } = {},
): InitialConfigType => ({
  namespace,
  onError: options.onError || logger.error,
  editable: options.editable ?? true,
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    TableRowNode,
    TableCellNode,
    CustomTableNode,
    {
      replace: TableNode,
      with: () => new CustomTableNode(),
      withKlass: CustomTableNode,
    },
  ],
  theme: {
    text: {
      bold: styles.editorTextBold,
      italic: styles.editorTextItalic,
      strikethrough: styles.editorTextStrikethrough,
      underline: styles.editorTextUnderline,
      underlineStrikethrough: styles.editorTextUnderlineStrikethrough,
    },
    table: tableStyles.table,
    tableSelection: tableStyles.tableSelection,
    tableSelected: tableStyles.tableSelected,
    tableCell: tableStyles.tableCell,
    tableCellHeader: tableStyles.tableCellHeader,
    tableCellSelected: tableStyles.tableCellSelected,
    tableCellPrimarySelected: tableStyles.tableCellPrimarySelected,
    tableScrollableWrapper: tableStyles.tableScrollableWrapper,
  },
});

export const LexicalEditor = ({
  namespace,
  editable = true,
  onError,
  contentEditable,
  placeholder = null,
  markdownContent = '',
  onChangeContent = () => {},
  additionalPlugins,
  isClickableLinks = false,
  initialConfig = {},
  transformers = COMPOSE_TRANSFORMERS,
  hasHorizontalScroll = true,
  hasCellMerge = false,
  hasCellBackgroundColor = false,
}: LexicalEditorProps) => {
  const supportsTables =
    useFeatureFlagValue('dash_assist_2025_01_09_assist_compose_tables') ===
    'ON';

  const config = useMemo(
    () => ({
      ...getLexicalConfig(namespace, { onError, editable }),
      ...initialConfig,
    }),
    [namespace, onError, editable, initialConfig],
  );

  return (
    <LexicalComposer initialConfig={config}>
      {additionalPlugins}
      <RichTextPlugin
        contentEditable={contentEditable}
        placeholder={placeholder}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <ListPlugin />
      <LinkPlugin />
      <AutoLinkPlugin matchers={MATCHERS} />
      {isClickableLinks && <ClickableLinkPlugin />}
      <MarkdownShortcutPlugin
        transformers={supportsTables ? transformers : TRANSFORMERS}
      />
      {supportsTables && (
        <>
          <TableHoverActionsPlugin />
          <TableCellResizerPlugin />
        </>
      )}
      {supportsTables && (
        <TablePlugin
          hasCellMerge={hasCellMerge}
          hasCellBackgroundColor={hasCellBackgroundColor}
          hasHorizontalScroll={hasHorizontalScroll}
        />
      )}
      <ComposeContentUpdaterPlugin
        supportsTables={supportsTables}
        markdownContent={markdownContent}
        onChangeContent={onChangeContent}
      />
    </LexicalComposer>
  );
};
