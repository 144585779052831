/**
 * TEMP - This file is used to mock the ask result for the omnibox
 * while we actually build the typeahead-search-service new result types
 *
 */

import { UIIcon } from '@dropbox/dig-icons';
import { Twinkle2Line } from '@dropbox/dig-icons/assets';
import { ResultType } from '@mirage/service-typeahead-search/service/types';

import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';

export const useMockAskResults = (query: string) => {
  const askSuggestedResult = [
    {
      uuid: 'ask-suggested-query',
      // TEMP: this should be a new result type, but that is still being build out
      // so let's key this off of uuid for not AskSuggestedQuery
      type: ResultType.SuggestedQuery,
      scoredResult: {
        type: ResultType.SuggestedQuery,
        uuid: 'ask-suggested-query',
        result: {
          uuid: 'ask-suggested-query',
          query,
        },
        score: 1,
        scoringNotes: {
          pinned: false,
          scoringComponents: {
            titleMatchScore: {
              unweightedScore: 1,
              weightedScore: 1,
              configuredWeight: 1,
              appliedWeight: 1,
            },
            lastClickedScore: {
              unweightedScore: 1,
              weightedScore: 1,
              configuredWeight: 1,
              appliedWeight: 1,
            },
            frequentlyClickedScore: {
              unweightedScore: 1,
              weightedScore: 1,
              configuredWeight: 1,
              appliedWeight: 1,
            },
            lastBrowserViewedScore: {
              unweightedScore: 1,
              weightedScore: 1,
              configuredWeight: 1,
              appliedWeight: 1,
            },
            frequentlyBrowserViewedScore: {
              unweightedScore: 1,
              weightedScore: 1,
              configuredWeight: 1,
              appliedWeight: 1,
            },
            fileTypeScore: {
              unweightedScore: 1,
              weightedScore: 1,
              configuredWeight: 1,
              appliedWeight: 1,
            },
          },
        },
      },
      icon: <UIIcon src={Twinkle2Line} />,
      title: query,
      subtitle: 'Ask Dash',
    },
  ];

  return {
    results: (query.trim() === ''
      ? []
      : askSuggestedResult) as TypeaheadResult[],
  };
};
