import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Change_DashSearchQuery } from '@mirage/analytics/events/types/change_dash_search_query';
import { PAP_Shown_DashNoResults } from '@mirage/analytics/events/types/shown_dash_no_results';
import { tagged } from '@mirage/service-logging';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { useResultPAPLogger } from '@mirage/shared/hooks/useResultPAPLogger';
import {
  extractFilterBindingsFromString,
  isValidEmail,
  keywordToSearchFilterType,
  SearchFilterKeyword,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import { useStableCallback } from '@mirage/shared/util/hooks';
import { useCallback } from 'react';

import type { stacks } from '@dropbox/api-v2-client';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';
import type { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { TypeaheadResultType } from '@mirage/analytics/events/enums/typeahead_result_type';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

export interface TypeaheadSearchPAPLogging {
  onResultsReturned: (
    searchQueryUuid: string,
    query: string,
    emittedResults: typeahead.ScoredResult[],
  ) => void;
  papLogResultOpen: (
    result: typeahead.ScoredResult,
    launchMethod: LaunchMethod,
  ) => void;
  papLogResultCopy: (result: typeahead.ScoredResult) => void;
  papLogResultShown: (result: typeahead.ScoredResult) => void;
  papLogAddToStack: (
    result: typeahead.ScoredResult,
    stack: stacks.Stack | undefined,
  ) => void;
  onResultsReturnedComplete: (
    internalActiveQuery: string,
    accumulatedResults: typeahead.ScoredResult[],
  ) => void;
}

const logger = tagged('service-typeahead-search/useTypeaheadSearchPAPLogging');

export function useTypeaheadSearchPAPLogging(
  typeaheadQuery: string,
  allFilters: SearchFilter[],
  results: typeahead.ScoredResult[],
  featureLine: FeatureLine,
  actionSurfaceComponent?: ActionSurfaceComponent,
  dashActionSurface?: DashActionSurface,
): TypeaheadSearchPAPLogging {
  //
  // Hooks
  //

  const { searchAttemptSessionManager, searchSessionManager, reportPapEvent } =
    useMirageAnalyticsContext();
  const papLogger = useResultPAPLogger();

  //
  // Helpers
  //

  const captureAllActiveFilterBindings = useCallback(
    (result: typeahead.ScoredResult) => {
      // Extracts all filter bindings currently in typeaheadQuery
      const filterBindings = extractFilterBindingsFromString(typeaheadQuery);

      // Captures all filters that we maintain in our state (connectors, types, last updated)
      // These types of filters are statically maintained or quickly accessible in their entirety
      const activeFilters = allFilters.filter((filter) => {
        return !!filterBindings.find((filterBinding) => {
          return (
            filterBinding[1] === filter.id &&
            keywordToSearchFilterType(filterBinding[0]) === filter.type
          );
        });
      });

      // Captures filters that may not be maintained in our state (e.g. person)
      // Person filters are currently fetched async with latency, and sometimes an email may be introduced that is not in a database at all
      // Because of this we rely on the users's ability to input a valid person filter
      for (const filterBinding of filterBindings) {
        if (filterBinding[0] !== SearchFilterKeyword.Person) continue;
        if (!isValidEmail(filterBinding[1])) continue;

        activeFilters.push({
          id: filterBinding[1],
          type: SearchFilterType.Person,
          parameters: {
            email: filterBinding[1],
          },
        });
      }

      // Captures filters that are not currently in the typeahead query
      // in the event of a SearchFilter result type, typeaheadQuery doesn't yet contain the new filter-binding because React state updates are async
      if (result.type === typeahead.ResultType.SearchFilter) {
        activeFilters.push(result.result);
      }

      return activeFilters;
    },
    [typeaheadQuery, allFilters],
  );

  //
  // PAP Logging Functions
  //

  const onResultsReturned = useCallback(
    (
      searchQueryUuid: string,
      query: string,
      emittedResults: typeahead.ScoredResult[],
    ) => {
      const emittedResultUuids = (emittedResults || [])
        .filter((r) => r.type !== typeahead.ResultType.PreviousQuery)
        .map((r) => r.uuid)
        .join(',');
      const emittedRequestIds = (emittedResults || [])
        .filter((r) => r.type === typeahead.ResultType.Recommendation)
        .map((r) => r.result.typeaheadRecommendationRequestId)
        .join(',');

      reportPapEvent(
        PAP_Change_DashSearchQuery({
          resultUuidList: emittedResultUuids,
          typeaheadRecommendationRequestIdList: emittedRequestIds,
          featureLine,
          actionSurfaceComponent: actionSurfaceComponent || 'search_bar',
          dashActionSurface,
          searchQueryUuid,
          queryString: query,
          searchAttemptId:
            searchAttemptSessionManager.getSessionIdOrUndefined(),
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        }),
      );
    },
    [
      reportPapEvent,
      searchAttemptSessionManager,
      searchSessionManager,
      dashActionSurface,
      actionSurfaceComponent,
    ],
  );

  const onResultsReturnedComplete = useCallback(
    (
      internalActiveQuery: string,
      accumulatedResults: typeahead.ScoredResult[],
    ) => {
      // "Measurable results" are the ones that could count towards our shown.dash_no_results event
      const measurableResults = getMeasurableResults(accumulatedResults);

      // Requirements for this event:
      // 1. No actual results were shown (previous queries, query suggestions and etc don't count)
      // 2. User was actively querying for something (don't want to trigger this on null state)
      if (!!measurableResults.length || !internalActiveQuery.length) return;

      reportPapEvent(
        PAP_Shown_DashNoResults({
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashActionSurface: dashActionSurface || 'search_typeahead',
          actionSurfaceComponent,
          featureLine,
        }),
      );
    },
    [
      searchSessionManager,
      reportPapEvent,
      dashActionSurface,
      actionSurfaceComponent,
    ],
  );

  const papLogResultOpen = useStableCallback(
    (result: typeahead.ScoredResult, launchMethod: LaunchMethod) => {
      const activeFilters = captureAllActiveFilterBindings(result);

      switch (result.type) {
        case typeahead.ResultType.Stack:
          papLogger.logStackResultOpen(
            {
              // The core Stack type (`result.result`) doesn't have uuid, only
              // namespace_id. Pass through the locally-generated uuid for
              // reporting purposes.
              uuid: result.uuid,
              stack: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            false,
            launchMethod,
            'search_dropdown',
            'stack',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.StackItem:
          papLogger.logStackItemResultOpen(
            {
              stackItem: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            false,
            launchMethod,
            'search_dropdown',
            'stack_item',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.URLShortcut:
          papLogger.logURLShortcutOpen(
            {
              urlShortcut: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'url_shortcut',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.SearchResult:
          papLogger.logSearchResultOpen({
            defaultProps: {
              searchResult: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            rightRailOpened: false,
            isTypeahead: true,
            launchMethod,
            actionSurfaceComponent: 'search_dropdown',
            typeaheadResultType: 'content',
            typeaheadScore: result.score,
            typeaheadScoringNotes: result.scoringNotes,
            dashSourceType: 'connector',
          });
          break;
        case typeahead.ResultType.Recommendation:
          papLogger.logRecommendationOpen(
            {
              recommendation: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            false,
            launchMethod,
            'search_dropdown',
            'recommendation',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.PreviousQuery:
          papLogger.logPreviousQueryOpen(
            {
              previousQuery: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            false,
            launchMethod,
            'search_dropdown',
            'query_suggestion',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.SuggestedQuery:
          papLogger.logPreviousQueryOpen(
            {
              previousQuery: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            false,
            launchMethod,
            'search_dropdown',
            'serp_cta',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.DesktopFile:
          papLogger.logSearchResultOpen({
            defaultProps: {
              searchResult: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            rightRailOpened: false,
            isTypeahead: true,
            launchMethod,
            actionSurfaceComponent: 'search_dropdown',
            typeaheadResultType: 'local_file',
            typeaheadScore: result.score,
            typeaheadScoringNotes: result.scoringNotes,
            dashSourceType: 'local_file_and_applications',
          });
          break;
        case typeahead.ResultType.DesktopApplication:
          papLogger.logSearchResultOpen({
            defaultProps: {
              searchResult: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            rightRailOpened: false,
            isTypeahead: true,
            launchMethod,
            actionSurfaceComponent: 'search_dropdown',
            typeaheadResultType: 'local_app',
            typeaheadScore: result.score,
            typeaheadScoringNotes: result.scoringNotes,
            dashSourceType: 'local_file_and_applications',
          });
          break;
        case typeahead.ResultType.MathCalculation:
          papLogger.logMathCalculationOpen(
            {
              mathCalculation: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'math_calculation',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.SearchFilter:
          papLogger.logSearchFilterOpen(
            {
              searchFilter: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            false,
            true,
            launchMethod,
            'search_dropdown',
            'search_filter',
            result.score,
            result.scoringNotes,
            result.result.type === SearchFilterType.Connector
              ? 'connector'
              : result.result.type === SearchFilterType.Person
                ? 'other'
                : 'local',
          );
          break;
        default:
          result satisfies never;
          logger.error(
            `[papLogResultOpen] Unrecognized result type, not reigstering open event`,
          );
      }
    },
  );

  const papLogResultCopy = useStableCallback(
    (result: typeahead.ScoredResult) => {
      let typeaheadResultType: TypeaheadResultType | undefined = undefined;

      switch (result.type) {
        case typeahead.ResultType.Stack:
          typeaheadResultType = 'stack';
          break;
        case typeahead.ResultType.StackItem:
          typeaheadResultType = 'stack_item';
          break;
        case typeahead.ResultType.URLShortcut:
          typeaheadResultType = 'url_shortcut';
          break;
        case typeahead.ResultType.SearchResult:
          typeaheadResultType = 'content';
          break;
        case typeahead.ResultType.Recommendation:
          typeaheadResultType = 'recommendation';
          break;
        case typeahead.ResultType.MathCalculation:
          typeaheadResultType = 'math_calculation';
          break;
        default:
          result satisfies
            | typeahead.ScoredPreviousQuery
            | typeahead.ScoredSuggestedQuery
            | typeahead.ScoredDesktopFile
            | typeahead.ScoredDesktopApplication
            | typeahead.ScoredSearchFilter;
      }

      if (!typeaheadResultType) return;

      papLogger.logTypeaheadResultCopy(
        {
          result,
          query: typeaheadQuery,
          filters: [],
          results,
          featureLine,
        },
        'search_dropdown',
        typeaheadResultType,
      );
    },
  );

  // delegate to appropriate logging fn when result is viewed
  const papLogResultShown = useStableCallback(
    (result: typeahead.ScoredResult) => {
      const activeFilters = captureAllActiveFilterBindings(result);

      switch (result.type) {
        case typeahead.ResultType.Stack:
          papLogger.logStackResultShown(
            {
              uuid: result.uuid,
              stack: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'stack',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.StackItem:
          papLogger.logStackItemResultShown(
            {
              stackItem: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'stack_item',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.URLShortcut:
          papLogger.logURLShortcutShown(
            {
              urlShortcut: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'url_shortcut',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.SearchResult:
          papLogger.logSearchResultShown(
            {
              searchResult: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'content',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.Recommendation:
          papLogger.logRecommendationShown(
            {
              recommendation: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'recommendation',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.PreviousQuery:
          papLogger.logPreviousQueryShown(
            {
              previousQuery: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'query_suggestion',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.SuggestedQuery:
          papLogger.logPreviousQueryShown(
            {
              previousQuery: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'serp_cta',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.MathCalculation:
          papLogger.logMathCalculationShown(
            {
              mathCalculation: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'math_calculation',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.SearchFilter:
          papLogger.logSearchFilterShown(
            {
              searchFilter: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'search_filter',
            result.score,
            result.scoringNotes,
            result.result.type === SearchFilterType.Connector
              ? 'connector'
              : result.result.type === SearchFilterType.Person
                ? 'other'
                : 'local',
          );
          break;
        case typeahead.ResultType.DesktopFile:
          papLogger.logDesktopFileShown(
            {
              desktopFile: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'local_file',
            result.score,
            result.scoringNotes,
          );
          break;
        case typeahead.ResultType.DesktopApplication:
          papLogger.logDesktopApplicationShown(
            {
              desktopApplication: result.result,
              query: typeaheadQuery,
              filters: activeFilters,
              results,
              featureLine,
            },
            true,
            'search_dropdown',
            'local_app',
            result.score,
            result.scoringNotes,
          );
          break;
        default:
          result satisfies never;
          logger.error(
            `[papLogResultShown] Unrecognized result type, not registering shown event`,
          );
      }
    },
  );

  const papLogAddToStack = (
    result: typeahead.ScoredResult,
    stack: stacks.Stack | undefined,
  ) => {
    papLogger.logSearchResultAddedToStack_Typeahead(
      {
        result,
        query: typeaheadQuery,
        filters: captureAllActiveFilterBindings(result),
        results,
        featureLine,
      },
      stack,
      'search_dropdown',
      // (we don't track typeaheadResultType/scoringNotes for this event)
    );
  };

  return {
    onResultsReturnedComplete,
    onResultsReturned,
    papLogResultOpen,
    papLogResultCopy,
    papLogResultShown,
    papLogAddToStack,
  };
}

export const getMeasurableResults = (results: typeahead.ScoredResult[]) => {
  return results.filter(
    (result) =>
      result !== undefined &&
      ![
        typeahead.ResultType.SuggestedQuery,
        typeahead.ResultType.PreviousQuery,
      ].includes(result.type),
  );
};
