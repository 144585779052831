import { LabelGroup } from '@dropbox/dig-components/combinations';
import { List } from '@dropbox/dig-components/list';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, CheckmarkLine, DeleteLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashConnectAppPageConnector } from '@mirage/analytics/events/types/select_dash_connect_app_page_connector';
import { useConnectQueryParam } from '@mirage/settings/hooks/useConnectQueryParam';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import styles from './Connector.module.css';

interface Props {
  connected: boolean;
  description?: string | React.ReactNode;
  label: string;
  // Either icon_src or leftAccessory should be provided
  icon_src?: string;
  leftAccessory?: React.ReactNode;
  loading: boolean;
  showAddLine?: boolean;
  onClick: () => void;
  allowDisconnect?: boolean;
  onDisconnectClick?: () => void;
  type: string;
}

export const Connector = ({
  onClick,
  onDisconnectClick = () => {},
  allowDisconnect,
  connected,
  description,
  label,
  icon_src,
  leftAccessory,
  loading,
  showAddLine = true,
  type,
}: Props) => {
  const [autoConnectOpen, setAutoConnectOpen] = useState(false);
  const { connect, removeConnect } = useConnectQueryParam();
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const { reportPapEvent } = useMirageAnalyticsContext();

  const launchAutoConnect = !autoConnectOpen && connect === type;

  const onConnectClick = useCallback(async () => {
    if (!connected) {
      reportPapEvent(
        PAP_Select_DashConnectAppPageConnector({ appName: label }),
      );
      onClick();
    }
  }, [connected, label, onClick, reportPapEvent]);

  const onConnectorClick = useCallback(async () => {
    if (!connected) {
      return onConnectClick();
    }

    if (connected && allowDisconnect) {
      onDisconnectClick();
    }
  }, [connected, onConnectClick, onDisconnectClick, allowDisconnect]);

  useEffect(() => {
    if (launchAutoConnect) {
      onConnectClick();
      setAutoConnectOpen(true);
      removeConnect();
    }
  }, [launchAutoConnect, setAutoConnectOpen, onConnectClick, removeConnect]);

  let tooltipText = i18n.t('add');
  if (connected && allowDisconnect) {
    tooltipText = i18n.t('disconnect');
  } else if (type === 'show-all-apps') {
    tooltipText = '';
  }

  return (
    <DigTooltip title={tooltipText}>
      <List.Item
        className={classnames(
          styles.listItem,
          connected && styles.connected,
          allowDisconnect && styles.allowDisconnect,
        )}
        tabIndex={0}
        role="button"
        verticalAlign="center"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        disabled={connected && !allowDisconnect}
        onClick={(e: React.MouseEvent): void => {
          e.stopPropagation();
          onConnectorClick();
        }}
      >
        <List.Content>
          <LabelGroup
            withLeftAccessory={
              <div className={styles.iconMarginRight}>
                {/* If icon_src exist, we use it, and fallback to leftAccessory */}
                {icon_src ? (
                  <img
                    key={`${type}-icon`}
                    src={icon_src}
                    width="24px"
                    height="24px"
                    alt={label}
                  />
                ) : (
                  leftAccessory
                )}
              </div>
            }
            withSubtext={
              description && (
                <Text color="subtle">
                  {hovered || focused ? i18n.t('disconnect') : description}
                </Text>
              )
            }
            withText={<Text isBold>{label}</Text>}
          />
        </List.Content>
        <List.Accessory className={styles.flexCenter}>
          {loading ? (
            <Spinner size="small" />
          ) : (
            (showAddLine || connected) && (
              <UIIcon
                className={classnames(styles.icon, {
                  [styles.connected]: connected,
                })}
                src={
                  connected
                    ? allowDisconnect && (hovered || focused)
                      ? DeleteLine
                      : CheckmarkLine
                    : AddLine
                }
                aria-label={
                  connected
                    ? allowDisconnect && (hovered || focused)
                      ? i18n.t('disconnect')
                      : i18n.t('connected')
                    : i18n.t('connect')
                }
                color="var(--dig-color__success__base)"
              />
            )
          )}
        </List.Accessory>
      </List.Item>
    </DigTooltip>
  );
};
