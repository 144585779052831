import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import * as rx from 'rxjs';

import type { user_metadata } from '@dropbox/api-v2-client/types';
import type {
  OnboardingStorage,
  Service,
} from '@mirage/service-onboarding/service';
import type { Surface } from '@mirage/shared/util/types';
export type { OnboardingStoragePartial } from '@mirage/service-onboarding/service';

export const service = services.get<Service>(ServiceId.ONBOARDING);

// In the UI, please use useOnboardingValues() instead.
export function getOnboardingValue<K extends keyof OnboardingStorage>(
  key: K,
): Promise<OnboardingStorage[K]> {
  return rx.firstValueFrom(service.get(key)) as Promise<OnboardingStorage[K]>;
}

// In the UI, please use useOnboardingValues() instead.
export function getAllOnboardingValues(): Promise<OnboardingStorage> {
  return rx.firstValueFrom(service.getAll());
}

// In the UI, please use useOnboardingValues() instead.
export function setOnboardingValue<K extends keyof OnboardingStorage>(
  key: K,
  value: OnboardingStorage[K],
): Promise<void> {
  return rx.firstValueFrom(service.set(key, value));
}

// In the UI, please use useOnboardingValues() instead.
export function subscribeToOnboardingValues(
  callback: (values: OnboardingStorage) => void | Promise<void>,
): rx.Subscription {
  const observable = service.onboardingValuesObservable();
  return observable.subscribe(callback);
}

export const clear = service.clear;

const getFirstLoginTimeCombined = getCombineAsyncRequestsFunc(
  (surface?: Surface): Promise<number | undefined> => {
    return rx.firstValueFrom(service.getFirstLoginTime(surface));
  },
);

export async function getFirstLoginTime(
  surface?: Surface,
): Promise<number | undefined> {
  return getFirstLoginTimeCombined(surface);
}

/**
 * We'll need to clear the cached value from the IPC client process (UI)
 * manually on logout.
 */
export function clearFirstLoginTimeCache(): void {
  // TODO: remove cached value now that we're not using IPC
  // TODO: Remove function once web and scout are moved to new logout
}

export function isUsersFirstLogin(): Promise<boolean> {
  return rx.firstValueFrom(service.isUsersFirstLogin());
}

export function isUsersDay0(): Promise<boolean> {
  return rx.firstValueFrom(service.isUsersDay0());
}

export function markDashUserDfIOnboardingCompleteIfNecessary(
  onboardingVariant: string,
): Promise<void> {
  return rx.firstValueFrom(
    service.markDashUserDfIOnboardingCompleteIfNecessary(onboardingVariant),
  );
}

export function markDashUserDfbOnboardingCompleteIfNecessary(
  onboardingVariant: string,
): Promise<void> {
  return rx.firstValueFrom(
    service.markDashUserDfbOnboardingCompleteIfNecessary(onboardingVariant),
  );
}

export function markDashUserFirstUseTimestamp(): Promise<user_metadata.UserMetadataResult> {
  return rx.firstValueFrom(service.markDashUserFirstUseTimestamp());
}

export function markDashWelcomeModalCompleteIfNecessary(
  modalVersion: number,
): Promise<void> {
  return rx.firstValueFrom(
    service.markDashWelcomeModalCompleteIfNecessary(modalVersion),
  );
}

export function getLatestWelcomeModalComplete(): Promise<number> {
  return rx.firstValueFrom(service.getLatestWelcomeModalComplete());
}

export function resetDashUserFirstUseTimestamp(): Promise<user_metadata.UserMetadataResult> {
  return rx.firstValueFrom(service.resetDashUserFirstUseTimestamp());
}

export function markDashUserStackRecipientCompleteIfNecessary(): Promise<void> {
  return rx.firstValueFrom(
    service.markDashUserStackRecipientCompleteIfNecessary(),
  );
}

export const getUserOnboardingState = () => {
  return rx.firstValueFrom(service.getUserOnboardingState());
};

export function fetchTeamLogoUrl(): Promise<string | undefined> {
  return rx.firstValueFrom(service.fetchTeamLogoUrl());
}
