import i18n from '@mirage/translations';

import type { ContentType } from '@mirage/shared/content-type/content-types';

export type CategoryConfig = {
  id: string;
  label: string;
  layout: 'grid' | 'list';
  contentType: ContentType;
  defaultOpen?: boolean;
  limit: number;
};

const messagesCategoryConfig: CategoryConfig = {
  id: 'messages',
  label: 'Messages',
  layout: 'list',
  contentType: {
    key: 'chat',
    label: () => i18n.t('chat_content_type'),
  },
  defaultOpen: true,
  limit: 3,
};
export const imagesCategoryConfig: CategoryConfig = {
  id: 'images',
  label: 'Images',
  layout: 'grid',
  contentType: {
    key: 'image',
    label: () => i18n.t('image_content_type'),
  },
  defaultOpen: true,
  limit: 4,
};

const emailCategoryConfig: CategoryConfig = {
  id: 'emails',
  label: 'Emails',
  layout: 'list',
  contentType: {
    key: 'email',
    label: () => i18n.t('email_content_type'),
  },
  defaultOpen: false,
  limit: 3,
};

export const defaultCategoryConfig: CategoryConfig[] = [
  imagesCategoryConfig,
  messagesCategoryConfig,
  emailCategoryConfig,
];
