import { SimpleTruncate } from '@dropbox/dash-component-library';
import { Badge } from '@dropbox/dig-components/badge';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box, Split } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddCircleLine,
  AiChatLine,
  DropboxLine,
  EditLine,
  PersonMultipleLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { getTimeOfDay } from '@mirage/mosaics/StartPage/Calendar/utils';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useName } from '@mirage/service-auth/useName';
import {
  useWorkflowAgents,
  useWorkflowAgentsEnabled,
} from '@mirage/service-conversation/hooks/useWorkflowAgents';
import { initializeWorkflowAgentConversation } from '@mirage/service-conversation/utils/initializeWorkflowAgentConversation';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { useIsSmallSizeForSidebar } from '@mirage/shared/responsive/mobile';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n, { meticulousRedactedStringClass } from '@mirage/translations';
import { RoutePath } from '@mirage/webapp/routeTypes';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styles from './EmptyChatInstructions.module.css';

import type { WorkflowAgent } from '@mirage/service-conversation/types';

export type ClickableEmptyChatInstructionsOption =
  | 'write'
  | 'chat_with_dash'
  | 'create-ai-tool'
  | 'workflow-agent';
type EmptyChatInstructionsOption =
  | 'write'
  | 'chat_with_dash'
  | 'create-ai-tool'
  | 'workflow-agent';

interface EmptyChatInstructionsProps {
  chatWithDashEnabled: boolean;
  onClickOption: (option: ClickableEmptyChatInstructionsOption) => void;
}
export const EmptyChatInstructions = memo(
  ({ chatWithDashEnabled, onClickOption }: EmptyChatInstructionsProps) => {
    const { firstName } = useName();
    const isSmallSize = useIsSmallSizeForSidebar();
    const isDesktop = EnvCtx.surface === 'desktop';
    const isWorkflowAgentsEnabled = useWorkflowAgentsEnabled();

    return (
      <div
        className={classNames(styles.EmptyChatInstructions, {
          [styles.Mobile]: isSmallSize,
          [styles.Desktop]: isDesktop,
        })}
      >
        <div
          className={classNames(styles.TitleContainer, {
            [styles.TitleContainerMobile]: isSmallSize,
          })}
        >
          <Box
            borderWidth="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ width: '48px', height: '48px' }}
          >
            <UIIcon
              className={styles.EmptyChatIcon}
              size="large"
              src={DropboxLine}
            />
          </Box>
          <Title
            size="medium"
            weightVariant="emphasized"
            className={styles.Title}
          >
            {i18n.t('assistant_empty_chat_title', {
              time: getTimeOfDay(),
              name: firstName,
            })}
          </Title>
          <Title
            size="medium"
            weightVariant="emphasized"
            className={styles.Subtitle}
          >
            {i18n.t('assistant_empty_chat_subtitle')}
          </Title>
        </div>
        <div
          className={classNames(styles.EmptyChatInstructionsCards, {
            [styles.MobileCards]: isSmallSize,
          })}
        >
          {getCardOptions(isWorkflowAgentsEnabled).map((option) => {
            const isComingSoon =
              !chatWithDashEnabled && option === 'chat_with_dash';
            return (
              <EmptyChatInstructionCard
                key={option}
                option={option}
                isComingSoon={isComingSoon}
                onClickOption={onClickOption}
                isMobileSize={isSmallSize}
                withCardAccessories={
                  isComingSoon && (
                    <Badge size="xsmall" over="base">
                      {i18n.t('coming_soon_label')}
                    </Badge>
                  )
                }
              />
            );
          })}
        </div>
        {isWorkflowAgentsEnabled && (
          <WorkflowAgentsList isSmallSize={isSmallSize} />
        )}
      </div>
    );
  },
);
EmptyChatInstructions.displayName = 'EmptyChatInstructions';

interface EmptyChatInstructionCardProps {
  option: EmptyChatInstructionsOption;
  isComingSoon?: boolean;
  onClickOption: (option: ClickableEmptyChatInstructionsOption) => void;
  isMobileSize: boolean; // New prop
  agent?: WorkflowAgent;
  withCardAccessories?: React.ReactNode;
}
export const EmptyChatInstructionCard = memo(
  ({
    option,
    isComingSoon,
    onClickOption,
    isMobileSize,
    agent,
    withCardAccessories,
  }: EmptyChatInstructionCardProps) => {
    const {
      title,
      description,
      icon,
      color,
      borderVariant: defaultBorderVariant,
    } = getCardRenderInfo(option, agent);
    const handleClick = useCallback(() => {
      if (isComingSoon) {
        // no-op if disabled
        return;
      }
      onClickOption(option);
    }, [isComingSoon, onClickOption, option]);
    const borderVariant = isComingSoon ? 'coming_soon' : defaultBorderVariant;

    // detect whether `icon` is an emoji or an icon component
    const isIconEmoji = typeof icon === 'string';

    return (
      <div
        className={classNames(
          styles.EmptyChatInstructionCard,
          meticulousRedactedStringClass,
          {
            [styles.BorderVariantNormal]: borderVariant === 'normal',
            [styles.BorderVariantDotted]: borderVariant === 'dotted',
            [styles.BorderVariantComingSoon]: borderVariant === 'coming_soon',
            [styles.Clickable]: borderVariant !== 'coming_soon',
            [styles.MobileCard]: isMobileSize,
          },
        )}
        onClick={handleClick}
        onKeyDown={onKeyDownCommitFn(handleClick)}
        tabIndex={0}
        role="button"
      >
        {!!withCardAccessories && (
          <div className={styles.CardAccessories}>{withCardAccessories}</div>
        )}
        {isIconEmoji ? (
          <Text
            size="xlarge"
            className={classNames({
              [styles.MobileCardIcon]: isMobileSize,
            })}
            tagName="div"
          >
            {icon}
          </Text>
        ) : (
          <UIIcon
            className={classNames({
              [styles.MobileCardIcon]: isMobileSize,
            })}
            src={icon}
            size="large"
            color={color}
          />
        )}
        <Title
          size="small"
          weightVariant="emphasized"
          className={styles.CardTitle}
          tagName="div"
        >
          <SimpleTruncate lines={2}>{title}</SimpleTruncate>
        </Title>
        {option === 'workflow-agent' && (
          <Text size="small" className={styles.CardDescription}>
            {description}
          </Text>
        )}
      </div>
    );
  },
);
EmptyChatInstructionCard.displayName = 'EmptyChatInstructionCard';

function WorkflowAgentsList({ isSmallSize }: { isSmallSize: boolean }) {
  const navigate = useNavigate();
  const currentAccount = useDropboxAccount();

  const { agents } = useWorkflowAgents();

  if (agents.length === 0) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(styles.TitleContainer, {
          [styles.TitleContainerMobile]: isSmallSize,
        })}
      >
        <Title
          size="medium"
          weightVariant="emphasized"
          className={styles.Title}
        >
          AI Workflow Agents
        </Title>
      </div>
      <div
        className={classNames(styles.EmptyChatInstructionsCards, {
          [styles.MobileCards]: isSmallSize,
        })}
      >
        {agents.map((agent) => (
          <EmptyChatInstructionCard
            key={agent.id}
            option="workflow-agent"
            isComingSoon={false}
            onClickOption={async () => {
              const { url, routeState } =
                await initializeWorkflowAgentConversation(agent.id);
              navigate(url, { state: routeState });
            }}
            withCardAccessories={
              <Split gap="Micro Small" alignY="center">
                {agent && agent.accessType === 'company' && (
                  <Split.Item paddingTop="2">
                    <Text>
                      <UIIcon src={PersonMultipleLine} size="small" />
                    </Text>
                  </Split.Item>
                )}
                {agent && agent.createdBy.email === currentAccount?.email && (
                  <Split.Item>
                    <IconButtonWithTooltip
                      shape="circular"
                      variant="outline"
                      size="small"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        navigate(
                          generatePath(RoutePath.WORKFLOW_AGENT, {
                            agentId: agent.id,
                          }),
                        );
                      }}
                      tooltipProps={{
                        title: i18n.t('edit'),
                      }}
                    >
                      <UIIcon src={EditLine} size="medium" />
                    </IconButtonWithTooltip>
                  </Split.Item>
                )}
              </Split>
            }
            isMobileSize={isSmallSize}
            agent={agent}
          />
        ))}
      </div>
    </>
  );
}

function getCardOptions(
  isWorkflowAgentsEnabled: boolean,
): EmptyChatInstructionsOption[] {
  const options: EmptyChatInstructionsOption[] = ['write', 'chat_with_dash'];
  if (isWorkflowAgentsEnabled) {
    options.push('create-ai-tool');
  }
  return options;
}

function getCardRenderInfo(
  option: EmptyChatInstructionsOption,
  agent?: WorkflowAgent,
) {
  switch (option) {
    case 'chat_with_dash':
      return {
        title: i18n.t('assistant_card_title_chat_with_dash'),
        icon: AiChatLine,
        color: 'var(--dig-color__accent__cyan)',
        borderVariant: 'normal',
      };
    case 'write':
      return {
        title: i18n.t('assistant_card_title_write'),
        icon: EditLine,
        color: 'var(--dig-color__accent__red)',
        borderVariant: 'normal',
      };
    case 'create-ai-tool':
      return {
        title: i18n.t('assistant_card_title_create_ai_tool'),
        description: i18n.t('assistant_card_description_create_ai_tool'),
        icon: AddCircleLine,
        color: 'var(--dig-color__accent__magenta)',
        borderVariant: 'dotted',
      };
    case 'workflow-agent': {
      if (!agent) {
        throw new Error('Workflow agent is required');
      }
      const { title, description, icon } =
        getWorkflowAgentCardRenderInfo(agent);
      return {
        title,
        description,
        icon,
        color: 'var(--dig-color__accent__green)',
        borderVariant: 'normal',
      };
    }
    default:
      option satisfies never;
      throw new Error(`Unknown option: ${option}`);
  }
}

function getWorkflowAgentCardRenderInfo(agent: WorkflowAgent) {
  return {
    title: agent.name,
    description: agent.description,
    icon: agent.icon,
  };
}
