import { Facepile } from '@dropbox/dig-components/avatar';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { InfoSmallLine } from '@dropbox/dig-icons/assets';
import { getInitials } from '@mirage/shared/account';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { type FC, useCallback, useState } from 'react';
import styles from './InviteEntireTeam.module.css';
import { TeamMembersCaptionDropdown } from './TeamMembersCaptionDropdown';

import type { TeamMember } from './types';
import type { DigTooltipProps } from '@mirage/shared/util/DigTooltip';

const InsufficientLicensesTooltip = (props: DigTooltipProps) => (
  <DigTooltip placement="bottom" openDelay={0} isPortaled {...props} />
);

export type InviteEntireTeamProps = {
  teamMembers: Readonly<TeamMember[]>;
  onCheckboxToggle?: (checked: boolean) => void;
  disabled?: boolean;
  insufficientLicensesTooltipTitle?: string;
};

const MAX_AVATARS = 2;

export const InviteEntireTeam: FC<InviteEntireTeamProps> = ({
  teamMembers,
  onCheckboxToggle,
  disabled = false,
  insufficientLicensesTooltipTitle,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const remainderCount = teamMembers.length - MAX_AVATARS;
  //   Controls style for disabled form
  const backgroundColor = isChecked ? 'Primary Surface' : 'transparent';
  const formOpacity = disabled ? 0.6 : 1;
  const formCursor = disabled ? 'not-allowed' : 'pointer';

  const toggleCheckbox = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      onCheckboxToggle?.(checked);
    },
    [onCheckboxToggle],
  );

  const handleCheckboxChange = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event?.target.checked ?? !isChecked;
      toggleCheckbox(checked);
    },
    [isChecked, toggleCheckbox],
  );

  return (
    <Box>
      <Box
        borderStyle="Solid"
        borderRadius="Large"
        borderColor="Border Subtle"
        borderWidth="1"
        paddingX="24"
        paddingY="16"
        marginBottom="8"
        lineHeight="Label Medium"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        style={{
          gap: '16px',
          opacity: formOpacity,
          cursor: formCursor,
        }}
        backgroundColor={backgroundColor}
      >
        <InsufficientLicensesTooltip title={insufficientLicensesTooltipTitle}>
          {/* Box wrapper is necessary because DigTooltip can't anchor to checkbox when checkbox is disabled */}
          <Box>
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              disabled={disabled}
            />
          </Box>
        </InsufficientLicensesTooltip>
        <Box paddingLeft="2">
          <Facepile>
            {teamMembers.slice(0, MAX_AVATARS).map((member) => (
              <Facepile.Item
                key={member.email}
                src={member.photo}
                alt={member.name}
              >
                {getInitials(member.name || member.email || '')}
              </Facepile.Item>
            ))}
            {remainderCount > 0 && (
              <Facepile.Item
                alt={i18n.t('remainder_facepile', {
                  count: remainderCount,
                })}
                colorPalette={{
                  background: 'var(--dig-color__opacity__surface)',
                  foreground: 'var(--dig-color__text__base)',
                }}
              >
                {`${remainderCount}`}
              </Facepile.Item>
            )}
          </Facepile>
        </Box>

        <Text variant="label" size="medium" tagName="p">
          {i18n.t('invite_your_whole_team')}
        </Text>
        <DigTooltip
          title={i18n.t('invite_your_whole_team_tooltip')}
          placement="bottom"
          openDelay={0}
          isPortaled
        >
          <Box className={styles.inviteEntireTeamTooltip}>
            <UIIcon src={InfoSmallLine} />
          </Box>
        </DigTooltip>
      </Box>
      <TeamMembersCaptionDropdown
        teamMembers={teamMembers}
        maxAvatars={MAX_AVATARS}
        remainderCount={remainderCount}
      />
    </Box>
  );
};

InviteEntireTeam.displayName = 'InviteEntireTeam';
