import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_StacksPage } from '@mirage/analytics/events/types/shown_stacks_page';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { dispatchElementClicked } from '@mirage/analytics/uxa/dispatchElementClicked';
import { StacksLoadingContainer } from '@mirage/mosaics/StacksLoading/StacksLoadingContainer';
import {
  stackDerivePAPProps,
  stackGetShareId,
} from '@mirage/service-stacks/service/utils';
import { useDashTitle } from '@mirage/shared/hooks/DashTitle';
import { AllStacks } from '@mirage/stacks/AllStacksGrid/AllStacks';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import type { stacks } from '@dropbox/api-v2-client/types';

export const StacksPage = () => {
  useDashTitle(i18n.t('stacks'));

  const { reportPapEvent } = useMirageAnalyticsContext();
  const hasSentViewEvent = useRef(false);

  useEffect(() => {
    if (hasSentViewEvent.current) {
      return;
    }

    reportPapEvent(
      PAP_Shown_StacksPage({
        actionSurfaceComponent: 'sidebar',
        featureLine: 'stacks',
      }),
    );
    hasSentViewEvent.current = true;
  }, [reportPapEvent]);

  return (
    <StacksLoadingContainer>
      <AllStacksWithAnalytics />
    </StacksLoadingContainer>
  );
};

const AllStacksWithAnalytics = () => {
  const navigate = useNavigate();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const onSelectStack = useCallback(
    (stack: stacks.Stack, numberOfLinks: number) => {
      reportPapEvent(
        PAP_View_DashStack({
          actionSurfaceComponent: 'sidebar',
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
          numberOfLinks: numberOfLinks,
        }),
      );
      dispatchElementClicked(
        createUxaElementId('stack', {
          featureLine: 'stacks',
        }),
      );
      navigate(`/stacks/${stackGetShareId(stack)}`);
    },
    [navigate, reportPapEvent],
  );

  return <AllStacks onSelectStack={onSelectStack} />;
};
