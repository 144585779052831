import { v4 as uuid } from 'uuid';
import { appendManualMessage, getWorkflowAgent } from '..';

import type {
  ExperimentSetting,
  WorkflowAgentRouteState,
  WorkflowStepValuesType,
} from '../types';

export const initializeWorkflowAgentConversation = async (
  agentId: string,
): Promise<{
  conversationId: string;
  url: string;
  routeState: WorkflowAgentRouteState;
}> => {
  const agent = await getWorkflowAgent(agentId);

  // Generate a new conversation ID
  const conversationId = uuid();

  const { workflow: workflowValues } = agent;
  const behavior = workflowValues['2'];
  const base_model = behavior['base-model'] as ExperimentSetting;
  const prompt = behavior.prompt as string;

  const workflow = workflowValues['3'] as {
    [key: string]: WorkflowStepValuesType;
  };
  // map workflow steps into an array
  const workflowSteps = Object.values(workflow).map(
    (step: WorkflowStepValuesType) => step,
  );
  const userPrompt = workflowSteps[0].prompt as string;

  const sources = workflowValues['4'].sources as string[];

  const message = `SYSTEM_PROMPT: ${prompt}`;

  // Add messages to the conversation using the service method directly
  await appendManualMessage({
    conversationId,
    message,
  });

  await appendManualMessage(
    {
      conversationId,
      message: userPrompt,
    },
    'assistantMessage',
  );

  // Open the conversation URL
  const url = `/conversation?conversationId=${conversationId}`;

  const routeState = {
    agentId,
    documentIds: sources,
    model: base_model,
  };

  return {
    conversationId,
    url,
    routeState,
  };
};
