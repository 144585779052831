import { useEffect, useRef, useState } from 'react';

export function useFirstIntersection(
  onShow: () => void,
  options = { threshold: 0.1 },
) {
  const elementRef = useRef(null);
  const [hasBeenShown, setHasBeenShown] = useState(false);

  useEffect(() => {
    if (!elementRef.current || hasBeenShown) return;

    const observer = new IntersectionObserver(([entry], obs) => {
      if (entry.isIntersecting) {
        onShow();
        setHasBeenShown(true);
        obs.unobserve(entry.target);
      }
    }, options);

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [hasBeenShown, onShow, options]);

  return elementRef;
}
