// @generated by bzl gen
// @generated by protoc-gen-es v0.2.1 with parameter "target=ts"
// @generated from file dropbox/proto/context_engine/context_engine_conversations_service.proto (package context_engine, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Empty, Message, proto3 } from '@bufbuild/protobuf';
import {
  ConversationsChatMessage,
  ConversationsUserMessage,
  ProgressUpdate,
  QuerySuggestionItem,
  SuggestedQuery,
} from './conversations_pb';
import { ClientContext } from './models_pb';
import { DevRequestOverrides } from './dev_settings_pb';
import { AnswersWorkflowError, Diagnostics } from './answers_pb';

/**
 * @generated from message context_engine.ConversationsUserRequest
 */
export class ConversationsUserRequest extends Message<ConversationsUserRequest> {
  /**
   * @generated from field: string conversation_id = 1;
   */
  conversationId = '';

  /**
   * @generated from field: repeated context_engine.ConversationsChatMessage history = 2;
   */
  history: ConversationsChatMessage[] = [];

  /**
   * @generated from field: context_engine.ConversationsUserMessage user_message = 3;
   */
  userMessage?: ConversationsUserMessage;

  /**
   * Optional starting document context
   *
   * @generated from field: repeated string document_ids = 4;
   */
  documentIds: string[] = [];

  /**
   * @generated from field: bool debug_enabled = 5;
   */
  debugEnabled = false;

  /**
   * @generated from field: context_engine.ClientContext client_context = 6;
   */
  clientContext?: ClientContext;

  /**
   * to trigger pre-defined agents, e.g summarize
   *
   * @generated from field: repeated string agent_ids = 7;
   */
  agentIds: string[] = [];

  /**
   * @generated from field: string experiment_setting = 8;
   */
  experimentSetting = '';

  /**
   * @generated from field: string source = 9;
   */
  source = '';

  /**
   * @generated from field: context_engine.DevRequestOverrides dev_overrides = 10;
   */
  devOverrides?: DevRequestOverrides;

  /**
   * @generated from field: bool skip_search = 11;
   */
  skipSearch = false;

  constructor(data?: PartialMessage<ConversationsUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConversationsUserRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'conversation_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 2,
      name: 'history',
      kind: 'message',
      T: ConversationsChatMessage,
      repeated: true,
    },
    {
      no: 3,
      name: 'user_message',
      kind: 'message',
      T: ConversationsUserMessage,
    },
    {
      no: 4,
      name: 'document_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 5,
      name: 'debug_enabled',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
    },
    { no: 6, name: 'client_context', kind: 'message', T: ClientContext },
    {
      no: 7,
      name: 'agent_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 8,
      name: 'experiment_setting',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 9, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 10, name: 'dev_overrides', kind: 'message', T: DevRequestOverrides },
    { no: 11, name: 'skip_search', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConversationsUserRequest {
    return new ConversationsUserRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConversationsUserRequest {
    return new ConversationsUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConversationsUserRequest {
    return new ConversationsUserRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ConversationsUserRequest
      | PlainMessage<ConversationsUserRequest>
      | undefined,
    b:
      | ConversationsUserRequest
      | PlainMessage<ConversationsUserRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConversationsUserRequest, a, b);
  }
}

/**
 * @generated from message context_engine.ConversationsAssistantResponse
 */
export class ConversationsAssistantResponse extends Message<ConversationsAssistantResponse> {
  /**
   * @generated from field: repeated context_engine.ConversationsChatMessage messages = 1;
   */
  messages: ConversationsChatMessage[] = [];

  /**
   * @generated from field: context_engine.Diagnostics diagnostics = 2;
   */
  diagnostics?: Diagnostics;

  /**
   * @generated from field: repeated context_engine.ProgressUpdate progress_updates = 3;
   */
  progressUpdates: ProgressUpdate[] = [];

  /**
   * @generated from field: string conversation_id = 4;
   */
  conversationId = '';

  /**
   * @generated from field: repeated context_engine.SuggestedQuery suggested_queries = 5;
   */
  suggestedQueries: SuggestedQuery[] = [];

  /**
   * @generated from field: string request_id = 6;
   */
  requestId = '';

  constructor(data?: PartialMessage<ConversationsAssistantResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConversationsAssistantResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'messages',
      kind: 'message',
      T: ConversationsChatMessage,
      repeated: true,
    },
    { no: 2, name: 'diagnostics', kind: 'message', T: Diagnostics },
    {
      no: 3,
      name: 'progress_updates',
      kind: 'message',
      T: ProgressUpdate,
      repeated: true,
    },
    {
      no: 4,
      name: 'conversation_id',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    {
      no: 5,
      name: 'suggested_queries',
      kind: 'message',
      T: SuggestedQuery,
      repeated: true,
    },
    { no: 6, name: 'request_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConversationsAssistantResponse {
    return new ConversationsAssistantResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConversationsAssistantResponse {
    return new ConversationsAssistantResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConversationsAssistantResponse {
    return new ConversationsAssistantResponse().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ConversationsAssistantResponse
      | PlainMessage<ConversationsAssistantResponse>
      | undefined,
    b:
      | ConversationsAssistantResponse
      | PlainMessage<ConversationsAssistantResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConversationsAssistantResponse, a, b);
  }
}

/**
 * @generated from message context_engine.ConversationsGetResponseError
 */
export class ConversationsGetResponseError extends Message<ConversationsGetResponseError> {
  /**
   * @generated from oneof context_engine.ConversationsGetResponseError.err
   */
  err:
    | {
        /**
         * Indicates that a required argument was not provided.
         *
         * @generated from field: google.protobuf.Empty missing_required_args = 1;
         */
        value: Empty;
        case: 'missingRequiredArgs';
      }
    | {
        /**
         * Indicates that an argument was provided that is not allowed.
         *
         * @generated from field: google.protobuf.Empty invalid_args = 2;
         */
        value: Empty;
        case: 'invalidArgs';
      }
    | {
        /**
         * Indicates that the user is not authorized to access the requested resource.
         *
         * @generated from field: google.protobuf.Empty unauthorized = 3;
         */
        value: Empty;
        case: 'unauthorized';
      }
    | {
        /**
         * Indicates that the request was not processed due to an internal error.
         *
         * @generated from field: google.protobuf.Empty internal_failure = 4;
         */
        value: Empty;
        case: 'internalFailure';
      }
    | {
        /**
         * Indicates that there was an error loading files.
         *
         * @generated from field: google.protobuf.Empty failed_to_get_files = 5;
         */
        value: Empty;
        case: 'failedToGetFiles';
      }
    | {
        /**
         * @generated from field: context_engine.AnswersWorkflowError workflow_err = 7;
         */
        value: AnswersWorkflowError;
        case: 'workflowErr';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ConversationsGetResponseError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.ConversationsGetResponseError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'missing_required_args',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    { no: 2, name: 'invalid_args', kind: 'message', T: Empty, oneof: 'err' },
    { no: 3, name: 'unauthorized', kind: 'message', T: Empty, oneof: 'err' },
    {
      no: 4,
      name: 'internal_failure',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    {
      no: 5,
      name: 'failed_to_get_files',
      kind: 'message',
      T: Empty,
      oneof: 'err',
    },
    {
      no: 7,
      name: 'workflow_err',
      kind: 'message',
      T: AnswersWorkflowError,
      oneof: 'err',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): ConversationsGetResponseError {
    return new ConversationsGetResponseError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): ConversationsGetResponseError {
    return new ConversationsGetResponseError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): ConversationsGetResponseError {
    return new ConversationsGetResponseError().fromJsonString(
      jsonString,
      options,
    );
  }

  static equals(
    a:
      | ConversationsGetResponseError
      | PlainMessage<ConversationsGetResponseError>
      | undefined,
    b:
      | ConversationsGetResponseError
      | PlainMessage<ConversationsGetResponseError>
      | undefined,
  ): boolean {
    return proto3.util.equals(ConversationsGetResponseError, a, b);
  }
}

/**
 * @generated from message context_engine.QuerySuggestionsRequest
 */
export class QuerySuggestionsRequest extends Message<QuerySuggestionsRequest> {
  /**
   * If we want to generate queries from particular set of ids.
   *
   * @generated from field: repeated string document_ids = 1;
   */
  documentIds: string[] = [];

  /**
   * Used for experimentation. V1, V2, V3 etc.
   *
   * @generated from field: string experiment_setting = 2;
   */
  experimentSetting = '';

  /**
   * Include context in the LLM reqeust for more personalized responses.
   *
   * @generated from field: context_engine.ClientContext client_context = 3;
   */
  clientContext?: ClientContext;

  /**
   * Include debug information, so that we understand the whole pipeline
   *
   * @generated from field: bool debug = 4;
   */
  debug = false;

  /**
   * Source of the request for tracking - dash, context_engine_test etc.
   *
   * @generated from field: string source = 5;
   */
  source = '';

  /**
   * Optional - use if we want to generate suggestions based on a user query.
   *
   * @generated from field: string user_query = 6;
   */
  userQuery = '';

  constructor(data?: PartialMessage<QuerySuggestionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.QuerySuggestionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'document_ids',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
      repeated: true,
    },
    {
      no: 2,
      name: 'experiment_setting',
      kind: 'scalar',
      T: 9 /* ScalarType.STRING */,
    },
    { no: 3, name: 'client_context', kind: 'message', T: ClientContext },
    { no: 4, name: 'debug', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'user_query', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): QuerySuggestionsRequest {
    return new QuerySuggestionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsRequest {
    return new QuerySuggestionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsRequest {
    return new QuerySuggestionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | QuerySuggestionsRequest
      | PlainMessage<QuerySuggestionsRequest>
      | undefined,
    b:
      | QuerySuggestionsRequest
      | PlainMessage<QuerySuggestionsRequest>
      | undefined,
  ): boolean {
    return proto3.util.equals(QuerySuggestionsRequest, a, b);
  }
}

/**
 * @generated from message context_engine.QuerySuggestionsError
 */
export class QuerySuggestionsError extends Message<QuerySuggestionsError> {
  /**
   * @generated from oneof context_engine.QuerySuggestionsError.err
   */
  err:
    | {
        /**
         * @generated from field: context_engine.AnswersWorkflowError workflow_err = 2;
         */
        value: AnswersWorkflowError;
        case: 'workflowErr';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<QuerySuggestionsError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.QuerySuggestionsError';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 2,
      name: 'workflow_err',
      kind: 'message',
      T: AnswersWorkflowError,
      oneof: 'err',
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): QuerySuggestionsError {
    return new QuerySuggestionsError().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsError {
    return new QuerySuggestionsError().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsError {
    return new QuerySuggestionsError().fromJsonString(jsonString, options);
  }

  static equals(
    a: QuerySuggestionsError | PlainMessage<QuerySuggestionsError> | undefined,
    b: QuerySuggestionsError | PlainMessage<QuerySuggestionsError> | undefined,
  ): boolean {
    return proto3.util.equals(QuerySuggestionsError, a, b);
  }
}

/**
 * @generated from message context_engine.QuerySuggestionsResponse
 */
export class QuerySuggestionsResponse extends Message<QuerySuggestionsResponse> {
  /**
   * @generated from field: repeated context_engine.QuerySuggestionItem query_suggestions = 1;
   */
  querySuggestions: QuerySuggestionItem[] = [];

  /**
   * @generated from field: string request_id = 2;
   */
  requestId = '';

  /**
   * @generated from field: context_engine.Diagnostics diagnostics = 100;
   */
  diagnostics?: Diagnostics;

  /**
   * @generated from field: context_engine.DevRequestOverrides dev_overrides = 101;
   */
  devOverrides?: DevRequestOverrides;

  constructor(data?: PartialMessage<QuerySuggestionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = 'context_engine.QuerySuggestionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'query_suggestions',
      kind: 'message',
      T: QuerySuggestionItem,
      repeated: true,
    },
    { no: 2, name: 'request_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 100, name: 'diagnostics', kind: 'message', T: Diagnostics },
    { no: 101, name: 'dev_overrides', kind: 'message', T: DevRequestOverrides },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>,
  ): QuerySuggestionsResponse {
    return new QuerySuggestionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsResponse {
    return new QuerySuggestionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>,
  ): QuerySuggestionsResponse {
    return new QuerySuggestionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | QuerySuggestionsResponse
      | PlainMessage<QuerySuggestionsResponse>
      | undefined,
    b:
      | QuerySuggestionsResponse
      | PlainMessage<QuerySuggestionsResponse>
      | undefined,
  ): boolean {
    return proto3.util.equals(QuerySuggestionsResponse, a, b);
  }
}
