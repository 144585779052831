import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import Sentry from '@mirage/shared/sentry';
import { ONBOARDING_SURVEY_QUESTIONS } from './onboarding-surveys/surveys';

import type { OnboardingSurveyAnswer, OnboardingSurveyQuestion } from './types';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { ConsolaInstance } from 'consola';

export type OnboardingSurveyId =
  | 'ADMIN_PROFILING_V1'
  | 'TEAM_MEMBER_PROFILING_V1'
  | 'JOBS_TO_BE_DONE_V1';

export type Service = {
  submitOnboardingSurvey(
    surveyId: OnboardingSurveyId,
    surveyAnswers: OnboardingSurveyAnswer[],
    isTeamSurvey: boolean,
  ): Promise<boolean>;
  getOnboardingSurveyResponse(
    surveyId: OnboardingSurveyId,
    surveyQuestions: string[],
    isTeamSurvey: boolean,
  ): Promise<OnboardingSurveyAnswer[] | undefined>;
  getOnboardingSurveyQuestions(
    surveyId: OnboardingSurveyId,
  ): Promise<OnboardingSurveyQuestion[] | undefined>;
};

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

export default function service(
  { callApiV2 }: DbxApiServiceContract,
  { tagged }: LoggingServiceContract,
) {
  const logger = tagged('service-onboarding-survey');

  async function submitOnboardingSurvey(
    surveyId: OnboardingSurveyId,
    surveyAnswers: OnboardingSurveyAnswer[],
    isTeamSurvey: boolean,
  ): Promise<boolean> {
    try {
      const response = await callApiV2('dashGrowthSubmitOnboardingSurvey', {
        is_team_survey: isTeamSurvey,
        survey_id: surveyId,
        survey_answers: surveyAnswers.map(
          ({ surveyQuestionId, surveyAnswer }) => ({
            survey_question_id: surveyQuestionId,
            survey_answer: surveyAnswer,
          }),
        ),
      });
      return !!response.success;
    } catch (error) {
      logger.error(
        `submitOnboardingSurvey - Failed to submit onboarding survey: "${surveyId}"`,
      );
      Sentry.captureMessage(
        `submitOnboardingSurvey - Failed to submit onboarding survey: "${surveyId}"`,
      );
    }
    return false;
  }

  async function getOnboardingSurveyResponse(
    surveyId: OnboardingSurveyId,
    surveyQuestions: string[],
    isTeamSurvey: boolean,
  ): Promise<OnboardingSurveyAnswer[] | undefined> {
    try {
      const response = await callApiV2(
        'dashGrowthGetOnboardingSurveyResponses',
        {
          survey_id: surveyId,
          survey_questions: surveyQuestions,
          is_team_survey: isTeamSurvey,
        },
      );
      return response?.survey_answers?.map(
        ({ survey_question_id, survey_answer }) => ({
          surveyQuestionId: survey_question_id,
          surveyAnswer: survey_answer,
        }),
      );
    } catch {
      logger.error(
        `getOnboardingSurveyResponse - Failed to fetch onboarding survey: "${surveyId}"`,
      );
      Sentry.captureMessage(
        `getOnboardingSurveyResponse - Failed to fetch onboarding survey: "${surveyId}"`,
      );
    }
    return undefined;
  }

  async function getOnboardingSurveyQuestions(
    surveyId: OnboardingSurveyId,
  ): Promise<OnboardingSurveyQuestion[] | undefined> {
    const surveyQuestions = ONBOARDING_SURVEY_QUESTIONS[surveyId];

    if (surveyQuestions === undefined) {
      Sentry.captureMessage(
        `getOnboardingSurveyResponse - Failed to fetch static onboarding survey questions: "${surveyId}"`,
      );
    }

    return surveyQuestions;
  }

  return services.provide<Service>(
    ServiceId.ONBOARDING_SURVEYS,
    {
      submitOnboardingSurvey,
      getOnboardingSurveyResponse,
      getOnboardingSurveyQuestions,
    },
    [ServiceId.DBX_API],
  );
}
