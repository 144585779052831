import { tagged } from '@mirage/service-logging';
import { AccessType } from '@mirage/shared/compose/assist-api';
import Sentry from '@mirage/shared/sentry';

import type { ItemDataParams } from '@mirage/service-compose/service/apiDataLoader';
import type {
  AssistActionCondition,
  CustomAssistAction,
} from '@mirage/shared/compose/assist-actions';
import type { AssistCustomTemplate } from '@mirage/shared/compose/assist-template';
import type { ComposeSession } from '@mirage/shared/compose/compose-session';
import type { ComposeVoice } from '@mirage/shared/compose/compose-voice';

const logger = tagged('compose-service/apiDataParsers');

export function parseSessionData(
  itemData: ItemDataParams,
): ComposeSession | undefined {
  const sessionData = JSON.parse(itemData.data);
  const session: ComposeSession = {
    ...sessionData,
    dataId: itemData.key,
    rev: itemData.rev,
  };
  if (!verifySessionData(session)) {
    Sentry.withScope((scope) => {
      scope.setTag('sessionId', sessionData.id);
      scope.setTag('dataId', sessionData.dataId);
      scope.setTag(
        'hasMessagesHistory',
        Array.isArray(sessionData.messagesHistory),
      );
      scope.setTag('hasSources', Array.isArray(sessionData.sources));
      scope.setTag('hasArtifacts', Array.isArray(sessionData.artifacts));
      scope.setTag('lastUpdatedType', typeof sessionData.lastUpdated);
      Sentry.captureMessage(
        '[Assistant] Invalid session data validation',
        'error',
        {},
        scope,
      );
    });
    logger.error('Invalid session data:', sessionData);
    return undefined;
  }
  return session;
}

export function parseVoiceData(
  itemData: ItemDataParams,
): ComposeVoice | undefined {
  const voiceData = JSON.parse(itemData.data);
  const voice: ComposeVoice = {
    ...voiceData,
    dataId: itemData.key,
    rev: itemData.rev,
    accessType: voiceData.accessType || AccessType.INDIVIDUAL,
    isOwned: itemData.is_owned || false,
  };
  if (!verifyVoiceData(voice)) {
    Sentry.withScope((scope) => {
      scope.setTag('voiceId', voice.id);
      scope.setTag('dataId', voice.dataId);
      Sentry.captureMessage(
        '[Assistant] Invalid voice data structure',
        'error',
        {},
        scope,
      );
    });
    logger.error('Invalid voice data:', voiceData);
    return undefined;
  }
  return voice;
}

export function parseTemplateData(
  itemData: ItemDataParams,
): AssistCustomTemplate | undefined {
  let templateData;
  try {
    templateData = JSON.parse(itemData.data);
  } catch (e) {
    logger.error('Invalid template data:', itemData.data);
    return undefined;
  }
  const template: AssistCustomTemplate = {
    ...templateData,
    dataId: itemData.key,
    rev: itemData.rev,
    accessType: templateData.accessType || AccessType.INDIVIDUAL,
    isOwned: itemData.is_owned || false,
  };
  if (!verifyTemplateData(template)) {
    Sentry.withScope((scope) => {
      scope.setTag('templateId', template.id);
      scope.setTag('dataId', template.dataId);
      Sentry.captureMessage(
        '[Assistant] Invalid template data structure',
        'error',
        {},
        scope,
      );
    });
    logger.error('Invalid template data:', templateData);
    return undefined;
  }
  return template;
}

export function parseAssistActionData(
  itemData: ItemDataParams,
): CustomAssistAction | undefined {
  const actionData = JSON.parse(itemData.data);
  const action: CustomAssistAction = {
    type: 'custom',
    ...actionData,
    dataId: itemData.key,
    rev: itemData.rev,
  };
  if (!verifyAssistActionData(action)) {
    Sentry.withScope((scope) => {
      scope.setTag('actionId', action.id);
      scope.setTag('dataId', action.dataId);
      Sentry.captureMessage(
        '[Assistant] Invalid action data structure',
        'error',
        {},
        scope,
      );
    });
    logger.error('Invalid action data:', actionData);
    return undefined;
  }
  return action;
}

export function verifyVoiceData(voiceData: ComposeVoice): boolean {
  return (
    typeof voiceData.id === 'string' &&
    typeof voiceData.name === 'string' &&
    typeof voiceData.description === 'string' &&
    typeof voiceData.dataId === 'string' &&
    Array.isArray(voiceData.sources)
  );
}

export function verifyTemplateData(
  templateData: AssistCustomTemplate,
): boolean {
  return (
    typeof templateData.id === 'string' &&
    typeof templateData.name === 'string' &&
    typeof templateData.description === 'string' &&
    typeof templateData.dataId === 'string' &&
    Array.isArray(templateData.sources) &&
    (templateData.messagesHistory === undefined ||
      Array.isArray(templateData.messagesHistory)) &&
    typeof templateData.templateContent === 'string'
  );
}

export function verifySessionData(sessionData: ComposeSession): boolean {
  return (
    typeof sessionData.id === 'string' &&
    typeof sessionData.dataId === 'string' &&
    Array.isArray(sessionData.messagesHistory) &&
    Array.isArray(sessionData.sources) &&
    Array.isArray(sessionData.artifacts) &&
    (sessionData.lastUpdated === undefined ||
      typeof sessionData.lastUpdated === 'number')
  );
}

export function verifyAssistActionData(
  actionData: CustomAssistAction,
): boolean {
  return (
    typeof actionData.id === 'string' &&
    typeof actionData.title === 'string' &&
    typeof actionData.dataId === 'string' &&
    typeof actionData.rev === 'number' &&
    verifyAssistActionCondition(actionData.condition) &&
    (actionData.instruction.type === 'single_message'
      ? typeof actionData.instruction.message === 'string'
      : false) &&
    (actionData.accessConfig.type === 'user' ||
      (actionData.accessConfig.type === 'team' &&
        typeof actionData.accessConfig.isOwned === 'boolean'))
  );
}

function verifyAssistActionCondition(
  condition: AssistActionCondition,
): boolean {
  switch (condition.type) {
    case 'web_url':
      return typeof condition.url_pattern === 'string';
    case 'doc_type':
      return typeof condition.doc_type_prefix === 'string';
    case 'any':
      return condition.conditions.every(verifyAssistActionCondition);
  }
}
