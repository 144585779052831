import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import type { HTMLAttributes } from 'react';

const NavigationFocusContext = createContext({
  excludeNavFocusPath: (_: string) => {},
});

export const useNavigationFocus = () => {
  return useContext(NavigationFocusContext);
};

let prevPathName: string | null = null;
const excludedPaths: Record<string, null> = {};

/**
 * some actions (like search) change the path, but we don't want to interfere with focus
 */
const isExcludedPath = (path: string): boolean => {
  for (const excludedPath in excludedPaths) {
    if (path.includes(excludedPath)) {
      return true;
    }
  }
  return false;
};

/**
 * This component offers a way to refocus the page to inform screen readers
 * of navigation changes when using browser navigation
 */
export const NavigationFocus = ({
  children,
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  const addExcludedPath = (path: string) => {
    excludedPaths[path] = null;
  };

  useEffect(() => {
    // don't refocus if only the query params/hash have changed
    if (location.pathname !== prevPathName) {
      if (prevPathName && !isExcludedPath(location.pathname)) {
        ref.current?.focus();

        window.scrollTo(0, 0);
      }

      prevPathName = location.pathname;
    }
  }, [location, excludedPaths]);

  return (
    <NavigationFocusContext.Provider
      value={{ excludeNavFocusPath: addExcludedPath }}
    >
      <div
        ref={ref}
        tabIndex={-1}
        style={{ outline: 'none' }}
        className={className}
        {...rest}
      >
        {children}
      </div>
    </NavigationFocusContext.Provider>
  );
};
