import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import WithDefaults from '@mirage/storage/with-defaults';
import { Subject } from 'rxjs';

import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

export type IpcProfilingStorage = {
  enableIpcProfiling: boolean;
};

export type Service = ReturnType<typeof provideIpcProfilingService>;

function newStats() {
  return {
    totalCount: 0,
    totalDurationMs: 0,
    statsByMethod: {} as {
      [type: string]: { count: number; durationMs: number };
    },
  };
}

/**
 * Define as a typed object and not a class to serialize over ipc. We might
 * keep on accumulating this data for good, so please keep the cardinality
 * (number of unique keys) small (few hundred is ok, but not millions).
 */
export type Stats = ReturnType<typeof newStats>;

export default function provideIpcProfilingService(
  storage: KVStorage<IpcProfilingStorage>,
) {
  const wrapped = new WithDefaults<IpcProfilingStorage>(storage, {
    enableIpcProfiling: false,
  });

  // Cache this boolean locally to minimize touching the store.
  let localEnableIpcProfiling = false;

  wrapped.get('enableIpcProfiling').then((v) => (localEnableIpcProfiling = v));

  const ipcProfilingEnabled$ = new Subject<boolean>();

  async function getIpcProfilingEnabled(): Promise<boolean> {
    return localEnableIpcProfiling;
  }

  async function setIpcProfilingEnabled(enabled: boolean): Promise<void> {
    localEnableIpcProfiling = enabled;
    ipcProfilingEnabled$.next(enabled);
    await wrapped.set('enableIpcProfiling', enabled);
  }

  function ipcProfilingEnabledObservable(): Observable<boolean> {
    return ipcProfilingEnabled$.asObservable();
  }

  const stats = newStats();

  function addToStats(method: string, durationMs: number) {
    stats.totalCount = (stats.totalCount ?? 0) + 1;
    stats.totalDurationMs = (stats.totalDurationMs ?? 0) + durationMs;

    const old = stats.statsByMethod[method];
    stats.statsByMethod[method] = {
      count: (old?.count ?? 0) + 1,
      durationMs: (old?.durationMs ?? 0) + durationMs,
    };
  }

  const recordIpcProfile: services.TimingLogger = (
    service: ServiceId,
    method: string[],
    args: unknown[],
    _requestId: string,
    isProvider: boolean,
    durationMs: number,
  ) => {
    if (!localEnableIpcProfiling) {
      return;
    }

    // Ignore messages from services.provide().
    if (isProvider) {
      return;
    }

    const methodName = `${service}.${method
      .map((m) => {
        // Make generic method names more specific.
        if (m === 'callApiV2') {
          m = m + '-' + args[0];
        }
        return m;
      })
      .join('.')}`;

    addToStats(methodName, durationMs);

    const perfNow = performance.now().toFixed(1);
    const count = stats.statsByMethod[methodName]?.count;
    const duration = durationMs.toFixed(1);

    // Cannot use `logger` here as that will create more IPC traffic! (infinite loop)
    // eslint-disable-next-line no-console
    console.debug(
      `ipc-profiler ${perfNow}ms: ${methodName}: count=${count} (${duration}ms)`,
    );
  };

  function getAllIpcProfilingStats(): Stats {
    return stats;
  }

  function clearIpcProfilingStats(): void {
    stats.totalCount = 0;
    stats.totalDurationMs = 0;
    stats.statsByMethod = {};
  }

  return services.provide(
    ServiceId.IPC_PROFILING,
    {
      getIpcProfilingEnabled,
      setIpcProfilingEnabled,
      ipcProfilingEnabledObservable,
      recordIpcProfile,
      getAllIpcProfilingStats,
      clearIpcProfilingStats,
    },
    [],
  );
}
