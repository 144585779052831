import { callApiV2 } from '@mirage/service-dbx-api';
import { useEffect, useReducer } from 'react';

import type { TeamMember } from '../types';
import type { dash_growth } from '@dropbox/api-v2-client';

export interface InitialInviteFormData {
  readonly isAdmin: boolean;
  readonly licenseCount: number;
  readonly licensesUsed: number;
  readonly teamMembers: Readonly<TeamMember[]>;
}

export enum INITIAL_INVITE_FORM_DATA_STATUS {
  LOADING = 'loading',
  ERROR = 'error',
  READY = 'ready',
}

export type UseInitialInviteFormDataState =
  | {
      data: null;
      status:
        | INITIAL_INVITE_FORM_DATA_STATUS.LOADING
        | INITIAL_INVITE_FORM_DATA_STATUS.ERROR;
    }
  | {
      data: InitialInviteFormData;
      status: INITIAL_INVITE_FORM_DATA_STATUS.READY;
    };

const initialState: UseInitialInviteFormDataState = {
  data: null,
  status: INITIAL_INVITE_FORM_DATA_STATUS.LOADING,
};

type UseInitialInviteFormDataAction =
  | {
      type: 'ready';
      data: InitialInviteFormData;
    }
  | {
      type: 'error';
    };

const initialInviteFormDataReducer = (
  state: UseInitialInviteFormDataState,
  action: UseInitialInviteFormDataAction,
): UseInitialInviteFormDataState => {
  switch (action.type) {
    case 'ready':
      return {
        data: action.data,
        status: INITIAL_INVITE_FORM_DATA_STATUS.READY,
      };
    case 'error':
      return {
        data: null,
        status: INITIAL_INVITE_FORM_DATA_STATUS.ERROR,
      };
    default:
      return state;
  }
};

export type UseInitialInviteFormDataReturnType = UseInitialInviteFormDataState;

const isValidTeamMemberData = (
  member: dash_growth.TeamMemberData,
): member is dash_growth.TeamMemberData &
  Required<Pick<dash_growth.TeamMemberData, 'email'>> => !!member.email;

export const useInitialInviteFormData =
  (): UseInitialInviteFormDataReturnType => {
    const [state, dispatch] = useReducer(
      initialInviteFormDataReducer,
      initialState,
    );

    useEffect(() => {
      let cancelled = false;

      async function runEffect() {
        try {
          const resp = await callApiV2('dashGrowthGetInviteFormData', {});
          if (cancelled) {
            return;
          }
          const validTeamMemberData = (resp.team_members_data || []).filter(
            isValidTeamMemberData,
          );
          const teamMembers: TeamMember[] = validTeamMemberData.map(
            ({ is_admin = false, email, display_name, photo_url }) => ({
              email,
              name: display_name,
              photo: photo_url,
              isAdmin: is_admin,
            }),
          );
          dispatch({
            type: 'ready',
            data: {
              isAdmin: resp.is_admin ?? false,
              licenseCount: resp.dash_license_count ?? 0,
              licensesUsed: resp.dash_license_used_count ?? 0,
              teamMembers: teamMembers,
            },
          });
        } catch (e) {
          if (cancelled) {
            return;
          }
          dispatch({ type: 'error' });
          return;
        }
      }

      runEffect();

      return () => {
        cancelled = true;
      };
    }, []);
    return state;
  };
