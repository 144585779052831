import { ServiceId } from '@mirage/discovery/id';
import * as service from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

import type { Service } from './service';

const logger = tagged('service-logout/index');

const logoutService = service.getp<Service>(ServiceId.LOGOUT);
const logoutService$ = service.get<Service>(ServiceId.LOGOUT);

export default logoutService;

export type LogoutServiceConsumerContract = {
  logout: () => Promise<void>;
  registerLogoutCallback(
    serviceId: ServiceId,
    callback: () => Promise<void>,
  ): void;
};

export function logout(): Promise<void> {
  return logoutService.logout();
}

export function registerLogoutCallback(
  serviceId: ServiceId,
  callback: () => Promise<void>,
) {
  logoutService$
    .registerService(serviceId)
    .pipe(op.mergeMap(() => rx.from(callback())))
    .subscribe({
      next: () => {
        logoutService.completeService(serviceId);
      },
      error: (error) => {
        logger.error(
          `Error running logout callback for service ${serviceId}`,
          error,
        );
        // Even on error, mark as completed so we don't block logout
        logoutService.completeService(serviceId);
      },
    });
}
