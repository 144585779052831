import { useDualMode } from '@mirage/service-experimentation/useDualMode';
import keymapActionsService from '@mirage/service-keymap-actions';
import { hotkeys } from '@mirage/shared/hotkeys';
import {
  getKeyMaps,
  getMousetrapMap,
  getOsModifierKeys,
} from '@mirage/shared/hotkeys/hotkeysKeyMap';
import { useEffect } from 'react';

import type { Handlers } from '@mirage/shared/hotkeys';
import type { KeyboardEvent } from 'react';

type UseHotkeysProps = {
  platform: string;
  pathname: string;
  onEscape: () => void;
  onCheckForUpdates: () => void;
  onOmniboxFocus: (event: KeyboardEvent | string | void) => void;
  isDesktop: boolean;
};

export const useHotkeys = ({
  platform,
  pathname,
  onCheckForUpdates,
  onEscape,
  onOmniboxFocus,
  isDesktop,
}: UseHotkeysProps) => {
  const { isDualModeEnabled } = useDualMode();

  const handleSelectAll = () => {
    keymapActionsService.selectAll();
  };

  const handleCut = () => {
    keymapActionsService.cut();
  };

  const handleCopy = () => {
    keymapActionsService.copy();
  };

  const handlePaste = () => {
    keymapActionsService.paste();
  };

  useEffect(() => {
    const keyMap = getKeyMaps({
      platform,
      isDesktop,
      isDualModeEnabled,
    }).common;
    const osModifiers = getOsModifierKeys(platform);
    const mapEventToMousetrap = getMousetrapMap(platform);

    const handleKeyDown = async (e: unknown) => {
      const keyMapHandler: Handlers = {
        checkForUpdates: onCheckForUpdates,
        focusOmnibox: onOmniboxFocus,
      };
      const overrideDefaultBehavior =
        await keymapActionsService.overrideDefaultBehavior();
      if (overrideDefaultBehavior) {
        keyMapHandler.select = handleSelectAll;
        keyMapHandler.cut = handleCut;
        keyMapHandler.copy = handleCopy;
        keyMapHandler.paste = handlePaste;
        keyMapHandler.escape = onEscape;
      }

      hotkeys(e as React.KeyboardEvent, keyMap, keyMapHandler, {
        osModifiers,
        mapEventToMousetrap,
      });
    };

    document.body.removeEventListener('keydown', handleKeyDown);
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [platform, pathname, isDesktop]);
};
