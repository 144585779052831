import {
  stopIntercepting,
  tryLoadAndStartRecorder,
} from "@alwaysmeticulous/recorder-loader";
import * as authService from "@mirage/service-auth";
import { tagged } from "@mirage/service-logging";
import {
  getMeticulousState,
  setMeticulousState,
} from "@mirage/shared/meticulous";

import {
  METICULOUS_REDACTION_MIDDLEWARE,
  METICULOUS_TOKEN_REDACTION_MIDDLEWARE,
} from "./middleware";

const _logger = tagged("meticulous");

// This is a public token for Meticulous.
const METICULOUS_PUBLIC_RECORDING_TOKEN =
  "bpciwGxTTN4oudQ0wbT21NLDrPeRTdBZt3lnpV7b";

// Check if the environment is valid
export const isValidEnvironment = () => {
  return (
    process.env.REACT_APP_ENV === "devbox" ||
    process.env.REACT_APP_ENV === "devprod" ||
    process.env.REACT_APP_ENV === "canary"
  );
};

export const middlewarePolicy = (accountEmail: string | undefined) => {
  return accountEmail?.endsWith("@dbx51.com")
    ? METICULOUS_TOKEN_REDACTION_MIDDLEWARE // only redact tokens for dbx51.com accounts
    : METICULOUS_REDACTION_MIDDLEWARE; // redact for other accounts or non-logged-in users
};

// initMeticulous starts recordings for logged-out users and dbx51.com accounts.
// It stops recordings for other accounts if a recording has already started.
export const initMeticulous = async (
  logger: ReturnType<typeof tagged> = _logger,
) => {
  authService.listenForAccount().subscribe(async (account) => {
    const meticulousState = getMeticulousState();

    if (isValidEnvironment()) {
      if (
        meticulousState.isEnabled &&
        meticulousState.accountEmail === account?.email
      ) {
        // Avoid re-enabling if already enabled for this account.
        return;
      }

      try {
        // Start recording and update state.
        const result = await tryLoadAndStartRecorder({
          isProduction: false, // false because index.html only loads meticulous for devbox, devprod, or canary.
          middleware: middlewarePolicy(account?.email),
          recordingToken: METICULOUS_PUBLIC_RECORDING_TOKEN,
          version: "1.2.0", // This needs to match the version of network-recorder.bundle.js set in index.html
        });
        window.Meticulous.record?.recordUserEmail(account?.email);
        setMeticulousState({
          accountEmail: account?.email,
          isEnabled: true,
          stopRecording: result.stopRecording,
        });
      } catch (err) {
        logger.error("Meticulous failed to initialize", err);
      }
    } else {
      // Meticulous should not record for this account. Stop recording and intercepting, and update state.
      // stopIntercepting() drops any network requests/responses stored in memory and deactivates Meticulous.
      try {
        await Promise.all([
          stopIntercepting(),
          meticulousState.stopRecording?.(),
        ]);
        setMeticulousState({
          accountEmail: account?.email,
          isEnabled: false,
          stopRecording: undefined,
        });
      } catch (err) {
        logger.error("Meticulous failed to stop recording", err);
      }
    }
  });
};

declare global {
  interface Window {
    Meticulous: {
      record?: {
        recordUserEmail: (email?: string) => void;
      };
    };
  }
}
