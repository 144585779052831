import { Button } from '@dropbox/dig-components/buttons';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { MultimediaView } from '@mirage/shared/search/search-result';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useMultimediaView } from '../../mosaics/SearchPage/Multimedia/hooks/useMultimediaView';
import { AppFilterDropdown } from './AppFilterDropdown';
import {
  ContentTypeFilterDropdown,
  getContentTypeDropdownVariant,
} from './ContentTypeFilter/ContentTypeFilterDropdown';
import styles from './FilterBar.module.css';
import { LastUpdatedFilterDropdown } from './LastUpdatedFilter';
import { PersonFilterDropdown } from './PersonFilterDropdown';

import type { FilterProps } from './types';
import type { DashFilterType } from '@mirage/analytics/events/enums/dash_filter_type';
import type { FeatureValue } from '@mirage/service-experimentation/features';
import type {
  ContentTypeFilter,
  LastUpdatedFilter,
  PersonFilter,
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

type Props = {
  connectorFilters?: Array<FilterProps>;
  browserHistoryFilters?: Array<FilterProps>;
  activeFilters?: SearchFilter[];
  disabled?: boolean;
  displayConnectMoreApps?: boolean;
  onLastUpdatedFilterSelect: (filter: LastUpdatedFilter | undefined) => void;
  onContentTypeFilterSelect: (filter: ContentTypeFilter | undefined) => void;
  onPersonFilterSelect: (
    filter: PersonFilter | undefined,
    query: string,
  ) => void;
  onConnectorFilterSelect: (connectorName: string) => void;
  activeUpdatedAtFilter: LastUpdatedFilter | undefined;
  activePersonFilter: PersonFilter | undefined;
  activeContentTypeFilters: ContentTypeFilter[];
  clearFilters?: () => void;
  clearConnectorFilters?: () => void;
  clearContentTypeFilters: () => void;
  contentTypeFilterExperimentValue: FeatureValue;
  onOpenFilterDropdown: (type: DashFilterType) => void;
  currentAccount?: PersonObject;
};

export default function FilterBar({
  connectorFilters = [],
  browserHistoryFilters = [],
  activeFilters = [],
  onLastUpdatedFilterSelect,
  onContentTypeFilterSelect,
  onPersonFilterSelect,
  onConnectorFilterSelect,
  activeUpdatedAtFilter,
  activePersonFilter,
  activeContentTypeFilters,
  clearFilters,
  clearConnectorFilters,
  clearContentTypeFilters,
  contentTypeFilterExperimentValue,
  onOpenFilterDropdown,
  currentAccount,
}: Props) {
  const anyFilterActive = activeFilters.length > 0;
  const isMobileSize = useIsMobileSize();
  const showClearAll = anyFilterActive;
  const showMultimediaView = useMultimediaView(activeContentTypeFilters);
  const isContentTypeFilterEnabled =
    contentTypeFilterExperimentValue === 'V1' ||
    contentTypeFilterExperimentValue === 'V2';

  return (
    <div
      className={classnames(styles.filterBarContainer, {
        [styles.isMobile]: isMobileSize,
      })}
    >
      <div
        className={classnames(styles.filterChipContainer, {
          [styles.isMobile]: isMobileSize,
        })}
      >
        {showMultimediaView !== MultimediaView.ON && (
          <AppFilterDropdown
            connectorFilters={connectorFilters}
            browserHistoryFilters={browserHistoryFilters}
            onDeselectAll={() => clearConnectorFilters?.()}
            onOpenDropdown={() => onOpenFilterDropdown('connector')}
            onSelect={onConnectorFilterSelect}
          />
        )}
        {isContentTypeFilterEnabled && (
          <ContentTypeFilterDropdown
            onDeselectAll={() => clearContentTypeFilters()}
            activeFilters={activeContentTypeFilters}
            onSelect={onContentTypeFilterSelect}
            selected={!!activeContentTypeFilters.length}
            variant={getContentTypeDropdownVariant(
              contentTypeFilterExperimentValue,
            )}
            onOpenDropdown={() => onOpenFilterDropdown('file_type')}
          />
        )}
        {showMultimediaView !== MultimediaView.ON && (
          <>
            <LastUpdatedFilterDropdown
              onSelect={onLastUpdatedFilterSelect}
              selected={!!activeUpdatedAtFilter}
              activeUpdatedAtFilter={activeUpdatedAtFilter}
              onOpenDropdown={() => onOpenFilterDropdown('last_updated')}
            />
            <PersonFilterDropdown
              onSelect={onPersonFilterSelect}
              selectedPerson={activePersonFilter}
              onOpenDropdown={() => onOpenFilterDropdown('people')}
              currentAccount={currentAccount}
            />
          </>
        )}
        {showClearAll && (
          <Button
            className={styles.clearAllButton}
            size="small"
            variant="transparent"
            onClick={clearFilters}
            data-uxa-log={createUxaElementId('clear_filters_button', {
              actionSurfaceComponent: 'filter_chip',
              featureLine: 'search',
            })}
          >
            {i18n.t('clear_filters')}
          </Button>
        )}
      </div>
    </div>
  );
}
