import styles from './QuickLinks.module.css';

export interface QuickLinksFaviconProps {
  favicon: string;
  fallbackFavicon?: string;
  title: string;
  fallbackIcon?: React.ReactNode;
}

export const QuickLinksFavicon: React.FC<QuickLinksFaviconProps> = ({
  favicon,
  fallbackFavicon,
  title,
  fallbackIcon,
}) => {
  return (
    <object data={favicon} type="image/png" className={styles.faviconImg}>
      {fallbackFavicon ? (
        <img src={fallbackFavicon} alt={title} className={styles.faviconImg} />
      ) : fallbackIcon ? (
        fallbackIcon
      ) : (
        <img src={favicon} alt={title} className={styles.faviconImg} />
      )}
    </object>
  );
};
