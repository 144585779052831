import { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './MarkdownResponse.module.css';

import type { Literal, Node, Parent, Root } from 'mdast';

// don't allow images in markdown.
const disallowedElements = ['img'];

interface MarkdownResponseProps {
  markdownText: string;
}
export const MarkdownResponse = memo(
  ({ markdownText }: MarkdownResponseProps) => {
    return (
      <div className={styles.MarkdownResponse}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm, LineBreakPlugin]}
          disallowedElements={disallowedElements}
        >
          {markdownText}
        </ReactMarkdown>
      </div>
    );
  },
);
MarkdownResponse.displayName = 'MarkdownResponse';

/**
 * Plugin to convert <br> tags to line breaks.
 * This is needed because we do not render HTML tags in the markdown response.
 * And markdown tables do not normally support line breaks, so AI responses often use
 * <br> tags to indicate line breaks.
 */
function LineBreakPlugin() {
  return (root: Root) => {
    function visit(node: Node, index: number, parent: Parent) {
      if (node.type === 'html' && isLiteral(node) && textIsBr(node.value)) {
        parent.children[index] = { type: 'break' };
      }
      if (isParent(node) && node.children) {
        node.children.forEach((child: Node, i: number) =>
          visit(child, i, node),
        );
      }
    }
    root.children.forEach((child: Node, i: number) => visit(child, i, root));
  };
}

function isParent(node: Node): node is Parent {
  return 'children' in node;
}

function isLiteral(node: Node): node is Literal {
  return 'value' in node;
}

function textIsBr(text: string) {
  return text.trim().match(/^<br\s*\/?>$/);
}
