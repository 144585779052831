import { Text } from '@dropbox/dig-components/typography';
import { useDashUserAttributes } from '@mirage/shared/eligibility/hooks/useDashUserAttributes';
import i18n, { Trans } from '@mirage/translations';
import { useMemo, useState } from 'react';
import { ActionButtonType } from '../self-serve-onboarding/SelfServeOnboarding';
import styles from '../self-serve-onboarding/SelfServeOnboarding.module.css';
import { SelfServeOnboardingStep } from '../self-serve-onboarding/types';

import type { OnboardingStepItem } from '../self-serve-onboarding/SelfServeOnboarding';

const ADMIN_ONBOARDING_STEPS: OnboardingStepItem[] = [
  {
    step: SelfServeOnboardingStep.COMPANY_INFO,
    title: i18n.t('self_serve_company_info_title'),
    description: i18n.t('self_serve_company_info_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.PROFILING,
    title: i18n.t('self_serve_profiling_title'),
    description: i18n.t('self_serve_profiling_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.JTBD,
    title: i18n.t('self_serve_jtbd_title'),
    description: i18n.t('self_serve_jtbd_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.CONNECTORS,
    title: i18n.t('self_serve_connectors_title'),
    description: '',
    primaryActions: [ActionButtonType.Continue],
    skipValidation: true,
  },
  {
    step: SelfServeOnboardingStep.TEAM_INVITE,
    title: i18n.t('self_serve_team_invite_title'),
    primaryActions: [ActionButtonType.Skip, ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.DOWNLOAD_UPSELL,
    title: i18n.t('self_serve_download_upsell_title'),
    description: i18n.t('self_serve_download_upsell_description'),
    primaryActions: [ActionButtonType.Later, ActionButtonType.Download],
    skipValidation: true,
  },
  // Creating a separate step for large team onboarding and avoid showing it in the main flow
  {
    step: SelfServeOnboardingStep.LARGE_TEAM,
    title: '', // This step is hidden, so it does not need a title or description
    description: '',
    primaryActions: [],
    skipValidation: true,
    hidden: true, // This step is hidden because it is outside of the main flow of onboarding
  },
  {
    step: SelfServeOnboardingStep.ERROR,
    title: '', // This step is hidden, so it does not need a title or description
    description: '',
    primaryActions: [],
    skipValidation: true,
    hidden: true, // This step is hidden because it is outside of the main flow of onboarding
  },
];

const TEAM_MEMBER_ONBOARDING_STEPS: OnboardingStepItem[] = [
  {
    step: SelfServeOnboardingStep.PROFILING,
    title: i18n.t('self_serve_profiling_title'),
    description: i18n.t('self_serve_profiling_description'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.CONNECTORS,
    title: i18n.t('self_serve_connectors_title'),
    primaryActions: [ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.TEAM_INVITE,
    title: i18n.t('self_serve_team_invite_title'),
    primaryActions: [ActionButtonType.Skip, ActionButtonType.Continue],
  },
  {
    step: SelfServeOnboardingStep.DOWNLOAD_UPSELL,
    title: i18n.t('self_serve_download_upsell_title'),
    description: i18n.t('self_serve_download_upsell_description'),
    primaryActions: [ActionButtonType.Later, ActionButtonType.Download],
    skipValidation: true,
  },
];

export const useSelfServeOnboardingSteps = (isAdmin: boolean) => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const { userAttributes } = useDashUserAttributes();
  const canSeeDfbInviteMemberForm = !!userAttributes?.canSeeDfbInviteMemberForm;

  const onboardingSteps = useMemo(() => {
    const steps = isAdmin
      ? ADMIN_ONBOARDING_STEPS
      : TEAM_MEMBER_ONBOARDING_STEPS;

    return steps
      .filter(
        (step) =>
          canSeeDfbInviteMemberForm ||
          step.step !== SelfServeOnboardingStep.TEAM_INVITE,
      )
      .map((step) =>
        step.step === SelfServeOnboardingStep.CONNECTORS
          ? {
              ...step,
              description: (
                <Trans
                  i18nKey={'self_serve_connectors_description'}
                  components={{
                    policyLink: (
                      <Text
                        className={styles.privacyLink}
                        onClick={() => setShowPrivacyModal(true)}
                      />
                    ),
                  }}
                />
              ),
            }
          : step,
      );
  }, [isAdmin, canSeeDfbInviteMemberForm]);

  return { onboardingSteps, showPrivacyModal, setShowPrivacyModal };
};
