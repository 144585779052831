import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { useTheme } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, ArrowUpLine } from '@dropbox/dig-icons/assets';
import { PAP_Send_ComposePrompt } from '@mirage/analytics/events/types/send_compose_prompt';
import { publishEvent, SurveyEvent } from '@mirage/service-feedback';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './ConversationInput.module.css';

import type { ChatConversationMessage, ChatSource } from '../../types';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { InputContext } from '@mirage/shared/compose/compose-session';

interface ConversationInputProps {
  variant?: 'default' | 'condensed' | 'minimal';
  sources: ChatSource[];
  inputContext?: InputContext;
  setInputContext?: (inputContext: InputContext | undefined) => void;
  onSubmit: (text: string) => void;
  onFocus?: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
  handleClickAdd?: () => void;
  messages: ChatConversationMessage[];
  showAddSourcesButton?: boolean;
  placeholderOverride?: string;
  disabled?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
}
export const ConversationInput = memo(
  ({
    variant = 'default',
    sources,
    inputContext,
    setInputContext,
    onSubmit,
    onFocus,
    logComposeEvent,
    handleClickAdd,
    messages,
    showAddSourcesButton = true,
    placeholderOverride,
    disabled = false,
    containerRef,
  }: ConversationInputProps) => {
    const textInput = useRef<HTMLTextAreaElement>(null);
    const shouldAutoFocusTextArea = useCallback(() => {
      const lastMessage = messages[messages.length - 1];
      const hasNoInstructionMessages =
        messages.filter((message) => message.type !== 'instruction').length ===
        0;
      const isTypeMessage = lastMessage && lastMessage.type === 'message';
      const isWritePrompt =
        isTypeMessage &&
        lastMessage.text.includes(
          i18n.t('compose_assistant_message_partial_match'),
        );
      const isGenericPrompt =
        isTypeMessage &&
        lastMessage.text.includes(i18n.t('compose_assistant_message_generic'));
      const isDoneMessage =
        isTypeMessage && lastMessage.actionContext?.type === 'done';
      const lastMessageIsGenericOrPartialMatch =
        isWritePrompt || isGenericPrompt || isDoneMessage;
      const shouldAutoFocus =
        hasNoInstructionMessages || lastMessageIsGenericOrPartialMatch;
      return textInput.current && shouldAutoFocus;
    }, [messages]);
    useEffect(() => {
      if (shouldAutoFocusTextArea()) {
        textInput?.current?.focus();
      }
    }, [shouldAutoFocusTextArea, sources]);
    const [promptText, setPromptText] = useState('');
    // Track IME (Japanese input) composition state
    const [isComposing, setIsComposing] = useState(false);
    // workaround to set the cursor position after a new line is added when using the altKey for new line
    const desiredCursorPosition = useRef<number | null>(null);
    useEffect(() => {
      if (desiredCursorPosition.current !== null && textInput.current) {
        textInput.current.selectionStart = desiredCursorPosition.current;
        textInput.current.selectionEnd = desiredCursorPosition.current;
        desiredCursorPosition.current = null;
      }
    }, [promptText]);

    // Handle IME (Japanese input) composition start
    const handleCompositionStart = () => {
      setIsComposing(true);
    };

    // Handle IME (Japanese input) composition end
    const handleCompositionEnd = () => {
      setIsComposing(false);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> =
      useCallback(
        (event) => {
          // Skip Enter key handling during IME (Japanese input) composition
          if (event.key === KeyCodes.enter && isComposing) {
            return;
          }

          if (event.key === KeyCodes.enter) {
            if (event.altKey) {
              const cursorPosition = event.currentTarget.selectionStart;
              const newText =
                promptText.slice(0, cursorPosition) +
                '\n' +
                promptText.slice(cursorPosition);
              event.preventDefault();
              desiredCursorPosition.current = cursorPosition + 1;
              setPromptText(newText);
            } else if (!event.shiftKey && promptText.trim().length > 0) {
              event.preventDefault();
              onSubmit(promptText);
              setPromptText('');
              logComposeEvent(
                PAP_Send_ComposePrompt({
                  queryString: promptText,
                  itemCount: messages.length,
                  queryLength: promptText.length,
                }),
              );
              publishEvent(SurveyEvent.SendAssistMessage, {}, true);
            }
          }
          if (event.key === 'Backspace' || event.key === 'Delete') {
            if (promptText.length === 0) {
              event.preventDefault();
              setInputContext?.(undefined);
            }
          }
        },
        [
          onSubmit,
          promptText,
          setInputContext,
          logComposeEvent,
          messages.length,
          isComposing,
        ],
      );
    const handleTextAreaChange: React.FormEventHandler<HTMLTextAreaElement> =
      useCallback((event) => {
        event.preventDefault();
        setPromptText(event.currentTarget.value);
      }, []);
    const handleSubmit: React.MouseEventHandler = useCallback(
      (event) => {
        event.preventDefault();
        if (promptText.length > 0) {
          onSubmit(promptText);
          setPromptText('');
          publishEvent(SurveyEvent.SendAssistMessage, {}, true);
        }
      },
      [onSubmit, promptText],
    );
    const theme = useTheme();
    return (
      <>
        <div
          className={classnames(styles.ConversationInput, {
            [styles.ConversationInputCondensed]: variant === 'condensed',
          })}
        >
          <div className={styles.ConversationInputContainer} ref={containerRef}>
            <div className={styles.ConversationInputs}>
              {inputContext && (
                <Text className={styles.ConversationInputContext}>
                  <Truncate lines={2}>{inputContext.selectedText}</Truncate>
                </Text>
              )}
              <TextArea
                ref={textInput}
                className={classnames(styles.ConversationInputTextArea, {
                  [styles.ConversationInputTextAreaMinimal]:
                    variant === 'minimal',
                })}
                placeholder={
                  placeholderOverride ||
                  getPlaceholderText(variant, messages.length, sources.length)
                }
                rows={1}
                resizable="auto"
                isTransparent
                value={promptText}
                onChange={handleTextAreaChange}
                onKeyDown={handleKeyDown}
                onFocus={onFocus}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
              />
              {variant !== 'condensed' &&
                showAddSourcesButton &&
                handleClickAdd && (
                  <div className={styles.ConversationInputSourcesActions}>
                    <Button
                      variant="filled"
                      size="medium"
                      withIconStart={<UIIcon src={AddLine} />}
                      onClick={handleClickAdd}
                    >
                      {i18n.t('compose_add_source_action')}
                    </Button>
                  </div>
                )}
            </div>
            <IconButton
              className={classnames(
                theme.getInverseProps().className,
                styles.ConversationInputSendButton,
                {
                  [styles.ConversationInputSendButtonBottomRight]:
                    variant !== 'minimal',
                },
              )}
              variant="filled"
              shape="circular"
              size="medium"
              aria-label={i18n.t('send')}
              onClick={handleSubmit}
              disabled={promptText.trim().length === 0 || disabled}
            >
              <UIIcon src={ArrowUpLine} size="standard" />
            </IconButton>
          </div>
        </div>
        <Text className={styles.ConversationInputDisclaimer}>
          {i18n.t('assist_ai_prompt_disclaimer')}
        </Text>
      </>
    );
  },
);
ConversationInput.displayName = 'ConversationInput';

export function getPlaceholderText(
  variant: ConversationInputProps['variant'],
  messagesCount: number,
  sourcesCount: number,
): string {
  switch (variant) {
    case 'condensed':
      return i18n.t('compose_prompt_dash_placeholder_chat');
    case 'minimal':
      return i18n.t('compose_prompt_dash_placeholder_minimal');
    default:
      if (sourcesCount > 0) {
        return i18n.t('compose_prompt_dash_placeholder_chat_with_sources', {
          count: sourcesCount,
          plural: sourcesCount > 1 ? 's' : '',
        });
      } else if (messagesCount !== 0) {
        return i18n.t('compose_prompt_dash_placeholder_chat');
      } else {
        return i18n.t('compose_prompt_dash_placeholder');
      }
  }
}
