export type TeamMember = {
  email: string;
  name?: string;
  photo?: string;
  isAdmin: boolean;
};

export type Role = 'Member' | 'Co-admin';
export enum EmailValidationError {
  UNKNOWN = 'UNKNOWN',
  VALIDATION_PENDING = 'VALIDATION_PENDING',
  VALIDATION_ERROR = 'VALIDATION_ERROR', // error in validation process itself (e.g. api call failed)
  MALFORMED = 'MALFORMED',
  INELIGIBLE_FOR_DASH_UNSPECIFIED = 'INELIGIBLE_FOR_DASH',
  TEAM_MEMBER_ALREADY_WITH_DASH = 'TEAM_MEMBER_ALREADY_WITH_DASH',
  TEAM_MEMBER_PENDING_INVITE = 'TEAM_MEMBER_PENDING_INVITE',
  ALREADY_ON_ANOTHER_TEAM = 'ALREADY_ON_ANOTHER_TEAM',
  PAID_PERSONAL_OR_TRAIL = 'PAID_PERSONAL_OR_TRAIL',
  NO_INVITE_PERMISSION = 'NO_INVITE_PERMISSION',
}

export enum DashInviteFormSource {
  DASH_HOMEPAGE_MODAL = 'dash_homepage_modal',
  DASH_NEW_TEAM_ONBOARDING = 'dash_new_team_onboarding',
}

export enum InviteFormStatus {
  CAN_SUBMIT = 'can_submit',
  CANNOT_SUBMIT = 'cannot_submit',
  LOADING = 'loading',
  SUBMITTED = 'submitted',
  SUBMITTING = 'submitting',
  OVER_QUOTA = 'over_quota',
}
/**
 * Represent an invitee in the current invite form data
 * This applies to invitee from both the individual e-mail input and the "invite whole team" button
 */
export interface InviteFormContact {
  /**
   * Allow email to be null to represent empty input field
   */
  readonly email: string | null;
  /**
   * Selected role for the invitee. Only applies to individual e-mail input
   */
  readonly newRole: Role | null;
  /**
   * Email validation error message for the contact. Null if validated but no error
   */
  readonly validationError?: EmailValidationError | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface IndividualInviteFormContact extends InviteFormContact {
  /**
   * Unique identifier for each contact in a list for state management.
   *   This is a temporary UI-concept and should not be used in API calls
   */
  readonly contactId: number;
}

export interface InviteWholeTeamFormContact extends InviteFormContact {
  /**
   * Contact from "Invite whole team" is expected to always have an email
   */
  readonly email: string;
  /**
   * Contact from "Invite whole team" does not support role selection
   */
  readonly newRole: null;
}
