import { Metadata } from '@dropbox/dash-component-library';
import { IconButton } from '@dropbox/dig-components/buttons';
import { ProgressBar } from '@dropbox/dig-components/progress_indicators';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { InfoLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { Link } from '@mirage/link/Link';
import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import { getTimeAgoString } from '@mirage/shared/util/time';
import {
  urlToDomainName,
  urlToService,
} from '@mirage/shared/util/urlRulesUtil';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo } from 'react';
import { ASSISTANT_FILE_UPLOAD_CONNECTOR_ID } from '../../utils/isAssistantFileUpload';
import styles from './ComposeSourceRow.module.css';

import type { ChatSource } from '@mirage/mosaics/Chat/types';
import type { SearchResult } from '@mirage/service-dbx-api/service/search';
import type { Props as FileContentIconProps } from '@mirage/shared/icons/FileContentIcon';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { TimeAgoStringConfig } from '@mirage/shared/util/time';
import type { ReactNode } from 'react';

export interface ComposeSourceRowProps {
  source: ChatSource;
  action?: {
    icon: ReactNode;
    variant: 'circle' | 'small';
    onClick: () => void;
    label: string;
  };
  loadState?: 'loading' | 'loaded' | 'error';
  variant?: 'default' | 'small';
}

export function formatFileSize(bytes: number): string {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
}

export function getFriendlyFileType(mimeType: string): string {
  const mimeToName: Record<string, string> = {
    'application/pdf': 'PDF',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'Word Document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'Excel Spreadsheet',
    'text/plain': 'Text Document',
    'text/csv': 'CSV File',
  };
  return mimeToName[mimeType] || mimeType;
}

export const ComposeSourceRow = memo(
  ({
    source,
    action,
    loadState,
    variant = 'default',
  }: ComposeSourceRowProps) => {
    const sourceRowParts = getSourceRowParts(source);
    if (!sourceRowParts) {
      return null;
    }
    const handleActionClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      action?.onClick();
    };

    return (
      <div
        className={classNames(styles.ComposeSourceRow, {
          [styles.ComposeSourceRowSmall]: variant === 'small',
          [styles.ComposeSourceRowDisabled]: action === undefined,
        })}
        onKeyUp={onKeyDownCommitFn(action?.onClick)}
        role="button"
        tabIndex={0}
      >
        <div className={styles.ComposeSourceIcon}>{sourceRowParts.icon}</div>
        <div className={styles.ComposeSourceLabels}>
          {sourceRowParts.title}
          {loadState === 'error' ? (
            <Text
              size="small"
              className={styles.ComposeSourceSubtitle}
              color="error"
            >
              {i18n.t('compose_source_content_load_error')}
            </Text>
          ) : (
            <Text
              size="small"
              className={styles.ComposeSourceSubtitle}
              color="subtle"
            >
              {sourceRowParts.subtitle}
            </Text>
          )}
        </div>
        {source.type === 'local_file_content' && (
          <DigTooltip
            title={i18n.t('compose_source_row_upload_tooltip')}
            placement="top"
            shouldCloseOnClick={false}
            openDelay={0}
          >
            <IconButton
              variant="borderless"
              className={styles.ComposeSourceActionButton}
              size="medium"
              shape="standard"
            >
              <UIIcon src={InfoLine} />
            </IconButton>
          </DigTooltip>
        )}
        {action ? (
          <DigTooltip
            title={action.label}
            placement="top"
            shouldCloseOnClick={false}
            openDelay={0}
          >
            <IconButton
              variant={action.variant === 'circle' ? 'opacity' : 'borderless'}
              className={styles.ComposeSourceActionButton}
              size={action.variant === 'circle' ? 'medium' : 'small'}
              shape={action.variant === 'circle' ? 'circular' : 'standard'}
              onClick={handleActionClick}
            >
              {action.icon}
            </IconButton>
          </DigTooltip>
        ) : (
          <DigTooltip
            title={i18n.t('compose_source_unsupported_error')}
            placement="top"
            openDelay={0}
            shouldCloseOnClick={false}
          >
            <IconButton
              variant="borderless"
              className={styles.ComposeSourceActionButton}
              size="medium"
              shape="circular"
            >
              <UIIcon src={InfoLine} />
            </IconButton>
          </DigTooltip>
        )}
        {loadState === 'loading' && (
          <div className={styles.ComposeSourceLoadProgress}>
            <ProgressBar in isIndeterminate />
          </div>
        )}
      </div>
    );
  },
);
ComposeSourceRow.displayName = 'ComposeSourceRow';

export function composeSourceToMirage(
  source: ChatSource,
): SearchResult | Recommendation | null {
  switch (source.type) {
    case 'dash_search_result':
      return transformDashResultToMirage(source.searchResult);
    case 'recommendation':
      return source.recommendation;
    case 'local_file_content':
      return {
        title: source.fileName,
        url: `file:///${source.fileName}`, // prefix is necessary for the icon type to show up properly
        providerUpdateAtMs: Date.now(),
        connectorInfo: {
          displayName: getFriendlyFileType(source.mimeType || ''),
          connectorType: {
            '.tag': 'other',
          },
          connectorId: '',
          connectorName: ASSISTANT_FILE_UPLOAD_CONNECTOR_ID,
          connectorIconUrl: '',
          platform: null,
        },
        uuid: '',
        recordType: {
          '.tag': 'other',
        },
        lastClickedMs: null,
        clicks: null,
        lastBrowserViewMs: null,
        browserViewsHistory: null,
      };
    case 'transient':
      // we don't show transient sources for now
      return null;
  }
}

export interface SourceRowParts {
  mirageResult: SearchResult | Recommendation;
  icon: ReactNode;
  title: ReactNode;
  titleString: string;
  subtitle: ReactNode;
}
export function getSourceRowParts(
  source: ChatSource,
  config?: {
    time?: TimeAgoStringConfig;
    icon?: Omit<FileContentIconProps, 'content'>;
  },
): SourceRowParts | undefined {
  const mirageResult = composeSourceToMirage(source);
  if (!mirageResult) {
    return undefined;
  }
  const serviceName =
    'displayName' in mirageResult.connectorInfo
      ? mirageResult.connectorInfo.displayName
      : urlToService(mirageResult.url || '') ||
        urlToDomainName(mirageResult.url || '');

  const icon = (
    <FileContentIcon content={mirageResult!} size="large" {...config?.icon} />
  );
  const titleString = mirageResult.title;
  const title =
    source.type === 'local_file_content' ? (
      <Truncate lines={1}>{titleString}</Truncate>
    ) : (
      <Link
        className={styles.ComposeSourceTitle}
        variant="monochromatic"
        href={mirageResult.url || ''}
        target="_blank"
        rel="noreferrer"
        hasNoUnderline
      >
        <Truncate lines={1}>{titleString}</Truncate>
      </Link>
    );

  let updatedMs = mirageResult.providerUpdateAtMs;
  if (!updatedMs && source.type === 'dash_search_result') {
    updatedMs = source.searchResult.updated_at_ms;
  }
  const sizeOrMtimeString =
    source.type === 'local_file_content'
      ? formatFileSize(source.fileSize || 0)
      : updatedMs &&
        i18n.t('modified_ago', {
          timeAgo: getTimeAgoString(updatedMs, config?.time),
        });

  const subtitle =
    serviceName || sizeOrMtimeString ? (
      <Metadata size="small">
        {serviceName && (
          <Metadata.Item>
            <Metadata.Label>{serviceName}</Metadata.Label>
          </Metadata.Item>
        )}
        {sizeOrMtimeString && (
          <Metadata.Item>
            <Metadata.Label>{sizeOrMtimeString}</Metadata.Label>
          </Metadata.Item>
        )}
      </Metadata>
    ) : null;
  return {
    mirageResult,
    icon,
    title,
    titleString,
    subtitle,
  };
}
