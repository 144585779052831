import { Avatar } from '@dropbox/dig-components/avatar';
import { LabelGroup } from '@dropbox/dig-components/combinations';
import { List } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { getInitials } from '@mirage/shared/account';
import i18n from '@mirage/translations';
import React from 'react';

import type { TeamMember } from './types';

export type TeamMembersListProps = React.ComponentProps<typeof Box> & {
  teamMembers: Readonly<TeamMember[]>;
};

export function TeamMembersList({ teamMembers }: TeamMembersListProps) {
  return (
    <Box
      borderRadius="Medium"
      paddingY="8"
      lineHeight="Label Medium"
      overflowX="hidden"
      boxShadow="Raised"
      style={{
        maxHeight: '200px',
        width: '300px',
      }}
    >
      <Box paddingX="16" paddingY="4">
        <Text variant="label" size="small" color="subtle" isBold>
          {i18n.t('team_members', { count: teamMembers.length })}
        </Text>
      </Box>
      <List padding="0px">
        {teamMembers.map((member) => (
          <List.Item key={member.email} paddingY={'8px'}>
            <List.Accessory>
              <Avatar size="small" src={member.photo} alt={member.name}>
                {getInitials(member.name || member.email || '')}
              </Avatar>
            </List.Accessory>
            <List.Content
              style={{
                margin: '0px',
                paddingLeft: '8px',
                overflowWrap: 'anywhere',
              }}
            >
              <LabelGroup
                withText={member.name || member.email || ''}
                withSubtext={member.name ? member.email : ''}
              />
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Box>
  );
}
