import { createSessionManager } from '@mirage/analytics/session/session-manager';
import {
  ANSWERS_SESSION_END_REASONS,
  DASH_SESSION_END_REASONS,
  SEARCH_SESSION_END_REASONS,
} from '@mirage/analytics/session/session-utils';

import type { SessionManager } from '@mirage/analytics/session/session-manager';

const DEBUG_REDUCE_TIMEOUTS = false;

const DASH_SESSION_TIMEOUT = DEBUG_REDUCE_TIMEOUTS ? 10000 : 60000;
const SEARCH_SESSION_TIMEOUT = DEBUG_REDUCE_TIMEOUTS ? 10000 : 60000;
const ANSWERS_SESSION_TIMEOUT = DEBUG_REDUCE_TIMEOUTS ? 5000 : 180000;

/**
 * Create the Session Managers
 */

export const dashSessionManager: SessionManager = createSessionManager(
  'dash-session',
  {
    timeout: DASH_SESSION_TIMEOUT,
    timeoutEndReason: DASH_SESSION_END_REASONS.TIMEOUT,
  },
);

export const dashAppDwellManager: SessionManager = createSessionManager(
  'dash-app-session',
  // No timeout for Dash App Session.
);

export const searchSessionManager: SessionManager = createSessionManager(
  'search-session',
  {
    timeout: SEARCH_SESSION_TIMEOUT,
    timeoutEndReason: SEARCH_SESSION_END_REASONS.TIMEOUT,
  },
);

export const searchAttemptManager: SessionManager = createSessionManager(
  'search-attempt',
  // No timeout for Search Attempt. Search Attempt ends explicitly (i.e. user
  // presses Esc) or when the parent session timeouts.
);

export const explicitAnswersSessionManager: SessionManager =
  createSessionManager('answers-session', {
    timeout: ANSWERS_SESSION_TIMEOUT,
    timeoutEndReason: ANSWERS_SESSION_END_REASONS.TIMEOUT,
  });

export const explicitAnswerDwellManager: SessionManager = createSessionManager(
  'explicit-answer-dwell',
  // No timeout for answer attempt. Answer attempt ends explicitly (i.e. user
  // presses Esc or enters new question)
);

export const implicitAnswerDwellManager: SessionManager = createSessionManager(
  'implicit-answer-dwell',
  // No timeout for answer attempt. Answer attempt ends explicitly (i.e. user
  // presses Esc or enters new question)
);
