import { redactString } from "@alwaysmeticulous/redaction";
import type { janus_proxy } from "@dropbox/api-v2-client";

import { createRedactedApiV2Endpoint } from "../utils";

export const janusProxyProxyRedacted = createRedactedApiV2Endpoint<
  null,
  janus_proxy.ProxyResponse
>({
  name: "janusProxyProxy",
  api: "janus_proxy",
  endpoint: "proxy",
  indexedDbKey: "",
  stringRedactors: {
    body: redactString,
  },
});
