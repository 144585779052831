import { Box } from '@dropbox/dig-foundations';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import i18n, { Trans } from '@mirage/translations';
import { useEffect, useState } from 'react';
import styles from './SearchHeader.module.css';

import type { RefObject } from 'react';

export const SearchInputPlaceholder = ({
  isDarwin = true,
  filtersActive,
  fullWidth = false,
  inputRef,
}: {
  isDarwin?: boolean;
  filtersActive: boolean;
  fullWidth?: boolean;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const [hasBeenUnfocused, setHasBeenUnfocused] = useState(false);

  const isInputActiveDocumentElement =
    inputRef?.current === document.activeElement;

  useEffect(() => {
    if (!isInputActiveDocumentElement) {
      setHasBeenUnfocused(true);
    }
  }, [isInputActiveDocumentElement]);

  const renderUnfocusedPlaceholder = () => {
    switch (EnvCtx.surface) {
      case 'extension':
        if (!hasBeenUnfocused) {
          return (
            <Trans
              i18nKey="search_placeholder_default_with_tab"
              values={{
                tab: isDarwin ? 'TAB' : 'Tab',
              }}
              components={{
                arrowTag: <div className={styles.placeholderKeyCapSymbol} />,
                tabTag: <div className={styles.placeholderKeyCap} />,
              }}
            />
          );
        }
        break;
    }
    return i18n.t('search_prompt');
  };

  if (filtersActive) {
    return null;
  }
  return (
    <Box
      as="span"
      pointerEvents="none"
      whiteSpace="nowrap"
      overflow="hidden"
      position="absolute"
      height="100%"
      width="100%"
      color="Text Subtle"
      fontSize={fullWidth ? 'Title Medium' : 'Text Large'}
      display="flex"
      fontFamily="Text"
      aria-hidden={true}
      className={styles.fakePlaceholder}
    >
      {isInputActiveDocumentElement
        ? i18n.t('search_prompt')
        : renderUnfocusedPlaceholder()}
    </Box>
  );
};
