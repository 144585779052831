import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { DashGlyph, GlyphLogo } from '@dropbox/dig-logos';
import { isMobileAppEnabledForCountry } from '@mirage/mosaics/MobileDownloadModal/util';
import { useAccountIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import { openURL } from '@mirage/service-platform-actions';
import useSettings from '@mirage/service-settings/useSettings';
import {
  ANDROID_MOBILE_APP_STORE_URL,
  IPHONE_MOBILE_APP_STORE_URL,
  MOBILE_APP_DEEP_LINK_URL,
} from '@mirage/shared/urls';
import { getBrowserType } from '@mirage/shared/util/browser';
import { getOperatingSystem } from '@mirage/shared/util/os';
import i18n from '@mirage/translations';
import { useState } from 'react';

const DEEPLINKING_TIMEOUT_MS = 1500;

export function MobileDownloadBanner() {
  // Relying only on settings creates a delay when user dismisses the banner
  const [showBanner, setShowBanner] = useState(true);
  const deviceOS = getOperatingSystem();
  const { account } = useAccountIsDropboxer();
  const { settings, set: setSettings } = useSettings([
    'dismissedMobileAppDownloadBanner',
  ]);

  function onClickGetApp() {
    if (deviceOS === 'ios') {
      // Only attempt to deeplink on iOS. Android infra is not ready yet.
      window.location.href = MOBILE_APP_DEEP_LINK_URL;
      setTimeout(() => {
        openURL(IPHONE_MOBILE_APP_STORE_URL);
      }, DEEPLINKING_TIMEOUT_MS);
    } else if (deviceOS === 'android') {
      // TODO: Add Android deep linking when ready
      openURL(ANDROID_MOBILE_APP_STORE_URL);
    }
    // Do not dismiss banner when user clicks on CTA
  }

  function onClickClose() {
    setSettings('dismissedMobileAppDownloadBanner', true);
    setShowBanner(false);
  }

  const displayBanner =
    showBanner &&
    !settings?.dismissedMobileAppDownloadBanner &&
    account?.country &&
    isMobileAppEnabledForCountry(account.country) &&
    (deviceOS === 'ios' || deviceOS === 'android') &&
    /**
     * We already display a banner for safari browsers on ios. Avoid showing two banners
     * https://developer.apple.com/documentation/webkit/promoting-apps-with-smart-app-banners
     */
    !(deviceOS === 'ios' && getBrowserType() === 'safari');

  if (displayBanner) {
    return (
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        backgroundColor="Background Base"
        borderBottom="Solid"
        borderColor="Border Subtle"
        borderWidth="1"
        paddingLeft="8"
        paddingRight="24"
        paddingTop="12"
        paddingBottom="12"
        style={{ height: '72px' }}
      >
        <IconButton
          variant="transparent"
          style={{ width: '16px', height: '16px', flexShrink: '0' }}
          onClick={onClickClose}
          data-testid="mobile-download-banner-close-button"
        >
          <UIIcon src={CloseLine} />
        </IconButton>
        <Box
          borderStyle="Solid"
          borderColor="Border Subtle"
          borderWidth="1"
          borderRadius="Medium"
          marginRight="12"
          marginLeft="8"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          style={{ width: '48px', height: '48px' }}
        >
          <GlyphLogo src={DashGlyph} size={128} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <Text variant="label" isBold={true}>
            {i18n.t('app_name')}
          </Text>
          <Text variant="label" color="subtle" size="small">
            {i18n.t('get_app_subtitle')}
          </Text>
        </Box>
        <Button
          variant="primary"
          style={{ flexShrink: '0' }}
          onClick={onClickGetApp}
        >
          {i18n.t('get_app_cta')}
        </Button>
      </Box>
    );
  }

  return null;
}
