import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { DocumentActivityObject } from './document/DocumentActivityObject';
import { StackActivityObject } from './stack/StackActivityObject';

import type { dash_feed, users } from '@dropbox/api-v2-client';

export type ActivityObjectProps = {
  activity: dash_feed.FeedItem;
  currentAccount?: users.FullAccount;
  index?: number;
};

export const ActivityObject = ({ activity, index }: ActivityObjectProps) => {
  if (!activity.target) {
    return null;
  }

  const objectDetails = activity.target.details?.object_details;
  switch (objectDetails?.['.tag']) {
    case 'full_stack':
      return (
        <StackActivityObject
          activity={activity}
          index={index}
          stack={objectDetails}
          stackData={objectDetails.stack_data!}
        />
      );
    case 'document':
      return (
        <DocumentActivityObject
          activity={activity}
          index={index}
          document={transformDashResultToMirage(objectDetails)!}
        />
      );
    default:
      return null;
  }
};
