import { FiltersMenu } from './FiltersMenu';

import type { ActivityFeedFilters } from '@mirage/service-feed/types';

type UserActivityFeedFiltersProps = {
  filters: ActivityFeedFilters;
  setFilters: (filters: ActivityFeedFilters) => void;
};

export const UserActivityFeedFilters = ({
  filters,
  setFilters,
}: UserActivityFeedFiltersProps) => {
  return <FiltersMenu filters={filters} setFilters={setFilters} />;
};
