import { Source as ConversationsSource } from '@mirage/conversations/SourceV2/Source';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { useEffect, useRef } from 'react';
import {
  normalizeResultForSummary,
  SummaryQnAEnum,
} from '../SummaryQnaPanel/utils';

import type {
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';

type SourceProps = {
  source: QuestionAndAnswerSource;
  answer: QuestionAndAnswerResponse;
  onClick: (
    answer: QuestionAndAnswerResponse,
    source: QuestionAndAnswerSource,
  ) => void;
  onShown: (
    answer: QuestionAndAnswerResponse,
    source: QuestionAndAnswerSource,
  ) => void;
};

export const Source = ({ source, answer, onClick, onShown }: SourceProps) => {
  const hasFiredShownEvent = useRef(false);
  const isDarkMode = useTheme();

  useEffect(() => {
    if (hasFiredShownEvent.current) return;
    onShown(answer, source);
    hasFiredShownEvent.current = true;

    return () => {
      hasFiredShownEvent.current = false;
    };
  }, [onShown, answer, source]);
  const summarizableResult = normalizeResultForSummary(
    source,
    SummaryQnAEnum.QNA,
    isDarkMode,
  );
  return summarizableResult ? (
    <ConversationsSource
      source={summarizableResult}
      onQASourceClick={() => onClick(answer, source)}
    />
  ) : null;
};
