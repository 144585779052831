// If this list continues to grow, opt for a denylist rather than an allowlist approach
const MOBILE_ENABLED_COUNTRIES = [
  'us', // United States
  'ca', // Canada
  'au', // Australia
  'jp', // Japan
  'nz', // New Zealand
  'sg', // Singapore
  'sa', // Saudi Arabia
  'ae', // United Arab Emirates
  'al', // Albania
  'at', // Austria
  'be', // Belgium
  'ba', // Bosnia and Herzegovina
  'bg', // Bulgaria
  'hr', // Croatia
  'cy', // Cyprus
  'cz', // Czech Republic
  'dk', // Denmark
  'ee', // Estonia
  'fi', // Finland
  'fr', // France
  'de', // Germany
  'gr', // Greece
  'hu', // Hungary
  'is', // Iceland
  'ie', // Ireland
  'it', // Italy
  'xk', // Kosovo (Unofficial, as Kosovo is not officially assigned a code by ISO)
  'lv', // Latvia
  'lt', // Lithuania
  'lu', // Luxembourg
  'mt', // Malta
  'md', // Moldova
  'me', // Montenegro
  'nl', // Netherlands
  'mk', // North Macedonia
  'no', // Norway
  'pl', // Poland
  'pt', // Portugal
  'ro', // Romania
  'rs', // Serbia
  'sk', // Slovakia
  'si', // Slovenia
  'es', // Spain
  'se', // Sweden
  'ch', // Switzerland
  'tr', // Türkiye / Turkey
  'gb', // United Kingdom
];

// country param can be undefined for caller's convenience.
// account.country: string | undefined
export function isMobileAppEnabledForCountry(country?: string) {
  return country
    ? MOBILE_ENABLED_COUNTRIES.includes(country.toLowerCase())
    : false;
}
