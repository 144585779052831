// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3685
import type { PAPEventGeneric } from "../base/event";
import type { StackAccessLevel } from "../enums/stack_access_level";
import type { FeatureLine } from "../enums/feature_line";

// Event_name: 'comment.dash_link'
// Description: Fires whenever a user adds a new comment to a Stack Item.
// Owner: dash-web
export type Comment_DashLink = PAPEventGeneric<
  'stacks',
  'comment',
  'dash_link',
  {
    // The stack id that represents the stack
    stackId?: string;
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // Bool that indicates if the item acted upon is pinned
    isPinned?: boolean;
    // marks if the content is shared content
    isShared?: boolean;
    // The access level settings for a stack
    stackAccessLevel?: StackAccessLevel;
    // The number of links in the stack
    numberOfLinks?: number;
    // The stack id that represents the stack (without extra hashing on the client side)
    unhashedStackId?: string;
    // Whether the user is interacting with their welcome stack
    isWelcomeStack?: boolean;
    // Used to identify if a stack is a published stack
    isPublished?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
  }
>;

export function PAP_Comment_DashLink(properties?: Comment_DashLink['properties']): Comment_DashLink {
  return <Comment_DashLink>{
    class: 'stacks',
    action: 'comment',
    object: 'dash_link',
    properties,
  };
}
