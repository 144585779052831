import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_View_DashSelfServeOnboarding } from '@mirage/analytics/events/types/view_dash_self_serve_onboarding';
import { ProfilingQuestions } from '@mirage/growth/components/ProfilingQuestions/ProfilingQuestions';
import { useDoOnceOnMount } from '@mirage/shared/hooks/useDoOnceOnMount';
import { RoutePath } from '@mirage/webapp/routeTypes';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useOnboardingSurveys } from '../hooks/useOnboardingSurveys';
import { useSelfServeOnboardingState } from '../hooks/useSelfServeOnboardingState';
import { SelfServeOnboardingStep } from './types';

import type { OnboardingSurveyAnswer } from '@mirage/service-onboarding-surveys/service/types';

type Props = {
  isAdmin: boolean;
  onValidationChange: (valid: boolean) => void;
};

const SMALL_COMPANY_SIZES = ['1', '2-20'];

const isLargeCompany = (value: OnboardingSurveyAnswer[]) => {
  const companySizeQuestion = value.find(
    (q) => q.surveyQuestionId === 'COMPANY_SIZE_V1',
  );
  if (companySizeQuestion && companySizeQuestion.surveyAnswer) {
    const selectedCompanySize = companySizeQuestion.surveyAnswer;
    return !SMALL_COMPANY_SIZES.includes(selectedCompanySize);
  }
  return false;
};

export const OnboardingProfilingStep = forwardRef(
  ({ isAdmin, onValidationChange }: Props, ref) => {
    const { reportPapEvent } = useMirageAnalyticsContext();
    const hasLoadedSurveyResponses = useRef(false);

    const surveyName = useMemo(
      () => (isAdmin ? 'ADMIN_PROFILING_V1' : 'TEAM_MEMBER_PROFILING_V1'),
      [isAdmin],
    );

    const {
      questions,
      submitSurvey,
      surveyResponses,
      getGroupedSurveyResponses,
    } = useOnboardingSurveys(surveyName);
    const { surveyResponses: surveyResponsesState, setSurveyResponses } =
      useSelfServeOnboardingState();
    const navigate = useNavigate();
    const [internalValues, setInternalValues] = useState<
      OnboardingSurveyAnswer[]
    >([]);

    useImperativeHandle(ref, () => ({
      submitSurvey: () => handleSubmitSurvey(internalValues),
    }));

    const handleProfilingAnswersChange = useCallback(
      (surveyResponses: OnboardingSurveyAnswer[]) => {
        setInternalValues(surveyResponses);

        if (isLargeCompany(surveyResponses)) {
          onValidationChange(true);
          return;
        }
        const hasCompletedAllQuestions =
          surveyResponses.filter(
            (response) => response.surveyAnswer?.trim() !== '',
          ).length >= questions.length;
        onValidationChange(hasCompletedAllQuestions);
      },
      [onValidationChange, questions.length],
    );

    useEffect(() => {
      const responsesToUse = surveyResponsesState.has(surveyName)
        ? surveyResponsesState.get(surveyName)
        : surveyResponses;

      if (responsesToUse?.length && !hasLoadedSurveyResponses.current) {
        // This ensures that the survey responses are not reset to the stored values on window resize
        hasLoadedSurveyResponses.current = true;
        setInternalValues(responsesToUse);
        handleProfilingAnswersChange(responsesToUse);
      }
    }, [
      handleProfilingAnswersChange,
      surveyResponses,
      surveyResponsesState,
      surveyName,
    ]);

    useDoOnceOnMount(() => {
      reportPapEvent(
        PAP_View_DashSelfServeOnboarding({
          selfServeIsTeamAdmin: isAdmin,
          onboardingStep: 'profiling',
          featureLine: 'onboarding',
        }),
      );
    });

    const getNavigatePathForStep = (step: SelfServeOnboardingStep): string => {
      return RoutePath.SETUP_ADMIN_SELF_SERVE.replace(':step', step);
    };

    const handleSubmitSurvey = async (responses: OnboardingSurveyAnswer[]) => {
      setInternalValues(responses);

      const { userBasedResponses } = getGroupedSurveyResponses();
      setSurveyResponses(surveyName, responses);
      const submitSuccess = await submitSurvey(userBasedResponses);
      if (!submitSuccess) {
        return false;
      }

      if (isLargeCompany(responses)) {
        navigate(getNavigatePathForStep(SelfServeOnboardingStep.LARGE_TEAM));
        return false;
      }
      return submitSuccess;
    };
    return (
      <ProfilingQuestions
        questions={questions}
        values={internalValues}
        skipRemaining={isLargeCompany(internalValues)}
        onChange={(value) => handleProfilingAnswersChange(value)}
      />
    );
  },
);

OnboardingProfilingStep.displayName = 'OnboardingProfilingStep';
