import { openURL } from '@mirage/service-platform-actions';
import { ConnectPrompt } from '@mirage/settings/components/connectors/ConnectPrompt';
import { GmailConnectPrompt } from '@mirage/settings/components/connectors/GmailConnectPrompt';
import { useState } from 'react';
import { showSnackbar } from '../../../shared/snackbar';
import { AvailableConnector } from '../../components/connectors/AvailableConnector';
import { useConnectConnector } from '../../hooks/connectors/useConnectConnector';
import { Skeletons } from './Skeleton';

import type { FetchedAtom } from '../../../shared/atoms/types';
import type { UIConnector, UIWebExtension } from '@mirage/shared/types';

export type ConnectorsSectionProps = Readonly<{
  connectors: UIConnector[];
  browserExtensionData?: FetchedAtom<UIWebExtension[]>;
}>;

export function ConnectorsSection({
  connectors,
  browserExtensionData,
  ...props
}: ConnectorsSectionProps) {
  const connectConnector = useConnectConnector({
    eventProps: { featureLine: 'connector' },
  });
  const [useConnectPrompt, setUseConnectPrompt] = useState<UIConnector | null>(
    null,
  );
  const [showGmailConnectFlow, setShowGmailConnectFlow] =
    useState<boolean>(false);

  function handleBrowserExtensionConnect(browserExtension: UIWebExtension) {
    if (browserExtension.id === 'safari') {
      // the safari extension is a deep link to the App Store, so in order to prevent a blank
      // tab from opening, navigate directly to just open the App Store app.
      // also, the app settings page is web only, so no need to account for desktop behavior
      window.location.href = browserExtension.installUrl;
      return;
    }

    openURL(browserExtension.installUrl);
  }

  async function handleConnectorConnect(connector: UIConnector | undefined) {
    if (!connector) return;

    if (connector?.auth_details?.prompt_configuration) {
      setUseConnectPrompt(connector);
    } else if (
      !showGmailConnectFlow &&
      connector.branding?.display_name === 'Gmail'
    ) {
      setShowGmailConnectFlow(true);
    } else {
      if (connector.branding?.display_name === 'Gmail') {
        setShowGmailConnectFlow(false);
      }
      const result = await connectConnector(connector);
      showSnackbar({ title: result.message });
    }
  }

  function onConnectPromptSubmit(
    connector: UIConnector | undefined,
    values: Record<string, string>,
  ): void {
    if (!connector) return;
    setUseConnectPrompt(null);
    connectConnector(connector, values);
  }

  if (browserExtensionData && !browserExtensionData.loaded) {
    return <Skeletons length={3} />;
  }

  const filterConnectedExtensions = (extension: UIWebExtension) =>
    !extension.connected;

  return (
    <div {...props}>
      {connectors.map((connector) => (
        <AvailableConnector
          key={connector.id_attrs?.id}
          item={connector}
          onConnect={() => handleConnectorConnect(connector)}
        />
      ))}
      {browserExtensionData?.data
        .filter(filterConnectedExtensions)
        .map((browserExtension) => (
          <AvailableConnector
            key={browserExtension.id}
            item={browserExtension}
            onConnect={() => {
              handleBrowserExtensionConnect(browserExtension);
            }}
          />
        ))}
      {useConnectPrompt && (
        <ConnectPrompt
          connector={useConnectPrompt}
          onSubmit={(values) => onConnectPromptSubmit(useConnectPrompt, values)}
          onCancel={() => setUseConnectPrompt(null)}
        />
      )}
      {showGmailConnectFlow && (
        <GmailConnectPrompt
          onContinue={() =>
            handleConnectorConnect(
              connectors.find(
                (connector) => connector.branding?.display_name === 'Gmail',
              ),
            )
          }
          onCancel={() => setShowGmailConnectFlow(false)}
        />
      )}
    </div>
  );
}
