import { Chip } from '@dropbox/dig-components/chip';
import { TextInput } from '@dropbox/dig-components/text_fields';
import i18n from '@mirage/translations';
import React, { useEffect, useState } from 'react';
import { AvatarMember, AvatarMemberOption } from './Avatar';
import { PermissionsMenu } from './PermissionsMenu';
import styles from './ShareModal.module.css';
import {
  getSharingMemberIdentifier,
  hasStackWritePermissions,
  sharingMemberKeysMatch,
  textMatchesMember,
  validateEmail,
} from './Utils';

import type {
  SharingMember,
  SharingMemberKey,
  SharingStackPermission,
  SharingUserContact,
  SharingUserContactKey,
  StackInfo,
} from './Types';

export const InvitePeople = ({
  stackInfo,
  getSharingMembers,
  invitees,
  setInvitees,
  inviteePermission,
  setInviteePermission,
}: {
  stackInfo: StackInfo;
  getSharingMembers: (searchText: string) => Promise<SharingMember[]>;
  invitees: (SharingMember | SharingUserContactKey)[];
  setInvitees: React.Dispatch<
    React.SetStateAction<(SharingMember | SharingUserContactKey)[]>
  >;
  inviteePermission: SharingStackPermission;
  setInviteePermission: React.Dispatch<
    React.SetStateAction<SharingStackPermission>
  >;
}) => {
  const [searchBarIsFocused, setSearchBarIsFocused] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [suggestedMembers, setSuggestedMembers] = useState<SharingMember[]>([]);

  const addSearchTextAsSharingEmail = () => {
    if (searchText === '') {
      return;
    }

    // We can only ever share with users in this manner, not groups
    const inviteeUsers = invitees.filter(
      (invitee) => invitee['.tag'] === 'user',
    ) as SharingUserContactKey[];
    const matchedContact = suggestedMembers.find(
      (contact) =>
        contact['.tag'] === 'user' && textMatchesMember(searchText, contact),
    ) as SharingUserContact;
    const alreadyAdded = inviteeUsers.some(
      ({ email }) => email === matchedContact?.email || email === searchText,
    );

    if (!alreadyAdded) {
      setInvitees((currentInvitees) => [
        ...currentInvitees,
        matchedContact || { '.tag': 'user', email: searchText },
      ]);
    }
    setSearchText('');
  };

  useEffect(() => {
    if (!searchBarIsFocused) {
      return;
    }
    getSharingMembers(searchText).then((members) => {
      setSuggestedMembers(
        // Filter out members who already exist on the stack or that are already added
        // to our list to be shared with
        members.filter(
          (member) =>
            !invitees.some((invitee) =>
              sharingMemberKeysMatch(invitee, member),
            ) &&
            !stackInfo.members.some((stackMember) =>
              sharingMemberKeysMatch(stackMember, member),
            ),
        ),
      );
    });
  }, [
    searchBarIsFocused,
    getSharingMembers,
    searchText,
    stackInfo.members,
    invitees,
  ]);

  return (
    <div>
      {hasStackWritePermissions(stackInfo.permission) && (
        <div className={styles.textSharePermissionsContainer}>
          <TextInput.Container size="large">
            <TextInput.ChipsContainer>
              {invitees.map(
                (sharingMember: SharingMember | SharingMemberKey) => (
                  <Chip
                    key={getSharingMemberIdentifier(sharingMember)}
                    size="small"
                    variant={
                      sharingMember['.tag'] === 'user' &&
                      !validateEmail(sharingMember.email)
                        ? 'warning'
                        : undefined
                    }
                    onDelete={() => {
                      // Remove the item with a matching email from the sharing members
                      setInvitees((existingInvitees) => {
                        return [...existingInvitees].filter(
                          (invitee) =>
                            !sharingMemberKeysMatch(invitee, sharingMember),
                        );
                      });
                    }}
                  >
                    {'displayName' in sharingMember && (
                      <Chip.AvatarAccessory>
                        <AvatarMember
                          member={sharingMember as SharingMember}
                          avatarSize="small"
                        />
                      </Chip.AvatarAccessory>
                    )}
                    <Chip.Content>
                      {'displayName' in sharingMember
                        ? sharingMember.displayName
                        : (sharingMember as SharingUserContactKey).email}
                    </Chip.Content>
                  </Chip>
                ),
              )}
              <TextInput.Input
                style={{
                  fontSize: 'var(--type__body__small--fontsize)',
                }}
                placeholder={i18n.t('invite_people')}
                onKeyDown={(e) => {
                  // Use entered text if the user presses enter or comma
                  if (e.key === 'Enter' || e.key === ',') {
                    addSearchTextAsSharingEmail();
                  }
                }}
                value={searchText}
                onChange={(e) => {
                  // Remove commas, as those are used to indicate that we are
                  // moving onto the next email
                  setSearchText(e.currentTarget.value.replace(',', ''));
                }}
                onFocus={() => setSearchBarIsFocused(true)}
                onBlur={() => {
                  addSearchTextAsSharingEmail();
                  setSearchBarIsFocused(false);
                }}
              />
            </TextInput.ChipsContainer>
          </TextInput.Container>
          {invitees.length > 0 && (
            <PermissionsMenu
              sharePermission={inviteePermission}
              onSelectSharePermissions={setInviteePermission}
              buttonProps={{
                variant: 'opacity',
                className: styles.sharingPermissionsButton,
              }}
            />
          )}
        </div>
      )}
      <div className={styles.contactsListContainer}>
        {searchBarIsFocused && suggestedMembers.length > 0 && (
          <div className={styles.contactsList}>
            {suggestedMembers.map((sharingMember) => (
              <AvatarMemberOption
                key={getSharingMemberIdentifier(sharingMember)}
                member={sharingMember}
                avatarSize="small"
                onSelection={() => {
                  if (
                    !invitees.some((member) =>
                      sharingMemberKeysMatch(member, sharingMember),
                    )
                  ) {
                    setInvitees((currentInvitees) => {
                      return [...currentInvitees, sharingMember];
                    });
                  }
                  setSearchText('');
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
