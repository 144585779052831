import { RoutePath } from '@mirage/webapp/routeTypes';
import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatHireDate = (dateUnformatted: string): string => {
  let [month, year] = dateUnformatted.split('/');
  if (!dateUnformatted.includes('/')) {
    [year, month] = dateUnformatted.split('-');
  }

  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(`${year}-${month}-01`));
};

export const getLocalTime = (timezone: string): string => {
  try {
    return new Date().toLocaleString('en-US', {
      timeZone: timezone,
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    });
  } catch (e) {
    return '';
  }
};

export const getTimezoneOffset = (timeZone: string): number => {
  const now = new Date();
  const localOffset = -now.getTimezoneOffset();
  const formattedDateInTargetTimezone = formatInTimeZone(now, timeZone, 'XXX');

  const match =
    formattedDateInTargetTimezone === 'Z'
      ? ['', '0', '0']
      : formattedDateInTargetTimezone.match(/([+-]\d{2}):?(\d{2})/);
  if (!match) {
    return 0;
  }
  if (!match) {
    return 0;
  }

  const [, hours, minutes] = match;
  const targetOffsetInMinutes =
    parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  return (targetOffsetInMinutes - localOffset) / 60;
};

const capitalizeWords = (wordsSring: string) => {
  const words = wordsSring.split(' ');
  const formattedWords = words.map((word) => {
    if (word.toLowerCase().startsWith('non-')) return word; // exclude "non-"
    return (
      word.toLowerCase().charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  });
  return formattedWords.filter((word) => !!word).join(' ');
};

export const formatLocation = (location?: string | null): string => {
  if (!location) {
    return location ?? '';
  }
  if (location.indexOf(' - ') >= 0) {
    return location;
  }
  const words = location.split(',');
  if (!words.length) {
    return location;
  }
  if (words.length == 1) {
    return capitalizeWords(words[0]);
  }
  if (words.length == 2) {
    const formattedWords = capitalizeWords(words[0]);
    const formattedState = words[1].toUpperCase();
    return [formattedWords, formattedState].join(',');
  }
  return location;
};

export const toMetro = (location: string) => {
  const metroSplit = location?.split(' - ');
  const metro = metroSplit?.[metroSplit.length - 1];

  return formatLocation(metro);
};

export const formatLeaveDate = (dateUnformatted: string): string => {
  const parsedDate = parseISO(dateUnformatted);
  return format(parsedDate, 'MMM d');
};

export const isMoreThanToday = (dateUnformatted: string): boolean => {
  const startDate = dateUnformatted.split(' ')[1];
  const parsedDate = parseISO(startDate);
  const today = new Date();
  // get the date of today
  today.setHours(0, 0, 0, 0);
  return parsedDate >= today;
};

export const calculateTenurePercentile = (
  tenure_percentile: number,
): number => {
  // Floor the number to one decimal place
  let floored = Math.floor(tenure_percentile * 10) / 10;
  if (Math.floor(tenure_percentile * 100) % 10 >= 5) {
    floored += 0.1;
  }
  return parseFloat(floored.toFixed(1));
};

export const getUserProfilePageHref = (
  email: string | undefined,
): string | undefined => {
  if (!email?.length) {
    return undefined;
  }
  return RoutePath.USER_PROFILE.replace(':id', email.split('@')[0]);
};
