import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Modal } from '@dropbox/dig-components/modal';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, OpenLine, VerifiedLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Create_DashCuration } from '@mirage/analytics/events/types/create_dash_curation';
import { PAP_Dismiss_DashCurationModal } from '@mirage/analytics/events/types/dismiss_dash_curation_modal';
import { PAP_Open_DashSearchResult } from '@mirage/analytics/events/types/open_dash_search_result';
import { PAP_Shown_DashCurationModal } from '@mirage/analytics/events/types/shown_dash_curation_modal';
import { PAP_Update_DashCuration } from '@mirage/analytics/events/types/update_dash_curation';
import ResultIcon from '@mirage/search/SearchResults/ResultIcon';
import { ResultRow } from '@mirage/search/SearchResults/ResultRow/ResultRow';
import { getTimeAgoStringFromTimestamp } from '@mirage/search/SearchResults/util/resultUtil';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import {
  createCuration,
  getCuration,
  updateCuration,
} from '@mirage/service-curations';
import { useDeleteVerification } from '@mirage/service-curations/useDeleteVerification';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { openResult } from '@mirage/service-result-actions';
import { VERIFIED_CURATION_TAG } from '@mirage/shared/curations';
import { UnsavedChangesModal } from '@mirage/shared/modals/UnsavedChangesModal';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DeleteVerificationModal } from './DeleteVerificationModal';
import styles from './VerifyContentModal.module.css';

import type { dash } from '@dropbox/api-v2-client';
import type { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { KeyboardEvent } from 'react';

// In sync with https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server/-/blob/go/src/dropbox/dash/curations/client.go?L35
const QUERY_CHAR_LIMIT = 50;
const QUERY_COUNT_LIMIT = 10;

type Props = {
  open: boolean;
  result: SearchResult;
  onClose: () => void;
  onSave: (curation: dash.Curation | null) => void;
  dashActionSurface?: DashActionSurface;
};

export function VerifyContentModal({
  open,
  result,
  onClose,
  onSave,
  dashActionSurface,
}: Props) {
  const { reportPapEvent, searchSessionManager } = useMirageAnalyticsContext();
  const hasLoggedShownEvent = useRef(false);
  const account = useDropboxAccount();
  const isDesktop = EnvCtx.surface === 'desktop';
  const isMobileSize = useIsMobileSize();
  const iconSize = isDesktop ? 'medium' : isMobileSize ? 'large' : 'xlarge';
  const { title, providerUpdateAtMs } = result;
  const timeAgoString = getTimeAgoStringFromTimestamp(providerUpdateAtMs, true);

  const [verifyCuration, setVerifyCuration] = useState<dash.Curation>();
  const [queryInput, setQueryInput] = useState<string>('');
  const [pinnedQueries, setPinnedQueries] = useState<Array<string>>([]);
  const [processing, setProcessing] = useState(false);
  const [confirmFailed, setConfirmFailed] = useState(false);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const handleSaveDeletion = (uid: string, curation: dash.Curation | null) => {
    onSave(curation);
  };

  const handleOpenResult = () => {
    reportPapEvent(
      PAP_Open_DashSearchResult({
        resultUuid: result.uuid,
        title: result.title,
        connector: result.connectorInfo?.connectorName,
        dashActionSurface,
      }),
    );
    openResult(result);
  };

  const {
    hasDeleteFailed,
    isDeleting,
    isDeleteConfirmOpen,
    confirmDeletion,
    initiateDeletion,
    cancelDeletion,
  } = useDeleteVerification(handleSaveDeletion, dashActionSurface);

  const queryStatus = useMemo(() => {
    const trimmedQuery = queryInput.trim();
    if (!trimmedQuery && queryInput) {
      return {
        error: true,
        errorStr: i18n.t('verify_content_error_empty_term'),
      };
    } else if (pinnedQueries.includes(trimmedQuery)) {
      return {
        error: true,
        errorStr: i18n.t('verify_content_error_duplicate_term'),
      };
    } else if (trimmedQuery.length >= QUERY_CHAR_LIMIT) {
      return {
        error: true,
        errorStr: i18n.t('verify_content_error_too_long_term'),
      };
    } else if (pinnedQueries.length > QUERY_COUNT_LIMIT) {
      return { error: true, errorStr: i18n.t('verify_content_error_max_term') };
    } else {
      return { error: false, errorStr: '' };
    }
  }, [queryInput, pinnedQueries]);

  useEffect(() => {
    // We need to fetch the existing curation to get the exact queries
    // since the exact queries aren't returned on the search result itself
    const retrieveCuration = async () => {
      const response = await getCuration(result.uuid, VERIFIED_CURATION_TAG);
      if (response.success) {
        setVerifyCuration(response.curation);
        setPinnedQueries(response.curation?.exact_queries || []);
      }
    };
    retrieveCuration();
  }, []);

  useEffect(() => {
    // Ensure we only log this event once, even if properties change
    if (hasLoggedShownEvent.current) return;

    reportPapEvent(
      PAP_Shown_DashCurationModal({
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashCurationType: 'verification',
        resultUuid: result.uuid,
        connector: result.connectorInfo?.connectorName,
        dashActionSurface,
      }),
    );

    hasLoggedShownEvent.current = true;
  }, [
    reportPapEvent,
    searchSessionManager,
    result.uuid,
    result.connectorInfo.connectorName,
    dashActionSurface,
  ]);

  const handleSaveVerification = async () => {
    // Decide whether to create or update the curation
    const isCreating = !verifyCuration;
    const writeOperation = isCreating ? createCuration : updateCuration;
    setProcessing(true);
    setConfirmFailed(false);

    const response = await writeOperation(
      result.uuid,
      VERIFIED_CURATION_TAG,
      pinnedQueries,
    );

    setProcessing(false);
    if (response.success) {
      if (response.curation) {
        onSave(response.curation);
      }
      showSnackbar({
        title: i18n.t('verify_content_save_success'),
        accessory: <UIIcon src={VerifiedLine} />,
      });

      // Determine whether to log "create" or "update" event
      const writeEvent = isCreating
        ? PAP_Create_DashCuration
        : PAP_Update_DashCuration;

      reportPapEvent(
        writeEvent({
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashCurationType: 'verification',
          dashVerificationQueryCount: pinnedQueries.length,
          resultUuid: result.uuid,
          title: result.title,
          connector: result.connectorInfo?.connectorName,
          curatedExactQueries: pinnedQueries.join(','),
          dashActionSurface,
        }),
      );
    } else {
      setConfirmFailed(true);
    }
  };

  const handleChangeQueryInput = (query: string) => {
    setHasUnsavedChanges(true);
    setQueryInput(query);
  };

  const handleAddQuery = () => {
    setHasUnsavedChanges(true);
    if (!queryStatus.error) {
      const trimmedQuery = queryInput.trim();
      setPinnedQueries((prev) => [...prev, trimmedQuery]);
      setQueryInput('');
    }
  };

  const handleDeleteQuery = (query: string) => {
    setHasUnsavedChanges(true);
    setPinnedQueries((prev) =>
      prev.filter((existingQuery) => existingQuery !== query),
    );
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyCodes.enter && queryInput) {
      handleAddQuery();
    }
  };

  const finalizeDismiss = () => {
    reportPapEvent(
      PAP_Dismiss_DashCurationModal({
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashCurationType: 'verification',
        resultUuid: result.uuid,
        connector: result.connectorInfo?.connectorName,
        dashActionSurface,
      }),
    );
    onClose();
  };

  const handleDismissModal = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedChangesModal(true);
      return;
    }
    finalizeDismiss();
  };

  return (
    <>
      <Modal
        open={open && !isDeleteConfirmOpen}
        isCentered={true}
        withCloseButton={i18n.t('close_modal_aria')}
        onRequestClose={handleDismissModal}
      >
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title>{i18n.t('verify_content_modal_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalContent}>
          <Text>{i18n.t('verify_content_modal_blurb')}</Text>
          <Box marginTop="16">
            <Text variant="label" size="small" isBold={true}>
              {i18n.t('verify_content_modal_preview_result')}
            </Text>
          </Box>
          <Box
            borderStyle="Solid"
            borderRadius="Small"
            marginTop="12"
            marginBottom="28"
          >
            <Box
              paddingX="20"
              paddingY="16"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: 'var(--dig-spacing__micro__large)' }}
              >
                <ResultIcon result={result} size={iconSize} />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={{ alignSelf: 'stretch' }}
                >
                  <Text variant="label" size={isDesktop ? 'medium' : 'large'}>
                    {title}
                  </Text>
                  {timeAgoString && (
                    <Text size="small" color="subtle" variant="label">
                      {timeAgoString}
                    </Text>
                  )}
                </Box>
              </Box>
              <DigTooltip
                title={i18n.t('result_open_button_text')}
                placement="top"
              >
                <IconButton
                  variant="borderless"
                  aria-label={i18n.t('open_result_aria')}
                  onClick={handleOpenResult}
                >
                  <UIIcon src={OpenLine} />
                </IconButton>
              </DigTooltip>
            </Box>

            <Box borderTop="Solid">
              <Box
                display="flex"
                flexDirection="column"
                margin="8"
                backgroundColor="Background Subtle"
                padding="24"
                justifyContent="center"
                alignItems="center"
                borderRadius="XSmall"
              >
                <Box
                  width="100%"
                  backgroundColor="Background Base"
                  borderRadius="Medium"
                >
                  <ResultRow
                    result={result}
                    query=""
                    iconSize={iconSize}
                    onLaunch={() => null}
                    resultActionConfig={{}}
                    onCopyResult={() => null}
                    resultPosition={0}
                    noMargin={true}
                    displayOnly={true}
                    onAddToStack={() => null}
                    onSummarize={() => null}
                    verification={{
                      // This is to show a "preview" of the verification
                      curation_type: { '.tag': 'curation_type_verified' },
                      updated_by: {
                        display_name: account?.name.display_name,
                        profile_image_url: account?.profile_photo_url,
                        email: account?.email,
                      },
                      updated_at_ms: Date.now(),
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Box marginBottom="8">
              <Text isBold={true} variant="label" size="small">
                {i18n.t('verify_content_modal_add_query')}
              </Text>
              <br />
              <Text color="subtle" variant="label" size="small">
                {i18n.t('verify_content_modal_add_query_subtitle')}
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" marginBottom="8">
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                style={{ gap: 'var(--dig-spacing__micro__small)' }}
              >
                <TextInput
                  wrapperProps={{ style: { boxShadow: 'none' } }}
                  onChange={(e) => handleChangeQueryInput(e.target.value)}
                  placeholder={i18n.t(
                    'verify_content_modal_add_query_placeholder',
                  )}
                  onKeyDown={handleKeyDown}
                  value={queryInput}
                />
                <IconButton
                  variant="opacity"
                  shape="standard"
                  disabled={queryStatus.error || !queryInput}
                  onClick={handleAddQuery}
                  aria-label={i18n.t('verify_content_modal_add_query_aria')}
                  className={styles.addQueryButton}
                >
                  <UIIcon src={AddLine} />
                </IconButton>
              </Box>
              <Text
                size="xsmall"
                color={queryStatus.error ? 'error' : 'subtle'}
              >
                {`${queryInput.length}/${QUERY_CHAR_LIMIT}`}
                {queryStatus.error && <>&nbsp;{queryStatus.errorStr}</>}
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: 'var(--dig-spacing__micro__small)' }}
            >
              {pinnedQueries.map((query) => (
                <Chip key={query} onDelete={() => handleDeleteQuery(query)}>
                  <Chip.Content>{query}</Chip.Content>
                </Chip>
              ))}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer hasTopBorder={true} preferComposition={true}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row-reverse"
          >
            <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
              {processing && <Spinner size="small" monochromatic={true} />}
              <Button variant="opacity" onClick={handleDismissModal}>
                {i18n.t('verify_content_modal_cancel')}
              </Button>
              <Button
                variant="primary"
                onClick={handleSaveVerification}
                disabled={processing}
              >
                {i18n.t('verify_content_modal_done')}
              </Button>
            </Box>
            {verifyCuration && (
              <Button
                variant="transparent"
                tone="destructive"
                onClick={() => initiateDeletion(result)}
                disabled={processing}
              >
                {i18n.t('verify_content_modal_remove')}
              </Button>
            )}
          </Box>
          {confirmFailed && (
            <Box width="100%" textAlign="right" marginTop="4">
              <Text color="error">{i18n.t('verify_content_save_failed')}</Text>
            </Box>
          )}
        </Modal.Footer>
      </Modal>

      <DeleteVerificationModal
        open={isDeleteConfirmOpen}
        processing={isDeleting}
        hasDeleteFailed={hasDeleteFailed}
        onDismiss={handleDismissModal}
        onConfirmDeletion={() => confirmDeletion(pinnedQueries)}
        onCancelDeletion={cancelDeletion}
        resultTitle={title}
      />

      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onCancel={() => setShowUnsavedChangesModal(false)} // Keep the verify modal open
        onContinue={() => {
          setShowUnsavedChangesModal(false);
          setHasUnsavedChanges(false);
          finalizeDismiss();
        }}
      />
    </>
  );
}
