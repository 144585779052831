import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { LinkComponent, LinkList } from '@mirage/link-list';
import { ListItemSize } from '@mirage/link-list/types';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './DragAndDrop.module.css';

import type {
  BaseEventPayload,
  ElementDragType,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import type { Link } from '@mirage/link-list/types';

type State =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
    };

const Preview = ({ link }: { link: Link }) => (
  <div className={styles.dragPreview}>
    <LinkList listItemSize={ListItemSize.Small}>
      <LinkComponent link={link} listItemSize={ListItemSize.Small} />
    </LinkList>
  </div>
);

export const useDraggablePreview = (link: Link) => {
  const [state, setState] = useState<State>({ type: 'idle' });

  const handleGenerateDragPreview = ({
    nativeSetDragImage,
  }: BaseEventPayload<ElementDragType> & {
    nativeSetDragImage: DataTransfer['setDragImage'] | null;
  }) => {
    setCustomNativeDragPreview({
      render({ container }) {
        // Cause a `react` re-render to create your portal synchronously
        setState({ type: 'preview', container });
        // In our cleanup function: cause a `react` re-render to remove your portal
        return () => setState({ type: 'idle' });
      },
      nativeSetDragImage,
    });
  };

  const preview =
    state.type === 'preview'
      ? createPortal(<Preview link={link} />, state.container)
      : null;

  return { preview, handleGenerateDragPreview };
};
