import { Button, IconButton } from '@dropbox/dig-components/buttons';
// eslint-disable-next-line no-restricted-imports
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashResultTooltip } from '@mirage/analytics/events/types/dismiss_dash_result_tooltip';
import { PAP_Shown_DashResultTooltip } from '@mirage/analytics/events/types/shown_dash_result_tooltip';
import { ActionIconButton } from '@mirage/search/SearchResults/ResultRow/ActionIconButton';
import { DisplayActionTypes } from '@mirage/search/SearchResults/ResultRow/types';
import {
  getOnboardingValue,
  setOnboardingValue,
} from '@mirage/service-onboarding';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './ResultActions.module.css';

import type { ResultTooltipType } from '@mirage/analytics/events/enums/result_tooltip_type';
import type { DropdownItem } from '@mirage/search/General/Dropdown';
import type { DisplayAction } from '@mirage/search/SearchResults/ResultRow';
import type { DisplayActionTooltip } from '@mirage/search/SearchResults/ResultRow/types';
import type { OnboardingStorage } from '@mirage/service-onboarding/service';

type ResultActionsProps = {
  displayedActions: DisplayAction[];
  dropdownActions?: Array<Array<DropdownItem>>;
  isResultSelected?: boolean;
  onOpenDropdown?: (isOpen: boolean) => void;
  showResultActions?: boolean;
};

type ResultActionProps = {
  action: DisplayAction;
  isResultSelected?: boolean;
};

export const ResultAction = ({
  action,
  isResultSelected,
}: ResultActionProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [dismissed, setDismissed] = useState(true);
  const [customTooltip, setCustomTooltip] = useState<
    DisplayActionTooltip | undefined
  >(undefined);
  const isMobileSize = useIsMobileSize();

  const handleActionClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const launchAction = e.detail === 1 ? 'click' : 'enter';
    action.onClick?.(launchAction);
  };

  const handleTooltipShown = useCallback(() => {
    reportPapEvent(
      PAP_Shown_DashResultTooltip({
        resultTooltipType: action.key as ResultTooltipType,
      }),
    );
  }, [action.key, reportPapEvent]);

  const handleDismissClick = useCallback(
    (evt: React.MouseEvent, key: string) => {
      evt.preventDefault();
      evt.stopPropagation();
      setDismissed(true);
      setOnboardingValue(key as keyof OnboardingStorage, true);
      reportPapEvent(
        PAP_Dismiss_DashResultTooltip({
          resultTooltipType: action.key as ResultTooltipType,
        }),
      );
    },
    [action.key, reportPapEvent],
  );

  useEffect(() => {
    // Detect if the custom tooltip has been dismissed
    const doSetCustomTooltip = async () => {
      if (action.tooltip) {
        const tooltip = await action.tooltip?.();
        setCustomTooltip(tooltip);
      }
    };
    doSetCustomTooltip();
  }, [action]);

  useEffect(() => {
    // Detect if the custom tooltip has been dismissed
    const getDismissed = async () => {
      if (customTooltip) {
        const isDismissed = await getOnboardingValue(
          customTooltip.key as keyof OnboardingStorage,
        );
        setDismissed(isDismissed as boolean);
      }
    };
    getDismissed();
  }, [action, customTooltip]);

  if (action.type === DisplayActionTypes.IconButton && !isMobileSize) {
    if (customTooltip && !dismissed) {
      return (
        <>
          <ActionIconButton
            onClick={handleActionClick}
            variant="borderless"
            ref={triggerRef}
            aria-label={action.title}
            {...action.analyticsAttr}
          >
            {action.icon}
          </ActionIconButton>
          <Tooltip.Control
            id={`${action.key}Tooltip`}
            triggerRef={triggerRef}
            open={true}
            auto
            placement="top"
            onDisplayed={handleTooltipShown}
            className={styles.customTooltip}
          >
            <IconButton
              aria-label={i18n.t('aria_dismiss_icon')}
              variant="transparent"
              onClick={(evt: React.MouseEvent) =>
                handleDismissClick(evt, customTooltip.key)
              }
              className={styles.dismissButton}
            >
              <UIIcon src={CloseLine} />
            </IconButton>
            <Text tagName="p" size="large" isBold>
              {customTooltip.title}
            </Text>
            <Text tagName="p" size="medium">
              {customTooltip.message}
            </Text>
          </Tooltip.Control>
        </>
      );
    } else if (action.disabled) {
      return (
        <DigTooltip
          key={action.key}
          title={action.title}
          openDelay={0}
          autoHide
        >
          <Box
            as="span"
            display="inline-block"
            borderRadius="Medium"
            boxShadow={{
              focusVisible: 'Focus Ring',
            }}
            outlineWidth="0"
            tabIndex={0}
          >
            <ActionIconButton
              variant="borderless"
              disabled={action.disabled}
              aria-label={action.title}
              {...action.analyticsAttr}
            >
              {action.icon}
            </ActionIconButton>
          </Box>
        </DigTooltip>
      );
    } else {
      return (
        <DigTooltip
          key={action.key}
          title={action.title}
          openDelay={0}
          autoHide
          maxWidth={400}
        >
          <ActionIconButton
            onClick={handleActionClick}
            variant="borderless"
            aria-label={action.title}
            disabled={action.disabled}
            {...action.analyticsAttr}
          >
            {action.icon}
          </ActionIconButton>
        </DigTooltip>
      );
    }
  }
  return (
    <Button
      key={action.key}
      variant="outline"
      onClick={handleActionClick}
      withIconEnd={isResultSelected && action.icon}
      disabled={action.disabled}
      aria-label={action.title}
      {...action.analyticsAttr}
    >
      <Text color="subtle" size="small">
        {action.title}
      </Text>
    </Button>
  );
};

export const ResultActions = ({
  displayedActions = [],
  isResultSelected,
  showResultActions,
}: ResultActionsProps) => {
  const isMobileSize = useIsMobileSize();

  if (displayedActions.length === 0) return null;

  return (
    <div
      className={classNames(styles.container, {
        [styles.isMobile]: isMobileSize,
      })}
    >
      {showResultActions &&
        displayedActions
          .filter((action) => !action.persistent && !action.hidden)
          .map((action) => (
            <ResultAction
              key={action.key}
              action={action}
              isResultSelected={isResultSelected}
            />
          ))}
      {displayedActions
        .filter((action) => action.persistent && !action.hidden)
        .map((action) => (
          <ResultAction
            key={action.key}
            action={action}
            isResultSelected={isResultSelected}
          />
        ))}
    </div>
  );
};
