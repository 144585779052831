import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Pin_DashStack } from '@mirage/analytics/events/types/pin_dash_stack';
import { PAP_Unpin_DashStack } from '@mirage/analytics/events/types/unpin_dash_stack';
import {
  stackDerivePAPProps,
  upsertToggleStackIsPinned,
} from '@mirage/service-stacks';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { asStackUpsertId, newStackPapEvent } from './utils';

import type { stacks } from '@dropbox/api-v2-client';

const actionSurfaceComponent = 'stacks';
const featureLine = 'stacks';

export const useToggleStackPin = (
  stack: stacks.Stack | null,
  mutationRequestId?: string,
  sessionId?: string,
) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isNewStack = !stack?.namespace_id;

  return useCallback(async () => {
    const { nsId } = await upsertToggleStackIsPinned(
      asStackUpsertId(stack?.namespace_id, mutationRequestId ?? null),
    );
    if (!isNewStack && stack?.user_data?.is_pinned) {
      reportPapEvent(
        PAP_Unpin_DashStack({
          ...stackDerivePAPProps(stack),
          actionSurfaceComponent,
          featureLine,
        }),
      );

      showSnackbar({ title: i18n.t('stack_unpinned') });
    } else {
      if (!isNewStack) {
        reportPapEvent(
          PAP_Pin_DashStack({
            ...stackDerivePAPProps(stack),
            actionSurfaceComponent,
            featureLine,
          }),
        );
      } else {
        reportPapEvent(newStackPapEvent(nsId, sessionId));
      }

      showSnackbar({ title: i18n.t('stack_pinned') });
    }
  }, [stack, reportPapEvent, isNewStack, mutationRequestId, sessionId]);
};
