import { activeStackHasWritePermissionsAtom } from '@mirage/stacks/ActiveStack/atoms';
import { useAtom, useAtomValue } from 'jotai';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { newStackDescriptionAtom, newStackNameAtom } from '../atoms';
import { EditingMode } from './EditingMode';
import { ReadOnlyModeDescription } from './ReadOnlyModeDescription';
import { ReadOnlyModeName } from './ReadOnlyModeName';

import type { TextAreaProps } from '@dropbox/dig-components/text_fields';
import type { RefObject } from 'react';

const FIELD_EDITOR_RELATED_BLUR = 'data-field-editor-related-blur';

export interface FieldEditorRef {
  enableEditing: (focus: boolean) => void;
}

interface FieldEditorProps {
  fieldName: string;
  submitFieldChange: () => boolean;
  placeholder: string;
  maxLength: number;
  mode: 'name' | 'description';
  fieldEditorRef?: RefObject<FieldEditorRef>;
  fieldInputRef?: RefObject<HTMLTextAreaElement>;
  additionalStyles?: string;
  onFocused?: () => void;
  onCancel?: () => void;
  enableEditMode?: boolean;
  hasWritePermissionsOverride?: boolean;
  dataTestId?: string;
  resizable?: TextAreaProps['resizable'];
  rows?: number;
  focused?: boolean;
  onEditStatusUpdate?: (status: boolean) => void;
}

export const FieldEditor: React.FC<FieldEditorProps> = ({
  fieldName: fieldNameProp,
  submitFieldChange,
  placeholder,
  maxLength,
  mode,
  fieldEditorRef,
  fieldInputRef,
  additionalStyles,
  onFocused,
  onCancel,
  enableEditMode = false,
  hasWritePermissionsOverride,
  dataTestId,
  resizable,
  rows,
  onEditStatusUpdate,
  focused = false,
}) => {
  const [isEditingField, setIsEditingField] = useState(enableEditMode);
  const [focus, setFocus] = useState(focused);
  const [newStackName, setNewStackName] = useAtom(newStackNameAtom);
  const [newStackDescription, setNewStackDescription] = useAtom(
    newStackDescriptionAtom,
  );
  const fieldName = mode === 'name' ? newStackName : newStackDescription;
  const setFieldName =
    mode === 'name' ? setNewStackName : setNewStackDescription;

  // We consider empty fieldName as changed to support setting a title,
  // clicking into the description, and then out of the description.
  // `updateStackNameAndDescription` will ensure that the stack does not
  // get created when both fields are empty.
  const hasFieldChanged =
    fieldName.trim() !== fieldNameProp.trim() || fieldName === '';
  const hasWritePermissionsForStack = useAtomValue(
    activeStackHasWritePermissionsAtom,
  );
  const hasWritePermissions =
    hasWritePermissionsOverride ?? hasWritePermissionsForStack;

  const handleStartEditing = useCallback(
    (focus: boolean) => {
      if (!hasWritePermissions) {
        return;
      }
      setFocus(focus);
      setIsEditingField(true);
    },
    [hasWritePermissions],
  );

  useEffect(() => {
    setFieldName(fieldNameProp.trim());
  }, [fieldNameProp, setFieldName]);

  useEffect(() => {
    if (isEditingField && focus && fieldInputRef?.current) {
      fieldInputRef.current.focus();
    }
  }, [isEditingField, focus, fieldInputRef]);

  useEffect(() => {
    onEditStatusUpdate?.(isEditingField);
  }, [isEditingField, onEditStatusUpdate]);

  const resetInitialValues = useCallback(() => {
    setIsEditingField(enableEditMode);
  }, [enableEditMode]);

  const handleCancelEditing = useCallback(() => {
    onCancel && onCancel();
    resetInitialValues();
  }, [resetInitialValues, onCancel]);

  const handleBlur = useCallback(
    (relatedTarget: HTMLElement | null) => {
      if (relatedTarget?.getAttribute(FIELD_EDITOR_RELATED_BLUR) === 'true') {
        // If the user clicked on another related editor, don't save the field
        return;
      }

      if (hasFieldChanged) {
        const success = submitFieldChange();
        if (success || fieldName === '') {
          handleCancelEditing();
        } else {
          setFieldName(fieldNameProp);
          fieldInputRef?.current?.focus();
        }
      } else {
        handleCancelEditing();
      }
    },
    [
      hasFieldChanged,
      submitFieldChange,
      fieldName,
      handleCancelEditing,
      setFieldName,
      fieldNameProp,
      fieldInputRef,
    ],
  );

  useImperativeHandle(fieldEditorRef, () => ({
    enableEditing(focus: boolean) {
      handleStartEditing(focus);
    },
  }));

  useEffect(() => {
    resetInitialValues();
  }, [resetInitialValues]);

  if (!isEditingField) {
    if (mode === 'name') {
      return (
        <ReadOnlyModeName
          stackName={fieldName}
          onClick={() => handleStartEditing(true)}
        />
      );
    }
    return (
      <ReadOnlyModeDescription
        stackDescription={fieldName}
        onClick={() => handleStartEditing(true)}
      />
    );
  }

  return (
    <EditingMode
      {...{ [FIELD_EDITOR_RELATED_BLUR]: 'true' }}
      field={fieldName}
      setField={setFieldName}
      stackFieldInputRef={fieldInputRef}
      onFocusEvent={onFocused || (() => {})}
      handleStackFieldBlur={handleBlur}
      placeholder={placeholder}
      maxLength={maxLength}
      data-testid={dataTestId}
      classnames={additionalStyles}
      resizable={resizable}
      rows={rows}
    />
  );
};
