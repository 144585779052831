import useSettings from '@mirage/service-settings/useSettings';
import { atom, useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

import type { TabIds } from '@mirage/service-settings/service/types';

const DEFAULT_FIRST_TAB: TabIds = 'recents';
const startPageSelectedTabAtom = atom<TabIds>(DEFAULT_FIRST_TAB);
export const useSelectedTab = () => {
  const { settings, set: setSettings } = useSettings(['startPageSelectedTab']);
  const { startPageSelectedTab: startPageSelectedTabFromSettings } =
    settings ?? {};

  useHydrateAtoms([
    [
      startPageSelectedTabAtom,
      startPageSelectedTabFromSettings || DEFAULT_FIRST_TAB,
    ],
  ]);

  const [startPageSelectedTab, _setStartPageSelectedTab] = useAtom(
    startPageSelectedTabAtom,
  );

  const setStartPageSelectedTab = (tabId: TabIds) => {
    _setStartPageSelectedTab(tabId);
    setSettings('startPageSelectedTab', tabId);
  };

  return {
    startPageSelectedTab,
    setStartPageSelectedTab,
  };
};
