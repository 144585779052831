import { atoms } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import cx from 'classnames';

import type { UIIconProps } from '@dropbox/dig-icons';

const size = {
  small: 24,
  medium: 36,
  standard: 32,
  large: 40,
};

export const UserProfileCircleIcon = (props: UIIconProps) => (
  <UIIcon
    {...props}
    size="standard"
    className={cx(
      props.className,
      atoms({
        backgroundColor: 'Opacity Surface',
        borderRadius: 'Circular',
        color: 'Text Subtle',
      }),
    )}
    style={{
      ...props.style,
      width: size[props.size ?? 'standard'],
      height: size[props.size ?? 'standard'],
      padding: '6',
    }}
  />
);
