import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import * as rx from 'rxjs';

import type { Service } from './service';

const userMetadataService = services.get<Service>(ServiceId.USER_METADATA);

export default userMetadataService;

export function getUserMetadata(metadataKeys: string[]) {
  return rx.firstValueFrom(userMetadataService.getUserMetadata(metadataKeys));
}

export function setUserMetadata(metadata: { [key: string]: string }) {
  return rx.firstValueFrom(userMetadataService.setUserMetadata(metadata));
}
