// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/1491
import type { PAPEventGeneric } from "../base/event";
import type { DashSurface } from "../enums/dash_surface";
import type { Environment } from "../enums/environment";
import type { OperatingSystem } from "../enums/operating_system";
import type { CalendarNotificationSetting } from "../enums/calendar_notification_setting";
import type { SidebarCollapsedState } from "../enums/sidebar_collapsed_state";

// Event_name: 'view.dash_start_page'
// Description: User views a start page tab. No delay or dwell criteria before launching this event.
// Owner: otc-startpage
export type View_DashStartPage = PAPEventGeneric<
  'dash',
  'view',
  'dash_start_page',
  {
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // the start time of the event in ms
    startTimeMs?: number;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The "Keep track of all your tabs" warning page that is displayed to the user on Dash Start Page if they do not have the desktop app opened.
    desktopWarning?: boolean;
    // Number of existing user stacks
    numberOfStacks?: number;
    // A list of all dash stack_ids associated with a user
    stackIds?: string;
    // The time in ms to load, cancel, or fail to load the content.
    loadTimeMs?: number;
    // Duplicate of build_version. Temporary while build_version gets sent to Hive data.
    buildNumber?: string;
    // True if the user has no stacks, false if the user has stacks
    stacksEmptyState?: boolean;
    // Setting for calendar notifications on dash
    calendarNotificationSetting?: CalendarNotificationSetting;
    // expanded/collapsed state of the dash stacks sidebar
    sidebarCollapsedState?: SidebarCollapsedState;
    // true if user has pinned stacks, false otherwise
    hasPinnedStacks?: boolean;
    // A list of the stack ids that are pinned by the user
    pinnedStackIds?: string;
    // number of pinned stacks
    numPinnedStacks?: number;
    // whether the user is currently in an onboarding flow when they perform the action being logged.
    inOnboardingFlow?: boolean;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUserV2?: boolean;
  }
>;

export function PAP_View_DashStartPage(properties?: View_DashStartPage['properties']): View_DashStartPage {
  return <View_DashStartPage>{
    class: 'dash',
    action: 'view',
    object: 'dash_start_page',
    properties,
  };
}
