import { Subject } from 'rxjs';

import type { KVStorage } from '@mirage/storage';

export interface KVStorageShape {
  /** User settings for each action, not persisted to the server */
  actionSettings: {
    settings: {
      [actionId: string]: AssistActionSettings;
    };
    version: 1;
  };
}

export interface AssistActionSettings {
  isEnabled?: boolean;
  // TODO: add things like ordering via things like sortKey with generateKeyBetween
}

export type AssistActionSettingsMap = {
  [actionId: string]: AssistActionSettings;
};

// TODO:(rich) clean up settings for actions that no longer exist

async function load(store: KVStorage<KVStorageShape>) {
  const actionSettings = await store.get('actionSettings');
  if (actionSettings?.version === 1) {
    return actionSettings.settings;
  } else {
    // Ignore old data if the version is not >=1
    return {};
  }
}

export function createAssistActionsSettings(
  storage: KVStorage<KVStorageShape>,
) {
  /* subject that emits latest items, includes presets */
  const subject = new Subject<AssistActionSettingsMap>();

  const getAllAssistActionSettings =
    async (): Promise<AssistActionSettingsMap> => {
      return load(storage);
    };

  const saveAssistActionSetting = async (
    actionId: string,
    setting: AssistActionSettings,
  ) => {
    const settings = await load(storage);
    settings[actionId] = setting;
    await storage.set('actionSettings', {
      settings,
      version: 1,
    });
    subject.next(settings);
  };

  return {
    getAllAssistActionSettings,
    saveAssistActionSetting,
    assistActionSettingsObservable: () => subject.asObservable(),
  };
}
