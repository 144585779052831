import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { OpenLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { SharingAirplaneMini } from '@dropbox/dig-illustrations';
import { useAdminUI } from '@mirage/mosaics/SettingsPage/useAdminUI';
import i18n from '@mirage/translations';
import styles from './OverQuota.module.css';

interface OverQuotaProps {
  licenseCount: number;
  isAdmin: boolean;
}

export function OverQuota({ licenseCount, isAdmin }: OverQuotaProps) {
  const { openAdminSettings } = useAdminUI();
  return (
    <Modal.Body>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingTop="24"
        paddingBottom="68"
      >
        <div className={styles.iconContainer}>
          <SharingAirplaneMini altText={'team_invite_image_alt_text'} />
        </div>
        <Title weightVariant="emphasized">
          {i18n.t('team_invite_over_quota_with_count', { count: licenseCount })}
        </Title>
        <Text color="subtle" variant="label" size="small">
          {isAdmin
            ? i18n.t('team_invite_admin_over_quota_message')
            : i18n.t('team_invite_user_over_quota_message')}
        </Text>
        {isAdmin && (
          <Box marginTop="Macro Small">
            <Button
              onClick={openAdminSettings}
              variant="opacity"
              withIconEnd={<UIIcon size="small" src={OpenLine} />}
            >
              {i18n.t('team_invite_manage_licenses')}
            </Button>
          </Box>
        )}
      </Box>
    </Modal.Body>
  );
}
