import React, { useRef } from 'react';
import { StackDescriptionEditor } from './components/StackDescriptionEditor';
import { StackNameEditor } from './components/StackNameEditor';

import type { StackDerivedPAPPropsWithFeature } from '../types';
import type { FieldEditorRef } from './components/FieldEditor';
import type { ReactNode, Ref } from 'react';

interface EditStackDetailsProps {
  triggerEditStackRef: Ref<HTMLInputElement> | undefined;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  stackName: string;
  stackDescription: string;
  isNewStack: boolean;
  withStackInfo?: ReactNode;
  updateStackNameAndDescription: (name: string, description: string) => void;
  hasWritePermissionsOverride?: boolean;
}

export const EditStackDetails: React.FC<EditStackDetailsProps> = ({
  triggerEditStackRef,
  stackDerivedPAPPropsWithFeature,
  stackName,
  stackDescription,
  isNewStack,
  withStackInfo,
  updateStackNameAndDescription,
  hasWritePermissionsOverride,
}) => {
  const stackNameRef = useRef<FieldEditorRef>(null);
  const stackDescriptionRef = useRef<FieldEditorRef>(null);

  const handleEditButtonClick = () => {
    stackNameRef.current?.enableEditing(false);
    stackDescriptionRef.current?.enableEditing(false);
  };

  return (
    <div>
      <StackNameEditor
        stackName={stackName}
        isNewStack={isNewStack}
        fieldEditorRef={stackNameRef}
        updateStackNameAndDescription={updateStackNameAndDescription}
        stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
        hasWritePermissionsOverride={hasWritePermissionsOverride}
      />
      {withStackInfo}
      <StackDescriptionEditor
        stackDescription={stackDescription}
        isNewStack={isNewStack}
        fieldEditorRef={stackDescriptionRef}
        updateStackNameAndDescription={updateStackNameAndDescription}
        stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
        hasWritePermissionsOverride={hasWritePermissionsOverride}
      />
      <input
        type="hidden"
        ref={triggerEditStackRef}
        onClick={handleEditButtonClick}
      />
    </div>
  );
};
