import { PAP_Comment_DashLink } from '@mirage/analytics/events/types/comment_dash_link';
import { PAP_Delete_DashLinkComment } from '@mirage/analytics/events/types/delete_dash_link_comment';
import { PAP_Edit_DashLinkComment } from '@mirage/analytics/events/types/edit_dash_link_comment';
import { reportPapEvent } from '@mirage/service-product-logging';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import {
  activeStackAtom,
  activeStackItemsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { useAtomValue } from 'jotai';

export const useStackCommentLogging = () => {
  const activeStack = useAtomValue(activeStackAtom);
  const activeStackItems = useAtomValue(activeStackItemsAtom);

  const logCommentAddSuccess = () => {
    if (activeStack) {
      reportPapEvent(
        PAP_Comment_DashLink({
          ...stackDerivePAPProps(activeStack),
          featureLine: 'stacks',
          numberOfLinks: activeStackItems?.length,
        }),
      );
    }
  };

  const logCommentDeleteSuccess = () => {
    if (activeStack) {
      reportPapEvent(
        PAP_Delete_DashLinkComment({
          ...stackDerivePAPProps(activeStack),
          featureLine: 'stacks',
          numberOfLinks: activeStackItems?.length,
        }),
      );
    }
  };

  const logCommentEditSuccess = () => {
    if (activeStack) {
      reportPapEvent(
        PAP_Edit_DashLinkComment({
          ...stackDerivePAPProps(activeStack),
          featureLine: 'stacks',
          numberOfLinks: activeStackItems?.length,
        }),
      );
    }
  };

  return {
    logCommentAddSuccess,
    logCommentDeleteSuccess,
    logCommentEditSuccess,
  };
};
