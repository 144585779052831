import {
  ClockLine,
  RewindLine,
  TextStyleLine,
  UnorderedListLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { PAP_Create_DashNewStack } from '@mirage/analytics/events/types/create_dash_new_stack';
import { StackSortOption } from '@mirage/service-settings/service/types';
import { toValidFileName } from '@mirage/service-stacks/service/utils';
import { nonNil, sortKeyCompare } from '@mirage/shared/util/tiny-utils';
import i18n from '@mirage/translations';
import { areStackItemsOrdered } from '../utils';

import type { stacks } from '@dropbox/api-v2-client';
import type { StackUpsertId } from '@mirage/service-stacks/service';

export const StackSortOptionMetadata = {
  [StackSortOption.RECENT]: {
    icon: ClockLine,
    label: () => i18n.t('sort_option_recent'),
  },
  [StackSortOption.ALPHA]: {
    icon: TextStyleLine,
    label: () => i18n.t('sort_option_alpha'),
  },
  [StackSortOption.SECTION]: {
    icon: UnorderedListLine,
    label: () => i18n.t('sort_option_section'),
  },
  [StackSortOption.VIEWED]: {
    icon: RewindLine,
    label: () => i18n.t('sort_option_viewed'),
  },
};

export const getMaxStackItemSortKey = (items: stacks.StackItemShortcut[]) => {
  if (areStackItemsOrdered(items) && items.length > 0) {
    const sortKeys = items.map((item) => item.sort_key ?? '');
    sortKeys.sort(sortKeyCompare);
    return sortKeys[sortKeys.length - 1];
  }
  return null;
};

export const hasItemWithUrlInSection = (
  items: stacks.StackItemShortcut[],
  toFind: { url: string; sectionId: string },
) => {
  return items.find(
    (item) =>
      item.url === toFind.url && item.parent_section_id === toFind.sectionId,
  );
};

export const asStackUpsertId = (
  nsId: string | undefined,
  mutationRequestId: string | null,
): StackUpsertId => {
  return {
    type: nsId ? 'nsId' : 'requestId',
    id: nsId ? nsId : nonNil(mutationRequestId, 'mutationRequestId'),
  };
};

export const newStackPapEvent = (
  nsId: string | undefined,
  sessionId: string | undefined,
  numberOfLinks: number = 0,
) =>
  PAP_Create_DashNewStack({
    isOwner: true,
    isShared: false,
    stackId: nsId,
    numberOfLinks,
    createStackSessionId: sessionId,
    featureLine: 'stacks',
    actionSurfaceComponent: 'stacks',
    numSuggestions: 0,
  });

// Remove illegal characters from the stack name during creation or edit
export const sanitizedStackName = (stackName: string): string => {
  return toValidFileName(stackName);
};
