import * as React from "react";
import styles from "./ContentIconImage.module.css";
import { Overlay } from "@dropbox/dig-components/overlay";

interface ContentIconImageProps {
  imageUrl: string;
}

export const ContentIconImage: React.FC<ContentIconImageProps> = ({
  imageUrl,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  return (
    <>
      <img
        ref={anchorRef}
        src={imageUrl}
        alt="Image Thumbnail"
        onMouseOver={() => setOpen(true)}
        onMouseOut={() => setOpen(false)}
      />
      {open && (
        <Overlay anchorRef={anchorRef}  placement="top" className={styles.imageOverlay} auto={true} offsetDistance={15}>
          <img src={imageUrl} alt="content icon" />
        </Overlay>
      )}
    </>
  );
};
