import { activeStackAtom } from '@mirage/stacks/ActiveStack/atoms';
import { useAtomValue } from 'jotai';
import { NoSectionsStackItems } from './NoSectionsStackItems';

import type { stacks } from '@dropbox/api-v2-client';

type SearchResultsStackItemsSectionProps = {
  items: stacks.StackItemShortcut[];
  onEditItem: (item: stacks.StackItemShortcut) => void;
};

export const SearchResultsStackItemsSection: React.FC<
  SearchResultsStackItemsSectionProps
> = ({ items, onEditItem }) => {
  const stack = useAtomValue(activeStackAtom);
  if (!stack) {
    return null;
  }

  return (
    <NoSectionsStackItems
      header=""
      stack={stack}
      items={items}
      onEditItem={onEditItem}
    />
  );
};
