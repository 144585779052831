import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Modal } from '@dropbox/dig-components/modal';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkCircleFill,
  ClockLine,
  SendLine,
  WarningLine,
} from '@dropbox/dig-icons/assets';
import i18n from '@mirage/translations';
import React, { useState } from 'react';
import styles from './InviteModalPostSubmit.module.css';

export const MAX_EMAIL_CHIP_WIDTH = 490; // in pixels

export interface InviteModalPostSubmitProps {
  successfullyInvitedEmails: string[];
  successfullySuggestedEmails: string[];
  failedEmails: string[];
  onClose?: () => void;
}

// Success Modal Parts
export const InviteModalSubmitSuccessContent = {
  Header: ({
    successfullyInvitedEmails,
    successfullySuggestedEmails,
  }: InviteModalPostSubmitProps) => (
    <Modal.FullBleed>
      <Box className={styles.sentTitleContainer}>
        <UIIcon
          className={styles.animatedCheckmark}
          src={CheckmarkCircleFill}
          width={80}
          height={80}
        />
        <Title className={styles.inviteSentText}>
          {i18n.t('invites_sent', {
            count:
              successfullyInvitedEmails.length +
              successfullySuggestedEmails.length,
          })}
        </Title>
      </Box>
    </Modal.FullBleed>
  ),
  Body: ({
    successfullyInvitedEmails,
    successfullySuggestedEmails,
  }: InviteModalPostSubmitProps) => (
    <Modal.Body>
      <Box className={styles.inviteeListContainer}>
        {successfullyInvitedEmails.map((invitee: string) => (
          <InviteeRow
            key={invitee}
            invitee={invitee}
            successful={true}
            color="standard"
          />
        ))}
        {successfullySuggestedEmails?.map((invitee: string) => (
          <InviteeRow
            key={invitee}
            invitee={invitee}
            successful={true}
            pendingApproval={true}
            color="standard"
          />
        ))}
      </Box>
    </Modal.Body>
  ),
  Footer: ({ onClose }: { onClose?: () => void }) => (
    <Modal.Footer>
      <Button variant="primary" onClick={onClose}>
        {i18n.t('done')}
      </Button>
    </Modal.Footer>
  ),
};

// Partial Success Modal Parts
export const InviteModalSubmitPartialSuccessContent = {
  Header: ({
    successfullyInvitedEmails,
    successfullySuggestedEmails,
  }: InviteModalPostSubmitProps) => (
    <Modal.Header>
      <Modal.Title
        weightVariant="emphasized"
        size="medium"
        className={styles.inviteTitle}
      >
        {i18n.t('invites_sent_with_count', {
          count:
            successfullyInvitedEmails.length +
            successfullySuggestedEmails.length,
        })}
      </Modal.Title>
    </Modal.Header>
  ),
  Body: ({
    successfullyInvitedEmails,
    successfullySuggestedEmails,
    failedEmails,
  }: InviteModalPostSubmitProps) => (
    <Modal.Body>
      <Box className={styles.successInviteListContainer}>
        {successfullyInvitedEmails.map((invitee: string) => (
          <InviteeRow
            key={invitee}
            invitee={invitee}
            successful={true}
            color="subtle"
          />
        ))}
        {successfullySuggestedEmails?.map((invitee: string) => (
          <InviteeRow
            key={invitee}
            invitee={invitee}
            successful={true}
            pendingApproval={true}
            color="subtle"
          />
        ))}
      </Box>

      <Box className={styles.failedInviteContainer}>
        <Title
          size="small"
          weightVariant="emphasized"
          color="error"
          className={styles.failedInviteTitle}
        >
          {i18n.t('invites_not_sent_with_count', {
            count: failedEmails.length,
          })}
        </Title>
        <Text variant="paragraph" size="small">
          {i18n.t('invites_failed_error_message')}
        </Text>
        <Box className={styles.failedInviteList}>
          {failedEmails.map((invitee: string) => (
            <InviteeRow
              key={invitee}
              invitee={invitee}
              successful={false}
              color="error"
            />
          ))}
        </Box>
      </Box>
    </Modal.Body>
  ),
  Footer: ({ onClose }: { onClose?: () => void }) => (
    <Modal.Footer>
      <Button variant="primary" onClick={onClose}>
        {i18n.t('done')}
      </Button>
    </Modal.Footer>
  ),
};

export const InviteModalSubmitSuccess: React.FC<InviteModalPostSubmitProps> = (
  props,
) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const onModalClose = () => {
    props.onClose?.();
    setIsModalOpen(false);
  };

  return (
    <Modal open={isModalOpen} isCentered onRequestClose={onModalClose}>
      <InviteModalSubmitSuccessContent.Header {...props} />
      <InviteModalSubmitSuccessContent.Body {...props} />
      <InviteModalSubmitSuccessContent.Footer onClose={onModalClose} />
    </Modal>
  );
};

export const InviteModalSubmitPartialSuccess: React.FC<
  InviteModalPostSubmitProps
> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const onModalClose = () => {
    props.onClose?.();
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      isCentered
      withCloseButton="close"
      onRequestClose={onModalClose}
    >
      <InviteModalSubmitPartialSuccessContent.Header {...props} />
      <InviteModalSubmitPartialSuccessContent.Body {...props} />
      <InviteModalSubmitPartialSuccessContent.Footer onClose={onModalClose} />
    </Modal>
  );
};

interface InviteeRowProps {
  invitee: string;
  successful: boolean;
  color: 'standard' | 'subtle' | 'error';
  pendingApproval?: boolean;
}

const getIconColor = (color: InviteeRowProps['color']): string => {
  switch (color) {
    case 'error':
      return 'var(--dig-color__alert__base)';
    case 'subtle':
      return 'var(--dig-color__text__subtle)';
    case 'standard':
    default:
      return 'var(--dig-color__text__base)';
  }
};

const InviteeRow: React.FC<InviteeRowProps> = ({
  invitee,
  successful,
  color,
  pendingApproval,
}) => {
  const inviteStatusIcon = successful ? SendLine : WarningLine;
  const inviteRowIconColor = getIconColor(color);

  return (
    <Box key={invitee} className={styles.inviteeListElement}>
      <UIIcon src={inviteStatusIcon} color={inviteRowIconColor} />
      <Text color={color}>
        <Truncate
          maxWidth={MAX_EMAIL_CHIP_WIDTH}
          tooltipControlProps={{ auto: true, placement: 'top' }}
        >
          {invitee}
        </Truncate>
      </Text>
      {pendingApproval && (
        <Chip className={styles.inviteePendingStatus} size="small">
          <Chip.IconAccessory>
            <UIIcon src={ClockLine} />
          </Chip.IconAccessory>
          <Chip.Content>{i18n.t('invite_pending_approval')}</Chip.Content>
        </Chip>
      )}
    </Box>
  );
};
