import { Backoff } from '@mirage/shared/util/backoff';
import { runWithRetries } from '@mirage/shared/util/retries';
import { getSupportedFeedConnectorNames } from '../util/helpers';

import type { ActivityFeedFilters } from '../types';
import type { dash_feed, DropboxResponseError } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { ConnectorName } from '@mirage/shared/connectors';

export async function listActivityFeed(
  callApiV2: APIv2Callable,
  limit?: number,
  cursor?: string,
  last_viewed_ts?: number,
  activityFeedFilters?: ActivityFeedFilters,
): Promise<dash_feed.ListActivityFeedResponse> {
  let filters: dash_feed.ActivityFeedFilters | undefined;
  if (activityFeedFilters) {
    // DASHWEB-3960 - protobuf treats empty arrays the same as undefined
    //    but product requirement says when there are no applications selected we should return no results
    //    however, the API returns all applications when none are passed (so that when the user hasn't made a selection yet it shows everything)
    //    so we special case it here
    if (
      activityFeedFilters.applications &&
      activityFeedFilters.applications.length === 0
    ) {
      return {
        items: [],
      };
    }

    const actor = activityFeedFilters.actor
      ? { email: activityFeedFilters.actor.email }
      : undefined;

    const applications = (activityFeedFilters.applications || []).map(
      (application) => ({ application }),
    );

    filters = {
      only_mine: activityFeedFilters.isOnlyMine,
      applications,
      actor,
    };
  }

  // Despite typecheck, this can occasionally be `null`, which makes the API throw
  if (last_viewed_ts === null) last_viewed_ts = undefined;

  const args: dash_feed.ListActivityFeedArg = {
    limit,
    cursor,
    last_viewed_ts,
    filters,
  };

  return callListActivityFeedWithRetries(callApiV2, args);
}

function callListActivityFeedWithRetries(
  callApiV2: APIv2Callable,
  args: dash_feed.ListActivityFeedArg,
): Promise<dash_feed.ListActivityFeedResponse> {
  return runWithRetries(
    () => {
      return callApiV2('dashFeedListActivityFeed', args);
    },
    {
      numAttempts: 3,
      backoff: new Backoff(1000, 2000),
      isRetryableError: (e) => {
        if (!isListActivityFeedError(e)) {
          return false;
        }

        const tag = e.error.error['.tag'];
        return tag === 'retryable_error';
      },
    },
  );
}

const isListActivityFeedError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any,
): e is { error: DropboxResponseError<dash_feed.ListActivityFeedError> } => {
  return e?.name === 'DropboxResponseError' && !!e.error?.error?.['.tag'];
};

export const listFeedItems = async (
  callApiV2: APIv2Callable,
  limit?: number,
  cursor?: string,
  last_viewed_ts?: number,
  activityFeedFilters?: ActivityFeedFilters,
): Promise<dash_feed.ListFeedItemsResponse> => {
  let filters: dash_feed.ActivityFeedFilters | undefined;
  if (activityFeedFilters) {
    // DASHWEB-3960 - protobuf treats empty arrays the same as undefined
    //    but product requirement says when there are no applications selected we should return no results
    //    however, the API returns all applications when none are passed (so that when the user hasn't made a selection yet it shows everything)
    //    so we special case it here
    if (
      activityFeedFilters.applications &&
      activityFeedFilters.applications.length === 0
    ) {
      return { items: [] };
    }
    const applications = (activityFeedFilters.applications || []).map(
      (application) => ({ application }),
    );

    filters = {
      applications,
    };
  }

  const args: dash_feed.ListFeedItemsArg = {
    limit,
    cursor,
    filters,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  let response = await callListFeedItemsFeedWithRetries(callApiV2, args);
  response = patchIsReadStatus(response, last_viewed_ts || 0);
  response = patchExpandedFeedItems(response);
  response = await filterSupportedConnectors(response);
  return response;
};

// patching the is_read status of the feed items based on the last_viewed_ts until it's handled on the server
const patchIsReadStatus = (
  response: dash_feed.ListFeedItemsResponse,
  last_viewed_ts: number,
) => {
  response.items?.forEach((item) => {
    // exists new activity since you last viewed
    const isUnread = item.activities?.some(
      (activity) => (activity.ts || 0) > last_viewed_ts / 1000,
    );
    item.is_read = last_viewed_ts !== 0 && !isUnread;
  });

  return response;
};

// patches items by expanding stack items that have multiple activities, and doc activities with multiple action names
// this is a temporary workaround until we can determine how to display multiple actions on a single target
const patchExpandedFeedItems = (response: dash_feed.ListFeedItemsResponse) => {
  const expandedItems: dash_feed.FeedItem[] = [];
  response.items?.forEach((item) => {
    expandedItems.push(...expandFeedItem(item));
  });
  response.items = expandedItems.sort((a, b) => {
    const aTs = a.activities?.[0]?.ts || 0;
    const bTs = b.activities?.[0]?.ts || 0;
    return bTs - aTs;
  });
  return response;
};

// Ensure there aren't any activities that are not supported
const filterSupportedConnectors = async (
  response: dash_feed.ListFeedItemsResponse,
) => {
  const supportedConnectorNames = await getSupportedFeedConnectorNames();
  response.items = response.items?.filter((item) => {
    if (item.target?.details?.object_details?.['.tag'] == 'document') {
      const connectorName =
        item.target.details.object_details.connector_info?.connector_name;
      return supportedConnectorNames.includes(connectorName as ConnectorName);
    }
    return true;
  });
  return response;
};

const expandFeedItem = (item: dash_feed.FeedItem): dash_feed.FeedItem[] => {
  if (item.target?.details?.object_details?.['.tag'] == 'full_stack') {
    return (
      item.activities?.map((activity) => {
        return {
          ...item,
          activities: [activity],
        };
      }) || []
    );
  } else if (item.target?.details?.object_details?.['.tag'] == 'document') {
    const activitiesByActionName: Record<string, dash_feed.FeedActivity[]> = {};

    item.activities?.forEach((activity) => {
      const actionName = activity.action?.action?.['.tag'];
      if (actionName) {
        if (!activitiesByActionName[actionName]) {
          activitiesByActionName[actionName] = [];
        }
        activitiesByActionName[actionName].push(activity);
      }
    });

    // create separate feed items for each action name
    // e.g. for now, we don't want to mix doc share and doc edits in one item
    return Object.entries(activitiesByActionName).map(([_, activities]) => ({
      ...item,
      activities,
    }));
  } else {
    return [item];
  }
};

function callListFeedItemsFeedWithRetries(
  callApiV2: APIv2Callable,
  args: dash_feed.ListFeedItemsArg,
): Promise<dash_feed.ListFeedItemsResponse> {
  return runWithRetries(
    () => {
      return callApiV2('dashFeedListFeedItems', args);
    },
    {
      numAttempts: 3,
      backoff: new Backoff(1000, 2000),
      isRetryableError: (e) => {
        if (!isListFeedItemsError(e)) {
          return false;
        }

        const tag = e.error.error['.tag'];
        return tag === 'retryable_error';
      },
    },
  );
}

const isListFeedItemsError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any,
): e is { error: DropboxResponseError<dash_feed.ListFeedItemsError> } => {
  return e?.name === 'DropboxResponseError' && !!e.error?.error?.['.tag'];
};
