import { Avatar } from '@dropbox/dig-components/avatar';
import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { FormHelperText } from '@dropbox/dig-components/form_row';
import { Menu } from '@dropbox/dig-components/menu';
import { Overlay } from '@dropbox/dig-components/overlay';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { FailFill } from '@dropbox/dig-icons/dist/mjs/assets';
import { getInitials } from '@mirage/shared/account';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './InviteFormRow.module.css';
import { SuggestedTeamInvites } from './SuggestedTeamInvites';
import { EmailValidationError } from './types';
import { getErrorMessage } from './utils/errorMessages';

import type { InviteFormContact, Role, TeamMember } from './types';
import type { TextInputInputRefObject } from '@dropbox/dig-components/text_fields';

type Props = {
  teamMembers: Readonly<TeamMember[]>;
  disableRoleSelect: boolean;
  showRoleSelect: boolean;
  emailValidationError?: EmailValidationError;
  disabled?: boolean;
  onChange: (contact: InviteFormContact) => void;
  onClose: () => void;
  className?: string;
  preventClose?: boolean;
};

export const InviteFormRow = ({
  teamMembers,
  disableRoleSelect,
  showRoleSelect,
  onChange,
  onClose,
  emailValidationError,
  className,
  preventClose = false,
  disabled,
}: Props) => {
  const [role, setRole] = useState<Role>('Member');
  const [chips, setChips] = useState<TeamMember[]>([]);
  const [typeaheadOpen, setTypeaheadOpen] = useState<boolean>(false);
  const [stringValue, setStringValue] = useState<string>('');
  const anchorRef = useRef(null);
  const inputRef = useRef<TextInputInputRefObject>(null);

  // We do not see undefined (unvalidated) as error for UI purposes
  const hasError =
    emailValidationError !== null &&
    emailValidationError !== undefined &&
    emailValidationError !== EmailValidationError.VALIDATION_PENDING;
  const errorMessage = hasError
    ? getErrorMessage(emailValidationError)
    : undefined;

  // Controls whether remove row icon should show
  const [isHovering, setIsHovering] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // Check if input matches any team member name or email
  const matchedMembers = teamMembers.filter((member) => {
    return (
      member.name?.toLowerCase().includes(stringValue?.toLowerCase()) ||
      member.email?.toLowerCase().includes(stringValue?.toLowerCase())
    );
  });

  const clearChip = () => {
    setStringValue('');
    setChips([]);
    setRole('Member');
  };

  useEffect(() => {
    setTypeaheadOpen(stringValue?.length > 0 && matchedMembers.length > 0);
  }, [stringValue, matchedMembers.length]);

  // Debounce function that updates chips/selection to prevent unnecessary calls to onSelectionChange
  const debouncedUpdateSelection = useDebounce((newChips: TeamMember[]) => {
    // Chip with no email fields for empty rows
    if (!newChips.length) {
      onChange({ email: null, newRole: role });
      return;
    }

    const matchedTeamMember = teamMembers.find(
      (member) => member.email === newChips[0].email,
    );

    if (matchedTeamMember) {
      const existingRole = matchedTeamMember.isAdmin ? 'Co-admin' : 'Member';
      setRole(existingRole);
      onChange({
        email: newChips[0].email,
        newRole: null,
      });
    } else {
      onChange({
        email: newChips[0].email,
        newRole: role,
      });
    }
  }, 300);

  useEffect(() => {
    debouncedUpdateSelection(chips);
  }, [chips, debouncedUpdateSelection]);

  const handleTeamMemberInputChange = useCallback((val: string) => {
    setStringValue(val);
  }, []);

  const handleTeamMemberInputBlur = useCallback(() => {
    if (chips.length) return;
    if (!stringValue) return;

    const member: TeamMember = {
      email: stringValue,
      isAdmin: false,
    };
    setChips([member]);

    setIsFocused(false);
  }, [chips.length, stringValue]);

  const handleTeamMemberSelect = useCallback((teamMember: TeamMember) => {
    setTypeaheadOpen(false);
    setChips([teamMember]);
    setStringValue('');
  }, []);

  const handleClickOutside = () => {
    setStringValue('');
    setTypeaheadOpen(false);
  };

  const handleClickClose = () => {
    onClose();
  };

  const handleRoleSelection = (val: Role) => {
    onChange({ email: chips[0]?.email, newRole: val });
    setRole(val);
    setIsFocused(false);
  };

  return (
    <Box>
      <Box
        className={styles.textFieldContainer}
        display="flex"
        flexGrow={1}
        alignItems="center"
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <TextInput.Container
          ref={anchorRef}
          onClick={() => {
            // Focus on the input when the container is clicked
            // This enables clicks on the whitespace around a chip to focus the input
            inputRef.current?.focus();
          }}
          disabled={disabled}
        >
          <TextInput.ChipsContainer>
            {!!chips.length &&
              chips.map((chip, i) => (
                <Chip
                  key={`team-member-chip=${i}`}
                  size="small"
                  onDelete={clearChip}
                  variant={hasError ? 'alert' : 'standard'}
                  disabled={disabled}
                >
                  <Chip.Content>
                    <TeamMemberChip
                      showRoleSelect={showRoleSelect}
                      teamMember={chip}
                    />
                  </Chip.Content>
                </Chip>
              ))}
            <TextInput.Input
              type="email"
              aria-invalid={hasError}
              aria-errormessage={errorMessage}
              disabled={chips?.length > 0 || disabled}
              className={classnames(styles.textField, className)}
              placeholder={
                !chips?.length ? i18n.t('team_invite_add_email_or_name') : ''
              }
              value={!chips.length ? stringValue : ''}
              onChange={(e) => handleTeamMemberInputChange(e.target.value)}
              onBlur={handleTeamMemberInputBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                  handleTeamMemberInputBlur();
                  handleClickOutside();
                }
              }}
              ref={inputRef}
            />
          </TextInput.ChipsContainer>
          <TextInput.Accessory>
            {showRoleSelect ? (
              <Menu.Wrapper
                isContentLabelledByTrigger
                onSelection={handleRoleSelection}
              >
                {({ getContentProps, getTriggerProps }) => (
                  <>
                    <Button
                      className={styles.menuButtonInput}
                      {...getTriggerProps()}
                      variant="opacity"
                      disabled={disableRoleSelect || disabled}
                      withDropdownIcon
                    >
                      {i18n.t(
                        role === 'Member'
                          ? 'team_invite_member'
                          : 'team_invite_co_admin',
                      )}
                    </Button>
                    <Menu.Content {...getContentProps()}>
                      <Menu.Segment>
                        <Menu.ActionItem key="Member" value="Member">
                          {i18n.t('team_invite_member')}
                        </Menu.ActionItem>
                        <Menu.ActionItem key="Co-admin" value="Co-admin">
                          {i18n.t('team_invite_co_admin')}
                        </Menu.ActionItem>
                      </Menu.Segment>
                    </Menu.Content>
                  </>
                )}
              </Menu.Wrapper>
            ) : undefined}
          </TextInput.Accessory>
        </TextInput.Container>

        {/* Prevents deletion of first typeahead field in InviteForm which could result in no typeaheads being shown */}
        {!preventClose && !disabled && (
          <UIIcon
            src={FailFill}
            onClick={handleClickClose}
            color="var(--dig-color__utility__overlay)"
            className={classnames(styles.icon, {
              [styles.focused]: isHovering || isFocused,
            })}
          />
        )}

        {typeaheadOpen && (
          <ClickOutside
            isActive={typeaheadOpen}
            onClickOutside={handleClickOutside}
            isClickThroughPortaled={false}
          >
            <Overlay
              anchorRef={anchorRef}
              placement="bottom"
              setWidthSameAsAnchor={true}
              isPortaled={false}
            >
              <SuggestedTeamInvites
                teamMembers={matchedMembers}
                onTeamMemberSelect={handleTeamMemberSelect}
              />
            </Overlay>
          </ClickOutside>
        )}
      </Box>
      {errorMessage && (
        <FormHelperText variant="alert">
          <span>{errorMessage}</span>
        </FormHelperText>
      )}
    </Box>
  );
};

type TeamMemberChipProps = {
  teamMember: TeamMember;
  showRoleSelect: boolean;
};

const TeamMemberChip = ({
  teamMember,
  showRoleSelect,
}: TeamMemberChipProps) => {
  if (teamMember.name) {
    // Max width for truncating icons with long names depending on whether role selection icon is present
    const chipTextMaxWidth = showRoleSelect ? 320 : 425;
    return (
      <>
        <Avatar
          src={teamMember.photo}
          alt={teamMember.name}
          className={styles.avatar}
          size="small"
        >
          {getInitials(teamMember.name || teamMember.email || '')}
        </Avatar>
        <Truncate maxWidth={chipTextMaxWidth}>
          <Text>{teamMember.name}</Text>
        </Truncate>
      </>
    );
  }
  // Max width for truncating icons with long email inputs depending on whether role selection icon is present
  const chipTextMaxWidth = showRoleSelect ? 340 : 445;
  return (
    <Truncate maxWidth={chipTextMaxWidth}>
      <Text>{teamMember.email}</Text>
    </Truncate>
  );
};
