import {
  RecentQueriesCache,
  RecommendationsCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';
import TypeaheadControlCache from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches/typeahead-control-cache';
import URLShortcutsCache from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches/url-shortcuts';
import { generateClickMap } from '@mirage/shared/search/scoring/clicks';
import WithDefaults from '@mirage/storage/with-defaults';
import { v5 as uuidv5 } from 'uuid';
import ChannelRecommendationsCache from './subcaches/channel-recommendations';
import HitTrackingCache from './subcaches/hit-tracking';
import PeopleRecommendationsCache from './subcaches/people-recommendations';

import type { HitTracking } from '../types/common';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { PeopleRecommendation } from '@mirage/service-dbx-api/service/people';
import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';
import type { TypeaheadControlCacheShape } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches/typeahead-control-cache';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { HitRecord } from '@mirage/shared/search/hit-record';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';
import type { KVStorage } from '@mirage/storage';

const TYPEAHEAD_SEARCH_NAMESPACE = 'b7549bdc-035a-11ef-be37-325096b39f47';

/**
 * Full cache for all types of typeahead results, encapsulates the search relevancy logic between the different types
 * also handles saving in-memory cache to persistent storage when items are added
 */
interface TypeaheadCache {
  // read
  all(key: CacheKey): Readonly<CacheKeyToValueMap[CacheKey][]>;
  getHits(uuid: string): HitTracking | undefined;
  getURLShortcuts(): readonly URLShortcut[];

  // write
  cacheRecentQueries(queries: string[]): Promise<void>;
  registerHit(uuid: string, epochDate: number): Promise<void>;
  addURLShortcutToCache(shortcut: URLShortcut): Promise<void>;

  // delete
  clear(key: CacheKey): Promise<void>;
  tearDown(): Promise<void>;
  remove(previousQuery: PreviousQuery): Promise<void>;
}

export enum CacheKey {
  ControlCache = 'cache-metadata',
  RecentQueries = 'recent-queries',
  Recommendations = 'recommendations',
  HitTracking = 'hit-tracking',
  URLShortcuts = 'url-shortcuts',
  PeopleRecommendations = 'people-recommendations',
  ChannelRecommendations = 'channel-recommendations',
}

// source of truth for cached values and internal cache representation
type CacheKeyToValueMap = {
  [CacheKey.ControlCache]: TypeaheadControlCacheShape;
  [CacheKey.RecentQueries]: PreviousQuery;
  [CacheKey.Recommendations]: Recommendation;
  [CacheKey.HitTracking]: HitRecord;
  [CacheKey.URLShortcuts]: URLShortcut;
  [CacheKey.PeopleRecommendations]: PeopleRecommendation;
  [CacheKey.ChannelRecommendations]: SearchResult;
};

type Subcaches = {
  [Property in keyof CacheKeyToValueMap]: MemoryCache<
    CacheKeyToValueMap[Property]
  >;
};

export type PersistentCacheShape = {
  [Property in keyof CacheKeyToValueMap]: CacheKeyToValueMap[Property][];
};

/**
 *
 * Full Cache Implementation
 *
 */
const defaults: PersistentCacheShape = {
  [CacheKey.ControlCache]: [{}],
  [CacheKey.RecentQueries]: [],
  [CacheKey.Recommendations]: [],
  [CacheKey.HitTracking]: [{}],
  [CacheKey.URLShortcuts]: [],
  [CacheKey.PeopleRecommendations]: [],
  [CacheKey.ChannelRecommendations]: [],
};

export class TypeaheadCacheImpl implements TypeaheadCache {
  private wrappedStorage: WithDefaults<PersistentCacheShape>;

  private subcaches: Subcaches = {
    [CacheKey.ControlCache]: new TypeaheadControlCache(),
    [CacheKey.RecentQueries]: new RecentQueriesCache(),
    [CacheKey.Recommendations]: new RecommendationsCache(),
    [CacheKey.HitTracking]: new HitTrackingCache(),
    [CacheKey.URLShortcuts]: new URLShortcutsCache(),
    [CacheKey.PeopleRecommendations]: new PeopleRecommendationsCache(),
    [CacheKey.ChannelRecommendations]: new ChannelRecommendationsCache(),
  };

  constructor(adapter: KVStorage<PersistentCacheShape>) {
    this.wrappedStorage = new WithDefaults<PersistentCacheShape>(
      adapter,
      defaults,
    );

    // hydrate in-memory subcaches from disk
    this.wrappedStorage.get(CacheKey.ControlCache).then((queries) => {
      this.subcaches[CacheKey.ControlCache].addItems(queries);
    });

    this.wrappedStorage.get(CacheKey.RecentQueries).then((queries) => {
      this.subcaches[CacheKey.RecentQueries].addItems(queries);
    });

    this.wrappedStorage.get(CacheKey.Recommendations).then((records) => {
      this.subcaches[CacheKey.Recommendations].addItems(records);
    });

    this.wrappedStorage.get(CacheKey.HitTracking).then((records) => {
      this.subcaches[CacheKey.HitTracking].addItems(records);
    });

    this.wrappedStorage.get(CacheKey.URLShortcuts).then((records) => {
      this.subcaches[CacheKey.URLShortcuts].addItems(records);
    });

    this.wrappedStorage.get(CacheKey.PeopleRecommendations).then((records) => {
      this.subcaches[CacheKey.PeopleRecommendations].addItems(records);
    });

    this.wrappedStorage.get(CacheKey.ChannelRecommendations).then((records) => {
      this.subcaches[CacheKey.ChannelRecommendations].addItems(records);
    });

    // clear out storage for old caches which are no longer used
    this.wrappedStorage.set('recently-seen' as CacheKey, []);
    this.wrappedStorage.set('recently-launched' as CacheKey, []);
  }

  all(key: CacheKey) {
    return this.subcaches[key].all();
  }

  async getLastRecommendationsSyncMs() {
    return this.subcaches[CacheKey.ControlCache].all()[0]
      .lastRecommendationsSyncMs;
  }

  async getLastPeopleRecommendationsSyncMs() {
    return this.subcaches[CacheKey.ControlCache].all()[0]
      .lastPeopleRecommendationsSyncMs;
  }

  async getLastChannelRecommendationsSyncMs() {
    return this.subcaches[CacheKey.ControlCache].all()[0]
      .lastChannelRecommendationsSyncMs;
  }

  async saveRecommendationsSyncedMs() {
    const previousMetadata = this.subcaches[CacheKey.ControlCache].all()[0];
    const newMetadata = await this.subcaches[CacheKey.ControlCache].addItems([
      {
        ...previousMetadata,
        lastRecommendationsSyncMs: Date.now(),
      },
    ]);
    await this.wrappedStorage.set(CacheKey.ControlCache, newMetadata);
  }

  async savePeopleRecommendationsSyncedMs() {
    const previousMetadata = this.subcaches[CacheKey.ControlCache].all()[0];
    const newMetadata = await this.subcaches[CacheKey.ControlCache].addItems([
      {
        ...previousMetadata,
        lastPeopleRecommendationsSyncMs: Date.now(),
      },
    ]);
    await this.wrappedStorage.set(CacheKey.ControlCache, newMetadata);
  }

  async saveChannelRecommendationsSyncedMs() {
    const previousMetadata = this.subcaches[CacheKey.ControlCache].all()[0];
    const newMetadata = await this.subcaches[CacheKey.ControlCache].addItems([
      {
        ...previousMetadata,
        lastChannelRecommendationsSyncMs: Date.now(),
      },
    ]);
    await this.wrappedStorage.set(CacheKey.ControlCache, newMetadata);
  }

  async cacheRecommendations(items: Recommendation[]) {
    const recs = await this.subcaches[CacheKey.Recommendations].addItems(items);
    await this.wrappedStorage.set(CacheKey.Recommendations, recs);
  }

  async cachePeopleRecommendations(items: PeopleRecommendation[]) {
    const recs =
      await this.subcaches[CacheKey.PeopleRecommendations].addItems(items);
    await this.wrappedStorage.set(CacheKey.PeopleRecommendations, recs);
  }

  async cacheChannelRecommendations(items: SearchResult[]) {
    const recs =
      await this.subcaches[CacheKey.ChannelRecommendations].addItems(items);
    await this.wrappedStorage.set(CacheKey.ChannelRecommendations, recs);
  }

  async clear(key: CacheKey) {
    this.wrappedStorage.set(key, []);
    this.subcaches[key].clear();
  }

  async tearDown() {
    this.wrappedStorage.clear();
  }

  async cacheRecentQueries(queries: string[]): Promise<void> {
    const searchQueries = queries.map((query) => ({
      uuid: uuidv5(query, TYPEAHEAD_SEARCH_NAMESPACE),
      query: query,
    }));

    return this.subcaches[CacheKey.RecentQueries]
      .addItems(searchQueries)
      .then((records) =>
        this.wrappedStorage.set(CacheKey.RecentQueries, records),
      );
  }

  async remove(previousQuery: PreviousQuery) {
    return this.subcaches[CacheKey.RecentQueries]
      .removeItem(previousQuery)
      .then((records) =>
        this.wrappedStorage.set(CacheKey.RecentQueries, records),
      );
  }

  async registerHit(uuid: string, epochDate: number): Promise<void> {
    const cache = this.subcaches[CacheKey.HitTracking] as HitTrackingCache;
    return cache
      .registerHit(uuid, epochDate)
      .then((records) =>
        this.wrappedStorage.set(CacheKey.HitTracking, records),
      );
  }

  getHits(uuid: string): HitTracking | undefined {
    const hits =
      this.subcaches[CacheKey.HitTracking].all()[0][uuid]?.hits ?? [];

    if (hits.length == 0) {
      return undefined;
    }

    return {
      history: generateClickMap(hits),
      mostRecentMs: hits[hits.length - 1],
    };
  }

  /**
   * Gets 10 most recent URL shortcuts from cache (pre-ordered by recency)
   */
  getURLShortcuts(): readonly URLShortcut[] {
    return this.subcaches[CacheKey.URLShortcuts].all();
  }

  /**
   * Adds recently used URL shortcut to top of cache
   * Removes duplicates if already in cache
   * Adds a timestamp to the shortcut to determine recency
   * @param shortcut
   */
  async addURLShortcutToCache(
    mostRecentURLShortcut: URLShortcut,
  ): Promise<void> {
    const shortcutWithTimestamp = {
      ...mostRecentURLShortcut,
      timestamp: Date.now(),
    };

    return this.subcaches[CacheKey.URLShortcuts]
      .addItems([shortcutWithTimestamp])
      .then((records) =>
        this.wrappedStorage.set(CacheKey.URLShortcuts, records),
      );
  }

  __debug__() {
    const DEBUG = false;
    DEBUG satisfies false; // ensure we don't accidentally commit this as true

    return {
      [CacheKey.RecentQueries]: this.subcaches[CacheKey.RecentQueries].count(),
      [CacheKey.Recommendations]:
        this.subcaches[CacheKey.Recommendations].count(),
      [CacheKey.HitTracking]: this.subcaches[CacheKey.HitTracking].count(),
      [`DEBUG_${CacheKey.RecentQueries}`]: DEBUG
        ? this.subcaches[CacheKey.RecentQueries].all()
        : undefined,
      [`DEBUG_${CacheKey.Recommendations}`]: DEBUG
        ? this.subcaches[CacheKey.Recommendations].all()
        : undefined,
      [`DEBUG_${CacheKey.HitTracking}`]: DEBUG
        ? this.subcaches[CacheKey.HitTracking].all()
        : undefined,
      [`DEBUG_${CacheKey.PeopleRecommendations}`]: DEBUG
        ? this.subcaches[CacheKey.PeopleRecommendations].all()
        : undefined,
      [`DEBUG_${CacheKey.ChannelRecommendations}`]: DEBUG
        ? this.subcaches[CacheKey.ChannelRecommendations].all()
        : undefined,
    };
  }
}
