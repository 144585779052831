import { urlToService } from './urlRulesUtil';

import type { ServiceName } from './urlRulesUtil';

const JIRA_URL = 'https://jira.atlassian.com';

const serviceToIconMap: {
  [key in ServiceName]: string | undefined;
} = {
  '': undefined,
  Airtable: 'airtable.svg',
  Coda: 'coda.svg',
  Confluence: 'confluence.svg',
  Docsend: undefined,
  Dropbox: 'dropbox.svg',
  Figma: 'figma.svg',
  'Google Calendar': 'googlecalendar.svg',
  'Google Docs': 'google_doc.svg',
  'Google Sheets': 'google_sheet.svg',
  'Google Slides': 'google_slide.svg',
  OneDrive: 'onedrive.svg',
  Paper: 'dropboxpaper.svg',
  Spotify: 'spotify.svg',
  'Stack Overflow': 'stackoverflow.svg',
  Twitter: 'x.svg',
};

const serviceToDarkIconMap: {
  [key in ServiceName]: string | undefined;
} = {
  '': undefined,
  Airtable: 'airtable.svg',
  Coda: 'coda.svg',
  Confluence: 'confluence.svg',
  Docsend: undefined,
  Dropbox: 'dropbox.svg',
  Figma: 'figma.svg',
  'Google Calendar': 'googlecalendar.svg',
  'Google Docs': 'google_doc_dark.svg',
  'Google Sheets': 'google_sheet_dark.svg',
  'Google Slides': 'google_slide_dark.svg',
  OneDrive: 'onedrive.svg',
  Paper: 'dropboxpaper_dark.svg',
  Spotify: 'spotify.svg',
  'Stack Overflow': 'stackoverflow.svg',
  Twitter: 'x.svg',
};

export const serviceFaviconSrcForSrcUrl = (
  srcUrl: string,
  isDarkMode: boolean = false,
): string | undefined => {
  const service = urlToService(srcUrl);
  if (
    isDarkMode &&
    service in serviceToDarkIconMap &&
    serviceToDarkIconMap[service]
  ) {
    return serviceToDarkIconMap[service];
  }

  // fallback to light
  if (service in serviceToIconMap && serviceToIconMap[service]) {
    return serviceToIconMap[service];
  }
  return undefined;
};

export const internalDropboxFavicon = (srcUrl: string): string | null => {
  // fuse universal
  if (/https:\/\/.*fuseuniversal\.com.*/.test(srcUrl)) {
    return 'https://dropbox.com';
  }

  // check for sourcegraph
  if (
    /https:\/\/.*\.sourcegraphcloud\.com.*/.test(srcUrl) ||
    /https:\/\/.*sourcegraph\.pp\.dropbox\.com.*/.test(srcUrl)
  ) {
    return 'https://sourcegraph.com';
  }

  // check for grafana
  if (/https:\/\/.*grafana\.pp\.dropbox\.com.*/.test(srcUrl)) {
    return 'https://grafana.com';
  }

  // check for superset
  if (/https:\/\/.*superset\.pp\.dropbox\.com.*/.test(srcUrl)) {
    return 'https://superset.apache.org';
  }

  // check for service now
  if (/https:\/\/.*service-now\.com.*/.test(srcUrl)) {
    return 'https://service-now.com';
  }

  // check for amplitude
  if (/https:\/\/.*amplitude\.com.*/.test(srcUrl)) {
    return 'https://amplitude.com';
  }

  if (/https:\/\/.*monday\.com.*/.test(srcUrl)) {
    return 'https://monday.com';
  }

  if (/https:\/\/.*pagerduty\.com.*/.test(srcUrl)) {
    return 'https://pagerduty.com';
  }

  if (/https:\/\/.*slack\.com.*/.test(srcUrl)) {
    return 'https://slack.com';
  }

  if (
    /https:\/\/.*tableau\.com.*/.test(srcUrl) ||
    /https:\/\/.*tableau\.[\w-]+\.net.*/.test(srcUrl)
  ) {
    return 'https://tableau.com';
  }

  return null;
};

export const faviconSrcForSrcUrl = (
  srcUrl: string,
  size?: number,
  isDarkMode?: boolean,
): string => {
  let preferredUrl = srcUrl;

  // Check for Jira
  if (/https:\/\/jira\.[A-Za-z0-9_]*\.net.*/.test(srcUrl)) {
    preferredUrl = JIRA_URL;
  }

  // binder
  if (/https:\/\/.*app\.dropboxer\.net*/.test(srcUrl)) {
    return 'https://app.dropboxer.net/static/binder/images/binder-32.png';
  }

  const serviceFavicon = serviceFaviconSrcForSrcUrl(srcUrl, isDarkMode);
  if (serviceFavicon) {
    return `https://cfl.dropboxstatic.com/static/go/src/dropbox/dash/connectors/metadata/icons/${serviceFavicon}`;
  }

  const dropboxUrl = internalDropboxFavicon(srcUrl);
  if (dropboxUrl) {
    preferredUrl = dropboxUrl;
  }

  // domain_url seems to have better results than domain
  // This works for wd5.myworkday.com while domain does not
  return `https://s2.googleusercontent.com/s2/favicons?sz=${
    size ?? 32
  }&domain_url=${encodeURI(preferredUrl)}`;
};
