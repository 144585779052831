import { useEffect, useState } from 'react';
import { useFLIPHeight } from './internal/useFLIPHeight';
import { useFLIPOrchestrator } from './useFLIPOrchestrator';

/**
 * A container that will gracefully handle changes to its height and the y positions of its
 * children.
 */
export const VerticalExpandable: React.FC<
  Omit<React.HTMLAttributes<HTMLElement>, 'ref'>
> = (props) => {
  const orchestrator = useFLIPOrchestrator();
  const [el, setEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!el) return;

    return orchestrator.register(el);
  }, [el, orchestrator]);

  useFLIPHeight(el);

  return <div ref={setEl} {...props} />;
};
