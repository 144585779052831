import { Button } from '@dropbox/dig-components/buttons';
import { Snackbar } from '@dropbox/dig-components/snackbar';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { FailLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { AiStorageMini } from '@dropbox/dig-illustrations';
import { useFeatureFlagValueBool } from '@mirage/service-experimentation/useFeatureFlagValue';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { type FileError, processFile } from '../../utils/fileProcessing';
import styles from './AddSourcesModal.module.css';
import { ComposeSources } from './ComposeSources';
import { ComposeUploadFileButton } from './ComposeUploadFileButton';

import type { SourcesContentCache } from '../../data/ComposeSourcesCache';
import type { ChatSource } from '../../types';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';

export interface AddSourcesContentProps {
  sources: ChatSource[];
  sourcesContentCache: SourcesContentCache;
  addSource: (source: ChatSource) => void;
  removeSource: (source: ChatSource) => void;
  onRequestClose: () => void;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: {
      actionSurfaceComponent?: ActionSurfaceComponent;
    },
  ) => void;
  className?: string;
  showTitle?: boolean;
  enableUploads?: boolean;
  customAddedSourcesSubtitle?: string;
}

export const AddSourcesContent = memo(
  ({
    sources,
    sourcesContentCache,
    addSource,
    removeSource,
    onRequestClose,
    logComposeEvent,
    className,
    showTitle = true,
    enableUploads = true,
    customAddedSourcesSubtitle,
  }: AddSourcesContentProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const dragCounter = useRef(0);
    const dragLeaveTimeout = useRef<NodeJS.Timeout>();
    const [error, setError] = useState<FileError>(null);
    const [uploadSnackbarOpen, setUploadSnackbarOpen] = useState(false);
    const uploadsEnabled =
      useFeatureFlagValueBool('dash_2025_01_24_assist_direct_uploads') &&
      enableUploads;

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onRequestClose();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [onRequestClose]);

    const handleDragEnter = (e: React.DragEvent) => {
      e.preventDefault();
      dragCounter.current += 1;
      if (dragLeaveTimeout.current) {
        clearTimeout(dragLeaveTimeout.current);
      }
      setIsDraggingOver(true);
    };

    const handleDragOver = (e: React.DragEvent) => {
      e.preventDefault();
      setIsDraggingOver(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
      e.preventDefault();
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        dragLeaveTimeout.current = setTimeout(() => {
          setIsDraggingOver(false);
        }, 50);
      }
    };

    const handleDrop = async (e: React.DragEvent) => {
      e.preventDefault();
      dragCounter.current = 0;
      setIsDraggingOver(false);
      if (dragLeaveTimeout.current) {
        clearTimeout(dragLeaveTimeout.current);
      }
      const files = Array.from(e.dataTransfer.files);
      if (files.length === 0) return;
      const file = files[0];
      const error = await processFile(file, addSource, logComposeEvent);
      if (error) {
        setError(error);
      }
    };

    return (
      <div
        ref={contentRef}
        className={classnames(
          styles.AddSourcesModalContent,
          isDraggingOver && styles.AddSourcesModalBodyDragging,
          className,
        )}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {showTitle && (
          <>
            <Title
              size="medium"
              className={styles.AddSourcesModalTitle}
              weightVariant="emphasized"
            >
              {i18n.t('compose_sources_header')}
            </Title>
            <Text
              size="medium"
              tagName="div"
              color="subtle"
              className={styles.AddSourcesModalDescription}
            >
              {i18n.t('compose_sources_subheader')}
            </Text>
          </>
        )}
        <div className={styles.AddSourcesModalControls}>
          <ComposeSources
            sources={sources}
            sourcesContentCache={sourcesContentCache}
            onAddSource={addSource}
            onRemoveSource={removeSource}
            logComposeEvent={logComposeEvent}
            hasBorder
            hideSearchResultsLabel
            uploadButton={
              uploadsEnabled && (
                <ComposeUploadFileButton
                  onAddSource={(s: ChatSource) => {
                    addSource(s);
                    setUploadSnackbarOpen(true);
                  }}
                  logComposeEvent={logComposeEvent}
                />
              )
            }
            emptyStateTitle={
              uploadsEnabled
                ? i18n.t('compose_sources_empty_state_sources_title')
                : undefined
            }
            sourcesTitleLabel={
              <>
                <Text size="medium" tagName="div" color="standard" isBold>
                  {i18n.t('compose_settings_reference_label')}
                </Text>
                {customAddedSourcesSubtitle && (
                  <Text size="medium" tagName="div" color="subtle">
                    {customAddedSourcesSubtitle}
                  </Text>
                )}
              </>
            }
            searchTitleLabel={
              <Text
                size="medium"
                tagName="div"
                color="standard"
                isBold
                className={styles.AddSourcesModalSourcesInputLabel}
              >
                {i18n.t('compose_settings_reference_description')}
              </Text>
            }
          />
        </div>
        {uploadSnackbarOpen && (
          <Snackbar.Position>
            <Snackbar
              open
              preferComposition
              timeout={5000}
              onRequestClose={() => {
                setUploadSnackbarOpen(false);
              }}
            >
              <Snackbar.Content>
                <Snackbar.Accessory>
                  <UIIcon src={FailLine} />
                </Snackbar.Accessory>
                <Snackbar.Message>
                  {i18n.t('compose_assistant_file_uploader_snackbar_message')}
                </Snackbar.Message>
                <Snackbar.Actions>
                  <Button
                    variant="transparent"
                    size="medium"
                    tone="neutral"
                    onClick={() => setUploadSnackbarOpen(false)}
                  >
                    {i18n.t('compose_assistant_file_uploader_snackbar_dismiss')}
                  </Button>
                </Snackbar.Actions>
              </Snackbar.Content>
            </Snackbar>
          </Snackbar.Position>
        )}
        {error && (
          <Snackbar.Position>
            <Snackbar
              open
              preferComposition
              timeout={5000}
              onRequestClose={() => {
                setError(null);
              }}
            >
              <Snackbar.Content>
                <Snackbar.Accessory>
                  <UIIcon src={FailLine} />
                </Snackbar.Accessory>
                <Snackbar.Message>
                  {i18n.t('compose_assistant_file_uploader_error_title')}
                  &nbsp;
                  {error === 'size'
                    ? i18n.t('compose_assistant_file_uploader_error_size')
                    : error === 'type'
                      ? i18n.t('compose_assistant_file_uploader_error_type')
                      : i18n.t(
                          'compose_assistant_file_uploader_error_unexpected',
                        )}
                </Snackbar.Message>
                <Snackbar.Actions>
                  <Button
                    variant="transparent"
                    size="medium"
                    tone="neutral"
                    onClick={() => setError(null)}
                  >
                    {i18n.t('dismiss')}
                  </Button>
                </Snackbar.Actions>
              </Snackbar.Content>
            </Snackbar>
          </Snackbar.Position>
        )}
        {isDraggingOver && (
          <div className={styles.AddSourcesModalDragOverlay}>
            <AiStorageMini
              altText=""
              className={styles.AddSourcesModalDragIcon}
            />
            <Text className={styles.AddSourcesModalDragTitle}>
              {i18n.t('compose_assistant_file_uploader_dnd_title')}
            </Text>
            <Text className={styles.AddSourcesModalDragDescription}>
              {i18n.t('compose_assistant_file_uploader_dnd_subtitle')}
            </Text>
            <Text className={styles.AddSourcesModalDragSubtext}>
              {i18n.t('compose_assistant_file_uploader_dnd_text')}
            </Text>
          </div>
        )}
      </div>
    );
  },
);

AddSourcesContent.displayName = 'AddSourcesContent';
