import { createContext, useContext, useEffect, useRef } from 'react';
import { useChecklistActions } from './useChecklistActions';
import { useMarkChecklistItemComplete } from './useMarkChecklistItemComplete';
import useOnboardingChecklist from './useOnboardingChecklist';

import type {
  OnboardingChecklistStep,
  OnboardingChecklistStepPartial,
} from '../types';

export type OnboardingChecklistContextType = {
  checklistItems: OnboardingChecklistStep[] | undefined;
  checklistSteps: OnboardingChecklistStepPartial[];
  progress: number;
  displayed: boolean;
  open: boolean;
  openId: string;
  className?: string;
  setDismissed: () => void;
  setOpen: (open: boolean) => void;
  setOpenId: (moduleId: string) => void;
  openLearnMore: (moduleId: string, uri: string) => void;
  handleShown: () => void;
  markChecklistItemComplete: (moduleId: string) => Promise<boolean | undefined>;
};

const OnboardingChecklistContext = createContext<
  OnboardingChecklistContextType | undefined
>(undefined);

export const OnboardingChecklistProvider: React.FC = ({ children }) => {
  const props = useOnboardingChecklist();

  const { checklistItems, checklistSteps, refreshChecklistItems, setOpen } =
    props;
  const markChecklistItemComplete = useMarkChecklistItemComplete(
    checklistItems,
    checklistSteps,
  );
  const { openLearnMore } = useChecklistActions(
    checklistItems,
    refreshChecklistItems,
    setOpen,
    markChecklistItemComplete,
  );
  const checklistStepsCountRef = useRef(0);

  useEffect(() => {
    const doSetChecklistItems = async () => {
      if (checklistStepsCountRef.current === checklistSteps.length) return;
      checklistStepsCountRef.current = checklistSteps.length;

      await refreshChecklistItems();
    };
    doSetChecklistItems();
  }, [checklistItems, checklistSteps.length, refreshChecklistItems]);

  return (
    <OnboardingChecklistContext.Provider
      value={{
        ...(props as unknown as OnboardingChecklistContextType),
        openLearnMore,
        markChecklistItemComplete,
      }}
    >
      {children}
    </OnboardingChecklistContext.Provider>
  );
};

export const useOnboardingChecklistContext = () =>
  useContext(OnboardingChecklistContext);
