import { Button } from '@dropbox/dig-components/buttons';
import { QueryInput } from '@mirage/conversations/QueryInput';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import styles from './AskQueryInput.module.css';
import { PageStates, usePages } from './useAskQueryInputPages';

import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { MutableRefObject } from 'react';

type AskQueryInputProps = {
  onSubmit: (query: string) => void;
  onChange?: (query: string) => void;
  attachment?: SummarizableResult;
  loading: boolean;
  placeholder?: string;
  setFocusRef: MutableRefObject<(() => void) | undefined>;
};

export const AskQueryInput = ({
  onSubmit,
  onChange,
  loading,
  placeholder,
  setFocusRef,
}: AskQueryInputProps) => {
  const { pageState, setClean, setDirty, setInit } = usePages();
  const inputRef = useRef<() => void>();

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (setFocusRef && pageState === PageStates.Init) {
      setFocusRef.current = () => buttonRef.current?.focus();
      buttonRef.current?.focus();
    } else if (setFocusRef && pageState !== PageStates.Init) {
      inputRef.current?.();
    }
  }, [setFocusRef, pageState, loading]);

  const handleAskNewQuestion = (queryString: string) => {
    setInit();
    onSubmit(queryString);
  };

  const handleOnChange = (queryString: string) => {
    if (queryString) {
      if (pageState !== PageStates.Dirty) setDirty();
    } else {
      setClean();
    }
    if (onChange) onChange(queryString);
  };

  useEffect(() => {
    if (pageState !== PageStates.Init) setInit();
  }, []);

  return (
    <div className={styles.container}>
      {pageState === PageStates.Init && (
        <div>
          <Button
            ref={buttonRef}
            variant="primary"
            onClick={setClean}
            disabled={loading}
          >
            {i18n.t('doc_summary_qna_ask_new_question')}
          </Button>
        </div>
      )}
      {pageState !== PageStates.Init && (
        <QueryInput
          onSubmit={handleAskNewQuestion}
          onChange={handleOnChange}
          loading={loading}
          placeholder={placeholder}
          setFocusRef={inputRef}
          cancel={setInit}
          reset={setClean}
        />
      )}
    </div>
  );
};
