import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo } from 'react';
import styles from './AnswerCitationCardsGrid.module.css';

import type { ReactNode } from 'react';

interface AnswerCitationCardsGridProps {
  singleRow: boolean;
  cards: ReactNode;
}
export const AnswerCitationCardsGrid = memo(
  ({ singleRow, cards }: AnswerCitationCardsGridProps) => {
    return (
      <>
        <Text
          size="medium"
          color="subtle"
          className={styles.AnswerCitationCardsTitle}
        >
          {i18n.t('answers_citations_title')}
        </Text>
        <div
          className={classNames(styles.AnswerCitationCardsGrid, {
            [styles.singleRow]: singleRow,
          })}
        >
          {cards}
        </div>
      </>
    );
  },
);
AnswerCitationCardsGrid.displayName = 'AnswerCitationCardsGrid';
