import {
  getOnboardingSurveyQuestions,
  getOnboardingSurveyResponse,
  submitOnboardingSurvey,
} from '@mirage/service-onboarding-surveys';
import { useCallback, useEffect, useState } from 'react';

import type { OnboardingSurveyId } from '@mirage/service-onboarding-surveys/service';
import type {
  OnboardingSurveyAnswer,
  OnboardingSurveyQuestion,
  OnboardingSurveyQuestionId,
} from '@mirage/service-onboarding-surveys/service/types';

export const useOnboardingSurveys = (surveyId?: OnboardingSurveyId) => {
  const [questions, setQuestions] = useState<OnboardingSurveyQuestion[]>([]);
  const [surveyResponses, setSurveyResponses] = useState<
    OnboardingSurveyAnswer[]
  >([]);

  const getGroupedSurveyQuestions = (
    surveyQuestions: OnboardingSurveyQuestion[],
  ) => {
    // Return an array of two arrays where:
    // - the first element is an array of team questions
    // - the second element is an array of non-team questions
    return surveyQuestions.reduce<
      [OnboardingSurveyQuestionId[], OnboardingSurveyQuestionId[]]
    >(
      (acc, { questionId, isTeamBased }) => {
        acc[isTeamBased ? 1 : 0].push(questionId);
        return acc;
      },
      [[], []],
    );
  };

  const getResponsesFromQuestions = (
    responseMap: Map<string | undefined, OnboardingSurveyAnswer>,
    questions: OnboardingSurveyQuestionId[],
  ) => {
    return questions
      .map((questionId) => responseMap.get(questionId))
      .filter(Boolean) as OnboardingSurveyAnswer[];
  };

  const getGroupedSurveyResponses = useCallback(() => {
    const [userBasedQuestions, teamBasedQuestions] =
      getGroupedSurveyQuestions(questions);

    // Convert surveyResponses into a Map for O(1) lookups
    const responseMap = new Map(
      surveyResponses.map((response) => [response.surveyQuestionId, response]),
    );

    // Filter responses based on user/team-based questions
    const userBasedResponses = getResponsesFromQuestions(
      responseMap,
      userBasedQuestions,
    );
    const teamBasedResponses = getResponsesFromQuestions(
      responseMap,
      teamBasedQuestions,
    );

    return { userBasedResponses, teamBasedResponses };
  }, [questions, surveyResponses]);

  useEffect(() => {
    const fetchSurveyData = async () => {
      if (!surveyId) return;
      const fetchedQuestions =
        (await getOnboardingSurveyQuestions(surveyId)) ?? [];

      if (fetchedQuestions.length === 0) {
        setQuestions([]);
        return; // No need to fetch answers if no questions exist
      }
      const [userBasedQuestions, teamBasedQuestions] =
        getGroupedSurveyQuestions(fetchedQuestions);

      const [userResponses = [], teamResponses = []] = await Promise.all([
        getOnboardingSurveyResponse(surveyId, userBasedQuestions, false),
        teamBasedQuestions.length
          ? getOnboardingSurveyResponse(surveyId, teamBasedQuestions, true)
          : Promise.resolve([]),
      ]);

      let values = [...userResponses, ...teamResponses];

      if (surveyId === 'ADMIN_PROFILING_V1') {
        // Create a set of questionIds that have already been answered in team-based questions
        const answeredTeamQuestions = new Set(
          teamResponses
            ?.filter((response) => response.surveyAnswer) // Only keep answered ones
            .map((response) => response.surveyQuestionId), // Extract questionId
        );

        // Remove already answered team-based questions from the fetchedQuestions collection
        const filteredQuestions = fetchedQuestions.filter(
          (question) =>
            !(
              question.isTeamBased &&
              answeredTeamQuestions.has(question.questionId)
            ),
        );

        // Remove values that correspond to removed questions
        values = values.filter((value) =>
          filteredQuestions.some(
            (q) => q.questionId === value.surveyQuestionId,
          ),
        );
        setQuestions(filteredQuestions);
        setSurveyResponses(values);
        return;
      }

      setQuestions(fetchedQuestions);
      setSurveyResponses(values);
    };

    fetchSurveyData();
  }, [surveyId]);

  const submitSurvey = useCallback(
    async (
      answers: OnboardingSurveyAnswer[],
      overrideSurveyId?: OnboardingSurveyId,
    ) => {
      setSurveyResponses(answers);

      const [userBasedQuestions, teamBasedQuestions] =
        getGroupedSurveyQuestions(questions);

      const filterAnswersByQuestions = (
        questionIds: OnboardingSurveyQuestionId[],
      ) =>
        answers.filter(({ surveyQuestionId }) =>
          questionIds.includes(surveyQuestionId as OnboardingSurveyQuestionId),
        );

      const userBasedAnswers = filterAnswersByQuestions(userBasedQuestions);
      const teamBasedAnswers = filterAnswersByQuestions(teamBasedQuestions);
      const submissionSurveyId = overrideSurveyId ?? surveyId;

      if (!submissionSurveyId) return;

      const results = await Promise.all([
        userBasedAnswers.length
          ? submitOnboardingSurvey(submissionSurveyId, userBasedAnswers, false)
          : Promise.resolve(true),
        teamBasedAnswers.length
          ? submitOnboardingSurvey(submissionSurveyId, teamBasedAnswers, true)
          : Promise.resolve(true),
      ]);

      return results.every(Boolean);
    },
    [questions, surveyId],
  );

  return {
    questions,
    surveyResponses,
    getGroupedSurveyResponses,
    submitSurvey,
  };
};
