import { BoltDriver } from '@mirage/bolt/driver';
import { createChannelStatesFromBoltData } from '@mirage/bolt/utils';
import { tagged } from '@mirage/service-logging';

import type { comments2 } from '@dropbox/api-v2-client';
import type { BoltData } from '@mirage/bolt/utils';

const logger = tagged('comments-bolt');

// For now we'll support a single channel, intended to be set alongside the initial request for
// comments on a stream. In the future, this can be expanded to support multiple channels to keep
// comments fresh for recently visited streams entities.
export class CommentsBolt {
  private readonly boltDriver: BoltDriver;

  public constructor(
    boltOrigin: string,
    isDev: boolean,
    isTest: boolean,
    private readonly performSync: () => Promise<void>,
  ) {
    this.boltDriver = new BoltDriver(
      boltOrigin,
      isDev ? (s) => logger.debug('[Comments Bolt Driver]', s) : () => {},
      isTest,
    );
  }

  // Start listening for bolt events on the supplied comment stream
  public async subscribe(streamBoltInfo: comments2.BoltInfo) {
    // If the bolt driver is already started, just update the channel states
    if (this.boltDriver.started) {
      this.setBoltData(streamBoltInfo);

      return;
    }

    // Otherwise, set the channel states and start the bolt driver
    this.setBoltData(streamBoltInfo);
    this.boltDriver.startListening(
      () => this.performSync(),
      () => this.performSync(),
    );
  }

  // Map the bolt info from the comments2 api to the bolt data used by the bolt driver
  private static transformBoltInfo(boltInfo: comments2.BoltInfo): BoltData {
    return {
      boltToken: boltInfo.token,
      boltUniqueId: boltInfo.unique_id,
      revision: Number(boltInfo.revision),
    };
  }

  // Transform comments2 bolt info to bolt data and set the channel states on the bolt driver
  public setBoltData(boltInfo: comments2.BoltInfo) {
    this.boltDriver.setChannelStates(
      createChannelStatesFromBoltData(boltInfo.app_id, [
        CommentsBolt.transformBoltInfo(boltInfo),
      ]),
    );
  }

  public stop() {
    this.boltDriver.stopListening();
  }
}
