import { tagged } from '@mirage/service-logging';
import { buildAuthorInfo } from '../utils';

import type { APIv2Callable } from '..';
import type { dash } from '@dropbox/api-v2-client';
import type { AuthorInfo } from '@mirage/shared/search/search-result';

const logger = tagged('dcsTypeaheadRecommendations');

export type PeopleRecommendation = AuthorInfo & {
  lastClickedMs: number | null;
};

export type PeopleRecommendations = {
  people: PeopleRecommendation[];
  cursor?: number;
  hasMore?: boolean;
};

export type PeopleRecommendationsRequestOptions = {
  limit?: number;
  cursor?: number;
};

export type FullAuthor = dash.Author & {
  last_clicked_ms: number | null;
};

export async function getPeopleRecommendations(
  api: APIv2Callable,
  options: PeopleRecommendationsRequestOptions,
): Promise<PeopleRecommendations> {
  const fallbackResponse: PeopleRecommendations = {
    people: [],
    cursor: undefined,
    hasMore: false,
  };

  try {
    const response = await api('dcsGetPeopleRecommendations', options);

    if (!response.people) {
      return fallbackResponse;
    }

    return {
      people: response.people
        .map((person) => buildAuthorInfo(person as FullAuthor))
        .filter((person): person is PeopleRecommendation => Boolean(person)),
      cursor: undefined,
      hasMore: false,
    };
  } catch (error) {
    logger.error(`Error getting people recommendations`, error);
    return fallbackResponse;
  }
}

export function toMiragePeopleRecommendation(
  person: FullAuthor,
): PeopleRecommendation {
  const lastClickedMs = person.last_clicked_ms ?? null;

  return {
    ...buildAuthorInfo(person),
    lastClickedMs,
  };
}
