import { Metadata, StackIcon } from '@dropbox/dash-component-library';
import { Text } from '@dropbox/dig-components/typography';
import { Stack } from '@dropbox/dig-foundations';
import { getInitials } from '@mirage/shared/account';
import { getTimeAgoString } from '@mirage/shared/util/time';

import type { WorkflowAgent } from '@mirage/service-conversation/types';

export const WorkflowAgentMessageHeader = ({
  agent,
}: {
  agent: WorkflowAgent;
}) => {
  const creator = agent.createdBy || {
    display_name: 'Unknown',
    profile_photo_url: undefined,
    email: 'unknown',
  };

  return (
    <Stack display="flex" align="center" gap="Macro Small">
      <StackIcon icon={agent.icon} variant="icon" size="xlarge" />
      <Stack display="flex" align="center">
        <Text isBold size="xlarge">
          {agent.name}
        </Text>
        <Metadata>
          <Metadata.Item>
            <Metadata.Avatar src={creator.profile_photo_url}>
              <Metadata.Label>
                {getInitials(creator.display_name)}
              </Metadata.Label>
            </Metadata.Avatar>
            <Metadata.Label>{creator.display_name}</Metadata.Label>
          </Metadata.Item>
          <Metadata.Item>
            <Metadata.Label>
              {getTimeAgoString(agent.createdAtMs)}
            </Metadata.Label>
          </Metadata.Item>
        </Metadata>
      </Stack>
    </Stack>
  );
};
