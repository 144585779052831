import { useCallback, useEffect, useState } from 'react';

// NOTE: Loading 3rd party pdf files may result in CORS issues
export async function getPdfFileFromUrl(
  pdfUrl: string,
  fileName: string,
): Promise<{ file: File | null; error: string | null }> {
  try {
    const response = await fetch(pdfUrl);

    if (!response.ok) {
      return { file: null, error: `HTTP error! status: ${response.status}` };
    }

    const blob = await response.blob();
    const file = new File([blob], fileName, { type: 'application/pdf' });
    return { file, error: null };
  } catch (error) {
    return { file: null, error: `Error exporting PDF to blob: ${error}` };
  }
}

export const usePdfFileFromUrl = (pdfUrl: string, fileName: string) => {
  const [file, setPdfFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchPdfFile = useCallback(async () => {
    const { file, error } = await getPdfFileFromUrl(pdfUrl, fileName);
    setPdfFile(file);
    setError(error);
  }, [pdfUrl, fileName]);

  useEffect(() => {
    fetchPdfFile();
  }, [fetchPdfFile]);

  return { file, error };
};
