import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import Sentry from '@mirage/shared/sentry';

import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { ConsolaInstance } from 'consola';

export type Service = {
  provisionFreeDashTeam(teamName: string): Promise<boolean>;
};

interface DbxApiServiceContract {
  callApiV2: APIv2Callable;
}

interface LoggingServiceContract {
  tagged: (tag: string) => ConsolaInstance;
}

export default function service(
  { callApiV2 }: DbxApiServiceContract,
  { tagged }: LoggingServiceContract,
) {
  const logger = tagged('service-provisioning');

  async function provisionFreeDashTeam(teamName: string): Promise<boolean> {
    try {
      const response = await callApiV2('subscriptionsStartFreeSubscription', {
        subscription_type: { '.tag': 'team' },
        team_properties: {
          team_name: teamName,
          product_line: { '.tag': 'multi_dash' },
        },
      });
      return response.status === 'ok';
    } catch (error) {
      logger.error(
        `provisionFreeDashTeam - Failed to start free subscription for team "${teamName}"`,
      );
      Sentry.captureException(error);
      return false;
    }
  }

  return services.provide<Service>(
    ServiceId.PROVISIONING,
    {
      provisionFreeDashTeam,
    },
    [ServiceId.DBX_API],
  );
}
