import { forwardRef, useImperativeHandle, useRef } from 'react';
import { OnboardingCompanyInfoStep } from './OnboardingCompanyInfoStep';
import { OnboardingConnectorsStep } from './OnboardingConnectorsStep';
import { OnboardingDownloadUpsellStep } from './OnboardingDownloadUpsellStep';
import { OnboardingJobsToBeDoneStep } from './OnboardingJobsToBeDoneStep';
import { OnboardingLargeTeams } from './OnboardingLargeTeams';
import { OnboardingProfilingStep } from './OnboardingProfilingStep';
import OnboardingTeamInviteStep from './OnboardingTeamInviteStep';
import { SelfServeOnboardingErrorPage } from './SelfServeOnboardingErrorPage';
import { SelfServeOnboardingStep } from './types';

type SelfServeOnboardingStepProps = {
  step: SelfServeOnboardingStep | undefined;
  isAdmin: boolean;
  onValidationChange: (
    valid: boolean,
    overrideStep?: SelfServeOnboardingStep,
  ) => void;
  onProvisionDash?: (isErrorPage?: boolean) => Promise<boolean>;
  onContinue: () => void;
};

export const SelfServeOnboardingItem = forwardRef(
  (
    {
      step,
      isAdmin,
      onValidationChange,
      onContinue,
      onProvisionDash = () => Promise.resolve(true),
    }: SelfServeOnboardingStepProps,
    ref,
  ) => {
    const onboardingStepRef = useRef();

    useImperativeHandle(ref, () => ({
      submitSurvey: () => {
        if (
          !onboardingStepRef.current ||
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !(onboardingStepRef.current as any).submitSurvey
        )
          return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (onboardingStepRef.current as any).submitSurvey();
      },
    }));

    switch (step) {
      case SelfServeOnboardingStep.COMPANY_INFO:
        return (
          <OnboardingCompanyInfoStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
            ref={onboardingStepRef}
          />
        );
      case SelfServeOnboardingStep.PROFILING:
        return (
          <OnboardingProfilingStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
            ref={onboardingStepRef}
          />
        );
      case SelfServeOnboardingStep.JTBD:
        return (
          <OnboardingJobsToBeDoneStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
            onProvisionDash={() => onProvisionDash(false)}
            ref={onboardingStepRef}
          />
        );
      case SelfServeOnboardingStep.CONNECTORS:
        return (
          <OnboardingConnectorsStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
            onContinue={onContinue}
            ref={onboardingStepRef}
          />
        );
      case SelfServeOnboardingStep.TEAM_INVITE:
        return (
          <OnboardingTeamInviteStep
            onValidationChange={onValidationChange}
            isAdmin={isAdmin}
            onContinue={onContinue}
            ref={onboardingStepRef}
          />
        );
      case SelfServeOnboardingStep.DOWNLOAD_UPSELL:
        return <OnboardingDownloadUpsellStep isAdmin={isAdmin} />;
      case SelfServeOnboardingStep.LARGE_TEAM:
        return <OnboardingLargeTeams />;
      case SelfServeOnboardingStep.ERROR:
        return (
          <SelfServeOnboardingErrorPage
            onProvisionDash={() => onProvisionDash(true)}
          />
        );
      default:
        return null;
    }
  },
);

SelfServeOnboardingItem.displayName = 'SelfServeOnboardingItem';
