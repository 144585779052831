import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';

import type { ReactElement } from 'react';

const modalOpenAtom = atom<boolean>(false);
const modalContentAtom = atom<ReactElement | null>(null);

export const ModalPortal = () => {
  const modalContent = useAtomValue(modalContentAtom);
  const modalOpen = useAtomValue(modalOpenAtom);

  return modalOpen && modalContent
    ? createPortal(modalContent, document.body)
    : null;
};

export const useModal = () => {
  const setModalContent = useSetAtom(modalContentAtom);
  const setModalOpen = useSetAtom(modalOpenAtom);

  const showModal = useCallback(
    (modalContent: ReactElement) => {
      setModalContent(modalContent);
      setModalOpen(true);
    },
    [setModalContent, setModalOpen],
  );

  const hideModal = useCallback(() => {
    setModalContent(null);
    setModalOpen(false);
  }, [setModalContent, setModalOpen]);

  return { showModal, hideModal };
};
