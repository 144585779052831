import { fetchMultimediaPreview } from '@mirage/service-dbx-api';
import { useEffect, useState } from 'react';

export function useFetchMultimediaPreview(previewUrl: string, size: number) {
  const [base64, setBase64] = useState<string | undefined>(undefined);
  const [base64Loading, setBase64Loading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // prevent flash between receiving and rendering the base64
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (hasError || base64Loading || base64 || !previewUrl) return;

    const fetchPreview = async () => {
      setBase64Loading(true);
      setImageLoading(true);

      try {
        const base64Preview = await fetchMultimediaPreview(
          `${previewUrl}?size=${size}x${size}&size_mode=7`,
        );

        if (base64Preview?.startsWith('data:text/html')) {
          setHasError(true);
          setImageLoading(false);
        }

        setBase64(base64Preview);
        setBase64Loading(false);
      } catch (e) {
        setHasError(true);
        setBase64Loading(false);
        setImageLoading(false);
      }
    };
    fetchPreview();
  }, [hasError, base64Loading, base64, previewUrl, size]);

  const onImageLoad = () => {
    setTimeout(() => {
      // Allow time for the image to render before removing the skeleton
      setImageLoading(false);
    }, 100);
  };

  return { base64, base64Loading, imageLoading, hasError, onImageLoad };
}
