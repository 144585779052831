import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export const getPublicSharingStatus = async (
  callApiV2: APIv2Callable,
): Promise<{ status: boolean } | undefined> => {
  const response = await callApiV2('stacksGetPublicSharingStatus', {});
  return response.is_public_sharing_allowed !== undefined
    ? { status: response.is_public_sharing_allowed }
    : undefined;
};
