import { Button } from '@dropbox/dig-components/buttons';
// Tooltip provides additional exports that allows for standard styling of rich tooltips without overrides
// eslint-disable-next-line no-restricted-imports
import { Tooltip } from '@dropbox/dig-components/tooltips';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';

type CommentPromptTooltipProps = {
  onDismiss: () => void;
  triggerRef: React.RefObject<HTMLElement | Element>;
};

export const CommentPromptTooltip = ({
  onDismiss,
  triggerRef,
}: CommentPromptTooltipProps) => {
  return (
    <DigTooltip.Control
      auto
      autoHide={false}
      isPortaled
      maxWidth={220}
      // Conditionally rendered
      open={true}
      openDelay={0}
      placement="top"
      triggerRef={triggerRef}
      variant="rich"
    >
      <Tooltip.Title size="medium">
        {i18n.t('stack_comments_prompt_title')}
      </Tooltip.Title>
      <Tooltip.Text size="medium">
        {i18n.t('stack_comments_prompt_body')}
      </Tooltip.Text>
      <Tooltip.Actions>
        <Button onClick={onDismiss} variant="primary">
          {i18n.t('acknowledge_button_cta')}
        </Button>
      </Tooltip.Actions>
    </DigTooltip.Control>
  );
};
