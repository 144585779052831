import { useCallback, useEffect, useState } from 'react';
import useSettings from './useSettings';

import type { CollapsedCardKey } from './service/types';

export function useCollapsedCardSettings(
  cardKey: CollapsedCardKey,
  initialValue?: boolean,
) {
  // Use local state for instant responsiveness in UI.
  const [isCollapsedLocal, setIsCollapsedLocal] = useState<boolean>();

  const { settings, set: setSettings } = useSettings(['collapsedCards']);
  const collapsedCards = settings?.collapsedCards;
  const isCollapsed =
    collapsedCards?.[cardKey] ?? initialValue ?? (settings ? false : undefined);

  useEffect(() => {
    // Note: Don't copy IPC value `isCollapsed` to local state after user made
    // changes because it might interfere with the user experience, and it is
    // not important to sync this setting from other tabs.
    if (isCollapsedLocal === undefined) {
      setIsCollapsedLocal(isCollapsed);
    }
  }, [isCollapsed, isCollapsedLocal]);

  const setCollapsed = useCallback(
    (state: boolean) => {
      setIsCollapsedLocal(state);
      setSettings('collapsedCards', { ...collapsedCards, [cardKey]: state });
    },
    [cardKey, collapsedCards, setSettings],
  );

  return {
    isCollapsed: isCollapsedLocal ?? isCollapsed,
    setCollapsed,
  };
}
