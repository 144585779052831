import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { ONE_MINUTE_IN_MILLIS } from '@mirage/shared/util/constants';
import WithDefaults from '@mirage/storage/with-defaults';
import { EventEmitter } from 'eventemitter3';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { getMetadataForUrls } from './api';

import type { UrlMetadata, UrlMetadataParams } from '../types';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';
import type { KVStorage } from '@mirage/storage';
import type { Observable } from 'rxjs';

const TIME_TO_LIVE = ONE_MINUTE_IN_MILLIS * 5;

export type Service = {
  metadataForUrls(
    urlsParams: UrlMetadataParams[],
  ): Observable<{ [key: string]: UrlMetadata }>;
};

export type StoredUrlMetadata = {
  urlMetadata: { [key: string]: UrlMetadata };
};

type DbxApiServiceContract = {
  callApiV2: APIv2Callable;
};

export default function provideUrlMetadataService(
  rawStorage: KVStorage<StoredUrlMetadata>,
  { callApiV2 }: DbxApiServiceContract,
) {
  const adapter = new WithDefaults(rawStorage, { urlMetadata: {} });
  const emitter = new EventEmitter<{ update: void }>();

  async function refreshNecessaryUrls(urlsParams: UrlMetadataParams[]) {
    const urlsWithOriginal = urlsParams.map(
      (urlParams): [string, UrlMetadataParams] => {
        return [urlParams.url, urlParams];
      },
    );

    const urls = urlsWithOriginal.map(([url]) => url);
    const cachedMetadataUrls = await urlMetadataForUrls(urls);
    const now = Date.now();
    const urlsToUpdate = urlsParams.filter(
      (urlParams) =>
        // If cached metadata is nonexistent or stale, we'll need to fetch it
        !(urlParams.url in cachedMetadataUrls) ||
        now - cachedMetadataUrls[urlParams.url].lastFetched > TIME_TO_LIVE,
    );

    // If we have up to date metadata on all urls, do nothing
    if (urlsToUpdate.length === 0) {
      return;
    }

    // Make the api call
    const newMetadata = await getMetadataForUrls(urlsToUpdate, callApiV2);
    if (!newMetadata?.length) {
      return;
    }

    // Add any new metadata to the map and write it. Then emit an update
    const allPreviousMetadata = await allUrlMetadata();
    let metadataChanged = false;
    for (const metadata of newMetadata) {
      // Check for material changes to the metadata before updating
      metadataChanged ||= !isEqual(
        omit(allPreviousMetadata[metadata.url], 'lastFetched'),
        omit(metadata, 'lastFetched'),
      );

      allPreviousMetadata[metadata.url] = metadata;
    }
    await adapter.set('urlMetadata', allPreviousMetadata);

    // Only emit update if metadata has materially changed
    if (metadataChanged) {
      emitter.emit('update');
    }
  }

  async function allUrlMetadata(): Promise<{ [key: string]: UrlMetadata }> {
    return await adapter.get('urlMetadata');
  }

  async function urlMetadataForUrls(
    urls: string[],
  ): Promise<{ [key: string]: UrlMetadata }> {
    const allMetadata = await allUrlMetadata();
    const filteredMetadata: { [key: string]: UrlMetadata } = {};
    for (const url of urls) {
      if (url in allMetadata) {
        filteredMetadata[url] = allMetadata[url];
      }
    }
    return filteredMetadata;
  }

  function metadataForUrls(
    urlsParams: UrlMetadataParams[],
  ): Observable<{ [key: string]: UrlMetadata }> {
    // Get fresh urls as needed
    refreshNecessaryUrls(urlsParams);
    const urls = urlsParams.map((urlParams) => urlParams.url);

    const changes$ = rx.fromEvent(emitter, 'update') as Observable<void>;
    return rx
      .from(urlMetadataForUrls(urls))
      .pipe(
        op.mergeWith(
          changes$.pipe(op.concatMap(() => urlMetadataForUrls(urls))),
        ),
      );
  }

  return services.provide<Service>(
    ServiceId.URL_METADATA,
    {
      metadataForUrls,
    },
    [ServiceId.DBX_API],
  );
}
