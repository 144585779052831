/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */

import { Button } from '@dropbox/dig-components/buttons';
import { Checkbox } from '@dropbox/dig-components/controls';
import { FormLabel } from '@dropbox/dig-components/form_row';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import {
  disableUxaDevtools,
  enableUxaDevtools,
  isUxaDevtoolsEnabled,
} from '@mirage/analytics/uxa/devtools';
import { useComponentConfig } from '@mirage/component-config';
import { getInstallId, getSessionId } from '@mirage/service-auth';
import { ApiEnvironment } from '@mirage/service-auth/types';
import { useApiEnvironment } from '@mirage/service-auth/useApiEnvironment';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { tagged } from '@mirage/service-logging';
import useSettings from '@mirage/service-settings/useSettings';
import {
  isExtensionInstalled,
  useExtensionConnectionId,
} from '@mirage/shared/util/webExtensionsHelpers';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { additionalDeveloperSettingsAtom } from './atoms/additionalDeveloperSettings';
import styles from './DeveloperSettings.module.css';
import { InternalTests } from './InternalTests';
import { IpcProfiling } from './IpcProfiling';
import { OperationalMetrics } from './OperationalMetrics';
import { RelevanceTools } from './RelevanceTools';

const logger = tagged('DeveloperSettings');

const CheckBoxAndLabelRow: React.FC<{
  checked: boolean;
  onChange: () => void;
  labelText: string;
}> = ({ checked, onChange, labelText }) => {
  const [id, setId] = useState('');

  useEffect(() => {
    setId(uuidv4());
  }, []);

  return (
    <Box display="flex" paddingBottom="16">
      <Checkbox id={id} checked={checked} onChange={onChange} />

      <FormLabel htmlFor={id}>
        &nbsp;&nbsp;
        <Text>{labelText}</Text>
      </FormLabel>
    </Box>
  );
};

export const DeveloperSettings: React.FC = () => {
  const { analyticsLogsOpen, toggleAnalyticsLogs } = useComponentConfig();
  const [openUxaTools, setOpenUxaTools] = useState(isUxaDevtoolsEnabled());
  const { settings, set: setSettings } = useSettings([
    'annotationMode',
    'region',
  ]);
  const annotationMode = !!settings?.annotationMode;
  const { headers } = useAtomValue(additionalDeveloperSettingsAtom);
  const [installId, setInstallId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const extensionInstalled = isExtensionInstalled();
  const connectionId = useExtensionConnectionId();
  const [apiEnvironment, setApiEnvironment] = useApiEnvironment();

  useEffect(() => {
    getInstallId()
      .then(setInstallId)
      .catch((error) => {
        logger.error(`Failed to get install ID: ${error}`);
      });
    getSessionId()
      .then(setSessionId)
      .catch((error) => {
        logger.error(`Failed to get session ID: ${error}`);
      });
  }, []);

  const toggleUxaTools = useCallback(() => {
    const isEnabled = isUxaDevtoolsEnabled();
    if (isEnabled) {
      disableUxaDevtools();
      setOpenUxaTools(false);
    } else {
      enableUxaDevtools();
      setOpenUxaTools(true);
    }
  }, []);

  const toggleAnnotationMode = () => {
    setSettings('annotationMode', annotationMode ? 0 : 1);
  };

  const clearStoredApiEnvironment = () => {
    setApiEnvironment(null);
    logger.info('API environment cleared, reverted to default.');
  };

  const isDesktop = EnvCtx.surface === 'desktop';

  const environmentOptions = [
    { label: 'Production', value: ApiEnvironment.PROD },
    { label: 'Staging', value: ApiEnvironment.STAGE },
    { label: 'Validation', value: ApiEnvironment.VALIDATION },
  ];

  const regionOptions = [
    { label: 'Auto', value: '' },
    { label: 'UK', value: 'lgw' },
    { label: 'JP', value: 'hnd' },
  ];

  return (
    <>
      <Box display="block" paddingBottom="60">
        <Stack gap="12" paddingBottom="12">
          <Box display="block">
            <Text isBold style={{ userSelect: 'text' }}>
              Version: {EnvCtx.version} · Surface: {EnvCtx.surface} · Platform:{' '}
              {EnvCtx.platform} · Build channel: {EnvCtx.buildChannel}
            </Text>
          </Box>
          <Box display="block" style={{ userSelect: 'text' }}>
            <Text isBold style={{ userSelect: 'text' }}>
              Install ID: {installId}
            </Text>
          </Box>
          <Box display="block">
            <Text isBold style={{ userSelect: 'text' }}>
              Session ID: {sessionId}
            </Text>
          </Box>
          {extensionInstalled && (
            <Box display="block">
              <Text isBold style={{ userSelect: 'text' }}>
                Connection ID: {connectionId}
              </Text>
            </Box>
          )}
        </Stack>
        {headers.map((Component, index) => (
          <Box
            key={index}
            display="block"
            paddingBottom="12"
            style={{ userSelect: 'text' }}
          >
            <Component />
          </Box>
        ))}
        <CheckBoxAndLabelRow
          checked={analyticsLogsOpen}
          onChange={toggleAnalyticsLogs}
          labelText="Show Analytics Viewer"
        />
        <CheckBoxAndLabelRow
          checked={openUxaTools}
          onChange={toggleUxaTools}
          labelText="Show UXA Devtool"
        />
        <CheckBoxAndLabelRow
          checked={annotationMode}
          onChange={toggleAnnotationMode}
          labelText="Enable annotation mode"
        />

        <Box
          display="flex"
          alignItems="center"
          className={styles.apiEnvironmentContainer}
        >
          <Text isBold>API Environment: </Text>
          <Menu.Wrapper>
            {({ getTriggerProps, getContentProps }) => (
              <div>
                <Button
                  variant="opacity"
                  {...getTriggerProps()}
                  withDropdownIcon
                >
                  {environmentOptions.find(
                    (option) => option.value === apiEnvironment,
                  )?.label || 'Select Environment'}
                </Button>
                <Menu.Content {...getContentProps()}>
                  <Menu.Segment>
                    {environmentOptions.map((option) => (
                      <Menu.SelectItem
                        key={option.value}
                        onClick={() => setApiEnvironment(option.value)}
                        selected={apiEnvironment === option.value}
                      >
                        {option.label}
                      </Menu.SelectItem>
                    ))}
                  </Menu.Segment>
                </Menu.Content>
              </div>
            )}
          </Menu.Wrapper>
          <Button onClick={clearStoredApiEnvironment} variant="opacity">
            Reset to default environment
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          marginTop="8"
          className={styles.apiEnvironmentContainer}
        >
          <Text isBold>Region: </Text>
          <Menu.Wrapper>
            {({ getTriggerProps, getContentProps }) => (
              <div>
                <Button
                  variant="opacity"
                  {...getTriggerProps()}
                  withDropdownIcon
                >
                  {regionOptions.find(
                    (option) => option.value === settings?.region,
                  )?.label || 'Auto'}
                </Button>
                <Menu.Content {...getContentProps()}>
                  <Menu.Segment>
                    {regionOptions.map((option) => (
                      <Menu.SelectItem
                        key={option.value}
                        onClick={() => setSettings('region', option.value)}
                        selected={settings?.region === option.value}
                      >
                        {option.label}
                      </Menu.SelectItem>
                    ))}
                  </Menu.Segment>
                </Menu.Content>
              </div>
            )}
          </Menu.Wrapper>
        </Box>
        <Box
          display="flex"
          paddingTop="16"
          paddingBottom="16"
          style={{ userSelect: 'text' }}
        >
          <OperationalMetrics />
        </Box>
        <Box display="flex" paddingTop="16" paddingBottom="16">
          <IpcProfiling />
        </Box>
        {isDesktop && (
          <Box display="flex" paddingTop="16" paddingBottom="16">
            <InternalTests />
          </Box>
        )}
        <Box display="flex" paddingTop="16" paddingBottom="16">
          <RelevanceTools />
        </Box>
      </Box>
    </>
  );
};
