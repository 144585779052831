import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { getSourceUUID } from '@mirage/shared/compose/compose-session';
import { getSourceContentFromCache } from './tools/compose-tools';

import type { context_engine } from '@dropbox/api-v2-client/types/dropbox_types';
import type { SourcesContentCache } from '@mirage/mosaics/Chat/data/ComposeSourcesCache';

const logger = tagged('ComposeAssistant/modify-template');

export interface ModifyTemplateParams {
  templateID: string;
  templateContent: string;
  templateSourceContents: SourcesContentCache;
  templateModificationHistory: string[];
}

export type ModifyTemplateResponse = {
  templateContent: string;
  followUpSuggestions: string[];
  draftModifications: string;
  templateName: string;
  templateDescription: string;
};

export async function callModifyTemplateApi(
  params: ModifyTemplateParams,
): Promise<ModifyTemplateResponse> {
  const templateParams = makeCEModifyTemplateParams(params);
  if (!templateParams) {
    throw new Error('Failed to convert template parameters');
  }

  const response = await callApiV2(
    'contextEngineAssistApiModifyTemplate',
    templateParams,
  );

  if (!response.results || response.results.length === 0) {
    throw new Error('No results returned from modify template API');
  }

  const responseMessage = response.results[0].generations![0].chat_message;
  switch (responseMessage?.['.tag']) {
    case 'assistant_message': {
      const responseText = responseMessage.content?.text || '';
      try {
        const modifyTemplateResponse = JSON.parse(responseText);
        return {
          templateContent: modifyTemplateResponse.template_content,
          followUpSuggestions: modifyTemplateResponse.follow_up_suggestions,
          draftModifications: modifyTemplateResponse.draft_modifications,
          templateName: modifyTemplateResponse.template_name,
          templateDescription: modifyTemplateResponse.template_description,
        };
      } catch (error) {
        logger.error('Failed to parse modify template response', error);
        throw new Error('Failed to parse modify template response');
      }
    }
    default:
      throw new Error(
        `Unexpected response message type: ${responseMessage?.['.tag']}`,
      );
  }
}

export function makeCEModifyTemplateParams(
  modifyTemplateParams?: ModifyTemplateParams,
): context_engine.ModifyTemplateArg | null {
  if (!modifyTemplateParams) {
    return null;
  }
  const templateSources: context_engine.SourceContent[] = [];
  for (const cacheContent of Object.values(
    modifyTemplateParams.templateSourceContents,
  )) {
    if (cacheContent.state !== 'loaded') {
      logger.error(
        'unable to load source content',
        getSourceUUID(cacheContent.source),
      );
      continue;
    }
    templateSources.push(getSourceContentFromCache(cacheContent, false));
  }

  return {
    template_id: modifyTemplateParams.templateID,
    template_sources: templateSources,
    template_modification_history:
      modifyTemplateParams.templateModificationHistory,
    template_content: modifyTemplateParams.templateContent,
  };
}
