import {
  ContentIcon,
  getTooltipInCardProps,
} from '@dropbox/dash-component-library';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Dismiss_DashExtensionUpsell } from '@mirage/analytics/events/types/dismiss_dash_extension_upsell';
import { PAP_Shown_DashExtensionUpsell } from '@mirage/analytics/events/types/shown_dash_extension_upsell';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { TileCard } from '@mirage/mosaics/TileCard';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import {
  urlToDomainName,
  urlToService,
} from '@mirage/shared/util/urlRulesUtil';
import i18n, { meticulousRedactedStringClass } from '@mirage/translations';
import { useEffect, useState } from 'react';

import type {
  RecentConnectorContent,
  RecentContent,
  RecentUpsellContent,
} from '@mirage/service-recent-content/types';
import type { CSSProperties } from 'react';

const getRecentTileCardIcon = ({
  content,
  iconSrc,
}: {
  content: RecentContent;
  iconSrc: string;
}) => {
  const icon =
    content.connectorInfo.type === 'connector' ? (
      <FileContentIcon content={content as RecentConnectorContent} />
    ) : (
      <ContentIcon
        icon={<FavIcon src={iconSrc} pureImg />}
        size="medium"
        hasBackground
      />
    );

  return icon;
};

const METADATA_MIN_HEIGHT = 24; // used to ensure recent tile card and stack tile cards are the same height
const RecentMetadataComponent = ({ content }: { content: RecentContent }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const tooltipTextProps = getTooltipInCardProps(isTruncated);
  const serviceName =
    'displayName' in content.connectorInfo
      ? content.connectorInfo.displayName
      : urlToService(content.url) || urlToDomainName(content.url);

  return (
    <Box style={{ minHeight: METADATA_MIN_HEIGHT }}>
      <Text color="subtle" tagName="div" {...tooltipTextProps}>
        <DashTruncateWithTooltip
          lines={1}
          tooltipProps={{
            title: serviceName,
          }}
          onBeforeTruncate={setIsTruncated}
        >
          {serviceName}
        </DashTruncateWithTooltip>
      </Text>
    </Box>
  );
};

export const RecentTileCard = ({
  hidden,
  style,
  content,
  iconSrc,
  handleOnClick,
  selectorClassname,
}: {
  hidden?: boolean;
  style?: CSSProperties;
  content: RecentContent;
  iconSrc: string;
  handleOnClick: (content: RecentContent) => void;
  selectorClassname: string;
}) => {
  const contentIsUpsell = content.connectorInfo.type === 'upsell';
  const iconComponent = contentIsUpsell
    ? (content as RecentUpsellContent).icon
    : getRecentTileCardIcon({ content, iconSrc });
  const withAccessory = contentIsUpsell ? (
    <UpsellDismissButton
      onDismiss={(content as RecentUpsellContent).onDismiss}
    />
  ) : undefined;

  return (
    <TileCard
      // Hide this card from Meticulous recordings
      className={`${selectorClassname} ${meticulousRedactedStringClass}`}
      style={style}
      hidden={hidden}
      onClick={() => handleOnClick(content)}
      visibleUrl={content.url}
      title={content.title}
      titleLinesBeforeTruncate={2}
      icon={iconComponent}
      metadata={<RecentMetadataComponent content={content} />}
      withAccessory={withAccessory}
      withInteractiveAccessory={!!withAccessory}
    />
  );
};

const UpsellDismissButton: React.FC<{ onDismiss: () => void }> = ({
  onDismiss,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  useEffect(() => {
    // As dismiss button is only rendered when there is an upsell,
    // this would be a good place to log shown event
    reportPapEvent(
      PAP_Shown_DashExtensionUpsell({
        featureLine: 'recents',
        actionSurfaceComponent: 'recents',
      }),
    );
  }, [reportPapEvent, PAP_Shown_DashExtensionUpsell]);

  const onClick = () => {
    reportPapEvent(
      PAP_Dismiss_DashExtensionUpsell({
        featureLine: 'recents',
        actionSurfaceComponent: 'recents',
      }),
    );
    onDismiss();
  };

  return (
    <IconButton
      aria-label={i18n.t('dismiss')}
      variant="borderless"
      onClick={onClick}
      type="reset"
    >
      <UIIcon src={CloseLine} />
    </IconButton>
  );
};
