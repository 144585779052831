import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { firstValueFrom } from 'rxjs';

import type { Service } from './service';
import type {
  ConversationMessage,
  NewUserMessage,
  WorkflowAgent,
  WorkflowValues,
} from './types';
import type { Observable } from 'rxjs';

const conversationService = services.get<Service>(ServiceId.CONVERSATION);

export default conversationService;

/**
 * Post a user message to the conversation.
 * @param newMessage The message to post.
 */
export function postUserMessage(newMessage: NewUserMessage): Promise<void> {
  return firstValueFrom(conversationService.postUserMessage(newMessage));
}

/**
 * Observe a conversation. This will emit the full conversation history as it changes.
 * You should replace the entire conversation state when this emits.
 * @param conversationId The ID of the conversation to observe.
 * @returns An observable that emits the full conversation history.
 */
export const observeConversation: (
  conversationId: string,
) => Observable<ConversationMessage[]> =
  conversationService.observeConversation;

/**
 * Append a manual user message to the conversation without submitting it to the API.
 * @param newMessage The new user message to append.
 */
export const appendManualMessage: (
  newMessage: NewUserMessage,
  messageType?: 'userMessage' | 'assistantMessage',
) => Promise<void> = (
  newMessage: NewUserMessage,
  messageType?: 'userMessage' | 'assistantMessage',
) =>
  firstValueFrom(
    conversationService.appendManualMessage(newMessage, messageType),
  );

/**
 * Get workflow values for a specific agent.
 * @param agentId The ID of the agent to retrieve workflow values for.
 * @returns The workflow values for the specified agent, or an empty object if none exist.
 */
export const getWorkflowAgent: (agentId: string) => Promise<WorkflowAgent> = (
  agentId: string,
) => firstValueFrom(conversationService.getWorkflowAgent(agentId));

/**
 * Set workflow values for a specific agent.
 * @param agentId The ID of the agent to set workflow values for.
 * @param values The workflow values to set for the agent.
 */
export const setWorkflowAgent: (
  agentId: string,
  values: WorkflowValues,
) => Promise<WorkflowAgent> = (agentId: string, values: WorkflowValues) =>
  firstValueFrom(conversationService.setWorkflowAgent(agentId, values));

/**
 * Get all workflow agents.
 * @returns An array of all workflow agents.
 */
export const getWorkflowAgents: () => Promise<WorkflowAgent[]> = () =>
  firstValueFrom(conversationService.getWorkflowAgents());

/**
 * Save a workflow agent.
 * @param agent The agent to save.
 */
export const saveWorkflowAgent: (
  agent: WorkflowAgent,
) => Promise<WorkflowAgent> = (agent: WorkflowAgent) =>
  firstValueFrom(conversationService.saveWorkflowAgent(agent));

/**
 * Delete a workflow agent.
 * @param agent The agent to delete.
 */
export const deleteWorkflowAgent: (agent: WorkflowAgent) => Promise<void> = (
  agent: WorkflowAgent,
) => firstValueFrom(conversationService.deleteWorkflowAgent(agent));

/**
 * Load all workflow agents.
 * @returns An array of all workflow agents.
 */
export const loadWorkflowAgents: () => Promise<WorkflowAgent[]> = () =>
  firstValueFrom(conversationService.loadApiWorkflowAgents());
