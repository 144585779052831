import { StackIcon } from '@dropbox/dash-component-library';
import { UIIcon } from '@dropbox/dig-icons';
import { StacksFill } from '@dropbox/dig-icons/dist/mjs/assets';
import { EMOJI_CLEARED_SENTINEL_VALUE } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { getTheme } from '@mirage/stacks/themes';
import React, { useRef } from 'react';

import type { StackIconProps } from '@dropbox/dash-component-library';
import type { DigTooltipProps } from '@mirage/shared/util/DigTooltip';

interface DashStackIconTooltipProps extends DigTooltipProps {
  title: string;
}

interface DashStackIconProps extends Partial<StackIconProps> {
  colorIndex?: number;
  emoji?: string;
  onClick?: () => void;
  tooltipProps?: DashStackIconTooltipProps;
  customColor?: string;
}

export const DashStackIcon: React.FC<DashStackIconProps> = ({
  colorIndex,
  emoji,
  size = 'large',
  onClick,
  tooltipProps,
  customColor,
  ...rest
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isEmoji = emoji && emoji !== EMOJI_CLEARED_SENTINEL_VALUE;
  return (
    <DigTooltip
      openDelay={200}
      title=""
      disabled={!onClick}
      {...tooltipProps}
      triggerRef={ref}
    >
      <span ref={ref}>
        <StackIcon
          size={size}
          theme={getTheme(colorIndex, customColor).primary}
          icon={isEmoji ? emoji : <UIIcon src={StacksFill} />}
          onClick={onClick}
          aria-label={onClick ? tooltipProps?.title : undefined}
          variant={isEmoji ? 'emoji' : 'icon'}
          {...rest}
        />
      </span>
    </DigTooltip>
  );
};
