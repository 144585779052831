import { tagged } from '@mirage/service-logging';
import { namespace } from '.';

import type { Tags } from './service';

const logger = tagged('measure-performance');

export const measurePerformance = ({
  metricNamespace,
  measureName,
  startMark,
  endMark,
  tags,
}: {
  metricNamespace: string;
  measureName: string;
  startMark: string;
  endMark: string;
  tags?: Tags;
}) => {
  try {
    performance.measure(measureName, startMark, endMark);
    const measure = performance.getEntriesByName(measureName).pop();

    const { stats } = namespace(metricNamespace);

    if (measure?.duration) {
      stats(measureName, measure.duration, tags);
    }
  } catch (err) {
    logger.error(
      `Failed to measure performance for metric name: ${measureName}`,
      err,
    );
  }
};
