import { ONE_DAY_IN_SECONDS } from '@mirage/shared/util/constants';
import { nonEmpty } from '@mirage/shared/util/tiny-utils';

import type { stacks } from '@dropbox/api-v2-client';
import type { LargeKvStore } from '@mirage/storage/large-kv-store/interface';

const THIRTY_DAYS_IN_SECONDS = ONE_DAY_IN_SECONDS * 30;
const CACHED_USER_STACKS_KEY = 'DashUserStacks';
const CACHED_USER_BOLT_DATA_KEY = 'DashUserChannel';
const CACHED_PUBLISHED_CONTENTS_KEY = 'DashPublishedContents';

function getCachedUserStackItemKey(namespaceId: string) {
  return 'DashUserStackItem-' + namespaceId;
}

export type CachedUserStacks = {
  stacks: stacks.Stack[];
};

type CachedUserStackItems = {
  namespaceId: string;
  items: stacks.StackItem[];
};

type CachedPublishedContents = {
  contents: stacks.PublishedContent[];
};

/** Persistent storage for stacks data. */
export class StacksStore {
  public constructor(private readonly largeKvStore: LargeKvStore) {}

  public async setUserBoltData(
    boltData: stacks.BoltData | undefined,
  ): Promise<void> {
    if (boltData) {
      await this.largeKvStore.set(
        CACHED_USER_BOLT_DATA_KEY,
        JSON.stringify(boltData),
        THIRTY_DAYS_IN_SECONDS,
      );
    } else {
      await this.largeKvStore.delete(CACHED_USER_BOLT_DATA_KEY);
    }
  }

  public async getUserBoltData(): Promise<stacks.BoltData | undefined> {
    const value = await this.largeKvStore.get(CACHED_USER_BOLT_DATA_KEY);
    if (value) {
      return JSON.parse(value) as stacks.BoltData;
    }
    return undefined;
  }

  public async setStacks(
    stacks: stacks.Stack[] | undefined,
  ): Promise<stacks.Stack[] | undefined> {
    if (stacks) {
      // Dedupe stacks by id.
      const stackById = new Map(
        stacks.map((s) => [
          nonEmpty(s.namespace_id, 'setStacks.namespace_id'),
          s,
        ]),
      );
      stacks = [...stackById.values()];

      const cached: CachedUserStacks = { stacks };
      await this.largeKvStore.set(
        CACHED_USER_STACKS_KEY,
        JSON.stringify(cached),
        THIRTY_DAYS_IN_SECONDS,
      );

      return stacks;
    } else {
      await this.largeKvStore.delete(CACHED_USER_STACKS_KEY);
      return undefined;
    }
  }

  public async getStacks(): Promise<stacks.Stack[] | undefined> {
    const value = await this.largeKvStore.get(CACHED_USER_STACKS_KEY);

    if (value) {
      const cached = JSON.parse(value) as CachedUserStacks;

      // Check stored format and userId.
      if (typeof cached === 'object' && 'stacks' in cached) {
        return cached.stacks;
      }

      await this.setStacks(undefined);
    }

    return undefined;
  }

  public async setStack(stack: stacks.Stack): Promise<stacks.Stack[]> {
    let stacks = await this.getStacks();
    stacks = stacks?.filter((s) => s.namespace_id !== stack.namespace_id) ?? [];
    stacks.push(stack);
    await this.setStacks(stacks);
    return stacks;
  }

  public async getStack(
    namespaceId: string,
  ): Promise<stacks.Stack | undefined> {
    const stacks = await this.getStacks();
    return stacks?.find((s) => s.namespace_id === namespaceId);
  }

  public async removeStack(namespaceId: string): Promise<stacks.Stack[]> {
    let stacks = await this.getStacks();
    stacks = stacks?.filter((s) => s.namespace_id !== namespaceId) ?? [];
    await this.setStacks(stacks);
    return stacks;
  }

  public async setStackItems(
    namespaceId: string,
    items: stacks.StackItem[] | undefined,
  ): Promise<void> {
    const key = getCachedUserStackItemKey(namespaceId);
    if (items) {
      const cached: CachedUserStackItems = { namespaceId, items };
      await this.largeKvStore.set(
        key,
        JSON.stringify(cached),
        THIRTY_DAYS_IN_SECONDS,
      );
    } else {
      await this.largeKvStore.delete(key);
    }
  }

  private isValidStackItem(cached: CachedUserStackItems) {
    return (
      typeof cached === 'object' && 'namespaceId' in cached && 'items' in cached
    );
  }

  public async getStackItems(
    namespaceId: string,
  ): Promise<stacks.StackItem[] | undefined> {
    const key = getCachedUserStackItemKey(namespaceId);
    const value = await this.largeKvStore.get(key);

    if (value) {
      const cached = JSON.parse(value) as CachedUserStackItems;

      if (this.isValidStackItem(cached)) {
        return cached.items;
      }

      await this.setStackItems(namespaceId, undefined);
    }

    return undefined;
  }

  public async multiGetStackItems(
    namespaceIds: string[],
  ): Promise<{ [nsid: string]: stacks.StackItem[] }> {
    const keys = namespaceIds.map((nsId) => getCachedUserStackItemKey(nsId));
    const values = await this.largeKvStore.multiGet(keys);
    const itemsByNsId: { [nsid: string]: stacks.StackItem[] } = {};

    for (let i = 0; i < values.length; i++) {
      const value = values[i];

      if (value) {
        const cached = JSON.parse(value) as CachedUserStackItems;

        if (this.isValidStackItem(cached)) {
          itemsByNsId[cached.namespaceId] = cached.items;
          continue;
        }

        this.setStackItems(namespaceIds[i], undefined);
      }
    }

    return itemsByNsId;
  }

  public async setPublishedContents(
    contents: stacks.PublishedContent[] | undefined,
  ): Promise<stacks.PublishedContent[] | undefined> {
    if (contents) {
      const cached: CachedPublishedContents = { contents };
      await this.largeKvStore.set(
        CACHED_PUBLISHED_CONTENTS_KEY,
        JSON.stringify(cached),
        THIRTY_DAYS_IN_SECONDS,
      );
      return contents;
    } else {
      await this.largeKvStore.delete(CACHED_PUBLISHED_CONTENTS_KEY);
      return undefined;
    }
  }

  public async unpublishStack(
    namespaceId: string,
  ): Promise<stacks.PublishedContent[]> {
    let contents = await this.getPublishedContents();
    contents =
      contents?.filter(
        (c) => c['.tag'] !== 'stack' || c.namespace_id !== namespaceId,
      ) ?? [];
    await this.setPublishedContents(contents);
    return contents;
  }

  public async setPublishedStack(
    stack: stacks.Stack,
  ): Promise<stacks.PublishedContent[]> {
    let contents = await this.getPublishedContents();
    // filter out existing stack if any
    contents =
      contents?.filter(
        (c) => c['.tag'] !== 'stack' || c.namespace_id !== stack.namespace_id,
      ) ?? [];

    const newPublishedContent: stacks.PublishedContent = {
      '.tag': 'stack',
      ...stack,
    };
    contents.push(newPublishedContent);
    await this.setPublishedContents(contents);
    return contents;
  }

  public async getPublishedContents(): Promise<
    stacks.PublishedContent[] | undefined
  > {
    const value = await this.largeKvStore.get(CACHED_PUBLISHED_CONTENTS_KEY);

    if (value) {
      const cached = JSON.parse(value) as CachedPublishedContents;

      if (typeof cached === 'object' && 'contents' in cached) {
        return cached.contents;
      }
      await this.setPublishedContents(undefined);
    }

    return undefined;
  }
}
