import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ActionsMenu } from '../ActionsMenu';
import { HexColorPicker } from '../ColorPicker/HexColorPicker';
import { stackIsArchived } from '../Helpers/Utils';
import { useUpdateStackWithCustomColor } from '../hooks';
import { ArchiveConfirmationModal } from './ArchiveConfirmationModal';
import { CloneConfirmationModal } from './CloningConfirmationModal';
import { useArchiveStack } from './hooks';
import { useCloneStack } from './useCloneStack';

import type { SetMenuOpenForStackId } from '../ActionsMenu';
import type { stacks, users } from '@dropbox/api-v2-client';
import type { MenuPlacement } from '@dropbox/dig-components/menu';

export const ManageStackMenu = ({
  stack,
  iconType,
  currentAccount,
  setMenuOpenForStackId,
  iconColor,
  menuPlacement,
  mutationRequestId,
  onEdit,
}: {
  stack: stacks.Stack | null;
  iconType: 'more' | 'gear';
  currentAccount?: users.FullAccount;
  setMenuOpenForStackId?: SetMenuOpenForStackId;
  iconColor?: string;
  menuPlacement?: MenuPlacement;
  mutationRequestId?: string;
  onEdit?: () => void;
}) => {
  const {
    isArchiveStacksEnabled,
    isArchiveModalOpen,
    setIsArchiveModalOpen,
    toggleArchiveStackStatus,
  } = useArchiveStack(stack);

  const {
    isCloning,
    isCloneStacksEnabled,
    isCloneStackModalOpen,
    setIsCloneStackModalOpen,
    handleCloneStack,
  } = useCloneStack(stack);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const colorPickerButtonRef = useRef(null);
  const updateCustomColor = useUpdateStackWithCustomColor(
    stack,
    mutationRequestId,
  );

  const handleColorPickerClose = () => {
    setIsColorPickerOpen(false);
  };

  const handleUpdateStackCustomColor = (customHexColor: string) => {
    updateCustomColor(customHexColor);
    setIsColorPickerOpen(false);
  };

  useEffect(() => {
    setIsColorPickerOpen(false);
  }, [stack]);

  return (
    <div ref={colorPickerButtonRef}>
      <ActionsMenu
        stack={stack}
        currentAccount={currentAccount}
        onEdit={onEdit}
        onArchive={
          isArchiveStacksEnabled ? () => setIsArchiveModalOpen(true) : undefined
        }
        onClone={
          isCloneStacksEnabled
            ? () => setIsCloneStackModalOpen(true)
            : undefined
        }
        isCloning={isCloning}
        setMenuOpenForStackId={setMenuOpenForStackId}
        iconColor={iconColor}
        menuPlacement={menuPlacement}
        showColorPicker
        mutationRequestId={mutationRequestId}
        useGearIcon={iconType === 'gear'}
        setIsColorPickerOpen={setIsColorPickerOpen}
        onUpdateCustomColor={handleUpdateStackCustomColor}
      />
      {isArchiveStacksEnabled && isArchiveModalOpen && (
        <ArchiveConfirmationModal
          isArchived={stackIsArchived(stack)}
          isOpen
          onConfirm={async () => {
            setIsArchiveModalOpen(false);
            await toggleArchiveStackStatus({});
          }}
          onCancel={() => {
            setIsArchiveModalOpen(false);
          }}
        />
      )}
      {isCloneStacksEnabled && (
        <CloneConfirmationModal
          isOpen={isCloneStackModalOpen}
          onConfirm={async () => {
            await handleCloneStack(uuidv4());
            setIsCloneStackModalOpen(false);
          }}
          onCancel={() => {
            setIsCloneStackModalOpen(false);
          }}
        />
      )}
      <HexColorPicker
        anchorRef={colorPickerButtonRef}
        isPortaled
        onClose={handleColorPickerClose}
        onSave={handleUpdateStackCustomColor}
        open={isColorPickerOpen}
        color={stack?.stack_data?.custom_color}
        key={stack?.namespace_id}
      />
    </div>
  );
};
