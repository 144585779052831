import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { SEARCH_ATTEMPT_END_REASONS } from '@mirage/analytics/session/session-utils';
import { focusSearchInput } from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { useIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import { useActivationForConnectors } from '@mirage/service-connectors/useActivationForConnectors';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { useDualMode } from '@mirage/service-experimentation/useDualMode';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool as convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { tagged } from '@mirage/service-logging';
import useSettings from '@mirage/service-settings/useSettings';
import { search } from '@mirage/service-typeahead-search';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { LOCAL_FILE_CONNECTOR } from '@mirage/shared/connectors';
import { filterableContentTypes } from '@mirage/shared/content-type/content-types';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { getLastUpdatedObjects } from '@mirage/shared/last-updated/last-updated';
import {
  mergeFilterTypes,
  syncFiltersWithQuery,
} from '@mirage/shared/search/search-filters';
import { atom, useAtom } from 'jotai';
import { throttle } from 'lodash';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCalendarEventsForTypeahead } from './useCalendarEventsForTypeahead';
import {
  useListenForTypeaheadFocus,
  useListenForTypeaheadReset,
} from './useListenForTypeaheadReset';
import { useTypeaheadSearchPAPLogging } from './useTypeaheadSearchPAPLogging';

import type { TypeaheadSearchPAPLogging } from './useTypeaheadSearchPAPLogging';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { DashActionSurface } from '@mirage/analytics/events/enums/dash_action_surface';
import type { FeatureLine } from '@mirage/analytics/events/enums/feature_line';
import type { ActivationTypes } from '@mirage/service-connectors/useActivationForConnectors';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

export type TypeaheadSearch = TypeaheadSearchPAPLogging & {
  typeaheadQuery: string;
  canShowTypeahead: boolean;
  setTypeaheadQuery: (query: string, taggedQuery?: boolean) => void;
  setCanShowTypeahead: (canShow: boolean) => void;
  reloadResults: () => void;
  typeaheadResults: typeahead.ScoredResult[];
  activationType?: ActivationTypes;
  performPreLaunchSearch: (
    query: string,
    onComplete: (results: typeahead.ScoredResult[]) => void,
  ) => void;
  filters: SearchFilter[];
  allFilters: SearchFilter[];
};

const logger = tagged('service-typeahead-search/useTypeaheadSearch');

export const TypeaheadQueryAtom = atom<string>('');

export function useTypeaheadSearch({
  isInstanced,
  instanceConfig,
  featureLine,
  actionSurfaceComponent,
  dashActionSurface,
}: {
  isInstanced?: boolean;
  instanceConfig?: typeahead.PerInstanceConfig; // ignored if isInstanced is false
  featureLine: FeatureLine;
  actionSurfaceComponent?: ActionSurfaceComponent;
  dashActionSurface?: DashActionSurface;
}): TypeaheadSearch {
  const { connections, connectors } = useConnectors();
  const settings = useSettings(['localFiles']);
  const { isDualModeLauncher } = useDualMode();
  const calendarEvents = useCalendarEventsForTypeahead();

  const [typeaheadQuery, _setTypeaheadQueryState] =
    useTypeaheadQueryState(isInstanced);
  /**
   * the typeahead query is duplicated as a ref to ensure we can always grab
   * the latest value regardless of what the value was when the performSearch
   * function was invoked
   **/
  const typeaheadQueryRef = useRef('');
  const setTypeaheadQueryState = useCallback(
    (innerTypeaheadQuery: string) => {
      typeaheadQueryRef.current = innerTypeaheadQuery;
      _setTypeaheadQueryState(innerTypeaheadQuery);
    },
    [_setTypeaheadQueryState],
  );

  // Determine if typeahead is eligible to be shown
  // Actual visibility will be determined by other factors like length of results
  const [_canShowTypeahead, setCanShowTypeahead] = useState(false);
  // Keep typeahead always open in the Dual Mode Launcher
  const canShowTypeahead = isDualModeLauncher ? true : _canShowTypeahead;

  const isUsingTypeaheadFiltersFeatureFlag =
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters') === 'ON';
  const isLocalFilesEnabled = Boolean(settings.settings?.localFiles?.enabled);

  const [filters, setFilters] = useState<SearchFilter[]>([]);

  const allFilters = useMemo<SearchFilter[]>(() => {
    const STATIC_CONNECTORS = [];

    if (isLocalFilesEnabled) {
      STATIC_CONNECTORS.push(LOCAL_FILE_CONNECTOR);
    }

    return mergeFilterTypes(
      [...connectors, ...STATIC_CONNECTORS],
      filterableContentTypes,
      getLastUpdatedObjects(),
    );
  }, [connectors, isLocalFilesEnabled]);

  const [results, setResults] = useState<typeahead.ScoredResult[]>([]);
  // Show upcoming calendar events at the top of the Dual Mode Launcher in the null state
  const typeaheadResults =
    isDualModeLauncher && !typeaheadQuery
      ? calendarEvents.concat(results)
      : results;
  const { searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();
  const { createSearchQueryId, clearSearchQueryId } = useSearchQueryId();
  const { activationType } = useActivationForConnectors();

  const resetTypeaheadState = useCallback(() => {
    setTypeaheadQueryState('');
    setCanShowTypeahead(false);
  }, [setTypeaheadQueryState]);
  useListenForTypeaheadReset(resetTypeaheadState);

  const focusTypeahead = useCallback(() => {
    setTypeaheadQueryState('');
    setCanShowTypeahead(true);
    focusSearchInput();
  }, [setTypeaheadQueryState]);
  useListenForTypeaheadFocus(focusTypeahead);

  //
  // build typeahead search config/context
  // ---------------------------------------------------------------------------
  const isDropboxer = useIsDropboxer() || false;
  const isNullStateUsesRecommendations = convertFeatureValueToBool(
    useFeatureFlagValue(
      'dash_2024_07_29_typeahead_null_state_uses_recommendations',
    ),
  );
  const isTypeaheadFiltersEnabled = convertFeatureValueToBool(
    useFeatureFlagValue('dash_2024_09_30_typeahead_filters'),
  );
  const isIncreasedDesktopApplicationScoring = convertFeatureValueToBool(
    useFeatureFlagValue(
      'dash_2024_10_25_increased_desktop_application_scoring',
    ),
  );

  const typeaheadSearchPAPLogging = useTypeaheadSearchPAPLogging(
    typeaheadQuery,
    allFilters,
    results,
    featureLine,
    actionSurfaceComponent,
    dashActionSurface,
  );

  //
  // typeahead search functionality
  // ---------------------------------------------------------------------------
  //

  const config = useMemo(() => {
    const config: typeahead.Config = {
      isDropboxer,
      isTypeaheadFiltersEnabled,
      isNullStateUsesRecommendations,
      connections,
      isIncreasedDesktopApplicationScoring,
      isRecentQueriesEnabled: true,
    };
    if (isInstanced && instanceConfig) {
      Object.assign(config, instanceConfig);
    }
    return config;
  }, [
    connections,
    instanceConfig,
    isDropboxer,
    isIncreasedDesktopApplicationScoring,
    isInstanced,
    isNullStateUsesRecommendations,
    isTypeaheadFiltersEnabled,
  ]);

  const performSearch = useCallback(
    (
      internalActiveQuery: string,
      isDropboxer: boolean,
      onComplete?: (lastEmittedResults: typeahead.ScoredResult[]) => void,
    ) => {
      if (!canShowTypeahead) {
        // this clears out previous results when the typeahead closes, so the
        // next time it opens it'll do a fresh typeahead search instead of
        // showing the old results.
        setResults([]);
        return;
      }

      const results$ = search(internalActiveQuery, config);
      let lastEmittedResults: typeahead.ScoredResult[] = [];
      const accumulatedResults: typeahead.ScoredResult[] = [];
      const subscription = results$.subscribe({
        next: (resultsWithoutUpdatedQuery) => {
          const emittedResults = resultsWithoutUpdatedQuery.map(
            (result: typeahead.ScoredResult) =>
              result.type === typeahead.ResultType.SuggestedQuery
                ? {
                    ...result,
                    result: {
                      ...result.result,
                      // the current query is pulled from a ref to ensure that
                      // we are always grabbing the latest query, not the query
                      // at the time performSearch was invoked
                      query: typeaheadQueryRef.current,
                    },
                  }
                : result,
          );
          logger.debug(`emittedResults`, emittedResults);

          if (typeaheadQuery) {
            const searchQueryUuid = createSearchQueryId();

            typeaheadSearchPAPLogging.onResultsReturned(
              searchQueryUuid,
              internalActiveQuery,
              emittedResults,
            );
          } else {
            clearSearchQueryId();
          }

          lastEmittedResults = emittedResults;
          accumulatedResults.push(...emittedResults);
          setResults(emittedResults);
        },
        error: (e) => {
          logger.error(`typeahead search error`, e);
          setResults([]);
        },
        complete: () => {
          typeaheadSearchPAPLogging.onResultsReturnedComplete(
            internalActiveQuery,
            accumulatedResults,
          );

          onComplete?.(lastEmittedResults);
          logger.debug(`typeahead search complete`);
        },
      });
      return () => subscription.unsubscribe();
    },
    [
      canShowTypeahead,
      typeaheadSearchPAPLogging.onResultsReturned,
      typeaheadSearchPAPLogging.onResultsReturnedComplete,
      config,
    ],
  );
  // using throttle over debounce ensures that the initial search is triggered
  // sooner, the final results will come sooner, and the user will constantly
  // see feedback as they type no matter how fast they're typing
  // and throttling on the search ensures that the update of the SERP CTA is nearly instant
  const performSearchThrottled = useMemo(
    () => throttle(performSearch, 250, { trailing: true, leading: true }),
    [performSearch],
  );

  useEffect(() => {
    return performSearchThrottled(typeaheadQuery, isDropboxer);
  }, [typeaheadQuery, isDropboxer, performSearchThrottled]);

  // every time the query changes, immediately update the SERP CTA in the
  // results array with the new query text as fast as possible
  useLayoutEffect(() => {
    setResults((previousResults) =>
      previousResults.map((result: typeahead.ScoredResult) =>
        result.type === typeahead.ResultType.SuggestedQuery
          ? { ...result, result: { ...result.result, query: typeaheadQuery } }
          : result,
      ),
    );
  }, [typeaheadQuery]);

  const reloadResults = useCallback(() => {
    performSearch(typeaheadQuery, isDropboxer);
  }, [typeaheadQuery, isDropboxer, performSearch]);

  /**
   * Used to perform a typeahead search after the user has hit enter but before
   * we actually pick the results. This allows us to ensure that we have all
   * results for all sources before we pick the item to launch.
   */
  const performPreLaunchSearch = useCallback(
    (
      finalQuery: string,
      onComplete: (lastEmittedResults: typeahead.ScoredResult[]) => void,
    ) => {
      if (isDualModeLauncher && !finalQuery) {
        return onComplete(typeaheadResults);
      }
      performSearch(finalQuery, isDropboxer, onComplete);
    },
    [isDropboxer, isDualModeLauncher, performSearch, typeaheadResults],
  );

  const setTypeaheadQuery = useCallback(
    async (query: string, taggedQuery?: boolean) => {
      setTypeaheadQueryState(query);
      const hasQuery = query.length > 0;

      if (!hasQuery) {
        searchAttemptSessionManager.endSession(
          SEARCH_ATTEMPT_END_REASONS.QUERY_MANUALLY_CLEARED,
        );
      } else {
        searchAttemptSessionManager.extendOrCreateSession('type_query');
        searchAttemptSessionManager.updateProperties({
          query,
          isTypeahead: taggedQuery,
          featureLine,
        });

        // If a query exists, set has_query to true
        // If a query does not exist, leave it as true for the remainder of the session
        searchSessionManager.updateProperties({
          hasQuery: true,
          featureLine,
        });
      }
    },
    [
      setTypeaheadQueryState,
      searchAttemptSessionManager,
      searchSessionManager,
      featureLine,
    ],
  );

  useEffect(() => {
    if (!isUsingTypeaheadFiltersFeatureFlag) return;

    setFilters(() => syncFiltersWithQuery(typeaheadQuery, filters, allFilters));

    // We don't want to track filters
    // eslint-disable-next-line local-rules/warn-disable-exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeaheadQuery, isUsingTypeaheadFiltersFeatureFlag, allFilters]);

  return {
    typeaheadQuery,
    canShowTypeahead,
    setTypeaheadQuery,
    setCanShowTypeahead,
    reloadResults,
    typeaheadResults,
    activationType,
    performPreLaunchSearch,
    filters,
    allFilters,
    ...typeaheadSearchPAPLogging,
  };
}

function useTypeaheadQueryState(
  useInstancedQuery?: boolean,
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const [typeaheadQueryAtom, setTypeaheadQueryAtom] =
    useAtom(TypeaheadQueryAtom);
  const [typeaheadQueryInstanced, setTypeaheadQueryInstanced] =
    useState<string>('');
  return useInstancedQuery
    ? [typeaheadQueryInstanced, setTypeaheadQueryInstanced]
    : [typeaheadQueryAtom, setTypeaheadQueryAtom];
}
