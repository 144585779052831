import { CreateChatCompletionRequest } from '@mirage/service-dbx-api/service/grpc/ml_capabilities_apiv2/gen/api_v2_pb';
import {
  ApiKey,
  ChatContent,
  ChatDialog,
  ChatMessage,
  KeyProvider,
  ModelInfo,
  SystemMessage,
  UserMessage,
} from '@mirage/service-dbx-api/service/grpc/ml_capabilities_apiv2/gen/common_pb';

import type { LinkSummarySuccess } from '@mirage/shared/stack-item-summary/types';

const modelSourcesToSecretSauceKeys = {
  openai: 'DASH_STACK_ITEM_SUMMARIZATION_OPENAI',
};

interface ModelConfig {
  maxTokens: number;
  reservedTokens: number;
  temperature: number;
}

interface ModelConfigurations {
  [key: string]: ModelConfig;
}

const modelConfigs: ModelConfigurations = {
  'gpt-3.5-turbo-0125': {
    maxTokens: 16385,
    reservedTokens: 500,
    temperature: 0,
  },
  'gpt-4o': {
    maxTokens: 128000,
    reservedTokens: 500,
    temperature: 0,
  },
  'llama-v3-70b-instruct': {
    maxTokens: 8192,
    reservedTokens: 500,
    temperature: 0.00001,
  },
  'llama3-70b-8192': {
    maxTokens: 8192,
    reservedTokens: 500,
    temperature: 0.00001,
  },
};

const models = {
  GPT_3_5: 'gpt-3.5-turbo-0125',
  GPT_4o: 'gpt-4o',
  LLAMA_3_70B_INSTRUCT: 'llama-v3-70b-instruct',
  LLAMA_3_70B_8192: 'llama3-70b-8192',
};

export const Prompts = {
  SummaryWithQnA: {
    system: `Summarize the document with a very short tl;dr summary of 1-3 sentences followed by 3 question-answer pairs based on the key points of the document. For each answer, include 1 supporting quote from the document in blockquotes.

The output should follow the rules below:
- Make sure to replace the placeholders with the actual content.
- Make sure the summary is in regular text.
- Make sure the question is in bold text.
- Make sure the answer is in regular text.
- Make sure the quote is formatted in a blockquote.
- Do not include double-quotes in the blockquote. The blockquote should just be > followed by text.
- Just output the summary text as is, do not include any header or title saying "Summary".
- Do not number the questions 1, 2, 3.
- Make sure to make the summary as concise as possible with information condensed into short, digestible sentences.

Please adhere strictly to this formatting rule unless quoting speech or text that includes double quotes in the original source.


Example format in Markdown is provided below:

Short summary here, ideally two to three sentences long. Do not include "Summary" before this. Regular text with no formatting.

**What is the specific question?**

Answer to question.

> Supporting short (few words or max 1 sentence) quote from the document for the answer.


**What is the next question?**

Answer to question.

> Supporting short (few words or max 1 sentence) quote from the document for the answer.


**What is another relevant question?**

Answer to question.

> Supporting short (few words or max 1 sentence) quote from the document for the answer.
`,
    user: `Now, please generate the response for the below document. Remember -- short summary of a couple sentences first, followed by 3 question-answer pairs.
TITLE: {title}
TEXT:
====================
{text}
====================
`,
  },
  SummaryWithHeaderTakeaways: {
    system: `Summarize the document in the following style: a short headline summarizing the key insight from the content, followed by 3 short takeaways that are easily digestible. 

Rules for output
- Output the content in markdown.
- The main headline should be a sentence summarizing the key insight.
- The main headline should be bolded.
- The takeaways should each be short sentences that are easily digestible.
- The takeaways should have a header each, representing the topic of the takeway, that should also be bolded. The header should be 1 word, or max 2 words.
- Do not use bullets anywhere.
- There should be a line break ("\\") between the headline and the takeaways, a line break ("\\") between the takeaway header and takeaway, and line breaks ("\\") between takeaways.
- There should be no extra subheadings or headers anywhere. Just the main header and then the 3 takeaways.

This example below highlights an example summary generated in the correct format. Follow this format precisely:
**Google is sunsetting Gmail's basic HTML view in January 2024, replacing it with the Standard view. The HTML view lacks features but is useful for low-connectivity situations.**
\\
**Change**
\\
Google is replacing Gmail's basic HTML view with the Standard view in January 2024.
\\
**Features**
\\
The HTML view lacks features like chat, spell checker, search filters, and rich formatting.
\\
**Usefulness**
\\
The HTML view is useful for low-connectivity situations or for viewing emails without extra features.
`,
    user: `Now, please generate the response for the below document.
TITLE: {title}
TEXT:
====================
{text}
====================
`,
  },
  LinkQnA: {
    system: `Based on the document below, respond with an answer to the given question. Response should be in the following valid markdown format with the answer to the question followed by a supporting quote from the document in a blockquote on a new line:

Answer to question.

> Supporting short (few words or max 1 sentence) quote from the document for the answer.

Note that you should not include any additional formatting to imply it's markdown, or start the answer with an "Answer" header. The response should be in plain text, with the quote in a blockquote.
`,
    user: `Now, please generate the response for the below document.
QUESTION: {question}

DOCUMENT TITLE: {title}
DOCUMENT TEXT:
====================
{text}
====================
`,
  },
};

function getPromptDialogs(
  promptTemplate: string,
  model: string,
  title: string,
  text: string,
  question?: string,
): ChatMessage[] {
  const systemPrompt = Prompts[promptTemplate as keyof typeof Prompts].system;
  let userPrompt = Prompts[promptTemplate as keyof typeof Prompts].user;
  const config = modelConfigs[model];

  const usedChars =
    systemPrompt.length +
    userPrompt.length +
    title.length +
    (question?.length || 0);
  // This is currently using a heuristic of 4 chars ~= 1 token.
  // TODO: Use the actual token count from the model-specific tokenizer.
  const availableChars =
    (config.maxTokens - config.reservedTokens - Math.ceil(usedChars / 4)) * 4;
  let truncatedText = text;
  if (text.length > availableChars) {
    truncatedText = text.slice(0, availableChars);
  }
  userPrompt = userPrompt
    .replace('{title}', title)
    .replace('{text}', truncatedText);

  if (question) {
    userPrompt = userPrompt.replace('{question}', question);
  }

  const systemMessage = new ChatMessage({
    messageType: {
      case: 'systemMessage',
      value: new SystemMessage({
        content: new ChatContent({
          content: {
            case: 'text',
            value: systemPrompt,
          },
        }),
      }),
    },
  });

  const userMessage = new ChatMessage({
    messageType: {
      case: 'userMessage',
      value: new UserMessage({
        content: new ChatContent({
          content: {
            case: 'text',
            value: userPrompt,
          },
        }),
      }),
    },
  });

  return [systemMessage, userMessage];
}

export function getChatCompletionRequest(
  model: string,
  modelSource: string,
  feature: string,
  messages: ChatMessage[],
): CreateChatCompletionRequest {
  const apiKey = new ApiKey({
    key: modelSourcesToSecretSauceKeys[
      modelSource as keyof typeof modelSourcesToSecretSauceKeys
    ],
    provider: KeyProvider.SECRET_SAUCE,
  });

  return new CreateChatCompletionRequest({
    modelInfo: new ModelInfo({ modelName: model, modelSource: modelSource }),
    dialogs: [
      new ChatDialog({
        messages: messages,
      }),
    ],
    capabilitiesConfig: {
      feature: feature,
      apiKey: apiKey,
    },
    modelParams: {
      max_new_tokens: {
        configType: {
          case: 'int32Config',
          value: modelConfigs[model].reservedTokens,
        },
      },
      temperature: {
        configType: {
          case: 'floatConfig',
          value: modelConfigs[model].temperature,
        },
      },
    },
  });
}

export function getContentSummaryRequest(
  title: string,
  text: string,
): CreateChatCompletionRequest {
  const promptTemplate = 'SummaryWithQnA';
  const model = models.GPT_4o;
  const modelSource = 'openai';
  const feature = 'dash/stacks/doc_summary';
  const messages = getPromptDialogs(promptTemplate, model, title, text);
  const request = getChatCompletionRequest(
    model,
    modelSource,
    feature,
    messages,
  );
  return request;
}

export function getContentAnswerRequest(
  summary: LinkSummarySuccess,
  question: string,
): CreateChatCompletionRequest {
  if (!summary.content.text) {
    throw new Error('No body text found in the document content.');
  }
  const promptTemplate = 'LinkQnA';
  const model = models.GPT_4o;
  const modelSource = 'openai';
  const feature = 'dash/stacks/doc_qna';
  const messages = getPromptDialogs(
    promptTemplate,
    model,
    summary.content.title,
    summary.content.text,
    question,
  );
  const request = getChatCompletionRequest(
    model,
    modelSource,
    feature,
    messages,
  );
  return request;
}
