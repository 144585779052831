import { LockLine } from '@dropbox/dig-icons/assets';
import { Link } from '@mirage/link/Link';
import { useTranslation } from 'react-i18next';
import { MetadataItem } from './MetadataItem';

import type { Channel } from '@mirage/shared/search/search-result';

export type ChannelMetadataProps = {
  channel: Channel;
  isThreadedMessage: boolean;
};

export const ChannelMetadata = ({
  channel,
  isThreadedMessage,
}: ChannelMetadataProps) => {
  const { t } = useTranslation();
  const { name, url, type } = channel;

  const text = isThreadedMessage
    ? t('thread_in_channel', {
        channelName: name,
      })
    : name;
  const title = url ? (
    <Link href={url} variant="monochromatic">
      {text}
    </Link>
  ) : (
    text
  );
  const iconSrc =
    type['.tag'] === 'channel_type_private' && !isThreadedMessage
      ? LockLine
      : undefined;
  return <MetadataItem title={title} iconSrc={iconSrc} />;
};
