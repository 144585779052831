import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_StacksDescription } from '@mirage/analytics/events/types/cancel_stacks_description';
import { PAP_Click_StacksDescription } from '@mirage/analytics/events/types/click_stacks_description';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { useStackChat } from '@mirage/service-experimentation/useStackChat';
import { MAX_STACK_DESCRIPTION_LENGTH } from '@mirage/shared/util/constants';
import i18n, { meticulousRedactedStringClass } from '@mirage/translations';
import classnames from 'classnames';
import { useRef } from 'react';
import styles from '../EditStackDetails.module.css';
import { useUpdateStackNameAndDescription } from '../hooks/useUpdateStackNameAndDescription';
import { FieldEditor } from './FieldEditor';

import type { StackDerivedPAPPropsWithFeature } from '../../types';
import type { FieldEditorRef } from './FieldEditor';
import type { RefObject } from 'react';

interface StackDescriptionEditorProps {
  stackDescription: string;
  isNewStack: boolean;
  updateStackNameAndDescription: (name: string, description: string) => void;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  fieldEditorRef?: RefObject<FieldEditorRef>;
  enableEditMode?: boolean;
  hasWritePermissionsOverride?: boolean;
  onEditStatusUpdate?: (status: boolean) => void;
}

export const StackDescriptionEditor: React.FC<StackDescriptionEditorProps> = ({
  stackDescription,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  fieldEditorRef,
  enableEditMode,
  hasWritePermissionsOverride,
  onEditStatusUpdate,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isAugustRev = useStackPageAugustRevisionEnabled();
  const isStackChatEnabled = useStackChat();
  const fieldInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = useUpdateStackNameAndDescription(
    isNewStack,
    updateStackNameAndDescription,
  );

  return (
    <FieldEditor
      fieldName={stackDescription}
      fieldEditorRef={fieldEditorRef}
      fieldInputRef={fieldInputRef}
      enableEditMode={enableEditMode || isNewStack}
      mode="description"
      rows={isAugustRev ? 1 : undefined}
      hasWritePermissionsOverride={hasWritePermissionsOverride}
      submitFieldChange={handleSubmit}
      placeholder={i18n.t('stack_description_placeholder')}
      maxLength={MAX_STACK_DESCRIPTION_LENGTH}
      onEditStatusUpdate={onEditStatusUpdate}
      onFocused={() => {
        reportPapEvent(
          PAP_Click_StacksDescription(stackDerivedPAPPropsWithFeature),
        );
        dispatchElementClicked(
          createUxaElementId('stack_description', {
            featureLine: 'stacks',
          }),
        );
      }}
      onCancel={() => {
        reportPapEvent(
          PAP_Cancel_StacksDescription(stackDerivedPAPPropsWithFeature),
        );
      }}
      additionalStyles={classnames(meticulousRedactedStringClass, {
        [styles.stackDescriptionInputAugRev]: isAugustRev,
        [styles.stackDescriptionInputChat]: isStackChatEnabled,
      })}
      resizable={isAugustRev ? 'auto' : 'vertical'}
    />
  );
};
