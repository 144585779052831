import { callApiV2 } from '@mirage/service-dbx-api';
import Sentry from '@mirage/shared/sentry';
import { useCallback, useEffect, useState } from 'react';

export function useDashTeamInfo() {
  const [teamName, setTeamName] = useState<string>('');
  const [teamLogoUrl, setTeamLogoUrl] = useState<string>('');

  useEffect(() => {
    const doFetchTeamLogo = async () => {
      const [logoResponse, teamResponse] = await Promise.allSettled([
        callApiV2('dashAdminGetDashTeamLogoUrl', {}),
        callApiV2('dashAdminGetDashTeam', {}),
      ]);

      if (logoResponse.status === 'fulfilled') {
        setTeamLogoUrl(logoResponse.value?.url ?? '');
      } else {
        Sentry.captureMessage(`[useDashTeamInfo] Error fetching team logo`);
      }

      if (teamResponse.status === 'fulfilled') {
        setTeamName(teamResponse.value?.team_name ?? '');
      } else {
        Sentry.captureMessage(`[useDashTeamInfo] Error fetching team name`);
      }
    };

    doFetchTeamLogo();
  }, [setTeamLogoUrl, setTeamName]);

  const updateTeamName = useCallback(async (updatedTeamName: string) => {
    const response = await callApiV2('dashAdminUpdateDashTeamName', {
      new_team_name: updatedTeamName,
    });
    const success = !!response.success;
    if (success) {
      setTeamName(updatedTeamName);
    }
    return success;
  }, []);

  const getTeamLogo = async (file: File) => {
    return new Promise<string | undefined>((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const data = reader.result;
        if (data === null) {
          resolve(undefined);
          return;
        }

        const base64Data = data?.toString().split(',')[1];
        resolve(base64Data);
      };

      reader.onerror = () =>
        reject(new Error('[getTeamLogo] Error reading team logo'));

      reader.readAsDataURL(file);
    });
  };

  const updateTeamLogo = async (teamLogo: string) => {
    if (!teamLogo) return false;
    try {
      await callApiV2('dashAdminSaveDashTeamLogo', {
        img_data: teamLogo,
      });
      return true;
    } catch (error) {
      Sentry.captureMessage(`[updateTeamLogo] Error uploading dash team logo`);
      return false;
    }
  };

  return { teamName, teamLogoUrl, updateTeamName, updateTeamLogo, getTeamLogo };
}
