import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { firstValueFrom } from 'rxjs';

import type { Service } from '@mirage/service-browser-history-v1/service';

const service = services.get<Service>(ServiceId.BROWSER_HISTORY_V1);

export function refreshBrowserHistory(dbxId: string): Promise<void> {
  return firstValueFrom(service.refreshBrowserHistory(dbxId));
}

export const listen = service.listen;
