import { getFirstLoginTime } from '@mirage/service-onboarding';
import { getOnboardingChecklist } from '@mirage/service-onboarding-checklist';
import { openURL } from '@mirage/service-platform-actions';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import useAvailableExtensions from '@mirage/settings/utils/useAvailableExtensions';
import { DESKTOP_DOWNLOAD_URL } from '@mirage/shared/urls';
import {
  openExtensionLinkForCurrentBrowser,
  useIsExtensionInstalled,
} from '@mirage/shared/util/webExtensionsHelpers';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  OnboardingChecklistAtom,
  OnboardingChecklistDirtyAtom,
  OpenTypeaheadAtom,
} from './checklist-atoms';

import type {
  OnboardingChecklistStep,
  OnboardingChecklistStepPartial,
} from '../types';
import type { stacks } from '@dropbox/api-v2-client';
import type { OnboardingChecklistItem } from '@mirage/service-onboarding/service';

const ONBOARDING_CHECKLIST_TYPE = 'is_dash_checklist';

export function useGetOnboardingChecklistItems(
  checklistSteps: OnboardingChecklistStepPartial[],
  gettingStartedStack: stacks.Stack | undefined,
) {
  const [srvChecklistItems, setSrvChecklistItems] = useState<
    OnboardingChecklistItem[] | undefined
  >(undefined);
  const setChecklistItems = useSetAtom(OnboardingChecklistAtom);
  const setOpenTypeahead = useSetAtom(OpenTypeaheadAtom);
  const [isDirty, setIsDirty] = useAtom(OnboardingChecklistDirtyAtom);
  const navigate = useNavigate();
  const { extensionAvailableForBrowser } = useAvailableExtensions();
  const extensionInstalled = useIsExtensionInstalled();
  const hasBrowserExtensionInstalled =
    extensionInstalled && extensionAvailableForBrowser;
  const checklistItemsRequested = useRef(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const doSetChecklistItems = useCallback(async () => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

    debounceTimeout.current = setTimeout(async () => {
      const items = await getOnboardingChecklist(ONBOARDING_CHECKLIST_TYPE);
      setSrvChecklistItems(items);
    }, 300); // Adjust the debounce delay as needed (e.g., 300ms)
  }, []);

  useEffect(() => {
    if (checklistItemsRequested.current) return;
    checklistItemsRequested.current = true;
    doSetChecklistItems();
  }, [doSetChecklistItems]);

  // If the checklist is 'dirty' that means we've marked checklist items
  // as complete and we need to perform a refresh
  useEffect(() => {
    const doChecklistItemRefresh = async () => {
      if (!isDirty) return;
      setIsDirty(false);
      await doSetChecklistItems();
    };
    doChecklistItemRefresh();
  }, [doSetChecklistItems, isDirty, setIsDirty]);

  useEffect(() => {
    const doSetChecklistSteps = async () => {
      const hasInstalledDesktop = !!(await getFirstLoginTime('desktop'));
      const updatedChecklistItems = checklistSteps
        .map((uiModule) => {
          const { moduleId } = uiModule;
          const module = srvChecklistItems?.find(
            (m) => m.moduleId === moduleId,
          );
          if (!module) return undefined;

          const newChecklistItem = {
            ...module,
            ...uiModule,
          };

          switch (moduleId) {
            case 'perform_dash_search_module':
              newChecklistItem.actions[0].action = () => {
                setOpenTypeahead(true);
              };
              break;
            case 'create_stack_module':
              newChecklistItem.actions[0].action = () => {
                navigate('/stacks/new');
              };
              break;
            case 'explore_get_started_stack_module':
              newChecklistItem.actions[0].action = () => {
                navigate(`/stacks/${stackGetShareId(gettingStartedStack)}`);
              };
              break;
            case 'install_dash_desktop_and_extension_module': {
              const downloadExtensionAction = newChecklistItem.actions.find(
                (action) => action.key === 'download_extension',
              );
              if (downloadExtensionAction) {
                downloadExtensionAction.markComplete =
                  hasBrowserExtensionInstalled;
                downloadExtensionAction.action = () => {
                  openExtensionLinkForCurrentBrowser(openURL);
                };
              }

              const downloadDesktopAppAction = newChecklistItem.actions.find(
                (action) => action.key === 'download_desktop_app',
              );
              if (downloadDesktopAppAction) {
                downloadDesktopAppAction.markComplete = hasInstalledDesktop;
                downloadDesktopAppAction.action = () => {
                  openURL(DESKTOP_DOWNLOAD_URL);
                };
              }
              break;
            }
          }

          return newChecklistItem;
        })
        .filter(Boolean) as OnboardingChecklistStep[];
      setChecklistItems(updatedChecklistItems);
    };
    doSetChecklistSteps();
  }, [
    checklistSteps,
    gettingStartedStack,
    hasBrowserExtensionInstalled,
    navigate,
    setChecklistItems,
    setOpenTypeahead,
    srvChecklistItems,
  ]);

  return { refreshChecklistItems: doSetChecklistItems };
}
