import { useFeatureFlagValueWithLogExposureCallback } from '@mirage/service-experimentation/useFeatureFlagValue';
import Sentry from '@mirage/shared/sentry';
import classNames from 'classnames';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { AsyncAssistantFileViewerToolbar } from './AsyncAssistantFileViewerToolbar';
import styles from './FileViewer.module.css';

import type { FileViewerProps } from './types';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';

const LazyFileViewer = lazy(
  async (): Promise<{
    default: React.ComponentType<FileViewerProps>;
  }> => {
    const component = await import('@mirage/mosaics/FileViewer/FileViewer');
    return { default: component.FileViewer };
  },
);

const FallbackFileViewer = () => {
  return (
    <div className={classNames(styles.FileViewerWrapper, styles.Skeleton)} />
  );
};

const AsyncFileViewer: React.FC<FileViewerProps> = (props) => {
  return (
    <Suspense fallback={<FallbackFileViewer />}>
      <LazyFileViewer {...props} />
    </Suspense>
  );
};

export const useAsyncAssistantFileViewer = ({
  url,
  fileName,
  handleRemoveSource,
  logComposeEvent,
}: {
  url: string | null | undefined;
  fileName: string | undefined;
  handleRemoveSource: React.MouseEventHandler;
  logComposeEvent: (
    event: PAPEvent,
    overrides?: { actionSurfaceComponent?: ActionSurfaceComponent },
  ) => void;
}) => {
  const [fileViewerIsErrored, setFileViewerIsErrored] = useState(false);
  const [isFileViewerEnabled, logFileViewerExposureCallback] =
    useFeatureFlagValueWithLogExposureCallback(
      'dash_web_2025_02_12_assistant_file_viewer',
    );

  useEffect(() => {
    // If the sharedLink has changed, it may now be possible to preview it.
    setFileViewerIsErrored(false);
  }, [url]);

  const errorCallback = useCallback(
    (error: string) => {
      Sentry.captureMessage(error, 'error');
      setFileViewerIsErrored(true);
    },
    [setFileViewerIsErrored],
  );

  const sharedLink =
    url && url.includes('dropbox.com/scl/fi') ? url : undefined;

  if (
    isFileViewerEnabled !== 'ON' ||
    !sharedLink ||
    !fileName ||
    fileViewerIsErrored
  ) {
    return null;
  }

  return (
    <AsyncFileViewer
      sharedLinkUrl={sharedLink}
      fileName={fileName}
      reportError={errorCallback}
      logExposureCallback={logFileViewerExposureCallback}
    >
      <AsyncAssistantFileViewerToolbar
        logFilenameClick={logComposeEvent}
        removeSource={handleRemoveSource}
        sharedLinkUrl={sharedLink}
        fileName={fileName}
      />
    </AsyncFileViewer>
  );
};
