import type { comments2 } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export const addComment = async (
  callApiV2: APIv2Callable,
  comment: comments2.UserSubmittedComment,
  stream: comments2.Stream,
): Promise<string> => {
  const response = await callApiV2('comments2AddComment', {
    comment,
    stream,
  });

  return response.id;
};

export const deleteComment = async (
  callApiV2: APIv2Callable,
  commentId: string,
  stream: comments2.Stream,
): Promise<string> => {
  const response = await callApiV2('comments2DeleteComment', {
    id: commentId,
    stream,
  });

  return response.id;
};

export const editComment = async (
  callApiV2: APIv2Callable,
  comment: comments2.UserSubmittedComment,
  commentId: string,
  stream: comments2.Stream,
): Promise<string> => {
  const response = await callApiV2('comments2EditComment', {
    comment,
    id: commentId,
    stream,
  });

  return response.id;
};

export const listComments = async (
  callApiV2: APIv2Callable,
  stream: comments2.Stream,
): Promise<comments2.ListThreadsResult> => {
  const response = await callApiV2('comments2ListComments', {
    stream,
  });

  return response;
};
