import { Text } from '@dropbox/dig-components/typography';
import { Trans } from '@mirage/translations';
import classnames from 'classnames';
import styles from './ShortcutTitle.module.css';

import type { TitleToken } from '@mirage/shared/search/url-shortcut';
import type { ReactNode } from 'react';

type ShortcutTitleProps = {
  title: TitleToken;
  selected?: boolean;
};

const ParamContainer = ({
  isSelected,
  children,
}: {
  isSelected: boolean;
  children?: ReactNode;
}) => {
  return (
    <div
      className={classnames(styles.paramContainer, {
        [styles.selectedContainer]: isSelected,
      })}
    >
      <Text isBold={true}>{children}</Text>
    </div>
  );
};

export const ShortcutTitle = ({
  title,
  selected = false,
}: ShortcutTitleProps) => {
  return (
    <Trans
      i18nKey={title.template}
      values={{ q: title.query }}
      components={{
        query: <ParamContainer isSelected={selected} />,
      }}
    />
  );
};

export function ShortcutSubtitle({ subtitle }: { subtitle: ReactNode }) {
  return <Text color="subtle">{subtitle}</Text>;
}
