import { ThemeProvider } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Initiate_UpdateStackEmoji } from '@mirage/analytics/events/types/initiate_update_stack_emoji';
import { DashStackIcon } from '@mirage/mosaics/DCLWrappers/DashStackIcon';
import { getDefaultColorIndex, getTheme } from '@mirage/stacks/themes';
import i18n from '@mirage/translations';
import React from 'react';
import { EMOJI_CLEARED_SENTINEL_VALUE } from '../../Helpers/Utils';
import { EditStackDetails } from '../EditStackDetails';
import { EmojiPicker } from '../EmojiPicker';
import styles from './Header.module.css';
import StackInfoAndMembers from './StackInfoAndMembers';

import type { HeaderCoreProps } from './HeaderCommon';

const HeaderV1: React.FC<HeaderCoreProps> = ({
  stack,
  canEdit,
  emojiPickerVisible,
  setEmojiPickerVisible,
  updateEmoji,
  stackDerivedPAPPropsWithFeature,
  withImmediateAction,
  triggerEditStackRef,
  defaultUserInfo,
  updateStackNameAndDescription,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isNewStack = stack === null;

  const newStackColorIndex = getDefaultColorIndex();
  const newStackEmoji = EMOJI_CLEARED_SENTINEL_VALUE;
  const newStackDescription = '';

  const stackTitle = stack?.stack_data?.name ?? '';

  return (
    <ThemeProvider
      overrides={
        getTheme(
          isNewStack ? newStackColorIndex : stack.stack_data?.color_index,
          isNewStack ? undefined : stack.stack_data?.custom_color,
        ).primary
      }
    >
      {({ getThemeProps }) => (
        <div
          {...getThemeProps({
            className: styles.headerContainer,
          })}
        >
          <div className={styles.headerMain}>
            <div className={styles.headerActions}>
              <DashStackIcon
                colorIndex={
                  isNewStack
                    ? newStackColorIndex
                    : stack.stack_data?.color_index || getDefaultColorIndex()
                }
                emoji={
                  isNewStack
                    ? newStackEmoji
                    : stack.stack_data?.emoji || EMOJI_CLEARED_SENTINEL_VALUE
                }
                onClick={
                  canEdit
                    ? () => {
                        setEmojiPickerVisible(!emojiPickerVisible);
                        reportPapEvent(
                          PAP_Initiate_UpdateStackEmoji(
                            stackDerivedPAPPropsWithFeature,
                          ),
                        );
                      }
                    : undefined
                }
                tooltipProps={{
                  title: i18n.t('change_stack_icon'),
                }}
                customColor={
                  isNewStack ? undefined : stack.stack_data?.custom_color
                }
              />
              {emojiPickerVisible && (
                <EmojiPicker
                  setEmojiPickerVisible={setEmojiPickerVisible}
                  onEmojiSelect={updateEmoji}
                  papProps={stackDerivedPAPPropsWithFeature}
                />
              )}
              {withImmediateAction}
            </div>
            <EditStackDetails
              triggerEditStackRef={triggerEditStackRef}
              isNewStack={isNewStack}
              stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
              stackName={stackTitle}
              stackDescription={
                isNewStack
                  ? newStackDescription
                  : stack.stack_data?.description || ''
              }
              withStackInfo={
                stack && (
                  <StackInfoAndMembers
                    stack={stack}
                    defaultUserInfo={defaultUserInfo}
                  />
                )
              }
              updateStackNameAndDescription={updateStackNameAndDescription}
              hasWritePermissionsOverride={canEdit}
            />
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default HeaderV1;
